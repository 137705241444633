import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  useTheme
} from "@mui/material"
import { useState } from "react"
import { monthNames } from "../PreventiveMaintenancePlan"
import DialogEditTarget from "./DialogEditTarget"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"

interface Repairer {
  id: number
  checkReceive: number
  completeDate: number
  department: number
  docNo: number
  repairMan: number
  repairType: number
  receiver: number
  detail: number
  requestDate: number
  startRepair: number
  waitRepair: number
  endWaitRepair: number
  referDate: number
  leaderCheck: number
  managerCheck: number
  actionDate: number
}
export interface ITargetKpi {
  typeName: string
  target: string
  line: string
}
interface IProp {
  linePress: Repairer[]
  lineSpot: Repairer[]
  lineArcWelding: Repairer[]
  target: ITargetKpi[]
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 16,
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2,
    width: "150px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2,
    width: "150px"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  border: "solid 1px black",
  "&:nth-of-type(odd)": {
    border: "solid 1px black"
  },

  "&:last-child td, &:last-child th": {
    border: "solid 1px black"
  }
}))

const total = (linePress: Repairer[], i: number) => {
  if (linePress === undefined)
    return { dataOfMonth: 0, totalQuantityRepair: 0, arc: 0 }
  const dataOfMonth = linePress.filter(
    (e) => new Date(e.actionDate).getMonth() === i
  )
  if (dataOfMonth.length) {
    const totalQuantityRepair = dataOfMonth
      .map((data) => {
        const diff = Math.abs(
          Number(new Date(data.completeDate)) -
            Number(new Date(data.endWaitRepair))
        )
        const actualTime = diff / 1000 / 60 / 60
        return actualTime
      })
      .reduce((prev: number, cur: number) => {
        return prev + cur
      }, 0)

    const arc = totalQuantityRepair / dataOfMonth.length
    return {
      dataOfMonth: totalQuantityRepair * 60,
      totalQuantityRepair: dataOfMonth.length,
      arc: (arc * 60).toFixed(2)
    }
  } else {
    return { dataOfMonth: 0, totalQuantityRepair: 0, arc: 0 }
  }
}

const MTTR: React.FC<IProp> = (props: IProp) => {
  const { linePress, lineSpot, lineArcWelding, target } = props
  const [open, setOpen] = useState<boolean>(false)
  const [line, setLine] = useState<string>("")
  const [updateTarget, setTarget] = useState<string>("")
  const theme = useTheme()
  return (
    <>
      <h1>ระยะเวลาเฉลี่ยในการซ่อมแต่ละครั้ง(MTTR)</h1>
      <DialogEditTarget
        setOpen={setOpen}
        setTarget={setTarget}
        open={open}
        line={line}
        type={"MTTR"}
        updateTarget={updateTarget}
      />
      <h3 style={{ display: "inline-block" }}>LINE PRESS</h3>
      <ThemedLoadingButton
        sx={{ mt: -2, marginLeft: "10px" }}
        variant="contained"
        style={{ backgroundColor: theme.palette.warning.main }}
        onClick={() => {
          setOpen(true)
          setLine("Press")
        }}
      >
        EDIT TARGET
      </ThemedLoadingButton>
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>Month</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  <span>{monthNames[i]}</span>
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Target
            </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell
                  colSpan={1}
                  rowSpan={1}
                  key={i}
                  style={{ backgroundColor: "grey" }}
                >
                  {target.length > 0
                    ? target.find((e: { line: string }) => e.line === "Press")
                        ?.target
                    : "0"}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>จำนวนครั้งที่ซ่อม</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {total(linePress, i).totalQuantityRepair}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>เวลาสูญเสีย </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {total(linePress, i).dataOfMonth.toFixed(2)}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Act.Targe (%)
            </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell
                  colSpan={1}
                  rowSpan={1}
                  key={i}
                  style={{ backgroundColor: "grey" }}
                >
                  {total(linePress, i).arc}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
        </TableBody>
      </Table>
      <hr />
      <h3 style={{ display: "inline-block" }}>LINE SPOT</h3>
      <ThemedLoadingButton
        sx={{ mt: -2, marginLeft: "10px" }}
        variant="contained"
        style={{ backgroundColor: theme.palette.warning.main }}
        onClick={() => {
          setOpen(true)
          setLine("Spot")
        }}
      >
        EDIT TARGET
      </ThemedLoadingButton>
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>Month</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  <span>{monthNames[i]}</span>
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Target
            </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell
                  colSpan={1}
                  rowSpan={1}
                  key={i}
                  style={{ backgroundColor: "grey" }}
                >
                  {target.length > 0
                    ? target.find((e: { line: string }) => e.line === "Spot")
                        ?.target
                    : "0"}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>จำนวนครั้งที่ซ่อม</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {total(lineSpot, i).totalQuantityRepair}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>เวลาสูญเสีย </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {total(lineSpot, i).dataOfMonth.toFixed(2)}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Act.Targe (%)
            </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell
                  colSpan={1}
                  rowSpan={1}
                  key={i}
                  style={{ backgroundColor: "grey" }}
                >
                  {total(lineSpot, i).arc}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
        </TableBody>
      </Table>
      <hr />
      <h3 style={{ display: "inline-block" }}>LINE ARC WELDING</h3>
      <ThemedLoadingButton
        sx={{ mt: -2, marginLeft: "10px" }}
        variant="contained"
        style={{ backgroundColor: theme.palette.warning.main }}
        onClick={() => {
          setOpen(true)
          setLine("ArcWelding")
        }}
      >
        EDIT TARGET
      </ThemedLoadingButton>
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>Month</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  <span>{monthNames[i]}</span>
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Target
            </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell
                  colSpan={1}
                  rowSpan={1}
                  key={i}
                  style={{ backgroundColor: "grey" }}
                >
                  {target.length > 0
                    ? target.find(
                        (e: { line: string }) => e.line === "ArcWelding"
                      )?.target
                    : "0"}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>จำนวนครั้งที่ซ่อม</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {total(lineArcWelding, i).totalQuantityRepair}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>เวลาสูญเสีย </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {total(lineArcWelding, i).dataOfMonth}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Act.Targe (%)
            </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell
                  colSpan={1}
                  rowSpan={1}
                  key={i}
                  style={{ backgroundColor: "grey" }}
                >
                  {total(lineArcWelding, i).arc}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
        </TableBody>
      </Table>
    </>
  )
}
export default MTTR
