import { TextField, TextFieldProps } from "@mui/material"

const FormTextField = (props: TextFieldProps): JSX.Element => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...props.InputProps,
        sx: {
          color: "#666666"
        }
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        sx: {
          color: "#666666",
          borderbottomColor: "blue"
        }
      }}
      sx={{
        ...props.sx,

        "& .MuiInput-underline:before": {
          borderBottomColor: "#666666"
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#666666"
        },
        "& .MuiInput-underline:hover:before": {
          borderBottomColor: "#666666"
        }
      }}
    />
  )
}

export default FormTextField
