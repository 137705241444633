import React, { Dispatch, SetStateAction } from "react"
import {
  Grid,
  TextField,
  Autocomplete,
  createFilterOptions
} from "@mui/material"
import "../../../css/Logistics/LogisticsCSS.css"

export interface IAdvanceFormProps {
  advanceQuantity: number
  costPerUnit: number
  setAdvanceQuantity: Dispatch<SetStateAction<number>>
  setCostPerUnit: Dispatch<SetStateAction<number>>
}

const AdvanceForm = (props: IAdvanceFormProps): JSX.Element => {
  const { advanceQuantity, costPerUnit, setAdvanceQuantity, setCostPerUnit } =
    props
  const costPerUnitList = [0, 4, 9, 13]
  const filter = createFilterOptions<number>()
  return (
    <Grid item xs={12}>
      <Grid item container spacing={2} xs={12}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            value={advanceQuantity}
            key="advanceQuantity"
            onChange={(e) => setAdvanceQuantity(+e.target.value)}
            label="จำนวนกล่อง (Advance Quantity)"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            filterOptions={(options, params) => {
              const filtered = filter(options, params)
              const { inputValue } = params
              if (inputValue !== "") {
                filtered.push(parseInt(inputValue))
              }
              return filtered
            }}
            freeSolo
            fullWidth
            value={costPerUnit}
            disablePortal
            key="costPerUnit"
            onChange={(event, selectCostPerUnit) => {
              setCostPerUnit(
                selectCostPerUnit ? parseInt(selectCostPerUnit.toString()) : 0
              )
            }}
            options={costPerUnitList}
            getOptionLabel={(option) => option.toString()}
            renderInput={(params) => (
              <TextField {...params} label="ราคา/กล่อง" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            disabled
            fullWidth
            label="ราคา(Advance)"
            key="totalAdvance"
            aria-label="totalAdvance"
            value={costPerUnit * advanceQuantity}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AdvanceForm
