import React, { Dispatch, SetStateAction, useState } from "react"
import { Grid, Autocomplete, useTheme, ThemeProvider } from "@mui/material"
import PrintIcon from "@mui/icons-material/Print"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import moment from "moment"
import { LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import DeliveryTagLabels from "./logisticscomponents/DeliveryTagLabels"
import "../../css/Logistics/DeliveryTag1.css"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import AutocompleteCustomer from "../../common/Resources/AutocompleteCustomer"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import DatePickerCustom from "../../common/Resources/DatePickerCustom"
import { factoryList } from "../../common/utilities"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"

export interface IDeliveryTagFormProps {
  customer: string
  partNo: string
  deliveryDate: Date
  period: string
  warehouseQuantity: number
  totalQuantity: number
  pdLotNo: string
  packingBy: string
  factory: string
  remarks: string
  poCustomer: string
  partName: string
  model: string
  printMode: boolean
  setCustomer: Dispatch<SetStateAction<string>>
  setPartNo: Dispatch<SetStateAction<string>>
  setDeliveryDate: Dispatch<SetStateAction<Date>>
  setPeriod: Dispatch<SetStateAction<string>>
  setWarehouseQuantity: Dispatch<SetStateAction<number>>
  setTotalQuantity: Dispatch<SetStateAction<number>>
  setPdLotNo: Dispatch<SetStateAction<string>>
  setPackingBy: Dispatch<SetStateAction<string>>
  setFactory: Dispatch<SetStateAction<string>>
  setRemarks: Dispatch<SetStateAction<string>>
  setPoCustomer: Dispatch<SetStateAction<string>>
  // makeDeliveryLabels: () => void
  setPrintMode: Dispatch<SetStateAction<boolean>>
}

export interface DeliveryTagInfo {
  partNo: string
  partName: string
  customer: string
  model: string
  quantity: number
  totalQuantity: number
  packingBy: string
  deliveryDate: string
  pdLotNo: string
  remarks: string
  boxCount: string
  factory: string
  period: string
  poCustomer: string
}

const pageTitle = "Delivery Tag-1"

export const DeliveryTagForm = (props: IDeliveryTagFormProps): JSX.Element => {
  const {
    customer,
    partNo,
    deliveryDate,
    period,
    warehouseQuantity,
    totalQuantity,
    pdLotNo,
    packingBy,
    factory,
    remarks,
    poCustomer,
    // partName,
    // model,
    // printMode
    setCustomer,
    setPartNo,
    setDeliveryDate,
    setPeriod,
    setWarehouseQuantity,
    setTotalQuantity,
    setPdLotNo,
    setPackingBy,
    setFactory,
    setRemarks,
    setPoCustomer
    // makeDeliveryLabels,
    // setPrintMode
  } = props

  const periodList = ["รอบปกติ", "รอบเสริม", "รอบพิเศษ"]
  const packingByList = ["รุ่งฤดี", "วรารัตน์", "อุไรรัตน์"]

  const handleReset = () => {
    setCustomer("")
    setPartNo("")
    setDeliveryDate(new Date())
    setPeriod("")
    setWarehouseQuantity(0)
    setTotalQuantity(0)
    setPdLotNo("")
    setPackingBy("")
    setFactory("")
    setRemarks("")
    setPoCustomer("")
  }

  const theme = useTheme()

  return (
    <div aria-label="delivery-form">
      <Grid container spacing={2} width={"98%"} padding={3}>
        <Grid item xs={12} sm={3}>
          <AutocompleteCustomer setCustomer={setCustomer} customer={customer} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AutocompletePartNo setPartNo={setPartNo} partNo={partNo} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePickerCustom
              setDate={setDeliveryDate}
              date={deliveryDate}
              label="วันที่ส่ง"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            fullWidth
            value={period}
            disableClearable
            disablePortal
            aria-label="periodTest"
            key="periodTest"
            onChange={(event, selectedPeriod) => {
              setPeriod(selectedPeriod as string)
            }}
            options={periodList}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                label="Period"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <ThemedTextField
            fullWidth
            value={warehouseQuantity}
            label="WH Quantity"
            onChange={(e) =>
              setWarehouseQuantity(isNaN(+e.target.value) ? 0 : +e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <ThemedTextField
            fullWidth
            value={totalQuantity}
            label="Total Quantity"
            onChange={(e) =>
              setTotalQuantity(isNaN(+e.target.value) ? 0 : +e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <ThemedTextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={pdLotNo}
            label="PD Lot NO"
            onChange={(e) => setPdLotNo(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            fullWidth
            value={packingBy}
            disableClearable
            disablePortal
            onChange={(event, selectedPackedBy) => {
              setPackingBy(selectedPackedBy as string)
            }}
            options={packingByList}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                label="Packed By"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            fullWidth
            disablePortal
            value={factory}
            onChange={(event, selectedFactory) => {
              setFactory(selectedFactory as string)
            }}
            options={factoryList}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                label="Factory"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <ThemedTextField
            fullWidth
            value={remarks}
            label="Remarks"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ThemedTextField
            fullWidth
            value={poCustomer}
            label="P/O Customer"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setPoCustomer(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} spacing={2} className="hidden-print">
          <ThemedButton
            variant="outlined"
            aria-label="print"
            endIcon={<PrintIcon />}
            style={{
              borderColor: theme.palette.primary.main,
              color: theme.palette.secondary.contrastText
            }}
            onClick={() => window.print()}
          >
            Print
          </ThemedButton>

          <ThemedButton
            variant="outlined"
            aria-label="reset"
            endIcon={<RestartAltIcon />}
            style={{
              borderColor: "red",
              color: "red",
              marginLeft: "15px"
            }}
            onClick={handleReset}
          >
            Reset
          </ThemedButton>
        </Grid>
      </Grid>
    </div>
  )
}

const DeliveryTag1 = (): JSX.Element => {
  const [customer, setCustomer] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [deliveryDate, setDeliveryDate] = useState<Date>(new Date())
  const [period, setPeriod] = useState<string>("")
  const [warehouseQuantity, setWarehouseQuantity] = useState<number>(0)
  const [totalQuantity, setTotalQuantity] = useState<number>(0)
  const [pdLotNo, setPdLotNo] = useState<string>("")
  const [packingBy, setPackingBy] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [remarks, setRemarks] = useState<string>("")
  const [poCustomer, setPoCustomer] = useState<string>("")
  const [printMode, setPrintMode] = useState<boolean>(false)

  const [partName, setPartName] = useState<string>("")
  const [model, setModel] = useState<string>("")
  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <div className="delivery-tag1-report-to-print">
        <PageLabel
          menuItem={menuItemList.Logistics}
          menuItemName={Object.keys(menuItemList)[8]}
          menuPageName={pageTitle}
        />
        <ThemedCard>
          <PageLabelCard
            title="Get Delivery Tag-1"
            subTitle="detail about Get Delivery Tag-1"
          />
          <div className="hidden-print">
            <DeliveryTagForm
              customer={customer}
              partNo={partNo}
              deliveryDate={deliveryDate}
              period={period}
              warehouseQuantity={warehouseQuantity}
              totalQuantity={totalQuantity}
              pdLotNo={pdLotNo}
              packingBy={packingBy}
              factory={factory}
              remarks={remarks}
              poCustomer={poCustomer}
              partName={partName}
              model={model}
              printMode={printMode}
              setCustomer={setCustomer}
              setPartNo={setPartNo}
              setDeliveryDate={setDeliveryDate}
              setPeriod={setPeriod}
              setWarehouseQuantity={setWarehouseQuantity}
              setTotalQuantity={setTotalQuantity}
              setPdLotNo={setPdLotNo}
              setPackingBy={setPackingBy}
              setFactory={setFactory}
              setRemarks={setRemarks}
              setPoCustomer={setPoCustomer}
              setPrintMode={setPrintMode}
            />
          </div>
        </ThemedCard>

        <Grid
          container
          className="print-delivery-new-tag"
          sx={{ backgroundColor: "white" }}
        >
          {warehouseQuantity !== 0 ? (
            <DeliveryTagLabels
              customer={customer}
              partNo={partNo}
              period={period}
              warehouseQuantity={warehouseQuantity}
              totalQuantity={totalQuantity}
              packingBy={packingBy}
              pdLotNo={pdLotNo}
              deliveryDate={moment(deliveryDate).format("DD-MM-YYYY")}
              setPartName={setPartName}
              setModel={setModel}
            />
          ) : (
            <></>
          )}
        </Grid>
      </div>
    </ThemeProvider>
  )
}

export default DeliveryTag1
