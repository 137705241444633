import { Divider, Grid, Typography, useTheme } from "@mui/material"
import { ThemeProvider } from "@mui/styles"
import GraphSheet from "./Graph/GraphSheet"
import { useQuery } from "@apollo/client"
import { GET_PM_SHEET } from "../../../../common/Query/engineerQuery"
import moment from "moment"
import { useState } from "react"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import ThemedTextField from "../../../../common/Resources/ThemedComponents/ThemedTextField"
import DateAdapter from "@mui/lab/AdapterMoment"
import ThemedCard from "../../../../common/Resources/ThemedComponents/ThemedCard"

const AllSheetDashBoard: React.FC = () => {
  const theme = useTheme()
  const [selectYear, setSelectYear] = useState<string>(
    moment(new Date()).format("YYYY")
  )
  const { data } = useQuery(GET_PM_SHEET, {
    variables: {
      year: selectYear
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12}>
          <ThemedCard
            sx={{
              background: theme.palette.secondary.main
            }}
          >
            <Grid container spacing={2}>
              <Grid item sm={5}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", padding: "10px" }}
                >
                  KPI Item : Die Breakdown จำนวนแม่พิมพ์ที่เสียหาย (
                  จากใบแจ้งซ่อม )
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    label="Select Year"
                    views={["year"]}
                    value={selectYear}
                    onChange={(newSelectDate) => {
                      setSelectYear(moment(newSelectDate).format("YYYY"))
                    }}
                    renderInput={(params) => <ThemedTextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={12} justifyContent="center">
                <GraphSheet data={data} />
              </Grid>
            </Grid>
          </ThemedCard>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default AllSheetDashBoard
