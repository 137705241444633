import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  tableCellClasses
} from "@mui/material"
import { ApproveSignature } from "./ApprovedSignature"
import { DataProductionPlan } from "../../ProductionPlanReport"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2,
    color: "black"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  border: "solid 1px black",
  "&:nth-of-type(odd)": {
    border: "solid 1px black"
  },

  "&:last-child td, &:last-child th": {
    border: "solid 1px black"
  }
}))

interface IApproveSignatureProps {
  status: boolean
  setStatus: (status: boolean) => void
  line: string
  revision: string
  date: string
  factory: string
  masterPlan: DataProductionPlan[]
  productionPlanReportByRevision: DataProductionPlan[]
}

export const ApprovePlanning: React.FC<IApproveSignatureProps> = (
  props: IApproveSignatureProps
) => {
  const {
    status,
    setStatus,
    line,
    revision,
    date,
    factory,
    masterPlan,
    productionPlanReportByRevision = []
  } = props
  return (
    <Table
      style={{
        height: "70px"
      }}
    >
      <TableBody>
        <StyledTableRow>
          <StyledTableCell colSpan={3} align="center">
            PLANNING
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{
              height: "30px"
            }}
          >
            {masterPlan[0]?.employee}
          </StyledTableCell>
          <StyledTableCell>
            {factory === "2" ? "มนตรี" : "ณัฏฐ์"}
          </StyledTableCell>
          <StyledTableCell>
            {productionPlanReportByRevision.length ? (
              <ApproveSignature
                status={status}
                setStatus={setStatus}
                line={line}
                revision={revision}
                date={date}
              />
            ) : (
              <></>
            )}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell>ISSUED</StyledTableCell>
          <StyledTableCell>Checked</StyledTableCell>
          <StyledTableCell>Approved</StyledTableCell>
        </StyledTableRow>
      </TableBody>
    </Table>
  )
}
