import { gql } from "@apollo/client"

export const CREATE_APPROVE_EDIT = gql`
  mutation CreateApproveEdit($dataCreateApproveEdit: ICreateApproveEdit) {
    createApproveEdit(dataCreateApproveEdit: $dataCreateApproveEdit)
  }
`
export const CREATE_PROBLEM_NAME = gql`
  mutation CreateProblemName($problemName: String) {
    createProblemName(problemName: $problemName) {
      problem_name
    }
  }
`
export const DELETE_PROBLEM_NAME = gql`
  mutation DeleteProblemName($problemName: String) {
    deleteProblemName(problemName: $problemName) {
      problemName
    }
  }
`
