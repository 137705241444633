import { useLazyQuery, useQuery } from "@apollo/client"
import { LocalizationProvider, TimePicker } from "@mui/lab"
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch
} from "@mui/material"
import { useMemo, useState } from "react"
import DateAdapter from "@mui/lab/AdapterMoment"
import { GET_MACHINES, GET_PARTS } from "../../../common/Query/MasterQuery"
import { GET_PART_PROCESS } from "../../../common/Query/ScannerQuery"
import { DataProductionPlan } from "../ProductionPlanReport"
import { calculateTime, diff } from "../ProductionPlan"
import moment from "moment"
import swal from "sweetalert"
import { filter } from "../../../common/utilities"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"

const AddTableRow = (props: {
  plan: DataProductionPlan[][]
  setProductionPlanData: (data: DataProductionPlan[][]) => void
  setAddPlans: (dataPlan: boolean) => void
}): JSX.Element => {
  const { plan: masterPlan, setProductionPlanData } = props
  const plan = props.plan[0][0]
  const [productionPlanJobNo] = useState<string>(plan.planNo ? plan.planNo : "")
  const [revision] = useState<string>(plan.revision ? plan.revision : "")
  const [planDate] = useState<string>(plan.planDate ? plan.planDate : "")
  const [pdPlan, setPdPlan] = useState<string>("")
  const [factory] = useState<string>(plan.factory ? plan.factory : "")
  const [shift] = useState<string>(plan.shift ? plan.shift : "")
  const [partNo, setPartNo] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [select, setSelect] = useState<boolean>(false)
  const [selectTime, setSelectTime] = useState<boolean>(false)
  const [id, setId] = useState<number>(plan.id + 1)
  const [customer, setCustomer] = useState<string>("")
  const [line] = useState<string>(plan.line ? plan.line : "")
  const [machine, setMachine] = useState<string>("")
  const [step, setStep] = useState<string>("")
  const [process, setProcess] = useState<string>("")
  const [section] = useState<string>(plan.section ? plan.section : "")
  const [jobPerHour, setJobPerHour] = useState<number>(0)
  const [startTime, setStartTime] = useState<number>(3600000)
  const [endTime, setEndTime] = useState<number>(36000000)
  const [startTimeOT, setStartTimeOT] = useState<number>(36000000)
  const [endTimeOT, setEndTimeOT] = useState<number>(46800000)
  const [hour, setWorkTime] = useState<number>(0)
  const [hourOT, setWorkTimeOT] = useState<number>(0)
  const [totalHour, setTotalHour] = useState<number>(0)
  const [totalQuantity, setTotalPlan] = useState<number>(0)
  const [employee, setEmployee] = useState<string>()
  const [plApprove, setPlanningApprove] = useState<string>()
  const [pdApprove, setProductApprove] = useState<string>()
  const [engApprove, setEngineerApprove] = useState<string>()
  const [qaApprove, setQaApprove] = useState<string>()
  const [mtApprove, setMaintenanceApprove] = useState<string>()
  const [remarks, setRemarks] = useState<string>("")
  const [partProcessing, setPartProcessing] = useState([])
  const [machineData, setMachineData] = useState([])
  const getPart = useQuery(GET_PARTS)
  const getMachine = useQuery(GET_MACHINES)
  const [getPartProcess] = useLazyQuery(GET_PART_PROCESS)
  const [tryOut, setTryOut] = useState<boolean>(false)

  const partNoList: string[] = getPart.data
    ? getPart.data.parts.map((part: { partNo: string }) => part.partNo)
    : []

  const machineList = machineData
    .filter((machine: { priority: string }) => machine.priority === "D")
    .map((machines: { machine: string }) => machines.machine)
  const isComplete = () => {
    if (tryOut) {
      return (
        factory !== "" &&
        partNo !== "" &&
        employee !== "" &&
        machine !== "" &&
        section !== "" &&
        line !== "" &&
        step !== "" &&
        process !== ""
      )
    }
    return (
      pdPlan && partNo && machine && step && process && jobPerHour && employee
    )
  }

  const onReset = () => {
    setPdPlan("")
    setPartNo("")
    setMachine("")
    setStep("")
    setJobPerHour(0)
    setProcess("")
  }

  const onSubmit = async () => {
    if (!isComplete())
      return swal("Warning", "กรุณากรอกข้อมูล ให้ครบถ้วน", "warning")
    const data: DataProductionPlan[] = [
      {
        id: id,
        planNo: productionPlanJobNo,
        revision,
        planDate,
        pdPlan,
        factory,
        shift,
        partNo,
        partName,
        customer,
        line,
        section,
        step,
        process,
        machine,
        jph: jobPerHour,
        startTime: selectTime ? moment(startTime).format("HH:mm") : null,
        endTime: selectTime ? moment(endTime).format("HH:mm") : null,
        totalHour: totalHour.toString(),
        totalQuantity,
        startTimeOT: select ? moment(startTimeOT).format("HH:mm") : null,
        endTimeOT: select ? moment(endTimeOT).format("HH:mm") : null,
        employee,
        plApprove,
        pdApprove,
        engApprove,
        qaApprove,
        mtApprove,
        remarks
      }
    ]
    setId(id ? id + 1 : 1)
    const newDataAddNew: DataProductionPlan[][] =
      pdPlan === "แผนหลัก"
        ? [masterPlan[0].concat(data), masterPlan[1]]
        : masterPlan[1]
        ? [masterPlan[0], masterPlan[1].concat(data)]
        : [masterPlan[0], data]
    onReset()
    swal("Success", "เพิ่มข้อมูลแล้ว", "success")
    setProductionPlanData(newDataAddNew)
  }

  useMemo(async () => {
    if (partNo) {
      setPartName(
        getPart.data && partNo
          ? getPart.data.parts.find(
              (part: { partNo: string }) => part.partNo === partNo
            )?.partName || "-"
          : ""
      )

      setCustomer(
        getPart.data && partNo
          ? getPart.data.parts.find(
              (part: { partNo: string }) => part.partNo === partNo
            )?.customer || "-"
          : ""
      )

      if (!selectTime) {
        setWorkTime(0)
      } else {
        if (
          Number(moment(endTime).format("HH")) > 12 &&
          Number(moment(startTime).format("HH")) < 13
        ) {
          const time = diff(startTime, endTime - 60 * 60 * 1000)
          setWorkTime(Number(calculateTime(time)))
        } else {
          const time = diff(startTime, endTime)
          setWorkTime(Number(calculateTime(time)))
        }
      }
      if (!select) {
        setWorkTimeOT(0)
      } else {
        const time = diff(startTimeOT, endTimeOT)
        setWorkTimeOT(Number(calculateTime(time)))
      }

      const partProcessing = await getPartProcess({
        variables: {
          partNo
        }
      })

      setMachineData(
        getMachine.data && factory && line
          ? getMachine.data.machines.filter(
              (machine: { line: string; factory: string }) =>
                machine.line === line && machine.factory === factory
            )
          : []
      )
      setPartProcessing(partProcessing.data.partProcesses)

      if (step && line && partProcessing.data) {
        const jph = partProcessing.data.partProcesses.find(
          (process: { line: string; processOrder: string }) =>
            process.line === line && process.processOrder === step
        )
        if (jph) {
          setJobPerHour(jph.jph)
        }
      }
      setTotalHour(hour + hourOT)
      setTotalPlan(jobPerHour * (hour + hourOT))
      if (factory === "2") {
        setPlanningApprove("ณัฏฐ์")
        setProductApprove("อนุชา")
        setEngineerApprove("ฐานพงศ์")
        setQaApprove("ไชยศ")
        setMaintenanceApprove("วิรัช")
      } else if (factory === "1") {
        setPlanningApprove("ณัฏฐ์")
        setProductApprove("ธวัฒชัย")
        setEngineerApprove("ปิยะพงษ์")
        setQaApprove("เสน่ห์")
        setMaintenanceApprove("มานิต")
      }
    }
  }, [
    endTime,
    endTimeOT,
    factory,
    getMachine.data,
    getPart.data,
    getPartProcess,
    hour,
    hourOT,
    jobPerHour,
    line,
    partNo,
    select,
    selectTime,
    startTime,
    startTimeOT,
    step
  ])

  const stepList = partProcessing
    .filter((process: { line: string }) => process.line !== "QC")
    .map((process: { processOrder: string }) => process.processOrder)

  const processList = partProcessing.map(
    (process: { process: string }) => process.process
  )

  partProcessing.find(
    (process: { line: string; processOrder: string }) =>
      process.line !== "QC" && process.processOrder === step
  )
  const recorder1 = ["มานพ", "วรารัตน์", "อรทัย"]
  const recorder2 = ["มนตรี", "อมราวดี"]

  return (
    <>
      <Box className="Form" width={"98%"}>
        <Grid container spacing={2}>
          <Grid item xs={10} sm={5}>
            <Autocomplete
              fullWidth
              disablePortal
              value={pdPlan}
              onChange={(e, newValue) => {
                setPdPlan(newValue ? newValue : "")
              }}
              renderInput={(params) => (
                <ThemedTextField {...params} label="Select Plan" />
              )}
              options={["แผนหลัก", "แผนสำรอง"]}
            />
          </Grid>
          <Grid item xs={2} sm={1}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setTryOut(e.target.checked)
                    e.target.checked ? setRemarks("Try Out") : setRemarks("")
                  }}
                />
              }
              label="Try Out"
            />
          </Grid>
          <Grid item sm={6} />

          <Grid item xs={12} sm={6}>
            <Autocomplete
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                const { inputValue } = params
                if (inputValue !== "") {
                  filtered.push(inputValue)
                }
                return filtered
              }}
              freeSolo
              fullWidth
              value={partNo}
              disablePortal
              onChange={(e, newValue) => setPartNo(newValue ? newValue : "")}
              renderInput={(params) => (
                <ThemedTextField {...params} label="Select Part No" />
              )}
              options={partNoList}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                const { inputValue } = params
                if (inputValue !== "") {
                  filtered.push(inputValue)
                }
                return filtered
              }}
              freeSolo
              fullWidth
              className={!tryOut ? "disable-back-ground-color" : ""}
              disabled={!tryOut}
              disablePortal
              value={customer}
              onChange={(e, newValue) => {
                setCustomer(newValue as string)
              }}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  value={customer}
                  label="Select Customer"
                />
              )}
              options={["-"]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              value={machine}
              disablePortal
              className={
                !line || !factory ? "disable-back-ground-color" : undefined
              }
              onChange={(e, newValue) => setMachine(newValue ? newValue : "")}
              renderInput={(params) => (
                <ThemedTextField {...params} label="Select Machine" />
              )}
              options={machineList}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                const { inputValue } = params
                if (inputValue !== "") {
                  filtered.push(inputValue)
                }
                return filtered
              }}
              freeSolo
              fullWidth
              value={step}
              disablePortal
              disabled={!partNo}
              className={!partNo ? "disable-back-ground-color" : undefined}
              onChange={(e, newValue) => setStep(newValue ? newValue : "")}
              renderInput={(params) => (
                <ThemedTextField {...params} label="Select Step" />
              )}
              options={stepList.length > 0 ? stepList : ["-"]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                const { inputValue } = params
                if (inputValue !== "") {
                  filtered.push(inputValue)
                }
                return filtered
              }}
              freeSolo
              fullWidth
              value={process}
              onChange={(e, newValue) => setProcess(newValue ? newValue : "")}
              disablePortal
              disabled={!partNo}
              className={!partNo ? "disable-back-ground-color" : undefined}
              renderInput={(params) => (
                <ThemedTextField {...params} label="Select Process" />
              )}
              options={processList.length > 0 ? processList : ["-"]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ThemedTextField
              // disabled
              label="Jobs Per Hour"
              onWheel={(event) =>
                event.currentTarget.querySelector("input")?.blur()
              }
              onChange={(e) => setJobPerHour(+e.target.value)}
              value={jobPerHour}
              fullWidth
              type={"number"}
            />
          </Grid>
          <Grid item sm={1}>
            <label style={{ fontSize: "20px" }}>Select Time</label>
          </Grid>
          <Grid item sm={1}>
            <Switch
              checked={selectTime}
              onChange={(event) =>
                setSelectTime(event.target.value ? !selectTime : selectTime)
              }
              name="gilad"
            />
          </Grid>
          <Grid item sm={2}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <TimePicker
                label="Start Time"
                ampm={false}
                disabled={!selectTime}
                views={["hours", "minutes"]}
                inputFormat="HH:mm"
                value={startTime}
                onChange={(newSelectTime) => {
                  setStartTime(newSelectTime ? newSelectTime : 0)
                }}
                renderInput={(params) => (
                  <ThemedTextField {...params} fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item sm={2}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <TimePicker
                label="End Time"
                ampm={false}
                disabled={!selectTime}
                views={["hours", "minutes"]}
                inputFormat="HH:mm"
                value={endTime}
                onChange={(newSelectTime) => {
                  setEndTime(newSelectTime ? newSelectTime : 0)
                }}
                renderInput={(params) => (
                  <ThemedTextField {...params} fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={1}>
            <label style={{ fontSize: "20px" }}>Select Time (OT)</label>
          </Grid>
          <Grid item sm={1}>
            <Switch
              checked={select}
              onChange={(event) =>
                setSelect(event.target.value ? !select : select)
              }
              name="gilad"
            />
          </Grid>
          <Grid item sm={2}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <TimePicker
                label="Start Time TO"
                ampm={false}
                disabled={!select}
                views={["hours", "minutes"]}
                inputFormat="HH:mm"
                value={startTimeOT}
                onChange={(newSelectTime) => {
                  setStartTimeOT(newSelectTime ? newSelectTime : 0)
                }}
                renderInput={(params) => (
                  <ThemedTextField {...params} fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item sm={2}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <TimePicker
                label="End Time TO"
                ampm={false}
                disabled={!select}
                views={["hours", "minutes"]}
                inputFormat="HH:mm"
                value={endTimeOT}
                onChange={(newSelectTime) => {
                  setEndTimeOT(newSelectTime ? newSelectTime : 0)
                }}
                renderInput={(params) => (
                  <ThemedTextField {...params} fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <ThemedTextField
              label="Total Hours (Hrs.)"
              fullWidth
              id="totalTime"
              type="number"
              onWheel={(event) =>
                event.currentTarget.querySelector("input")?.blur()
              }
              disabled
              className="disable-back-ground-color"
              value={totalHour}
            />
          </Grid>

          <Grid item sm={6}>
            <ThemedTextField
              fullWidth
              label="Total Plan (Quantity)"
              id="totalQuantity"
              type="number"
              onWheel={(event) =>
                event.currentTarget.querySelector("input")?.blur()
              }
              onChange={(e) => setTotalPlan(+e.target.value)}
              value={totalQuantity}
            />
          </Grid>

          <Grid item sm={6}>
            <Autocomplete
              fullWidth
              onChange={(e, newValue) => setEmployee(newValue ? newValue : "")}
              disablePortal
              disabled={!factory}
              className={!factory ? "disable-back-ground-color" : undefined}
              renderInput={(params) => (
                <ThemedTextField {...params} label={"Select Employee"} />
              )}
              options={
                factory === "1" ? recorder1 : factory === "2" ? recorder2 : []
              }
            />
          </Grid>

          <Grid item sm={6}>
            <ThemedTextField
              label="Remarks"
              fullWidth
              id="remarks"
              value={remarks}
              type="text"
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Grid>
          <Grid item sm={12}>
            <Button variant="contained" onClick={onSubmit}>
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default AddTableRow
