import {
  Grid,
  Autocomplete,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  styled,
  CircularProgress,
  useTheme,
  Box,
  CardHeader,
  ThemeProvider,
  tableCellClasses
} from "@mui/material"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { useState } from "react"
import { useQuery } from "@apollo/client"
import { GET_SPARE_PARTS } from "../../common/Query/MaintenanceQuery"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import { AddNewSparePart } from "./component/AddSparePart"
import EditSparePart from "./component/EditSparePart"
import { TabPanel, a11yProps } from "../Master/component/MachineDialogReport"
import SparePartInOurOut from "./SparePartInOrOut"
import SparePartStockCard from "./SparePartStockCard"
import { getImagePng } from "../../common/utilities"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"

const pageTitle = "Spare Part"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },

  "td, th": {
    border: 0
  }
}))
interface ISparePart {
  code: string
  partName: string
  model: string
  brand: string
  min: number
  max: number
  location: string
  factory: string
}
const RepairDocument: React.FC = () => {
  const { loading, error, data } = useQuery(GET_SPARE_PARTS, {})
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [value, setValue] = useState<number>(0)
  const [search, setSearch] = useState<string>("")
  const [openEditSparePart, setOpenEditSparePart] = useState<boolean>(false)
  const [openAddSparePart, setOpenAddSparePart] = useState<boolean>(false)
  const theme = useTheme()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  if (loading)
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ padding: "10px" }}
      >
        <CircularProgress />
      </Grid>
    )
  if (error) return <p>Error: {error}</p>

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const printDiv = (id: string) => {
    const printContents = document.getElementById(id)?.innerHTML
    const originalContents = document.body.innerHTML
    document.body.innerHTML =
      "<html><head><title></title></head><body>" + printContents + "</body>"
    window.print()
    document.body.innerHTML = originalContents
    location.reload()
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows: number =
    rowsPerPage -
    Math.min(rowsPerPage, data.sparePartsList.length - page * rowsPerPage)

  const sparePartListSearch =
    data?.sparePartsList?.map((spareParts: ISparePart) => spareParts.code) || []

  const machineList = data?.sparePartsList
    .filter((sparePart: ISparePart) => {
      if (search) return sparePart.code === search
      else return sparePart
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((sparePart: ISparePart, i: number) => {
      return (
        <StyledTableRow key={i}>
          <StyledTableCell align="left">{sparePart.code}</StyledTableCell>
          <StyledTableCell align="left">{sparePart.partName}</StyledTableCell>
          <StyledTableCell align="left">{sparePart.model}</StyledTableCell>
          <StyledTableCell align="left">{sparePart.brand}</StyledTableCell>
          <StyledTableCell align="left">{sparePart.min}</StyledTableCell>
          <StyledTableCell align="left">{sparePart.max}</StyledTableCell>
          <StyledTableCell align="left">{sparePart.location}</StyledTableCell>
          <StyledTableCell align="left">{sparePart.factory}</StyledTableCell>
          <StyledTableCell align="left">
            <img
              src={getImagePng(`images/sparePart/${sparePart.code}`)}
              width={100}
              height={100}
              alt=""
            ></img>
          </StyledTableCell>
        </StyledTableRow>
      )
    })

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Maintenance}
        menuItemName={Object.keys(menuItemList)[12]}
        menuPageName={pageTitle}
      />
      <>
        <ThemedCard>
          <CardHeader
            titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
            title="Spare Part "
            subheaderTypographyProps={{ fontFamily: "Sarabun" }}
            subheader="Add incoming Spare Part  to supply"
          />
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="SparePart" {...a11yProps(0)} />
                <Tab label="SparePart InOrOut" {...a11yProps(1)} />
                <Tab label="SparePart StockCard" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <>
                <AddNewSparePart
                  setOpenAddSparePart={setOpenAddSparePart}
                  openAddSparePart={openAddSparePart}
                />
                <EditSparePart
                  dialogOpen={openEditSparePart}
                  setDialogOpen={setOpenEditSparePart}
                />

                <TableContainer component={Paper} style={{ marginTop: "20px" }}>
                  <div id="sparePartList">
                    <CardHeader
                      titleTypographyProps={{
                        variant: "h4",
                        fontFamily: "Sarabun"
                      }}
                      title="Spare Part List"
                      subheaderTypographyProps={{ fontFamily: "Sarabun" }}
                      subheader="Add incoming Spare Part  to supply"
                    />
                    <Grid container spacing={2} width={"98%"} padding={2}>
                      <Grid item xs={5} sm={4}>
                        <Autocomplete
                          id="search"
                          freeSolo
                          value={search}
                          options={sparePartListSearch}
                          style={{ width: "100%" }}
                          sx={{ width: 100 }}
                          onChange={(e, newValue) =>
                            setSearch(newValue ? newValue : "")
                          }
                          renderInput={(params) => (
                            <ThemedTextField
                              style={{ marginLeft: "15px" }}
                              {...params}
                              label="Search"
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        container
                        justifyContent="flex-end"
                      >
                        <ThemedButton
                          style={{
                            whiteSpace: "nowrap",
                            width: "180px",
                            height: "40px",
                            top: "8px",
                            marginRight: "20px",
                            backgroundColor: theme.palette.primary.main
                          }}
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => setOpenAddSparePart(true)}
                        >
                          ADD NEW SPARE PART
                        </ThemedButton>
                        <ThemedButton
                          style={{
                            whiteSpace: "nowrap",
                            width: "180px",
                            height: "40px",
                            top: "8px",
                            marginRight: "20px",
                            backgroundColor: theme.palette.warning.main
                          }}
                          variant="contained"
                          startIcon={<EditIcon />}
                          onClick={() => setOpenEditSparePart(true)}
                        >
                          EDIT SPARE PART
                        </ThemedButton>
                        <ThemedButton
                          style={{
                            whiteSpace: "nowrap",
                            width: "180px",
                            height: "40px",
                            top: "8px",
                            marginRight: "20px",
                            backgroundColor: "green"
                          }}
                          variant="contained"
                          onClick={() => printDiv("sparePartList")}
                        >
                          Print
                        </ThemedButton>
                      </Grid>
                    </Grid>
                    <Table
                      sx={{ minWidth: 700 }}
                      aria-label="customized table"
                      className=" zui-table-highlight print-sm-order"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Code</StyledTableCell>
                          <StyledTableCell>Part Name</StyledTableCell>
                          <StyledTableCell>Model</StyledTableCell>
                          <StyledTableCell>Brand</StyledTableCell>
                          <StyledTableCell>Min</StyledTableCell>
                          <StyledTableCell>Max</StyledTableCell>
                          <StyledTableCell>Location</StyledTableCell>
                          <StyledTableCell>Factory</StyledTableCell>
                          <StyledTableCell>Picture</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {machineList}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>

                  <TablePagination
                    rowsPerPageOptions={[5, 20, data.sparePartsList.length]}
                    component="div"
                    count={
                      data.sparePartsList.filter((sparePart: ISparePart) => {
                        if (search) return sparePart.code === search
                        else return sparePart
                      }).length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <>
                <SparePartInOurOut />
              </>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <>
                <SparePartStockCard />
              </>
            </TabPanel>
          </Box>
        </ThemedCard>
      </>
    </ThemeProvider>
  )
}
export default RepairDocument
