import React, { ReactElement, useState } from "react"
import {
  Grid,
  Autocomplete,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  useTheme,
  ThemeProvider
} from "@mui/material"
import { useQuery } from "@apollo/client"
import { GET_FACTORY } from "../../../common/Query/engineerQuery"
import { PageLabel } from "../../../common/Resources/PageLabel"
import { PageLabelCard } from "../../../common/Resources/PageLabelCard"
import { menuItemList } from "../../../common/Resources/menuItemList"
import ThemedCard from "../../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import { GET_PARTS } from "../../../common/Query/MasterQuery"

interface DataFactory {
  partNo: string
  process: string
  quantity: string
  minStroke: string
  needMaintenance: string
  factory: string
}
interface IProps {
  factory: string
}

const Maintenance: React.FC<IProps> = (props: IProps): ReactElement => {
  const { factory } = props
  const [status, setStatus] = useState<string | null>(null)
  const [partNo, setPartNo] = useState<string | null>(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const getPart = useQuery(GET_PARTS)

  let partNoList: Array<string> = []
  partNoList = getPart.data
    ? getPart.data.parts.map((e: { partNo: string }) => e.partNo)
    : []

  const { data } = useQuery(GET_FACTORY, {
    variables: {
      factory: factory
    }
  })

  const statusList: Array<string> = ["❌", "✔️", "⚠"]
  let rows: DataFactory[] = data ? data.factory : []

  if (status && data) {
    const newData = rows.filter(
      (val: { needMaintenance: string }) => val.needMaintenance === status
    )
    rows = newData
  }

  if (partNo && data) {
    const newData = rows.filter(
      (val: { partNo: string }) => val.partNo === partNo
    )
    rows = newData
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  interface Column {
    id:
      | "partNo"
      | "process"
      | "quantity"
      | "minStroke"
      | "needMaintenance"
      | "factory"
    label: string
    minWidth?: number
    align?: "right"
    format?: (value: number) => string
  }

  const columns: Column[] = [
    { id: "partNo", label: "Part No.", minWidth: 100 },
    { id: "process", label: "Process", minWidth: 100 },
    { id: "quantity", label: "Quantity", minWidth: 100 },
    { id: "minStroke", label: "Min Stroke", minWidth: 100 },
    { id: "needMaintenance", label: "Need Maintenance", minWidth: 100 },
    { id: "factory", label: "Factory", minWidth: 100 }
  ]

  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Engineer}
        menuItemName={Object.keys(menuItemList)[5]}
        menuPageName={`Factory ${factory}`}
      />
      <ThemedCard>
        <PageLabelCard
          title={`Factory ${factory}`}
          subTitle={`detail about Factory ${factory}`}
        />

        <Grid container spacing={2} width={"98%"} margin={2}>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="status"
              value={status}
              defaultValue={""}
              options={statusList}
              style={{ width: "100%" }}
              sx={{ width: 100 }}
              onChange={(e, newValue) => setStatus(newValue)}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Select Status"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="partNo"
              value={partNo}
              loading={partNoList.length === 0}
              defaultValue={""}
              options={partNoList}
              style={{ width: "100%" }}
              sx={{ width: 100 }}
              onChange={(e, newValue) => setPartNo(newValue)}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Part No."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ marginTop: "20px" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      backgroundColor: theme.palette.warning.light
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ backgroundColor: theme.palette.warning.light }}
        />
      </ThemedCard>
    </ThemeProvider>
  )
}

export default Maintenance
