import { Box, Divider, Grid, Typography, useTheme } from "@mui/material"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DateAdapter from "@mui/lab/AdapterMoment"
import { DatePicker } from "@mui/lab"
import { useMemo, useRef, useState } from "react"
import moment from "moment"
import ThemedTextField from "../../../../../common/Resources/ThemedComponents/ThemedTextField"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"
import { GET_INFORMANT_PM } from "../../../../../common/Query/engineerQuery"
import AutocompleteFactory from "../../../../../common/Resources/AutocompleteFactory"
import { useQuery } from "@apollo/client"
import { IRepairInformant } from "../../../PMDocument"
import { ThemeProvider } from "@mui/styles"
import ThemedCard from "../../../../../common/Resources/ThemedComponents/ThemedCard"

const Status: React.FC = () => {
  const [dateFrom, setDateFrom] = useState<Date | null>(new Date())
  const [factory, setFactory] = useState<string>("2")
  const { data } = useQuery(GET_INFORMANT_PM, {
    variables: {
      date: moment(dateFrom).format("YYYY-MM"),
      factory: factory
    },
    fetchPolicy: "no-cache"
  })
  const balance = useMemo(() => {
    if (data) {
      return data.getPMHistory.filter(
        (item: { leaderQC: string }) => !item.leaderQC
      ).length
    }
    return 0
  }, [data])
  const waitingToClose = useMemo(() => {
    if (data) {
      return data.getPMHistory.filter(
        (item: IRepairInformant) =>
          item.leaderQC && (!item.leaderProduction || !item.leaderTooling)
      ).length
    }
    return 0
  }, [data])
  const close = useMemo(() => {
    if (data) {
      return data.getPMHistory.filter(
        (item: IRepairInformant) => item.leaderProduction && item.leaderTooling
      ).length
    }
    return 0
  }, [data])
  const totalDoc = useMemo(() => {
    if (data) {
      return data.getPMHistory.length
    }
    return 0
  }, [data])

  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <ThemedCard
        sx={{ background: theme.palette.secondary.main, minHeight: "507px" }}
      >
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", padding: "10px" }}
            >
              Repair Status
            </Typography>
          </Grid>
          <Grid item container spacing={1} sm={8} padding={1}>
            <Grid item sm={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="Select Month"
                  views={["year", "month"]}
                  inputFormat="MM/yyyy"
                  value={dateFrom}
                  onChange={(newSelectDate) => {
                    setDateFrom(moment(newSelectDate).toDate() as Date)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      fullWidth
                      sx={{
                        "& .Mui-selected": {
                          backgroundColor: theme.palette.warning.main
                        }
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={6}>
              <AutocompleteFactory setFactory={setFactory} factory={factory} />
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={12}>
            <Grid item container spacing={2}>
              <Grid item sm={5}>
                <ChartStatusMonthly
                  balance={balance}
                  waitingToClose={waitingToClose}
                  close={close}
                />
              </Grid>
              <Grid item sm={7} mt={5}>
                <Grid container spacing={2}>
                  <Grid item sm={4} sx={{ fontWeight: "bold" }}>
                    Repair Status
                  </Grid>
                  <Grid item sm={4} sx={{ fontWeight: "bold" }}>
                    Quantity
                  </Grid>
                  <Grid item sm={4} sx={{ fontWeight: "bold" }}>
                    Value
                  </Grid>

                  <Grid item sm={12} sx={{ fontWeight: "bold" }}>
                    <Divider />
                  </Grid>
                  <Grid item sm={4} sx={{ display: "inline-flex;" }}>
                    <Box
                      sx={{
                        backgroundColor: "#3BB731",
                        width: "30px",
                        height: "20px",
                        mr: 1
                      }}
                    />
                    เสร็จสิ้น
                  </Grid>
                  <Grid item sm={4}>
                    {close}
                  </Grid>
                  <Grid item sm={4}>
                    {((close / totalDoc) * 100).toFixed(2)} %
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                  <Grid item sm={4} sx={{ display: "inline-flex;" }}>
                    <Box
                      sx={{
                        backgroundColor: "#FFCA3A",
                        width: "30px",
                        height: "20px",
                        mr: 1
                      }}
                    />
                    รอส่งมอบ
                  </Grid>
                  <Grid item sm={4}>
                    {waitingToClose}
                  </Grid>
                  <Grid item sm={4}>
                    {((waitingToClose / totalDoc) * 100).toFixed(2)} %
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                  <Grid item sm={4} sx={{ display: "inline-flex;" }}>
                    <Box
                      sx={{
                        backgroundColor: "#FE6A35",
                        width: "30px",
                        height: "20px",
                        mr: 1
                      }}
                    />
                    อยู่ระหว่างซ่อม
                  </Grid>
                  <Grid item sm={4}>
                    {balance}
                  </Grid>
                  <Grid item sm={4}>
                    {((balance / totalDoc) * 100).toFixed(2)} %
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                  <Grid item sm={4}>
                    Total
                  </Grid>
                  <Grid item sm={4}>
                    {totalDoc}
                  </Grid>
                  <Grid item sm={4}>
                    {100} %
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}
const ChartStatusMonthly = (props: {
  chart?: HighchartsReact.Props
  balance: number
  waitingToClose: number
  close: number
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const { balance, waitingToClose, close } = props
  const theme = useTheme()
  const options: Highcharts.Options = {
    chart: {
      type: "pie",
      backgroundColor: theme.palette.secondary.main,
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0
      }
    },
    title: {
      text: "",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    credits: { enabled: false },
    accessibility: {
      point: {
        valueSuffix: "%"
      }
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        depth: 35,
        dataLabels: {
          enabled: true,
          format: "{point.name}"
        }
      }
    },
    series: [
      {
        type: "pie",
        name: "จำนวน",
        data: [
          {
            name: "",
            y: balance,
            color: "#FE6A35"
          },
          {
            name: "",
            y: close,
            color: "#3BB731"
          },
          {
            name: "",
            y: waitingToClose,
            color: "#FFCA3A"
          }
        ],
        size: "90%",
        dataLabels: {
          formatter: function () {
            return this.y ? this.point.name : null
          },
          distance: -30
        }
      }
    ],
    exporting: {
      enabled: false
    }
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props.chart}
    />
  )
}
export default Status
