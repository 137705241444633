import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  styled,
  tableCellClasses
} from "@mui/material"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { SetStateAction, useEffect, useState } from "react"
import swal from "sweetalert"
import QrReader from "react-qr-reader"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { useTheme } from "@emotion/react"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { useMutation, useQuery } from "@apollo/client"
import { CREATE_SCANNER_IOT, GET_PLAN_WITH_PART } from "../../common/Query/Iot"
import moment from "moment"
import DatePickerCustom from "../../common/Resources/DatePickerCustom"

interface IPlanData {
  partNo: string
  machine: string
  process: string
  startTime: string
  endTime: string
  startTimeOT: string
  endTimeOT: string
  quantity: number
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center",
    border: "1px solid black"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun",
    textAlign: "center",
    border: "1px solid black"
  }
}))

const iotScanner = (): JSX.Element => {
  const [jobOrder, setJobOrder] = useState<string>("")
  const [dataScan, setDataScan] = useState<string>("")
  const [dateSelect, setDateSelect] = useState<Date>(new Date())
  const [isStream, setIsStream] = useState<boolean>(false)
  const [partNo, setPartNo] = useState<string>("")
  const [PlanDate, setPlanDate] = useState<IPlanData[]>([])
  const [selected, setSelected] = useState<IPlanData | null>(null)
  const theme = useTheme()

  const { data } = useQuery(GET_PLAN_WITH_PART, {
    variables: {
      partNo: partNo,
      date: dateSelect
    }
  })
  const [createScannerIot, { loading }] = useMutation(CREATE_SCANNER_IOT, {
    variables: {
      data: {
        machine: selected?.machine,
        process: selected?.process,
        jobOrder: jobOrder,
        startTime: selected?.startTime
          ? selected?.startTime
          : selected?.startTimeOT,
        endTime: selected?.endTimeOT ? selected?.endTimeOT : selected?.endTime,
        startDate: moment(dateSelect).format("YYYY-MM-DD"),
        endDate: moment(dateSelect).format("YYYY-MM-DD"),
        partNo: partNo,
        createdDate: `${moment(new Date()).format("YYYY-MM-DD")}T${
          selected?.startTime ? selected?.startTime : selected?.startTimeOT
        }:05.000Z`
      }
    }
  })

  const handleSubmit = async () => {
    try {
      if (selected) {
        await createScannerIot()
        swal("Success", "Create Scanner Iot Success", "success")
      } else {
        swal("Error", "Please Select Process and Machine", "warning")
      }
    } catch (err) {
      swal("Error", (err as Error).message, "warning")
    }
  }
  const handleScan = (data: SetStateAction<string> | null): void => {
    if (data) {
      setDataScan(data)
      if (dataScan) {
        breakQRCode()
      }
    }
  }

  const breakQRCode = async (e?: { preventDefault: () => void }) => {
    e?.preventDefault()
    const newSplit = dataScan.trim().split("|")
    if (newSplit.length !== 6) {
      swal("Error", "มีปัญหากับ format ของ QR Code!", "warning")
    }

    setJobOrder(`${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`)
    setPartNo(newSplit[4])
  }

  const selectRow = (data: IPlanData) => {
    if (selected) {
      setSelected(null)
    } else {
      setSelected(data)
    }
  }

  useEffect(() => {
    if (data) {
      if (data.getPlanWithPartNo) {
        setPlanDate(data.getPlanWithPartNo)
      }
    }
  }, [data])

  return (
    <>
      <ThemedCard>
        <Box component="form" onSubmit={breakQRCode}>
          <Accordion
            expanded={isStream}
            onChange={() => setIsStream(!isStream)}
            style={{
              marginBottom: "5px",
              marginTop: "10px"
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#ffffff", display: "none" }}
            />

            <AccordionDetails>
              {isStream ? (
                <>
                  <Grid container justifyContent={"center"}>
                    <Grid
                      item
                      sm={3}
                      xs={12}
                      container
                      justifyContent={"center"}
                    >
                      <QrReader
                        delay={300}
                        onError={(e) => {
                          swal("Error", e, "warning")
                        }}
                        onScan={handleScan}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </AccordionDetails>
          </Accordion>
          <PageLabelCard
            title="Scanner IOT"
            subTitle="detail about Scanner Iot"
          />
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Grid container spacing={2} width={"98%"} margin={2}>
                <Grid item xs={12} sm={12}>
                  <Switch
                    checked={isStream}
                    onChange={(event) =>
                      setIsStream(event.target.value ? !isStream : isStream)
                    }
                    name="gilad"
                  />
                  <label style={{ fontSize: "14px" }}>
                    Active Camera{" "}
                    <CameraAltIcon style={{ verticalAlign: "middle" }} />
                  </label>
                </Grid>
                <Grid item xs={9} sm={9}>
                  <TextField
                    type="text"
                    value={dataScan}
                    style={{ width: "100%", height: "36px" }}
                    onChange={(e) => {
                      setDataScan(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <DatePickerCustom
                    label="Date"
                    date={dateSelect}
                    setDate={(date) => setDateSelect(date)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} mt={2}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell colSpan={8}>
                          Part No: {partNo}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell>Process</StyledTableCell>
                        <StyledTableCell>Machine</StyledTableCell>
                        <StyledTableCell>Start Time</StyledTableCell>
                        <StyledTableCell>End Time</StyledTableCell>
                        <StyledTableCell>Start Time OT</StyledTableCell>
                        <StyledTableCell>End Time OT</StyledTableCell>
                        <StyledTableCell>Quantity</StyledTableCell>
                        <StyledTableCell>Select</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {PlanDate.map((item, index) => (
                        <TableRow key={index}>
                          <StyledTableCell>{item.process}</StyledTableCell>
                          <StyledTableCell>{item.machine}</StyledTableCell>
                          <StyledTableCell>{item.startTime}</StyledTableCell>
                          <StyledTableCell>{item.endTime}</StyledTableCell>
                          <StyledTableCell>{item.startTimeOT}</StyledTableCell>
                          <StyledTableCell>{item.endTimeOT}</StyledTableCell>
                          <StyledTableCell>{item.quantity}</StyledTableCell>
                          <StyledTableCell>
                            <Checkbox
                              onClick={() => selectRow(item)}
                              disabled={
                                !!selected &&
                                item.machine !== selected.machine &&
                                item.process !== selected.process
                              }
                              size="small"
                            />
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <Grid container margin={2} spacing={2} width={"98%"}>
                <Grid item sm={12} xs={12}>
                  <ThemedLoadingButton
                    variant="contained"
                    style={{
                      width: "86px",
                      height: "36px",
                      top: "9px"
                    }}
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    Submit
                  </ThemedLoadingButton>
                </Grid>
              </Grid>
              <Button type="submit"></Button>
            </Box>
          </ThemeProvider>
        </Box>
      </ThemedCard>
    </>
  )
}
export default iotScanner
