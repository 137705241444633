import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
  useTheme
} from "@mui/material"
import { bankImage, getImagePng, miniLogo } from "../../../common/utilities"
import { useEffect, useMemo, useRef, useState } from "react"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import { PMDocumentGrid } from "./RepairInformant"
import {
  GET_CUSTOMERS,
  GET_EMPLOYEE,
  GET_MACHINES,
  GET_PARTS
} from "../../../common/Query/MasterQuery"
import { useMutation, useQuery } from "@apollo/client"
import { makeCustomerList } from "../../Master/Parts"

import moment from "moment"
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import DeleteIcon from "@mui/icons-material/Delete"
import { IMachine } from "../../Master/Machine"
import FormTextField from "../../../common/Resources/ThemedComponents/Maintenance/FormTextField"
import { autocompleteRenderTags } from "../../../common/Resources/ThemedComponents/Other/autocompleteRenderTags"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { TRIAL_TOOLING_SHEET_CREATE } from "../../../common/Mutation/engineerMutation"
import swal from "sweetalert"
import { user } from "../../../common/MainApp"
import { UPLOAD_PART_IMAGE } from "../../../common/Mutation/MasterMutation"
import { GET_TRIAL_TOOLING_SHEET } from "../../../common/Query/engineerQuery"

interface ITooling {
  die: string
  size: string
  amount: number
  line: string
  machineCode: string
  [key: string]: number | string
}

const toolNameData: { key: string; name: string }[] = [
  { key: "newTool", name: "แม่พิมพ์ใหม่ NEW TOOLING" },
  { key: "modifyTool", name: "แม่พิมพ์แก้ไข MODIFY TOOLING" },
  { key: "supplyTool", name: "แม่พิมพ์จากลูกค้า SUPPLY TOOLING" },
  { key: "changeMachine", name: "เปลี่ยนเครื่องจักร CHANGE MACHINE" },
  { key: "changeOperator", name: "เปลี่ยนพนักงาน CHANGE OPERATOR" },
  { key: "changeMaterial", name: "เปลี่ยนวัตถุดิบ CHANGE MATERIAL" },
  {
    key: "changeSupplierMaterial",
    name: "เปลี่ยนผู้ส่งมอบวัตถุดิบ CHANGE SUPPLIER MATERIAL"
  },
  { key: "changePlant", name: "เปลี่ยนสถานที่ CHANGE PLANT" }
]

const division = {
  eng: "ENG",
  pd1: "PD1",
  pd2: "PD2",
  pd: "PD",
  pt: "PT",
  qa: "QA",
  mt: "MT"
}

export const signatureMap: Record<string, string> = {
  eNGSignature: "ENG",
  pD1Signature: "PD1",
  pD2Signature: "PD2",
  pDSignature: "PD",
  pTSignature: "PT",
  qASignature: "QA",
  mTSignature: "MT"
}

const StyledTableRow = styled(TableRow)(() => ({
  "td, th": {
    backgroundColor: "white",
    color: "black",
    padding: "5px"
  }
}))

const StyledTableCell = styled(TableCell)(() => ({
  blackGroundColor: "white",
  color: "black",
  textAlign: "center",
  padding: 0,
  fontSize: "16px",
  border: "solid 1px black"
}))

export const TrialToolingPaper: React.FC = () => {
  const theme = useTheme()
  const [error, setError] = useState<boolean>(false)
  const [partName, setPartName] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [trialDate, setTrialDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [controlNo, setControlNo] = useState<string>("")

  const [targetTrialEvent, setTargetTrialEvent] = useState<string>("")
  const [cycleTime, setCycleTime] = useState<number>()
  const [capacity, setCapacity] = useState<number>()
  const [internalDefect, setInternalDefect] = useState<number>()
  const [operatorSkill, setOperatorSkill] = useState<number>()
  const [cpk, setCpk] = useState<number>()
  const [reworkRatio, setReworkRatio] = useState<number>()

  const [selectedTools, setSelectedTools] = useState<Record<string, string>>({})

  const { data: getTrialToolingData } = useQuery(GET_TRIAL_TOOLING_SHEET)
  const { data: getParts } = useQuery(GET_PARTS)
  let partNameList: string[] = []
  let partNoList: string[] = []
  if (getParts && getTrialToolingData) {
    partNoList = Array.from(
      new Set([
        ...(getParts.parts.map((part: { partNo: string }) => part.partNo) ||
          []),
        ...(getTrialToolingData.getTrialToolingSheet.map(
          (part: { partNo: string }) => part.partNo
        ) || [])
      ])
    )

    partNameList = Array.from(
      new Set(getParts.parts.map((part: { partName: string }) => part.partName))
    )
  }






  useEffect(() => {
    const filteredPartNo = getParts?.parts.find(
      (part: { partNo: string }) => part.partNo === partNo
    )

    const filterPartNoThatNotHaveInPartNoTable = getTrialToolingData?.getTrialToolingSheet.find((part: { partNo: string }) => part.partNo === partNo)


    setPartName(filteredPartNo?.partName ? filteredPartNo.partName : filterPartNoThatNotHaveInPartNoTable?.partName || "")
    setCustomer(filteredPartNo?.customer ? filteredPartNo.customer : filterPartNoThatNotHaveInPartNoTable?.customer || "")

    if (partNoList.includes(partNo)) {
      setPreviewUrl(null)
      setFile(null)
      if (fileInputRef.current) fileInputRef.current.value = ""
    }
  }, [partNo])

  const customerQuery = useQuery(GET_CUSTOMERS)
  const customerList = useMemo(
    () => makeCustomerList(customerQuery),
    [customerQuery]
  )

  const [toolingData, setToolingData] = useState<ITooling[]>([])
  const handleCarFormSubmitTooling = (data: ITooling[]) => {
    setToolingData(data)
  }

  const [material, setMaterial] = useState<string>("")
  const [plating, setPlating] = useState<string>("")
  const [trialNo, setTrialNo] = useState<string>("")
  const [quantity, setQuantity] = useState<number>()
  const [jobNo, setJobNo] = useState<string>("")

  const [pJPlanDate, setPJPlanDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [pJStartTime, setPJStartTime] = useState<number>(3600000)
  const [pJEndTime, setPJEndTime] = useState<number>(3600000)
  const [pJSignature, setPJSignature] = useState<string>("")

  const [pJMembersTrial, setPJMembersTrial] = useState<string[]>([])

  const [pLPlanDate, setPLPlanDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [pLStartTime, setPLStartTime] = useState<number>(3600000)
  const [pLEndTime, setPLEndTime] = useState<number>(3600000)
  const [pLSignature, setPLSignature] = useState<string>("")
  const [pLMembersTrial, setPLMembersTrial] = useState<string[]>([])

  const [eNGMembersTrial, setENGMembersTrial] = useState<string[]>([])

  const [pD1MembersTrial, setPD1MembersTrial] = useState<string[]>([])

  const [pD2MembersTrial, setPD2MembersTrial] = useState<string[]>([])

  const [pDMembersTrial, setPDMembersTrial] = useState<string[]>([])

  const [pTMembersTrial, setPTMembersTrial] = useState<string[]>([])

  const [qAMembersTrial, setQAMembersTrial] = useState<string[]>([])

  const [mTMembersTrial, setMTMembersTrial] = useState<string[]>([])

  const { data: getEmployee } = useQuery(GET_EMPLOYEE)
  const listEmployees: string[] =
    Array.from(
      new Set(
        getEmployee?.employee.map(
          ({ firstName }: { firstName: string }) => firstName
        )
      )
    ) || []

  const pJSignatureTime: string = moment(new Date()).format("YYYY-MM-DD")
  const [fmSignature, setFmSignature] = useState<string>("")
  const [engineerHead1Signature, setEngineerHead1Signature] =
    useState<string>("")
  const [engineerHead2Signature, setEngineerHead2Signature] =
    useState<string>("")

  const fmSignatureTime: string = moment(new Date()).format("YYYY-MM-DD")

  const engineerHead1SignatureTime: string = moment(new Date()).format(
    "YYYY-MM-DD"
  )

  const engineerHead2SignatureTime: string = moment(new Date()).format(
    "YYYY-MM-DD"
  )

  useEffect(() => {
    if (!pLSignature) {
      setFmSignature("")
      setEngineerHead1Signature("")
      setEngineerHead2Signature("")
    }
  }, [pLSignature])

  const [createTrialToolingSheet, { loading: loadingCreateTrialToolingSheet }] =
    useMutation(TRIAL_TOOLING_SHEET_CREATE)

  const checkInput = async () => {
    if (!partNoList.includes(partNo)) {
      if (!file) {
        swal(
          "Error",
          " partNo ที่ไม่ได้มีในระบบ, กรุณาระบุ partNo และ Upload รูป",
          "warning"
        )
        return false
      }
    }

    if (
      !partNo ||
      !factory ||
      !trialDate ||
      toolingData.length < 1 ||
      !pJSignature ||
      pJMembersTrial.length < 1
    ) {
      swal("Error", "กรอกข้อมูลไม่ครบ, Tooling Name และลงชื่อ", "warning")
      return false
    }

    if (Object.keys(selectedTools).length === 0) {
      swal("Error", "ไม่ได้ติ๊กเลือกสาเหตุ", "warning")
      return false
    }

    if (toolingData.length > 0) {
      for (const obj of toolingData) {
        for (const key in obj) {
          if (obj[key] === "" || obj[key] === 0) {
            swal("Error", "ระบุ Tooling Name ไม่ครบ", "warning")
            return false
          }
        }
      }
    }

    if (Object.keys(selectedDivisions).length === 0) {
      swal(
        "Error",
        "กรุณา เลือกแผนกให้ลงชื่อ ที่จะต้องเข้าร่วม Trial",
        "warning"
      )
      return false
    }

    return true
  }

  const handleCreate = async () => {
    const data = {
      partName,
      partNo,
      customer,
      factory: factory === "โรง 1" ? "1" : "2",
      trialDate,
      targetTrialEvent,

      cycleTime,
      capacity,
      internalDefect,
      operatorSkill,
      cpk,
      reworkRatio,
      selectedTools: JSON.stringify(selectedTools),
      toolingData: JSON.stringify(toolingData),
      material,
      plating,
      trialNo,
      quantity,
      jobNo,
      pJPlanDate,
      pJStartTime: moment(pJStartTime).format("HH:mm"),
      pJEndTime: moment(pJEndTime).format("HH:mm"),
      pJSignature,
      pJSignatureTime,
      pJMembersTrial: JSON.stringify(pJMembersTrial),
      selectedDivisions: JSON.stringify(selectedDivisions),
      commentPj,
      updatedAt: moment().format("YYYY-MM-DD HH:mm")
    }

    if (!(await checkInput())) {
      setError(true)
      return
    }

    try {
      if (!partNoList.includes(partNo)) {
        if (!file) {
          throw new Error("กรุณาเลือก File ภาพ")
        } else {
          const base64 = await getBase64(file)
          await uploadImage({
            variables: {
              partNo,
              file: base64
            }
          })
        }
      }

      await createTrialToolingSheet({
        variables: {
          inputTrialToolingCreate: data
        }
      })

      swal("Success", "บันทึกข้อมูลเรียบร้อย.", "success").then(() => {
        window.location.reload()
      })
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  const checkUserRoles = (
    userRole: string,
    rolesToCheck: string[]
  ): boolean => {
    const rolesArray = userRole.split(",")

    return rolesToCheck.every((role) => rolesArray.includes(role))
  }

  const userRole = {
    pj:
      (user.position === "Manager" ||
        user.position === "Asst.Mgr" ||
        user.position === "Supervisor" ||
        user.position === "Leader") &&
      checkUserRoles(user.role, ["7", "19"]),
    pl: false,
    eng: false,

    pd1: false,
    pd2: false,
    pd: false,

    pt: false,

    qa: false,

    mt: false,

    leader: false,
    fm: false
  }

  const [otherToolName, setOtherToolName] = useState<string>("")
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false)

  const handleToolChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    name: string
  ) => {
    const { checked } = event.target
    setSelectedTools((prev) => {
      const updated = { ...prev }
      if (checked) {
        updated[key] = name
      } else {
        delete updated[key]
      }
      setSelectedTools(updated)
      return updated
    })
  }

  const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    setIsOtherSelected(checked)

    if (!checked) {
      setOtherToolName("")
      setSelectedTools((prev) => {
        const updated = { ...prev }
        delete updated["other"]
        setSelectedTools(updated)
        return updated
      })
    }
  }

  const handleOtherToolNameInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setOtherToolName(value)

    setSelectedTools((prev) => {
      const updated = { ...prev }
      if (value) {
        updated["other"] = value
      } else {
        delete updated["other"]
      }
      setSelectedTools(updated)
      return updated
    })
  }

  const [toolings, setToolings] = useState<ITooling[]>([
    { die: "", size: "", amount: 1, line: "", machineCode: "" }
  ])

  const { data } = useQuery(GET_MACHINES)
  const machineQuery: IMachine[] = data?.machines || []

  const machineList = Array.from(
    new Set(machineQuery.map((machine: IMachine) => machine.code))
  )

  const getLineOptions = (code: string | number) => {
    const line = machineQuery
      .filter((e) => e.code === code)
      .map((e) => (e.line ? e.line : ""))
    return Array.from(new Set(line))
  }

  const getMachinePowerOptions = (code: string | number) => {
    const machinePower = machineQuery
      .filter((e) => e.code === code)
      .map((e) => (e.machinePower ? e.machinePower : ""))
    return Array.from(new Set(machinePower))
  }

  const handleChange = (
    index: number,
    field: keyof ITooling,
    value: string | number
  ) => {
    const newToolings = [...toolings]
    newToolings[index][field] = value

    setToolings(newToolings)
    handleCarFormSubmitTooling(newToolings)
  }

  const addTool = () => {
    const newToolings = [
      ...toolings,
      { die: "", size: "", amount: 1, line: "", machineCode: "" }
    ]
    setToolings(newToolings)

    handleCarFormSubmitTooling(newToolings)
  }

  const deleteTool = (index: number) => {
    const newToolings = toolings.filter((_, i) => i !== index)
    setToolings(newToolings)
    handleCarFormSubmitTooling(newToolings)
  }

  const formatTime = (time: string | number) => {
    const date = new Date(time)
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false
    })
  }

  const [selectedDivisions, setSelectedDivisions] = useState<
    Record<string, number>
  >({})
  const handleDivisionSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const { checked } = event.target

    setSelectedDivisions((prev) => {
      const updated = { ...prev }
      const departmentNumber =
        name === "ENG"
          ? 7
          : name === "PD1" || name === "PD2" || name === "PD"
            ? 4
            : name === "PT"
              ? 15
              : name === "QA"
                ? 6
                : 12
      if (checked) {
        updated[name] = departmentNumber
      } else {
        delete updated[name]
      }
      setSelectedDivisions(updated)
      return updated
    })
  }

  const signatureNames = {
    pj: ["พิทยา", "กฤษณะ", "อนุสรณ์"],
    pl: listEmployees,
    eng: listEmployees,
    pd1: ["อนุชา", "ไพฑูรย์"],
    pd2: ["อนุชา", "ไพฑูรย์"],
    pd: ["อนุชา", "ไพฑูรย์"],
    pt: listEmployees,
    qa: ["ไชยศ", "นริศรา"],
    mt: listEmployees,
    leader1: ["ภควัต"],
    leader2: ["ภควัต", "พิทยา"],
    fm:
      Array.from(
        new Set(
          getEmployee?.employee
            .filter(({ position }: { position: string }) => {
              return position === "FM"
            })
            .map(({ firstName }: { firstName: string }) => firstName)
        )
      ) || []
  }
  const memberTrialNames = {
    pj: listEmployees,
    pl: listEmployees,
    eng: listEmployees,
    pd1: [
      "จรัญ",
      "ศุภชัย",
      "สุรศักดิ์",
      "วีรชัย",
      "สาหร่าย",
      "เกรียงไกร",
      "วิชิต",
      "ธนายุทธ"
    ],
    pd2: listEmployees,
    pd: listEmployees,
    pt: listEmployees,
    qa: ["ธวัชชัย", "สิทธิชัย", "กฤษณา", "สุนิสา", "วิไลวรรณ"],
    mt: listEmployees
  }

  const [commentPj, setCommentPj] = useState<string>("")
  const [file, setFile] = useState<File | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const getBase64 = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const baseURL = reader.result
        resolve(baseURL)
      }
    })
  }
  const [uploadImage, { loading: imageUpload }] = useMutation(UPLOAD_PART_IMAGE)

  return (
    <ThemeProvider theme={theme}>
      <div className="repair-print-head">
        <Table sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img src={miniLogo} alt="BAE logo" width={"80px"} />
                  <Typography variant="h1" sx={{ fontSize: "20px" }}>
                    Brother Auto Parts & Engineering Co.,Ltd.
                  </Typography>
                </Box>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={1}>
                <Typography variant="h2" sx={{ fontSize: "15px" }}>
                  TRIAL TOOLING SHEET (ใบขอทดลองผลิต)
                </Typography>
              </StyledTableCell>
              <StyledTableCell colSpan={4}></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <StyledTableRow>
              <StyledTableCell colSpan={5}>
                <Grid container>
                  <Grid
                    container
                    item
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid item>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Part Name: (ชื่อชิ้นงาน)</Typography>
                        <Autocomplete
                          freeSolo
                          sx={{ width: 300 }}
                          value={partName}
                          onChange={(e, value) => setPartName(value as string)}
                          inputValue={partName ?? ""}
                          onInputChange={(event, newInputValue) => {
                            if (event && event.type === "change") {
                              setPartName(newInputValue)
                            } else if (
                              event &&
                              event.type === "click" &&
                              newInputValue === ""
                            ) {
                              setPartName("")
                            }
                          }}
                          options={partNameList}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              sx={{
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          )}
                        />
                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Part No: (เลขที่ชิ้นงาน)</Typography>
                        <Autocomplete
                          freeSolo
                          sx={{ width: 300 }}
                          value={partNo || ""}
                          onChange={(e, value) => setPartNo(value as string)}
                          inputValue={partNo ?? ""}
                          onInputChange={(event, newInputValue) => {
                            if (event && event.type === "change") {
                              setPartNo(newInputValue)
                            } else if (
                              event &&
                              event.type === "click" &&
                              newInputValue === ""
                            ) {
                              setPartNo("")
                            }
                          }}
                          options={partNoList}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!partNo && error}
                              variant="standard"
                              sx={{
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          )}
                        />
                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Customer:(ลูกค้า)</Typography>
                        <Autocomplete
                          freeSolo
                          sx={{ width: 300 }}
                          value={customer}
                          onChange={(e, value) => setCustomer(value as string)}
                          inputValue={customer ?? ""}
                          onInputChange={(event, newInputValue) => {
                            if (event && event.type === "change") {
                              setCustomer(newInputValue)
                            } else if (
                              event &&
                              event.type === "click" &&
                              newInputValue === ""
                            ) {
                              setCustomer("")
                            }
                          }}

                          options={customerList}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              sx={{
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          )}
                        />
                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Plant:(สถานที่ขอทดลอง)</Typography>
                        <Autocomplete
                          sx={{ width: 300 }}
                          value={factory}
                          options={["โรง 1", "โรง 2"]}
                          onChange={(e, value) => setFactory(value as string)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!factory && error}
                              variant="standard"
                              sx={{
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          )}
                        />
                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Date/วันที่ออกเอกสาร</Typography>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <DatePicker
                            disabled
                            openTo="day"
                            views={["year", "month", "day"]}
                            inputFormat="DD/MM/YYYY"
                            aria-label="date-from"
                            value={trialDate}
                            onChange={(newStartDate) => {
                              setTrialDate(
                                moment(newStartDate).format("YYYY-MM-DD")
                              )
                            }}
                            renderInput={(params) => (
                              <ThemedTextField
                                {...params}
                                variant="standard"
                                type="date"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                sx={{
                                  width: 300,
                                  input: {
                                    color: "black",
                                    textAlign: "center"
                                  },
                                  "& .MuiInput-underline:before": {
                                    borderBottomColor: "#666666"
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "#666666"
                                  },
                                  "& .MuiInput-underline:hover:before": {
                                    borderBottomColor: "#666666"
                                  }
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Control No./เลขที่เอกสาร</Typography>
                        <TextField
                          disabled
                          variant="standard"
                          type="text"
                          onChange={(e) => setControlNo(e.target.value)}
                          value={controlNo}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>
                    </Grid>
                    <Grid item>
                      <img
                        src={
                          partNoList.includes(partNo)
                            ? getImagePng(`images/parts/${partNo}`)
                            : previewUrl
                              ? previewUrl
                              : bankImage
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src = bankImage
                        }}
                        width={200}
                        height={200}
                        alt="partNo Image"
                      ></img>
                      <br />
                      {!partNoList.includes(partNo) ? (
                        <div>
                          <Typography variant="subtitle2" sx={{ color: "red" }}>
                            * ไฟล์ png และขนาดไม่เกิน 60kb
                          </Typography>
                          <input
                            type="file"
                            accept=".png"
                            ref={fileInputRef}
                            onChange={(e) => {
                              const file = e.currentTarget.files?.[0]
                              if (
                                file &&
                                (file.type !== "image/png" ||
                                  file.size > 60 * 1024)
                              ) {
                                swal(
                                  "Error",
                                  "Upload ได้เฉพาะ png และ ขนาดไฟล์ต้องไม่เกิน 60kb",
                                  "error"
                                )
                                setFile(null)
                                setPreviewUrl(null)
                                if (fileInputRef.current)
                                  fileInputRef.current.value = ""
                              } else {
                                setFile(file || null)
                                setPreviewUrl(
                                  file ? URL.createObjectURL(file) : null
                                )
                              }
                            }}
                          />{" "}
                          <IconButton
                            onClick={() => {
                              setPreviewUrl(null)
                              setFile(null)
                              if (fileInputRef.current) {
                                fileInputRef.current.value = ""
                              }
                            }}
                            aria-label="delete"
                          >
                            <DeleteIcon style={{ color: "red" }} />
                          </IconButton>{" "}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                    <Grid
                      item
                      sx={{
                        borderLeft: "1px solid black",
                        borderBottom: "1px solid black",
                        p: "20px"
                      }}
                    >
                      <Grid item>
                        <Typography>TARGET TRIAL</Typography>
                      </Grid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Event</Typography>
                        <TextField
                          fullWidth
                          type="text"
                          variant="standard"
                          onChange={(e) => setTargetTrialEvent(e.target.value)}
                          value={targetTrialEvent}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>1. Cycle time :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          onChange={(e) => setCycleTime(+e.target.value)}
                          value={cycleTime}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(Sec.)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>2. Capacity :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          onChange={(e) => setCapacity(+e.target.value)}
                          value={capacity}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>3. Internal defect :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          onChange={(e) => setInternalDefect(+e.target.value)}
                          value={internalDefect}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>4. Operator Skill :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          onChange={(e) => setOperatorSkill(+e.target.value)}
                          value={operatorSkill}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>5. Cpk :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          onChange={(e) => setCpk(+e.target.value)}
                          value={cpk}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>6. Rework Ratio :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          onChange={(e) => setReworkRatio(+e.target.value)}
                          value={reworkRatio}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>
                    </Grid>
                  </Grid>

                  <Grid item sx={{ mt: "50px" }}>
                    <Box sx={{ padding: 2 }}>
                      <FormGroup>
                        <Grid container spacing={2}>
                          {toolNameData.map((tool) => (
                            <Grid
                              item
                              xs={4}
                              key={tool.key}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start"
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) =>
                                      handleToolChange(e, tool.key, tool.name)
                                    }
                                    checked={!!selectedTools[tool.key]}
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "black"
                                      }
                                    }}
                                  />
                                }
                                label={tool.name}
                              />
                            </Grid>
                          ))}
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start"
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isOtherSelected}
                                  onChange={handleOtherChange}
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black"
                                    }
                                  }}
                                />
                              }
                              label="Other"
                            />
                            <TextField
                              fullWidth
                              variant="standard"
                              disabled={!isOtherSelected}
                              value={otherToolName}
                              onChange={handleOtherToolNameInput}
                              sx={{
                                input: { color: "black", textAlign: "center" },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      pt: "50px",

                      borderTop: "2px solid #000"
                    }}
                    xs={12}
                  >
                    <Grid container direction="column" spacing={3}>
                      {toolings.map((tooling, index) => (
                        <Grid item key={index}>
                          <PMDocumentGrid container spacing={2}>
                            <Typography>{index + 1}. Process :</Typography>
                            <TextField
                              variant="standard"
                              onChange={(e) =>
                                handleChange(index, "die", e.target.value || "")
                              }
                              value={toolings[index].die}
                              error={!toolings[index].die && error}
                              sx={{
                                width: "200px",
                                pr: "20px",
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                }
                              }}
                            />

                            <Typography>Size(ขนาดเครื่องจักร)</Typography>
                            <Autocomplete
                              sx={{ width: 150 }}
                              options={getMachinePowerOptions(
                                tooling.machineCode
                              )}
                              value={toolings[index].size}
                              onChange={(event, newValue) =>
                                handleChange(index, "size", newValue || "")
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!toolings[index].size && error}
                                  variant="standard"
                                  sx={{
                                    input: {
                                      color: "black",
                                      textAlign: "center"
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:hover:before": {
                                      borderBottomColor: "#666666"
                                    }
                                  }}
                                />
                              )}
                            />
                            <Typography sx={{ pr: "20px" }}>
                              Tons(ตัน)
                            </Typography>

                            <Typography>จำนวน</Typography>
                            <TextField
                              disabled
                              type="number"
                              variant="standard"
                              onChange={(e) =>
                                handleChange(index, "amount", +e.target.value)
                              }
                              value={toolings[index].amount}
                              sx={{
                                width: "50px",
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                            <Typography sx={{ pr: "20px" }}>เครื่อง</Typography>

                            <Typography>Line</Typography>
                            <Autocomplete
                              sx={{ width: 150 }}
                              options={getLineOptions(tooling.machineCode)}
                              value={toolings[index].line}
                              onChange={(event, newValue) =>
                                handleChange(index, "line", newValue || "")
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!toolings[index].line && error}
                                  variant="standard"
                                  sx={{
                                    input: {
                                      color: "black",
                                      textAlign: "center"
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:hover:before": {
                                      borderBottomColor: "#666666"
                                    }
                                  }}
                                />
                              )}
                            />
                            <Typography sx={{ pr: "20px" }}>No.</Typography>

                            <Typography>M/C (เลขที่เครื่องจักร)</Typography>
                            <Autocomplete
                              sx={{ width: 200 }}
                              options={machineList}
                              value={toolings[index].machineCode}
                              onChange={(event, newValue) =>
                                handleChange(
                                  index,
                                  "machineCode",
                                  newValue || ""
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!toolings[index].machineCode && error}
                                  variant="standard"
                                  sx={{
                                    input: {
                                      color: "black",
                                      textAlign: "center"
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:hover:before": {
                                      borderBottomColor: "#666666"
                                    }
                                  }}
                                />
                              )}
                            />

                            <IconButton
                              onClick={() => deleteTool(index)}
                              aria-label="delete"
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </PMDocumentGrid>
                        </Grid>
                      ))}
                      <Grid item>
                        <ThemedLoadingButton
                          variant="contained"
                          onClick={addTool}
                          sx={{
                            bgcolor:
                              theme.palette.mode === "light"
                                ? "yellowgreen"
                                : "darkgreen"
                          }}
                        >
                          เพิ่ม Tooling name
                        </ThemedLoadingButton>
                      </Grid>

                      <Grid
                        container
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>MATERIAL (วัตถุดิบ) </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            onChange={(e) => setMaterial(e.target.value)}
                            value={material}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>ผิวชุบ Plating Coating </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            onChange={(e) => setPlating(e.target.value)}
                            value={plating}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>

                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>Trial No. (เลขที่ทดลอง) </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            onChange={(e) => setTrialNo(e.target.value)}
                            value={trialNo}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>Q'ty (จำนวนชิ้น) </Typography>
                          <TextField
                            type="number"
                            variant="standard"
                            onChange={(e) => setQuantity(+e.target.value)}
                            value={quantity}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                          <Typography>Pcs. (ชิ้น)</Typography>
                        </PMDocumentGrid>
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>JOB No.(เลขที่สั่งงาน) </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            onChange={(e) => setJobNo(e.target.value)}
                            value={jobNo}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      mt: "50px"
                    }}
                    xs={12}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell rowSpan={2} colSpan={2}>
                            <Typography>ฝ่าย/แผนก</Typography>
                            <Typography>Department/Section Division</Typography>
                          </StyledTableCell>
                          <StyledTableCell colSpan={2}>
                            <Typography>
                              Trial Plan Date(วันที่ทดลอง)
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell rowSpan={2}>
                            <Typography>Signature</Typography>
                            <Typography>ลงชื่อ</Typography>
                          </StyledTableCell>
                          <StyledTableCell rowSpan={2}>
                            <Typography>MEMBER TRIAL</Typography>
                            <Typography>รายชื่อผู้ร่วมทดลองการผลิต</Typography>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>Plan Date(วัน)</StyledTableCell>
                          <StyledTableCell sx={{ minWidth: "150px" }}>Plan Time(เวลา)</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>วิศวกรโครงการ</StyledTableCell>
                          <StyledTableCell>PJ</StyledTableCell>
                          <StyledTableCell>
                            <FormTextField
                              disabled={!userRole.pj}
                              type="date"
                              variant="standard"
                              fullWidth
                              value={moment(pJPlanDate).format("YYYY-MM-DD")}
                              onChange={(e) => setPJPlanDate(e.target.value)}
                              sx={{
                                width: "100%", textAlign: "center",
                                "& .MuiInputBase-input": {
                                  textAlign: "center",
                                },
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LocalizationProvider dateAdapter={DateAdapter}>
                              <TimePicker
                                disabled={!userRole.pj}
                                ampm={false}
                                views={["hours", "minutes"]}
                                inputFormat="HH:mm"
                                value={pJStartTime}
                                onChange={(newSelectTime) => {
                                  setPJStartTime(
                                    newSelectTime ? newSelectTime : 0
                                  )
                                }}
                                renderInput={(params) => (
                                  <FormTextField
                                    sx={{
                                      input: { textAlign: "center" },
                                      width: "30%",
                                      "& svg": {
                                        color: "#888888"
                                      }
                                    }}
                                    {...params}
                                    id="start-time-ot"
                                    variant="standard"
                                  />
                                )}
                              />
                              {" - "}
                              <TimePicker
                                disabled={!userRole.pj}
                                ampm={false}
                                views={["hours", "minutes"]}
                                inputFormat="HH:mm"
                                value={pJEndTime}
                                onChange={(newSelectTime) => {
                                  setPJEndTime(
                                    newSelectTime ? newSelectTime : 0
                                  )
                                }}
                                renderInput={(params) => (
                                  <FormTextField
                                    sx={{
                                      input: { textAlign: "center" },
                                      width: "30%",
                                      "& svg": {
                                        color: "#888888"
                                      }
                                    }}
                                    {...params}
                                    id="start-time-ot"
                                    variant="standard"
                                  />
                                )}
                              />
                              {" น. "}
                            </LocalizationProvider>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.pj}
                              sx={{ width: 200 }}
                              options={signatureNames.pj}
                              value={pJSignature}
                              onChange={(event, newValue) =>
                                setPJSignature(newValue as string)
                              }
                              renderInput={(params) => (
                                <TextField
                                  error={!pJSignature && error}
                                  {...params}
                                  variant="standard"
                                  sx={{
                                    input: {
                                      color: "black",
                                      textAlign: "center"
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:hover:before": {
                                      borderBottomColor: "#666666"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.pj}
                              multiple
                              freeSolo
                              options={memberTrialNames.pj}
                              value={pJMembersTrial}
                              sx={{
                                width: "320px",
                                display: "inline-flex"
                              }}
                              onChange={(e, newValue) =>
                                setPJMembersTrial(newValue)
                              }
                              renderTags={autocompleteRenderTags}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={pJMembersTrial.length < 1 && error}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  sx={{
                                    input: {
                                      color: "black"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>วางแผน</StyledTableCell>
                          <StyledTableCell>PL</StyledTableCell>
                          <StyledTableCell>
                            <LocalizationProvider dateAdapter={DateAdapter}>
                              <DatePicker
                                disabled={!userRole.pl}
                                openTo="day"
                                views={["year", "month", "day"]}
                                inputFormat="DD/MM/YYYY"
                                aria-label="date-from"
                                value={pLPlanDate}
                                onChange={(newStartDate) => {
                                  setPLPlanDate(
                                    moment(newStartDate).format("YYYY-MM-DD")
                                  )
                                }}
                                renderInput={(params) => (
                                  <ThemedTextField
                                    {...params}
                                    variant="standard"
                                    type="date"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    sx={{
                                      width: 200,
                                      input: {
                                        color: "black",
                                        textAlign: "center"
                                      },
                                      "& .MuiInput-underline:before": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:after": {
                                        borderBottomColor: "#666666"
                                      },
                                      "& .MuiInput-underline:hover:before": {
                                        borderBottomColor: "#666666"
                                      }
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </StyledTableCell>
                          <StyledTableCell>
                            <LocalizationProvider dateAdapter={DateAdapter}>
                              <TimePicker
                                disabled={!userRole.pl}
                                ampm={false}
                                views={["hours", "minutes"]}
                                inputFormat="HH:mm"
                                value={pLStartTime}
                                onChange={(newSelectTime) => {
                                  setPLStartTime(
                                    newSelectTime ? newSelectTime : 0
                                  )
                                }}
                                renderInput={(params) => (
                                  <FormTextField
                                    sx={{
                                      input: { textAlign: "center" },
                                      width: "30%",
                                      "& svg": {
                                        color: "#888888"
                                      }
                                    }}
                                    {...params}
                                    id="start-time-ot"
                                    variant="standard"
                                  />
                                )}
                              />
                              {" - "}
                              <TimePicker
                                disabled={!userRole.pl}
                                views={["hours", "minutes"]}
                                inputFormat="HH:mm"
                                value={pLEndTime}
                                onChange={(newSelectTime) => {
                                  setPLEndTime(
                                    newSelectTime ? newSelectTime : 0
                                  )
                                }}
                                renderInput={(params) => (
                                  <FormTextField
                                    sx={{
                                      input: { textAlign: "center" },
                                      width: "30%",
                                      "& svg": {
                                        color: "#888888"
                                      }
                                    }}
                                    {...params}
                                    id="start-time-ot"
                                    variant="standard"
                                  />
                                )}
                              />
                              {" น. "}
                            </LocalizationProvider>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.pl}
                              sx={{ width: 200 }}
                              options={listEmployees}
                              value={pLSignature}
                              onChange={(event, newValue) =>
                                setPLSignature(newValue as string)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  sx={{
                                    input: {
                                      color: "black",
                                      textAlign: "center"
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "#666666"
                                    },
                                    "& .MuiInput-underline:hover:before": {
                                      borderBottomColor: "#666666"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.pl}
                              multiple
                              freeSolo
                              options={listEmployees}
                              value={pLMembersTrial}
                              sx={{
                                width: "320px",
                                display: "inline-flex"
                              }}
                              onChange={(e, newValue) =>
                                setPLMembersTrial(newValue)
                              }
                              renderTags={autocompleteRenderTags}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  sx={{
                                    input: {
                                      color: "black"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>วิศวกรรม</StyledTableCell>
                          <StyledTableCell>{division.eng}</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {pLStartTime && pLSignature
                              ? formatTime(pLStartTime)
                              : ""}
                            {" - "}
                            {pLEndTime && pLSignature
                              ? formatTime(pLEndTime)
                              : ""}
                            {" น. "}
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!userRole.pj}
                                  onChange={(e) =>
                                    handleDivisionSelect(e, division.eng)
                                  }
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black"
                                    }
                                  }}
                                />
                              }
                              label={"เลือกแผนกให้ลงชื่อ"}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.eng}
                              multiple
                              freeSolo
                              options={listEmployees}
                              value={eNGMembersTrial}
                              sx={{
                                width: "320px",
                                display: "inline-flex"
                              }}
                              onChange={(e, newValue) =>
                                setENGMembersTrial(newValue)
                              }
                              renderTags={autocompleteRenderTags}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  sx={{
                                    input: {
                                      color: "black"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>ผลิตโรง 1</StyledTableCell>
                          <StyledTableCell>{division.pd1}</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {pLStartTime && pLSignature
                              ? formatTime(pLStartTime)
                              : ""}
                            {" - "}
                            {pLEndTime && pLSignature
                              ? formatTime(pLEndTime)
                              : ""}
                            {" น. "}
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!userRole.pj}
                                  onChange={(e) =>
                                    handleDivisionSelect(e, division.pd1)
                                  }
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black"
                                    }
                                  }}
                                />
                              }
                              label={"เลือกแผนกให้ลงชื่อ"}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.pd1}
                              multiple
                              freeSolo
                              options={listEmployees}
                              value={pD1MembersTrial}
                              sx={{
                                width: "320px",
                                display: "inline-flex"
                              }}
                              onChange={(e, newValue) =>
                                setPD1MembersTrial(newValue)
                              }
                              renderTags={autocompleteRenderTags}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  sx={{
                                    input: {
                                      color: "black"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>ผลิตโรง 2</StyledTableCell>
                          <StyledTableCell>{division.pd2}</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {pLStartTime && pLSignature
                              ? formatTime(pLStartTime)
                              : ""}
                            {" - "}
                            {pLEndTime && pLSignature
                              ? formatTime(pLEndTime)
                              : ""}
                            {" น. "}
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!userRole.pj}
                                  onChange={(e) =>
                                    handleDivisionSelect(e, division.pd2)
                                  }
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black"
                                    }
                                  }}
                                />
                              }
                              label={"เลือกแผนกให้ลงชื่อ"}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.pd2}
                              multiple
                              freeSolo
                              options={listEmployees}
                              value={pD2MembersTrial}
                              sx={{
                                width: "320px",
                                display: "inline-flex"
                              }}
                              onChange={(e, newValue) =>
                                setPD2MembersTrial(newValue)
                              }
                              renderTags={autocompleteRenderTags}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  sx={{
                                    input: {
                                      color: "black"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>
                            ประกอบ Spot/Wedling Assemble
                          </StyledTableCell>
                          <StyledTableCell>{division.pd}</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {pLStartTime && pLSignature
                              ? formatTime(pLStartTime)
                              : ""}
                            {" - "}
                            {pLEndTime && pLSignature
                              ? formatTime(pLEndTime)
                              : ""}
                            {" น. "}
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!userRole.pj}
                                  onChange={(e) =>
                                    handleDivisionSelect(e, division.pd)
                                  }
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black"
                                    }
                                  }}
                                />
                              }
                              label={"เลือกแผนกให้ลงชื่อ"}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.pd}
                              multiple
                              freeSolo
                              options={listEmployees}
                              value={pDMembersTrial}
                              sx={{
                                width: "320px",
                                display: "inline-flex"
                              }}
                              onChange={(e, newValue) =>
                                setPDMembersTrial(newValue)
                              }
                              renderTags={autocompleteRenderTags}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  sx={{
                                    input: {
                                      color: "black"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>ชุบโลหะ</StyledTableCell>
                          <StyledTableCell>{division.pt}</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {pLStartTime && pLSignature
                              ? formatTime(pLStartTime)
                              : ""}
                            {" - "}
                            {pLEndTime && pLSignature
                              ? formatTime(pLEndTime)
                              : ""}
                            {" น. "}
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!userRole.pj}
                                  onChange={(e) =>
                                    handleDivisionSelect(e, division.pt)
                                  }
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black"
                                    }
                                  }}
                                />
                              }
                              label={"เลือกแผนกให้ลงชื่อ"}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.pt}
                              multiple
                              freeSolo
                              options={listEmployees}
                              value={pTMembersTrial}
                              sx={{
                                width: "320px",
                                display: "inline-flex"
                              }}
                              onChange={(e, newValue) =>
                                setPTMembersTrial(newValue)
                              }
                              renderTags={autocompleteRenderTags}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  sx={{
                                    input: {
                                      color: "black"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>ประกันคุณภาพ</StyledTableCell>
                          <StyledTableCell>{division.qa}</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {pLStartTime && pLSignature
                              ? formatTime(pLStartTime)
                              : ""}
                            {" - "}
                            {pLEndTime && pLSignature
                              ? formatTime(pLEndTime)
                              : ""}
                            {" น. "}
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!userRole.pj}
                                  onChange={(e) =>
                                    handleDivisionSelect(e, division.qa)
                                  }
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black"
                                    }
                                  }}
                                />
                              }
                              label={"เลือกแผนกให้ลงชื่อ"}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.qa}
                              multiple
                              freeSolo
                              options={listEmployees}
                              value={qAMembersTrial}
                              sx={{
                                width: "320px",
                                display: "inline-flex"
                              }}
                              onChange={(e, newValue) =>
                                setQAMembersTrial(newValue)
                              }
                              renderTags={autocompleteRenderTags}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  sx={{
                                    input: {
                                      color: "black"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>ซ่อมบำรุง</StyledTableCell>
                          <StyledTableCell>{division.mt}</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {pLStartTime && pLSignature
                              ? formatTime(pLStartTime)
                              : ""}
                            {" - "}
                            {pLEndTime && pLSignature
                              ? formatTime(pLEndTime)
                              : ""}
                            {" น. "}
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!userRole.pj}
                                  onChange={(e) =>
                                    handleDivisionSelect(e, division.mt)
                                  }
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black"
                                    }
                                  }}
                                />
                              }
                              label={"เลือกแผนกให้ลงชื่อ"}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              disabled={!userRole.mt}
                              multiple
                              freeSolo
                              options={listEmployees}
                              value={mTMembersTrial}
                              sx={{
                                width: "320px",
                                display: "inline-flex"
                              }}
                              onChange={(e, newValue) =>
                                setMTMembersTrial(newValue)
                              }
                              renderTags={autocompleteRenderTags}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // error={checkValue}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  sx={{
                                    input: {
                                      color: "black"
                                    }
                                  }}
                                />
                              )}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell
                rowSpan={4}
                sx={{ width: 650, p: "0 20px 0 20px" }}
              >
                <Typography sx={{ fontSize: "20px" }}>
                  PJ (ข้อเสนอแนะ)
                </Typography>

                <TextField
                  disabled={!userRole.pj}
                  variant="standard"
                  type="text"
                  multiline
                  fullWidth
                  rows={5}
                  onChange={(e) => setCommentPj(e.target.value)}
                  value={commentPj}
                />
                <Typography sx={{ fontSize: "20px" }}>
                  PL (ข้อเสนอแนะ)
                </Typography>
                <TextField
                  disabled={!userRole.pl}
                  variant="standard"
                  type="text"
                  multiline
                  fullWidth
                  rows={5}
                />
              </StyledTableCell>

              <StyledTableCell>Approve by (ผู้อนุมัติ)</StyledTableCell>
              <StyledTableCell>Approve by (ผู้อนุมัติ)</StyledTableCell>
              <StyledTableCell>Check by (ผู้ตรวจสอบ)</StyledTableCell>
              <StyledTableCell>Prepare by (ผู้จัดทำ)</StyledTableCell>
            </TableRow>
            <TableRow sx={{ height: 100 }}>
              <StyledTableCell>
                <Autocomplete
                  disabled={
                    !pLSignature ||
                    !engineerHead1Signature ||
                    !engineerHead2Signature ||
                    !userRole.fm
                  }
                  options={listEmployees}
                  value={pLSignature ? fmSignature : ""}
                  onChange={(event, newValue) =>
                    setFmSignature(newValue as string)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        input: {
                          color: "black",
                          textAlign: "center"
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#666666"
                        }
                      }}
                    />
                  )}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Autocomplete
                  disabled={!pLSignature || !userRole.leader}
                  options={listEmployees}
                  value={pLSignature ? engineerHead1Signature : ""}
                  onChange={(event, newValue) =>
                    setEngineerHead1Signature(newValue as string)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        input: {
                          color: "black",
                          textAlign: "center"
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#666666"
                        }
                      }}
                    />
                  )}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Autocomplete
                  disabled={!pLSignature || !userRole.leader}
                  options={listEmployees}
                  value={pLSignature ? engineerHead2Signature : ""}
                  onChange={(event, newValue) =>
                    setEngineerHead2Signature(newValue as string)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        input: {
                          color: "black",
                          textAlign: "center"
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#666666"
                        }
                      }}
                    />
                  )}
                />
              </StyledTableCell>
              <StyledTableCell>{pJSignature}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                {fmSignature ? fmSignatureTime : ""}
              </StyledTableCell>
              <StyledTableCell>
                {engineerHead1Signature ? engineerHead1SignatureTime : ""}
              </StyledTableCell>
              <StyledTableCell>
                {engineerHead2Signature ? engineerHead2SignatureTime : ""}
              </StyledTableCell>
              <StyledTableCell>
                {pJSignature ? pJSignatureTime : ""}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>FM ผู้จัดการโรงงาน</StyledTableCell>
              <StyledTableCell colSpan={3}>
                Reqeust by (ผู้ร้องขอ) Project Engineer
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
        <ThemedLoadingButton
          loading={loadingCreateTrialToolingSheet && imageUpload}
          variant="contained"
          sx={{ width: "200px", fontWeight: 600, fontSize: "20px", mt: "50px" }}
          onClick={handleCreate}
        >
          บันทึกข้อมูล
        </ThemedLoadingButton>
      </div>
    </ThemeProvider>
  )
}

const TrialToolingCreate: React.FC = () => {
  return (
    <>
      <Typography
        variant="h1"
        sx={{ fontSize: "50px", textAlign: "center", pb: "20px" }}
      >
        สำหรับสร้าง Trial Tooling Sheet
      </Typography>
      <TrialToolingPaper />
    </>
  )
}

export default TrialToolingCreate
