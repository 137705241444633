import { useLazyQuery } from "@apollo/client"
import {
  Grid,
  Autocomplete,
  Table,
  tableCellClasses,
  TableCell,
  styled,
  TableRow,
  TableHead,
  TableBody
} from "@mui/material"
import React, { useState } from "react"
import { GET_MATERIAL_TAG } from "../../common/Query/MaterialQuery"
import QRCode from "qrcode.react"
import "../../css/Materials/MaterialTag.css"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import { getImageJPG } from "../../common/utilities"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import swal from "sweetalert"
import { useInvoice } from "./materialCustomHook/useInvoice"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center",
    border: "solid 1px black",
    padding: 0
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bold",
    padding: 0,
    fontFamily: "Sarabun"
  }
}))

const getQR = (
  matNo: string,
  receiveDate: string,
  invoiceNo: string,
  quantity: number,
  boxNo: number
) => {
  return `${matNo}|${receiveDate}|${invoiceNo}|${quantity}|${boxNo}`
}
const getFiFo = (date: string) => {
  const getMonth = date.split("-")
  if (getMonth[1] === "01" || getMonth[1] === "07")
    return (
      <img
        src={getImageJPG("images/color/green")}
        alt=""
        width={250}
        height={40}
      ></img>
    )
  if (getMonth[1] === "02" || getMonth[1] === "08")
    return (
      <img
        src={getImageJPG("images/color/blue")}
        alt=""
        width={250}
        height={40}
      ></img>
    )
  if (getMonth[1] === "03" || getMonth[1] === "09")
    return (
      <img
        src={getImageJPG("images/color/red")}
        alt=""
        width={250}
        height={40}
      ></img>
    )
  if (getMonth[1] === "04" || getMonth[1] === "10")
    return (
      <img
        src={getImageJPG("images/color/yellow")}
        alt=""
        width={250}
        height={40}
      ></img>
    )
  if (getMonth[1] === "05" || getMonth[1] === "11")
    return (
      <img
        src={getImageJPG("images/color/pink")}
        alt=""
        width={250}
        height={40}
      ></img>
    )
  if (getMonth[1] === "06" || getMonth[1] === "12")
    return (
      <img
        src={getImageJPG("images/color/orange")}
        alt=""
        width={250}
        height={40}
      ></img>
    )
}
const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {},

  "td, th": {
    border: "solid 1px black"
  }
}))

interface IMatTag {
  matNo: string
  spec: string
  totalPack: number
  fraction: number
  model: string
  customer: string
  sizeH: string
  sizeW: string
  sizeL: string
  quantity: number
  receiveBy: string
  receiveDate: string
  invoiceNo: string
  boxNo: number
  totalPackStock: number
  boxNoInStock: number
}

const pageTitle = "FIFO Materials'Tag"

const MaterialTag: React.FC = () => {
  const [partNo, setPartNo] = useState<string>("")
  const [invoiceNo, setInvoiceNO] = useState<string | null>("")
  const [actionDate, setDate] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [getMaterialTag, { data, loading }] = useLazyQuery(GET_MATERIAL_TAG)

  const invoiceList = useInvoice(partNo, actionDate, factory)

  const handleSubmit = async () => {
    if (!partNo && !invoiceNo && !actionDate && !factory) {
      return swal("Warning", "กรุณาใส่ข้อมูล", "warning")
    }

    if (actionDate && !factory) {
      return swal("Warning", "กรุณาใส่ Factory", "warning")
    }

    const result = await getMaterialTag({
      variables: { matNo: partNo, invoiceNo, receiveDate: actionDate, factory },
      fetchPolicy: "network-only"
    })

    if (!result?.data?.matTag?.length) {
      swal("Warning", "ไม่พบข้อมูล", "warning")
    }
  }

  return (
    <div>
      <PageLabel
        menuItem={menuItemList.Materials}
        menuItemName={Object.keys(menuItemList)[1]}
        menuPageName={pageTitle}
      />{" "}
      <ThemedCard style={{ marginTop: "25px" }} className="none-display">
        <PageLabelCard
          title="FIFO Material Tag"
          subTitle="detail about FIFO Material Tag"
        />
        <Grid
          container
          spacing={2}
          width={"98%"}
          margin={2}
          paddingRight={3}
          sx={{ "@media print": { display: "none" } }}
        >
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                id="Date Range"
                label="Action Date"
                InputLabelProps={{ shrink: true }}
                type="date"
                fullWidth
                onChange={(e) => setDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                disablePortal
                id="factory"
                options={["1", "2"]}
                style={{ width: "100%" }}
                value={factory}
                onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Select Factory" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <AutocompletePartNo setPartNo={setPartNo} partNo={partNo} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                disablePortal
                id="invoiceNo"
                options={invoiceList}
                style={{ width: "100%" }}
                sx={{ width: 300 }}
                onChange={(e, newValue) => setInvoiceNO(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select Invoice"
                    value={invoiceNo}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs="auto">
              <ThemedLoadingButton
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
                loading={loading}
                style={{
                  whiteSpace: "nowrap"
                }}
              >
                Submit
              </ThemedLoadingButton>
            </Grid>
            <Grid item xs="auto">
              <ThemedLoadingButton
                contrast
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => window.print()}
                style={{
                  marginLeft: "20px"
                }}
              >
                Print
              </ThemedLoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </ThemedCard>
      <ThemedCard>
        <div id="print-page">
          <Grid container spacing={2} className="pagebreak">
            {data ? (
              <>
                {Array.from(Array(data.matTag.length), (e, i) => {
                  const matTag: IMatTag = data.matTag[i]
                  return (
                    <Grid item xs={4} sm={3} key={i.toString()}>
                      <Table
                        style={{
                          maxWidth: "350px",
                          marginTop: "30px",
                          maxHeight: "450px",
                          minHeight: "420px"
                        }}
                      >
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell colSpan={5} rowSpan={1}>
                              BROTHER AUTO PARTS ENGINEERING CO.,LTD <br />
                              SM Tag
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell colSpan={2} rowSpan={1}>
                              Mat NO (รหัสวัถตุดิบ)
                            </StyledTableCell>
                            <StyledTableCell colSpan={3} rowSpan={1}>
                              {matTag.matNo}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell colSpan={2} rowSpan={1}>
                              Spec Mat'l
                            </StyledTableCell>
                            <StyledTableCell colSpan={3} rowSpan={1}>
                              {matTag.spec}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell colSpan={2} rowSpan={1}>
                              MODEL (รุ่น)<br></br>
                              {matTag.model}
                            </StyledTableCell>
                            <StyledTableCell colSpan={3} rowSpan={1}>
                              Customer (ลูกค้า)<br></br>
                              {matTag.customer}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell colSpan={2} rowSpan={2}>
                              Size `Mat'l
                            </StyledTableCell>
                            <StyledTableCell colSpan={1} rowSpan={1}>
                              H
                            </StyledTableCell>
                            <StyledTableCell colSpan={1} rowSpan={1}>
                              W
                            </StyledTableCell>
                            <StyledTableCell colSpan={1} rowSpan={1}>
                              L
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>{matTag.sizeH}</StyledTableCell>
                            <StyledTableCell>{matTag.sizeW}</StyledTableCell>
                            <StyledTableCell>{matTag.sizeL} </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell colSpan={1}>
                              Q'TY (แผ่น/กก.)
                            </StyledTableCell>
                            <StyledTableCell colSpan={1}>
                              {matTag.fraction !== 0 &&
                              matTag.totalPackStock === matTag.boxNoInStock
                                ? matTag.fraction
                                : matTag.quantity}
                            </StyledTableCell>
                            <StyledTableCell colSpan={1}>
                              receive by (ผู้รับ)
                            </StyledTableCell>
                            <StyledTableCell colSpan={2}>
                              {matTag.receiveBy}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell colSpan={2} rowSpan={2}>
                              <QRCode
                                value={getQR(
                                  matTag.matNo,
                                  matTag.receiveDate,
                                  matTag.invoiceNo,
                                  matTag.fraction !== 0 &&
                                    matTag.totalPack === matTag.boxNo
                                    ? matTag.fraction
                                    : matTag.quantity,
                                  matTag.boxNo
                                )}
                                size={70}
                              />
                            </StyledTableCell>
                            <StyledTableCell colSpan={3} rowSpan={1}>
                              RECEIVE DATE : {matTag.receiveDate}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell colSpan={3} rowSpan={1}>
                              Inv No. : {matTag.invoiceNo}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>Pack</StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "23px",
                                fontWeight: "bold"
                              }}
                            >
                              {matTag.boxNo}
                            </StyledTableCell>
                            <StyledTableCell>/</StyledTableCell>
                            <StyledTableCell
                              colSpan={2}
                              style={{
                                fontSize: "23px",
                                fontWeight: "bold"
                              }}
                            >
                              {matTag.totalPack}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell colSpan={5}>
                              FIFO:Color<br></br>
                              {getFiFo(matTag.receiveDate)}
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  )
                })}
              </>
            ) : (
              <></>
            )}
          </Grid>
        </div>
      </ThemedCard>
    </div>
  )
}
export default MaterialTag
