import { createFilterOptions } from "@mui/material"
import { IDataMachine } from "../page/Maintenance/KPIMaintenance"

export const formatNumber = (value: number): string => {
  if (value) {
    return value?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  return "0"
}

export const factoryList: string[] = ["1", "2"]
export const shiftList: string[] = ["Day", "Night"]

export const setDocumentTitle = (newTitle: string): void => {
  document.title = newTitle
}

export const filter = createFilterOptions<string>()

export const departMentList = [
  "planning",
  "production",
  "quality",
  "warehouse",
  "plating"
]

export const logoTable =
  "https://brother-auto-parts-image.s3.ap-southeast-1.amazonaws.com/images/bae.png"

export const miniLogo =
  "https://brother-auto-parts-image.s3.ap-southeast-1.amazonaws.com/logo.png"

export const bankImage =
  "https://brother-auto-parts-image.s3.ap-southeast-1.amazonaws.com/images/parts/blank.jpg"

export const conditionLinePress = (line: string): boolean => {
  return line === "LINE A" ||
    line === "LINE B" ||
    line === "LINE C" ||
    line === "LINE D" ||
    line === "LINE 2B" ||
    line === "TAP"
    ? true
    : false
}
interface IDate {
  year: number
  month: number
  day: number
  hour: number
  minute: number
  date: Date
}
export const subtractDate = (date2: Date, date1: Date): IDate => {
  const diff = new Date(date2.getTime() - date1.getTime())
  return {
    year: diff.getUTCFullYear() - 1970,
    month: diff.getUTCMonth(),
    day: diff.getUTCDate() - 1,
    hour: diff.getUTCHours(),
    minute: diff.getUTCMinutes(),
    date: diff
  }
}

export const getProcessFilterRumTimeMachine = (
  type = "",
  rowsMachineRuntime: IDataMachine[]
): IDataMachine[] => {
  if (type === "stamp") {
    return rowsMachineRuntime.filter(
      (datum) =>
        datum.line.includes("LINE A") ||
        datum.line.includes("LINE B") ||
        datum.line.includes("LINE C") ||
        datum.line.includes("LINE D") ||
        datum.line.includes("LINE 2B")
    )
  } else if (type === "weld") {
    return rowsMachineRuntime?.filter((datum) =>
      datum.line.includes("LINE SPOT")
    )
  } else if (type === "arcWeld") {
    return rowsMachineRuntime?.filter((datum) =>
      datum.line.includes("ARC WELDING")
    )
  } else {
    return rowsMachineRuntime
  }
}

export const clearParam = (params: string): void => {
  window.history.pushState({}, document.title, "/#/" + params)
}

export const getImagePng = (name: string): string =>
  `https://brother-auto-parts-image.s3.ap-southeast-1.amazonaws.com/${name}.png?${new Date().getTime()}`

export const getImageJPG = (name: string): string =>
  `https://brother-auto-parts-image.s3.ap-southeast-1.amazonaws.com/${name}.jpg`

export const isMobile = (): boolean => {
  const match = window.matchMedia
  if (match) {
    const mq = match("(pointer:coarse)")
    return mq.matches ? false : true
  }
  return true
}

export const formatDateToYearMonth = (
  props: { year: Date },
  date: Date
): string => {
  const { year } = props
  const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0")
  const formattedYear = year.getFullYear().toString()
  return `${formattedYear}-${formattedMonth}`
}
