import { useLazyQuery, useQuery } from "@apollo/client"
import { Box, Grid, Autocomplete, useTheme, ThemeProvider } from "@mui/material"
import React, { useEffect, useState } from "react"
import { GET_MATERIALS, GET_PARTS } from "../../common/Query/MasterQuery"
import {
  GET_PART_PROCESSING,
  GET_WIP_CARD,
  GET_WIP_STOCK
} from "../../common/Query/MaterialQuery"
import { PageLabel } from "../../common/Resources/PageLabel"

import "../../css/Dashboard/Dashboard.css"
import "../../css/Planning/ProductionTag.css"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import moment from "moment"
import { WIPCardStamps } from "./component/WIPCard"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

interface IMatData {
  actionDate: string
  arcweld: number
  cost: number
  customer: string
  dateScan: string
  factory: string
  inOrOut: number
  indexNo: number
  isNG: number
  jobOrder: string
  leadTime: number
  matNo: string
  model: string
  packingBy: string
  partNo: string
  partName: string
  pdLotNo: string
  perBox: number
  plate: number
  quantity: number
  remarks: string
  stamp: number
  weld: number
}

const pageTitle = "Production Tag"

const ProductionTag: React.FC = () => {
  const theme = useTheme()
  const [partNo, setPartNo] = useState<string | null>("")
  const [jobOrder, setJobOrder] = useState<string | null>("")
  const [jobOrderList, setJobOrderList] = useState<string[]>([])
  const getPart = useQuery(GET_PARTS)
  const getMaterials = useQuery(GET_MATERIALS)
  const getPartProcessing = useQuery(GET_PART_PROCESSING)
  const [getStocks] = useLazyQuery(GET_WIP_STOCK)
  const [getWipCard, { loading }] = useLazyQuery(GET_WIP_CARD)
  const [dateTo, setDateTo] = useState<string>("")
  const [wipCard, setWipCard] = useState<IMatData[]>([])
  const [partNoList, setPartNoList] = useState<string[]>([])
  const [factory, setFactory] = useState<string>("")
  const factoryList: string[] = ["1", "2"]

  useEffect(() => {
    setPartNoList(
      getPart.data
        ? getPart.data.parts.map((e: { partNo: string }) => e.partNo)
        : []
    )
  }, [getPart.data])

  async function handleChangeDate(actionDate: string) {
    const date = moment(actionDate).format("DD-MM-YY")
    const stocks = await getStocks({
      variables: {
        actionDate: date
      }
    })
    setPartNoList(
      stocks.data.wipStocks.map((e: { partNo: string }) => e.partNo)
    )
  }
  const callMat = (partNo: string) => {
    return getMaterials.data.materials.filter(
      (e: { matNo: string }) => e.matNo === partNo
    )
  }

  const callProcess = (partNo: string) => {
    return getPartProcessing.data.partsprocessings.filter(
      (e: { partNo: string }) => e.partNo === partNo
    )
  }
  async function handleChange(partNo: string) {
    if (partNo !== "" && getMaterials.data && getPartProcessing.data) {
      const stocks = await getStocks({
        variables: {
          partNo
        }
      })

      const jobOrderList: string[] = Array.from(
        new Set(
          stocks.data.wipStocks
            .filter((parts: { partNo: string }) => parts.partNo === partNo)
            .sort((a: { actionDate: string }, b: { actionDate: string }) =>
              a.actionDate > b.actionDate
                ? -1
                : a.actionDate < b.actionDate
                ? 1
                : 0
            )
            .map((e: { jobOrder: string }) => e.jobOrder)
        )
      )

      setJobOrderList(jobOrderList)
    }
  }

  const handleSubmit = async () => {
    if (!(dateTo || partNo)) {
      return swal("ERROR", "กรุณาเลือกวันที่ หรือ Part No.", "warning")
    }
    if (partNo && !jobOrder) {
      return swal("ERROR", "กรุณาเลือก Job Order", "warning")
    }

    const wipcard = await getWipCard({
      variables: {
        partNo,
        jobOrder,
        actionDate: dateTo
      }
    })
    if (wipcard.data) {
      if (factory) {
        setWipCard(
          wipcard.data?.wipcard.filter(
            (data: IMatData) => data.factory === factory
          )
        )
      } else {
        setWipCard(wipcard.data?.wipcard)
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Planning}
        menuItemName={Object.keys(menuItemList)[2]}
        menuPageName={pageTitle}
      />

      <ThemedCard style={{ padding: "20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <PageLabelCard
            title="Production TAG"
            subTitle="Retrieve production tags"
          />

          <Box component="form" className="display-none-print">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <ThemedTextField
                  fullWidth
                  id="Document Name"
                  type="date"
                  value={dateTo}
                  onChange={(e) => {
                    handleChangeDate(e.target.value)
                    setDateTo(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  value={factory}
                  onChange={(event, selectedFactory) => {
                    setFactory(selectedFactory as string)
                  }}
                  options={factoryList}
                  renderInput={(params) => (
                    <ThemedTextField {...params} label="Factory" />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Autocomplete
                  loading={partNoList.length === 0}
                  disablePortal
                  id="partNo"
                  value={partNo}
                  options={partNoList}
                  style={{ width: "100%" }}
                  sx={{ width: 300 }}
                  onChange={(e, newValue) => {
                    handleChange(newValue ? newValue : "")
                    setPartNo(newValue ? newValue : "")
                    setJobOrder("")
                  }}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="Part No"
                      value={partNo}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Autocomplete
                  disablePortal
                  id="partNo"
                  disabled={!jobOrderList.length}
                  value={jobOrder}
                  options={jobOrderList}
                  style={{ width: "100%" }}
                  sx={{ width: 300 }}
                  onChange={(e, newValue) => setJobOrder(newValue)}
                  renderInput={(params) => (
                    <ThemedTextField {...params} label="Job Order" />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <ThemedLoadingButton
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2
                  }}
                  fullWidth
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Submit
                </ThemedLoadingButton>
              </Grid>
              <Grid item xs={12} sm={1}>
                <ThemedButton
                  contrast
                  style={{
                    marginLeft: "10px"
                  }}
                  fullWidth
                  variant="outlined"
                  color="inherit"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => window.print()}
                >
                  Print
                </ThemedButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <PageLabelCard title="Production Process Tag (ใบกำกับชิ้นส่วน)" />
        <div className="print-production-tag">
          {wipCard.map((item) => {
            return (
              <WIPCardStamps
                getBoxCount={Math.ceil(item.quantity / item.perBox) || 0}
                curMatLot={item}
                curMat={callMat(item.partNo) || []}
                partprocessing={callProcess(item.partNo) || []}
              />
            )
          })}
        </div>
      </ThemedCard>
    </ThemeProvider>
  )
}
export default ProductionTag
