import moment from "moment"
import React, { useEffect, useState } from "react"
import "./WIPCardStamp.css"
import QRCode from "qrcode.react"

import { useQuery } from "@apollo/client"
import { GET_MACHINES } from "../../../../common/Query/MasterQuery"
import "./WIPCardStamp.css"
import { IMachine } from "../../../Master/Machine"
import { getImagePng, miniLogo } from "../../../../common/utilities"
import { Grid } from "@mui/material"
interface curMatLot {
  actionDate: string
  arcweld: number
  cost: number
  customer: string
  dateScan: string
  factory: string
  inOrOut: number
  indexNo: number
  isNG: number
  jobOrder: string
  leadTime: number
  matNo: string
  model: string
  packingBy: string
  partNo: string
  partName: string
  pdLotNo: string
  perBox: number
  plate: number
  quantity: number
  remarks: string
  stamp: number
  weld: number
}

interface curMat {
  matNo: string
  matName: string
  partType: string
  ratio: number
  sizeH: string
  sizeL: string
  sizeW: string
  spec: string
  wipQtyBox: number
}
interface partprocessing {
  jph: number
  line: string
  machine: string
  minStroke: number
  partNo: string
  process: string
  processOrder: string
  stepProcess: number
  operator: string
}
interface Prop {
  data?: curMatLot
  curMat: curMat[]
  quantity: number
  boxCount: number
  boxNum: number
  partProcesses: partprocessing[]
}

export default function WIPCardStamp(props: Prop): JSX.Element {
  const [machines, setMachines] = useState<IMachine[]>([])
  const { data } = useQuery(GET_MACHINES)

  useEffect(() => {
    if (data && data.machine) {
      setMachines(data.machines)
    }
  }, [data])
  const getQR = () => {
    return `${props.data?.jobOrder}|${props.boxNum}|${props.data?.partNo}|${
      props.data?.pdLotNo.split("|")[0]
    }`
  }

  const shortMachines = () => {
    return props.partProcesses.map((process) => {
      const machine = process.machine
      const shortMachine = machines.find(
        (mac: { machine: string }) => mac.machine === machine
      )
      if (!shortMachine) return machine
      else return shortMachine.code
    })
  }
  const PartProcesses = () => {
    function compare(a: { stepProcess: number }, b: { stepProcess: number }) {
      if (a.stepProcess < b.stepProcess) {
        return -1
      }
      if (a.stepProcess > b.stepProcess) {
        return 1
      }
      return 0
    }
    if (props.partProcesses.length !== 0) props.partProcesses.sort(compare)
    return (
      <>
        {Array.from(Array(10), (e, i) => {
          return (
            <td className="thick" colSpan={1} key={i}>
              <div style={{ fontSize: "18px", whiteSpace: "nowrap" }}>
                {i < props.partProcesses.length
                  ? props.partProcesses[i].processOrder
                  : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }
  const ProcessesMC = () => {
    return (
      <>
        {Array.from(Array(11), (e, i) => {
          return (
            <td className="thick" colSpan={1} key={i}>
              <div style={{ fontSize: "18px", whiteSpace: "nowrap" }}>
                {shortMachines()[i]}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  const ProcessesLine = () => {
    return (
      <>
        {Array.from(Array(11), (e, i) => {
          return (
            <td className="thick" colSpan={1} key={i}>
              <div style={{ fontSize: "18px", whiteSpace: "nowrap" }}>
                {i < props.partProcesses?.length
                  ? props.partProcesses[i]?.line
                  : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  const ProcessesOperator = () => {
    return (
      <>
        {Array.from(Array(11), (e, i) => {
          return (
            <td className="thick" colSpan={1} key={i}>
              <div style={{ fontSize: "18px", whiteSpace: "nowrap" }}>
                {i < props.partProcesses?.length &&
                props.partProcesses[i]?.machine.startsWith("P2-") &&
                props.boxCount !== props.boxNum
                  ? props.partProcesses[i]?.operator
                  : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  const TotalQuantity = () => {
    return (
      <>
        {Array.from(Array(10), (e, i) => {
          return (
            <td className="thick" colSpan={1} key={i}>
              <div style={{ fontSize: "18px", whiteSpace: "nowrap" }}>
                {i < props.partProcesses?.length &&
                props.partProcesses[i]?.machine.startsWith("P2-") &&
                props.boxCount !== props.boxNum
                  ? props.quantity
                  : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  const shortPdLotNo = () => {
    const splitPdLotNo = props.data ? props.data?.pdLotNo.split("|") : []
    const partName = splitPdLotNo[1]
    return `${splitPdLotNo[0]}|${partName.substring(
      partName.length - 4,
      partName.length
    )}`
  }
  const shortJobOrder = () => {
    const splitJobOrder = props.data ? props.data?.jobOrder.split("|") : []
    const newReceipt = splitJobOrder[2].substring(
      splitJobOrder[2].length - 4,
      splitJobOrder[2].length
    )
    const matNo = splitJobOrder[1]
    const order = matNo.includes("-")
      ? matNo.substring(matNo.length - 6, matNo.length)
      : matNo.substring(matNo.length - 4, matNo.length)
    return `${splitJobOrder[0]}|${order}|${newReceipt}`
  }
  const getDate = () => {
    return moment(props.data?.actionDate).format("DD-MM-YY")
  }
  return (
    <Grid
      container
      item
      xs={12}
      className={`body_wip_card ${
        props.boxNum % 4 === 0 ? "break-after-tag" : ""
      }`}
    >
      <table
        className="table table-bordered"
        style={{
          border: "1px solid black",
          height: "500px",
          width: "100%",
          marginBottom: "70px",
          backgroundColor: "white",
          color: "black"
        }}
      >
        <tbody>
          <tr className="wip-title-row">
            <th
              className="thick"
              rowSpan={1}
              colSpan={1}
              style={{ width: "1%" }}
            >
              <div>
                <img src={miniLogo} className="logoPic" alt=""></img>
              </div>
            </th>
            <th
              className="wip-thick-empty-right"
              rowSpan={1}
              colSpan={8}
              style={{
                width: "99%"
              }}
            >
              <div className="row">
                <div
                  className="col-md-9"
                  style={{ fontSize: "30px", fontWeight: "bold" }}
                >
                  <div>Production Process Tag (ใบกำกับชิ้นส่วน)</div>
                  <br />
                  <br />
                  <span
                    style={{
                      fontSize: "24px",
                      whiteSpace: "nowrap"
                    }}
                  >
                    QR Code: {getQR()}
                  </span>
                </div>
              </div>
            </th>
            <th
              className="wip-special-thick"
              rowSpan={1}
              colSpan={3}
              style={{
                width: "99%"
              }}
            >
              <div className="row">
                <div
                  className="col-md-9"
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  <div>Part NO:{props.data?.partNo}</div>
                  <br />
                  <div>
                    Box Count:({props.boxNum}/{props.boxCount})
                  </div>
                </div>
              </div>
            </th>
            <th
              className="wip-special-thick"
              rowSpan={1}
              colSpan={2}
              style={{
                width: "99%",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              <div>F-PD-04-05</div>
              <br></br>
              <div>Effective Date: 04/01/21 Rev.01</div>
              <br></br>
              <div>Issue: 1</div>
              <br></br>
            </th>
          </tr>
          <tr className="wip-data-row">
            <th className="thick" colSpan={1}>
              CUSTOMER
            </th>
            <td className="thick" colSpan={1} style={{ width: "8%" }}>
              {props.data?.customer}
            </td>
            <th className="thick" colSpan={1} style={{ width: "1%" }}>
              DATE
            </th>
            {Array.from(Array(10), () => {
              return (
                <th className="thick" colSpan={1} style={{ width: "9%" }}></th>
              )
            })}
            <th className="thick" colSpan={1} style={{ width: "9%" }}>
              Remarks
            </th>
          </tr>
          <tr className="wip-data-row">
            <th className="thick" colSpan={1}>
              MODEL
            </th>
            <td className="thick" colSpan={1}>
              {props.data?.model}
            </td>
            <th className="thick" colSpan={1}>
              PROCESS
            </th>
            <PartProcesses></PartProcesses>
            <td className="thick" colSpan={1} style={{ width: "9%" }}>
              <samp style={{ fontSize: "20px", fontWeight: "bold" }}>
                {props.data?.remarks}
              </samp>
            </td>
          </tr>
          <tr className="wip-data-row">
            <th className="thick" colSpan={1}>
              Spec Mat'L
            </th>
            <td className="thick wip-super-long-input" colSpan={1}>
              {props.curMat[0].spec}
            </td>
            <th className="thick" colSpan={1}>
              M/C
            </th>
            <ProcessesMC></ProcessesMC>
          </tr>
          <tr className="wip-data-row">
            <th className="thick" colSpan={1}>
              Size
            </th>
            <td className="thick wip-long-input" colSpan={1}>
              {props.curMat[0].sizeH} x {props.curMat[0].sizeW} x{" "}
              {props.curMat[0].sizeL}
            </td>
            <th className="thick" colSpan={1}>
              LINE
            </th>
            <ProcessesLine></ProcessesLine>
          </tr>
          <tr className="wip-data-row">
            <th className="thick" colSpan={1}>
              J O N/Mat Lot No:
            </th>
            <td
              className="thick wip-super-long-input"
              colSpan={1}
              style={{ whiteSpace: "nowrap", fontSize: "18px" }}
            >
              {shortJobOrder()}
            </td>
            <th className="thick" colSpan={1}>
              SHIFT/กะ
            </th>
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
          </tr>
          <tr className="wip-data-row">
            <th className="thick" colSpan={1}>
              PD Lot No:
            </th>
            <td
              className="thick wip-long-input"
              colSpan={1}
              style={{ fontSize: "18px" }}
            >
              {shortPdLotNo()}
            </td>
            <th className="thick" colSpan={1} rowSpan={2}>
              OPERATOR
            </th>
            <ProcessesOperator />
          </tr>
          <tr className="wip-data-row">
            <th className="thick" colSpan={1}>
              P/Name:
            </th>
            <td
              className="thick wip-super-long-input"
              colSpan={1}
              style={{ whiteSpace: "nowrap" }}
            >
              {props.data?.partName}
            </td>
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
          </tr>
          <tr className="wip-sub-wip-data-row">
            <th className="thick" colSpan={1} rowSpan={5}>
              <div>WIPQty</div>
              <br />
              <br />
              <br />
              <div>{props.quantity}</div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <div>Lot Size</div>
              <br />
              <br />
              <br />
              <div>{props.data?.quantity}</div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <div>PD Date</div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <div>{getDate()}</div>
            </th>
            <td className="thick" colSpan={1} rowSpan={5}>
              <div style={{ textAlign: "center", paddingTop: "5px" }}>
                <span
                  style={{
                    textAlign: "center",
                    fontWeight: "bold"
                  }}
                >
                  PICTURE
                </span>
              </div>
              <div style={{ margin: "20px 0px 0px 0px" }}>
                <img
                  src={getImagePng(
                    props.data?.partNo
                      ? "images/parts/" + props.data.partNo
                      : ""
                  )}
                  className="partPic"
                  alt=""
                />
              </div>
            </td>
            <th className="thick wip-data-row" colSpan={1}>
              Total Q'ty
            </th>
            <TotalQuantity />
            <td className="thick" colSpan={1} rowSpan={5} align="center">
              <div style={{ margin: "20px 0px 0px 0px" }}>
                <QRCode value={getQR()} size={100} />
              </div>
            </td>
          </tr>
          <tr>
            <th className="thick wip-data-row" colSpan={1}>
              OK
            </th>
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
          </tr>

          <tr>
            <th className="thick wip-data-row" colSpan={1}>
              Detail NG
            </th>
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
          </tr>
          <tr>
            <th className="thick wip-data-row" colSpan={1}>
              QC
            </th>
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
            <td className="thick" colSpan={1} />
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}
