import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  styled,
  tableCellClasses,
  useTheme
} from "@mui/material"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import { miniLogo } from "../../../common/utilities"
import "../../../css/QC/QC.css"
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined"
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined"
import { GET_CUSTOMERS, GET_PARTS } from "../../../common/Query/MasterQuery"
import { useMemo, useState } from "react"
import { useLazyQuery, useQuery } from "@apollo/client"
import moment from "moment"
import { GET_MASTERPLAN_FOR_CREATE_OR_REPORT_CHECK_SHEET } from "../../../common/Query/QCQuery"
import swal from "sweetalert"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import PrintIcon from "@mui/icons-material/Print"

interface ITableData {
  no: number
  dueDate: string
  customer: string
  partNo: string
  plant: string
  pdLotNo: string
  quantity: number
  quantityActual: number
  wipQtyBox: number
  wipQtyBoxActual: number
  tag: boolean
  rusty: number
  deformed: number
  plating: number
  cleanliness: number
  upSideDownLetter: number
  randomInspection: boolean
  officer: string
  verifyOrderBy: string
  inspector: string
  approver: string
  action: string
}

interface IParts {
  partNo: string
  customer: string
}

const StyledTableRow = styled(TableRow)(() => ({
  "td, th": {
    border: "1px solid black"
  }
}))

const StyledTableCell = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "Sarabun",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Sarabun",
    fontSize: "16px",
    textAlign: "center",
    padding: "20px 5px 20px 5px"
    // whiteSpace: "nowrap"
  }
}))

const StyledTableCellThin = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  fontFamily: "Sarabun",
  fontSize: "17px",
  textAlign: "center",
  // padding: "0px 5px 0px 5px",
  // lineHeight: "2.5rem",
  whiteSpace: "nowrap"
}))

const StyledTableCellVertical = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  fontFamily: "Sarabun",
  fontSize: "17px",
  whiteSpace: "nowrap"
}))

const StyledTextField = styled(TextField)(() => ({
  width: "100px",
  fontSize: "10px"
}))

StyledTextField.defaultProps = {
  label: "ระบุตัวเลข",
  variant: "outlined"
}

const ReportDockAuditCheckSheet: React.FC = () => {
  const theme = useTheme()
  const [dataTable, setDataTable] = useState<ITableData[]>([])
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [customer, setCustomer] = useState<string>("")
  const [factory, setFactory] = useState<string>("")

  const { data: getCustomers } = useQuery(GET_CUSTOMERS)
  const listCustomers: string[] = useMemo(() => {
    if (!getCustomers || !getCustomers.customers) return []
    return getCustomers.customers.map(
      ({ customer }: { customer: string }) => customer
    )
  }, [getCustomers])

  const [getFilterData, { loading: loadingFilterData }] = useLazyQuery(
    GET_MASTERPLAN_FOR_CREATE_OR_REPORT_CHECK_SHEET,
    {
      onCompleted: (data) => {
        if (data && data.getMasterPlanForCreateOrReportCheckSheet.length) {
          const getOnlyDataThatHaveValueOfOfficer =
            data.getMasterPlanForCreateOrReportCheckSheet.filter(
              ({ officer }: { officer: string }) => {
                return officer !== null && officer !== undefined
              }
            )
          if (getOnlyDataThatHaveValueOfOfficer.length)
            setDataTable(getOnlyDataThatHaveValueOfOfficer)
          else {
            setDataTable([])
            swal("Error", "ไม่พบข้อมูล", "warning")
          }
        } else {
          setDataTable([])
          swal("Error", "ไม่พบข้อมูล", "warning")
        }
      }
    }
  )

  const handleGetFilter = async () => {
    if (dateFrom && dateTo && customer && factory) {
      await getFilterData({
        variables: {
          date: dateFrom,
          dateTo: dateTo,
          customer: customer,
          factory: factory,
          partNo: partNo
        },
        fetchPolicy: "network-only"
      })
    } else
      swal("Error", "ระบุวันที่, ชื่อลูกค้า, หมายเลขโรงงานไม่ครบ", "warning")
  }

  const [partNo, setPartNo] = useState<string>("")

  const { data: getPartNo } = useQuery(GET_PARTS)
  const listParts: string[] = useMemo(() => {
    if (!getPartNo || !getPartNo.parts || !customer) return []
    else {
      return getPartNo.parts
        .filter((item: IParts) => {
          return item.customer === customer
        })
        .map((item: IParts) => {
          return item.partNo
        })
    }
  }, [getPartNo, customer])

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        <Box className="none-display" sx={{ width: "98%", pt: "20px" }}>
          <Grid container spacing={2} margin={0}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <Grid item xs={12} md={2}>
                <DatePicker
                  openTo="day"
                  views={["year", "month", "day"]}
                  inputFormat="DD/MM/YYYY"
                  aria-label="date-from"
                  value={dateFrom}
                  onChange={(newStartDate) => {
                    setDateFrom(moment(newStartDate).format("YYYY-MM-DD"))
                  }}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      fullWidth
                      label="Date From"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <DatePicker
                  openTo="day"
                  views={["year", "month", "day"]}
                  inputFormat="DD/MM/YYYY"
                  aria-label="date-from"
                  value={dateTo}
                  onChange={(newEndDate) => {
                    setDateTo(moment(newEndDate).format("YYYY-MM-DD"))
                  }}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      fullWidth
                      label="Date To"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} md={2}>
              <Autocomplete
                value={customer}
                onChange={(event, value) => {
                  setCustomer(value as string)
                }}
                options={listCustomers}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Customer"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                value={factory}
                freeSolo
                onChange={(event, value) => {
                  setFactory(value as string)
                }}
                onInputChange={(event, freeHandInputValue) => {
                  setFactory(freeHandInputValue)
                }}
                options={["1", "2", "2-1"]}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Factory"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <Autocomplete
                value={partNo}
                onChange={(event, value) => {
                  setPartNo(value as string)
                }}
                options={listParts}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Part No"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <ThemedButton
                variant="outlined"
                sx={{ mt: 1 }}
                onClick={handleGetFilter}
              >
                SUBMIT FILTER
              </ThemedButton>
            </Grid>

            <Grid item>
              <ThemedButton
                variant="outlined"
                sx={{
                  mt: 1,
                  bgcolor: theme.palette.primary.dark
                }}
                endIcon={<PrintIcon />}
                onClick={() => window.print()}
              >
                Print
              </ThemedButton>
            </Grid>
          </Grid>
        </Box>

        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ p: "5px" }}>
            <Table aria-label="customized table" className="qc-absolute">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell colSpan={3}>
                    <img src={miniLogo} alt="BAE logo" width={"120px"} />
                  </StyledTableCell>
                  <StyledTableCell colSpan={14}>
                    <Box sx={{ fontSize: "25px" }}>
                      บันทึกการตรวจเช็คชิ้นงานสำเร็จรูปก่อนทำการส่งให้ลูกค้า
                    </Box>
                    <br />
                    <Box sx={{ fontSize: "25px" }}>
                      (DOCK AUDIT CHECK SHEET)
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell colSpan={4}>
                    <Box>Code No.: F-QA-03-05</Box>
                    <Box sx={{ fontWeight: "100", fontSize: "17px" }}>
                      Effective: 01/08/2024 Rev 04 Issue 1
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCellThin rowSpan={3}>No</StyledTableCellThin>
                  <StyledTableCellThin rowSpan={3} sx={{ width: "100px" }}>
                    Date
                  </StyledTableCellThin>
                  <StyledTableCellThin rowSpan={3}>
                    Customer
                  </StyledTableCellThin>
                  <StyledTableCellThin rowSpan={3}>
                    Part No.
                  </StyledTableCellThin>
                  <StyledTableCellVertical rowSpan={3}>
                    <Box className="vertical-bold-text-dock-audit">Lot No.</Box>
                  </StyledTableCellVertical>
                  <StyledTableCell colSpan={5}>Packing</StyledTableCell>
                  <StyledTableCell colSpan={5}>ตรวจคุณภาพ</StyledTableCell>
                  <StyledTableCellVertical colSpan={2} rowSpan={2}>
                    <Box
                      className="vertical-bold-text-dock-audit"
                      sx={{ fontWeight: "bold" }}
                    >
                      ผลการสุ่มตรวจ
                    </Box>
                  </StyledTableCellVertical>
                  <StyledTableCellVertical rowSpan={3}>
                    <Box className="vertical-text-dock-audit">ผู้จัดทำ</Box>
                  </StyledTableCellVertical>
                  <StyledTableCellVertical rowSpan={3}>
                    <Box className="vertical-text-dock-audit">ผู้ตรวจสอบ</Box>
                  </StyledTableCellVertical>
                  <StyledTableCellVertical rowSpan={3}>
                    <Box className="vertical-text-dock-audit">ผู้อนุมัติ</Box>
                  </StyledTableCellVertical>
                  <StyledTableCellThin rowSpan={3}>Action</StyledTableCellThin>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCellVertical rowSpan={2}>
                    <Box className="vertical-text-dock-audit">จำนวนส่ง</Box>
                  </StyledTableCellVertical>
                  <StyledTableCellVertical rowSpan={2}>
                    <Box className="vertical-text-dock-audit">จำนวนส่งจริง</Box>
                  </StyledTableCellVertical>
                  <StyledTableCell colSpan={2}>จำนวนชิ้น/ลัง</StyledTableCell>
                  <StyledTableCellThin rowSpan={2}>
                    TAG ที่สมบูรณ์
                  </StyledTableCellThin>

                  <StyledTableCellVertical rowSpan={2}>
                    <Box className="vertical-text-dock-audit">สนิม</Box>
                  </StyledTableCellVertical>
                  <StyledTableCellVertical rowSpan={2}>
                    <Box className="vertical-text-dock-audit">เสียรูป</Box>
                  </StyledTableCellVertical>
                  <StyledTableCellVertical rowSpan={2}>
                    <Box className="vertical-text-dock-audit">การชุบ</Box>
                  </StyledTableCellVertical>
                  <StyledTableCellVertical rowSpan={2}>
                    <Box className="vertical-text-dock-audit">ความสะอาด</Box>
                  </StyledTableCellVertical>

                  <StyledTableCellVertical rowSpan={2}>
                    <Box className="vertical-text-dock-audit">
                      ตัวอักษรไม่กลับหัว
                    </Box>
                  </StyledTableCellVertical>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCellThin>Std.</StyledTableCellThin>
                  <StyledTableCellThin>Actual</StyledTableCellThin>

                  <StyledTableCellThin>OK</StyledTableCellThin>
                  <StyledTableCellThin>NG</StyledTableCellThin>
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {dataTable && loadingFilterData ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={25}>
                      <CircularProgress size={"100px"} />
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  dataTable.map((e, idx) => {
                    return (
                      <StyledTableRow key={idx}>
                        <StyledTableCell>{idx + 1}</StyledTableCell>
                        <StyledTableCell>{e.dueDate}</StyledTableCell>
                        <StyledTableCell>{e.customer}</StyledTableCell>
                        <StyledTableCell>{e.partNo}</StyledTableCell>
                        <StyledTableCell>{e.pdLotNo}</StyledTableCell>
                        <StyledTableCell>{e.quantity}</StyledTableCell>
                        <StyledTableCell>{e.quantityActual}</StyledTableCell>
                        <StyledTableCell>{e.wipQtyBox}</StyledTableCell>
                        <StyledTableCell>{e.wipQtyBoxActual}</StyledTableCell>
                        <StyledTableCell>
                          {e.tag ? (
                            <CheckBoxOutlinedIcon
                              sx={{
                                fontSize: 30
                              }}
                            />
                          ) : (
                            <CheckBoxOutlineBlankOutlinedIcon
                              sx={{
                                fontSize: 30
                              }}
                            />
                          )}
                        </StyledTableCell>

                        <StyledTableCell>{e.rusty}</StyledTableCell>

                        <StyledTableCell>{e.deformed}</StyledTableCell>
                        <StyledTableCell>{e.plating}</StyledTableCell>
                        <StyledTableCell>{e.cleanliness}</StyledTableCell>

                        <StyledTableCell>{e.upSideDownLetter}</StyledTableCell>

                        <StyledTableCell colSpan={2}>
                          {e.randomInspection}
                        </StyledTableCell>
                        <StyledTableCell>{e.officer}</StyledTableCell>
                        <StyledTableCell>{e.inspector}</StyledTableCell>
                        <StyledTableCell>{e.approver}</StyledTableCell>
                        <StyledTableCell>{e.action}</StyledTableCell>
                      </StyledTableRow>
                    )
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default ReportDockAuditCheckSheet
