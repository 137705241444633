import React, { SyntheticEvent, useState } from "react"
import Grid from "@mui/material/Grid"
import UploadFiles from "./mpcomponents/UploadFiles"
import AddDueDates from "./mpcomponents/AddDueDates"
import CustomerAndParts from "./mpcomponents/CustomerAndParts"
import AddDueDatesMMTh from "./mpcomponents/AddDueDatesMMTh"
import { PageLabel } from "../../common/Resources/PageLabel"
import { Box, ThemeProvider, useTheme } from "@mui/material"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { useMutation } from "@apollo/client"
import {
  MP_UPLOAD_FILE,
  ORDER_UPLOAD_FILE
} from "../../common/Mutation/MasterPlanMutation"
import swal from "sweetalert"
import { TabPanel, Tabs } from "bitsoft-c3"
import UploadOrderGreenTag from "./mpcomponents/UploadOrderGreenTag"
const pageTitle = "Order ActualPlan"

const MPEntry: React.FC = () => {
  const [uploadFile, { loading }] = useMutation(MP_UPLOAD_FILE)
  const [uploadFileOtherCus, { loading: otherOrder }] =
    useMutation(ORDER_UPLOAD_FILE)
  const [customer, setCustomer] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [tapMenu, setTapMenu] = useState<number>(0)

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTapMenu(newValue)
  }

  const theme = useTheme()

  const handleUploadFile = async (e: SyntheticEvent) => {
    e.preventDefault()
    try {
      await uploadFile({ variables: { file: selectedFile } })
      swal("Upload Success!", "", "success")
    } catch (err) {
      swal(`${(err as Error).message}`, "", "error")
    }
  }
  const handleUploadFileOtherCus = async (e: SyntheticEvent) => {
    e.preventDefault()
    try {
      await uploadFileOtherCus({
        variables: { file: selectedFile, customer: customer }
      })
      swal("Upload Success!", "", "success")
    } catch (err) {
      swal(`${(err as Error).message}`, "", "error")
    }
  }
  let displayPage = <></>
  if (
    customer === "MMTh" ||
    customer === "H-ONE" ||
    customer === "EMERSON" ||
    customer === "MAHLE"
  ) {
    displayPage = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UploadFiles
            loading={loading || otherOrder}
            functionUpload={
              customer === "MMTh" ? handleUploadFile : handleUploadFileOtherCus
            }
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.MasterPlan}
        menuItemName={Object.keys(menuItemList)[7]}
        menuPageName={pageTitle}
      />
      <Box width={"50vw"}>
        <Tabs
          tabItems={[" MP Due Date Entry", "Add Order In green Tag"]}
          currTabIndex={tapMenu}
          handleSwitchTab={handleChange}
          tabProps={{
            style: {
              backgroundColor: theme.palette.primary.light
            }
          }}
        />
      </Box>
      <TabPanel currTabIndex={tapMenu} tabIndex={0}>
        <ThemedCard>
          <PageLabelCard title="MP Due Date Entry" />
          <Grid container spacing={2} width={"98%"} margin={2}>
            <Grid item xs={12}>
              <CustomerAndParts
                customer={customer}
                partNo={partNo}
                setCustomer={setCustomer}
                setPartNo={setPartNo}
              />
            </Grid>
            <Grid item xs={6}>
              {displayPage}
            </Grid>
          </Grid>
          {customer !== "MAHLE" && partNo !== "MD050076V1" && partNo ? (
            <AddDueDatesMMTh partNo={partNo} />
          ) : (customer == "MAHLE" && partNo) || partNo == "MD050076V1" ? (
            <AddDueDates partNo={partNo} />
          ) : (
            <></>
          )}
        </ThemedCard>
      </TabPanel>
      <TabPanel currTabIndex={tapMenu} tabIndex={1}>
        <UploadOrderGreenTag />
      </TabPanel>
    </ThemeProvider>
  )
}

export default MPEntry
