import { useTheme } from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useRef } from "react"
import { useQuery } from "@apollo/client"
import { GET_NUMBER_MACHINE_REPAIR_TIME } from "../../../../common/Query/MaintenanceQuery"

interface IRawData {
  machineCode: string
  sumTime: string
}

export const MaintenanceTimeDashboard = (props: {
  lineData: string
  factory: string
  year: string
  month: string
}): JSX.Element => {
  const { lineData, factory, year, month } = props

  const { data: maintenanceTime } = useQuery(GET_NUMBER_MACHINE_REPAIR_TIME, {
    variables: {
      year: year,
      line: lineData || "",
      factory: factory,
      month: month
    }
  })

  const rawData = maintenanceTime?.getMachineRepairTimePerYear || []

  const theme = useTheme()

  const columnOptions: Highcharts.Options = {
    chart: {
      type: "column",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "เวลาที่เครื่องจักร maintenance",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },

    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    },
    xAxis: {
      categories: rawData.map((val: IRawData) => val.machineCode),
      labels: {
        x: -10,
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "10px"
        }
      }
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "Total",
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      }
    },
    series: [
      {
        type: "column",
        name: "Time",
        data: rawData.map((val: IRawData) => Number(val.sumTime))
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  return (
    <>
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={columnOptions}
          ref={chartComponentRef}
        />
      </div>
    </>
  )
}
