import { ThemeProvider } from "@mui/styles"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Theme,
  useTheme
} from "@mui/material"
import { DataGrid } from "bitsoft-c3"
import {
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp
} from "@mui/x-data-grid"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import { useRef, useState } from "react"
import Swal from "sweetalert2"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import moment from "moment"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { user } from "../../common/MainApp"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import {
  GET_LOGISTICS_REPORT,
  GET_LOGISTICS_REPORT_CAR_LICENSE,
  GET_LOGISTICS_REPORT_CUSTOMERS
} from "../../common/Query/LogisticsQuery"
import SearchIcon from "@mui/icons-material/Search"
import { UPDATE_LOGISTICS_REPORT } from "../../common/Mutation/LogisticsMutation"

interface ILogistics {
  logisticsId: number
  deliveryDate: string
  fromOrigin: string
  companyCustom: string
  poCustomer: string
  invoiceNo: number
  amountSales: number
  carPerWay: number
  cost: number
  drop: number
  advance: number
  premiumFreight: number
  transportCost: number
  userRecord: string
  carLicense: string
  remarks: string
  isNew?: boolean
}

const makeColumns = (
  handleCancelClick: (id: GridRowId) => () => void,
  handleEditClick: (id: GridRowId) => () => void,
  handleSaveClick: (id: GridRowId) => () => void,
  rowModesModel: GridRowModesModel,
  theme: Theme
): GridColDef[] => {
  const column: GridColDef[] = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          return [
            <Button
              startIcon={<SaveIcon />}
              onClick={handleSaveClick(id)}
              sx={{
                color: theme.palette.text.primary,
                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme.palette.primary.main
                }
              }}
            />,
            <Button
              startIcon={<CancelIcon />}
              className="textPrimary"
              onClick={handleCancelClick(id)}
              sx={{
                color: theme.palette.text.primary,
                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme.palette.text.secondary
                }
              }}
            />
          ]
        }
        return [
          <Button
            startIcon={<EditIcon />}
            className="textPrimary"
            onClick={handleEditClick(id)}
            sx={{
              color: theme.palette.text.primary,
              "&:hover": {
                backgroundColor: "transparent",
                color: theme.palette.text.secondary
              }
            }}
          />
        ]
      }
    },
    {
      field: "logisticsId",
      headerName: "ID",
      headerAlign: "center",
      align: "center"
    },
    {
      field: "deliveryDate",
      headerName: "Delivery Date",
      minWidth: 200,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "fromOrigin",
      headerName: "From Origin",
      minWidth: 200,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "companyCustom",
      headerName: "Company Custom",
      headerAlign: "center",
      minWidth: 200,
      align: "center"
    },
    {
      field: "poCustomer",
      headerName: "PO Customer",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      editable: true
    },
    {
      field: "invoiceNo",
      headerName: "Invoice No",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      editable: true
    },
    {
      field: "amountSales",
      headerName: "Amount Sales",
      minWidth: 100,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "carPerWay",
      headerName: "Car Per Way",
      minWidth: 160,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "cost",
      headerName: "Cost",
      minWidth: 120,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "drop",
      headerName: "Drop",
      minWidth: 180,
      headerAlign: "center",
      align: "center",
      type: "number",
      editable: true
    },
    {
      field: "advance",
      headerName: "Advance",
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      type: "number",
      editable: true
    },
    {
      field: "premiumFreight",
      headerName: "Premium Freight",
      minWidth: 80,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "transportCost",
      headerName: "Transport Cost",
      type: "number",
      minWidth: 180,
      editable: true,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "userRecord",
      headerName: "User Record",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "carLicense",
      headerName: "Car License",
      minWidth: 180,
      headerAlign: "center",
      align: "center",
      editable: true
    },
    {
      field: "remarks",
      headerName: "Remarks",
      minWidth: 180,
      headerAlign: "center",
      align: "center",
      editable: true
    }
  ]
  return column
}

const LogisticsCostEdit: React.FC = () => {
  const theme = useTheme()
  const [rows, setRows] = useState<GridRowsProp>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const rowIdRef = useRef<GridRowId>()
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [carLicense, setCarLicense] = useState<string>("")
  const [deliveryCustomer, setDeliveryCustomer] = useState<string>("")
  const [premiumFreight, setPremiumFreight] = useState<boolean>(false)

  const { data: carLicenseData, loading: carLicenseLoading } = useQuery(
    GET_LOGISTICS_REPORT_CAR_LICENSE
  )
  const carLicenseList =
    !carLicenseLoading && carLicenseData
      ? carLicenseData.carLicense.map(
        ({ carLicense }: { carLicense: string }) => carLicense
      )
      : [""]

  const { data: customerData, loading: customerLoading } = useQuery(
    GET_LOGISTICS_REPORT_CUSTOMERS
  )
  const deliveryCustomerList =
    !customerLoading && customerData
      ? customerData.companycustom.map(
        ({ companyName }: { companyName: string }) => companyName
      )
      : [""]

  const [getLogisticsReport, { loading: loadLogisticsReport }] = useLazyQuery(
    GET_LOGISTICS_REPORT,
    {
      onCompleted: (data) => {
        if (data && data.logisticReport.entry.length) {
          setRows(data.logisticReport.entry)
        } else {
          Swal.fire({ title: `ไม่พบข้อมูล`, icon: "info" })
        }
      }
    }
  )
  const handleSearch = async () => {
    setRows([])
    await getLogisticsReport({
      variables: {
        dateFrom: moment(startDate).format("YYYY-MM-DD"),
        dateTo: moment(endDate).format("YYYY-MM-DD"),
        carRegistration: carLicense || "",
        customer: deliveryCustomer,
        premiumFreight: premiumFreight
      },
      fetchPolicy: "network-only"
    })
  }

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit }
    })
  }
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View }
    })
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
    const editedRow = rows.find((row) => row.logisticsId === id)
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.logisticsId !== id))
    }
  }

  const handleProcessError = async (error: Error) => {
    const id = rowIdRef.current as GridRowId
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
    return Swal.fire({
      title: "error",
      text: error.message,
      icon: "error"
    })
  }

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false }
    await handleSaveData(updatedRow as ILogistics, "update")
    setRows(
      rows.map((row) =>
        row.logisticsId === newRow.logisticsId ? updatedRow : row
      )
    )
    return updatedRow
  }

  const [updateLogisticsReport] = useMutation(UPDATE_LOGISTICS_REPORT, {
    awaitRefetchQueries: true,
    refetchQueries: [GET_LOGISTICS_REPORT]
  })

  const handleSaveData = async (updateRow: ILogistics, type: string) => {
    if (type === "update") {
      try {
        const updated = await updateLogisticsReport({
          variables: {
            logisticsId: updateRow.logisticsId,
            poCustomer: updateRow.poCustomer,
            invoiceNo: updateRow.invoiceNo,
            drop: updateRow.drop,
            advance: updateRow.advance,
            transportCost: updateRow.transportCost,
            carLicense: updateRow.carLicense,
            remarks: updateRow.remarks,
            updatedBy: user.employee,
            fromPage: "Logistics > Logistics Cost Edit"
          }
        })
        handleSearch()
        Swal.fire({
          title: "สำเร็จ",
          text: "ข้อมูลถูกแก้ไขเรียบร้อยแล้วนะ",
          icon: "success"
        })

        return updated.data?.updateLogisticsReport
      } catch (error) {
        Swal.fire({
          title: "error",
          text: (error as Error).message,
          icon: "error"
        })
      }
    }
  }

  const columns = makeColumns(
    handleCancelClick,
    handleEditClick,
    handleSaveClick,
    rowModesModel,
    theme
  )

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <PageLabel
          menuItem={menuItemList.Logistics}
          menuItemName={Object.keys(menuItemList)[8]}
          menuPageName={"Logistics Cost Edit"}
        />
        <ThemedCard>
          <PageLabelCard
            title="Cost Edit"
            subTitle="ใช้แก้ไขค่าข้อมูล Cost Logistic"
          />
          <Divider
            sx={{ height: 1, bgcolor: theme.palette.secondary.contrastText }}
          />
          <br />
          <Grid container spacing={2} sx={{ p: "20px" }}>
            <Grid item xs={12} sm={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  openTo="day"
                  views={["year", "month", "day"]}
                  inputFormat="DD/MM/YYYY"
                  value={startDate}
                  onChange={(newStartDate) => {
                    setStartDate(moment(newStartDate).toDate() as Date)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      fullWidth
                      label="Date From"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  openTo="day"
                  views={["year", "month", "day"]}
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  onChange={(newEndDate) => {
                    setEndDate(moment(newEndDate).toDate() as Date)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      fullWidth
                      label="Date To"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Autocomplete
                fullWidth
                value={carLicense}
                disablePortal
                onChange={(event, selectedCarLicense) => {
                  setCarLicense(selectedCarLicense as string)
                }}
                options={carLicenseList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="ทะเบียนรถบรรทุก"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Autocomplete
                fullWidth
                value={deliveryCustomer}
                disablePortal
                onChange={(event, selectedDeliveryCustomer) => {
                  setDeliveryCustomer(selectedDeliveryCustomer as string)
                }}
                options={deliveryCustomerList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Customer"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setPremiumFreight(event.target.checked)
                    }
                  />
                }
                value={premiumFreight}
                label="	Premium Freight"
              />
            </Grid>

            <Grid item xs={4} md={4}>
              <ThemedLoadingButton
                loading={loadLogisticsReport}
                style={{ backgroundColor: theme.palette.primary.main }}
                variant="contained"
                onClick={handleSearch}
                endIcon={<SearchIcon />}
              >
                ค้นหา
              </ThemedLoadingButton>
            </Grid>

            <Grid item xs={12}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                dataGridProps={{
                  rowModesModel: rowModesModel,
                  onRowEditStop: handleRowEditStop,
                  processRowUpdate: processRowUpdate,
                  onProcessRowUpdateError: handleProcessError,
                  getRowHeight: () => "auto",
                  getRowId: (row) => row.logisticsId,
                  sx: {
                    bgcolor: theme.palette.secondary.main,
                    "& .MuiGrid-root .MuiButtonBase-root": {
                      color: theme.palette.secondary.contrastText,
                      "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.1) !important"
                      }
                    },
                    "& .MuiDataGrid-row:nth-of-type(odd)": {
                      backgroundColor: theme.palette.background.default
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </ThemedCard>
      </Box>
    </ThemeProvider>
  )
}

export default LogisticsCostEdit
