import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import CircularProgress from "@mui/material/CircularProgress"
import AddIcon from "@mui/icons-material/Add"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import Input from "../../common/Resources/Input"
import { useMemo, useState } from "react"
import Grid from "@mui/material/Grid"
import TablePagination from "@mui/material/TablePagination"
import { ADD_PARTS_PROCESS } from "../../common/Mutation/MasterMutation"
import {
  GET_MACHINES,
  GET_PARTS,
  GET_PARTS_PROCESS,
  GET_PROCESS
} from "../../common/Query/MasterQuery"
import {
  useQuery,
  useMutation,
  OperationVariables,
  QueryResult
} from "@apollo/client"
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  useTheme
} from "@mui/material"

import "../../css/Master/Parts_Process.css"
import EditPartProcess from "./EditComponent/EditPartProcess"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import { GET_LINE } from "../../common/Query/GeneralQuery"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { CSVLink } from "react-csv"

export interface IPartsProcessing {
  line: string
  machine: string
  minStroke: number
  partNo: string
  process: string
  processOrder: string
  stepProcess: number
  jph: number
  operator: string
  customer: string
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.secondary.contrastText,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.black
  },

  "td, th": {
    border: 0
  }
}))

export const DataPartProcessList: React.FC = () => {
  const theme = useTheme()
  const { loading, error, data } = useQuery(GET_PARTS_PROCESS)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [search, setSearch] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [openAddPartProcess, setOpenAddPartProcess] = useState<boolean>(false)
  const [openEditPartProcess, setOpenEditPartProcess] = useState<boolean>(false)

  if (loading)
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ padding: "10px" }}
      >
        <CircularProgress />
      </Grid>
    )

  if (error) return <p>Error: {error}</p>
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const partProcessList: IPartsProcessing[] = data?.partsprocessings || []

  const emptyRows: number =
    rowsPerPage -
    Math.min(rowsPerPage, partProcessList.length - page * rowsPerPage)

  const partsProcessingsListFilter = partProcessList.filter(
    (partProcess: IPartsProcessing) => {
      const matchesSearch = search ? partProcess.partNo === search : true
      const matchesCustomer = customer
        ? partProcess.customer === customer
        : true
      return matchesSearch && matchesCustomer
    }
  )

  const partsProcessListSearch: string[] = Array.from(
    new Set(
      partsProcessingsListFilter.map((e: IPartsProcessing) => {
        return e.partNo ? e.partNo : ""
      })
    )
  )

  const customerList: string[] = Array.from(
    new Set(
      partsProcessingsListFilter.map((e: IPartsProcessing) => {
        return e.customer ? e.customer : ""
      })
    )
  )

  const partsProcessingsList = partsProcessingsListFilter
    .sort((a: { partNo: string }, b: { partNo: string }) =>
      a.partNo > b.partNo ? 1 : a.partNo < b.partNo ? -1 : 0
    )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((partsprocessings: IPartsProcessing) => {
      return (
        <StyledTableRow
          key={
            partsprocessings.partNo +
            partsprocessings.process +
            partsprocessings.processOrder
          }
        >
          <StyledTableCell component="th" scope="row">
            {partsprocessings.partNo}
          </StyledTableCell>
          <StyledTableCell align="left">
            {partsprocessings.process}
          </StyledTableCell>
          <StyledTableCell align="left">
            {partsprocessings.customer}
          </StyledTableCell>
          <StyledTableCell align="left">
            {partsprocessings.processOrder}
          </StyledTableCell>
          <StyledTableCell align="left">
            {partsprocessings.stepProcess}
          </StyledTableCell>
          <StyledTableCell align="left">
            {partsprocessings.minStroke}
          </StyledTableCell>
          <StyledTableCell align="left">
            {partsprocessings.machine}
          </StyledTableCell>
          <StyledTableCell align="left">
            {partsprocessings.line}
          </StyledTableCell>
          <StyledTableCell align="left">{partsprocessings.jph}</StyledTableCell>
          <StyledTableCell align="left">
            {partsprocessings.operator}
          </StyledTableCell>
        </StyledTableRow>
      )
    })
  return (
    <>
      <AddNewPartProcess
        setOpenAddPartProcess={setOpenAddPartProcess}
        openAddPartProcess={openAddPartProcess}
      />
      <EditPartProcess
        setOpenEditPartProcess={setOpenEditPartProcess}
        openEditPartProcess={openEditPartProcess}
      />
      <Grid container spacing={2} width={"98%"} padding={2}>
        <Grid item xs={2} sm={2}>
          <Autocomplete
            id="customer"
            freeSolo
            value={customer}
            options={customerList}
            style={{ width: "100%" }}
            sx={{ width: 100 }}
            onChange={(e, newValue) => {
              setPage(0)
              setCustomer(newValue ? newValue : "")
            }}
            renderInput={(params) => (
              <ThemedTextField
                style={{ marginLeft: "15px" }}
                {...params}
                label="Customer"
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          <Autocomplete
            id="search-partsprocess"
            freeSolo
            value={search}
            options={partsProcessListSearch}
            style={{ width: "100%", fontFamily: "Sarabun" }}
            sx={{ width: 100 }}
            onChange={(e, newValue) => {
              setPage(0)
              setSearch(newValue ? newValue : "")
            }}
            renderInput={(params) => (
              <ThemedTextField
                style={{ marginLeft: "15px", fontFamily: "Sarabun" }}
                {...params}
                label="Part No. Process"
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sm={8}
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <CSVLink
              data={partsProcessingsListFilter.map((val: IPartsProcessing) => {
                return {
                  "Part No": val.partNo,
                  Process: val.process,
                  Customer: val.customer,
                  "Process Order": val.processOrder.includes("/")
                    ? ` ${val.processOrder}`
                    : val.processOrder,
                  "Step Process": val.stepProcess,
                  "Min Stroke": val.minStroke,
                  Machine: val.machine,
                  Line: val.line,
                  "Job Per Hour": val.jph,
                  Operator: val.operator
                }
              })}
              filename={`Part Process`}
            >
              <ThemedButton
                variant="contained"
                style={{
                  marginLeft: "15px",
                  backgroundColor:
                    theme.palette.mode === "light" ? "yellowgreen" : "darkgreen"
                }}
              >
                Download CSV
              </ThemedButton>
            </CSVLink>
          </Grid>
          <Grid item>
            <ThemedButton
              variant="contained"
              onClick={() => setOpenEditPartProcess(true)}
            >
              EDIT PART PROCESS
            </ThemedButton>
          </Grid>
          <Grid item>
            <ThemedButton
              contrast
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenAddPartProcess(true)}
            >
              ADD PART PROCESS
            </ThemedButton>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Part No</StyledTableCell>
              <StyledTableCell>Process</StyledTableCell>
              <StyledTableCell>Customer</StyledTableCell>
              <StyledTableCell>Process Order</StyledTableCell>
              <StyledTableCell>Step Process</StyledTableCell>
              <StyledTableCell>Min Stroke</StyledTableCell>
              <StyledTableCell>Machine</StyledTableCell>
              <StyledTableCell>Line</StyledTableCell>
              <StyledTableCell>Job Per Hour</StyledTableCell>
              <StyledTableCell>Operator</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partsProcessingsList}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={partsProcessingsListFilter.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ backgroundColor: theme.palette.warning.light }}
        />
      </TableContainer>
    </>
  )
}
type TDataGetPart = {
  parts: [{ partNo: string }]
}

export const makePartNoList = (
  getPart: QueryResult<TDataGetPart, OperationVariables>
): string[] => {
  const { loading: queryLoading, data } = getPart

  const partNoArray: string[] = queryLoading
    ? []
    : data?.parts?.map(({ partNo }: { partNo: string }) => partNo) || []

  // make array with unique values
  const partNoList = Array.from(new Set(partNoArray)).sort()
  return partNoList
}

type TDataProcess = {
  process: [{ process: string }]
}
export const makeProcessList = (
  process: QueryResult<TDataProcess, OperationVariables>
): string[] => {
  const { loading: queryLoading, data } = process
  const processArray: string[] = queryLoading
    ? []
    : data?.process?.map(({ process }: { process: string }) => process) || []

  //make array with unique values
  const processList = Array.from(new Set(processArray)).sort()
  return processList
}

type TDataMachine = {
  machines: [{ machine: string }]
}

export const makeMachineList = (
  machines: QueryResult<TDataMachine, OperationVariables>
): string[] => {
  const { loading: queryLoading, data } = machines
  const machineArray: string[] = queryLoading
    ? []
    : data?.machines?.map(({ machine }: { machine: string }) => machine) || []

  //make array with unique values
  const machineList = Array.from(new Set(machineArray)).sort()
  return machineList
}

type TDataPartsProcessQuery = {
  partsprocessings: [{ line: string }]
}

export const makeLineList = (
  partsProcessQuery: QueryResult<TDataPartsProcessQuery, OperationVariables>
): string[] => {
  const { loading: queryLoading, data } = partsProcessQuery
  const lineArray: string[] = queryLoading
    ? []
    : data?.partsprocessings?.map(({ line }: { line: string }) => line) || []

  //make array with unique values
  //arrange Line A - D in order for practical purposes
  const lineList = Array.from(new Set(lineArray)).sort()
  return lineList
}

interface IPropsAddPart {
  setOpenAddPartProcess: (boolean: boolean) => void
  openAddPartProcess: boolean
}

export const AddNewPartProcess: React.FC<IPropsAddPart> = (
  props: IPropsAddPart
) => {
  const { setOpenAddPartProcess, openAddPartProcess } = props
  const partsProcessQuery = useQuery(GET_PARTS_PROCESS)
  const getPart = useQuery(GET_PARTS)
  const getProcess = useQuery(GET_PROCESS)
  const getMachines = useQuery(GET_MACHINES)
  const [addPartProcess, { loading: mutationLoading }] = useMutation(
    ADD_PARTS_PROCESS,
    {
      refetchQueries: [GET_PARTS_PROCESS],
      awaitRefetchQueries: true
    }
  )
  const [partNo, setPartNo] = useState<string>("")
  const [process, setProcess] = useState<string>("")
  const [machine, setMachine] = useState<string>("")
  const [line, setLine] = useState<string>("")
  const [jobsPerHour, setJobsPerHour] = useState<number>(0)
  const [minStroke, setMinStroke] = useState<number>(0)
  const [processOrder, setProcessOrder] = useState<string>("")
  const [operator, setOperator] = useState<string>("")
  const [stepProcess, setStepProcess] = useState<number>(0)
  const [error, setError] = useState<boolean>(false)
  const partNoList = useMemo(() => makePartNoList(getPart), [getPart])
  const processList = useMemo(() => makeProcessList(getProcess), [getProcess])
  const machineList = useMemo(() => makeMachineList(getMachines), [getMachines])
  const getLine = useQuery(GET_LINE)
  let lineList = []
  if (getLine.data) {
    lineList = getLine.data.getLine.map(
      (element: { lineName: string }) => element.lineName
    )
  }

  if (partsProcessQuery.error) return <p>Error: {partsProcessQuery.error}</p>

  const isComplete = () => {
    return (
      partNo &&
      process &&
      machine &&
      line &&
      jobsPerHour &&
      minStroke &&
      stepProcess &&
      processOrder
    )
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!isComplete()) {
      setError(true)
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
    try {
      await addPartProcess({
        variables: {
          partNo,
          process,
          processOrder,
          stepProcess,
          minStroke,
          machine,
          line,
          jph: jobsPerHour,
          operator
        }
      })
      swal("Success", "Add Part Process Successful", "success")
      setOpenAddPartProcess(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openAddPartProcess}
        onClose={() => setOpenAddPartProcess(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Add New Part Process
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            spacing={2}
            style={{ textAlign: "center", marginTop: "5px" }}
          >
            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                defaultValue={""}
                disableClearable
                disablePortal
                onChange={(event, selectedPartNo) => {
                  setPartNo(selectedPartNo as string)
                }}
                loading={!!partNoList}
                options={partNoList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Part No."
                    value={partNo}
                    error={!partNo && error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                defaultValue={""}
                disableClearable
                disablePortal
                onChange={(event, selectedProcess) => {
                  setProcess(selectedProcess as string)
                }}
                options={processList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Process"
                    value={process}
                    error={!process && error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                defaultValue={""}
                disableClearable
                disablePortal
                onChange={(event, selectedMachine) => {
                  setMachine(selectedMachine as string)
                }}
                options={machineList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Machine"
                    value={machine}
                    error={!machine && error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                defaultValue={""}
                disableClearable
                disablePortal
                onChange={(event, selectedLine) => {
                  setLine(selectedLine as string)
                }}
                options={lineList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Line"
                    value={line}
                    error={!line && error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                label="Jobs Per Hour"
                type="number"
                onChange={(event) => {
                  setJobsPerHour(+event.target.value)
                }}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                label="Min Stroke"
                type="number"
                onChange={(event) => {
                  setMinStroke(+event.target.value)
                }}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Input
              fullWidth
              text={"Process Order"}
              setValue={(event) => {
                setProcessOrder(event.target.value)
              }}
              sm={12}
              inputType="string"
            />
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                label="Step Process"
                type="number"
                onChange={(event) => {
                  setStepProcess(+event.target.value)
                }}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                label="Operator"
                type="text"
                value={operator ? operator : ""}
                onChange={(event) => {
                  setOperator(event.target.value)
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={mutationLoading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenAddPartProcess(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const pageTitle = "Parts Process"

export const PartsProcess: React.FC = () => {
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Master}
        menuItemName={Object.keys(menuItemList)[0]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="Parts Process"
          subTitle="About detail Parts Process"
        />
        <DataPartProcessList />
      </ThemedCard>
    </ThemeProvider>
  )
}

export default PartsProcess
