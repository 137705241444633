import { gql } from "@apollo/client"

export const GET_MONTHLY_DEFECT = gql`
  query InventoryDefectReport($dateFrom: String, $dateTo: String) {
    inventoryDefectReport(dateFrom: $dateFrom, dateTo: $dateTo) {
      totalING
      totalTNG
      totalSNG
      totalSupplierNG
    }
  }
`

export const GET_MONTHLY_BREAK_DOWN_MACHINE = gql`
  query BreakDownMachineMonthlyReport($dateFrom: String, $dateTo: String) {
    breakDownMachineMonthlyReport(dateFrom: $dateFrom, dateTo: $dateTo) {
      totalTime
      machine
      problemName
    }
  }
`
export const GET_MONTHLY_BREAK_DOWN_PROBLEM = gql`
  query BreakDownProblemMonthlyReport($dateFrom: String, $dateTo: String) {
    breakDownProblemMonthlyReport(dateFrom: $dateFrom, dateTo: $dateTo) {
      totalTime
      remarks
      problemName
    }
  }
`

export const GET_INVENTORY_VS_SALE_ORDER = gql`
  query InventoryDashboard($date: String, $type: String) {
    inventoryDashboard(date: $date, type: $type) {
      filterData {
        balanceMatStock
        customer
        balanceFgStock
        saleOrder
      }
      sumInventory {
        saleOrder
        matStock
        fgStock
      }
    }
  }
`
