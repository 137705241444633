import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { bankImage, getImagePng, miniLogo } from "../../../common/utilities"
import { useMutation, useQuery } from "@apollo/client"
import { GET_ZINC_RACK } from "../../../common/Query/PlatingQuery"
import { ReactElement, useEffect, useState } from "react"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import swal from "sweetalert"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import {
  ADD_ZINC_RACK,
  DELETE_ZINC_RACK,
  UPDATE_ZINC_RACK
} from "../../../common/Mutation/PlatingMutation"
import PrintIcon from "@mui/icons-material/Print"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import { GET_PARTS } from "../../../common/Query/MasterQuery"
import { usePartNo } from "../../../common/Resources/customHook/usePartNo"
import { IPart } from "../../Master/Parts"

interface IReportZincRack {
  item: number
  customer: string
  model: string
  partNo: string
  partName: string
  wt: number
  adm: number
  spec: string
  volt: string
  small: number
  big: number
  twoSump: string
  min: string
  colour: string
  packing: string
  img: string
}

interface IAddZincRackProps {
  setOpenAddZincRack: (boolean: boolean) => void
  openAddZincRack: boolean
}

interface IUpdateZincRackProps {
  setOpenUpdateZincRack: (boolean: boolean) => void
  openUpdateZincRack: boolean
}

interface IDeleteZincRackProps {
  setOpenDeleteZincRack: (boolean: boolean) => void
  openDeleteZincRack: boolean
}

interface Column {
  id:
    | "item"
    | "customer"
    | "model"
    | "partNo"
    | "partName"
    | "wt"
    | "spec"
    | "volt"
    | "adm"
    | "small"
    | "big"
    | "twoSump"
    | "min"
    | "colour"
    | "packing"
    | "img"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

const columns: Column[] = [
  { id: "item", label: "item", minWidth: 50 },
  { id: "customer", label: "Customer", minWidth: 50 },
  { id: "model", label: "Model", minWidth: 100 },
  { id: "partNo", label: "part No", minWidth: 50 },
  { id: "partName", label: "Part Name", minWidth: 100 },
  { id: "img", label: "img", minWidth: 150 },
  { id: "wt", label: "น้ำหนัก wt(g)", minWidth: 100 },
  { id: "spec", label: "Spec", minWidth: 100 },
  { id: "volt", label: "กระแสไฟ Volt", minWidth: 50 },
  { id: "adm", label: "พื้นที่ผิว A (dm2)", minWidth: 100 },
  { id: "small", label: "ชิ้นต่อรอบบ่อเล็ก", minWidth: 50 },
  { id: "big", label: "ชิ้นต่อรอบบ่อใหญ่", minWidth: 50 },
  { id: "twoSump", label: "ชิ้นต่อรอบรวม 2 บ่อ", minWidth: 50 },
  { id: "min", label: "เวลาชุบ (นาที)", minWidth: 100 },
  { id: "colour", label: "colour", minWidth: 100 },
  { id: "packing", label: "Packing", minWidth: 50 }
]

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 29,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 30,
    fontFamily: "Sarabun",
    textAlign: "center"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },

  "td, th": {
    border: "1px solid black"
  }
}))

const AddNewZincRack: React.FC<IAddZincRackProps> = (
  props: IAddZincRackProps
): ReactElement => {
  const { setOpenAddZincRack, openAddZincRack } = props
  const theme = useTheme()
  const [partNo, setPartNo] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [model, setModel] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [spec, setSpec] = useState<string>("")
  const [wt, setWt] = useState<number>()
  const [adm, setAdm] = useState<number>()
  const [volt, setVolt] = useState<string>("")
  const [small, setSmall] = useState<number>()
  const [big, setBig] = useState<number>()
  const [twoSump, setTwoSump] = useState<string>("")
  const [colour, setColour] = useState<string>("")
  const [min, setMin] = useState<string>("")
  const [packing, setPacking] = useState<string>("")
  const [error, setError] = useState<boolean>(false)

  const [addZincRack, { loading }] = useMutation(ADD_ZINC_RACK, {
    refetchQueries: [GET_ZINC_RACK],
    awaitRefetchQueries: true
  })

  const partNoList = usePartNo()
  const { data: partNoData } = useQuery(GET_PARTS, {
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    if (!partNo) {
      handleReset()
    } else if (partNoData) {
      const part = partNoData.parts.find(
        (part: IPart) => part.partNo === partNo
      )
      setPartName(part.partName)
      setCustomer(part.customer)
      setModel(part.model)
    }
  }, [partNo])

  const isComplete = () => {
    return (
      customer &&
      partNo &&
      model &&
      partName &&
      wt &&
      adm &&
      spec &&
      big &&
      volt &&
      small &&
      colour &&
      min &&
      twoSump &&
      packing
    )
  }

  const handleReset = () => {
    setPartName("")
    setPartNo("")
    setCustomer("")
    setModel("")

    setPacking("")
    setSpec("")
    setAdm(0)
    setWt(0)
    setVolt("")
    setBig(0)
    setSmall(0)
    setColour("")
    setTwoSump("")
    setMin("")
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    if (
      !customer &&
      !partNo &&
      !model &&
      !partName &&
      !wt &&
      !adm &&
      !spec &&
      !big &&
      !volt &&
      !small &&
      !colour &&
      !min &&
      !twoSump &&
      !packing
    ) {
      setError(true)
      return swal("Warning", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
    e.preventDefault()
    if (!isComplete()) {
      setError(true)
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }

    try {
      await addZincRack({
        variables: {
          customer,
          partNo,
          model,
          partName,
          wt,
          adm,
          spec,
          big,
          volt,
          small,
          colour,
          min,
          twoSump,
          packing
        }
      })
      swal("Success", "Add Part Successful", "success")
      handleReset()
      setOpenAddZincRack(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openAddZincRack}
        onClose={() => setOpenAddZincRack(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Add New List Zinc Hanger
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                id="select-part-no"
                value={partNo ? partNo : ""}
                options={partNoList}
                loading={!!partNoList}
                onChange={(e, newValue) => setPartNo(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Part No."
                    error={!partNo && error}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setPartName(e.target.value)}
                value={partName ? partName : ""}
                id="part name"
                autoComplete="family-name"
                label="Part Name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setCustomer(e.target.value)}
                value={customer ? customer : ""}
                id="customer"
                label="Customer"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setSpec(e.target.value)}
                value={spec ? spec : ""}
                id="spec"
                label="Spec"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="Model"
                onChange={(e) => setModel(e.target.value)}
                value={model ? model : ""}
                id="sizeL"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item xs={4} sm={4}>
              <ThemedTextField
                label="น้ำหนัก (กรัม)"
                fullWidth
                value={wt ? wt : ""}
                type="number"
                inputProps={{
                  minLength: 0,
                  step: "0.1"
                }}
                onChange={(e) => setWt(parseFloat(e.target.value))}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="พื้นที่ผิว"
                onChange={(e) => setAdm(parseFloat(e.target.value))}
                value={adm ? adm : ""}
                id="adm"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <ThemedTextField
                fullWidth
                label="กระแสไฟ (โวล์ท)"
                onChange={(e) => setVolt(e.target.value)}
                value={volt ? volt : ""}
                id="kg"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="บ่อเล็ก"
                onChange={(e) => setSmall(parseFloat(e.target.value))}
                value={small ? small : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="บ่อใหญ่"
                onChange={(e) => setBig(parseFloat(e.target.value))}
                value={big ? big : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="รวม 2 บ่อ"
                onChange={(e) => setTwoSump(e.target.value)}
                value={twoSump ? twoSump : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="เวลาชุบ (นาที)"
                onChange={(e) => setMin(e.target.value)}
                value={min ? min : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="colour"
                onChange={(e) => setColour(e.target.value)}
                value={colour ? colour : ""}
                id="colour"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="packing"
                onChange={(e) => setPacking(e.target.value)}
                value={packing ? packing : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            sx={{
              backgroundColor: theme.palette.error.main,
              "&:hover": { backgroundColor: theme.palette.error.dark }
            }}
            variant="text"
            onClick={() => handleReset()}
          >
            RESET
          </ThemedButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenAddZincRack(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const UpdateZincRack: React.FC<IUpdateZincRackProps> = (
  props: IUpdateZincRackProps
): ReactElement => {
  const { openUpdateZincRack, setOpenUpdateZincRack } = props
  const [partNo, setPartNo] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [model, setModel] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [spec, setSpec] = useState<string>("")
  const [wt, setWt] = useState<number>()
  const [adm, setAdm] = useState<number>()
  const [volt, setVolt] = useState<string>("")
  const [small, setSmall] = useState<number>()
  const [big, setBig] = useState<number>()
  const [twoSump, setTwoSump] = useState<string>("")
  const [colour, setColour] = useState<string>("")
  const [min, setMin] = useState<string>("")
  const [packing, setPacking] = useState<string>("")
  const [error, setError] = useState<boolean>(false)

  const [updateZincRack, { loading }] = useMutation(UPDATE_ZINC_RACK, {
    refetchQueries: [GET_ZINC_RACK],
    awaitRefetchQueries: true
  })

  const { data } = useQuery(GET_ZINC_RACK)

  useEffect(() => {
    const zincRack = data?.masterHanger?.find((entry: { partNo: string }) => {
      return entry.partNo === partNo
    })
    if (zincRack) {
      setPartName(zincRack.partName)
      setPartNo(zincRack.partNo)
      setPacking(zincRack.packing)
      setSpec(zincRack.spec)
      setCustomer(zincRack.customer)
      setModel(zincRack.model)
      setAdm(zincRack.adm)
      setWt(zincRack.wt)
      setVolt(zincRack.volt)
      setBig(zincRack.big)
      setSmall(zincRack.small)
      setColour(zincRack.colour)
      setTwoSump(zincRack.twoSump)
      setMin(zincRack.min)
    }
    if (!partNo) handleReset()
  }, [partNo])

  const zincRackList = data
    ? data?.masterHanger?.map(({ partNo }: { partNo: string }) => partNo)
    : []

  const handleReset = () => {
    setPartName("")
    setPartNo("")
    setPacking("")
    setSpec("")
    setCustomer("")
    setModel("")
    setAdm(0)
    setWt(0)
    setVolt("")
    setBig(0)
    setSmall(0)
    setColour("")
    setTwoSump("")
    setMin("")
  }

  const handleSubmit = async () => {
    try {
      await updateZincRack({
        variables: {
          customer,
          partNo,
          model,
          partName,
          wt,
          adm,
          spec,
          big,
          volt,
          small,
          colour,
          min,
          twoSump,
          packing
        }
      })
      swal("Success", "Updater Success", "success")
      setOpenUpdateZincRack(false)
    } catch (err) {
      setError(true)
      swal("Warning", `กรุณาใส่ MatNo`, "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openUpdateZincRack}
        onClose={() => setOpenUpdateZincRack(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Update Zinc Hanger
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item sm={12} xs={12}>
              <Autocomplete
                id="part no"
                value={partNo ? partNo : ""}
                options={zincRackList}
                style={{ width: "100%" }}
                sx={{ width: 100 }}
                loading={!!zincRackList}
                onChange={(e, newValue) => setPartNo(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select partNo"
                    error={!partNo && error}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setPartName(e.target.value)}
                value={partName ? partName : ""}
                id="part name"
                autoComplete="family-name"
                label="Part Name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setCustomer(e.target.value)}
                value={customer ? customer : ""}
                id="customer"
                label="Customer"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setSpec(e.target.value)}
                value={spec ? spec : ""}
                id="spec"
                label="Spec"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="Model"
                onChange={(e) => setModel(e.target.value)}
                value={model ? model : ""}
                id="sizeL"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item xs={4} sm={4}>
              <ThemedTextField
                label="น้ำหนัก (กรัม)"
                fullWidth
                value={wt ? wt : ""}
                type="number"
                inputProps={{
                  minLength: 0,
                  step: "0.1"
                }}
                onChange={(e) => setWt(parseFloat(e.target.value))}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="พื้นที่ผิว"
                onChange={(e) => setAdm(parseFloat(e.target.value))}
                value={adm ? adm : ""}
                id="adm"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <ThemedTextField
                fullWidth
                label="กระแสไฟ (โวล์ท)"
                onChange={(e) => setVolt(e.target.value)}
                value={volt ? volt : ""}
                id="kg"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="บ่อเล็ก"
                onChange={(e) => setSmall(parseFloat(e.target.value))}
                value={small ? small : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="บ่อใหญ่"
                onChange={(e) => setBig(parseFloat(e.target.value))}
                value={big ? big : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="รวม 2 บ่อ"
                onChange={(e) => setTwoSump(e.target.value)}
                value={twoSump ? twoSump : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="เวลาชุบ (นาที)"
                onChange={(e) => setMin(e.target.value)}
                value={min ? min : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="colour"
                onChange={(e) => setColour(e.target.value)}
                value={colour ? colour : ""}
                id="colour"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="packing"
                onChange={(e) => setPacking(e.target.value)}
                value={packing ? packing : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenUpdateZincRack(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const DeleteZincRack: React.FC<IDeleteZincRackProps> = (
  props: IDeleteZincRackProps
) => {
  const { openDeleteZincRack, setOpenDeleteZincRack } = props
  const [partNo, setPartNo] = useState<string>("")
  const [error, setError] = useState<boolean>(false)

  const { data } = useQuery(GET_ZINC_RACK)
  const [deleteZinc, { loading }] = useMutation(DELETE_ZINC_RACK, {
    refetchQueries: [GET_ZINC_RACK],
    awaitRefetchQueries: true
  })

  const zincRackList = data
    ? data?.masterHanger?.map(({ partNo }: { partNo: string }) => partNo)
    : []

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!partNo) {
      setError(true)
    }
    try {
      await deleteZinc({
        variables: { partNo }
      })
      swal("Success", "Delete Successful", "success")
      setOpenDeleteZincRack(false)
    } catch (error) {
      swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openDeleteZincRack}
        onClose={() => setOpenDeleteZincRack(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Sarabun" }}>
          Delete Zinc Hanger
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} marginTop={2}>
            <Grid item sm={12} xs={12}>
              <Autocomplete
                id="part no"
                value={partNo ? partNo : ""}
                options={zincRackList}
                style={{ width: "100%" }}
                sx={{ width: 100 }}
                loading={!!zincRackList}
                onChange={(e, newValue) => setPartNo(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select partNo"
                    error={!partNo && error}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              borderRadius: "5px",
              marginLeft: "16px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenDeleteZincRack(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const ZincHanger: React.FC = () => {
  const [search, setSearch] = useState<string>("")
  const [openUpdateZincRack, setOpenUpdateZincRack] = useState<boolean>(false)
  const [openAddZincRack, setOpenAddZincRack] = useState<boolean>(false)
  const [openDeleteZincRack, setOpenDeleteZincRack] = useState<boolean>(false)

  const { data } = useQuery(GET_ZINC_RACK)

  const theme = useTheme()

  const partListSearch =
    data?.masterHanger?.map((part: IReportZincRack) => part.partNo) || []

  let rows: IReportZincRack[] = []

  if (data) {
    rows = data.masterHanger
      .map((part: IReportZincRack, idx: number) => {
        return {
          ...part,
          item: idx + 1
        }
      })
      .filter((part: IReportZincRack) => {
        if (search) return part.partNo === search
        else return part
      })
  }
  return (
    <ThemeProvider theme={theme}>
      <UpdateZincRack
        openUpdateZincRack={openUpdateZincRack}
        setOpenUpdateZincRack={setOpenUpdateZincRack}
      />
      <AddNewZincRack
        openAddZincRack={openAddZincRack}
        setOpenAddZincRack={setOpenAddZincRack}
      />
      <DeleteZincRack
        openDeleteZincRack={openDeleteZincRack}
        setOpenDeleteZincRack={setOpenDeleteZincRack}
      />
      <div className="none-display">
        <Grid container spacing={2} width={"98%"} padding={2}>
          <Grid item xs={3} sm={3}>
            <Autocomplete
              id="search"
              freeSolo
              value={search}
              options={partListSearch}
              style={{ width: "100%", marginLeft: "-15px" }}
              sx={{ width: 100 }}
              onChange={(e, newValue) => setSearch(newValue ? newValue : "")}
              renderInput={(params) => (
                <ThemedTextField
                  style={{ marginLeft: "15px" }}
                  {...params}
                  label="Search"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={9}
            sm={9}
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <ThemedButton
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenAddZincRack(true)}
              >
                ADD Zinc Hanger
              </ThemedButton>
            </Grid>
            <Grid item>
              <ThemedButton
                contrast
                variant="contained"
                startIcon={<EditIcon />}
                onClick={() => setOpenUpdateZincRack(true)}
              >
                Edit Zinc Hanger
              </ThemedButton>
            </Grid>
            <Grid item>
              <ThemedButton
                contrast
                color="error"
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => setOpenDeleteZincRack(true)}
              >
                DELETE Zinc Hanger
              </ThemedButton>
            </Grid>
            <Grid item>
              <ThemedButton
                variant="contained"
                endIcon={<PrintIcon />}
                onClick={() => window.print()}
              >
                Print
              </ThemedButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Table sx={{ zoom: "50%" }} className="bg-plating plating-absolute">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell colSpan={1}>
              <img src={miniLogo} alt="" width={"100px"} />
            </StyledTableCell>
            <StyledTableCell colSpan={22}>Zinc Hanger Plating</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.id} align={column.align}>
                {column.label}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            return (
              <StyledTableRow hover tabIndex={-1} key={i}>
                {columns.map((column) => {
                  const value = row[column.id]
                  return (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={column.id === "img" ? { padding: "3px" } : {}}
                    >
                      {column.id === "img" ? (
                        <img
                          src={getImagePng(`images/parts/${row.partNo}`)}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = bankImage
                          }}
                          width={80}
                          height={80}
                          alt=""
                        ></img>
                      ) : column.format && typeof value === "number" ? (
                        column.format(value)
                      ) : (
                        value
                      )}
                    </StyledTableCell>
                  )
                })}
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </ThemeProvider>
  )
}

export default ZincHanger
