import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Input from "../../common/Resources/Input"
import CircularProgress from "@mui/material/CircularProgress"
import TablePagination from "@mui/material/TablePagination"
import { useState } from "react"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import { useQuery, useMutation } from "@apollo/client"
import {
  ADD_MACHINE,
  UPLOAD_MACHINE_IMAGE
} from "../../common/Mutation/MasterMutation"
import { GET_MACHINES } from "../../common/Query/MasterQuery"
import AddIcon from "@mui/icons-material/Add"
import "../../css/Master/Machine.css"
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import MachineDialogReport from "./component/MachineDialogReport"
import swal from "sweetalert"
import EditMachine from "./component/EditMachine"
import EditIcon from "@mui/icons-material/Edit"
import { GET_LINE } from "../../common/Query/GeneralQuery"
import { bankImage, getImagePng } from "../../common/utilities"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

export interface IMachine {
  machine: string
  code: string
  line: string
  factory: string
  frequency: number
  priority: string
  company: string
  model: string
  brand: string
  installationDate: string
  details: string
  machinePower: string
  remarks: string
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.secondary.contrastText,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.black
  },

  "td, th": {
    border: 0
  }
}))

const pageTitle = "Machine"

interface IPropsAddImage {
  setOpenAddImage: (boolean: boolean) => void
  openAddImage: boolean
}

const AddNewImage: React.FC<IPropsAddImage> = (props: IPropsAddImage) => {
  const { openAddImage, setOpenAddImage } = props

  const [name, setName] = useState<string | null>("")
  const machine = useQuery(GET_MACHINES)
  const [uploadImage, { loading }] = useMutation(UPLOAD_MACHINE_IMAGE)
  const [file, setFile] = useState<File | null>(null)
  const [error, setError] = useState<boolean>(false)
  let machineList: Array<string> = []
  machineList = machine.data?.machines
    .map((e: { machine: string }) => e.machine)
    .reduce(
      (prev: string[], cur: string) =>
        prev.includes(cur) ? prev : prev.concat(cur),
      []
    )

  const handleUploadImage = async () => {
    if (!name || !file) {
      setError(true)
      swal("Error", "กรุณาใส่ข้อมูลให้ครบถ้วน", "error")
      throw error
    }
    try {
      await uploadImage({
        variables: {
          name,
          file
        }
      })
      swal("Success", "", "success")
    } catch (error) {
      setError(true)
    }
  }
  return (
    <Dialog
      open={openAddImage}
      onClose={() => setOpenAddImage(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          width: "50%",
          maxHeight: 300
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="text-material">
        Add New Image
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} margin={"2px"} width={"98%"}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="partNo"
              options={machineList}
              onChange={(e, newValue) => setName(newValue)}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  value={name}
                  label={"Machines"}
                  error={!name && error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
            <input
              type="file"
              onChange={(e) => {
                setFile(
                  e.currentTarget.files?.["0"]
                    ? e.currentTarget.files?.["0"]
                    : null
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} sx={{ mt: 2 }}>
            <ThemedLoadingButton
              loading={loading}
              variant="contained"
              onClick={handleUploadImage}
            >
              Upload
            </ThemedLoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ alignSelf: "start" }}>
        <ThemedButton
          contrast
          variant="text"
          onClick={() => setOpenAddImage(false)}
        >
          CANCEL
        </ThemedButton>
      </DialogActions>
    </Dialog>
  )
}

export const MachineDataList: React.FC = () => {
  const theme = useTheme()
  const { loading, error, data } = useQuery(GET_MACHINES)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [search, setSearch] = useState<string>("")
  const [searchFactory, setSearchFactory] = useState<string>("")
  const [openAddMachine, setOpenAddMachine] = useState<boolean>(false)
  const [openReport, setOpenReport] = useState<boolean>(false)
  const [openEditMachine, setOpenEditMachine] = useState<boolean>(false)
  const [machine, setMachine] = useState<IMachine>()
  const [openAddImage, setOpenAddImage] = useState<boolean>(false)

  const handleOpenHistoryMachine = (data: IMachine) => {
    setMachine(data)
    setOpenReport(true)
  }

  if (loading)
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ padding: "10px" }}
      >
        <CircularProgress />
      </Grid>
    )
  if (error) return <p>Error: {error}</p>

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows: number =
    rowsPerPage -
    Math.min(rowsPerPage, data.machines.length - page * rowsPerPage)

  const machineListSearch =
    data?.machines
      ?.map((machine: IMachine) => machine.machine)
      .reduce(
        (prev: string[], cur: string) =>
          prev.includes(cur) ? prev : prev.concat(cur),
        []
      ) || []

  let rawMachineList: IMachine[] = data.machines || []

  if (search) {
    rawMachineList = rawMachineList.filter((e) => e.machine === search)
  }
  if (searchFactory) {
    rawMachineList = rawMachineList.filter((e) => e.factory === searchFactory)
  }
  const machineList = rawMachineList
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((machine: IMachine) => {
      return (
        <StyledTableRow
          key={machine.machine + machine.code}
          onDoubleClick={() => handleOpenHistoryMachine(machine)}
        >
          <StyledTableCell component="th" align="left">
            {machine.machine}
          </StyledTableCell>
          <StyledTableCell align="left">
            <img
              src={getImagePng(`images/machine/${machine.code}`)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = bankImage
              }}
              width={40}
              height={40}
              alt=""
            ></img>
          </StyledTableCell>
          <StyledTableCell align="left">{machine.priority}</StyledTableCell>

          <StyledTableCell align="left">{machine.line}</StyledTableCell>
          <StyledTableCell align="left">{machine.code}</StyledTableCell>
          <StyledTableCell align="left">{machine?.factory}</StyledTableCell>
          <StyledTableCell
            align="left"
            aria-labelledby={`${machine.machine}-machinepower`}
          >
            {machine?.machinePower}
          </StyledTableCell>
          <StyledTableCell align="left">{machine?.remarks}</StyledTableCell>
        </StyledTableRow>
      )
    })

  return (
    <>
      <AddNewMachine
        setOpenAddMachine={setOpenAddMachine}
        openAddMachine={openAddMachine}
      />
      <AddNewImage
        setOpenAddImage={setOpenAddImage}
        openAddImage={openAddImage}
      />
      <MachineDialogReport
        dialogOpen={openReport}
        setDialogOpen={setOpenReport}
        machine={machine}
      />
      <EditMachine
        dialogOpen={openEditMachine}
        setDialogOpen={setOpenEditMachine}
      />
      <Grid container spacing={2} width={"98%"} padding={2}>
        <Grid item container xs={6} sm={6} spacing={2} width={"98%"}>
          <Grid item xs={12} sm={8}>
            <Autocomplete
              id="search-machine"
              freeSolo
              value={search}
              options={machineListSearch}
              style={{ width: "100%" }}
              sx={{ width: 100 }}
              onChange={(e, newValue) => {
                setPage(0)
                setSearch(newValue ? newValue : "")
              }}
              renderInput={(params) => (
                <ThemedTextField
                  style={{ marginLeft: "15px" }}
                  {...params}
                  label="Machine"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="search-factory"
              freeSolo
              value={searchFactory}
              options={["1", "2"]}
              style={{ width: "100%" }}
              sx={{ width: 100 }}
              onChange={(e, newValue) =>
                setSearchFactory(newValue ? newValue : "")
              }
              renderInput={(params) => (
                <ThemedTextField
                  style={{ marginLeft: "15px" }}
                  {...params}
                  label="Factory"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          container
          direction={{ xs: "column", sm: "row" }}
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs sm="auto">
            <ThemedButton
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenAddMachine(true)}
            >
              ADD NEW MACHINE
            </ThemedButton>
          </Grid>
          <Grid item xs sm="auto">
            <ThemedButton
              contrast
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => setOpenEditMachine(true)}
            >
              EDIT MACHINE
            </ThemedButton>
          </Grid>
          <Grid item xs sm="auto">
            <ThemedButton
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenAddImage(true)}
            >
              ADD IMAGE
            </ThemedButton>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          className=" zui-table-highlight"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Machines</StyledTableCell>
              <StyledTableCell>Image Machine</StyledTableCell>
              <StyledTableCell>Rank</StyledTableCell>
              <StyledTableCell>Line</StyledTableCell>
              <StyledTableCell>Code</StyledTableCell>
              <StyledTableCell>Factory</StyledTableCell>
              <StyledTableCell>กำลังเครื่องจักร</StyledTableCell>
              <StyledTableCell>Remark</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {machineList}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, data.machines.length]}
          component="div"
          count={
            data.machines.filter((machine: IMachine) => {
              if (search) return machine.machine === search
              else return machine
            }).length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ backgroundColor: theme.palette.warning.light }}
        />
      </TableContainer>
    </>
  )
}

interface IPropsAddMachine {
  setOpenAddMachine: (boolean: boolean) => void
  openAddMachine: boolean
}

export const AddNewMachine: React.FC<IPropsAddMachine> = (
  props: IPropsAddMachine
) => {
  const { setOpenAddMachine, openAddMachine } = props
  const [addMachine, { loading }] = useMutation(ADD_MACHINE, {
    refetchQueries: [GET_MACHINES],
    awaitRefetchQueries: true
  })
  const [machineNo, setMachineNo] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const [line, setLine] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  const [frequency, setFrequency] = useState<number>(0)
  const [factory, setFacNo] = useState<string>()
  const [remarks, setRemarks] = useState("")
  const [priority, setPriority] = useState("")
  const [model, setModel] = useState("")
  const [brand, setBrand] = useState("")
  const [installationDate, setInstallationDate] = useState("")
  const [details, setDetails] = useState("")
  const [machinePower, setMachinePower] = useState("")
  const [company, setCompany] = useState("")
  const [file, setFile] = useState<File | null>(null)
  const [uploadImage] = useMutation(UPLOAD_MACHINE_IMAGE)
  const getLine = useQuery(GET_LINE)
  let lineList = []
  if (getLine.data) {
    lineList = getLine.data.getLine.map(
      (element: { lineName: string }) => element.lineName
    )
  }

  const isComplete = () => {
    return machineNo && code && line && factory
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    setError(true)
    e.preventDefault()
    if (!isComplete())
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    try {
      await addMachine({
        variables: {
          machine: machineNo,
          code,
          line,
          factory,
          frequency,
          priority,
          company,
          model,
          brand,
          installationDate,
          details,
          machinePower,
          remarks
        }
      })
      if (file) {
        await handleUploadImage()
      }
      swal("Success", "Add Machine Successful", "success")
      setOpenAddMachine(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  const handleUploadImage = async () => {
    try {
      await uploadImage({
        variables: {
          name: machineNo,
          file
        }
      })
    } catch (error) {
      swal("Error", "", "error")
    }
  }

  return (
    <>
      <Dialog
        open={openAddMachine}
        onClose={() => setOpenAddMachine(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Add New Machine</DialogTitle>

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Input
              fullWidth
              text={"Machine No."}
              setValue={(event) => {
                setMachineNo(event.target.value)
              }}
              inputType="string"
              sm={12}
              error={!machineNo && error}
            />
            <Input
              fullWidth
              text={"Code"}
              setValue={(event) => {
                setCode(event.target.value)
              }}
              inputType="string"
              sm={12}
              error={!code && error}
            />
            <Grid item sm={4} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                value={line}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select Line"
                    error={!line && error}
                  />
                )}
                onChange={(e, newValue) => setLine(newValue ? newValue : "")}
                options={lineList}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                value={factory}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select Factory"
                    error={!factory && error}
                  />
                )}
                onChange={(e, newValue) => setFacNo(newValue ? newValue : "")}
                options={["1", "2"]}
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
              <input
                type="file"
                onChange={(e) => {
                  setFile(
                    e.currentTarget.files?.["0"]
                      ? e.currentTarget.files?.["0"]
                      : null
                  )
                }}
              />
            </Grid>
            <Input
              fullWidth
              text={"Frequency"}
              setValue={(event) => {
                setFrequency(+event.target.value)
              }}
              inputType="number"
              sm={6}
            />
            <Grid item sm={6} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                value={priority}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Select Priority" />
                )}
                onChange={(e, newValue) =>
                  setPriority(newValue ? newValue : "")
                }
                options={["A", "B", "C", "D"]}
              />
            </Grid>
            <Input
              fullWidth
              text={"Company"}
              setValue={(event) => {
                setCompany(event.target.value)
              }}
              inputType="string"
              sm={12}
            />
            <Input
              fullWidth
              text={"Model"}
              setValue={(event) => {
                setModel(event.target.value)
              }}
              inputType="string"
              sm={6}
            />
            <Input
              fullWidth
              text={"Brand"}
              setValue={(event) => {
                setBrand(event.target.value)
              }}
              inputType="string"
              sm={6}
            />
            <Input
              fullWidth
              text={"วันที่ติดตั้ง"}
              setValue={(event) => {
                setInstallationDate(event.target.value)
              }}
              error={!installationDate && error}
              inputType="date"
              sm={6}
            />
            <Input
              fullWidth
              text={"กำลังเครื่องจักร"}
              setValue={(event) => {
                setMachinePower(event.target.value)
              }}
              inputType="string"
              sm={6}
            />
            <Grid item sm={12} xs={12}>
              ข้อมูลเครื่องจักร
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder="Empty"
                onChange={(e) => setDetails(e.target.value)}
                minRows={3}
                style={{ width: "100%", fontSize: "25px" }}
              />
            </Grid>
            <Input
              fullWidth
              text={"Remarks"}
              setValue={(event) => {
                setRemarks(event.target.value)
              }}
              inputType="string"
              sm={12}
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenAddMachine(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const Machine: React.FC = () => {
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Master}
        menuItemName={Object.keys(menuItemList)[0]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard title="Machine" subTitle="About detail Machine" />
        <MachineDataList />
      </ThemedCard>
    </ThemeProvider>
  )
}

export default Machine
