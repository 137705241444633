import {
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  styled,
  tableCellClasses,
  useTheme
} from "@mui/material"
import { PageLabel } from "../../../common/Resources/PageLabel"
import { menuItemList } from "../../../common/Resources/menuItemList"
import ThemedCard from "../../../common/Resources/ThemedComponents/ThemedCard"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DateAdapter from "@mui/lab/AdapterMoment"
import DatePicker from "@mui/lab/DatePicker"
import { useMemo, useState } from "react"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import moment from "moment"
import AutocompleteFactory from "../../../common/Resources/AutocompleteFactory"
import ThemedRadio from "../../../common/Resources/ThemedComponents/ThemedRadio"
import { GET_INFORMANT_PM } from "../../../common/Query/engineerQuery"
import { useLazyQuery } from "@apollo/client"
import { IRepairInformant } from "../PMDocument"

const pageTitle = "PM Engineer Report"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

interface IRow {
  documentNo: number
  qcApprove: string
  productionApprove: string
  engineerApprove: string
}
const PMEngineerReport: React.FC = () => {
  const [date, setDate] = useState<Date>(new Date())
  const [factory, setFactory] = useState<string>("")
  const [approve, setApprove] = useState<string>("Clear")

  const [getPMHistory, { data: queryPMEngineer }] = useLazyQuery(
    GET_INFORMANT_PM,
    {
      fetchPolicy: "no-cache"
    }
  )
  useMemo(async () => {
    await getPMHistory({
      variables: {
        date: moment(date).format("YYYY-MM"),
        factory: factory
      }
    })
  }, [date, factory])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = approve === event.target.value ? "" : event.target.value
    setApprove(newValue)
  }

  const rows = useMemo(() => {
    if (queryPMEngineer?.getPMHistory) {
      return queryPMEngineer.getPMHistory
        .filter((e: IRepairInformant) => {
          if (approve === "QC Approve") {
            return !!e.leaderQC
          }
          if (approve === "Production Approve") {
            return !!e.deliverLeaderProduction
          }
          if (approve === "Engin Approve") {
            return !!e.leaderTooling
          } else return true
        })
        .map((item: IRepairInformant) => ({
          documentNo: item.documentNo,
          qcApprove: item.leaderQC,
          productionApprove: item.deliverLeaderProduction,
          engineerApprove: item.leaderTooling
        }))
        .sort((a: IRow, b: IRow) => a.documentNo - b.documentNo)
    }
    return []
  }, [queryPMEngineer?.getPMHistory, approve, factory, date])
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.engineer}
        menuItemName={Object.keys(menuItemList)[12]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <CardHeader
          titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
          title="PM Engineer Report "
          subheaderTypographyProps={{ fontFamily: "Sarabun" }}
          subheader="Add incoming PM Engineer Report here"
        />
        <Grid container spacing={2} sx={{ m: 2 }}>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                label="Select Month"
                views={["year", "month"]}
                inputFormat="MM/yyyy"
                value={date}
                onChange={(newSelectDate) => {
                  setDate(moment(newSelectDate).toDate() as Date)
                }}
                PaperProps={{
                  sx: {
                    "& .MuiCalendarPicker-root": {
                      ".Mui-selected": {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? theme.palette.warning.main
                            : theme.palette.primary.main,
                        color: "#000000"
                      }
                    }
                  }
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    fullWidth
                    sx={{
                      "& .Mui-selected": {
                        backgroundColor: theme.palette.warning.main
                      }
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <AutocompleteFactory setFactory={setFactory} factory={factory} />
          </Grid>

          <Grid item xs={8}>
            <FormControl>
              <RadioGroup row onChange={handleChange} value={approve}>
                <FormControlLabel
                  value="QC Approve"
                  control={<ThemedRadio />}
                  label="QC Approve"
                />
                <FormControlLabel
                  value="Production Approve"
                  style={{ paddingLeft: "50px" }}
                  control={<ThemedRadio />}
                  label="Production Approve"
                />
                <FormControlLabel
                  value="Engin Approve"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px"
                  }}
                  control={<ThemedRadio />}
                  label="Engineer Approve"
                />
                <FormControlLabel
                  value="Clear"
                  style={{
                    paddingRight: "50px"
                  }}
                  control={<ThemedRadio />}
                  label="Clear"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Document No</StyledTableCell>
                <StyledTableCell>QC Approve</StyledTableCell>
                <StyledTableCell>Production Approve</StyledTableCell>
                <StyledTableCell>Engineer Approve</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((e: IRow) => {
                return (
                  <TableRow>
                    <StyledTableCell>{e.documentNo}</StyledTableCell>
                    <StyledTableCell>{e.qcApprove}</StyledTableCell>
                    <StyledTableCell>{e.productionApprove}</StyledTableCell>
                    <StyledTableCell>{e.engineerApprove}</StyledTableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default PMEngineerReport
