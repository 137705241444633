import { ThemeProvider, useTheme } from "@mui/material"
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton"

import { styled } from "@mui/material/styles"

interface IThemedLoadingButtonProps extends LoadingButtonProps {
  contrast?: boolean
}

const ThemedLoadingButtonComponent = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.secondary.contrastText
})) as typeof LoadingButton

const ContrastLoadingButtonComponent = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText
})) as typeof LoadingButton

const ThemedLoadingButton = (props: IThemedLoadingButtonProps): JSX.Element => {
  const { contrast = false } = props
  const theme = useTheme()

  const themedLoadingButton = contrast ? (
    <ContrastLoadingButtonComponent {...props} />
  ) : (
    <ThemedLoadingButtonComponent {...props} />
  )
  return <ThemeProvider theme={theme}>{themedLoadingButton}</ThemeProvider>
}

export default ThemedLoadingButton
