import { ReactElement, useCallback, useEffect, useMemo } from "react"
import { useMutation, useQuery } from "@apollo/client"
import {
  Box,
  Grid,
  Select,
  MenuItem,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  InputLabel,
  FormControl,
  useTheme,
  ThemeProvider
} from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import { GET_PARTS } from "../../common/Query/MasterQuery"
import swal from "sweetalert"
import { CREATE_FG_OUT } from "../../common/Mutation/WarehouseMutation"
import { GET_WH_FG_REPORT_ALL } from "../../common/Query/WarehouseQuery"
import { NewDataReport } from "./WHFGReport"
import { partOs } from "./WarehouseFGIn"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"

export interface dictionaryStock {
  [id: string]: number
}

const pageTitle = "FG Out"

const WarehouseFGOut: React.FC = (): ReactElement => {
  const [partNo, setPartNo] = useState<string>("")
  const [actionDate, setActionDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [error, setError] = useState<boolean>(false)
  const [quantity, setQuantity] = useState<number>(0)
  const [lastQuantity, setLastQuantity] = useState<NewDataReport | "">()
  const [factory, setFactory] = useState<string>("1")
  const [operator, setOperator] = useState<string | null>("")
  const [remarks, setRemarks] = useState("")
  const [fgReport, setFgReport] = useState<NewDataReport[]>()
  const [isNG, setIsNG] = useState<boolean>(false)

  let customer = ""
  const { data } = useQuery(GET_PARTS)

  const [createFg, { loading }] = useMutation(CREATE_FG_OUT)
  const getFGReport = useQuery(GET_WH_FG_REPORT_ALL, {
    variables: { partNo, factory },
    fetchPolicy: "network-only"
  })

  const getLotStock = useCallback(() => {
    const lots = fgReport?.filter((e) => factory === e.factory)
    let total = 0
    lots?.forEach((lot) => {
      total += lot.quantity * lot.inOrOut
    })

    return total
  }, [factory, fgReport])

  const getLastLotStock = useCallback(() => {
    const getFgStock: NewDataReport[] =
      fgReport?.filter((e) => e.factory === factory && e.inOrOut === -1) || []
    if (getFgStock.length > 0) {
      return getFgStock[getFgStock.length - 1]
    }
    return ""
  }, [factory, fgReport])
  const setFgReportCall = useCallback(async () => {
    const getFGReportList: NewDataReport[] =
      (await getFGReport?.data?.whFGReportAll) || []
    if (getFGReportList?.length !== 0) {
      setFgReport(getFGReportList)
    }
    setLastQuantity(getLastLotStock())
  }, [getFGReport, getLastLotStock])

  useEffect(() => {
    if (partNo && factory) {
      setFgReportCall()
    }
  }, [partNo, factory, setFgReportCall])

  useEffect(() => {
    const getFGReportList: NewDataReport[] =
      getFGReport?.data?.whFGReportAll || []
    if (getFGReportList?.length < 1) {
      setFgReport([])
      setQuantity(0)
      setRemarks("")
      setLastQuantity("")
    }
  }, [partNo])

  useMemo(() => {
    if (quantity > getLotStock() && quantity !== 0) {
      setQuantity(0)
      swal("Warning", "ยอดที่เบิกไม่ถูกต้อง", "warning")
    }
  }, [quantity])

  const isComplete = () => {
    return (
      partNo !== "" &&
      actionDate !== "" &&
      quantity !== 0 &&
      operator !== "" &&
      factory !== ""
    )
  }
  const isReset = () => {
    setFgReport([])
    setPartNo("")
    setQuantity(0)
    setFactory("")
    setRemarks("")
    setLastQuantity("")
  }

  const checkCustomer = () => {
    if (partNo) {
      customer = data.parts.filter(
        (e: { partNo: string }) => e.partNo === partNo
      )[0].customer
      if (customer === "MAHLE" || partOs.includes(partNo)) return true
      return false
    }
  }

  const handleSubmit = async () => {
    if (!isComplete()) {
      setError(true)
      return swal("ERROR!", "กรุณากรอกข้อมูลให้ครบ", "error")
    }
    try {
      const result = await createFg({
        variables: {
          dataFgOut: {
            partNo,
            actionDate,
            quantity,
            factory: checkCustomer() ? "1" : factory,
            packingBy: operator,
            remarks,
            isNG
          }
        }
      })
      if (result.data) {
        swal("Success", "Create FG Out Success", "success")
        isReset()
      }
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "warning")
    }
  }
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <Box style={{ marginLeft: "22px" }}>
        <PageLabel
          menuItem={menuItemList.Warehouse}
          menuItemName={Object.keys(menuItemList)[6]}
          menuPageName={pageTitle}
        />

        <ThemedCard style={{ marginTop: "26px" }}>
          <PageLabelCard title="FG Out" subTitle="detail about FG Out" />
          <Grid container spacing={2} width={"98%"} margin={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={checkCustomer() ? 12 : 6}>
                <AutocompletePartNo
                  setPartNo={setPartNo}
                  partNo={partNo}
                  error={!partNo && error}
                />
              </Grid>
              {checkCustomer() ? (
                <>
                  <Grid item xs={12} sm={2}>
                    <InputLabel>Customer: {customer}</InputLabel>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Factory</InputLabel>
                      <Select
                        value={factory}
                        label="factory"
                        onChange={(e) => setFactory(e.target.value)}
                      >
                        <MenuItem value={"1"}>1</MenuItem>
                        <MenuItem value={"2"}>2</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={2}>
                <InputLabel style={getLotStock() < 0 ? { color: "red" } : {}}>
                  Quantity: {getLotStock()}
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>
                  Last FG Out Stock Date:{" "}
                  {lastQuantity ? lastQuantity.actionDate : ""}
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputLabel>
                  Quantity: {lastQuantity ? lastQuantity.quantity : ""}
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputLabel>
                  Lot No:
                  {lastQuantity ? lastQuantity.pdLotNo.split("Date")[0] : ""}
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  label="Delivery Date"
                  value={actionDate}
                  id="actiondate"
                  type="date"
                  autoComplete="family-name"
                  onChange={(e) => setActionDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  id="quantity"
                  value={quantity ? quantity : ""}
                  label="Quantity"
                  error={!quantity && error}
                  onChange={(e) => setQuantity(+e.target.value)}
                  type="number"
                  onWheel={(event) =>
                    event.currentTarget.querySelector("input")?.blur()
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  disablePortal
                  id="operator"
                  options={
                    factory === "1"
                      ? ["ณัฏฐ์", "วันเฉลิม", "วรารัตน์", "สุธิดา"]
                      : checkCustomer()
                      ? ["ณัฏฐ์", "วันเฉลิม", "วรารัตน์", "สุธิดา"]
                      : ["รุ่งฤดีม", "ศรัณยา", "สุธิดา"]
                  }
                  style={{ width: "100%" }}
                  sx={{ width: 300 }}
                  onChange={(e, newValue) => setOperator(newValue)}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="Select Operator"
                      error={!operator && error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <ThemedTextField
                  id="remarks"
                  fullWidth
                  label="Remarks"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                ></ThemedTextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox onChange={(e) => setIsNG(e.target.checked)} />
                  }
                  label="is NG?"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <ThemedLoadingButton
                  variant="contained"
                  onClick={handleSubmit}
                  loading={loading}
                  style={{
                    backgroundColor: theme.palette.primary.main
                  }}
                >
                  Submit
                </ThemedLoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </ThemedCard>
      </Box>
    </ThemeProvider>
  )
}

export default WarehouseFGOut
