import { gql } from "@apollo/client"

export const GET_JOB_ORDER = gql`
  query JobOrder($jobOrderList: [String]) {
    jobOrder(jobOrderList: $jobOrderList) {
      response {
        customer
        deliveryDate
        leadTime
        matQuantity
        packingBy
        factory
        partName
        perBox
        quantity
        pdLotNo
        partType
        ratio
        sizeH
        sizeL
        sizeW
        spec
        partNo
        jobOrder
      }
      newProcesses {
        processOrder
        stepProcess
        partNo
        process
        machine
        line
        jph
        minStroke
        amount {
          quantity
          ng
          total
          complete
        }
        complete
      }
    }
  }
`

export const GET_PRODUCTION_PLAN_REVISION = gql`
  query Query($date: String, $line: String, $factory: String, $shift: String) {
    productionPlanRevision(
      date: $date
      line: $line
      factory: $factory
      shift: $shift
    )
  }
`

export const GET_PRODUCTION_PLAN = gql`
  query ProductionPlanReport($date: String, $line: [String], $factory: String) {
    productionPlanReport(date: $date, line: $line, factory: $factory) {
      id
      planNo
      planDate
      pdPlan
      factory
      shift
      partNo
      partName
      customer
      line
      section
      step
      process
      machine
      jph
      startTime
      endTime
      startTimeOT
      endTimeOT
      totalHour
      totalQuantity
      employee
      plApprove
      pdApprove
      engApprove
      qaApprove
      mtApprove
      remarks
      productivity
      rateQ
      performance
      oee
      status
      quantity
      iNG
      sNG
      productionWorker
      revision
    }
  }
`

export const GET_PRODUCTION_PLAN_BY_REVISION = gql`
  query ProductionPlanReportByRevision(
    $date: String
    $line: [String]
    $factory: String
    $shift: String
    $revision: String
  ) {
    productionPlanReportByRevision(
      date: $date
      line: $line
      factory: $factory
      shift: $shift
      revision: $revision
    ) {
      id
      planNo
      revision
      planDate
      pdPlan
      factory
      shift
      partNo
      partName
      customer
      line
      section
      step
      process
      machine
      jph
      startTime
      endTime
      startTimeOT
      endTimeOT
      totalHour
      totalQuantity
      employee
      plApprove
      pdApprove
      engApprove
      qaApprove
      mtApprove
      remarks
      productivity
      rateQ
      performance
      oee
      status
      quantity
      iNG
      sNG
      productionWorker
    }
  }
`

export const GET_SUMMARY_MACHINE = gql`
  query SummaryMachineOfPlan($date: String!) {
    summaryMachineOfPlan(date: $date) {
      partNo
      quantity
      machine
      shift
      process
      sNG
      packingBy
      productionWorker
    }
  }
`

export const GET_PROBLEM_MACHINE = gql`
  query ProblemStatusReport($dataProblemReport: IDataProblemReport) {
    problemStatusReport(dataProblemReport: $dataProblemReport) {
      id
      machine
      partNo
      process
      actionDate
      startTime
      endTime
      totalTime
      problemName
      remarks
    }
  }
`

export const GET_LINE_PRODUCTION_PLAN = gql`
  query Query($date: String!, $factory: String) {
    lineProductionPlan(date: $date, factory: $factory)
  }
`

export const GET_CAPACITY = gql`
  query Capacity($dateFrom: String, $dateTo: String) {
    capacity(dateFrom: $dateFrom, dateTo: $dateTo) {
      jph
      machine
      customer
      line
    }
  }
`
export const GET_VOLUME_PLAN = gql`
  query Order($quarter: Int) {
    volumePlanReport(quarter: $quarter) {
      order {
        month
        quantity
      }
      type
    }
  }
`

export const GET_VOLUME_PLAN_AUTO_PART = gql`
  query VolumePlanAutoPart($dateFrom: String, $dateTo: String, $type: String) {
    volumePlanAutoPart(dateFrom: $dateFrom, dateTo: $dateTo, type: $type) {
      customer
      quantity
    }
  }
`

export const GET_WORK_IN_PROCESS = gql`
  query WorkInProcess(
    $dateFrom: String
    $dateTo: String
    $factory: String
    $customer: String
    $partNo: String
  ) {
    workInProcess(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
      customer: $customer
      partNo: $partNo
    ) {
      factory
      jobOrder
      customer
      actionDate
      processOrder
      stepProcess
      partNo
      process
      machine
      line
      jph
      minStroke
      amount {
        quantity
        ng
        total
        complete
      }
      complete
      cost
    }
  }
`

export const UPLOAD_FILE_ORDER_GREEN_TAG = gql`
  query UploadTagGreenSaleOrder($file: Upload!) {
    uploadTagGreenSaleOrder(file: $file) {
      partNo
      dueDate
      quantity
      factory
      plant
    }
  }
`
