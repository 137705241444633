import { useLazyQuery } from "@apollo/client"
import moment from "moment"
import { useEffect, useMemo } from "react"
import { GET_PROBLEM_MACHINE } from "../../../common/Query/PlanningQuery"
import { DataProductionPlan } from "../ProductionPlanReport"
import { problemNameList } from "./DialogProblem"
import { IDataProblem } from "../../Production/component/SummaryMachine"
export interface IDictionaryPart {
  [id: string]: number
}

export interface IDictionaryPartName {
  [id: string]: IProblemOther
}

interface IProblemOther {
  totalTime: number
  problemNameList: string[]
}

interface IProps {
  setProblemTime: (data: IDictionaryPartName) => void
  setStatusRun: (status: boolean) => void
  planData: DataProductionPlan
}

const ProblemTime = (props: IProps): JSX.Element => {
  const { planData, setStatusRun, setProblemTime } = props
  const [getProblem] = useLazyQuery(GET_PROBLEM_MACHINE)

  const part: IDictionaryPartName = {
    "Machine Breakdown": { totalTime: 0, problemNameList: [] },
    "Wait QC First Set Up": { totalTime: 0, problemNameList: [] },
    other: { totalTime: 0, problemNameList: [] },
    PM: { totalTime: 0, problemNameList: [] },
    "DIE Breakdown": { totalTime: 0, problemNameList: [] },
    totalStopMachine: { totalTime: 0, problemNameList: [] }
  }
  const getProblemStatus = async () => {
    if (
      planData.machine &&
      planData.partNo &&
      planData.process &&
      planData.planDate
    ) {
      const dataProblemReport = await getProblem({
        variables: {
          dataProblemReport: {
            machine: planData.machine,
            partNo: planData.partNo,
            process: planData.process,
            actionDate: planData.planDate
          }
        },
        fetchPolicy: "network-only"
      })
      if (dataProblemReport?.data?.problemStatusReport.length > 0) {
        const problem = dataProblemReport?.data?.problemStatusReport

        checkStatus(problem)
        problemNameList.forEach((element: { value: string }) => {
          let total = 0
          const problemName: string[] = []
          problem.forEach((entry: IDataProblem) => {
            if (element.value === entry.problemName) {
              total += entry.totalTime
              problemName.push(
                entry.remarks +
                  " เป็นเวลา " +
                  Math.round(entry.totalTime * 60) +
                  " min"
              )
            }
          })
          part[element.value].totalTime = total
          part[element.value].problemNameList = problemName
          part["totalStopMachine"].totalTime += total
        })
      } else {
        checkStatus([])
      }
      setProblemTime(part)
    }
  }

  const checkStatus = (problemData: IDataProblem[]) => {
    const nowTime: string = moment(new Date()).format("HH:mm").toString()
    const nowDate: string = moment(new Date()).format("YYYY-MM-DD").toString()
    if (problemData.length > 0) {
      const checkProblem = problemData.filter((ele: IDataProblem) => {
        if (
          ele.startTime <= nowTime &&
          ele.endTime >= nowTime &&
          nowDate === ele.actionDate
        ) {
          return true
        }
        return false
      })
      if (checkProblem.length > 0) {
        setStatusRun(false)
      } else {
        setStatusRun(true)
      }
    } else {
      setStatusRun(true)
    }
  }
  useMemo(() => {
    getProblemStatus()
  }, [planData])

  useEffect(() => {
    setInterval(() => getProblemStatus(), 5 * 60 * 5000)
  }, [])

  return <></>
}

export default ProblemTime
