import { gql } from "@apollo/client"

export const UPDATE_USER_READ = gql`
  mutation UpdateUserRead($userRead: String, $updateUserReadId: Int) {
    updateUserRead(userRead: $userRead, id: $updateUserReadId)
  }
`
export const UPDATE_USER_READ_ALL = gql`
  mutation UpdateUserReadAll($userRead: String) {
    updateUserReadAll(userRead: $userRead)
  }
`
