import { Divider, Grid, Typography, useTheme } from "@mui/material"
import { ThemeProvider } from "@mui/styles"
import moment from "moment"
import { useRef, useState } from "react"
import ThemedTextField from "../../../../../common/Resources/ThemedComponents/ThemedTextField"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import AutocompleteFactory from "../../../../../common/Resources/AutocompleteFactory"
import { useQuery } from "@apollo/client"
import {
  GET_OBJECTIVE_CREATE,
  GET_PROBLEM_PM,
  GET_PROBLEM_PM_STACK
} from "../../../../../common/Query/engineerQuery"
import ThemedCard from "../../../../../common/Resources/ThemedComponents/ThemedCard"

interface IParetoProblem {
  factory: string
  problem_count: number
  grouped_problem: string
  __typename: string
}

const ChartDefectReport = (props: {
  chart?: HighchartsReact.Props
  data: IParetoProblem[]
  dataStack: IParetoProblem[]
  dataObj: IParetoProblem[]
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const { data, dataObj, dataStack } = props
  const newData = dataObj.concat(data)
  newData.sort((a, b) => {
    if (a.__typename === "objectiveCreate") return 1
    if (b.__typename === "objectiveCreate") return -1
    const countDiff = b.problem_count - a.problem_count
    if (countDiff !== 0) {
      return countDiff
    }
    return a.grouped_problem.localeCompare(b.grouped_problem, undefined, {
      numeric: true,
      sensitivity: "base"
    })
  })

  const totalData = newData.reduce((prev, cur) => {
    return (prev += cur.problem_count)
  }, 0)

  const percent =
    newData.reduce((prev: number[], item) => {
      const lastPercent = prev[prev.length - 1] || 0
      return prev.concat([(item.problem_count / totalData) * 100 + lastPercent])
    }, []) ?? []

  const findIndex = newData.findIndex((item) =>
    item.grouped_problem.includes("6.แม่พิมพ์ชำรุด")
  )

  const dataSerial = newData.map((item) => item.problem_count) ?? []

  dataSerial[findIndex] = dataStack[0]?.problem_count ?? null
  const arrayNull =
    findIndex > 0
      ? Array.from(Array(findIndex), () => {
          return null
        })
      : []

  const dataInStack = dataStack.slice(1)

  const theme = useTheme()

  const options = {
    chart: {
      renderTo: "container",
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      shared: true
    },
    xAxis: {
      categories: newData.map((item) => item.grouped_problem),
      crosshair: true,
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "15px",
          whiteSpace: "normal"
        }
      }
    },
    legend: {
      enabled: false
    },
    yAxis: [
      {
        title: {
          text: "Number of Defects"
        },
        stackLabels: {
          enabled: true
        }
      },
      {
        title: {
          text: "Cumulative %"
        },
        opposite: true
      }
    ],
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true
        }
      }
    },
    series: [
      {
        type: "column",
        name: "จำนวนปัญหา",
        data: dataSerial ?? [],
        stack: "defects",
        color: "#1E9fFF"
      },

      {
        type: "column",
        name: "Guide Pins and Bushings",
        data: [
          ...arrayNull,
          dataInStack.find(
            (item) =>
              item.grouped_problem === "6.แม่พิมพ์ชำรุด:Guide Pins and Bushings"
          )?.problem_count || null
        ],
        stack: "defects",
        color: "#00FFFF"
      },
      {
        type: "column",
        name: "Punches and Pierce",
        data: [
          ...arrayNull,
          dataInStack.find(
            (item) =>
              item.grouped_problem === "6.แม่พิมพ์ชำรุด:Punches and Pierce"
          )?.problem_count || null
        ],
        stack: "defects",
        color: "#00F"
      },
      {
        type: "column",
        name: "Buttons",
        data: [
          ...arrayNull,
          dataInStack.find(
            (item) => item.grouped_problem === "6.แม่พิมพ์ชำรุด:Buttons"
          )?.problem_count || null
        ],
        stack: "defects",
        color: "#ff0000"
      },
      {
        type: "column",
        name: "Springs",
        data: [
          ...arrayNull,
          dataInStack.find(
            (item) => item.grouped_problem === "6.แม่พิมพ์ชำรุด:Springs"
          )?.problem_count || null
        ],
        stack: "defects",
        color: "#00ff00"
      },
      {
        type: "column",
        name: "Bolt",
        data: [
          ...arrayNull,
          dataInStack.find(
            (item) => item.grouped_problem === "6.แม่พิมพ์ชำรุด:Bolt"
          )?.problem_count || null
        ],
        stack: "defects",
        color: "#ff00ff"
      },
      {
        type: "column",
        name: "Striper",
        data: [
          ...arrayNull,
          dataInStack.find(
            (item) => item.grouped_problem === "6.แม่พิมพ์ชำรุด:Striper"
          )?.problem_count || null
        ],
        stack: "defects",
        color: "#ffff00"
      },
      {
        type: "column",
        name: "Insert",
        data: [
          ...arrayNull,
          dataInStack.find(
            (item) => item.grouped_problem === "6.แม่พิมพ์ชำรุด:Insert"
          )?.problem_count || null
        ],
        stack: "defects",
        color: "#0ffaaa"
      },
      {
        type: "pareto",
        name: "Pareto",
        yAxis: 1,
        data: percent.map((item) => Math.floor(item)),
        zIndex: 10,
        marker: {
          enabled: true
        },
        tooltip: {
          valueSuffix: " %"
        }
      }
    ]
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props.chart}
      />
    </>
  )
}

const GraphDefectProblem: React.FC = () => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-01")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [factory, setFactory] = useState<string>("2")
  const { data: queryObjective } = useQuery(GET_OBJECTIVE_CREATE, {
    variables: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      factory: factory
    }
  })
  const { data: queryProblem } = useQuery(GET_PROBLEM_PM, {
    variables: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      factory: factory
    }
  })

  const { data: queryProblemStack } = useQuery(GET_PROBLEM_PM_STACK, {
    variables: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      factory: factory
    }
  })
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <ThemedCard sx={{ background: theme.palette.secondary.main }}>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", padding: "10px" }}
            >
              Defect Pareto Problem
            </Typography>
          </Grid>
          <Grid item container spacing={1} sm={9} padding={1}>
            <Grid item sm={4}>
              <ThemedTextField
                fullWidth
                value={dateFrom}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date From"
                autoComplete="family-name"
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Grid>
            <Grid item sm={4}>
              <ThemedTextField
                fullWidth
                value={dateTo}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date To"
                autoComplete="family-name"
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Grid>
            <Grid item sm={4}>
              <AutocompleteFactory setFactory={setFactory} factory={factory} />
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={12} justifyContent="center">
            <ChartDefectReport
              data={queryProblem?.getDefectParetoProblemPMHistory ?? []}
              dataStack={
                queryProblemStack?.getDefectParetoProblemPMHistoryStack ?? []
              }
              dataObj={queryObjective?.getDefectParetoProblemCreate ?? []}
            />
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default GraphDefectProblem
