import Highcharts from "highcharts/highstock"
import "highcharts/modules/accessibility"
import HighchartsReact from "highcharts-react-official"
import { useRef } from "react"
import { useTheme } from "@mui/material"

interface ITypeOEE {
  type: string
  percent: number
}
Highcharts.setOptions({ colors: ["#362FD9", "#2B3467"] })
const OEEDashBoard = (props: {
  data: ITypeOEE
  chart?: HighchartsReact.Props
}): JSX.Element => {
  const theme = useTheme()
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const options: Highcharts.Options = {
    chart: {
      type: "pie",
      backgroundColor: undefined,
      height: 150,
      width: 150
    },
    credits: {
      enabled: false
    },
    title: {
      text: `${props.data.type} ${props.data.percent.toFixed(2)}%`,
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: "12px",
        color: theme.palette.secondary.contrastText
      }
    },
    plotOptions: {
      pie: {
        slicedOffset: 0,
        size: "100%",
        dataLabels: {
          enabled: false
        },
        colors: [
          `${theme.palette.mode === "light" ? "#62CDFF" : "#2F58CD"}`,
          `${theme.palette.mode === "light" ? "#C9EEFF" : "#3795BD"}`
        ]
      }
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        type: "pie",
        data: [
          ["", props.data.percent],
          ["", 100 - props.data.percent]
        ],
        innerSize: "70%",
        showInLegend: false,
        dataLabels: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          }
        }
      }
    ]
  }
  return (
    <div style={{ float: "left" }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props.chart}
      />
    </div>
  )
}
export default OEEDashBoard
