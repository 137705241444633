import {
  Box,
  styled,
  Tab,
  TableCell,
  tableCellClasses,
  Tabs,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"

import { a11yProps, TabPanel } from "../Master/component/MachineDialogReport"

import "../../css/Maintenance/PreventiveMaintenancePlan.css"
import PMPlanRecord from "./component/PMPlanRecord"
import PmCheckListCreate from "./component/PmCheckListCreate"

import PmPlanYear from "./component/PmPlanYear"
import PmPlanMonth from "./component/PmPlanMonth"
import { useState } from "react"
import { CreateQRCode } from "./component/CreateQR"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"

const pageTitle = "Preventive Maintenance Plan"

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    fontSize: 25,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
    fontFamily: "Sarabun"
  }
}))

export const StyledTableCellApprove = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    textAlign: "center",
    fontSize: 20,
    fontFamily: "Sarabun"
  }
}))

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
]

const PreventiveMaintenancePlan: React.FC = () => {
  const [value, setValue] = useState<number>(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Maintenance}
        menuItemName={Object.keys(menuItemList)[12]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title={pageTitle}
          subTitle={"About Detail " + pageTitle}
        />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Plan Year" {...a11yProps(0)} />
              <Tab label="Plan Monthly" {...a11yProps(1)} />
              <Tab label="Plan Preventive Maintenance" {...a11yProps(2)} />
              <Tab label="Record List " {...a11yProps(3)} />
              <Tab label="Plan Year OS " {...a11yProps(4)} />
              <Tab label="Plan Month OS " {...a11yProps(5)} />
              <Tab label="Create QrCode" {...a11yProps(6)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <>
              <PmPlanYear status={false} />
            </>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <>
              <PmPlanMonth status={false} />
            </>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <>
              <PMPlanRecord />
            </>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <>
              <PmCheckListCreate />
            </>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <>
              <PmPlanYear status={true} />
            </>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <>
              <PmPlanMonth status={true} />
            </>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <>
              <CreateQRCode />
            </>
          </TabPanel>
        </Box>
      </ThemedCard>
    </ThemeProvider>
  )
}
export default PreventiveMaintenancePlan
