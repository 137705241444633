import { Dispatch, SetStateAction } from "react"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import moment from "moment"
import ThemedTextField from "./ThemedComponents/ThemedTextField"
import { useTheme } from "@mui/material"

interface IProps {
  setDate: Dispatch<SetStateAction<Date>>
  date: Date
  label: string
}
const DatePickerCustom = (props: IProps): JSX.Element => {
  const { date, setDate, label } = props
  const theme = useTheme()

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        label={label}
        openTo="day"
        views={["year", "month", "day"]}
        inputFormat="DD-MM-YYYY"
        value={date}
        onChange={(newDate) => {
          setDate(moment(newDate).toDate() as Date)
        }}
        PaperProps={{
          sx: {
            "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected": {
              color: theme.palette.text.secondary
            },
            "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected:focus": {
              backgroundColor: theme.palette.primary.contrastText
            }
          }
        }}
        renderInput={(params) => <ThemedTextField {...params} fullWidth />}
      />
    </LocalizationProvider>
  )
}
export default DatePickerCustom
