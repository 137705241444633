import { gql } from "@apollo/client"

export const authorizationProd = gql`
  query ProductionReport($date: Date, $factory: String) {
    productionReport(date: $date, factory: $factory) {
      date
      partNo
      machine
      jobOrder
      process
      pdLotNo
      componentLot
      line
      quantity
      iNG
      sNG
      tNG
      supplierNG
      operator
      totalCost
      remarks
      stampNG
      weldNG
      plateNG
      cost
    }
  }
`

export const authorizationQC = gql`
  query QcReport($date: Date, $factory: String) {
    qcReport(date: $date, factory: $factory) {
      date
      partNo
      machine
      process
      jobOrder
      pdLotNo
      componentLot
      line
      quantity
      iNG
      sNG
      tNG
      supplierNG
      operator
      totalCost
      stampNG
      weldNG
      plateNG
      cost
      remarks
    }
  }
`

export const GET_CONFIRM = gql`
  query ConfirmReport($department: String) {
    confirmReport(department: $department) {
      name
      date
      factory
      actionDate
    }
  }
`
