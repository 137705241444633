import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import {
  Grid,
  Autocomplete,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  useTheme,
  ThemeProvider
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import PrintIcon from "@mui/icons-material/Print"
import { styled } from "@mui/material/styles"
import CircularProgress from "@mui/material/CircularProgress"
import moment from "moment"
import { GET_DELIVERY_TAG_REPORT } from "../../common/Query/LogisticsQuery"
import {
  gql,
  LazyQueryResult,
  QueryLazyOptions,
  useLazyQuery,
  useQuery
} from "@apollo/client"
import "../../css/Logistics/LogisticsCSS.css"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import { logoTable } from "../../common/utilities"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Barcode = require("react-barcode")

interface DeliveryTagReportData {
  dateTo: string
  dateFrom: string
  partNo: string
  factory: string
  period: string
}
export interface DeliveryTagReportQueryArgument {
  data: DeliveryTagReportData
}

export interface ISearchDeliveryTagReportProps {
  dateTo: string
  dateFrom: string
  partNo: string
  factory: string
  period: string
  partNoList: string[]
  setDateTo: Dispatch<SetStateAction<string>>
  setDateFrom: Dispatch<SetStateAction<string>>
  setPartNo: Dispatch<SetStateAction<string>>
  setFactory: Dispatch<SetStateAction<string>>
  setPeriod: Dispatch<SetStateAction<string>>
  getDeliveryTagReport: (
    options?: QueryLazyOptions<{ data: DeliveryTagReportData }>
  ) => Promise<LazyQueryResult<unknown, { data: DeliveryTagReportData }>>
}

export interface DeliveryReportType {
  partNo: string
  partName: string
  customer: string
  model: string
  quantity: number
  totalQuantity: number
  packingBy: string
  deliveryDate: string
  pdLotNo: string
  remarks: string
  boxCount: string
  factory: string
  period: string
  poCustomer: string
  status: string
}

export interface PartNoList {
  parts: PartNo[]
}

export interface PartNo {
  partNo: string
}

const StyledTableCell = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  textAlign: "center",
  borderBottom: "1px"
}))

const StyledTableCellHeader = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  textAlign: "center",
  borderBottom: "1px",
  fontWeight: "bold",
  border: "solid 1px black"
}))

const StyledTableRow = styled(TableRow)(() => ({
  borderBottom: "1px",
  "&:last-child td": { borderBottom: "1px solid black" }
}))

export const GET_PARTNO = gql`
  query Parts {
    parts {
      partNo
    }
  }
`

const pageTitle = "Delivery Tag Report"

export const SearchDeliveryTagReport: React.FC<
  ISearchDeliveryTagReportProps
> = (props: ISearchDeliveryTagReportProps) => {
  const {
    dateTo,
    dateFrom,
    partNo,
    factory,
    period,
    partNoList,
    setDateTo,
    setDateFrom,
    setPartNo,
    setFactory,
    setPeriod,
    getDeliveryTagReport
  } = props

  const periodList = ["รอบปกติ", "รอบเสริม", "รอบพิเศษ"]

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault()
    getDeliveryTagReport()
  }

  const theme = useTheme()

  return (
    <>
      <Grid container spacing={2} width={"98%"} padding={2}>
        <Grid item xs={12} sm={2.5}>
          <ThemedTextField
            value={dateFrom}
            label="Date From"
            type="date"
            fullWidth
            onChange={(e) => {
              setDateFrom(e.target.value)
            }}
          ></ThemedTextField>
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <ThemedTextField
            value={dateTo}
            label="Date To"
            fullWidth
            type="date"
            onChange={(e) => {
              setDateTo(e.target.value)
            }}
          ></ThemedTextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            fullWidth
            value={partNo}
            loading={partNoList.length === 0}
            disablePortal
            onChange={(event, selectedPartNo) => {
              setPartNo(selectedPartNo as string)
            }}
            options={partNoList}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                label="Part No"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Autocomplete
            fullWidth
            value={factory}
            disableClearable
            disablePortal
            onChange={(event, selectedFactory) => {
              setFactory(selectedFactory as string)
            }}
            options={["1", "2"]}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                label="Factory"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            fullWidth
            value={period}
            disableClearable
            disablePortal
            onChange={(event, selectedPeriod) => {
              setPeriod(selectedPeriod as string)
            }}
            options={periodList}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                label="Period"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <ThemedButton
            style={{
              backgroundColor: theme.palette.primary.main
            }}
            variant="contained"
            endIcon={<SearchIcon />}
            onClick={handleSearch}
          >
            ค้นหา
          </ThemedButton>

          <ThemedButton
            variant="outlined"
            style={{
              color: theme.palette.secondary.contrastText,
              borderColor: theme.palette.primary.main,
              marginLeft: "15px"
            }}
            endIcon={<PrintIcon />}
            onClick={() => window.print()}
          >
            Print
          </ThemedButton>
        </Grid>
      </Grid>
    </>
  )
}

export const DeliveryTagReportSignature: React.FC = () => {
  return (
    <div style={{ maxWidth: "50vw", marginTop: "20px" }}>
      <Table>
        <TableHead>
          <StyledTableRow style={{ height: "73px", border: "solid 1px black" }}>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCellHeader>SENT by</StyledTableCellHeader>
            <StyledTableCellHeader>APPROVED by</StyledTableCellHeader>
            <StyledTableCellHeader>RECEIVED by</StyledTableCellHeader>
          </StyledTableRow>
        </TableBody>
      </Table>
    </div>
  )
}

const DeliveryTagReport = (): JSX.Element => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [partNo, setPartNo] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [period, setPeriod] = useState<string>("")
  const { data: partNoData, loading: partNoLoading } = useQuery(GET_PARTNO)

  const [
    getDeliveryTagReport,
    { data: deliveryTagReportData, loading, called }
  ] = useLazyQuery(GET_DELIVERY_TAG_REPORT, {
    variables: {
      data: {
        dateTo: moment(dateTo).format("YYYY-MM-DD"),
        dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
        partNo,
        factory,
        period
      }
    }
  })

  const makePartNoList = (partsList: PartNoList, loading: boolean) => {
    if (loading || !partsList || partsList.parts.length === 0) return []
    return partsList.parts.map((part: PartNo) => part.partNo)
  }

  const partNoList = useMemo(
    () => makePartNoList(partNoData, partNoLoading),
    [partNoData, partNoLoading]
  )

  const deliveryTagReportBody =
    called && loading ? (
      <StyledTableRow data-testid="delivery-tag-report-table-loading">
        <StyledTableCell colSpan={13}>
          <CircularProgress />
        </StyledTableCell>
      </StyledTableRow>
    ) : !called ||
      !deliveryTagReportData ||
      !deliveryTagReportData.deliveryReport.length ? (
      <></>
    ) : (
      deliveryTagReportData.deliveryReport.map(
        (entry: DeliveryReportType, index: number) => {
          const barcodeVal = `${entry.partNo}|${entry.deliveryDate}|${entry.quantity}|${entry.poCustomer}`
          return (
            <StyledTableRow key={index} className="delivery-tag-report-table">
              <StyledTableCell>{entry.deliveryDate}</StyledTableCell>
              <StyledTableCell>{entry.partNo}</StyledTableCell>
              <StyledTableCell>{entry.partName}</StyledTableCell>
              <StyledTableCell
                style={{
                  color:
                    period === "รอบเสริม"
                      ? "yellow"
                      : period === "รอบพิเศษ"
                      ? "red"
                      : "green"
                }}
              >
                {entry.period}
              </StyledTableCell>
              <StyledTableCell>{entry.customer}</StyledTableCell>
              <StyledTableCell>{entry.pdLotNo}</StyledTableCell>
              <StyledTableCell>{entry.totalQuantity}</StyledTableCell>
              <StyledTableCell>{entry.quantity}</StyledTableCell>
              <StyledTableCell>{entry.status}</StyledTableCell>
              <StyledTableCell>{entry.packingBy}</StyledTableCell>
              <StyledTableCell>{entry.factory}</StyledTableCell>
              <StyledTableCell>
                <div className="barcode-resize">
                  <Barcode value={barcodeVal} />
                </div>
              </StyledTableCell>
              <StyledTableCell>{entry.remarks}</StyledTableCell>
            </StyledTableRow>
          )
        }
      )
    )

  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Logistics}
        menuItemName={Object.keys(menuItemList)[8]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="Delivery Tag Report"
          subTitle="detail about Delivery Tag Report"
        />
        <div className="hidden-print">
          <SearchDeliveryTagReport
            dateTo={dateTo}
            dateFrom={dateFrom}
            partNo={partNo}
            factory={factory}
            period={period}
            partNoList={partNoList}
            setDateTo={setDateTo}
            setDateFrom={setDateFrom}
            setPartNo={setPartNo}
            setFactory={setFactory}
            setPeriod={setPeriod}
            getDeliveryTagReport={getDeliveryTagReport}
          />
        </div>
      </ThemedCard>
      <div className="delivery-tag-report-to-print">
        <TableContainer>
          <Table
            sx={{ minWidth: 700, border: "1px solid black" }}
            aria-label="customized table"
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCellHeader colSpan={4}>
                  <img src={logoTable} alt="Brother Auto Parts." />
                </StyledTableCellHeader>
                <StyledTableCellHeader align="center" colSpan={6}>
                  <span style={{ fontSize: "18px" }}>
                    Part Delivery Summary
                  </span>
                </StyledTableCellHeader>
                <StyledTableCellHeader align="center" colSpan={3}>
                  <p>
                    <span style={{ fontSize: "18px" }}>F-PL-03-09/1</span>
                    <br />
                    Effective Date : 1-10-21 Rev.00
                  </p>
                </StyledTableCellHeader>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCellHeader>Delivery Date</StyledTableCellHeader>
                <StyledTableCellHeader>Part No</StyledTableCellHeader>
                <StyledTableCellHeader>Part Name</StyledTableCellHeader>
                <StyledTableCellHeader>Periods</StyledTableCellHeader>
                <StyledTableCellHeader>Customer</StyledTableCellHeader>
                <StyledTableCellHeader>PD Lot No</StyledTableCellHeader>
                <StyledTableCellHeader>Order Plan</StyledTableCellHeader>
                <StyledTableCellHeader>Action Plan</StyledTableCellHeader>
                <StyledTableCellHeader>Status</StyledTableCellHeader>
                <StyledTableCellHeader>Packing By</StyledTableCellHeader>
                <StyledTableCellHeader>Factory</StyledTableCellHeader>
                <StyledTableCellHeader>Bar Code</StyledTableCellHeader>
                <StyledTableCellHeader>Remarks</StyledTableCellHeader>
              </StyledTableRow>
            </TableHead>
            <TableBody>{deliveryTagReportBody}</TableBody>
          </Table>
        </TableContainer>
        <hr className="hidden-print" style={{ marginTop: "20px" }} />
        <DeliveryTagReportSignature />
      </div>
    </ThemeProvider>
  )
}

export default DeliveryTagReport
