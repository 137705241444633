import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Tabs
} from "@mui/material"

import { getImagePng, miniLogo } from "../../../common/utilities"
import { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { GET_MACHINE_BREAK_DOWN } from "../../../common/Query/ProductionQuery"
import { GET_PM_RECORD } from "../../../common/Query/MaintenanceQuery"
import moment from "moment"
import { IMachine } from "../MachineMt"
import { printDiv } from "../PMReport"

interface IProps {
  dialogOpen: boolean
  setDialogOpen: (status: boolean) => void
  machine?: IMachine
}

interface IPropsTabPanel {
  children?: React.ReactNode
  index: number
  value: number
}

export const TabPanel = (props: IPropsTabPanel): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

interface IArrayProps {
  id: string
  "aria-controls": string
}

export const a11yProps = (index: number): IArrayProps => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: "solid black 1px",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun",
    // border: "solid black 1px"
    borderBottom: "solid black 1px"
  }
}))

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: "solid black 1px",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun",
    border: "solid black 1px"
    // borderBottom: "solid black 1px"
  }
}))

interface IDataProblemReport {
  id: number
  machine: string
  partNo: string
  process: string
  actionDate: string
  startTime: string
  endTime: string
  totalTime: number
  problemName: string
  remarks: string
  detailRepair: IDataDetailRepair[]
}

export interface IPMReport {
  id: number
  machineCode: string
  actionDate: string
  startTime: string
  endTime: string
  totalTime: number
  operator: string
  maintenance: string
  remarks: string
}
interface IDataDetailRepair {
  problemName: string
  correction: string
  protection: string
}

const MachineDialogReportMt = (props: IProps): JSX.Element => {
  const { dialogOpen, setDialogOpen, machine } = props
  const [value, setValue] = useState<number>(0)
  const [dataMachineBreakDown, setDataMachineBreakDown] = useState<
    IDataProblemReport[]
  >([])
  const [dataPMRecord, setDataPMRecord] = useState<IPMReport[]>([])
  const oneHour = 60
  const updateTotalTime = dataPMRecord?.map((item) => ({
    ...item,
    totalTime: Math.round(item?.totalTime * oneHour)
  }))
  const updateDataMachineBreakDown = dataMachineBreakDown?.map((item) => ({
    ...item,
    totalTime: Math.round(item?.totalTime * oneHour)
  }))

  const { data } = useQuery(GET_MACHINE_BREAK_DOWN, {
    variables: {
      machine: machine?.code ?? ""
    },
    fetchPolicy: "no-cache"
  })

  const { data: rawDataPMRecord } = useQuery(GET_PM_RECORD, {
    variables: {
      machine: machine?.code ?? ""
    }
  })
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  useEffect(() => {
    setDataMachineBreakDown(data?.machineBreakdownReport ?? [])
  }, [data])

  useEffect(() => {
    setDataPMRecord(rawDataPMRecord?.pmReport ?? [])
  }, [rawDataPMRecord])

  const getRowSpan = (detailRepair: IDataDetailRepair[]) => {
    return detailRepair?.length || 1
  }
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"xl"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        >
          ประวัติเครื่องจักร {machine?.machine}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="History" {...a11yProps(0)} />
                <Tab label="PM history" {...a11yProps(1)} />
                <Tab label="Break down History" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div id="machine history">
                <Table
                  style={{
                    border: "solid black 1px",
                    borderCollapse: "collapse"
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell colSpan={3}>
                        <img src={miniLogo} alt="" width={"120px"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          textAlign: "center",
                          fontSize: "30px",
                          whiteSpace: "nowrap"
                        }}
                        colSpan={5}
                      >
                        ประวัติเครื่องจักร
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ textAlign: "center", whiteSpace: "nowrap" }}
                        colSpan={3}
                      >
                        Doc.No. F-MT-01-11
                        <br /> Effective Date: 01.10.10 R00 Issue:1
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell colSpan={1}>
                        ชื่อเครื่องจักร :
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        {machine?.machine}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1}>
                        รหัสเครื่องจักร :
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        {machine?.code}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          border: "1px solid black"
                        }}
                        colSpan={3}
                        rowSpan={7}
                      >
                        <img
                          src={getImagePng(`images/machine/${machine?.code}`)}
                          alt=""
                          width={"350px"}
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto"
                          }}
                        />
                      </StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell colSpan={1}>แผนก :</StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        {machine?.line}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1}>
                        สถานที่ติดั้ง :
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        {machine?.factory}
                      </StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell colSpan={1}>
                        ลำดับความสำคัญ :
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        {machine?.priority}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1}>ความถี่ :</StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        {machine?.frequency}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell colSpan={1}>
                        บริษัทที่ผลิต :
                      </StyledTableCell>
                      <StyledTableCell colSpan={5}>
                        {machine?.company}
                      </StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell colSpan={1}>รุ่น :</StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        {machine?.model}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1}>ยี่ห้อ :</StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        {machine?.brand}
                      </StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell colSpan={1}>
                        กำลังเครื่องจักร :
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        {machine?.machinePower}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1}>
                        วันที่ติดตั้ง :
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        {machine?.installationDate}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell colSpan={1} style={{ float: "left" }}>
                        รายละเอียด :
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={5}
                        style={{ whiteSpace: "pre" }}
                      >
                        {machine?.details}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div id="PMReport">
                <Table
                  style={{
                    border: "solid black 1px",
                    borderCollapse: "collapse"
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell2
                        style={{
                          textAlign: "center",
                          fontSize: "30px",
                          whiteSpace: "nowrap"
                        }}
                        colSpan={8}
                      >
                        PREVENTIVE MAINTENANCE REPORT
                      </StyledTableCell2>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell2
                        style={{
                          textAlign: "center",
                          fontSize: "30px",
                          whiteSpace: "nowrap"
                        }}
                        colSpan={4}
                      >
                        Machine: {machine?.machine}
                      </StyledTableCell2>
                      <StyledTableCell2
                        style={{
                          textAlign: "center",
                          fontSize: "30px",
                          whiteSpace: "nowrap"
                        }}
                        colSpan={4}
                      >
                        Code: {machine?.code}
                      </StyledTableCell2>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell2 rowSpan={2}>No.</StyledTableCell2>
                      <StyledTableCell2 rowSpan={2}>Date</StyledTableCell2>
                      <StyledTableCell2 rowSpan={2}>Start</StyledTableCell2>
                      <StyledTableCell2 rowSpan={2}>Finish</StyledTableCell2>
                      <StyledTableCell2 rowSpan={2}>TotalTime</StyledTableCell2>
                      <StyledTableCell2 colSpan={2}>INCHANGE</StyledTableCell2>
                      <StyledTableCell2 rowSpan={2}>Remark</StyledTableCell2>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell2 colSpan={1}>MT</StyledTableCell2>
                      <StyledTableCell2 colSpan={1}>OPER'R</StyledTableCell2>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {updateTotalTime?.map((value, index: number) => {
                      return (
                        <TableRow>
                          <StyledTableCell2>{index + 1}</StyledTableCell2>
                          <StyledTableCell2>
                            {moment(value.actionDate).format("DD-MM-YYYY")}
                          </StyledTableCell2>
                          <StyledTableCell2>{value.startTime}</StyledTableCell2>
                          <StyledTableCell2>{value.endTime}</StyledTableCell2>
                          <StyledTableCell2>
                            {value.totalTime} Min.
                          </StyledTableCell2>
                          <StyledTableCell2>
                            {value.maintenance}
                          </StyledTableCell2>
                          <StyledTableCell2>{value.operator}</StyledTableCell2>
                          <StyledTableCell2>{value.remarks}</StyledTableCell2>
                        </TableRow>
                      )
                    })}
                    <TableRow>
                      <StyledTableCell2 />
                      <StyledTableCell2 />
                      <StyledTableCell2 />
                      <StyledTableCell2 />
                      <StyledTableCell2 />
                      <StyledTableCell2 />
                      <StyledTableCell2 />
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div id="breakDownHistory">
                <Table
                  style={{
                    border: "solid black 1px",
                    borderCollapse: "collapse"
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell2
                        style={{
                          textAlign: "center",
                          fontSize: "30px",
                          whiteSpace: "nowrap"
                        }}
                        colSpan={8}
                      >
                        Machine Break Down Report
                      </StyledTableCell2>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell2
                        style={{
                          textAlign: "center",
                          fontSize: "30px",
                          whiteSpace: "nowrap"
                        }}
                        colSpan={3}
                      >
                        Machine: {machine?.machine}
                      </StyledTableCell2>
                      <StyledTableCell2
                        style={{
                          textAlign: "center",
                          fontSize: "30px",
                          whiteSpace: "nowrap"
                        }}
                        colSpan={5}
                      >
                        Code: {machine?.code}
                      </StyledTableCell2>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell2>No.</StyledTableCell2>
                      <StyledTableCell2>Date</StyledTableCell2>
                      <StyledTableCell2>Start</StyledTableCell2>
                      <StyledTableCell2>Finish</StyledTableCell2>
                      <StyledTableCell2>Total</StyledTableCell2>
                      <StyledTableCell2>Details</StyledTableCell2>
                      <StyledTableCell2>Details Repair</StyledTableCell2>
                      <StyledTableCell2>Remark</StyledTableCell2>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {updateDataMachineBreakDown?.map((value, index: number) => {
                      return (
                        <>
                          <TableRow key={index}>
                            <StyledTableCell2
                              rowSpan={getRowSpan(value.detailRepair)}
                            >
                              {index + 1}
                            </StyledTableCell2>
                            <StyledTableCell2
                              rowSpan={getRowSpan(value.detailRepair)}
                            >
                              {value.actionDate}
                            </StyledTableCell2>
                            <StyledTableCell2
                              rowSpan={getRowSpan(value.detailRepair)}
                            >
                              {value.startTime}
                            </StyledTableCell2>
                            <StyledTableCell2
                              rowSpan={getRowSpan(value.detailRepair)}
                            >
                              {value.endTime}
                            </StyledTableCell2>
                            <StyledTableCell2
                              rowSpan={getRowSpan(value.detailRepair)}
                            >
                              {value.totalTime} Min.
                            </StyledTableCell2>

                            <StyledTableCell2 rowSpan={1}>
                              {value?.detailRepair?.length
                                ? value?.detailRepair[0]?.problemName ||
                                  value.problemName
                                : value.problemName}
                            </StyledTableCell2>
                            <StyledTableCell2 rowSpan={1}>
                              {value?.detailRepair?.length
                                ? value?.detailRepair[0]?.correction
                                : ""}
                            </StyledTableCell2>

                            <StyledTableCell2
                              rowSpan={getRowSpan(value.detailRepair)}
                            >
                              {value.remarks}
                            </StyledTableCell2>
                          </TableRow>
                          {value?.detailRepair?.length > 1 ? (
                            value.detailRepair.slice(1).map((val, index) => {
                              return (
                                <TableRow key={index + val.problemName}>
                                  <StyledTableCell2 rowSpan={1}>
                                    {val.problemName}
                                  </StyledTableCell2>
                                  <StyledTableCell2 rowSpan={1}>
                                    {val.correction}
                                  </StyledTableCell2>
                                </TableRow>
                              )
                            })
                          ) : (
                            <></>
                          )}
                        </>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            </TabPanel>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "green" }}
            onClick={() =>
              printDiv(
                value === 0
                  ? "machine history"
                  : value === 1
                  ? "PMReport"
                  : "breakDownHistory"
              )
            }
          >
            Print
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MachineDialogReportMt
