import { useMutation, useQuery } from "@apollo/client"
import { Autocomplete, Grid, useTheme } from "@mui/material"
import { useMemo, useState } from "react"
import swal from "sweetalert"
import {
  CREATE_PM_CHECK_LIST,
  UPDATE_PM_CHECK_LIST
} from "../../../common/Mutation/MaintenanceMutation"
import AddIcon from "@mui/icons-material/Add"
import { PageLabelCard } from "../../../common/Resources/PageLabelCard"
import { GET_PM_ALL_CHECK_LIST } from "../../../common/Query/MaintenanceQuery"
import DialogDeletePMChecklist from "./DialogDeletePMChecklist"
import ThemedCard from "../../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
export const typeList = [
  "PRESS",
  "AIR COMPRESSOR",
  "AIR DRYER",
  "FORK LIFT",
  "FEEDER",
  "SPOT WELDING",
  "DIE TRANSFER",
  "COOLING TOWER",
  "NUT FEEDER",
  "FEED COIL",
  "TAPPING MACHINE",
  "CRANE",
  "CONVEYOR",
  "TRANSFORMER",
  "ROBOT ARM ABB",
  "ROBOT ARM TCR",
  "ROBOT WELDING",
  "GRINDING",
  "PRESS HYDROLIC",
  "CHROMIUM",
  "EDP",
  "ZINC",
  "COLLET LATHE",
  "CNC",
  "WIRE CUT",
  "SUPPER DRILL",
  "DEI SPOT"
]

export interface IReportPMCheckList {
  id: number
  type: string
  details: string
  method: string
  tool: string
  specificationReference: string
  remarks: string
  status?: boolean
}

const PmCheckListCreate = (): JSX.Element => {
  const [type, setType] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const [details, setDetails] = useState<string>("")
  const [method, setMethod] = useState<string>("")
  const [tool, setTool] = useState<string>("")
  const [specificationReference, setSpecificationReference] =
    useState<string>("")
  const [remarks, setRemarks] = useState<string>("")

  const [id, setId] = useState<number>(0)
  const [typeUpdate, setTypeUpdate] = useState<string>("")
  const [detailsUpdate, setDetailsUpdate] = useState<string>("")
  const [typeUpdate2, setTypeUpdate2] = useState<string>("")
  const [detailsUpdate2, setDetailsUpdate2] = useState<string>("")
  const [methodUpdate, setMethodUpdate] = useState<string>("")
  const [toolUpdate, setToolUpdate] = useState<string>("")
  const [specificationReferenceUpdate, setSpecificationReferenceUpdate] =
    useState<string>("")
  const [remarksUpdate, setRemarksUpdate] = useState<string>("")

  const { data: dataPmAllCheckList } = useQuery(GET_PM_ALL_CHECK_LIST)
  let pmCheckList: IReportPMCheckList[] =
    dataPmAllCheckList?.pmCheckListAllReport || []

  const [createPMCheckList, { loading }] = useMutation(CREATE_PM_CHECK_LIST)

  const [updatePMCheckList, { loading: loadingUpdateCheckList }] =
    useMutation(UPDATE_PM_CHECK_LIST)

  const theme = useTheme()

  const isComplete = () => {
    return type && details && method && tool && specificationReference
  }

  const isCompleteUpdate = () => {
    return (
      typeUpdate &&
      detailsUpdate &&
      methodUpdate &&
      toolUpdate &&
      specificationReferenceUpdate &&
      id !== 0
    )
  }

  const handleSubmit = async () => {
    try {
      if (!isComplete()) {
        return swal("Warning", "กรุณาใส่ข้อมูลให้ครบถ้วน", "warning")
      }
      await createPMCheckList({
        variables: {
          inputPmCheckList: {
            type: type,
            details: details,
            method: method,
            tool: tool,
            specificationReference: specificationReference,
            remarks: remarks
          }
        }
      })
      swal("Success", `Create Success`, "success")
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  const handleSubmitUpdate = async () => {
    try {
      if (!isCompleteUpdate()) {
        return swal("Warning", "กรุณาใส่ข้อมูลให้ครบถ้วน", "warning")
      }
      await updatePMCheckList({
        variables: {
          inputPmCheckList: {
            id: id,
            type: typeUpdate,
            details: detailsUpdate,
            method: methodUpdate,
            tool: toolUpdate,
            specificationReference: specificationReferenceUpdate,
            remarks: remarksUpdate
          }
        }
      })
      swal("Success", `Update Success`, "success")
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  useMemo(() => {
    if (typeUpdate) {
      pmCheckList = pmCheckList.filter((val) => val.type === typeUpdate)
    }
    if (typeUpdate && detailsUpdate) {
      const pmCheckListOne = pmCheckList.find(
        (val) => val.details === detailsUpdate && val.type === typeUpdate
      )
      setId(pmCheckListOne?.id || 0)
      setMethodUpdate(pmCheckListOne?.method || "")
      setTypeUpdate2(pmCheckListOne?.type || "")
      setDetailsUpdate2(pmCheckListOne?.details || "")
      setToolUpdate(pmCheckListOne?.tool || "")
      setSpecificationReferenceUpdate(
        pmCheckListOne?.specificationReference || ""
      )
      setRemarksUpdate(pmCheckListOne?.remarks || "")
    }
  }, [typeUpdate, detailsUpdate])

  return (
    <>
      <>
        <DialogDeletePMChecklist open={open} setOpen={setOpen} />
      </>
      <>
        <ThemedCard>
          <PageLabelCard title="Create Check List" />
          <Grid container spacing={2} width={"98%"} padding={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                id="type"
                value={type}
                freeSolo
                options={typeList}
                onChange={(e, newValue) => setType(newValue ?? "")}
                renderInput={(params) => (
                  <ThemedTextField {...params} label={"Select type"} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                label={"Detail"}
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                id="detail"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                id="method"
                label={"Method"}
                fullWidth
                value={method}
                onChange={(e) => setMethod(e.target.value)}
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                id="tool"
                label={"Tool"}
                fullWidth
                value={tool}
                onChange={(e) => setTool(e.target.value)}
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                id="specificationReference"
                label={"specificationReference"}
                fullWidth
                value={specificationReference}
                onChange={(e) => setSpecificationReference(e.target.value)}
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                id="remark"
                label={"Remark"}
                fullWidth
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                autoComplete="family-name"
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <ThemedLoadingButton
                variant="contained"
                sx={{
                  mt: 1,
                  mb: 2,
                  backgroundColor: theme.palette.primary.main
                }}
                onClick={handleSubmit}
                loading={loading}
                endIcon={<AddIcon />}
              >
                Submit
              </ThemedLoadingButton>
            </Grid>
          </Grid>
        </ThemedCard>

        <ThemedCard>
          <PageLabelCard title="Update Or Delete Check List" key={"update2"} />
          <Grid container spacing={2} width={"98%"} padding={2}>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disablePortal
                id="type"
                value={typeUpdate}
                freeSolo
                options={typeList}
                onChange={(e, newValue) => setTypeUpdate(newValue ?? "")}
                renderInput={(params) => (
                  <ThemedTextField {...params} label={"Select type"} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disablePortal
                id="details"
                value={detailsUpdate}
                freeSolo
                options={pmCheckList.map((e) => e.details)}
                onChange={(e, newValue) => setDetailsUpdate(newValue ?? "")}
                renderInput={(params) => (
                  <ThemedTextField {...params} label={"Select Details"} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
              <Autocomplete
                disablePortal
                id="type"
                value={typeUpdate2}
                freeSolo
                options={typeList}
                onChange={(e, newValue) => setTypeUpdate2(newValue ?? "")}
                renderInput={(params) => (
                  <ThemedTextField {...params} label={"Select type"} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
              <ThemedTextField
                id="details"
                label={"details"}
                fullWidth
                value={detailsUpdate2}
                onChange={(e) => setDetailsUpdate2(e.target.value)}
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                id="method"
                label={"Method"}
                fullWidth
                value={methodUpdate}
                onChange={(e) => setMethodUpdate(e.target.value)}
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                id="tool"
                label={"Tool"}
                fullWidth
                value={toolUpdate}
                onChange={(e) => setToolUpdate(e.target.value)}
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                id="specificationReference"
                label={"specificationReference"}
                fullWidth
                value={specificationReferenceUpdate}
                onChange={(e) =>
                  setSpecificationReferenceUpdate(e.target.value)
                }
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                id="remark"
                label={"Remark"}
                fullWidth
                value={remarksUpdate}
                onChange={(e) => setRemarksUpdate(e.target.value)}
                autoComplete="family-name"
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <ThemedLoadingButton
                variant="contained"
                sx={{
                  mt: 1,
                  mb: 2,
                  backgroundColor: theme.palette.primary.main
                }}
                onClick={handleSubmitUpdate}
                loading={loadingUpdateCheckList}
              >
                Submit Update
              </ThemedLoadingButton>
              <ThemedLoadingButton
                style={{ marginLeft: "10px" }}
                variant="contained"
                sx={{ mt: 1, mb: 2, backgroundColor: theme.palette.error.main }}
                onClick={() => setOpen(true)}
              >
                Delete
              </ThemedLoadingButton>
            </Grid>
          </Grid>
        </ThemedCard>
      </>
    </>
  )
}

export default PmCheckListCreate
