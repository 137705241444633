import { ReactElement } from "react"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  Grid,
  Autocomplete,
  styled,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material"

import React, { useState } from "react"
import { GET_PARTS } from "../../common/Query/MasterQuery"
import swal from "sweetalert"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { menuItemList } from "../../common/Resources/menuItemList"
import { GET_WH_FG_REPORT_ALL } from "../../common/Query/WarehouseQuery"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import { EDIT_FG } from "../../common/Mutation/WarehouseMutation"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

interface IFGDataReport {
  __typename?: string
  id: number
  partNo: string
  pdLotNo: string
  inOrOut: number
  actionDate: Date
  quantity: number
  factory: string
  packingBy: string
  isNG: number
  dateScan: Date
  remarks: string
}

const pageTitle = "WH FG Edit"
let customer = ""

export const partOs: string[] = []

const WarehouseEditFG: React.FC = (): ReactElement => {
  const [partNo, setPartNo] = useState<string>("")
  const [factory, setFactory] = useState<string>("1")
  const [rows, setRows] = useState<IFGDataReport[]>([])
  const [error, setError] = useState<boolean>(false)
  const getParts = useQuery(GET_PARTS)
  const [editFG] = useMutation(EDIT_FG)
  const [getFGReport, { loading }] = useLazyQuery(GET_WH_FG_REPORT_ALL, {
    variables: { partNo, factory },
    fetchPolicy: "network-only"
  })

  const Root = styled("div")(
    () => `
      table {
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        border-collapse: collapse;
        width: 100%;
      }
  
      td,
      th {
        border: 1px solid black;
        padding: 10px;
      }
  
  
      `
  )

  const handleDelete = async (row: IFGDataReport) => {
    const data = {
      ...row
    }
    delete data.__typename
    try {
      await editFG({
        variables: {
          dataDeleteFg: data
        }
      })
      handleSubmit()
      swal("Success", "Create FG In Success", "success")
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "warning")
    }
  }

  const handleSubmit = async () => {
    if (partNo === "") {
      setError(true)
      return swal("Error", "ใส่ข้อมูล PartNo", "error")
    }
    if (partNo && factory) {
      const dataFGReport = await getFGReport()
      setRows(dataFGReport?.data?.whFGReportAll || [])
    }
  }

  const checkCustomer = () => {
    if (partNo) {
      const partNoFilter = getParts?.data?.parts.find(
        (e: { partNo: string }) => {
          return e.partNo === partNo
        }
      )
      customer = partNoFilter?.customer
      if (customer === "MAHLE" || partOs.includes(partNo)) {
        if (factory === "2") setFactory("1")
        return true
      }
      return false
    }
    return false
  }

  return (
    <Root>
      <PageLabel
        menuItem={menuItemList.Warehouse}
        menuItemName={Object.keys(menuItemList)[6]}
        menuPageName={pageTitle}
      />
      <ThemedCard className="hide-print">
        <PageLabelCard
          title="Warehouse Edit FG"
          subTitle="detail about Warehouse Edit FG"
        />
        <Grid container spacing={2} width={"98%"} margin={2}>
          <Grid item xs={12} sm={4}>
            <AutocompletePartNo
              setPartNo={setPartNo}
              partNo={partNo}
              error={!partNo && error}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              disabled={checkCustomer()}
              id="factory"
              value={factory}
              options={["1", "2"]}
              onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
              renderInput={(params) => (
                <ThemedTextField {...params} label="Factory" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="hide-print">
            <ThemedLoadingButton
              variant="contained"
              sx={{ mt: 1, mb: 2, marginRight: "15px" }}
              onClick={handleSubmit}
              loading={loading}
            >
              Submit
            </ThemedLoadingButton>
          </Grid>
        </Grid>
      </ThemedCard>
      <ThemedCard style={{ padding: "5px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>PD Lot No</TableCell>
              <TableCell>In Or Out</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((val) => {
              return (
                <>
                  <TableRow key={val.id}>
                    <TableCell>{val.actionDate}</TableCell>
                    <TableCell>{val.pdLotNo}</TableCell>
                    <TableCell>{val.inOrOut === -1 ? "OUT" : "IN"}</TableCell>
                    <TableCell>{val.quantity}</TableCell>
                    <TableCell>
                      <ThemedButton
                        onClick={() => {
                          swal({
                            title: "Are you sure ?",
                            text: "You will not be able to recover",
                            icon: "warning",
                            buttons: ["No, cancel it!", "Yes, I am sure!"]
                          }).then((confirm) => {
                            if (confirm) handleDelete(val)
                          })
                        }}
                      >
                        Delete
                      </ThemedButton>
                    </TableCell>
                  </TableRow>
                </>
              )
            })}
          </TableBody>
        </Table>
      </ThemedCard>
    </Root>
  )
}

export default WarehouseEditFG
