import { gql } from "@apollo/client"

export const GET_SPARE_PARTS = gql`
  query SparePartsList {
    sparePartsList {
      code
      partName
      model
      brand
      min
      max
      location
      factory
    }
  }
`

export const GET_SPARE_PARTS_STOCK_CARD = gql`
  query SparePartStockCard($code: String, $partName: String, $factory: String) {
    sparePartStockCard(code: $code, partName: $partName, factory: $factory) {
      code
      partName
      quantity
      actionDate
      operator
      inOrOut
      factory
    }
  }
`

export const GET_PM_RECORD = gql`
  query PmReport($machine: String) {
    pmReport(machine: $machine) {
      id
      machineCode
      actionDate
      startTime
      endTime
      totalTime
      operator
      maintenance
      remarks
    }
  }
`

export const GET_PM_RECORD_APPROVE = gql`
  query PmRecordApproveReport($machine: String, $actionDate: String) {
    pmRecordApproveReport(machine: $machine, actionDate: $actionDate) {
      machineCode
      actionDate
      approveBy
      checkBy
      reportBy
      remark
    }
  }
`

export const GET_PM_PLAN_YEAR = gql`
  query PmYearReport(
    $month: String
    $year: String
    $outsource: Boolean
    $factory: String
  ) {
    pmYearReport(
      month: $month
      year: $year
      outsource: $outsource
      factory: $factory
    ) {
      machine
      code
      line
      machineRemark
      frequency
      actionDate {
        actionDate
        status
        outsource
        percent
      }
    }
  }
`

export const GET_SPARE_PARTS_STOCK_CARD_DATA = gql`
  query SparePartStockCardReport(
    $dateFrom: String
    $dateTo: String
    $factory: String
    $type: String
    $code: String
    $partName: String
  ) {
    sparePartStockCardReport(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
      type: $type
      code: $code
      partName: $partName
    ) {
      code
      partName
      model
      min
      max
      factory
      curStock
      actionDate {
        in
        out
        balance
        actionDate
        remarks
      }
    }
  }
`

export const GET_REPAIR_PROBLEM = gql`
  query GetRepairProblem($problemName: String) {
    getRepairProblem(problemName: $problemName) {
      problemName
      correction
      protection
      id
    }
  }
`

export const GET_REPAIRER = gql`
  query GetRepairerData(
    $department: String
    $recordNo: String
    $actionDate: String
    $factory: String
    $recordList: [Int]
    $dateFrom: String
    $dateTo: String
  ) {
    getRepairerData(
      department: $department
      recordNo: $recordNo
      actionDate: $actionDate
      factory: $factory
      recordList: $recordList
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      id
      checkReceive
      completeDate
      department
      docNo
      repairMan
      repairType
      receiver
      detail
      requestDate
      startRepair
      waitRepair
      endWaitRepair
      referDate
      leaderCheck
      managerCheck
      correctionList
      factory
    }
  }
`

export const GET_REPAIR_AUDIT = gql`
  query GetRepairAuditor(
    $department: String
    $recordNo: String
    $factory: String
    $actionDate: String
    $year: String
  ) {
    getRepairAuditor(
      department: $department
      recordNo: $recordNo
      factory: $factory
      actionDate: $actionDate
      year: $year
    ) {
      recordNo
      status
      remarks
      checker
      actionDate
      department
      factory
    }
  }
`
export const GET_NUMBER_REPAIR = gql`
  query GetNumberOfRepairInvoices(
    $month: String
    $line: String
    $factory: String
  ) {
    getNumberOfRepairInvoices(month: $month, line: $line, factory: $factory) {
      line
      complete
      unComplete
    }
  }
`

export const GET_NUMBER_REPAIR_YEAR = gql`
  query GetNumberOfRepairInvoicesYear(
    $year: String
    $line: String
    $factory: String
  ) {
    getNumberOfRepairInvoicesYear(year: $year, line: $line, factory: $factory) {
      month
      complete
      unComplete
    }
  }
`

export const GET_NUMBER_MACHINE_REPAIR_TIME = gql`
  query GetMachineRepairTimePerYear(
    $year: String
    $line: String
    $factory: String
    $month: String
  ) {
    getMachineRepairTimePerYear(
      year: $year
      line: $line
      factory: $factory
      month: $month
    ) {
      sumTime
      machineCode
    }
  }
`

export const GET_MACHINE_REPAIR_TIMES = gql`
  query GetMachineRepairTimes($year: String, $month: String, $factory: String) {
    getMachineRepairTimes(year: $year, month: $month, factory: $factory) {
      machineCode
      sumTime
    }
  }
`

export const GET_MACHINES_MT = gql`
  query MachinesMt {
    machinesMt {
      machine
      code
      line
      factory
      frequency
      priority
      company
      model
      brand
      installationDate
      details
      machinePower
      remarks
    }
  }
`

export const GET_PM_CHECK_LIST = gql`
  query PmCheckListReport($machine: String) {
    pmCheckListReport(machine: $machine) {
      id
      type
      details
      method
      tool
      specificationReference
      remarks
    }
  }
`
export const GET_PM_LIST_REPORT = gql`
  query PmListReport($machineCode: String, $actionDate: String) {
    pmListReport(machineCode: $machineCode, actionDate: $actionDate) {
      pmCheckListId
      actionDate
      ok
      ng
      modify
      remark
      machineCode
    }
  }
`

export const GET_REPAIR_REQUEST = gql`
  query RepairRequestFrom(
    $dateFrom: String
    $dateTo: String
    $recordNo: String
    $factory: String
    $department: String
    $recordList: [Int]
  ) {
    repairRequestFrom(
      dateFrom: $dateFrom
      dateTo: $dateTo
      recordNo: $recordNo
      factory: $factory
      department: $department
      recordList: $recordList
    ) {
      id
      recordNo
      department
      actionDate
      workType
      jobType
      urgency
      completeDate
      machineName
      code
      detail
      requestBy
      requestDate
      approveBy
      factory
    }
  }
`

export const GET_PM_ALL_CHECK_LIST = gql`
  query PmCheckListAllReport {
    pmCheckListAllReport {
      id
      type
      details
      method
      tool
      specificationReference
      remarks
      status
    }
  }
`
export const GET_PM_MACHINE_REPORT = gql`
  query PmMachineReport(
    $dateFrom: String
    $dateTo: String
    $factory: String
    $machine: String
  ) {
    pmMachineReport(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
      machine: $machine
    ) {
      id
      machineCode
      actionDate
      startTime
      endTime
      totalTime
      operator
      maintenance
      remarks
    }
  }
`
export const GET_PM_MACHINE_KPI = gql`
  query PmMachineKPI($year: String, $factory: String) {
    pmMachineKPI(year: $year, factory: $factory) {
      target
      month
      actual
    }
  }
`

export const GET_MAINTENANCE_KPI = gql`
  query MaintenanceKPI {
    maintenanceKPI {
      typeName
      target
      line
    }
  }
`

export const GET_DATE_PM = gql`
  query Query($machine: String) {
    queryDatePM(machine: $machine)
  }
`
