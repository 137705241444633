import "../../css/Master/Customer.css"
import { styled } from "@mui/material/styles"
import AddIcon from "@mui/icons-material/Add"
import CircularProgress from "@mui/material/CircularProgress"
import Table from "@mui/material/Table"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import TablePagination from "@mui/material/TablePagination"
import { GET_CUSTOMERS } from "../../common/Query/MasterQuery"
import { ADD_CUSTOMER } from "../../common/Mutation/MasterMutation"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  useTheme
} from "@mui/material"
import { useQuery, useMutation } from "@apollo/client"
import React, { Dispatch, ReactElement, SetStateAction, useState } from "react"
import "../../css/Dashboard/Customer.css"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import AutocompleteCustomer from "../../common/Resources/AutocompleteCustomer"
import { ThemeProvider } from "@mui/material"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

export interface ICustomer {
  customer: string
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    //these gets overwritten when using ThemeProvider
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.secondary.contrastText,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.black
  },

  "td, th": {
    border: 0
  }
}))

export const getCustomerListTableCells = (
  customers: ICustomer[],
  page: number,
  rowsPerPage: number
): JSX.Element[] => {
  return customers
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((customer: ICustomer) => {
      return (
        <StyledTableRow key={customer.customer}>
          <StyledTableCell align="left">{customer.customer}</StyledTableCell>
        </StyledTableRow>
      )
    })
}
interface IProps {
  setOpenAddCustomer: Dispatch<SetStateAction<boolean>>
  openAddCustomer: boolean
}

const pageTitle = "Customers"

export const AddCustomer: React.FC<IProps> = (props: IProps) => {
  const [error, setError] = useState<boolean>(false)
  const { openAddCustomer, setOpenAddCustomer } = props
  const [addCustomer, { loading }] = useMutation(ADD_CUSTOMER)
  const [customerName, setCustomerName] = useState("")

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!customerName) {
      setError(true)
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "error")
    } else {
      try {
        await addCustomer({
          variables: { customer: customerName }
        })
        swal("Success", "Add Customer Successful", "success")
        setCustomerName("")
        setOpenAddCustomer(false)
      } catch (error) {
        swal("Error", `${(error as Error).message}`, "error")
      }
    }
  }

  return (
    <>
      <Dialog
        open={openAddCustomer}
        onClose={() => setOpenAddCustomer(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        key="master-customer-dialog"
        className="master-customer-dialog"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Sarabun" }}>
          Add Customer Name
        </DialogTitle>

        <DialogContent>
          <ThemedTextField
            error={!customerName && error}
            fullWidth
            value={customerName}
            id="customer-input-field"
            type="text"
            autoComplete="family-name"
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              borderRadius: "5px",
              marginLeft: "16px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenAddCustomer(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const GetCustomerList: React.FC = (): ReactElement => {
  const theme = useTheme()
  const { loading, data } = useQuery(GET_CUSTOMERS)
  const [page, setPage] = useState<number>(0)
  const [openAddCustomer, setOpenAddCustomer] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  if (loading)
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ padding: "10px" }}
      >
        <CircularProgress />
      </Grid>
    )

  let { customers } = data ? data : { customers: [] }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  if (search) {
    customers = customers.filter(
      ({ customer }: { customer: string }) => customer === search
    )
  }
  const emptyRows: number =
    rowsPerPage - Math.min(rowsPerPage, customers.length - page * rowsPerPage)

  const customerList = getCustomerListTableCells(customers, page, rowsPerPage)

  return (
    <>
      <Grid container spacing={2} padding={2} width={"100%"}>
        <Grid item xs={7} sm={4} xl={4}>
          <AutocompleteCustomer setCustomer={setSearch} customer={search} />
        </Grid>
        <Grid item xs={1} sm={4} xl={4}></Grid>
        <Grid item xs={4} sm={4} xl={4} container justifyContent="flex-end">
          <ThemedButton
            id="add-customer-dialog-button"
            style={{
              whiteSpace: "nowrap",
              top: "8px",
              width: "155px",
              height: "40px",
              marginRight: "20px"
            }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setOpenAddCustomer(true)
            }}
          >
            Add Customer
          </ThemedButton>
          <AddCustomer
            openAddCustomer={openAddCustomer}
            setOpenAddCustomer={setOpenAddCustomer}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{ marginTop: "5px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Customer</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerList}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[3, 5, 10]}
          component="div"
          count={customers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ backgroundColor: theme.palette.warning.light }}
        />
      </TableContainer>
    </>
  )
}

const Customers: React.FC = () => {
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Master}
        menuItemName={Object.keys(menuItemList)[0]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard title="Customer" subTitle="About detail customers" />
        <GetCustomerList />
      </ThemedCard>
    </ThemeProvider>
  )
}

export default Customers
