import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useEffect, useRef, useState } from "react"
import exporting from "highcharts/modules/exporting.js"
import moment from "moment"
import { useTheme } from "@mui/material"
import { IVolumePlanReport } from "../hook/useFetchData"
exporting(Highcharts)
interface IMonthlyOrder {
  month: string
  quantity: number
}
interface IVolumePlan {
  type: string
  order: IMonthlyOrder[]
}
const paddingPartDeliveryDates = (orders: IMonthlyOrder[]) => {
  const newOrders: number[] = []
  const date = moment(new Date()).format("YYYY-MM-DD").split("-")
  const data = orders.find(
    (entry: { month: string }) => entry.month === `${date[0]}-${date[1]}`
  )
  newOrders.push(data ? data.quantity : 0)
  return newOrders
}

interface ISeries {
  name: string
  colorByPoint: boolean
  data: (
    | {
        name: string
        y: number
        sliced: boolean
        selected: boolean
      }
    | {
        name: string
        y: number
        sliced?: undefined
        selected?: undefined
      }
  )[]
}

const ShareMarketVolumePlan = (props: {
  chart?: HighchartsReact.Props
  volumePlan: IVolumePlanReport[] | undefined
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [volumeChart, setVolumeChart] = useState<ISeries[]>([])
  const theme = useTheme()
  const date = moment(new Date()).format("YYYY-MM-DD").split("-")
  const { volumePlan } = props
  useEffect(() => {
    const allMonth: Array<string> = []
    if (volumePlan) {
      const volumePlanReport = volumePlan.map((e: IVolumePlan) => {
        const order = e.order.map((order) => {
          const month = new Date(
            new Date().setMonth(Number(order.month) - 1)
          ).toLocaleDateString("fr-CA", {
            year: "numeric",
            month: "2-digit"
          })
          return {
            month,
            quantity: order.quantity
          }
        })
        return {
          type: e.type,
          order: order.sort((a, b) =>
            a.month > b.month ? 1 : b.month > a.month ? -1 : 0
          )
        }
      })
      volumePlan.forEach((e: IVolumePlan) => {
        e.order.forEach((order) => {
          const month = new Date(
            new Date().setMonth(Number(order.month) - 1)
          ).toLocaleDateString("fr-CA", {
            year: "numeric",
            month: "2-digit"
          })
          allMonth.push(month)
        })
      })
      const dataSeries = volumePlanReport.map((data: IVolumePlan) => {
        const number = paddingPartDeliveryDates(data.order.slice(-3)).reduce(
          (prev, cur) => prev + cur
        )
        return {
          name:
            data.type === "autoPart"
              ? "Automotive"
              : data.type === "electronic"
              ? "Electronic"
              : data.type === "furniture"
              ? "Furniture"
              : data.type,
          y: number,
          dataLabels: {
            enabled: number > 0 ? true : false
          }
        }
      })

      setVolumeChart(dataSeries as never[])
    }
  }, [volumePlan])
  if (!volumePlan?.length) return <>Loading...</>

  const options: Highcharts.Options = {
    colors: ["#03C988", "#BFDB38", "#FFFBAC"],
    chart: {
      type: "pie",
      plotShadow: false,
      plotBorderWidth: 0,
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: `Market shares ${date[0]}-${date[1]}`,
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
    },
    accessibility: {
      point: {
        valueSuffix: "%"
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        showInLegend: true,
        dataLabels: {
          enabled: false,
          distance: -50,
          style: {
            fontWeight: "bold"
          }
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "120%"
      }
    },
    legend: {
      itemStyle: {
        fontSize: "12px",
        color: theme.palette.secondary.contrastText
      }
    },
    series: [
      {
        type: "pie",
        name: "type share",
        innerSize: "50%",
        data: volumeChart
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props.chart}
    />
  )
}
export default ShareMarketVolumePlan
