import { useTheme } from "@mui/material"
import { useRef } from "react"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"

interface IData {
  count: string
  month: string
}

interface IProps {
  getPMHistorySheet: [IData]
}

interface IData1 {
  data: IProps
}

const ChartTopBySheet = (props: {
  chart?: HighchartsReact.Props
  data: IProps
}): JSX.Element => {
  const { data } = props

  const dataMonth = [
    { month: "Jan", monthCount: "1", target: 50 },
    { month: "Feb", monthCount: "2", target: 50 },
    { month: "Mar", monthCount: "3", target: 50 },
    { month: "Apr", monthCount: "4", target: 50 },
    { month: "May", monthCount: "5", target: 50 },
    { month: "Jun", monthCount: "6", target: 50 },
    { month: "Jul", monthCount: "7", target: 50 },
    { month: "Aug", monthCount: "8", target: 50 },
    { month: "Sep", monthCount: "9", target: 50 },
    { month: "Oct", monthCount: "10", target: 50 },
    { month: "Nov", monthCount: "11", target: 50 },
    { month: "Dec", monthCount: "12", target: 50 }
  ]

  const checkDataMonth = dataMonth?.map((item) => {
    const findMonth = data?.getPMHistorySheet.find(
      (e) => e.month === item.monthCount
    )
    return {
      month: item.month,
      monthCount: item.monthCount,
      target: item.target,
      totalSheet: Number(findMonth?.count) || 0
    }
  })

  const keepMonth = checkDataMonth?.map((item) => item.month)
  const keepCount = checkDataMonth?.map((item) => item.totalSheet)
  const keepTarget = checkDataMonth?.map((item) => item.target)

  const theme = useTheme()
  const options: Highcharts.Options = {
    title: {
      text: "",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    credits: { enabled: false },
    chart: {
      type: "column",
      backgroundColor: theme.palette.secondary.main,
      reflow: true
    },
    xAxis: {
      categories: keepMonth,
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "20px",
          fontWeight: "bold"
        }
      }
    },
    yAxis: {
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      min: 0,
      title: {
        text: "ใบแจ้งซ่อม",
        style: {
          fontSize: "20px",
          fontWeight: "bold",
          color: theme.palette.secondary.contrastText
        }
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color: theme.palette.secondary.contrastText,
          textOutline: "none"
        }
      }
    },
    legend: {
      align: "left",
      x: 70,
      verticalAlign: "top",
      y: 30,
      floating: false,
      backgroundColor: theme.palette.secondary.main,
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
      itemStyle: {
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "Quantity : {point.y}"
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true
        }
      }
    },
    series: [
      {
        name: "totalSheet",
        data: keepCount,
        type: "column",
        color: "#4595F7"
      },
      {
        name: "Target",
        data: keepTarget,
        type: "line",
        color: "#FF983C"
      }
    ],
    exporting: {
      enabled: false
    }
  }
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props.chart}
    />
  )
}

const GraphSheet: React.FC<IData1> = (props: IData1) => {
  const { data } = props
  return (
    <>
      <ChartTopBySheet data={data} />
    </>
  )
}

export default GraphSheet
