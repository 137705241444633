import { useQuery } from "@apollo/client"
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  useTheme,
  ThemeProvider
} from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"
import { GET_REPAIR_PROBLEM } from "../../../common/Query/MaintenanceQuery"
import { LocalizationProvider, TimePicker } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import { miniLogo, subtractDate } from "../../../common/utilities"
import PrintIcon from "@mui/icons-material/Print"
import { IRepairAuditData } from "./RepairAuditor"
import "../../../css/Maintenance/repair.css"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import FormTextField from "../../../common/Resources/ThemedComponents/Maintenance/FormTextField"
import ThemedRadio from "../../../common/Resources/ThemedComponents/ThemedRadio"
import { GET_DEPARTMENT } from "../../../common/Query/MasterQuery"
import { IDepartment } from "../RepairDocument"

const StyledTableRow = styled(TableRow)(() => ({
  "td, th": {
    backgroundColor: "white",
    color: "black",
    padding: "5px"
  }
}))

interface IRequestData {
  actionDate: string
  recordNo: number
  department: string
  machineName: string
  code: string
  workType: string
  jobType: string
  urgency: string
  detail: string
  requestDate: string
  request: string
  completeDate: string
  requestBy: string
  approveBy: string
  factory: string
}
interface IRepairer {
  id: number
  checkReceive: string
  completeDate: string
  department: string
  docNo: string
  repairMan: string
  repairType: string
  receiver: string
  detail: string
  requestDate: string
  startRepair: string
  waitRepair: string
  endWaitRepair: string
  waitRepairTime: string
  referDate: string
  leaderCheck: string
  managerCheck: string
  correctionList: string
  factory: string
}
interface IProp {
  repairFrom: IRequestData
  repairer: IRepairer
  repairAuditData?: IRepairAuditData
}
const StyledTableCell = styled(TableCell)(() => ({
  blackGroundColor: "white",
  color: "black",
  textAlign: "center",
  padding: 0,
  fontSize: "16px",
  border: "solid 1px black"
}))
interface IRepairProblem {
  problemName?: string
  correction: string
  protection: string
}
const StyledTextField = styled(TextField)(() => ({
  "& input": {
    color: "#666666"
  },
  'input[type="date"]::-webkit-calendar-picker-indicator ': {
    display: "none",

    "-webkit-appearance": "none"
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#666666"
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#666666"
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "#666666"
  }
}))
const ShowDocumentRepair: React.FC<IProp> = (props: IProp) => {
  const { repairFrom, repairer, repairAuditData } = props
  const correctionList = JSON.parse(repairer.correctionList)
  const [startTime, setStartTime] = useState<number>(36000000)
  const [startRepair, setStartRepair] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [waitRepair, setWaitRepair] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [completeDate, setCompleteDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [completeTime, setCompleteTime] = useState<number>(36000000)

  const [waitRepairTime, setWaitRepairTime] = useState<number>(36000000)
  const [endWaitRepairTime, setEndWaitRepairTime] = useState<number>(36000000)
  const [endWaitRepair, setEndWaitRepair] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const getDepartment = useQuery(GET_DEPARTMENT)
  const departmentList: IDepartment[] = getDepartment?.data?.department || []
  const { data: getRepairProblem } = useQuery(GET_REPAIR_PROBLEM, {
    variables: {
      problemName: repairFrom?.detail || ""
    },
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    if (repairFrom) {
      setStartRepair(repairer.startRepair.split(" ")[0])
      setStartTime(new Date(repairer.startRepair).getTime())
      setCompleteDate(repairer.completeDate.split(" ")[0])
      setCompleteTime(new Date(repairer.completeDate).getTime())
      setWaitRepair(repairer.waitRepair.split(" ")[0])
      setWaitRepairTime(new Date(repairer.waitRepair).getTime())
      setEndWaitRepairTime(new Date(repairer.endWaitRepair).getTime())
      setEndWaitRepair(repairer.endWaitRepair.split(" ")[0])
    }
  }, [getRepairProblem, repairer])
  const waitTime = subtractDate(
    new Date(endWaitRepair + " " + moment(endWaitRepairTime).format("HH:mm")),
    new Date(waitRepair + " " + moment(waitRepairTime).format("HH:mm"))
  )
  const repairTime = subtractDate(
    new Date(completeDate + " " + moment(completeTime).format("HH:mm")),
    new Date(startRepair + " " + moment(startTime).format("HH:mm"))
  )

  const actualWorkingTime = subtractDate(repairTime.date, waitTime.date)
  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <div className="hidden-print">
        <ThemedButton
          variant="outlined"
          aria-label="print"
          endIcon={<PrintIcon />}
          style={{
            marginBottom: "5px"
          }}
          onClick={() => window.print()}
        >
          Print
        </ThemedButton>
      </div>
      <div className="repair-print-head">
        <Table sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell rowSpan={2}>
                <img
                  src={miniLogo}
                  alt="Brother Auto Parts."
                  style={{
                    width: "150px",
                    height: "100px"
                  }}
                />
              </StyledTableCell>
              <StyledTableCell rowSpan={2}>
                <h1>ใบเเจ้งซ่อม</h1>
              </StyledTableCell>
              <StyledTableCell>
                <p>Doc No.F-MT-01-04</p>
                <p>Effective Date: 04/1/22 R03 Issue: 1</p>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                <p>
                  Rec.No.
                  {moment(repairFrom.actionDate).format("YYYY-MM") +
                    "-" +
                    repairFrom?.recordNo}
                  -1000
                </p>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell>สำหรับผู้เเจ้งซ่อม</StyledTableCell>
              <StyledTableCell style={{ textAlign: "left" }}>
                แผนก :
                {
                  departmentList.find(
                    (val) => val.departmentName === repairFrom.department
                  )?.departmentNameThai
                }
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                วันที่เเจ้ง :{repairFrom.actionDate}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell colSpan={3} style={{ textAlign: "left" }}>
                <p style={{ display: "inline-block", marginRight: "5px" }}>
                  ลักษณะงาน
                </p>
                <FormControl>
                  <RadioGroup row defaultValue={repairFrom.workType}>
                    <FormControlLabel
                      value="ซ่อม"
                      control={<ThemedRadio />}
                      label="ซ่อม"
                    />
                    <FormControlLabel
                      value="สร้าง/จัดทำ"
                      control={<ThemedRadio />}
                      label="สร้าง/จัดทำ"
                    />
                    <FormControlLabel
                      value="ดัดแปลง/ปรับปรุง"
                      control={<ThemedRadio />}
                      label="ดัดแปลง/ปรับปรุง"
                    />
                  </RadioGroup>
                </FormControl>
                <FormTextField
                  type="text"
                  value={
                    repairFrom.workType === "ดัดแปลง/ปรับปรุง" ||
                    repairFrom.workType === "สร้าง/จัดทำ" ||
                    repairFrom.workType === "ซ่อม"
                      ? ""
                      : repairFrom.workType
                  }
                  label="อื่นๆ..."
                  variant="standard"
                  sx={{
                    mt: -1,
                    width: "25%"
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell colSpan={3} style={{ textAlign: "left" }}>
                <p style={{ display: "inline-block", marginRight: "5px" }}>
                  ประเภทงานงาน
                </p>
                <FormControl>
                  <RadioGroup row defaultValue={repairFrom.jobType}>
                    <FormControlLabel
                      value="เครื่องมือ/เครื่องจักร"
                      control={<ThemedRadio />}
                      label="เครื่องมือ/เครื่องจักร"
                    />
                    <FormControlLabel
                      value="สารธารณูปโภค"
                      control={<ThemedRadio />}
                      label="สารธารณูปโภค"
                    />
                  </RadioGroup>
                </FormControl>
                <FormTextField
                  type="text"
                  value={
                    repairFrom.jobType === "เครื่องมือ/เครื่องจักร" ||
                    repairFrom.jobType === "สารธารณูปโภค"
                      ? ""
                      : repairFrom.jobType
                  }
                  label="อื่นๆ..."
                  variant="standard"
                  sx={{ mt: -1, width: "25%" }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell colSpan={3} style={{ textAlign: "left" }}>
                <p style={{ display: "inline-block", marginRight: "5px" }}>
                  ความจำเป็น
                </p>
                <FormControl>
                  <RadioGroup row defaultValue={repairFrom.urgency}>
                    <FormControlLabel
                      value="ปกติ"
                      control={<ThemedRadio />}
                      label="ปกติ"
                    />
                    <FormControlLabel
                      value="ด่วน/ภายในวันที่"
                      control={<ThemedRadio />}
                      label="ด่วน/ภายในวันที่"
                    />
                  </RadioGroup>
                </FormControl>
                <StyledTextField
                  type="date"
                  value={repairFrom.completeDate}
                  variant="standard"
                  sx={{ width: "20%" }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                style={{
                  textAlign: "left",
                  borderBottom: "solid 1px white"
                }}
                colSpan={3}
              >
                ชื่อเครื่องจักร :{repairFrom.machineName} รหัสเครื่องจักร :
                {repairFrom.code}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                style={{ textAlign: "left", borderBottom: "solid 1px white" }}
                colSpan={3}
              >
                :&nbsp;&nbsp;
                {repairFrom.detail}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                colSpan={1}
                style={{
                  borderRight: "solid 1px white"
                }}
              >
                ผู้เเจ้ง: {repairFrom.requestBy}
                <br />
                หัวหน้าแผนก
              </StyledTableCell>
              <StyledTableCell
                style={{
                  textAlign: "left"
                }}
                colSpan={2}
              >
                ผู้อนุมัติ: {repairFrom.approveBy}
                <br />
                ผู้จัดการฝ่าย/ผู้จัดการโรงงาน
                <h6 style={{ display: "contents" }}>
                  (เฉพาะงานสร้าง/ปรับปรุง)
                </h6>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>สำหรับผู้ซ่อม</StyledTableCell>
              <StyledTableCell colSpan={2}>
                ผลจากการตรวจสอบอาการเครื่องจักรเบื้องต้น
                แผนกซ่อมบำรุงพิจารณาเเล้วเห็นว่าสมควร
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell colSpan={3}>
                <FormControl>
                  <RadioGroup row value={repairer.repairType}>
                    <FormControlLabel
                      value="ซ่อมเองภายใน"
                      control={<ThemedRadio />}
                      label="ซ่อมเองภายใน"
                    />
                    <FormControlLabel
                      value="จัดหาผู้ว่าจ้างภายนอก"
                      control={<ThemedRadio />}
                      label="จัดหาผู้ว่าจ้างภายนอก"
                    />
                  </RadioGroup>
                </FormControl>
                <StyledTextField
                  type="date"
                  variant="standard"
                  value={repairer.referDate}
                  sx={{ marginLeft: "20px" }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell colSpan={3}>
                <p style={{ display: "inline-flex" }}>
                  ผู้รับเรื่อง {repairer.receiver}
                </p>
                <p style={{ display: "inline-flex", marginLeft: "10px" }}>
                  หัวหน้าแผนกซ่อมบำรุง
                </p>
                <p style={{ display: "inline-flex", marginLeft: "10px" }}>
                  ผู้ตรวจสอบ {repairer.checkReceive}
                </p>
                <p style={{ display: "inline-flex", marginLeft: "10px" }}>
                  ผู้จัดการฝ่ายซ่อมบำรุง
                </p>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell colSpan={3}>รายงานผลการซ่อม</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell colSpan={1}>ลำดับ</StyledTableCell>
              <StyledTableCell colSpan={1}>รายละเอียดการเเก้ไข</StyledTableCell>
              <StyledTableCell colSpan={1}>เเนวทางป้องกัน</StyledTableCell>
            </StyledTableRow>
            {correctionList.map((e: IRepairProblem, i: number) => {
              return (
                <StyledTableRow key={i}>
                  <StyledTableCell colSpan={1}>{i + 1}</StyledTableCell>
                  <StyledTableCell colSpan={1}>{e.correction}</StyledTableCell>
                  <StyledTableCell colSpan={1}>{e.protection}</StyledTableCell>
                </StyledTableRow>
              )
            })}
            <StyledTableRow>
              <StyledTableCell
                colSpan={3}
                style={{ textAlign: "left", fontSize: "16px" }}
              >
                <p>
                  แจ้งซ่อมเมื่อ
                  {moment(repairFrom?.requestDate || "0000-00-00 00:00").format(
                    "YYYY-MM-DD"
                  )}
                  เวลา
                  {moment(repairFrom?.requestDate || "0000-00-00 00:00").format(
                    "HH:mm"
                  )}
                  เริ่มซ่อมเมื่อ
                  <StyledTextField
                    type="date"
                    variant="standard"
                    sx={{
                      width: "12%",
                      mt: -1,
                      input: { fontSize: "16px", textAlign: "center" }
                    }}
                    value={startRepair}
                  />
                  เวลา
                  <StyledTextField
                    type="string"
                    variant="standard"
                    sx={{
                      width: "12%",
                      mt: -1,
                      input: { fontSize: "16px", textAlign: "center" }
                    }}
                    value={moment(
                      repairer.startRepair || "0000-00-00 00:00"
                    ).format("HH:mm")}
                  />
                  ซ่อมเสร็จเมื่อ
                  <StyledTextField
                    type="date"
                    variant="standard"
                    value={completeDate}
                    sx={{
                      width: "12%",
                      mt: -1,
                      input: {
                        fontSize: "16px",
                        textAlign: "center"
                      }
                    }}
                    onChange={(e) => setCompleteDate(e.target.value)}
                  />
                  เวลา
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <TimePicker
                      disableOpenPicker
                      ampm={false}
                      views={["hours", "minutes"]}
                      inputFormat="HH:mm"
                      value={completeTime}
                      onChange={(newSelectTime) => {
                        setCompleteTime(newSelectTime ? newSelectTime : 0)
                      }}
                      renderInput={(params) => (
                        <FormTextField
                          {...params}
                          sx={{
                            width: "7%",
                            mt: -1,
                            input: { fontSize: "16px", textAlign: "center" }
                          }}
                          id="start-time-ot"
                          variant="standard"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </p>
                <br />
                เวลารอคอย
                <StyledTextField
                  type="date"
                  variant="standard"
                  value={waitRepair}
                  sx={{
                    width: "12%",
                    mt: -1,
                    input: { fontSize: "16px", textAlign: "center" }
                  }}
                />
                เวลา
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    disableOpenPicker
                    ampm={false}
                    views={["hours", "minutes"]}
                    inputFormat="HH:mm"
                    value={waitRepairTime}
                    onChange={(newSelectTime) => {
                      setWaitRepairTime(newSelectTime ? newSelectTime : 0)
                    }}
                    renderInput={(params) => (
                      <FormTextField
                        {...params}
                        sx={{
                          width: "7%",
                          mt: -1,
                          input: { fontSize: "16px", textAlign: "center" }
                        }}
                        id="start-time-ot"
                        variant="standard"
                      />
                    )}
                  />
                </LocalizationProvider>
                ถึง
                <StyledTextField
                  type="date"
                  variant="standard"
                  value={endWaitRepair}
                  sx={{
                    width: "12%",
                    mt: -1,
                    input: { fontSize: "16px", textAlign: "center" }
                  }}
                />
                เวลา
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    disableOpenPicker
                    ampm={false}
                    views={["hours", "minutes"]}
                    inputFormat="HH:mm"
                    value={endWaitRepairTime}
                    onChange={(newSelectTime) => {
                      setEndWaitRepairTime(newSelectTime ? newSelectTime : 0)
                    }}
                    renderInput={(params) => (
                      <FormTextField
                        {...params}
                        sx={{
                          width: "7%",
                          mt: -1,
                          input: { fontSize: "16px", textAlign: "center" }
                        }}
                        id="start-time-ot"
                        variant="standard"
                      />
                    )}
                  />
                </LocalizationProvider>
                รวมเวลารอคอย:
                {waitTime.month +
                  " เดือน " +
                  waitTime.day +
                  " วัน " +
                  waitTime.hour +
                  " ชม " +
                  waitTime.minute +
                  " นาที "}
                <p style={{ display: "inline-block" }}>
                  เวลาปฎิบัติงานจริง :
                  {actualWorkingTime.month +
                    " เดือน " +
                    actualWorkingTime.day +
                    " วัน " +
                    actualWorkingTime.hour +
                    " ชม " +
                    actualWorkingTime.minute +
                    " นาที "}
                </p>
                <p style={{ display: "inline-block", marginLeft: "30px" }}>
                  รวมเวลาซ่อม :
                  {repairTime.month +
                    " เดือน " +
                    repairTime.day +
                    " วัน " +
                    repairTime.hour +
                    " ชม " +
                    repairTime.minute +
                    " นาที "}
                </p>
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell
                colSpan={1}
                style={{
                  // borderTop: "solid 2px white",
                  textAlign: "right",
                  fontSize: "14px"
                }}
              >
                <p style={{ display: "inline-flex", marginRight: "10px" }}>
                  ลงชื่อ : {repairer.repairMan}
                </p>
                <p style={{ display: "inline-flex" }}>ผู้ซ่อม</p>
              </StyledTableCell>
              <StyledTableCell
                colSpan={2}
                style={{
                  textAlign: "right",
                  fontSize: "14px"
                }}
              >
                <p style={{ marginRight: "100px" }}>
                  ผู้ตรวจสอบ : {repairer.leaderCheck}&nbsp;&nbsp; ผู้ตรวจสอบ :
                  {repairer.managerCheck}
                </p>
                <p style={{ marginRight: "100px" }}>
                  หัวหน้าแผนกซ่อมบำรุง &nbsp;&nbsp;ผู้จัดการฝ่ายซ่อมบำรุง
                </p>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>สำหรับผู้ตรวจรับ</StyledTableCell>
              <StyledTableCell colSpan={2}>
                ผู้ตรวจรับได้นำเนินการตรวจสอบการซ่อมตามรายละเอียดการเเจ้งซ่อมที่กำหนดข้างต้นเเล้ว
                ปรากฎว่า
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell colSpan={3}>
                <FormControl>
                  <RadioGroup row value={repairAuditData?.status}>
                    <FormControlLabel
                      value="เรียบร้อยเเละสามารถใช้งานได้ตามปกติ"
                      control={<ThemedRadio />}
                      label="เรียบร้อยเเละสามารถใช้งานได้ตามปกติ"
                    />
                    <FormControlLabel
                      value="ไม่เรียบร้อย"
                      control={<ThemedRadio />}
                      label="ไม่เรียบร้อย"
                    />
                    <FormTextField
                      type="text"
                      variant="standard"
                      value={repairAuditData?.remarks}
                      label="สาเหตุเพราะ"
                      sx={{ mt: -2 }}
                    />
                  </RadioGroup>
                </FormControl>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                colSpan={3}
                style={{ textAlign: "right", borderTop: "solid 2px white" }}
              >
                <p style={{ display: "inline-flex" }}>ผู้ตรวจรับ :</p>

                <FormTextField
                  value={repairAuditData?.checker}
                  variant="standard"
                  sx={{ input: { textAlign: "center" } }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <br />
                <p style={{ paddingRight: "30px" }}>หัวหน้า/ผู้จัดการฝ่าย</p>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </div>
    </ThemeProvider>
  )
}
export default ShowDocumentRepair
