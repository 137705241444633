import { gql } from "@apollo/client"

export const GET_MAT_OUT_REPORT = gql`
  query MatOutScanReport($dateFrom: String, $dateTo: String, $factory: String) {
    matOutScanReport(dateFrom: $dateFrom, dateTo: $dateTo, factory: $factory) {
      actionDate
      partNo
      invoiceNo
      sizeL
      sizeW
      sizeH
      matPlan
      actualPlan
      jobOrder
      factory
      packingBy
      remarks
    }
  }
`
