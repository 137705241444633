import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { useEffect, useMemo, useState } from "react"
import { GET_MASTER_PM_SPARE_PART } from "../../common/Query/engineerQuery"
import { useMutation, useQuery } from "@apollo/client"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import AddIcon from "@mui/icons-material/Add"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { CREATE_PM_SPARE_PART_STOCK } from "../../common/Mutation/engineerMutation"
import swal from "sweetalert"

interface Column {
  id: "partName" | "code" | "partNo" | "min" | "max" | "reOrder" | "cost"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}
interface IMasterSparePart {
  partName: string
  code: string
  partNo: string
  min: number
  max: number
  reOrder: number
  cost: number
}
const columns: Column[] = [
  { id: "partName", label: "Part Name", minWidth: 20 },
  { id: "code", label: "Code", minWidth: 20 },
  { id: "partNo", label: "Part No.", minWidth: 20 },
  { id: "min", label: "Min", minWidth: 20 },
  { id: "max", label: "Max", minWidth: 20 },
  { id: "reOrder", label: "Re-Order", minWidth: 20 },
  { id: "cost", label: "Cost", minWidth: 20 }
]
const pageTitle = "Master Spare Part"

const MasterPMSparePart: React.FC = () => {
  const theme = useTheme()
  const [partName, setPartName] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [open, setOpen] = useState<boolean>(false)

  const { data: masterPMSparePart, loading } = useQuery(
    GET_MASTER_PM_SPARE_PART
  )
  const partNameList = Array.from(
    new Set(
      masterPMSparePart?.masterPMSparePart
        .map((data: { partName: string }) => data.partName)
        .filter((val: string) => val)
    )
  )
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const rows: IMasterSparePart[] = useMemo(() => {
    if (!masterPMSparePart?.masterPMSparePart) return []
    return masterPMSparePart?.masterPMSparePart
      .filter((val: { partName: string; code: string }) => {
        if (partName && code) {
          return val.partName === partName && val.code === code
        } else if (partName) {
          return val.partName === partName
        }
        return true
      })
      .map((val: IMasterSparePart) => {
        return {
          partName: val.partName,
          code: val.code,
          partNo: val.partNo,
          min: val.min,
          max: val.max,
          reOrder: val.reOrder,
          cost: val.cost
        }
      })
  }, [masterPMSparePart?.masterPMSparePart, partName, code])
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Engineer}
        menuItemName={Object.keys(menuItemList)[5]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="Master Spare Part"
          subTitle="detail about master spare part"
        />
        <Grid container spacing={2} margin={2} width={"98%"}>
          <Grid item xs={7} sm={4} xl={4}>
            <Autocomplete
              value={partName}
              fullWidth
              onChange={(event, selectedCustomer) => {
                setPartName(selectedCustomer as string)
                setCode("")
              }}
              options={partNameList}
              loading={loading}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Part Name"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={7} sm={4} xl={4}>
            <Autocomplete
              value={code}
              fullWidth
              onChange={(event, selectedCustomer) => {
                setCode(selectedCustomer as string)
                setPage(0)
              }}
              options={
                masterPMSparePart?.masterPMSparePart
                  .filter(
                    (val: { partName: string }) => val.partName === partName
                  )
                  .map((item: { code: string }) => item.code) || []
              }
              loading={loading}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="code"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} xl={4} container justifyContent="flex-end">
            <ThemedButton
              id="add-customer-dialog-button"
              style={{
                whiteSpace: "nowrap",
                top: "8px",
                width: "155px",
                height: "40px",
                marginRight: "20px"
              }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpen(true)
              }}
            >
              Add Spare Part
            </ThemedButton>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ThemedCard>
      <AddSparePart
        open={open}
        setOpen={setOpen}
        masterPMSparePart={masterPMSparePart?.masterPMSparePart}
      />
    </ThemeProvider>
  )
}
interface IProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  masterPMSparePart?: IMasterSparePart[]
}
const AddSparePart: React.FC<IProps> = (props: IProps) => {
  const { open, setOpen, masterPMSparePart = [] } = props
  const [addSparePart] = useMutation(CREATE_PM_SPARE_PART_STOCK)
  const [partName, setPartName] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [min, setMin] = useState<number>(0)
  const [max, setMax] = useState<number>(0)
  const [reOrder, setReOrder] = useState<number>(0)
  const [cost, setCost] = useState<number>(0)

  const findData = useMemo(() => {
    if (!masterPMSparePart) return {}
    return masterPMSparePart.find((val: IMasterSparePart) => {
      return val.partName === partName && val.code === code
    })
  }, [masterPMSparePart, partName, code]) as IMasterSparePart
  const partNameList = Array.from(
    new Set(
      masterPMSparePart
        ? masterPMSparePart.map((data: { partName: string }) => data.partName)
        : []
    )
  )

  const codeList =
    masterPMSparePart
      ?.filter(
        (val: { partName: string; code: string }) =>
          val.code && val.partName === partName
      )
      .map((item: { code: string }) => item.code) ?? []

  useEffect(() => {
    if (findData) {
      setPartNo(findData.partNo)
      setMin(findData.min)
      setMax(findData.max)
      setReOrder(findData.reOrder)
      setCost(findData.cost)
    }
  }, [findData])

  const isComplete = () => {
    return partName !== "" && code !== "" && partNo !== ""
  }
  const handleSubmit = () => {
    if (!isComplete()) return swal("ERROR", "กรอกข้อมูลไม่ครบ", "warning")
    try {
      const data = {
        partName,
        code,
        partNo,
        min,
        max,
        reOrder,
        cost
      }
      addSparePart({
        variables: data
      })

      setOpen(false)
      swal("Success", "Add Spare Part Successful", "success")
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      key="master-customer-dialog"
      className="master-customer-dialog"
    >
      <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Sarabun" }}>
        Add Spare Part
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12} mt={2}>
            <Autocomplete
              value={partName}
              fullWidth
              onChange={(event, selectedCustomer) => {
                setPartName(selectedCustomer as string)
                setCode("")
              }}
              freeSolo
              autoSelect
              options={partNameList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Part Name"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Autocomplete
              freeSolo
              autoSelect
              value={code}
              fullWidth
              onChange={(event, selectedCustomer) => {
                setCode(selectedCustomer as string)
              }}
              options={codeList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="code"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <ThemedTextField
              fullWidth
              value={partNo}
              type="text"
              label="Part No"
              onChange={(e) => setPartNo(e.target.value)}
            />
          </Grid>
          <Grid item sm={12}>
            <ThemedTextField
              fullWidth
              value={min ? min : ""}
              type="number"
              label="Min"
              inputProps={{ step: "0.1", lang: "en-US" }}
              onChange={(e) => setMin(Number(e.target.value))}
            />
          </Grid>
          <Grid item sm={12}>
            <ThemedTextField
              fullWidth
              value={max ? max : ""}
              type="number"
              label="Max"
              inputProps={{ step: "0.1", lang: "en-US" }}
              onChange={(e) => setMax(Number(e.target.value))}
            />
          </Grid>
          <Grid item sm={12}>
            <ThemedTextField
              fullWidth
              value={reOrder ? reOrder : ""}
              type="number"
              label="Re-Order"
              inputProps={{ step: "0.1", lang: "en-US" }}
              onChange={(e) => setReOrder(Number(e.target.value))}
            />
          </Grid>
          <Grid item sm={12}>
            <ThemedTextField
              fullWidth
              value={cost ? cost : ""}
              type="number"
              label="Cost"
              inputProps={{ step: "0.1", lang: "en-US" }}
              onChange={(e) => setCost(Number(e.target.value))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ alignSelf: "start" }}>
        <ThemedLoadingButton
          onClick={handleSubmit}
          variant="contained"
          style={{
            borderRadius: "5px",
            marginLeft: "16px"
          }}
        >
          SUBMIT
        </ThemedLoadingButton>
        <ThemedButton contrast variant="text" onClick={() => setOpen(false)}>
          CANCEL
        </ThemedButton>
      </DialogActions>
    </Dialog>
  )
}
export default MasterPMSparePart
