import { gql } from "@apollo/client"

export const CREATE_USER = gql`
  mutation Mutation(
    $username: String!
    $password: String!
    $role: String!
    $employeeId: Int!
  ) {
    createUser(
      username: $username
      password: $password
      role: $role
      employeeId: $employeeId
    ) {
      username
      password
      role
      accessToken
      errorMessage
      employeeId
    }
  }
`
