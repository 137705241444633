import { Grid, ThemeProvider, useTheme } from "@mui/material"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { useCallback, useState } from "react"

import { useMutation } from "@apollo/client"
import { CREATE_UPLOAD_FILE_INVOICE } from "../../common/Mutation/AccountMutation"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import UploadFiles from "../MasterPlan/mpcomponents/UploadFiles"

const AccountProduction = (): JSX.Element => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [uploadFile, { loading }] = useMutation(CREATE_UPLOAD_FILE_INVOICE)

  const theme = useTheme()

  const handleDownload = useCallback(async () => {
    try {
      if (!selectedFile) {
        return swal("Warning", "ไม่มีข้อมูลอัพโหลด", "warning")
      }
      await uploadFile({
        variables: {
          file: selectedFile
        }
      })
      swal("Success", "อัพโหลดข้อมูลสำเร็จ", "success")
    } catch (err) {
      return swal("Error", `${(err as Error).message}`, "error")
    }
  }, [selectedFile])

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Account}
        menuItemName={Object.keys(menuItemList)[13]}
        menuPageName={"Invoice"}
      />
      <ThemedCard>
        <PageLabelCard title="Invoice" subTitle="About detail Invoice" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <UploadFiles
              loading={loading}
              functionUpload={handleDownload}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default AccountProduction
