import { gql } from "@apollo/client"

export const PM_RESET = gql`
  mutation PmReset($partNo: String!, $process: String!, $date: String!) {
    pmReset(partNo: $partNo, process: $process, date: $date) {
      data {
        partNo
        process
        date
      }
      status
    }
  }
`

export const CREATE_PM_FROM = gql`
  mutation CreatePMFrom($inputCreatePmFrom: InputPMFrom) {
    createPMFrom(inputCreatePmFrom: $inputCreatePmFrom) {
      id
      message
      department
      actionDate
      link
      time
      userRead
    }
  }
`

export const DELETE_PM_FROM = gql`
  mutation DeletePMFrom(
    $factory: String
    $documentNo: Int
    $repairRequestDate: String
  ) {
    deletePMFrom(
      factory: $factory
      documentNo: $documentNo
      repairRequestDate: $repairRequestDate
    )
  }
`

export const UPDATE_PM_FROM = gql`
  mutation UpdatePMFrom($inputCreatePmFrom: InputPMFrom) {
    updatePMFrom(inputCreatePmFrom: $inputCreatePmFrom) {
      id
      message
      department
      actionDate
      link
      time
      userRead
    }
  }
`

export const CREATE_PM_SPARE_PART = gql`
  mutation CreatePMSparePart($inputSparePart: InputPMSparePart) {
    createPMSparePart(inputSparePart: $inputSparePart)
  }
`

export const DELETE_PM_SPARE_PART_STOCK = gql`
  mutation DeleteStockCard(
    $factory: String
    $documentNo: Int
    $repairRequestDate: String
    $repairDate: String
  ) {
    deleteStockCard(
      factory: $factory
      documentNo: $documentNo
      repairRequestDate: $repairRequestDate
      repairDate: $repairDate
    )
  }
`

export const CREATE_PM_SPARE_PART_STOCK = gql`
  mutation CreateNewCustomerSparePart(
    $partName: String
    $code: String
    $partNo: String
    $min: Int
    $reOrder: Int
    $max: Int
    $cost: Int
  ) {
    createNewCustomerSparePart(
      partName: $partName
      code: $code
      partNo: $partNo
      min: $min
      reOrder: $reOrder
      max: $max
      cost: $cost
    )
  }
`
export const TRIAL_TOOLING_SHEET_CREATE = gql`
mutation TrialToolingSheetCreate($inputTrialToolingCreate: inputTrialToolingCreate) {
  trialToolingSheetCreate(inputTrialToolingCreate: $inputTrialToolingCreate)
}
`

export const TRIAL_TOOLING_SHEET_UPDATE_APPROVAL = gql`
mutation TrialToolingSheetUpdateApproval($inputTrialToolingApproval: InputTrialToolingApproval) {
  trialToolingSheetUpdateApproval(inputTrialToolingApproval: $inputTrialToolingApproval)
}
`
