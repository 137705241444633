import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import {
  Grid,
  Autocomplete,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  useTheme,
  ThemeProvider
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import PrintIcon from "@mui/icons-material/Print"
import { styled } from "@mui/material/styles"
import CircularProgress from "@mui/material/CircularProgress"
import moment from "moment"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import {
  gql,
  useQuery,
  LazyQueryResult,
  QueryLazyOptions,
  useLazyQuery
} from "@apollo/client"
import "../../css/Logistics/LogisticsCSS.css"
import { GET_QC_DEMOLISH } from "../../common/Query/QCQuery"
import "../../css/QC/QC.css"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import { miniLogo } from "../../common/utilities"
import swal from "sweetalert"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

interface PartNoList {
  parts: PartNo[]
}

interface PartNo {
  partNo: string
}

interface QCDemolishQueryArguments {
  dateFrom: string
  dateTo: string
  factory: string
  partNo: string
  status: string
}

interface ISearchQCDemolishProps {
  startDate: Date
  endDate: Date
  factory: string
  partNo: string
  type: string
  ngType: string
  partNoList: string[]
  printMode: boolean
  status: string
  setStartDate: Dispatch<SetStateAction<Date>>
  setEndDate: Dispatch<SetStateAction<Date>>
  setFactory: Dispatch<SetStateAction<string>>
  setPartNo: Dispatch<SetStateAction<string>>
  setType: Dispatch<SetStateAction<string>>
  setNgType: Dispatch<SetStateAction<string>>
  setPrintMode: Dispatch<SetStateAction<boolean>>
  setStatus: Dispatch<SetStateAction<string>>
  getQcDemolish: (
    options?: QueryLazyOptions<QCDemolishQueryArguments>
  ) => Promise<LazyQueryResult<QCDemolishDataList, QCDemolishQueryArguments>>
}

interface NgTypeInfo {
  name: string
  dataKey: string
  ngTypeInfoHeader: string[]
  ngTypeInfoKey: string[]
}

interface NgType {
  [key: string]: NgTypeInfo
}

interface IQCDemolishBodyProps {
  qcDemolishData: QCDemolishDataList | undefined
  qcDemolishLoading: boolean
  qcDemolishCalled: boolean
  ngType: string
  status: string
}

interface QCDemolishDataList {
  qcDemolishReport: QCDemolishData[]
}

interface QCDemolishData {
  date: Date
  pdLotNo: string
  jobOrder: string
  partNo: string
  partName: string
  customer: string
  stamp: number
  weld: number
  arcweld: number
  plate: number
  quantity: number
  okQuantity: number
  scratched: number
  deformed: number
  torn: number
  dirtyStains: number
  rusted: number
  fin: number
  incorrectStampSize: number
  skipProcessStamp: number
  otherStamp: number
  offset: number
  incorrectWeldPosition: number
  incorrectWeldSize: number
  looseWelding: number
  noWelding: number
  skipProcessWeld: number
  otherWeld: number
  loosePlating: number
  discoloredPlating: number
  otherPlate: number
  discontinuousWeld: number
  oneSidedWeld: number
  twiceWelded: number
  puncturedWeld: number
  messyWeld: number
  incompleteWeld: number
  forgetToTurnOffCO2: number
  deformedArcweld: number
  otherArcweld: number
  totalStamp: number
  totalWeld: number
  totalPlate: number
  totalArcWeld: number
  operator: string
  remarks: string
  factory: string
  process: string
  sNG: number
  tNG: number
  supplierNG: number
  iNG: number
}

const StyledTableCell = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  textAlign: "center",
  borderBottom: "1px",
  border: "solid 1px black"
}))

const StyledTableCellBold = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  textAlign: "center",
  borderBottom: "1px",
  border: "solid 1px black",
  fontWeight: "bold"
}))

const StyledTableCellVertical = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  textAlign: "center",
  borderBottom: "1px",
  // writingMode: "vertical-rl",
  height: "130px",
  border: "solid 1px black",
  // transform: "rotate(180deg)",
  verticalAlign: "bottom",
  fontWeight: "bold"
}))

export const StyledTableRow = styled(TableRow)(() => ({
  borderBottom: "1px",
  "&:last-child td": { borderBottom: "1px solid black" },
  border: "solid 1px black"
}))

export const GET_PARTNO = gql`
  query Parts {
    parts {
      partNo
    }
  }
`

const pageTitle = "QC ใบอนุมัติทำลาย"

//english keys are needed due to Apollo query limitations
const ngTypeInfoList: NgType = {
  Stamping: {
    name: "Stamping",
    dataKey: "stamp",
    ngTypeInfoHeader: [
      "เป็นรอย",
      "เสียรูป",
      "ขาดแหว่ง",
      "สนิม",
      "ครีบ",
      "ขนาดไม้ได้",
      "คราบสกปรก",
      "ข้ามprocessStamp",
      "อื่นStamp",
      "totalStamp"
    ],
    ngTypeInfoKey: [
      "scratched",
      "deformed",
      "torn",
      "rusted",
      "fin",
      "incorrectStampSize",
      "dirtyStains",
      "skipProcessStamp",
      "otherStamp",
      "totalStamp"
    ]
  },
  Welding: {
    name: "Welding",
    dataKey: "weld",
    ngTypeInfoHeader: [
      "offset",
      "ผิดตำแหน่ง",
      "ผิดขนาด",
      "ไม่ติด",
      "noWelding",
      "",
      "",
      "ข้ามprocessWeld",
      "อื่นWeld",
      "totalWeld"
    ],
    ngTypeInfoKey: [
      "offset",
      "incorrectWeldPosition",
      "incorrectWeldSize",
      "looseWelding",
      "noWelding",
      "",
      "",
      "skipProcessWeld",
      "otherWeld",
      "totalWeld"
    ]
  },
  Plating: {
    name: "Plating",
    dataKey: "plate",
    ngTypeInfoHeader: [
      "ชุบไม่ติด",
      "สีพอง",
      "",
      "",
      "",
      "",
      "",
      "",
      "อื่นPlate",
      "totalPlate"
    ],
    ngTypeInfoKey: [
      "loosePlating",
      "discoloredPlating",
      "",
      "",
      "",
      "",
      "",
      "",
      "otherPlate",
      "totalPlate"
    ]
  },
  "Arc Welding": {
    name: "Arc Welding",
    dataKey: "arcweld",
    ngTypeInfoHeader: [
      "เชื่อมไม่ต่อเนื่อง",
      "เชื่อมติดด้านเดียว",
      "เชื่อม2รอบ",
      "เชื่อมทะลุ",
      "เชื่อมเป็นตามด",
      "เชื่อมไม่เต็ม",
      "ลืมปิดCO2",
      "ชิ้นงานเสียรูป",
      "อื่นArcWeld",
      "totalArcWeld"
    ],
    ngTypeInfoKey: [
      "discontinuousWeld",
      "oneSidedWeld",
      "twiceWelded",
      "puncturedWeld",
      "messyWeld",
      "incompleteWeld",
      "forgetToTurnOffCO2",
      "deformedArcweld",
      "otherArcweld",
      "totalArcWeld"
    ]
  }
}

const calculateQcDemolishTotal = (
  filteredList: QCDemolishData[],
  ngTypeKey: string,
  status?: string
): number | string => {
  if (!filteredList.length) return ""
  const total: number = filteredList.reduce(
    (previousVal: number, currObject: QCDemolishData) => {
      if (status === "Set Up") {
        return (previousVal +=
          currObject.sNG +
          (currObject[ngTypeKey as keyof QCDemolishData] as number))
      } else if (status === "ปรับแต่งแม่พิมพ์") {
        return (previousVal +=
          currObject.tNG +
          (currObject[ngTypeKey as keyof QCDemolishData] as number))
      } else {
        return (
          previousVal +
          (currObject[ngTypeKey as keyof QCDemolishData] as number)
        )
      }
    },
    0
  )
  return isNaN(total) ? "" : total
}

export const SearchQCDemolish = (
  props: ISearchQCDemolishProps
): JSX.Element => {
  const {
    startDate,
    endDate,
    factory,
    partNo,
    type,
    ngType,
    status,
    partNoList,
    setStartDate,
    setEndDate,
    setFactory,
    setPartNo,
    setStatus,
    setType,
    setNgType,
    getQcDemolish
  } = props
  const typeList = ["MATERIAL", "PART"]
  const factoryList = ["1", "2"]
  const statusList = ["In Process", "Final", "ปรับแต่งแม่พิมพ์", "Set Up"]
  const ngTypeList = ["Stamping", "Welding", "Plating", "Arc Welding"]
  const [error, setError] = useState<boolean>(false)
  const handleSearch = (event: React.FormEvent) => {
    if (!factory) {
      setError(true)
      return swal("WARNING", "กรุณาใส่ factory", "error")
    }
    event.preventDefault()
    getQcDemolish({
      variables: {
        dateFrom: moment(startDate).format("YYYY-MM-DD"),
        dateTo: moment(endDate).format("YYYY-MM-DD"),
        factory,
        partNo,
        status
      }
    })
  }
  const theme = useTheme()

  return (
    <>
      <form onSubmit={handleSearch} data-testid="qc-demolish-form">
        <Grid container spacing={2} margin={2} width={"98%"}>
          <Grid item xs={12} sm={3}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                openTo="day"
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                aria-label="date-from"
                value={startDate}
                onChange={(newStartDate) => {
                  setStartDate(moment(newStartDate).toDate() as Date)
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    fullWidth
                    label="Date From"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={3}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                openTo="day"
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                aria-label="date-to"
                value={endDate}
                onChange={(newEndDate) => {
                  setEndDate(moment(newEndDate).toDate() as Date)
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    fullWidth
                    label="Date To"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              fullWidth
              value={factory}
              disableClearable
              disablePortal
              aria-label="select-factory"
              onChange={(event, selectedFactory) => {
                setFactory(selectedFactory as string)
              }}
              options={factoryList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Factory"
                  error={!factory && error}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              fullWidth
              value={status}
              disablePortal
              aria-label="select-status"
              onChange={(event, selectedStatus) => {
                setStatus(selectedStatus as string)
              }}
              options={statusList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Status"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              fullWidth
              value={ngType}
              disablePortal
              aria-label="select-ng-type"
              onChange={(event, selectedNgType) => {
                setNgType(selectedNgType as string)
              }}
              options={ngTypeList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="NG Type"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              value={partNo}
              loading={partNoList.length === 0}
              disablePortal
              aria-label="select-partno"
              onChange={(event, selectedPartNo) => {
                setPartNo(selectedPartNo as string)
              }}
              options={partNoList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Part No."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              fullWidth
              value={type}
              disablePortal
              aria-label="select-type"
              onChange={(event, selectedType) => {
                setType(selectedType as string)
              }}
              options={typeList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Type"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            />
          </Grid>

          <Grid container item xs={12} spacing={2} className="hidden-print">
            <Grid item>
              <ThemedButton
                type="submit"
                variant="contained"
                aria-label="search-qc-demolish"
                endIcon={<SearchIcon />}
              >
                ค้นหา
              </ThemedButton>
            </Grid>
            <Grid item>
              <ThemedButton
                style={{
                  borderColor: theme.palette.primary.main
                }}
                variant="outlined"
                aria-label="print-qc-demolish"
                endIcon={<PrintIcon />}
                onClick={() => window.print()}
              >
                Print
              </ThemedButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export const QCDemolishBody = (props: IQCDemolishBodyProps): JSX.Element => {
  const {
    qcDemolishData,
    qcDemolishCalled,
    qcDemolishLoading,
    ngType,
    status
  } = props

  const filteredList: QCDemolishData[] =
    qcDemolishData && qcDemolishData.qcDemolishReport.length
      ? qcDemolishData.qcDemolishReport.filter(
          (demolishReport: QCDemolishData) =>
            demolishReport[
              ngTypeInfoList[ngType].dataKey as keyof QCDemolishData
            ]
        )
      : []

  //each ngType has 10 columns to it hence index 0 -> 9
  const qcDemolishReportBody: JSX.Element | JSX.Element[] =
    qcDemolishCalled && qcDemolishLoading ? (
      <StyledTableRow>
        <StyledTableCell colSpan={30}>
          <CircularProgress />
        </StyledTableCell>
      </StyledTableRow>
    ) : (
      filteredList.map((demolishReport: QCDemolishData, index: number) => {
        let totalForType = 0
        if (status === "Final" || status === "In Process") {
          totalForType = demolishReport[
            ngTypeInfoList[ngType].ngTypeInfoKey[9] as keyof QCDemolishData
          ] as number
        } else if (status === "Set Up") {
          totalForType =
            Number(demolishReport.sNG) +
            Number(
              demolishReport[
                ngTypeInfoList[ngType].ngTypeInfoKey[9] as keyof QCDemolishData
              ]
            )
        } else if (status === "ปรับแต่งแม่พิมพ์") {
          totalForType =
            Number(demolishReport.tNG) +
            Number(
              demolishReport[
                ngTypeInfoList[ngType].ngTypeInfoKey[9] as keyof QCDemolishData
              ]
            )
        } else {
          totalForType =
            demolishReport.iNG +
            demolishReport.tNG +
            demolishReport.sNG +
            demolishReport.supplierNG
        }

        const demolishQuantity: number = demolishReport.quantity
        return (
          <StyledTableRow
            aria-label="qc-demolish-body"
            key={`qc-demolish-body-row-${index + 1}`}
          >
            <StyledTableCell colSpan={1}>{index + 1}</StyledTableCell>
            <StyledTableCell colSpan={2}>{demolishReport.date}</StyledTableCell>
            <StyledTableCell colSpan={2}>
              {demolishReport.partNo}
            </StyledTableCell>
            <StyledTableCell colSpan={3}>
              {demolishReport.partName}
            </StyledTableCell>
            <StyledTableCell colSpan={2}>
              {demolishReport.customer}
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[0] as keyof QCDemolishData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[1] as keyof QCDemolishData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[2] as keyof QCDemolishData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[3] as keyof QCDemolishData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[4] as keyof QCDemolishData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[5] as keyof QCDemolishData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[6] as keyof QCDemolishData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[7] as keyof QCDemolishData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[8] as keyof QCDemolishData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>{totalForType}</StyledTableCell>
            <StyledTableCell colSpan={1}>
              {demolishReport.okQuantity}
            </StyledTableCell>
            <StyledTableCell colSpan={1}>{demolishQuantity}</StyledTableCell>
            <StyledTableCell colSpan={1}>
              {(100 * (totalForType / demolishQuantity)).toFixed(2)}%
            </StyledTableCell>
            <StyledTableCell colSpan={2}>
              {demolishReport.pdLotNo}
            </StyledTableCell>
            <StyledTableCell colSpan={2}>
              {demolishReport.jobOrder}
            </StyledTableCell>
            <StyledTableCell colSpan={2}>
              {demolishReport.remarks}
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {demolishReport.operator}
            </StyledTableCell>
          </StyledTableRow>
        )
      })
    )

  return (
    <>
      {qcDemolishReportBody}
      <StyledTableRow style={{ height: "70px" }}>
        <StyledTableCellBold colSpan={10}>ยอดรวม</StyledTableCellBold>
        <StyledTableCell colSpan={1}>
          {calculateQcDemolishTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[0]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcDemolishTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[1]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcDemolishTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[2]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcDemolishTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[3]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcDemolishTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[4]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcDemolishTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[5]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcDemolishTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[6]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcDemolishTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[7]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcDemolishTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[8]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcDemolishTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[9],
            status
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={10}></StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export const QCDemolishSignature = (): JSX.Element => {
  return (
    <>
      <StyledTableRow style={{ height: "70px" }}>
        <StyledTableCell colSpan={4}></StyledTableCell>
        <StyledTableCell colSpan={5}></StyledTableCell>
        <StyledTableCell colSpan={5}></StyledTableCell>
        <StyledTableCell colSpan={4}></StyledTableCell>
        <StyledTableCell colSpan={4}></StyledTableCell>
        <StyledTableCell colSpan={4}></StyledTableCell>
        <StyledTableCell colSpan={4}></StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell colSpan={4}>Manager PD</StyledTableCell>
        <StyledTableCell colSpan={5}>Manager QA</StyledTableCell>
        <StyledTableCell colSpan={5}>Manager Eng</StyledTableCell>
        <StyledTableCell colSpan={4}>Manager PL</StyledTableCell>
        <StyledTableCell colSpan={4}>Mgr AC</StyledTableCell>
        <StyledTableCell colSpan={4}>FM</StyledTableCell>
        <StyledTableCell colSpan={4}>MD</StyledTableCell>
      </StyledTableRow>
    </>
  )
}

const QCDemolish: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [factory, setFactory] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [type, setType] = useState<string>("")
  const [status, setStatus] = useState<string>("")
  const [ngType, setNgType] = useState<string>("Stamping")

  const [printMode, setPrintMode] = useState<boolean>(false)

  const { data: partNoData, loading: partNoLoading } = useQuery(GET_PARTNO)

  const [
    getQcDemolish,
    {
      data: qcDemolishData,
      loading: qcDemolishLoading,
      called: qcDemolishCalled
    }
  ] = useLazyQuery<QCDemolishDataList, QCDemolishQueryArguments>(
    GET_QC_DEMOLISH
  )

  const makePartNoList = (partsList: PartNoList, loading: boolean) => {
    if (loading || !partsList || partsList.parts.length === 0) return []
    return partsList.parts.map((part: PartNo) => part.partNo)
  }

  const partNoList = useMemo(
    () => makePartNoList(partNoData, partNoLoading),
    [partNoData, partNoLoading]
  )

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <div style={{ position: "relative" }}>
        <div className="hidden-print">
          <PageLabel
            menuItem={menuItemList.Qc}
            menuItemName={Object.keys(menuItemList)[4]}
            menuPageName={pageTitle}
          />
        </div>
        <ThemedCard>
          <PageLabelCard
            title="QC ใบอนุมัติทำลาย"
            subTitle="detail about QC ใบอนุมัติทำลาย"
          />
          <div className="hidden-print">
            <SearchQCDemolish
              startDate={startDate}
              endDate={endDate}
              factory={factory}
              status={status}
              partNo={partNo}
              type={type}
              ngType={ngType}
              partNoList={partNoList}
              printMode={printMode}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setFactory={setFactory}
              setStatus={setStatus}
              setPartNo={setPartNo}
              setType={setType}
              setNgType={setNgType}
              getQcDemolish={getQcDemolish}
              setPrintMode={setPrintMode}
            />
          </div>

          <div className="qc-report-to-print">
            <div className="hidden-print">
              <PageLabelCard
                title="Preview QCDemolish"
                subTitle="detail about QCDemolish"
              />
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 700 }}
                    aria-label="customized table"
                    className="print-sm-order"
                  >
                    <TableHead>
                      <StyledTableRow id="qc-demolish-header">
                        <StyledTableCell
                          style={{ maxWidth: "300px" }}
                          colSpan={5}
                        >
                          <img src={miniLogo} alt="Brother Auto Parts." />
                        </StyledTableCell>
                        <StyledTableCellBold
                          align="center"
                          colSpan={20}
                          sx={{ fontSize: "50px" }}
                        >
                          ใบอนุมัติทำลาย
                        </StyledTableCellBold>
                        <StyledTableCellBold align="center" colSpan={5}>
                          F-QA-07-02
                          <br />
                          Effective Date: 02/08/2024 R06 Issue: 1
                        </StyledTableCellBold>
                      </StyledTableRow>
                      <StyledTableRow id="qc-demolish-options">
                        <StyledTableCellBold colSpan={1}>
                          Type
                        </StyledTableCellBold>
                        <StyledTableCell colSpan={4}>{type}</StyledTableCell>
                        <StyledTableCellBold colSpan={2}>
                          Status
                        </StyledTableCellBold>
                        <StyledTableCell colSpan={4}>{status}</StyledTableCell>
                        <StyledTableCellBold colSpan={2}>
                          NG Type
                        </StyledTableCellBold>
                        <StyledTableCell colSpan={4}>{ngType}</StyledTableCell>
                        <StyledTableCellBold colSpan={2}>
                          Factory
                        </StyledTableCellBold>
                        <StyledTableCell colSpan={3}>{factory}</StyledTableCell>
                        <StyledTableCellBold colSpan={2}>
                          Date
                        </StyledTableCellBold>
                        <StyledTableCell colSpan={6}>{`${moment(
                          startDate
                        ).format("DD/MM/YYYY")} - ${moment(endDate).format(
                          "DD/MM/YYYY"
                        )}`}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow id="qc-demolish-body-header">
                        <StyledTableCell rowSpan={4} colSpan={1}>
                          No.
                        </StyledTableCell>
                        <StyledTableCell rowSpan={4} colSpan={2}>
                          Date
                        </StyledTableCell>
                        <StyledTableCell rowSpan={4} colSpan={2}>
                          Part No.
                        </StyledTableCell>
                        <StyledTableCell rowSpan={4} colSpan={3}>
                          Part Name
                        </StyledTableCell>
                        <StyledTableCellVertical rowSpan={4} colSpan={2}>
                          <div className="vertical-text-qc">Customer</div>
                        </StyledTableCellVertical>
                        <StyledTableCell rowSpan={1} colSpan={9}>
                          รายระเอียดของเสีย
                        </StyledTableCell>

                        <StyledTableCellVertical rowSpan={4} colSpan={1}>
                          <div className="vertical-text-qc">รวมของเสีย</div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={4} colSpan={1}>
                          <div className="vertical-text-qc">ยอดจำนวน OK</div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={4} colSpan={1}>
                          <div className="vertical-text-qc">Lot Size</div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={4} colSpan={1.5}>
                          <div className="vertical-text-qc">%NG</div>
                        </StyledTableCellVertical>

                        <StyledTableCellVertical rowSpan={4} colSpan={2}>
                          <div className="vertical-text-qc">PRODUCTION LOT</div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={4} colSpan={2}>
                          <div className="vertical-text-qc">JON/Mat Lot No</div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={4} colSpan={2}>
                          <div className="vertical-text-qc">หมายเหตุ</div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={4} colSpan={2}>
                          <div className="vertical-text-qc">
                            ผู้รับผิดชอบ QC/QA
                          </div>
                        </StyledTableCellVertical>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCellVertical
                          rowSpan={3}
                          colSpan={1}
                          aria-label="ng-type-col-1"
                        >
                          <div className="vertical-text-qc">
                            {ngTypeInfoList[ngType].ngTypeInfoHeader[0]}
                          </div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={3} colSpan={1}>
                          <div className="vertical-text-qc">
                            {ngTypeInfoList[ngType].ngTypeInfoHeader[1]}
                          </div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={3} colSpan={1}>
                          <div className="vertical-text-qc">
                            {ngTypeInfoList[ngType].ngTypeInfoHeader[2]}
                          </div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={3} colSpan={1}>
                          <div className="vertical-text-qc">
                            {ngTypeInfoList[ngType].ngTypeInfoHeader[3]}
                          </div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={3} colSpan={1}>
                          <div className="vertical-text-qc">
                            {ngTypeInfoList[ngType].ngTypeInfoHeader[4]}
                          </div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={3} colSpan={1}>
                          <div className="vertical-text-qc">
                            {ngTypeInfoList[ngType].ngTypeInfoHeader[5]}
                          </div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={3} colSpan={1}>
                          <div className="vertical-text-qc">
                            {ngTypeInfoList[ngType].ngTypeInfoHeader[6]}
                          </div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={3} colSpan={1}>
                          <div className="vertical-text-qc">
                            {ngTypeInfoList[ngType].ngTypeInfoHeader[7]}
                          </div>
                        </StyledTableCellVertical>
                        <StyledTableCellVertical rowSpan={3} colSpan={1}>
                          <div className="vertical-text-qc">อื่น</div>
                        </StyledTableCellVertical>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      <QCDemolishBody
                        qcDemolishData={qcDemolishData}
                        qcDemolishCalled={qcDemolishCalled}
                        qcDemolishLoading={qcDemolishLoading}
                        ngType={ngType}
                        status={status}
                      />
                      <QCDemolishSignature />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </ThemedCard>
      </div>
    </ThemeProvider>
  )
}

export default QCDemolish
