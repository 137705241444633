import {
  Autocomplete,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  useTheme,
  styled,
  tableCellClasses,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Table
} from "@mui/material"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import Paper from "@mui/material/Paper"
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import moment from "moment"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_PLATING_DAILY_REPORT } from "../../common/Query/PlatingQuery"
import { GET_MASTERPLAN_REPORT } from "../../common/Query/MasterPlanQuery"
import { IMasterPlanPartData } from "../MasterPlan/MPReport"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import swal from "sweetalert"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { EDIT_QUANTITY_PLATING } from "../../common/Mutation/PlatingMutation"
import PrintIcon from "@mui/icons-material/Print"
import "../../css/Plate/DailyPlating.css"
interface IPlatingReport {
  no: number
  adm: number
  customer: string
  model: string
  partNo: string
  partName: string
  spec: string
  wt: number
  round: number
  factory: string
  quantity: number
  adjust: number
  orderMasterPlan: IOrder[]
}

interface IOrder {
  dueDate: string
  quantity: number
}

interface IProps {
  setOpenEditQuantity: Dispatch<SetStateAction<boolean>>
  openEditQuantity: boolean
  platingDailyReport: IPlatingReport[]
}

interface Column {
  id:
    | "no"
    | "customer"
    | "partNo"
    | "partName"
    | "round"
    | "spec"
    | "wt"
    | "factory"
    | "quantity"
  label: string
  minWidth?: number
  align?: "center"
  format?: (value: number) => string
}

const columns: Column[] = [
  { id: "no", label: "No", minWidth: 100 },
  { id: "customer", label: "Customer", minWidth: 100 },
  { id: "partNo", label: "partNo.", minWidth: 100 },
  { id: "partName", label: "partName", minWidth: 100 },
  { id: "spec", label: "Spec", minWidth: 100 },
  { id: "wt", label: "Weight (kg)", minWidth: 100 },
  { id: "round", label: "STD (Pcs./Round)", minWidth: 100 },
  { id: "factory", label: "	PD Fac.", minWidth: 100 }
]

const pageTitle = "Plating Daily Report"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 20,
    fontFamily: "Sarabun",
    textAlign: "center"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },

  "td, th": {
    border: "1px solid black"
  }
}))

const compare = (a: { partNo: string }, b: { partNo: string }) => {
  if (a.partNo < b.partNo) {
    return -1
  }
  if (a.partNo > b.partNo) {
    return 1
  }
  return 0
}
const validationStatusDeficitBalActual = (plating: boolean, status: string) => {
  if (plating) {
    return status && status !== "-"
  } else {
    return (
      status === "" || status === "-" || status === null || status === undefined
    )
  }
}
const deficitBalActual = (
  data: IMasterPlanPartData[],
  status: boolean,
  date: string
) => {
  let response = data
  let minimumDate = date
  data
    .filter((part) => validationStatusDeficitBalActual(status, part.status))
    .forEach((part) => {
      const order = part.order.find((order) => order.balActual < 0)

      if (order) {
        if (order.dueDate < minimumDate) minimumDate = order.dueDate
      }
    })
  response = response.map((part) => {
    return {
      ...part,
      order: part.order.filter((e) => e.dueDate >= minimumDate)
    }
  })
  response = response.filter((e) =>
    e.order.some((order) => {
      return order.balActual < 0
    })
  )
  return response
}

const paddingPartDeliveryDates = (
  orders: Array<IOrder>,
  masterDateList: Array<string>
): Array<IOrder> => {
  const newOrders: Array<IOrder> = []
  let j = 0

  masterDateList.forEach((e, i) => {
    if (orders[j]) {
      if (orders.length > j && masterDateList[i] === orders[j].dueDate) {
        newOrders.push(orders[j])
        j++
      } else {
        const tempOrder: IOrder = {
          dueDate: masterDateList[i],
          quantity: 0
        }

        newOrders.push(tempOrder)
      }
    } else {
      const tempOrder: IOrder = {
        quantity: 0,
        dueDate: masterDateList[i]
      }
      newOrders.push(tempOrder)
    }
  })
  return newOrders
}

const EditQuantity: React.FC<IProps> = (props: IProps) => {
  const { openEditQuantity, setOpenEditQuantity, platingDailyReport } = props
  const [partNo, setPartNo] = useState<string>("")
  const [quantity, setQuantity] = useState<number | null>(0)
  const [editQuantity, { loading }] = useMutation(EDIT_QUANTITY_PLATING, {
    refetchQueries: [GET_PLATING_DAILY_REPORT],
    awaitRefetchQueries: true
  })
  const theme = useTheme()
  const handleSubmit = async () => {
    try {
      await editQuantity({
        variables: {
          partNo,
          quantity,
          actionDate: moment(new Date()).format("YYYY-MM-DD")
        }
      })
      setPartNo("")
      setQuantity(0)
      swal("Success", "Successful", "success")
      setOpenEditQuantity(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }
  useEffect(() => {
    const defaultValue = platingDailyReport.find(
      (data) => data.partNo === partNo
    )
    setQuantity(defaultValue?.quantity as number)
  }, [partNo])
  return (
    <>
      <Dialog
        open={openEditQuantity}
        onClose={() => setOpenEditQuantity(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.default
          }
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Sarabun" }}>
          Edit Quantity
        </DialogTitle>

        <DialogContent style={{ paddingTop: 10 }}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <AutocompletePartNo setPartNo={setPartNo} partNo={partNo} />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                value={quantity}
                onChange={(e) => setQuantity(+e.target.value)}
                label="Quantity"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            style={{ backgroundColor: theme.palette.warning.main }}
            onClick={() => setOpenEditQuantity(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const DailyPlatingReport: React.FC = () => {
  const theme = useTheme()
  const [line, setLine] = useState<string>("EDP")
  const [maxDueDate, setMaxDueDate] = useState<Array<string>>([])
  const [platingDailyReport, setPlatingDailyReport] = useState<
    IPlatingReport[]
  >([])
  const [open, setOpen] = useState(false)
  const [openEditQuantity, setOpenEditQuantity] = useState<boolean>(false)

  const [getPlatingDailyReport, { data }] = useLazyQuery(
    GET_PLATING_DAILY_REPORT,
    {
      variables: {
        date: moment(new Date()).format("YYYY-MM-DD"),
        line: "EDP"
      }
    }
  )

  const [getMasterPlanReport, { data: masterPlanReport }] = useLazyQuery(
    GET_MASTERPLAN_REPORT
  )
  const day = moment()
  const handleClick = async () => {
    setOpen(true)
    await getPlatingDailyReport({
      variables: {
        date: moment(new Date()).format("YYYY-MM-DD"),
        line
      },
      fetchPolicy: "network-only"
    })

    await getMasterPlanReport({
      variables: {
        dateFrom: day.add(1, "days").format("YYYY-MM-DD"),
        dateTo: day.add(5, "days").format("YYYY-MM-DD"),
        plating: true
      },
      fetchPolicy: "network-only"
    })
    setOpen(false)
  }
  useMemo(async () => {
    setOpen(true)
    await getPlatingDailyReport({
      variables: {
        date: moment(new Date()).format("YYYY-MM-DD"),
        line
      },
      fetchPolicy: "network-only"
    })

    await getMasterPlanReport({
      variables: {
        dateFrom: day.add(1, "days").format("YYYY-MM-DD"),
        dateTo: day.add(5, "days").format("YYYY-MM-DD"),
        plating: true
      },
      fetchPolicy: "network-only"
    })
    setOpen(false)
  }, [])
  useEffect(() => {
    const allDueDate: string[] = []
    if (data && masterPlanReport && masterPlanReport.MasterPlan) {
      const deficitOrder = deficitBalActual(
        masterPlanReport.MasterPlan,
        true,
        day.add(5, "days").format("YYYY-MM-DD")
      )
      deficitOrder.forEach((e: { order: IOrder[] }) => {
        e.order.forEach((e) => {
          allDueDate.push(e.dueDate)
        })
      })
      const newDueDate = Array.from(new Set(allDueDate))
      setMaxDueDate(newDueDate.sort())

      const dailyPlateReport = data.platingDailyReport.map(
        (plate: IPlatingReport) => {
          const orderMasterPlan: IMasterPlanPartData =
            masterPlanReport.MasterPlan.find(
              (mp: IMasterPlanPartData) => mp.partNo === plate.partNo
            )
          return {
            ...plate,
            quantity: plate.quantity.toLocaleString(),
            adjust: plate.adjust ? plate.adjust : plate.quantity,
            factory: orderMasterPlan?.factory,
            orderMasterPlan: orderMasterPlan?.order || []
          }
        }
      )
      const dataReport = [...dailyPlateReport]
      if (dataReport.length > 0) dataReport.sort(compare)
      setPlatingDailyReport(
        dataReport.map((report, i: number) => {
          return { ...report, no: i + 1 }
        })
      )
    }
  }, [data, masterPlanReport])

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Plate}
        menuItemName={Object.keys(menuItemList)[14]}
        menuPageName={pageTitle}
      ></PageLabel>
      <div className="label_navbar">
        <ThemedCard>
          <PageLabelCard
            title="Plating Daily Plan"
            subTitle="About detail line plate"
          />
          <Grid container spacing={1} margin={2} width={"98%"}>
            <Grid item sm={2} xs={2}>
              <Autocomplete
                value={line}
                fullWidth
                onChange={(event, value) => {
                  setLine(value as string)
                }}
                options={["EDP", "Hanger", "Barrel"]}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Line"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={1} xs={1}>
              <ThemedButton
                variant="contained"
                fullWidth
                sx={{ mt: 1 }}
                onClick={handleClick}
              >
                SUBMIT
              </ThemedButton>
            </Grid>
            <Grid item sm={1} xs={1}>
              <ThemedButton
                variant="contained"
                fullWidth
                sx={{
                  mt: 1,
                  backgroundColor: theme.palette.warning.main
                }}
                onClick={() => setOpenEditQuantity(true)}
              >
                EDIT
              </ThemedButton>
            </Grid>
            <Grid item sm={1} xs={1}>
              <ThemedButton
                contrast
                variant="outlined"
                endIcon={<PrintIcon />}
                fullWidth
                sx={{
                  mt: 1,
                  bgcolor: theme.palette.primary.dark
                }}
                onClick={() => window.print()}
              >
                Print
              </ThemedButton>
            </Grid>
          </Grid>
          <Dialog open={open}>
            <DialogContent style={{ width: "auto" }}>
              <CircularProgress />
            </DialogContent>
          </Dialog>
          <EditQuantity
            openEditQuantity={openEditQuantity}
            setOpenEditQuantity={setOpenEditQuantity}
            platingDailyReport={platingDailyReport}
          />
        </ThemedCard>
      </div>
      <TableContainer className="daily-plating" component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell colSpan={8} align="center">
                Plating Daily Plan {line}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                colSpan={maxDueDate.length}
                rowSpan={maxDueDate.length ? 1 : 2}
              >
                Due ส่งงาน
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    top: 57,
                    minWidth: column.minWidth
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              {maxDueDate.map((e: string, i: number) => {
                return (
                  <StyledTableCell
                    style={{
                      borderWidth: "1px",
                      borderStyle: "solid",
                      whiteSpace: "nowrap",
                      padding: "7px"
                    }}
                    colSpan={1}
                    rowSpan={1}
                    key={i + "max-due-date"}
                  >
                    {e.split("-")[2]} - {e.split("-")[1]}
                  </StyledTableCell>
                )
              })}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {platingDailyReport.map((partData, i) => {
              const order = paddingPartDeliveryDates(
                partData.orderMasterPlan,
                maxDueDate
              )
              return (
                <>
                  <StyledTableRow key={i}>
                    <StyledTableCell>{partData.no}</StyledTableCell>
                    <StyledTableCell>{partData.customer}</StyledTableCell>
                    <StyledTableCell>{partData.partNo}</StyledTableCell>
                    <StyledTableCell>{partData.partName}</StyledTableCell>
                    <StyledTableCell>{partData.spec}</StyledTableCell>
                    <StyledTableCell>{partData.wt}</StyledTableCell>
                    <StyledTableCell>
                      {Number(partData.round).toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell>{partData.factory}</StyledTableCell>

                    {Array.from(Array(maxDueDate.length), (num, i) => {
                      if (order[i]) {
                        return (
                          <>
                            <StyledTableCell
                              colSpan={1}
                              rowSpan={1}
                              key={"quantity" + i}
                            >
                              {Number(order[i].quantity).toLocaleString()}
                            </StyledTableCell>
                          </>
                        )
                      }
                    })}
                  </StyledTableRow>
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  )
}
export default DailyPlatingReport
