import { useMutation } from "@apollo/client"
import { checkRole } from "../../../../common/MainApp"
import ThemedButton from "../../../../common/Resources/ThemedComponents/ThemedButton"
import swal from "sweetalert"
import { UPDATE_STATUS } from "../../../../common/Mutation/PlanningMutation"
import { getImagePng } from "../../../../common/utilities"
import { useCallback, useContext } from "react"
import AuthContext from "../../../../context/AuthContext"

interface IProp {
  status: boolean
  setStatus: (status: boolean) => void
  line: string
  revision: string
  date: string
}
export const ApproveSignature: React.FC<IProp> = (props: IProp) => {
  const { status, line, revision, date, setStatus } = props
  const [updateStatus] = useMutation(UPDATE_STATUS)
  const { token } = useContext(AuthContext)

  const handleApprove = useCallback(async () => {
    try {
      await updateStatus({
        variables: {
          line,
          revision,
          planDate: date
        }
      })
      setStatus(false)
    } catch (error) {
      swal("Error", `${error}`, "error")
    }
  }, [])

  return (
    <>
      {status ? (
        <ThemedButton
          style={checkRole(["99"], token) && status ? {} : { display: "none" }}
          onClick={handleApprove}
        >
          Approve
        </ThemedButton>
      ) : (
        <img
          src={getImagePng("images/signature/signature_nut")}
          width={"55px"}
        />
      )}
    </>
  )
}
