import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { bankImage, getImagePng, miniLogo } from "../../../common/utilities"
import { useMutation, useQuery } from "@apollo/client"
import { GET_EDP_COATING } from "../../../common/Query/PlatingQuery"

import { ReactElement, useEffect, useState } from "react"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import swal from "sweetalert"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import {
  ADD_EDP_COATING,
  DELETE_EDP_COATING,
  UPDATE_EDP_COATING
} from "../../../common/Mutation/PlatingMutation"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import PrintIcon from "@mui/icons-material/Print"
import { usePartNo } from "../../../common/Resources/customHook/usePartNo"
import { GET_PARTS } from "../../../common/Query/MasterQuery"
import { IPart } from "../../Master/Parts"

interface IReportEDPCoating {
  item: number
  customer: string
  model: string
  partNo: string
  partName: string
  wt: number
  adm: number
  spec: string
  jig: string
  round: number
  volt: string
  oc: number
  min: string
  packing: string
  img: string
}

interface IPropsAddEdpCoating {
  setOpenAddEdpCoating: (boolean: boolean) => void
  openAddEdpCoating: boolean
}

interface IUpdateEdpCoating {
  setOpenUpdateEdpCoating: (boolean: boolean) => void
  openUpdateEdpCoating: boolean
}

interface IDeleteEdpCoating {
  setOpenDeleteEdpCoating: (boolean: boolean) => void
  openDeleteEdpCoating: boolean
}

interface Column {
  id:
    | "item"
    | "customer"
    | "model"
    | "partNo"
    | "partName"
    | "wt"
    | "adm"
    | "spec"
    | "jig"
    | "round"
    | "volt"
    | "oc"
    | "min"
    | "packing"
    | "img"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

const columns: Column[] = [
  { id: "item", label: "item", minWidth: 50 },
  { id: "customer", label: "Customer", minWidth: 50 },
  { id: "model", label: "Model", minWidth: 100 },
  { id: "partNo", label: "part No", minWidth: 50 },
  { id: "partName", label: "Part Name", minWidth: 100 },
  { id: "img", label: "img", minWidth: 100 },
  { id: "wt", label: "น้ำหนัก wt(g)", minWidth: 100 },
  { id: "adm", label: "พื้นที่ผิว A (dm2)", minWidth: 100 },
  { id: "spec", label: "Spec", minWidth: 100 },
  { id: "jig", label: "ไม้จิ๊ก", minWidth: 50 },
  { id: "round", label: "จำนวนชิ้นต่อรอบ", minWidth: 50 },
  { id: "volt", label: "กระแสไฟ Volt", minWidth: 50 },
  { id: "oc", label: "องศา", minWidth: 50 },
  { id: "min", label: "เวลาอบ", minWidth: 100 },
  { id: "packing", label: "Packing", minWidth: 50 }
]

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 29,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 30,
    fontFamily: "Sarabun",
    textAlign: "center"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },

  "td, th": {
    border: "1px solid black"
  }
}))

const AddNewEdpCoating: React.FC<IPropsAddEdpCoating> = (
  props: IPropsAddEdpCoating
): ReactElement => {
  const [addEdpCoating, { loading }] = useMutation(ADD_EDP_COATING, {
    refetchQueries: [GET_EDP_COATING],
    awaitRefetchQueries: true
  })
  const theme = useTheme()
  const { setOpenAddEdpCoating, openAddEdpCoating } = props
  const [partNo, setPartNo] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [model, setModel] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [spec, setSpec] = useState<string>("")
  const [wt, setWt] = useState<number>()
  const [adm, setAdm] = useState<number>()
  const [volt, setVolt] = useState<string>("")
  const [round, setRound] = useState<number>()
  const [jig, setJig] = useState<string>("")
  const [oc, setOc] = useState<number>()
  const [min, setMin] = useState<string>("")
  const [packing, setPacking] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  const isComplete = () => {
    return (
      customer &&
      model &&
      partNo &&
      partName &&
      wt &&
      adm &&
      spec &&
      jig &&
      round &&
      volt &&
      oc &&
      min &&
      packing
    )
  }

  const partNoList = usePartNo()
  const { data: partNoData } = useQuery(GET_PARTS, {
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    if (!partNo) {
      handleReset()
    } else if (partNoData) {
      const part = partNoData.parts.find(
        (part: IPart) => part.partNo === partNo
      )
      setPartName(part.partName)
      setCustomer(part.customer)
      setModel(part.model)
    }
  }, [partNo])

  const handleReset = () => {
    setPartName("")
    setPartNo("")
    setCustomer("")
    setModel("")
    setPacking("")
    setSpec("")
    setAdm(0)
    setWt(0)
    setVolt("")
    setRound(0)
    setOc(0)
    setJig("")
    setMin("")
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    if (
      !customer &&
      !model &&
      !partNo &&
      !partName &&
      !wt &&
      !adm &&
      !spec &&
      !jig &&
      !round &&
      !volt &&
      !oc &&
      !min &&
      !packing
    ) {
      setError(true)
      return swal("Warning", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
    e.preventDefault()
    if (!isComplete()) {
      setError(true)
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }

    try {
      await addEdpCoating({
        variables: {
          customer,
          model,
          partNo,
          partName,
          wt,
          adm,
          spec,
          jig,
          round,
          volt,
          oc,
          min,
          packing
        }
      })
      swal("Success", "Add Part Successful", "success")
      handleReset()
      setOpenAddEdpCoating(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openAddEdpCoating}
        onClose={() => setOpenAddEdpCoating(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Add EDP
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                id="select-part-no"
                value={partNo ? partNo : ""}
                options={partNoList}
                loading={!!partNoList}
                onChange={(e, newValue) => setPartNo(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Part No."
                    error={!partNo && error}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setPartName(e.target.value)}
                value={partName ? partName : ""}
                id="part name"
                autoComplete="family-name"
                label="Part Name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setCustomer(e.target.value)}
                value={customer ? customer : ""}
                id="customer"
                label="Customer"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setSpec(e.target.value)}
                value={spec ? spec : ""}
                id="spec"
                label="Spec"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="Model"
                onChange={(e) => setModel(e.target.value)}
                value={model ? model : ""}
                id="sizeL"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item xs={4} sm={4}>
              <ThemedTextField
                label="น้ำหนัก (กรัม)"
                fullWidth
                value={wt ? wt : ""}
                type="number"
                inputProps={{
                  minLength: 0,
                  step: "0.1"
                }}
                onChange={(e) => setWt(parseFloat(e.target.value))}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="พื้นที่ผิว"
                onChange={(e) => setAdm(parseFloat(e.target.value))}
                value={adm ? adm : ""}
                id="adm"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <ThemedTextField
                fullWidth
                label="กระแสไฟ (โวล์ท)"
                onChange={(e) => setVolt(e.target.value)}
                value={volt ? volt : ""}
                id="kg"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="จำนวนชิ้นต่อรอบ"
                onChange={(e) => setRound(parseFloat(e.target.value))}
                value={round ? round : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="อบ"
                onChange={(e) => setOc(parseFloat(e.target.value))}
                value={oc ? oc : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="ไม้จิ๊ก"
                onChange={(e) => setJig(e.target.value)}
                value={jig ? jig : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="เวลาชุบ (นาที)"
                onChange={(e) => setMin(e.target.value)}
                value={min ? min : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="packing"
                onChange={(e) => setPacking(e.target.value)}
                value={packing ? packing : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            sx={{
              backgroundColor: theme.palette.error.main,
              "&:hover": { backgroundColor: theme.palette.error.dark }
            }}
            variant="text"
            onClick={() => handleReset()}
          >
            RESET
          </ThemedButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenAddEdpCoating(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const UpdateEdpCoating: React.FC<IUpdateEdpCoating> = (
  props: IUpdateEdpCoating
): ReactElement => {
  const [updateEdpCoating, { loading }] = useMutation(UPDATE_EDP_COATING, {
    refetchQueries: [GET_EDP_COATING],
    awaitRefetchQueries: true
  })

  const { data } = useQuery(GET_EDP_COATING)
  const { openUpdateEdpCoating, setOpenUpdateEdpCoating } = props
  const [partNo, setPartNo] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [model, setModel] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [spec, setSpec] = useState<string>("")
  const [wt, setWt] = useState<number>()
  const [adm, setAdm] = useState<number>()
  const [volt, setVolt] = useState<string>("")
  const [round, setRound] = useState<number>()
  const [jig, setJig] = useState<string>("")
  const [oc, setOc] = useState<number>()
  const [min, setMin] = useState<string>("")
  const [packing, setPacking] = useState<string>("")
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    const edpCoatingList = data?.masterEDP?.find(
      (entry: { partNo: string }) => {
        return entry.partNo === partNo
      }
    )
    if (edpCoatingList) {
      setPartName(edpCoatingList.partName)
      setPartNo(edpCoatingList.partNo)
      setPacking(edpCoatingList.packing)
      setSpec(edpCoatingList.spec)
      setCustomer(edpCoatingList.customer)
      setModel(edpCoatingList.model)
      setAdm(edpCoatingList.adm)
      setWt(edpCoatingList.wt)
      setVolt(edpCoatingList.volt)
      setRound(edpCoatingList.round)
      setOc(edpCoatingList.oc)
      setJig(edpCoatingList.jig)
      setMin(edpCoatingList.min)
    }
    if (!partNo) handleReset()
  }, [partNo])

  const edpCoatingList = data
    ? data?.masterEDP?.map(({ partNo }: { partNo: string }) => partNo)
    : []

  const handleSubmit = async () => {
    try {
      await updateEdpCoating({
        variables: {
          customer,
          model,
          partNo,
          partName,
          wt,
          adm,
          spec,
          jig,
          round,
          volt,
          oc,
          min,
          packing
        }
      })
      swal("Success", "Updater Success", "success")
      setOpenUpdateEdpCoating(false)
    } catch (err) {
      setError(true)
      swal("Warning", `กรุณาใส่ MatNo`, "warning")
    }
  }

  const handleReset = () => {
    setPartName("")
    setPartNo("")
    setPacking("")
    setSpec("")
    setCustomer("")
    setModel("")
    setAdm(0)
    setWt(0)
    setVolt("")
    setRound(0)
    setOc(0)
    setJig("")
    setMin("")
  }

  return (
    <>
      <Dialog
        open={openUpdateEdpCoating}
        onClose={() => setOpenUpdateEdpCoating(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Edit EDP
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item sm={12} xs={12}>
              <Autocomplete
                id="part no"
                value={partNo ? partNo : ""}
                options={edpCoatingList}
                style={{ width: "100%" }}
                sx={{ width: 100 }}
                loading={!!edpCoatingList}
                onChange={(e, newValue) => setPartNo(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select partNo"
                    error={!partNo && error}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setPartName(e.target.value)}
                value={partName ? partName : ""}
                id="part name"
                autoComplete="family-name"
                label="Part Name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setCustomer(e.target.value)}
                value={customer ? customer : ""}
                id="customer"
                label="Customer"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setSpec(e.target.value)}
                value={spec ? spec : ""}
                id="spec"
                label="Spec"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="Model"
                onChange={(e) => setModel(e.target.value)}
                value={model ? model : ""}
                id="sizeL"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item xs={4} sm={4}>
              <ThemedTextField
                label="น้ำหนัก (กรัม)"
                fullWidth
                value={wt ? wt : ""}
                type="number"
                inputProps={{
                  minLength: 0,
                  step: "0.1"
                }}
                onChange={(e) => setWt(parseFloat(e.target.value))}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="พื้นที่ผิว"
                onChange={(e) => setAdm(parseFloat(e.target.value))}
                value={adm ? adm : ""}
                id="adm"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <ThemedTextField
                fullWidth
                label="กระแสไฟ (โวล์ท)"
                onChange={(e) => setVolt(e.target.value)}
                value={volt ? volt : ""}
                id="kg"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="จำนวนชิ้นต่อรอบ"
                onChange={(e) => setRound(parseFloat(e.target.value))}
                value={round ? round : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="อบ"
                onChange={(e) => setOc(parseFloat(e.target.value))}
                value={oc ? oc : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="ไม้จิ๊ก"
                onChange={(e) => setJig(e.target.value)}
                value={jig ? jig : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="เวลาชุบ (นาที)"
                onChange={(e) => setMin(e.target.value)}
                value={min ? min : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="packing"
                onChange={(e) => setPacking(e.target.value)}
                value={packing ? packing : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenUpdateEdpCoating(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const DeleteEdpCoating: React.FC<IDeleteEdpCoating> = (
  props: IDeleteEdpCoating
) => {
  const [deleteEdpCoating, { loading }] = useMutation(DELETE_EDP_COATING, {
    refetchQueries: [GET_EDP_COATING],
    awaitRefetchQueries: true
  })
  const { data } = useQuery(GET_EDP_COATING)

  const { openDeleteEdpCoating, setOpenDeleteEdpCoating } = props
  const [partNo, setPartNo] = useState<string>("")
  const [error, setError] = useState<boolean>(false)

  const zincBarrelList = data
    ? data?.masterEDP?.map(({ partNo }: { partNo: string }) => partNo)
    : []

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!partNo) {
      setError(true)
    }
    try {
      await deleteEdpCoating({
        variables: { partNo }
      })
      swal("Success", "Delete Successful", "success")
      setOpenDeleteEdpCoating(false)
    } catch (error) {
      swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openDeleteEdpCoating}
        onClose={() => setOpenDeleteEdpCoating(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Sarabun" }}>
          Delete Zinc Hanger
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} marginTop={2}>
            <Grid item sm={12} xs={12}>
              <Autocomplete
                id="part no"
                value={partNo ? partNo : ""}
                options={zincBarrelList}
                style={{ width: "100%" }}
                sx={{ width: 100 }}
                loading={!!zincBarrelList}
                onChange={(e, newValue) => setPartNo(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select partNo"
                    error={!partNo && error}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              borderRadius: "5px",
              marginLeft: "16px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenDeleteEdpCoating(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const EDPCoating: React.FC = () => {
  const { data } = useQuery(GET_EDP_COATING)
  const [search, setSearch] = useState<string>("")
  const [openAddEdpCoating, setOpenAddEdpCoating] = useState<boolean>(false)
  const [openDeleteEdpCoating, setOpenDeleteEdpCoating] =
    useState<boolean>(false)
  const [openUpdateEdpCoating, setOpenUpdateEdpCoating] =
    useState<boolean>(false)
  const partListSearch =
    data?.masterEDP?.map((part: IReportEDPCoating) => part.partNo) || []

  const theme = useTheme()

  let rows: IReportEDPCoating[] = []

  if (data) {
    rows = data.masterEDP
      .map((part: IReportEDPCoating, idx: number) => {
        return { ...part, item: idx + 1, spec: part.spec.replace("mm", "μm") }
      })
      .filter((part: IReportEDPCoating) => {
        if (search) return part.partNo === search
        else return part
      })
  }

  return (
    <ThemeProvider theme={theme}>
      <AddNewEdpCoating
        openAddEdpCoating={openAddEdpCoating}
        setOpenAddEdpCoating={setOpenAddEdpCoating}
      />
      <UpdateEdpCoating
        openUpdateEdpCoating={openUpdateEdpCoating}
        setOpenUpdateEdpCoating={setOpenUpdateEdpCoating}
      />
      <DeleteEdpCoating
        openDeleteEdpCoating={openDeleteEdpCoating}
        setOpenDeleteEdpCoating={setOpenDeleteEdpCoating}
      />
      <div className="none-display">
        <Grid container spacing={2} width={"98%"} padding={2}>
          <Grid item xs={3} sm={3}>
            <Autocomplete
              id="search"
              freeSolo
              value={search}
              options={partListSearch}
              style={{ width: "100%", marginLeft: "-15px" }}
              sx={{ width: 100 }}
              onChange={(e, newValue) => setSearch(newValue ? newValue : "")}
              renderInput={(params) => (
                <ThemedTextField
                  style={{ marginLeft: "15px" }}
                  {...params}
                  label="Search"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={9}
            sm={9}
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <ThemedButton
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenAddEdpCoating(true)}
              >
                ADD EDP Coating
              </ThemedButton>
            </Grid>
            <Grid item>
              <ThemedButton
                contrast
                variant="contained"
                startIcon={<EditIcon />}
                onClick={() => setOpenUpdateEdpCoating(true)}
              >
                Edit EDP Coating
              </ThemedButton>
            </Grid>
            <Grid item>
              <ThemedButton
                contrast
                color="error"
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => setOpenDeleteEdpCoating(true)}
              >
                DELETE EDP Coating
              </ThemedButton>
            </Grid>
            <Grid item>
              <ThemedButton
                variant="contained"
                endIcon={<PrintIcon />}
                onClick={() => window.print()}
              >
                Print
              </ThemedButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Table sx={{ zoom: "50%" }} className="bg-plating plating-absolute">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell colSpan={1}>
              <img src={miniLogo} alt="" width={"100px"} />
            </StyledTableCell>
            <StyledTableCell colSpan={25}>EDP Coating Plating</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.id} align={column.align}>
                {column.label}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            return (
              <StyledTableRow hover tabIndex={-1} key={i}>
                {columns.map((column) => {
                  const value = row[column.id]
                  return (
                    <StyledTableCell key={column.id} align={column.align}>
                      {column.id === "img" ? (
                        <img
                          src={getImagePng(`images/parts/${row.partNo}`)}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = bankImage
                          }}
                          width={80}
                          height={80}
                          alt=""
                        ></img>
                      ) : column.format && typeof value === "number" ? (
                        column.format(value)
                      ) : (
                        value
                      )}
                    </StyledTableCell>
                  )
                })}
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </ThemeProvider>
  )
}

export default EDPCoating
