import { useTheme } from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useRef } from "react"
import { ITargetKpi } from "../../Maintenance/component/MTTR"
import { monthNames } from "../../Maintenance/PreventiveMaintenancePlan"
import { IPress } from "../hook/useFetchData"

const total = (linePress: IPress[], i: number) => {
  const millisecond = 1000
  const second = 60
  const minute = 60
  if (linePress === undefined)
    return { dataOfMonth: 0, totalQuantityRepair: 0, arc: 0 }
  const dataOfMonth = linePress.filter(
    (e) => new Date(e?.startRepair || new Date()).getMonth() === i
  )
  if (dataOfMonth.length) {
    const totalQuantityRepair = dataOfMonth
      .map((data) => {
        const diff = Math.abs(
          Number(new Date(data?.completeDate || new Date())) -
            Number(new Date(data?.endWaitRepair || new Date()))
        )
        const actualTime = diff / millisecond / second / minute
        return actualTime
      })
      .reduce((prev: number, cur: number) => {
        return prev + cur
      }, 0)
    const arc = totalQuantityRepair / dataOfMonth.length

    return {
      dataOfMonth: totalQuantityRepair * 60,
      totalQuantityRepair: dataOfMonth.length,
      arc: Math.ceil(arc * 60)
    }
  } else {
    return { dataOfMonth: 0, totalQuantityRepair: 0, arc: 0 }
  }
}

export const MTTRColumnDashboard = (props: {
  chart?: HighchartsReact.Props
  lineData?: IPress[]
  setShowChart?: React.Dispatch<React.SetStateAction<boolean>>
  target: ITargetKpi
}): JSX.Element => {
  const { lineData, target } = props
  if (!lineData?.length) return <div>Loading...</div>
  const actual = monthNames.map((e: string, i: number) => {
    return total(lineData, i).arc
  })

  const theme = useTheme()
  const columnOptions: Highcharts.Options = {
    chart: {
      type: "column",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "ระยะเวลาเฉลี่ยในการซ่อมแต่ละครั้ง(MTTR)",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },

    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    },
    xAxis: {
      categories: monthNames,
      labels: {
        x: -10,
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "10px"
        }
      }
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "Total",
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      }
    },
    series: [
      {
        type: "column",
        name: "Target",
        data: monthNames.map(() => Number(target?.target))
      },
      {
        type: "column",
        name: "Actual",
        data: actual
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  return (
    <div style={{ display: "inline-block" }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={columnOptions}
        ref={chartComponentRef}
        {...props.chart}
      />
    </div>
  )
}
