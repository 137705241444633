import { gql } from "@apollo/client"

export const GET_APPROVE_EDIT_SCANNER = gql`
  query GetApproveEdit(
    $tableName: String!
    $actionDate: String
    $actionDateTo: String
  ) {
    getApproveEdit(
      tableName: $tableName
      actionDate: $actionDate
      actionDateTo: $actionDateTo
    ) {
      id
      tableName
      statusApprove
      approver
      approvalDate
      requestDate
      editJson
      editBy
    }
  }
`
