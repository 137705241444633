import { LocalizationProvider, DatePicker } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  useTheme
} from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import moment from "moment"
import { useRef } from "react"
import DateAdapter from "@mui/lab/AdapterMoment"
import { IBreakdownMachine } from "../hook/useFetchData"

interface IDataBreakDown {
  machine: string
  totalTime: number
  problemName: string
}
export const ChartBreakDownMonthlyMonthly = (props: {
  chart?: HighchartsReact.Props
  breakDownMachine: IBreakdownMachine[] | undefined
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const { breakDownMachine } = props
  if (!breakDownMachine) return <>Loading...</>
  let machineList: string[] = []

  const columnData: {
    "DIE Breakdown": number[]
    "Wait QC First Set Up": number[]
    "Machine Breakdown": number[]
    PM: number[]
    Other: number[]
  } = {
    "DIE Breakdown": [],
    "Wait QC First Set Up": [],
    "Machine Breakdown": [],
    PM: [],
    Other: []
  }

  if (breakDownMachine?.length) {
    const rawDataBreakDraw = breakDownMachine
    machineList = Array.from(new Set(rawDataBreakDraw.map((e) => e.machine)))
    machineList = machineList.slice(0, 10)
    machineList?.forEach((machine) => {
      columnData.Other.push(
        Math.round(
          rawDataBreakDraw.find(
            (entry) =>
              entry.machine === machine && entry.problemName === "other"
          )?.totalTime || 0
        )
      )
      columnData["DIE Breakdown"].push(
        Math.round(
          rawDataBreakDraw.find(
            (entry) =>
              entry.machine === machine && entry.problemName === "DIE Breakdown"
          )?.totalTime || 0
        )
      )
      columnData["Machine Breakdown"].push(
        Math.round(
          rawDataBreakDraw.find(
            (entry: IDataBreakDown) =>
              entry.machine === machine &&
              entry.problemName === "Machine Breakdown"
          )?.totalTime || 0
        )
      )
      columnData.PM.push(
        Math.round(
          rawDataBreakDraw.find(
            (entry: IDataBreakDown) =>
              entry.machine === machine && entry.problemName === "PM"
          )?.totalTime || 0
        )
      )
      columnData["Wait QC First Set Up"].push(
        Math.round(
          rawDataBreakDraw.find(
            (entry: IDataBreakDown) =>
              entry.machine === machine &&
              entry.problemName === "Wait QC First Set Up"
          )?.totalTime || 0
        )
      )
    })
  }
  const theme = useTheme()
  const options: Highcharts.Options = {
    chart: {
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "Break Down Monthly",
      align: "center",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    xAxis: {
      categories: machineList,
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "8px"
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: "Hour"
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          textOutline: "none"
        }
      }
    },
    legend: {
      align: "right",
      x: 10,
      verticalAlign: "top",
      y: 40,
      floating: true,
      backgroundColor: theme.palette.secondary.main,
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
      itemStyle: {
        fontSize: "10px",
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true
        }
      }
    },
    series: [
      {
        name: "Wait QC First Set Up",
        type: "column",
        data: columnData["Wait QC First Set Up"]
      },
      {
        name: "Machine Breakdown",
        type: "column",
        data: columnData["Machine Breakdown"]
      },
      {
        name: "DIE Breakdown",
        type: "column",
        color: "#FF0000",
        data: columnData["DIE Breakdown"]
      },
      {
        name: "PM",
        type: "column",
        data: columnData.PM
      },
      {
        name: "Other",
        type: "column",
        color: "#FFFB00",
        data: columnData.Other
      }
    ],
    exporting: {
      enabled: false
    }
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props.chart}
      />
    </>
  )
}

interface IBreakDownMonthlyMonthly {
  breakDownMachine: IBreakdownMachine[] | undefined
  dialogOpen: boolean
  setDialogOpen: (open: boolean) => void
  dateFrom: string
  dateTo: string
  setDateFrom: (date: string) => void
  setDateTo: (date: string) => void
}

const BreakDownMonthlyMonthly = (
  props: IBreakDownMonthlyMonthly
): JSX.Element => {
  const {
    dialogOpen,
    setDialogOpen,
    breakDownMachine,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo
  } = props

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        >
          Break Down Monthly
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12} justifyContent={"center"} margin={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="Date From"
                  // views={["year", "month"]}
                  value={dateFrom}
                  onChange={(newSelectDate) => {
                    setDateFrom(moment(newSelectDate).format("YYYY-MM-DD"))
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      value={moment(dateFrom).format("DD-MM-YYYY")}
                      inputProps={{
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={3} xs={12} justifyContent={"center"} margin={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="Date To"
                  // views={["year", "month"]}
                  value={dateTo}
                  onChange={(newSelectDate) => {
                    setDateTo(moment(newSelectDate).format("YYYY-MM-DD"))
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      value={moment(dateTo).format("DD-MM-YYYY")}
                      inputProps={{
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={12} xs={12} justifyContent="center">
              <ChartBreakDownMonthlyMonthly
                breakDownMachine={breakDownMachine}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BreakDownMonthlyMonthly
