import { gql } from "@apollo/client"

export const GET_PART_MATSTOCK = gql`
  query PartMatStock($partNo: String!) {
    partMatStock(partNo: $partNo) {
      invoice
      factory
      totalQuantity
    }
  }
`

export const GET_PART_PROCESSING = gql`
  query Partsprocessings($partNo: String) {
    partsprocessings(partNo: $partNo) {
      processOrder
      stepProcess
      partNo
      process
      machine
      line
      jph
      minStroke
      operator
    }
  }
`

export const GET_WIP_STOCK = gql`
  query WipStocks($partNo: String, $actionDate: String, $toActionDate: String) {
    wipStocks(
      partNo: $partNo
      actionDate: $actionDate
      toActionDate: $toActionDate
    ) {
      indexNo
      matNo
      partNo
      jobOrder
      pdLotNo
      inOrOut
      actionDate
      quantity
      factory
      packingBy
      remarks
      isNG
      perBox
      dateScan
      updatedBy
      updatedDate
    }
  }
`

export const GET_WIP_CARD = gql`
  query Wipcard($actionDate: String, $jobOrder: String, $partNo: String) {
    wipcard(actionDate: $actionDate, jobOrder: $jobOrder, partNo: $partNo) {
      actionDate
      arcweld
      cost
      customer
      dateScan
      factory
      inOrOut
      indexNo
      isNG
      jobOrder
      leadTime
      matNo
      model
      packingBy
      partNo
      partName
      pdLotNo
      perBox
      plate
      quantity
      remarks
      stamp
      weld
    }
  }
`

export const GET_MATSTOCK = gql`
  query MatStocks($matNo: String) {
    matStocks(matNo: $matNo) {
      indexNo
      matNo
      inOrOut
      actionDate
      quantity
      factory
      packingBy
      jobOrder
      remarks
      isNG
      invoiceNo
      deliveryDate
      dateScan
      smQty
      supplier
      updatedBy
      updatedDate
      pdLotNo
    }
  }
`

export const GET_MATERIAL_TAG = gql`
  query MatTag(
    $matNo: String
    $invoiceNo: String
    $factory: String
    $receiveDate: String
  ) {
    matTag(
      matNo: $matNo
      invoiceNo: $invoiceNo
      factory: $factory
      receiveDate: $receiveDate
    ) {
      matNo
      spec
      totalPack
      fraction
      model
      customer
      sizeH
      sizeW
      sizeL
      quantity
      receiveBy
      receiveDate
      invoiceNo
      boxNo
      totalPackStock
      boxNoInStock
    }
  }
`
export const GET_INVENTORY_MATERIAL_REPORT = gql`
  query InventoryMaterialReport($date: String) {
    inventoryMaterialReport(date: $date) {
      matNo
      balanceMaterial
      maxStock
      saleOrder
      remarks
      factory
      minStock
    }
  }
`
export const GET_LIST_INVOICE = gql`
  query Query($date: String, $factory: String, $partNo: String) {
    GetListInvoiceNo(date: $date, factory: $factory, partNo: $partNo)
  }
`

export const GET_STOCK_BY_DATE = gql`
  query GetMatStocksByDate($matNo: String, $dateFrom: String, $dateTo: String) {
    getMatStocksByDate(matNo: $matNo, dateFrom: $dateFrom, dateTo: $dateTo) {
      indexNo
      matNo
      inOrOut
      actionDate
      quantity
      factory
      packingBy
      jobOrder
      remarks
      isNG
      invoiceNo
      deliveryDate
      dateScan
      smQty
      supplier
    }
  }
`
