import { gql } from "@apollo/client"

export const CREATE_LOCATION_SCAN = gql`
  mutation CreatLocationScan($dataLocation: dataInPutLocationScan) {
    creatLocationScan(dataLocation: $dataLocation) {
      status
    }
  }
`
export const CREATE_WH_TAG = gql`
  mutation CreateWhTag($data: dataInputWhTag!) {
    createWhTag(data: $data) {
      status
    }
  }
`
export const DELETE_WH_LOCATION = gql`
  mutation DeleteWhLocation($data: DataIInputDeleteWHLocation) {
    deleteWhLocation(data: $data) {
      status
    }
  }
`

export const CREATE_WAREHOUSE_TRANSPORT = gql`
  mutation CreateWHTransport($data: DataIInputCreateWhTransport) {
    createWHTransport(data: $data) {
      status
    }
  }
`

export const CREATE_FG = gql`
  mutation CreateFGIn($data: IDataFGIn) {
    createFGIn(data: $data) {
      status
    }
  }
`

export const CREATE_FG_OUT = gql`
  mutation CreateFGOut($dataFgOut: IDataFGOut) {
    createFGOut(dataFGOut: $dataFgOut) {
      status
    }
  }
`
export const CREATE_QC_SCAN = gql`
  mutation SumQuantityQcScan($dataScan: IDataFGIn) {
    sumQuantityQcScan(dataScan: $dataScan)
  }
`

export const CREATE_MULTIPLE_FG_OUT = gql`
  mutation FgOutMultiple($dataFgOutMultiple: [IDataFGOut]) {
    fgOutMultiple(dataFgOutMultiple: $dataFgOutMultiple)
  }
`
export const EDIT_FG = gql`
  mutation EditFG($dataDeleteFg: IFGDataReport) {
    editFG(dataDeleteFG: $dataDeleteFg)
  }
`
