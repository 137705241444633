import { useQuery } from "@apollo/client"
import { Autocomplete, TextFieldProps } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { GET_CUSTOMERS } from "../Query/MasterQuery"
import ThemedTextField from "./ThemedComponents/ThemedTextField"

type IProps = TextFieldProps & {
  setCustomer: Dispatch<SetStateAction<string>>
  customer: string
  required?: boolean
  error?: boolean
}

const AutocompleteCustomer = (props: IProps): JSX.Element => {
  const { customer, setCustomer, required, error } = props
  const { data, loading } = useQuery(GET_CUSTOMERS)
  let customerList = []
  if (data) {
    customerList = data.customers.map(
      ({ customer }: { customer: string }) => customer
    )
  }

  return (
    <Autocomplete
      value={customer}
      fullWidth
      onChange={(event, selectedCustomer) => {
        setCustomer(selectedCustomer as string)
      }}
      options={customerList}
      loading={loading}
      renderInput={(params) => (
        <ThemedTextField
          {...params}
          label="Customer"
          required={required}
          error={error ? error : false}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  )
}
export default AutocompleteCustomer
