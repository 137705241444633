import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AddIcon from "@mui/icons-material/Add"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import Input from "../../common/Resources/Input"
import TablePagination from "@mui/material/TablePagination"
import { styled } from "@mui/material/styles"
import MenuItem from "@mui/material/MenuItem"
import Paper from "@mui/material/Paper"
import {
  GET_CUSTOMERS,
  GET_MATERIALS,
  GET_PARTS
} from "../../common/Query/MasterQuery"
import {
  useQuery,
  useMutation,
  QueryResult,
  OperationVariables
} from "@apollo/client"
import React, { useState, ReactElement, useMemo } from "react"
import "../../css/Master/Parts.css"
import {
  ADD_PARTS,
  UPDATE_PART,
  UPLOAD_PART_IMAGE
} from "../../common/Mutation/MasterMutation"
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  useTheme
} from "@mui/material"
import "../../css/Master/Material.css"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import { bankImage, getImagePng } from "../../common/utilities"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { Edit } from "@mui/icons-material"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { CSVLink } from "react-csv"

export interface IPart {
  partNo: string
  partName: string
  customer: string
  model: string
  cost: number
  leadTime: number
  stamp: number
  weld: number
  arcweld: number
  plate: number
  boxStd: number
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.secondary.contrastText,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.black
  },

  "td, th": {
    border: 0
  }
}))

export const GetPartsList = (): ReactElement => {
  const theme = useTheme()

  const { loading, error, data } = useQuery(GET_PARTS)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [search, setSearch] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [openAddPart, setOpenAddPart] = useState<boolean>(false)
  const [openUpdatePart, setOpenUpdatePart] = useState<boolean>(false)
  const [openAddImage, setOpenAddImage] = useState<boolean>(false)

  if (loading)
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ padding: "10px" }}
      >
        <CircularProgress />
      </Grid>
    )
  if (error) return <p>Error: {error}</p>
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows: number =
    rowsPerPage - Math.min(rowsPerPage, data.parts.length - page * rowsPerPage)

  const filterPartsAccordingToTextField = data.parts.filter((part: IPart) => {
    const matchesSearch = search ? part.partNo === search : true
    const matchesCustomer = customer ? part.customer === customer : true
    return matchesSearch && matchesCustomer
  })

  const partListSearch: string[] = Array.from(
    new Set(
      filterPartsAccordingToTextField.map((e: IPart) => {
        return e.partNo ? e.partNo : ""
      })
    )
  )

  const customerList: string[] = Array.from(
    new Set(
      filterPartsAccordingToTextField.map((e: IPart) => {
        return e.customer ? e.customer : ""
      })
    )
  )

  const partList = filterPartsAccordingToTextField
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((part: IPart) => {
      return (
        <StyledTableRow key={part.partNo}>
          <StyledTableCell component="th" scope="row">
            {part.partNo}
          </StyledTableCell>
          <StyledTableCell align="left">{part.partName}</StyledTableCell>
          <StyledTableCell align="left">
            <img
              src={getImagePng(`images/parts/${part.partNo}`)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = bankImage
              }}
              width={40}
              height={40}
              alt=""
            ></img>
          </StyledTableCell>
          <StyledTableCell align="left">{part.customer}</StyledTableCell>
          <StyledTableCell align="left">{part.model}</StyledTableCell>
          <StyledTableCell align="left">{part.cost}</StyledTableCell>
          <StyledTableCell align="left">{part.leadTime}</StyledTableCell>
          <StyledTableCell
            align="left"
            aria-labelledby={`${part.partNo}-stamp`}
          >
            {part.stamp}
          </StyledTableCell>
          <StyledTableCell align="left" aria-labelledby={`${part.partNo}-weld`}>
            {part.weld}
          </StyledTableCell>
          <StyledTableCell
            align="left"
            aria-labelledby={`${part.partNo}-arcweld`}
          >
            {part.arcweld}
          </StyledTableCell>
          <StyledTableCell
            align="left"
            aria-labelledby={`${part.partNo}-plate`}
          >
            {part.plate}
          </StyledTableCell>
          <StyledTableCell align="left">{part.boxStd}</StyledTableCell>
        </StyledTableRow>
      )
    })
  return (
    <>
      <AddNewParts setOpenAddPart={setOpenAddPart} openAddPart={openAddPart} />
      <UpdateParts
        setOpenUpdatePart={setOpenUpdatePart}
        openUpdatePart={openUpdatePart}
      />
      <AddNewImage
        setOpenAddImage={setOpenAddImage}
        openAddImage={openAddImage}
      />
      <Grid container spacing={2} width={"98%"} padding={2}>
        <Grid item xs={2} sm={2}>
          <Autocomplete
            id="customer"
            freeSolo
            value={customer}
            options={customerList}
            style={{ width: "100%", marginLeft: "-15px" }}
            sx={{ width: 100 }}
            onChange={(e, newValue) => {
              setPage(0)
              setCustomer(newValue ? newValue : "")
            }}
            renderInput={(params) => (
              <ThemedTextField
                style={{ marginLeft: "15px" }}
                {...params}
                label="Customer"
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          <Autocomplete
            id="search"
            freeSolo
            value={search}
            options={partListSearch}
            style={{ width: "100%", marginLeft: "-15px" }}
            sx={{ width: 100 }}
            onChange={(e, newValue) => {
              setPage(0)
              setSearch(newValue ? newValue : "")
            }}
            renderInput={(params) => (
              <ThemedTextField
                style={{ marginLeft: "15px" }}
                {...params}
                label="Part No."
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sm={8}
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <CSVLink
              data={filterPartsAccordingToTextField.map((val: IPart) => {
                return {
                  "Part No": val.partNo,
                  "Part Name": val.partName,
                  Customer: val.customer,
                  Model: val.model,
                  Cost: val.cost,
                  "Lead Time": val.leadTime,
                  Stamp: val.stamp,
                  Weld: val.weld,
                  Arcweld: val.arcweld,
                  Plate: val.plate,
                  BoxStd: val.boxStd
                }
              })}
              filename={`Parts`}
            >
              <ThemedButton
                variant="contained"
                style={{
                  marginLeft: "15px",
                  backgroundColor:
                    theme.palette.mode === "light" ? "yellowgreen" : "darkgreen"
                }}
              >
                Download CSV
              </ThemedButton>
            </CSVLink>
          </Grid>
          <Grid item>
            <ThemedButton
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenAddPart(true)}
            >
              ADD NEW PART
            </ThemedButton>
          </Grid>
          <Grid item>
            <ThemedButton
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenUpdatePart(true)}
            >
              UPDATE PART
            </ThemedButton>
          </Grid>
          <Grid item>
            <ThemedButton
              contrast
              variant="contained"
              startIcon={<Edit />}
              onClick={() => setOpenAddImage(true)}
            >
              ADD IMAGE
            </ThemedButton>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Part No</StyledTableCell>
              <StyledTableCell>Part Name</StyledTableCell>
              <StyledTableCell>Image Part</StyledTableCell>
              <StyledTableCell>Customers</StyledTableCell>
              <StyledTableCell>Model</StyledTableCell>
              <StyledTableCell>Cost</StyledTableCell>
              <StyledTableCell>Lead Time</StyledTableCell>
              <StyledTableCell>Stamp</StyledTableCell>
              <StyledTableCell>Weld</StyledTableCell>
              <StyledTableCell>Arcweld</StyledTableCell>
              <StyledTableCell>Plate</StyledTableCell>
              <StyledTableCell>Box Standard</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partList}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filterPartsAccordingToTextField.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ backgroundColor: theme.palette.warning.light }}
        />
      </TableContainer>
    </>
  )
}
type TDataCustomerQuery = {
  customers: [{ customer: string }]
}
export const makeCustomerList = (
  customerQuery: QueryResult<TDataCustomerQuery, OperationVariables>
): string[] => {
  const customerList = customerQuery.loading
    ? []
    : customerQuery?.data?.customers?.map(
      ({ customer }: { customer: string }) => customer
    ) || []
  return customerList
}
type TDataMaterialQuery = {
  materials: [{ matNo: string }]
}
export const makeMaterialList = (
  materialQuery: QueryResult<TDataMaterialQuery, OperationVariables>
): string[] => {
  const materialList = materialQuery.loading
    ? []
    : materialQuery?.data?.materials?.map(
      ({ matNo }: { matNo: string }) => matNo
    ) || []

  return materialList
}

interface IPropsAddPart {
  setOpenAddPart: (boolean: boolean) => void
  openAddPart: boolean
}

export const AddNewParts: React.FC<IPropsAddPart> = (
  props: IPropsAddPart
): ReactElement => {
  const { setOpenAddPart, openAddPart } = props
  const customerQuery = useQuery(GET_CUSTOMERS)
  const customerList = useMemo(
    () => makeCustomerList(customerQuery),
    [customerQuery]
  )

  const materialQuery = useQuery(GET_MATERIALS)
  const materialList = useMemo(
    () => makeMaterialList(materialQuery),
    [materialQuery]
  )

  const [addParts, { loading }] = useMutation(ADD_PARTS, {
    refetchQueries: [GET_CUSTOMERS, GET_MATERIALS],
    awaitRefetchQueries: true
  })
  const [partNo, setPartNo] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [model, setModel] = useState<string>("")
  const [material, setMaterial] = useState<string>("")
  const [cost, setCost] = useState<number>(0.0)
  const [leadTime, setLeadTime] = useState<number>(0)
  const [stamp, setStamp] = useState<number>(0)
  const [weld, setWeld] = useState<number>(0)
  const [arcweld, setArcweld] = useState<number>(0)
  const [plate, setPlate] = useState<number>(0)
  const [boxStd, setBoxStd] = useState<number>(0)
  const [error, setError] = useState<boolean>(false)
  const isComplete = () => {
    return (
      partNo &&
      partName &&
      customer &&
      model &&
      material &&
      cost &&
      leadTime &&
      boxStd
    )
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    if (
      !partNo &&
      !partName &&
      !customer &&
      !model &&
      !material &&
      !cost &&
      !leadTime &&
      !boxStd
    ) {
      setError(true)
      return swal("Warning", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
    if (!stamp && !weld && !arcweld && !plate) {
      setError(true)
      return swal("Warning", "กรุณาเลือกอย่างใดอย่างหนึ่ง", "warning")
    }
    e.preventDefault()
    if (!isComplete()) {
      setError(true)
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }

    try {
      await addParts({
        variables: {
          partNo,
          partName,
          customer: customer || null,
          model,
          material,
          cost,
          leadTime,
          stamp,
          weld,
          arcweld,
          plate,
          boxStd
        }
      })
      swal("Success", "Add Part Successful", "success")
      setOpenAddPart(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openAddPart}
        onClose={() => setOpenAddPart(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Add New Part
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item xs={12} sm={12}>
              <Input
                fullWidth
                value={partNo}
                error={!partNo && error}
                text={"Part No."}
                inputType="string"
                setValue={(event) => setPartNo(event.target.value)}
                sm={12}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                value={partName}
                error={!partName && error}
                label={"Part Name"}
                onChange={(event) => setPartName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                defaultValue={""}
                disableClearable
                disablePortal
                onChange={(event, selectedCustomer) => {
                  setCustomer(selectedCustomer as string)
                }}
                loading={!!customerList}
                options={customerList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Customer"
                    value={customer}
                    error={!customer && error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                fullWidth
                defaultValue={""}
                disableClearable
                disablePortal
                onChange={(event, selectedMaterial) => {
                  setMaterial(selectedMaterial as string)
                }}
                loading={!!materialList}
                options={materialList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Material"
                    value={material}
                    error={!material && error}
                  />
                )}
              />
            </Grid>
            <Input
              fullWidth
              value={model}
              error={!model && error}
              text={"Model"}
              inputType="string"
              setValue={(event) => setModel(event.target.value)}
              sm={4}
            />

            <Grid item xs={12} sm={4}>
              <ThemedTextField
                label="Cost"
                value={cost}
                error={!cost && error}
                fullWidth
                type="number"
                inputProps={{
                  minLength: 0,
                  step: "0.01"
                }}
                onChange={(e) => setCost(parseFloat(e.target.value))}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                label="Lead Time"
                fullWidth
                value={leadTime}
                error={!leadTime && error}
                type="number"
                onChange={(e) => setLeadTime(+e.target.value)}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                id="master-stamp"
                select
                value={stamp ? "YES" : "NO"}
                label="Stamp"
                fullWidth
                onChange={(e) => setStamp(e.target.value === "YES" ? 1 : 0)}
              >
                <MenuItem key="YES" value={"YES"}>
                  {"YES"}
                </MenuItem>
                <MenuItem key="NO" value={"NO"}>
                  {"NO"}
                </MenuItem>
              </ThemedTextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                id="master-weld"
                select
                value={weld ? "YES" : "NO"}
                label="Weld"
                fullWidth
                onChange={(e) => setWeld(e.target.value === "YES" ? 1 : 0)}
              >
                <MenuItem key="YES" value={"YES"}>
                  {"YES"}
                </MenuItem>
                <MenuItem key="NO" value={"NO"}>
                  {"NO"}
                </MenuItem>
              </ThemedTextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                id="master-arc-weld"
                select
                value={arcweld ? "YES" : "NO"}
                label="Arcweld"
                fullWidth
                onChange={(e) => setArcweld(e.target.value === "YES" ? 1 : 0)}
              >
                <MenuItem key="YES" value={"YES"}>
                  {"YES"}
                </MenuItem>
                <MenuItem key="NO" value={"NO"}>
                  {"NO"}
                </MenuItem>
              </ThemedTextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                id="master-plate"
                select
                value={plate ? "YES" : "NO"}
                label="Plate"
                fullWidth
                onChange={(e) => setPlate(e.target.value === "YES" ? 1 : 0)}
              >
                <MenuItem key="YES" value={"YES"}>
                  {"YES"}
                </MenuItem>
                <MenuItem key="NO" value={"NO"}>
                  {"NO"}
                </MenuItem>
              </ThemedTextField>
            </Grid>
            <Grid item xs={12}>
              <ThemedTextField
                label="Box Standard"
                fullWidth
                value={boxStd}
                error={!boxStd && error}
                type="number"
                onChange={(e) => setBoxStd(+e.target.value)}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenAddPart(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

interface IPropsUpdatePart {
  setOpenUpdatePart: (boolean: boolean) => void
  openUpdatePart: boolean
}

const UpdateParts: React.FC<IPropsUpdatePart> = (
  props: IPropsUpdatePart
): ReactElement => {
  const { setOpenUpdatePart, openUpdatePart } = props
  const customerQuery = useQuery(GET_CUSTOMERS)
  const customerList = useMemo(
    () => makeCustomerList(customerQuery),
    [customerQuery]
  )

  const [updateParts, { loading }] = useMutation(UPDATE_PART, {
    refetchQueries: [GET_PARTS],
    awaitRefetchQueries: true
  })
  const [partNo, setPartNo] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [model, setModel] = useState<string>("")
  const [cost, setCost] = useState<number>(0.0)
  const [leadTime, setLeadTime] = useState<number>(0)
  const [stamp, setStamp] = useState<number>(0)
  const [weld, setWeld] = useState<number>(0)
  const [arcweld, setArcweld] = useState<number>(0)
  const [plate, setPlate] = useState<number>(0)
  const [boxStd, setBoxStd] = useState<number>(0)
  const [error, setError] = useState<boolean>(false)

  const { data } = useQuery(GET_PARTS)

  useMemo(() => {
    if (partNo && data?.parts) {
      const part: IPart = data?.parts.find(
        (part: { partNo: string }) => part.partNo === partNo
      )
      if (part) {
        setCustomer(part.customer)
        setPartName(part.partName)
        setModel(part.model)
        setCost(part.cost)
        setLeadTime(part.leadTime)
        setStamp(part.stamp)
        setWeld(part.weld)
        setArcweld(part.arcweld)
        setPlate(part.plate)
        setBoxStd(part.boxStd)
      }
    }
  }, [partNo])

  const isComplete = () => {
    return partNo && partName && customer && model && cost && leadTime && boxStd
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    if (!stamp && !weld && !arcweld && !plate) {
      setError(true)
      return swal("Warning", "กรุณาเลือกอย่างใดอย่างหนึ่ง", "warning")
    }
    e.preventDefault()
    if (!isComplete()) {
      setError(true)
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }

    try {
      await updateParts({
        variables: {
          partNo,
          partName,
          customer: customer || null,
          model,
          cost,
          leadTime,
          stamp,
          weld,
          arcweld,
          plate,
          boxStd
        }
      })
      swal("Success", "Update Part Successful", "success")
      setOpenUpdatePart(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openUpdatePart}
        onClose={() => setOpenUpdatePart(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Update Part
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item xs={12} sm={12}>
              <AutocompletePartNo setPartNo={setPartNo} partNo={partNo} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                value={partName}
                error={!partName && error}
                label={"Part Name"}
                onChange={(event) => setPartName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                defaultValue={""}
                disableClearable
                disablePortal
                value={customer}
                onChange={(event, selectedCustomer) => {
                  setCustomer(selectedCustomer as string)
                }}
                loading={!!customerList}
                options={customerList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Customer"
                    value={customer}
                    error={!customer && error}
                  />
                )}
              />
            </Grid>

            <Input
              fullWidth
              value={model}
              error={!model && error}
              text={"Model"}
              inputType="string"
              setValue={(event) => setModel(event.target.value)}
              sm={4}
            />

            <Grid item xs={12} sm={4}>
              <ThemedTextField
                label="Cost"
                value={cost}
                error={!cost && error}
                fullWidth
                type="number"
                inputProps={{
                  minLength: 0,
                  step: "0.01"
                }}
                onChange={(e) => setCost(parseFloat(e.target.value))}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <ThemedTextField
                label="Lead Time"
                fullWidth
                value={leadTime}
                error={!leadTime && error}
                type="number"
                onChange={(e) => setLeadTime(+e.target.value)}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemedTextField
                id="master-stamp"
                select
                value={stamp ? "YES" : "NO"}
                label="Stamp"
                fullWidth
                onChange={(e) => setStamp(e.target.value === "YES" ? 1 : 0)}
              >
                <MenuItem key="YES" value={"YES"}>
                  {"YES"}
                </MenuItem>
                <MenuItem key="NO" value={"NO"}>
                  {"NO"}
                </MenuItem>
              </ThemedTextField>
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemedTextField
                id="master-weld"
                select
                value={weld ? "YES" : "NO"}
                label="Weld"
                fullWidth
                onChange={(e) => setWeld(e.target.value === "YES" ? 1 : 0)}
              >
                <MenuItem key="YES" value={"YES"}>
                  {"YES"}
                </MenuItem>
                <MenuItem key="NO" value={"NO"}>
                  {"NO"}
                </MenuItem>
              </ThemedTextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemedTextField
                id="master-arc-weld"
                select
                value={arcweld ? "YES" : "NO"}
                label="Arcweld"
                fullWidth
                onChange={(e) => setArcweld(e.target.value === "YES" ? 1 : 0)}
              >
                <MenuItem key="YES" value={"YES"}>
                  {"YES"}
                </MenuItem>
                <MenuItem key="NO" value={"NO"}>
                  {"NO"}
                </MenuItem>
              </ThemedTextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemedTextField
                id="master-plate"
                select
                value={plate ? "YES" : "NO"}
                label="Plate"
                fullWidth
                onChange={(e) => setPlate(e.target.value === "YES" ? 1 : 0)}
              >
                <MenuItem key="YES" value={"YES"}>
                  {"YES"}
                </MenuItem>
                <MenuItem key="NO" value={"NO"}>
                  {"NO"}
                </MenuItem>
              </ThemedTextField>
            </Grid>

            <Grid item xs={4} sm={4}>
              <ThemedTextField
                label="Box Standard"
                fullWidth
                value={boxStd}
                error={!boxStd && error}
                type="number"
                onChange={(e) => setBoxStd(+e.target.value)}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenUpdatePart(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

interface IPropsAddImage {
  setOpenAddImage: (boolean: boolean) => void
  openAddImage: boolean
}

const AddNewImage: React.FC<IPropsAddImage> = (props: IPropsAddImage) => {
  const { openAddImage, setOpenAddImage } = props
  const [error, setError] = useState<boolean>(false)

  const [partNo, setPartNo] = useState<string | null>("")
  const partData = useQuery(GET_PARTS)
  const [uploadImage, { loading }] = useMutation(UPLOAD_PART_IMAGE)
  const [file, setFile] = useState<File | null>(null)
  let partNoList: Array<string> = []
  partNoList = partData.data?.parts.map((e: { partNo: string }) => e.partNo)

  const getBase64 = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const baseURL = reader.result
        resolve(baseURL)
      }
    })
  }

  const handleUploadImage = async () => {
    if (!partNo) {
      setError(true)
      swal("Warning", "กรุณาใส่ข้อมูลให้ครบถ้วน", "warning")
      throw error
    }
    if (!file) {
      setError(true)
      swal("Warning", "กรุณาใส่รูปภาพ", "warning")
      throw error
    }

    try {
      const base64 = await getBase64(file)
      await uploadImage({
        variables: {
          partNo,
          file: base64
        }
      })
      swal("Success", "", "success")
    } catch (error) {
      if (!partNo && !file) {
        setError(true)
        swal("Error", "กรุณาใส่ข้อมูลให้ครบถ้วน", "error")
      }
      if (!file || !partNo) {
        setError(true)
        swal("Warning", "กรุณาใส่ข้อมูลให้ครบถ้วน", "warning")
      }
    }
  }
  return (
    <Dialog
      open={openAddImage}
      onClose={() => setOpenAddImage(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" className="text-material">
        Add New Image
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} margin={"2px"} width={"98%"}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="partNo"
              options={partNoList}
              onChange={(e, newValue) => setPartNo(newValue)}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  value={partNo}
                  label={"Part No"}
                  error={!partNo && error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
            <input
              type="file"
              accept=".png"
              onChange={(e) => {
                const file = e.currentTarget.files?.["0"];
                if (file && file.type !== "image/png") {
                  swal("Error", "Upload ใข้เฉพาะ png", "error")
                  setFile(null);
                } else {
                  setFile(e.currentTarget.files?.["0"]
                    ? e.currentTarget.files?.["0"]
                    : null);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} sx={{ mt: 2 }}>
            <ThemedLoadingButton
              loading={loading}
              variant="contained"
              onClick={handleUploadImage}
            >
              Upload
            </ThemedLoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ alignSelf: "start" }}>
        <ThemedButton
          contrast
          variant="text"
          onClick={() => setOpenAddImage(false)}
        >
          CANCEL
        </ThemedButton>
      </DialogActions>
    </Dialog>
  )
}

const pageTitle = "Parts"

export const Parts: React.FC = () => {
  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Master}
        menuItemName={Object.keys(menuItemList)[0]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard title="Parts" subTitle="About detail Parts" />
        <GetPartsList />
      </ThemedCard>
    </ThemeProvider>
  )
}

export default Parts
