import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TablePagination from "@mui/material/TablePagination"
import CircularProgress from "@mui/material/CircularProgress"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import Input from "../../common/Resources/Input"
import { useState } from "react"
import { ADD_PROCESS } from "../../common/Mutation/MasterMutation"
import { GET_PROCESS } from "../../common/Query/MasterQuery"
import { useQuery, useMutation } from "@apollo/client"
import AddIcon from "@mui/icons-material/Add"
import "../../css/Master/Process.css"
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

export interface IProcess {
  process: string
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.secondary.contrastText,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.black
  },

  "td, th": {
    border: 0
  }
}))

export const ProcessDataList: React.FC = () => {
  const theme = useTheme()
  const { loading, error, data } = useQuery(GET_PROCESS)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [search, setSearch] = useState<string>("")
  const [openAddProcess, setOpenAddProcess] = useState<boolean>(false)

  if (loading)
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ padding: "10px" }}
      >
        <CircularProgress />
      </Grid>
    )

  if (error) return <p>Error: {error}</p>
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows: number =
    rowsPerPage -
    Math.min(rowsPerPage, data.process.length - page * rowsPerPage)

  const processListSearch =
    data?.process?.map((process: IProcess) => process.process) || []

  const processList = data.process
    .filter((process: IProcess) => {
      if (search) return process.process === search
      else return process
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((process: IProcess) => {
      return (
        <StyledTableRow key={process.process}>
          <StyledTableCell align="left">{process.process}</StyledTableCell>
        </StyledTableRow>
      )
    })

  return (
    <>
      <AddNewProcess
        setOpenAddProcess={setOpenAddProcess}
        openAddProcess={openAddProcess}
      />
      <Grid container spacing={2} width={"98%"} padding={2}>
        <Grid item xs={5} sm={4}>
          <Autocomplete
            id="search-proccess"
            freeSolo
            value={search}
            options={processListSearch}
            style={{ width: "100%" }}
            sx={{ width: 100 }}
            onChange={(e, newValue) => {
              setPage(0)
              setSearch(newValue ? newValue : "")
            }}
            renderInput={(params) => (
              <ThemedTextField
                style={{ marginLeft: "15px" }}
                {...params}
                label="Process"
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sm={8}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <ThemedButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenAddProcess(true)}
          >
            ADD NEW PROCESS
          </ThemedButton>
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Process</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processList}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, data.process.length]}
          component="div"
          count={
            data.process.filter((process: IProcess) => {
              if (search) return process.process === search
              else return process
            }).length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ backgroundColor: theme.palette.warning.light }}
        />
      </TableContainer>
    </>
  )
}

interface IAddProcess {
  setOpenAddProcess: (boolean: boolean) => void
  openAddProcess: boolean
}

export const AddNewProcess: React.FC<IAddProcess> = (props: IAddProcess) => {
  const { setOpenAddProcess, openAddProcess } = props
  const [error, setError] = useState<boolean>(false)
  const [addProcess, { loading }] = useMutation(ADD_PROCESS, {
    refetchQueries: [GET_PROCESS],
    awaitRefetchQueries: true
  })
  const [process, setProcess] = useState<string>("")

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!process) {
      setError(true)
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
    try {
      await addProcess({
        variables: {
          process
        }
      })
      swal("Success", "Add Process Successful", "success")
      setOpenAddProcess(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openAddProcess}
        onClose={() => setOpenAddProcess(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Add New Process
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} marginTop={2}>
            <Input
              fullWidth
              value={process}
              error={!process && error}
              text={"Process"}
              setValue={(event) => {
                setProcess(event.target.value)
              }}
              sm={12}
              inputType="string"
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenAddProcess(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const pageTitle = "Process"

const Process: React.FC = () => {
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Master}
        menuItemName={Object.keys(menuItemList)[0]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard title="Process" subTitle="About detail Process" />
        <ProcessDataList />
      </ThemedCard>
    </ThemeProvider>
  )
}

export default Process
