import React, { SetStateAction, SyntheticEvent, useState } from "react"
import {
  Grid,
  Autocomplete,
  Switch,
  Box,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  useTheme
} from "@mui/material"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import AddIcon from "@mui/icons-material/Add"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import QrReader from "react-qr-reader"
import swal from "sweetalert"
import "../../css/Logistics/LogisticsCSS.css"
import { useMutation, useLazyQuery } from "@apollo/client"
import {
  GET_DELIVERY_SCAN_DATA,
  GET_DELIVERY_TAG
} from "../../common/Query/LogisticsQuery"
import { ADD_DELIVERY_SCAN } from "../../common/Mutation/LogisticsMutation"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { factoryList } from "../../common/utilities"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"

// interface DeliveryScanList {
//   dataInput: DeliveryScanDataType[]
// }

interface DeliveryTagDataType {
  partNo: string
  partName: string
  customer: string
  model: string
  quantity: number
  totalQuantity: number
  packingBy: string
  deliveryDate: string
  pdLotNo: string
  remarks: string
  boxCount: string
  factory: string
  period: string
  poCustomer: string
}

interface DeliveryScanDataType {
  partNo: string
  partName: string
  customer: string
  model: string
  quantity: number
  totalQuantity: number
  packingBy: string
  deliveryDate: string
  pdLotNo: string
  remarks: string
  boxCount: string
  factory: string
  period: string
  poCustomer: string
  status: string
  totalBox: string
  boxNo: number
  operator: string
}

interface DeliveryScanSubmitType {
  partNo: string
  deliveryDate: string
  pdLotNo: string
  totalBox: string
  boxNo: number
  factory: string
  period: string
  operator: string
  remarks: string
  poCustomer: string
}

const pageTitle = "Delivery Scan"

const DeliveryScan = (): JSX.Element => {
  const [streamMode, setStreamMode] = useState<boolean>(false)

  const [scanner, setScanner] = useState<string>("")
  const [pdLotNo, setPdLotNo] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [deliveryDate, setDeliveryDate] = useState<string>("")
  const [boxNo, setBoxNo] = useState<number>(0)
  const [factory, setFactory] = useState<string>("")
  const [period, setPeriod] = useState<string>("")
  const [recorder, setRecorder] = useState<string>("")
  const [remarks, setRemarks] = useState<string>("")
  const [poCustomer, setPoCustomer] = useState<string>("")

  const [totalBox, setTotalBox] = useState<number>(0)
  const [chPeriod, setChPeriod] = useState<string[]>([])
  const [chPo, setChPo] = useState<string>("")

  const [addDeliveryScan, { error: mutationError, loading }] =
    useMutation(ADD_DELIVERY_SCAN)

  const [getDeliveryTag, { data: getDeliveryTagData }] =
    useLazyQuery(GET_DELIVERY_TAG)

  const [getDeliveryScan, { data: getDeliveryScanData }] = useLazyQuery(
    GET_DELIVERY_SCAN_DATA
  )

  const handleDeliveryScanSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!isComplete()) {
      swal("Error", "กรุณาใส่ข้อมูลให้ครบถ้วน", "error")
      return
    }
    if (!checkOrder()) {
      return
    }

    const deliveryScanData: DeliveryScanSubmitType = {
      partNo,
      deliveryDate,
      pdLotNo,
      totalBox: totalBox.toString(),
      boxNo,
      factory,
      period,
      operator: recorder,
      remarks,
      poCustomer
    }

    try {
      const result = await addDeliveryScan({
        variables: { data: deliveryScanData }
      })
      if (result) swal("Success!", "ส่งข้อมูลสำเร็จ!", "success")
      handleReset()
    } catch (error) {
      if (mutationError) {
        swal("Error", `${mutationError}`, "error")
        return
      }
      swal("Error", `${error}`, "error")
    }
  }

  const handleError = (err: string) => {
    swal("Error", `${err}`, "error")
    console.error(err)
  }

  const handleScan = (data: SetStateAction<string> | null): void => {
    if (data) {
      setScanner(data)
    }
  }

  const checkOrder = (): boolean => {
    if (!getDeliveryScanData || !getDeliveryScanData.getDeliveryScan.length) {
      swal("error!", "ไม่สามารถหาข้อมูล Delivery Scan ได้!", "error")
      return false
    }
    if (!getDeliveryTagData || !getDeliveryTagData.getDeliveryTag.length) {
      swal("error!", "ไม่สามารถหาข้อมูล Delivery Tag ได้!", "error")
      return false
    }
    const checkData: DeliveryScanDataType[] =
      getDeliveryScanData.getDeliveryScan.filter(
        (entry: DeliveryScanDataType) =>
          entry.poCustomer === chPo &&
          entry.deliveryDate === deliveryDate &&
          entry.pdLotNo === pdLotNo &&
          entry.partNo === partNo &&
          entry.period === period
      )

    let max = 0
    if (checkData.length > 0) {
      max = checkData.reduce(
        (pre: DeliveryScanDataType, cur: DeliveryScanDataType) =>
          pre.boxNo > cur.boxNo ? pre : cur
      ).boxNo
    }
    if (chPeriod.length !== 1) {
      const checkPeriod: string[] = chPeriod.filter((entry) => entry === period)
      if (!checkPeriod.length || checkPeriod[0] !== period) {
        swal("warning", "คุณยิง Period ผิด", "warning")
        return false
      }
    }
    //I'm pretty worried returning false on these conditions would become a problem eventually
    if (max + 1 > boxNo) {
      swal("Warning!", `Box ${boxNo} ได้ถูกยิงเข้าไปแล้ว`, "warning")
      return false
    }
    if (max + 1 < boxNo && boxNo <= totalBox) {
      swal(
        "Warning!",
        `มีการยิงข้าม Box ไม่สามารถ Submit ได้ \nBox ที่หายไป: ${max + 1}`,
        "warning"
      )
      return false
    }
    if (boxNo > totalBox) {
      swal("Warning", "มีการยิงเกินจำนวน Box ไม่สามารถ Submit ได้", "warning")
      return false
    }

    const result = max + 1 === boxNo && max + 1 <= totalBox ? false : true

    return result
  }

  const isComplete = (): boolean => {
    return (
      scanner !== "" &&
      pdLotNo !== "" &&
      partNo !== "" &&
      deliveryDate !== "" &&
      totalBox.toString() !== "" &&
      boxNo.toString() !== "" &&
      recorder !== "" &&
      factory !== "" &&
      period !== ""
    )
  }

  const breakQRCode = async (e: SyntheticEvent) => {
    e.preventDefault()

    const newSplit = scanner.split("|")
    if (newSplit.length !== 6) {
      swal("warning!", "มีปัญหากับ format ของ QR Code!", "warning")
    }

    setPartNo(newSplit[0])
    setDeliveryDate(newSplit[1])
    setPdLotNo(newSplit[2])
    setPoCustomer(newSplit[3])
    setBoxNo(parseInt(newSplit[5]))

    getDeliveryTag({
      variables: {
        data: {
          deliveryDate: newSplit[1],
          partNo: newSplit[0],
          period,
          pdLotNo: newSplit[2],
          poCustomer: newSplit[3]
        }
      }
    })
      .then((response) => {
        const filteredDeliveryTag: DeliveryTagDataType[] =
          response.data.getDeliveryTag.filter(
            (entry: DeliveryTagDataType) =>
              entry.partNo === newSplit[0] &&
              entry.pdLotNo === newSplit[2] &&
              entry.deliveryDate === newSplit[1] &&
              entry.period === period &&
              entry.poCustomer === newSplit[3]
          )
        setTotalBox(parseInt(filteredDeliveryTag[0].boxCount))
        setChPo(filteredDeliveryTag[0].poCustomer)
        setChPeriod(filteredDeliveryTag.map((entry) => entry.period))
      })
      .catch((error: string) => {
        alert(error)
      })
    getDeliveryScan({
      variables: {
        dataInput: {
          deliveryDate: newSplit[1],
          partNo: newSplit[0],
          period,
          pdLotNo: newSplit[2],
          poCustomer: newSplit[3]
        }
      }
    })
  }

  const handleReset = () => {
    setScanner("")
    setPdLotNo("")
    setPartNo("")
    setDeliveryDate("")
    setBoxNo(0)
    setFactory("")
    setPeriod("")
    setRecorder("")
    setRemarks("")
    setPoCustomer("")
    setChPeriod([])
    setTotalBox(0)
    setChPo("")
  }

  const recorderList = ["รุ่งฤดี", "อุไรรัตน์"]
  const periodList = ["รอบปกติ", "รอบเสริม", "รอบพิเศษ"]
  const theme = useTheme()
  return (
    <div>
      <PageLabel
        menuItem={menuItemList.Logistics}
        menuItemName={Object.keys(menuItemList)[8]}
        menuPageName={pageTitle}
      />
      <ThemedCard style={{ marginTop: "25px", minWidth: "800px" }}>
        <Box component="form" onSubmit={breakQRCode} key="form">
          <Accordion
            expanded={streamMode}
            onChange={() => setStreamMode(!streamMode)}
            style={{
              marginBottom: "5px",
              marginTop: "10px"
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#ffffff", display: "none" }}
            />

            <AccordionDetails>
              {streamMode ? (
                <>
                  <Grid container justifyContent={"center"}>
                    <Grid
                      item
                      sm={3}
                      xs={12}
                      container
                      justifyContent={"center"}
                    >
                      <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </AccordionDetails>
          </Accordion>
          <PageLabelCard
            title="Delivery Scan"
            subTitle="detail about Delivery Scan"
          />
          <Grid container spacing={2} width={"98%"} padding={2}>
            <Grid item xs={12} sm={12}>
              <Switch
                checked={streamMode}
                onChange={(event) =>
                  setStreamMode(event.target.value ? !streamMode : streamMode)
                }
                name="gilad"
              />
              <label style={{ fontSize: "14px" }}>
                Active Camera{" "}
                <CameraAltIcon style={{ verticalAlign: "middle" }} />
              </label>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                value={scanner}
                key="scanner"
                aria-label="scanner"
                label="Scanner"
                InputLabelProps={{ shrink: true }}
                fullWidth
                onChange={(e) => setScanner(e.target.value)}
              />
            </Grid>
            <Grid item xs={0} sm={6} />
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                value={pdLotNo}
                InputLabelProps={{ shrink: true }}
                className="disable-field"
                key="pdLotNo"
                label="Pd Lot No"
                fullWidth
                disabled
                onChange={(e) => setPdLotNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                value={partNo}
                key="partNo"
                aria-label="partNo"
                className="disable-field"
                InputLabelProps={{ shrink: true }}
                label="Part No"
                fullWidth
                disabled
                onChange={(e) => setPartNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                value={deliveryDate}
                className="disable-field"
                InputLabelProps={{ shrink: true }}
                label="Delivery Date"
                key="deliveryDate"
                aria-label="deliveryDate"
                fullWidth
                disabled
                onChange={(e) => setDeliveryDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                value={boxNo}
                className="disable-field"
                InputLabelProps={{ shrink: true }}
                label="Box No."
                key="boxNo"
                aria-label="boxNo"
                fullWidth
                disabled
                onChange={(e) => setBoxNo(+e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                fullWidth
                value={factory}
                disablePortal
                key="factory"
                aria-label="factory"
                onChange={(event, selectedFactory) => {
                  setFactory(selectedFactory as string)
                }}
                options={factoryList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Factory"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                fullWidth
                value={period}
                disablePortal
                key="period"
                aria-label="period"
                onChange={(event, selectedPeriod) => {
                  setPeriod(selectedPeriod as string)
                }}
                options={periodList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Period"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                fullWidth
                value={recorder}
                key="recorder"
                aria-label="recorder"
                disablePortal
                onChange={(event, selectedRecorder) => {
                  setRecorder(selectedRecorder as string)
                }}
                options={recorderList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="ผู้บันทึก"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                value={remarks}
                key="remarks"
                aria-label="remarks"
                label="Remarks"
                InputLabelProps={{ shrink: true }}
                fullWidth
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedLoadingButton
                loading={loading}
                onClick={(event) => handleDeliveryScanSubmit(event)}
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main
                }}
                key="submitButton"
                aria-label="submitButton"
                endIcon={<AddIcon />}
              >
                Submit
              </ThemedLoadingButton>
              <ThemedButton
                onClick={handleReset}
                variant="outlined"
                key="resetButton"
                style={{
                  color: "red",
                  marginLeft: "15px",
                  borderColor: "red"
                }}
                aria-label="resetButton"
                endIcon={<RestartAltIcon />}
              >
                Reset
              </ThemedButton>
            </Grid>
          </Grid>
        </Box>
      </ThemedCard>
    </div>
  )
}

export default DeliveryScan
