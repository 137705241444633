import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  useTheme
} from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { UPDATE_MAINTENANCE_KPI } from "../../../common/Mutation/MaintenanceMutation"
import { GET_MAINTENANCE_KPI } from "../../../common/Query/MaintenanceQuery"
import { useMutation } from "@apollo/client"

interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>
  setTarget: Dispatch<SetStateAction<string>>
  open: boolean
  updateTarget: string
  line: string
  type: string
}

const DialogEditTargetLinePress = (props: IProps): JSX.Element => {
  const { setOpen, setTarget, open, updateTarget, line, type } = props
  const [updateKPI] = useMutation(UPDATE_MAINTENANCE_KPI, {
    refetchQueries: [GET_MAINTENANCE_KPI],
    awaitRefetchQueries: true
  })
  const handleEditTargetLinePress = async () => {
    await updateKPI({
      variables: {
        typeName: type,
        target: updateTarget,
        line: line
      }
    })
    setOpen(false)
  }
  const theme = useTheme()

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle style={{ textAlign: "center" }}>
        EDIT TARGET LINE {line.toUpperCase()}
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <TextField
          label="Target"
          InputLabelProps={{
            style: { marginTop: "5px" },
            shrink: true
          }}
          onChange={(e) => setTarget(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{
            mt: 1,
            backgroundColor: theme.palette.primary.main,
            marginLeft: "20px"
          }}
          onClick={handleEditTargetLinePress}
        >
          Submit
        </Button>
      </DialogContent>
    </Dialog>
  )
}
export default DialogEditTargetLinePress
