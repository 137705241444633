import { ChangeEvent, ReactElement, useState } from "react"
import { UploadFile, CreatePmwi } from "../../common/Mutation/DocumentMutation"
import { DownloadFile, Get_Document } from "../../common/Query/DocumentQuery"
import { useLazyQuery, useQuery } from "@apollo/client"
import { useMutation } from "@apollo/client"
import Grid from "@mui/material/Grid"
import "../../css/Document/Document.css"
import AddIcon from "@mui/icons-material/Add"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import {
  Box,
  FormControl,
  InputLabel,
  TablePagination,
  useTheme
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import UploadFileBox, {
  SelectedFileBox
} from "../../common/Resources/UploadFileBox"
import moment from "moment"
import swal from "sweetalert"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import DialogShowPdf from "./component/DialogShowPdf"
interface data {
  department: string
  docNo: string
  docName: string
  revision: string
  remarks: string
  date: string
  record: string
  id: number
}

function createData(
  department: string,
  docNo: string,
  docName: string,
  revision: string,
  remarks: string,
  date: string,
  record: string,
  id: number
) {
  return { department, docNo, docName, revision, remarks, date, record, id }
}

const pageTitle = "Document"

const DocumentList = (): ReactElement => {
  const { error, data } = useQuery(Get_Document, {
    variables: { department: "" }
  })
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [documentList, setDocumentList] = useState<Array<data>>([])
  const [department, setDepartment] = useState<string>("")

  const handleShowHistory = () => {
    const result = data.pmwis.filter(
      (e: { department: string }) => e.department === department
    )
    setDocumentList(result)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const rows = documentList.map((e: data) =>
    createData(
      e.department,
      e.docNo,
      e.docName,
      e.revision,
      e.remarks,
      e.date,
      e.record,
      e.id
    )
  )

  if (error) swal("ERROR", `${error.toString()}`, "warning")
  const depNames = data.pmwis
    .map((e: { department: string }) => e.department)
    .reduce(
      (prev: Array<string>, cur: string) =>
        prev.includes(cur) ? prev : prev.concat(cur),
      []
    )
  const theme = useTheme()
  return (
    <ThemedCard sx={{ marginTop: "15px", minHeight: "535px" }}>
      <PageLabelCard
        title="Document History"
        subTitle="detail about Document History"
      />
      <Grid container spacing={2} width={"98%"} margin={1}>
        <Grid item xs={12} sm={10}>
          <FormControl fullWidth>
            <InputLabel>Document</InputLabel>
            <Select
              value={department}
              onChange={(event: SelectChangeEvent) =>
                setDepartment(event.target.value as string)
              }
              label="Department"
            >
              {depNames.map((name: string) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{ mt: 1 }}
          display="inline-grid"
          justifyContent="center"
          alignContent="center"
        >
          <ThemedLoadingButton
            type="submit"
            style={{ backgroundColor: theme.palette.primary.main }}
            variant="contained"
            onClick={handleShowHistory}
          >
            show history
          </ThemedLoadingButton>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Department
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Doc No
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Doc Name
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Revision
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Remark
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Date
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Record
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: data) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.department}
                    </TableCell>
                    <TableCell align="left">{row.docNo}</TableCell>
                    <TableCell align="left">{row.docName}</TableCell>
                    <TableCell align="left">{row.revision}</TableCell>
                    <TableCell align="left">{row.remarks}</TableCell>
                    <TableCell align="left">{row.date}</TableCell>
                    <TableCell align="left">{row.record}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </ThemedCard>
  )
}

const Document: React.FC = () => {
  const { error, data, loading, refetch } = useQuery(Get_Document, {
    variables: { department: "" }
  })
  const [department, setDepartment] = useState<string>("")
  const [docName, setDocName] = useState<string>("")
  const [docNo, setDocNo] = useState<string>("")
  const [revision, setRevision] = useState<string>("")
  const [record, setRecord] = useState<string>("")
  const [date, setDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [file, setFile] = useState<File | null>(null)
  const [remarks, setRemarks] = useState<string>("")

  const [uploadFile] = useMutation(UploadFile)
  const [createPmwi] = useMutation(CreatePmwi)
  const [downloadFile] = useLazyQuery(DownloadFile)
  const [open, setOpen] = useState<boolean>(false)
  const [url, setUrl] = useState<string>("")

  if (error) swal("ERROR", `${error.toString()}`, "warning")
  if (loading) return <h1>Loading...</h1>

  const handleUpload = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    try {
      const { data: dataUrl } = await uploadFile({
        variables: {
          fileName: file?.name,
          contentType: file?.type
        }
      })
      const presignedUrl = dataUrl.uploadFile
      if (!presignedUrl) return swal("ERROR", "can not upload file", "warning")

      const response = await fetch(presignedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file?.type ?? ""
        }
      })

      if (response.status === 200) {
        await createPmwi({
          variables: {
            department,
            docNo,
            docName,
            revision,
            fileName: file?.name,
            remarks,
            record,
            date
          }
        })
        swal("Success", "", "success")
      }
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "warning")
    }
  }

  const handleDownload = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    try {
      const result = await downloadFile({
        variables: {
          department,
          docName,
          docNo,
          revision
        }
      })
      window.open(result.data.downloadFile.url)
      swal("Success", "", "success")
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "warning")
    }
  }

  const handleShowPdf = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    try {
      const result = await downloadFile({
        variables: {
          department,
          docName,
          docNo,
          revision
        }
      })
      setUrl(result.data.downloadFile.url)
      setOpen(true)
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "warning")
    }
  }

  const depNames = data.pmwis
    .map((e: { department: string }) => e.department)
    .reduce(
      (prev: Array<string>, cur: string) =>
        prev.includes(cur) ? prev : prev.concat(cur),
      []
    )
  const docNO = data.pmwis
    .filter((e: { department: string }) => e.department === department)
    .map((e: { docNo: string }) => e.docNo)
  const name = data.pmwis
    .filter((e: { docNo: string }) => e.docNo === docNo)
    .map((e: { docName: string }) => e.docName)
  const revisions = data.pmwis
    .filter((e: { docName: string }) => e.docName === docName)
    .map((e: { revision: string }) => e.revision)
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setFile(
      event.currentTarget.files?.["0"] ? event.currentTarget.files?.["0"] : null
    )
  }
  const theme = useTheme()
  return (
    <Box style={{ marginLeft: "22px" }} component="form">
      <PageLabel
        menuItem={[{ key: "Document", url: "Document" }]}
        menuItemName={""}
        menuPageName={pageTitle}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} mt={1}>
          <ThemedCard style={{ height: "535px" }}>
            <PageLabelCard
              title="Upload Document"
              subTitle="detail about Upload Document"
            />
            <Grid container spacing={2} width={"98%"} margin={1}>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  value={department}
                  label="Department"
                  onChange={(e) => setDepartment(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  value={docName}
                  label="Document Name"
                  onChange={(e) => setDocName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  value={docNo}
                  label="Document No"
                  onChange={(e) => setDocNo(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  value={revision}
                  label="Revision"
                  onChange={(e) => setRevision(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  value={record}
                  label="Record"
                  onChange={(e) => setRecord(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  type="date"
                  label="Date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={date}
                  fullWidth
                  onChange={(event) => setDate(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <ThemedTextField
                  fullWidth
                  value={remarks}
                  label="Remark"
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <UploadFileBox changeHandler={changeHandler} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectedFileBox
                  selectedFile={file}
                  setSelectedFile={setFile}
                  createLoading={undefined}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <ThemedLoadingButton
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: theme.palette.primary.main }}
                  onClick={async (e) => {
                    await handleUpload(e)
                    refetch({ department: "" })
                  }}
                  endIcon={<AddIcon />}
                >
                  Submit
                </ThemedLoadingButton>
              </Grid>
            </Grid>
          </ThemedCard>
        </Grid>
        <Grid item sm={4} xs={12} mt={1}>
          <ThemedCard style={{ height: "535px" }}>
            <PageLabelCard
              title="Download Document"
              subTitle="detail about Download Upload Document"
            />
            <Grid container spacing={2} width={"98%"} margin={1}>
              <Grid item xs={12} sm={12} marginRight={2}>
                <FormControl fullWidth>
                  <InputLabel>Type Of Document</InputLabel>
                  <Select
                    value={department}
                    onChange={(event: SelectChangeEvent) =>
                      setDepartment(event.target.value as string)
                    }
                    label="Type Of Document"
                  >
                    {depNames.map((name: string) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} marginRight={2}>
                <FormControl fullWidth>
                  <InputLabel>Document No.</InputLabel>
                  <Select
                    value={docNo}
                    onChange={(event: SelectChangeEvent) =>
                      setDocNo(event.target.value as string)
                    }
                    label="Document No"
                  >
                    {docNO.map((name: string) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} marginRight={2}>
                <FormControl fullWidth>
                  <InputLabel>Document Name</InputLabel>
                  <Select
                    value={docName}
                    onChange={(event: SelectChangeEvent) =>
                      setDocName(event.target.value as string)
                    }
                    label="Document Name"
                  >
                    {name.map((name: string) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} marginRight={2}>
                <FormControl fullWidth>
                  <InputLabel>Revision</InputLabel>
                  <Select
                    value={revision}
                    onChange={(event: SelectChangeEvent) =>
                      setRevision(event.target.value as string)
                    }
                    label="Revision"
                  >
                    {revisions.map((name: string) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} marginTop={5}>
                <ThemedLoadingButton
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: theme.palette.primary.main }}
                  onClick={handleDownload}
                >
                  Download
                </ThemedLoadingButton>
              </Grid>
              <Grid item xs={12} sm={6} marginTop={5}>
                <ThemedLoadingButton
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: theme.palette.primary.main }}
                  onClick={handleShowPdf}
                >
                  Show PDF
                </ThemedLoadingButton>
              </Grid>
            </Grid>
          </ThemedCard>
        </Grid>
      </Grid>
      <DocumentList />
      <DialogShowPdf url={url} showPdf={open} setShowPdf={setOpen} />
    </Box>
  )
}

export default Document
