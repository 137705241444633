import { useMutation, useQuery } from "@apollo/client"
import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextareaAutosize,
  TextField
} from "@mui/material"
import { useEffect, useState } from "react"

import { GET_LINE } from "../../../common/Query/GeneralQuery"

import Input from "../../../common/Resources/Input"
import { factoryList } from "../../../common/utilities"
import swal from "sweetalert"
import { IMachine } from "../MachineMt"
import {
  DELETE_MACHINE_MT,
  UPDATE_MACHINE_MT
} from "../../../common/Mutation/MaintenanceMutation"
import { GET_MACHINES_MT } from "../../../common/Query/MaintenanceQuery"
interface IProps {
  dialogOpen: boolean
  setDialogOpen: (status: boolean) => void
}

const EditMachineMt = (props: IProps): JSX.Element => {
  const { dialogOpen, setDialogOpen } = props
  const [machineNo, setMachineNo] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const [line, setLine] = useState<string>("")
  const [frequency, setFrequency] = useState<number>(0)
  const [factory, setFacNo] = useState<string>()
  const [remarks, setRemarks] = useState("")
  const [priority, setPriority] = useState("")
  const [model, setModel] = useState("")
  const [brand, setBrand] = useState("")
  const [installationDate, setInstallationDate] = useState("")
  const [details, setDetails] = useState("")
  const [machinePower, setMachinePower] = useState("")
  const [company, setCompany] = useState("")
  const [error, setError] = useState<boolean>(false)
  const { data } = useQuery(GET_MACHINES_MT)
  const machineQuery: IMachine[] = data?.machinesMt || []
  const machineList = machineQuery.map((machine: IMachine) => machine.code)
  const [editMachine, { loading: loadingEdit }] = useMutation(
    UPDATE_MACHINE_MT,
    {
      refetchQueries: [GET_MACHINES_MT],
      awaitRefetchQueries: true
    }
  )
  const getLine = useQuery(GET_LINE)
  let lineList = []
  if (getLine.data) {
    lineList = getLine.data.getLine.map(
      (element: { lineName: string }) => element.lineName
    )
  }
  const [deleteMachine, { loading: loadingDelete }] = useMutation(
    DELETE_MACHINE_MT,
    {
      refetchQueries: [GET_MACHINES_MT],
      awaitRefetchQueries: true
    }
  )

  useEffect(() => {
    const findMachine = machineQuery.find((e) => e.code === code)
    if (findMachine) {
      setMachineNo(findMachine.machine ?? "")
      setLine(findMachine.line ?? "")
      setFrequency(findMachine.frequency ?? 0)
      setPriority(findMachine.priority ?? "")
      setModel(findMachine.model ?? "")
      setFacNo(findMachine.factory ?? "")
      setCompany(findMachine.company ?? "")
      setBrand(findMachine.brand ?? "")
      setMachinePower(findMachine.machinePower ?? "")
      setDetails(findMachine.details ?? "")
      setInstallationDate(findMachine.installationDate ?? "")
      setRemarks(findMachine.remarks ?? "")
    }
  }, [code])

  const isComplete = () => {
    return machineNo !== "" && code !== ""
  }
  const handleUpdate = async () => {
    if (!isComplete()) {
      setError(true)

      swal("ERROR", "กรุณาใส่ข้อมูลให้ครบถ้วน", "error")
      return
    }
    await editMachine({
      variables: {
        machine: machineNo,
        code,
        line,
        factory,
        frequency,
        priority,
        company,
        model,
        brand,
        installationDate,
        details,
        machinePower,
        remarks
      }
    })
    swal("Success", "Update Machine Successful", "success")
    setDialogOpen(false)
  }

  const handleDelete = async () => {
    if (!isComplete()) {
      swal("ERROR", "กรุณาใส่ข้อมูลให้ครบถ้วน", "error")
      return
    }
    await deleteMachine({
      variables: {
        machine: code
      }
    })
    swal("Success", "Delete Machine Successful", "success")
    setDialogOpen(false)
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"xl"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        >
          Edit Machine
        </DialogTitle>
        <DialogContent>
          <>
            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  value={code}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select machine Code"
                      error={!code && error}
                    />
                  )}
                  onChange={(e, newValue) => setCode(newValue ? newValue : "")}
                  options={machineList}
                />
              </Grid>
              <Input
                fullWidth
                value={machineNo}
                text={"Machine Name"}
                setValue={(event) => {
                  setMachineNo(event.target.value)
                }}
                inputType="string"
                sm={6}
                error={!machineNo && error}
              />
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  value={line}
                  renderInput={(params) => (
                    <TextField {...params} label="Select line" />
                  )}
                  onChange={(e, newValue) => setLine(newValue ? newValue : "")}
                  options={lineList}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  value={factory ? factory : ""}
                  fullWidth
                  disablePortal
                  renderInput={(params) => (
                    <TextField {...params} label="Select Factory" />
                  )}
                  onChange={(e, newValue) => setFacNo(newValue ? newValue : "")}
                  options={factoryList}
                />
              </Grid>

              <Input
                fullWidth
                text={"Frequency"}
                setValue={(event) => {
                  setFrequency(+event.target.value)
                }}
                value={frequency}
                inputType="number"
                sm={6}
              />
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  value={priority}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Priority" />
                  )}
                  onChange={(e, newValue) =>
                    setPriority(newValue ? newValue : "")
                  }
                  options={["A", "B", "C", "D"]}
                />
              </Grid>
              <Input
                fullWidth
                text={"Company"}
                setValue={(event) => {
                  setCompany(event.target.value)
                }}
                value={company}
                inputType="string"
                sm={12}
              />
              <Input
                fullWidth
                text={"Model"}
                setValue={(event) => {
                  setModel(event.target.value)
                }}
                value={model}
                inputType="string"
                sm={6}
              />
              <Input
                fullWidth
                text={"Brand"}
                setValue={(event) => {
                  setBrand(event.target.value)
                }}
                value={brand}
                inputType="string"
                sm={6}
              />
              <Input
                fullWidth
                text={"วันที่ติดตั้ง"}
                setValue={(event) => {
                  setInstallationDate(event.target.value)
                }}
                value={installationDate}
                inputType="date"
                sm={6}
              />
              <Input
                fullWidth
                text={"กำลังเครื่องจักร"}
                setValue={(event) => {
                  setMachinePower(event.target.value)
                }}
                value={machinePower}
                inputType="string"
                sm={6}
              />
              <Grid item sm={12} xs={12}>
                ข้อมูลเครื่องจักร
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Empty"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                  minRows={3}
                  style={{ width: "100%", fontSize: "25px" }}
                />
              </Grid>
              <Input
                fullWidth
                text={"Remarks"}
                setValue={(event) => {
                  setRemarks(event.target.value)
                }}
                value={remarks}
                inputType="string"
                sm={12}
              />
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            style={{ backgroundColor: "green" }}
            autoFocus
            onClick={handleUpdate}
            loading={loadingEdit}
          >
            Update
          </LoadingButton>
          <LoadingButton
            variant="contained"
            style={{ backgroundColor: "red" }}
            autoFocus
            onClick={handleDelete}
            loading={loadingDelete}
          >
            Delete
          </LoadingButton>
          <Button
            variant="outlined"
            style={{ color: "red", borderColor: "red" }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditMachineMt
