import moment from "moment"
import React, { ReactElement, useState } from "react"
import { styled } from "@mui/material/styles"
import {
  Autocomplete,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  ThemeProvider
} from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { GET_CUSTOMERS, GET_PARTS } from "../../common/Query/MasterQuery"
import { GET_WH_TAG_REPORT } from "../../common/Query/WarehouseQuery"
import { CREATE_WH_TAG } from "../../common/Mutation/WarehouseMutation"
import QRCode from "qrcode.react"
import "../../css/Warehouse/GetWarehouseTag.css"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import AutocompleteCustomer from "../../common/Resources/AutocompleteCustomer"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import { bankImage, getImageJPG, getImagePng } from "../../common/utilities"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"

interface DataPart {
  arcweld: number
  cost: number
  customer: string
  leadTime: number
  model: string
  partName: string
  partNo: string
  plate: number
  stamp: number
  weld: number
}

interface DataWHTagReport {
  partNo: string
  partName: string
  customer: string
  model: string
  quantity: number
  totalQuantity: number
  packingBy: string
  recieveDate: string
  pdLotNo: string
  remarks: string
  boxCount: string
}

const pageTitle = "WH Tag"

const GetWarehouseTag: React.FC = (): ReactElement => {
  const theme = useTheme()
  const [customer, setCustomer] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [partSelect, setPartSelect] = useState<DataPart | null>(null)
  const [receivedDate, setReceivedDAte] = useState(
    moment(new Date("2021-01-01")).format("YYYY-MM-DD")
  )
  const [pdLotNo, setPdLotNo] = useState<string | null>(null)
  const [quantity, setQuantity] = useState<number | 1>(1)
  const [totalQuantity, setTotalQuantity] = useState<number | 1>(1)
  const [remarks, setRemarks] = useState<string | null>(null)
  const [packingBy, setPackingBy] = useState<string | null>(null)
  const [showReport, setShowReport] = useState<boolean>(false)
  const getCustomer = useQuery(GET_CUSTOMERS)
  const getPart = useQuery(GET_PARTS)
  const getWhTagReport = useQuery(GET_WH_TAG_REPORT)
  const [createWarehouseTag, { loading }] = useMutation(CREATE_WH_TAG)
  const [imgSrc, setImgSrc] = useState<string | undefined>("")
  const [slot1, setSlot1] = useState<string | undefined>("")
  const [slot2, setSlot2] = useState<boolean>(false)
  const [slot3, setSlot3] = useState<boolean>(false)
  const [slot4, setSlot4] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  let customerList: Array<string> = []
  let partNoList: Array<string> = []
  const packingByList: Array<string> = [
    "อุไรรัตน์WH1",
    "สมหมายWH1",
    "วรวิทย์WH1",
    "ทรงWH1",
    "ลัดดาWH2",
    "วินัตWH2",
    "รุ่งฤดีWH2"
  ]

  if (customerList.length === 0) {
    if (getCustomer) {
      const customers: Array<{ customer: string }> =
        getCustomer?.data?.customers || []
      customerList = customers.map((e: { customer: string }) => e.customer)
    }
  }

  if (partNoList.length === 0 && customer) {
    if (getPart) {
      const parts = getPart?.data?.parts || []
      partNoList = parts
        .filter((e: { customer: string }) => e.customer === customer)
        .map((e: { partNo: string }) => e.partNo)
    }
  }

  if (partNo && partSelect === null) {
    const parts = getPart?.data?.parts || []
    const filterPartName: Array<DataPart> = parts.filter(
      (e: { customer: string | null; partNo: string }) =>
        e.customer === customer && e.partNo === partNo
    )
    setImgSrc(getImagePng(`images/parts/${partNo}`))
    setPartSelect(filterPartName[0])
  }

  const getColor = () => {
    if (receivedDate.slice(5, 7) === "01" || receivedDate.slice(5, 7) === "07")
      setSlot1(getImageJPG("images/color/1"))
    if (receivedDate.slice(5, 7) === "02" || receivedDate.slice(5, 7) === "08")
      setSlot1(getImageJPG("images/color/2"))
    if (receivedDate.slice(5, 7) === "03" || receivedDate.slice(5, 7) === "09")
      setSlot1(getImageJPG("images/color/3"))
    if (receivedDate.slice(5, 7) === "04" || receivedDate.slice(5, 7) === "10")
      setSlot1(getImageJPG("images/color/4"))
    if (receivedDate.slice(5, 7) === "05" || receivedDate.slice(5, 7) === "11")
      setSlot1(getImageJPG("images/color/5"))
    if (receivedDate.slice(5, 7) === "06" || receivedDate.slice(5, 7) === "12")
      setSlot1(getImageJPG("images/color/6"))
  }
  const getFifoColor = () => {
    const year = receivedDate.slice(0, 4)
    const month = receivedDate.slice(5, 7)
    const whTag: DataWHTagReport[] = getWhTagReport?.data?.whTagReport || []
    const newData: string[] = whTag
      .filter(
        (e) =>
          e.partNo === partNo &&
          e.recieveDate.slice(0, 4) === year &&
          e.recieveDate.slice(5, 7) === month
      )
      .map((e) => e.pdLotNo)
      .reduce(
        (prev: string[], cur) => (prev.includes(cur) ? prev : prev.concat(cur)),
        []
      )
    if (newData.length === 1) {
      setSlot2(true)
    }
    if (newData.length === 2) {
      setSlot3(true)
    }
    if (newData.length === 3) {
      setSlot4(true)
    }
  }

  const isDataComplete = () => {
    return (
      customer !== null &&
      partNo !== null &&
      quantity !== 0 &&
      totalQuantity !== 0 &&
      receivedDate !== null &&
      pdLotNo !== null &&
      packingBy !== null
    )
  }

  const filterBoxCount = (): number => {
    const box = Math.ceil(totalQuantity / quantity)
    return box > 0 && box !== Infinity ? box : 0
  }
  const filterQuantity = () => {
    if (totalQuantity % quantity === 0) {
      return quantity
    }
    return totalQuantity - Math.floor(totalQuantity / quantity) * quantity
  }

  const filterColorQuantity = () => {
    if (filterQuantity() !== quantity)
      return getImagePng("images/color/%E0%B9%80%E0%B8%A8%E0%B8%A9")
  }
  const onReset = () => {
    setCustomer("")
    setPartNo("")
    setQuantity(1)
    setTotalQuantity(1)
    setReceivedDAte("2021-01-01")
    setPdLotNo(null)
    setPackingBy(null)
    setRemarks(null)
  }
  const rePrint = () => {
    setShowReport(true)
    const whTag: DataWHTagReport[] = getWhTagReport?.data?.whTagReport || []
    whTag.filter((e) => e.partNo === partNo && e.recieveDate === receivedDate)
    setQuantity(whTag.slice().map((e) => e.quantity)[0])
    setTotalQuantity(whTag.slice().map((e) => e.totalQuantity)[0])
    filterBoxCount()
    setPdLotNo(whTag.slice().map((e) => e.pdLotNo)[0])
    setPackingBy(whTag.slice().map((e) => e.packingBy)[0])
    getColor()
    getFifoColor()
  }

  const onSubmit = async () => {
    if (customer === "" && partNo === "") {
      setError(true)
      return swal("warning", "กรุณาใส่ข้อมูล", "error")
    }
    setShowReport(true)
    getFifoColor()
    getColor()
    if (!isDataComplete()) {
      swal("Warning", "กรุณาใส่ข้อมูลให้ครบถ้วน", "warning")
      return
    }
    const data = {
      partNo: partNo,
      partName: partSelect?.partName,
      customer: customer,
      model: partSelect?.model,
      quantity: quantity,
      totalQuantity: totalQuantity,
      packingBy: packingBy,
      recieveDate: receivedDate,
      pdLotNo: pdLotNo,
      remarks: remarks,
      boxCount: filterBoxCount().toString()
    }
    try {
      await createWarehouseTag({
        variables: { data }
      })
      swal("Success", "คุณบันทึกค่า สำเร็จ", "success")
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "warning")
    }
  }
  const StyledTableCell = styled(TableCell)(() => ({
    border: "1px solid black"
    // width: "auto"
  }))

  const StyledTableRow = styled(TableRow)(() => ({
    border: "1px solid"
  }))

  const getQR = (count: number) => {
    return `${partNo}|${receivedDate}|${pdLotNo}|${quantity}|${count}`
  }

  const onError = () => setImgSrc(bankImage)

  const TableWhTag = (count: { count: number }) => {
    return (
      <Table
        style={{ marginTop: "23px", width: "350px", height: "443px" }}
        key={count.count}
        className="print-warehouse-tag"
      >
        <TableHead>
          <StyledTableRow>
            <StyledTableCell
              align="center"
              colSpan={11}
              style={{ fontWeight: "bold", fontSize: "13px" }}
            >
              <label>{count.count}</label>
              <label>BROTHER AUTO PARTS ENGINEERING CO.,LTD</label>
              <br />
              <label style={{ textAlign: "left" }}>WH-TAG</label>
              <label style={{ textAlign: "right" }}>F-WH-02</label>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell colSpan={2} style={{ width: "150px" }}>
              Part No(รหัสชิ้นส่วน)
            </StyledTableCell>
            <StyledTableCell colSpan={2} style={{ width: "150px" }}>
              {partNo}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell colSpan={2}>
              {" "}
              MODEL(รุ่น): {partSelect?.model}
            </StyledTableCell>
            <StyledTableCell colSpan={2}>Customer: {customer}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell colSpan={1} style={{ width: "75px" }}>
              {" "}
              Q'TY
            </StyledTableCell>
            <StyledTableCell colSpan={1} style={{ width: "75px" }}>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                {count.count === Math.ceil(totalQuantity / quantity)
                  ? filterQuantity()
                  : quantity}
                {count.count === Math.ceil(totalQuantity / quantity) &&
                filterQuantity() !== quantity ? (
                  <img
                    src={filterColorQuantity()}
                    style={{
                      width: "20px",
                      height: "20px",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                    alt=""
                  />
                ) : (
                  <></>
                )}
              </label>
            </StyledTableCell>
            <StyledTableCell colSpan={1} style={{ width: "75px" }}>
              Packing By
            </StyledTableCell>
            <StyledTableCell colSpan={1} style={{ width: "75px" }}>
              {packingBy}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell
              colSpan={2}
              style={{ fontSize: "10px", textAlign: "center" }}
            >
              <label>RECEIVE DATE</label>
              <br />
              <label>{receivedDate}</label>
            </StyledTableCell>
            <StyledTableCell
              colSpan={2}
              style={{ fontSize: "10px", textAlign: "center" }}
            >
              <label>Pd Lot No</label>
              <br />
              <label>{pdLotNo}</label>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell
              colSpan={2}
              style={{ fontSize: "10px", textAlign: "center" }}
            >
              <QRCode value={getQR(count.count)} size={100} />
            </StyledTableCell>
            <StyledTableCell
              colSpan={2}
              style={{ fontSize: "10px", textAlign: "center" }}
            >
              <img
                src={imgSrc ? imgSrc : bankImage}
                style={{
                  width: "100px",
                  height: "100px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
                onError={onError}
                alt=""
                // {...props}
              />
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell colSpan={1} style={{ textAlign: "center" }}>
              Box No.
            </StyledTableCell>
            <StyledTableCell colSpan={1} style={{ textAlign: "center" }}>
              {count.count}
            </StyledTableCell>
            <StyledTableCell colSpan={1} style={{ textAlign: "center" }}>
              /
            </StyledTableCell>
            <StyledTableCell colSpan={1} style={{ textAlign: "center" }}>
              {filterBoxCount()}
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow>
            <StyledTableCell
              colSpan={1}
              style={{ textAlign: "center", padding: "1px" }}
            >
              <img
                src={slot1}
                style={{
                  width: "auto",
                  height: "25px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
                alt=""
                // {...props}
              />
            </StyledTableCell>
            <StyledTableCell colSpan={1} style={{ textAlign: "center" }}>
              {slot2 ? (
                <img
                  src={slot1}
                  style={{
                    width: "auto",
                    height: "25px",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                  alt=""
                  // {...props}
                />
              ) : (
                <></>
              )}
            </StyledTableCell>
            <StyledTableCell colSpan={1} style={{ textAlign: "center" }}>
              {slot3 ? (
                <img
                  src={slot1}
                  style={{
                    width: "auto",
                    height: "25px",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                  alt=""
                  // {...props}
                />
              ) : (
                <></>
              )}
            </StyledTableCell>
            <StyledTableCell colSpan={1} style={{ textAlign: "center" }}>
              {slot4 ? (
                <img
                  src={slot1}
                  style={{
                    width: "auto",
                    height: "25px",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                  alt=""
                  // {...props}
                />
              ) : (
                <></>
              )}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Box style={{ marginLeft: "22px" }} component="form">
        <div className="print-warehouse-tag-hide">
          <PageLabel
            menuItem={menuItemList.Warehouse}
            menuItemName={Object.keys(menuItemList)[6]}
            menuPageName={pageTitle}
          />
          <ThemedCard style={{ marginTop: "26px" }}>
            <PageLabelCard
              title="FIFO Warehouse Tag"
              subTitle="detail about Warehouse Tag"
            />
            <Grid container spacing={2} width={"98%"} margin={2}>
              <Grid item xs={12} sm={3}>
                <AutocompleteCustomer
                  setCustomer={setCustomer}
                  customer={customer}
                  error={!customer && error}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <AutocompletePartNo
                  setPartNo={setPartNo}
                  partNo={partNo}
                  error={!partNo && error}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ThemedTextField
                  fullWidth
                  value={receivedDate}
                  id="receivedDate"
                  type="date"
                  label="Receive Date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  autoComplete="family-name"
                  onChange={(e) => setReceivedDAte(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ThemedTextField
                  fullWidth
                  id="pdLotNo"
                  value={pdLotNo ? pdLotNo : ""}
                  type="text"
                  label="PD Lot No"
                  autoComplete="family-name"
                  onChange={(e) => setPdLotNo(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ThemedTextField
                  fullWidth
                  id="quantity"
                  value={quantity ? quantity : ""}
                  type="number"
                  autoComplete="family-name"
                  label="Quantity"
                  onChange={(e) => setQuantity(+e.target.value)}
                  onWheel={(event) =>
                    event.currentTarget.querySelector("input")?.blur()
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ThemedTextField
                  fullWidth
                  id="totalQuantity"
                  value={totalQuantity ? totalQuantity : ""}
                  type="number"
                  label="Total Quantity"
                  autoComplete="family-name"
                  onChange={(e) => setTotalQuantity(+e.target.value)}
                  onWheel={(event) =>
                    event.currentTarget.querySelector("input")?.blur()
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ThemedTextField
                  fullWidth
                  id="remarks"
                  value={remarks ? remarks : ""}
                  type="text"
                  label="Remarks"
                  autoComplete="family-name"
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  disablePortal
                  id="packingBy"
                  value={packingBy}
                  options={packingByList}
                  onChange={(e, newValue) => setPackingBy(newValue)}
                  renderInput={(params) => (
                    <ThemedTextField {...params} label="Packing By" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <ThemedLoadingButton
                  variant="contained"
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    width: "80px"
                  }}
                  onClick={onSubmit}
                  loading={loading}
                >
                  Submit
                </ThemedLoadingButton>
              </Grid>
              <Grid item xs={12} sm={1}>
                <ThemedButton
                  variant="outlined"
                  style={{
                    color: theme.palette.secondary.contrastText,
                    borderColor: theme.palette.primary.main,
                    width: "80px"
                  }}
                  onClick={rePrint}
                >
                  Reprint
                </ThemedButton>
              </Grid>
              <Grid item xs={12} sm={1}>
                <ThemedButton
                  variant="outlined"
                  style={{
                    color: theme.palette.secondary.contrastText,
                    borderColor: theme.palette.primary.main,
                    width: "80px"
                  }}
                  onClick={() => window.print()}
                >
                  Print
                </ThemedButton>
              </Grid>
              <Grid item xs={12} sm={1}>
                <ThemedButton
                  variant="contained"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    width: "80px"
                  }}
                  onClick={onReset}
                >
                  Reset
                </ThemedButton>
              </Grid>
            </Grid>
          </ThemedCard>
        </div>
        {showReport ? (
          <Grid container spacing={2}>
            {Array.from(Array(filterBoxCount()), (e, i) => {
              return (
                <Grid item xs={12} sm={4}>
                  <TableWhTag count={i + 1} key={i} />
                </Grid>
              )
            })}
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </ThemeProvider>
  )
}

export default GetWarehouseTag
