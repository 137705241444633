import { useLazyQuery } from "@apollo/client"
import {
  CardHeader,
  Box,
  Grid,
  MenuItem,
  useTheme,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow
} from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"
import swal from "sweetalert"
import { GET_SPARE_PARTS_STOCK_CARD_DATA } from "../../common/Query/MaintenanceQuery"
import AutocompleteSparePartCode from "../../common/Resources/AutocompleteSparePartCode"
import AutocompleteSparePartPartName from "../../common/Resources/AutocompleteSparePartPartName"

import { logoTable } from "../../common/utilities"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "bold",
    border: "solid",
    borderWidth: "1px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "bold",
    border: "solid",
    borderWidth: "1px",
    padding: "0px"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {},
  "&:last-child td, &:last-child th": {
    border: "solid",
    borderWidth: "1px"
  }
}))
interface IDateStock {
  in: number
  out: number
  balance: number
  actionDate: string
  remarks: string
  partCounted: number
}

interface ISparePartStock {
  code: string
  partName: string
  curStock: number
  actionDate: IDateStock[]
  model: string
  factory: string
  min: string
  max: string
}

const paddingPartDeliveryDates = (
  orders: IDateStock[],
  masterDateList: string[],
  curStock: number
): IDateStock[] => {
  const newOrders: IDateStock[] = []
  let j = 0

  masterDateList.forEach((e, i) => {
    if (orders[j]) {
      if (orders.length > j && masterDateList[i] === orders[j].actionDate) {
        newOrders.push(orders[j])
        j++
      } else {
        const tempOrder: IDateStock = {
          in: 0,
          out: 0,
          balance: j === 0 ? curStock : orders[j - 1].balance,
          actionDate: masterDateList[i],
          partCounted: 0,
          remarks: ""
        }

        newOrders.push(tempOrder)
      }
    } else {
      const tempOrder: IDateStock = {
        in: 0,
        out: 0,
        balance: orders[j - 1]?.balance,
        actionDate: masterDateList[i],
        partCounted: 0,
        remarks: ""
      }
      newOrders.push(tempOrder)
    }
  })
  return newOrders
}
const SparePartStockCard: React.FC = () => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [factory, setFactory] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [type, setType] = useState<string>("")
  const [dataStockCard, { data, loading }] = useLazyQuery(
    GET_SPARE_PARTS_STOCK_CARD_DATA
  )
  const [maxDueDate, setMaxDueDate] = useState<Array<string>>([])
  const [sparePartStockData, setSparePartStockData] = useState<
    ISparePartStock[]
  >([])

  const theme = useTheme()
  useEffect(() => {
    const allDueDate: Array<string> = []
    if (data && data.sparePartStockCardReport) {
      data.sparePartStockCardReport.forEach(
        (e: { actionDate: IDateStock[] }) => {
          e.actionDate.forEach((date) => {
            allDueDate.push(date.actionDate)
          })
        }
      )
      const newDueDate = Array.from(new Set(allDueDate))
      setMaxDueDate(newDueDate.sort())
      const dataReport = [...data?.sparePartStockCardReport]
      setSparePartStockData(dataReport)
    }
  }, [data])

  useEffect(() => {
    if (data && !data.sparePartStockCardReport.length)
      swal("ERROR", "ไม่พบข้อมูลค้นหา", "warning")
  }, [data])

  const handleSubmit = async () => {
    if (!(dateTo && dateFrom)) return swal("ERROR", "กรุณาใส่วันที่", "warning")

    await dataStockCard({
      variables: {
        dateFrom,
        dateTo,
        factory,
        code,
        partName,
        type
      },
      fetchPolicy: "network-only"
    })
  }

  const printDiv = (id: string) => {
    const printContents = document.getElementById(id)?.innerHTML
    const originalContents = document.body.innerHTML
    document.body.innerHTML =
      "<html><head><title></title></head><body>" + printContents + "</body>"
    window.print()
    document.body.innerHTML = originalContents
    location.reload()
  }

  return (
    <div>
      <CardHeader
        titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
        title="Spare Part In Or Out"
        subheaderTypographyProps={{ fontFamily: "Sarabun" }}
        subheader="Add incoming Spare Part  to supply"
      />
      <div style={{ margin: "20px" }}>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <ThemedTextField
                fullWidth
                label="Date From."
                value={dateFrom}
                type="date"
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <ThemedTextField
                fullWidth
                label="Date To."
                value={dateTo}
                type="date"
                onChange={(e) => setDateTo(e.target.value)}
              ></ThemedTextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <AutocompleteSparePartCode setCode={setCode} code={code} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <AutocompleteSparePartPartName
                setPartName={setPartName}
                partName={partName}
                code={code}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <ThemedTextField
                id="factory"
                fullWidth
                label="Factory No."
                value={factory}
                onChange={(e) => setFactory(e.target.value)}
                select
              >
                <MenuItem value={"1"}>1</MenuItem>
                <MenuItem value={"2"}>2</MenuItem>
              </ThemedTextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <ThemedTextField
                fullWidth
                label="Type."
                value={type}
                onChange={(e) => setType(e.target.value)}
                select
              >
                <MenuItem value={"over"}>Over</MenuItem>
                <MenuItem value={"low"}>Low</MenuItem>
              </ThemedTextField>
            </Grid>
          </Grid>
        </Box>
        <ThemedLoadingButton
          variant="contained"
          sx={{ mt: 3, mb: 2, backgroundColor: theme.palette.primary.main }}
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </ThemedLoadingButton>
        <ThemedButton
          sx={{
            ml: 3,
            mt: 3,
            mb: 2,
            backgroundColor: theme.palette.primary.main
          }}
          style={{
            backgroundColor: "green"
          }}
          variant="contained"
          onClick={() => printDiv("stockCard")}
        >
          Print
        </ThemedButton>
        <div id="stockCard">
          <Table className="print-sm-order">
            <TableHead>
              <StyledTableRow key={"table-masterplan"}>
                <StyledTableCell
                  style={{
                    border: "solid",
                    borderWidth: "1px",
                    padding: "20px",
                    fontSize: "20px"
                  }}
                  colSpan={7}
                  rowSpan={1}
                >
                  <img src={logoTable} alt="" height={"80vh"} />
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    border: "solid",
                    borderWidth: "1px",
                    padding: "20px",
                    fontSize: "35px"
                  }}
                  colSpan={10}
                  rowSpan={1}
                >
                  <div> Spare Part Stock Card</div>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key={"row-masterplan"}>
                <StyledTableCell
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "7px"
                  }}
                  colSpan={1}
                  rowSpan={1}
                >
                  <>No</>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "7px"
                  }}
                  colSpan={1}
                  rowSpan={1}
                >
                  <>Code</>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                    padding: "7px"
                  }}
                  colSpan={1}
                  rowSpan={1}
                >
                  <>Part Name</>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "7px"
                  }}
                  colSpan={1}
                  rowSpan={1}
                >
                  <>model</>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "7px"
                  }}
                  colSpan={1}
                  rowSpan={1}
                >
                  <>Factory</>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "7px"
                  }}
                  colSpan={1}
                  rowSpan={1}
                >
                  <>Min</>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "7px"
                  }}
                  colSpan={1}
                  rowSpan={1}
                >
                  <>Max</>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "7px"
                  }}
                  colSpan={1}
                  rowSpan={1}
                >
                  <>Order</>
                </StyledTableCell>
                {maxDueDate?.map((e: string, i: number) => {
                  return (
                    <StyledTableCell
                      style={{
                        borderWidth: "1px",
                        borderStyle: "solid",
                        whiteSpace: "nowrap",
                        padding: "7px"
                      }}
                      colSpan={1}
                      rowSpan={1}
                      key={i + "max-due-date"}
                    >
                      {e?.split("-")[2]} - {e?.split("-")[1]}
                    </StyledTableCell>
                  )
                })}
              </StyledTableRow>
            </TableHead>
            <TableBody key={"table-body-masterplan"}>
              {sparePartStockData.map((data: ISparePartStock, index) => {
                const order = paddingPartDeliveryDates(
                  data.actionDate,
                  maxDueDate,
                  data.curStock
                )

                return (
                  <>
                    <StyledTableRow
                      className={index % 2 === 0 ? "display-border" : undefined}
                    >
                      <StyledTableCell
                        style={{
                          borderWidth: "1px",
                          borderStyle: "solid",
                          whiteSpace: "nowrap",
                          padding: "7px"
                        }}
                        colSpan={1}
                        rowSpan={4}
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderWidth: "1px",
                          borderStyle: "solid",
                          whiteSpace: "nowrap",
                          padding: "7px"
                        }}
                        colSpan={1}
                        rowSpan={4}
                      >
                        {data.code}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderWidth: "1px",
                          borderStyle: "solid",
                          whiteSpace: "nowrap",
                          padding: "7px"
                        }}
                        colSpan={1}
                        rowSpan={4}
                      >
                        {data.partName}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderWidth: "1px",
                          borderStyle: "solid",
                          whiteSpace: "nowrap",
                          padding: "7px"
                        }}
                        colSpan={1}
                        rowSpan={4}
                      >
                        {data.model}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderWidth: "1px",
                          borderStyle: "solid",
                          whiteSpace: "nowrap",
                          padding: "7px"
                        }}
                        colSpan={1}
                        rowSpan={4}
                      >
                        {data.factory}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderWidth: "1px",
                          borderStyle: "solid",
                          whiteSpace: "nowrap",
                          padding: "7px"
                        }}
                        colSpan={1}
                        rowSpan={4}
                      >
                        {data.min}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderWidth: "1px",
                          borderStyle: "solid",
                          whiteSpace: "nowrap",
                          padding: "7px"
                        }}
                        colSpan={1}
                        rowSpan={4}
                      >
                        {data.max}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1} rowSpan={1}>
                        In
                      </StyledTableCell>
                      {Array.from(Array(maxDueDate.length), (num, i) => {
                        return (
                          <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                            <span>{order[i].in}</span>
                          </StyledTableCell>
                        )
                      })}
                    </StyledTableRow>
                    <StyledTableRow
                      className={index % 2 === 0 ? "display-border" : undefined}
                    >
                      <StyledTableCell colSpan={1} rowSpan={1}>
                        Out
                      </StyledTableCell>
                      {Array.from(Array(maxDueDate.length), (num, i) => {
                        return (
                          <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                            <span
                              style={
                                order[i].partCounted > 1 ? { color: "red" } : {}
                              }
                            >
                              {order[i].out}
                            </span>
                          </StyledTableCell>
                        )
                      })}
                    </StyledTableRow>
                    <StyledTableRow
                      className={index % 2 === 0 ? "display-border" : undefined}
                    >
                      <StyledTableCell colSpan={1} rowSpan={1}>
                        Balance
                      </StyledTableCell>
                      {Array.from(Array(maxDueDate.length), (num, i) => {
                        return (
                          <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                            <span
                              style={
                                order[i].partCounted > 1 ? { color: "red" } : {}
                              }
                            >
                              {order[i].balance
                                ? order[i].balance
                                : order.find(
                                    (val, index) => index > i && val.balance
                                  )?.balance || 0}
                            </span>
                          </StyledTableCell>
                        )
                      })}
                    </StyledTableRow>
                    <StyledTableRow
                      className={index % 2 === 0 ? "display-border" : undefined}
                    >
                      <StyledTableCell colSpan={1} rowSpan={1}>
                        Remarks
                      </StyledTableCell>
                      {Array.from(Array(maxDueDate.length), (num, i) => {
                        return (
                          <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                            <span
                              style={
                                order[i].balance <= Number(data.min)
                                  ? { backgroundColor: "red" }
                                  : order[i].balance >= Number(data.max)
                                  ? { backgroundColor: "orange" }
                                  : {}
                              }
                            >
                              {order[i].remarks}
                            </span>
                          </StyledTableCell>
                        )
                      })}
                    </StyledTableRow>
                  </>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}
export default SparePartStockCard
