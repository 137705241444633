import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useEffect, useRef, useState } from "react"
import exporting from "highcharts/modules/exporting.js"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
  DialogActions,
  Button,
  useTheme
} from "@mui/material"
import { IDashBoardProps } from "../../../common/Resources/reuseInterface"
import { IVolumePlanReport } from "../hook/useFetchData"
exporting(Highcharts)
interface IMonthlyOrder {
  month: string
  quantity: number
}
interface IVolumePlan {
  type: string
  order: IMonthlyOrder[]
}

const paddingPartDeliveryDates = (
  orders: IMonthlyOrder[],
  masterDateList: string[]
) => {
  const newOrders: number[] = []

  masterDateList.forEach((e) => {
    const data = orders.find((entry: { month: string }) => entry.month === e)
    newOrders.push(data ? data.quantity : 0)
  })
  return newOrders
}

export const ChartVolumePlan = (props: {
  chart?: HighchartsReact.Props
  volumePlan: IVolumePlanReport[] | undefined
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [monthly, setMonthly] = useState<string[]>([])
  const [volumeChart, setVolumeChart] = useState([])
  const { volumePlan } = props
  const theme = useTheme()
  useEffect(() => {
    const allMonth: Array<string> = []
    if (volumePlan?.length) {
      const volumePlanReport = volumePlan.map((e: IVolumePlan) => {
        const order = e.order.map((order) => {
          const month = new Date(
            new Date().setMonth(Number(order.month) - 1)
          ).toLocaleDateString("fr-CA", {
            year: "numeric",

            month: "2-digit"
          })
          return {
            month,
            quantity: order.quantity
          }
        })
        return {
          type: e.type,
          order: order.sort((a, b) =>
            a.month > b.month ? 1 : b.month > a.month ? -1 : 0
          )
        }
      })
      volumePlan.forEach((e: IVolumePlan) => {
        e.order.forEach((order) => {
          const month = new Date(
            new Date().setMonth(Number(order.month) - 1)
          ).toLocaleDateString("fr-CA", {
            year: "numeric",

            month: "2-digit"
          })
          allMonth.push(month)
        })
      })
      const uniqueMonthly = Array.from(new Set(allMonth.sort()))
      const columnData = volumePlanReport.map((data: IVolumePlan) => {
        return {
          type: "column",
          name:
            data.type === "autoPart"
              ? "Automotive"
              : data.type === "electronic"
              ? "Electronic"
              : data.type === "furniture"
              ? "Furniture"
              : data.type,
          data: paddingPartDeliveryDates(data.order, uniqueMonthly)
        }
      })
      const dataSeries = columnData as never[]
      setVolumeChart(dataSeries)
      setMonthly(uniqueMonthly)
    }
  }, [volumePlan])
  if (!volumePlan) return <>Loading...</>

  const options: Highcharts.Options = {
    colors: ["#03C988", "#BFDB38", "#FFFBAC"],
    chart: {
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "Volume Plan",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    xAxis: {
      categories: monthly,
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "8px"
        }
      }
    },
    yAxis: {
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      title: {
        text: "Million liter",
        style: {
          color: theme.palette.secondary.contrastText
        }
      }
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true
        }
      }
    },
    series: volumeChart,
    legend: {
      itemStyle: {
        fontSize: "10px",
        color: theme.palette.secondary.contrastText
      }
    },
    exporting: {
      enabled: false
    }
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props.chart}
    />
  )
}
interface IVolumePlanChart extends IDashBoardProps {
  volumePlan: IVolumePlanReport[] | undefined
  setQuarter: (quarter: number) => void
  quarter: number
}

const VolumePlan = (props: IVolumePlanChart): JSX.Element => {
  const { dialogOpen, setDialogOpen, volumePlan, setQuarter, quarter } = props
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        ></DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12} justifyContent={"center"} margin={2}>
              <TextField
                id="factory"
                fullWidth
                label="ไตรมาส"
                value={quarter}
                onChange={(e) => setQuarter(+e.target.value)}
                select
              >
                <MenuItem value={1}>ไตรมาส 1</MenuItem>
                <MenuItem value={2}>ไตรมาส 2</MenuItem>
                <MenuItem value={3}>ไตรมาส 3</MenuItem>
                <MenuItem value={4}>ไตรมาส 4</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={12} xs={12} justifyContent="center">
              <ChartVolumePlan volumePlan={volumePlan} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default VolumePlan
