import { Dispatch, SetStateAction } from "react"
import ThemedTextField from "./ThemedComponents/ThemedTextField"

interface IProps {
  setDate: Dispatch<SetStateAction<string>>
  date: string
  error?: boolean
}
const CustomTextFieldTypeDate = (props: IProps): JSX.Element => {
  const { date, setDate, error } = props

  return (
    <ThemedTextField
      fullWidth
      value={date}
      error={!date && error}
      id="date"
      type="date"
      label="Date"
      InputLabelProps={{
        shrink: true
      }}
      autoComplete="family-name"
      onChange={(e) => setDate(e.target.value)}
    />
  )
}
export default CustomTextFieldTypeDate
