import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ThemeProvider,
  styled,
  tableCellClasses,
  useTheme
} from "@mui/material"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import { miniLogo } from "../../../common/utilities"
import "../../../css/QC/QC.css"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedRadio from "../../../common/Resources/ThemedComponents/ThemedRadio"
import {
  GET_CUSTOMERS,
  GET_EMPLOYEE,
  GET_PARTS
} from "../../../common/Query/MasterQuery"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import moment from "moment"
import { GET_MASTERPLAN_FOR_CREATE_OR_REPORT_CHECK_SHEET } from "../../../common/Query/QCQuery"
import swal from "sweetalert"
import { CREATE_CHECK_SHEET } from "../../../common/Mutation/QcMutation"
import { useEffect, useMemo, useState } from "react"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import { user } from "../../../common/MainApp"

interface ITableData {
  [key: string]: number | string | boolean
  Id: number
  no: number
  dueDate: string
  customer: string
  partNo: string
  plant: string
  pdLotNo: string
  quantity: number
  quantityActual: number
  wipQtyBox: number
  wipQtyBoxActual: number
  tag: boolean
  rusty: number
  deformed: number
  plating: number
  cleanliness: number
  upSideDownLetter: number
  randomInspection: boolean
  officer: string
  verifyOrderBy: string
  inspector: string
  approver: string
  action: string
}

interface IParts {
  partNo: string
  customer: string
}

const StyledTableRow = styled(TableRow)(() => ({
  "td, th": {
    border: "1px solid black"
  }
}))

const StyledTableCell = styled(TableCell)(() => ({
  color: "black",
  backgroundColor: "white",
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "Sarabun",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Sarabun",
    fontSize: "16px",
    textAlign: "center",
    padding: "20px 5px 20px 5px"
  }
}))

const StyledTableCellThin = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  fontFamily: "Sarabun",
  fontSize: "17px",
  textAlign: "center"
  // whiteSpace: "nowrap"
}))

const StyledTableCellVertical = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  fontFamily: "Sarabun",
  fontSize: "17px",
  whiteSpace: "nowrap"
}))

const StyledTextField = styled(TextField)(() => ({
  width: "100px",
  fontSize: "10px"
}))

StyledTextField.defaultProps = {
  label: "จำนวน",
  variant: "outlined"
}

const CreateDockAuditCheckSheet: React.FC = () => {
  const theme = useTheme()
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [dataTable, setDataTable] = useState<ITableData[]>([])
  const [date, setDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [customer, setCustomer] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const onlyDisplayToLeader =
    user.role === "99" || user.role === "99,6" || user.role === "99,3,4,6"

  const [postCreateCheckSheet, { loading: loadingWhilePUTData }] =
    useMutation(CREATE_CHECK_SHEET)

  const [getFilterData, { loading: loadingFilterData }] = useLazyQuery(
    GET_MASTERPLAN_FOR_CREATE_OR_REPORT_CHECK_SHEET,
    {
      onCompleted: (data) => {
        if (data && data.getMasterPlanForCreateOrReportCheckSheet.length) {
          const mapData: ITableData[] =
            data.getMasterPlanForCreateOrReportCheckSheet.map(
              (item: ITableData) => {
                return {
                  Id: item.Id,
                  dueDate: item.dueDate,
                  customer: item.customer,
                  partNo: item.partNo,
                  plant: item.plant,
                  factory: item.factory,
                  pdLotNo: item.pdLotNo ?? undefined,
                  quantity: item.quantity,
                  quantityActual: item.quantityActual ?? undefined,
                  wipQtyBox: item.wipQtyBox,
                  wipQtyBoxActual: item.wipQtyBoxActual ?? undefined,
                  tag: item.tag ?? undefined,
                  rusty: item.rusty ?? undefined,
                  deformed: item.deformed ?? undefined,
                  plating: item.plating ?? undefined,
                  cleanliness: item.cleanliness ?? undefined,
                  upSideDownLetter: item.upSideDownLetter ?? undefined,
                  randomInspection: item.randomInspection ?? undefined,
                  officer: item.officer ?? undefined,
                  verifyOrderBy: item.verifyOrderBy,
                  inspector: item.inspector,
                  approver: item.approver,
                  action: item.action ?? undefined
                }
              }
            )
          setDataTable(mapData)
        } else {
          setDataTable([])
          swal("Error", "ไม่พบข้อมูล", "warning")
        }
      }
    }
  )

  const handleGetFilter = async () => {
    if (date && customer && factory) {
      setDataTable([])
      await getFilterData({
        variables: {
          date: date,
          customer: customer,
          factory: factory,
          partNo: partNo
        },
        fetchPolicy: "network-only"
      })
      setPage(0)
    } else
      swal("Error", "ระบุวันที่, ชื่อลูกค้า, หมายเลขโรงงานไม่ครบ", "warning")
  }

  const { data: getCustomers } = useQuery(GET_CUSTOMERS)
  const listCustomers: string[] = useMemo(() => {
    if (!getCustomers || !getCustomers.customers) return []
    return getCustomers.customers.map(
      ({ customer }: { customer: string }) => customer
    )
  }, [getCustomers])

  const [qcEmployeeList, setQcEmployeeList] = useState<string[]>([])
  const [inspectorsList, setInspectorsList] = useState<string[]>([])
  const [approverList, setApproverList] = useState<string[]>([])
  const { data: getEmployees } = useQuery(GET_EMPLOYEE)
  useEffect(() => {
    if (getEmployees && getEmployees.employee) {
      let listQCEmployees: string[] = []
      let listInspectors: string[] = []
      let listApprovers: string[] = []

      listQCEmployees = Array.from(
        new Set(
          getEmployees.employee
            .filter(({ departmentId }: { departmentId: number }) => {
              return departmentId === 6
            })
            .map(({ firstName }: { firstName: string }) => firstName)
        )
      )

      listInspectors = getEmployees.employee
        .filter(
          ({
            position,
            firstName
          }: {
            position: string
            firstName: string
          }) => {
            return (
              (position === "FM" || position === "Leader") &&
              (firstName === "ไชยศ" || firstName === "เสน่ห์")
            )
          }
        )
        .map(({ firstName }: { firstName: string }) => {
          return firstName
        })

      listApprovers = getEmployees.employee
        .filter(
          ({
            position,
            firstName
          }: {
            position: string
            firstName: string
          }) => {
            return (
              (position === "FM" || position === "Leader") &&
              (firstName === "ไชยศ" ||
                firstName === "เสน่ห์" ||
                firstName === "เลิศวรรธน์")
            )
          }
        )
        .map(({ firstName }: { firstName: string }) => {
          return firstName
        })
      const clearDuplicateFirstNameListApprovers: string[] = Array.from(
        new Set(listApprovers)
      )

      setQcEmployeeList(listQCEmployees)
      setInspectorsList(listInspectors)
      setApproverList(clearDuplicateFirstNameListApprovers)
    }
  }, [getEmployees])

  const [partNo, setPartNo] = useState<string>("")

  const { data: getPartNo } = useQuery(GET_PARTS)
  const listParts: string[] = useMemo(() => {
    if (!getPartNo || !getPartNo.parts || !customer) return []
    else {
      return getPartNo.parts
        .filter((item: IParts) => {
          return item.customer === customer
        })
        .map((item: IParts) => {
          return item.partNo
        })
    }
  }, [getPartNo, customer])

  const handleInputChange = (
    value: number | string | boolean,
    rowIndex: number,
    columnName: keyof ITableData
  ) => {
    const newData: ITableData[] = [...dataTable]
    const row: ITableData = newData[rowIndex]
    row[columnName] = value
    setDataTable(newData)
  }

  const handleUpdateButton = async () => {
    if (!checkQuantity()) return

    try {
      if (dataTable.length) {
        await postCreateCheckSheet({
          variables: { data: dataTable }
        })
        swal("Success", "บันทึกสำเร็จ", "success").then(() => {
          setDataTable([])
        })
      }
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const rowsToDisplay = dataTable.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  )

  const swalCaution = async (
    phase: string,
    fromHandle: string,
    idx: number
  ) => {
    const isConfirmed = await swal({
      title: "ต้องการยืนยันใช่หรือไม่?",
      text: phase,
      icon: "warning",
      buttons: ["ไม่", "ใช่"],
      dangerMode: true
    })
    if (isConfirmed && fromHandle === "verifyOrderBy") {
      swal("ตรวจสอบสำเร็จ", {
        icon: "success"
      })
      handleInputChange(user.employee, idx, fromHandle)
    } else if (isConfirmed && fromHandle === "approver") {
      if (
        dataTable[idx]["inspector"] !== null &&
        dataTable[idx]["inspector"] !== "" &&
        dataTable[idx]["inspector"] !== undefined
      ) {
        swal("อนุมัติสำเร็จ", {
          icon: "success"
        })
        handleInputChange(user.employee, idx, fromHandle)
      } else {
        swal("Error", "ให้ผู้ตรวจสอบ Verify Order นี้ก่อน Approve", "warning")
      }
    } else return
  }

  const qtyValidateField = (value: number, index: number) => {
    if (value >= 0 && value !== dataTable[index]["quantity"]) return true
    else return false
  }

  const checkQuantity = () => {
    const accumulate: number[] = []
    for (let i = 0; i < dataTable.length; i++) {
      if (
        dataTable[i].quantityActual !== dataTable[i].quantity &&
        dataTable[i].quantityActual >= 0
      ) {
        accumulate.push(i + 1)
      }
    }

    if (accumulate.length) {
      swal("Error", `จำนวนส่งจริงไม่ถูกต้องใน No. ที่ ${accumulate}`, "warning")
      return false
    } else return true
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
          display: "table",
          tableLayout: "fixed"
        }}
      >
        <Grid container spacing={2} sx={{ pt: "20px" }}>
          <Grid item xs={12} md={2}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                openTo="day"
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                aria-label="date-from"
                value={date}
                onChange={(newStartDate) => {
                  setDate(moment(newStartDate).format("YYYY-MM-DD"))
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    fullWidth
                    label="Date"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              value={customer}
              onChange={(event, value) => {
                setCustomer(value as string)
              }}
              options={listCustomers}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Customer"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <Autocomplete
              value={factory}
              freeSolo
              onChange={(event, value) => {
                setFactory(value as string)
              }}
              onInputChange={(event, freeHandInputValue) => {
                setFactory(freeHandInputValue)
              }}
              options={["1", "2", "2-1"]}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Factory"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <Autocomplete
              value={partNo}
              onChange={(event, value) => {
                setPartNo(value as string)
              }}
              options={listParts}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Part No"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item>
            <ThemedButton
              variant="contained"
              sx={{ mt: 1 }}
              onClick={handleGetFilter}
            >
              SUBMIT FILTER
            </ThemedButton>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: "680px" }}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell colSpan={3}>
                        <img src={miniLogo} alt="BAE logo" width={"80px"} />
                      </StyledTableCell>
                      <StyledTableCell colSpan={15}>
                        <Box>
                          บันทึกการตรวจเช็คชิ้นงานสำเร็จรูปก่อนทำการส่งให้ลูกค้า
                        </Box>

                        <Box>(DOCK AUDIT CHECK SHEET)</Box>
                      </StyledTableCell>
                      <StyledTableCell colSpan={5}>
                        <Box>Code No.: F-QA-03-05</Box>
                        <Box sx={{ fontWeight: "100", fontSize: "14px" }}>
                          Effective: 12/06/24 Rev 04 Issue 1
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellThin rowSpan={3}>No</StyledTableCellThin>
                      <StyledTableCellThin rowSpan={3} sx={{ width: "100px" }}>
                        Time
                      </StyledTableCellThin>
                      <StyledTableCellThin rowSpan={3}>
                        Customer
                      </StyledTableCellThin>
                      <StyledTableCellThin rowSpan={3}>
                        Part No.
                      </StyledTableCellThin>
                      <StyledTableCellVertical rowSpan={3}>
                        <Box className="vertical-text-dock-audit">Plant</Box>
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={3}>
                        <Box className="vertical-text-dock-audit">Lot No.</Box>
                      </StyledTableCellVertical>
                      <StyledTableCell colSpan={5}>Packing</StyledTableCell>
                      <StyledTableCell colSpan={5}>ตรวจคุณภาพ</StyledTableCell>
                      <StyledTableCellVertical colSpan={2} rowSpan={2}>
                        <Box
                          className="vertical-text-dock-audit"
                          sx={{ fontWeight: "bold" }}
                        >
                          ผลการสุ่มตรวจ
                        </Box>
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={3}>
                        <Box className="vertical-text-dock-audit">ผู้จัดทำ</Box>
                      </StyledTableCellVertical>

                      <StyledTableCellVertical rowSpan={3}>
                        <Box className="vertical-text-dock-audit">
                          Verify Order
                        </Box>
                      </StyledTableCellVertical>

                      <StyledTableCellVertical rowSpan={3}>
                        <Box className="vertical-text-dock-audit">
                          ผู้ตรวจสอบ
                        </Box>
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={3}>
                        <Box className="vertical-text-dock-audit">
                          ผู้อนุมัติ
                        </Box>
                      </StyledTableCellVertical>
                      <StyledTableCellThin rowSpan={3}>
                        Action
                      </StyledTableCellThin>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellVertical rowSpan={2}>
                        <Box className="vertical-text-dock-audit">จำนวนส่ง</Box>
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={2}>
                        <Box className="vertical-text-dock-audit">
                          จำนวนส่งจริง
                        </Box>
                      </StyledTableCellVertical>
                      <StyledTableCell colSpan={2}>
                        จำนวนชิ้น/ลัง
                      </StyledTableCell>
                      <StyledTableCellThin rowSpan={2}>
                        TAG ที่สมบูรณ์
                      </StyledTableCellThin>

                      <StyledTableCellVertical rowSpan={2}>
                        <Box className="vertical-text-dock-audit">สนิม</Box>
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={2}>
                        <Box className="vertical-text-dock-audit">เสียรูป</Box>
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={2}>
                        <Box className="vertical-text-dock-audit">การชุบ</Box>
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={2}>
                        <Box className="vertical-text-dock-audit">
                          ความสะอาด
                        </Box>
                      </StyledTableCellVertical>

                      <StyledTableCellVertical rowSpan={2}>
                        <Box className="vertical-text-dock-audit">
                          ตัวอักษรไม่กลับหัว
                        </Box>
                      </StyledTableCellVertical>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellThin>Std.</StyledTableCellThin>
                      <StyledTableCellThin>Actual</StyledTableCellThin>

                      <StyledTableCellThin>OK</StyledTableCellThin>
                      <StyledTableCellThin>NG</StyledTableCellThin>
                    </StyledTableRow>
                  </TableHead>

                  <TableBody>
                    {dataTable && loadingFilterData ? (
                      <StyledTableRow>
                        <StyledTableCell colSpan={25}>
                          <CircularProgress size={"100px"} />
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      rowsToDisplay.map((e, idx) => {
                        return (
                          <StyledTableRow key={page * rowsPerPage + idx}>
                            <StyledTableCell>
                              {page * rowsPerPage + idx + 1}
                            </StyledTableCell>
                            <StyledTableCell>{e.dueDate}</StyledTableCell>
                            <StyledTableCell>{e.customer}</StyledTableCell>
                            <StyledTableCell>{e.partNo}</StyledTableCell>
                            <StyledTableCell>{e.plant}</StyledTableCell>

                            <StyledTableCell>
                              <TextField
                                disabled={
                                  e.verifyOrderBy !== null &&
                                  e.verifyOrderBy !== ""
                                }
                                value={e.pdLotNo}
                                label="ระบุ Lot No."
                                style={{ width: "200px", fontSize: "10px" }}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.value as string,
                                    page * rowsPerPage + idx,
                                    "pdLotNo"
                                  )
                                }
                              />
                            </StyledTableCell>

                            <StyledTableCell>{e.quantity}</StyledTableCell>

                            <StyledTableCell>
                              <StyledTextField
                                type="number"
                                inputProps={{
                                  min: "0",
                                  pattern: "[0-9]*"
                                }}
                                disabled={
                                  e.verifyOrderBy !== null &&
                                  e.verifyOrderBy !== ""
                                }
                                value={e.quantityActual}
                                onChange={(e) =>
                                  handleInputChange(
                                    parseInt(e.target.value, 10),
                                    page * rowsPerPage + idx,
                                    "quantityActual"
                                  )
                                }
                                error={qtyValidateField(
                                  e.quantityActual,
                                  page * rowsPerPage + idx
                                )}
                                helperText={
                                  qtyValidateField(
                                    e.quantityActual,
                                    page * rowsPerPage + idx
                                  )
                                    ? "ค่าไม่ถูกต้อง"
                                    : ""
                                }
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              <StyledTextField
                                type="number"
                                inputProps={{
                                  pattern: "[0-9]*"
                                }}
                                disabled={
                                  e.verifyOrderBy !== null &&
                                  e.verifyOrderBy !== ""
                                }
                                value={e.wipQtyBox}
                                onChange={(e) =>
                                  handleInputChange(
                                    parseInt(e.target.value, 10),
                                    page * rowsPerPage + idx,
                                    "wipQtyBox"
                                  )
                                }
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              <StyledTextField
                                type="number"
                                inputProps={{
                                  pattern: "[0-9]*"
                                }}
                                disabled={
                                  e.verifyOrderBy !== null &&
                                  e.verifyOrderBy !== ""
                                }
                                value={e.wipQtyBoxActual}
                                onChange={(e) =>
                                  handleInputChange(
                                    parseInt(e.target.value, 10),
                                    page * rowsPerPage + idx,
                                    "wipQtyBoxActual"
                                  )
                                }
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              <Checkbox
                                disabled={
                                  e.verifyOrderBy !== null &&
                                  e.verifyOrderBy !== ""
                                }
                                checked={e.tag}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.checked,
                                    page * rowsPerPage + idx,
                                    "tag"
                                  )
                                }
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              <StyledTextField
                                type="number"
                                inputProps={{
                                  pattern: "[0-9]*"
                                }}
                                disabled={
                                  e.verifyOrderBy !== null &&
                                  e.verifyOrderBy !== ""
                                }
                                value={e.rusty}
                                label="สนิม"
                                onChange={(e) =>
                                  handleInputChange(
                                    parseInt(e.target.value, 10),
                                    page * rowsPerPage + idx,
                                    "rusty"
                                  )
                                }
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              <StyledTextField
                                type="number"
                                inputProps={{
                                  pattern: "[0-9]*"
                                }}
                                disabled={
                                  e.verifyOrderBy !== null &&
                                  e.verifyOrderBy !== ""
                                }
                                value={e.deformed}
                                label="เสียรูป"
                                onChange={(e) =>
                                  handleInputChange(
                                    parseInt(e.target.value, 10),
                                    page * rowsPerPage + idx,
                                    "deformed"
                                  )
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <StyledTextField
                                type="number"
                                inputProps={{
                                  pattern: "[0-9]*"
                                }}
                                disabled={
                                  e.verifyOrderBy !== null &&
                                  e.verifyOrderBy !== ""
                                }
                                value={e.plating}
                                label="การชุบ"
                                onChange={(e) =>
                                  handleInputChange(
                                    parseInt(e.target.value, 10),
                                    page * rowsPerPage + idx,
                                    "plating"
                                  )
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <StyledTextField
                                type="number"
                                inputProps={{
                                  pattern: "[0-9]*"
                                }}
                                disabled={
                                  e.verifyOrderBy !== null &&
                                  e.verifyOrderBy !== ""
                                }
                                value={e.cleanliness}
                                label="ความสะอาด"
                                onChange={(e) =>
                                  handleInputChange(
                                    parseInt(e.target.value, 10),
                                    page * rowsPerPage + idx,
                                    "cleanliness"
                                  )
                                }
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              <StyledTextField
                                type="number"
                                inputProps={{
                                  pattern: "[0-9]*"
                                }}
                                disabled={
                                  e.verifyOrderBy !== null &&
                                  e.verifyOrderBy !== ""
                                }
                                value={e.upSideDownLetter}
                                onChange={(e) =>
                                  handleInputChange(
                                    parseInt(e.target.value, 10),
                                    page * rowsPerPage + idx,
                                    "upSideDownLetter"
                                  )
                                }
                              />
                            </StyledTableCell>

                            <StyledTableCell colSpan={2}>
                              <FormControl>
                                <RadioGroup
                                  value={e.randomInspection}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value as string,
                                      page * rowsPerPage + idx,
                                      "randomInspection"
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    disabled={
                                      e.verifyOrderBy !== null &&
                                      e.verifyOrderBy !== ""
                                    }
                                    value="OK"
                                    control={<ThemedRadio />}
                                    label="OK"
                                  />
                                  <FormControlLabel
                                    disabled={
                                      e.verifyOrderBy !== null &&
                                      e.verifyOrderBy !== ""
                                    }
                                    value="NG"
                                    control={<ThemedRadio />}
                                    label="NG"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </StyledTableCell>

                            <StyledTableCell>
                              <Autocomplete
                                disabled={
                                  e.verifyOrderBy !== null &&
                                  e.verifyOrderBy !== ""
                                }
                                value={e.officer}
                                onChange={(e, selectValue) =>
                                  handleInputChange(
                                    selectValue as string,
                                    page * rowsPerPage + idx,
                                    "officer"
                                  )
                                }
                                options={qcEmployeeList}
                                renderInput={(params) => (
                                  <ThemedTextField
                                    {...params}
                                    label="จัดทำโดย"
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: "150px" }}
                                  />
                                )}
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              {e.verifyOrderBy ? (
                                e.verifyOrderBy
                              ) : (
                                <ThemedButton
                                  sx={{ bgcolor: theme.palette.warning.main }}
                                  variant="outlined"
                                  onClick={() => {
                                    swalCaution(
                                      `Verify this order by name: ${user.employee}`,
                                      "verifyOrderBy",
                                      page * rowsPerPage + idx
                                    )
                                  }}
                                >
                                  Verify
                                </ThemedButton>
                              )}
                            </StyledTableCell>

                            <StyledTableCell>
                              <Autocomplete
                                sx={
                                  onlyDisplayToLeader
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                                value={e.inspector}
                                onChange={(e, selectValue) =>
                                  handleInputChange(
                                    selectValue as string,
                                    page * rowsPerPage + idx,
                                    "inspector"
                                  )
                                }
                                options={inspectorsList}
                                renderInput={(params) => (
                                  <ThemedTextField
                                    {...params}
                                    label="ตรวจสอบโดย"
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: "150px" }}
                                  />
                                )}
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              <Autocomplete
                                sx={
                                  onlyDisplayToLeader
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                                value={e.approver}
                                onChange={(e, selectValue) =>
                                  handleInputChange(
                                    selectValue as string,
                                    page * rowsPerPage + idx,
                                    "approver"
                                  )
                                }
                                options={approverList}
                                renderInput={(params) => (
                                  <ThemedTextField
                                    {...params}
                                    label="อนุมัติโดย"
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: "150px" }}
                                  />
                                )}
                              />
                            </StyledTableCell>

                            <StyledTableCell>
                              <TextField
                                disabled={
                                  e.verifyOrderBy !== null &&
                                  e.verifyOrderBy !== ""
                                }
                                value={e.action}
                                label="Remark"
                                multiline
                                maxRows={4}
                                style={{ width: "200px", fontSize: "10px" }}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.value as string,
                                    page * rowsPerPage + idx,
                                    "action"
                                  )
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{ p: "5px", backgroundColor: theme.palette.warning.light }}
              >
                <TablePagination
                  rowsPerPageOptions={[3, 5, 10]}
                  component="div"
                  count={dataTable.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} sx={{ mt: "40px" }}>
            <ThemedLoadingButton
              loading={loadingWhilePUTData}
              variant="contained"
              sx={{ width: "200px", fontWeight: 600, fontSize: "20px" }}
              onClick={handleUpdateButton}
            >
              บันทึกข้อมูล
            </ThemedLoadingButton>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default CreateDockAuditCheckSheet
