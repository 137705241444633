import { gql } from "@apollo/client"

export const ADD_FG = gql`
  mutation AddFgMonthlyStock(
    $date: String!
    $partNo: String
    $balanceOs: Float
    $balanceOOs: Float
    $balancePlanOs: Float
    $balanceF1: Float
    $balanceF2: Float
    $balanceFollowUp: Float
    $balActualProductionOsf1: Float
  ) {
    addFgMonthlyStock(
      date: $date
      partNo: $partNo
      balanceOs: $balanceOs
      balanceOOs: $balanceOOs
      balancePlanOs: $balancePlanOs
      balanceF1: $balanceF1
      balanceF2: $balanceF2
      balanceFollowUp: $balanceFollowUp
      balActualProductionOSF1: $balActualProductionOsf1
    )
  }
`

export const ADD_DUE_DATES = gql`
  mutation MasterplanManualInput($otherplan: Master_Plan_Other!) {
    masterplanManualInput(otherplan: $otherplan)
  }
`

export const MP_ADD_REMOVE = gql`
  mutation AddOrMinusDueDate(
    $partNo: String!
    $quantity: Float
    $dueDate: String
  ) {
    addOrMinusDueDate(partNo: $partNo, quantity: $quantity, dueDate: $dueDate)
  }
`
export const MP_UPDATE_ACTUAL_PRODUCTION = gql`
  mutation UpdateActualProduction(
    $partNo: String
    $actualProduction: Float
    $dueDate: String
    $factory: String!
  ) {
    updateActualProduction(
      partNo: $partNo
      actualProduction: $actualProduction
      dueDate: $dueDate
      factory: $factory
    )
  }
`
export const MP_UPLOAD_FILE = gql`
  mutation MasterplanMMTH($file: Upload!) {
    masterplanMMTH(file: $file)
  }
`
export const UPDATE_FG_STOCK = gql`
  mutation UpdateFGStock($data: [fgStock]) {
    updateFGStock(data: $data)
  }
`

export const ORDER_UPLOAD_FILE = gql`
  mutation UploadOrderMasterPlan($file: Upload, $customer: String) {
    uploadOrderMasterPlan(file: $file, customer: $customer)
  }
`
export const FETCH_FG_STOCK = gql`
  query FindFgMonthlyStock($partNo: String, $date: String) {
    findFgMonthlyStock(partNo: $partNo, date: $date) {
      balanceOs
      balanceOOs
      balancePlanOs
      balanceF1
      balanceF2
      balanceFollowUp
      balActualProductionOSF1
      date
    }
  }
`
export const SAVE_ORDER_GREEN_TAG = gql`
  mutation SaveOrderGreenTag($data: [UploadOrderGreenTagInput]) {
    saveOrderGreenTag(data: $data)
  }
`
