import React, { ChangeEvent, ReactElement, useState } from "react"
import {
  Box,
  Grid,
  FormControlLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  RadioGroup,
  Radio,
  tableCellClasses
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { useQuery } from "@apollo/client"
import { GET_WH_LOCATION } from "../../common/Query/WarehouseQuery"
import "../../css/Warehouse/WarehouseReport.css"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"

interface Column {
  id: "partNo" | "quantity" | "jobOrder" | "pdLotNo"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

interface DataLocation {
  partNo: string
  quantity: number
  jobOrder: string
  pdLotNo: string
  location: string
}

const pageTitle = "WH Location Report"

const WhLocationReport: React.FC = (): ReactElement => {
  const [partNo, setPartNo] = useState<string>("")
  const [value, setValue] = React.useState("A")
  const [valueTable, setValueTable] = React.useState(7)
  const getWhLocation = useQuery(GET_WH_LOCATION)
  let rows: DataLocation[] = []

  const columns: Column[] = [
    { id: "partNo", label: "Part no", minWidth: 100 },
    { id: "quantity", label: "Quantity", minWidth: 100 },
    { id: "jobOrder", label: "Job order", minWidth: 100 },
    { id: "pdLotNo", label: "PD Lot No", minWidth: 100 }
  ]
  if (getWhLocation?.data?.whLocationReport) {
    rows = getWhLocation.data.whLocationReport
    if (partNo) {
      rows = getWhLocation.data.whLocationReport.filter(
        (e: DataLocation) => e.partNo === partNo
      )
    }
  }
  const tableLocationA = 7
  const tableLocationB = 6
  const locationTable = "A"

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
      border: "solid 1px black"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: "bold",
      textAlign: "center",
      border: "solid 1px black"
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.selected
    },
    "&:last-child td, &:last-child th": {}
  }))

  const handleChangeLocation = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value === locationTable
      ? setValueTable(tableLocationA)
      : setValueTable(tableLocationB)
    setValue(event.target.value)
  }
  const TableLocation = (valueTable: { valueTable: number }) => {
    return (
      <Table
        stickyHeader
        aria-label="sticky table"
        style={{ marginTop: "20px" }}
        key={valueTable.valueTable}
      >
        <TableHead>
          <StyledTableRow>
            <StyledTableCell
              align="center"
              colSpan={11}
              key={valueTable.valueTable + 1}
            >
              History Location {value}
              {valueTable.valueTable + 1}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.id} align={column.align}>
                {column.label}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows
            .filter(
              (e: DataLocation) =>
                e.location === value + (valueTable.valueTable + 1)
            )
            .map((row, i) => {
              return (
                <StyledTableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    const value = row[column.id]
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </StyledTableCell>
                    )
                  })}
                </StyledTableRow>
              )
            })}
        </TableBody>
      </Table>
    )
  }
  return (
    <Box style={{ marginLeft: "22px" }} component="form">
      <PageLabel
        menuItem={menuItemList.Warehouse}
        menuItemName={Object.keys(menuItemList)[6]}
        menuPageName={pageTitle}
      />
      <ThemedCard style={{ marginTop: "26px" }} className="print-hide">
        <PageLabelCard
          title="Warehouse Location Report"
          subTitle="detail about Warehouse Location Report"
        />
        <Grid container spacing={2} width={"98%"} margin={2}>
          <Grid item xs={12} sm={4}>
            <AutocompletePartNo setPartNo={setPartNo} partNo={partNo} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                width: "auto",
                height: "50px",
                border: " 1px solid"
              }}
              paddingLeft={2}
            >
              <legend
                id="labels"
                style={{
                  marginTop: "-13px",
                  backgroundColor: "white",
                  width: "100px",
                  marginLeft: "5px"
                }}
              >
                Location
              </legend>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleChangeLocation}
              >
                <FormControlLabel value="A" control={<Radio />} label="A1-A7" />
                <FormControlLabel value="B" control={<Radio />} label="B1-B6" />
              </RadioGroup>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mt: 1 }}>
            <ThemedButton variant="outlined" onClick={() => window.print()}>
              Print
            </ThemedButton>
          </Grid>
        </Grid>
      </ThemedCard>
      <div id="pri">
        <Grid container spacing={2}>
          {Array.from(Array(valueTable), (e, i) => {
            return (
              <Grid item xs={12} md={6} key={i}>
                <TableLocation valueTable={i} key={i} />
              </Grid>
            )
          })}
        </Grid>
      </div>
    </Box>
  )
}

export default WhLocationReport
