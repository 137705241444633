import { Box, Grid, Tab, Tabs, ThemeProvider, useTheme } from "@mui/material"
import { useState } from "react"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import EDPCoating from "./Component/EDPCoating"
import ZincBarrel from "./Component/ZincBarrel"
import ZincHanger from "./Component/ZincHanger"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"

interface IPropsTabPanel {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: IPropsTabPanel) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

const MasterZinc: React.FC = () => {
  const [tapMenu, setTapMenu] = useState<number>(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTapMenu(newValue)
  }

  const tapProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    }
  }
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Plate}
        menuItemName={Object.keys(menuItemList)[14]}
        menuPageName={"Master List Part Plating"}
      />
      <ThemedCard>
        <PageLabelCard
          title="Master List Part Plating"
          subTitle="About detail Master List Part Plating"
        />
        <Grid item xs={12} sm={12}>
          <Box className="hidden-print">
            <Tabs value={tapMenu} onChange={handleChange}>
              <Tab label="Zinc Hanger " {...tapProps(0)} />
              <Tab label="Zinc Barrel " {...tapProps(1)} />
              <Tab label="EDP Coating " {...tapProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={tapMenu} index={0}>
            <ZincHanger />
          </TabPanel>
          <TabPanel value={tapMenu} index={1}>
            <ZincBarrel />
          </TabPanel>
          <TabPanel value={tapMenu} index={2}>
            <EDPCoating />
          </TabPanel>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default MasterZinc
