import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  MenuItem,
  useTheme
} from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import moment from "moment"
import { useRef } from "react"
import { IDashBoardProps } from "../../../common/Resources/reuseInterface"
import { IAutoPart } from "../hook/useFetchData"

export const ChartVolumePlanAutoPart = (props: {
  chart?: HighchartsReact.Props
  autoPart: IAutoPart[] | undefined
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const theme = useTheme()
  const { autoPart } = props
  if (!autoPart?.length) return <>Loading...</>
  const options: Highcharts.Options = {
    title: {
      text: `Automotive ${moment().format("YYYY")}-${moment().format("MM")}`,
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    credits: { enabled: false },
    chart: {
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    xAxis: {
      categories: autoPart.map((e: { customer: string }) => e.customer),
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "8px"
        }
      },
      crosshair: true,
      className: "xAxis-style"
    },
    yAxis: [
      {
        labels: {
          style: {
            color: theme.palette.secondary.contrastText
          }
        },
        title: {
          text: "PCS",
          style: {
            color: theme.palette.secondary.contrastText
          }
        }
      }
    ],
    legend: {
      enabled: false
    },
    tooltip: {
      headerFormat: '<span style="font-size:8px"><b>{point.key}: </b></span>',
      pointFormat: '<span style="font-size:8px">{point.y} </span>',
      shared: true,
      useHTML: true
    },
    series: [
      {
        type: "column",
        colorByPoint: true,
        data: autoPart.map((e: { quantity: number }) => e.quantity)
      }
    ],
    exporting: {
      enabled: false
    }
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props.chart}
      />
    </>
  )
}
interface IAutoPartChart extends IDashBoardProps {
  autoPart: IAutoPart[] | undefined
  inventoryType: string
  setInventoryType: (type: string) => void
}

const VolumePlanAutoPart = (props: IAutoPartChart): JSX.Element => {
  const {
    dialogOpen,
    setDialogOpen,
    autoPart,
    inventoryType,
    setInventoryType
  } = props

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        ></DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12} justifyContent={"center"} margin={2}>
              <TextField
                id="factory"
                fullWidth
                label="Type"
                value={inventoryType}
                onChange={(e) => setInventoryType(e.target.value)}
                select
              >
                <MenuItem value={"autoPart"}>Auto Part</MenuItem>
                <MenuItem value={"electronic"}>Electronic</MenuItem>
                <MenuItem value={"furniture"}>Furniture</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={12} xs={12} justifyContent="center">
              <ChartVolumePlanAutoPart autoPart={autoPart} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default VolumePlanAutoPart
