import * as React from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useRef } from "react"

interface IData {
  inventoryMaterial: number
  totalSaleOrder: number
  date: string
}

const MultiChart = (props: {
  chart?: HighchartsReact.Props
  data: IData
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]

  const month = months[new Date(props.data.date).getMonth()]
  const year = new Date(props.data.date).getFullYear()

  const options: Highcharts.Options = {
    title: {
      text: "กราฟเเสดงการเปรียบเทียบ Sale Order VS Mat'l Inventory"
    },
    chart: {
      type: "column"
    },
    xAxis: {
      categories: [month + "  " + year],
      crosshair: true,
      className: "xAxis-style"
    },
    yAxis: [
      {
        title: {
          text: "Total"
        },
        plotLines: [
          {
            value: props.data.totalSaleOrder / 2,
            color: "blue",
            width: 2,
            zIndex: 3,
            label: {
              text: `Sale Order 50% : ${props.data.totalSaleOrder / 2}`,
              style: {
                fontSize: "14px",
                fontWeight: "bold"
              }
            }
          }
        ]
      },
      {
        opposite: true,
        title: {
          text: ""
        }
      },
      {
        opposite: true,
        title: {
          text: ""
        }
      }
    ],
    tooltip: {
      headerFormat: '<span style="font-size:8px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="padding:0">{series.name}: </td>' +
        '<td style="padding:3"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.02,
        borderRadius: 5,
        stacking: "normal"
      }
    },
    series: [
      {
        name: "Sale Order",
        type: "column",
        stack: "inventory",
        color: "#90ed7d",
        data: [props.data.totalSaleOrder],
        dataLabels: [
          {
            enabled: true,
            style: {
              fontSize: "24px"
            }
          }
        ]
      },
      {
        name: "Mat'l Over/Less Stock 50%",
        type: "column",
        color:
          props.data.inventoryMaterial > props.data.totalSaleOrder / 2
            ? "#ff0000"
            : "#ffa500",
        stack: "saleOrder",
        data: [props.data.inventoryMaterial],
        dataLabels: [
          {
            enabled: true,
            style: {
              fontSize: "24px"
            },
            formatter: function () {
              if (
                props.data.inventoryMaterial >
                props.data.totalSaleOrder / 2
              ) {
                return `Over ${(
                  props.data.inventoryMaterial -
                  props.data.totalSaleOrder / 2
                ).toLocaleString()}`
              } else if (
                props.data.inventoryMaterial <
                props.data.totalSaleOrder / 2
              ) {
                return `Less ${(
                  props.data.totalSaleOrder / 2 -
                  props.data.inventoryMaterial
                ).toLocaleString()}`
              }
            }
          }
        ]
      }
    ]
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props.chart}
    />
  )
}
export default MultiChart
