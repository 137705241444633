import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from "react"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import Grid from "@mui/material/Grid"
import "../../css/Document/Document.css"
import AddIcon from "@mui/icons-material/Add"
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { MP_ADD_REMOVE } from "../../common/Mutation/MasterPlanMutation"
import { GET_PARTS_AND_CUSTOMERS } from "./mpcomponents/CustomerAndParts"
import moment from "moment"
import DateAdapter from "@mui/lab/AdapterMoment"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { GET_CUSTOMERS } from "../../common/Query/MasterQuery"
import { menuItemList } from "../../common/Resources/menuItemList"
import { GET_QUANTITY_DUE_DATE } from "../../common/Query/MasterPlanQuery"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"

interface IModifyDueDateProps {
  partNo: string
  factory?: string
  setDueDate: Dispatch<SetStateAction<string>>
  setQuantity: Dispatch<SetStateAction<number>>
}

const pageTitle = "Add | Cancel"

const ModifyDueDate = (props: IModifyDueDateProps) => {
  const { partNo, setDueDate, setQuantity } = props
  const [date, setDate] = useState<Date>(new Date())
  const [modifyValue, setModifyValue] = useState<number>(0)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [modifyDueDate, { loading }] = useMutation(MP_ADD_REMOVE)

  const isCompleted = () => {
    return partNo
  }

  const handleSubmit = () => {
    setOpenDialog(true)
  }

  const handleClick = () => {
    if (!isCompleted()) {
      setOpenDialog(false)
      return swal("Warning", "กรุณาเลือก Part No", "warning")
    }

    submitModifyDueDate()
  }
  const submitModifyDueDate = async () => {
    const result = await modifyDueDate({
      variables: {
        partNo,
        quantity: modifyValue,
        dueDate: moment(date).format("YYYY-MM-DD")
      }
    })
    if (result.data.addOrMinusDueDate)
      swal("Success", "Update Success", "success")
    setOpenDialog(false)
  }

  useEffect(() => {
    setDueDate(moment(date).format("YYYY-MM-DD"))
  }, [date])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              label="เลือก Due Date"
              openTo="year"
              views={["year", "month", "day"]}
              value={date}
              inputFormat="DD-MM-YYYY"
              onChange={(newDate) => {
                setDate(moment(newDate).toDate() as Date)
                setDueDate(moment(newDate).format("YYYY-MM-DD"))
              }}
              renderInput={(params) => (
                <ThemedTextField {...params} fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ThemedTextField
            fullWidth
            label="จำนวน"
            onChange={(e) => {
              setModifyValue(parseFloat(e.target.value))
              setQuantity(Number(e.target.value))
            }}
            placeholder="50, -100"
          />
        </Grid>
        <Grid item xs={2}>
          <ThemedLoadingButton
            loading={loading}
            variant="contained"
            loadingPosition="end"
            endIcon={<AddIcon />}
            onClick={handleSubmit}
            sx={{ mt: 1 }}
          >
            Submit
          </ThemedLoadingButton>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{"Confirm Update Quantity?"}</DialogTitle>
        <DialogActions
          style={{ justifyContent: "flex-start", marginLeft: "24px" }}
        >
          <ThemedButton variant="contained" onClick={handleClick}>
            Agree
          </ThemedButton>
          <ThemedButton
            variant="text"
            contrast
            onClick={() => setOpenDialog(false)}
          >
            Disagree
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const MPAddRemove: React.FC = () => {
  const [customer, setCustomer] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [dueDate, setDueDate] = useState<string>("")
  const [quantity, setQuantity] = useState<number>(0)
  const theme = useTheme()

  const [getQuantity, { data: getDataQuantity }] = useLazyQuery(
    GET_QUANTITY_DUE_DATE
  )
  const CustomerandPartsQuery = () => {
    const customerQuery = useQuery(GET_CUSTOMERS)
    const partNoQuery = useQuery(GET_PARTS_AND_CUSTOMERS)
    return [customerQuery, partNoQuery]
  }
  const [
    { error: customerListError, data: customerListData },
    { error: partNoListError, data: partNoListData }
  ] = CustomerandPartsQuery()
  if (customerListError) return <p>Error: {customerListError}</p>
  if (partNoListError) return <p>Error: {partNoListError}</p>

  let customerList = []
  if (customerListData) {
    customerList = customerListData.customers.map(
      ({ customer }: { customer: string }) => {
        return customer
      }
    )
  }
  interface PartType {
    partNo: string
    customer: string
  }

  let partNoList = []

  if (partNoListData) {
    partNoList = partNoListData.parts.reduce(
      (list: string[], part: PartType) => {
        if (part.customer === customer) {
          list.push(part.partNo)
        }
        return list
      },
      []
    )
  }
  useMemo(async () => {
    if (partNo && dueDate) {
      await getQuantity({
        variables: {
          partNo,
          dueDate
        }
      })
    }
  }, [partNo, dueDate])

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.MasterPlan}
        menuItemName={Object.keys(menuItemList)[7]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="Modify Due Date"
          subTitle="about detail Modify Due Date"
        />
        <Grid container width={"98%"} margin={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <Autocomplete
                value={customer}
                fullWidth
                aria-label="customers-select"
                loading={customerList.length === 0}
                onChange={(event, selectedCustomer) => {
                  setCustomer(selectedCustomer as string)
                  setPartNo("")
                }}
                options={customerList}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Customers" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                key={partNoList}
                value={partNo}
                fullWidth
                aria-label="partno-select"
                onChange={(event, selectedPartNo) => {
                  setPartNo(selectedPartNo as string)
                }}
                disabled={
                  Array.isArray(partNoList) && partNoList.length ? false : true
                }
                options={partNoList}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Part NO." />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ModifyDueDate
                partNo={partNo}
                setDueDate={setDueDate}
                setQuantity={setQuantity}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Before</TableCell>
                  <TableCell>{partNo}</TableCell>
                  <TableCell>{dueDate}</TableCell>
                  <TableCell>{getDataQuantity?.getDueDate || 0}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>After</TableCell>
                  <TableCell>{partNo}</TableCell>
                  <TableCell>{dueDate}</TableCell>
                  <TableCell>{quantity}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default MPAddRemove
