import { DatePicker, LocalizationProvider } from "@mui/lab"
import {
  Autocomplete,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  useTheme
} from "@mui/material"
import DateAdapter from "@mui/lab/AdapterMoment"
import { useMemo, useState, useEffect } from "react"
import moment from "moment"
import { printDiv } from "../PMReport"

import { factoryList, miniLogo } from "../../../common/utilities"
import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined"
import Brightness1Icon from "@mui/icons-material/Brightness1"
import { useQuery } from "@apollo/client"
import { GET_PM_PLAN_YEAR } from "../../../common/Query/MaintenanceQuery"
import { GET_HOLIDAY } from "../../../common/Query/GeneralQuery"
import PMRecord, { IData } from "./PmRecord"
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"

interface IDatePm {
  actionDate: string
  status: boolean
  percent: string
}

interface IReportPMYear {
  machine: string
  code: string
  machineRemark: string
  frequency: string
  line: string
  actionDate: [IDatePm]
}

interface IProps {
  status: boolean
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 25,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
    fontFamily: "Sarabun"
  }
}))

const StyledTableCellApprove = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    textAlign: "center",
    fontSize: 20,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "td, th": {
    border: "1px solid black"
  }
}))

const PmPlanMonth = (props: IProps): JSX.Element => {
  const theme = useTheme()
  const [selectDate, setSelectDate] = useState<Date>(new Date())
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [dataDialog, setDataDialog] = useState<IData>()
  const [reportPMYear, setReportPMYear] = useState<IReportPMYear[]>([])
  const [factory, setFactory] = useState<string>("1")
  const [getPmPlanMonthList, setGetPmPlanMonthList] = useState<IReportPMYear[]>(
    []
  )
  const [dateFrom, setDateFrom] = useState<string>("")
  const [dateTo, setDateTo] = useState<string>("")

  const { data: getHoliday } = useQuery(GET_HOLIDAY)

  const holiday: string[] =
    getHoliday?.getHoliday.map(({ holiday }: { holiday: string }) => holiday) ||
    []

  const checkDay = (date: string): number => {
    const getDay = new Date(date).toLocaleString("en-us", { weekday: "long" })
    const findHoliday = holiday.find((val) => val === date)
    if (findHoliday) {
      return 1
    }
    if (getDay === "Sunday" || getDay === "Saturday") {
      return 2
    } else {
      return 3
    }
  }

  const getPmPlanMonth = useQuery(GET_PM_PLAN_YEAR, {
    variables: {
      year: moment(new Date(selectDate)).format("YYYY"),
      factory,
      month: moment(new Date(selectDate)).format("MM"),
      outsource: props.status
    },
    fetchPolicy: "network-only"
  })

  const makeDatesList = (date: Date): string[] => {
    //used to get length of array
    const daysInMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate()

    const makeDateString = (dateListIndex: number): string => {
      const newDate: string =
        dateListIndex < 10 ? "0" + (dateListIndex + 1) : dateListIndex + 1 + ""
      const month: string =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1 + ""
      const year: string = date.getFullYear() + ""
      const dateString = moment(new Date(`${year}-${month}-${newDate}`)).format(
        "YYYY-MM-DD"
      )
      return dateString
    }
    const dateList: string[] = Array.from(Array(daysInMonth), (_, index) =>
      makeDateString(index)
    )
    return dateList
  }

  const dateListArray: string[] = useMemo(
    () => makeDatesList(selectDate),
    [selectDate]
  )

  useEffect(() => {
    setGetPmPlanMonthList(getPmPlanMonth?.data?.pmYearReport || [])
  }, [getPmPlanMonth])

  const handleOpenDialog = (data: IData) => {
    setDataDialog(data)
    setOpenDialog(true)
  }

  const checkDate = (index: number, dateArr: IDatePm[], status: boolean) => {
    const getDate = dateArr.map((date) => {
      return {
        date: new Date(date.actionDate).getDate(),
        status: date.status
      }
    })

    const date = getDate.find(
      (value) => value.date === index && value.status === status
    )
    if (date && date.status) return 1
    if (date && !date.status) return 2
    return 3
  }

  const filterDate = () => {
    if (dateFrom && dateTo) {
      const dateFromSearch = `${moment(selectDate).format("YYYY-MM")}-${
        Number(dateFrom) < 10 ? "0" + dateFrom : dateFrom
      }`
      const dateToSearch = `${moment(selectDate).format("YYYY-MM")}-${
        Number(dateTo) < 10 ? "0" + dateTo : dateTo
      }`

      if (reportPMYear.length === 0) {
        setReportPMYear(getPmPlanMonthList)
        const filter = getPmPlanMonthList.filter((val) => {
          return (
            val.actionDate[0].actionDate >= dateFromSearch &&
            val.actionDate[0].actionDate <= dateToSearch
          )
        })
        setGetPmPlanMonthList(filter)
      } else {
        const filter = reportPMYear.filter((val) => {
          return (
            val.actionDate[0].actionDate >= dateFromSearch &&
            val.actionDate[0].actionDate <= dateToSearch
          )
        })

        setGetPmPlanMonthList(filter)
      }
    }
  }

  return (
    <>
      <Grid container spacing={2} width={"98%"} padding={2}>
        <Grid item sm={2}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              label="เลือกเดือนและปี"
              views={["year", "month"]}
              value={selectDate}
              onChange={(newSelectDate) => {
                setSelectDate(moment(newSelectDate).toDate() as Date)
              }}
              renderInput={(params) => (
                <ThemedTextField {...params} fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Autocomplete
            fullWidth
            disablePortal
            value={factory}
            renderInput={(params) => (
              <ThemedTextField {...params} label="Select Factory" />
            )}
            onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
            options={factoryList}
          />
        </Grid>
        <Grid item sm={8} />
        <Grid item sm={2}>
          <Autocomplete
            value={dateFrom}
            fullWidth
            onChange={(event, selectDateFrom) => {
              setDateFrom(selectDateFrom ? selectDateFrom : "")
            }}
            options={dateListArray.map((each, index) => String(index + 1))}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                label="Date From"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item sm={2}>
          <Autocomplete
            value={dateTo}
            fullWidth
            onChange={(event, selectDateTo) => {
              setDateTo(selectDateTo ? selectDateTo : "")
            }}
            options={dateListArray.map((each, index) => String(index + 1))}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                label="Date To"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item sm={4}>
          <ThemedButton
            variant="contained"
            style={{
              backgroundColor: theme.palette.primary.main,
              marginTop: "-8px"
            }}
            onClick={() => filterDate()}
          >
            Search
          </ThemedButton>

          <ThemedButton
            variant="contained"
            sx={{ ml: 2, mt: 1, mb: 2, backgroundColor: "yellowgreen" }}
            onClick={() => printDiv("pmMonth")}
          >
            Print
          </ThemedButton>
        </Grid>
      </Grid>
      <div id="pmMonth">
        <Table sx={{ zoom: "50%" }} className="print-sm-order">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell colSpan={2}>
                <img src={miniLogo} alt="" width={"100px"} />
              </StyledTableCell>
              <StyledTableCell colSpan={dateListArray.length + 7}>
                Preventive Maintenance Plan ประจำเดือน{" "}
                {moment(new Date(selectDate)).format("MM-YYYY")}
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }} colSpan={3}>
                Doc. No. <br />
                F-MT-01-05 <br />
                <span style={{ fontSize: "14px" }}>
                  Effective Date : 30/10/12 Rev :00
                </span>
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell rowSpan={2} colSpan={2}>
                No.
              </StyledTableCell>
              <StyledTableCell rowSpan={2} colSpan={3}>
                Machine
              </StyledTableCell>
              <StyledTableCell rowSpan={2} colSpan={3}>
                Machine Code
              </StyledTableCell>
              <StyledTableCell rowSpan={2} colSpan={1}>
                Inchange
              </StyledTableCell>
              <StyledTableCell rowSpan={2} colSpan={1} />
              <StyledTableCell colSpan={dateListArray.length}>
                Date
              </StyledTableCell>
              <StyledTableCell rowSpan={2}>
                Progress <br /> (%)
              </StyledTableCell>
              <StyledTableCell rowSpan={2}>Remark</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              {dateListArray.map((value, index) => {
                return (
                  <StyledTableCell
                    key={value + index}
                    style={
                      checkDay(value) === 2 ? { backgroundColor: "red" } : {}
                    }
                  >
                    {index + 1}
                  </StyledTableCell>
                )
              })}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {getPmPlanMonthList.map((value, index: number) => {
              return (
                <>
                  <StyledTableRow key={value.machine + index}>
                    <StyledTableCell rowSpan={2} colSpan={2}>
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell
                      rowSpan={2}
                      colSpan={3}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      <div style={{ fontSize: "25px" }}>{value.machine}</div>
                    </StyledTableCell>
                    <StyledTableCell
                      rowSpan={2}
                      colSpan={3}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      <div style={{ fontSize: "25px" }}>{value.code}</div>
                    </StyledTableCell>
                    <StyledTableCell rowSpan={2} />
                    <StyledTableCell rowSpan={1}>Plan</StyledTableCell>

                    {Array.from(Array(dateListArray.length), (num, i) => {
                      return (
                        <StyledTableCell
                          key={i + "1" + dateListArray[i]}
                          sx={{ textAlign: "center", padding: 0 }}
                          onDoubleClick={() => {
                            if (
                              checkDate(i + 1, value.actionDate, false) === 2 &&
                              !props.status
                            ) {
                              handleOpenDialog({
                                machineCode: value.code,
                                actionDate: dateListArray[i],
                                line: value.line,
                                machineName: value.machine,
                                frequency: value.frequency
                              })
                            }
                          }}
                        >
                          {checkDate(i + 1, value.actionDate, false) === 2 ? (
                            <Brightness1OutlinedIcon
                              sx={{ fontSize: "50px" }}
                            />
                          ) : checkDay(dateListArray[i]) === 2 ? (
                            <div style={{ fontSize: "25px" }}>/</div>
                          ) : checkDay(dateListArray[i]) === 1 ? (
                            <>
                              <ChangeHistoryIcon sx={{ fontSize: "50px" }} />
                            </>
                          ) : (
                            <></>
                          )}
                        </StyledTableCell>
                      )
                    })}
                    <StyledTableCell
                      rowSpan={2}
                      style={{ textAlign: "center" }}
                    >
                      <div className="single-chart">
                        <svg
                          viewBox="0 0 36 36"
                          className="circular-chart green"
                        >
                          <path
                            className="circle-bg"
                            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                          />
                          <path
                            className="circle"
                            strokeDasharray={`${value.actionDate[0].percent}, 100`}
                            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                          />
                          <text x="18" y="20.35" className="percentage">
                            {value.actionDate[0].percent + "%"}
                          </text>
                        </svg>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell rowSpan={2} />
                  </StyledTableRow>
                  <StyledTableRow key={index + value.machine}>
                    <StyledTableCell>Actual</StyledTableCell>
                    {Array.from(Array(dateListArray.length), (num, i) => {
                      return (
                        <StyledTableCell
                          key={dateListArray[i] + i}
                          sx={{ textAlign: "center", padding: 0 }}
                          onDoubleClick={() => {
                            if (
                              checkDate(i + 1, value.actionDate, true) === 1 &&
                              !props.status
                            ) {
                              handleOpenDialog({
                                machineCode: value.code,
                                actionDate: dateListArray[i],
                                line: value.line,
                                machineName: value.machine,
                                frequency: value.frequency
                              })
                            }
                          }}
                        >
                          {checkDate(i + 1, value.actionDate, true) === 1 ? (
                            <Brightness1Icon sx={{ fontSize: "50px" }} />
                          ) : checkDay(dateListArray[i]) === 2 ? (
                            <div style={{ fontSize: "25px" }}>/</div>
                          ) : checkDay(dateListArray[i]) === 1 ? (
                            <>
                              <ChangeHistoryIcon sx={{ fontSize: "50px" }} />
                            </>
                          ) : (
                            <></>
                          )}
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                </>
              )
            })}
            <StyledTableRow>
              <StyledTableCell rowSpan={3} colSpan={10}>
                หมายเหตุ : Plan
                <Brightness1OutlinedIcon sx={{ mt: 2, ml: 2 }} />
                <span style={{ marginLeft: "15px" }}>วันเสาร์ อาทิตย์ (/)</span>
                <br />
                <div style={{ marginLeft: "90px" }}>
                  Actual <Brightness1Icon sx={{ mt: 2, ml: 1 }} />
                  <span style={{ marginLeft: "15px", marginRight: "15px" }}>
                    วันหยุด
                  </span>
                  <ChangeHistoryIcon />
                </div>
              </StyledTableCell>
              <StyledTableCellApprove
                rowSpan={3}
                colSpan={dateListArray.length - 14}
                style={{ borderBottom: 0 }}
              />

              <StyledTableCellApprove colSpan={5}>
                ผู้จัดทำ
              </StyledTableCellApprove>
              <StyledTableCellApprove colSpan={5}>
                ผู้ตรวจสอบ
              </StyledTableCellApprove>
              <StyledTableCellApprove colSpan={6}>
                ผู้อนุมัติ
              </StyledTableCellApprove>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCellApprove colSpan={5} sx={{ height: "50px" }} />
              <StyledTableCellApprove colSpan={5} sx={{ height: "50px" }} />
              <StyledTableCellApprove colSpan={6} sx={{ height: "50px" }} />
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCellApprove colSpan={5}>
                หัวหน้าฝ่ายซ่อมบำรุง
              </StyledTableCellApprove>
              <StyledTableCellApprove colSpan={5}>
                ผู้จัดการฝ่ายซ่อมบำรุง
              </StyledTableCellApprove>
              <StyledTableCellApprove colSpan={6}>
                ผู้จัดการโรงงาน
              </StyledTableCellApprove>
            </StyledTableRow>
          </TableBody>
        </Table>
      </div>
      <>
        {openDialog ? (
          <>
            <PMRecord
              dialogOpen={openDialog}
              setDialogOpen={setOpenDialog}
              data={dataDialog}
            />
          </>
        ) : (
          <></>
        )}
      </>
    </>
  )
}

export default PmPlanMonth
