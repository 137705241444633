import { useMPContext } from "../../../context/MPContext"
import { IMasterPlanPartData, IOrder } from "../MPReport"
import {
  StyledTableCell,
  StyledTableRow,
  getFgStock,
  showTableActualF1,
  tableCustomerMahle
} from "../utilities"

interface IProp {
  index: number
  customer: string
  partData: IMasterPlanPartData
  order: IOrder[]
  negativePartFollowUpF1: boolean
  plating: boolean
  followUp: boolean
}
export const TableRowActualF1: React.FC<IProp> = (props: IProp) => {
  const { index, partData, order, negativePartFollowUpF1, plating, followUp } =
    props
  const { maxDueDate, orderCheck, factory } = useMPContext()

  return (
    <>
      <StyledTableRow
        className={index % 2 === 0 ? "display-border" : undefined}
        key={"row-f1" + index + partData.partNo}
        style={
          showTableActualF1(
            partData.customer,
            orderCheck,
            factory,
            negativePartFollowUpF1,
            plating,
            followUp
          )
            ? {}
            : { display: "none" }
        }
      >
        <StyledTableCell
          style={{
            whiteSpace: "nowrap",
            padding: "7px"
          }}
          colSpan={1}
          rowSpan={2}
        >
          {tableCustomerMahle(partData.customer, partData.partNo) ? (
            <>{getFgStock(partData.fgStock, "os")}</>
          ) : (
            <>{getFgStock(partData.fgStock, "1")}</>
          )}
        </StyledTableCell>
        <StyledTableCell
          style={{
            whiteSpace: "nowrap",
            padding: "7px"
          }}
          colSpan={1}
          rowSpan={2}
        >
          {tableCustomerMahle(partData.customer, partData.partNo) ? (
            <>{partData.stockBegin.balanceOs || 0}</>
          ) : (
            <>{partData.stockBegin.balanceF1 || 0}</>
          )}
        </StyledTableCell>
        <StyledTableCell
          style={{
            whiteSpace: "nowrap",
            padding: "7px"
          }}
          colSpan={1}
          rowSpan={1}
        >
          {tableCustomerMahle(partData.customer, partData.partNo) ? (
            <>Actual OS</>
          ) : (
            <>Actual Production F1</>
          )}
        </StyledTableCell>
        {Array.from(Array(maxDueDate.length), (num, i) => {
          if (order[i]) {
            return (
              <StyledTableCell
                colSpan={1}
                rowSpan={1}
                key={"cel-al-f1" + i + partData.partNo}
              >
                <>{order[i].actualProduction}</>
              </StyledTableCell>
            )
          } else {
            return (
              <StyledTableCell
                colSpan={1}
                rowSpan={1}
                key={"cel-al-f1" + i + partData.partNo}
              >
                <>{0}</>
              </StyledTableCell>
            )
          }
        })}
      </StyledTableRow>
      <StyledTableRow
        className={index % 2 === 0 ? "display-border" : undefined}
        key={"row-f1-os" + index + partData.partNo}
      >
        <StyledTableCell
          style={{
            whiteSpace: "nowrap",
            padding: "7px"
          }}
          colSpan={1}
          rowSpan={1}
        >
          {tableCustomerMahle(partData.customer, partData.partNo) ? (
            <>Stock bal. (actual) OS</>
          ) : (
            <>Stock bal. (actual) F1</>
          )}
        </StyledTableCell>
        {Array.from(Array(maxDueDate.length), (num, i) => {
          if (order[i]) {
            return (
              <StyledTableCell
                colSpan={1}
                rowSpan={1}
                key={"cel-bl-f1" + i + partData.partNo}
              >
                <span style={order[i].balActual < 0 ? { color: "red" } : {}}>
                  {order[i].balActual}
                </span>
              </StyledTableCell>
            )
          } else {
            return (
              <StyledTableCell
                colSpan={1}
                rowSpan={1}
                key={"cel-bl-f1" + i + partData.partNo}
              >
                <>{0}</>
              </StyledTableCell>
            )
          }
        })}
      </StyledTableRow>
    </>
  )
}
