import { useQuery } from "@apollo/client"
import {
  Box,
  Grid,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  Card,
  CardHeader,
  useTheme,
  tableCellClasses
} from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import swal from "sweetalert"
import { GET_MATERIALS, GET_PARTS } from "../../common/Query/MasterQuery"
import { GET_STOCK_BY_DATE } from "../../common/Query/MaterialQuery"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { logoTable } from "../../common/utilities"
import "../../css/Materials/SMOrder.css"

interface Column {
  id:
    | "actionDate"
    | "matNo"
    | "partName"
    | "spec"
    | "sizeH"
    | "sizeW"
    | "sizeL"
    | "quantity"
    | "invoiceNo"
    | "customer"
    | "supplier"
    | "packingBy"
    | "remarks"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

interface Data {
  actionDate: string
  matNo: string
  partName: string
  spec: string
  sizeH: string
  sizeW: string
  sizeL: string
  quantity: number
  invoiceNo: string
  customer: string
  supplier: string
  packingBy: string
  remarks: string
}

interface IFilterMatStock {
  actionDate: string
  matNo: string
  invoiceNo: string
  factory: string
  partNo: string
  inOrOut: number
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.contrastText,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: "solid 1px black"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {},

  "td, th": {
    border: "solid 1px black"
  }
}))

const pageTitle = "ใบตรวจสินค้า(sm)"

const columns: Column[] = [
  { id: "actionDate", label: "actionDate", minWidth: 50 },
  { id: "matNo", label: "matNo", minWidth: 100 },
  { id: "partName", label: "partName", minWidth: 100 },
  { id: "spec", label: "spec", minWidth: 100 },
  { id: "sizeH", label: "sizeH", minWidth: 100 },
  { id: "sizeW", label: "sizeW", minWidth: 100 },
  { id: "sizeL", label: "sizeL", minWidth: 50 },
  { id: "quantity", label: "quantity", minWidth: 50 },
  { id: "invoiceNo", label: "invoiceNo", minWidth: 100 },
  { id: "customer", label: "customer", minWidth: 50 },
  { id: "supplier", label: "supplier", minWidth: 50 },
  { id: "packingBy", label: "receiveBy", minWidth: 50 },
  { id: "remarks", label: "remarks", minWidth: 50 }
]

const SMOrder: React.FC = () => {
  const [partNo, setPartNo] = useState<string | null>("")
  const [dateFrom, setDateFrom] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [factory, setFactory] = useState<string>("1")
  const [invoiceNo, setInvoiceNo] = useState<string | null>("")
  const [partData, setPartData] = useState<Data[]>([])
  const getMatStock = useQuery(GET_STOCK_BY_DATE, {
    variables: { matNo: partNo, dateFrom: dateFrom, dateTo: dateTo }
  })
  const getMaterial = useQuery(GET_MATERIALS)
  const { data } = useQuery(GET_PARTS)

  const theme = useTheme()

  let partNoList: Array<string> = []
  let invoiceNoList: Array<string> = []

  if (getMatStock?.data && data) {
    partNoList = data.parts.map((e: { partNo: string }) => e.partNo)
    invoiceNoList = Array.from(
      new Set(
        getMatStock?.data?.getMatStocksByDate
          .filter((mat: { inOrOut: number }) => mat.inOrOut === 1)
          .map((e: { invoiceNo: string }) => e.invoiceNo)
      )
    )
  }
  const rows: Data[] = partData

  const handleSubmit = async () => {
    let filterData = getMatStock?.data?.getMatStocksByDate

    if (filterData?.length === 0) {
      return swal("warning", "ไม่พบข้อมูล", "warning")
    }
    if (filterData?.length === 0) {
      return swal("warning", "ไม่พบข้อมูล", "warning")
    }
    if (factory)
      filterData = filterData?.filter(
        (e: IFilterMatStock) => factory === e.factory && e.inOrOut === 1
      )
    if (partNo)
      filterData = filterData?.filter(
        (e: IFilterMatStock) => partNo === e.matNo && e.inOrOut === 1
      )
    if (invoiceNo)
      filterData = filterData?.filter(
        (e: IFilterMatStock) => invoiceNo === e.invoiceNo && e.inOrOut === 1
      )
    const newData = filterData?.map(
      (order: { matNo: string; partName: string }) => {
        const parts = data.parts.find(
          (element: { partNo: string }) => element.partNo === order.matNo
        )
        const materials = getMaterial.data.materials.find(
          (element: { matNo: string }) => element.matNo === order.matNo
        )
        return {
          ...order,
          ...materials,
          ...parts
        }
      }
    )
    setPartData(newData)
  }

  return (
    <div>
      <PageLabel
        menuItem={menuItemList.Materials}
        menuItemName={Object.keys(menuItemList)[1]}
        menuPageName={pageTitle}
      />

      <ThemedCard
        style={{ marginTop: "25px", minWidth: "1620px" }}
        className="inventory-print"
      >
        <CardHeader
          titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
          title="SM Order"
          subheaderTypographyProps={{ fontFamily: "Sarabun" }}
        />
        {/* <div style={{ margin: "20px" }}> */}
        <Grid container spacing={2} sx={{ p: 2 }}>
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column"
            }}
          > */}
          <Grid item xs={12}>
            <Box component="form">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ThemedTextField
                    fullWidth
                    value={dateFrom}
                    id="dateFrom"
                    label="Date From"
                    type="date"
                    autoComplete="family-name"
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ThemedTextField
                    fullWidth
                    value={dateTo}
                    label="Date To"
                    id="dateTo"
                    type="date"
                    autoComplete="family-name"
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    disablePortal
                    id="factory"
                    options={["1", "2"]}
                    style={{ width: "100%" }}
                    value={factory}
                    onChange={(e, newValue) =>
                      setFactory(newValue ? newValue : "")
                    }
                    renderInput={(params) => (
                      <ThemedTextField {...params} label="Select Factory" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    disablePortal
                    id="partNo"
                    options={partNoList}
                    loading={partNoList.length === 0}
                    style={{ width: "100%" }}
                    sx={{ width: 300 }}
                    onChange={(e, newValue) => setPartNo(newValue)}
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label="Select Part NO"
                        value={partNo}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    disablePortal
                    id="invoice"
                    options={invoiceNoList}
                    style={{ width: "100%" }}
                    sx={{ width: 300 }}
                    onChange={(e, newValue) => setInvoiceNo(newValue)}
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label="Select Invoice NO"
                        value={invoiceNo}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* </Box> */}
          <Grid item container xs={12} spacing={2}>
            <Grid item xs="auto">
              <ThemedButton
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
                style={{
                  whiteSpace: "nowrap"
                }}
              >
                Submit
              </ThemedButton>
            </Grid>
            <Grid item xs="auto">
              <ThemedButton
                contrast
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => window.print()}
                style={{
                  borderColor: theme.palette.primary.main
                }}
              >
                Print
              </ThemedButton>
            </Grid>
          </Grid>
        </Grid>
        {/* </div> */}
      </ThemedCard>

      <Card style={{ marginTop: "25px", minWidth: "1620px" }}>
        <PageLabelCard title="SM Order Report" />

        <Grid container spacing={2} width={"98%"} margin={2}>
          <Grid item sm={12}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={5}>
                    <img src={logoTable} alt="" height="80vh" />
                  </StyledTableCell>

                  <StyledTableCell
                    align="center"
                    colSpan={4}
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "30px"
                    }}
                  >
                    ใบตรวจรับสินค้า (SM)
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    colSpan={4}
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    F-PL-03-09 Effective Date 1-10-21 Rev.03
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        top: 57,
                        minWidth: column.minWidth
                      }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, i) => {
                  return (
                    <StyledTableRow hover tabIndex={-1} key={i}>
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        )
                      })}
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Grid>
          <Grid item sm={12} container justifyContent={"flex-end"}>
            <Table
              style={{
                width: "300px",
                border: "1px solid black",
                height: "100px"
              }}
            >
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    style={{ height: "100px" }}
                  ></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Approved
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Checked
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}
export default SMOrder
