import "../../css/accounting/accountingCSS.css"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import {
  Autocomplete,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { useEffect, useState } from "react"
import moment from "moment"
import DateAdapter from "@mui/lab/AdapterMoment"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { useLazyQuery, useQuery } from "@apollo/client"
import {
  GET_BUSINESS_PLACE,
  GET_INVOICE_REPORT,
  GET_NEW_PRICE_CONFIRM_NO,
  GET_STATUS,
  GET_VAT_STATUS
} from "../../common/Query/InvoiceReport"
import { miniLogo } from "../../common/utilities"
import swal from "sweetalert"
import { CSVLink } from "react-csv"
import PrintIcon from "@mui/icons-material/Print"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 29,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 30,
    fontFamily: "Sarabun",
    textAlign: "center"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },

  "td, th": {
    border: "1px solid black"
  }
}))

interface IQuantity {
  date: string
  quantity: number
}

interface IReportFinalData {
  partNo: string
  diffUnitPrice: number
  lastAdjustPrice: number
  newUnitPrice: number
  quantity: IQuantity[]
  newPriceConfirmNo: string
  differentPrice: number
}

interface IReportInvoice {
  partNo: string
  lastAdjustPrice: number
  newUnitPrice: number
  diffUnitPrice: number
  status: string
  quantity: number
  month: string
  year: string
  differentPrice: number
  vatStatus: string
  businessPlace: string
  newPriceConfirmNo: string
}

const InvoiceReport = (): JSX.Element => {
  const [selectDateStart, setSelectDateStart] = useState<Date>(new Date())
  const [selectDateEnd, setSelectDateEnd] = useState<Date>(new Date())
  const [monthLength, setMonthLength] = useState<string[]>([])
  const [getBusiness, setGetBusiness] = useState<string>("")
  const [getStatus, setGetStatus] = useState<string>("")
  const [getNewPriceConfirmNo, setGetNewPriceConfirmNo] = useState<string>("")
  const [getVatStatus, setGetVatStatus] = useState<string>("NO-VAT")
  const [getDataInvoice, setGetDataInvoice] = useState<IReportFinalData[]>([])

  useEffect(() => {
    const monthStart = new Date(selectDateStart).getMonth() + 1
    const monthEnd = new Date(selectDateEnd).getMonth() + 1
    const yearStart = new Date(selectDateStart).getFullYear()
    const yearEnd = new Date(selectDateEnd).getFullYear()

    const monthLength = []

    if (yearStart === yearEnd) {
      for (let i = monthStart; i <= monthEnd; i++) {
        monthLength.push(`${i}-${yearStart}`)
      }
    } else {
      for (let i = monthStart; i <= 12 + monthEnd; i++) {
        if (i <= 12) {
          monthLength.push(`${i}-${yearStart}`)
        } else {
          monthLength.push(`${i - 12}-${yearEnd}`)
        }
      }
    }

    setMonthLength(monthLength)
  }, [selectDateStart, selectDateEnd])

  const theme = useTheme()

  const [getData, { loading }] = useLazyQuery(GET_INVOICE_REPORT, {
    fetchPolicy: "network-only"
  })

  const { data: vatListStatus } = useQuery(GET_VAT_STATUS, {
    fetchPolicy: "network-only"
  })
  let vatStatusList = []
  if (vatListStatus) {
    vatStatusList = vatListStatus.getVatStatus || []
  }

  const { data: status } = useQuery(GET_STATUS, { fetchPolicy: "network-only" })
  let statusList = []
  if (status) {
    statusList = status.getStatus || []
  }

  const { data: dataBusiness } = useQuery(GET_BUSINESS_PLACE, {
    fetchPolicy: "network-only"
  })
  let businessList = []
  if (dataBusiness) {
    businessList = dataBusiness.getBusinessPlace || []
  }

  const { data: newPriceConfirmNo } = useQuery(GET_NEW_PRICE_CONFIRM_NO, {
    fetchPolicy: "network-only"
  })
  let newPriceConfirmNoList = []
  if (newPriceConfirmNo) {
    newPriceConfirmNoList = newPriceConfirmNo.getNewPriceConfirmNo || []
  }

  const handleSubmitData = async () => {
    const data = await getData({
      variables: {
        dateStart: selectDateStart,
        dateEnd: selectDateEnd
      }
    })

    const fetchData = data?.data?.quantityAll || []

    let dataShow: IReportInvoice[] = fetchData

    if (getBusiness) {
      dataShow = dataShow.filter((obj: IReportInvoice) => {
        return obj.businessPlace === getBusiness
      })
    }

    if (getVatStatus) {
      dataShow = dataShow.filter((obj: IReportInvoice) => {
        return obj.vatStatus === getVatStatus
      })
    }

    if (getStatus) {
      dataShow = dataShow.filter((obj: IReportInvoice) => {
        return obj.status === getStatus
      })
    }

    if (getNewPriceConfirmNo) {
      dataShow = dataShow.filter((obj: IReportInvoice) => {
        return obj.newPriceConfirmNo === getNewPriceConfirmNo
      })
    }

    const uniquePartNo = dataShow.reduce(
      (prev: string[], cur: IReportInvoice) =>
        prev.includes(cur.partNo + "|" + cur.diffUnitPrice)
          ? prev
          : prev.concat(cur.partNo + "|" + cur.diffUnitPrice),
      []
    )

    const dataFinal = uniquePartNo.map((part) => {
      const [partNo, diffUnitPrice] = part.split("|")
      const filterFindData = dataShow.filter((data) => {
        return (
          data.partNo === partNo && data.diffUnitPrice === Number(diffUnitPrice)
        )
      })
      const filterQuantity = filterFindData.map((data) => {
        return {
          date: data.month + "-" + data.year,
          quantity: data.quantity
        }
      })
      return {
        partNo,
        diffUnitPrice: Number(diffUnitPrice),
        lastAdjustPrice: filterFindData[0].lastAdjustPrice,
        newUnitPrice: filterFindData[0].newUnitPrice,
        quantity: filterQuantity,
        newPriceConfirmNo: filterFindData[0].newPriceConfirmNo,
        differentPrice: filterFindData[0].differentPrice
      }
    })

    setGetDataInvoice(dataFinal)

    if (!dataShow.length) {
      swal("Warning", "ไม่พบข้อมูลการค้นหา", "warning")
    }
  }

  const sumQuantityPerMOnth = (month: string) => {
    const totalQuantity = getDataInvoice.reduce(
      (prev: number, cur: IReportFinalData) => {
        const quantity =
          cur?.quantity.find((data: IQuantity) => {
            return data.date === month
          })?.quantity || 0

        return prev + quantity
      },
      0
    )
    return totalQuantity
  }

  const sumQuantityAll = () => {
    const totalQuantity = getDataInvoice.reduce(
      (prev: number, cur: IReportFinalData) => {
        const quantity = cur?.quantity.reduce(
          (prev: number, cur: IQuantity) => {
            return prev + cur.quantity
          },
          0
        )

        return prev + quantity
      },
      0
    )
    return totalQuantity
  }

  const totalAll = () => {
    const totalQuantity = monthLength.reduce(
      (a, v) => ({ ...a, [v]: sumQuantityPerMOnth(v) }),
      {}
    )
    const dataPush = {
      partNo: "total",
      diffUnitPrice: 0,
      lastAdjustPrice: 0,
      newUnitPrice: 0,
      newPriceConfirmNo: "-",
      differentPrice: 0,
      totalPcs: sumQuantityAll(),
      totalAmountBaths: sumTotalAmountBaths(),
      ...totalQuantity
    }

    const dataReturn = getDataInvoice.map((data) => {
      const quantity = data.quantity.reduce(
        (a, v) => ({ ...a, [v.date]: v.quantity }),
        {}
      )

      const totalPcs = data.quantity.reduce((prev: number, cur: IQuantity) => {
        return prev + cur.quantity
      }, 0)

      const totalAmountBaths =
        data.quantity.reduce((prev: number, cur: IQuantity) => {
          return prev + cur.quantity
        }, 0) * data.diffUnitPrice

      return {
        partNo: data.partNo,
        diffUnitPrice: data.diffUnitPrice,
        lastAdjustPrice: data.lastAdjustPrice,
        newUnitPrice: data.newUnitPrice,
        newPriceConfirmNo: data.newPriceConfirmNo,
        differentPrice: data.differentPrice,
        totalPcs: totalPcs,
        totalAmountBaths: String(totalAmountBaths),
        ...quantity
      }
    })
    dataReturn.push(dataPush)
    return dataReturn
  }

  const totalSumAmount = (value: IReportFinalData) => {
    return Number(
      (
        value.quantity.reduce((prev: number, cur: IQuantity) => {
          return prev + cur.quantity
        }, 0) * value.diffUnitPrice
      ).toFixed(2)
    )
  }

  const sumTotalAmountBaths = () => {
    return getDataInvoice
      .reduce((prev: number, cur: IReportFinalData) => {
        return (prev += totalSumAmount(cur))
      }, 0)
      .toFixed(2)
  }

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Account}
        menuItemName={Object.keys(menuItemList)[13]}
        menuPageName={"Invoice Report"}
      />
      <ThemedCard>
        <PageLabelCard
          title="Invoice Report"
          subTitle="About detail Invoice Report"
        />
        <div className={"label_navbar"}>
          <Grid container spacing={2} width={"98%"} padding={2}>
            <Grid item sm={3}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="เลือก เดือน วัน ปี"
                  views={["year", "month", "day"]}
                  value={selectDateStart}
                  onChange={(newSelectDate) => {
                    setSelectDateStart(moment(newSelectDate).toDate() as Date)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField {...params} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={3}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="ถึง เดือน วัน ปี"
                  views={["year", "month", "day"]}
                  value={selectDateEnd}
                  onChange={(newSelectDate) => {
                    setSelectDateEnd(moment(newSelectDate).toDate() as Date)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField {...params} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                value={getVatStatus}
                fullWidth
                aria-label="vatStatus-select"
                onChange={(event, selectedVatStatus) => {
                  setGetVatStatus(selectedVatStatus as string)
                }}
                options={vatStatusList}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Vat Status" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                value={getBusiness}
                fullWidth
                aria-label="business-select"
                onChange={(event, selectedBusiness) => {
                  setGetBusiness(selectedBusiness as string)
                }}
                options={businessList}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Business Place" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                value={getStatus}
                fullWidth
                aria-label="status-select"
                onChange={(event, selectedStatus) => {
                  setGetStatus(selectedStatus as string)
                }}
                options={statusList}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="status" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                value={getNewPriceConfirmNo}
                fullWidth
                aria-label="status-select"
                onChange={(event, selectedNewPriceConfirmNo) => {
                  setGetNewPriceConfirmNo(selectedNewPriceConfirmNo as string)
                }}
                options={newPriceConfirmNoList}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="New Price Confirm No." />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} margin={2}>
              <ThemedLoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                style={{ backgroundColor: theme.palette.primary.main }}
                onClick={handleSubmitData}
              >
                Find data
              </ThemedLoadingButton>
              {getDataInvoice.length ? (
                <>
                  <ThemedButton
                    contrast
                    variant="outlined"
                    endIcon={<PrintIcon />}
                    style={{
                      color: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                      marginRight: "10px",
                      marginLeft: "10px"
                    }}
                    onClick={() => window.print()}
                  >
                    Print
                  </ThemedButton>
                  <CSVLink
                    data={totalAll()}
                    filename={`${moment(selectDateStart).format(
                      "DD-MM-YYYY"
                    )}To${moment(selectDateEnd).format("DD-MM-YYYY")}`}
                  >
                    <ThemedButton
                      variant="contained"
                      style={{
                        marginLeft: "15px",
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? "yellowgreen"
                            : "darkgreen"
                      }}
                    >
                      Download CSV
                    </ThemedButton>
                  </CSVLink>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </div>
        <Table sx={{ zoom: "50%" }} className="print-sm-order">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell colSpan={2}>
                <img src={miniLogo} alt="" width={"100px"} />
              </StyledTableCell>
              <StyledTableCell colSpan={8 + monthLength.length}>
                Invoice Report ในช่วงเดือน{" "}
                {moment(new Date(selectDateStart)).format("MM-YYYY")} to{" "}
                {moment(new Date(selectDateEnd)).format("MM-YYYY")}
              </StyledTableCell>
              <StyledTableCell colSpan={3 + monthLength.length}>
                Business: {getBusiness}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell rowSpan={2} colSpan={2}>
                Item No.
              </StyledTableCell>
              <StyledTableCell rowSpan={2} colSpan={2}>
                Part No.
              </StyledTableCell>
              <StyledTableCell rowSpan={2} colSpan={2}>
                Price No.
              </StyledTableCell>
              <StyledTableCell rowSpan={1} colSpan={2}>
                Price
              </StyledTableCell>
              <StyledTableCell rowSpan={2} colSpan={2}>
                Diff <br /> Price
              </StyledTableCell>
              <StyledTableCell rowSpan={1} colSpan={1 + monthLength.length}>
                Quantity
              </StyledTableCell>
              <StyledTableCell rowSpan={2} colSpan={2}>
                Total Amount
                <br /> Baths
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                Old <br /> pcs.
              </StyledTableCell>
              <StyledTableCell>
                New <br /> pcs.
              </StyledTableCell>
              {monthLength.map((month) => {
                return (
                  <StyledTableCell key={month} colSpan={1}>
                    {month} <br /> pcs.
                  </StyledTableCell>
                )
              })}
              <StyledTableCell colSpan={1}>
                Total <br /> pcs.
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {getDataInvoice.map((value, i) => {
              return (
                <StyledTableRow key={value.partNo + i}>
                  <StyledTableCell colSpan={2}>{i + 1}</StyledTableCell>
                  <StyledTableCell colSpan={2}>{value.partNo}</StyledTableCell>
                  <StyledTableCell colSpan={2}>
                    {value.newPriceConfirmNo}
                  </StyledTableCell>
                  <StyledTableCell>{value.lastAdjustPrice}</StyledTableCell>
                  <StyledTableCell>{value.newUnitPrice}</StyledTableCell>
                  <StyledTableCell colSpan={2}>
                    {value.diffUnitPrice}
                  </StyledTableCell>
                  {monthLength.map((month, index) => {
                    return (
                      <StyledTableCell key={month + index} colSpan={1}>
                        {value.quantity.find((data) => {
                          return data.date === month
                        })?.quantity || 0}
                      </StyledTableCell>
                    )
                  })}
                  <StyledTableCell colSpan={1}>
                    {value.quantity.reduce((prev: number, cur: IQuantity) => {
                      return prev + cur.quantity
                    }, 0)}
                  </StyledTableCell>
                  <StyledTableCell colSpan={2}>
                    {totalSumAmount(value)}
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
            <StyledTableRow>
              <StyledTableCell colSpan={2}>Total</StyledTableCell>
              <StyledTableCell colSpan={2}></StyledTableCell>
              <StyledTableCell colSpan={2}></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell colSpan={2}></StyledTableCell>
              {monthLength.map((month) => {
                return (
                  <StyledTableCell key={month} colSpan={1}>
                    {sumQuantityPerMOnth(month)}
                  </StyledTableCell>
                )
              })}
              <StyledTableCell>{sumQuantityAll()}</StyledTableCell>
              <StyledTableCell>{sumTotalAmountBaths()}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default InvoiceReport
