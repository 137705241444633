import { Circle, Clear, UploadFile } from "@mui/icons-material"
import {
  Box,
  Button,
  InputLabel,
  LinearProgress,
  useTheme
} from "@mui/material"
import React, { ChangeEvent, Dispatch, SetStateAction } from "react"

interface IUploadFileBoxProps {
  selectedFile?: File | null
  error?: boolean
  changeHandler: (event: ChangeEvent<HTMLInputElement>) => void
}

interface ISelectedFileBoxProps {
  selectedFile: File | null
  setSelectedFile: Dispatch<SetStateAction<File | null>>
  createLoading?: boolean
}

export const SelectedFileBox = (props: ISelectedFileBoxProps): JSX.Element => {
  const { setSelectedFile, selectedFile, createLoading } = props
  const theme = useTheme()

  return (
    <Box
      component="span"
      sx={
        selectedFile
          ? {
              padding: "33px",
              display: "flex",
              alignItems: "center",
              color: theme.palette.secondary.contrastText
            }
          : { display: "none" }
      }
    >
      <UploadFile
        style={{
          fontSize: "40px",
          padding: "6px",
          backgroundColor: "#DFE5E3",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          color: theme.palette.primary.main
        }}
      ></UploadFile>
      <div style={{ display: "grid" }}>
        <span style={{ fontSize: "16px", paddingLeft: "10px" }}>
          {selectedFile?.name}
        </span>
        <InputLabel
          style={{
            fontSize: "14px",
            paddingLeft: "10px",
            color: theme.palette.secondary.contrastText
          }}
        >
          {(Number(selectedFile?.size) / 1024).toFixed(2)}kb{" "}
          {createLoading ? <LinearProgress /> : <></>}
          <Circle style={{ fontSize: "5px" }} />
          {!createLoading ? "" : " Complete"}
        </InputLabel>
      </div>
      <Button onClick={() => setSelectedFile(null)}>
        <Clear style={{ color: theme.palette.secondary.contrastText }} />
      </Button>
    </Box>
  )
}

export const UploadFileBox = (props: IUploadFileBoxProps): JSX.Element => {
  const { changeHandler, error, selectedFile } = props
  const theme = useTheme()

  return (
    <Box
      component="span"
      sx={
        !selectedFile && error
          ? { p: 2, border: "1px dashed red", display: "grid" }
          : { p: 2, border: "1px dashed grey", display: "grid" }
      }
    >
      <Button
        component="label"
        style={{
          textTransform: "none",
          textAlign: "revert",
          display: "grid",
          backgroundColor: theme.palette.secondary.main
        }}
      >
        <UploadFile
          style={{
            fontSize: "40px",
            padding: "6px",
            backgroundColor: "#DFE5E3",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            marginLeft: "40%"
          }}
        ></UploadFile>
        <input type="file" onChange={changeHandler} hidden />
        <div>
          <label
            style={{
              fontSize: "16px",
              textDecoration: "underline",
              color:
                theme.palette.mode === "dark" ? theme.palette.warning.main : ""
            }}
          >
            Click to upload
          </label>
          <label
            style={{
              fontSize: "16px",
              color: theme.palette.secondary.contrastText
            }}
          >
            {` or drag and drop`}
          </label>
        </div>
      </Button>
    </Box>
  )
}

export default UploadFileBox
