import { useLazyQuery, useQuery } from "@apollo/client"
import {
  Grid,
  Autocomplete,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
  Typography,
  ThemeProvider
} from "@mui/material"
import React, { useState } from "react"
import { GET_LINE } from "../../common/Query/GeneralQuery"
import {
  GET_CUSTOMERS,
  GET_EMPLOYEE,
  GET_MACHINES,
  GET_PARTS
} from "../../common/Query/MasterQuery"
import { GET_WIP_STOCK } from "../../common/Query/MaterialQuery"
import {
  GET_COMPONENT_Lot,
  GET_MACHINE_RUN_TIME,
  GET_WIP_REPORT
} from "../../common/Query/ProductionQuery"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { formatNumber } from "../../common/utilities"
import PrintIcon from "@mui/icons-material/Print"
import moment from "moment"
import swal from "sweetalert"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"

interface Column {
  id:
    | "date"
    | "partNo"
    | "machine"
    | "process"
    | "jobOrder"
    | "pdLotNo"
    | "componentLot"
    | "line"
    | "quantity"
    | "iNG"
    | "sNG"
    | "tNG"
    | "supplierNG"
    | "stopTime"
    | "totalCost"
    | "remarks"
    | "packingBy"
    | "productionWorker"

  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

const columns: Column[] = [
  { id: "date", label: "Production Date", minWidth: 100 },
  { id: "partNo", label: "Part No.", minWidth: 100 },
  { id: "process", label: "Process", minWidth: 150 },
  { id: "jobOrder", label: "Job Order / Material No", minWidth: 250 },
  { id: "pdLotNo", label: "PD Lot No.", minWidth: 150 },
  { id: "componentLot", label: "Component part / semi part Lot", minWidth: 50 },
  { id: "line", label: "Line/Machine", minWidth: 100 },
  { id: "quantity", label: "Quantity", minWidth: 50 },
  { id: "iNG", label: "I-NG", minWidth: 50 },
  { id: "sNG", label: "S-NG", minWidth: 50 },
  { id: "tNG", label: "T-NG", minWidth: 50 },
  { id: "supplierNG", label: "Supplier-NG", minWidth: 50 },
  { id: "productionWorker", label: "Operator", minWidth: 50 },
  { id: "packingBy", label: "Scanner", minWidth: 50 },
  { id: "stopTime", label: "Stop Time", minWidth: 50 },
  { id: "totalCost", label: "Price", minWidth: 50 },
  { id: "remarks", label: "Remarks", minWidth: 100 }
]

interface IDataMachine {
  machine: string
  partNo: string
  line: string
  factory: string
  totalHour: number
  stopTime: number
  setupTime: number
}

interface Data {
  date: string
  partNo: string
  machine: string
  process: string
  jobOrder: string
  pdLotNo: string
  componentLot: string
  line: string
  quantity: number
  iNG: number
  sNG: number
  tNG: number
  supplier: number
  supplierNG: number
  stopTime: string
  productionWorker: string
  packingBy: string
  totalCost: number
  remarks: string
  customer: string
}

const Root = styled("div")(
  () => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;

  }
  td,
  th {
    border: 1px solid #787878;
    text-align: left;
    padding: 6px;
  }
  th {
    background-color: white;
    color: black;
    font-weight:bold
  }
  `
)

const pageTitle = "Production Report"

const Production: React.FC = () => {
  const theme = useTheme()
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [factory, setFactory] = useState<string>("")
  const [partNo, setPartNo] = useState<string | null>("")
  const [pdLotNo, setPdLotNo] = useState<string | null>("")
  const [jobOrder, setJobOrder] = useState<string | null>("")
  const [process, setProcess] = useState<string | null>("")
  const [line, setLine] = useState<string | null>("")
  const [operator, setOperator] = useState<string | null>("")
  const [machine, setMachine] = useState<string | null>("")
  const [componentLot, setComponentLot] = useState<string | null>("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchCustomer, setSearchCustomer] = useState<string>("")
  const [typeNG, setTypeNg] = useState<string>("")
  const typeNgList = ["I-NG", "S-NG", "T-NG", "SUPPLIER-NG"]

  const getPart = useQuery(GET_PARTS)
  const getLot = useQuery(GET_WIP_STOCK, { variables: { partNo: partNo } })
  const getLine = useQuery(GET_LINE)
  const getEmployee = useQuery(GET_EMPLOYEE)
  const getMachine = useQuery(GET_MACHINES)
  const getComponent = useQuery(GET_COMPONENT_Lot)
  const [getWipReport, { data, loading: loadingWip }] =
    useLazyQuery(GET_WIP_REPORT)
  const [
    getMachineRumTime,
    { data: dataMachineRunTime, loading: loadingMachine }
  ] = useLazyQuery(GET_MACHINE_RUN_TIME)
  const { data: customerData } = useQuery(GET_CUSTOMERS)

  const { customers } = customerData ? customerData : { customers: [] }
  const customerList = customers.map(
    ({ customer }: { customer: string }) => customer
  )

  const partNoList: Array<string> = getPart.data
    ? getPart.data.parts.map((e: { partNo: string }) => e.partNo)
    : []
  const jobOrderList: Array<string> = getLot.data
    ? getLot.data.wipStocks
        .filter((part: { partNo: string }) => part.partNo === partNo)
        .map((wipstock: { jobOrder: string }) => wipstock.jobOrder)
    : []
  const pdLotList: Array<string> = getLot.data
    ? getLot.data.wipStocks
        .filter((part: { partNo: string }) => part.partNo === partNo)
        .map((wipstock: { pdLotNo: string }) => wipstock.pdLotNo)
    : []
  const lineList: Array<string> = getLine.data
    ? getLine.data.getLine.map((line: { lineName: string }) => line.lineName)
    : []

  const employeeList: Array<string> = getEmployee.data
    ? Array.from(
        new Set(
          getEmployee.data.employee
            .filter((dep: { departmentId: number }) => dep.departmentId === 4)
            .map((emp: { firstName: string }) => emp.firstName)
        )
      )
    : []
  const machineList: Array<string> = getMachine.data
    ? getMachine.data.machines
        .map((machineInstance: { machine: string }) => machineInstance.machine)
        .reduce(
          (prev: string[], cur: string) =>
            prev.includes(cur) ? prev : prev.concat(cur),
          []
        )
    : []
  const componentList: Array<string> = getComponent.data
    ? getComponent.data.componentLot.map(
        (component: { componentLot: string }) => component.componentLot
      )
    : []
  let processList: Array<string> = []

  let rows: Data[] = []
  let rowsMachineRuntime: IDataMachine[] = []
  if (data) {
    rows = data.wipReport.map((data: Data) => {
      return {
        ...data,
        line: data.line + "/" + data.machine
      }
    })
    processList = Array.from(
      new Set(
        data.wipReport
          .map(
            (processInstance: { process: string }) => processInstance.process
          )
          ?.sort() || []
      )
    )
  }
  if (dataMachineRunTime) {
    rowsMachineRuntime = dataMachineRunTime?.runTimeMachine
  }
  if (rowsMachineRuntime.length > 0 && partNo && partNo !== null) {
    rowsMachineRuntime = rowsMachineRuntime.filter(
      (data: { partNo: string }) => data.partNo === partNo
    )
  }
  if (rowsMachineRuntime.length > 0 && line && line !== null) {
    rowsMachineRuntime = rowsMachineRuntime.filter(
      (data: { line: string }) => data.line.split("/")[0] === line
    )
  }

  if (pdLotNo && pdLotNo !== null) {
    rows = rows.filter((wip: { pdLotNo: string }) => wip.pdLotNo === pdLotNo)
  }
  if (jobOrder && jobOrder !== null) {
    rows = rows.filter((wip: { jobOrder: string }) => wip.jobOrder === jobOrder)
  }
  if (line && line !== null) {
    rows = rows.filter(
      (wip: { line: string }) => wip.line.split("/")[0] === line
    )
  }
  if (componentLot && componentLot !== null) {
    rows = rows.filter(
      (wip: { componentLot: string }) => wip.componentLot === componentLot
    )
  }
  if (process && process !== null) {
    rows = rows.filter((wip: { process: string }) => wip.process === process)
  }
  if (operator && operator !== null) {
    rows = rows.filter(
      (wip: { packingBy: string; partNo: string }) => wip.packingBy === operator
    )
  }
  if (machine && machine !== null) {
    rows = rows.filter(
      (wip: { machine: string; partNo: string }) => wip.machine === machine
    )
  }

  if (typeNG && typeNG !== null) {
    if (typeNG === "I-NG") {
      rows = rows.filter((wip: { iNG: number }) => wip.iNG > 0)
    }
    if (typeNG === "T-NG") {
      rows = rows.filter((wip: { tNG: number }) => wip.tNG > 0)
    }
    if (typeNG === "S-NG") {
      rows = rows.filter((wip: { sNG: number }) => wip.sNG > 0)
    }
    if (typeNG === "SUPPLIER-NG") {
      rows = rows.filter((wip: { supplierNG: number }) => wip.supplierNG > 0)
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleSubmit = async () => {
    if (!factory) {
      swal("warning", "กรุณาเลือก Factory", "warning")
    }
    await getWipReport({
      variables: {
        dateFrom,
        dateTo,
        factory: factory,
        partNo: partNo,
        customer: searchCustomer
      },
      fetchPolicy: "network-only"
    })
    await getMachineRumTime({
      variables: { dateFrom, dateTo, factory: factory },
      fetchPolicy: "network-only"
    })
  }

  const getProcessFilterRumTimeMachine = (type?: string) => {
    if (type === "stamp") {
      return rowsMachineRuntime?.filter(
        (datum) =>
          datum.line.includes("LINE A") ||
          datum.line.includes("LINE B") ||
          datum.line.includes("LINE C") ||
          datum.line.includes("LINE D") ||
          datum.line.includes("LINE 2B")
      )
    } else if (type === "weld") {
      return rowsMachineRuntime?.filter((datum) => datum.line.includes("SPOT"))
    } else if (type === "check100") {
      return rowsMachineRuntime?.filter((datum) =>
        datum.line.includes("CHECK100")
      )
    } else if (type === "arcWeld") {
      return rowsMachineRuntime?.filter((datum) =>
        datum.line.includes("ARC WELDING")
      )
    } else if (type === "plating") {
      return rowsMachineRuntime?.filter(
        (datum) => datum.line === "LINE ZINC" || datum.line === "LINE EDP"
      )
    } else if (type === "qc") {
      return rowsMachineRuntime?.filter((datum) => datum.line === "QC")
    } else {
      return rowsMachineRuntime
    }
  }

  const getProcessFilter = (type?: string) => {
    if (type === "stamp") {
      return rows?.filter(
        (datum: Data) =>
          datum.process.includes("/") || datum.process.includes("ASSY PIN")
      )
    } else if (type === "weld") {
      return rows?.filter((datum: Data) => datum.process.includes("SPOT"))
    } else if (type === "check100") {
      return rows?.filter(
        (datum: Data) =>
          datum.process.includes("CHECK100") ||
          datum.process.includes("PD 100%")
      )
    } else if (type === "arcWeld") {
      return rows?.filter((datum: Data) =>
        datum.process.includes("ARC WELDING")
      )
    } else if (type === "plating") {
      return rows?.filter(
        (datum: Data) =>
          datum.process === "PLATING ZINC" || datum.process === "PLATING EDP"
      )
    } else if (type === "qc") {
      return rows?.filter(
        (datum: Data) => datum.process === "QC" || datum.process === "QF"
      )
    } else {
      return rows
    }
  }

  const machineRunTime = (type?: string): number => {
    const machineRunTime = getProcessFilterRumTimeMachine(type)
    return Number(
      machineRunTime
        ?.reduce((acc: number, obj: IDataMachine) => {
          return acc + obj.totalHour
        }, 0)
        .toFixed(2)
    )
  }

  const machineStopTime = (type?: string): number => {
    const machineStopTime = getProcessFilterRumTimeMachine(type)
    return Number(
      machineStopTime
        ?.reduce((acc: number, obj: IDataMachine) => {
          return acc + obj.stopTime
        }, 0)
        .toFixed(2)
    )
  }

  const machineSetUpTime = (type?: string): number => {
    const machineSetUpTime = getProcessFilterRumTimeMachine(type)
    return Number(
      machineSetUpTime
        ?.reduce((acc: number, obj: IDataMachine) => {
          return acc + obj.setupTime
        }, 0)
        .toFixed(2)
    )
  }

  const ppm = (type?: string) => {
    const iNg = iNG(type)
    return Math.ceil((1000000 * iNg) / (ok(type) + iNG()))
  }

  const ok = (type?: string): number => {
    const partData = getProcessFilter(type)
    return partData?.reduce((acc: number, obj: Data) => {
      return acc + obj.quantity
    }, 0)
  }

  const iNG = (type?: string): number => {
    const partData = getProcessFilter(type)
    return partData?.reduce((acc: number, obj: Data) => {
      return acc + obj.iNG
    }, 0)
  }

  const sNG = (type?: string) => {
    const partData = getProcessFilter(type)
    return partData?.reduce((acc: number, obj: Data) => {
      return acc + obj.sNG
    }, 0)
  }

  const tNG = (type?: string) => {
    const partData = getProcessFilter(type)
    return partData?.reduce((acc: number, obj: Data) => {
      return acc + obj.tNG
    }, 0)
  }

  const supplierNG = (type?: string) => {
    const partData = getProcessFilter(type)
    return partData?.reduce((acc: number, obj: Data) => {
      return acc + obj.supplierNG
    }, 0)
  }
  const total = (type?: string) => {
    const partData = getProcessFilter(type)
    return partData?.reduce((acc: number, obj: Data) => {
      return acc + getCost(obj)
    }, 0)
  }

  const getCost = (data: Data) => {
    const part = data
    const total = part.sNG + part.iNG + part.tNG
    const totalCost = part.totalCost * total
    return totalCost
  }

  const getCostPoor = (data: Data) => {
    const part = data
    const poor = part.iNG
    const totalPoor = part.totalCost * poor
    return totalPoor
  }

  const costOFPoor = (type?: string) => {
    const partData = getProcessFilter(type)
    return partData?.reduce((acc: number, obj: Data) => {
      return acc + getCostPoor(obj)
    }, 0)
  }

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Production}
        menuItemName={Object.keys(menuItemList)[3]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="ตรวจสอบข้อมูลการผลิต"
          subTitle="About detail ตรวจสอบข้อมูลการผลิต"
        />
        <div className="none-display">
          <Grid container spacing={2} margin={1} width={"98%"}>
            <Grid item sm={2}>
              <ThemedTextField
                id="DateFrom"
                label="From"
                type="date"
                value={dateFrom}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                onChange={(event: {
                  target: { value: React.SetStateAction<string> }
                }) => setDateFrom(event.target.value)}
              />
            </Grid>
            <Grid item sm={2}>
              <ThemedTextField
                fullWidth
                id="DateTo"
                type="date"
                label="To"
                value={dateTo}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(event: {
                  target: { value: React.SetStateAction<string> }
                }) => setDateTo(event.target.value)}
              />
            </Grid>

            <Grid item sm={2}>
              <Autocomplete
                disablePortal
                id="factory"
                options={["1", "2"]}
                style={{ width: "100%" }}
                value={factory}
                onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Factory"
                  />
                )}
              />
            </Grid>
            <Grid item sm={2}>
              <Autocomplete
                disablePortal
                id="partNo"
                loading={partNoList.length === 0}
                options={partNoList}
                value={partNo}
                style={{ width: "100%" }}
                onChange={(e, newValue) => setPartNo(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Part NO"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={2}>
              <Autocomplete
                id="search"
                value={searchCustomer}
                disablePortal
                options={customerList}
                style={{ width: "100%", fontFamily: "Sarabun" }}
                sx={{ width: 100 }}
                onChange={(e, newValue) =>
                  setSearchCustomer(newValue ? newValue : "")
                }
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Customer" />
                )}
              />
            </Grid>
            <Grid item sm={2} container justifyContent="flex-end">
              <ThemedLoadingButton
                loading={loadingWip || loadingMachine}
                variant="contained"
                style={{
                  width: "120px",
                  height: "36px",
                  top: "9px"
                }}
                onClick={handleSubmit}
              >
                Submit
              </ThemedLoadingButton>
              <ThemedButton
                variant="outlined"
                color="primary"
                endIcon={<PrintIcon />}
                style={{
                  width: "120px",
                  height: "36px",
                  marginLeft: "15px",
                  top: "9px",
                  borderColor: theme.palette.primary.main
                }}
                onClick={() => window.print()}
              >
                Print
              </ThemedButton>
            </Grid>

            <Grid item sm={12}>
              <Typography fontSize={14} color="gray">
                Filter Data (optional)
              </Typography>
            </Grid>

            <Grid item sm={2.4}>
              <Autocomplete
                disablePortal
                id="pdlot"
                options={jobOrderList}
                value={jobOrder}
                style={{ width: "100%" }}
                onChange={(e, newValue) => setJobOrder(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Material Lot No."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={2.4}>
              <Autocomplete
                disablePortal
                id="pdLot"
                options={pdLotList}
                value={pdLotNo}
                style={{ width: "100%" }}
                onChange={(e, newValue) => setPdLotNo(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Production Lot No."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={2.4}>
              <Autocomplete
                disablePortal
                id="line"
                options={lineList}
                value={line}
                style={{ width: "100%" }}
                onChange={(e, newValue) => setLine(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Production Line"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={2.4}>
              <Autocomplete
                disablePortal
                id="process"
                options={processList}
                value={process}
                style={{ width: "100%" }}
                onChange={(e, newValue) => setProcess(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Production Process"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={2.4}>
              <Autocomplete
                disablePortal
                id="operator"
                options={employeeList}
                style={{ width: "100%" }}
                value={operator}
                onChange={(e, newValue) => setOperator(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select Operator"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={2.4}>
              <Autocomplete
                disablePortal
                id="machine"
                options={machineList}
                style={{ width: "100%" }}
                value={machine}
                onChange={(e, newValue) => setMachine(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select Machine"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={2.4}>
              <Autocomplete
                disablePortal
                id="componentLot"
                options={componentList}
                value={componentLot}
                style={{ width: "100%" }}
                onChange={(e, newValue) => setComponentLot(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select Component Lot"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={2.4}>
              <Autocomplete
                id="searchNG"
                value={typeNG}
                disablePortal
                options={typeNgList}
                style={{ width: "100%", fontFamily: "Sarabun" }}
                sx={{ width: 100 }}
                onChange={(e, newValue) => setTypeNg(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Type-NG" value={typeNG} />
                )}
              />
            </Grid>
          </Grid>
        </div>
        <div className="print-sm-order">
          <Root
            sx={{ width: "100%", marginTop: "25px", marginLeft: "25px" }}
            className="print-background-white"
          >
            <Table style={{ width: "97%", backgroundColor: "white" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    style={{
                      fontWeight: "bold",
                      fontSize: "24px"
                    }}
                  >
                    Production Report
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={3}
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px"
                    }}
                  >
                    _______________<br></br>
                    ผู้อนุมัติ
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    _______________<br></br>
                    ผู้ตรวจสอบ
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        top: 57,
                        minWidth: column.minWidth
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={i}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {columns.map((column) => {
                          const value = row[column.id]
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={
                                row.remarks === "Rework" &&
                                column.id === "remarks"
                                  ? { color: "red" }
                                  : { color: "black" }
                              }
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
            <TablePagination
              style={{ marginRight: "15px" }}
              rowsPerPageOptions={[10, 25, 100, rows.length]}
              component="div"
              className="none-display"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ backgroundColor: theme.palette.warning.light }}
            />
          </Root>
          <div>
            <Table style={{ margin: "25px", width: "97%" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Line</TableCell>
                  <TableCell>Total Run Time (HRS.)</TableCell>
                  <TableCell>Total Machine Break Down (HRS.)</TableCell>
                  <TableCell>Total Stop Time (HRS.)</TableCell>
                  <TableCell>OK</TableCell>
                  <TableCell>I-NG</TableCell>
                  <TableCell>S-NG</TableCell>
                  <TableCell>T-NG</TableCell>
                  <TableCell>Supplier-NG</TableCell>
                  <TableCell>Cost of Poor</TableCell>
                  <TableCell>Total NG Cost (บาท)</TableCell>
                  <TableCell>PPM</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      fontFamily: "Sarabun",
                      fontWeight: "bold"
                    }}
                  >
                    Stamping
                  </TableCell>
                  <TableCell>{formatNumber(machineRunTime("stamp"))}</TableCell>
                  <TableCell>
                    {formatNumber(machineStopTime("stamp"))}
                  </TableCell>
                  <TableCell>
                    {formatNumber(machineSetUpTime("stamp"))}
                  </TableCell>
                  <TableCell> {formatNumber(ok("stamp"))}</TableCell>
                  <TableCell> {formatNumber(iNG("stamp"))}</TableCell>
                  <TableCell> {formatNumber(sNG("stamp"))}</TableCell>
                  <TableCell> {formatNumber(tNG("stamp"))}</TableCell>
                  <TableCell>{formatNumber(supplierNG("stamp"))}</TableCell>
                  <TableCell>{formatNumber(costOFPoor("stamp"))}</TableCell>
                  <TableCell>{formatNumber(total("stamp"))}</TableCell>
                  <TableCell> {formatNumber(ppm("stamp"))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      fontFamily: "Sarabun",
                      fontWeight: "bold"
                    }}
                  >
                    Welding
                  </TableCell>
                  <TableCell>{formatNumber(machineRunTime("weld"))}</TableCell>
                  <TableCell>{formatNumber(machineStopTime("weld"))}</TableCell>
                  <TableCell>
                    {formatNumber(machineSetUpTime("weld"))}
                  </TableCell>
                  <TableCell> {formatNumber(ok("weld"))}</TableCell>
                  <TableCell> {formatNumber(iNG("weld"))}</TableCell>
                  <TableCell> {formatNumber(sNG("weld"))}</TableCell>
                  <TableCell> {formatNumber(tNG("weld"))}</TableCell>
                  <TableCell>{formatNumber(supplierNG("weld"))}</TableCell>
                  <TableCell>{formatNumber(costOFPoor("weld"))}</TableCell>
                  <TableCell>{formatNumber(total("weld"))}</TableCell>
                  <TableCell> {formatNumber(ppm("weld"))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      fontFamily: "Sarabun",
                      fontWeight: "bold"
                    }}
                  >
                    Arc Welding
                  </TableCell>
                  <TableCell>
                    {formatNumber(machineRunTime("arcWeld"))}
                  </TableCell>
                  <TableCell>
                    {formatNumber(machineStopTime("arcWeld"))}
                  </TableCell>
                  <TableCell>
                    {formatNumber(machineSetUpTime("arcWeld"))}
                  </TableCell>
                  <TableCell> {formatNumber(ok("arcWeld"))}</TableCell>
                  <TableCell> {formatNumber(iNG("arcWeld"))}</TableCell>
                  <TableCell> {formatNumber(sNG("arcWeld"))}</TableCell>
                  <TableCell> {formatNumber(tNG("arcWeld"))}</TableCell>
                  <TableCell>{formatNumber(supplierNG("arcWeld"))}</TableCell>
                  <TableCell>{formatNumber(costOFPoor("arcWeld"))}</TableCell>
                  <TableCell>{formatNumber(total("arcWeld"))}</TableCell>
                  <TableCell> {formatNumber(ppm("arcWeld"))}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      fontFamily: "Sarabun",
                      fontWeight: "bold"
                    }}
                  >
                    Plating
                  </TableCell>
                  <TableCell>
                    {formatNumber(machineRunTime("plating"))}
                  </TableCell>
                  <TableCell>
                    {formatNumber(machineStopTime("plating"))}
                  </TableCell>
                  <TableCell>
                    {formatNumber(machineSetUpTime("plating"))}
                  </TableCell>
                  <TableCell> {formatNumber(ok("plating"))}</TableCell>
                  <TableCell> {formatNumber(iNG("plating"))}</TableCell>
                  <TableCell> {formatNumber(sNG("plating"))}</TableCell>
                  <TableCell> {formatNumber(tNG("plating"))}</TableCell>
                  <TableCell>{formatNumber(supplierNG("plating"))}</TableCell>
                  <TableCell>{formatNumber(costOFPoor("plating"))}</TableCell>
                  <TableCell>{formatNumber(total("plating"))}</TableCell>
                  <TableCell> {formatNumber(ppm("plating"))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      fontFamily: "Sarabun",
                      fontWeight: "bold"
                    }}
                  >
                    PD Check 100 %
                  </TableCell>
                  <TableCell>
                    {formatNumber(machineRunTime("check100"))}
                  </TableCell>
                  <TableCell>
                    {formatNumber(machineStopTime("check100"))}
                  </TableCell>
                  <TableCell>
                    {formatNumber(machineSetUpTime("check100"))}
                  </TableCell>
                  <TableCell> {formatNumber(ok("check100"))}</TableCell>
                  <TableCell> {formatNumber(iNG("check100"))}</TableCell>
                  <TableCell> {formatNumber(sNG("check100"))}</TableCell>
                  <TableCell> {formatNumber(tNG("check100"))}</TableCell>
                  <TableCell>{formatNumber(supplierNG("check100"))}</TableCell>
                  <TableCell>{formatNumber(costOFPoor("check100"))}</TableCell>
                  <TableCell>{formatNumber(total("check100"))}</TableCell>
                  <TableCell> {formatNumber(ppm("check100"))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      fontFamily: "Sarabun",
                      fontWeight: "bold"
                    }}
                  >
                    QC Final
                  </TableCell>
                  <TableCell>{formatNumber(machineRunTime("qc"))}</TableCell>
                  <TableCell>{formatNumber(machineStopTime("qc"))}</TableCell>
                  <TableCell>{formatNumber(machineSetUpTime("qc"))}</TableCell>
                  <TableCell> {formatNumber(ok("qc"))}</TableCell>
                  <TableCell> {formatNumber(iNG("qc"))}</TableCell>
                  <TableCell> {formatNumber(sNG("qc"))}</TableCell>
                  <TableCell> {formatNumber(tNG("qc"))}</TableCell>
                  <TableCell>{formatNumber(supplierNG("qc"))}</TableCell>
                  <TableCell>{formatNumber(costOFPoor("qc"))}</TableCell>
                  <TableCell>{formatNumber(total("qc"))}</TableCell>
                  <TableCell> {formatNumber(ppm("qc"))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      fontFamily: "Sarabun",
                      fontWeight: "bold"
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell>{formatNumber(machineRunTime())}</TableCell>
                  <TableCell>{formatNumber(machineStopTime())}</TableCell>
                  <TableCell>{formatNumber(machineSetUpTime())}</TableCell>
                  <TableCell>
                    {formatNumber(
                      ok("stamp") +
                        ok("weld") +
                        ok("arcWeld") +
                        ok("plating") +
                        ok("check100") +
                        ok("qc")
                    )}
                  </TableCell>
                  <TableCell> {formatNumber(iNG())}</TableCell>
                  <TableCell> {formatNumber(sNG())}</TableCell>
                  <TableCell> {formatNumber(tNG())}</TableCell>
                  <TableCell>{formatNumber(supplierNG())}</TableCell>
                  <TableCell>
                    {formatNumber(
                      costOFPoor("stamp") +
                        costOFPoor("weld") +
                        costOFPoor("arcWeld") +
                        costOFPoor("plating") +
                        costOFPoor("check100") +
                        costOFPoor("qc")
                    )}
                  </TableCell>
                  <TableCell>
                    {formatNumber(
                      total("stamp") +
                        total("weld") +
                        total("arcWeld") +
                        total("plating") +
                        total("check100") +
                        total("qc")
                    )}
                  </TableCell>
                  <TableCell>
                    {formatNumber(
                      (1000000 * iNG()) /
                        (ok("stamp") +
                          ok("weld") +
                          ok("arcWeld") +
                          ok("plating") +
                          ok("check100") +
                          ok("qc") +
                          iNG())
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </ThemedCard>
    </ThemeProvider>
  )
}
export default Production
