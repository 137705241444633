import { gql } from "@apollo/client"

export const UploadPackagingStandard = gql`
  mutation UploadPackagingStandard($fileName: String, $contentType: String) {
    uploadPackagingStandard(fileName: $fileName, contentType: $contentType)
  }
`

export const CreatePackagingStandard = gql`
  mutation CreatePackagingStandard(
    $partNo: String!
    $revision: String!
    $record: String!
    $date: String!
    $fileName: String!
    $remarks: String
  ) {
    createPackagingStandard(
      partNo: $partNo
      revision: $revision
      date: $date
      fileName: $fileName
      remarks: $remarks
      record: $record
    ) {
      partNo
      revision
      fileName
      remarks
      record
      date
    }
  }
`
