import { gql } from "@apollo/client"
export const GET_SUPPLIER_STOCK_CARD = gql`
  query GetSupplierStockCard($partNo: String) {
    getSupplierStockCard(partNo: $partNo) {
      date
      remarkIn
      remarkOut
      quantityIn
      quantityOut
      lotNoIn
      lotNoOut
      total
      supplier
    }
  }
`
export const GET_SUPPLIER_REPORT = gql`
  query GetSupplierReport(
    $supplier: String
    $dateFrom: String
    $dateTo: String
  ) {
    getSupplierReport(
      supplier: $supplier
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      actionDate
      jobOrder
      pdLotNo
      partNo
      remarks
      quantityInBae
      quantityOutBae
    }
  }
`
export const GET_SUPPLIER_TRANSPORT = gql`
  query GetSupplierTransportReport(
    $carLicense: String
    $period: String
    $partNo: String
    $dateTo: String!
    $dateFrom: String!
  ) {
    getSupplierTransportReport(
      carLicense: $carLicense
      period: $period
      partNo: $partNo
      dateTo: $dateTo
      dateFrom: $dateFrom
    ) {
      entry {
        deliveryDate
        branchFrom
        branchTo
        period
        partNo
        pdLotNo
        jobOrder
        quantity
        carLicense
        operator
        remark
        box
      }
      totalQuantity
      totalBox
    }
  }
`
export const GET_SUPPLIER_CONTROL = gql`
  query GetSupplierControl($partNo: String) {
    getSupplierControl(partNo: $partNo) {
      id
      jobOrder
      pdLotNo
      partNo
      boxNo
      quantity
      NGQty
      deliveryDate
      dateScan
      inOrOut
      operator
      branchFrom
      branchTo
      period
      carLicense
      remark
    }
  }
`
