import { useMutation } from "@apollo/react-hooks"
import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Autocomplete,
  TextField,
  DialogActions,
  Button,
  useTheme
} from "@mui/material"
import { useState } from "react"
import swal from "sweetalert"
import { ADD_SPARE_PART } from "../../../common/Mutation/MaintenanceMutation"
import { GET_SPARE_PARTS } from "../../../common/Query/MaintenanceQuery"
import Input from "../../../common/Resources/Input"

interface IPropsAddSparePart {
  setOpenAddSparePart: (boolean: boolean) => void
  openAddSparePart: boolean
}

export const AddNewSparePart: React.FC<IPropsAddSparePart> = (
  props: IPropsAddSparePart
) => {
  const theme = useTheme()
  const { setOpenAddSparePart, openAddSparePart } = props
  const [code, setCode] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [model, setModel] = useState<string>("")
  const [brand, setBrand] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [min, setMin] = useState<number>(0)
  const [max, setMax] = useState<number>(0)
  const [location, setLocation] = useState<string>("")
  const [file, setFile] = useState<File | null>(null)
  const [error, setError] = useState<boolean>(false)

  const [addSparePart, { loading }] = useMutation(ADD_SPARE_PART, {
    refetchQueries: [GET_SPARE_PARTS],
    awaitRefetchQueries: true
  })

  const handleSubmit = async (e: React.SyntheticEvent) => {
    setError(true)
    e.preventDefault()
    if (!isComplete()) return swal("ERROR", "กรอกข้อมูลไม่ครบ", "warning")
    const data = {
      code,
      partName,
      model,
      brand,
      factory,
      min,
      max,
      location,
      file
    }
    try {
      await addSparePart({
        variables: {
          addSparePart: data
        }
      })
      setOpenAddSparePart(false)
      swal("Success", "Add Spare Part Successful", "success")
      setOpenAddSparePart(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }
  const isComplete = () => {
    return (
      code !== "" &&
      partName !== "" &&
      model !== "" &&
      brand !== "" &&
      factory !== "" &&
      min !== 0 &&
      max !== 0 &&
      location !== "" &&
      file !== null
    )
  }
  return (
    <>
      <Dialog
        open={openAddSparePart}
        onClose={() => setOpenAddSparePart(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Add New Spare Part</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Input
              fullWidth
              text={"Code"}
              setValue={(event) => {
                setCode(event.target.value)
              }}
              inputType="string"
              sm={12}
              error={!code && error}
            />
            <Input
              fullWidth
              text={"Part Name"}
              setValue={(event) => {
                setPartName(event.target.value)
              }}
              inputType="string"
              sm={12}
              error={!partName && error}
            />
            <Input
              fullWidth
              text={"Model"}
              setValue={(event) => {
                setModel(event.target.value)
              }}
              inputType="string"
              sm={4}
              error={!model && error}
            />
            <Grid item sm={4} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                value={factory}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Factory"
                    error={!factory && error}
                  />
                )}
                onChange={(e, newValue) => setFactory(newValue as string)}
                options={["1", "2"]}
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
              <input
                type="file"
                onChange={(e) => {
                  setFile(
                    e.currentTarget.files?.["0"]
                      ? e.currentTarget.files?.["0"]
                      : null
                  )
                }}
              />
            </Grid>
            <Input
              fullWidth
              text={"Brand"}
              setValue={(event) => {
                setBrand(event.target.value)
              }}
              inputType="string"
              sm={6}
              error={!brand && error}
            />
            <Grid item sm={6} xs={12}>
              <Input
                fullWidth
                text={"Min"}
                setValue={(event) => {
                  setMin(+event.target.value)
                }}
                inputType="number"
                sm={12}
                error={!min && error}
              />
            </Grid>
            <Input
              fullWidth
              text={"Max"}
              setValue={(event) => {
                setMax(+event.target.value)
              }}
              inputType="number"
              sm={12}
              error={!max && error}
            />
            <Input
              fullWidth
              text={"Model"}
              setValue={(event) => {
                setModel(event.target.value)
              }}
              inputType="string"
              sm={6}
              error={!model && error}
            />
            <Input
              fullWidth
              text={"Brand"}
              setValue={(event) => {
                setBrand(event.target.value)
              }}
              inputType="string"
              sm={6}
              error={!brand && error}
            />
            <Input
              fullWidth
              text={"Location"}
              setValue={(event) => {
                setLocation(event.target.value)
              }}
              inputType="string"
              sm={12}
              error={!location && error}
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <LoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              backgroundColor: theme.palette.primary.main,
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </LoadingButton>
          <Button variant="text" onClick={() => setOpenAddSparePart(false)}>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
