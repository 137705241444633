import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material"
import ThemedButton from "./ThemedComponents/ThemedButton"

interface IProp {
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  agreeFunction: () => void
}
export const DeleteConfirmDialog: React.FC<IProp> = (props: IProp) => {
  const { open, title, setOpen, agreeFunction } = props
  return (
    <Dialog open={open}>
      <DialogContent style={{ width: "auto" }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogActions>
          <ThemedButton
            variant="contained"
            style={{ backgroundColor: "green" }}
            onClick={agreeFunction}
            autoFocus
          >
            Agree
          </ThemedButton>
          <ThemedButton
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => {
              setOpen(false)
            }}
          >
            Disagree
          </ThemedButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
