import React, { Dispatch, SetStateAction, useState } from "react"
import {
  Grid,
  Autocomplete,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  useTheme,
  ThemeProvider
} from "@mui/material"
import PrintIcon from "@mui/icons-material/Print"
import { styled } from "@mui/material/styles"
import CircularProgress from "@mui/material/CircularProgress"
import moment from "moment"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import { GET_LOGISTICS_REPORT_CAR_LICENSE } from "../../common/Query/LogisticsQuery"
import {
  LazyQueryResult,
  QueryLazyOptions,
  useLazyQuery,
  useQuery
} from "@apollo/client"
import "../../css/Logistics/LogisticsCSS.css"
import { GET_PARTS } from "../../common/Query/MasterQuery"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import { miniLogo } from "../../common/utilities"
import swal from "sweetalert"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import { GET_SUPPLIER_TRANSPORT } from "../../common/Query/SupplierControl"

interface ITransportSignaturesProps {
  sender: string
  approvedBy: string
}

export interface ITransportBodyProps {
  whtransportReport: TransportReportEntryList
  totalQuantity: number
}

export interface TransportReportEntryList {
  entry: [TransportReportEntry]
}

export interface TransportReportEntry {
  transportDate: string
  branchFrom: string
  branchTo: string
  period: string
  partNo: string
  pdLotNo: string
  jobOrder: string
  quantity: number
  carLicense: string
  approval: string
  operatorWH: string
  remarks: string
}
interface IDataWHTranSport {
  carLicense: string
  quantity: number
  jobOrder: string
  pdLotNo: string
  partNo: string
  period: string
  branchTo: string
  branchFrom: string
  deliveryDate: Date
  remark: string
  box: string
}

type TData = {
  whtransportReport: { entry: string[] }
}
export interface ISearchTransportationProps {
  dateFrom: Date
  dateTo: Date
  partNo: string
  period: string
  carLicense: string
  setDateFrom: Dispatch<SetStateAction<Date>>
  setDateTo: Dispatch<SetStateAction<Date>>
  setPartNo: Dispatch<SetStateAction<string>>
  setPeriod: Dispatch<SetStateAction<string>>
  setCarLicense: Dispatch<SetStateAction<string>>
  getTransportReport: (
    options?:
      | QueryLazyOptions<{
          dateFrom: string
          dateTo: string
          partNo: string
          period: string
          carLicense: string
        }>
      | undefined
  ) => Promise<
    LazyQueryResult<
      TData,
      {
        dateFrom: string
        dateTo: string
        partNo: string
        period: string
        carLicense: string
      }
    >
  >
}

const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  borderBottom: "1px",
  border: "solid 1px black"
}))

const StyledTableRow = styled(TableRow)(() => ({
  borderBottom: "1px",
  "&:last-child td": { borderBottom: "1px solid black" }
}))

const pageTitle = "Supplier Transport"

export const SearchTransportation = (
  props: ISearchTransportationProps
): JSX.Element => {
  const {
    dateFrom,
    dateTo,
    period,
    partNo,
    carLicense,
    setDateFrom,
    setDateTo,
    setPartNo,
    setPeriod,
    setCarLicense,
    getTransportReport
  } = props
  const getPart = useQuery(GET_PARTS)

  const periodsList = [
    "เช้า: 09.00 น.",
    "เช้า: 11.00 น.",
    "บ่าย: 14.00 น.",
    "บ่าย: 16.00 น.",
    "เย็น: 18.00 น.",
    "เย็น: 19.00 น."
  ]
  const [error, setError] = useState<boolean>(false)
  const { data } = useQuery(GET_LOGISTICS_REPORT_CAR_LICENSE)
  const carLicenseList = data
    ? data.carLicense.map((e: { carLicense: string }) => e.carLicense)
    : []

  const handleSearch = async (event: React.FormEvent) => {
    event.preventDefault()
    const { data, loading } = await getTransportReport()
    if (loading) return
    if (data?.whtransportReport?.entry.length === 0) {
      setError(true)
      swal("ERROR", "ไม่พบข้อมูล", "error")
    }
  }
  const partNoList = getPart.data
    ? getPart.data.parts.map((e: { partNo: string }) => e.partNo)
    : []

  const theme = useTheme()
  return (
    <div style={{ padding: "0 5px 5px 5px" }}>
      <form autoComplete="off" onSubmit={handleSearch}>
        <Grid container spacing={2} width={"98%"} margin={2}>
          <Grid item xs={12} sm={2.4}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                label="dateFrom"
                openTo="day"
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                value={dateFrom}
                onChange={(newSelectDate) => {
                  setDateFrom(moment(newSelectDate).toDate() as Date)
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                label="dateTo"
                openTo="day"
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                value={dateTo}
                onChange={(newSelectDate) => {
                  setDateTo(moment(newSelectDate).toDate() as Date)
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              fullWidth
              value={period}
              disableClearable
              disablePortal
              onChange={(event, selectedPeriod) => {
                setPeriod(selectedPeriod as string)
              }}
              options={periodsList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="รอบที่ส่ง"
                  InputLabelProps={{ shrink: true }}
                  error={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              fullWidth
              value={carLicense}
              disableClearable
              disablePortal
              onChange={(event, selectedCarLicense) => {
                setCarLicense(selectedCarLicense as string)
              }}
              options={carLicenseList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="ทะเบียนรถ"
                  InputLabelProps={{ shrink: true }}
                  error={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              fullWidth
              value={partNo}
              onChange={(event, selectedPeriod) => {
                setPartNo(selectedPeriod as string)
              }}
              options={partNoList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="PartNo"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <ThemedButton
              type="submit"
              variant="contained"
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              Submit
            </ThemedButton>
            <ThemedButton
              variant="outlined"
              endIcon={<PrintIcon />}
              style={{
                color: theme.palette.secondary.contrastText,
                borderColor: theme.palette.primary.main,
                marginLeft: "20px"
              }}
              onClick={() => window.print()}
            >
              Print
            </ThemedButton>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export const TransportSignature = (
  props: ITransportSignaturesProps
): JSX.Element => {
  const { sender, approvedBy } = props

  return (
    <div style={{ maxWidth: "50vw", marginTop: "20px" }}>
      <Table>
        <TableHead>
          <StyledTableRow style={{ height: "53px" }}>
            <StyledTableCell data-testid="sender">{sender}</StyledTableCell>
            <StyledTableCell data-testid="approver">
              {approvedBy}
            </StyledTableCell>
            <StyledTableCell data-testid="receiver"></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>SENT by</StyledTableCell>
            <StyledTableCell>APPROVED by</StyledTableCell>
            <StyledTableCell>RECEIVED by</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </div>
  )
}

const SupplierTransport = (): JSX.Element => {
  const [dateFrom, setDateFrom] = useState<Date>(new Date())
  const [dateTo, setDateTo] = useState<Date>(new Date())

  const [period, setPeriod] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [carLicense, setCarLicense] = useState<string>("")
  const [getTransportReport, { data, loading, called }] = useLazyQuery(
    GET_SUPPLIER_TRANSPORT,
    {
      variables: {
        dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(dateTo).format("YYYY-MM-DD"),
        partNo: partNo ? partNo : "",
        period,
        carLicense
      },
      fetchPolicy: "network-only"
    }
  )

  const transportReportBody =
    called && loading ? (
      <StyledTableRow className="report-table-loading">
        <StyledTableCell colSpan={11}>
          <CircularProgress />
        </StyledTableCell>
      </StyledTableRow>
    ) : !called || !data || !data.getSupplierTransportReport ? (
      <></>
    ) : (
      data.getSupplierTransportReport.entry.map(
        (entry: IDataWHTranSport, index: number) => {
          return (
            <StyledTableRow key={index} className="report-table">
              <StyledTableCell
                style={{
                  fontSize: "18px",
                  whiteSpace: "nowrap"
                }}
              >
                {index + 1}
              </StyledTableCell>
              <StyledTableCell
                style={{
                  fontSize: "18px",
                  whiteSpace: "nowrap"
                }}
              >
                {entry.deliveryDate}
              </StyledTableCell>
              <StyledTableCell
                style={{
                  fontSize: "18px",
                  whiteSpace: "nowrap"
                }}
              >
                {entry.branchFrom}
              </StyledTableCell>
              <StyledTableCell
                style={{
                  fontSize: "18px",
                  whiteSpace: "nowrap"
                }}
              >
                {entry.branchTo}
              </StyledTableCell>
              <StyledTableCell
                style={{
                  fontSize: "18px",
                  whiteSpace: "nowrap"
                }}
              >
                {entry.period}
              </StyledTableCell>
              <StyledTableCell
                style={{
                  fontSize: "18px",
                  whiteSpace: "nowrap"
                }}
              >
                {entry.partNo}
              </StyledTableCell>
              <StyledTableCell
                style={{
                  fontSize: "18px",
                  whiteSpace: "nowrap"
                }}
              >
                {entry.pdLotNo}
              </StyledTableCell>
              <StyledTableCell
                style={{
                  fontSize: "18px"
                }}
              >
                {entry.jobOrder}
              </StyledTableCell>
              <StyledTableCell
                style={{
                  fontSize: "18px"
                }}
              >
                {entry.quantity}
              </StyledTableCell>
              <StyledTableCell
                style={{
                  fontSize: "18px"
                }}
              >
                {entry.box}
              </StyledTableCell>
              <StyledTableCell
                style={{
                  fontSize: "18px"
                }}
              >
                {entry.carLicense}
              </StyledTableCell>
              <StyledTableCell
                style={{
                  fontSize: "18px"
                }}
              >
                {entry.remark}
              </StyledTableCell>
            </StyledTableRow>
          )
        }
      )
    )

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <div className="label_navbar">
        <PageLabel
          menuItem={menuItemList.SupplierControl}
          menuItemName={Object.keys(menuItemList)[18]}
          menuPageName={pageTitle}
        />
      </div>
      <ThemedCard>
        <div className="label_navbar">
          <PageLabelCard
            title="Supplier Transport"
            subTitle="detail about Supplier Transport"
          />
          <SearchTransportation
            dateFrom={dateFrom}
            dateTo={dateTo}
            partNo={partNo}
            period={period}
            carLicense={carLicense}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
            setPartNo={setPartNo}
            setPeriod={setPeriod}
            setCarLicense={setCarLicense}
            getTransportReport={getTransportReport}
          />
        </div>
        <div className="transport-report-to-print">
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell
                    rowSpan={3}
                    colSpan={1}
                    style={{ maxWidth: "100px" }}
                  >
                    <img
                      src={miniLogo}
                      alt="Brother Auto Parts."
                      width={"100px"}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={3}
                    align="center"
                    colSpan={9}
                    sx={{
                      fontSize: "30px",
                      position: "relative"
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        Warehouse Transportation
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          position: "absolute",
                          bottom: "0px",
                          right: "0px",
                          padding: "10px",
                          fontSize: "25px"
                        }}
                        justifyContent={"flex-end"}
                        display={"flex"}
                      >
                        {carLicense &&
                        moment(dateTo).format("DD-MM-YYYY") ===
                          moment(dateFrom).format("DD-MM-YYYY")
                          ? `Doc No : ${carLicense} - ${moment(dateTo).format(
                              "DD-MM-YYYY"
                            )}`
                          : ""}
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={2}>
                    F-PL-03-05
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={2}>
                    Effective Date: 04/01/21
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={2}>
                    R01 Issue: 01
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>ลำดับที่</StyledTableCell>
                  <StyledTableCell>วันที่ส่ง</StyledTableCell>
                  <StyledTableCell>จาก</StyledTableCell>
                  <StyledTableCell>ส่งถึง</StyledTableCell>
                  <StyledTableCell>รอบที่ส่ง</StyledTableCell>
                  <StyledTableCell>Part No.</StyledTableCell>
                  <StyledTableCell>PD-Lot-No</StyledTableCell>
                  <StyledTableCell>Mat-Lot-No</StyledTableCell>
                  <StyledTableCell>จำนวน</StyledTableCell>
                  <StyledTableCell>จำนวนกล่อง</StyledTableCell>
                  <StyledTableCell>ทะเบียนรถ</StyledTableCell>
                  <StyledTableCell>หมายเหตุ</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody data-testid="transport-table-report">
                {transportReportBody}
                <StyledTableRow>
                  <StyledTableCell colSpan={8} style={{ textAlign: "right" }}>
                    TOTAL
                  </StyledTableCell>
                  <StyledTableCell colSpan={1}>
                    {!loading && data?.getSupplierTransportReport
                      ? data.getSupplierTransportReport.totalQuantity
                      : 0}
                  </StyledTableCell>
                  <StyledTableCell colSpan={1}>
                    {!loading && data?.getSupplierTransportReport
                      ? data.getSupplierTransportReport.totalBox
                      : 0}
                  </StyledTableCell>
                  <StyledTableCell colSpan={2} />
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <hr className="hidden-print" style={{ marginTop: "20px" }} />
          <TransportSignature
            sender={
              !loading && data?.getSupplierTransportReport
                ? data.getSupplierTransportReport.entry[0]?.operatorWH
                : ""
            }
            approvedBy={
              !loading && data?.getSupplierTransportReport
                ? data.getSupplierTransportReport.entry[0]?.approval
                : ""
            }
          />
        </div>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default SupplierTransport
