import { ReactElement } from "react"
import {
  Box,
  Grid,
  Select,
  MenuItem,
  Autocomplete,
  InputLabel,
  FormControl,
  useTheme
} from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import { GET_CUSTOMERS } from "../../common/Query/MasterQuery"
import { useQuery } from "@apollo/client"
import TableFGOut from "./component/TableFGOut"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import { GET_ORDER_FG_OUT } from "../../common/Query/WarehouseQuery"
import { useLazyQuery } from "@apollo/react-hooks"
import swal from "sweetalert"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import Swal from "sweetalert2"

const pageTitle = "FG Out Multiple"

interface IRow {
  [key: string]: number | string
  partNo: string
  actionDate: string
  quantity: number
  factory: string
  remarks: string
  packingBy: string
  currentStock: number
  status: string
  plating: string
}
const WarehouseFGOutMMTh: React.FC = (): ReactElement => {
  const [actionDate, setActionDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [factory, setFactory] = useState<string>("1")
  const [operator, setOperator] = useState<string | null>("")
  const [customer, setCustomer] = useState<string | null>("")
  const [remarks, setRemarks] = useState<string>("")
  const [error, setError] = useState<boolean>(false)

  const [filteredOrders, setFilteredOrders] = useState<IRow[]>([])
  const [getOrder, { loading }] = useLazyQuery(GET_ORDER_FG_OUT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.fgOutMMTh.length < 1) {
        Swal.fire({ title: "ไม่พบข้อมูล", icon: "warning" })
      }
      const filtered = data?.fgOutMMTh
        .filter((e: IRow) => e.quantity > 0)
        .sort((a: IRow, b: IRow) => {
          if (a.partNo < b.partNo) {
            return -1
          }
          if (a.partNo > b.partNo) {
            return 1
          }
          return 0
        })
      setFilteredOrders(filtered)
    }
  })

  const CustomerAndPartsQuery = () => {
    const customerQuery = useQuery(GET_CUSTOMERS)
    return [customerQuery]
  }
  const [{ error: customerListError, data: customerListData }] =
    CustomerAndPartsQuery()
  if (customerListError) return <p>Error: {customerListError}</p>

  let customerList = []
  if (customerListData) {
    customerList = customerListData.customers.map(
      ({ customer }: { customer: string }) => {
        return customer
      }
    )
  }
  const handleSubmit = async () => {
    setFilteredOrders([])
    if (!customer || !operator) {
      setError(true)
      return swal("ERROR", "กรอกข้อมูลให้ครบถ้วน", "error")
    }
    try {
      await getOrder({
        variables: {
          mmThFgOut: {
            actionDate,
            factory,
            customer,
            packingBy: operator,
            remarks
          }
        }
      })
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "warning")
    }
  }

  const theme = useTheme()
  return (
    <Box style={{ marginLeft: "22px" }} component="form">
      <PageLabel
        menuItem={menuItemList.Warehouse}
        menuItemName={Object.keys(menuItemList)[6]}
        menuPageName={pageTitle}
      />
      <ThemedCard style={{ marginTop: "26px" }}>
        <PageLabelCard
          title="FG Out Multiple"
          subTitle="about detail FG Out Multiple"
        />
        <Grid container width={"98%"} margin={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <Autocomplete
                value={customer}
                fullWidth
                aria-label="customers-select"
                loading={customerList.length === 0}
                onChange={(event, selectedCustomer) => {
                  setCustomer(selectedCustomer)
                }}
                options={customerList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Customers"
                    error={!customer && error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <FormControl fullWidth>
                <InputLabel>Factory</InputLabel>
                <Select
                  value={factory}
                  label="factory"
                  onChange={(e) => {
                    setFactory(e.target.value)
                    setOperator("")
                  }}
                >
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}>
              <ThemedTextField
                fullWidth
                value={actionDate}
                error={!actionDate && error}
                id="actionDate"
                type="date"
                autoComplete="family-name"
                onChange={(e) => setActionDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Autocomplete
                disablePortal
                id="operator"
                options={
                  factory === "1"
                    ? ["ณัฏฐ์", "วันเฉลิม", "สุรศักดิ์"]
                    : ["รุ่งฤดี", "ศรัณยา"]
                }
                fullWidth
                value={operator}
                onChange={(e, newValue) => setOperator(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select Operator"
                    error={!operator && error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <ThemedTextField
                id="remarks"
                fullWidth
                label="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              ></ThemedTextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <ThemedLoadingButton
                variant="contained"
                loading={loading}
                sx={{ mt: 1 }}
                onClick={handleSubmit}
                style={{ backgroundColor: theme.palette.primary.main }}
              >
                Submit
              </ThemedLoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <TableFGOut
          data={filteredOrders}
          userFactory={factory}
          handleSubmit={handleSubmit}
        ></TableFGOut>
      </ThemedCard>
    </Box>
  )
}

export default WarehouseFGOutMMTh
