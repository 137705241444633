import { useQuery } from "@apollo/client"
import { Autocomplete } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { GET_SPARE_PARTS } from "../Query/MaintenanceQuery"
import ThemedTextField from "./ThemedComponents/ThemedTextField"

interface IProps {
  setCode: Dispatch<SetStateAction<string>>
  code: string
  required?: boolean
  error?: boolean
}
const AutocompleteSparePartCode = (props: IProps): JSX.Element => {
  const { code, setCode, required, error } = props
  const { loading, data } = useQuery(GET_SPARE_PARTS)
  let codeList = []
  if (data) {
    codeList = data.sparePartsList.map(({ code }: { code: string }) => code)
  }
  codeList = Array.from(new Set(codeList))

  return (
    <Autocomplete
      value={code}
      fullWidth
      onChange={(event, selectedCode) => {
        setCode(selectedCode as string)
      }}
      options={codeList}
      loading={loading}
      renderInput={(params) => (
        <ThemedTextField
          {...params}
          label="Code"
          required={required}
          error={error ? error : false}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  )
}
export default AutocompleteSparePartCode
