import React, { Dispatch, SetStateAction } from "react"
import { useQuery } from "@apollo/client"
import Grid from "@mui/material/Grid"
import { Autocomplete } from "@mui/material"
import { GET_CUSTOMERS } from "../../../common/Query/MasterQuery"
import { gql } from "@apollo/client"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"

export const GET_PARTS_AND_CUSTOMERS = gql`
  query getParts {
    parts {
      partNo
      customer
    }
  }
`

const CustomerAndParts = ({
  customer,
  partNo,
  setCustomer,
  setPartNo,
  xs = 12,
  sm = 3
}: {
  customer: string
  partNo: string
  setCustomer: Dispatch<SetStateAction<string>>
  setPartNo: Dispatch<SetStateAction<string>>
  xs?: number
  sm?: number
}): JSX.Element => {
  const CustomerandPartsQuery = () => {
    const customerQuery = useQuery(GET_CUSTOMERS)
    const partNoQuery = useQuery(GET_PARTS_AND_CUSTOMERS)
    return [customerQuery, partNoQuery]
  }
  const [
    { error: customerListError, data: customerListData },
    { error: partNoListError, data: partNoListData }
  ] = CustomerandPartsQuery()
  if (customerListError) return <p>Error: {customerListError}</p>
  if (partNoListError) return <p>Error: {partNoListError}</p>

  let customerList = []
  if (customerListData) {
    customerList = customerListData.customers.map(
      ({ customer }: { customer: string }) => {
        return customer
      }
    )
  }

  interface PartType {
    partNo: string
    customer: string
  }

  let partNoList = []

  if (partNoListData) {
    partNoList = partNoListData.parts.reduce(
      (list: string[], part: PartType) => {
        if (part.customer === customer) {
          list.push(part.partNo)
        }
        return list
      },
      []
    )
  }

  return (
    <Grid item container spacing={2}>
      <Grid item xs={xs} sm={sm}>
        <Autocomplete
          value={customer}
          fullWidth
          aria-label="customers-select"
          loading={customerList.length === 0}
          onChange={(event, selectedCustomer) => {
            setCustomer(selectedCustomer as string)
            setPartNo("")
          }}
          options={customerList}
          renderInput={(params) => (
            <ThemedTextField {...params} label="Customers" />
          )}
        />
      </Grid>
      <Grid item xs={xs} sm={sm}>
        <Autocomplete
          key={partNoList}
          value={partNo}
          fullWidth
          aria-label="partno-select"
          onChange={(event, selectedPartNo) => {
            setPartNo(selectedPartNo as string)
          }}
          disabled={
            Array.isArray(partNoList) && partNoList.length ? false : true
          }
          options={partNoList}
          renderInput={(params) => (
            <ThemedTextField {...params} label="Part NO." />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default CustomerAndParts
