import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
  DialogActions,
  Button,
  useTheme
} from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useRef } from "react"
import { IInventorySaleOrder } from "../hook/useFetchData"
Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  }
})
export const ChartInventoryMaterialMonthly = (props: {
  chart?: HighchartsReact.Props
  inventorySaleOrder: IInventorySaleOrder | undefined
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const { inventorySaleOrder } = props
  const theme = useTheme()
  if (!inventorySaleOrder) return <>Loading...</>
  const options: Highcharts.Options = {
    title: {
      text: "กราฟเเสดงการเปรียบเทียบ Sale Order VS Inventory",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    credits: { enabled: false },
    chart: {
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    xAxis: {
      categories: inventorySaleOrder?.inventoryDashboard?.filterData.map(
        (e: { customer: string }) => e.customer
      ),
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "8px"
        }
      }
    },
    legend: {
      enabled: false
    },
    yAxis: [
      {
        labels: {
          style: {
            color: theme.palette.secondary.contrastText
          }
        },
        title: {
          text: "Total",
          style: {
            color: theme.palette.secondary.contrastText
          }
        }
      },
      {
        opposite: true,
        title: {
          text: ""
        }
      }
    ],
    tooltip: {
      shared: true,
      useHTML: true
    },

    plotOptions: {
      column: {
        stacking: "normal"
      }
    },
    series: [
      {
        name: "Sale Order",
        type: "column",
        stack: "inventory",
        color: "#AACB73",
        data: inventorySaleOrder?.inventoryDashboard?.filterData.map(
          (e: { saleOrder: number }) => e.saleOrder
        )
      },
      {
        name: "Inventory Mat't Stock",
        type: "column",
        color: "#FF8B13",
        stack: "saleOrder",
        data: inventorySaleOrder?.inventoryDashboard?.filterData.map(
          (e: { balanceMatStock: number }) =>
            Number(e.balanceMatStock.toFixed())
        )
      },
      {
        name: "Inventory FG Stock",
        type: "column",
        color: "#03C988",
        stack: "saleOrder",
        data: inventorySaleOrder?.inventoryDashboard?.filterData.map(
          (e: { balanceFgStock: number }) => Number(e.balanceFgStock.toFixed())
        )
      }
    ],
    exporting: {
      enabled: false
    }
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props.chart}
      />
    </>
  )
}

interface IInventoryMaterialMonthly {
  type: string
  setInventoryType: (value: string) => void
  inventorySaleOrder: IInventorySaleOrder | undefined
  dialogOpen: boolean
  setDialogOpen: (value: boolean) => void
}
const InventoryMaterialMonthly = (
  props: IInventoryMaterialMonthly
): JSX.Element => {
  const {
    type,
    setInventoryType,
    inventorySaleOrder,
    dialogOpen,
    setDialogOpen
  } = props
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        ></DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12} justifyContent={"center"} margin={2}>
              <TextField
                id="factory"
                fullWidth
                label="Type"
                value={type}
                onChange={(e) => setInventoryType(e.target.value)}
                select
              >
                <MenuItem value={"autoPart"}>Auto Part</MenuItem>
                <MenuItem value={"electronic"}>Electronic</MenuItem>
                <MenuItem value={"furniture"}>Furniture</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={12} xs={12} justifyContent="center">
              <ChartInventoryMaterialMonthly
                inventorySaleOrder={inventorySaleOrder}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default InventoryMaterialMonthly
