import { useLazyQuery, useQuery } from "@apollo/client"
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Tab,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { GET_DEPARTMENT } from "../../common/Query/MasterQuery"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import RepairAuditor from "./component/RepairAuditor"
import Repairer from "./component/Repairer"
import RepairPerson from "./component/RepairPerson"
import RepairPersonFrom from "./component/RepairPersonFrom"
import {
  GET_REPAIRER,
  GET_REPAIR_AUDIT,
  GET_REPAIR_REQUEST
} from "../../common/Query/MaintenanceQuery"
import ShowDocumentRepair from "./component/ShowDocumentRepair"
import { clearParam } from "../../common/utilities"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"
import DateAdapter from "@mui/lab/AdapterMoment"
import moment from "moment"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTabs from "../../common/Resources/ThemedComponents/ThemedTabs"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import React from "react"
interface IPropsTabPanel {
  children?: React.ReactNode
  index: number
  value: number
}

export interface IDepartment {
  departmentId: number
  departmentName: string
  departmentNameThai: string
}

interface ICodeList {
  recordNo: string
  actionDate: string
  documentNo: string
  approveBy: string
}

interface INextStepProcessEndAdornment {
  endAdornment: React.ReactNode
  status: string | undefined
  recordNo: string
}

const NextStepProcessEndAdornment = (props: INextStepProcessEndAdornment) => {
  const { endAdornment, status, recordNo } = props
  const children = React.Children.toArray(
    (endAdornment as React.ReactElement)?.props?.children
  )
  recordNo
    ? children.unshift(
      <CheckCircleIcon
        sx={{
          color: status ? "green" : "red"
        }}
      />
    )
    : null
  return React.isValidElement(endAdornment)
    ? React.cloneElement(endAdornment, {}, children)
    : null
}

const TabPanel = (props: IPropsTabPanel) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}
const pageTitle = "Break Down"
const RepairDocument: React.FC = () => {
  const [tapMenu, setTapMenu] = useState<number>(0)
  const [recordNo, setRecord] = useState<string>("")
  const [department, setDepartment] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [circular, setCircular] = useState<boolean>(false)
  const [openRepairPerson, setOpenRepairPerson] = useState<boolean>(false)
  const [selectDate, setSelectDate] = useState<Date>(new Date())
  const getDepartment = useQuery(GET_DEPARTMENT)

  const [
    getRepairFrom,
    { data: repairRequestData, loading: repairRequestLoading }
  ] = useLazyQuery(GET_REPAIR_REQUEST)
  const [getRepairer, { data: repairerData, loading: repairerLoading }] =
    useLazyQuery(GET_REPAIRER)
  const [
    getRepairAudit,
    { data: repairAuditData, loading: repairAuditLoading }
  ] = useLazyQuery(GET_REPAIR_AUDIT)
  const { data: requestData } = useQuery(GET_REPAIR_REQUEST)

  useEffect(() => {
    if (repairRequestLoading) setCircular(true)
    if (repairerLoading) setCircular(true)
    if (repairAuditLoading) setCircular(true)
    else setCircular(false)
  }, [repairRequestData, repairRequestLoading, repairerData, repairerLoading])

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const departmentParam = urlParams.get("department")
  const noParam = urlParams.get("no")
  const actionDateParam = urlParams.get("date")
  const factoryParam = urlParams.get("factory")

  const handleSubmitRepairFrom = async () => {
    const rawRecordNo = requestData?.repairRequestFrom.find(
      (val: { recordNo: string; actionDate: string }) =>
        `(์No. ${val.recordNo})  Date ${val.actionDate}` === recordNo
    )
    await getRepairFrom({
      variables: {
        department,
        dateFrom: rawRecordNo.actionDate,
        dateTo: rawRecordNo.actionDate,
        recordNo: rawRecordNo.recordNo,
        factory
      },
      fetchPolicy: "network-only"
    })
    await getRepairer({
      variables: {
        department,
        actionDate: rawRecordNo.actionDate,
        recordNo: rawRecordNo.recordNo,
        factory
      },
      fetchPolicy: "network-only"
    })
    await getRepairAudit({
      variables: {
        department,
        actionDate: rawRecordNo.actionDate,
        recordNo: rawRecordNo.recordNo,
        factory
      },
      fetchPolicy: "network-only"
    })
    clearParam("RepairDocument")
  }

  useMemo(async () => {
    if (departmentParam && noParam && actionDateParam && factoryParam) {
      setDepartment(departmentParam ? departmentParam : "")
      setFactory(factoryParam)
      setRecord(
        noParam && actionDateParam
          ? `(์No. ${noParam})  Date ${actionDateParam}`
          : ""
      )

      await getRepairFrom({
        variables: {
          department: departmentParam,
          dateFrom: actionDateParam,
          dateTo: actionDateParam,
          recordNo: noParam,
          factory: factoryParam
        },
        fetchPolicy: "network-only"
      })

      await getRepairer({
        variables: {
          department: departmentParam,
          actionDate: actionDateParam,
          recordNo: noParam,
          factory: factoryParam
        },
        fetchPolicy: "network-only"
      })
      await getRepairAudit({
        variables: {
          department: departmentParam,
          actionDate: actionDateParam,
          recordNo: noParam,
          factory: factoryParam
        },
        fetchPolicy: "network-only"
      })
      clearParam("RepairDocument")
    }
    if (department && selectDate && recordNo && factory) {
      await handleSubmitRepairFrom()
    }
  }, [
    noParam,
    actionDateParam,
    departmentParam,
    factoryParam,
    department,
    selectDate,
    recordNo,
    factory
  ])

  useEffect(() => {
    setRecord("")
  }, [factory, department, selectDate])

  let codeList = [] as ICodeList[]
  if (department && requestData && factory) {
    codeList = requestData?.repairRequestFrom
      .filter(
        (data: {
          department: string
          code: string
          factory: string
          actionDate: string
        }) =>
          data.department === department &&
          data.factory === factory &&
          moment(selectDate).format("YYYY-MM") ===
          moment(data.actionDate).format("YYYY-MM")
      )
      .sort(
        (a: { recordNo: string }, b: { recordNo: string }) =>
          a.recordNo > b.recordNo
      )
  }

  const departmentList: IDepartment[] = getDepartment.data?.department || []
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTapMenu(newValue)
  }
  const tapProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    }
  }

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Maintenance}
        menuItemName={Object.keys(menuItemList)[12]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <Dialog open={circular} onClose={() => setCircular(false)}>
          <DialogContent style={{ width: "auto" }}>
            <CircularProgress />
          </DialogContent>
        </Dialog>
        <PageLabelCard title="Repair Document" />
        <Grid container spacing={2} width={"98%"} margin={2}>
          <Box sx={{ width: "100%" }}>
            <div className="hidden-print">
              <Grid container spacing={2}>
                <Grid item sm={2} xs={12}>
                  <Autocomplete
                    value={
                      department
                        ? departmentList.find(
                          (val) => val.departmentName === department
                        )
                        : {
                          departmentId: 0,
                          departmentName: "",
                          departmentNameThai: ""
                        }
                    }
                    fullWidth
                    onChange={(event, selectedDepartment) => {
                      setDepartment(
                        selectedDepartment?.departmentName as string
                      )
                    }}
                    options={departmentList}
                    getOptionLabel={(option) => option.departmentNameThai}
                    renderInput={(params) => (
                      <ThemedTextField {...params} label="Department" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <ThemedTextField
                    id="factory"
                    fullWidth
                    label="Factory No."
                    value={factory}
                    onChange={(e) => {
                      setFactory(e.target.value)
                      setRecord("")
                    }}
                    select
                  >
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                  </ThemedTextField>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Select Month"
                      views={["year", "month"]}
                      value={selectDate}
                      onChange={(newSelectDate) => {
                        setSelectDate(moment(newSelectDate).toDate() as Date)
                      }}
                      PaperProps={{
                        sx: {
                          "& .MuiCalendarPicker-root": {
                            ".Mui-selected": {
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? theme.palette.warning.main
                                  : theme.palette.primary.main,
                              color: "#000000"
                            }
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <ThemedTextField
                          {...params}
                          fullWidth
                          sx={{
                            "& .Mui-selected": {
                              backgroundColor: theme.palette.warning.main
                            }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Autocomplete
                    fullWidth
                    id="next-step-process"
                    disablePortal
                    value={
                      codeList.find(
                        (val) =>
                          `(์No. ${val.recordNo})  Date ${val.actionDate}` ===
                          recordNo
                      ) || null
                    }
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label={"Document No."}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <NextStepProcessEndAdornment
                              endAdornment={params.InputProps.endAdornment}
                              status={
                                codeList.find(
                                  (val) =>
                                    `(์No. ${val.recordNo})  Date ${val.actionDate}` ===
                                    recordNo
                                )?.approveBy
                              }
                              recordNo={recordNo}
                            />
                          )
                        }}
                      />
                    )}
                    onChange={(e, newValue) => {
                      setRecord(
                        newValue?.recordNo
                          ? `(์No. ${newValue.recordNo})  Date ${newValue.actionDate}`
                          : ""
                      )
                    }}
                    getOptionLabel={(option) =>
                      option?.recordNo
                        ? `(์No. ${option.recordNo})  Date ${option.actionDate}`
                        : ""
                    }
                    renderOption={(props, options) => {
                      return (
                        <Box component="li" {...props}>
                          <Grid container sx={{ padding: "0 5px" }}>
                            <Grid item xs="auto">
                              {`(์No. ${options.recordNo})  Date ${options.actionDate}`}
                            </Grid>
                            <Grid item container xs justifyContent="flex-end">
                              <CheckCircleIcon
                                sx={{
                                  color: options.approveBy ? "green" : "red"
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      )
                    }}
                    options={codeList}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <ThemedLoadingButton
                    variant="contained"
                    sx={{ mt: 1, width: "150px", marginLeft: "10px" }}
                    style={{ backgroundColor: theme.palette.warning.main }}
                    autoFocus
                    onClick={() => setOpenRepairPerson(true)}
                  >
                    CREATE
                  </ThemedLoadingButton>
                </Grid>
              </Grid>
              <Dialog
                open={openRepairPerson}
                onClose={() => setOpenRepairPerson(false)}
                maxWidth="xl"
                fullWidth
              >
                <DialogTitle style={{ textAlign: "center" }}>
                  สำหรับผู้เเจ้งซ่อม
                </DialogTitle>
                <DialogContent>
                  <RepairPersonFrom setOpenRepairPerson={setOpenRepairPerson} />
                </DialogContent>
              </Dialog>
            </div>

            <Box className="hidden-print">
              <ThemedTabs
                value={tapMenu}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="สำหรับผู้เเจ้งซ่อม" {...tapProps(0)} />
                <Tab label="สำหรับผู้ซ่อม" {...tapProps(1)} />
                <Tab label="สำหรับผู้ตรวจรับ" {...tapProps(2)} />
                <Tab
                  label="ใบเเจ้งซ่อม"
                  {...tapProps(3)}
                  style={
                    repairAuditData?.getRepairAuditor[0]
                      ? {}
                      : { display: "none" }
                  }
                />
              </ThemedTabs>
            </Box>
            <TabPanel value={tapMenu} index={0}>
              <br></br>
              {repairRequestData && recordNo ? (
                <RepairPerson
                  factory={factory}
                  data={repairRequestData?.repairRequestFrom[0]}
                />
              ) : (
                <div></div>
              )}
            </TabPanel>
            <TabPanel value={tapMenu} index={1}>
              {!repairRequestData?.repairRequestFrom[0]?.approveBy &&
                recordNo ? (
                <div></div>
              ) : (
                <Repairer
                  data={repairerData?.getRepairerData[0]}
                  repairFrom={repairRequestData?.repairRequestFrom[0]}
                />
              )}
            </TabPanel>
            <TabPanel value={tapMenu} index={2}>
              {repairerData?.getRepairerData[0]?.leaderCheck &&
                repairerData?.getRepairerData[0]?.managerCheck &&
                recordNo ? (
                <RepairAuditor
                  repairAuditData={repairAuditData?.getRepairAuditor[0]}
                  repairFrom={repairRequestData?.repairRequestFrom[0]}
                />
              ) : (
                <div></div>
              )}
            </TabPanel>
            <TabPanel value={tapMenu} index={3}>
              {repairAuditData?.getRepairAuditor[0] && recordNo ? (
                <>
                  <ShowDocumentRepair
                    repairer={repairerData?.getRepairerData[0]}
                    repairAuditData={repairAuditData?.getRepairAuditor[0]}
                    repairFrom={repairRequestData?.repairRequestFrom[0]}
                  />
                </>
              ) : (
                <div></div>
              )}
            </TabPanel>
          </Box>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default RepairDocument
