import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import exporting from "highcharts/modules/exporting.js"
import accessibility from "highcharts/modules/accessibility.js"
import { Autocomplete, Grid, TextField } from "@mui/material"
import { PMColumnChart } from "./dashboardComponent/PMDashboard"
import { MTTRColumnDashboard } from "./dashboardComponent/MTTRDashboard"
import { ITargetKpi } from "./MTTR"
import { useState } from "react"
import { MTBFDashboard } from "./dashboardComponent/MTBFDashboard"
import { BMDashboard } from "./dashboardComponent/BMDashboard"
import { RepairNotiDashboard } from "./dashboardComponent/RepairNotiDashboard"
import { MaintenanceTimeDashboard } from "./dashboardComponent/MaintenanceTimeDashboard"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import moment from "moment"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
exporting(Highcharts)
accessibility(Highcharts)
interface IPMKpi {
  target: number
  month: number
  actual: number
}
interface Repairer {
  id: number
  checkReceive: number
  completeDate: number
  department: number
  docNo: number
  repairMan: number
  repairType: number
  receiver: number
  detail: number
  requestDate: number
  startRepair: number
  waitRepair: number
  endWaitRepair: number
  referDate: number
  leaderCheck: number
  managerCheck: number
}
const MaintenanceDashboard = (props: {
  chart?: HighchartsReact.Props
  pmData: IPMKpi[]
  linePress: Repairer[]
  lineSpot: Repairer[]
  lineArcWelding: Repairer[]
  MTBFTarget: ITargetKpi[]
  MTTRTarget: ITargetKpi[]
  BMTarget: ITargetKpi[]
  year: number
  factory: string
  rowsMachineRuntime: never[] | undefined

  setShowChart?: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
  const {
    pmData,
    linePress,
    lineSpot,
    lineArcWelding,
    MTBFTarget,
    MTTRTarget,
    rowsMachineRuntime,
    BMTarget,
    factory,
    year
  } = props
  const [MTTRLine, setMTTRLine] = useState<string>("Press")
  const [MTBFLine, setMTBFLine] = useState<string>("Press")
  const [BMLine, setBMLine] = useState<string>("Press")
  const [dateMonth, setDateMonth] = useState<Date>(new Date())

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>PM.Machine ให้ได้ตามแผน</h1>
      <div style={{ display: "inline-block" }}>
        <PMColumnChart pmData={pmData} />
      </div>
      <hr />
      <div>
        <h1 style={{ textAlign: "center" }}>
          ระยะเวลาเฉลี่ยในการซ่อมแต่ละครั้ง(MTTR)
        </h1>
        <Autocomplete
          fullWidth
          value={MTTRLine}
          onChange={(event, value) => setMTTRLine(value as string)}
          options={["Press", "Spot", "ArcWelding"]}
          renderInput={(params) => <TextField {...params} label="Line" />}
        />
      </div>
      <div>
        <MTTRColumnDashboard
          lineData={
            MTTRLine === "Press"
              ? linePress
              : MTTRLine === "Spot"
              ? lineSpot
              : MTTRLine === "ArcWelding"
              ? lineArcWelding
              : []
          }
          target={MTTRTarget.filter((e) => e.line === MTTRLine)[0]}
        />
      </div>
      <hr />
      <div>
        <h1 style={{ textAlign: "center" }}>
          ระยะเวลาที่เครื่องจักรจะเสีย (MTBF)
        </h1>
        <Autocomplete
          fullWidth
          value={MTBFLine}
          onChange={(event, value) => setMTBFLine(value as string)}
          options={["Press", "Spot", "ArcWelding"]}
          renderInput={(params) => <TextField {...params} label="Line" />}
        />
      </div>
      <div>
        <MTBFDashboard
          lineData={
            MTBFLine === "Press"
              ? linePress
              : MTBFLine === "Spot"
              ? lineSpot
              : MTBFLine === "ArcWelding"
              ? lineArcWelding
              : []
          }
          type={
            MTBFLine === "Press"
              ? "stamp"
              : MTBFLine === "Spot"
              ? "weld"
              : MTBFLine === "ArcWelding"
              ? "arcWeld"
              : ""
          }
          rowsMachineRuntime={rowsMachineRuntime}
          target={MTBFTarget.filter((e) => e.line === MTBFLine)[0]}
        />
      </div>
      <hr />
      <div>
        <h1 style={{ textAlign: "center" }}>
          เวลาสูญเสียที่เกิดจากเครื่องจักร
        </h1>
        <Autocomplete
          fullWidth
          value={BMLine}
          onChange={(event, value) => setBMLine(value as string)}
          options={["Press", "Spot", "ArcWelding"]}
          renderInput={(params) => <TextField {...params} label="Line" />}
        />
      </div>
      <div>
        <BMDashboard
          lineData={
            BMLine === "Press"
              ? linePress
              : BMLine === "Spot"
              ? lineSpot
              : BMLine === "ArcWelding"
              ? lineArcWelding
              : []
          }
          type={
            BMLine === "Press"
              ? "stamp"
              : BMLine === "Spot"
              ? "weld"
              : BMLine === "ArcWelding"
              ? "arcWeld"
              : ""
          }
          rowsMachineRuntime={rowsMachineRuntime}
          target={BMTarget.filter((e) => e.line === BMLine)[0]}
        />
      </div>

      <div>
        <h1 style={{ textAlign: "center" }}>กราฟเปรียบเทียบใบแจ้งซ่อม</h1>
        <Autocomplete
          fullWidth
          value={BMLine}
          onChange={(event, value) => setBMLine(value as string)}
          options={["Press", "Spot", "ArcWelding"]}
          renderInput={(params) => <TextField {...params} label="Line" />}
        />
      </div>
      <div>
        <RepairNotiDashboard
          lineData={BMLine}
          factory={factory}
          year={year.toString()}
        />
      </div>
      <div>
        <h1 style={{ textAlign: "center" }}>เวลาที่เครื่องจักร maintenance</h1>

        <Grid item container sm={12} spacing={2}>
          <Grid item sm={6}>
            <Autocomplete
              fullWidth
              value={BMLine}
              onChange={(event, value) => setBMLine(value as string)}
              options={["Press", "Spot", "ArcWelding"]}
              renderInput={(params) => <TextField {...params} label="Line" />}
            />
          </Grid>
          <Grid item sm={6}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                label="เลือกเดือน"
                views={["month"]}
                inputFormat="MM"
                value={dateMonth}
                onChange={(newSelectDate) => {
                  setDateMonth(moment(newSelectDate).toDate() as Date)
                }}
                renderInput={(params) => (
                  <ThemedTextField {...params} fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </div>
      <div>
        <MaintenanceTimeDashboard
          lineData={BMLine}
          factory={factory}
          month={(dateMonth.getMonth() + 1).toString()}
          year={year.toString()}
        />
      </div>
    </div>
  )
}
export default MaintenanceDashboard
