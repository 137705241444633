import { useQuery } from "@apollo/client"
import {
  Box,
  Grid,
  Autocomplete,
  styled,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  CardHeader,
  useTheme,
  ThemeProvider
} from "@mui/material"
import { grey } from "@mui/material/colors"
import React, { useState } from "react"
import { GET_MATERIALS, GET_PARTS } from "../../common/Query/MasterQuery"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import AutocompleteCustomer from "../../common/Resources/AutocompleteCustomer"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import {
  StyledTableCell,
  StyledTableRow
} from "../../common/Resources/ThemedComponents/StyledTableComponents"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Barcode = require("react-barcode")
interface Column {
  id:
    | "matNo"
    | "partName"
    | "sizeH"
    | "sizeW"
    | "sizeL"
    | "spec"
    | "customer"
    | "barcode"
  label: string
  minWidth?: number
  align?: "left" | "right" | "center"
  format?: (value: number) => string
}

interface IRows {
  matNo: string
  partName: string
  sizeH: string
  sizeW: string
  sizeL: string
  spec: string
  customer: string
  barcode: string
}

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    padding: 6px;

  }

  th {
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[100]};
    font-weight:bold;
  }
  `
)

const pageTitle = "Barcode Part List"

const columns: Column[] = [
  { id: "matNo", label: "Mat No", minWidth: 50 },
  { id: "partName", label: "Part Name", minWidth: 50 },
  { id: "sizeH", label: "Size H", minWidth: 20, align: "right" },
  { id: "sizeW", label: "Size W", minWidth: 20, align: "right" },
  { id: "sizeL", label: "Size L", minWidth: 20, align: "right" },
  { id: "spec", label: "Spec", minWidth: 50, align: "right" },
  { id: "customer", label: "Customer", minWidth: 20, align: "center" },
  { id: "barcode", label: "Barcode", minWidth: 20, align: "center" }
]

const BarCodeMaterial: React.FC = () => {
  const theme = useTheme()
  const [partNo, setPartNo] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [sizeH, setSizeH] = useState<string | null>("")
  const [spec, setSpec] = useState<string | null>("")
  const [rows, setRows] = useState<IRows[]>([])
  const [print, setPrint] = useState<boolean>(false)
  const [style, setStyle] = useState<string>("")
  const getPart = useQuery(GET_PARTS)
  const getMaterial = useQuery(GET_MATERIALS)
  let sizeHList: Array<string> = []
  let specList: Array<string> = []

  if (getPart.data && getMaterial.data) {
    sizeHList = Array.from(
      new Set(getMaterial.data.materials.map((e: { sizeH: string }) => e.sizeH))
    )
    specList = Array.from(
      new Set(getMaterial.data.materials.map((e: { spec: string }) => e.spec))
    )
  }
  const handleSubmit = () => {
    if (getMaterial.data) {
      let material = getMaterial.data.materials.map(
        (mat: { matNo: string }) => {
          const part = getPart.data.parts.find(
            (element: { partNo: string }) => element.partNo === mat.matNo
          )
          return {
            ...mat,
            partName: part ? part.partName : "",
            customer: part ? part.customer : ""
          }
        }
      )
      if (partNo)
        material = material.filter(
          (row: { matNo: string }) => row.matNo === partNo
        )
      if (customer)
        material = material.filter(
          (row: { customer: string }) => row.customer === customer
        )

      if (sizeH)
        material = material.filter(
          (row: { sizeH: string }) => row.sizeH === sizeH
        )
      if (spec)
        material = material.filter((row: { spec: string }) => row.spec === spec)
      setRows(material)
    }
  }
  const handlePrint = () => {
    setPrint(true)
    setStyle("print-sm-order")
  }
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Materials}
        menuItemName={Object.keys(menuItemList)[1]}
        menuPageName={pageTitle}
      />
      {print ? (
        <Grid container>
          <Grid item>
            <ThemedButton
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: "red" }}
              onClick={() => setPrint(false)}
            >
              Return
            </ThemedButton>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <ThemedCard>
        {!print ? (
          <>
            <CardHeader
              titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
              title="Barcode Part List"
              subheaderTypographyProps={{ fontFamily: "Sarabun" }}
              subheader="List of Barcode Parts"
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "LEFT"
              }}
            >
              <Box component="form">
                <Grid
                  container
                  spacing={2}
                  style={{ padding: "20px", paddingTop: "0" }}
                >
                  <Grid item xs={12} sm={3}>
                    <AutocompletePartNo setPartNo={setPartNo} partNo={partNo} />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <AutocompleteCustomer
                      setCustomer={setCustomer}
                      customer={customer}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="select-sizeh"
                      options={sizeHList}
                      style={{ width: "100%" }}
                      onChange={(e, newValue) => setSizeH(newValue)}
                      renderInput={(params) => (
                        <ThemedTextField
                          {...params}
                          label="Size H"
                          value={sizeH}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="spec"
                      options={specList}
                      style={{ width: "100%" }}
                      onChange={(e, newValue) => setSpec(newValue)}
                      renderInput={(params) => (
                        <ThemedTextField
                          {...params}
                          label="Spec"
                          value={spec}
                        />
                      )}
                    />
                  </Grid>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs="auto">
                      <ThemedButton
                        variant="contained"
                        sx={{
                          mt: 3,
                          mb: 2
                        }}
                        onClick={handleSubmit}
                      >
                        Submit
                      </ThemedButton>
                    </Grid>
                    <Grid item xs="auto">
                      <ThemedButton
                        variant="contained"
                        contrast
                        sx={{
                          mt: 3,
                          mb: 2
                        }}
                        onClick={handlePrint}
                      >
                        Print
                      </ThemedButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        ) : (
          <></>
        )}

        <div className={style}>
          <Root sx={{ width: "100%" }}>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="print-sm-order"
              >
                <TableHead>
                  <StyledTableRow>
                    {columns.map((column) => (
                      <StyledTableCell key={column.id} align={column.align}>
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => {
                    return (
                      <StyledTableRow hover tabIndex={-1} key={i}>
                        {columns.map((column) => {
                          const value = row[column.id]
                          return (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                            >
                              {column.id === "barcode" ? (
                                <Barcode value={row.matNo}></Barcode>
                              ) : (
                                value
                              )}
                            </StyledTableCell>
                          )
                        })}
                      </StyledTableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Root>
        </div>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default BarCodeMaterial
