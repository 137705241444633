import {
  Box,
  Card,
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  useTheme,
  styled,
  TableRow,
  tableCellClasses,
  ThemeProvider
} from "@mui/material"
import HighchartsReact from "highcharts-react-official"
import BarChart from "../../common/Resources/CustomBarChart"
import OEEDashBoard from "./component/OEEDashBoard"

interface Column {
  id: "partNo" | "machine" | "process" | "jobOrder" | "quantity" | "totalNG"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

const columns: Column[] = [
  { id: "partNo", label: "Part No.", minWidth: 50 },
  { id: "machine", label: "Machine", minWidth: 50 },
  { id: "process", label: "Process", minWidth: 50 },
  { id: "jobOrder", label: "Job Order", minWidth: 50 },
  { id: "quantity", label: "Quantity", minWidth: 50 },
  { id: "totalNG", label: "Total NG", minWidth: 50 }
]
export interface IOEE {
  machine: string
  productivity: number
  performance: number
  actual: number
  target: number
  rateQ: number
  oee: number
  ng: number
  totalQuantity: number
  startTime: string
  endTime: string
}

export interface IMachineProblem {
  startTime: string
  endTime: string
  problemName: string
  machine: string
}

interface IProp {
  chart?: HighchartsReact.Props
  data: IOEE[]
  wipData: IProduction[]
  machineProblem: IMachineProblem[]
}
interface IProduction {
  partNo: string
  machine: string
  process: string
  jobOrder: string
  quantity: string
  iNG: number
  sNG: number
  tNG: number
  supplierNG: number
  totalNG: number
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "back",
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    padding: 0,
    lineBreak: "auto"
  },
  [`&.${tableCellClasses.body}`]: { fontSize: 12, fontFamily: "Sarabun" }
}))
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.black
  },
  "td, th": { border: 0, padding: 3 }
}))

const OEE: React.FC<IProp> = (props: IProp) => {
  const { data, wipData, machineProblem } = props

  const theme = useTheme()
  let rows: IProduction[] = []
  let totalNG = 0
  let totalQuantity = 0
  if (wipData) {
    rows = wipData.map((wip) => {
      return {
        ...wip,
        totalNG: wip.iNG + wip.sNG + wip.tNG + wip.supplierNG
      }
    })
  }
  return (
    <ThemeProvider theme={theme}>
      {data?.map((oeeReport, i) => {
        if (wipData) {
          rows = wipData
            .filter((dataScan) => dataScan.machine === oeeReport.machine)
            .map((wip) => {
              return {
                ...wip,
                totalNG: wip.iNG + wip.sNG + wip.tNG + wip.supplierNG
              }
            })
          totalNG = rows.reduce((a, b) => a + b.totalNG, 0)
          totalQuantity = rows.reduce((a, b) => a + Number(b.quantity), 0)
        }
        return (
          <Card
            style={{
              margin: "10px",
              backgroundColor: theme.palette.primary.main
            }}
            key={i}
          >
            <Box textAlign={"center"}>
              <h3 style={{ color: theme.palette.secondary.contrastText }}>
                {oeeReport.machine}
              </h3>
            </Box>
            <Grid container>
              <Grid
                item
                container
                spacing={2}
                direction={"column"}
                alignItems="center"
                sm={12}
                lg={7.5}
                xl={6}
              >
                <Grid item>
                  <Input
                    disableUnderline
                    defaultValue={"Production Target : "}
                    inputProps={{
                      style: {
                        textAlign: "center",
                        fontWeight: "bold",
                        color: theme.palette.secondary.contrastText
                      }
                    }}
                  />
                  <Input
                    disableUnderline
                    defaultValue={oeeReport.totalQuantity}
                    sx={{ width: "10%" }}
                    inputProps={{
                      style: {
                        textAlign: "center",
                        fontWeight: "bold",
                        color: theme.palette.secondary.contrastText
                      }
                    }}
                  />
                  <br />
                  <Input
                    disableUnderline
                    defaultValue={"Production Actual : "}
                    inputProps={{
                      style: {
                        textAlign: "center",
                        fontWeight: "bold",
                        color: theme.palette.secondary.contrastText
                      }
                    }}
                  />
                  <Input
                    disableUnderline
                    defaultValue={oeeReport.actual + oeeReport.ng}
                    sx={{ width: "10%" }}
                    inputProps={{
                      style: {
                        textAlign: "center",
                        fontWeight: "bold",
                        color: theme.palette.secondary.contrastText
                      }
                    }}
                  />
                  <Input
                    disableUnderline
                    defaultValue={"OK"}
                    sx={{ width: "10%" }}
                    inputProps={{
                      style: {
                        textAlign: "center",
                        fontWeight: "bold",
                        color: theme.palette.secondary.contrastText
                      }
                    }}
                  />
                  <Input
                    disableUnderline
                    defaultValue={oeeReport.actual}
                    sx={{ width: "10%" }}
                    inputProps={{
                      style: {
                        textAlign: "center",
                        fontWeight: "bold",
                        color: theme.palette.secondary.contrastText
                      }
                    }}
                  />
                  <Input
                    disableUnderline
                    defaultValue={"NG"}
                    sx={{ width: "10%" }}
                    inputProps={{
                      style: {
                        textAlign: "center",
                        fontWeight: "bold",
                        color: theme.palette.secondary.contrastText
                      }
                    }}
                  />
                  <Input
                    disableUnderline
                    defaultValue={oeeReport.ng}
                    sx={{ width: "10%" }}
                    inputProps={{
                      style: {
                        textAlign: "center",
                        fontWeight: "bold",
                        color: theme.palette.secondary.contrastText
                      }
                    }}
                  />
                </Grid>
                <Grid item sm={6} justifyContent={"center"}>
                  <Box>
                    <OEEDashBoard
                      data={{ type: "OEE", percent: oeeReport.oee }}
                    />
                    <Input
                      disableUnderline
                      defaultValue="="
                      sx={{ float: "left", width: "10px", mt: 5 }}
                      inputProps={{
                        style: {
                          textAlign: "center",
                          color: theme.palette.secondary.contrastText
                        }
                      }}
                    />
                    <OEEDashBoard
                      data={{
                        type: "Availability",
                        percent: oeeReport.productivity
                      }}
                    />
                    <Input
                      disableUnderline
                      defaultValue="X"
                      sx={{ float: "left", width: "10px", mt: 5 }}
                      inputProps={{
                        style: {
                          textAlign: "center",
                          color: theme.palette.secondary.contrastText
                        }
                      }}
                    />
                    <OEEDashBoard
                      data={{
                        type: "Performance",
                        percent: oeeReport.performance
                      }}
                    />
                    <Input
                      disableUnderline
                      defaultValue="X"
                      sx={{ float: "left", width: "10px", mt: 5 }}
                      inputProps={{
                        style: {
                          textAlign: "center",
                          color: theme.palette.secondary.contrastText
                        }
                      }}
                    />
                    <OEEDashBoard
                      data={{ type: "Quality", percent: oeeReport.rateQ }}
                    />
                  </Box>
                  <BarChart
                    machineProblem={machineProblem.filter(
                      (problem) => problem.machine === oeeReport.machine
                    )}
                    startDayTime={oeeReport.startTime}
                    endDayTime={oeeReport.endTime}
                  />
                </Grid>
              </Grid>
              <Grid item sm={12} lg={4.5} xl={6} style={{ padding: 10 }}>
                <TableContainer
                  sx={{
                    border: 2,
                    maxHeight: "350px",
                    minHeight: "250px",
                    overflow: "scroll"
                  }}
                >
                  <Table>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell
                          align="center"
                          colSpan={12}
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                        >
                          Production Report
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ top: 57, minWidth: column.minWidth }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    </TableBody>
                    <TableBody>
                      {rows.map((row, i) => {
                        return (
                          <StyledTableRow
                            tabIndex={-1}
                            key={i}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {columns.map((column) => {
                              const value = row[column.id]
                              return (
                                <StyledTableCell key={column.id} align={"left"}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </StyledTableCell>
                              )
                            })}
                          </StyledTableRow>
                        )
                      })}
                      <StyledTableRow>
                        <StyledTableCell colSpan={4} align="center">
                          Total
                        </StyledTableCell>
                        <StyledTableCell>{totalQuantity}</StyledTableCell>
                        <StyledTableCell>{totalNG}</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Card>
        )
      })}
    </ThemeProvider>
  )
}
export default OEE
