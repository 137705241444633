import { useQuery } from "@apollo/client"
import { GET_LIST_INVOICE } from "../../../common/Query/MaterialQuery"

export const useInvoice = (
  partNo: string,
  actionDate: string,
  factory: string
): string[] => {
  const { data } = useQuery(GET_LIST_INVOICE, {
    variables: {
      partNo: partNo,
      date: actionDate,
      factory: factory
    },
    fetchPolicy: "network-only"
  })

  const listInvoice = data?.GetListInvoiceNo || []

  return listInvoice
}
