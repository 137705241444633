import { gql } from "@apollo/client"

export const GET_INVOICE_REPORT = gql`
  query QuantityAll($dateStart: String, $dateEnd: String) {
    quantityAll(dateStart: $dateStart, dateEnd: $dateEnd) {
      partNo
      lastAdjustPrice
      newUnitPrice
      diffUnitPrice
      status
      quantity
      month
      year
      differentPrice
      vatStatus
      businessPlace
      newPriceConfirmNo
    }
  }
`
export const GET_BUSINESS_PLACE = gql`
  query Query {
    getBusinessPlace
  }
`
export const GET_VAT_STATUS = gql`
  query Query {
    getVatStatus
  }
`
export const GET_STATUS = gql`
  query Query {
    getStatus
  }
`
export const GET_NEW_PRICE_CONFIRM_NO = gql`
  query Query {
    getNewPriceConfirmNo
  }
`
