import { useMutation } from "@apollo/client"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Grid,
  Switch
} from "@mui/material"
import { useState, SetStateAction } from "react"
import QrReader from "react-qr-reader"
import { MAT_OUT_SCANNER } from "../../../common/Mutation/MaterialMutation"
import { PageLabelCard } from "../../../common/Resources/PageLabelCard"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import swal from "sweetalert"
import ThemedCard from "../../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"

interface IProp {
  invoiceNo: string
  materialPlan: number
  jobOrder: string
}

const ActualPlan = (props: IProp): JSX.Element => {
  const [isStream, setIsStream] = useState(false)
  const [partNo, setPartNo] = useState<string>("")
  const [quantity, setQuantity] = useState<number>()
  const [actionDate, setActionDate] = useState<string>("")
  const [factNo, setFactNo] = useState<string>("")
  const [operator, setOperator] = useState<string>("")
  const [remark, setRemark] = useState<string>("")
  const [dataScan, setDataScan] = useState<string>("")
  const [boxNo, setBoxNo] = useState<string>("")
  const [invoiceNo, setInvoiceNo] = useState<string>("")
  const [matScanner, { loading }] = useMutation(MAT_OUT_SCANNER)

  const handleScan = (data: SetStateAction<string> | null): void => {
    if (data) {
      setDataScan(data)
      if (dataScan) {
        breakQRCode()
      }
    }
  }
  const handleError = (err: string) => {
    console.error(err)
  }
  const factoryList = ["1", "2"]
  const breakQRCode = async (e?: { preventDefault: () => void }) => {
    e?.preventDefault()
    const newSplit = dataScan.split("|")
    if (newSplit.length !== 5) {
      return swal("Error", "มีปัญหากับ format ของ QR Code!", "warning")
    } else {
      swal("Success", "Scan Actual Plan Successful", "success")
    }
    if (newSplit[2] !== props.invoiceNo) {
      return swal("Error", "Invoice ไม่ตรงกับ JobOrder!", "warning")
    }
    setPartNo(newSplit[0])
    setInvoiceNo(newSplit[2])
    setQuantity(parseFloat(newSplit[3]))
    setBoxNo(newSplit[4])
  }
  const isComplete = () => {
    return partNo && invoiceNo && quantity && actionDate && factNo
  }

  const handleClick = async () => {
    if (!isComplete()) {
      return swal("warning", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
    const data = {
      partNo,
      invoiceNo,
      quantity,
      actionDate,
      factory: factNo,
      packingBy: operator,
      boxNo,
      jobOrder: props.jobOrder,
      actualPlan: props.materialPlan
    }
    try {
      await matScanner({
        variables: {
          inputMatOut: data
        }
      })
      swal("Success", "บันทึกข้อมูลสำเร็จ", "success")
      onReset()
    } catch (err) {
      swal("Error", `${(err as Error).message}`, "warning")
    }
  }

  const onReset = () => {
    setPartNo("")
    setInvoiceNo("")
    setQuantity(0)
    setDataScan("")
  }

  return (
    <>
      <Box component="form" onSubmit={breakQRCode}>
        <Accordion
          expanded={isStream}
          onChange={() => setIsStream(!isStream)}
          style={{ marginBottom: "5px", marginTop: "10px" }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ display: "none" }}
          ></AccordionSummary>
          <AccordionDetails>
            {isStream ? (
              <>
                <Grid container justifyContent={"center"}>
                  <div style={{ width: "500px" }}>
                    <QrReader
                      delay={300}
                      onError={handleError}
                      onScan={handleScan}
                      style={{ width: "70%" }}
                    />
                  </div>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </AccordionDetails>
        </Accordion>
        <ThemedCard>
          <PageLabelCard title="Actual Plan" subTitle="Add actual plan" />
          <>
            <Grid container spacing={2} paddingLeft={2} width={"98%"}>
              <Grid item xs={6} sm={12}>
                <Switch
                  checked={isStream}
                  onChange={(event) =>
                    setIsStream(event.target.value ? !isStream : isStream)
                  }
                  name="gilad"
                />
                <label style={{ fontSize: "14px" }}>
                  Active Camera{" "}
                  <CameraAltIcon style={{ verticalAlign: "middle" }} />
                </label>
              </Grid>
              <Grid
                item
                xs={6}
                justifyContent="flex-end"
                sx={{ display: { xs: "flex", sm: "none" } }}
              >
                <ThemedLoadingButton
                  variant="contained"
                  style={{
                    width: "120px",
                    height: "36px",
                    top: "9px"
                  }}
                  onClick={handleClick}
                  loading={loading}
                >
                  Submit
                </ThemedLoadingButton>
              </Grid>

              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  id="scanner"
                  value={dataScan}
                  label="scanner"
                  onChange={(e) => setDataScan(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                justifyContent="flex-end"
                sx={{ display: { xs: "none", sm: "flex" } }}
              >
                <ThemedLoadingButton
                  variant="contained"
                  style={{
                    width: "120px",
                    height: "36px",
                    top: "9px"
                  }}
                  onClick={handleClick}
                  loading={loading}
                >
                  Submit
                </ThemedLoadingButton>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ThemedTextField
                  fullWidth
                  label="Part No."
                  disabled
                  className="disable-back-ground-color"
                  value={partNo}
                  id="partNo"
                  autoComplete="family-name"
                />
              </Grid>

              <Grid item xs={12}>
                <ThemedTextField
                  label="Invoice No."
                  fullWidth
                  disabled
                  className="disable-back-ground-color"
                  value={invoiceNo}
                  id="invoiceNo"
                  autoComplete="family-name"
                />
              </Grid>

              <Grid item xs={12}>
                <ThemedTextField
                  label="Quantity"
                  fullWidth
                  disabled
                  className="disable-back-ground-color"
                  value={quantity}
                  id="quantity"
                  autoComplete="family-name"
                />
              </Grid>

              <Grid item xs={12}>
                <ThemedTextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  label="Receive Date"
                  fullWidth
                  type="date"
                  value={actionDate}
                  id="actionDate"
                  onChange={(e) => setActionDate(e.target.value)}
                  autoComplete="family-name"
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  onChange={(event, selectedFactory) => {
                    setFactNo(selectedFactory as string)
                  }}
                  options={factoryList}
                  renderInput={(params) => (
                    <ThemedTextField {...params} label="Factory" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <ThemedTextField
                  fullWidth
                  label="Operator"
                  value={operator}
                  onChange={(e) => setOperator(e.target.value)}
                  id="operator"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <ThemedTextField
                  fullWidth
                  label="remark"
                  value={remark}
                  id="remark"
                  onChange={(e) => setRemark(e.target.value)}
                  autoComplete="family-name"
                />
              </Grid>
            </Grid>
            <Button type="submit" />
          </>
        </ThemedCard>
      </Box>
    </>
  )
}
export default ActualPlan
