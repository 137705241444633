import { gql } from "@apollo/client"

export const ADD_ZINC_BARREL = gql`
  mutation CreateZincBarrel(
    $customer: String
    $model: String
    $partNo: String
    $partName: String
    $wt: Float
    $adm: Float
    $spec: String
    $kg: Float
    $pcs: Float
    $colour: String
    $chromatting: String
    $packing: String
  ) {
    createZincBarrel(
      customer: $customer
      model: $model
      partNo: $partNo
      partName: $partName
      wt: $wt
      adm: $adm
      spec: $spec
      kg: $kg
      pcs: $pcs
      colour: $colour
      chromatting: $chromatting
      packing: $packing
    ) {
      customer
      model
      partNo
      partName
      wt
      adm
      spec
      kg
      pcs
      chromatting
      colour
      packing
    }
  }
`
export const UPDATE_ZINC_BARREL = gql`
  mutation UpdateZincBarrel(
    $customer: String
    $model: String
    $partNo: String
    $partName: String
    $wt: Float
    $adm: Float
    $spec: String
    $kg: Float
    $pcs: Float
    $colour: String
    $chromatting: String
    $packing: String
  ) {
    updateZincBarrel(
      customer: $customer
      model: $model
      partNo: $partNo
      partName: $partName
      wt: $wt
      adm: $adm
      spec: $spec
      kg: $kg
      pcs: $pcs
      colour: $colour
      chromatting: $chromatting
      packing: $packing
    )
  }
`
export const DELETE_ZINC_BARREL = gql`
  mutation DeleteZincBarrel($partNo: String) {
    deleteZincBarrel(partNo: $partNo)
  }
`
export const UPDATE_ZINC_RACK = gql`
  mutation UpdateZincRack(
    $customer: String
    $model: String
    $partNo: String
    $partName: String
    $wt: Float
    $adm: Float
    $spec: String
    $volt: String
    $small: Float
    $big: Float
    $twoSump: String
    $min: String
    $colour: String
    $packing: String
  ) {
    updateZincRack(
      customer: $customer
      model: $model
      partNo: $partNo
      partName: $partName
      wt: $wt
      adm: $adm
      spec: $spec
      volt: $volt
      small: $small
      big: $big
      twoSump: $twoSump
      min: $min
      colour: $colour
      packing: $packing
    )
  }
`
export const ADD_ZINC_RACK = gql`
  mutation CreateZincRack(
    $customer: String
    $model: String
    $partNo: String
    $partName: String
    $wt: Float
    $adm: Float
    $spec: String
    $volt: String
    $small: Float
    $big: Float
    $twoSump: String
    $min: String
    $colour: String
    $packing: String
  ) {
    createZincRack(
      customer: $customer
      model: $model
      partNo: $partNo
      partName: $partName
      wt: $wt
      adm: $adm
      spec: $spec
      volt: $volt
      small: $small
      big: $big
      twoSump: $twoSump
      min: $min
      colour: $colour
      packing: $packing
    ) {
      customer
      model
      partNo
      partName
      wt
      adm
      spec
      volt
      small
      big
      twoSump
      min
      colour
      packing
    }
  }
`
export const DELETE_ZINC_RACK = gql`
  mutation DeleteZincRack($partNo: String) {
    deleteZincRack(partNo: $partNo)
  }
`
export const ADD_EDP_COATING = gql`
  mutation CreateEdpCoating(
    $customer: String
    $model: String
    $partNo: String
    $partName: String
    $wt: Float
    $adm: Float
    $spec: String
    $jig: String
    $round: Float
    $volt: String
    $oc: Float
    $min: String
    $packing: String
  ) {
    createEdpCoating(
      customer: $customer
      model: $model
      partNo: $partNo
      partName: $partName
      wt: $wt
      adm: $adm
      spec: $spec
      jig: $jig
      round: $round
      volt: $volt
      oc: $oc
      min: $min
      packing: $packing
    ) {
      customer
      model
      partNo
      partName
      wt
      adm
      spec
      jig
      round
      volt
      oc
      min
      packing
    }
  }
`
export const UPDATE_EDP_COATING = gql`
  mutation UpdateEdpCoating(
    $customer: String
    $model: String
    $partNo: String
    $partName: String
    $wt: Float
    $adm: Float
    $spec: String
    $jig: String
    $round: Float
    $volt: String
    $oc: Float
    $min: String
    $packing: String
  ) {
    updateEdpCoating(
      customer: $customer
      model: $model
      partNo: $partNo
      partName: $partName
      wt: $wt
      adm: $adm
      spec: $spec
      jig: $jig
      round: $round
      volt: $volt
      oc: $oc
      min: $min
      packing: $packing
    )
  }
`
export const DELETE_EDP_COATING = gql`
  mutation DeleteEdpCoating($partNo: String) {
    deleteEdpCoating(partNo: $partNo)
  }
`

export const EDIT_QUANTITY_PLATING = gql`
  mutation EditDailyPlatingQuantity(
    $partNo: String
    $actionDate: String
    $quantity: Float
  ) {
    editDailyPlatingQuantity(
      partNo: $partNo
      actionDate: $actionDate
      quantity: $quantity
    )
  }
`
export const EDIT_TARGET_PLATING = gql`
  mutation EditTargetPlating(
    $line: String
    $targetProductivity: Float
    $targetDefect: Float
    $year: String
    $plan: Float
    $planDate: String
  ) {
    editTargetPlating(
      line: $line
      targetProductivity: $targetProductivity
      targetDefect: $targetDefect
      year: $year
      plan: $plan
      planDate: $planDate
    )
  }
`
export const ADD_PRODUCTIVITY_PLATING = gql`
  mutation AddProductivityPlan(
    $line: String
    $plan: Float
    $date: String
    $rework: Float
  ) {
    addProductivityPlan(line: $line, plan: $plan, date: $date, rework: $rework)
  }
`
export const ADD_TARGET_PRODUCTIVITY_PLATING = gql`
  mutation TargetProductivityPlatingReport(
    $actionDate: String
    $line: String
    $targetProductivity: Float
    $targetDefect: Float
  ) {
    targetProductivityPlatingReport(
      actionDate: $actionDate
      line: $line
      targetProductivity: $targetProductivity
      targetDefect: $targetDefect
    )
  }
`
