import { Box } from "@mui/material"
import LinearProgress from "@mui/material/LinearProgress"
import { ThemeProvider, useTheme } from "@mui/styles"
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridToolbar
} from "@mui/x-data-grid"
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity"

export interface ICommonTableProps<T extends Record<string, string | number>>
  extends Partial<DataGridProps> {
  columnNames: GridColDef<T>[]
  data: T[]
  loading?: boolean
  pinnedColumns?: string[]
  footerData?: T[]
  initialState?: GridInitialStateCommunity
  pageSize?: number
  disableToolbar?: boolean
  handleRowUpdate?: (updatedRow: T) => void
  fileName: string
}

const CommonDataGrid = <T extends Record<string, string | number>>(
  props: ICommonTableProps<T>
): JSX.Element => {
  const {
    columnNames,
    data,
    pageSize,
    footerData,
    initialState,
    loading,
    disableToolbar,
    handleRowUpdate,
    fileName
  } = props

  if (loading) {
    return <LinearProgress value={0} />
  }

  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <DataGrid
          rows={data}
          processRowUpdate={(updatedRow) => {
            if (handleRowUpdate) handleRowUpdate(updatedRow)
            return updatedRow
          }}
          loading={loading}
          columns={columnNames}
          slotProps={{
            toolbar: disableToolbar
              ? undefined
              : {
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    utf8WithBom: true,
                    fileName: `${fileName}`
                  }
                }
          }}
          slots={{
            toolbar: GridToolbar
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: pageSize ?? 10 }
            },
            ...initialState
          }}
          pageSizeOptions={pageSize ? [pageSize] : [5, 10, 25]}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#DCF5F7",
              textAlign: "right",
              fontSize: "16px",
              color: "#000000"
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "16px",
              color: "#000000"
            },
            ...props.sx
          }}
          hideFooterSelectedRowCount
          experimentalFeatures={props.experimentalFeatures}
          columnGroupingModel={props.columnGroupingModel}
        />

        {footerData && (
          <DataGrid
            rows={footerData ? footerData : []}
            columns={columnNames}
            slots={{
              columnHeaders: () => null
            }}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#9db2bf",
                textAlign: "right",
                fontSize: "16px"
              },
              "& .MuiDataGrid-cellContent": {
                fontSize: "16px",
                color: "#000000"
              }
            }}
            hideFooter
          />
        )}
      </Box>
    </ThemeProvider>
  )
}

export default CommonDataGrid
