import { useQuery } from "@apollo/client"
import {
  Autocomplete,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme
} from "@mui/material"
import QRCode from "qrcode.react"
import { useState } from "react"
import { GET_LINE } from "../../../common/Query/GeneralQuery"
import { GET_MACHINES_MT } from "../../../common/Query/MaintenanceQuery"
import { PageLabelCard } from "../../../common/Resources/PageLabelCard"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"

export const CreateQRCode: React.FC = () => {
  const { loading, data } = useQuery(GET_MACHINES_MT)
  const getLine = useQuery(GET_LINE)
  const [code, setCode] = useState<string>("")
  const [line, setLine] = useState<string>("")
  const [qrCodeList, setQrCodeList] = useState<string[]>([])

  let lineList = []
  if (getLine.data) {
    lineList = getLine.data.getLine.map(
      (element: { lineName: string }) => element.lineName
    )
  }

  let machineList: { code: string; line: string }[] = []
  let machineCodeList: string[] = []
  if (data) {
    machineList = data.machinesMt || []
    machineCodeList = data.machinesMt.map(({ code }: { code: string }) => code)
  }
  machineCodeList = Array.from(new Set(machineCodeList))
  let rawQrCodeList: string[] = []

  const handleSubmit = async () => {
    rawQrCodeList = machineList.map(({ code }: { code: string }) => code)

    if (code) {
      rawQrCodeList = machineList
        .filter((val) => val.code === code)
        .map(({ code }: { code: string }) => code)
    }
    if (line) {
      rawQrCodeList = machineList
        .filter((val) => val.line === line)
        .map(({ code }: { code: string }) => code)
    }

    setQrCodeList(rawQrCodeList)
  }
  const theme = useTheme()

  const url = window.location.href

  return (
    <>
      <PageLabelCard title="Create Qr Code" />
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Autocomplete
            value={line}
            fullWidth
            onChange={(event, selectedPartName) => {
              setLine(selectedPartName as string)
            }}
            options={lineList}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                label="Select Line"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item sm={4}>
          <Autocomplete
            value={code}
            fullWidth
            onChange={(event, selectedPartName) => {
              setCode(selectedPartName as string)
            }}
            options={machineCodeList}
            loading={loading}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                label="Machine"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item sm={4}>
          <ThemedButton
            type="submit"
            variant="contained"
            style={{
              marginTop: "10px",
              backgroundColor: theme.palette.primary.main
            }}
            onClick={handleSubmit}
          >
            Submit
          </ThemedButton>
          <ThemedButton
            variant="contained"
            style={{
              marginTop: "10px",
              marginLeft: "10px",
              backgroundColor: "yellowgreen"
            }}
            onClick={() => window.print()}
          >
            Print
          </ThemedButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {qrCodeList.map((val) => {
          return (
            <>
              <Grid item sm={2} key={val}>
                <Table style={{ marginTop: "10px" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "1px solid black",
                          textAlign: "center"
                        }}
                      >
                        <QRCode
                          value={`${
                            url.split("#")[0]
                          }?code=${val}#/PMPlanRecord`}
                          size={200}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          border: "1px solid black",
                          textAlign: "center"
                        }}
                      >
                        {val}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </>
          )
        })}
      </Grid>
    </>
  )
}
