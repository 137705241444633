import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ThemeProvider,
  useTheme
} from "@mui/material"
import {
  GET_BOM,
  GET_MATERIALS,
  GET_PARTS
} from "../../common/Query/MasterQuery"
import Paper from "@mui/material/Paper"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { ReactElement, useEffect, useMemo, useState } from "react"
import swal from "sweetalert"
import RemoveIcon from "@mui/icons-material/Remove"

import { LoadingButton } from "@mui/lab"
import AddIcon from "@mui/icons-material/Add"
import { ADD_BOM } from "../../common/Mutation/MasterMutation"
import { bankImage, getImageJPG, getImagePng } from "../../common/utilities"
import { IMaterial } from "./Material"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"

export interface IPartToChildren {
  partNo: string
  children: string
  type: string
  ratio: number
  factory: string
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "bold",
    border: "solid gray 1px",
    borderWidth: "1px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary.contrastText,
    padding: "2px",
    whiteSpace: "nowrap"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "bold",
    border: "solid gray 1px",
    borderWidth: "1px",
    padding: "0px",
    whiteSpace: "nowrap"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {},

  "&:last-child td, &:last-child th": {
    border: "solid gray 1px",
    borderWidth: "1px"
  }
}))

export const Bom: React.FC = () => {
  const [openAddBom, setOpenAddBom] = useState<boolean>(false)
  const [openEditBom, setOpenEditBom] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  const { data: dataMaterial } = useQuery(GET_MATERIALS)
  const bom = useQuery(GET_BOM)
  const bomList = bom?.data?.queryBom || []

  const findMainPart = (bom: IPartToChildren): string => {
    const mainPart = bomList.find(
      (val: IPartToChildren) =>
        val.children === bom.partNo && val.type === "PART"
    )

    if (!mainPart) return bom.partNo
    else return findMainPart(mainPart)
  }
  const mainPart: string[] = []

  if (bomList.length > 0) {
    const bomListTemp = bomList
    for (const bom of bomListTemp) {
      const tempPart = findMainPart(bom)
      if (mainPart.indexOf(tempPart) === -1) {
        mainPart.push(tempPart)
      }
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getMaterial = (matNo: string) => {
    return dataMaterial?.materials?.find(
      (material: { matNo: string }) => material.matNo === matNo
    )?.partType
  }

  const TableChildren = (props: { part: IPartToChildren[]; no: number }) => {
    const { part, no } = props

    return (
      <>
        {part
          .sort((a: { type: string }, b: { type: string }) =>
            a.type < b.type ? 1 : a.type > b.type ? -1 : 0
          )
          .map((children, index: number) => {
            const findChildren = bomList.filter(
              ({ partNo, type }: { partNo: string; type: string }) =>
                partNo === children.children && type !== children.type
            )
            return (
              <>
                {findChildren.length > 0 ? (
                  <>
                    {findChildren
                      .sort((a: { type: string }, b: { type: string }) =>
                        a.type > b.type ? 1 : a.type < b.type ? -1 : 0
                      )
                      .map((children2: IPartToChildren, index2: number) => {
                        return (
                          <>
                            <StyledTableRow
                              key={children2.children + children2.partNo}
                            >
                              <StyledTableCell>
                                {index > 0 ? "" : no + page * rowsPerPage}
                              </StyledTableCell>
                              <StyledTableCell>
                                {index > 0 ? "" : children.partNo}
                              </StyledTableCell>
                              <TableImage
                                part={children.partNo}
                                index={index}
                                type={children.type}
                              />
                              <StyledTableCell>
                                {index2 > 0 ? "" : children.children}
                              </StyledTableCell>
                              <TableImage
                                part={children.children}
                                index={index2}
                                type={children.type}
                              />
                              <StyledTableCell>
                                {children.ratio}
                              </StyledTableCell>
                              <StyledTableCell>
                                {children.type === "PART"
                                  ? "SEMI-PART"
                                  : children.type}
                              </StyledTableCell>

                              <StyledTableCell>
                                {children2.children}
                              </StyledTableCell>
                              <TableImage
                                part={children2.children}
                                index={0}
                                type={children2.type}
                              />
                              <StyledTableCell>
                                {children2.ratio}
                              </StyledTableCell>
                              <StyledTableCell>
                                {children2.type === "PART"
                                  ? "SEMI-PART"
                                  : children2.type}
                              </StyledTableCell>
                            </StyledTableRow>
                          </>
                        )
                      })}
                  </>
                ) : (
                  <>
                    <StyledTableRow key={children.partNo + children.children}>
                      <StyledTableCell>
                        {index > 0 ? "" : no + page * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell>
                        {index > 0 ? "" : children.partNo}
                      </StyledTableCell>
                      <TableImage
                        part={children.partNo}
                        index={index}
                        type={"PART"}
                      />
                      <StyledTableCell>{children.children}</StyledTableCell>
                      <TableImage
                        part={children.children}
                        index={0}
                        type={"MATERIAL"}
                      />
                      <StyledTableCell>{children.ratio}</StyledTableCell>
                      <StyledTableCell>
                        {children.type === "PART" ? "SEMI-PART" : children.type}
                      </StyledTableCell>
                      <StyledTableCell />
                      <StyledTableCell />
                      <StyledTableCell />
                      <StyledTableCell />
                    </StyledTableRow>
                  </>
                )}
              </>
            )
          })}
      </>
    )
  }

  const TableImage = (props: { part: string; index: number; type: string }) => {
    const { part, index } = props
    return (
      <>
        <StyledTableCell>
          {index > 0 ? (
            ""
          ) : (
            <img
              src={
                props.type === "PART"
                  ? getImagePng(`images/parts/${part}`)
                  : getImageJPG(`images/material/${getMaterial(part)}`)
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = bankImage
              }}
              alt=""
              height="50px"
              width="50px"
            />
          )}
        </StyledTableCell>
      </>
    )
  }

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <AddNewBom setOpenAddBom={setOpenAddBom} openAddBom={openAddBom} />
      <EditBom setOpenEditBom={setOpenEditBom} openEditBom={openEditBom} />
      <PageLabel
        menuItem={menuItemList.Master}
        menuItemName={Object.keys(menuItemList)[0]}
        menuPageName={"Bom"}
      />
      <ThemedCard>
        <PageLabelCard title="BOM" subTitle="About detail Bom" />
        <Grid container spacing={2} margin={2}>
          <Grid item xs={4} sm={4} xl={4} marginLeft={-1}>
            <Autocomplete
              id="search"
              freeSolo
              value={search}
              options={mainPart}
              style={{ width: "100%", fontFamily: "Sarabun" }}
              sx={{ width: 100 }}
              onChange={(e, newValue) => {
                setSearch(newValue ? newValue : "")
                setPage(1)
              }}
              renderInput={(params) => (
                <ThemedTextField
                  style={{ marginLeft: "15px", fontFamily: "Sarabun" }}
                  {...params}
                  label="Search"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={7} container justifyContent={"flex-end"}>
            <ThemedButton
              style={{
                whiteSpace: "nowrap",
                width: "180px",
                height: "40px",
                top: "8px",
                marginRight: "20px"
              }}
              variant="contained"
              onClick={() => setOpenAddBom(true)}
            >
              ADD NEW BOM
            </ThemedButton>
            <ThemedButton
              contrast
              style={{
                whiteSpace: "nowrap",
                width: "180px",
                height: "40px",
                top: "8px",
                marginRight: "20px"
              }}
              variant="contained"
              onClick={() => setOpenEditBom(true)}
            >
              Edit BOM
            </ThemedButton>
          </Grid>
          <Grid item xs={12} sm={12} marginRight={3} padding={3} mt={2}>
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>No</StyledTableCell>
                    <StyledTableCell>PartNo</StyledTableCell>
                    <StyledTableCell>Image Part</StyledTableCell>
                    <StyledTableCell>Children Name 1</StyledTableCell>
                    <StyledTableCell>Image Children 1</StyledTableCell>
                    <StyledTableCell>Ratio 1</StyledTableCell>
                    <StyledTableCell>Type 1</StyledTableCell>
                    <StyledTableCell>Children Name 2</StyledTableCell>
                    <StyledTableCell>Image Children 2</StyledTableCell>
                    <StyledTableCell>Ratio 2</StyledTableCell>
                    <StyledTableCell>Type 2</StyledTableCell>
                  </StyledTableRow>
                </TableHead>

                <TableBody>
                  {mainPart
                    .filter((val: string) => {
                      if (search) return val === search
                      else return val
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((val: string, index: number) => {
                      const part = bomList.filter(
                        ({ partNo }: { partNo: string }) => partNo === val
                      )
                      return (
                        <>
                          <TableChildren part={part} key={val} no={index + 1} />
                        </>
                      )
                    })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={
                  mainPart?.filter((val: string) => {
                    if (search) return val === search
                    else return val
                  }).length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ backgroundColor: theme.palette.primary.dark }}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default Bom

interface IPropsAddBom {
  setOpenAddBom: (boolean: boolean) => void
  openAddBom: boolean
}

interface IPropsEditBom {
  setOpenEditBom: (boolean: boolean) => void
  openEditBom: boolean
}

interface IChildrenPart {
  partNo: string
  children: string
  type: string
  ratio: number
  factory: string
  deleteStatus?: boolean
}

export const AddNewBom: React.FC<IPropsAddBom> = (
  props: IPropsAddBom
): ReactElement => {
  const { setOpenAddBom, openAddBom } = props

  const materialQuery = useQuery(GET_MATERIALS)
  const materialList =
    materialQuery?.data?.materials
      ?.filter((material: IMaterial) => material.partType !== "Component")
      ?.map((val: { matNo: string }) => val.matNo) || []
  const componentList =
    materialQuery?.data?.materials
      ?.filter((material: IMaterial) => material.partType === "Component")
      ?.map((val: { matNo: string }) => val.matNo) || []
  const partNoQuery = useQuery(GET_PARTS)

  const partNoList =
    partNoQuery?.data?.parts.map(({ partNo }: { partNo: string }) => partNo) ||
    []

  const [addBom, { loading }] = useMutation(ADD_BOM, {
    refetchQueries: [GET_BOM],
    awaitRefetchQueries: true
  })
  const theme = useTheme()
  const [partNo, setPartNo] = useState<string>("")
  const [childrenPart, setChildrenPart] = useState<string>("")
  const [childrenParts, setChildrenParts] = useState<IChildrenPart[]>([])
  const [typeChildren, setTypeChildren] = useState<string>("")
  const [listChildren, setListChildren] = useState<string[]>([])

  const [ratio, setRatio] = useState<number>(0)
  const [factory, setFactory] = useState("")

  const addChildrenPart = () => {
    if (!childrenPart) {
      swal("Warning", "กรุณาเลือก Children Part", "warning")
    } else {
      let children = childrenParts
      children = children.concat([
        {
          children: childrenPart,
          partNo: partNo,
          type: typeChildren,
          ratio: ratio,
          factory: factory
        }
      ])
      setChildrenParts(children)
      setChildrenPart("")
    }
  }

  const removeChildrenPart = (
    typeSelect: string,
    childrenPartSelect: string
  ) => {
    let children = childrenParts
    children = children.filter(
      (e) => e.children !== childrenPartSelect || e.type !== typeSelect
    )
    setChildrenParts(children)
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    try {
      await addBom({
        variables: {
          partNo,
          childrenPart: childrenParts
        }
      })
      setChildrenParts([])
      swal("Success", `Add Bom ssuccess`, "success")
      setOpenAddBom(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  useEffect(() => {
    setListChildren(
      typeChildren === "PART"
        ? partNoList.filter((val: string) => val !== partNo)
        : typeChildren === "MATERIAL"
        ? materialList
        : componentList
    )
  }, [typeChildren])

  return (
    <>
      <Dialog
        open={openAddBom}
        onClose={() => setOpenAddBom(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            minHeight: window.innerHeight * 0.8
          }
        }}
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Add New Bom
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                defaultValue={""}
                disableClearable
                disablePortal
                onChange={(event, selectedCustomer) => {
                  setPartNo(selectedCustomer as string)
                }}
                options={partNoList}
                renderInput={(params) => (
                  <TextField {...params} label="Part No" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} />

            {childrenParts.map((e) => (
              <>
                <Grid item xs={12} sm={12} key={e.partNo + e.children}>
                  type: {e.type} Children: {e.children} Ratio: {e.ratio}
                  <IconButton
                    aria-label="Remove"
                    onClick={() => removeChildrenPart(e.type, e.children)}
                  >
                    <RemoveIcon fontSize="large" color="primary" />
                  </IconButton>
                </Grid>
              </>
            ))}

            <Grid item xs={12} sm={2}>
              <Autocomplete
                fullWidth
                defaultValue={"PART"}
                disableClearable
                disablePortal
                value={typeChildren}
                onChange={(event, selectedMaterial) => {
                  setTypeChildren(selectedMaterial as string)
                }}
                // loading={!!materialList}
                options={["MATERIAL", "PART", "COMPONENT"]}
                renderInput={(params) => (
                  <TextField {...params} label="Type Children" />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Autocomplete
                fullWidth
                defaultValue={""}
                disableClearable
                disablePortal
                value={childrenPart}
                onChange={(event, selectedMaterial) => {
                  setChildrenPart(selectedMaterial as string)
                }}
                options={listChildren}
                renderInput={(params) => (
                  <TextField {...params} label="Children Part" />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="Ratio"
                fullWidth
                type="number"
                inputProps={{
                  minLength: 0,
                  step: "1"
                }}
                onChange={(e) => setRatio(+e.target.value)}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item sm={2}>
              <Autocomplete
                fullWidth
                disablePortal
                renderInput={(params) => (
                  <TextField {...params} label="Select Factory" />
                )}
                onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
                options={["1", "2"]}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <IconButton aria-label="add" onClick={addChildrenPart}>
                <AddIcon fontSize="large" color="primary" />
              </IconButton>
            </Grid>
            <Grid item sm={12} />
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <LoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              backgroundColor: theme.palette.primary.main,
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </LoadingButton>
          <Button variant="text" onClick={() => setOpenAddBom(false)}>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const EditBom: React.FC<IPropsEditBom> = (
  props: IPropsEditBom
): ReactElement => {
  const { setOpenEditBom, openEditBom } = props

  const materialQuery = useQuery(GET_MATERIALS)
  const materialList =
    materialQuery?.data?.materials
      ?.filter((material: IMaterial) => material.partType !== "Component")
      ?.map((val: { matNo: string }) => val.matNo) || []
  const componentList =
    materialQuery?.data?.materials
      ?.filter((material: IMaterial) => material.partType === "Component")
      ?.map((val: { matNo: string }) => val.matNo) || []
  const partNoQuery = useQuery(GET_PARTS)
  const partNoList =
    partNoQuery?.data?.parts.map(({ partNo }: { partNo: string }) => partNo) ||
    []

  const [addBom, { loading }] = useMutation(ADD_BOM, {
    refetchQueries: [GET_BOM],
    awaitRefetchQueries: true
  })
  const theme = useTheme()
  const [partNo, setPartNo] = useState<string>("")

  const [childrenPart, setChildrenPart] = useState<string>("")
  const [childrenParts, setChildrenParts] = useState<IChildrenPart[]>([])
  const [typeChildren, setTypeChildren] = useState<string>("")
  const [listChildren, setListChildren] = useState<string[]>([])
  const [ratio, setRatio] = useState<number>(0)

  const [factory, setFactory] = useState("")

  const [getBom] = useLazyQuery(GET_BOM)

  useMemo(async () => {
    if (partNo) {
      const bomList = await getBom({
        variables: {
          partNo
        }
      })
      setChildrenParts(
        bomList?.data?.queryBom?.map((val: IPartToChildren) => {
          return {
            children: val.children,
            partNo: val.partNo,
            type: val.type,
            ratio: val.ratio,
            factory: val.factory
          }
        })
      )
    }
  }, [partNo])

  const addChildrenPart = () => {
    if (!childrenPart) {
      swal("Warning", "กรุณาเลือก Children Part", "warning")
    } else {
      let children = childrenParts
      children = children.concat([
        {
          children: childrenPart,
          partNo: partNo,
          type: typeChildren,
          ratio: ratio,
          factory: factory
        }
      ])
      setChildrenParts(children)
      setChildrenPart("")
    }
  }

  const removeChildrenPart = (
    typeSelect: string,
    childrenPartSelect: string
  ) => {
    let children = childrenParts
    children = children.map((val) => {
      if (val.children === childrenPartSelect && val.type === typeSelect) {
        return {
          ...val,
          deleteStatus: true
        }
      } else {
        return val
      }
    })
    setChildrenParts(children)
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    try {
      await addBom({
        variables: {
          partNo,
          childrenPart: childrenParts
        }
      })

      swal("Success", `Add Bom ssuccess`, "success")
      setPartNo("")
      setChildrenParts([])
      setOpenEditBom(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  useEffect(() => {
    setListChildren(
      typeChildren === "PART"
        ? partNoList.filter((val: string) => val !== partNo)
        : typeChildren === "MATERIAL"
        ? materialList
        : componentList
    )
  }, [typeChildren])

  return (
    <>
      <Dialog
        open={openEditBom}
        onClose={() => setOpenEditBom(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            minHeight: window.innerHeight * 0.8
          }
        }}
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Edit New Bom
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                defaultValue={""}
                disableClearable
                disablePortal
                onChange={(event, selectedCustomer) => {
                  setPartNo(selectedCustomer as string)
                }}
                options={partNoList}
                renderInput={(params) => (
                  <TextField {...params} label="Part No" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} />

            {childrenParts
              .filter((val) => !val.deleteStatus)
              ?.map((e) => (
                <>
                  <Grid item xs={12} sm={12} key={e.partNo + e.children}>
                    type: {e.type} Children: {e.children} Ratio: {e.ratio}
                    <IconButton
                      aria-label="Remove"
                      onClick={() => removeChildrenPart(e.type, e.children)}
                    >
                      <RemoveIcon fontSize="large" color="primary" />
                    </IconButton>
                  </Grid>
                </>
              ))}

            <Grid item xs={12} sm={2}>
              <Autocomplete
                fullWidth
                defaultValue={"PART"}
                disableClearable
                disablePortal
                value={typeChildren}
                onChange={(event, selectedMaterial) => {
                  setTypeChildren(selectedMaterial as string)
                }}
                // loading={!!materialList}
                options={["MATERIAL", "PART", "COMPONENT"]}
                renderInput={(params) => (
                  <TextField {...params} label="Type Children" />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Autocomplete
                fullWidth
                defaultValue={""}
                disableClearable
                disablePortal
                value={childrenPart}
                onChange={(event, selectedMaterial) => {
                  setChildrenPart(selectedMaterial as string)
                }}
                options={listChildren}
                renderInput={(params) => (
                  <TextField {...params} label="Children Part" />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="Ratio"
                fullWidth
                type="number"
                inputProps={{
                  minLength: 0,
                  step: "1"
                }}
                onChange={(e) => setRatio(+e.target.value)}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item sm={2}>
              <Autocomplete
                fullWidth
                disablePortal
                renderInput={(params) => (
                  <TextField {...params} label="Select Factory" />
                )}
                onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
                options={["1", "2"]}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <IconButton aria-label="add" onClick={addChildrenPart}>
                <AddIcon fontSize="large" color="primary" />
              </IconButton>
            </Grid>
            <Grid item sm={12} />
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <LoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              backgroundColor: theme.palette.primary.main,
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </LoadingButton>
          <Button variant="text" onClick={() => setOpenEditBom(false)}>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
