import { Table, TableBody, TableHead } from "@mui/material"
import "../../css/Masterplan/MasterplanReport.css"
import { IMasterPlanPartData } from "./MPReport"
import {
  getAllPlant,
  paddingPartDeliveryDates,
  StyledTableCell,
  StyledTableRow,
  validateRowSpanToPrint
} from "./utilities"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { columns, dataRows } from "./mpcomponents/TableContainerMP"

interface IProp {
  maxDueDate: Array<string>
  masterPlan: Array<IMasterPlanPartData>
  factory: string
  plating: boolean
  followUp: boolean
  showOrder: boolean
  customer: string | null
  showDeficitFollowup?: boolean
  showFollowUp: boolean
}

const MPReportToPrint = (props: IProp): JSX.Element => {
  const newMasterPlan: IMasterPlanPartData[][] = []
  let dataTemp: IMasterPlanPartData[] = []
  let lengthTable = 6
  if (props.showOrder || props.showDeficitFollowup || props.showFollowUp)
    lengthTable = 18
  props.masterPlan
    .filter((data: IMasterPlanPartData) => data.order.length > 0)
    .map((masterPlan: IMasterPlanPartData, index: number) => {
      return { index: index + 1, ...masterPlan }
    })
    .forEach((element: IMasterPlanPartData, index: number) => {
      if (dataTemp.length < lengthTable) {
        dataTemp.push(element)
      } else {
        dataTemp.push(element)
        newMasterPlan.push(dataTemp)
        dataTemp = []
      }
      if (index === props.masterPlan.length - 1) newMasterPlan.push(dataTemp)
    })

  return (
    <ThemedCard>
      {props.masterPlan ? (
        <>
          <Table className="mp-report-to-print">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell colSpan={props.maxDueDate.length + 14}>
                  <div> Master Plan</div>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                {columns
                  .filter(
                    (val) => val.id !== "fgStock" && val.id !== "beginStock"
                  )
                  .map((column) => (
                    <StyledTableCell key={column.id}>
                      {column.label}
                    </StyledTableCell>
                  ))}
                {props.maxDueDate.map((e: string, i: number) => {
                  return (
                    <StyledTableCell
                      style={{
                        borderWidth: "1px",
                        borderStyle: "solid",

                        padding: "5px"
                      }}
                      colSpan={1}
                      rowSpan={1}
                      key={i}
                    >
                      {e.split("-")[2]} - {e.split("-")[1]}
                    </StyledTableCell>
                  )
                })}
              </StyledTableRow>
            </TableHead>
            <TableBody style={{ marginTop: "10px" }}>
              {props.masterPlan
                .map((masterPlan: IMasterPlanPartData, index: number) => {
                  return { index: index + 1, ...masterPlan }
                })
                .map((partData: IMasterPlanPartData, i: number) => {
                  const order = paddingPartDeliveryDates(
                    partData.order.map((oldOrder) => {
                      return {
                        ...oldOrder
                      }
                    }),
                    props.maxDueDate,
                    partData.stockBegin
                  )
                  return (
                    <>
                      <StyledTableRow
                        className={i % 2 === 0 ? "display-border" : undefined}
                      >
                        {Array.from(Array(dataRows.length), (num, i) => {
                          return (
                            <StyledTableCell
                              key={i}
                              rowSpan={validateRowSpanToPrint(
                                props.factory,
                                props.plating,
                                props.followUp,
                                partData.customer,
                                props.showOrder
                              )}
                            >
                              {dataRows[i] === "plant"
                                ? getAllPlant(partData.plant)
                                : partData[
                                    dataRows[i] as keyof IMasterPlanPartData
                                  ]}
                            </StyledTableCell>
                          )
                        })}
                      </StyledTableRow>
                      {props.showOrder ? (
                        <>
                          <StyledTableRow
                            className={
                              i % 2 === 0 ? "display-border" : undefined
                            }
                          >
                            <StyledTableCell
                              style={{
                                borderWidth: "1px",
                                borderStyle: "solid",
                                whiteSpace: "nowrap"
                              }}
                              colSpan={1}
                              rowSpan={1}
                            >
                              <>Order</>
                            </StyledTableCell>
                            {Array.from(
                              Array(props.maxDueDate.length),
                              (num, i) => {
                                if (order[i]) {
                                  return (
                                    <StyledTableCell
                                      style={{
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        fontSize: "16px"
                                      }}
                                      colSpan={1}
                                      rowSpan={1}
                                      key={i}
                                    >
                                      {order[i].quantity < 0 ? (
                                        <span style={{ color: "red" }}>
                                          {order[i].quantity}
                                        </span>
                                      ) : (
                                        <> {order[i].quantity}</>
                                      )}
                                    </StyledTableCell>
                                  )
                                } else {
                                  return (
                                    <StyledTableCell
                                      style={{
                                        borderWidth: "1px",
                                        borderStyle: "solid"
                                      }}
                                      colSpan={1}
                                      rowSpan={1}
                                      key={i}
                                    >
                                      <>{0}</>
                                    </StyledTableCell>
                                  )
                                }
                              }
                            )}
                          </StyledTableRow>
                        </>
                      ) : (
                        <>
                          {props.factory === "1" ||
                          props.factory === "" ||
                          (props.factory === "2" &&
                            props.plating === true &&
                            !props.followUp) ? (
                            <StyledTableRow
                              className={
                                i % 2 === 0 ? "display-border" : undefined
                              }
                            >
                              <StyledTableCell
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  whiteSpace: "nowrap"
                                }}
                                colSpan={1}
                                rowSpan={1}
                              >
                                {props.customer === "MAHLE" ||
                                partData.customer === "MAHLE" ? (
                                  <>Stock bal. (actual) OS</>
                                ) : (
                                  <>Stock bal. (actual) F1</>
                                )}
                              </StyledTableCell>
                              {Array.from(
                                Array(props.maxDueDate.length),
                                (num, i) => {
                                  return (
                                    <StyledTableCell
                                      style={{
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        fontWeight: "bold",
                                        fontSize: "16px"
                                      }}
                                      colSpan={1}
                                      rowSpan={1}
                                      key={i}
                                    >
                                      {order[i].balActual < 0 ? (
                                        <span style={{ color: "red" }}>
                                          {order[i].balActual}
                                        </span>
                                      ) : (
                                        <> {order[i].balActual}</>
                                      )}
                                    </StyledTableCell>
                                  )
                                }
                              )}
                            </StyledTableRow>
                          ) : (
                            <></>
                          )}
                          {(props.factory === "2" &&
                            (props.plating === false ||
                              props.plating === true) &&
                            !props.followUp) ||
                          props.factory === "" ? (
                            <StyledTableRow
                              className={
                                i % 2 === 0 ? "display-border" : undefined
                              }
                            >
                              <StyledTableCell
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  whiteSpace: "nowrap"
                                }}
                                colSpan={1}
                                rowSpan={1}
                              >
                                {props.customer === "MAHLE" ||
                                partData.customer === "MAHLE" ? (
                                  <>Stock bal. (actual) F1</>
                                ) : (
                                  <>Stock bal. (actual) F2</>
                                )}
                              </StyledTableCell>
                              {Array.from(
                                Array(props.maxDueDate.length),
                                (num, i) => {
                                  return (
                                    <StyledTableCell
                                      style={{
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        fontWeight: "bold",
                                        fontSize: "16px"
                                      }}
                                      colSpan={1}
                                      rowSpan={1}
                                      key={i}
                                    >
                                      {props.customer === "MAHLE" ||
                                      partData.customer === "MAHLE" ? (
                                        <>
                                          {order[i].balActualProductionOSF1 <
                                          0 ? (
                                            <span style={{ color: "red" }}>
                                              {order[i].balActualProductionOSF1}
                                            </span>
                                          ) : (
                                            <>
                                              {" "}
                                              {order[i].balActualProductionOSF1}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {order[i].balActual2 < 0 ? (
                                            <span style={{ color: "red" }}>
                                              {order[i].balActual2}
                                            </span>
                                          ) : (
                                            <> {order[i].balActual2}</>
                                          )}
                                        </>
                                      )}
                                    </StyledTableCell>
                                  )
                                }
                              )}
                            </StyledTableRow>
                          ) : (
                            <></>
                          )}
                          {props.customer === "MAHLE" ||
                          partData.customer === "MAHLE" ? (
                            <>
                              <StyledTableRow
                                className={
                                  i % 2 === 0 ? "display-border" : undefined
                                }
                              >
                                <StyledTableCell
                                  style={{
                                    borderWidth: "1px",
                                    borderStyle: "solid"
                                  }}
                                  colSpan={1}
                                  rowSpan={1}
                                >
                                  <>BalActual OOs</>
                                </StyledTableCell>
                                {Array.from(
                                  Array(props.maxDueDate.length),
                                  (num, i) => {
                                    if (order[i]) {
                                      return (
                                        <StyledTableCell
                                          style={{
                                            borderWidth: "1px",
                                            borderStyle: "solid"
                                          }}
                                          colSpan={1}
                                          rowSpan={1}
                                          key={i}
                                        >
                                          <>
                                            {order[i].balActualOos < 0 ? (
                                              <span style={{ color: "red" }}>
                                                {order[i].balActualOos}
                                              </span>
                                            ) : (
                                              <> {order[i].balActualOos}</>
                                            )}
                                          </>
                                        </StyledTableCell>
                                      )
                                    } else {
                                      return (
                                        <StyledTableCell
                                          style={{
                                            borderWidth: "1px",
                                            borderStyle: "solid"
                                          }}
                                          colSpan={1}
                                          rowSpan={1}
                                          key={i}
                                        >
                                          <>{0}</>
                                        </StyledTableCell>
                                      )
                                    }
                                  }
                                )}
                              </StyledTableRow>
                            </>
                          ) : (
                            <>
                              {(props.factory === "2" &&
                                props.plating === true &&
                                (props.followUp || !props.followUp)) ||
                              props.factory === "" ? (
                                <StyledTableRow
                                  className={
                                    i % 2 === 0 ? "display-border" : undefined
                                  }
                                >
                                  <StyledTableCell
                                    style={{
                                      borderWidth: "1px",
                                      borderStyle: "solid",
                                      whiteSpace: "nowrap"
                                    }}
                                    colSpan={1}
                                    rowSpan={1}
                                  >
                                    <>Stock bal. (follow up) F2</>
                                  </StyledTableCell>
                                  {Array.from(
                                    Array(props.maxDueDate.length),
                                    (num, i) => {
                                      return (
                                        <StyledTableCell
                                          style={{
                                            borderWidth: "1px",
                                            borderStyle: "solid",
                                            fontWeight: "bold",
                                            fontSize: "16px"
                                          }}
                                          colSpan={1}
                                          rowSpan={1}
                                          key={i}
                                        >
                                          {order[i].balActualFollowUp < 0 ? (
                                            <span style={{ color: "red" }}>
                                              {order[i].balActualFollowUp}
                                            </span>
                                          ) : (
                                            <> {order[i].balActualFollowUp}</>
                                          )}
                                        </StyledTableCell>
                                      )
                                    }
                                  )}
                                </StyledTableRow>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )
                })}
            </TableBody>
          </Table>
        </>
      ) : (
        <></>
      )}
    </ThemedCard>
  )
}

export default MPReportToPrint
