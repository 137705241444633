import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Grid,
  Switch,
  useTheme
} from "@mui/material"
import QrReader from "react-qr-reader"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import moment from "moment"
import swal from "sweetalert"
import { SetStateAction, useMemo, useState } from "react"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import { GET_WIP_CARD } from "../../../common/Query/MaterialQuery"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { GET_LOGISTICS_REPORT_CAR_LICENSE } from "../../../common/Query/LogisticsQuery"
import { CREATE_SUPPLIER_CONTROL } from "../../../common/Mutation/SupplierControl"
import { GET_EMPLOYEE } from "../../../common/Query/MasterQuery"
import { IEmployee } from "../../Master/Employee"

const periodList: string[] = [
  "เช้า: 09.00 น.",
  "เช้า: 11.00 น.",
  "บ่าย: 14.00 น.",
  "บ่าย: 16.00 น.",
  "เย็น: 18.00 น.",
  "เย็น: 19.00 น."
]

const SupplierOut = (): JSX.Element => {
  const theme = useTheme()
  const [isStream, setIsStream] = useState(false)
  const [dataScan, setDataScan] = useState("")
  const [prodDate, setProdDate] = useState("")
  const [pdLotNo, setPdLotNo] = useState("")
  const [jobOrder, setJobOrder] = useState("")
  const [partNo, setPartNo] = useState("")
  const [boxNo, setBoxNo] = useState("")
  const [endBox, setEndBox] = useState<number>(0)
  const [array, setBoxCount] = useState<undefined[]>([])
  const [getWipCard] = useLazyQuery(GET_WIP_CARD)
  const [deliveryDate, setDeliveryDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [personnel, setPersonnel] = useState<string>("")
  const [remarks, setRemarks] = useState<string>("")
  const [quantity, setQuantity] = useState<number>(0)
  const [period, setPeriod] = useState<string>("")
  const [branchFrom, setBranchFrom] = useState<string>("")
  const [branchTo, setBranchTo] = useState<string>("")
  const [carLicense, setCarLicense] = useState<string>("")
  const [createSupplierControl, { loading }] = useMutation(
    CREATE_SUPPLIER_CONTROL
  )
  const { data: carLicenseData, loading: carLicenseLoading } = useQuery(
    GET_LOGISTICS_REPORT_CAR_LICENSE
  )

  const { data: employeeData } = useQuery(GET_EMPLOYEE)

  const personnelList: string[] = useMemo(() => {
    if (employeeData?.employee) {
      return Array.from(
        new Set(
          employeeData.employee
            .filter((e: IEmployee) => e.departmentId === 3)
            .map((emp: IEmployee) => emp.firstName)
        )
      )
    }
    return []
  }, [employeeData])

  const breakQRCode = async (e?: { preventDefault: () => void }) => {
    e?.preventDefault()

    const newSplit = dataScan.trim().split("|")
    if (newSplit.length !== 6) {
      swal("Error", "มีปัญหากับ format ของ QR Code!", "warning")
    }
    setProdDate(moment(newSplit[5], "DD-MM-YY").format("YYYY-MM-DD"))
    setPdLotNo(`${newSplit[5]}|${newSplit[4]}`)
    setJobOrder(`${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`)
    setPartNo(newSplit[4])
    setBoxNo(newSplit[3])
    alert(dataScan)
    if (newSplit[1] && `${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`) {
      const wipcard = await getWipCard({
        variables: {
          partNo: newSplit[1],
          jobOrder: `${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`
        }
      })

      if (wipcard?.data?.wipcard[0]) {
        const box = Math.ceil(
          wipcard.data.wipcard[0].quantity / wipcard.data.wipcard[0].perBox -
            Number(newSplit[3]) +
            1
        )
        setEndBox(Number(newSplit[3]))
        setBoxCount([...new Array(box)])
      }
    }
  }

  const handleScan = (data: SetStateAction<string> | null): void => {
    if (data) {
      setDataScan(data)
      if (dataScan) {
        breakQRCode()
      }
    }
  }

  const handleError = (err: string) => {
    swal("Error", `${err}`, "warning")
  }

  const isComplete = () => {
    return (
      partNo !== "" &&
      pdLotNo !== "" &&
      boxNo !== "" &&
      deliveryDate !== "" &&
      quantity !== 0 &&
      personnel !== "" &&
      period !== "" &&
      branchFrom !== "" &&
      branchTo !== "" &&
      carLicense !== ""
    )
  }

  const isReset = () => {
    setDataScan("")
    setProdDate("")
    setPdLotNo("")
    setJobOrder("")
    setPartNo("")
    setBoxNo("")
    setEndBox(0)
    setBoxCount([])
    setRemarks("")
  }

  const onSave = async () => {
    if (!isComplete()) {
      swal("warning", `กรุณากรอกข้อมูลให้ครบถ้วน`, "warning")
      return
    }

    try {
      await createSupplierControl({
        variables: {
          input: {
            jobOrder: jobOrder,
            pdLotNo: pdLotNo,
            partNo: partNo,
            boxNo: Number(boxNo),
            endBox: endBox,
            quantity: quantity,
            deliveryDate: deliveryDate,
            operator: personnel,
            remark: remarks,
            inOrOut: -1,
            period: period,
            branchFrom: branchFrom,
            branchTo: branchTo,
            carLicense: carLicense
          }
        }
      })
      swal("Success", "บันทึกข้อมูลสำเร็จ", "success")
      isReset()
    } catch (err) {
      swal("Error", `${(err as Error).message}`, "warning")
    }
  }

  const carLicenseList =
    !carLicenseLoading && carLicenseData
      ? carLicenseData.carLicense.map(
          ({ carLicense }: { carLicense: string }) => carLicense
        )
      : [""]

  const supplierList =
    carLicenseData?.carLicense
      .map((item: { carLicense: string }) => item.carLicense)
      .filter((value: string) => value.split("-")[0] == "OS") || []

  return (
    <div>
      <h1>Supplier Out</h1>
      <Box component="form" onSubmit={breakQRCode}>
        <Accordion
          expanded={isStream}
          onChange={() => setIsStream(!isStream)}
          style={{
            marginBottom: "5px",
            marginTop: "10px"
          }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ backgroundColor: "#ffffff", display: "none" }}
          />

          <AccordionDetails>
            {isStream ? (
              <>
                <Grid container justifyContent={"center"}>
                  <Grid item sm={3} xs={12} container justifyContent={"center"}>
                    <QrReader
                      delay={300}
                      onError={handleError}
                      onScan={handleScan}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </AccordionDetails>
        </Accordion>

        <Grid container spacing={2} width={"98%"} margin={2}>
          <Grid item xs={12} sm={12}>
            <Switch
              checked={isStream}
              onChange={(event) =>
                setIsStream(event.target.value ? !isStream : isStream)
              }
              name="gilad"
            />
            <label style={{ fontSize: "14px" }}>
              Active Camera{" "}
              <CameraAltIcon style={{ verticalAlign: "middle" }} />
            </label>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ThemedTextField
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              id="scanner"
              value={dataScan}
              label="scanner"
              onChange={(e) => setDataScan(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ThemedTextField
              fullWidth
              className="disable-field"
              disabled
              label="Production Lot No."
              InputLabelProps={{
                shrink: true
              }}
              value={pdLotNo}
              id="Production Lot No."
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ThemedTextField
              fullWidth
              disabled
              InputLabelProps={{
                shrink: true
              }}
              label="Part No."
              className="disable-field"
              value={partNo}
              id="partNo"
              autoComplete="family-name"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <ThemedTextField
              fullWidth
              className="disable-field"
              disabled
              InputLabelProps={{
                shrink: true
              }}
              label="Mat'l Job Order"
              value={jobOrder}
              id="JobOrder"
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ThemedTextField
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              label="Production Date"
              className="disable-field"
              disabled
              value={prodDate}
              id="ProductionDate"
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ThemedTextField
              fullWidth
              id="boxNo"
              value={boxNo}
              disabled
              label="Box No"
              InputProps={{
                style: { WebkitTextFillColor: "black" }
              }}
              className="disable-field"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="boxNo"
              value={endBox}
              options={array.map((each, index) => index + Number(boxNo))}
              style={{ width: "100%" }}
              sx={{ width: 300 }}
              onChange={(e, newValue) => setEndBox(newValue ? newValue : 0)}
              renderInput={(params) => (
                <ThemedTextField {...params} label="Select end box " />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ThemedTextField
              fullWidth
              id="quantity"
              error={!quantity}
              value={quantity ? quantity : ""}
              type="number"
              label="Quantity"
              onWheel={(event) =>
                event.currentTarget.querySelector("input")?.blur()
              }
              autoComplete="family-name"
              onChange={(e) => setQuantity(+e.target.value)}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <ThemedTextField
              id="Date Range"
              type="date"
              value={deliveryDate}
              error={!deliveryDate}
              fullWidth
              label="Delivery Date"
              onChange={(event: {
                target: { value: React.SetStateAction<string> }
              }) => setDeliveryDate(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="period"
              value={period}
              options={periodList}
              onChange={(e, newValue) => setPeriod(newValue ? newValue : "")}
              renderInput={(params) => (
                <ThemedTextField {...params} error={!period} label="Period" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="branchFrom"
              value={branchFrom}
              options={["BAE1", "BAE2"]}
              onChange={(e, newValue) =>
                setBranchFrom(newValue ? newValue : "")
              }
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  error={!branchFrom}
                  label="Delivery From"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="branchTo"
              value={branchTo}
              options={supplierList.concat(["BAE1", "BAE2"])}
              onChange={(e, newValue) => setBranchTo(newValue ? newValue : "")}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  error={!branchTo}
                  label="Delivery To"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="carLicense"
              value={carLicense}
              options={carLicenseList}
              onChange={(e, newValue) =>
                setCarLicense(newValue ? newValue : "")
              }
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  error={!carLicense}
                  label="CarLicense"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Autocomplete
              disablePortal
              id="personnel"
              value={personnel}
              options={personnelList}
              onChange={(e, newValue) => setPersonnel(newValue ? newValue : "")}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  error={!personnel}
                  label="Personnel"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <ThemedTextField
              fullWidth
              id="remarks"
              label="Remarks"
              value={remarks}
              autoComplete="family-name"
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ThemedLoadingButton
              variant="contained"
              style={{
                backgroundColor: theme.palette.primary.main
              }}
              onClick={onSave}
              loading={loading}
            >
              SUBMIT
            </ThemedLoadingButton>
          </Grid>
        </Grid>
        <Button type="submit" />
      </Box>
    </div>
  )
}
export default SupplierOut
