import { Autocomplete, Box, CardContent, CardHeader, Grid } from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { useMutation, useQuery } from "@apollo/client"
import { GET_MASTER_PM_SPARE_PART } from "../../common/Query/engineerQuery"
import { useState } from "react"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import AutocompleteFactory from "../../common/Resources/AutocompleteFactory"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import AddIcon from "@mui/icons-material/Add"
import { useEmployee } from "../../common/Resources/customHook/useEmployee"
import { CREATE_PM_SPARE_PART } from "../../common/Mutation/engineerMutation"
import swal from "sweetalert"

const pageTitle = "PM Engineer Spare Part In"

const PMSparePartIn: React.FC = () => {
  const { data: masterPMSparePart, loading } = useQuery(
    GET_MASTER_PM_SPARE_PART
  )
  const [createPMSparePart] = useMutation(CREATE_PM_SPARE_PART)
  const [code, setCode] = useState<string>("")
  const [actionDate, setActionDate] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [quantity, setQuantity] = useState<string>("")
  const [operator, setOperator] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const employeeList = useEmployee()
  const partNameList = Array.from(
    new Set(
      masterPMSparePart?.masterPMSparePart.map(
        (data: { partName: string }) => data.partName
      )
    )
  )
  const isComplete = () => {
    if (code && actionDate && factory && quantity && operator) {
      return true
    }
    return false
  }

  const handleSubmit = async () => {
    if (!isComplete()) {
      swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "error")
      return
    }
    const inputSparePart = {
      code: code,
      actionDate: actionDate,
      factory: factory,
      quantity: Number(quantity),
      inOrOut: 1,
      employee: operator
    }
    try {
      await createPMSparePart({
        variables: {
          inputSparePart: inputSparePart
        }
      })
      swal("Success", "Add Spare Part In Success", "success")
    } catch (error) {
      swal("Error", (error as Error).message, "error")
    }
  }
  return (
    <Box>
      <PageLabel
        menuItem={menuItemList.Engineer}
        menuItemName={Object.keys(menuItemList)[0]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="PM Engineer Spare Part In"
          subTitle="About detail PM Engineer Spare Part In"
        />
        <CardHeader
          titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
          title="Spare Part "
          subheaderTypographyProps={{ fontFamily: "Sarabun" }}
          subheader="Add incoming Spare Part  to supply"
        />
        <Box sx={{ margin: "20px" }}>
          <CardContent className="hide-container">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  value={partName}
                  fullWidth
                  onChange={(event, selectedCustomer) => {
                    setPartName(selectedCustomer as string)
                    setCode("")
                  }}
                  disabled={partNameList.length ? false : true}
                  options={partNameList}
                  loading={loading}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="Part Name"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  value={code}
                  options={
                    masterPMSparePart?.masterPMSparePart
                      .filter(
                        (val: { partName: string; code: string }) =>
                          val.partName === partName && val.code
                      )
                      .map((item: { code: string }) => item.code) || []
                  }
                  loading={loading}
                  disablePortal
                  disableClearable
                  popupIcon={null}
                  onChange={(e, value) => {
                    setCode(value as string)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      variant="outlined"
                      label="Code"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <ThemedTextField
                  fullWidth
                  value={actionDate}
                  variant="outlined"
                  id="action-date"
                  type="date"
                  label="Date Received"
                  autoComplete="family-name"
                  onChange={(e) => setActionDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteFactory
                  factory={factory}
                  setFactory={setFactory}
                />
              </Grid>
              <Grid item xs={12}>
                <ThemedTextField
                  fullWidth
                  id="quantity"
                  type="number"
                  value={quantity ? quantity : ""}
                  inputProps={{ step: "0.1", lang: "en-US" }}
                  label="Quantity"
                  variant="outlined"
                  autoComplete="family-name"
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={employeeList}
                  value={operator}
                  fullWidth
                  onChange={(e, newValue) => setOperator(newValue as string)}
                  renderInput={(params) => (
                    <ThemedTextField
                      label="ชื่อผู้บันทึก"
                      {...params}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <ThemedLoadingButton
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
              endIcon={<AddIcon />}
            >
              Submit
            </ThemedLoadingButton>
          </CardContent>
        </Box>
      </ThemedCard>
    </Box>
  )
}
export default PMSparePartIn
