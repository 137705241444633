import "../../css/Master/Material.css"
import Table from "@mui/material/Table"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AddIcon from "@mui/icons-material/Add"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import Input from "../../common/Resources/Input"
import TablePagination from "@mui/material/TablePagination"
import { GET_MATERIALS } from "../../common/Query/MasterQuery"
import { useQuery, useMutation } from "@apollo/client"
import React, { useState, ReactElement, useMemo } from "react"
import {
  ADD_MATERIAL,
  ADD_WIP_MATERIAL
} from "../../common/Mutation/MasterMutation"
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import { bankImage, getImageJPG } from "../../common/utilities"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

export interface IMaterial {
  matName: string
  matNo: string
  partType: string
  ratio: number
  sizeH: string
  sizeW: string
  sizeL: string
  spec: string
  wipQtyBox: number
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.secondary.contrastText,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.black
  },

  "td, th": {
    border: 0
  }
}))

export const GetMaterialsList: React.FC = (): ReactElement => {
  const theme = useTheme()
  const { loading, data } = useQuery(GET_MATERIALS)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [search, setSearch] = useState<string>("")
  const [openAddMaterial, setOpenAddMaterial] = useState<boolean>(false)
  const [openUpdateMaterial, setOpenUpdateMaterial] = useState<boolean>(false)

  if (loading)
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ padding: "10px" }}
      >
        <CircularProgress />
      </Grid>
    )
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const matNoList =
    data?.materials?.map((material: IMaterial) => material.matNo) || []

  const materialList = data.materials
    .filter((material: IMaterial) => {
      if (search) return material.matNo === search
      else return material
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((material: IMaterial) => {
      return (
        <StyledTableRow key={material.matNo}>
          <StyledTableCell component="th" scope="row">
            {material.matNo}
          </StyledTableCell>
          <StyledTableCell align="left">{material.matName}</StyledTableCell>
          <StyledTableCell align="left">{material.sizeH}</StyledTableCell>
          <StyledTableCell align="left">{material.sizeW}</StyledTableCell>
          <StyledTableCell align="left">{material.sizeL}</StyledTableCell>
          <StyledTableCell align="left">{material.ratio}</StyledTableCell>
          <StyledTableCell align="left">{material.spec}</StyledTableCell>
          <StyledTableCell align="left">{material.partType}</StyledTableCell>
          <StyledTableCell align="left">
            {
              <img
                src={getImageJPG(`images/material/${material.partType}`)}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = bankImage
                }}
                width={40}
                height={40}
                alt=""
              ></img>
            }
          </StyledTableCell>
        </StyledTableRow>
      )
    })

  return (
    <>
      <AddNewMaterial
        setOpenAddMaterial={setOpenAddMaterial}
        openAddMaterial={openAddMaterial}
      />
      <UpdateMaterial
        setOpenUpdateMaterial={setOpenUpdateMaterial}
        openUpdateMaterial={openUpdateMaterial}
      />
      <Grid container spacing={2} width={"98%"} padding={2}>
        <Grid item xs={4} sm={4} xl={4} marginLeft={-1}>
          <Autocomplete
            id="search"
            freeSolo
            value={search}
            options={matNoList}
            style={{ width: "100%", fontFamily: "Sarabun" }}
            sx={{ width: 100 }}
            onChange={(e, newValue) => {
              setPage(0)
              setSearch(newValue ? newValue : "")
            }}
            renderInput={(params) => (
              <ThemedTextField
                style={{ marginLeft: "15px", fontFamily: "Sarabun" }}
                {...params}
                label="Search"
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={8}
          sm={8}
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <ThemedButton
              contrast
              variant="contained"
              onClick={() => setOpenUpdateMaterial(true)}
            >
              UPDATE MATERIAL
            </ThemedButton>
          </Grid>
          <Grid item>
            <ThemedButton
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenAddMaterial(true)}
            >
              ADD NEW MATERIAL
            </ThemedButton>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Mat No</StyledTableCell>
              <StyledTableCell>Mat Name</StyledTableCell>
              <StyledTableCell>Size H</StyledTableCell>
              <StyledTableCell>Size W</StyledTableCell>
              <StyledTableCell>Size L</StyledTableCell>
              <StyledTableCell>Ratio</StyledTableCell>
              <StyledTableCell>Spec</StyledTableCell>
              <StyledTableCell>Part Type</StyledTableCell>
              <StyledTableCell>Image Material</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{materialList}</TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={
            data.materials.filter((material: IMaterial) => {
              if (search) return material.matNo === search
              else return material
            }).length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ backgroundColor: theme.palette.warning.light }}
        />
      </TableContainer>
    </>
  )
}

interface IUpdateMaterial {
  setOpenUpdateMaterial: (boolean: boolean) => void
  openUpdateMaterial: boolean
}

export const UpdateMaterial: React.FC<IUpdateMaterial> = (
  props: IUpdateMaterial
): ReactElement => {
  const { openUpdateMaterial, setOpenUpdateMaterial } = props
  const [matNo, setMatNo] = useState<string>()
  const [wipQtyBox, setWipQtyBox] = useState<number>()
  const [factory, setFactory] = useState<string>()
  const [matName, setMatName] = useState<string>("")
  const [sizeH, setSizeH] = useState<string>("")
  const [sizeW, setSizeW] = useState<string>("")
  const [sizeL, setSizeL] = useState<string>("")
  const [ratio, setRatio] = useState<number>(0.0)
  const [spec, setSpec] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  const [partType, setPartType] = useState<string>("")
  const partTypeList = ["Component", "Coil", "Sheet"]
  const [addWipMaterial, { loading }] = useMutation(ADD_WIP_MATERIAL)
  const { data } = useQuery(GET_MATERIALS)
  const materialList = data
    ? data?.materials?.map(({ matNo }: { matNo: string }) => matNo)
    : []

  const handleSubmit = async () => {
    try {
      await addWipMaterial({
        variables: {
          matNo,
          wipQtyBox,
          factory,
          sizeH,
          sizeL,
          sizeW,
          ratio,
          spec,
          matName,
          partType
        }
      })
      swal("Success", "Updater Success", "success")
      setOpenUpdateMaterial(false)
    } catch (err) {
      setError(true)
      swal("Warning", `กรุณาใส่ MatNo`, "warning")
    }
  }

  useMemo(() => {
    const material = data?.materials?.find((entry: { matNo: string }) => {
      return entry.matNo === matNo
    })
    if (material) {
      setMatNo(material.matNo)
      setMatName(material.matName)
      setSizeH(material.sizeH)
      setSizeL(material.sizeL)
      setSizeW(material.sizeW)
      setRatio(material.ratio)
      setSpec(material.spec)
      setPartType(material.partType)
      setWipQtyBox(material.wipQtyBox)
      setFactory(material.factory)
    }
  }, [matNo])

  return (
    <>
      <Dialog
        open={openUpdateMaterial}
        onClose={() => setOpenUpdateMaterial(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Update Material
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item sm={12} xs={12}>
              <Autocomplete
                disablePortal
                id="status"
                value={matNo ? matNo : ""}
                options={materialList}
                style={{ width: "100%" }}
                sx={{ width: 100 }}
                loading={!!materialList}
                onChange={(e, newValue) => setMatNo(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select MatNo"
                    error={!matNo && error}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setMatName(e.target.value)}
                value={matName ? matName : ""}
                id="material name"
                autoComplete="family-name"
                label="Material Name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setSizeH(e.target.value)}
                value={sizeH ? sizeH : ""}
                id="sizeH"
                label="Size H"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setSizeW(e.target.value)}
                value={sizeW ? sizeW : ""}
                id="sizeW"
                label="Size W"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="Size L"
                onChange={(e) => setSizeL(e.target.value)}
                value={sizeL ? sizeL : ""}
                id="sizeL"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <ThemedTextField
                label="Ratio"
                fullWidth
                value={ratio ? ratio : ""}
                type="number"
                inputProps={{
                  minLength: 0,
                  step: "0.1"
                }}
                onChange={(e) => setRatio(parseFloat(e.target.value))}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                fullWidth
                label="Spec"
                onChange={(e) => setSpec(e.target.value)}
                value={spec ? spec : ""}
                id="spec"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                value={partType ? partType : ""}
                onChange={(_event, selectedPartType) => {
                  setPartType(selectedPartType ? selectedPartType : "")
                }}
                options={partTypeList}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Material Type" />
                )}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <ThemedTextField
                label="Wip Qty Box"
                fullWidth
                type="number"
                value={wipQtyBox}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
                onChange={(e) => setWipQtyBox(+e.target.value)}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <Autocomplete
                disablePortal
                id="factory"
                value={factory ? factory : ""}
                options={["1", "2"]}
                style={{ width: "100%" }}
                sx={{ width: 100 }}
                onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Select Factory" />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenUpdateMaterial(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

interface IAddMaterial {
  setOpenAddMaterial: (boolean: boolean) => void
  openAddMaterial: boolean
}

export const AddNewMaterial: React.FC<IAddMaterial> = (
  props: IAddMaterial
): ReactElement => {
  const { openAddMaterial, setOpenAddMaterial } = props
  const [addMaterial, { loading }] = useMutation(ADD_MATERIAL, {
    refetchQueries: [GET_MATERIALS],
    awaitRefetchQueries: true
  })
  const [matNo, setMatNo] = useState<string>("")
  const [matName, setMatName] = useState<string>("")
  const [sizeH, setSizeH] = useState<string>("")
  const [sizeW, setSizeW] = useState<string>("")
  const [sizeL, setSizeL] = useState<string>("")
  const [ratio, setRatio] = useState<number>(0.0)
  const [spec, setSpec] = useState<string>("")
  const [factory, setFactory] = useState<string>()
  const [wipQtyBox, setWipQtyBox] = useState<number>()
  const [partType, setPartType] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  const partTypeList = ["Component", "Coil", "Sheet"]

  const isComplete = () => {
    if (
      !matNo ||
      !matName ||
      !wipQtyBox ||
      !partType ||
      !spec ||
      !ratio ||
      !sizeH ||
      !sizeL ||
      !sizeW ||
      !factory
    ) {
      setError(true)
    }
    return (
      matNo &&
      matName &&
      wipQtyBox &&
      partType &&
      spec &&
      ratio &&
      sizeH &&
      sizeL &&
      sizeW &&
      factory
    )
  }

  const handleSubmit = async () => {
    if (!isComplete())
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    try {
      await addMaterial({
        variables: {
          matNo,
          matName,
          sizeH,
          sizeW,
          sizeL,
          ratio,
          spec,
          partType,
          wipQtyBox,
          factory
        }
      })
      swal("Success", "Create Success", "success")
      setOpenAddMaterial(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openAddMaterial}
        onClose={() => setOpenAddMaterial(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Add New Material</DialogTitle>

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Input
              fullWidth
              value={matNo}
              text={"Material No."}
              inputType="string"
              setValue={(event) => setMatNo(event.target.value)}
              sm={12}
              error={!matNo && error}
            />
            <Input
              value={matName}
              error={!matName && error}
              fullWidth
              text={"Material Name"}
              inputType="string"
              setValue={(event) => setMatName(event.target.value)}
              sm={12}
            />
            <Input
              value={sizeH}
              error={!sizeH && error}
              fullWidth
              text={"Size H"}
              inputType="string"
              setValue={(event) => setSizeH(event.target.value)}
              sm={4}
              xs={4}
            />
            <Input
              fullWidth
              value={sizeW}
              error={!sizeW && error}
              text={"Size W"}
              inputType="string"
              setValue={(event) => setSizeW(event.target.value)}
              sm={4}
              xs={4}
            />
            <Input
              fullWidth
              value={sizeL}
              error={!sizeL && error}
              text={"Size L"}
              inputType="string"
              setValue={(event) => setSizeL(event.target.value)}
              xs={4}
              sm={4}
            />
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                label="Ratio"
                fullWidth
                value={ratio}
                error={!ratio && error}
                type="number"
                inputProps={{
                  minLength: 0,
                  step: "0.1"
                }}
                onChange={(e) => setRatio(parseFloat(e.target.value))}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Input
              fullWidth
              value={spec}
              error={!spec && error}
              text={"Spec"}
              inputType="string"
              setValue={(event) => setSpec(event.target.value)}
              sm={12}
            />
            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                value={partType}
                defaultValue={""}
                disableClearable
                disablePortal
                onChange={(event, selectedPartType) => {
                  setPartType(selectedPartType as string)
                }}
                options={partTypeList}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Material Type"
                    error={!partType && error}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                label="Wip Qty Box"
                fullWidth
                type="number"
                value={wipQtyBox}
                error={!wipQtyBox && error}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
                onChange={(e) => setWipQtyBox(+e.target.value)}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                value={factory}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select Factory"
                    error={!factory && error}
                  />
                )}
                onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
                options={["1", "2"]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenAddMaterial(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const pageTitle = "Material"

export const Material: React.FC = () => {
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Master}
        menuItemName={Object.keys(menuItemList)[0]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard title="Material" subTitle="About detail Material" />
        <GetMaterialsList />
      </ThemedCard>
    </ThemeProvider>
  )
}

export default Material
