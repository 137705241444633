import { ThemeProvider } from "@mui/styles"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { Autocomplete, Box, Button, Divider, Grid, Theme, useTheme } from "@mui/material"
import { DataGrid } from "bitsoft-c3"
import {
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp
} from "@mui/x-data-grid"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import { useMemo, useRef, useState } from "react"
import Swal from "sweetalert2"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import moment from "moment"
import { GET_WIP_STOCK } from "../../common/Query/MaterialQuery"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { UPDATE_WIP_STOCK } from "../../common/Mutation/MaterialMutation"
import { user } from "../../common/MainApp"
import { GET_MATERIALS } from "../../common/Query/MasterQuery"

interface IWipstock {
  indexNo: number
  matNo: string
  partNo: string
  jobOrder: string
  pdLotNo: string
  inOrOut: number
  actionDate: string
  quantity: number
  factory: string
  packingBy: string
  remarks: string
  isNG: boolean
  perBox: number
  dateScan: string
  updatedBy: string
  updatedDate: string
  isNew?: boolean
}

const makeColumns = (
  handleCancelClick: (id: GridRowId) => () => void,
  handleEditClick: (id: GridRowId) => () => void,
  handleSaveClick: (id: GridRowId) => () => void,
  rowModesModel: GridRowModesModel,
  theme: Theme
): GridColDef[] => {
  const column: GridColDef[] = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          return [
            <Button
              startIcon={<SaveIcon />}
              onClick={handleSaveClick(id)}
              sx={{
                color: theme.palette.text.primary,
                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme.palette.primary.main
                }
              }}
            />,
            <Button
              startIcon={<CancelIcon />}
              className="textPrimary"
              onClick={handleCancelClick(id)}
              sx={{
                color: theme.palette.text.primary,
                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme.palette.text.secondary
                }
              }}
            />
          ]
        }
        return [
          <Button
            startIcon={<EditIcon />}
            className="textPrimary"
            onClick={handleEditClick(id)}
            sx={{
              color: theme.palette.text.primary,
              "&:hover": {
                backgroundColor: "transparent",
                color: theme.palette.text.secondary
              }
            }}
          />
        ]
      }
    },
    {
      field: "indexNo",
      headerName: "ID",
      headerAlign: "center",
      align: "center"
    },
    {
      field: "matNo",
      headerName: "Material No",
      minWidth: 200,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "partNo",
      headerName: "Part No",
      minWidth: 200,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "jobOrder",
      headerName: "Job Order",
      headerAlign: "center",
      minWidth: 200,
      align: "center"
    },
    {
      field: "pdLotNo",
      headerName: "Lot No",
      minWidth: 200,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "actionDate",
      headerName: "ActionDate",
      minWidth: 200,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "inOrOut",
      headerName: "in Or Out",
      minWidth: 100,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "quantity",
      headerName: "Quantity",
      minWidth: 160,
      type: "number",
      editable: true,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "factory",
      headerName: "Factory",
      minWidth: 120,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "packingBy",
      headerName: "Packing by",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "remarks",
      headerName: "Remarks",
      minWidth: 120,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "isNG",
      headerName: "isNG",
      minWidth: 80,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "perBox",
      headerName: "Per Box",
      type: "number",
      minWidth: 180,
      editable: true,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "dateScan",
      headerName: "Date Scan",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    }
  ]
  return column
}

const WipStock: React.FC = () => {
  const theme = useTheme()
  const [rows, setRows] = useState<GridRowsProp>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const rowIdRef = useRef<GridRowId>()
  const [matNo, setMatNo] = useState<string>("")
  const { data: getMatNo } = useQuery(GET_MATERIALS)
  const listMatNo: string[] = useMemo(() => {
    if (!getMatNo || !getMatNo.materials) return []
    else {
      return getMatNo?.materials.map(({ matNo }: { matNo: string }) => {
        return matNo
      })
    }
  }, [getMatNo])

  const [getWipStocks, { loading: loadingGetWipStocks }] = useLazyQuery(
    GET_WIP_STOCK,
    {
      onCompleted: (data) => {
        if (data && data.wipStocks.length) setRows(data.wipStocks) 
          else Swal.fire({ title: `ไม่พบข้อมูล`, icon: "info" })
      }
    }
  )

  const handleSubmit = async () => {
    setRows([])
    if (matNo) {
      await getWipStocks({
        variables: {
          partNo: matNo
        },
        fetchPolicy: "network-only"
      })
    } else Swal.fire({ title: "ข้อมูลไม่ครบ", icon: "warning" })
  }

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit }
    })
  }
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View }
    })
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
    const editedRow = rows.find((row) => row.indexNo === id)
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.indexNo !== id))
    }
  }

  const handleProcessError = async (error: Error) => {
    const id = rowIdRef.current as GridRowId
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
    return Swal.fire({
      title: "error",
      text: error.message,
      icon: "error"
    })
  }

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false }
    await handleSaveData(updatedRow as IWipstock, "update")
    setRows(
      rows.map((row) => (row.indexNo === newRow.indexNo ? updatedRow : row))
    )
    return updatedRow
  }

  const [updateWipStock] = useMutation(UPDATE_WIP_STOCK, {
    awaitRefetchQueries: true,
    refetchQueries: [GET_WIP_STOCK]
  })

  const handleSaveData = async (updateRow: IWipstock, type: string) => {
    if (type === "update") {
      try {
        const updated = await updateWipStock({
          variables: {
            indexNo: updateRow.indexNo,
            quantity: updateRow.quantity,
            perBox: updateRow.perBox,
            updatedBy: user.employee,
            updatedDate: moment().format("YYYY-MM-DD HH:mm:ss")
          }
        })
        handleSubmit()
        Swal.fire({
          title: "สำเร็จ",
          text: "ข้อมูลถูกแก้ไขเรียบร้อย",
          icon: "success"
        })

        return updated.data?.updateWipStock
      } catch (error) {
        Swal.fire({
          title: "error",
          text: (error as Error).message,
          icon: "error"
        })
      }
    }
  }

  const columns = makeColumns(
    handleCancelClick,
    handleEditClick,
    handleSaveClick,
    rowModesModel,
    theme
  )

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <PageLabel
          menuItem={menuItemList.Materials}
          menuItemName={Object.keys(menuItemList)[1]}
          menuPageName={"WipStock"}
        />
        <ThemedCard>
          <PageLabelCard
            title="Edit Wip Stock"
            subTitle="Edit Quantity Wip Stock"
          />
          <Divider
            sx={{ height: 1, bgcolor: theme.palette.secondary.contrastText }}
          />
          <br />
          <Grid container spacing={2} sx={{ p: "20px" }}>
            
          <Grid item xs={4} md={2}>
            <Autocomplete
              value={matNo}
              onChange={(event, value) => {
                setMatNo(value as string)
              }}
              options={listMatNo}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Material No."
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
            <Grid item xs={3} md={2}>
              <ThemedLoadingButton
                loading={loadingGetWipStocks}
                variant="outlined"
                sx={{ mt: 1, width: "70%" }}
                onClick={handleSubmit}
              >
                SUBMIT
              </ThemedLoadingButton>
            </Grid>

            <Grid item xs={12}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                dataGridProps={{
                  rowModesModel: rowModesModel,
                  onRowEditStop: handleRowEditStop,
                  processRowUpdate: processRowUpdate,
                  onProcessRowUpdateError: handleProcessError,
                  getRowHeight: () => "auto",
                  getRowId: (row) => row.indexNo,
                  sx: {
                    bgcolor: theme.palette.secondary.main,
                    "& .MuiGrid-root .MuiButtonBase-root": {
                      color: theme.palette.secondary.contrastText,
                      "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.1) !important"
                      }
                    },
                    "& .MuiDataGrid-row:nth-of-type(odd)": {
                      backgroundColor: theme.palette.background.default
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </ThemedCard>
      </Box>
    </ThemeProvider>
  )
}

export default WipStock
