import { gql } from "@apollo/client"

export const CREATE_PREMIUM_FREIGHT = gql`
  mutation QcTransportCreate($data: qcTransportCreateData) {
    premiumFreightCreate(data: $data)
  }
`
export const CREATE_CHECK_SHEET = gql`
  mutation CreateCheckSheet($data: [IInputDataCheckSheet]) {
    createCheckSheet(data: $data)
  }
`
