import { Card, CardProps, useTheme, ThemeProvider } from "@mui/material"
import { styled } from "@mui/material/styles"

const ThemedCardComponent = styled(Card)(({ theme }) => ({
  paddingTop: "25px",
  backgroundColor: theme.palette.success.light
})) as typeof Card

const ThemedCard = (props: CardProps): JSX.Element => {
  const theme = useTheme()

  const themedCard = (
    <ThemedCardComponent {...props} className="hide-container" />
  )
  return <ThemeProvider theme={theme}>{themedCard}</ThemeProvider>
}

export default ThemedCard
