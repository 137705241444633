import { gql } from "@apollo/client"

export const CREATE_SUPPLIER_CONTROL = gql`
  mutation CreateSupplierControl($input: ISupplierControlInput) {
    createSupplierControl(input: $input)
  }
`
export const DELETE_SUPPLIER_CONTROL = gql`
  mutation DeleteSupplierControl(
    $pdLotNo: String
    $partNo: String
    $boxNo: Int
    $inOrOut: Int
  ) {
    deleteSupplierControl(
      pdLotNo: $pdLotNo
      partNo: $partNo
      boxNo: $boxNo
      inOrOut: $inOrOut
    )
  }
`
