import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardHeader,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  useTheme
} from "@mui/material"
import { useEffect, useRef, useState } from "react"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { GET_PARTS } from "../../common/Query/MasterQuery"
import { useQuery } from "@apollo/client"
import { IPart } from "../Master/Parts"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { bankImage, getImagePng } from "../../common/utilities"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import Webcam from "react-webcam"
import Tesseract from "tesseract.js"
import {
  DownloadPackagingStandard,
  IPackagingStandard
} from "../Warehouse/PackagingStandard"
import { GetPackagingStandard } from "../../common/Query/PackagingStandardQuery"
import swal from "sweetalert"

const ScannerQRCodeReadPart = (): JSX.Element => {
  const theme = useTheme()
  const { data } = useQuery(GET_PARTS)
  const [isShow, setIsShow] = useState<boolean>(false)
  const [partNo, setPartNo] = useState<IPart>({} as IPart)
  const [isStream, setIsStream] = useState<boolean>(false)
  const [dataScan, setDataScan] = useState<string | null>("")
  const webcamRef = useRef<Webcam>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [filteredText, setFilteredText] = useState<string[]>([])

  const [torchOn, setTorchOn] = useState<boolean>(false)
  const [videoTrack, setVideoTrack] = useState<MediaStreamTrack | null>(null)

  // const [tempStr, setTempStr] = useState<string>()
  // const [tempStr2, setTempStr2] = useState<string>()

  const { error: errorPackingStd, data: getPackingStd } = useQuery(
    GetPackagingStandard,
    {
      fetchPolicy: "network-only"
    }
  )
  if (errorPackingStd) swal("error", errorPackingStd.toString(), "")
  let packagingStandard: IPackagingStandard[] = []
  if (getPackingStd) packagingStandard = getPackingStd?.packagingStandard

  const cleanString = (input: string): string => {
    let cleaned = input.replace(/~/g, "-")
    cleaned = cleaned.replace(/—/g, "-")
    cleaned = cleaned.replace(/\s+/g, "")
    cleaned = cleaned.replace(/o/g, "0")
    // cleaned = cleaned.replace(/O/g, "0")
    cleaned = cleaned.replace(/!/g, "1")
    cleaned = cleaned.replace(/i/g, "1")
    cleaned = cleaned.replace(/\|/g, "1")
    cleaned = cleaned.replace(/[^A-Z0-9-]/g, "")
    return cleaned
  }

  const handleReset = async () => {
    setDataScan("")
    setIsShow(false)
    setFilteredText([])
  }

  const captureAndExtractText = async () => {
    await handleReset()
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()

      if (imageSrc) {
        setLoading(true)
        try {
          const result = await Tesseract.recognize(imageSrc, "eng")
          const extractedText = result.data.text

          const clearChar = cleanString(extractedText)

          const matchedPartNo = data?.parts
            .map((e: { partNo: string }) => {
              return e.partNo
            })
            .filter((partNo: string) => clearChar.includes(partNo))

          // setTempStr(extractedText)
          // setTempStr2(clearChar)

          if (extractedText) {
            setFilteredText(matchedPartNo)
          } else {
            setFilteredText([])
          }
        } catch (error) {
          console.error("Error extracting text:", error)
        } finally {
          setLoading(false)
        }
      }
    }
  }

  const toggleTorch = async () => {
    if (videoTrack) {
      const capabilities = videoTrack.getCapabilities() as never as {
        torch?: boolean
      }

      if (capabilities.torch) {
        const newTorchState = !torchOn
        try {
          await videoTrack.applyConstraints({
            advanced: [{ torch: newTorchState }]
          } as never)
          setTorchOn(newTorchState)
        } catch (error) {
          console.error("Error toggling torch:", error)
        }
      } else {
        alert("Torch is not supported on this device.")
      }
    }
  }

  useEffect(() => {
    if (torchOn && dataScan) {
      toggleTorch()
    }
  }, [dataScan])

  const handleUserMedia = (stream: MediaStream) => {
    const videoTrack = stream.getVideoTracks()[0]
    setVideoTrack(videoTrack)
  }

  const breakQRCode = (e?: { preventDefault: () => void }) => {
    e?.preventDefault()
    const findPart = data.parts.find((part: IPart) => part.partNo === dataScan)
    if (findPart) {
      setPartNo(findPart)
      setIsShow(true)
    } else setIsShow(false)
  }

  useEffect(() => {
    if (dataScan) {
      breakQRCode()
    }
  }, [dataScan])

  return (
    <ThemedCard>
      <CardHeader
        titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
        title={"Scanner QR Code Read Part"}
        subheaderTypographyProps={{ fontFamily: "Sarabun" }}
      />
      <Box component="form" onSubmit={breakQRCode}>
        <Accordion
          expanded={isStream}
          onChange={() => setIsStream(!isStream)}
          style={{
            marginBottom: "5px",
            marginTop: "10px"
          }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ backgroundColor: "#ffffff", display: "none" }}
          />

          <AccordionDetails>
            {isStream ? (
              <>
                <Grid container justifyContent={"center"}>
                  <Grid item xs={8} sm={3} container justifyContent={"center"}>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{
                        facingMode: "environment"
                      }}
                      onUserMedia={handleUserMedia}
                      width={"100%"}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  rowSpacing={5}
                  direction="column"
                  sx={{
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <ThemedLoadingButton
                      loading={loading}
                      variant="contained"
                      sx={{
                        mt: 1,
                        width: "100%",
                        backgroundColor: theme.palette.warning.main,
                        color: "white"
                      }}
                      onClick={captureAndExtractText}
                    >
                      scan code
                    </ThemedLoadingButton>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={dataScan || null}
                      onChange={(e) => {
                        setDataScan((e.target as HTMLInputElement).value)
                      }}
                    >
                      {filteredText.map((item, index) => (
                        <FormControlLabel
                          value={item}
                          control={<Radio />}
                          label={item}
                          key={index}
                        />
                      ))}
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={toggleTorch}
                    >
                      {torchOn ? "Turn Off Flashlight" : "Turn On Flashlight"}
                    </Button>
                  </Grid>
                  {/* <Box sx={{ fontStyle: "italic" }}>{tempStr}</Box> */}
                  {/* <Box>{tempStr2}</Box> */}
                </Grid>
              </>
            ) : (
              <></>
            )}
          </AccordionDetails>
        </Accordion>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Grid container spacing={2} width={"98%"} margin={2}>
            <Grid item xs={12} sm={12}>
              <Switch
                checked={isStream}
                onChange={(event) => {
                  setIsStream(event.target.value ? !isStream : isStream)
                }}
                name="gilad"
              />
              <label style={{ fontSize: "14px" }}>
                Active Camera{" "}
                <CameraAltIcon style={{ verticalAlign: "middle" }} />
              </label>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemedTextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                id="scanner"
                value={dataScan}
                label="scanner"
                onChange={(e) => {
                  setDataScan(e.target.value)
                }}
              />
            </Grid>
            <Grid item>
              <ThemedLoadingButton
                contrast
                variant="outlined"
                sx={{ mt: 1, width: "100%" }}
                onClick={handleReset}
              >
                Clear Value
              </ThemedLoadingButton>
            </Grid>
            {isShow && (
              <>
                <Grid item sm={4} xs={12} container justifyContent={"center"}>
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      boxShadow: 1,
                      borderRadius: 1,
                      p: 2,
                      m: 1,
                      textAlign: "left",
                      fontFamily: "Sarabun"
                    }}
                  >
                    <Typography variant="h6">
                      Part No. : {partNo.partNo}
                    </Typography>
                    <Typography variant="h6">
                      Part Name : {partNo.partName}
                    </Typography>
                    <Typography variant="h6">
                      Customer : {partNo.customer}
                    </Typography>
                    <Typography variant="h6">Model : {partNo.model}</Typography>
                    <Typography variant="h6">Cost : {partNo.cost}</Typography>
                    <Typography variant="h6">
                      Lead Time : {partNo.leadTime}
                    </Typography>
                    <Typography variant="h6">Stamp : {partNo.stamp}</Typography>
                    <Typography variant="h6">Weld : {partNo.weld}</Typography>
                    <Typography variant="h6">
                      Arc Weld : {partNo.arcweld}
                    </Typography>
                    <Typography variant="h6">Plate : {partNo.plate}</Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={12} container justifyContent={"center"}>
                  <img
                    src={getImagePng(`images/parts/${partNo.partNo}`)}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null
                      currentTarget.src = bankImage
                    }}
                    style={{ width: "100%" }}
                    alt=""
                  ></img>
                </Grid>

                <Grid item sm={4} xs={12} container justifyContent={"center"}>
                  <DownloadPackagingStandard
                    packagingStandard={packagingStandard}
                    isStream={dataScan != null}
                    customer={partNo.customer}
                    partNo={partNo.partNo}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Button type="submit"></Button>
        </Box>
      </Box>
    </ThemedCard>
  )
}
export default ScannerQRCodeReadPart
