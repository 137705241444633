import { useLazyQuery, useQuery } from "@apollo/client"
import {
  Autocomplete,
  Table,
  CardHeader,
  useTheme,
  styled,
  ThemeProvider,
  Grid
} from "@mui/material"
import React, { useCallback, useState } from "react"
import { GET_MATERIALS, GET_PARTS } from "../../common/Query/MasterQuery"
import { GET_MATSTOCK } from "../../common/Query/MaterialQuery"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { logoTable } from "../../common/utilities"
import swal from "sweetalert"

import "../../css/Materials/SMOrder.css"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { useParsedMatData } from "./materialCustomHook/useParsedMatData"

const Root = styled("div")(
  () => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid black;
    text-align: center;
    padding: 6px;
    white-space:nowrap

  }

  th {
    font-weight:bold;

  }
  `
)

const pageTitle = "Materials Report Stock Card"

const MaterialsStockCard: React.FC = () => {
  const [partNo, setPartNo] = useState<string>("")
  const [jobOrder, setJobOrder] = useState<string | null>("")
  const [dateFrom, setDateFrom] = useState("")
  const [dateTo, setDateTo] = useState("")
  const [error, setError] = useState<boolean>(false)
  const getParts = useQuery(GET_PARTS)
  const getMat = useQuery(GET_MATERIALS)
  const [getMatStock, { data, loading }] = useLazyQuery(GET_MATSTOCK)
  const formattedData = useParsedMatData(
    data?.matStocks,
    dateFrom,
    dateTo,
    jobOrder
  )

  const theme = useTheme()

  let jobOrderList: Array<string> = []

  if (data) {
    jobOrderList = data.matStocks
      .filter(
        (matStock: { matNo: string; jobOrder: string }) =>
          matStock.matNo === partNo && matStock.jobOrder !== null
      )
      .map((mat: { jobOrder: string }) => mat.jobOrder)
  }

  const isComplete = () => {
    return partNo !== ""
  }

  const handleSubmit = useCallback(async () => {
    if (!isComplete() || !partNo) {
      setError(true)
      swal("ERROR", "กรุณาใส่ part No", "error")
      return
    }
    await getMatStock({
      variables: { matNo: partNo }
    })
  }, [partNo])

  const handlePrint = () => {
    window.print()
  }
  const getSize = () => {
    let matData = []
    if (getMat.data)
      matData = getMat.data.materials.filter(
        (e: { matNo: string }) => e.matNo === partNo
      )[0]
    if (!matData) return ""
    if (matData.partType === "Component" || matData.partType === "Coil") {
      return `${matData.sizeH}x${matData.sizeL}x${matData.sizeW}`
    }
    return `${matData.sizeH}x${matData.sizeW}x${matData.sizeL}`
  }

  let filteredCustomer: Array<string> = []
  if (data) {
    filteredCustomer = getParts.data
      ? getParts.data.parts
          .filter(
            (filterPart: { partNo: string }) => filterPart.partNo === partNo
          )
          .map((part: { customer: string }) => part.customer)
      : ""
  }

  let filteredPartName: Array<string> = []
  if (data) {
    filteredPartName = getParts.data
      ? getParts.data.parts
          .filter(
            (filterPart: { partNo: string }) => filterPart.partNo === partNo
          )
          .map((part: { partName: string }) => part.partName)
      : ""
  }

  let filteredMatSpec: Array<string> = []
  if (data) {
    filteredMatSpec = getMat.data
      ? getMat.data.materials
          .filter((mat: { matNo: string }) => mat.matNo === partNo)
          .map((matData: { spec: string }) => matData.spec)
      : ""
  }

  return (
    <Root>
      <div>
        <div className="inventory-print">
          <ThemeProvider theme={theme}>
            <PageLabel
              menuItem={menuItemList.Materials}
              menuItemName={Object.keys(menuItemList)[1]}
              menuPageName={pageTitle}
            />
            <ThemedCard style={{ minWidth: "1200px" }}>
              <CardHeader
                titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
                title="Report Stock Card"
                subheaderTypographyProps={{ fontFamily: "Sarabun" }}
                subheader="About detail Report Stock Card"
              />
              <div style={{ margin: "20px" }}>
                <Grid container>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <AutocompletePartNo
                        setPartNo={setPartNo}
                        partNo={partNo}
                        error={!partNo && error}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <ThemedTextField
                        fullWidth
                        label="Date from"
                        InputLabelProps={{ shrink: true }}
                        value={dateFrom}
                        id="dateFrom"
                        type="date"
                        autoComplete="family-name"
                        onChange={(e) => setDateFrom(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <ThemedTextField
                        fullWidth
                        label="Date To"
                        InputLabelProps={{ shrink: true }}
                        value={dateTo}
                        id="dateTo"
                        type="date"
                        autoComplete="family-name"
                        onChange={(e) => setDateTo(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Autocomplete
                        disablePortal
                        id="jobOrder"
                        options={jobOrderList}
                        style={{ width: "100%" }}
                        sx={{ width: 300 }}
                        onChange={(e, newValue) => setJobOrder(newValue)}
                        renderInput={(params) => (
                          <ThemedTextField
                            {...params}
                            label="Job Order"
                            value={jobOrder}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs="auto">
                      <ThemedLoadingButton
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSubmit}
                        loading={loading}
                        style={{
                          whiteSpace: "nowrap"
                        }}
                      >
                        Submit
                      </ThemedLoadingButton>
                    </Grid>
                    <Grid item xs="auto">
                      <ThemedButton
                        variant="outlined"
                        contrast
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handlePrint}
                        style={{
                          whiteSpace: "nowrap"
                        }}
                        id="print"
                      >
                        PRINT
                      </ThemedButton>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </ThemedCard>
          </ThemeProvider>
        </div>
        <ThemedCard style={{ minWidth: "1200px" }}>
          <PageLabelCard
            title="Preview Report Stock Card"
            subTitle="About detail Report Stock Card"
          />
          <div style={{ margin: "20px" }}>
            <Table
              style={{ border: "1px solid black" }}
              sx={{
                backgroundColor:
                  theme.palette.mode === "light"
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
                color: "#000000"
              }}
              className="bg-material material-absolute"
            >
              <thead>
                <tr>
                  <th
                    rowSpan={2}
                    colSpan={3}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      height: "30%"
                    }}
                  >
                    <img src={logoTable} alt="" height={"80vh"} />
                  </th>
                  <th rowSpan={2} colSpan={5}>
                    <h3>บัญชีรายการวัตถุดิบ MATERIAL STOCK CARD</h3>
                  </th>
                  <th colSpan={3}>F-PL-03-03 </th>
                </tr>
                <tr>
                  <td colSpan={5}>Effective Date: 14/01/08 R02 Issue: 1</td>
                </tr>
                <tr>
                  <td colSpan={5} align="left">
                    <div>
                      <b>ชื่อลูกค้า:</b>
                      {filteredCustomer}
                    </div>
                    <div>
                      <b>รหัสสินค้า (Part No.):</b> {partNo}
                    </div>
                    <div>
                      <b>ชื่อสินค้า (Part Name):</b> {filteredPartName}
                    </div>
                  </td>
                  <td colSpan={6} align="left">
                    <div></div>
                    <div>
                      <b>Spec Mat'l:</b>
                      {filteredMatSpec}
                    </div>
                    <div>
                      <b>Size Mat'l:</b> {getSize()}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th rowSpan={2} colSpan={1}>
                    วัน/เดือน/ปี
                  </th>
                  <th colSpan={4}>บันทึกรับเข้า (RECEIVING RECORD)</th>
                  <th colSpan={5}> บันทึกจ่ายออก (DISTRIBUTE RECORD)</th>
                  <th colSpan={1} rowSpan={2}>
                    {" "}
                    ยอดคงเหลือ{" "}
                  </th>
                </tr>
                <tr>
                  <th colSpan={2}>Inv No.</th>
                  <th colSpan={1}>รับเข้า (pcs.)</th>
                  <th colSpan={1}>หมายเหตุ</th>
                  <th colSpan={1}>Inv No.</th>
                  <th colSpan={2}>Job Order</th>
                  <th colSpan={1}> จ่ายออก (pcs.)</th>
                  <th colSpan={1}>หมายเหตุ</th>
                </tr>
              </thead>
              <tbody>
                {formattedData.map((entry, index) => {
                  return (
                    <tr key={index}>
                      <td colSpan={1}>{entry.date ? entry.date : ""}</td>
                      <td colSpan={2}>{entry.in ? entry.in.invoiceNo : ""}</td>
                      <td colSpan={1}>
                        {entry.in
                          ? entry.in.quantity
                              .toString()
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                          : ""}
                      </td>
                      <td colSpan={1}>{entry.in ? entry.in.remarks : ""}</td>
                      <td colSpan={1}>
                        {entry.out ? entry.out.invoiceNo : ""}
                      </td>
                      <td colSpan={1}>{entry.out ? entry.out.jobOrder : ""}</td>
                      <td colSpan={2}>
                        {entry.out
                          ? entry.out.quantity
                              .toString()
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                          : ""}
                      </td>
                      <td colSpan={1}>{entry.out ? entry.out.remarks : ""}</td>
                      <td colSpan={1}>{entry.total.toFixed(2)}</td>
                    </tr>
                  )
                })}
                <tr>
                  <td colSpan={4} rowSpan={4} align="left">
                    <b>Remarks:</b>
                  </td>
                  <th colSpan={2} rowSpan={1}>
                    ผู้จัดทำ
                  </th>
                  <th colSpan={2} rowSpan={1}>
                    ผู้ตรวจสอบ
                  </th>
                  <th colSpan={3} rowSpan={1}>
                    ผู้อนุมัติ
                  </th>
                </tr>
                <tr>
                  <td colSpan={2} rowSpan={1} style={{ height: "25px" }}></td>
                  <td colSpan={2} rowSpan={1} style={{ height: "25px" }}></td>
                  <td colSpan={3} rowSpan={1} style={{ height: "25px" }}></td>
                </tr>
                <tr>
                  <td colSpan={2} rowSpan={1}></td>
                  <td colSpan={2} rowSpan={1}></td>
                  <td colSpan={3} rowSpan={1}></td>
                </tr>
                <tr>
                  <th colSpan={2}>พนักงานแผนกสโตร์</th>
                  <th colSpan={2}>หัวหน้าแผนกสโตร์</th>
                  <th colSpan={3}>ผู้จัดการฝ่ายวางแผน</th>
                </tr>
              </tbody>
            </Table>
          </div>
        </ThemedCard>
      </div>
    </Root>
  )
}

export default MaterialsStockCard
