import {
  Autocomplete,
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  styled,
  tableCellClasses,
  useTheme
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { useState, useEffect } from "react"
import moment from "moment"
import { useQuery } from "@apollo/client"
import { GET_LOGISTICS_REPORT_CAR_LICENSE } from "../../common/Query/LogisticsQuery"
import { GET_SUPPLIER_REPORT } from "../../common/Query/SupplierControl"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"

interface ISupplierReport {
  actionDate: string
  jobOrder: string
  pdLotNo: string
  partNo: string
  quantityInBae: number
  quantityOutBae: number
  remarks: string
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: "20px",
    fontFamily: "Sarabun",
    textAlign: "center",
    fontWeight: "bold",
    border: "1px solid black"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "15px",
    fontFamily: "Sarabun",
    textAlign: "center",
    border: "1px solid black"
  }
}))

const SupplierReport = (): JSX.Element => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment().format("DD-MM-YYYY")
  )
  const { data: dataCarLicense } = useQuery(GET_LOGISTICS_REPORT_CAR_LICENSE)
  const [dateTo, setDateTo] = useState<string>(moment().format("DD-MM-YYYY"))
  const [supplier, setSupplier] = useState<string>("")
  const [supplierReport, setSupplierReport] = useState<ISupplierReport[]>([])
  const supplierList =
    dataCarLicense?.carLicense
      .map((item: { carLicense: string }) => item.carLicense)
      .filter((value: string) => value.split("-")[0] == "OS") || []

  const { data, refetch } = useQuery(GET_SUPPLIER_REPORT, {
    variables: {
      supplier: supplier,
      dateFrom: dateFrom,
      dateTo: dateTo
    }
  })
  useEffect(() => {
    if (data) {
      setSupplierReport(data?.getSupplierReport || [])
    }
  }, [data])

  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <PageLabel
          menuItem={menuItemList.SupplierControl}
          menuItemName={Object.keys(menuItemList)[18]}
          menuPageName={"Supplier Report"}
        />
        <ThemedCard>
          <PageLabelCard
            title="Supplier Record"
            subTitle="detail about Supplier record"
          />
          <Grid container spacing={2} m={2}>
            <Grid item sm={3} xs={12}>
              <ThemedTextField
                id="Date From"
                type="date"
                value={dateFrom}
                error={!dateFrom}
                fullWidth
                label="Delivery Date"
                onChange={(event: {
                  target: { value: React.SetStateAction<string> }
                }) => setDateFrom(event.target.value)}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <ThemedTextField
                id="Date To"
                type="date"
                value={dateTo}
                error={!dateTo}
                fullWidth
                label="Delivery Date"
                onChange={(event: {
                  target: { value: React.SetStateAction<string> }
                }) => setDateTo(event.target.value)}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Autocomplete
                id="Supplier"
                options={supplierList}
                value={supplier}
                onChange={(_, newValue) => {
                  setSupplier(newValue ? newValue : "")
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Supplier"
                    error={!supplier}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <ThemedLoadingButton
                variant="contained"
                style={{
                  width: "120px",
                  height: "36px",
                  backgroundColor: theme.palette.primary.main
                }}
                sx={{ mt: 1, mb: 1 }}
                onClick={() => refetch()}
              >
                Submit
              </ThemedLoadingButton>
            </Grid>
          </Grid>
          <Card style={{ marginTop: "26px" }}>
            <PageLabelCard
              title="View Part History"
              subTitle="detail about View Part History"
            />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Action Date</StyledTableCell>
                    <StyledTableCell>Job Order</StyledTableCell>
                    <StyledTableCell>Part No</StyledTableCell>
                    <StyledTableCell>Quantity Out-Bae</StyledTableCell>
                    <StyledTableCell>Quantity In-Bae</StyledTableCell>
                    <StyledTableCell>Balance OS</StyledTableCell>
                    <StyledTableCell>Remarks</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {supplierReport.map((item, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>{item.actionDate}</StyledTableCell>
                      <StyledTableCell>{item.jobOrder}</StyledTableCell>
                      <StyledTableCell>{item.partNo}</StyledTableCell>
                      <StyledTableCell>{item.quantityOutBae}</StyledTableCell>
                      <StyledTableCell>{item.quantityInBae}</StyledTableCell>
                      <StyledTableCell>
                        {item.quantityOutBae - item.quantityInBae}
                      </StyledTableCell>
                      <StyledTableCell>{item.remarks}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </ThemedCard>
      </Box>
    </ThemeProvider>
  )
}

export default SupplierReport
