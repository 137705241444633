import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  useTheme,
  Table,
  TableHead,
  TableBody,
  Grid,
  Autocomplete,
  styled,
  TableCell,
  tableCellClasses,
  TableRow
} from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { DELETE_PM_CHECK_LIST } from "../../../common/Mutation/MaintenanceMutation"
import { GET_PM_ALL_CHECK_LIST } from "../../../common/Query/MaintenanceQuery"
import { useMutation, useQuery } from "@apollo/client"
import { IReportPMCheckList, typeList } from "./PmCheckListCreate"
import swal from "sweetalert"
import { LoadingButton } from "@mui/lab"

interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>

  open: boolean
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 8,
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  border: "solid 1px black",
  "&:nth-of-type(odd)": {
    border: "solid 1px black"
  },

  "&:last-child td, &:last-child th": {
    border: "solid 1px black"
  }
}))

const DialogDeletePMChecklist = (props: IProps): JSX.Element => {
  const { setOpen, open } = props
  const [typeUpdate, setTypeUpdate] = useState<string>("")
  const [pmCheckList, setPMCheckList] = useState<IReportPMCheckList[]>([])
  const { data: dataPmAllCheckList } = useQuery(GET_PM_ALL_CHECK_LIST)

  const [deletePMCheckList, { loading: loadingDeleteCheckList }] = useMutation(
    DELETE_PM_CHECK_LIST,
    { refetchQueries: [GET_PM_ALL_CHECK_LIST], awaitRefetchQueries: true }
  )
  useEffect(() => {
    setPMCheckList(dataPmAllCheckList?.pmCheckListAllReport || [])
  }, [dataPmAllCheckList])

  const theme = useTheme()

  const handleDeleteData = async (data: IReportPMCheckList) => {
    try {
      await deletePMCheckList({
        variables: {
          inputPmCheckList: {
            id: data.id,
            type: data.type,
            details: data.details,
            method: data.method,
            tool: data.tool,
            specificationReference: data.specificationReference,
            remarks: data.remarks
          }
        }
      })

      swal("ลบข้อมูลสำเร็จ", {
        icon: "success",
        timer: 1500
      })
    } catch (err) {
      swal("Error", `${(err as Error).message}`, "error")
    }
  }

  const handleCheckDelete = async (data: IReportPMCheckList) => {
    swal({
      title: "ยืนยันที่จะลบข้อมูลหรือไม่",
      text: "",
      icon: "warning",
      buttons: ["Cancel", "OK"],
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        await handleDeleteData(data)
      }
    })
  }

  useMemo(() => {
    if (typeUpdate) {
      setPMCheckList(
        dataPmAllCheckList?.pmCheckListAllReport.filter(
          (val: IReportPMCheckList) => val.type === typeUpdate
        )
      )
    }
  }, [typeUpdate])

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl" fullWidth>
      <DialogTitle style={{ textAlign: "center" }}>
        EDIT TARGET LINE
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <Grid container spacing={2} width={"98%"} padding={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              disablePortal
              id="type"
              value={typeUpdate}
              freeSolo
              options={typeList}
              onChange={(e, newValue) => setTypeUpdate(newValue ?? "")}
              renderInput={(params) => (
                <TextField {...params} label={"Select type"} />
              )}
            />
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Maintenance Checking</StyledTableCell>
              <StyledTableCell>Method</StyledTableCell>
              <StyledTableCell>Equipment</StyledTableCell>
              <StyledTableCell>Std.</StyledTableCell>

              <StyledTableCell>Comment</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {pmCheckList.map((val) => {
              return (
                <>
                  <StyledTableRow>
                    <StyledTableCell>{val.details}</StyledTableCell>
                    <StyledTableCell>{val.method}</StyledTableCell>
                    <StyledTableCell>{val.tool}</StyledTableCell>
                    <StyledTableCell>
                      {val.specificationReference}
                    </StyledTableCell>
                    <StyledTableCell>{val.remarks}</StyledTableCell>
                    <StyledTableCell>
                      {val.status ? "Active" : "No Active"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {val.status ? (
                        <LoadingButton
                          style={{ marginLeft: "10px" }}
                          variant="contained"
                          sx={{
                            mt: 1,
                            mb: 2,
                            backgroundColor: theme.palette.error.main
                          }}
                          onClick={() => handleCheckDelete(val)}
                          loading={loadingDeleteCheckList}
                        >
                          Delete
                        </LoadingButton>
                      ) : (
                        <></>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              )
            })}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  )
}
export default DialogDeletePMChecklist
