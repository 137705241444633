import { useTheme } from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useRef } from "react"
import { getProcessFilterRumTimeMachine } from "../../../common/utilities"
import { IDataMachine } from "../../Maintenance/KPIMaintenance"
import { ITargetKpi } from "../../Maintenance/component/MTTR"
import { monthNames } from "../../Maintenance/PreventiveMaintenancePlan"
import { IRuntimeMachine, IPress } from "../hook/useFetchData"

const machineRunTime = (
  type = "",
  filterMachineOfMonth: IRuntimeMachine[]
): number => {
  const machineRunTime = getProcessFilterRumTimeMachine(
    type,
    filterMachineOfMonth
  )
  return Number(
    machineRunTime
      ?.reduce((acc: number, obj: IDataMachine) => {
        return acc + obj.totalTime
      }, 0)
      .toFixed(2)
  )
}
const total = (
  lineData: IPress[],
  i: number,
  rowsMachineRuntime: IRuntimeMachine[],
  type = ""
) => {
  const millisecond = 1000
  const second = 60
  const minute = 60
  const filterMachineOfMonth = rowsMachineRuntime.filter(
    (e: IDataMachine) => new Date(e.planDate).getMonth() === i
  )
  const lineMachineRunTime = machineRunTime(type, filterMachineOfMonth)

  if (!lineData)
    return {
      dataOfMonth: 0,
      totalTimeOfMonth: Math.ceil(lineMachineRunTime * minute),
      arc: 0
    }
  const dataOfMonth = lineData.filter(
    (e) => new Date(e?.startRepair || new Date()).getMonth() === i
  )
  if (dataOfMonth.length) {
    const totalTimeOfMonth = dataOfMonth
      .map((data) => {
        const diff = Math.abs(
          Number(new Date(data?.completeDate || new Date())) -
            Number(new Date(data?.endWaitRepair || new Date()))
        )
        const actualTime = diff / millisecond / second / minute
        return actualTime
      })
      .reduce((prev: number, cur: number) => {
        return prev + cur
      }, 0)

    const arc = lineMachineRunTime ? totalTimeOfMonth / lineMachineRunTime : 0
    return {
      dataOfMonth: Math.ceil(totalTimeOfMonth * minute),
      totalTimeOfMonth: Math.ceil(lineMachineRunTime * minute),
      arc: Number(arc.toFixed(3))
    }
  } else {
    return {
      dataOfMonth: 0,
      totalTimeOfMonth: Math.ceil(lineMachineRunTime) * minute,
      arc: 0
    }
  }
}
export const BMDashboard = (props: {
  chart?: HighchartsReact.Props
  lineData: IPress[]
  type: string
  setShowChart?: React.Dispatch<React.SetStateAction<boolean>>
  rowsMachineRuntime: IRuntimeMachine[]

  target: ITargetKpi
}): JSX.Element => {
  const { lineData, target, type, rowsMachineRuntime } = props
  const theme = useTheme()
  if (!lineData.length) return <>Loading...</>
  const actual = monthNames.map((e: string, i: number) => {
    return total(lineData, i, rowsMachineRuntime, type).arc * 100
  })
  const columnOptions: Highcharts.Options = {
    chart: {
      type: "column",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "เวลาสูญเสียที่เกิดจากเครื่องจักร (BM)",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },

    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    },
    xAxis: {
      categories: monthNames,
      labels: {
        x: -10,
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "10px"
        }
      }
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "Total",
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      }
    },
    series: [
      {
        type: "column",
        name: "Target",
        data: monthNames.map(() => Number(target?.target))
      },
      {
        type: "column",
        name: "Actual",
        data: actual
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  return (
    <>
      <div style={{ display: "inline-block" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={columnOptions}
          ref={chartComponentRef}
          {...props.chart}
        />
      </div>
    </>
  )
}
