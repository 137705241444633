import moment from "moment"
import { useEffect, useState } from "react"
import swal from "sweetalert"

interface IDataTable {
  date: string
  in: IMatStock
  out: IMatStock
  total: number
}

interface IDictionaryMat {
  [id: string]: IMatStock[]
}

interface IMatStock {
  matNo: string
  inOrOut: string
  quantity: number
  factory: number
  packingBy: string
  jobOrder: string
  remarks: string
  isNG: number
  invoiceNo: string
  deliveryDate: Date
  dateScan: Date
  smQty: number
  supplier: string
  date: string
  total: number
  actionDate: string
}

const currentSum = (matStocks: IMatStock[]) => {
  const dailyStock = matStocks.map((mat) => Number(mat.inOrOut) * mat.quantity)
  const dailySum = []
  for (let i = 0; i < dailyStock.length; i++) {
    dailySum.push(
      dailyStock.slice(0, i + 1).reduce((p: number, v: number) => {
        const curSum = p + v
        if (curSum < 0) {
          swal(
            "WARNING: จำนวนสินค้าติดลบ มีข้อบกพร่องใน Database",
            "",
            "warning"
          )
        }
        return curSum
      })
    )
  }
  return dailySum
}

const getRowDates = (date: string, data: IMatStock[]) => {
  const fgIn = data.filter((entry) => entry.inOrOut === "In")
  const fgOut = data.filter((entry) => entry.inOrOut === "Out")
  const rows = []
  const length = Math.max(fgIn.length, fgOut.length)
  const diff = Math.max(0, fgIn.length - fgOut.length)
  for (let i = 0; i < length; i++) {
    const row: IDataTable = {
      date: date,
      total: fgOut[i - diff] ? fgOut[i - diff].total : fgIn[i].total,
      in: fgIn[i],
      out: fgOut[i - diff]
    }
    if (i === 0) row.date = date
    if (i >= diff && fgOut[i - diff]) row.out = fgOut[i - diff]
    if (fgIn[i]) row.in = fgIn[i]
    if (i === length - 1) {
      if (i >= diff && fgOut[i - diff]) row.total = fgOut[i - diff].total
      else if (fgIn[i]) row.total = fgIn[i].total
    }
    rows.push(row)
  }
  return rows
}

export const useParsedMatData = (
  matStocks: IMatStock[],
  dateFrom: string,
  dateTo: string,
  jobOrder: string | null
): IDataTable[] => {
  const [formattedData, setFormattedData] = useState<IDataTable[]>([])

  useEffect(() => {
    if (matStocks) {
      let newData: IMatStock[] = matStocks.map(
        (data: IMatStock, index: number) => {
          const { actionDate, inOrOut, isNG, ...oldData } = data
          const date = moment(actionDate).format("DD-MM-YYYY")
          const newInOrOut = Number(inOrOut) === 1 ? "In" : "Out"
          const updatedData = {
            ...oldData,
            actionDate,
            date,
            inOrOut: newInOrOut,
            total: currentSum(matStocks)[index],
            isNG: +(isNG === 1)
          }
          return updatedData
        }
      )
      if (dateFrom !== "") {
        newData = newData.filter((val) => {
          const newDateFrom = new Date(dateFrom)
          newDateFrom.setHours(0, 0, 0, 0)
          const curDate = moment(val.date, "DD-MM-YYYY").toDate()
          return curDate.getTime() >= newDateFrom.getTime()
        })
      }
      if (dateTo !== "") {
        newData = newData.filter((val) => {
          const newDateTo = new Date(dateTo)
          newDateTo.setHours(0, 0, 0, 0)
          const curDate = moment(val.date, "DD-MM-YYYY").toDate()
          return curDate.getTime() <= newDateTo.getTime()
        })
      }
      if (jobOrder && jobOrder !== "") {
        newData = newData.filter((val) => val.jobOrder === jobOrder)
      }

      const matData: IDictionaryMat = {}
      const dates: string[] = []
      if (!matStocks) swal("error", "ไม่พบข้อมูล", "error")
      for (let i = 0; i < newData.length; i++) {
        const date = newData[i].date
        if (!matData[date]) matData[date] = [newData[i]]
        else matData[date].push(newData[i])
        if (!dates.includes(date)) dates.push(date)
      }
      let rows: IDataTable[] = []
      for (let i = 0; i < dates.length; i++) {
        const curDate = dates[i]
        const dateRows = getRowDates(curDate, matData[curDate])
        rows = rows.concat(dateRows)
      }
      setFormattedData(rows)
    }
  }, [matStocks, dateFrom, dateTo, jobOrder])

  return formattedData
}
