import { Tabs, TabsProps } from "@mui/material"
import { styled } from "@mui/material/styles"

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTab-root.Mui-selected": {
    color:
      theme.palette.mode === "light"
        ? theme.palette.primary.main
        : theme.palette.warning.main
  },
  "& .MuiTabs-indicator": {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.primary.main
        : theme.palette.warning.main
  }
}))

const ThemedTabs = (props: TabsProps): JSX.Element => {
  const themedTabs = <StyledTabs {...props} />
  return <>{themedTabs}</>
}

export default ThemedTabs
