import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  styled,
  Grid,
  Autocomplete,
  useTheme,
  ThemeProvider
} from "@mui/material"
import { grey } from "@mui/material/colors"
import { useState } from "react"
import { authorizationQC } from "../../common/Query/authorizationProdQuery"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import moment from "moment"
import AddIcon from "@mui/icons-material/Add"
import { Authorization } from "../../common/Mutation/AuthorizationMutation"
import swal from "sweetalert"
import { IIndxData } from "./AuthorizationProduction"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { factoryList, formatNumber } from "../../common/utilities"
import { IDataEmployee } from "../Scanner/ApproveEditScanner"
import { GET_EMPLOYEE } from "../../common/Query/MasterQuery"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"

interface Column {
  id:
    | "date"
    | "partNo"
    | "machine"
    | "machine"
    | "process"
    | "jobOrder"
    | "pdLotNo"
    | "componentLot"
    | "line"
    | "iNG"
    | "sNG"
    | "tNG"
    | "supplierNG"
    | "totalCost"
    | "remarks"
    | "quantity"
    | "operator"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}
const columns: Column[] = [
  { id: "date", label: "date", minWidth: 100 },
  { id: "partNo", label: "partNo", minWidth: 100 },
  { id: "machine", label: "machine", minWidth: 100 },
  { id: "process", label: "process", minWidth: 150 },
  { id: "jobOrder", label: "jobOrder", minWidth: 250 },
  { id: "pdLotNo", label: "pdLotNo", minWidth: 150 },
  { id: "componentLot", label: "componentLot", minWidth: 50 },
  { id: "line", label: "line", minWidth: 100 },
  { id: "quantity", label: "quantity", minWidth: 50 },
  { id: "iNG", label: "iNG", minWidth: 50 },
  { id: "sNG", label: "sNG", minWidth: 50 },
  { id: "tNG", label: "tNG", minWidth: 50 },
  { id: "supplierNG", label: "supplierNG", minWidth: 50 },
  { id: "operator", label: "operator", minWidth: 50 },
  { id: "totalCost", label: "totalCost", minWidth: 50 },
  { id: "remarks", label: "remarks", minWidth: 100 }
]

interface Data {
  date: string
  partNo: string
  machine: string
  process: string
  jobOrder: string
  pdLotNo: string
  componentLot: string
  line: string
  quantity: number
  iNG: number
  sNG: number
  tNG: number
  supplierNG: number
  operator: string
  totalCost: number
  remarks: string
}

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[100]};
    font-weight:bold
  }
  `
)
const pageTitle = "QC"

const AuthorizationProduction: React.FC = () => {
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [factory, setFactory] = useState<string>("1")
  const [name, setName] = useState<string>("")

  const [getAuthorizationQC, { data, loading }] = useLazyQuery(authorizationQC)
  const [authorization] = useMutation(Authorization)

  let employeeList: IDataEmployee[] = []
  const getEmployee = useQuery(GET_EMPLOYEE)
  if (getEmployee.data) {
    employeeList = getEmployee.data.employee.filter(
      ({ position }: { position: string }) =>
        position === "Manager" || position === "Leader" || position === "FM"
    )
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const rows: Data[] = data ? data.qcReport : []

  const getProcessFilter = (type: string) => {
    if (type === "stamp") {
      return data.qcReport.filter((datum: { process: string | string[] }) =>
        datum.process.includes("stamp")
      )
    } else if (type === "weld") {
      return data.qcReport.filter((datum: { process: string | string[] }) =>
        datum.process.includes("SPOT")
      )
    } else if (type === "arcweld") {
      return data.qcReport.filter((datum: { process: string | string[] }) =>
        datum.process.includes("ARC WELDING")
      )
    } else if (type === "plateNG") {
      return data.qcReport.filter((datum: { process: string | string[] }) =>
        datum.process.includes("PLATING")
      )
    } else {
      return data.qcReport
    }
  }
  const ok = (type: string) => {
    if (data) {
      const partData = getProcessFilter(type)
      return partData.reduce(function (acc: number, obj: { quantity: string }) {
        return acc + parseInt(obj.quantity)
      }, 0)
    }
  }
  const checkNG = (type: string, name?: string) => {
    if (data) {
      const partData = getProcessFilter(type)
      if (name === "iNG") {
        return partData.reduce(function (acc: number, obj: { iNG: number }) {
          return acc + obj.iNG
        }, 0)
      } else if (name === "sNG") {
        return partData.reduce(function (acc: number, obj: { sNG: number }) {
          return acc + obj.sNG
        }, 0)
      } else if (name === "tNG") {
        return partData.reduce(function (acc: number, obj: { tNG: number }) {
          return acc + obj.tNG
        }, 0)
      }
    }
  }

  const costOFPoor = (type?: string) => {
    if (data) {
      const partData = getProcessFilter(type || "")
      return partData.reduce((acc: number, index: IIndxData) => {
        return acc + getCostPoor(index)
      }, 0)
    }
    return 0
  }

  const total = (type?: string) => {
    if (data) {
      const partData = getProcessFilter(type || "")
      return partData.reduce((acc: number, index: IIndxData) => {
        return acc + getCost(index)
      }, 0)
    }
    return 0
  }
  const getCost = (index: IIndxData) => {
    if (data) {
      const total = index.sNG + index.iNG + index.tNG
      const totalCost = index.cost * total
      return Number(totalCost.toFixed(2))
    }
    return 0
  }
  const getCostPoor = (index: IIndxData) => {
    if (data) {
      const poor = index.iNG
      const totalPoor = index.cost * poor
      return Number(totalPoor.toFixed(2))
    }
    return 0
  }
  const ppm = (type: string) => {
    if (data) {
      const iNG: number = checkNG(type, "iNG")
      return Math.round((1000000 * iNG) / (ok(type) + iNG)) || 0
    }
  }
  const handleSubmit = () => {
    getAuthorizationQC({
      variables: {
        date,
        factory
      }
    })
    if (data?.qcReport.length === 0) alert("ไม่พบข้อมูล")
  }
  const handleAuthorize = async () => {
    try {
      const result = await authorization({
        variables: { date, factory, name, authorizeName: "QC" }
      })
      if (result?.data?.authorization === "Authorization QC") {
        swal("Success", "Authorization QC", "success")
      } else {
        swal("Unsuccess", "Can't Authorization QC", "error")
      }
    } catch (error) {
      swal("Unsuccess", `${error}`, "error")
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Authorization}
        menuItemName={Object.keys(menuItemList)[9]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="Daily Report"
          subTitle="detail about daily report"
        />
        <Grid container spacing={2} width={"98%"} padding={2}>
          <Grid item xs={12} sm={3}>
            <ThemedTextField
              fullWidth
              value={date}
              label="Select Date"
              id="date"
              type="date"
              autoComplete="family-name"
              onChange={(e) => setDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              value={factory ? factory : ""}
              options={factoryList}
              disableClearable
              disablePortal
              onChange={(e, value) => setFactory(value)}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Factory"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ThemedLoadingButton
              loading={loading}
              variant="contained"
              style={{
                width: "120px",
                height: "36px",
                backgroundColor: theme.palette.primary.main
              }}
              sx={{ mt: 1, mb: 1 }}
              onClick={handleSubmit}
              endIcon={<AddIcon />}
            >
              Submit
            </ThemedLoadingButton>
          </Grid>
        </Grid>
      </ThemedCard>
      <ThemedCard>
        <Root sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    style={{ fontWeight: "bold", fontSize: "24px" }}
                  >
                    QC Report
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    _______________<br></br>
                    ผู้อนุมัติ
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    _______________<br></br>
                    ผู้ตรวจสอบ
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ top: 57, minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                        {columns.map((column) => {
                          const value = row[column.id]
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Root>

        <Table style={{ margin: "15px", width: "98%" }}>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  fontSize: "16px",
                  fontFamily: "Sarabun",
                  fontWeight: "bold"
                }}
              >
                Stamping
              </TableCell>
              <TableCell>OK: {ok("stamp")}</TableCell>
              <TableCell>i-NG: {checkNG("stampNG", "iNG")}</TableCell>
              <TableCell>s-NG: {checkNG("stampNG", "sNG")}</TableCell>
              <TableCell>t-NG: {checkNG("stampNG", "tNG")}</TableCell>
              <TableCell>Cost of Poor: {costOFPoor("stampNG")}</TableCell>
              <TableCell>Total Cost: ฿ {total("stampNG")}</TableCell>
              <TableCell>PPM: {ppm("stamp")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  fontSize: "16px",
                  fontFamily: "Sarabun",
                  fontWeight: "bold"
                }}
              >
                Welding
              </TableCell>
              <TableCell>OK: {ok("weld")}</TableCell>
              <TableCell>i-NG: {checkNG("weld", "iNG")}</TableCell>
              <TableCell>s-NG: {checkNG("weld", "sNG")}</TableCell>
              <TableCell>t-NG: {checkNG("weld", "tNG")}</TableCell>
              <TableCell>Cost of Poor: {costOFPoor("weld")}</TableCell>
              <TableCell>Total Cost: ฿ {total("weld")}</TableCell>
              <TableCell>PPM: {ppm("weld")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  fontSize: "16px",
                  fontFamily: "Sarabun",
                  fontWeight: "bold"
                }}
              >
                Arc Welding
              </TableCell>
              <TableCell>OK: {ok("arcweld")}</TableCell>
              <TableCell>i-NG: {checkNG("arcweld", "iNG")}</TableCell>
              <TableCell>s-NG: {checkNG("arcweld", "sNG")}</TableCell>
              <TableCell>t-NG: {checkNG("arcweld", "tNG")}</TableCell>
              <TableCell>Cost of Poor: {costOFPoor("arcweld")}</TableCell>
              <TableCell>Total Cost: ฿ {total("arcweld")}</TableCell>
              <TableCell>PPM: {ppm("arcweld")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  fontSize: "16px",
                  fontFamily: "Sarabun",
                  fontWeight: "bold"
                }}
              >
                Plating
              </TableCell>
              <TableCell>OK: {ok("plateNG")}</TableCell>
              <TableCell>i-NG: {checkNG("plateNG", "iNG")}</TableCell>
              <TableCell>s-NG: {checkNG("plateNG", "sNG")}</TableCell>
              <TableCell>t-NG: {checkNG("plateNG", "tNG")}</TableCell>
              <TableCell>Cost of Poor: {costOFPoor("plateNG")}</TableCell>
              <TableCell>Total Cost: ฿ {total("plateNG")}</TableCell>
              <TableCell>PPM: {ppm("plateNG")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  fontSize: "16px",
                  fontFamily: "Sarabun",
                  fontWeight: "bold"
                }}
              >
                Total
              </TableCell>
              <TableCell>OK: {ok("")}</TableCell>
              <TableCell>i-NG: {checkNG("")}</TableCell>
              <TableCell>s-NG: {checkNG("")}</TableCell>
              <TableCell>t-NG: {checkNG("")}</TableCell>
              <TableCell>Cost of Poor: {costOFPoor()}</TableCell>
              <TableCell>
                Total Ng Cost: ฿{" "}
                {formatNumber(
                  total("stamp") +
                    total("weld") +
                    total("arcWeld") +
                    total("plating")
                )}
              </TableCell>
              <TableCell>PPM: {ppm("")}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Grid container spacing={2} width={"98%"} padding={2}>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              disablePortal
              value={name}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Approver By"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(e, newValue) => setName(newValue ? newValue : "")}
              options={employeeList.map(
                ({ firstName }: { firstName: string }) => firstName
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ThemedLoadingButton
              loading={loading}
              style={{
                width: "120px",
                height: "36px",
                backgroundColor: theme.palette.primary.main
              }}
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
              onClick={handleAuthorize}
            >
              Authorize
            </ThemedLoadingButton>
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default AuthorizationProduction
