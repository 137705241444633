import { Dialog, DialogContent, CircularProgress } from "@mui/material"

interface IProp {
  loading: boolean
}
export const DialogCircularProgress: React.FC<IProp> = (props: IProp) => {
  const { loading } = props
  return (
    <Dialog open={loading}>
      <DialogContent style={{ width: "auto" }}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )
}
