import { useTheme } from "@mui/material"
import "../css/Dashboard/Dashboard.css"

const Footer: React.FC = () => {
  const theme = useTheme()

  return (
    <footer
      className="display-none-print"
      style={{
        whiteSpace: "nowrap",
        position: "inherit",
        flexShrink: 0,
        width: "100%",
        padding: "20px",
        height: "20px",
        color: theme.palette.secondary.contrastText,
        textAlign: "center",
        bottom: 0,
        left: 0
      }}
    >
      Bunnag Industrial Technologies And Software CO.,LTD @ 2022
    </footer>
  )
}

export default Footer
