import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ThemeProvider,
  Tooltip,
  styled,
  tableCellClasses
} from "@mui/material"
import { bankImage, getImagePng } from "../../common/utilities"
import { useEffect, useRef, useState } from "react"
import { user } from "../../common/MainApp"
import { useQuery } from "@apollo/client"
import { GET_DATA_IOT } from "../../common/Query/Iot"
import moment from "moment"
import { IDictionaryPartName } from "../Planning/component/ProblemTime"
import { calculateTime } from "../Planning/ProductionPlan"
import { problemNameList } from "../Planning/component/DialogProblem"
import { useTheme } from "@emotion/react"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "15px",
    border: "solid gray",
    color: "white",
    borderWidth: "2px",
    padding: "0px",
    whiteSpace: "nowrap"
  }
}))

interface IProductionPlan {
  planDate: string
  startTime: string
  machine: string
  endTime: string
  startTimeOT: string
  endTineOT: string
  planNo: string
  revision: string
  pdPlan: string
  factory: string
  shift: string
  partNo: string
  partName: string
  customer: string
  line: string
  section: string
  step: string
  process: string
  jph: number
  endTimeOT: string
  totalHour: number
  totalQuantity: number
  employee: string
  plApprove: string
  pdApprove: string
  engApprove: string
  qaApprove: string
  mtApprove: string
  remarks: string
  productivity: number
  rateQ: number
  performance: number
  oee: number
  status: boolean
  quantity: number
  iNG: number
  sNG: number
  productionWorker: string
  actualQuantity: number
  problem: IProblem[]
}

interface IProblem {
  machine: string
  process: string
  problemName: string
  startTime: string
  actionDate: string
  endTime: string
  totalTime: number
}

interface ISocket {
  machine: string
  quantity: number
}

interface IReturnOee {
  oee: number
}

interface IDataProblem {
  id?: number
  machine?: string
  partNo?: string
  process?: string
  actionDate?: string
  startTime?: string
  endTime?: string
  totalTime: number
  problemName: string
  remarks?: string
  department?: string
  line?: string
}

const URL =
  "wss://7n36pi5gwj.execute-api.ap-southeast-1.amazonaws.com/production/"
const IotQuantity = (): JSX.Element => {
  const [planData, setPlanData] = useState<IProductionPlan[]>([])
  const [dataMessage, setDataMessage] = useState<ISocket>({} as ISocket)
  const [dateState, setDateState] = useState(new Date())
  const theme = useTheme()
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 1000)
  }, [])
  const currentTime = dateState.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false
  })
  const { data } = useQuery(GET_DATA_IOT, {
    variables: { planDate: moment(new Date()).format("YYYY-MM-DD") }
  })

  const socket = useRef<WebSocket | null>(null)
  const onSocketMessage = (data: string) => {
    const result = JSON.parse(data)
    if (result.message) {
      setDataMessage(result.message)
    }
  }

  const findMachineRun = (plan: IProductionPlan) => {
    const startDateFormat = new Date(`${plan.planDate} ${plan.startTime}:00`)
    const endDateFormat = new Date(`${plan.planDate} ${plan.endTime}:00`)
    const startDateFormatOT = new Date(
      `${plan.planDate} ${plan.startTimeOT}:00`
    )
    const endDateFormatOT = new Date(`${plan.planDate} ${plan.endTimeOT}:00`)
    return (
      plan.machine === dataMessage.machine &&
      (startDateFormat <= new Date() || startDateFormatOT <= new Date()) &&
      (endDateFormat >= new Date() || endDateFormatOT >= new Date())
    )
  }

  const calculateOEE = (
    problemTime: IDataProblem[],
    productionPlan: IProductionPlan,
    actual: number,
    ng: number
  ): IReturnOee => {
    const getHour = getTotalHur(productionPlan)
    const totalHour = getHour.total
    const totalQuantity = getHour.target

    const other = Math.round(
      (problemTime.find((problem) => problem.problemName === "other")
        ?.totalTime || 0) * 60
    )
    const qc = Math.round(
      (problemTime.find(
        (problem) => problem.problemName === "Wait QC First Set Up"
      )?.totalTime || 0) * 60
    )
    const die = Math.round(
      (problemTime.find((problem) => problem.problemName === "DIE Breakdown")
        ?.totalTime || 0) * 60
    )
    const pm = Math.round(
      (problemTime.find((problem) => problem.problemName === "PM")?.totalTime ||
        0) * 60
    )
    const mcBreakdown = Math.round(
      (problemTime.find(
        (problem) => problem.problemName === "Machine Breakdown"
      )?.totalTime || 0) * 60
    )
    const lostTime = (other + qc + die + mcBreakdown) / 60
    const workloadTime = totalHour - pm / 60
    const machineRunTime = workloadTime - lostTime

    const productivity = machineRunTime / workloadTime
    const performance = totalQuantity !== 0 ? (actual | 0) / totalQuantity : 0
    const rateQ = actual ? (actual - ng) / actual : 0

    const oee = productivity * performance * rateQ * 100

    return {
      oee: oee || oee > 0 ? Number(oee.toFixed(2)) : 0.0
    }
  }

  const onSocketOpen = () => {
    setTimeout(() => {
      socket.current?.send(
        JSON.stringify({
          action: "sendMessage",
          name: user.username + Math.random().toString(36).substring(7)
        })
      )
    }, 1000)
  }

  const conditionEndTime = (
    currentTime: string,
    endTime: string,
    date?: string
  ) => {
    return Number(
      calculateTime(
        currentTime > endTime &&
          date !== moment(new Date()).format("YYYY-MM-DD") &&
          endTime !== "00:00"
          ? endTime
          : currentTime
      )
    )
  }

  const getTotalHur = (plan: IProductionPlan) => {
    let total = 0
    const nowDate: string = moment(new Date()).format("YYYY-MM-DD").toString()
    const nowTime: string = moment(new Date()).format("HH:mm").toString()
    const endTimeNow = conditionEndTime(
      currentTime,
      plan.endTime,
      plan.planDate
    )
    const startTime = Number(calculateTime(plan.startTime))
    const endTime = Number(calculateTime(plan.endTime))
    const endTimeOT = Number(calculateTime(plan.endTimeOT))
    const startTimeOT = Number(calculateTime(plan.startTimeOT))

    if (plan.planDate !== nowDate) {
      if (endTime > 12 && startTime < 13) {
        total += endTime - startTime - 1 + endTimeOT - startTimeOT
      } else if (endTime <= 12 && startTime <= 12) {
        total += endTime - startTime + endTimeOT - startTimeOT
      } else if (startTime >= 13) {
        total += endTime - startTime + endTimeOT - startTimeOT
      } else {
        total += 0
      }
    } else {
      if (
        Number(calculateTime(nowTime)) <= 12 &&
        startTime < 13 &&
        startTime !== 0
      ) {
        if (endTime <= Number(calculateTime(nowTime)))
          total += endTime - startTime
        else total += endTimeNow - startTime
      } else if (
        Number(calculateTime(nowTime)) >= 13 &&
        Number(calculateTime(nowTime)) <= 17
      ) {
        if (
          (startTime <= 12 && startTime !== 0 && endTime <= 12) ||
          (startTime >= 13 &&
            startTime !== 0 &&
            Number(calculateTime(nowTime)) > startTime)
        ) {
          if (endTime <= Number(calculateTime(nowTime)))
            total += endTime - startTime
          else total += endTimeNow - startTime
        } else if (startTime <= 12 && startTime !== 0 && endTime >= 13) {
          if (endTime <= Number(calculateTime(nowTime)))
            total += endTime - startTime - 1
          else total += endTimeNow - startTime - 1
        } else {
          total += 0
        }
      } else if (Number(calculateTime(nowTime)) >= 17.5) {
        if (startTimeOT !== 0) {
          if (
            (startTime <= 12 && endTime <= 12) ||
            (startTime >= 13 && Number(calculateTime(nowTime)) > startTimeOT)
          ) {
            total += endTime - startTime + endTimeNow - startTimeOT
          } else if (startTime <= 12 && endTime >= 13) {
            total += endTime - startTime + endTimeNow - startTimeOT - 1
          } else {
            total += 0
          }
        } else {
          if (
            (startTime <= 12 && startTime !== 0 && endTime <= 12) ||
            (startTime >= 13 && startTime !== 0)
          ) {
            total += endTime - startTime
          } else if (startTime <= 12 && startTime !== 0 && endTime >= 13) {
            total += endTime - startTime - 1
          }
        }
      } else if (
        Number(calculateTime(nowTime)) > 12 &&
        Number(calculateTime(nowTime)) < 13
      ) {
        if (endTime !== 0 && endTime <= 12) {
          total += endTime - startTime
        } else if (startTime <= 12 && startTime !== 0 && endTime >= 13) {
          total += endTimeNow - startTime - (endTimeNow - 12)
        } else {
          total += 0
        }
      } else if (
        Number(calculateTime(nowTime)) > 17 &&
        Number(calculateTime(nowTime)) < 17.5
      ) {
        if ((endTime !== 0 && endTime <= 12) || startTime >= 13) {
          total += endTime - startTime
        } else if (startTime <= 12 && startTime !== 0 && endTime >= 13) {
          total += endTime - startTime - 1
        }
      } else {
        total += 0
      }
    }
    const target =
      total * plan.jph > plan.totalQuantity || plan.planDate !== nowDate
        ? plan.totalQuantity
        : total * (plan.jph || 0)
    return {
      total,
      target
    }
  }

  useEffect(() => {
    socket.current = new WebSocket(URL)
    socket.current?.addEventListener("open", onSocketOpen)
    socket.current?.addEventListener("message", (event) => {
      onSocketMessage(event.data)
    })
  }, [])
  useEffect(() => {
    if (data) {
      setPlanData(data.getIotProduction)
    }
  }, [data])

  const getProblemReport = (plan: IProductionPlan): IDictionaryPartName => {
    const part: IDictionaryPartName = {
      "Machine Breakdown": { totalTime: 0, problemNameList: [] },
      "Wait QC First Set Up": { totalTime: 0, problemNameList: [] },
      other: { totalTime: 0, problemNameList: [] },
      PM: { totalTime: 0, problemNameList: [] },
      "DIE Breakdown": { totalTime: 0, problemNameList: [] },
      totalStopMachine: { totalTime: 0, problemNameList: [] }
    }

    if (plan.problem.length > 0) {
      const problem = plan.problem

      problemNameList.forEach((element: { value: string }) => {
        let total = 0
        const problemName: string[] = []
        problem.forEach((entry: IDataProblem) => {
          if (element.value === entry.problemName) {
            total += entry.totalTime
            problemName.push(
              entry.remarks +
                " เป็นเวลา " +
                Math.round(entry.totalTime * 60) +
                " min"
            )
          }
        })
        part[element.value].totalTime = total
        part[element.value].problemNameList = problemName
        part["totalStopMachine"].totalTime += total
      })
    }

    return { ...part }
  }

  const checkStatus = (plan: IProductionPlan) => {
    if (plan.problem.length > 0) {
      const problemData = plan.problem
      const nowTime: string = moment(new Date()).format("HH:mm").toString()
      const nowDate: string = moment(new Date()).format("YYYY-MM-DD").toString()
      if (problemData.length > 0) {
        const checkProblem = problemData.filter((ele) => {
          if (
            ele?.startTime <= nowTime &&
            ele?.endTime >= nowTime &&
            nowDate === ele.actionDate
          ) {
            return true
          }
          return false
        })
        if (checkProblem.length > 0) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <PageLabel
          menuItem={menuItemList.Iot}
          menuItemName={Object.keys(menuItemList)[17]}
          menuPageName={"Iot Quantity"}
        />
        <ThemedCard>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyItems="center"
            ml={2}
            p={2}
          >
            <Grid item xs={12} sm={12}>
              <h1>IOT Quantity</h1>
            </Grid>
            {planData.map((item, index) => {
              return (
                <Grid
                  item
                  sm={4}
                  style={{ minWidth: "500px" }}
                  key={item.machine + item.partNo + String(index)}
                >
                  <div>
                    <Table
                      style={{
                        width: "100%",
                        marginLeft: "15px",
                        backgroundColor: "black",
                        zoom: "80%"
                      }}
                    >
                      <TableBody style={{ border: "solid 8px black" }}>
                        <TableRow>
                          <StyledTableCell
                            colSpan={2}
                            style={{ color: "yellow" }}
                          >
                            <label
                              style={{ color: "yellow", fontSize: "16px" }}
                            >
                              Machine:
                            </label>
                            <label style={{ color: "white", fontSize: "16px" }}>
                              {item.machine}
                            </label>
                            <label style={{ color: "green", fontSize: "16px" }}>
                              (
                              {item.remarks === "Try Out"
                                ? "Try Out"
                                : item.pdPlan
                                ? item.pdPlan
                                : "ไม่มีในแผน"}
                              )
                            </label>
                            <br />
                            <label
                              style={{ color: "yellow", fontSize: "16px" }}
                            >
                              Step:
                            </label>
                            <label style={{ color: "white", fontSize: "16px" }}>
                              {item.step}
                            </label>
                            <label
                              style={{ color: "yellow", fontSize: "16px" }}
                            >
                              JPH:
                            </label>
                            <label style={{ color: "white", fontSize: "16px" }}>
                              {item.jph}
                            </label>
                            <br />
                            <label
                              style={{ color: "yellow", fontSize: "16px" }}
                            >
                              Process:
                            </label>
                            <label style={{ color: "white", fontSize: "16px" }}>
                              {item.process}
                            </label>
                            <br />
                            <label
                              style={{ color: "yellow", fontSize: "16px" }}
                            >
                              Part No:
                            </label>
                            <label style={{ color: "white", fontSize: "16px" }}>
                              {item.partNo}
                            </label>
                            <br />
                            <label
                              style={{ color: "yellow", fontSize: "16px" }}
                            >
                              Part Name:
                            </label>
                            <label style={{ color: "white", fontSize: "16px" }}>
                              {item.partName}
                            </label>
                          </StyledTableCell>
                          <StyledTableCell>
                            Date: {item.planDate}
                            <br />
                            Line: {item.line}
                            <br />
                            Shift:{" "}
                            <span
                              style={{
                                color:
                                  item.shift === "Day" ? "#ffcc00" : "#00ffff"
                              }}
                            >
                              {item.shift}
                            </span>
                            <br />
                            <img
                              src={getImagePng(`images/parts/${item.partNo}`)}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null
                                currentTarget.src = bankImage
                              }}
                              alt=""
                              width={"100px"}
                              height={"60"}
                              style={{ paddingLeft: "30px" }}
                            />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell colSpan={2} align="center">
                            <label
                              style={{
                                fontSize: "15px",
                                margin: "1px",
                                fontWeight: "bold"
                              }}
                            >
                              Production Result
                            </label>
                          </StyledTableCell>
                          <StyledTableCell colSpan={1}>
                            <label
                              style={{
                                color: "#ff55ff",
                                fontSize: "16px",
                                margin: "3px"
                              }}
                            >
                              Target Plan:
                            </label>
                            {item.totalQuantity}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <label
                              style={{
                                color: "yellow",
                                fontSize: "16px",
                                margin: "3px"
                              }}
                            >
                              Time:
                            </label>
                            <label style={{ fontSize: "12px" }}>
                              {item.startTime} - {item.endTime}
                            </label>
                          </StyledTableCell>
                          <StyledTableCell>
                            <label
                              style={{
                                color: "yellow",
                                fontSize: "16px",
                                margin: "3px"
                              }}
                            >
                              OT:
                            </label>

                            <label style={{ fontSize: "12px" }}>
                              {item.startTimeOT} - {item.endTimeOT}
                            </label>
                          </StyledTableCell>
                          <StyledTableCell>
                            <label
                              style={{
                                color: "#ff55ff",
                                fontSize: "16px",
                                margin: "3px"
                              }}
                            >
                              Current Target :
                            </label>
                            {Math.ceil(getTotalHur(item).target) < 0
                              ? 0
                              : Math.ceil(getTotalHur(item).target) || 0}{" "}
                            PCs.
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell align="center">
                            Status
                          </StyledTableCell>
                          <StyledTableCell>
                            <label
                              style={{
                                color: !checkStatus(item) ? "#55ff55" : "red",
                                fontSize: "16px"
                              }}
                            >
                              {!checkStatus(item) ? "Running" : "Stop"}
                            </label>
                          </StyledTableCell>
                          <StyledTableCell>
                            <label
                              style={{
                                color: "#ff55ff",
                                fontSize: "16px",
                                margin: "3px"
                              }}
                            >
                              Actual:
                            </label>
                            {findMachineRun(item)
                              ? dataMessage.quantity
                              : item.actualQuantity}{" "}
                            Pcs.
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell align="center">
                            Current Time
                          </StyledTableCell>
                          <StyledTableCell>
                            <label
                              style={{ color: "#55ff55", fontSize: "16px" }}
                            >
                              <>
                                <span>{`${Math.floor(
                                  getTotalHur(item).total > 0
                                    ? getTotalHur(item).total
                                    : 0
                                )} ชม.  ${Math.round(
                                  getTotalHur(item).total > 0
                                    ? (getTotalHur(item).total % 1) * 60
                                    : 0
                                )} นาที`}</span>
                              </>
                            </label>
                          </StyledTableCell>
                          <StyledTableCell>
                            <label
                              style={{
                                color: "#ff55ff",
                                fontSize: "16px",
                                margin: "3px"
                              }}
                            >
                              Diff:
                            </label>
                            <label
                              style={
                                (getTotalHur(item).target ?? 0) -
                                  (findMachineRun(item)
                                    ? dataMessage.quantity
                                    : item.actualQuantity) >
                                0
                                  ? {
                                      color: "red",
                                      fontSize: "16px",
                                      margin: "3px"
                                    }
                                  : {
                                      color: "white",
                                      fontSize: "16px",
                                      margin: "3px"
                                    }
                              }
                            >
                              {(findMachineRun(item)
                                ? dataMessage.quantity
                                : item.actualQuantity) -
                                (getTotalHur(item).target > 0
                                  ? Math.ceil(getTotalHur(item).target)
                                  : 0) ?? 0}
                              Pcs.
                            </label>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell colSpan={2} align="center">
                            Stop Machine
                          </StyledTableCell>
                          <StyledTableCell>
                            <label
                              style={{
                                color: "#ff55ff",
                                fontSize: "16px",
                                margin: "3px"
                              }}
                            >
                              NG:
                            </label>
                            <label
                              style={{
                                color: "red",
                                fontSize: "16px",
                                margin: "3px"
                              }}
                            >
                              {item.sNG || 0} Pcs.
                            </label>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              borderRight: "solid black",
                              borderWidth: "2px",
                              color: "yellow"
                            }}
                          >
                            PM :
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {Math.round(
                              getProblemReport(item)["PM"]?.totalTime * 60
                            ) || 0}{" "}
                            {""}
                            min
                          </StyledTableCell>
                          <StyledTableCell rowSpan={1} align="left">
                            Operation:
                            <label
                              style={{ color: "yellow", fontSize: "16px" }}
                            >
                              {item.productionWorker || ""}
                            </label>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              borderRight: "solid black",
                              borderWidth: "2px"
                            }}
                          >
                            <label style={{ color: "red", fontSize: "16px" }}>
                              Machine Breakdown:
                            </label>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {Math.round(
                              getProblemReport(item)["Machine Breakdown"]
                                ?.totalTime * 60
                            ) || 0}{" "}
                            min
                          </StyledTableCell>
                          <StyledTableCell rowSpan={4} align="center">
                            {Number(
                              calculateOEE(
                                item.problem,
                                item,
                                item.actualQuantity
                                  ? item.actualQuantity
                                  : dataMessage.quantity,
                                item.sNG ? item.sNG : 0
                              )?.oee.toFixed(2)
                            ) < 80 ? (
                              <img
                                src={getImagePng(`pakorabad`)}
                                alt=""
                                width={"120"}
                                height={"120"}
                              />
                            ) : (
                              <img
                                src={getImagePng(
                                  `__hoshimachi_suisei_hololive_drawn_by_hinata_kokag`
                                )}
                                alt=""
                                width={"120"}
                                height={"120"}
                              />
                            )}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              borderRight: "solid black",
                              borderWidth: "2px",
                              color: "blue"
                            }}
                          >
                            DIE Breakdown :
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {Math.round(
                              getProblemReport(item)["DIE Breakdown"]
                                ?.totalTime * 60
                            ) || 0}{" "}
                            min
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              borderRight: "solid black",
                              borderWidth: "2px"
                            }}
                          >
                            Wait QC First Set Up :
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {Math.round(
                              getProblemReport(item)["Wait QC First Set Up"]
                                ?.totalTime * 60
                            ) || 0}{" "}
                            min
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              borderRight: "solid black",
                              borderWidth: "2px",
                              color: "orange"
                            }}
                          >
                            other :
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Tooltip
                              title={
                                Math.round(
                                  getProblemReport(item)["other"]?.totalTime *
                                    60
                                ) > 0
                                  ? "มีปัญหา"
                                  : "ไม่มีปัญหา"
                              }
                              arrow
                              sx={{ fontSize: "25px" }}
                              followCursor={true}
                            >
                              <div>
                                {Math.round(
                                  getProblemReport(item)["other"]?.totalTime *
                                    60
                                ) || 0}{" "}
                                min
                              </div>
                            </Tooltip>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell align="center" colSpan={2}>
                            ประสิทธิภาพเครื่อง OEE
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <label
                              style={{ color: "#55ff55", fontSize: "20px" }}
                            >
                              {calculateOEE(
                                item.problem,
                                item,
                                item.actualQuantity
                                  ? item.actualQuantity
                                  : dataMessage.quantity,
                                item.sNG ? item.sNG : 0
                              )?.oee.toFixed(2) ?? 0}
                              %
                            </label>
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </ThemedCard>
      </ThemeProvider>
    </div>
  )
}
export default IotQuantity
