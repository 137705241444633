import React, { useMemo, useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import Grid from "@mui/material/Grid"
import AddIcon from "@mui/icons-material/Add"
import { AlertColor } from "@mui/material"
import {
  ADD_FG,
  FETCH_FG_STOCK
} from "../../../common/Mutation/MasterPlanMutation"
import moment from "moment"
import DateAdapter from "@mui/lab/AdapterMoment"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"
import swal from "sweetalert"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
export interface IStatus {
  message: string
  type: AlertColor | undefined
}
interface IAddFinishedGoods {
  partNo: string
}

const AddFinishedGoods = (props: IAddFinishedGoods): JSX.Element => {
  const { partNo } = props
  const [quantity, setQuantity] = useState<number | null>(0)
  const [balanceOs, setBalanceOs] = useState<number | null>(0)
  const [balanceOOs, setBalanceOOs] = useState<number | null>(0)
  const [balancePlanOs, setBalancePlanOs] = useState<number | null>(0)
  const [balanceF1, setBalanceF1] = useState<number | null>(0)
  const [balanceF2, setBalanceF2] = useState<number | null>(0)
  const [balanceFollowUp, setBalanceFollowUp] = useState<number | null>(0)
  const [balActualProductionOsf1, setBalActualProductionOsf1] = useState<
    number | null
  >(0)
  const [selectDate, setSelectDate] = useState<string>(
    moment(new Date()).format("YYYY-MM")
  )

  const [addFG, { loading }] = useMutation(ADD_FG, {
    refetchQueries: [FETCH_FG_STOCK],
    awaitRefetchQueries: true
  })
  const [getData] = useLazyQuery(FETCH_FG_STOCK, {
    variables: { partNo: partNo, date: selectDate },
    fetchPolicy: "network-only"
  })

  useMemo(async () => {
    if (partNo) {
      const data = await getData({
        variables: { partNo: partNo, date: selectDate }
      })
      const findFgStock = data?.data.findFgMonthlyStock

      if (findFgStock) {
        setBalanceFollowUp(findFgStock?.balanceFollowUp)
        setBalActualProductionOsf1(findFgStock?.balActualProductionOSF1)
        setBalanceF1(findFgStock?.balanceF1)
        setBalanceF2(findFgStock?.balanceF2)
        setBalanceOs(findFgStock?.balanceOs)
        setBalanceOOs(findFgStock?.balanceOOs)
        setBalancePlanOs(findFgStock?.balancePlanOs)
      } else {
        setBalanceFollowUp(0)
        setBalActualProductionOsf1(0)
        setBalanceF1(0)
        setBalanceF2(0)
        setBalanceOs(0)
        setBalanceOOs(0)
        setBalancePlanOs(0)
      }
    }
  }, [selectDate, partNo])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!partNo || !selectDate)
      return swal("Warning", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    try {
      addFG({
        variables: {
          partNo,
          quantity,
          balanceOs,
          balanceOOs,
          balancePlanOs,
          balanceF1,
          balanceF2,
          balanceFollowUp,
          balActualProductionOsf1,
          date: moment(selectDate).format("YYYY-MM-01")
        }
      })
      setQuantity(0)
      swal("Success", `Add Stock ${partNo} Successful `, "success")
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "warning")
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid item container spacing={2} width={"98%"} padding={2}>
        <Grid item xs={12} sm={12}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              label="เลือกเดือน"
              views={["year", "month"]}
              value={selectDate}
              onChange={(newSelectDate) => {
                setSelectDate(moment(newSelectDate).format("YYYY-MM"))
              }}
              renderInput={(params) => (
                <ThemedTextField {...params} fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ThemedTextField
            fullWidth
            value={balanceF1 ? balanceF1 : ""}
            onChange={(e) => setBalanceF1(parseFloat(e.target.value))}
            label="Stock Balance F1"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ThemedTextField
            fullWidth
            value={balanceF2 ? balanceF2 : ""}
            onChange={(e) => setBalanceF2(parseFloat(e.target.value))}
            label="Stock Balance F2"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ThemedTextField
            fullWidth
            value={balanceFollowUp ? balanceFollowUp : ""}
            onChange={(e) => setBalanceFollowUp(parseFloat(e.target.value))}
            label="Stock Balance follow Up F2	"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ThemedTextField
            fullWidth
            value={balanceOs ? balanceOs : ""}
            onChange={(e) => setBalanceOs(parseFloat(e.target.value))}
            label="Stock Balance Os"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ThemedTextField
            fullWidth
            value={balanceOOs ? balanceOOs : ""}
            onChange={(e) => setBalanceOOs(parseFloat(e.target.value))}
            label="Stock Balance OOs"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ThemedTextField
            fullWidth
            value={balancePlanOs ? balancePlanOs : ""}
            onChange={(e) => setBalancePlanOs(parseFloat(e.target.value))}
            label="Stock Balance PlanOs"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ThemedTextField
            fullWidth
            value={balActualProductionOsf1 ? balActualProductionOsf1 : ""}
            onChange={(e) =>
              setBalActualProductionOsf1(parseFloat(e.target.value))
            }
            label="Stock Balance Production Os F1"
          />
        </Grid>
        <Grid item xs={12}>
          <ThemedLoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            loadingPosition="end"
            sx={{ mt: 1 }}
            endIcon={<AddIcon />}
          >
            Submit
          </ThemedLoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default AddFinishedGoods
