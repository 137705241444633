import { gql } from "@apollo/client"

export const ADD_LOGISTICS_RECORD = gql`
  mutation SubmitLogisticsCostRecord($data: Data!) {
    submitLogisticsCostRecord(data: $data)
  }
`

export const ADD_DELIVERY_TAG = gql`
  mutation Deliverytag($data: deliverytag!) {
    deliverytag(data: $data)
  }
`

export const ADD_DELIVERY_SCAN = gql`
  mutation DeliveryScan($data: deliveryScan!) {
    deliveryScan(data: $data) {
      partNo
      deliveryDate
      pdLotNo
      remarks
      factory
      period
      poCustomer
      totalBox
      boxNo
      operator
    }
  }
`
export const ADD_NEW_LOGISTIC_PRICE = gql`
  mutation AddNewLogisticPrice(
    $origin: String!
    $destination: String!
    $price: Int
    $drop: Int
  ) {
    addNewLogisticPrice(
      origin: $origin
      destination: $destination
      price: $price
      drop: $drop
    )
  }
`

export const UPDATE_LOGISTIC_PRICE = gql`
  mutation UpdateLogisticPrice(
    $origin: String!
    $destination: String!
    $price: Int
    $drop: Int
  ) {
    updateLogisticPrice(
      origin: $origin
      destination: $destination
      price: $price
      drop: $drop
    )
  }
`

export const UPDATE_LOGISTICS_REPORT = gql`
mutation UpdateLogisticsReport($logisticsId: Int!, $updatedBy: String!, $fromPage: String!, $poCustomer: String, $invoiceNo: String, $drop: Int, $advance: Int, $transportCost: Int, $remarks: String, $carLicense: String) {
  updateLogisticsReport(logisticsId: $logisticsId, updatedBy: $updatedBy, fromPage: $fromPage, poCustomer: $poCustomer, invoiceNo: $invoiceNo, drop: $drop, advance: $advance, transportCost: $transportCost, remarks: $remarks, carLicense: $carLicense)
}
`
