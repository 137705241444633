import { gql } from "@apollo/client"

export const GET_TRANSPORT_REPORT = gql`
  query WhtransportReport(
    $dateFrom: String!
    $dateTo: String!
    $partNo: String
    $period: String
    $carLicense: String
  ) {
    whtransportReport(
      dateFrom: $dateFrom
      dateTo: $dateTo
      partNo: $partNo
      period: $period
      carLicense: $carLicense
    ) {
      entry {
        transportDate
        branchFrom
        branchTo
        period
        partNo
        pdLotNo
        jobOrder
        quantity
        carLicense
        approval
        operatorWH
        remarks
        box
      }
      totalQuantity
      totalBox
    }
  }
`

export const GET_LOGISTICS_REPORT = gql`
  query LogisticReport(
    $dateFrom: String!
    $dateTo: String!
    $carRegistration: String!
    $customer: String
    $premiumFreight: Boolean
  ) {
    logisticReport(
      dateFrom: $dateFrom
      dateTo: $dateTo
      carRegistration: $carRegistration
      customer: $customer
      premiumFreight: $premiumFreight
    ) {
      entry {
        logisticsId
        deliveryDate
        fromOrigin
        companyCustom
        poCustomer
        invoiceNo
        amountSales
        carPerWay
        cost
        drop
        advance
        premiumFreight
        transportCost
        userRecord
        carLicense
        remarks
      }
      total {
        sales
        transport
      }
      sumCustomer {
        customer
        incomeCost
        transportCost
        percentTransportCost
        target
      }
    }
  }
`

export const GET_LOGISTICS_REPORT_CUSTOMERS = gql`
  query Companycustom {
    companycustom {
      companyName
    }
  }
`

export const GET_LOGISTICS_REPORT_CAR_LICENSE = gql`
  query Carlicense {
    carLicense {
      carLicense
    }
  }
`

export const GET_LOGISTICS_COST_RECORD_PRICE = gql`
  query Price($origin: String, $destination: String) {
    price(origin: $origin, destination: $destination) {
      origin
      destination
      price
      drop
    }
  }
`

export const GET_ALL_LOGISTIC_PRICE = gql`
  query GetAllLogisticPrice {
    getAllLogisticPrice {
      origin
      destination
      price
      drop
    }
  }
`

export const GET_DELIVERY_TAG_REPORT = gql`
  query DeliveryReport($data: deliveryTagReport!) {
    deliveryReport(data: $data) {
      partNo
      partName
      customer
      model
      quantity
      totalQuantity
      packingBy
      deliveryDate
      pdLotNo
      remarks
      boxCount
      factory
      period
      poCustomer
      status
    }
  }
`

export const GET_DELIVERY_TAG = gql`
  query GetDeliveryTag($data: inputGetDeliveryScan) {
    getDeliveryTag(data: $data) {
      partNo
      partName
      customer
      model
      quantity
      totalQuantity
      packingBy
      deliveryDate
      pdLotNo
      remarks
      boxCount
      factory
      period
      poCustomer
    }
  }
`

export const GET_DELIVERY_SCAN_DATA = gql`
  query GetDeliveryScan($dataInput: inputGetDeliveryScan) {
    getDeliveryScan(dataInput: $dataInput) {
      partNo
      deliveryDate
      pdLotNo
      remarks
      factory
      period
      poCustomer
      totalBox
      boxNo
      operator
    }
  }
`
