import { gql } from "@apollo/client"

export const LOGIN = gql`
  query SignIn($username: String!, $password: String!) {
    signIn(username: $username, password: $password) {
      accessToken
      errorMessage
    }
  }
`
