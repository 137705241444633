import { TableCell, TableRow, styled, tableCellClasses } from "@mui/material"
import { IFGReport, IOrder, IPartOrder } from "./MPReport"

export function getAllPlant(plant: string[]): string {
  return Array.from(new Set(plant.join(",").replace(/ /g, "").split(","))).join(
    ","
  )
}

export const paddingPartDeliveryDates = (
  orders: IOrder[],
  masterDateList: string[],
  stockBegin: IPartOrder
): IOrder[] => {
  const newOrders: Array<IOrder> = []
  let j = 0

  masterDateList.forEach((e, i) => {
    if (orders[j]) {
      if (orders.length > j && masterDateList[i] === orders[j].dueDate) {
        newOrders.push(orders[j])
        j++
      } else {
        // stockBegin = stockBegin ? stockBegin : "0"
        const tempOrder: IOrder = {
          actualProduction: 0,
          balActual:
            j === 0 ? Number(stockBegin.balanceF1) : orders[j - 1].balActual,
          actualProductionFactory2: 0,
          balActual2:
            j === 0 ? Number(stockBegin.balanceF2) : orders[j - 1].balActual2,
          actualProductionOSF1: 0,
          balActualProductionOSF1:
            j === 0
              ? Number(stockBegin.balActualProductionOSF1)
              : orders[j - 1].balActualProductionOSF1,
          dueDate: masterDateList[i],
          actualProductionFollowUp: 0,
          balActualFollowUp:
            j === 0
              ? Number(stockBegin.balanceFollowUp)
              : orders[j - 1].balActualFollowUp,
          quantity: 0,
          planOs: 0,
          balActualPlanOs: 0,
          balActualOos: 0,
          partCounted: 0
        }

        newOrders.push(tempOrder)
      }
    } else {
      const tempOrder: IOrder = {
        actualProduction: 0,
        balActual: orders[j - 1]?.balActual,
        actualProductionFactory2: 0,
        balActual2: orders[j - 1]?.balActual2,
        actualProductionOSF1: 0,
        balActualProductionOSF1: orders[j - 1]?.balActualProductionOSF1,
        actualProductionFollowUp: 0,
        balActualFollowUp: orders[j - 1]?.balActualFollowUp,
        dueDate: masterDateList[i],
        quantity: 0,
        planOs: 0,
        balActualPlanOs: 0,
        balActualOos: 0,
        partCounted: 0
      }
      newOrders.push(tempOrder)
    }
  })
  return newOrders
}

export const getFgStock = (fgStock: IFGReport[], factory: string): number => {
  const quantity = fgStock.find((val) => val.factory === factory)?.quantity
  return quantity || 0
}

export const validateRowSpan = (
  factory: string,
  plating: boolean,
  followUp: boolean,
  customer: string | null,
  partNo: string | null,
  order: boolean
): number => {
  if (order) return 2

  if (customer === "EMERSON" || customer === "ไทยไทโย" || customer === "H-ONE")
    return 4

  if (customer === "MAHLE") {
    return 6
  }
  if (factory === "" || (factory === "2" && plating && !followUp)) {
    return 8
  } else {
    return 4
  }
}
export const validateRowSpanToPrint = (
  factory: string,
  plating: boolean,
  followUp: boolean,
  customer: string | null,
  order: boolean
): number => {
  if (order) return 2
  if (customer === "MAHLE") {
    return 4
  }
  if (factory === "" || (factory === "2" && plating && !followUp)) {
    return 4
  } else {
    return 2
  }
}
export const tableCustomerMahle = (
  customerPart?: string | null,
  partNo?: string | null
): boolean => {
  if (customerPart === "MAHLE" || partNo === "MD050076V1") return true
  return false
}
export const tableCustomerShowTableF1Only = (customer: string): boolean => {
  if (customer === "EMERSON" || customer === "ไทยไทโย") return true
  return false
}
export const showTableActualF1 = (
  customer: string,
  orderCheck: boolean,
  factory: string,
  negativePartFollowUpF1: boolean,
  plating: boolean,
  followUp: boolean
): boolean => {
  if (!orderCheck && tableCustomerShowTableF1Only(customer)) {
    return true
  }
  if (!orderCheck && customer === "H-ONE") {
    return false
  }
  return (
    (!orderCheck && factory === "1") ||
    (!orderCheck && factory === "") ||
    (!orderCheck && factory === "2" && plating === true && !followUp) ||
    (negativePartFollowUpF1 && plating === true && followUp)
  )
}
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "bold",
    border: "solid",
    borderWidth: "1px",
    borderStyle: "solid",
    whiteSpace: "nowrap",
    padding: "7px",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary.main
        : theme.palette.secondary.contrastText,
    color: "#000000"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "bold",
    border: "solid",
    borderWidth: "1px",
    padding: "0px",
    borderStyle: "solid",
    whiteSpace: "nowrap",
    color: "#000000"
  }
}))
export const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: "#FFFFFF",
  [`&[class*=display-border]`]: {
    backgroundColor: "#BBBBBB"
  },
  "&:last-child td, &:last-child th": {
    border: "solid",
    borderWidth: "1px"
  }
}))
export const showTableFollowUp = (
  customer: string,
  orderCheck: boolean,
  factory: string,
  plating: boolean,
  negativePartFollowUpF1: boolean,
  followUp: boolean
): boolean => {
  if (
    (!orderCheck && tableCustomerShowTableF1Only(customer)) ||
    customer === "H-ONE" ||
    customer === "MAHLE"
  ) {
    return false
  }
  return (
    (factory === "2" &&
      plating === true &&
      !negativePartFollowUpF1 &&
      ((!orderCheck && followUp) || (!orderCheck && !followUp))) ||
    (!orderCheck && factory === "")
  )
}
export const showTableActualF2 = (
  customer: string,
  orderCheck: boolean,
  factory: string,
  plating: boolean,
  followUp: boolean
): boolean => {
  if (!orderCheck && tableCustomerShowTableF1Only(customer)) {
    return false
  }
  return (
    (factory === "2" &&
      ((!orderCheck && plating === false) ||
        (!orderCheck && plating === true)) &&
      !followUp) ||
    (!orderCheck && factory === "")
  )
}
