import { Grid, ThemeProvider, useTheme } from "@mui/material"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { useState } from "react"

import { useMutation } from "@apollo/client"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import UploadFiles from "../MasterPlan/mpcomponents/UploadFiles"
import { UPLOAD_FILE_SLIP_DATA } from "../../common/Mutation/HumanResourcesMutation"

const UploadFileSlipSalary = (): JSX.Element => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [uploadFile, { loading }] = useMutation(UPLOAD_FILE_SLIP_DATA)

  const theme = useTheme()

  const handleDownload = async () => {
    if (!selectedFile) {
      return swal("Warning", "ไม่มีข้อมูลอัพโหลด", "warning")
    }
    try {
      await uploadFile({
        variables: {
          file: selectedFile
        }
      })
      swal("Success", "อัพโหลดข้อมูลสำเร็จ", "success")
    } catch (err) {
      return swal("Error", `${(err as Error).message}`, "error")
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.HumanResources}
        menuItemName={Object.keys(menuItemList)[15]}
        menuPageName={"Upload data Slip Salary"}
      />
      <ThemedCard>
        <PageLabelCard
          title="Upload data Slip Salary"
          subTitle="About detail Human Resources"
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <UploadFiles
              loading={loading}
              functionUpload={handleDownload}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default UploadFileSlipSalary
