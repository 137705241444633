import React, { Dispatch, SetStateAction, useState } from "react"
import { Grid, Autocomplete, useTheme, ThemeProvider } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import PrintIcon from "@mui/icons-material/Print"
import RestartAltIcon from "@mui/icons-material/RestartAlt"

import moment from "moment"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import { useMutation } from "@apollo/client"
import { ADD_DELIVERY_TAG } from "../../common/Mutation/LogisticsMutation"
import DeliveryTagLabels from "./logisticscomponents/DeliveryTagLabels"
import "../../css/Logistics/DeliveryTag1.css"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import AutocompleteCustomer from "../../common/Resources/AutocompleteCustomer"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { factoryList } from "../../common/utilities"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import "../../css/Warehouse/GetWarehouseTag.css"

interface IDeliveryTagFormProps {
  customer: string
  partNo: string
  deliveryDate: Date
  period: string
  warehouseQuantity: number
  totalQuantity: number
  pdLotNo: string
  packingBy: string
  factory: string
  remarks: string
  poCustomer: string
  partName: string
  model: string
  setCustomer: Dispatch<SetStateAction<string>>
  setPartNo: Dispatch<SetStateAction<string>>
  setDeliveryDate: Dispatch<SetStateAction<Date>>
  setPeriod: Dispatch<SetStateAction<string>>
  setWarehouseQuantity: Dispatch<SetStateAction<number>>
  setTotalQuantity: Dispatch<SetStateAction<number>>
  setPdLotNo: Dispatch<SetStateAction<string>>
  setPackingBy: Dispatch<SetStateAction<string>>
  setFactory: Dispatch<SetStateAction<string>>
  setRemarks: Dispatch<SetStateAction<string>>
  setPoCustomer: Dispatch<SetStateAction<string>>
  makeDeliveryLabels: () => void
}

interface DeliveryTagInfo {
  partNo: string
  partName: string
  customer: string
  model: string
  quantity: number
  totalQuantity: number
  packingBy: string
  deliveryDate: string
  pdLotNo: string
  remarks: string
  boxCount: string
  factory: string
  period: string
  poCustomer: string
}

const pageTitle = "FIFO Delivery Tag"

export const DeliveryTagForm = (props: IDeliveryTagFormProps): JSX.Element => {
  const {
    customer,
    partNo,
    deliveryDate,
    period,
    warehouseQuantity,
    totalQuantity,
    pdLotNo,
    packingBy,
    factory,
    remarks,
    poCustomer,
    partName,
    model,
    setCustomer,
    setPartNo,
    setDeliveryDate,
    setPeriod,
    setWarehouseQuantity,
    setTotalQuantity,
    setPdLotNo,
    setPackingBy,
    setFactory,
    setRemarks,
    setPoCustomer,
    makeDeliveryLabels
  } = props

  const [addDeliveryTag, { loading }] = useMutation(ADD_DELIVERY_TAG)

  const periodList = ["รอบปกติ", "รอบเสริม", "รอบพิเศษ"]
  const packingByList = ["รุ่งฤดี", "วรารัตน์", "อุไรรัตน์"]

  const isComplete = () => {
    return (
      customer &&
      partNo &&
      deliveryDate &&
      period &&
      pdLotNo &&
      packingBy &&
      factory
    )
  }
  const handleGetDeliveryFormSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    makeDeliveryLabels()
    if (!isComplete())
      return swal("Warning", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    const deliveryTagData: DeliveryTagInfo = {
      customer: customer,
      partNo: partNo,
      partName: partName,
      deliveryDate: moment(deliveryDate).format("DD-MM-YYYY"),
      period,
      model,
      quantity: warehouseQuantity,
      totalQuantity,
      pdLotNo,
      packingBy,
      factory,
      remarks,
      poCustomer,
      boxCount:
        warehouseQuantity !== 0
          ? (totalQuantity / warehouseQuantity).toString()
          : ""
    }
    try {
      addDeliveryTag({
        variables: {
          data: deliveryTagData
        }
      })
      swal("Success", "uploaded data successfully!", "success")
    } catch (error) {
      swal(
        "Warning",
        `oops, something went wrong ${(error as Error).message}`,
        "warning"
      )
    }
  }
  const handleReset = () => {
    setCustomer("")
    setPartNo("")
    setDeliveryDate(new Date())
    setPeriod("")
    setWarehouseQuantity(0)
    setTotalQuantity(0)
    setPdLotNo("")
    setPackingBy("")
    setFactory("")
    setRemarks("")
    setPoCustomer("")
  }
  const theme = useTheme()

  return (
    <>
      <form onSubmit={handleGetDeliveryFormSubmit} className="none-display">
        <Grid container spacing={2} margin={2} width={"98%"}>
          <Grid item xs={12} sm={3}>
            <AutocompleteCustomer
              setCustomer={setCustomer}
              customer={customer}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <AutocompletePartNo setPartNo={setPartNo} partNo={partNo} />
          </Grid>

          <Grid item xs={12} sm={3}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                label="วันที่ส่ง"
                openTo="day"
                views={["year", "month", "day"]}
                inputFormat="DD-MM-YYYY"
                value={deliveryDate}
                onChange={(newDeliveryDate) => {
                  setDeliveryDate(moment(newDeliveryDate).toDate() as Date)
                }}
                renderInput={(params) => (
                  <ThemedTextField {...params} fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              value={period}
              aria-label="periodTest"
              key="periodTest"
              disableClearable
              disablePortal
              onChange={(event, selectedPeriod) => {
                setPeriod(selectedPeriod as string)
              }}
              options={periodList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Period"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ThemedTextField
              fullWidth
              value={warehouseQuantity}
              label="WH Quantity"
              onChange={(e) =>
                setWarehouseQuantity(
                  isNaN(+e.target.value) ? 0 : +e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ThemedTextField
              fullWidth
              value={totalQuantity}
              label="Total Quantity"
              onChange={(e) =>
                setTotalQuantity(isNaN(+e.target.value) ? 0 : +e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ThemedTextField
              fullWidth
              value={pdLotNo}
              label="PD Lot NO"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setPdLotNo(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              value={packingBy}
              disableClearable
              disablePortal
              onChange={(event, selectedPackedBy) => {
                setPackingBy(selectedPackedBy as string)
              }}
              options={packingByList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Packed By"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              value={factory}
              disablePortal
              onChange={(event, selectedFactory) => {
                setFactory(selectedFactory as string)
              }}
              options={factoryList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Factory"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <ThemedTextField
              fullWidth
              value={remarks}
              label="Remarks"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <ThemedTextField
              fullWidth
              value={poCustomer}
              label="P/O Customer"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setPoCustomer(e.target.value)}
            />
          </Grid>

          <Grid item sm={12} marginBottom={2}>
            <ThemedLoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              style={{
                backgroundColor: theme.palette.primary.main,
                marginRight: "10px"
              }}
              loadingPosition="end"
              endIcon={<AddIcon />}
              onClick={() => makeDeliveryLabels()}
            >
              Submit
            </ThemedLoadingButton>

            <ThemedButton
              variant="outlined"
              endIcon={<PrintIcon />}
              style={{
                color: theme.palette.secondary.contrastText,
                borderColor: theme.palette.primary.main,
                marginRight: "10px"
              }}
              onClick={() => window.print()}
            >
              Print
            </ThemedButton>
            <ThemedButton
              variant="outlined"
              endIcon={<RestartAltIcon />}
              style={{ color: "red", borderColor: "red" }}
              onClick={handleReset}
            >
              Reset
            </ThemedButton>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

const DeliveryTag = (): JSX.Element => {
  const [customer, setCustomer] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [deliveryDate, setDeliveryDate] = useState<Date>(new Date())
  const [period, setPeriod] = useState<string>("")
  const [warehouseQuantity, setWarehouseQuantity] = useState<number>(0)
  const [totalQuantity, setTotalQuantity] = useState<number>(0)
  const [pdLotNo, setPdLotNo] = useState<string>("")
  const [packingBy, setPackingBy] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [remarks, setRemarks] = useState<string>("")
  const [poCustomer, setPoCustomer] = useState<string>("")

  const [partName, setPartName] = useState<string>("")
  const [model, setModel] = useState<string>("")

  const makeDeliveryLabels = () => {
    if (warehouseQuantity !== 0) {
      // deliveryLabelsElement = (
      //   <DeliveryTagLabels
      //     customer={customer}
      //     partNo={partNo}
      //     period={period}
      //     warehouseQuantity={warehouseQuantity}
      //     totalQuantity={totalQuantity}
      //     packingBy={packingBy}
      //     pdLotNo={pdLotNo}
      //     deliveryDate={moment(deliveryDate).format("DD-MM-YYYY")}
      //     setPartName={setPartName}
      //     setModel={setModel}
      //   />
      // )
    }
  }
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <>
        <div className="hidden-print">
          <PageLabel
            menuItem={menuItemList.Logistics}
            menuItemName={Object.keys(menuItemList)[8]}
            menuPageName={pageTitle}
          />
        </div>
        <ThemedCard>
          <div className="delivery-tag1-report-to-print">
            <div className="hidden-print">
              <PageLabelCard
                title="Delivery Tag"
                subTitle="detail about FIFO Delivery Tag"
              />
            </div>

            <DeliveryTagForm
              customer={customer}
              partNo={partNo}
              deliveryDate={deliveryDate}
              period={period}
              warehouseQuantity={warehouseQuantity}
              totalQuantity={totalQuantity}
              pdLotNo={pdLotNo}
              packingBy={packingBy}
              factory={factory}
              remarks={remarks}
              poCustomer={poCustomer}
              partName={partName}
              model={model}
              setCustomer={setCustomer}
              setPartNo={setPartNo}
              setDeliveryDate={setDeliveryDate}
              setPeriod={setPeriod}
              setWarehouseQuantity={setWarehouseQuantity}
              setTotalQuantity={setTotalQuantity}
              setPdLotNo={setPdLotNo}
              setPackingBy={setPackingBy}
              setFactory={setFactory}
              setRemarks={setRemarks}
              setPoCustomer={setPoCustomer}
              makeDeliveryLabels={makeDeliveryLabels}
            />
          </div>
        </ThemedCard>
        <Grid
          className="print-delivery-new-tag"
          container
          sx={{ backgroundColor: "white" }}
        >
          {warehouseQuantity !== 0 ? (
            <DeliveryTagLabels
              customer={customer}
              partNo={partNo}
              period={period}
              warehouseQuantity={warehouseQuantity}
              totalQuantity={totalQuantity}
              packingBy={packingBy}
              pdLotNo={pdLotNo}
              deliveryDate={moment(deliveryDate).format("DD-MM-YYYY")}
              setPartName={setPartName}
              setModel={setModel}
            />
          ) : (
            <></>
          )}
        </Grid>
      </>
    </ThemeProvider>
  )
}

export default DeliveryTag
