import { useMPContext } from "../../../context/MPContext"
import { IMasterPlanPartData, IOrder } from "../MPReport"
import { StyledTableCell, StyledTableRow, getFgStock } from "../utilities"

interface IProp {
  index: number
  customer: string
  partData: IMasterPlanPartData
  order: IOrder[]
}

export const TableRowActualF2: React.FC<IProp> = (props: IProp) => {
  const { index, partData, order } = props
  const { maxDueDate } = useMPContext()
  return (
    <>
      <StyledTableRow
        style={
          partData.customer === "MAHLE" || partData.partNo === "MD050076V1"
            ? { display: "none" }
            : {}
        }
        className={index % 2 === 0 ? "display-border" : undefined}
        key={"row-f2" + index + partData.partNo}
      >
        <StyledTableCell
          style={{
            whiteSpace: "nowrap",
            padding: "7px"
          }}
          colSpan={1}
          rowSpan={2}
        >
          <>{getFgStock(partData.fgStock, "2")}</>
        </StyledTableCell>
        <StyledTableCell
          style={{
            whiteSpace: "nowrap",
            padding: "7px"
          }}
          colSpan={1}
          rowSpan={2}
        >
          <>{partData.stockBegin.balanceF2 || 0}</>
        </StyledTableCell>
        <StyledTableCell
          style={{
            whiteSpace: "nowrap",
            padding: "7px"
          }}
          colSpan={1}
          rowSpan={1}
        >
          <>Actual Production F2</>
        </StyledTableCell>
        {Array.from(Array(maxDueDate.length), (num, i) => {
          if (order[i]) {
            return (
              <StyledTableCell
                colSpan={1}
                rowSpan={1}
                key={"cel-al-f2" + i + partData.partNo}
              >
                <>{order[i].actualProductionFactory2}</>
              </StyledTableCell>
            )
          } else {
            return (
              <StyledTableCell
                colSpan={1}
                rowSpan={1}
                key={"cel-al-f2" + i + partData.partNo}
              >
                <>{0}</>
              </StyledTableCell>
            )
          }
        })}
      </StyledTableRow>
      <StyledTableRow
        style={
          partData.customer === "MAHLE" || partData.partNo === "MD050076V1"
            ? { display: "none" }
            : {}
        }
        className={index % 2 === 0 ? "display-border" : undefined}
        key={"row-f2-customer" + index + partData.partNo}
      >
        <StyledTableCell
          style={{
            whiteSpace: "nowrap",
            padding: "7px"
          }}
          colSpan={1}
          rowSpan={1}
        >
          <>Stock bal (actual) F2</>
        </StyledTableCell>
        {Array.from(Array(maxDueDate.length), (num, i) => {
          if (order[i]) {
            return (
              <StyledTableCell
                colSpan={1}
                rowSpan={1}
                key={"cel-bl-f2" + i + partData.partNo}
              >
                <span style={order[i].balActual2 < 0 ? { color: "red" } : {}}>
                  {order[i].balActual2}
                </span>
              </StyledTableCell>
            )
          } else {
            return (
              <StyledTableCell
                colSpan={1}
                rowSpan={1}
                key={"cel-bl-f2" + i + partData.partNo}
              >
                <>{0}</>
              </StyledTableCell>
            )
          }
        })}
      </StyledTableRow>
    </>
  )
}
