import { useMutation, useQuery } from "@apollo/client"
import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  Radio,
  MenuItem,
  RadioGroup,
  TextField,
  useTheme
} from "@mui/material"
import moment from "moment"
import { Dispatch, SetStateAction, useState } from "react"
import { REPAIR_REQUEST_FROM } from "../../../common/Mutation/MaintenanceMutation"
import { GET_DEPARTMENT, GET_EMPLOYEE } from "../../../common/Query/MasterQuery"
import swal from "sweetalert"
import {
  GET_MACHINES_MT,
  GET_REPAIR_REQUEST
} from "../../../common/Query/MaintenanceQuery"

interface IProp {
  setOpenRepairPerson: Dispatch<SetStateAction<boolean>>
}
const RepairPersonFrom: React.FC<IProp> = (props: IProp) => {
  const { setOpenRepairPerson } = props
  const [department, setDepartment] = useState<string>("")
  const getDepartment = useQuery(GET_DEPARTMENT)
  const [workType, setWorkType] = useState<string>("")
  const [jobType, setJobType] = useState<string>("")
  const [completeDate, setCompleteDate] = useState<string>("")
  const [urgency, setUrgency] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const [detail, setDetail] = useState<string>("")
  const [actionDate, setActionDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [requestBy, setRequestBy] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const getMachine = useQuery(GET_MACHINES_MT)
  const getEmployee = useQuery(GET_EMPLOYEE)
  const [createRequestFrom, { loading: loadingWhileSubmit }] = useMutation(
    REPAIR_REQUEST_FROM,
    {
      refetchQueries: [GET_REPAIR_REQUEST],
      awaitRefetchQueries: true
    }
  )
  const [checkValue, setCheckValue] = useState<boolean>(false)
  const departmentList: string[] =
    getDepartment.data?.department.map(
      (e: { departmentName: string }) => e.departmentName
    ) || []
  const codeList =
    getMachine.data?.machinesMt
      .filter((data: { factory: string }) => data.factory === factory)
      .map(({ code }: { code: string }) => code) || []
  const employeeList =
    Array.from(
      new Set(
        getEmployee.data?.employee.map(
          ({ firstName }: { firstName: string }) => firstName
        )
      )
    ) || []
  const theme = useTheme()
  const handleSubmit = async () => {
    const inputRepairRequestFrom = {
      department,
      actionDate,
      workType,
      jobType,
      urgency,
      completeDate,
      machineName:
        getMachine.data?.machinesMt.find(
          (machine: { code: string }) => machine.code === code
        )?.machine || "",
      code,
      factory,
      detail,
      requestBy
    }
    if (
      department === "" ||
      workType === "" ||
      jobType === "" ||
      urgency === "" ||
      detail === ""
    ) {
      setCheckValue(true)
      return
    }
    setCheckValue(false)
    try {
      await createRequestFrom({
        variables: {
          inputRepairRequestFrom
        }
      })
      swal("OK", "", "success")
      setOpenRepairPerson(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }
  return (
    <Grid container spacing={2} width={"98%"} margin={2}>
      <Grid item sm={6} xs={6}>
        <Autocomplete
          options={departmentList}
          value={department}
          freeSolo
          fullWidth
          onChange={(e, newValue) => setDepartment(newValue as string)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="แผนก"
              error={checkValue}
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <TextField
          type="date"
          value={actionDate}
          fullWidth
          label="วันที่เเจ้ง"
          onChange={(e) => setActionDate(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <TextField
          id="factory"
          fullWidth
          label="Factory No."
          value={factory}
          onChange={(e) => setFactory(e.target.value)}
          select
        >
          <MenuItem value={"1"}>1</MenuItem>
          <MenuItem value={"2"}>2</MenuItem>
        </TextField>
      </Grid>
      <Grid item sm={6} xs={6}>
        <Autocomplete
          value={code}
          onChange={(event, selectedCode) => {
            setCode(selectedCode as string)
          }}
          options={codeList}
          renderInput={(params) => (
            <TextField
              {...params}
              error={checkValue}
              label="รหัสเครื่องจักร"
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
        />
      </Grid>

      <Grid item sm={2} xs={2} sx={{ mt: 1 }}>
        <Input
          sx={checkValue ? { color: "red" } : {}}
          value="ลักษณะงาน"
          disableUnderline
        />
      </Grid>
      <Grid item sm={10} xs={10}>
        <FormControl>
          <RadioGroup row onChange={(e) => setWorkType(e.target.value)}>
            <FormControlLabel value="ซ่อม" control={<Radio />} label="ซ่อม" />
            <FormControlLabel
              value="สร้าง/จัดทำ"
              control={<Radio />}
              label="สร้าง/จัดทำ"
            />
            <FormControlLabel
              value="ดัดแปลง/ปรับปรุง"
              control={<Radio />}
              label="ดัดแปลง/ปรับปรุง"
            />
          </RadioGroup>
        </FormControl>
        <TextField
          type="text"
          label="อื่นๆ..."
          variant="standard"
          sx={{ mt: -1 }}
          onChange={(e) => setWorkType(e.target.value)}
        />
      </Grid>
      <Grid item sm={2} xs={2} sx={{ mt: 1 }}>
        <Input
          sx={checkValue ? { color: "red" } : {}}
          value="ประเภทงาน"
          disableUnderline
        />
      </Grid>
      <Grid item sm={10} xs={10}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <RadioGroup row onChange={(e) => setJobType(e.target.value)}>
              <FormControlLabel
                value="เครื่องมือ/เครื่องจักร"
                control={<Radio />}
                label="เครื่องมือ/เครื่องจักร"
              />
              <FormControlLabel
                value="สารธารณูปโภค"
                control={<Radio />}
                label="สารธารณูปโภค"
              />
            </RadioGroup>
          </RadioGroup>
        </FormControl>
        <TextField
          type="text"
          label="อื่นๆ..."
          variant="standard"
          sx={{ mt: -1 }}
          onChange={(e) => setJobType(e.target.value)}
        />
      </Grid>
      <Grid item sm={2} xs={2} sx={{ mt: 1 }}>
        <Input
          sx={checkValue ? { color: "red" } : {}}
          value="ความจำเป็น"
          disableUnderline
        />
      </Grid>
      <Grid item sm={10} xs={10}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <RadioGroup row onChange={(e) => setUrgency(e.target.value)}>
              <FormControlLabel value="ปกติ" control={<Radio />} label="ปกติ" />

              <FormControlLabel
                value="ด่วน/ภายในวันที่"
                control={<Radio />}
                style={{ paddingLeft: "103px" }}
                label="ด่วน/ภายในวันที่"
              />
              <TextField
                type="date"
                style={{
                  width: "120px",
                  paddingRight: "210px"
                }}
                disabled={urgency === "ด่วน/ภายในวันที่" ? false : true}
                variant="standard"
                value={completeDate}
                onChange={(e) => setCompleteDate(e.target.value)}
              />
            </RadioGroup>
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item sm={12} xs={12}>
        <TextField
          fullWidth
          value={detail}
          variant="standard"
          error={checkValue}
          label="รายละเอียดงานหรือลักษณะงานที่ชำรุด"
          onChange={(e) => setDetail(e.target.value)}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Autocomplete
          value={requestBy}
          fullWidth
          onChange={(event, selectedRequestBy) => {
            setRequestBy(selectedRequestBy as string)
          }}
          options={employeeList}
          renderInput={(params) => (
            <TextField
              {...params}
              error={checkValue}
              label="ผู้เเจ้ง"
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <LoadingButton
          loading={loadingWhileSubmit}
          variant="contained"
          style={{ backgroundColor: theme.palette.primary.main }}
          autoFocus
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
export default RepairPersonFrom
