import { ChangeEvent, Dispatch, SyntheticEvent } from "react"

import Grid from "@mui/material/Grid"
import AddIcon from "@mui/icons-material/Add"
import UploadFileBox, {
  SelectedFileBox
} from "../../../common/Resources/UploadFileBox"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
interface IProp {
  loading: boolean
  functionUpload: (e: SyntheticEvent) => void
  selectedFile: File | null
  setSelectedFile: Dispatch<React.SetStateAction<File | null>>
}
const UploadFiles: React.FC<IProp> = (props: IProp) => {
  const { loading, functionUpload, selectedFile, setSelectedFile } = props
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(
      event.currentTarget.files?.["0"] ? event.currentTarget.files?.["0"] : null
    )
  }

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <UploadFileBox changeHandler={changeHandler} />
        </Grid>
        <Grid item xs={6} sm={6}>
          <SelectedFileBox
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        </Grid>
        <Grid item xs={12}>
          <ThemedLoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            loadingPosition="end"
            onClick={functionUpload}
            endIcon={<AddIcon />}
          >
            Submit
          </ThemedLoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default UploadFiles
