import { useLazyQuery, useQuery } from "@apollo/client"
import { Save } from "@mui/icons-material"
import { LocalizationProvider, TimePicker } from "@mui/lab"
import {
  Autocomplete,
  Button,
  styled,
  TableCell,
  tableCellClasses,
  TableRow
} from "@mui/material"
import { ChangeEvent, useMemo, useState } from "react"
import DateAdapter from "@mui/lab/AdapterMoment"
import {
  GET_MACHINES,
  GET_PARTS,
  GET_CUSTOMERS
} from "../../../common/Query/MasterQuery"
import { GET_PART_PROCESS } from "../../../common/Query/ScannerQuery"
import { DataProductionPlan, IDataEvent } from "../ProductionPlanReport"
import { calculateTime, diff } from "../ProductionPlan"
import moment from "moment"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
interface IProcessing {
  jph?: number
  line?: string
  machine?: string
  minStroke?: number
  partNo?: string
  process?: string
  processOrder?: string
  stepProcess?: number
}

interface ITarget {
  name: string
  value: string
  partName?: string
  process?: string
  totalQuantity?: number
  totalHour?: number
}
interface PartialEvent {
  target: ITarget
}

export type TChangeEvent = ChangeEvent<IDataEvent> | PartialEvent

interface IEditTableRow {
  editFormData: DataProductionPlan
  handleEditFromData: (e: TChangeEvent) => void
  edit: boolean
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2,
    color: "black"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  border: "solid 1px black",
  "&:nth-of-type(odd)": {
    border: "solid 1px black"
  },

  "&:last-child td, &:last-child th": {
    border: "solid 1px black"
  }
}))
const EditTableRow: React.FC<IEditTableRow> = ({
  editFormData,
  handleEditFromData,
  edit
}: IEditTableRow) => {
  const machineList = useQuery(GET_MACHINES)
  const partNoList = useQuery(GET_PARTS)
  const customerList = useQuery(GET_CUSTOMERS)
  const [getPartProcess] = useLazyQuery(GET_PART_PROCESS)
  const [partNo, setPartNo] = useState<string>(editFormData.partNo)
  const [partName, setPartName] = useState<string>(editFormData.partName)
  const [remarks, setRemarks] = useState<string>(
    editFormData.remarks ? editFormData.remarks : ""
  )
  const [step, setStep] = useState<string>(editFormData.step)
  const [process, setProcess] = useState<string>(editFormData.process)
  const [startWorkingTime, setStartWorkingTime] = useState<number>(3600000)
  const [endWorkingTime, setEndWorkingTime] = useState<number>(3600000)
  const [startWorkingTimeOT, setStartWorkingTimeOT] = useState<number>(37800000)
  const [endWorkingTimeOT, setEndWorkingTimeOT] = useState<number>(48600000)
  const [totalWorkingTime, setTotalWorkingTime] = useState<number>(
    Number(editFormData.totalHour)
  )
  const [jopPerHour, setJph] = useState<number>(editFormData.jph)
  const [totalQuantity, setTotalQuantity] = useState<number>(
    editFormData.totalQuantity
  )
  const [stepList, setStepList] = useState<string[]>([])
  const [processData, setProcessData] = useState<IProcessing[]>()

  useMemo(async () => {
    const process = await getPartProcess({
      variables: {
        partNo: partNo
      }
    })
    setProcessData(process.data.partProcesses)

    setStepList(
      process.data
        ? process.data.partProcesses.map(
            (e: { processOrder: string }) => e.processOrder
          )
        : []
    )

    setStartWorkingTime(
      new Date(`2022-01-01 ${editFormData.startTime}`).getTime()
    )
    setEndWorkingTime(new Date(`2022-01-01 ${editFormData.endTime}`).getTime())
    setStartWorkingTimeOT(
      new Date(`2022-01-01 ${editFormData.startTimeOT}`).getTime()
    )
    setEndWorkingTimeOT(
      new Date(`2022-01-01 ${editFormData.endTimeOT}`).getTime()
    )

    if (!startWorkingTime && !endWorkingTime) {
      setTotalWorkingTime(0)
    } else if (
      (Number(moment(endWorkingTime).format("HH")) > 12 &&
        Number(moment(startWorkingTime).format("HH")) < 13) ||
      (Number(moment(endWorkingTime).format("HH")) >= 1 &&
        Number(moment(endWorkingTime).format("HH")) <= 5 &&
        ((Number(moment(startWorkingTime).format("HH")) < 24 &&
          Number(moment(startWorkingTime).format("HH")) > 19) ||
          Number(moment(startWorkingTime).format("HH")) === 0))
    ) {
      const time = diff(startWorkingTime, endWorkingTime - 60 * 60 * 1000)
      setTotalWorkingTime(
        startWorkingTimeOT && endWorkingTimeOT
          ? Number(calculateTime(time)) +
              Number(calculateTime(diff(startWorkingTimeOT, endWorkingTimeOT)))
          : Number(calculateTime(time))
      )
    } else {
      const time = diff(startWorkingTime, endWorkingTime)
      setTotalWorkingTime(
        startWorkingTimeOT && endWorkingTimeOT
          ? Number(calculateTime(time)) +
              Number(calculateTime(diff(startWorkingTimeOT, endWorkingTimeOT)))
          : Number(calculateTime(time))
      )
    }
    setTotalQuantity(Math.round(jopPerHour * totalWorkingTime))
  }, [
    editFormData.endTime,
    editFormData.endTimeOT,
    editFormData.startTime,
    editFormData.startTimeOT,
    endWorkingTime,
    endWorkingTimeOT,
    getPartProcess,
    jopPerHour,
    partNo,
    startWorkingTime,
    startWorkingTimeOT,
    totalWorkingTime
  ])

  const calculateTotalRealTime = (
    startWorkingTime: number,
    endWorkingTime: number,
    startWorkingTimeOT: number,
    endWorkingTimeOT: number
  ) => {
    if (
      (Number(moment(endWorkingTime).format("HH")) > 12 &&
        Number(moment(startWorkingTime).format("HH")) < 13) ||
      (Number(moment(endWorkingTime).format("HH")) >= 1 &&
        Number(moment(endWorkingTime).format("HH")) <= 5 &&
        ((Number(moment(startWorkingTime).format("HH")) < 24 &&
          Number(moment(startWorkingTime).format("HH")) > 19) ||
          Number(moment(startWorkingTime).format("HH")) === 0))
    ) {
      const time = diff(startWorkingTime, endWorkingTime - 60 * 60 * 1000)
      const totalTime =
        startWorkingTimeOT && endWorkingTimeOT
          ? Number(calculateTime(time)) +
            Number(calculateTime(diff(startWorkingTimeOT, endWorkingTimeOT)))
          : Number(calculateTime(time))
      return totalTime
    } else {
      const time = diff(startWorkingTime, endWorkingTime)
      const totalTime =
        startWorkingTimeOT && endWorkingTimeOT
          ? Number(calculateTime(time)) +
            Number(calculateTime(diff(startWorkingTimeOT, endWorkingTimeOT)))
          : Number(calculateTime(time))
      return totalTime
    }
  }
  return (
    <StyledTableRow>
      {edit ? (
        <StyledTableCell>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Save />}
            type="submit"
            style={{ backgroundColor: "green" }}
          >
            Save
          </Button>
        </StyledTableCell>
      ) : (
        <></>
      )}
      <StyledTableCell>
        <Autocomplete
          fullWidth
          disablePortal
          freeSolo
          disableClearable
          ListboxProps={{
            style: {
              fontSize: "7px",
              width: "auto"
            }
          }}
          onChange={(e, newValue) => {
            return handleEditFromData({
              target: { name: "machine", value: newValue }
            })
          }}
          value={editFormData.machine}
          renderInput={(params) => (
            <ThemedTextField
              {...params}
              inputProps={{
                ...params.inputProps,
                style: { fontSize: "7px", minWidth: "60px" }
              }}
            />
          )}
          options={
            machineList.data
              ? machineList.data.machines.map(
                  (e: { machine: string }) => e.machine
                )
              : []
          }
        />
      </StyledTableCell>
      <StyledTableCell>
        <Autocomplete
          fullWidth
          disablePortal
          freeSolo
          disableClearable
          ListboxProps={{
            style: {
              fontSize: "7px",
              width: "auto"
            }
          }}
          onChange={(e, newValue) => {
            setPartNo(newValue)
            return handleEditFromData({
              target: { name: "customer", value: newValue }
            })
          }}
          value={editFormData.customer}
          renderInput={(params) => (
            <ThemedTextField
              {...params}
              inputProps={{
                ...params.inputProps,
                style: { fontSize: "7px", minWidth: "60px" }
              }}
            />
          )}
          options={
            customerList.data
              ? customerList.data.customers.map(
                  (e: { customer: string }) => e.customer
                )
              : []
          }
        />
      </StyledTableCell>
      <StyledTableCell>
        <Autocomplete
          fullWidth
          disablePortal
          freeSolo
          disableClearable
          ListboxProps={{
            style: {
              fontSize: "7px",
              width: "auto"
            }
          }}
          onChange={(e, newValue) => {
            setPartNo(newValue)
            const part = partNoList.data
              ? partNoList.data.parts.find(
                  (e: { partNo: string }) => e.partNo === newValue
                )
              : ""

            setPartName(part?.partName)
            return handleEditFromData({
              target: {
                name: "partNo",
                value: newValue,
                partName: part?.partName || ""
              }
            })
          }}
          value={editFormData.partNo}
          renderInput={(params) => (
            <ThemedTextField
              {...params}
              inputProps={{
                ...params.inputProps,
                style: { fontSize: "7px", minWidth: "60px" }
              }}
            />
          )}
          options={
            partNoList.data
              ? partNoList.data.parts.map((e: { partNo: string }) => e.partNo)
              : []
          }
        />
      </StyledTableCell>
      <StyledTableCell>
        <ThemedTextField
          value={partName}
          inputProps={{
            style: {
              fontSize: "7px",
              minWidth: "70px"
            }
          }}
          name="partName"
        />
      </StyledTableCell>
      <StyledTableCell>
        <Autocomplete
          fullWidth
          disablePortal
          freeSolo
          disableClearable
          ListboxProps={{
            style: {
              fontSize: "7px",
              width: "auto"
            }
          }}
          onChange={(e, newValue) => {
            setStep(newValue)
            const process = processData?.find(
              (e) => e.processOrder === newValue
            )
            setProcess(process?.process || "")

            return handleEditFromData({
              target: {
                name: "step",
                value: newValue,
                process: process?.process || ""
              }
            })
          }}
          value={step}
          renderInput={(params) => (
            <ThemedTextField
              {...params}
              inputProps={{
                ...params.inputProps,
                style: { fontSize: "7px", minWidth: "60px" }
              }}
            />
          )}
          options={stepList}
        />
      </StyledTableCell>
      <StyledTableCell>
        <ThemedTextField
          value={process}
          inputProps={{
            style: {
              fontSize: "7px",
              minWidth: "70px"
            }
          }}
          name="process"
        />
      </StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <TimePicker
            OpenPickerButtonProps={{
              style: {
                fontSize: "7px",
                width: "30px"
              }
            }}
            ampm={false}
            views={["hours", "minutes"]}
            inputFormat="HH:mm"
            value={startWorkingTime}
            onChange={(newSelectTime) => {
              setStartWorkingTime(newSelectTime ? newSelectTime : 0)
              const totalWorkingTime = calculateTotalRealTime(
                startWorkingTime,
                endWorkingTime,
                startWorkingTimeOT,
                endWorkingTimeOT
              )
              const totalQuantity = jopPerHour * totalWorkingTime
              return handleEditFromData({
                target: {
                  name: "startTime",
                  value: moment(newSelectTime).format("HH:mm"),
                  totalHour: totalWorkingTime,
                  totalQuantity
                }
              })
            }}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                size={"small"}
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: "12px", minWidth: "30px" }
                }}
              />
            )}
          />
        </LocalizationProvider>
      </StyledTableCell>
      <StyledTableCell>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <TimePicker
            OpenPickerButtonProps={{
              style: {
                fontSize: "7px",
                width: "30px"
              }
            }}
            ampm={false}
            views={["hours", "minutes"]}
            inputFormat="HH:mm"
            value={endWorkingTime}
            onChange={(newSelectTime) => {
              setEndWorkingTime(newSelectTime ? newSelectTime : 0)
              const totalWorkingTime = calculateTotalRealTime(
                startWorkingTime,
                endWorkingTime,
                startWorkingTimeOT,
                endWorkingTimeOT
              )
              const totalQuantity = jopPerHour * totalWorkingTime
              return handleEditFromData({
                target: {
                  name: "endTime",
                  value: moment(newSelectTime).format("HH:mm"),
                  totalHour: totalWorkingTime,
                  totalQuantity
                }
              })
            }}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                size={"small"}
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: "12px", minWidth: "30px" }
                }}
              />
            )}
          />
        </LocalizationProvider>
      </StyledTableCell>
      <StyledTableCell>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <TimePicker
            OpenPickerButtonProps={{
              style: {
                fontSize: "7px",
                width: "30px"
              }
            }}
            ampm={false}
            views={["hours", "minutes"]}
            value={startWorkingTimeOT || null}
            inputFormat="HH:mm"
            onChange={(newSelectTime) => {
              setStartWorkingTimeOT(newSelectTime ? newSelectTime : 0)
              const totalQuantity = jopPerHour * totalWorkingTime
              return handleEditFromData({
                target: {
                  name: "startTimeOT",
                  value: moment(newSelectTime).format("HH:mm"),
                  totalHour: totalWorkingTime,
                  totalQuantity
                }
              })
            }}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                size={"small"}
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: "12px", minWidth: "30px" }
                }}
              />
            )}
          />
        </LocalizationProvider>
      </StyledTableCell>
      <StyledTableCell>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <TimePicker
            OpenPickerButtonProps={{
              style: {
                fontSize: "7px",
                width: "30px"
              }
            }}
            ampm={false}
            views={["hours", "minutes"]}
            inputFormat="HH:mm"
            value={endWorkingTimeOT || null}
            onChange={(newSelectTime) => {
              setEndWorkingTimeOT(newSelectTime ? newSelectTime : 0)
              const totalQuantity = jopPerHour * totalWorkingTime
              return handleEditFromData({
                target: {
                  name: "endTimeOT",
                  value: moment(newSelectTime).format("HH:mm"),
                  totalHour: totalWorkingTime,
                  totalQuantity
                }
              })
            }}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                size={"small"}
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: "12px", minWidth: "30px" }
                }}
              />
            )}
          />
        </LocalizationProvider>
      </StyledTableCell>
      <StyledTableCell>
        <ThemedTextField
          value={totalWorkingTime}
          inputProps={{
            style: {
              fontSize: "7px",
              minWidth: "70px"
            }
          }}
          name="totalHour"
        />
      </StyledTableCell>
      <StyledTableCell>
        <ThemedTextField
          onChange={(e) => {
            setJph(+e.target.value)
            const totalQuantity = +e.target.value * totalWorkingTime
            return handleEditFromData({
              target: {
                name: e.target.name,
                value: e.target.value,
                totalQuantity: totalQuantity
              }
            })
          }}
          value={jopPerHour}
          inputProps={{
            style: {
              fontSize: "7px",
              minWidth: "70px"
            }
          }}
          name="jph"
        />
      </StyledTableCell>
      <StyledTableCell>
        <ThemedTextField
          onChange={(e) => {
            setRemarks(e.target.value)
            return handleEditFromData
          }}
          value={totalQuantity}
          inputProps={{
            style: {
              fontSize: "7px",
              minWidth: "70px"
            }
          }}
          name="totalQuantity"
        />
      </StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>

      <StyledTableCell>
        <ThemedTextField
          onChange={() => {
            return handleEditFromData
          }}
          value={remarks}
          inputProps={{
            style: {
              fontSize: "7px",
              minWidth: "70px"
            }
          }}
          name="totalQuantity"
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}
export default EditTableRow
