import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"

interface IToolingProps {
  brokenType: string
  setBrokenType: (brokenType: string) => void
  open: boolean
  setOpenDialog: (showPdf: boolean) => void
}

export const typeList = [
  "Guide Pins and Bushings",
  "Punches and Pierce",
  "Buttons",
  "Springs",
  "Bolt",
  "Striper",
  "Insert"
]

const DialogToolingBroken = (props: IToolingProps): JSX.Element => {
  const { brokenType, open, setBrokenType, setOpenDialog } = props

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        keepMounted
        maxWidth="md"
        onClose={() => setOpenDialog(false)}
      >
        <DialogContent>
          <DialogTitle>ประเภทการชำรุด</DialogTitle>
          <Autocomplete
            value={brokenType}
            fullWidth
            onChange={(event, selectedCustomer) => {
              setBrokenType(selectedCustomer as string)
            }}
            options={typeList}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                label="ประเภทการชำรุด"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <ThemedButton
            variant="contained"
            style={{ backgroundColor: "blue" }}
            onClick={() => {
              setOpenDialog(false)
            }}
          >
            Submit
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default DialogToolingBroken
