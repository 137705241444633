import { Link, Menu, MenuItem, Typography } from "@mui/material"
import AuthContext from "../../context/AuthContext"
import { checkRole } from "../MainApp"
import { useContext } from "react"
interface ICustomNavbarMenuProps {
  anchorElement: HTMLElement | null
  removeWhiteSpaces: (menuItemName: string) => string
  handleMenuClose: () => void
  menuList: string[]
}

const CustomNavbarMenu: React.FC<ICustomNavbarMenuProps> = (
  props: ICustomNavbarMenuProps
) => {
  const { anchorElement, handleMenuClose, menuList, removeWhiteSpaces } = props
  const clearToken = () => {
    window.localStorage.clear()
  }

  const authContext = useContext(AuthContext)
  const token = authContext.token

  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={Boolean(anchorElement)}
      onClose={handleMenuClose}
    >
      {menuList.map((menuItem, i) =>
        menuItem === "Log Out" ? (
          <Link href="/" underline="none" style={{ color: "black" }} key={i}>
            <MenuItem value={menuItem} onClick={() => clearToken()}>
              <Typography textAlign="center">{menuItem}</Typography>
            </MenuItem>
          </Link>
        ) : menuItem === "Sign Up" && !checkRole(["99"], token) ? (
          <></>
        ) : (
          <Link
            href={
              menuItem === "QC ใบอนุมัติทำลาย"
                ? "QCDemolish"
                : menuItem === "Edit | Cancel Scanner"
                ? "EditCancelScanner"
                : removeWhiteSpaces("#/" + menuItem)
            }
            underline="none"
            style={{ color: "black" }}
            key={i}
          >
            <MenuItem value={menuItem}>
              <Typography textAlign="center">{menuItem}</Typography>
            </MenuItem>
          </Link>
        )
      )}
    </Menu>
  )
}

export default CustomNavbarMenu
