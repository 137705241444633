import { useMutation, useQuery } from "@apollo/client"
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from "@mui/material"
import { useMemo, useState } from "react"
import swal from "sweetalert"
import { EDIT_PART_PROCESS } from "../../../common/Mutation/MasterMutation"
import { GET_LINE } from "../../../common/Query/GeneralQuery"
import {
  GET_MACHINES,
  GET_PARTS,
  GET_PARTS_PROCESS
} from "../../../common/Query/MasterQuery"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { makeMachineList, makePartNoList } from "../PartsProcess"

interface IPartsProcess {
  partNo: string
  processOrder: string
  process: string
  machine: string
  line: string
  stepProcess: number
  jph: number
  minStroke: number
  operator: string
}

interface IPropsEditPartProcess {
  setOpenEditPartProcess: (boolean: boolean) => void
  openEditPartProcess: boolean
}

const EditPartProcess: React.FC<IPropsEditPartProcess> = (
  props: IPropsEditPartProcess
) => {
  const { setOpenEditPartProcess, openEditPartProcess } = props
  const getPart = useQuery(GET_PARTS)
  const getMachines = useQuery(GET_MACHINES)
  const getLine = useQuery(GET_LINE)
  const partNoList = useMemo(() => makePartNoList(getPart), [getPart])
  const machineList = useMemo(() => makeMachineList(getMachines), [getMachines])
  const { data } = useQuery(GET_PARTS_PROCESS)
  const [partNo, setPartNo] = useState<string>("")
  const [machine, setMachine] = useState<string>("")
  const [jobsPerHour, setJobsPerHour] = useState<number>(0)
  const [minStroke, setMinStroke] = useState<number>(0)
  const [processList, setProcessList] = useState<string[]>([])
  const [process, setProcess] = useState<string>("")
  const [operator, setOperator] = useState<string>("")
  const [line, setLine] = useState<string>("")
  const [stepProcess, setStepProcess] = useState<number>(0)
  const [error, setError] = useState<boolean>(false)
  let lineList = []
  if (getLine.data) {
    lineList = getLine.data.getLine.map(
      (element: { lineName: string }) => element.lineName
    )
  }

  const [updatePartProcess, { loading }] = useMutation(EDIT_PART_PROCESS, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PARTS_PROCESS }]
  })
  useMemo(() => {
    const filterPart = data?.partsprocessings?.filter(
      (entry: IPartsProcess) => {
        return entry.partNo === partNo
      }
    )
    const mapProcessList =
      filterPart?.map(
        ({ processOrder }: { processOrder: string }) => processOrder
      ) || []
    setProcessList(mapProcessList)

    if (process) {
      const filterProcess = filterPart?.find((entry: IPartsProcess) => {
        return entry.partNo === partNo && entry.processOrder === process
      })
      if (filterProcess) {
        setMachine(filterProcess.machine)
        setJobsPerHour(filterProcess.jph)
        setMinStroke(filterProcess.minStroke)
        setLine(filterProcess.line)
        setStepProcess(filterProcess.stepProcess)
        setOperator(filterProcess.operator)
      }
    }
  }, [partNo, process])

  const handleSubmit = async () => {
    if (
      !partNo ||
      !process ||
      !machine ||
      !jobsPerHour ||
      !minStroke ||
      !line ||
      !stepProcess ||
      !operator
    ) {
      setError(true)
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
    try {
      await updatePartProcess({
        variables: {
          partNo: partNo,
          processOrder: process,
          machine: machine,
          jph: jobsPerHour,
          minStroke: minStroke,
          line: line,
          stepProcess: stepProcess,
          operator: operator
        }
      })
      swal("Success", "Edit Part Process Successful", "success")
      setOpenEditPartProcess(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openEditPartProcess}
        onClose={() => setOpenEditPartProcess(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Edit Part Process
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                value={partNo}
                disableClearable
                disablePortal
                onChange={(event, selectedPartNo) => {
                  setPartNo(selectedPartNo)
                }}
                options={partNoList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Part No."
                    value={partNo}
                    error={!partNo && error}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                value={process}
                onChange={(event, selectedProcess) => {
                  setProcess(selectedProcess ? selectedProcess : "")
                }}
                options={processList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Process"
                    value={process}
                    error={!process && error}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                value={machine}
                disableClearable
                disablePortal
                onChange={(event, selectedPartNo) => {
                  setMachine(selectedPartNo)
                }}
                options={machineList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Machine"
                    value={machine}
                    error={!machine && error}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Jobs Per Hour"
                type="number"
                value={jobsPerHour}
                onChange={(event) => {
                  setJobsPerHour(+event.target.value)
                }}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Min Stroke"
                type="number"
                value={minStroke}
                onChange={(event) => {
                  setMinStroke(+event.target.value)
                }}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                value={line}
                disableClearable
                disablePortal
                onChange={(event, selectedLine) => {
                  setLine(selectedLine)
                }}
                options={lineList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Line"
                    value={line}
                    error={!line && error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Step Process"
                type="number"
                value={stepProcess ? stepProcess : ""}
                onChange={(event) => {
                  setStepProcess(+event.target.value)
                }}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Operator"
                type="text"
                value={operator ? operator : ""}
                onChange={(event) => {
                  setOperator(event.target.value)
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenEditPartProcess(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default EditPartProcess
