import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Menu,
  Theme,
  useTheme
} from "@mui/material"
import React, { useCallback } from "react"
import FilterListIcon from "@mui/icons-material/FilterList"
import ThemedCheckbox from "./ThemedComponents/ThemedCheckbox"

interface IFilterChartButtonProps {
  buttonText: string
  filterChartList: FilterChart[]
  setFilterChartList: (chartList: FilterChart[]) => void
  onResetChartLayout: () => void
  onResetChartList: () => void
}

export interface FilterChart {
  name: string
  checked: boolean
}
interface ICheckTaskProps {
  labelName: string
  chartIndex: number
  filterChartList: FilterChart[]
  setFilterChartList: (chartList: FilterChart[]) => void
}

const useStyles = (theme: Theme) => ({
  filterList: {
    paddingTop: "5px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  filterButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    }
  }
})

const FilterChartButton: React.FC<IFilterChartButtonProps> = (
  props: IFilterChartButtonProps
) => {
  const {
    buttonText,
    filterChartList,
    setFilterChartList,
    onResetChartLayout,
    onResetChartList
  } = props
  const theme = useTheme()
  const classes = useStyles(theme)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const CheckTask: React.FC<ICheckTaskProps> = useCallback(
    (props: ICheckTaskProps) => {
      const { labelName, chartIndex, filterChartList, setFilterChartList } =
        props

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFilterChartList = filterChartList.map(
          (chart: FilterChart, checkedIndex: number) => {
            if (chartIndex === checkedIndex) {
              return { ...chart, checked: event.target.checked }
            } else {
              return chart
            }
          }
        )
        window.localStorage.setItem(
          "filter-chart-list",
          JSON.stringify(newFilterChartList)
        )
        setFilterChartList(newFilterChartList)
      }

      return (
        <FormControlLabel
          style={{ paddingRight: "12px", paddingLeft: "12px" }}
          id={`chart${chartIndex}`}
          control={
            <ThemedCheckbox
              checked={filterChartList[chartIndex].checked}
              onChange={handleChange}
            />
          }
          label={labelName}
        />
      )
    },
    [theme.palette.mode]
  )

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const charts = filterChartList.map(
    (chart: FilterChart, chartIndex: number) => {
      return (
        <CheckTask
          key={filterChartList[chartIndex].name}
          labelName={chart.name}
          filterChartList={filterChartList}
          chartIndex={chartIndex}
          setFilterChartList={setFilterChartList}
        />
      )
    }
  )

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        onClick={handleClick}
        sx={{
          color: theme.palette.mode === "light" ? "#000000" : "#ffffff"
        }}
        endIcon={<FilterListIcon />}
      >
        {buttonText}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          style: {
            padding: 0,
            margin: 0
          }
        }}
      >
        <FormGroup
          sx={{
            ...classes.filterList
          }}
        >
          {charts}
          <Button
            onClick={onResetChartLayout}
            sx={{
              ...classes.filterButton,
              color: theme.palette.secondary.contrastText,
              "&:hover": { backgroundColor: theme.palette.secondary.light }
            }}
          >
            Reset Layout
          </Button>
          <Button
            onClick={onResetChartList}
            sx={{
              ...classes.filterButton,
              color: theme.palette.secondary.contrastText,
              "&:hover": { backgroundColor: theme.palette.secondary.light }
            }}
          >
            Reset Filter
          </Button>
          <Button
            sx={{
              ...classes.filterButton,
              color: theme.palette.secondary.contrastText,
              "&:hover": { backgroundColor: theme.palette.secondary.light }
            }}
          >
            Reset All
          </Button>
        </FormGroup>
      </Menu>
    </Box>
  )
}

export default FilterChartButton
