import { gql } from "@apollo/client"

export const GET_ZINC_RACK = gql`
  query MasterHanger {
    masterHanger {
      customer
      model
      partNo
      partName
      wt
      adm
      spec
      volt
      small
      big
      twoSump
      min
      colour
      packing
    }
  }
`

export const GET_ZINC_BARREL = gql`
  query MasterBarrel {
    masterBarrel {
      customer
      model
      partNo
      partName
      wt
      adm
      spec
      kg
      pcs
      chromatting
      colour
      packing
    }
  }
`

export const GET_EDP_COATING = gql`
  query MasterEDP {
    masterEDP {
      customer
      model
      partNo
      partName
      wt
      adm
      spec
      jig
      round
      volt
      oc
      min
      packing
    }
  }
`

export const GET_PLATING_REPORT = gql`
  query PlatingReport(
    $dateFrom: String
    $dateTo: String
    $line: String
    $shift: String
  ) {
    platingReport(
      dateFrom: $dateFrom
      dateTo: $dateTo
      line: $line
      shift: $shift
    ) {
      customer
      partNo
      partName
      model
      spec
      wt
      adm
      round
      actionDate
      quantity
      kg
      useRound
      pdLotNo
      thicknessData {
        firstPieceThickness
        secondPieceThickness
        thirdPieceThickness
      }
      reworkPlatingData {
        detail
        quantity
      }
    }
  }
`

export const GET_PLATING_DAILY_REPORT = gql`
  query PlatingDailyReport($line: String) {
    platingDailyReport(line: $line) {
      customer
      partNo
      partName
      model
      spec
      wt
      adm
      round
      factory
      quantity
      adjust
    }
  }
`

export const GET_SUMMARY_DAILY_PLATING_REPORT = gql`
  query SummaryPlatingDailyReport($dateFrom: String, $dateTo: String) {
    summaryPlatingDailyReport(dateFrom: $dateFrom, dateTo: $dateTo) {
      type
      round
      plan
      quantity
      productivity
      targetProductivity
      judgmentProductivity
      rework
      reworkPercent
      targetDefect
      judgmentDefect
    }
  }
`

export const GET_TARGET_PLATING = gql`
  query GetTargetPlating($planDate: String) {
    getTargetPlating(planDate: $planDate) {
      line
      targetProductivity
      targetDefect
      year
      plan
      planDate
    }
  }
`
