import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import { ChangeEvent } from "react"

export type IProps = {
  text: string
  inputType: "string" | "number" | "date" | "password"
  setValue: (event: ChangeEvent<HTMLInputElement>) => void
  sm?: number
  xs?: number
  onKeyDown?: (event: { code: string }) => void
  fullWidth?: boolean
  error?: boolean
  helperText?: string
  value?: string | number
}

const Input: React.FC<IProps> = (props: IProps) => {
  const {
    text,
    inputType,
    setValue,
    sm = 12,
    xs = 12,
    onKeyDown,
    fullWidth = false,
    error = false,
    helperText,
    value
  } = props

  return (
    <>
      <Grid item xs={xs} sm={sm}>
        <TextField
          value={value}
          fullWidth={fullWidth}
          InputLabelProps={{ shrink: true }}
          // inputProps={{min: 0, style:{ width: "100%", textAlign:"center" }}}
          onChange={setValue}
          type={inputType}
          label={text}
          onKeyDown={onKeyDown}
          error={error}
          helperText={helperText}
        />
        {/* <SelectInputType /> */}
      </Grid>
    </>
  )
}

export default Input
