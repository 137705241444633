import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import exporting from "highcharts/modules/exporting.js"
import { useRef } from "react"
import { LocalizationProvider, DatePicker } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useTheme
} from "@mui/material"
import moment from "moment"
import DateAdapter from "@mui/lab/AdapterMoment"
import { IOEEMonthly } from "../hook/useFetchData"

exporting(Highcharts)
interface IOeeReport {
  line: string
  oee: number
}
export const ChartOEE = (props: {
  chart?: HighchartsReact.Props
  oeeMonthly: IOEEMonthly[] | undefined
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const { oeeMonthly } = props
  if (!oeeMonthly?.length) return <label>...Loading</label>

  const theme = useTheme()
  const options: Highcharts.Options = {
    title: {
      text: "OEE Monthly",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    credits: { enabled: false },
    chart: {
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    xAxis: {
      type: "category",
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "8px"
        }
      }
    },
    yAxis: [
      {
        labels: {
          style: {
            color: theme.palette.secondary.contrastText
          }
        },
        title: {
          text: "Total",
          style: {
            color: theme.palette.secondary.contrastText
          }
        }
      },
      {
        opposite: true,
        title: {
          text: "Target",
          style: {
            color: theme.palette.secondary.contrastText
          }
        }
      }
    ],
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y:.1f}%"
        },
        events: {
          mouseOver: () => {
            return (options.chart = {
              backgroundColor: "red"
            })
          },
          mouseOut: () => {
            return (options.chart = {
              backgroundColor: "white"
            })
          }
        }
      },
      line: {
        dataLabels: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: "Browsers",
        type: "column",
        colorByPoint: true,
        data:
          oeeMonthly.map((e: IOeeReport) => {
            return {
              name: e.line,
              y: e.oee
            }
          }) || []
      }
    ],
    exporting: { enabled: false }
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props.chart}
    />
  )
}
interface IOEEProp {
  oeeMonthly: IOEEMonthly[] | undefined
  dateFrom: string
  setDateFrom: (date: string) => void
  dateTo: string
  setDateTo: (date: string) => void
  dialogOpen: boolean
  setDialogOpen: (open: boolean) => void
}

const OEEMonthly = (props: IOEEProp): JSX.Element => {
  const {
    dialogOpen,
    setDialogOpen,
    oeeMonthly,
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo
  } = props

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        >
          OEE Monthly
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12} justifyContent={"center"} margin={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="Date From"
                  // views={["year", "month"]}
                  value={new Date(dateFrom)}
                  onChange={(newSelectDate) => {
                    setDateFrom(moment(newSelectDate).format("YYYY-MM-DD"))
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      value={moment(dateFrom).format("DD-MM-YYYY")}
                      inputProps={{
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={3} xs={12} justifyContent={"center"} margin={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="Date To"
                  value={dateTo}
                  onChange={(newSelectDate) => {
                    setDateTo(moment(newSelectDate).format("YYYY-MM-DD"))
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      value={moment(dateTo).format("DD-MM-YYYY")}
                      inputProps={{
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={12} xs={12} justifyContent="center">
              <ChartOEE oeeMonthly={oeeMonthly} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default OEEMonthly
