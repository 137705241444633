import { useMPContext } from "../../../context/MPContext"
import { IMasterPlanPartData, IOrder } from "../MPReport"
import {
  StyledTableCell,
  StyledTableRow,
  showTableFollowUp
} from "../utilities"

interface IProp {
  index: number
  order: IOrder[]
  partData: IMasterPlanPartData
  partNo: string
  plating: boolean
  negativePartFollowUpF1: boolean
  followUp: boolean
}

export const TableRowFollowUp: React.FC<IProp> = (props: IProp) => {
  const {
    index,
    order,
    partNo,
    partData,
    plating,
    negativePartFollowUpF1,
    followUp
  } = props
  const { maxDueDate, orderCheck, factory } = useMPContext()
  return (
    <>
      <StyledTableRow
        className={index % 2 === 0 ? "display-border" : undefined}
        key={"row-fol-f2" + index + partNo}
        style={
          showTableFollowUp(
            partData.customer,
            orderCheck,
            factory,
            plating,
            negativePartFollowUpF1,
            followUp
          )
            ? {}
            : { display: "none" }
        }
      >
        <StyledTableCell style={{ padding: "7px" }} colSpan={1} rowSpan={2}>
          <>{0}</>
        </StyledTableCell>
        <StyledTableCell style={{ padding: "7px" }} colSpan={1} rowSpan={2}>
          <>{partData.stockBegin.balanceFollowUp || 0}</>
        </StyledTableCell>
        <StyledTableCell style={{ padding: "7px" }} colSpan={1} rowSpan={1}>
          <>Actual Follow Up F2</>
        </StyledTableCell>
        {Array.from(Array(maxDueDate.length), (num, i) => {
          if (order[i]) {
            return (
              <StyledTableCell
                colSpan={1}
                rowSpan={1}
                key={"cel-fol-f2" + i + partNo}
              >
                <>{order[i].actualProductionFollowUp}</>
              </StyledTableCell>
            )
          } else {
            return (
              <StyledTableCell
                colSpan={1}
                rowSpan={1}
                key={"cel-fol-f2" + i + partNo}
              >
                <>{0}</>
              </StyledTableCell>
            )
          }
        })}
      </StyledTableRow>
      <StyledTableRow
        className={index % 2 === 0 ? "display-border" : undefined}
        key={"row-stock-bal-f2" + index + partNo}
      >
        <StyledTableCell
          style={{
            padding: "7px",
            whiteSpace: "nowrap"
          }}
          colSpan={1}
          rowSpan={1}
        >
          <>Stock bal. (follow up) F2</>
        </StyledTableCell>
        {Array.from(Array(maxDueDate.length), (num, i) => {
          if (order[i]) {
            return (
              <StyledTableCell
                colSpan={1}
                rowSpan={1}
                key={"cel-stock-bal-f2" + i + partNo}
              >
                <span
                  style={order[i].balActualFollowUp < 0 ? { color: "red" } : {}}
                >
                  {order[i].balActualFollowUp}
                </span>
              </StyledTableCell>
            )
          } else {
            return (
              <StyledTableCell
                colSpan={1}
                rowSpan={1}
                key={"cel-stock-bal-f2" + i + partNo}
              >
                <>{0}</>
              </StyledTableCell>
            )
          }
        })}
      </StyledTableRow>
    </>
  )
}
