import React, { ReactElement, useState } from "react"

import { Grid, Autocomplete, useTheme, ThemeProvider } from "@mui/material"
import { GET_PROCESS_ORDER } from "../../common/Query/engineerQuery"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { GET_PARTS } from "../../common/Query/MasterQuery"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import moment from "moment"
import { PM_RESET } from "../../common/Mutation/engineerMutation"
import AddIcon from "@mui/icons-material/Add"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

const pageTitle = "PM Reset"

const PMreset: React.FC = (): ReactElement => {
  const [partNo, setPartNo] = useState<string | null>(null)
  const [process, setProcess] = useState<string | null>(null)
  const [processList, setProcessList] = useState<Array<string>>([])
  const [maintenanceDate, setMaintenanceDate] = useState<Date>(new Date())

  const [getProcess] = useLazyQuery(GET_PROCESS_ORDER)
  const [pmReset, { loading }] = useMutation(PM_RESET)
  const getPart = useQuery(GET_PARTS)
  let partNoList: Array<string> = []
  partNoList = getPart.data
    ? getPart.data.parts.map((e: { partNo: string }) => e.partNo)
    : []

  async function handleChange(partNo: string | null) {
    if (partNo) {
      const data = await getProcess({
        variables: {
          partNo: partNo
        }
      })
      setPartNo(partNo)
      setProcess("")
      setProcessList(
        data
          ? data.data.processPart.map(
              (e: { processOrder: string }) => e.processOrder
            )
          : []
      )
    } else {
      setProcessList([])
      setPartNo("")
      setProcess("")
    }
  }
  const submit = async () => {
    const date = moment(maintenanceDate).format("YYYY-MM-DD")
    try {
      await pmReset({
        variables: {
          partNo,
          process,
          date
        }
      })
      swal("Success", "บัททึกสำเร็จ", "success")
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "error")
    }
  }

  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Engineer}
        menuItemName={Object.keys(menuItemList)[5]}
        menuPageName={pageTitle}
      />{" "}
      <ThemedCard>
        <PageLabelCard
          title="Engineer PM Reset"
          subTitle="detail about engineer pm reset"
        />
        <Grid container spacing={2} margin={2} width={"98%"}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              disablePortal
              id="partNo"
              value={partNo}
              loading={partNoList.length === 0}
              defaultValue={""}
              options={partNoList}
              style={{ width: "100%" }}
              sx={{ width: 100 }}
              onChange={(e, newValue) => handleChange(newValue)}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Part No."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Autocomplete
              disablePortal
              id="process"
              value={process}
              defaultValue={""}
              options={processList}
              style={{ width: "100%" }}
              sx={{ width: 100 }}
              onChange={(e, newValue) => setProcess(newValue)}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="process"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                views={["year", "month", "day"]}
                inputFormat="DD-MM-YYYY"
                value={maintenanceDate}
                onChange={(newMaintenanceDate) => {
                  setMaintenanceDate(
                    moment(newMaintenanceDate).toDate() as Date
                  )
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    fullWidth
                    label="Maintenance Date"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={2}>
            <ThemedLoadingButton
              variant="contained"
              style={{
                backgroundColor: theme.palette.primary.main,
                color: "white"
              }}
              onClick={submit}
              loading={loading}
              endIcon={<AddIcon />}
            >
              SUBMIT
            </ThemedLoadingButton>
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default PMreset
