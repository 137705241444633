import { useLazyQuery, useQuery } from "@apollo/client"
import {
  Box,
  Grid,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  useTheme,
  tableCellClasses
} from "@mui/material"
import moment from "moment"
import React, { useMemo, useState } from "react"
import { CSVLink } from "react-csv"
import swal from "sweetalert"
import { GET_MATERIALS, GET_PARTS } from "../../common/Query/MasterQuery"
import { GET_INVENTORY_MATERIAL_REPORT } from "../../common/Query/MaterialQuery"
import AutocompleteCustomer from "../../common/Resources/AutocompleteCustomer"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { formatNumber, logoTable } from "../../common/utilities"
import "../../css/Materials/Inventory.css"
import MultiChart from "./chartComponent/ColumnChart"

interface Column {
  id:
    | "matNo"
    | "matName"
    | "customer"
    | "spec"
    | "sizeH"
    | "sizeW"
    | "sizeL"
    | "weightKg"
    | "balActual"
    | "inventoryCost"
    | "remarks"
    | "balanceMaterial"
    | "maxStock"
    | "minStock"
    | "saleOrder"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

interface Data {
  balActual: number
  balanceMaterial: number
  matName: string
  matNo: string
  maxStock: number
  minStock: number
  partType: string
  ratio: number
  sizeH: string
  sizeL: string
  sizeW: string
  spec: string
  customer: string
  remarks: string
  saleOrder: number
  factory: string
  model: string
  inventoryCost: number
  weightKg: number
}

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {},

  "td, th": {
    border: 0,
    borderBottom: "1px solid black"
  }
}))

const columns: Column[] = [
  { id: "matNo", label: "Mat No.", minWidth: 20 },
  { id: "matName", label: "Mat Name", minWidth: 100 },
  { id: "customer", label: "Customer", minWidth: 50 },
  { id: "spec", label: "Spec", minWidth: 50 },
  { id: "sizeH", label: "Size H", minWidth: 50 },
  { id: "sizeW", label: "Size W", minWidth: 50 },
  { id: "sizeL", label: "Size L", minWidth: 50 },
  { id: "balanceMaterial", label: "ยอดคงเหลือ(sheet/kg)", minWidth: 50 },
  { id: "weightKg", label: "น้ำหนัก(kg)", minWidth: 50 },
  { id: "balActual", label: "จำนวนที่ผลิตได้(pcs.)", minWidth: 50 },
  { id: "inventoryCost", label: "Inventory Cost", minWidth: 50 },
  { id: "saleOrder", label: "Sale Order", minWidth: 50 },
  { id: "minStock", label: "Min Stock(6 วัน)", minWidth: 100 },
  { id: "maxStock", label: "Max Stock(12 วัน)", minWidth: 100 },
  { id: "remarks", label: "remarks", minWidth: 100 }
]

const pageTitle = "Material Inventory Report"

const InventoryMaterial: React.FC = (): JSX.Element => {
  const theme = useTheme()
  const [partNo, setPartNo] = useState<string | null>("")
  const [supplierType, setSupplierType] = useState<string>("")
  const [remarks, setRemarks] = useState<string>("")
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [factory, setFactory] = useState<string>("1")
  const [customer, setCustomer] = useState<string>("")
  const [model, setModel] = useState<string | null>("")
  const [error, setError] = useState<boolean>(false)
  const materialQuery = useQuery(GET_MATERIALS)
  const partQuery = useQuery(GET_PARTS)
  const [inventoryReport, { data, loading }] = useLazyQuery(
    GET_INVENTORY_MATERIAL_REPORT
  )
  const remarkList: string[] = ["Over Max 12 Day", "Loss Min 6  Day", "-"]
  let partNoList: Array<string> = []
  let modelList: Array<string> = []

  const matNoType: string[] = useMemo(() => {
    return Array.from(
      new Set(
        materialQuery?.data?.materials.map(
          ({ partType }: { partType: string }) => {
            return partType
          }
        )
      )
    )
  }, [materialQuery])

  let rows: Data[] = []
  const total = {
    inventoryMaterial: 0,
    inventoryMaterialWeightKg: 0,
    totalSaleOrder: 0,
    totalInventoryCost: 0,
    date
  }
  if (customer && partQuery.data) {
    const partData = partQuery.data?.parts.filter(
      (part: { customer: string }) => part.customer === customer
    )
    partNoList = partData.map((e: { partNo: string }) => e.partNo)

    modelList = Array.from(
      new Set(partData.map((e: { model: string }) => e.model))
    )
  }
  if (materialQuery.data && data && partQuery.data) {
    rows = data.inventoryMaterialReport.map((e: Data) => {
      const sheetDensity = 7.85
      const unitConversionToKg = 1000000
      const matPrice = 38

      const materialData = materialQuery.data.materials.find(
        (mat: { matNo: string }) => mat.matNo.includes(e.matNo.trim())
      )

      const partData = partQuery.data.parts.find(
        (mat: { partNo: string }) => mat.partNo === e.matNo.trim()
      )
      const balActual = materialData?.ratio * e.balanceMaterial

      const inventoryCostCalculation = (partType: string): number => {
        if (partType === "Sheet") {
          const result =
            ((materialData?.sizeH *
              materialData?.sizeW *
              materialData?.sizeL *
              sheetDensity) /
              unitConversionToKg) *
            e.balanceMaterial *
            matPrice
          return isNaN(result) ? 0 : result
        } else if (partType === "Coil") {
          return e.balanceMaterial * matPrice
        } else if (partType === "Component") {
          return e.balanceMaterial * 1
        } else {
          return 0
        }
      }

      const weightCalculation = (partType: string): number => {
        if (partType === "Sheet") {
          const result =
            ((materialData?.sizeH *
              materialData?.sizeW *
              materialData?.sizeL *
              sheetDensity) /
              unitConversionToKg) *
            e.balanceMaterial
          return isNaN(result) ? 0 : result
        } else if (partType === "Coil") {
          return e.balanceMaterial
        } else if (partType === "Component") {
          return e.balanceMaterial * 0.002
        } else {
          return 0
        }
      }

      return {
        ...e,
        partType: materialData?.partType,
        matName: materialData?.matName,
        customer: partData?.customer,
        model: partData?.model,
        spec: materialData?.spec,
        sizeH: materialData?.sizeH,
        sizeW: materialData?.sizeW,
        sizeL: materialData?.sizeL,
        weightKg: weightCalculation(materialData?.partType),
        balActual: parseFloat(balActual.toFixed(2)) || 0,
        inventoryCost: inventoryCostCalculation(materialData?.partType),
        remarks:
          e.saleOrder && e.maxStock <= balActual
            ? remarkList[0]
            : e.saleOrder && e.minStock >= balActual
            ? remarkList[1]
            : remarkList[2]
      }
    })
    if (customer) {
      rows = rows.filter((e) => e.customer === customer)
    }
    if (factory) {
      rows = rows.filter((e) => e.factory === factory)
    }
    if (model) {
      rows = rows.filter((e) => e.model === model)
    }
    if (partNo) {
      rows = rows.filter((e) => e.matNo === partNo)
    }

    if (supplierType) {
      rows = rows.filter((e) => e.partType === supplierType)
    }

    if (remarks) {
      
        rows = rows.filter((e) => e.remarks === remarks)
      
    }

    rows.forEach(
      (e: {
        balActual: number
        saleOrder: number
        inventoryCost: number
        weightKg: number
      }) => {
        total.inventoryMaterial += e.balActual
        total.inventoryMaterialWeightKg += e.weightKg
        total.totalSaleOrder += e.saleOrder
        total.totalInventoryCost += e.inventoryCost
      }
    )
  }

  const isComplete = () => {
    return date !== ""
  }

  const handleSubmit = async () => {
    if (!isComplete()) {
      setError(true)
      return swal("warning", "ไม่พบข้อมูล", "warning")
    }
    await inventoryReport({
      variables: {
        date
      },
      fetchPolicy: "network-only"
    })
  }

  return (
    <div>
      <div className="inventory-print">
        <PageLabel
          menuItem={menuItemList.Materials}
          menuItemName={Object.keys(menuItemList)[1]}
          menuPageName={pageTitle}
        />
        <ThemedCard>
          <PageLabelCard
            title="Inventory Material Report"
            subTitle="detail about Inventory Material Report"
          />
          <>
            <Box component="form">
              <Grid
                container
                spacing={2}
                width={"98%"}
                marginLeft={2}
                marginBottom={2}
              >
                <Grid item xs={12} sm={2}>
                  <ThemedTextField
                    fullWidth
                    error={!date && error}
                    value={date}
                    id="dateFrom"
                    label="From"
                    type="date"
                    onChange={(e) => setDate(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={1.6}>
                  <Autocomplete
                    disablePortal
                    id="partNo"
                    options={["none", "1", "2"]}
                    value={factory}
                    style={{ width: "100%" }}
                    sx={{ width: 300 }}
                    onChange={(e, newValue) => {
                      setFactory(newValue ? newValue : "")
                    }}
                    renderInput={(params) => (
                      <ThemedTextField {...params} label="Factory" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={1.6}>
                  <AutocompleteCustomer
                    setCustomer={setCustomer}
                    customer={customer}
                  />
                </Grid>
                <Grid item xs={12} sm={1.6}>
                  <Autocomplete
                    disablePortal
                    id="Model"
                    options={modelList}
                    disabled={!customer}
                    value={model}
                    style={{ width: "100%" }}
                    sx={{ width: 300 }}
                    onChange={(e, newValue) => {
                      setPartNo("")
                      setSupplierType("")
                      setModel(newValue)
                    }}
                    renderInput={(params) => (
                      <ThemedTextField {...params} label="Model" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Autocomplete
                    disablePortal
                    id="partNo"
                    options={partNoList}
                    style={{ width: "100%" }}
                    disabled={!customer}
                    loading={partNoList.length === 0}
                    sx={{ width: 300 }}
                    value={partNo}
                    onChange={(e, newValue) => {
                      setSupplierType("")
                      setPartNo(newValue)
                    }}
                    renderInput={(params) => (
                      <ThemedTextField {...params} label="Part NO." />
                    )}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Autocomplete
                    disablePortal
                    id="suppliertype"
                    options={matNoType}
                    style={{ width: "100%" }}
                    sx={{
                      width: 300
                    }}
                    value={supplierType}
                    onChange={(e, newValue) => {
                      setSupplierType(newValue ? newValue : "")
                    }}
                    renderInput={(params) => (
                      <ThemedTextField {...params} label="Supplier Type" />
                    )}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Autocomplete
                    disablePortal
                    id="remarks"
                    options={remarkList}
                    style={{ width: "100%" }}
                    sx={{
                      width: 300
                    }}
                    value={remarks}
                    onChange={(e, newValue) => {
                      setRemarks(newValue ? newValue : "")
                    }}
                    renderInput={(params) => (
                      <ThemedTextField {...params} label="Remarks" />
                    )}
                  />
                </Grid>
                <Grid item xs={10}>
                  <ThemedLoadingButton
                    variant="contained"
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    Submit
                  </ThemedLoadingButton>
                  &emsp;
                  <ThemedButton
                    contrast
                    variant="outlined"
                    onClick={() => window.print()}
                  >
                    Print
                  </ThemedButton>
                  <CSVLink
                    data={rows.map((val) => {
                      return {
                        "Part No": val.matNo,
                        "Part Name": val.matName,
                        Customer: val.customer,
                        Spec: val.spec,
                        "Size H": val.sizeH,
                        "Size W": val.sizeW,
                        "Size L": val.sizeL,
                        Inventory: val.balanceMaterial,
                        saleOrder: val.saleOrder
                      }
                    })}
                    filename={`${customer}-${moment(date).format(
                      "DD-MM-YYYY"
                    )}-Mat-Inventory`}
                  >
                    <ThemedButton
                      variant="contained"
                      style={{
                        marginLeft: "15px",
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? "yellowgreen"
                            : "darkgreen"
                      }}
                    >
                      Download CSV
                    </ThemedButton>
                  </CSVLink>
                </Grid>
              </Grid>
            </Box>
          </>
        </ThemedCard>
      </div>
      <ThemedCard>
        <PageLabelCard
          staticColor
          title="Preview Inventory Material Report"
          subTitle="About detail Inventory Material Report"
        />
        <Table className="inventory-table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#fff",
              zIndex: 1
            }}
          >
            <StyledTableRow style={{ borderBottom: "1px solid gray" }}>
              <StyledTableCell
                colSpan={5}
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                <img src={logoTable} alt="" height="80vh" />
              </StyledTableCell>
              <StyledTableCell
                align="center"
                colSpan={5}
                style={{
                  fontWeight: "bold",
                  textAlign: "center"
                }}
              >
                Inventory Material Report
              </StyledTableCell>
              <StyledTableCell
                align="center"
                colSpan={5}
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                F-PL-03-10 Effective Date 1-8-22 Rev.00
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <StyledTableRow hover tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    const value = row[column.id]
                    return (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={
                          column.id === "remarks" &&
                          row.maxStock <= row.balActual
                            ? { color: "red" }
                            : column.id === "remarks" &&
                              row.maxStock >= row.balActual
                            ? { color: "#FF5900" }
                            : { color: "black" }
                        }
                      >
                        {typeof value === "number"
                          ? formatNumber(value)
                          : value}
                      </StyledTableCell>
                    )
                  })}
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>

        <Grid container spacing={2} marginTop={2} className="page-break">
          <Grid item xs={12} sm={8} className="chart-inventory">
            <MultiChart data={total}></MultiChart>
          </Grid>
          <Grid
            item
            sm={4}
            container
            justifyContent={"center"}
            marginTop={"26px"}
          >
            <Table
              style={{
                width: "350px",
                border: "1px solid black",
                height: "300px"
              }}
            >
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      fontSize: "20px"
                    }}
                  >
                    Inventory Material (Pcs.)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "right",
                      fontSize: "20px"
                    }}
                  >
                    {formatNumber(total.inventoryMaterial)}
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      fontSize: "20px"
                    }}
                  >
                    Inventory Material (Kg)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "right",
                      fontSize: "20px"
                    }}
                  >
                    {formatNumber(total.inventoryMaterialWeightKg)}
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      fontSize: "20px"
                    }}
                  >
                    Total Inventory Cost
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "right",
                      fontSize: "20px"
                    }}
                  >
                    {formatNumber(total.totalInventoryCost)}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      fontSize: "20px"
                    }}
                  >
                    Total Sale Order
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "right",
                      fontSize: "20px"
                    }}
                  >
                    {formatNumber(total.totalSaleOrder)}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      fontSize: "20px"
                    }}
                  >
                    Inventory Date
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "right",
                      fontSize: "20px"
                    }}
                  >
                    {moment(date).format("DD-MM-YYYY")}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item sm={8} />
          <Grid item sm={4} container justifyContent={"center"}>
            <Table
              style={{
                width: "350px",
                border: "1px solid black",
                height: "100px"
              }}
            >
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    height={100}
                    width={200}
                    style={{
                      border: "1px solid black"
                    }}
                  ></StyledTableCell>
                  <StyledTableCell
                    height={100}
                    width={200}
                    style={{
                      border: "1px solid black"
                    }}
                  ></StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid black"
                    }}
                  >
                    Approved
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid black"
                    }}
                  >
                    Checked
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </ThemedCard>
    </div>
  )
}
export default InventoryMaterial
