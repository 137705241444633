import { useQuery } from "@apollo/client"
import { Autocomplete } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { GET_PARTS } from "../Query/MasterQuery"
import ThemedTextField from "./ThemedComponents/ThemedTextField"

interface IProps {
  setPartNo: Dispatch<SetStateAction<string>>
  partNo: string
  error?: boolean
}
const AutocompletePartNo = (props: IProps): JSX.Element => {
  const { partNo, setPartNo, error } = props
  const { data, loading } = useQuery(GET_PARTS)

  let partNoList = []
  if (data) {
    partNoList = data.parts.map((part: { partNo: string }) => part.partNo)
  }

  return (
    <Autocomplete
      value={partNo}
      fullWidth
      onChange={(event, selectedPartNo) => {
        setPartNo(selectedPartNo as string)
      }}
      options={partNoList}
      loading={loading}
      renderInput={(params) => (
        <ThemedTextField
          {...params}
          label="Part NO."
          InputLabelProps={{ shrink: true }}
          error={error ? error : false}
        />
      )}
    />
  )
}
export default AutocompletePartNo
