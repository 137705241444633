import { Autocomplete, Grid, ThemeProvider, useTheme } from "@mui/material"
import { useMemo, useState } from "react"
import AutocompleteCustomer from "../../common/Resources/AutocompleteCustomer"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { departMentList, factoryList, filter } from "../../common/utilities"
import AddIcon from "@mui/icons-material/Add"

import { useMutation } from "@apollo/client"
import moment from "moment"
import { CREATE_PREMIUM_FREIGHT } from "../../common/Mutation/QcMutation"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

const PremiumFreight: React.FC = () => {
  const [date, setDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [remarks, setRemarks] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [man, setMan] = useState<number>(0)
  const [hour, setHour] = useState<number>(0)
  const [allowance, setAllowance] = useState<number>(0)
  const [customerClaim, setCustomerClaim] = useState<number>(0)
  const [baht, setBaht] = useState<number>(0)
  const [PremiumFreight, setPremiumFreight] = useState<number>(0)
  const [recordBy, setRecordBy] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [department, setDepartment] = useState<string>("")
  const [status, setStatus] = useState<boolean>(false)

  const pageTitle = "Premium Freight-Man Hour"
  const packingByList = ["วาสนา", "ณัฐ"]
  const [createPremiumFreight, { loading }] = useMutation(
    CREATE_PREMIUM_FREIGHT
  )
  const isComplete = () => {
    return date && customer && customer && department && recordBy
  }

  const isReset = () => {
    setBaht(0)
    setCustomer("")
    setDepartment("")
    setFactory("")
    setRecordBy("")
    setRemarks("")
    setHour(0)
    setMan(0)
    setAllowance(0)
    setCustomerClaim(0)
    setPremiumFreight(0)
  }
  const theme = useTheme()
  const handleSubmit = async () => {
    setStatus(true)
    if (!isComplete())
      return swal("Warning", "กรุณากรอข้อมูลให้ครับถ้วน", "warning")
    const data = {
      actionDate: date,
      cost: baht,
      customer,
      department,
      customerClaim,
      numberOfPeople: man,
      remarks,
      allowance,
      totalHour: hour,
      recordBy,
      factory
    }
    try {
      await createPremiumFreight({
        variables: { data: data }
      })
      swal("Success", "Create Success", "success")
      isReset()
      setStatus(false)
    } catch (error) {
      alert((error as Error).message)
    }
  }

  useMemo(() => {
    if (baht && hour && man) {
      setPremiumFreight(baht * man * hour)
      if (allowance) setPremiumFreight(baht * man * hour + allowance)
    } else {
      setPremiumFreight(0)
    }
  }, [baht, hour, man, allowance])
  return (
    <ThemeProvider theme={theme}>
      <div className="hidden-print">
        <PageLabel
          menuItem={menuItemList.Qc}
          menuItemName={Object.keys(menuItemList)[4]}
          menuPageName={pageTitle}
        />
      </div>
      <ThemedCard>
        <PageLabelCard
          title="Premium Freight"
          subTitle="about detail premium freight"
        />
        <Grid container spacing={2} width={"98%"} padding={2}>
          <Grid item xs={12} sm={3}>
            <ThemedTextField
              value={date}
              fullWidth
              id="date"
              type="date"
              onChange={(e) => setDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <AutocompleteCustomer
              required={true}
              error={status && !customer ? true : false}
              setCustomer={setCustomer}
              customer={customer}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              value={department}
              fullWidth
              onChange={(event, department) => {
                setDepartment(department as string)
              }}
              options={departMentList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  error={status && !department ? true : false}
                  label="Department"
                  required
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              value={factory}
              fullWidth
              onChange={(event, department) => {
                setFactory(department as string)
              }}
              options={factoryList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  required
                  error={status && !factory ? true : false}
                  label="Factory"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <ThemedTextField
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              label="Man"
              id="man"
              type="number"
              onWheel={(event) =>
                event.currentTarget.querySelector("input")?.blur()
              }
              onChange={(e) => setMan(+e.target.value)}
              value={man === 0 ? "" : man}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ThemedTextField
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              label="Hour"
              id="Hour"
              type="number"
              onWheel={(event) =>
                event.currentTarget.querySelector("input")?.blur()
              }
              onChange={(e) => setHour(+e.target.value)}
              value={hour === 0 ? "" : hour}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ThemedTextField
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              label="Baht / Hur."
              id="baht"
              type="number"
              onWheel={(event) =>
                event.currentTarget.querySelector("input")?.blur()
              }
              onChange={(e) => setBaht(+e.target.value)}
              value={baht === 0 ? "" : baht}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ThemedTextField
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              label="เบี้ยเลี้ยง"
              id="allowance"
              type="number"
              onWheel={(event) =>
                event.currentTarget.querySelector("input")?.blur()
              }
              onChange={(e) => setAllowance(+e.target.value)}
              value={allowance === 0 ? "" : allowance}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ThemedTextField
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              label="Customer Claim"
              id="customerClaim"
              type="number"
              onWheel={(event) =>
                event.currentTarget.querySelector("input")?.blur()
              }
              onChange={(e) => setCustomerClaim(+e.target.value)}
              value={customerClaim === 0 ? "" : customerClaim}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <ThemedTextField
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              label="PF"
              id="pf"
              type="number"
              onWheel={(event) =>
                event.currentTarget.querySelector("input")?.blur()
              }
              disabled
              className="disable-back-ground-color"
              value={PremiumFreight}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                const { inputValue } = params
                if (inputValue !== "") {
                  filtered.push(inputValue)
                }
                return filtered
              }}
              freeSolo
              fullWidth
              value={recordBy}
              disableClearable
              disablePortal
              onChange={(event, selectedPackedBy) => {
                setRecordBy(selectedPackedBy as string)
              }}
              options={packingByList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  required
                  error={status && !recordBy ? true : false}
                  label="Packed By"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <ThemedTextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="remarks"
              label="remarks"
              type="text"
              onChange={(e) => setRemarks(e.target.value)}
              value={remarks}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ThemedLoadingButton
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
              loading={loading}
              endIcon={<AddIcon />}
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              Submit
            </ThemedLoadingButton>
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default PremiumFreight
