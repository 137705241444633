import { LoadingButton, LocalizationProvider, TimePicker } from "@mui/lab"
import {
  Autocomplete,
  Button,
  Card,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@mui/material"
import DateAdapter from "@mui/lab/AdapterMoment"
import { useMemo, useState } from "react"
import swal from "sweetalert"
import moment from "moment"
import { calculateTime, diff } from "../../Planning/ProductionPlan"
import { DataProductionPlan } from "../../Planning/ProductionPlanReport"
import { useLazyQuery, useMutation } from "@apollo/client"
import { user } from "../../../common/MainApp"
import { GET_PROBLEM_MACHINE } from "../../../common/Query/PlanningQuery"
import {
  DELETE_STOP_MACHINE,
  UPDATE_STOP_MACHINE
} from "../../../common/Query/ProductionQuery"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"

interface IProps {
  dialogOpen: boolean
  setDialogOpen: (status: boolean) => void
  planData: DataProductionPlan
  handleSubmit: () => void
}

interface IProblem {
  name: string
  value: string
}

export const problemNameList: string | IProblem[] = [
  { name: "รอ QC set up", value: "Wait QC First Set Up" },
  { name: "เครื่องเสีย", value: "Machine Breakdown" },
  { name: "แม่พิมพ์เสีย", value: "DIE Breakdown" },
  { name: "แผนบำรุงรักษา PM ", value: "PM" },
  { name: "อื่นๆ", value: "other" }
]

export const problemOtherList: string[] = [
  "รอ Mat'l (Supplier)",
  "รอรถโฟล์คลิฟท์",
  "ไฟฟ้าดับ",
  "พนักงานมาสาย",
  "พนักงานขาด",
  "พนักงานลา (ลากิจ / ลาป่วย)",
  "Set up",
  "Qc สั่งหยุด",
  "process ก่อนหน้ามีปัญหา",
  "รอแม่พิมพ์",
  "พนักงานติดอบรม"
]

interface IDataProblem {
  id: number
  machine: string
  partNo: string
  process: string
  actionDate: string
  startTime: string
  endTime: string
  totalTime: number
  problemName: string
  remarks: string
  line: string
  department: string
}

const EditProblemMachine = (props: IProps): JSX.Element => {
  const { planData, dialogOpen, setDialogOpen, handleSubmit } = props
  const [startTime, setStartTime] = useState<number>(3600000)
  const [endTime, setEndTime] = useState<number>(36000000)
  const [selectStartTime, setSelectStartTime] = useState<string>("")
  const [selectEndTime, setSelectEndTime] = useState<string>("")
  const [startTimeList, setStartTimeList] = useState<string[]>([])
  const [endTimeList, setEndTimeList] = useState<string[]>([])
  const [problemListData, setProblemListData] = useState<IDataProblem[]>([])
  const [problemName, setProblemName] = useState<string>("")
  const [id, setId] = useState<number>(0)
  const [remarks, setRemarks] = useState<string>("")
  const [updateProblem, { loading: updateLoading }] = useMutation(
    UPDATE_STOP_MACHINE,
    {
      refetchQueries: [GET_PROBLEM_MACHINE],
      awaitRefetchQueries: true
    }
  )
  const [deleteProblem, { loading: deleteLoading }] = useMutation(
    DELETE_STOP_MACHINE,
    {
      refetchQueries: [GET_PROBLEM_MACHINE],
      awaitRefetchQueries: true
    }
  )
  const [workTime, setWorkTime] = useState<number>(0)

  const [getProblem, { data }] = useLazyQuery(GET_PROBLEM_MACHINE, {
    fetchPolicy: "network-only"
  })

  useMemo(async () => {
    await getProblem({
      variables: {
        dataProblemReport: {
          machine: planData.machine,
          partNo: planData.partNo,
          process: planData.process,
          actionDate: planData.planDate
        }
      }
    })
    if (data?.problemStatusReport.length > 0) {
      setProblemListData(data.problemStatusReport)
      setEndTimeList(
        data.problemStatusReport.map((e: { endTime: string }) => e.endTime)
      )
      setStartTimeList(
        data.problemStatusReport.map((e: { startTime: string }) => e.startTime)
      )
    } else {
      setEndTimeList([])
      setStartTimeList([])
    }
  }, [dialogOpen])

  useMemo(() => {
    const findProblem = problemListData.find(
      (e) => e.startTime === selectStartTime && e.endTime === selectEndTime
    )
    if (findProblem && selectEndTime && selectStartTime) {
      const name = problemNameList.find(
        (e) => e.value === findProblem.problemName
      )?.name
      setId(findProblem.id)
      setStartTime(new Date(`2022-01-01 ${findProblem.startTime}`).getTime())
      setEndTime(new Date(`2022-01-01 ${findProblem.endTime}`).getTime())
      setProblemName(name ? name : "")
      setRemarks(findProblem.remarks)
    } else {
      setStartTime(0)
      setEndTime(0)
      setProblemName("")
      setRemarks("")
    }
  }, [selectStartTime, selectEndTime])

  useMemo(() => {
    if (
      Number(moment(endTime).format("HH")) > 12 &&
      Number(moment(startTime).format("HH")) < 13
    ) {
      const time = diff(startTime, endTime - 60 * 60 * 1000)
      setWorkTime(Number(calculateTime(time)))
    } else {
      const time = diff(startTime, endTime)
      setWorkTime(Number(calculateTime(time)))
    }
    // getProblemStatus()
  }, [startTime, endTime])

  const onSubmit = async () => {
    if (!problemName) return swal("Warning", "กรุณากรอกปัญหาที่พบ", "warning")
    try {
      const problemValue = problemNameList.find(
        (e) => e.name === problemName
      )?.value
      const data = {
        id: id,
        machine: planData.machine,
        partNo: planData.partNo,
        process: planData.process,
        actionDate: planData.planDate,
        startTime: moment(startTime).format("HH:mm"),
        endTime: moment(endTime).format("HH:mm"),
        totalTime: workTime,
        problemName: problemValue,
        remarks: remarks,
        line: planData.line,
        department: user.role
      }
      await updateProblem({
        variables: { dataProblem: data }
      })
      swal("Success", "บัททึกสำเร็จ", "success")
      setRemarks("")
      setProblemName("")
      handleSubmit()
      setDialogOpen(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  const onDelete = async () => {
    if (!problemName) return swal("Warning", "กรุณากรอกปัญหาที่พบ", "warning")
    try {
      await deleteProblem({
        variables: { problemDeleteId: id }
      })
      swal("Success", "บัททึกสำเร็จ", "success")
      setRemarks("")
      setProblemName("")
      handleSubmit()
      setDialogOpen(false)
      onReset()
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  const onReset = () => {
    setStartTime(3600000)
    setEndTime(36000000)
    setSelectStartTime("")
    setSelectEndTime("")
  }

  const filter = createFilterOptions<string>()

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        >
          Problem Machine
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            color={"black"}
            fontSize="20px"
            marginBottom={"10px"}
          >
            Machine: {props.planData.machine} Part No: {props.planData.partNo}
            <br />
            Part Name: {props.planData.partName} Process:{" "}
            {props.planData.process} Step: {props.planData.step}
          </DialogContentText>
          <Grid
            container
            spacing={2}
            marginTop={"25px"}
            width={"98%"}
            padding={2}
          >
            <Grid item sm={2}>
              เลือกช่วงเวลาที่ต้องการแก้ไข
            </Grid>
            <Grid item sm={5}>
              <Autocomplete
                fullWidth
                value={selectStartTime}
                disablePortal
                getOptionLabel={(option) => option.toString()}
                onChange={(event, selectedDrop) => {
                  setSelectStartTime(selectedDrop ? selectedDrop : "")
                }}
                options={startTimeList}
                renderInput={(params) => <ThemedTextField {...params} />}
              />
            </Grid>
            <Grid item sm={5}>
              <Autocomplete
                fullWidth
                value={selectEndTime}
                disablePortal
                getOptionLabel={(option) => option.toString()}
                onChange={(event, selectedDrop) => {
                  setSelectEndTime(selectedDrop ? selectedDrop : "")
                }}
                options={endTimeList}
                renderInput={(params) => <ThemedTextField {...params} />}
              />
            </Grid>
          </Grid>

          <Card>
            <Grid container spacing={2} padding={2} width={"98%"}>
              <Grid item sm={6}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    label="Start Time"
                    ampm={false}
                    views={["hours", "minutes"]}
                    inputFormat="HH:mm"
                    value={startTime}
                    onChange={(newSelectTime) => {
                      setStartTime(newSelectTime ? newSelectTime : 0)
                    }}
                    renderInput={(params) => (
                      <ThemedTextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item sm={6}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    label="End Time"
                    ampm={false}
                    views={["hours", "minutes"]}
                    inputFormat="HH:mm"
                    value={endTime}
                    onChange={(newSelectTime) => {
                      setEndTime(newSelectTime ? newSelectTime : 0)
                    }}
                    renderInput={(params) => (
                      <ThemedTextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item sm={2}>
                <label>Total time: </label>
              </Grid>
              <Grid item sm={10}>
                {workTime >= 1 ? (
                  <label>{Math.trunc(workTime)} Hour </label>
                ) : (
                  ""
                )}
                <label>{Math.round((workTime % 1) * 60)} Minute</label>
              </Grid>

              <Grid item sm={12}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  value={problemName}
                  onChange={(e, newValue) => {
                    setProblemName(newValue ? newValue : "")
                  }}
                  renderInput={(params) => (
                    <ThemedTextField {...params} label="Select Problem Type" />
                  )}
                  options={problemNameList.map((e) => e.name)}
                />
              </Grid>

              <Grid item sm={12}>
                <Autocomplete
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params)
                    const { inputValue } = params
                    if (inputValue !== "") {
                      filtered.push(inputValue)
                    }
                    return filtered
                  }}
                  freeSolo
                  fullWidth
                  value={remarks}
                  disablePortal
                  getOptionLabel={(option) => option.toString()}
                  onChange={(event, selectedDrop) => {
                    setRemarks(selectedDrop ? selectedDrop : "")
                  }}
                  options={problemName !== "อื่นๆ" ? [] : problemOtherList}
                  renderInput={(params) => <ThemedTextField {...params} />}
                />
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            style={{ backgroundColor: "green" }}
            autoFocus
            onClick={onSubmit}
            loading={updateLoading}
          >
            Update
          </LoadingButton>
          <LoadingButton
            variant="contained"
            style={{ backgroundColor: "red" }}
            autoFocus
            onClick={onDelete}
            loading={deleteLoading}
          >
            Delete
          </LoadingButton>
          <Button
            variant="outlined"
            style={{ color: "red", borderColor: "red" }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditProblemMachine
