import { Autocomplete } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import ThemedTextField from "./ThemedComponents/ThemedTextField"

interface IProps {
  setFactory: Dispatch<SetStateAction<string>>
  factory: string
  error?: boolean
}
const AutocompleteFactory = (props: IProps): JSX.Element => {
  const { factory, setFactory, error } = props
  return (
    <Autocomplete
      fullWidth
      id="factory"
      value={factory}
      options={["1", "2"]}
      disablePortal
      onChange={(e, value) => setFactory(value as string)}
      renderInput={(params) => (
        <ThemedTextField
          {...params}
          label="Factory"
          error={!factory && error}
        />
      )}
    />
  )
}
export default AutocompleteFactory
