import React, { useState } from "react"
import Grid from "@mui/material/Grid"
import { GET_PARTS_AND_CUSTOMERS } from "./mpcomponents/CustomerAndParts"
import { Autocomplete } from "@mui/material"
import AddFinishedGoods from "./mpcomponents/AddFinishedGoods"
import { ThemeProvider, useTheme } from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { useQuery } from "@apollo/client"
import { GET_CUSTOMERS } from "../../common/Query/MasterQuery"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

const pageTitle = "Add Begin Stock"

const AddFG: React.FC = () => {
  const [customer, setCustomer] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const theme = useTheme()

  const CustomerandPartsQuery = () => {
    const customerQuery = useQuery(GET_CUSTOMERS)
    const partNoQuery = useQuery(GET_PARTS_AND_CUSTOMERS)
    return [customerQuery, partNoQuery]
  }
  const [
    { error: customerListError, data: customerListData },
    { error: partNoListError, data: partNoListData }
  ] = CustomerandPartsQuery()
  if (customerListError) return <p>Error: {customerListError}</p>
  if (partNoListError) return <p>Error: {partNoListError}</p>

  let customerList = []
  if (customerListData) {
    customerList = customerListData.customers.map(
      ({ customer }: { customer: string }) => {
        return customer
      }
    )
  }
  interface PartType {
    partNo: string
    customer: string
  }

  let partNoList = []

  if (partNoListData) {
    partNoList = partNoListData.parts.reduce(
      (list: string[], part: PartType) => {
        if (part.customer === customer) {
          list.push(part.partNo)
        }
        return list
      },
      []
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.MasterPlan}
        menuItemName={Object.keys(menuItemList)[7]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="Add Begin Stock"
          subTitle="about detail Add Begin Stock"
        />
        <Grid container width={"98%"} margin={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                value={customer}
                fullWidth
                aria-label="customers-select"
                loading={customerList.length === 0}
                onChange={(event, selectedCustomer) => {
                  setCustomer(selectedCustomer as string)
                  setPartNo("")
                }}
                options={customerList}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Customers" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                key={partNoList}
                value={partNo}
                fullWidth
                aria-label="partno-select"
                onChange={(event, selectedPartNo) => {
                  setPartNo(selectedPartNo as string)
                }}
                disabled={
                  Array.isArray(partNoList) && partNoList.length ? false : true
                }
                options={partNoList}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Part NO." />
                )}
              />
            </Grid>
            <Grid container item xs={12} sm={12}>
              <AddFinishedGoods partNo={partNo} />
            </Grid>
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default AddFG
