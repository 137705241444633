import { useLazyQuery } from "@apollo/client"
import {
  Grid,
  Autocomplete,
  styled,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  CardHeader,
  tableCellClasses,
  useTheme,
  ThemeProvider
} from "@mui/material"

import React, { useState } from "react"
import swal from "sweetalert"
import { GET_MAT_OUT_REPORT } from "../../common/Query/MatOutScanQuery"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

interface Column {
  id:
    | "actionDate"
    | "partNo"
    | "invoiceNo"
    | "sizeH"
    | "sizeW"
    | "sizeL"
    | "matPlan"
    | "actualPlan"
    | "jobOrder"
    | "factory"
    | "packingBy"
    | "remarks"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

interface IRows {
  actionDate: string
  partNo: string
  invoiceNo: string
  sizeH: string
  sizeW: string
  sizeL: string
  matPlan: number
  actualPlan: string
  jobOrder: string
  factory: string
  packingBy: string
  remarks: string
}

const pageTitle = "Material Out Report"

const columns: Column[] = [
  { id: "actionDate", label: "Action Date", minWidth: 50 },
  { id: "partNo", label: "Part No", minWidth: 50 },
  { id: "sizeH", label: "Size H", minWidth: 20 },
  { id: "sizeW", label: "Size W", minWidth: 20 },
  { id: "sizeL", label: "Size L", minWidth: 20 },
  { id: "matPlan", label: "Mat Plan", minWidth: 50 },
  { id: "actualPlan", label: "Actual Plan", minWidth: 20 },
  { id: "jobOrder", label: "Job Order", minWidth: 20 },
  { id: "factory", label: "Factory", minWidth: 20 },
  { id: "packingBy", label: "Packing By", minWidth: 20 },
  { id: "remarks", label: "Remarks", minWidth: 20 }
]

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.secondary.contrastText,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0,
    whiteSpace: "nowrap"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.black
  },

  "td, th": {
    border: 0
  }
}))

const MaterialOutReport: React.FC = () => {
  const theme = useTheme()
  const [dateFrom, setDateFrom] = useState<string>("")
  const [dateTo, setDateTo] = useState<string>("")
  const [factory, setFactory] = useState<string | null>()
  const [requiredFactory, setRequiredFactory] = useState<boolean>(false)
  const [partNo, setPartNo] = useState<string>("")
  const [invoiceNo, setInvoiceNo] = useState<string | null>("")
  const [jobOrder, setJobOrder] = useState<string | null>("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [matOutReport, { data, loading }] = useLazyQuery(GET_MAT_OUT_REPORT)
  const [error, setError] = useState<boolean>(false)
  const factoryList = ["1", "2"]

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const isComplete = () => {
    return dateFrom !== "" && dateTo !== ""
  }
  const handleSubmit = async () => {
    if (!isComplete() || !dateFrom || !dateTo) {
      setError(true)
      return swal("ERROR", "กรุณากำหนดวันที่ต้องการ", "error")
    }
    if (!factory) setRequiredFactory(true)
    if (!dateFrom || !dateTo || !factory) return
    await matOutReport({
      variables: {
        dateFrom,
        dateTo,
        factory
      },
      fetchPolicy: "network-only"
    })
  }
  let rows: IRows[] = data ? data.matOutScanReport : []
  if (data && partNo) {
    rows = data.matOutScanReport.filter(
      (matOutScan: IRows) => matOutScan.partNo === partNo
    )
  }
  if (invoiceNo) {
    rows = data.matOutScanReport.filter(
      (matOutScan: IRows) => matOutScan.invoiceNo === invoiceNo
    )
  }
  if (jobOrder) {
    rows = data.matOutScanReport.filter(
      (matOutScan: IRows) => matOutScan.jobOrder === jobOrder
    )
  }
  const invoiceNoList: Array<string> = data
    ? data.matOutScanReport
        .map((matOutScan: IRows) => matOutScan.invoiceNo)
        .reduce(
          (prev: string, cur: string) =>
            prev.includes(cur) ? prev : prev.concat(cur),
          []
        )
    : []
  const jobOrderList: Array<string> = data
    ? data.matOutScanReport
        .map((matOutScan: IRows) => matOutScan.jobOrder)
        .reduce(
          (prev: string, cur: string) =>
            prev.includes(cur) ? prev : prev.concat(cur),
          []
        )
    : []

  const emptyRows: number =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Materials}
        menuItemName={Object.keys(menuItemList)[1]}
        menuPageName={pageTitle}
      />
      <ThemedCard style={{ padding: "5px" }}>
        <CardHeader
          titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
          title="Mat'l Out Report"
          subheaderTypographyProps={{ fontFamily: "Sarabun" }}
          subheader="detail Mat'l Out Report"
        />
        <Grid container spacing={2} width={"98%"} marginLeft={1}>
          <Grid item sm={3}>
            <ThemedTextField
              error={!dateFrom && error}
              InputLabelProps={{
                shrink: true
              }}
              id="Date Range"
              label="From"
              type="date"
              value={dateFrom}
              fullWidth
              onChange={(event: {
                target: { value: React.SetStateAction<string> }
              }) => {
                setDateFrom(event.target.value)
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <ThemedTextField
              error={!dateTo && error}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              value={dateTo}
              label="To"
              id="Date To"
              type="date"
              onChange={(event: {
                target: { value: React.SetStateAction<string> }
              }) => {
                setDateTo(event.target.value)
              }}
            />
          </Grid>
          <Grid item sm={5}>
            <Autocomplete
              fullWidth
              disablePortal
              onChange={(e, newValue) => {
                setFactory(newValue)
                setRequiredFactory(false)
              }}
              options={factoryList}
              renderInput={(params) => (
                <ThemedTextField
                  error={requiredFactory}
                  {...params}
                  InputLabelProps={{
                    shrink: true
                  }}
                  label="Factory"
                />
              )}
            />
          </Grid>

          <Grid item sm={1} margin="auto">
            <ThemedLoadingButton
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              loading={loading}
              style={{
                width: "100%"
              }}
            >
              Submit
            </ThemedLoadingButton>
          </Grid>
        </Grid>

        <Grid container spacing={2} width={"98%"} marginLeft={1}>
          <Grid item sm={3} marginTop={1}>
            <AutocompletePartNo setPartNo={setPartNo} partNo={partNo} />
          </Grid>

          <Grid item sm={3} marginTop={1}>
            <Autocomplete
              fullWidth
              disablePortal
              onChange={(e, newValue) => {
                setInvoiceNo(newValue)
                setPage(0)
              }}
              options={invoiceNoList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  InputLabelProps={{
                    shrink: true
                  }}
                  label="Select Invoice"
                />
              )}
            />
          </Grid>

          <Grid item sm={6} marginTop={1}>
            <Autocomplete
              fullWidth
              disablePortal
              onChange={(e, newValue) => {
                setJobOrder(newValue)
                setPage(0)
              }}
              options={jobOrderList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  InputLabelProps={{
                    shrink: true
                  }}
                  label="Select JobOrder"
                />
              )}
            />
          </Grid>
        </Grid>

        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ marginTop: "15px" }}
          >
            <TableHead>
              <StyledTableRow>
                {columns.map((column) => (
                  <StyledTableCell key={column.id} align={column.align}>
                    {column.label}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      style={{}}
                    >
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        )
                      })}
                    </StyledTableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={11} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ backgroundColor: theme.palette.warning.light }}
        />
      </ThemedCard>
    </ThemeProvider>
  )
}

export default MaterialOutReport
