import { ITrialToolingData } from "./TrialToolingSheetPlanningApproval"
import PrintIcon from "@mui/icons-material/Print"

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
  useTheme
} from "@mui/material"
import { bankImage, getImagePng, miniLogo } from "../../../common/utilities"
import { useEffect, useState } from "react"
import { PMDocumentGrid } from "./RepairInformant"
import { useMutation } from "@apollo/client"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import moment from "moment"
import { TRIAL_TOOLING_SHEET_UPDATE_APPROVAL } from "../../../common/Mutation/engineerMutation"
import swal from "sweetalert"
import { user } from "../../../common/MainApp"
import { signatureMap } from "./TrialToolingCreate"

interface ITooling {
  die: string
  size: string
  amount: number
  line: string
  machineCode: string
  [key: string]: number | string
}



const toolNameData: { key: string; name: string }[] = [
  { key: "newTool", name: "แม่พิมพ์ใหม่ NEW TOOLING" },
  { key: "modifyTool", name: "แม่พิมพ์แก้ไข MODIFY TOOLING" },
  { key: "supplyTool", name: "แม่พิมพ์จากลูกค้า SUPPLY TOOLING" },
  { key: "changeMachine", name: "เปลี่ยนเครื่องจักร CHANGE MACHINE" },
  { key: "changeOperator", name: "เปลี่ยนพนักงาน CHANGE OPERATOR" },
  { key: "changeMaterial", name: "เปลี่ยนวัตถุดิบ CHANGE MATERIAL" },
  {
    key: "changeSupplierMaterial",
    name: "เปลี่ยนผู้ส่งมอบวัตถุดิบ CHANGE SUPPLIER MATERIAL"
  },
  { key: "changePlant", name: "เปลี่ยนสถานที่ CHANGE PLANT" }
]

const StyledTableRow = styled(TableRow)(() => ({
  "td, th": {
    backgroundColor: "white",
    color: "black",
    padding: "5px"
  }
}))

const StyledTableCell = styled(TableCell)(() => ({
  blackGroundColor: "white",
  color: "black",
  textAlign: "center",
  padding: 0,
  fontSize: "16px",
  border: "solid 1px black"
}))

export const TrialToolingPaper: React.FC<IProp> = (props: IProp) => {
  const { filteredData } = props

  const displayDivision = {
    eng: filteredData?.selectedDivisions
      ? "ENG" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false,
    pd1: filteredData?.selectedDivisions
      ? "PD1" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false,
    pd2: filteredData?.selectedDivisions
      ? "PD2" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false,
    pd: filteredData?.selectedDivisions
      ? "PD" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false,
    pt: filteredData?.selectedDivisions
      ? "PT" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false,
    qa: filteredData?.selectedDivisions
      ? "QA" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false,
    mt: filteredData?.selectedDivisions
      ? "MT" in JSON.parse(filteredData.selectedDivisions)
        ? true
        : false
      : false
  }

  useEffect(() => {
    setPartName(filteredData?.partName || "")
    setPartNo(filteredData?.partNo)
    setCustomer(filteredData?.customer || "")
    setFactory(filteredData?.factory)
    setTrialDate(filteredData?.trialDate ? filteredData.trialDate : trialDate)
    setControlNo(filteredData?.controlNo)
    setTargetTrialEvent(filteredData?.targetTrialEvent || "")
    setCycleTime(filteredData?.cycleTime || "")
    setCapacity(filteredData?.capacity || "")
    setInternalDefect(filteredData?.internalDefect || "")
    setOperatorSkill(filteredData?.operatorSkill || "")
    setCpk(filteredData?.cpk || "")
    setReworkRatio(filteredData?.reworkRatio || "")

    setSelectedTools(
      filteredData?.selectedTools ? JSON.parse(filteredData.selectedTools) : {}
    )
    setIsOtherSelected(
      filteredData?.selectedTools
        ? JSON.parse(filteredData.selectedTools).other || false
        : false
    )
    setOtherToolName(
      filteredData?.selectedTools
        ? JSON.parse(filteredData.selectedTools).other || ""
        : ""
    )
    setToolings(
      filteredData?.toolingData
        ? JSON.parse(filteredData.toolingData)
        : toolingData
    )

    setMaterial(filteredData?.material || "")
    setPlating(filteredData?.plating || "")
    setTrialNo(filteredData?.trialNo || "")
    setJobNo(filteredData?.jobNo || "")
    setQuantity(filteredData?.quantity || "")

    setPJPlanDate(
      filteredData?.pJPlanDate
        ? moment(filteredData.pJPlanDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : pJPlanDate
    )
    setPJStartTime(
      filteredData?.pJStartTime
        ? parseTimeStringToDate(filteredData?.pJStartTime)
        : pJStartTime
    )
    setPJEndTime(
      filteredData?.pJEndTime
        ? parseTimeStringToDate(filteredData?.pJEndTime)
        : pJEndTime
    )
    setPJSignature(filteredData?.pJSignature || "")
    setPJMembersTrial(
      filteredData?.pJMembersTrial
        ? JSON.parse(filteredData.pJMembersTrial)
        : pJMembersTrial
    )

    setPLPlanDate(
      filteredData?.pLPlanDate
        ? moment(filteredData.pLPlanDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment(filteredData?.pJPlanDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    )
    setPLStartTime(
      filteredData?.pLStartTime
        ? parseTimeStringToDate(filteredData?.pLStartTime)
        : pLStartTime
    )
    setPLEndTime(
      filteredData?.pLEndTime
        ? parseTimeStringToDate(filteredData?.pLEndTime)
        : pLEndTime
    )
    setPLSignature(filteredData?.pLSignature || "")
    setPLMembersTrial(
      filteredData?.pLMembersTrial
        ? JSON.parse(filteredData.pLMembersTrial)
        : pLMembersTrial
    )

    setENGSignature(filteredData?.eNGSignature || "")
    setENGMembersTrial(
      filteredData?.eNGMembersTrial
        ? JSON.parse(filteredData.eNGMembersTrial)
        : eNGMembersTrial
    )

    setPD1Signature(filteredData?.pD1Signature || "")
    setPD1MembersTrial(
      filteredData?.pD1MembersTrial
        ? JSON.parse(filteredData.pD1MembersTrial)
        : pD1MembersTrial
    )

    setPD2Signature(filteredData?.pD2Signature || "")
    setPD2MembersTrial(
      filteredData?.pD2MembersTrial
        ? JSON.parse(filteredData.pD2MembersTrial)
        : pD2MembersTrial
    )

    setPDSignature(filteredData?.pDSignature || "")
    setPDMembersTrial(
      filteredData?.pDMembersTrial
        ? JSON.parse(filteredData.pDMembersTrial)
        : pDMembersTrial
    )

    setPTSignature(filteredData?.pTSignature || "")
    setPTMembersTrial(
      filteredData?.pTMembersTrial
        ? JSON.parse(filteredData.pTMembersTrial)
        : pTMembersTrial
    )

    setQASignature(filteredData?.qASignature || "")
    setQAMembersTrial(
      filteredData?.qAMembersTrial
        ? JSON.parse(filteredData.qAMembersTrial)
        : qAMembersTrial
    )

    setMTSignature(filteredData?.mTSignature || "")
    setMTMembersTrial(
      filteredData?.mTMembersTrial
        ? JSON.parse(filteredData.mTMembersTrial)
        : mTMembersTrial
    )

    setFmSignature(filteredData?.fmSignature || "")
    setEngineerHead1Signature(filteredData?.engineerHead1Signature || "")
    setEngineerHead2Signature(filteredData?.engineerHead2Signature || "")

    setFmSignatureTime(
      filteredData?.fmSignatureTime ? filteredData.fmSignatureTime : ""
    )

    setEngineerHead1SignatureTime(
      filteredData?.engineerHead1SignatureTime
        ? filteredData.engineerHead1SignatureTime
        : ""
    )
    setEngineerHead2SignatureTime(
      filteredData?.engineerHead2SignatureTime
        ? filteredData.engineerHead2SignatureTime
        : ""
    )

    setCommentPj(filteredData?.commentPj ? filteredData.commentPj : "")
    setCommentPl(filteredData?.commentPl ? filteredData.commentPl : "")
  }, [filteredData])

  function parseAndFormatArray(data: string | undefined): string {
    if (!data) return ""

    try {
      const array = JSON.parse(data) as string[]
      return Array.isArray(array) ? array.join(", ") : ""
    } catch (error) {
      console.error("Invalid JSON:", error)
      return ""
    }
  }

  const theme = useTheme()
  const [partName, setPartName] = useState<string | undefined>("")
  const [partNo, setPartNo] = useState<string | undefined>("")
  const [customer, setCustomer] = useState<string | undefined>("")
  const [factory, setFactory] = useState<string | undefined>("")
  const [trialDate, setTrialDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [controlNo, setControlNo] = useState<string | undefined>("")

  const [targetTrialEvent, setTargetTrialEvent] = useState<string | undefined>(
    ""
  )
  const [cycleTime, setCycleTime] = useState<number | undefined | string>("")
  const [capacity, setCapacity] = useState<number | undefined | string>("")
  const [internalDefect, setInternalDefect] = useState<
    number | undefined | string
  >("")
  const [operatorSkill, setOperatorSkill] = useState<
    number | undefined | string
  >("")
  const [cpk, setCpk] = useState<number | undefined | string>("")
  const [reworkRatio, setReworkRatio] = useState<number | undefined | string>(
    ""
  )

  const [selectedTools, setSelectedTools] = useState<Record<string, string>>({})

  const toolingData = [
    { die: "", size: "", amount: 0, line: "", machineCode: "" }
  ]

  const [material, setMaterial] = useState<string>("")
  const [plating, setPlating] = useState<string>("")
  const [trialNo, setTrialNo] = useState<string>("")
  const [quantity, setQuantity] = useState<number | undefined | string>("")
  const [jobNo, setJobNo] = useState<string>("")

  const [pJPlanDate, setPJPlanDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )

  const parseTimeStringToDate = (timeString?: string): Date | null => {
    if (!timeString) return null
    const [hours, minutes] = timeString.split(":").map(Number)
    const date = new Date()
    date.setHours(hours, minutes, 0, 0)
    return date
  }

  const [pJStartTime, setPJStartTime] = useState<Date | null>(null)
  const [pJEndTime, setPJEndTime] = useState<Date | null>(null)
  const [pJSignature, setPJSignature] = useState<string>("")
  const [pJMembersTrial, setPJMembersTrial] = useState<string[]>([])

  const [pLPlanDate, setPLPlanDate] = useState<string>(
    moment(filteredData?.pJPlanDate, "YYYY-MM-DD").format("YYYY-MM-DD")
  )

  const [pLStartTime, setPLStartTime] = useState<Date | null>(
    parseTimeStringToDate(filteredData?.pJStartTime)
  )
  const [pLEndTime, setPLEndTime] = useState<Date | null>(
    parseTimeStringToDate(filteredData?.pJEndTime)
  )

  const displayTimeToDivision = (
    start?: string,
    end?: string,
    signature?: string
  ): string => {
    if (!signature) return ""
    return `${start || ""} - ${end || ""} น.`
  }

  const [pLSignature, setPLSignature] = useState<string>("")
  const [pLMembersTrial, setPLMembersTrial] = useState<string[]>([])

  const [eNGSignature, setENGSignature] = useState<string>("")
  const [eNGMembersTrial, setENGMembersTrial] = useState<string[]>([])

  const [pD1Signature, setPD1Signature] = useState<string>("")
  const [pD1MembersTrial, setPD1MembersTrial] = useState<string[]>([])

  const [pD2Signature, setPD2Signature] = useState<string>("")
  const [pD2MembersTrial, setPD2MembersTrial] = useState<string[]>([])

  const [pDSignature, setPDSignature] = useState<string>("")
  const [pDMembersTrial, setPDMembersTrial] = useState<string[]>([])

  const [pTSignature, setPTSignature] = useState<string>("")
  const [pTMembersTrial, setPTMembersTrial] = useState<string[]>([])

  const [qASignature, setQASignature] = useState<string>("")
  const [qAMembersTrial, setQAMembersTrial] = useState<string[]>([])

  const [mTSignature, setMTSignature] = useState<string>("")
  const [mTMembersTrial, setMTMembersTrial] = useState<string[]>([])

  const [fmSignature, setFmSignature] = useState<string>("")
  const [engineerHead1Signature, setEngineerHead1Signature] =
    useState<string>("")
  const [engineerHead2Signature, setEngineerHead2Signature] =
    useState<string>("")

  const [fmSignatureTime, setFmSignatureTime] = useState<string>("")
  const [engineerHead1SignatureTime, setEngineerHead1SignatureTime] =
    useState<string>("")
  const [engineerHead2SignatureTime, setEngineerHead2SignatureTime] =
    useState<string>("")


  const checkDivisionSign = async () => {
    for (const [key] of Object.entries(JSON.parse(filteredData?.selectedDivisions ? filteredData?.selectedDivisions : "{}"))) {
      const grabKeyAndConvertItLikeSignature = Object.keys(signatureMap).find(
        (signatureKey) => signatureMap[signatureKey] === key
      );

      if ((grabKeyAndConvertItLikeSignature && (filteredData && !filteredData[grabKeyAndConvertItLikeSignature as keyof ITrialToolingData])) || !filteredData?.pJSignature || !filteredData?.pLSignature || !filteredData?.engineerHead1Signature || !filteredData?.engineerHead2Signature) {
        swal(
          "Error",
          "ไม่สามารถ Approve ได้, เพราะบางแผนกยังลงชื่อไม่ครบ",
          "warning"
        )
        return false
      }
    }
    return true
  };

  const [updateTrialToolingSheetApproval] = useMutation(
    TRIAL_TOOLING_SHEET_UPDATE_APPROVAL
  )

  const handleUpdate = async (signature: string, signatureTime: string) => {
    try {
      await updateTrialToolingSheetApproval({
        variables: {
          inputTrialToolingApproval: {
            trialId: filteredData?.trialId,
            fmSignature: signature,
            fmSignatureTime: signatureTime,

            updatedAt: filteredData?.updatedAt,
            updatedBy: user.username,
            fromPage: "Engineer > Trial Tooling Page"
          }
        }
      })
      swal("Success", "บันทึกข้อมูลเรียบร้อย", "success").then(() => {
        window.location.reload()
      })
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  const [otherToolName, setOtherToolName] = useState<string>("")
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false)

  const [toolings, setToolings] = useState<ITooling[]>(toolingData)

  const fmConfirm = async (phase: string) => {
    if (!(await checkDivisionSign())) return
    const isConfirmed = await swal({
      title: "ต้องการยืนยันใช่หรือไม่?",
      text: phase,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true
    })
    if (isConfirmed) {
      const signature = user.employee
      const signatureTime = moment(new Date()).format("YYYY-MM-DD")

      setFmSignature(signature)
      setFmSignatureTime(signatureTime)

      await handleUpdate(signature, signatureTime)
    } else return
  }

  const [commentPj, setCommentPj] = useState<string>("")
  const [commentPl, setCommentPl] = useState<string>("")

  return (
    <ThemeProvider theme={theme}>
      <div className="repair-print-head">
        <Table sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
          <TableHead>
            <TableRow sx={{ height: "100px" }}>
              <StyledTableCell colSpan={5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img src={miniLogo} alt="BAE logo" width={"80px"} />
                  <Typography variant="h1" sx={{ fontSize: "20px" }}>
                    Brother Auto Parts & Engineering Co.,Ltd.
                  </Typography>
                </Box>
              </StyledTableCell>
            </TableRow>
            <TableRow sx={{ height: "70px" }}>
              <StyledTableCell colSpan={1}>
                <Typography variant="h2" sx={{ fontSize: "15px" }}>
                  TRIAL TOOLING SHEET (ใบขอทดลองผลิต)
                </Typography>
              </StyledTableCell>
              <StyledTableCell colSpan={4}>
                <Box>Code No.: F-PJ-01-29</Box>
                <Box sx={{ fontWeight: "100", fontSize: "14px" }}>
                  Effective: 19/11/2024 Rev 01 Issue 1
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <StyledTableRow>
              <StyledTableCell colSpan={5}>
                <Grid container>
                  <Grid
                    container
                    item
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid item>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Part Name: (ชื่อชิ้นงาน)</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          value={filteredData?.partName || partName}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Part No: (เลขที่ชิ้นงาน)</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          value={partNo}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Customer:(ลูกค้า)</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          value={filteredData?.customer || customer}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Plant:(สถานที่ขอทดลอง)</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          value={filteredData?.factory || factory}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Date/วันที่ออกเอกสาร</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          value={
                            filteredData?.trialDate
                              ? filteredData.trialDate
                              : trialDate
                          }
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Control No./เลขที่เอกสาร</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          value={filteredData?.controlNo || controlNo}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>
                    </Grid>
                    <Grid item>
                      <img
                        src={getImagePng(`images/parts/${partNo}`)}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src = bankImage
                        }}
                        width={200}
                        height={200}
                        alt=""
                      ></img>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        borderLeft: "1px solid black",
                        borderBottom: "1px solid black",
                        p: "20px"
                      }}
                    >
                      <Grid item>
                        <Typography>TARGET TRIAL</Typography>
                      </Grid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Event</Typography>
                        <TextField
                          fullWidth
                          type="text"
                          variant="standard"
                          value={targetTrialEvent}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>1. Cycle time :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          value={cycleTime}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(Sec.)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>2. Capacity :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          value={capacity}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>3. Internal defect :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          value={internalDefect}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>4. Operator Skill :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          value={operatorSkill}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>5. Cpk :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          value={cpk}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>6. Rework Ratio :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          value={reworkRatio}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>
                    </Grid>
                  </Grid>

                  <Grid item sx={{ mt: "50px" }}>
                    <Box sx={{ padding: 2 }}>
                      <FormGroup>
                        <Grid container spacing={2}>
                          {toolNameData.map((tool) => (
                            <Grid
                              item
                              xs={4}
                              key={tool.key}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start"
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!!selectedTools[tool.key]}
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "black"
                                      }
                                    }}
                                  />
                                }
                                label={tool.name}
                              />
                            </Grid>
                          ))}
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start"
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isOtherSelected}
                                  sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black"
                                    }
                                  }}
                                />
                              }
                              label="Other"
                            />
                            <TextField
                              fullWidth
                              variant="standard"
                              disabled={!isOtherSelected}
                              value={otherToolName}
                              sx={{
                                input: { color: "black", textAlign: "center" },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      pt: "50px",
                      borderTop: "2px solid #000"
                    }}
                    xs={12}
                  >
                    <Grid container direction="column" spacing={3}>
                      {toolings.map((tooling, index) => (
                        <Grid item key={index}>
                          <PMDocumentGrid
                            container
                            justifyContent={"start"}
                            spacing={2}
                            sx={{ marginLeft: "10px" }}
                          >
                            <Typography>{index + 1}. Process :</Typography>
                            <TextField
                              variant="standard"
                              value={toolings[index].die}
                              sx={{
                                width: "200px",
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                            <Typography sx={{ pr: "20px" }}>M/C</Typography>

                            <Typography>Size(ขนาดเครื่องจักร)</Typography>
                            <TextField
                              variant="standard"
                              type="text"
                              value={toolings[index].size}
                              sx={{
                                width: 200,
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />

                            <Typography sx={{ pr: "20px" }}>
                              Tons(ตัน)
                            </Typography>

                            <Typography>จำนวน</Typography>
                            <TextField
                              type="number"
                              variant="standard"
                              value={toolings[index].amount}
                              sx={{
                                width: "50px",
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                            <Typography sx={{ pr: "20px" }}>เครื่อง</Typography>

                            <Typography>Line</Typography>
                            <TextField
                              variant="standard"
                              type="text"
                              value={toolings[index].line}
                              sx={{
                                width: 150,
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />

                            <Typography sx={{ pr: "20px" }}>No.</Typography>

                            <Typography>M/C (เลขที่เครื่องจักร)</Typography>
                            <TextField
                              variant="standard"
                              type="text"
                              value={toolings[index].machineCode}
                              sx={{
                                width: 200,
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          </PMDocumentGrid>
                        </Grid>
                      ))}

                      <Grid
                        container
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "10px"
                        }}
                      >
                        <PMDocumentGrid
                          item
                          justifyContent={"start"}
                          xs={6}
                          sx={{ paddingLeft: "0px" }}
                        >
                          <Typography>MATERIAL (วัตถุดิบ) </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            value={filteredData?.material || material}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>ผิวชุบ Plating Coating </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            value={filteredData?.plating || plating}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>

                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>Trial No. (เลขที่ทดลอง) </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            value={filteredData?.trialNo || trialNo}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>Q'ty (จำนวนชิ้น) </Typography>
                          <TextField
                            type="number"
                            variant="standard"
                            value={filteredData?.quantity || quantity}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                          <Typography>Pcs. (ชิ้น)</Typography>
                        </PMDocumentGrid>
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>JOB No.(เลขที่สั่งงาน) </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            value={filteredData?.jobNo || jobNo}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      mt: "50px"
                    }}
                    xs={12}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell rowSpan={2} colSpan={2}>
                            <Typography>ฝ่าย/แผนก</Typography>
                            <Typography>Department/Section Division</Typography>
                          </StyledTableCell>
                          <StyledTableCell colSpan={2}>
                            <Typography>
                              Trial Plan Date(วันที่ทดลอง)
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell rowSpan={2}>
                            <Typography>Signature</Typography>
                            <Typography>ลงชื่อ</Typography>
                          </StyledTableCell>
                          <StyledTableCell rowSpan={2}>
                            <Typography>MEMBER TRIAL</Typography>
                            <Typography>รายชื่อผู้ร่วมทดลองการผลิต</Typography>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>Plan Date(วัน)</StyledTableCell>
                          <StyledTableCell>Plan Time(เวลา)</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>วิศวกรโครงการ</StyledTableCell>
                          <StyledTableCell>PJ</StyledTableCell>
                          <StyledTableCell>{pLPlanDate}</StyledTableCell>
                          <StyledTableCell>
                            {filteredData?.pLStartTime} -{" "}
                            {filteredData?.pLEndTime}
                            {` น.`}
                          </StyledTableCell>
                          <StyledTableCell>{pJSignature}</StyledTableCell>
                          <StyledTableCell>
                            {parseAndFormatArray(filteredData?.pJMembersTrial)}
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>วางแผน</StyledTableCell>
                          <StyledTableCell>PL</StyledTableCell>
                          <StyledTableCell>{pLPlanDate}</StyledTableCell>
                          <StyledTableCell>
                            {filteredData?.pLStartTime} -{" "}
                            {filteredData?.pLEndTime}
                            {` น.`}
                          </StyledTableCell>
                          <StyledTableCell>{pLSignature}</StyledTableCell>
                          <StyledTableCell>
                            {parseAndFormatArray(filteredData?.pLMembersTrial)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {displayDivision.eng ? (
                          <StyledTableRow>
                            <StyledTableCell>วิศวกรรม</StyledTableCell>
                            <StyledTableCell>ENG</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature && eNGSignature
                                ? pLPlanDate
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {eNGSignature
                                ? displayTimeToDivision(
                                  filteredData?.pLStartTime,
                                  filteredData?.pLEndTime,
                                  pLSignature
                                )
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>{eNGSignature}</StyledTableCell>
                            <StyledTableCell>
                              {parseAndFormatArray(
                                filteredData?.eNGMembersTrial
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}

                        {displayDivision.pd1 ? (
                          <StyledTableRow>
                            <StyledTableCell>ผลิตโรง 1</StyledTableCell>
                            <StyledTableCell>PD 1</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature && pD1Signature
                                ? pLPlanDate
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {pD1Signature
                                ? displayTimeToDivision(
                                  filteredData?.pLStartTime,
                                  filteredData?.pLEndTime,
                                  pLSignature
                                )
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>{pD1Signature}</StyledTableCell>
                            <StyledTableCell>
                              {parseAndFormatArray(
                                filteredData?.pD1MembersTrial
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}

                        {displayDivision.pd2 ? (
                          <StyledTableRow>
                            <StyledTableCell>ผลิตโรง 2</StyledTableCell>
                            <StyledTableCell>PD 2</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature && pD2Signature
                                ? pLPlanDate
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {pD2Signature
                                ? displayTimeToDivision(
                                  filteredData?.pLStartTime,
                                  filteredData?.pLEndTime,
                                  pLSignature
                                )
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>{pD2Signature}</StyledTableCell>
                            <StyledTableCell>
                              {parseAndFormatArray(
                                filteredData?.pD2MembersTrial
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}

                        {displayDivision.pd ? (
                          <StyledTableRow>
                            <StyledTableCell>
                              ประกอบ Spot/Wedling Assemble
                            </StyledTableCell>
                            <StyledTableCell>PD</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature && pDSignature
                                ? pLPlanDate
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {pDSignature
                                ? displayTimeToDivision(
                                  filteredData?.pLStartTime,
                                  filteredData?.pLEndTime,
                                  pLSignature
                                )
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>{pDSignature}</StyledTableCell>
                            <StyledTableCell>
                              {parseAndFormatArray(
                                filteredData?.pDMembersTrial
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}

                        {displayDivision.pt ? (
                          <StyledTableRow>
                            <StyledTableCell>ชุบโลหะ</StyledTableCell>
                            <StyledTableCell>PT</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature && pTSignature
                                ? pLPlanDate
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {pTSignature
                                ? displayTimeToDivision(
                                  filteredData?.pLStartTime,
                                  filteredData?.pLEndTime,
                                  pLSignature
                                )
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>{pTSignature}</StyledTableCell>
                            <StyledTableCell>
                              {parseAndFormatArray(
                                filteredData?.pTMembersTrial
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}

                        {displayDivision.qa ? (
                          <StyledTableRow>
                            <StyledTableCell>ประกันคุณภาพ</StyledTableCell>
                            <StyledTableCell>QA</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature && qASignature
                                ? pLPlanDate
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {qASignature
                                ? displayTimeToDivision(
                                  filteredData?.pLStartTime,
                                  filteredData?.pLEndTime,
                                  pLSignature
                                )
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>{qASignature}</StyledTableCell>
                            <StyledTableCell>
                              {parseAndFormatArray(
                                filteredData?.qAMembersTrial
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}

                        {displayDivision.mt ? (
                          <StyledTableRow>
                            <StyledTableCell>ซ่อมบำรุง</StyledTableCell>
                            <StyledTableCell>MT</StyledTableCell>
                            <StyledTableCell>
                              {pLPlanDate && pLSignature && mTSignature
                                ? pLPlanDate
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {mTSignature
                                ? displayTimeToDivision(
                                  filteredData?.pLStartTime,
                                  filteredData?.pLEndTime,
                                  pLSignature
                                )
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>{mTSignature}</StyledTableCell>
                            <StyledTableCell>
                              {parseAndFormatArray(
                                filteredData?.mTMembersTrial
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell
                rowSpan={4}
                sx={{ width: 650, p: "0 20px 0 20px" }}
              >
                <Typography sx={{ fontSize: "20px" }}>
                  PJ (ข้อเสนอแนะ)
                </Typography>

                <TextField
                  variant="standard"
                  type="text"
                  multiline
                  fullWidth
                  minRows={5}
                  maxRows={Infinity}
                  value={commentPj}
                />
                <Typography sx={{ fontSize: "20px" }}>
                  PL (ข้อเสนอแนะ)
                </Typography>
                <TextField
                  variant="standard"
                  type="text"
                  multiline
                  fullWidth
                  minRows={5}
                  maxRows={Infinity}
                  value={commentPl}
                />
              </StyledTableCell>
              <StyledTableCell>
                Approve by
                <br />
                (ผู้อนุมัติ)
              </StyledTableCell>
              <StyledTableCell>
                Approve by
                <br />
                (ผู้อนุมัติ)
              </StyledTableCell>
              <StyledTableCell>
                Check by
                <br />
                (ผู้ตรวจสอบ)
              </StyledTableCell>
              <StyledTableCell>
                Prepare by
                <br />
                (ผู้จัดทำ)
              </StyledTableCell>
            </TableRow>
            <TableRow sx={{ height: 150 }}>
              <StyledTableCell sx={{ height: "50px" }}>
                {fmSignature ? (
                  fmSignature
                ) : (
                  <ThemedButton
                    sx={{
                      bgcolor: theme.palette.warning.main
                    }}
                    variant="outlined"
                    onClick={() => {
                      fmConfirm(`อนุมัติเอกสารนี้โดย: ${user.employee}`)
                    }}
                  >
                    Verify
                  </ThemedButton>
                )}
              </StyledTableCell>
              <StyledTableCell>{engineerHead1Signature}</StyledTableCell>
              <StyledTableCell>{engineerHead2Signature}</StyledTableCell>
              <StyledTableCell>{pJSignature}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ height: "25px" }}>
                {fmSignatureTime}
              </StyledTableCell>
              <StyledTableCell>{engineerHead1SignatureTime}</StyledTableCell>
              <StyledTableCell>{engineerHead2SignatureTime}</StyledTableCell>
              <StyledTableCell>
                {filteredData?.pJSignature ? filteredData?.pJSignatureTime : ""}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>FM ผู้จัดการโรงงาน</StyledTableCell>
              <StyledTableCell colSpan={3}>
                Reqeust by (ผู้ร้องขอ) Project Engineer
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </ThemeProvider>
  )
}

interface IProp {
  filteredData?: ITrialToolingData
}
const TrialToolingSheetFmApproval: React.FC<IProp> = (props: IProp) => {
  const { filteredData } = props
  return (
    <>
      <Typography
        variant="h1"
        sx={{ fontSize: "50px", textAlign: "center", pb: "20px" }}
      >
        สำหรับ FM Approve
      </Typography>
      <div className="hidden-print">
        <ThemedButton
          variant="outlined"
          aria-label="print"
          endIcon={<PrintIcon />}
          style={{
            marginBottom: "5px"
          }}
          onClick={() => window.print()}
        >
          Print
        </ThemedButton>
      </div>
      <div className="repair-print-head">
        <TrialToolingPaper filteredData={filteredData} />
      </div>
    </>
  )
}

export default TrialToolingSheetFmApproval
