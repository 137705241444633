import {
  Box,
  Grid,
  Autocomplete,
  useTheme,
  TableHead,
  Table,
  TableContainer,
  TableBody,
  styled,
  TableCell,
  TableRow,
  CircularProgress,
  Dialog,
  DialogContent
} from "@mui/material"
import React, { useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { PageLabel } from "../../common/Resources/PageLabel"
import { GET_WORK_IN_PROCESS } from "../../common/Query/PlanningQuery"
import "../../css/Dashboard/Dashboard.css"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import AutocompleteCustomer from "../../common/Resources/AutocompleteCustomer"
import moment from "moment"
import swal from "sweetalert"
import { conditionLinePress, logoTable } from "../../common/utilities"
// import axios from "axios"
import { CSVLink } from "react-csv"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"

const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  borderBottom: "1px",
  border: "solid 1px black",
  backgroundColor: "white",
  color: "black"
}))

const StyledTableRow = styled(TableRow)(() => ({
  borderBottom: "1px",
  "&:last-child td": { borderBottom: "1px solid black" },
  border: "solid 1px black"
}))

interface Column {
  id:
    | "actionDate"
    | "customer"
    | "partNo"
    | "jobOrder"
    | "factory"
    | "quantity"
    | "wip"
    | "remarks"
    | "process"
    | "inventoryCost"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}
const pageTitle = "Work In Process"
const columns: Column[] = [
  { id: "actionDate", label: "วันที่เบิกวัตถุดิบ", minWidth: 120 },
  { id: "customer", label: "Customer", minWidth: 50 },
  { id: "partNo", label: "PartNo", minWidth: 50 },
  { id: "jobOrder", label: "JobOrder", minWidth: 50 },
  { id: "factory", label: "Factory", minWidth: 50 },
  { id: "quantity", label: "Quantity/Job", minWidth: 50 },
  { id: "wip", label: "WIP", minWidth: 50 },
  { id: "inventoryCost", label: "Inventory Cost", minWidth: 50 },
  { id: "process", label: "Process", minWidth: 50 },
  { id: "remarks", label: "Remarks", minWidth: 50 }
]
export interface INewProcesses {
  jobOrder?: string
  factory?: string
  customer?: string
  actionDate?: string
  processOrder: string
  stepProcess: number
  partNo: string
  process: string
  machine: string
  line: string
  jph: number
  minStroke: number
  quantity: number
  complete: string
  remarks: string
  wip: number
  cost: number
  inventoryCost: number
  amount: IAmount
}
export interface IAmount {
  quantity: number
  ng: number
  total: number
  complete: string
}
const WorkInProcess: React.FC = () => {
  const [customer, setCustomer] = useState<string>("")
  const theme = useTheme()
  const [factory, setFactory] = useState<string>("")
  const [line, setLine] = useState<string>("")
  const [wipData, setWipData] = useState<INewProcesses[]>([])
  const [error, setError] = useState<boolean>(false)
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const factoryList: string[] = ["1", "2"]
  const [workInProcess, { loading }] = useLazyQuery(GET_WORK_IN_PROCESS)

  const handleSubmit = async () => {
    if (dateFrom === "" || dateTo === "" || factory === "") {
      setError(true)
      return swal("ERROR", "กรุณากรอกข้อมูล", "error")
    }
    if (dateTo === "") {
      return swal("ERROR", "กรุณาใส่วันที่", "error")
    }
    if (!factory) {
      setError(true)
      return swal("", "Please Select Factory", "error")
    }
    const getData = await workInProcess({
      variables: {
        dateFrom,
        dateTo,
        factory,
        partNo: ""
      },
      fetchPolicy: "network-only"
    })

    // setLoading(true)
    // const getData = await axios({
    //   method: "get",
    //   url: `http://ec2-52-221-189-35.ap-southeast-1.compute.amazonaws.com:3000/?dateFrom=${dateFrom}&dateTo=${dateTo}&factory=${factory}`
    // })
    // setLoading(false)
    const data = (getData.data?.workInProcess)
      .filter(
        (data: INewProcesses) => data.amount.total - data.amount.quantity !== 0
      )
      .map((e: INewProcesses) => {
        const inventoryC = Number(
          (e.cost * 0.65 * (e.amount.total - e.amount.quantity)).toFixed(2)
        )
        return {
          ...e,
          inventoryCost: inventoryC,
          quantity: e.amount.total,
          wip: e.amount.total - e.amount.quantity
        }
      })
    if (customer) {
      setWipData(
        data.filter(
          (data: { customer: string; amount: IAmount }) =>
            data.customer === customer
        )
      )
    } else {
      setWipData(data)
    }
  }
  let rows: INewProcesses[] = wipData
  if (line === "Stamping")
    rows = rows.filter((e: { line: string }) => conditionLinePress(e.line))
  if (line === "Qc")
    rows = rows.filter((e: { line: string }) => e.line === "QC")
  if (line === "Plating")
    rows = rows.filter((e: { line: string }) => e.line === "PLATING")
  if (line === "SPOT")
    rows = rows.filter((e: { line: string }) => e.line === "LINE SPOT")
  if (line === "ARC WELDING")
    rows = rows.filter((e: { line: string }) => e.line === "ARC WELDING")
  if (line === "CHECK100")
    rows = rows.filter((e: { line: string }) => e.line === "CHECK100")
  const totalQuantityPerJob = rows?.reduce(
    (prev: number, cur: INewProcesses) => {
      return prev + cur.quantity
    },
    0
  )
  const totalQuantityWip = rows?.reduce((prev: number, cur: INewProcesses) => {
    return prev + cur.wip
  }, 0)

  const totalInventoryCost = Number(
    rows
      ?.reduce((prev: number, cur: INewProcesses) => {
        return prev + cur.inventoryCost
      }, 0)
      .toFixed(2)
  )
  const wipPercent = (totalQuantityWip / totalQuantityPerJob) * 100

  return (
    <div className="MPReportToPrint">
      <Dialog open={loading}>
        <DialogContent style={{ width: "auto" }}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <PageLabel
        menuItem={menuItemList.Planning}
        menuItemName={Object.keys(menuItemList)[2]}
        menuPageName={pageTitle}
      />
      <br />

      <ThemedCard style={{ padding: "20px" }}>
        <>
          <Box
            sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <PageLabelCard
              title="Work In Process"
              subTitle="Retrieve Work In Process"
            />

            <Box component="form" className="display-none-print">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <ThemedTextField
                    type="date"
                    fullWidth
                    value={dateFrom}
                    error={!dateFrom && error}
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <ThemedTextField
                    type="date"
                    fullWidth
                    value={dateTo}
                    error={!dateTo && error}
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    value={factory}
                    onChange={(event, selectedFactory) => {
                      setFactory(selectedFactory as string)
                    }}
                    options={factoryList}
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label="Factory"
                        error={!factory && error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <AutocompleteCustomer
                    setCustomer={setCustomer}
                    customer={customer}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={1}>
                <ThemedLoadingButton
                  variant="contained"
                  sx={{
                    mt: 2,
                    mb: 2
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </ThemedLoadingButton>
                <ThemedButton
                  style={{
                    marginLeft: "10px"
                  }}
                  variant="outlined"
                  color="inherit"
                  sx={{ mt: 2, mb: 2 }}
                  onClick={() => window.print()}
                >
                  Print
                </ThemedButton>
                <CSVLink
                  data={rows.map((val) => {
                    return {
                      วันที่เบิกวัตถุดิบ: val.actionDate,
                      Customer: val.customer,
                      "Part No": val.partNo,
                      JobOrder: val.jobOrder,
                      factory: val.factory,
                      quantityJob: val.quantity,
                      Wip: val.wip,
                      Process: `"${val.process}`,
                      Remark: val.remarks,
                      inventoryCost: val.inventoryCost
                    }
                  })}
                  filename={`${customer}-${moment(dateFrom).format(
                    "DD-MM-YYYY"
                  )}To${moment(dateFrom).format("DD-MM-YYYY")}-WIP`}
                >
                  <ThemedButton
                    variant="contained"
                    style={{
                      marginLeft: "15px",
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? "yellowgreen"
                          : "darkgreen"
                    }}
                  >
                    Download CSV
                  </ThemedButton>
                </CSVLink>
              </Grid>
            </Box>
          </Box>
          <PageLabelCard title="Wip Report" />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                fullWidth
                disablePortal
                value={line}
                onChange={(event, selectedLine) => {
                  setLine(selectedLine as string)
                }}
                options={[
                  "Stamping",
                  "QC",
                  "Plating",
                  "SPOT",
                  "ARC WELDING",
                  "CHECK100"
                ]}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Line"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <br></br>
          <div className="print-sm-order">
            <div className={"inventory-table"}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <StyledTableRow style={{ borderBottom: "1px solid gray" }}>
                      <StyledTableCell
                        colSpan={6}
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        <img
                          style={{
                            verticalAlign: "top",
                            float: "left"
                          }}
                          height="80vh"
                          src={logoTable}
                          alt=""
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        colSpan={2}
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          textAlign: "center"
                        }}
                      >
                        วันที่
                        <br />
                        {moment(dateFrom).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        colSpan={2}
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          textAlign: "center"
                        }}
                      >
                        ถึงวันที่
                        <br />
                        {moment(dateTo).format("DD-MM-YYYY")}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          className="setBorder"
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth
                          }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row, i) => {
                      return (
                        <StyledTableRow hover tabIndex={-1} key={i}>
                          {columns.map((column) => {
                            const value = row[column.id]
                            return (
                              <StyledTableCell
                                key={column.id}
                                align={column.align}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </StyledTableCell>
                            )
                          })}
                        </StyledTableRow>
                      )
                    })}

                    <StyledTableRow>
                      <StyledTableCell
                        style={{ fontSize: "16px", fontWeight: "bolder" }}
                        colSpan={5}
                      >
                        Total
                      </StyledTableCell>
                      <StyledTableCell>{totalQuantityPerJob}</StyledTableCell>
                      <StyledTableCell>{totalQuantityWip}</StyledTableCell>
                      <StyledTableCell>{totalInventoryCost}</StyledTableCell>
                      <StyledTableCell>WIP %</StyledTableCell>
                      <StyledTableCell>
                        {Math.round(wipPercent) || 0}%
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Table
                  style={{
                    marginTop: "25px",
                    width: "400px"
                  }}
                >
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        height={"100px"}
                        style={{
                          border: "1px solid black"
                        }}
                      ></StyledTableCell>
                      <StyledTableCell
                        height={"100px"}
                        style={{
                          border: "1px solid black"
                        }}
                      ></StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        style={{
                          textAlign: "center",
                          border: "1px solid black"
                        }}
                      >
                        Approved
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          textAlign: "center",
                          border: "1px solid black"
                        }}
                      >
                        Checked
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </>
      </ThemedCard>
    </div>
  )
}
export default WorkInProcess
