import { gql } from "@apollo/client"

export const GET_CUSTOMERS = gql`
  query GetCustomers {
    customers {
      customer
    }
  }
`
export const GET_MATERIALS = gql`
  query Materials {
    materials {
      matNo
      matName
      sizeL
      sizeW
      sizeH
      ratio
      partType
      spec
      wipQtyBox
    }
  }
`

export const GET_PARTS = gql`
  query Parts {
    parts {
      partNo
      partName
      customer
      model
      cost
      leadTime
      stamp
      weld
      arcweld
      plate
      boxStd
    }
  }
`

export const GET_MACHINES = gql`
  query Machines {
    machines {
      machine
      code
      line
      factory
      frequency
      priority
      company
      model
      brand
      installationDate
      details
      machinePower
      remarks
    }
  }
`

export const GET_PROCESS = gql`
  query GetProcess {
    process {
      process
    }
  }
`

export const GET_PARTS_PROCESS = gql`
  query GetPartsProcessings {
    partsprocessings {
      processOrder
      stepProcess
      partNo
      process
      machine
      line
      jph
      minStroke
      operator
      customer
    }
  }
`

export const GET_SUPPLIERS = gql`
  query Suppliers {
    suppliers {
      supplierName
      type
    }
  }
`

export const GET_EMPLOYEE = gql`
  query Employee {
    employee {
      employeeId
      firstName
      lastName
      departmentId
      factory
      position
    }
  }
`

export const GET_DEPARTMENT = gql`
  query Department {
    department {
      departmentId
      departmentName
      departmentNameThai
    }
  }
`

export const GET_BOM = gql`
  query QueryBom($partNo: String) {
    queryBom(partNo: $partNo) {
      partNo
      children
      type
      ratio
      factory
    }
  }
`
