import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Tab,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"
import DateAdapter from "@mui/lab/AdapterMoment"
import moment from "moment"
import { useEffect, useState } from "react"
import ThemedTabs from "../../common/Resources/ThemedComponents/ThemedTabs"
import TrialToolingCreate from "./Component/TrialToolingCreate"
import { useQuery } from "@apollo/client"
import { GET_TRIAL_TOOLING_SHEET } from "../../common/Query/engineerQuery"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import React from "react"
import TrialToolingSheetPlanningApproval from "./Component/TrialToolingSheetPlanningApproval"
import TrialToolingSheetPeApproval from "./Component/TrialToolingSheetPeApproval"
import TrialToolingSheetDivisionApproval from "./Component/TrialToolingSheetDivisionApproval"
import TrialToolingSheetFmApproval from "./Component/TrialToolingSheetFmApproval"
import { ITrialToolingData } from "./Component/TrialToolingSheetPlanningApproval"
import { clearParam } from "../../common/utilities"
import swal from "sweetalert"
import { makeStyles } from "@mui/styles"

interface IPropsTabPanel {
  children?: React.ReactNode
  index: number
  value: number
}

interface ICodeList {
  recordNo: string
  trialDate: string
  controlNo: string
  fmSignature: string
}

const TabPanel = (props: IPropsTabPanel) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

interface INextStepProcessEndAdornment {
  endAdornment: React.ReactNode
  status: string | undefined
  recordNo: string | null
}

const NextStepProcessEndAdornment = (props: INextStepProcessEndAdornment) => {
  const { endAdornment, status, recordNo } = props
  const children = React.Children.toArray(
    (endAdornment as React.ReactElement)?.props?.children
  )
  recordNo
    ? children.unshift(
      <CheckCircleIcon
        sx={{
          color: status ? "green" : "red"
        }}
      />
    )
    : null
  return React.isValidElement(endAdornment)
    ? React.cloneElement(endAdornment, {}, children)
    : null
}

const pageTitle = "Trial Tooling Sheet"

const TrialToolingSheet: React.FC = () => {
  const theme = useTheme()
  const [selectDate, setSelectDate] = useState<Date>(new Date())
  const [tapMenu, setTapMenu] = useState<number>(0)
  const [recordNo, setRecord] = useState<string | null>("")
  const [factory, setFactory] = useState<string | null>("")
  const [filteredData, setFilteredData] = useState<ITrialToolingData>()

  const { data: getTrialToolingData } = useQuery(GET_TRIAL_TOOLING_SHEET)

  let codeList = [] as ICodeList[]
  if (getTrialToolingData && factory) {
    codeList = getTrialToolingData?.getTrialToolingSheet
      .filter(
        (data: { factory: string; trialDate: string }) =>
          data.factory === factory &&
          moment(selectDate).format("YYYY-MM") ===
          moment(data.trialDate).format("YYYY-MM")
      )
      .sort(
        (a: { recordNo: string }, b: { recordNo: string }) =>
          a.recordNo > b.recordNo
      )
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTapMenu(newValue)
  }
  const tapProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    }
  }

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const factoryParam = urlParams.get("factory")
  const dateParam = urlParams.get("date")
  const docNoParam = urlParams.get("docNo")
  const panelParam = urlParams.get("panel")
  const panelInt = panelParam ? parseInt(panelParam, 10) : 0
  const dataIdParam = urlParams.get("dataId")
  const dataIdInt = dataIdParam ? parseInt(dataIdParam, 10) : 0

  useEffect(() => {
    if (
      getTrialToolingData &&
      factoryParam &&
      dateParam &&
      docNoParam &&
      panelParam &&
      dataIdParam
    ) {
      setFactory(factoryParam)
      setSelectDate(dateParam ? moment(dateParam).toDate() : new Date())
      setRecord(docNoParam)
      setTapMenu(panelInt)

      const filterInfo = getTrialToolingData.getTrialToolingSheet.filter(
        (data: { trialId: number }) => data.trialId === dataIdInt
      )
      if (filterInfo.length < 1) {
        swal("Error", "ไม่พบข้อมูล", "warning")
      } else {
        setFilteredData(
          filterInfo && filterInfo.length > 0 ? filterInfo[0] : []
        )
        swal("Success", "Load ข้อมูลเรียบร้อย", "success")
      }
      clearParam("TrialToolingSheet")
    }
  }, [
    getTrialToolingData,
    factoryParam,
    dateParam,
    docNoParam,
    panelParam,
    dataIdParam
  ])

  const useStyles = makeStyles({
    tabs: {
      "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.secondary.contrastText
      },
      "& .MuiTab-root.Mui-selected": {
        color: theme.palette.secondary.contrastText
      }
    }
  })

  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Engineer}
        menuItemName={Object.keys(menuItemList)[5]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="Trial Tooling Sheet"
          subTitle="ใช้สร้างและอนุมัติใบขอทดลองผลิต"
        />
        <Grid container spacing={2} width={"98%"} margin={2}>
          <Box sx={{ width: "100%" }}>
            <div className="hidden-print">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <ThemedTextField
                    id="factory"
                    fullWidth
                    label="Factory No."
                    value={factory}
                    onChange={(e) => {
                      setFactory(e.target.value)
                      setRecord("")
                    }}
                    select
                  >
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                  </ThemedTextField>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Select Month"
                      views={["year", "month"]}
                      value={selectDate}
                      onChange={(newSelectDate) => {
                        setSelectDate(moment(newSelectDate).toDate() as Date)
                      }}
                      PaperProps={{
                        sx: {
                          "& .MuiCalendarPicker-root": {
                            ".Mui-selected": {
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? theme.palette.warning.main
                                  : theme.palette.primary.main,
                              color: "#000000"
                            }
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <ThemedTextField
                          {...params}
                          fullWidth
                          sx={{
                            "& .Mui-selected": {
                              backgroundColor: theme.palette.warning.main
                            }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Autocomplete
                    fullWidth
                    id="next-step-process"
                    disablePortal
                    value={
                      codeList.find(
                        (val) =>
                          `(No. ${val.recordNo})  Date ${val.trialDate}` ===
                          recordNo
                      ) || null
                    }
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label={"Document No."}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <NextStepProcessEndAdornment
                              endAdornment={params.InputProps.endAdornment}
                              status={
                                codeList.find(
                                  (val) =>
                                    `(No. ${val.recordNo})  Date ${val.trialDate}` ===
                                    recordNo
                                )?.fmSignature
                              }
                              recordNo={recordNo}
                            />
                          )
                        }}
                      />
                    )}
                    onChange={(e, newValue) => {
                      const filterData =
                        getTrialToolingData.getTrialToolingSheet.filter(
                          (data: {
                            factory: string
                            trialDate: string
                            recordNo: string
                          }) => {
                            return (
                              data.factory === factory &&
                              data.recordNo === newValue?.recordNo &&
                              data.trialDate === newValue?.trialDate
                            )
                          }
                        )
                      setFilteredData(filterData[0])

                      setRecord(
                        newValue?.recordNo
                          ? `(No. ${newValue.recordNo})  Date ${newValue.trialDate}`
                          : ""
                      )
                    }}
                    getOptionLabel={(option) =>
                      option?.recordNo
                        ? `(No. ${option.recordNo})  Date ${option.trialDate}`
                        : ""
                    }
                    renderOption={(props, options) => {
                      return (
                        <Box component="li" {...props}>
                          <Grid container sx={{ padding: "0 5px" }}>
                            <Grid item xs="auto">
                              {`(No. ${options.recordNo})  Date ${options.trialDate}`}
                            </Grid>
                            <Grid item container xs justifyContent="flex-end">
                              <CheckCircleIcon
                                sx={{
                                  color: options.fmSignature ? "green" : "red"
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      )
                    }}
                    options={codeList}
                  />
                </Grid>
              </Grid>
            </div>

            <Box className="hidden-print" sx={{ mt: "35px" }}>
              <ThemedTabs
                value={tapMenu}
                onChange={handleChange}
                className={classes.tabs}
              >
                <Tab label="CREATE" {...tapProps(0)} />
                <Tab label="PLANNING_APPROVE" {...tapProps(1)} />
                <Tab label="PROJECT_ENGINEER_APPROVE" {...tapProps(2)} />
                <Tab label="SECTION_DIVISION_APPROVE" {...tapProps(3)} />
                <Tab label="FM__APPROVE" {...tapProps(4)} />
              </ThemedTabs>
            </Box>

            <TabPanel value={tapMenu} index={0}>
              <TrialToolingCreate />
            </TabPanel>

            <TabPanel value={tapMenu} index={1}>
              {filteredData ? (
                <TrialToolingSheetPlanningApproval
                  filteredData={filteredData}
                />
              ) : (
                <div></div>
              )}
            </TabPanel>
            <TabPanel value={tapMenu} index={2}>
              {filteredData && filteredData.pLSignature ? (
                <TrialToolingSheetPeApproval filteredData={filteredData} />
              ) : (
                <div></div>
              )}
            </TabPanel>
            <TabPanel value={tapMenu} index={3}>
              {filteredData &&
                filteredData.engineerHead1Signature &&
                filteredData.engineerHead2Signature ? (
                <TrialToolingSheetDivisionApproval
                  filteredData={filteredData}
                />
              ) : (
                <div></div>
              )}
            </TabPanel>
            <TabPanel value={tapMenu} index={4}>
              {filteredData &&
                filteredData.pLSignature &&
                filteredData.engineerHead1Signature &&
                filteredData.engineerHead2Signature ? (
                <TrialToolingSheetFmApproval filteredData={filteredData} />
              ) : (
                <div></div>
              )}
            </TabPanel>
          </Box>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default TrialToolingSheet
