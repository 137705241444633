import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { useState } from "react"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { departMentList, factoryList } from "../../common/utilities"
import AddIcon from "@mui/icons-material/Add"

import { useLazyQuery } from "@apollo/client"
import moment from "moment"
import { GET_PREMIUM_FREIGHT } from "../../common/Query/QCQuery"
import AutocompleteCustomer from "../../common/Resources/AutocompleteCustomer"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

interface IDataPremiumFreight {
  customer: string
  customerClaim: number
  id: number
  numberOfPeople: number
  totalHour: number
  cost: number
  actionDate: string
  factory: string
  department: string
  recordBy: string
  remarks: string
  allowance: number
}

const PremiumFreight: React.FC = () => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dataPremiumFreight, setDataPremiumFreight] = useState<
    IDataPremiumFreight[]
  >([])
  const [factory, setFactory] = useState<string>("")

  const [customerClaim, setCustomerClaim] = useState<boolean>(false)
  const [customer, setCustomer] = useState<string>("")
  const [department, setDepartment] = useState<string>("")
  const pageTitle = "Premium Freight Report"

  const [getPremiumFreight, { loading }] = useLazyQuery(GET_PREMIUM_FREIGHT)
  const theme = useTheme()
  const handleSubmit = async () => {
    try {
      const result = await getPremiumFreight({
        variables: { dateTo, dateFrom, factory },
        fetchPolicy: "network-only"
      })
      let data = result?.data?.premiumFreightReport
      if (department) {
        data = data.filter(
          (e: IDataPremiumFreight) => e.department === department
        )
      }
      if (customer) {
        data = data.filter((e: IDataPremiumFreight) => e.customer === customer)
      }
      if (customerClaim) {
        data = data.filter((e: IDataPremiumFreight) => e.customerClaim > 0)
      }
      setDataPremiumFreight(data)
    } catch (error) {
      alert((error as Error).message)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="hidden-print">
        <PageLabel
          menuItem={menuItemList.Qc}
          menuItemName={Object.keys(menuItemList)[4]}
          menuPageName={pageTitle}
        />
      </div>
      <ThemedCard>
        <PageLabelCard
          title="Premium Freight Report"
          subTitle="about detail premium freight Report"
        />
        <Grid container spacing={2} width={"98%"} padding={2}>
          <Grid item xs={12} sm={2}>
            <ThemedTextField
              value={dateFrom}
              fullWidth
              id="dateFrom"
              type="date"
              onChange={(e) => setDateFrom(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ThemedTextField
              value={dateTo}
              fullWidth
              id="dateTo"
              type="date"
              onChange={(e) => setDateTo(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              value={factory}
              fullWidth
              onChange={(event, department) => {
                setFactory(department as string)
              }}
              options={factoryList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Factory"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              value={department}
              fullWidth
              onChange={(event, department) => {
                setDepartment(department as string)
              }}
              options={departMentList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Department"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <AutocompleteCustomer
              setCustomer={setCustomer}
              customer={customer}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setCustomerClaim(e.target.checked)
                  }}
                />
              }
              label="Customer Claim"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ThemedLoadingButton
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
              loading={loading}
              endIcon={<AddIcon />}
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              Submit
            </ThemedLoadingButton>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Man</TableCell>
              <TableCell>Hour</TableCell>
              <TableCell>Baht / Hour</TableCell>
              <TableCell>Allowance</TableCell>
              <TableCell>Man Hour Cost</TableCell>
              <TableCell>Customer Claim</TableCell>
              <TableCell>Factory</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Remark</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataPremiumFreight.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.actionDate}</TableCell>
                  <TableCell>{item.customer}</TableCell>
                  <TableCell>{item.numberOfPeople}</TableCell>
                  <TableCell>{item.totalHour.toFixed(2)}</TableCell>
                  <TableCell>{item.cost.toFixed(2)}</TableCell>
                  <TableCell>{item.allowance.toFixed(2)}</TableCell>
                  <TableCell>
                    {(
                      item.numberOfPeople * item.totalHour * item.cost +
                      item.allowance
                    ).toFixed(2)}
                  </TableCell>
                  <TableCell>{item.customerClaim.toFixed(2)}</TableCell>
                  <TableCell>{item.factory}</TableCell>
                  <TableCell>{item.department}</TableCell>
                  <TableCell>{item.remarks}</TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell colSpan={5} />
              <TableCell>Total</TableCell>
              <TableCell>
                {dataPremiumFreight.reduce((prev, obj) => {
                  return (
                    prev +
                    obj.totalHour * obj.cost * obj.numberOfPeople +
                    obj.allowance
                  )
                }, 0)}
              </TableCell>
              <TableCell>
                {dataPremiumFreight.reduce((prev, obj) => {
                  return prev + obj.customerClaim
                }, 0)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default PremiumFreight
