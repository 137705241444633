import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  tableCellClasses
} from "@mui/material"
import { getImagePng } from "../../../../common/utilities"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2,
    color: "black"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  border: "solid 1px black",
  "&:nth-of-type(odd)": {
    border: "solid 1px black"
  },

  "&:last-child td, &:last-child th": {
    border: "solid 1px black"
  }
}))

interface IApproveSignatureProps {
  diffHours: number
}

export const ApproverProductionPlan: React.FC<IApproveSignatureProps> = (
  props: IApproveSignatureProps
) => {
  const { diffHours } = props
  return (
    <Table>
      <TableBody>
        <StyledTableRow>
          <StyledTableCell colSpan={1} align="center">
            Approve By
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          {diffHours >= 22 ? (
            <StyledTableCell style={{ height: "46px" }}>
              <img
                src={getImagePng("images/signature/signature_nut")}
                width={"55px"}
              />
            </StyledTableCell>
          ) : (
            <StyledTableCell
              style={{
                height: "46px"
              }}
            />
          )}
        </StyledTableRow>
      </TableBody>
    </Table>
  )
}
