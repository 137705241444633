import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { PageLabelCard } from "../../../common/Resources/PageLabelCard"
import ThemedCard from "../../../common/Resources/ThemedComponents/ThemedCard"
import UploadFileBox, {
  SelectedFileBox
} from "../../../common/Resources/UploadFileBox"
import { Grid, useTheme } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { UPLOAD_FILE_ORDER_GREEN_TAG } from "../../../common/Query/PlanningQuery"
import { DialogCircularProgress } from "../../../common/Resources/DialogCircularProgress"
import { GridColDef } from "@mui/x-data-grid"
import { DataGrid } from "bitsoft-c3"
import { LoadingButton } from "@mui/lab"
import { SAVE_ORDER_GREEN_TAG } from "../../../common/Mutation/MasterPlanMutation"
import swal from "sweetalert"

interface IDataUpload {
  partNo: string
  dueDate: string
  quantity: number
  factory: string
  plant: string
}

const makeColumns = (): GridColDef[] => {
  const column: GridColDef[] = [
    {
      field: "partNo",
      headerName: "PartNo No",
      minWidth: 300,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      minWidth: 200,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "center",
      minWidth: 50,
      align: "center"
    },
    {
      field: "factory",
      headerName: "Factory",
      minWidth: 20,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "plant",
      headerName: "Plant",
      minWidth: 20,
      headerAlign: "center",
      align: "center"
    }
  ]
  return column
}
const UploadOrderGreenTag = (): JSX.Element => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const { data, error, loading } = useQuery(UPLOAD_FILE_ORDER_GREEN_TAG, {
    variables: { file: selectedFile }
  })
  const [uploadFile, { loading: loadingSave }] =
    useMutation(SAVE_ORDER_GREEN_TAG)

  const dataUpload = useMemo(() => {
    if (data) {
      const rawData = data.uploadTagGreenSaleOrder || []
      return rawData.map((item: IDataUpload, index: number) => {
        return {
          ...item,
          id: index
        }
      })
    }
    return []
  }, [data])

  useEffect(() => {
    if (error && selectedFile) {
      swal(`${(error as Error).message}`, "", "error")
    }
  }, [error])

  const dataInsert = useMemo(() => {
    if (data) {
      const rawData = data.uploadTagGreenSaleOrder || []
      return rawData.map((item: IDataUpload) => {
        return {
          partNo: item.partNo,
          dueDate: item.dueDate,
          quantity: item.quantity,
          factory: item.factory,
          plant: item.plant
        }
      })
    }
    return []
  }, [data])

  const handleSubmit = async () => {
    try {
      await uploadFile({
        variables: {
          data: dataInsert
        }
      })
      swal("Save Success!", "", "success")
    } catch (err) {
      swal(`${(err as Error).message}`, "", "error")
    }
  }
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(
      event.currentTarget.files?.["0"] ? event.currentTarget.files?.["0"] : null
    )
  }

  const columns = makeColumns()
  const theme = useTheme()

  return (
    <ThemedCard>
      <PageLabelCard title="Upload Order Green Tag" />
      <Grid container spacing={1} p={2}>
        <Grid item xs={3} sm={3}>
          <UploadFileBox changeHandler={changeHandler} />
        </Grid>
        <Grid item xs={3} sm={3}>
          <SelectedFileBox
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={dataUpload}
            pageSize={10}
            columns={columns}
            dataGridProps={{
              sx: {
                "& .MuiGrid-root .MuiButtonBase-root": {
                  color: theme.palette.primary.dark,

                  "&:hover": {
                    bgcolor: "rgba(0, 0, 0, 0.1) !important"
                  }
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={loadingSave}
            sx={{
              color: theme.palette.text.primary
            }}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
      <DialogCircularProgress loading={loading && !!selectedFile} />
    </ThemedCard>
  )
}
export default UploadOrderGreenTag
