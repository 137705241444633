import { useMutation, useQuery } from "@apollo/client"
import {
  Box,
  Grid,
  MenuItem,
  Autocomplete,
  CardHeader,
  useTheme
} from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import { GET_SUPPLIERS } from "../../common/Query/MasterQuery"
import { ADD_MATERIAL_IN } from "../../common/Mutation/MaterialMutation"
import AddIcon from "@mui/icons-material/Add"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import { ThemeProvider } from "@mui/styles"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"

interface ISupplier {
  supplierName: string
  type: string
}

const pageTitle = "Materials'In"

const MatIn: React.FC = () => {
  const theme = useTheme()
  const [partNo, setPartNo] = useState<string>("")
  const [actionDate, setActionDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [quantity, setQuantity] = useState<string>("")
  const [smQuantity, setSMQuantity] = useState<string>("")
  const [supplierType, setSupplierType] = useState<string>("")
  const [supplier, setSupplier] = useState<string | null>("")
  const [factory, setFactory] = useState<string>("1")
  const [operator, setOperator] = useState<string | null>("")
  const [invoiceNo, setInvoiceNo] = useState<string | null>("")
  const [remarks, setRemarks] = useState<string | null>("")

  let supplierList: Array<ISupplier> = []

  const operators1 = [
    "ณัฏฐ์",
    "วันเฉลิม",
    "สุรศักดิ์",
    "กิตติศักดิ์",
    "ประกฤษฏิ์",
    "วรารัตน์"
  ]
  const operators2 = ["อมราวดี", "อรัญญา", "มนตรี"]

  const getSupplier = useQuery(GET_SUPPLIERS)
  const [addMatIn, { loading }] = useMutation(ADD_MATERIAL_IN)

  supplierList = getSupplier.data ? getSupplier.data.suppliers : []

  const isComplete = () => {
    return (
      partNo !== "" &&
      actionDate !== "" &&
      quantity !== "0" &&
      smQuantity !== "0" &&
      operator !== "" &&
      invoiceNo !== "" &&
      supplier !== "" &&
      factory !== ""
    )
  }
  const onReset = () => {
    setPartNo("")
    setQuantity("")
    setSMQuantity("")
    setSupplier("")
    setSupplierType("")
  }
  const handleSubmit = async () => {
    if (!isComplete()) {
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }

    try {
      const result = await addMatIn({
        variables: {
          matNo: partNo,
          inOrOut: 1,
          actionDate,
          quantity: parseFloat(quantity),
          factory,
          packingBy: operator,
          smQty: parseFloat(smQuantity),
          invoiceNo,
          remarks,
          supplier
        }
      })
      onReset()
      if (result.data) {
        return swal(
          "Success",
          "Added Incoming Material Successfully",
          "success"
        )
      }
      if (!result.data) {
        return swal("Error", "ไม่สามารถส่งข้อมูลได้", "warning")
      }
    } catch (error) {
      return swal("Error", `${(error as Error).message}`, "warning")
    }
  }
  const locations = ["A1", "A2"]
  for (let i = 66; i <= 77; i++) {
    for (let num = 1; num <= 6; num++) {
      locations.push(String.fromCharCode(i) + num)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Materials}
        menuItemName={Object.keys(menuItemList)[1]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <CardHeader
          titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
          title="Mat'l In"
          subheaderTypographyProps={{ fontFamily: "Sarabun" }}
          subheader="Add incoming material to supply"
        />
        <Box component="form" style={{ padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutocompletePartNo setPartNo={setPartNo} partNo={partNo} />
            </Grid>
            <Grid item xs={12}>
              <ThemedTextField
                fullWidth
                value={actionDate}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date Received"
                autoComplete="family-name"
                onChange={(e) => setActionDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <ThemedTextField
                fullWidth
                id="quantity"
                value={quantity}
                label="Quantity"
                variant="outlined"
                autoComplete="family-name"
                onChange={(e) => setQuantity(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <ThemedTextField
                fullWidth
                id="smQuantity"
                label="SM Quantity"
                value={smQuantity}
                autoComplete="family-name"
                onChange={(e) => setSMQuantity(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                id="suppliertype"
                fullWidth
                label="Supplier Type"
                value={supplierType}
                onChange={(e) => setSupplierType(e.target.value)}
                select
              >
                <MenuItem value={"Component Parts"}>Component</MenuItem>
                <MenuItem value={"Material"}>Material</MenuItem>
              </ThemedTextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                disablePortal
                id="supplier"
                options={supplierList
                  .filter(
                    (supplier: { type: string }) =>
                      supplier.type === supplierType
                  )
                  .map(
                    (supplier: { supplierName: string }) =>
                      supplier.supplierName
                  )}
                value={supplier}
                style={{ width: "100%" }}
                sx={{ width: 300 }}
                onChange={(e, newValue) => setSupplier(newValue)}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Supplier" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                id="factory"
                fullWidth
                label="Factory No."
                value={factory}
                onChange={(e) => setFactory(e.target.value)}
                select
              >
                <MenuItem value={"1"}>1</MenuItem>
                <MenuItem value={"2"}>2</MenuItem>
              </ThemedTextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                disablePortal
                id="operator"
                options={factory === "1" ? operators1 : operators2}
                style={{ width: "100%" }}
                sx={{ width: 300 }}
                onChange={(e, newValue) => setOperator(newValue)}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Operator" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ThemedTextField
                id="invoiceno"
                fullWidth
                label="Invoice No."
                value={invoiceNo}
                onChange={(e) => setInvoiceNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="remarks"
                options={locations}
                style={{ width: "100%" }}
                sx={{ width: 300 }}
                freeSolo
                onInputChange={(e, newValue) => setRemarks(newValue)}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Remarks" />
                )}
              />
            </Grid>
          </Grid>
          <ThemedLoadingButton
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmit}
            loading={loading}
            endIcon={<AddIcon />}
          >
            Submit
          </ThemedLoadingButton>
        </Box>
      </ThemedCard>
    </ThemeProvider>
  )
}
export default MatIn
