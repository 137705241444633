import { gql } from "@apollo/client"

export const GET_DATA_SLIP = gql`
  query GetDataSlipSalary($userId: String, $date: String) {
    getDataSlipSalary(userId: $userId, date: $date) {
      userId
      userName
      section
      socialSecurity
      rate
      salary
      day
      tax
      ot10Hr
      ot10
      notPay
      payRollDate
      ot15Hr
      ot15
      healthCheck
      ot20Hr
      ot20
      uniform
      ot30Hr
      ot30
      cremation
      benefit
      toolFee
      positionValue
      deductionOfRefund
      diligenceAllowance
      studentLoanFund
      shiftFee
      providerFund
      cheepCanalFee
      missingWork
      leaveOfAbsence
      netToPay
      totalEarnings
      totalDeduction
      accumulatedIncomePerYear
      taxPerYear
      providerFundPerYear
      socialSecurityPerYear
      otherDeductibles
      accountNumber
    }
  }
`
