import { gql } from "@apollo/client"

export const GET_DATA_IOT = gql`
  query GetIotProduction($planDate: String) {
    getIotProduction(planDate: $planDate) {
      planNo
      revision
      planDate
      pdPlan
      factory
      shift
      partNo
      partName
      customer
      line
      section
      step
      process
      machine
      jph
      startTime
      endTime
      startTimeOT
      endTimeOT
      totalHour
      totalQuantity
      employee
      plApprove
      pdApprove
      engApprove
      qaApprove
      mtApprove
      remarks
      productivity
      rateQ
      performance
      oee
      status
      quantity
      iNG
      sNG
      productionWorker
      actualQuantity
      problem {
        machine
        process
        remarks
        problemName
        totalTime
        startTime
        actionDate
        endTime
      }
    }
  }
`

export const GET_PLAN_WITH_PART = gql`
  query GetPlanWithPartNo($partNo: String, $date: String) {
    getPlanWithPartNo(partNo: $partNo, date: $date) {
      partNo
      machine
      process
      startTime
      endTime
      startTimeOT
      endTimeOT
      quantity
    }
  }
`

export const CREATE_SCANNER_IOT = gql`
  mutation CreateScannerIot($data: IInputIotScan) {
    createScannerIot(data: $data)
  }
`

export const GET_PRODUCTION_IOT = gql`
  query GetProductionReportIot($dateTo: String, $dateFrom: String) {
    getProductionReportIot(dateTo: $dateTo, dateFrom: $dateFrom) {
      machine
      quantity
      jobOrder
      partNo
      process
      planStartTime
      planEndTime
      createdDate
      updatedDate
    }
  }
`
