import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  useTheme
} from "@mui/material"
import { useState } from "react"
import { UPDATE_MAINTENANCE_KPI } from "../../../common/Mutation/MaintenanceMutation"
import { GET_MAINTENANCE_KPI } from "../../../common/Query/MaintenanceQuery"
import { monthNames } from "../PreventiveMaintenancePlan"
import { useMutation } from "@apollo/client"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"

interface IProps {
  data: IPMKpi[]
  target?: string
}
interface IPMKpi {
  target: number
  month: number
  actual: number
}

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun",
    textAlign: "center",
    width: "150px"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },

  "td, th": {
    border: "solid gray 1px"
  }
}))

const PmKpi = (props: IProps): JSX.Element => {
  const { data, target } = props
  const [editTarget, setEditTarget] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const [updateKPI] = useMutation(UPDATE_MAINTENANCE_KPI, {
    refetchQueries: [GET_MAINTENANCE_KPI],
    awaitRefetchQueries: true
  })
  const handleOpenEditTarget = async () => {
    setOpen(true)
  }
  const handleEditTarget = async () => {
    await updateKPI({
      variables: {
        typeName: "PM",
        target: editTarget,
        line: ""
      }
    })
    setOpen(false)
  }
  const theme = useTheme()

  return (
    <>
      <h1 style={{ display: "inline-block" }}>PM.Machine ให้ได้ตามแผน</h1>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{ textAlign: "center" }}>EDIT TARGET</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <ThemedTextField
            label="Target"
            InputLabelProps={{
              style: { marginTop: "5px" },
              shrink: true
            }}
            onChange={(e) => setEditTarget(e.target.value)}
          />
          <Button
            variant="contained"
            sx={{
              mt: 1,
              backgroundColor: theme.palette.primary.main,
              marginLeft: "20px"
            }}
            onClick={handleEditTarget}
          >
            Submit
          </Button>
        </DialogContent>
      </Dialog>
      <ThemedLoadingButton
        sx={{ mt: -2, marginLeft: "10px" }}
        variant="contained"
        style={{ backgroundColor: theme.palette.warning.main }}
        onClick={handleOpenEditTarget}
      >
        EDIT TARGET
      </ThemedLoadingButton>
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>Month</StyledTableCell>
            {monthNames.map((val: string) => {
              return <StyledTableCell key={val}>{val}</StyledTableCell>
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Target (%)
            </StyledTableCell>
            {monthNames.map((val: string) => {
              return (
                <StyledTableCell
                  key={val + target}
                  style={{ backgroundColor: "grey" }}
                >
                  {target}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>Plan PM</StyledTableCell>
            {monthNames.map((val: string, index: number) => {
              return (
                <StyledTableCell key={val}>
                  {data
                    .filter((e: IPMKpi) => e.month === index + 1)
                    .map((e) => e.target || 0)}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>Actual PM</StyledTableCell>
            {monthNames.map((val: string, index: number) => {
              return (
                <StyledTableCell key={val}>
                  {data
                    .filter((e: IPMKpi) => e.month === index + 1)
                    .map((e) => e.actual || 0)}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Act.Targe (%)
            </StyledTableCell>
            {monthNames.map((val: string, index: number) => {
              return (
                <StyledTableCell key={val} style={{ backgroundColor: "grey" }}>
                  {data
                    .filter((e: IPMKpi) => e.month === index + 1)
                    .map((e) => ((e.actual / e.target) * 100).toFixed(2) || 0)}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
        </TableBody>
      </Table>
    </>
  )
}

export default PmKpi
