import { useTheme } from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import moment from "moment"
import { useRef } from "react"
import { monthNames } from "../../PreventiveMaintenancePlan"
import { ITargetKpi } from "../MTTR"

interface Repairer {
  id: number
  checkReceive: number
  completeDate: number
  department: number
  docNo: number
  repairMan: number
  repairType: number
  receiver: number
  detail: number
  requestDate: number
  startRepair: number
  waitRepair: number
  endWaitRepair: number
  referDate: number
  leaderCheck: number
  managerCheck: number
}
const total = (linePress: Repairer[], i: number) => {
  if (linePress === undefined)
    return { dataOfMonth: 0, totalQuantityRepair: 0, arc: 0 }
  const dataOfMonth = linePress.filter(
    (e) => new Date(e.startRepair).getMonth() === i
  )

  if (dataOfMonth.length) {
    const totalQuantityRepair = dataOfMonth
      .map((data) => {
        const diff = Math.abs(
          Number(new Date(data.completeDate)) -
            Number(new Date(data.endWaitRepair))
        )
        const actualTime = diff / 1000 / 60 / 60
        return actualTime
      })
      .reduce((prev: number, cur: number) => {
        return prev + cur
      }, 0)

    const arc = totalQuantityRepair / dataOfMonth.length

    return {
      dataOfMonth: totalQuantityRepair * 60,
      totalQuantityRepair: dataOfMonth.length,
      arc: Math.ceil(arc * 60)
    }
  } else {
    return { dataOfMonth: 0, totalQuantityRepair: 0, arc: 0 }
  }
}
export const MTTRColumnDashboard = (props: {
  chart?: HighchartsReact.Props
  lineData: Repairer[]
  setShowChart?: React.Dispatch<React.SetStateAction<boolean>>
  target: ITargetKpi
}): JSX.Element => {
  const { lineData, target } = props
  const actual = monthNames.map((e: string, i: number) => {
    return total(lineData, i).arc
  })
  const actualMonthly = total(lineData, new Date().getMonth()).arc

  const theme = useTheme()
  const columnOptions: Highcharts.Options = {
    chart: {
      type: "column",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "ระยะเวลาเฉลี่ยในการซ่อมแต่ละครั้ง(MTTR)",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },

    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    },
    xAxis: {
      categories: monthNames,
      labels: {
        x: -10,
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "10px"
        }
      }
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "Total",
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      }
    },
    series: [
      {
        type: "column",
        name: "Target",
        data: monthNames.map(() => Number(target?.target))
      },
      {
        type: "column",
        name: "Actual",
        data: actual
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }
  const pieOptions: Highcharts.Options = {
    chart: {
      type: "pie",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main
    },
    title: {
      text: `MTTR Monthly ${moment(new Date()).format("YYYY-MM")}`,
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
    },
    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    series: [
      {
        name: "Brands",
        type: "pie",
        colorByPoint: true,
        data: [
          {
            name: "Target",
            y: Number(target?.target)
          },
          {
            name: "Actual",
            y: actualMonthly
          }
        ]
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  return (
    <>
      <div style={{ display: "inline-block" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={columnOptions}
          ref={chartComponentRef}
          {...props.chart}
        />
      </div>
      <div style={{ display: "inline-block" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={pieOptions}
          ref={chartComponentRef}
          {...props.chart}
        />
      </div>
    </>
  )
}
