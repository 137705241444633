import React, { ReactElement, useMemo, useState } from "react"
import {
  Grid,
  Autocomplete,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Card,
  useTheme,
  styled,
  ThemeProvider,
  tableCellClasses,
  TableContainer
} from "@mui/material"
import { GET_PARTS } from "../../common/Query/MasterQuery"
import { useLazyQuery, useQuery } from "@apollo/client"
import {
  GET_PD_LOT_NO_FG,
  GET_WH_FG_REPORT
} from "../../common/Query/WarehouseQuery"
import "../../css/Warehouse/WarehouseFGReport.css"
import swal from "sweetalert"
import { partOs } from "./WarehouseFGIn"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { menuItemList } from "../../common/Resources/menuItemList"
import { logoTable } from "../../common/utilities"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"

export interface NewDataReport {
  date: Date
  actionDate: Date
  dateScan: Date
  factory: string
  inOrOut: number
  newInOrOut?: string
  isNG: boolean
  packingBy: string
  partNo: string
  pdLotNo: string
  quantity: number
  remarks: string
  total: number
}
interface DataTable {
  date: string
  in: NewDataReport
  out: NewDataReport
  total: number
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 22,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 22,
    fontFamily: "Sarabun",
    textAlign: "center"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },

  "td, th": {
    border: "1px solid black"
  }
}))

const pageTitle = "FG Report Stock Card"
const WhFGReport: React.FC = (): ReactElement => {
  const [partNo, setPartNo] = useState<string>("")
  const [pdLotNo, setPdLotNo] = useState<string | null>("")
  const [factory, setFactory] = useState<string | null>("1")
  const [rowsPrintTable, setRowsPrintTable] = useState<DataTable[]>([])
  const [pdLotNoList, setPdLotNoList] = useState<string[]>([])
  const [error, setError] = useState<boolean>(false)
  const [dateFrom, setDateFrom] = useState<string>("")
  const [dateTo, setDateTo] = useState<string>("")
  const getParts = useQuery(GET_PARTS)
  const [getFGReport, { loading }] = useLazyQuery(GET_WH_FG_REPORT)
  const [getPdLotNo] = useLazyQuery(GET_PD_LOT_NO_FG)

  let partName = ""
  let customer = ""

  const Root = styled("div")(
    () => `
      table {
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        border-collapse: collapse;
        width: 100%;
      }
  
      td,
      th {
        border: 1px solid black;
        padding: 10px;
      }
  
  
      `
  )

  useMemo(async () => {
    const pdLot = await getPdLotNo({
      variables: {
        partNo,
        factory
      }
    })
    setPdLotNo("")
    if (pdLot?.data?.getPdLotNoFG) {
      setPdLotNoList(pdLot?.data?.getPdLotNoFG || [])
    }
  }, [partNo, factory])

  const handleSubmit = async () => {
    if (partNo === "") {
      setError(true)
      return swal("Error", "ใส่ข้อมูล PartNo", "error")
    }
    if (partNo && factory) {
      const dataFGReport = await getFGReport({
        variables: { partNo, factory, pdLotNo, dateFrom, dateTo },
        fetchPolicy: "network-only"
      })

      if (dataFGReport?.data?.whFGReport) {
        setRowsPrintTable(dataFGReport?.data?.whFGReport)
      }
    }
  }

  const checkCustomer = () => {
    if (partNo) {
      const partNoFilter = getParts.data.parts.find((e: { partNo: string }) => {
        return e.partNo === partNo
      })
      partName = partNoFilter?.partName
      customer = partNoFilter?.customer
      if (customer === "MAHLE" || partOs.includes(partNo)) {
        if (factory === "2") setFactory("1")
        return true
      }

      return false
    }
  }

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Warehouse}
        menuItemName={Object.keys(menuItemList)[6]}
        menuPageName={pageTitle}
      />
      <ThemedCard style={{ marginTop: "26px" }} className="hide-print">
        <PageLabelCard
          title="View Part History"
          subTitle="detail about View Part History"
        />
        <Grid container spacing={2} width={"98%"} margin={2}>
          <Grid item xs={12} sm={4}>
            <AutocompletePartNo
              setPartNo={setPartNo}
              partNo={partNo}
              error={!partNo && error}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              disabled={checkCustomer()}
              id="factory"
              value={factory}
              options={["1", "2"]}
              onChange={(e, newValue) => setFactory(newValue)}
              renderInput={(params) => (
                <ThemedTextField {...params} label="Factory" />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <ThemedTextField
              fullWidth
              value={dateFrom}
              label="Date From"
              id="dateFrom"
              type="date"
              autoComplete="family-name"
              onChange={(e) => setDateFrom(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={2}>
            <ThemedTextField
              fullWidth
              value={dateTo}
              id="dateTo"
              type="date"
              label="Date To"
              autoComplete="family-name"
              onChange={(e) => setDateTo(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              id="status"
              value={pdLotNo}
              options={pdLotNoList}
              onChange={(e, newValue) => setPdLotNo(newValue)}
              renderInput={(params) => (
                <ThemedTextField {...params} label="PD Lot No" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} className="hide-print">
            <ThemedLoadingButton
              variant="contained"
              sx={{ mt: 3, mb: 2, marginRight: "15px" }}
              onClick={handleSubmit}
              loading={loading}
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              Submit
            </ThemedLoadingButton>

            <ThemedButton
              variant="outlined"
              style={{
                color: theme.palette.secondary.contrastText,
                borderColor: theme.palette.primary.main
              }}
              sx={{ mt: 3, mb: 2 }}
              onClick={() => window.print()}
            >
              Print
            </ThemedButton>
          </Grid>
        </Grid>
      </ThemedCard>
      <Card style={{ marginTop: "26px" }}>
        <PageLabelCard
          title="View Part History"
          subTitle="detail about View Part History"
        />
        <Grid
          container
          spacing={2}
          width={"98%"}
          margin={2}
          justifyContent={"center"}
        >
          <TableContainer>
            <Root className="print-wh-fg-report">
              <Table>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell colSpan={3} rowSpan={2}>
                      <img
                        src={logoTable}
                        alt=""
                        style={{ width: "400px", backgroundColor: "white" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell colSpan={5} rowSpan={2}>
                      <label className="label_auto_center_font">
                        บัญชีรายการสินค้าและวัตถุดิบ STOCK CARD
                      </label>
                    </StyledTableCell>
                    <StyledTableCell colSpan={2}>
                      <label className="label_auto_center_font">
                        F-PL-03-03
                      </label>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell colSpan={2} style={{ textAlign: "left" }}>
                      <label className="label_auto_center_font">
                        Effective Date: 14/01/08 <br></br>
                        R02 Issue: 1
                      </label>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell colSpan={5}>
                      <div>
                        <b>ชื่อลูกค้า:{customer ? customer : ""}</b>
                      </div>
                      <div>
                        <b>รหัสสินค้า ( Part No.): {partNo}</b>
                      </div>
                      <div>
                        <b>
                          ชื่อสินค้า (Part Name): {partName ? partName : ""}
                        </b>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell colSpan={5}>
                      <div>
                        <b>Supplier:</b>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell rowSpan={2} colSpan={1}>
                      วัน/เดือน/ปี
                    </StyledTableCell>
                    <StyledTableCell colSpan={3}>
                      บันทึกรับเข้า (RECEIVING RECORD)
                    </StyledTableCell>
                    <StyledTableCell colSpan={4}>
                      บันทึกจ่ายออก (DISTRIBUTE RECORD)
                    </StyledTableCell>
                    <StyledTableCell rowSpan={2} colSpan={1}>
                      ยอดคงเหลือ
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell colSpan={1}>หมายเหตุ</StyledTableCell>
                    <StyledTableCell colSpan={1}>Lot No.</StyledTableCell>
                    <StyledTableCell colSpan={1}>
                      รับเข้า (pcs.)
                    </StyledTableCell>
                    <StyledTableCell colSpan={2}>หมายเหตุ</StyledTableCell>
                    <StyledTableCell colSpan={1}>Lot No.</StyledTableCell>
                    <StyledTableCell colSpan={1}>
                      จ่ายออก (pcs.)
                    </StyledTableCell>
                  </StyledTableRow>
                  {rowsPrintTable.length > 0 ? (
                    Array.from(Array(rowsPrintTable.length), (e, i) => {
                      return (
                        <StyledTableRow key={i}>
                          <StyledTableCell colSpan={1}>
                            {rowsPrintTable[i].date
                              ? rowsPrintTable[i].date
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell colSpan={1}>
                            {rowsPrintTable[i].in
                              ? rowsPrintTable[i].in.remarks
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell colSpan={1}>
                            {" "}
                            {rowsPrintTable[i].in
                              ? rowsPrintTable[i].in.pdLotNo
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell colSpan={1}>
                            {" "}
                            {rowsPrintTable[i].in
                              ? rowsPrintTable[i].in.quantity
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell colSpan={2}>
                            {" "}
                            {rowsPrintTable[i].out
                              ? rowsPrintTable[i].out.remarks
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            style={{
                              textAlign: "center",
                              whiteSpace: "nowrap"
                            }}
                          >
                            {rowsPrintTable[i].out
                              ? rowsPrintTable[i].out.pdLotNo
                                  .split("Date")[0]
                                  .trim()
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell colSpan={1}>
                            {rowsPrintTable[i].out
                              ? rowsPrintTable[i].out.quantity
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell colSpan={1}>
                            {rowsPrintTable[i].total}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={1}></StyledTableCell>
                      <StyledTableCell colSpan={1}></StyledTableCell>
                      <StyledTableCell colSpan={1}></StyledTableCell>
                      <StyledTableCell colSpan={1}></StyledTableCell>
                      <StyledTableCell colSpan={2}></StyledTableCell>
                      <StyledTableCell colSpan={1}></StyledTableCell>
                      <StyledTableCell colSpan={1}></StyledTableCell>
                      <StyledTableCell colSpan={1}></StyledTableCell>
                    </StyledTableRow>
                  )}
                  <StyledTableRow>
                    <StyledTableCell rowSpan={3} colSpan={4}>
                      <b>Remarks:</b>
                    </StyledTableCell>
                    <StyledTableCell rowSpan={1} colSpan={2}>
                      <b>ผู้จัดทำ</b>
                    </StyledTableCell>
                    <StyledTableCell rowSpan={1} colSpan={2}>
                      <b>ผู้ตรวจสอบ</b>
                    </StyledTableCell>
                    <StyledTableCell rowSpan={1} colSpan={2}>
                      <b>ผู้อนุมัติ</b>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell
                      sx={{ height: "80px" }}
                      rowSpan={1}
                      colSpan={2}
                    ></StyledTableCell>
                    <StyledTableCell
                      sx={{ height: "80px" }}
                      rowSpan={1}
                      colSpan={2}
                    ></StyledTableCell>
                    <StyledTableCell
                      sx={{ height: "80px" }}
                      rowSpan={1}
                      colSpan={2}
                    ></StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell rowSpan={1} colSpan={2}>
                      <b>พนักงานแผนกสโตร์</b>
                    </StyledTableCell>
                    <StyledTableCell rowSpan={1} colSpan={2}>
                      <b>หัวหน้าแผนกสโตร์</b>
                    </StyledTableCell>
                    <StyledTableCell rowSpan={1} colSpan={2}>
                      <b>ผู้จัดการฝ่ายวางแผน</b>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Root>
          </TableContainer>
        </Grid>
      </Card>
    </ThemeProvider>
  )
}
export default WhFGReport
