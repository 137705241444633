import { ReactElement } from "react"
import { useMutation, useQuery } from "@apollo/client"
import {
  Box,
  Grid,
  Select,
  MenuItem,
  Autocomplete,
  FormControl,
  InputLabel,
  useTheme,
  ThemeProvider
} from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import { GET_PARTS } from "../../common/Query/MasterQuery"
import swal from "sweetalert"
import { CREATE_FG } from "../../common/Mutation/WarehouseMutation"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"

const pageTitle = "FG In"
let customer = ""
export const partOs: string[] = []
const WarehouseFGIn: React.FC = (): ReactElement => {
  const [partNo, setPartNo] = useState<string>("")
  const [actionDate, setActionDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [quantity, setQuantity] = useState<number>()
  const [factory, setFactory] = useState<string>("1")
  const [operator, setOperator] = useState<string | null>("")
  const [pdLotNo, setPdLotNo] = useState("")
  const [remarks, setRemarks] = useState("")
  const [error, setError] = useState<boolean>(false)
  const { data } = useQuery(GET_PARTS)
  const [createFg, { loading }] = useMutation(CREATE_FG)

  const isComplete = () => {
    return (
      partNo !== "" &&
      actionDate !== "" &&
      quantity !== 0 &&
      operator !== "" &&
      pdLotNo !== ""
    )
  }
  const isReset = () => {
    setPartNo("")
    setQuantity(0)
    setFactory("1")
    setPdLotNo("")
    setRemarks("")
  }

  const checkCustomer = () => {
    if (partNo) {
      customer = data.parts.filter(
        (e: { partNo: string }) => e.partNo === partNo
      )[0].customer
      if (customer === "MAHLE" || partOs.includes(partNo)) return true
      return false
    }
  }

  const handleSubmit = async () => {
    setError(true)
    if (!isComplete()) return swal("ERROR!", "กรุณากรอกข้อมูลให้ครบ", "error")
    try {
      await createFg({
        variables: {
          data: {
            partNo,
            actionDate,
            quantity,
            factory: checkCustomer() ? "1" : factory,
            packingBy: operator,
            pdLotNo,
            inOrOut: 1,
            remarks
          }
        }
      })
      swal("Success", "Create FG In Success", "success")
      isReset()
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "warning")
    }
  }
  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <Box style={{ marginLeft: "22px" }}>
        <PageLabel
          menuItem={menuItemList.Warehouse}
          menuItemName={Object.keys(menuItemList)[6]}
          menuPageName={pageTitle}
        />
        <ThemedCard style={{ marginTop: "26px" }}>
          <PageLabelCard title="FG In" subTitle="detail about FG In" />
          <Grid container spacing={2} width={"98%"} margin={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <AutocompletePartNo
                  setPartNo={setPartNo}
                  partNo={partNo}
                  error={!partNo && error}
                />
              </Grid>
              <Grid item xs={4}>
                <ThemedTextField
                  fullWidth
                  value={actionDate}
                  id="actiondate"
                  type="date"
                  autoComplete="family-name"
                  onChange={(e) => setActionDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <ThemedTextField
                  fullWidth
                  id="quantity"
                  value={quantity ? quantity : ""}
                  onChange={(e) => setQuantity(+e.target.value)}
                  autoComplete="family-name"
                  label="Quantity"
                  type="number"
                  error={!quantity && error}
                  onWheel={(event) =>
                    event.currentTarget.querySelector("input")?.blur()
                  }
                />
              </Grid>
              {checkCustomer() ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <label>Part No (OS)</label>
                    <label style={{ padding: "7px" }}>
                      Customer: {customer}
                    </label>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Factory</InputLabel>
                      <Select
                        value={factory}
                        label="factory"
                        onChange={(e) => setFactory(e.target.value)}
                        error={!factory && error}
                      >
                        <MenuItem value={"1"}>1</MenuItem>
                        <MenuItem value={"2"}>2</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={4}>
                <ThemedTextField
                  fullWidth
                  value={pdLotNo}
                  label="PdLotNo"
                  error={!pdLotNo && error}
                  onChange={(e) => setPdLotNo(e.target.value)}
                ></ThemedTextField>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  disablePortal
                  value={operator}
                  id="operator"
                  options={
                    factory === "1"
                      ? ["ณัฏฐ์", "วันเฉลิม", "วรารัตน์", "สุธิดา"]
                      : checkCustomer()
                      ? ["ณัฏฐ์", "วันเฉลิม", "วรารัตน์", "สุธิดา"]
                      : ["รุ่งฤดีม", "ศรัณยา", "สุธิดา"]
                  }
                  fullWidth
                  onChange={(e, newValue) => setOperator(newValue)}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      label="Select Operator"
                      error={!operator && error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <ThemedTextField
                  id="remarks"
                  fullWidth
                  label="Remarks"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                ></ThemedTextField>
              </Grid>
              <Grid item xs={12}>
                <ThemedLoadingButton
                  variant="contained"
                  onClick={handleSubmit}
                  loading={loading}
                  style={{
                    backgroundColor: theme.palette.primary.main
                  }}
                >
                  Submit
                </ThemedLoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </ThemedCard>
      </Box>
    </ThemeProvider>
  )
}

export default WarehouseFGIn
