import {
  Autocomplete,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  useTheme,
  styled,
  tableCellClasses,
  Typography,
  Box,
  CircularProgress
} from "@mui/material"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import Paper from "@mui/material/Paper"
import { useState } from "react"
import moment from "moment"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import { useLazyQuery } from "@apollo/client"
import { GET_PLATING_REPORT } from "../../common/Query/PlatingQuery"
import { miniLogo } from "../../common/utilities"
import "../../css/Plate/PlatingReport.css"
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined"
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined"
import PrintIcon from "@mui/icons-material/Print"

interface IThickness {
  firstPieceThickness: number
  secondPieceThickness: number
  thirdPieceThickness: number
}

interface IReworkPlatingData {
  detail: string
  quantity: number
}
interface reworkPlatingDataReform {
  [key: string]: number
  nonStickPlatingRework: number
  woodJigFragment: number
  solutionSpill: number
  solutionStain: number
  needlePitting: number
  oilPitting: number
  greasyBulgingColor: number
  roughSurface: number
  nonStickBlackSpotPlating: number
  colorStain: number
  peelSwellWorkPiece: number
  burnWorkPiece: number
  greasyStainSolution: number
  whiteStain: number
}

interface IPlatingReport {
  customer: string
  partNo: string
  partName: string
  model: string
  spec: string
  wt: number
  adm: number
  round: number
  actionDate: string
  quantity: number
  kg: number
  useRound: number
  pdLotNo: string
  thicknessData: IThickness[]
  reworkPlatingData: IReworkPlatingData[]
  reworkPlatingDataReform: reworkPlatingDataReform
}

interface NgTypeInfo {
  ngTypeInfoHeader: string[]
  ngTypeInfoCodeList: string[]
  ngTypeInfoHeaderLineThai: string[]
}

interface NgType {
  [key: string]: NgTypeInfo
}

const ngTypeInfoList: NgType = {
  EDP: {
    ngTypeInfoHeader: [
      "ชุบไม่ติด",
      "สะเก็ดไม้จิ๊ก",
      "เม็ดน้ำยา",
      "คราบขาว/น้ำยา",
      "หลุมเข็ม",
      "หลุมน้ำมัน",
      "สีเยิ้ม/สีปูด",
      "ผิวไม่เรียบ/ขรุขระ"
    ],
    ngTypeInfoCodeList: [
      "F-DBC-01-02-03",
      "Effective Date: 1/8/2017 R04 Issue1"
    ],
    ngTypeInfoHeaderLineThai: ["ไลน์อีดีพี"]
  },
  Hanger: {
    ngTypeInfoHeader: [
      "ชุบไม่ติด/งานดำ",
      "คราบสี/งานเหลือง",
      "งานลอกพอง",
      "งานไหม้",
      "เยิ้ม/คราบน้ำยา",
      "คราบขาว",
      "",
      ""
    ],
    ngTypeInfoCodeList: [
      "F-DBC-01-02-02",
      "Effective Date: 1/8/2017 R04 Issue1"
    ],
    ngTypeInfoHeaderLineThai: ["ไลน์ซิงค์แขวน"]
  },
  Barrel: {
    ngTypeInfoHeader: [
      "ชุบไม่ติด/งานดำ",
      "คราบสี/งานเหลือง",
      "งานลอกพอง",
      "งานไหม้",
      "เยิ้ม/คราบน้ำยา",
      "คราบขาว",
      "",
      ""
    ],
    ngTypeInfoCodeList: [
      "F-DBC-01-02-01",
      "Effective Date: 1/8/2017 R04 Issue1"
    ],
    ngTypeInfoHeaderLineThai: ["ไลน์ซิงค์กลิ้ง"]
  }
}

const pageTitle = "Monthly Plating Report"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "white",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: theme.palette.secondary.contrastText,
    fontWeight: "bold",
    fontSize: "18px",
    fontFamily: "Sarabun",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Sarabun",
    textAlign: "center",
    fontSize: "16px",
    padding: "20px 5px 20px 5px"
  }
}))

const StyledTableCellThin = styled(TableCell)(({ theme }) => ({
  backgroundColor: "white",
  color: theme.palette.secondary.contrastText,
  fontFamily: "Sarabun",
  textAlign: "center",
  padding: "0px 5px 0px 5px",
  lineHeight: "2.5rem",
  whiteSpace: "nowrap",
  fontSize: "17px"
}))

const StyledTableCellVertical = styled(TableCell)(({ theme }) => ({
  backgroundColor: "white",
  color: theme.palette.secondary.contrastText,
  fontFamily: "Sarabun",
  verticalAlign: "bottom",
  fontSize: "17px"
}))

const StyledTableRow = styled(TableRow)(() => ({
  "td, th": {
    border: "1px solid black"
  }
}))

const HeaderTableTypography = styled((props) => (
  <Typography {...props} component="span" />
))(() => ({
  borderBottom: "2px solid black",
  padding: "0px 30px",
  fontWeight: 600,
  fontSize: "20px"
}))

const MonthlyPlatingReport: React.FC = () => {
  const theme = useTheme()
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [line, setLine] = useState<string>("EDP")
  const [shift, setShift] = useState<string>("Day")
  const [platingReport3Data, setPlatingReport3Data] = useState<
    IPlatingReport[]
  >([])
  const [getPlatingReport2, { loading: loadingPlatingReport }] = useLazyQuery(
    GET_PLATING_REPORT,
    {
      onCompleted: (data) => {
        if (data && data.platingReport) {
          const transformedReports: IPlatingReport[] = data.platingReport.map(
            (report: IPlatingReport) => {
              const reworkData: Record<string, number> =
                report.reworkPlatingData.reduce(
                  (
                    acc: Record<string, number>,
                    item: { detail: string; quantity: number }
                  ) => {
                    acc[item.detail] = item.quantity
                    return acc
                  },
                  {}
                )

              return {
                ...report,
                reworkPlatingDataReform: reworkData
              }
            }
          )
          setPlatingReport3Data(transformedReports)
        }
      }
    }
  )

  const handleClick = async () => {
    await getPlatingReport2({
      variables: {
        dateFrom: dateFrom,
        dateTo: dateTo,
        line: line,
        shift: shift
      },
      fetchPolicy: "network-only"
    })
  }

  const propertiesToSum = [
    "nonStickPlatingRework",
    "woodJigFragment",
    "solutionSpill",
    "solutionStain",
    "needlePitting",
    "oilPitting",
    "greasyBulgingColor",
    "roughSurface",
    "nonStickBlackSpotPlating",
    "colorStain",
    "peelSwellWorkPiece",
    "burnWorkPiece",
    "greasyStainSolution",
    "whiteStain"
  ]

  const sumForFooterData: { [key: string]: number } = {}
  platingReport3Data.forEach((item) => {
    const { reworkPlatingDataReform } = item
    propertiesToSum.forEach((property) => {
      if (reworkPlatingDataReform && reworkPlatingDataReform[property]) {
        sumForFooterData[property] =
          (sumForFooterData[property] || 0) + reworkPlatingDataReform[property]
      }
    })
  })
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Plate}
        menuItemName={Object.keys(menuItemList)[14]}
        menuPageName={pageTitle}
      ></PageLabel>
      <ThemedCard sx={{ p: "20px 20px 20px 0px" }}>
        <PageLabelCard
          title="Monthly Plating Report"
          subTitle="About detail line plate"
        />
        <Box className="none-display">
          <Grid container spacing={2} margin={2} width={"98%"}>
            <Grid item sm={2} xs={2}>
              <ThemedTextField
                onChange={(e) => setDateFrom(e.target.value)}
                value={dateFrom}
                label="dateFrom"
                type="date"
                fullWidth
              />
            </Grid>
            <Grid item sm={2} xs={2}>
              <ThemedTextField
                onChange={(e) => setDateTo(e.target.value)}
                value={dateTo}
                label="dateTo"
                type="date"
                fullWidth
              />
            </Grid>
            <Grid item sm={2} xs={2}>
              <Autocomplete
                value={line}
                fullWidth
                onChange={(event, value) => {
                  setLine(value as string)
                }}
                options={["EDP", "Hanger", "Barrel"]}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Line"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={2} xs={2}>
              <Autocomplete
                value={shift}
                fullWidth
                onChange={(event, value) => {
                  setShift(value as string)
                }}
                options={["Day", "Night"]}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Shift"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={2} xs={2}>
              <ThemedButton
                variant="contained"
                sx={{ mt: 1, width: "70%" }}
                onClick={handleClick}
              >
                SUBMIT
              </ThemedButton>
            </Grid>
            <Grid item sm={2} xs={2}>
              <ThemedButton
                variant="contained"
                sx={{ mt: 1, width: "70%" }}
                endIcon={<PrintIcon />}
                onClick={() => window.print()}
              >
                Print
              </ThemedButton>
            </Grid>
          </Grid>
        </Box>

        <TableContainer component={Paper} sx={{ p: "5px" }}>
          <Table aria-label="customized table" className="plating-absolute">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell colSpan={2} sx={{ p: "0px" }}>
                  <Box sx={{ display: "flex" }}>
                    <img src={miniLogo} alt="BAE logo" width={"150px"} />
                  </Box>
                </StyledTableCell>
                <StyledTableCell colSpan={17} sx={{ p: "0px" }}>
                  <Box sx={{ mb: "10px", fontSize: "20px" }}>
                    ใบบันทึกการชุบและการตรวจสอบชิ้นงานชุบสำเร็จรูป
                    <HeaderTableTypography>
                      {ngTypeInfoList[line]?.ngTypeInfoHeaderLineThai[0] || ""}
                    </HeaderTableTypography>
                    ประจำวันที่
                    <HeaderTableTypography>{dateFrom}</HeaderTableTypography>
                    <Typography
                      variant="inherit"
                      component="span"
                      sx={{ p: "0px" }}
                    >
                      ถึงวันที่
                    </Typography>
                    <HeaderTableTypography>{dateTo}</HeaderTableTypography>
                  </Box>

                  <Box sx={{ fontSize: "18px", p: "0px" }}>
                    <Typography variant="inherit">
                      (Plating Record and Final Inspection: {line} Line)
                      {shift === "Day" ? (
                        <CheckBoxOutlinedIcon
                          sx={{
                            verticalAlign: "middle",
                            fontSize: 30,
                            pl: "80px"
                          }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankOutlinedIcon
                          sx={{
                            verticalAlign: "middle",
                            fontSize: 30,
                            pl: "80px"
                          }}
                        />
                      )}
                      <span style={{ paddingRight: "80px" }}>กะกลางวัน</span>
                      {shift === "Night" ? (
                        <CheckBoxOutlinedIcon
                          sx={{ verticalAlign: "middle", fontSize: 30 }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankOutlinedIcon
                          sx={{ verticalAlign: "middle", fontSize: 30 }}
                        />
                      )}
                      กะกลางคืน
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell colSpan={5} sx={{ p: "0px" }}>
                  <Box sx={{ fontSize: "17px", mb: "10px" }}>
                    {ngTypeInfoList[line]?.ngTypeInfoCodeList[0]}
                  </Box>
                  <Box sx={{ fontSize: "15px", fontWeight: "500" }}>
                    {ngTypeInfoList[line]?.ngTypeInfoCodeList[1]}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell
                  colSpan={8}
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <Box>รายการชุบชิ้นงาน</Box>
                </StyledTableCell>
                <StyledTableCell
                  colSpan={8}
                  style={{ backgroundColor: "#f5f5f5" }}
                >
                  <Box>การแก้ไข Rework</Box>
                </StyledTableCell>
                <StyledTableCell
                  colSpan={8}
                  style={{ backgroundColor: "#dedede" }}
                >
                  <Box>การตรวจสอบชิ้นงานหลังชุบ</Box>
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCellThin rowSpan={2}>
                  <Box>ลำดับที่</Box>
                  <Box>No.</Box>
                </StyledTableCellThin>
                <StyledTableCellThin rowSpan={2} sx={{ minWidth: "80px" }}>
                  <Box>วันที่ชุบ</Box>
                  <Box>Plating Date</Box>
                </StyledTableCellThin>
                <StyledTableCellThin rowSpan={2}>
                  <Box>ลูกค้า</Box>
                  <Box>Customer</Box>
                </StyledTableCellThin>
                <StyledTableCellThin rowSpan={2}>
                  <Box>หมายเลข/ชื่อชิ้นงาน</Box>
                  <Box>Part No./ Part Name</Box>
                </StyledTableCellThin>
                <StyledTableCellThin rowSpan={2}>
                  <Box>ล็อตที่ผลิต</Box>
                  <Box>PD Lot No.</Box>
                </StyledTableCellThin>
                <StyledTableCellThin rowSpan={2}>
                  <Box>มาตรฐานชิ้นต่อ{line === "Barrel" ? "โม่" : "รอบ"}</Box>
                  <Box>pcs./{line === "Barrel" ? "Barrel" : "round"}</Box>
                </StyledTableCellThin>
                <StyledTableCellThin rowSpan={2}>
                  <Box>จำนวนชิ้นงานที่ชุบได้</Box>
                  <Box>Q'ty (pcs.)</Box>
                </StyledTableCellThin>
                <StyledTableCellThin rowSpan={2}>
                  <Box>
                    {line === "Barrel" ? "จำนวนโม่ที่ใช้" : "จำนวนรอบที่ใช้"}
                  </Box>
                  <Box>{line === "Barrel" ? "Barrel" : "Round"}</Box>
                </StyledTableCellThin>

                <StyledTableCellVertical rowSpan={2}>
                  <Box className="vertical-text-platingReport">
                    {ngTypeInfoList[line]?.ngTypeInfoHeader[0]}
                  </Box>
                </StyledTableCellVertical>
                <StyledTableCellVertical rowSpan={2}>
                  <Box className="vertical-text-platingReport">
                    {ngTypeInfoList[line]?.ngTypeInfoHeader[1]}
                  </Box>
                </StyledTableCellVertical>
                <StyledTableCellVertical rowSpan={2}>
                  <Box className="vertical-text-platingReport">
                    {ngTypeInfoList[line]?.ngTypeInfoHeader[2]}
                  </Box>
                </StyledTableCellVertical>
                <StyledTableCellVertical rowSpan={2}>
                  <Box className="vertical-text-platingReport">
                    {ngTypeInfoList[line]?.ngTypeInfoHeader[3]}
                  </Box>
                </StyledTableCellVertical>
                <StyledTableCellVertical rowSpan={2}>
                  <Box className="vertical-text-platingReport">
                    {ngTypeInfoList[line]?.ngTypeInfoHeader[4]}
                  </Box>
                </StyledTableCellVertical>
                <StyledTableCellVertical rowSpan={2}>
                  <Box className="vertical-text-platingReport">
                    {ngTypeInfoList[line]?.ngTypeInfoHeader[5]}
                  </Box>
                </StyledTableCellVertical>
                <StyledTableCellVertical rowSpan={2}>
                  <Box className="vertical-text-platingReport">
                    {ngTypeInfoList[line]?.ngTypeInfoHeader[6]}
                  </Box>
                </StyledTableCellVertical>
                <StyledTableCellVertical rowSpan={2}>
                  <Box className="vertical-text-platingReport">
                    {ngTypeInfoList[line]?.ngTypeInfoHeader[7]}
                  </Box>
                </StyledTableCellVertical>

                <StyledTableCellThin rowSpan={2} sx={{ minWidth: "150px" }}>
                  <Box>มาตรฐานชุบ</Box>
                  <Box>Spec.</Box>
                </StyledTableCellThin>
                <StyledTableCellThin colSpan={3}>
                  <Box>ความหนา(ไมครอน)</Box>
                  <Box>Thickness (μm)</Box>
                </StyledTableCellThin>

                <StyledTableCellVertical rowSpan={2}>
                  <Box className="vertical-text-platingReport">
                    ชุบติดทั่วทั้งตัว
                  </Box>
                </StyledTableCellVertical>
                <StyledTableCellVertical rowSpan={2}>
                  <Box className="vertical-text-platingReport">
                    ไม่บิดงอเสียรูป
                  </Box>
                </StyledTableCellVertical>
                <StyledTableCellVertical rowSpan={2}>
                  <Box className="vertical-text-platingReport">
                    ไม่มีรอยขีดข่วน
                  </Box>
                </StyledTableCellVertical>
                <StyledTableCellVertical
                  rowSpan={2}
                  sx={{ fontWeight: "bold", fontSize: "17px" }}
                >
                  <Box className="vertical-text-platingReport">การตัดสิน</Box>
                </StyledTableCellVertical>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCellThin>
                  <Box>ชิ้นที่ 1</Box>
                </StyledTableCellThin>
                <StyledTableCellThin>
                  <Box>ชิ้นที่ 2</Box>
                </StyledTableCellThin>
                <StyledTableCellThin>
                  <Box>ชิ้นที่ 3</Box>
                </StyledTableCellThin>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {platingReport3Data && loadingPlatingReport ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={24}>
                    <CircularProgress size={"100px"} />
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                platingReport3Data.map(
                  (item: IPlatingReport, index: number) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>{item.actionDate}</StyledTableCell>
                        <StyledTableCell>{item.customer}</StyledTableCell>
                        <StyledTableCell>
                          {item.partNo} / {item.partName}
                        </StyledTableCell>
                        <StyledTableCell>{item.pdLotNo}</StyledTableCell>
                        <StyledTableCell>
                          {item.round.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.quantity.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.useRound.toFixed(2)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.reworkPlatingDataReform?.nonStickPlatingRework
                            ? item.reworkPlatingDataReform
                                ?.nonStickPlatingRework
                            : item.reworkPlatingDataReform
                                ?.nonStickBlackSpotPlating}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.reworkPlatingDataReform?.woodJigFragment
                            ? item.reworkPlatingDataReform?.woodJigFragment
                            : item.reworkPlatingDataReform?.colorStain}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.reworkPlatingDataReform?.solutionSpill
                            ? item.reworkPlatingDataReform?.solutionSpill
                            : item.reworkPlatingDataReform?.peelSwellWorkPiece}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.reworkPlatingDataReform?.solutionStain
                            ? item.reworkPlatingDataReform?.solutionStain
                            : item.reworkPlatingDataReform?.burnWorkPiece}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.reworkPlatingDataReform?.needlePitting
                            ? item.reworkPlatingDataReform?.needlePitting
                            : item.reworkPlatingDataReform?.greasyStainSolution}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.reworkPlatingDataReform?.oilPitting
                            ? item.reworkPlatingDataReform?.oilPitting
                            : item.reworkPlatingDataReform?.whiteStain}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.reworkPlatingDataReform?.greasyBulgingColor}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.reworkPlatingDataReform?.roughSurface}
                        </StyledTableCell>
                        <StyledTableCell>{item.spec}</StyledTableCell>
                        <StyledTableCell>
                          {item.thicknessData[0]?.firstPieceThickness}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.thicknessData[0]?.secondPieceThickness}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.thicknessData[0]?.thirdPieceThickness}
                        </StyledTableCell>
                        <StyledTableCell>✓</StyledTableCell>
                        <StyledTableCell>✓</StyledTableCell>
                        <StyledTableCell>✓</StyledTableCell>
                        <StyledTableCell>OK</StyledTableCell>
                      </StyledTableRow>
                    )
                  }
                )
              )}
              <StyledTableRow>
                <StyledTableCell
                  colSpan={6}
                  sx={{
                    fontWeight: 600,
                    bgcolor: "#dedede"
                  }}
                >
                  รวมจำนวนทั้งหมด
                </StyledTableCell>
                <StyledTableCell>
                  {platingReport3Data
                    .reduce((total, item) => total + item.quantity, 0)
                    .toLocaleString()}
                </StyledTableCell>
                <StyledTableCell>
                  {platingReport3Data
                    .reduce((total, item) => total + item.useRound, 0)
                    .toFixed(2)
                    .toLocaleString()}
                </StyledTableCell>
                <StyledTableCell>
                  {line === "EDP"
                    ? sumForFooterData.nonStickPlatingRework
                    : sumForFooterData.nonStickBlackSpotPlating}
                </StyledTableCell>
                <StyledTableCell>
                  {line === "EDP"
                    ? sumForFooterData.woodJigFragment
                    : sumForFooterData.colorStain}
                </StyledTableCell>
                <StyledTableCell>
                  {line === "EDP"
                    ? sumForFooterData.solutionSpill
                    : sumForFooterData.peelSwellWorkPiece}
                </StyledTableCell>
                <StyledTableCell>
                  {line === "EDP"
                    ? sumForFooterData.solutionStain
                    : sumForFooterData.burnWorkPiece}
                </StyledTableCell>
                <StyledTableCell>
                  {line === "EDP"
                    ? sumForFooterData.needlePitting
                    : sumForFooterData.greasyStainSolution}
                </StyledTableCell>
                <StyledTableCell>
                  {line === "EDP"
                    ? sumForFooterData.oilPitting
                    : sumForFooterData.whiteStain}
                </StyledTableCell>
                <StyledTableCell>
                  {sumForFooterData.greasyBulgingColor}
                </StyledTableCell>
                <StyledTableCell>
                  {sumForFooterData.roughSurface}
                </StyledTableCell>
                <StyledTableCell colSpan={8}>
                  <span style={{ fontWeight: "600" }}>หมายเหตุ</span>: 1) ✓ =
                  ผ่าน, 𐄂 = ไม่ผ่าน 2) การตัดสิน: OK = ยอมรับ, NG = ไม่ยอมรับ
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ThemedCard>
    </ThemeProvider>
  )
}
export default MonthlyPlatingReport
