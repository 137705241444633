import React, { ChangeEvent, useEffect, useState } from "react"

import {
  UploadPackagingStandard,
  CreatePackagingStandard
} from "../../common/Mutation/PackagingStandardMutation"
import {
  DOWNLOAD_PACKAGING_STANDARD,
  GetPackagingStandard
} from "../../common/Query/PackagingStandardQuery"
import { useLazyQuery, useQuery } from "@apollo/client"
import { useMutation } from "@apollo/client"
import swal from "sweetalert"

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Box,
  Grid,
  useTheme,
  Switch,
  Autocomplete,
  TablePagination,
  ThemeProvider
} from "@mui/material"
import TableCell from "@mui/material/TableCell"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { FileUpload, FileDownload, LayersSharp } from "@mui/icons-material"
import moment from "moment"
import UploadFileBox, {
  SelectedFileBox
} from "../../common/Resources/UploadFileBox"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { IPart } from "../Master/Parts"
import { GET_PARTS } from "../../common/Query/MasterQuery"

export interface IPackagingStandard {
  partNo: string
  revision: string
  remarks: string
  date: string
  record: string
  fileName: string
  customer?: string
}

export function onlyUnique(
  value: string,
  index: number,
  self: string[]
): boolean {
  return self.indexOf(value) === index
}

const pageTitle = "Packaging Standard"

const UploadPackagingSTD: React.FC = () => {
  const theme = useTheme()
  const [error, setError] = useState<boolean>(false)
  const [partNo, setPartNo] = useState<string>("")
  const [revision, setRevision] = useState<string>("")
  const [remark, setRemark] = useState<string>("")
  const [date, setDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [operator, setOperator] = useState<string>("")

  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const [_createPackagingStandard, { loading: createLoading }] = useMutation(
    CreatePackagingStandard,
    {
      refetchQueries: [GetPackagingStandard],
      awaitRefetchQueries: true
    }
  )
  const [uploadPackagingStandard, { loading }] = useMutation(
    UploadPackagingStandard
  )

  const toggleUpload = async () => {
    if (!partNo || !revision || !remark || !operator) {
      setError(true)
      return swal("ERROR", "กรอกข้อมูลให้ครบ", "error")
    }
    try {
      // mutation file upload
      const { data: dataUrl } = await uploadPackagingStandard({
        variables: {
          fileName: selectedFile?.name,
          contentType: selectedFile?.type
        }
      })
      const presignedUrl = dataUrl.uploadPackagingStandard

      const response = await fetch(presignedUrl, {
        method: "PUT",
        body: selectedFile,
        headers: {
          "Content-Type": selectedFile?.type ?? ""
        }
      })

      // Clear
      setPartNo("")
      setRevision("")
      setRemark("")
      setDate("")
      setOperator("")
      if (response.status === 200) {
        const data = {
          partNo,
          revision,
          remarks: remark,
          date,
          record: operator,
          fileName: selectedFile?.name
        }

        await _createPackagingStandard({
          variables: data
        })

        swal("Success", "Upload Package Standard Successful ", "success")
      }
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "warning")
    }
  }
  const onChangeRemark = (event: ChangeEvent<HTMLInputElement>) => {
    setRemark(event.target.value)
  }
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(
      event.currentTarget.files?.["0"] ? event.currentTarget.files?.["0"] : null
    )
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <AutocompletePartNo
          partNo={partNo}
          setPartNo={setPartNo}
          error={!partNo && error}
        ></AutocompletePartNo>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ThemedTextField
          label="revision"
          fullWidth
          onChange={(event) => setRevision(event.target.value)}
          value={revision}
          error={!revision && error}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ThemedTextField
          type="date"
          label="Date"
          InputLabelProps={{
            shrink: true
          }}
          value={date}
          error={!date && error}
          fullWidth
          onChange={(event) => setDate(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ThemedTextField
          label="Operator"
          fullWidth
          onChange={(event) => setOperator(event.target.value)}
          value={operator}
          error={!operator && error}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ThemedTextField
          label="Remarks"
          fullWidth
          onChange={onChangeRemark}
          value={remark}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <UploadFileBox
          changeHandler={changeHandler}
          error={error}
          selectedFile={selectedFile}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectedFileBox
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          createLoading={createLoading}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ThemedLoadingButton
          loading={loading}
          variant="contained"
          sx={{ mt: 1 }}
          style={{
            backgroundColor: theme.palette.primary.main,
            color: "white"
          }}
          onClick={toggleUpload}
          endIcon={<FileUpload />}
        >
          Upload
        </ThemedLoadingButton>
      </Grid>
    </Grid>
  )
}

const DownloadPackagingStandard: React.FC<{
  packagingStandard: IPackagingStandard[]
  isStream: boolean
  customer?: string
  partNo?: string
}> = (props) => {
  const { packagingStandard, isStream, customer, partNo } = props
  const [customerSelect, setCustomerSelect] = useState<string>(customer || "")
  const [partNoSelect, setPartNoSelect] = useState<string>(partNo || "")
  const [revisionSelect, setRevisionSelect] = useState<string | null>("")
  const { loading: loadingParts, data: dataParts } = useQuery(GET_PARTS)
  const [downloadPackagingStandard, { loading }] = useLazyQuery(
    DOWNLOAD_PACKAGING_STANDARD
  )

  const filterPartsAccordingToTextField = dataParts?.parts.filter((part: IPart) => {
    const matchesPart = partNoSelect ? part.partNo === partNoSelect : true
    const matchesCustomer = customerSelect ? part.customer === customerSelect : true
    return matchesPart && matchesCustomer
  })

  const partListSearch: string[] = Array.from(
    new Set(
      filterPartsAccordingToTextField?.map((e: IPart) => {
        return e.partNo ? e.partNo : ""
      })
    )
  )

  const customerList: string[] = Array.from(
    new Set(
      filterPartsAccordingToTextField?.map((e: IPart) => {
        return e.customer ? e.customer : ""
      })
    )
  )

  const toggleDownload = async () => {
    try {
      const result = await downloadPackagingStandard({
        variables: {
          record: customerSelect,
          partNo: partNoSelect,
          revision: revisionSelect
        }
      })
      swal("Success", "Download Package Standard Successful", "success")
      window.open(result.data.downloadPackagingStandard.url)
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "error")
    }
  }
  const theme = useTheme()
  return (
    <Grid
      container
      spacing={2}
      marginLeft="44px"
      style={isStream ? {} : { display: "none" }}
    >
      <Grid item xs={12} sm={6}>

        <Autocomplete
          value={customerSelect}
          fullWidth
          onChange={(event, selectedCustomer) => {
            setCustomerSelect(selectedCustomer as string)
          }}
          options={customerList}
          loading={loading}
          renderInput={(params) => (
            <ThemedTextField
              {...params}
              label="Customer"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />


      </Grid>

      <Grid item xs={12} sm={8} />
      <Grid item xs={12} sm={6}>
        <Autocomplete
          value={partNoSelect}
          fullWidth
          onChange={(event, selectedPartNo) => {
            setPartNoSelect(selectedPartNo as string)
          }}
          options={partListSearch}
          loading={loadingParts}
          renderInput={(params) => (
            <ThemedTextField
              {...params}
              label="Part NO."
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

      </Grid>
      <Grid item xs={12} sm={8} />
      <Grid item xs={12} sm={6}>
        <Autocomplete
          fullWidth
          value={revisionSelect}
          options={packagingStandard
            .filter(
              (packagingStandard: IPackagingStandard) =>
                packagingStandard.partNo === partNoSelect
            )
            .map((e: IPackagingStandard) => e.revision)}
          disablePortal
          onChange={(e, value) => setRevisionSelect(value)}
          renderInput={(params) => (
            <ThemedTextField {...params} label="Revision" />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ThemedLoadingButton
          loading={loading}
          variant="outlined"
          sx={{ mt: 1 }}
          style={{
            border: `1px solid ${theme.palette.primary.main}`
          }}
          onClick={toggleDownload}
          endIcon={<FileDownload />}
        >
          Download
        </ThemedLoadingButton>
      </Grid>
    </Grid>
  )
}
interface Column {
  id: "partNo" | "remarks" | "date" | "record" | "revision" | "customer"
  label: string
  minWidth?: number
  align?: "left"
  format?: (value: number) => string
}
const columns: Column[] = [
  { id: "partNo", label: "PartNo", minWidth: 20 },
  { id: "customer", label: "Customer", minWidth: 20 },
  { id: "revision", label: "Revision", minWidth: 20 },
  { id: "remarks", label: "Remarks", minWidth: 20 },
  { id: "date", label: "Date", minWidth: 20 },
  { id: "record", label: "Record", minWidth: 20 }
]
const SearchRevisionTable = (
  tableResult: IPackagingStandard[]
): JSX.Element => {
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  useEffect(() => { setPage(0) }, [tableResult])
  return (
    <TableContainer component={Paper} sx={{ mt: "20px" }}>
      <Table sx={{ minWidth: 700 }} aria-label="SearchRevision">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableResult
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((packagingstandard: IPackagingStandard, idx) => {
              return (
                <TableRow key={idx + page * rowsPerPage}>
                  <TableCell component="th" scope="row">
                    {packagingstandard.partNo}
                  </TableCell>
                  <TableCell>{packagingstandard.customer}</TableCell>
                  <TableCell>{packagingstandard.revision}</TableCell>
                  <TableCell>{packagingstandard.remarks}</TableCell>
                  <TableCell>{packagingstandard.date}</TableCell>
                  <TableCell>{packagingstandard.record}</TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableResult.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

const SearchRevision: React.FC<{
  packagingStandard: IPackagingStandard[]
}> = (props: { packagingStandard: IPackagingStandard[] }) => {
  const [partNoSelect, setPartNoSelect] = useState<string>("")
  const [customerSelect, setCustomerSelect] = useState<string>("")
  const { packagingStandard } = props
  const [rowsData, setRowsData] = useState<IPackagingStandard[]>([])

  const partListSearch: string[] = Array.from(
    new Set(
      packagingStandard.filter((e: IPackagingStandard) => {
        return customerSelect ? e.customer === customerSelect : true
      }).map((e: IPackagingStandard) => { return e.partNo ? e.partNo : "" })
    )
  )

  const customerList: string[] = Array.from(
    new Set(
      packagingStandard.map((e: IPackagingStandard) => {
        return e.customer ? e.customer : ""
      })
    )
  )

  const toggleShowHistory = () => {
    let tempData = packagingStandard
    if (customerSelect) {
      tempData = tempData.filter((e) => e.customer === customerSelect)
    }
    if (partNoSelect) {
      tempData = tempData.filter((e) => e.partNo === partNoSelect)
    }
    setRowsData(tempData)
  }

  useEffect(() => {
    if (rowsData.length > 0) {
      let tempData = packagingStandard
      if (customerSelect) {
        tempData = tempData.filter((e) => e.customer === customerSelect)
      }
      if (partNoSelect) {
        tempData = tempData.filter((e) => e.partNo === partNoSelect)
      }
      setRowsData(tempData)

    }
  }, [customerSelect, partNoSelect])


  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            id="search"
            freeSolo
            value={partNoSelect}
            options={partListSearch}
            style={{ width: "100%", marginLeft: "-15px" }}
            sx={{ width: 100 }}
            onChange={(e, newValue) => {

              setPartNoSelect(newValue ? newValue : "")
            }}
            renderInput={(params) => (
              <ThemedTextField
                style={{ marginLeft: "15px" }}
                {...params}
                label="Part No."
              />
            )}
          />

        </Grid>

        <Grid item xs={12} sm={3}>
          <Autocomplete
            id="customer"
            freeSolo
            value={customerSelect}
            options={customerList}
            style={{ width: "100%", marginLeft: "-15px" }}
            sx={{ width: 100 }}
            onChange={(e, newValue) => {

              setCustomerSelect(newValue ? newValue : "")
            }}
            renderInput={(params) => (
              <ThemedTextField
                style={{ marginLeft: "15px" }}
                {...params}
                label="Customer"
              />
            )}
          />


        </Grid>
        <Grid item xs={12} sm={2}>
          <ThemedLoadingButton
            variant="contained"
            sx={{ mt: 1 }}
            style={{
              backgroundColor: theme.palette.primary.main,
              color: "white"
            }}
            onClick={toggleShowHistory}
          >
            show history
          </ThemedLoadingButton>
        </Grid>
        {SearchRevisionTable(rowsData)}
      </Grid>
    </ThemeProvider>
  )
}

const Packaging_Standard: React.FC = () => {
  const [dataInfo, setDataInfo] = useState<IPackagingStandard[]>([])
  const { data: dataParts } = useQuery(GET_PARTS)
  const { error } = useQuery(GetPackagingStandard, {
    onCompleted: (data) => {
      if (data && data.packagingStandard.length && dataParts.parts.length) {
        const updatedPackingData = data.packagingStandard.map((packingData: IPackagingStandard) => {
          const getCustomer = dataParts.parts.find((parts: IPart) => { return parts.partNo === packingData.partNo })
          return {
            ...packingData,
            customer: getCustomer ? getCustomer.customer : ""
          }
        })
        setDataInfo(updatedPackingData)
      } else {
        swal("ERROR", "Fail to load PARTS or PACKINGSTANDARD", "error")
      }
    },
    fetchPolicy: "network-only"
  });
  const [isStream, setIsStream] = useState<boolean>(false)

  if (error) swal("error", error.toString(), "")

  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <Box style={{ marginLeft: "22px" }} component="form">
        <PageLabel
          menuItem={menuItemList.Warehouse}
          menuItemName={Object.keys(menuItemList)[6]}
          menuPageName={pageTitle}
        />
        <ThemedCard style={{ marginTop: "26px" }}>
          <PageLabelCard
            title="Upload Packaging Standard"
            subTitle="detail about Upload Packaging Standard"
          />
          <Grid container spacing={2} width={"98%"} margin={2}>
            <UploadPackagingSTD />
            <Grid item xs={12} sm={12}>
              <Switch
                checked={isStream}
                onChange={(event) =>
                  setIsStream(event.target.value ? !isStream : isStream)
                }
                name="gilad"
              />
              <label style={{ fontSize: "16px" }}>
                Download Packaging Standard (WH)
                <LayersSharp style={{ verticalAlign: "middle" }} />
              </label>
            </Grid>
            <DownloadPackagingStandard
              packagingStandard={dataInfo}
              isStream={isStream}
            />
          </Grid>
          <PageLabelCard
            title="Search Revision"
            subTitle="detail about Search Revision"
          />
          <Grid
            container
            spacing={2}
            width={"100%"}
            marginTop={2}
            marginLeft={2}
            marginRight={2}
            marginBottom={0}
          >
            <SearchRevision packagingStandard={dataInfo} />
          </Grid>
        </ThemedCard>
      </Box>
    </ThemeProvider>
  )
}

export default Packaging_Standard
export {
  UploadPackagingSTD as UploadPackagingStandard,
  DownloadPackagingStandard,
  SearchRevision
}
