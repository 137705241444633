import { Breadcrumbs, useTheme, Link, Grid, Typography } from "@mui/material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import "../../css/Dashboard/Dashboard.css"
import { IMenuItem } from "../Navbar"

interface INewPageLabelProps {
  menuItem: IMenuItem[]
  menuItemName: string
  menuPageName: string
}

export const PageLabel: React.FC<INewPageLabelProps> = (
  props: INewPageLabelProps
) => {
  const { menuItem, menuItemName, menuPageName } = props

  const currentPage = menuItem?.find(
    (menuItemPage: IMenuItem) => menuItemPage.key === menuPageName
  )

  const theme = useTheme()
  return (
    <div className="display-none-print">
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="flex-end"
        justifyContent="flex-start"
        style={{
          paddingTop: "15px",
          backgroundColor: theme.palette.background.default
        }}
      >
        <Grid item>
          <svg width="5" height="24">
            <rect
              width="5"
              height="24"
              style={{ fill: theme.palette.text.secondary }}
            />
          </svg>
        </Grid>
        <Grid item>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
            style={{ color: theme.palette.secondary.contrastText }}
          >
            <Link
              underline="hover"
              href="#/Dashboard"
              color="inherit"
              key="1"
              style={{ color: theme.palette.secondary.contrastText }}
            >
              Dashboard
            </Link>
            {menuItemName && (
              <Link
                underline="hover"
                href="#/Dashboard"
                color="inherit"
                key="2"
                style={{ color: theme.palette.secondary.contrastText }}
              >
                {menuItemName}
              </Link>
            )}
            <Typography
              color="text.primary"
              key="3"
              style={{ color: theme.palette.secondary.contrastText }}
            >
              {currentPage?.key}
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
    </div>
  )
}
