import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { GET_EMPLOYEE } from "../../Query/MasterQuery"

export const useEmployee = (): string[] => {
  const [employee, setEmployee] = useState<string[]>([""])
  const { data } = useQuery(GET_EMPLOYEE)
  useEffect(() => {
    if (data) {
      const searchList: string[] = Array.from(
        new Set(
          data.employee.map(
            (employee: { firstName: string; lastName: string }) =>
              employee.firstName + " " + employee.lastName
          )
        )
      )
      setEmployee(searchList)
    }
  }, [data])
  return employee
}
