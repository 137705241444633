import React, { useRef, useState } from "react"
import { ThemeProvider } from "@mui/styles"
import { Divider, Grid, Typography, useTheme } from "@mui/material"
import ThemedTextField from "../../../../../common/Resources/ThemedComponents/ThemedTextField"
import moment from "moment"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"
import { useQuery } from "@apollo/client"
import { GET_MAN_MACHINE_PM } from "../../../../../common/Query/engineerQuery"
import AutocompleteFactory from "../../../../../common/Resources/AutocompleteFactory"
import ThemedCard from "../../../../../common/Resources/ThemedComponents/ThemedCard"

interface IMan {
  manHour: number
  machineHour: number
  machine: string
  partNo: string
}

interface IDictionaryProblemLine {
  [id: string]: IMan
}

export const ChartBreakDownCost = (props: {
  chart?: HighchartsReact.Props
  dateTo: string
  dateFrom: string
  factory: string
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const { data } = useQuery(GET_MAN_MACHINE_PM, {
    variables: {
      dateTo: props.dateTo,
      dateFrom: props.dateFrom,
      factory: props.factory
    }
  })

  const rawData =
    data?.getBreakDownPMHistory?.map((val: IMan) => {
      return {
        manHour: Number(val.manHour) * 130,
        machineHour: Number(val.machineHour) * 850,
        machine: val.machine,
        partNo: val.partNo
      }
    }) ?? []

  const result: IMan[] = Object.values(
    rawData.reduce((line: IDictionaryProblemLine, item: IMan) => {
      line[item.partNo] = line[item.partNo]
        ? {
            ...item,
            manHour: item.manHour + line[item.partNo].manHour,
            machineHour: item.machineHour + line[item.partNo].machineHour
          }
        : item
      return line
    }, {})
  )

  const sortData: IMan[] = [...result].sort((a, b) =>
    a.manHour + a.machineHour > b.manHour + b.machineHour
      ? -1
      : a.manHour + a.machineHour < b.manHour + b.machineHour
      ? 1
      : 0
  )

  const totalPaymentMan = sortData
    ?.map((val: IMan) => Number(val.manHour.toFixed(2)))
    .slice(0, 6)

  const totalPaymentMachine = sortData
    ?.map((no: IMan) => Number(no.machineHour.toFixed(2)))
    .slice(0, 6)

  const partList = sortData?.map((no: IMan) => no.partNo).slice(0, 6)

  const theme = useTheme()

  const options: Highcharts.Options = {
    chart: {
      type: "column",
      backgroundColor: theme.palette.secondary.main,
      height: 410
    },
    credits: { enabled: false },
    title: {
      text: "",
      align: "center",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    xAxis: {
      categories: partList,
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "12px"
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: "Baht",
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          color: theme.palette.secondary.contrastText
        }
      },
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          textOutline: "none",
          fontSize: "12px",
          color: theme.palette.secondary.contrastText
        }
      }
    },
    legend: {
      align: "right",
      x: 10,
      verticalAlign: "top",
      y: 40,
      floating: true,
      backgroundColor: theme.palette.secondary.main,
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
      itemStyle: {
        fontSize: "10px",
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true
        }
      }
    },
    series: [
      {
        name: "Machine Cost",
        type: "column",
        color: "#FFC03C",
        data: totalPaymentMachine || []
      },
      {
        name: "Man Cost",
        type: "column",
        color: "#466EDC",
        data: totalPaymentMan || []
      }
    ],
    exporting: {
      enabled: false
    }
  }
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props.chart}
      />
    </>
  )
}

const GraphDefectParato: React.FC = () => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-01")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [factory, setFactory] = useState<string>("2")
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <ThemedCard sx={{ background: theme.palette.secondary.main }}>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", padding: "10px" }}
            >
              Breakdown Cost
            </Typography>
          </Grid>
          <Grid item container sm={9} spacing={2} padding={1}>
            <Grid item sm={4}>
              <ThemedTextField
                fullWidth
                value={dateFrom}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date From"
                autoComplete="family-name"
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Grid>
            <Grid item sm={4}>
              <ThemedTextField
                fullWidth
                value={dateTo}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date To"
                autoComplete="family-name"
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Grid>
            <Grid item sm={4}>
              <AutocompleteFactory setFactory={setFactory} factory={factory} />
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={12} justifyContent="center">
            <ChartBreakDownCost
              dateTo={dateTo}
              dateFrom={dateFrom}
              factory={factory}
            />
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default GraphDefectParato
