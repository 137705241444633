import { gql } from "@apollo/client"

export const DOWNLOAD_PACKAGING_STANDARD = gql`
  query Query($partNo: String!, $revision: String!, $record: String!) {
    downloadPackagingStandard(
      partNo: $partNo
      revision: $revision
      record: $record
    ) {
      url
    }
  }
`

export const GetPackagingStandard = gql`
  query Query {
    packagingStandard {
      partNo
      revision
      fileName
      remarks
      record
      date
    }
  }
`
