import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material"
import { miniLogo } from "../../../common/utilities"
import styled from "@emotion/styled"
import { LocalizationProvider, TimePicker } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import { useEffect, useMemo, useState } from "react"
import { useLine } from "../../../common/Resources/customHook/useLine"
import { useEmployee } from "../../../common/Resources/customHook/useEmployee"
import ThemedRadio from "../../../common/Resources/ThemedComponents/ThemedRadio"
import { useMutation, useQuery } from "@apollo/client"
import { GET_PARTS } from "../../../common/Query/MasterQuery"
import { GET_PART_PROCESSING } from "../../../common/Query/MaterialQuery"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import moment from "moment"
import {
  CREATE_PM_FROM,
  DELETE_PM_FROM,
  UPDATE_PM_FROM
} from "../../../common/Mutation/engineerMutation"
import swal from "sweetalert"
import { IRepairInformant } from "../PMDocument"
import { GET_INFORMANT_PM } from "../../../common/Query/engineerQuery"
import { Delete, Add } from "@mui/icons-material"
import { useMachine } from "../../../common/Resources/customHook/useMachine"
import DialogToolingBroken from "./DialogToolingBroken"

type MyComponentProps = {
  needBorderBottom?: boolean
  needBorderRight?: boolean
  justifyContent?: string
  alignItems?: string
}
interface IProcessing {
  jph: number
  line: string
  machine: string
  minStroke: number
  partNo: string
  process: string
  processOrder: string
  stepProcess: number
}
interface DataItem {
  partNo: string
  partName: string
  customer: string
  process: IProcessing[]
}
interface IProp {
  informantPMFrom: IRepairInformant | undefined
  record: number
}

const wsUrl =
  "wss://dht6wg9m3k.execute-api.ap-southeast-1.amazonaws.com/production/"
const ws = new WebSocket(wsUrl)

const shouldForwardProp = <CustomProps extends Record<string, unknown>>(
  props: Array<keyof CustomProps>,
  prop: PropertyKey
): boolean => !props.includes(prop as string)

export const PMDocumentGrid = styled(Grid, {
  shouldForwardProp: (props) =>
    shouldForwardProp<MyComponentProps>(
      ["needBorderBottom", "needBorderRight", "justifyContent", "alignItems"],
      props
    )
})<MyComponentProps>(
  ({
    needBorderRight,
    needBorderBottom,
    justifyContent = "center",
    alignItems = "center"
  }) => ({
    borderBottom: needBorderBottom ? "2px solid black" : "none",
    justifyContent: justifyContent,
    alignItems: alignItems,
    color: "black",
    borderRight: needBorderRight ? "2px solid black" : "none",
    display: "flex"
  })
)

const RepairInformant: React.FC<IProp> = (props: IProp) => {
  const { record, informantPMFrom } = props
  const { data: partQuery } = useQuery(GET_PARTS)
  const { data: partProcessingQuery } = useQuery(GET_PART_PROCESSING)
  const [deleteFrom] = useMutation(DELETE_PM_FROM, {
    refetchQueries: [GET_INFORMANT_PM],
    awaitRefetchQueries: true
  })
  const [updateFrom] = useMutation(UPDATE_PM_FROM, {
    refetchQueries: [GET_INFORMANT_PM],
    awaitRefetchQueries: true
  })
  const [repairRequestDate, setRequestDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [repairRequestTime, setRepairRequestTime] = useState<number>(
    new Date().getTime()
  )
  const [line, setLine] = useState<string>("")
  const [factoryFrom, setFactory] = useState<string>("")
  const [worker, setWorker] = useState<string>("")
  const [leaderProduction, setLeaderProduction] = useState<string>("")
  const [machine, setMachine] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [process, setProcess] = useState<string>("")
  const [objective, setObjective] = useState<string>("")
  const [type, setType] = useState<string>("")
  const [typeOther, setTypeOther] = useState<string>("")
  const [production, setProduction] = useState<number>(0)
  const [repairDueDate, setRepairDueDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [problem, setProblem] = useState<string>("")
  const [otherProblem, setOtherProblem] = useState<string>("")
  const [repairDueDateTime, setRepairDueDateTime] = useState<number>(3600000)
  const [open, setOpen] = useState<boolean>(false)
  const [brokenType, setBrokenType] = useState<string>("")

  const [createPMEngineerFrom] = useMutation(CREATE_PM_FROM, {
    refetchQueries: [GET_INFORMANT_PM],
    awaitRefetchQueries: true
  })
  const [socket, setSocket] = useState<WebSocket | null>(null)

  useEffect(() => {
    ws.onopen
    setSocket(ws)
  }, [])

  useEffect(() => {
    if (problem !== "6.แม่พิมพ์ชำรุด") {
      setBrokenType("")
    }
  }, [problem])

  const sendMessage = (msg: string) => {
    try {
      if (socket && socket.readyState === WebSocket.OPEN) {
        const messagePayload = {
          action: "sendMessage",
          message: msg
        }

        socket.send(JSON.stringify(messagePayload))
      }
    } catch (e) {
      swal("Error", `${e}`, "warning")
    }
  }
  const lineList = useLine()
  const employeeList = useEmployee()
  const machineList = useMachine()
  useEffect(() => {
    if (informantPMFrom) {
      setRequestDate(informantPMFrom.repairRequestDate)
      setRepairDueDate(informantPMFrom.repairDueDate)
      setRepairRequestTime(
        new Date("2023-01-01 " + informantPMFrom.repairRequestTime).getTime()
      )
      setLine(informantPMFrom.line)
      setFactory(informantPMFrom.factory)
      setPartNo(informantPMFrom.partNo)
      setProcess(informantPMFrom.process)
      setWorker(informantPMFrom.worker)
      setLeaderProduction(informantPMFrom.leaderProduction)
      setMachine(informantPMFrom.machine)
      setObjective(informantPMFrom.objective)
      setType(informantPMFrom.type)
      setTypeOther(informantPMFrom.type)
      setProduction(informantPMFrom.production)
      setRepairDueDate(informantPMFrom.repairDueDate)
      const otherProblem = informantPMFrom.problem.indexOf("7.อื่นๆระบุปัญหา")

      setProblem(
        informantPMFrom.problem.includes("7.อื่นๆระบุปัญหา")
          ? "7.อื่นๆระบุปัญหา"
          : informantPMFrom.problem
      )
      setOtherProblem(
        informantPMFrom.problem.substring(
          otherProblem + "7.อื่นๆระบุปัญหา".length
        )
      )
      setRepairDueDateTime(
        new Date("2023-01-01 " + informantPMFrom.repairDueDateTime).getTime()
      )
    }
  }, [informantPMFrom])
  useEffect(() => {
    if (!informantPMFrom) {
      setRepairDueDate("")
      setLine("")
      setFactory("")
      setPartNo("")
      setProcess("")
      setWorker("")
      setLeaderProduction("")
      setMachine("")
      setObjective("")
      setType("")
      setTypeOther("")
      setProduction(0)
      setRepairDueDate("")
      setProblem("")
      setRepairDueDateTime(0)
    }
  }, [informantPMFrom])

  const leaderApproved = [
    "อนุชา นุสและ",
    "จรัญ สร้อยประดิษฐ์",
    "ศุภชัย เอี่ยมฉาย",
    "สรุศักดิ์ แจ่มอารีย์",
    "วีรชัย เลาะเซ็น",
    "เกรียงไกร สนบุญ",
    "ธนายุทธ ฝาอิดิน",
    "สาหร่าย ประเสริฐทรัพย์",
    "ไชยศ จิตรประภา",
    "นริศรา คงทรัพย์"
  ]

  const parts = useMemo(() => {
    const transformedData: { [x: string]: DataItem } = {}
    if (partQuery && partProcessingQuery) {
      partQuery.parts.forEach((part: DataItem) => {
        const { partNo, partName, customer } = part
        const partProcess = partProcessingQuery.partsprocessings.filter(
          (partProcessing: { partNo: string }) =>
            partProcessing.partNo === partNo
        )
        transformedData[part.partNo] = {
          partNo,
          partName,
          customer,
          process: partProcess
        }
      })
      return transformedData
    }
    return {}
  }, [partQuery, partProcessingQuery])

  const isComplete = () => {
    if (
      !repairRequestDate ||
      !repairRequestTime ||
      !line ||
      !factoryFrom ||
      !worker ||
      !machine ||
      !partNo ||
      !process ||
      !objective ||
      !type ||
      !repairDueDate ||
      !problem
    ) {
      return false
    }
    return true
  }
  const handleRepairInformant = async () => {
    if (!isComplete()) {
      swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "error")
      return
    }
    const inputCreatePmFrom = {
      repairRequestDate: repairRequestDate,
      repairRequestTime: moment(repairRequestTime).format("HH:mm"),
      line: line,
      factory: factoryFrom,
      documentNo: Number(record) ?? 0,
      partNo: partNo,
      partName: parts[partNo]?.partName,
      process: process,
      customer: parts[partNo]?.customer,
      worker: worker,
      leaderProduction: leaderProduction,
      machine: machine,
      objective: objective,
      type: type === "Other" ? typeOther : type,
      production: production,
      repairDueDate: repairDueDate,
      problem:
        problem === "7.อื่นๆระบุปัญหา"
          ? "7.อื่นๆระบุปัญหา" + otherProblem
          : problem === "6.แม่พิมพ์ชำรุด"
          ? problem + ":" + brokenType
          : problem,
      repairDueDateTime: moment(repairDueDateTime).format("HH:mm")
    }
    try {
      const { data: formData } = await createPMEngineerFrom({
        variables: {
          inputCreatePmFrom
        }
      })
      const sendData = {
        message: formData.createPMFrom.message,
        วันที่: formData.createPMFrom.actionDate,
        เลขที่เอกสาร: formData.createPMFrom.id,
        factory: formData.createPMFrom.link,
        userRead: formData.createPMFrom.userRead,
        status: false
      }
      sendMessage(JSON.stringify(sendData))

      swal("Success", "Create PM From Success", "success")
    } catch (error) {
      swal("Error", (error as Error).message, "error")
    }
  }
  const handleUpdateRepairInformant = async () => {
    const inputCreatePmFrom = {
      repairRequestDate: repairRequestDate,
      repairRequestTime: moment(repairRequestTime).format("HH:mm"),
      line: line,
      factory: factoryFrom,
      documentNo: Number(record) ?? 0,
      partNo: partNo,
      partName: parts[partNo]?.partName,
      process: process,
      customer: parts[partNo]?.customer,
      worker: worker,
      leaderProduction: leaderProduction,
      machine: machine,
      objective: objective,
      type: type === "Other" ? typeOther : type,
      production: production,
      repairDueDate: repairDueDate,
      problem:
        problem === "7.อื่นๆระบุปัญหา"
          ? "7.อื่นๆระบุปัญหา" + otherProblem
          : problem === "6.แม่พิมพ์ชำรุด"
          ? problem + ":" + brokenType
          : problem,
      repairDueDateTime: moment(repairDueDateTime).format("HH:mm")
    }
    try {
      await updateFrom({
        variables: {
          inputCreatePmFrom
        }
      })
      swal("Success", "Update PM From Success", "success")
    } catch (error) {
      swal("Error", (error as Error).message, "error")
    }
  }
  const handleDeleteRepairInformant = async () => {
    try {
      await deleteFrom({
        variables: {
          documentNo: Number(record),
          factory: factoryFrom,
          repairRequestDate: repairRequestDate
        }
      })
      swal("Success", "Delete PM From Success", "success")
    } catch (error) {
      swal("Error", (error as Error).message, "error")
    }
  }

  return (
    <Box>
      <Grid
        container
        item
        border={"2px solid black"}
        bgcolor={"white"}
        sx={{
          "@media print": {
            zoom: "60%"
          }
        }}
      >
        <Grid item container xs={12} borderBottom="2px solid black">
          <PMDocumentGrid item xs={2} needBorderRight container>
            <img src={miniLogo} alt="" style={{ width: "70%" }} />
          </PMDocumentGrid>
          <PMDocumentGrid item xs={7} needBorderRight container>
            <h1>ใบแจ้งซ่อม Tooling</h1>
          </PMDocumentGrid>
          <PMDocumentGrid item xs={3} container>
            <h4>Doc.No. F-ENG-02-07</h4>
            <br />
            <h4>Effective date 10/02/23 Rev.07 Issue:1</h4>
          </PMDocumentGrid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          borderBottom="2px solid black"
          pb={1}
          pt={1}
        >
          <Grid item container xs={12}>
            <PMDocumentGrid item xs={2}>
              <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
                1.ผู้แจ้งซ่อม (หัวหน้าฝ่ายผลิต)
              </Typography>
            </PMDocumentGrid>

            <PMDocumentGrid container item xs={4} columnSpacing={2}>
              <Grid item xs={"auto"}>
                <Typography>วันที่</Typography>
              </Grid>
              <Grid item xs={"auto"}>
                <TextField
                  type="date"
                  variant="standard"
                  disabled
                  onChange={(e) => setRequestDate(e.target.value)}
                  value={repairRequestDate}
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <Typography>เวลา</Typography>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    ampm={false}
                    disabled
                    disableOpenPicker
                    value={repairRequestTime}
                    onChange={(value) => {
                      setRepairRequestTime(value ? value : 0)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        sx={{
                          input: {
                            color: "black",
                            textAlign: "center"
                          },

                          svg: {
                            color: "black"
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </PMDocumentGrid>

            <PMDocumentGrid
              item
              xs={6}
              container
              columnSpacing={2}
              alignItems={"center"}
            >
              <Grid item xs={1}>
                <Typography>Line</Typography>
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  fullWidth
                  value={line}
                  options={lineList}
                  disablePortal
                  disableClearable
                  popupIcon={null}
                  onChange={(e, value) => setLine(value as string)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        input: {
                          color: "black"
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#666666"
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <Typography>Factory</Typography>
              </Grid>
              <Grid item xs={1}>
                <Autocomplete
                  value={factoryFrom}
                  options={["1", "2"]}
                  disablePortal
                  disableClearable
                  popupIcon={null}
                  onChange={(e, value) => setFactory(value as string)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        input: {
                          color: "black",
                          textAlign: "center"
                        },
                        option: {
                          whiteSpace: "normal"
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#666666"
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <Typography>เลขที่</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>
                  {`${moment(repairRequestDate).format("YY")}/${moment(
                    repairRequestDate
                  ).format("MM")}/${(record || 0).toString().padStart(3, "0")}`}
                </Typography>
              </Grid>
            </PMDocumentGrid>
          </Grid>

          <Grid item container xs={12} pt={1}>
            <PMDocumentGrid
              item
              xs={2}
              display={"flex"}
              justifyItems={"center"}
            >
              <Typography>PART NUMBER</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2}>
              <Autocomplete
                fullWidth
                value={partNo}
                options={Object.keys(parts)}
                disablePortal
                disableClearable
                popupIcon={null}
                onChange={(e, value) => setPartNo(value as string)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      input: {
                        color: "black",
                        textAlign: "center"
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#666666"
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#666666"
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#666666"
                      }
                    }}
                  />
                )}
              />
            </PMDocumentGrid>
            <PMDocumentGrid item xs={3}>
              <Typography>PART NAME</Typography>
              <Typography pl={2}>{parts[partNo]?.partName}</Typography>
            </PMDocumentGrid>

            <PMDocumentGrid item xs={2}>
              <Typography pr={2}>PROCESS</Typography>
              <Autocomplete
                fullWidth
                value={process}
                options={
                  parts[partNo]?.process?.map(
                    (process) => process?.processOrder
                  ) || []
                }
                disablePortal
                disableClearable
                popupIcon={null}
                onChange={(e, value) => setProcess(value as string)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      input: {
                        color: "black",
                        textAlign: "center"
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#666666"
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#666666"
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#666666"
                      }
                    }}
                  />
                )}
              />
            </PMDocumentGrid>

            <PMDocumentGrid item xs={2}>
              <Typography>CUSTOMER</Typography>
              <Typography pl={2}>{parts[partNo]?.customer}</Typography>
            </PMDocumentGrid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          borderBottom="2px solid black"
          pb={1}
          pt={1}
        >
          <PMDocumentGrid item xs={2} display={"flex"} justifyItems={"center"}>
            <Typography>ลงชื่อ (ผู้ปฏิบัติงาน)</Typography>
          </PMDocumentGrid>
          <PMDocumentGrid item xs={2}>
            <Autocomplete
              fullWidth
              value={worker}
              options={employeeList}
              onChange={(e, value) => setWorker(value as string)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
              )}
            />
          </PMDocumentGrid>
          <PMDocumentGrid item xs={2}>
            <Typography>ลงชื่อ (หัวหน้าฝ่ายผลิต /QA)</Typography>
          </PMDocumentGrid>
          <PMDocumentGrid item xs={2}>
            <Autocomplete
              fullWidth
              value={leaderProduction}
              options={leaderApproved}
              onChange={(e, value) => setLeaderProduction(value as string)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
              )}
            />
          </PMDocumentGrid>
          <PMDocumentGrid item xs={2}>
            <Typography>Press machine No.</Typography>
          </PMDocumentGrid>
          <PMDocumentGrid item xs={2}>
            <Autocomplete
              fullWidth
              value={machine}
              options={machineList
                .filter((e) => e.line === line)
                .map((e: { machine: string }) => e.machine)}
              disablePortal
              disableClearable
              popupIcon={null}
              onChange={(e, value) => setMachine(value as string)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
              )}
            />
          </PMDocumentGrid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          borderBottom="2px solid black"
          pb={1}
          pt={1}
        >
          <PMDocumentGrid item xs={2}>
            <Typography>วัตถุประสงค์</Typography>
          </PMDocumentGrid>
          <PMDocumentGrid item xs={10}>
            <FormControl>
              <RadioGroup
                row
                onChange={(e) => setObjective(e.target.value)}
                value={objective}
              >
                <FormControlLabel
                  value="ซ่อม"
                  control={<ThemedRadio />}
                  label="ซ่อม"
                />
                <FormControlLabel
                  value={`PM Shot`}
                  style={{ paddingLeft: "103px" }}
                  control={<ThemedRadio />}
                  label={`PM Shot ${
                    parts[partNo]?.process?.find(
                      (e) => e?.processOrder === process
                    )?.minStroke ?? 0
                  }`}
                />
                <FormControlLabel
                  value="ปรับปรุง"
                  style={{
                    paddingLeft: "100px",
                    paddingRight: "100px"
                  }}
                  control={<ThemedRadio />}
                  label="ปรับปรุง"
                />
                <FormControlLabel
                  value="สร้าง"
                  style={{
                    paddingLeft: "100px",
                    paddingRight: "100px"
                  }}
                  control={<ThemedRadio />}
                  label="สร้าง"
                />
              </RadioGroup>
            </FormControl>
          </PMDocumentGrid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          borderBottom="2px solid black"
          pb={1}
          pt={1}
        >
          <PMDocumentGrid item xs={2}>
            <Typography>ประเภท</Typography>
          </PMDocumentGrid>
          <PMDocumentGrid item xs={10}>
            <FormControl>
              <RadioGroup
                row
                onChange={(e) => setType(e.target.value)}
                value={type}
              >
                <FormControlLabel
                  value="แม่พิมพ์ "
                  control={<ThemedRadio />}
                  label="แม่พิมพ์"
                />
                <FormControlLabel
                  value="อุปกรณ์ (JIG)"
                  style={{ paddingLeft: "60px" }}
                  control={<ThemedRadio />}
                  label="อุปกรณ์ (JIG)......"
                />
                <FormControlLabel
                  value="อุปกรณ์ (POKA-YOKE)"
                  style={{ paddingLeft: "60px" }}
                  control={<ThemedRadio />}
                  label="อุปกรณ์ (POKA-YOKE)"
                />
                <FormControlLabel
                  value="Other"
                  style={{ paddingLeft: "60px" }}
                  control={<ThemedRadio />}
                  label="Other"
                />
                <TextField
                  type="text"
                  variant="standard"
                  onChange={(e) => setTypeOther(e.target.value)}
                  value={type === "Other" ? typeOther : ""}
                />
              </RadioGroup>
            </FormControl>
          </PMDocumentGrid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          borderBottom="2px solid black"
          pb={1}
          pt={1}
        >
          <PMDocumentGrid item xs={2}>
            <Typography>ผลิตชิ้นงานได้</Typography>
          </PMDocumentGrid>
          <PMDocumentGrid item xs={2}>
            <TextField
              variant="standard"
              type="number"
              inputProps={{ step: "0.1", lang: "en-US" }}
              onChange={(e) => setProduction(Number(e.target.value))}
              value={production}
              sx={{
                input: {
                  color: "black",
                  textAlign: "center"
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#666666"
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#666666"
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#666666"
                }
              }}
            />
            <Typography>ชิ้น</Typography>
          </PMDocumentGrid>
          <PMDocumentGrid item xs={2}>
            <Typography>วันที่ต้องการ</Typography>
          </PMDocumentGrid>
          <PMDocumentGrid item xs={2}>
            <TextField
              variant="standard"
              type="date"
              onChange={(e) => setRepairDueDate(e.target.value)}
              value={repairDueDate}
              sx={{
                input: {
                  color: "black",
                  textAlign: "center"
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#666666"
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#666666"
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#666666"
                }
              }}
            />
          </PMDocumentGrid>
          <PMDocumentGrid item xs={1}>
            <Typography>เวลา</Typography>
          </PMDocumentGrid>
          <PMDocumentGrid item xs={1}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <TimePicker
                ampm={false}
                value={repairDueDateTime}
                onChange={(value) => {
                  setRepairDueDateTime(value ? value : 0)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{
                      input: {
                        color: "black",
                        textAlign: "center"
                      },

                      svg: {
                        color: "black"
                      }
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </PMDocumentGrid>
        </Grid>

        <Grid item container xs={12} pb={1} pt={1}>
          <PMDocumentGrid item xs={2}>
            <Typography>ปัญหา</Typography>
          </PMDocumentGrid>
          <PMDocumentGrid item xs={10}>
            <FormControl>
              <RadioGroup
                row
                onChange={(e) => setProblem(e.target.value)}
                value={problem}
              >
                <FormControlLabel
                  value="1.ชิ้นงานเป็นครีบรู"
                  control={<ThemedRadio />}
                  label="1.ชิ้นงานเป็นครีบรู"
                />
                <FormControlLabel
                  value="2.ครีบขอบ"
                  control={<ThemedRadio />}
                  style={{ paddingLeft: "120px" }}
                  label="2.ครีบขอบ"
                />
                <FormControlLabel
                  value="3.ชิ้นงานไม่ได้ระยะขนาด"
                  style={{ paddingLeft: "120px" }}
                  control={<ThemedRadio />}
                  label="3.ชิ้นงานไม่ได้ระยะขนาด"
                />
                <FormControlLabel
                  value="4.เป็นรอยขุดรอยไหม้"
                  style={{ paddingLeft: "100px" }}
                  control={<ThemedRadio />}
                  label="4.เป็นรอยขุดรอยไหม้"
                />
                <FormControlLabel
                  value="5.ชิ้นงานเป็นรอยกดเบียด"
                  style={{ paddingLeft: "120px" }}
                  control={<ThemedRadio />}
                  label="5.ชิ้นงานเป็นรอยกดเบียด"
                />
                <FormControlLabel
                  value="6.แม่พิมพ์ชำรุด"
                  onClick={() => setOpen(true)}
                  style={{ paddingLeft: "100px" }}
                  control={<ThemedRadio />}
                  label={"6.แม่พิมพ์ชำรุด " + "(" + brokenType + ")"}
                />

                <FormControlLabel
                  value="7.อื่นๆระบุปัญหา"
                  style={{ paddingLeft: "100px" }}
                  control={<ThemedRadio />}
                  label="7.อื่นๆระบุปัญหา"
                />
                <TextField
                  type="text"
                  variant="standard"
                  onChange={(e) => setOtherProblem(e.target.value)}
                  value={otherProblem}
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
              </RadioGroup>
            </FormControl>
          </PMDocumentGrid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          "@media print": {
            display: "none"
          },
          display: informantPMFrom?.repairDate ? "none" : ""
        }}
      >
        <Grid
          item
          xs={2}
          sx={!informantPMFrom ? { mt: 1 } : { display: "none" }}
        >
          <ThemedButton
            fullWidth
            sx={!informantPMFrom ? { mt: 1 } : { display: "none" }}
            endIcon={<Add />}
            onClick={handleRepairInformant}
          >
            Create From
          </ThemedButton>
        </Grid>
        <Grid item xs={2}>
          <ThemedButton
            fullWidth
            sx={informantPMFrom ? { mt: 1 } : { display: "none" }}
            endIcon={<Add />}
            onClick={handleUpdateRepairInformant}
          >
            Update
          </ThemedButton>
        </Grid>
        <Grid item xs={2}>
          <ThemedButton
            fullWidth
            sx={informantPMFrom ? { mt: 1 } : { display: "none" }}
            endIcon={<Delete />}
            onClick={handleDeleteRepairInformant}
          >
            Delete
          </ThemedButton>
        </Grid>
      </Grid>
      <DialogToolingBroken
        brokenType={brokenType}
        open={open}
        setBrokenType={setBrokenType}
        setOpenDialog={setOpen}
      />
    </Box>
  )
}
export default RepairInformant
