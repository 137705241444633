import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses
} from "@mui/material"
import { logoTable } from "../../common/utilities"
import { DataProductionPlan } from "./ProductionPlanReport"
import EditTableRow, { TChangeEvent } from "./component/EditTableRow"
import ReadOnlyRow from "./component/ReadOnlyRow"

interface IProductionPlanProps {
  masterPlan: DataProductionPlan[]
  edit: boolean
  deleteMachine: boolean
  editPlan: number
  editFormData: DataProductionPlan
  totalQuantity: number
  totalQuantityBackup: number
  backupPlan: DataProductionPlan[]
  handleEditTable: (
    even: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: DataProductionPlan
  ) => void
  handleDeleteTable: (
    even: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: DataProductionPlan
  ) => void
  handleEditFromData: (e: TChangeEvent) => void
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2,
    color: "black"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  border: "solid 1px black",
  color: "black",
  "&:nth-of-type(odd)": {
    border: "solid 1px black"
  },

  "&:last-child td, &:last-child th": {
    border: "solid 1px black"
  }
}))

export const TableProductionPlan: React.FC<IProductionPlanProps> = (
  props: IProductionPlanProps
) => {
  const {
    masterPlan,
    edit,
    deleteMachine,
    editPlan,
    editFormData,
    totalQuantity,
    totalQuantityBackup,
    backupPlan,
    handleEditFromData,
    handleEditTable,
    handleDeleteTable
  } = props
  return (
    <Table className="production-plan-table">
      <TableHead>
        <StyledTableRow>
          <StyledTableCell colSpan={7}>
            <img
              style={{ width: "70%", verticalAlign: "top" }}
              src={logoTable}
              alt=""
            />
          </StyledTableCell>
          <StyledTableCell colSpan={14} style={{ fontSize: "25px" }}>
            PRODUCTION PLAN
          </StyledTableCell>
          <StyledTableCell colSpan={2} style={{ textAlign: "left" }}>
            <Typography>REV: F-PL-02-01</Typography>
            <Typography>Effective Date: 1/2/17 R.01 Issue:1</Typography>
            <hr />
            <Typography>
              SECTION:
              {masterPlan[0]?.section ?? ""}
            </Typography>
            <hr />
            <Typography>
              DATE:
              {masterPlan[0]?.planDate ?? ""}
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell colSpan={5} style={{ fontSize: "15px" }}>
            PRODUCTION OUT-PUT REPORT
          </StyledTableCell>
          <StyledTableCell colSpan={2} style={{ fontSize: "15px" }}>
            PLAN : {masterPlan[0]?.pdPlan ?? ""}
          </StyledTableCell>
          <StyledTableCell colSpan={5} style={{ fontSize: "15px" }}>
            PRODUCTION PLAN NO : {masterPlan[0]?.planNo ?? ""}
          </StyledTableCell>
          <StyledTableCell colSpan={7} style={{ fontSize: "15px" }}>
            REVISION : {masterPlan[0]?.revision ?? ""}
          </StyledTableCell>
          <StyledTableCell colSpan={2} style={{ fontSize: "12px" }}>
            PLAN LINE:{masterPlan[0]?.line ?? ""}
          </StyledTableCell>
          <StyledTableCell colSpan={2}>
            SHIFT : {masterPlan[0]?.shift ?? ""}
          </StyledTableCell>
        </StyledTableRow>
        <TableRow>
          {edit ? <StyledTableCell rowSpan={2}>Edit</StyledTableCell> : <></>}
          {deleteMachine ? (
            <StyledTableCell rowSpan={2}>Delete</StyledTableCell>
          ) : (
            <></>
          )}
          <StyledTableCell rowSpan={2}>MACHINE</StyledTableCell>
          <StyledTableCell rowSpan={2}>CUSTOMER</StyledTableCell>
          <StyledTableCell rowSpan={2}>PART NO</StyledTableCell>
          <StyledTableCell rowSpan={2}>PART NAME</StyledTableCell>
          <StyledTableCell rowSpan={2}>STEP</StyledTableCell>
          <StyledTableCell rowSpan={2}>PROCESS</StyledTableCell>
          <StyledTableCell rowSpan={2}>MAT.LOT</StyledTableCell>
          <StyledTableCell rowSpan={2}>PD.LOT</StyledTableCell>
          <StyledTableCell rowSpan={2}>COM.LOT</StyledTableCell>
          <StyledTableCell colSpan={2}>WORKING TIME</StyledTableCell>
          <StyledTableCell colSpan={2}>WORKING TIME.OT.</StyledTableCell>
          <StyledTableCell>TOTAL TIME</StyledTableCell>
          <StyledTableCell>Cycle TIME</StyledTableCell>
          <StyledTableCell>TOTAL PLAN</StyledTableCell>
          {Array.from(Array(3), () => {
            return <StyledTableCell>TOTAL ACTUAL</StyledTableCell>
          })}

          <StyledTableCell colSpan={2}>WORKING TIME.PD.</StyledTableCell>
          <StyledTableCell rowSpan={2}>OPERATOR</StyledTableCell>
          <StyledTableCell rowSpan={2}>REMARKS</StyledTableCell>
        </TableRow>
        <StyledTableRow>
          <StyledTableCell>START</StyledTableCell>
          <StyledTableCell>END</StyledTableCell>
          <StyledTableCell>START (OT)</StyledTableCell>
          <StyledTableCell>END (OT)</StyledTableCell>
          <StyledTableCell>(hrs.)</StyledTableCell>
          <StyledTableCell>Q'ty (pcs/hr.)</StyledTableCell>
          <StyledTableCell>Q'ty(pcs)</StyledTableCell>
          <StyledTableCell>OK(pcs)</StyledTableCell>
          <StyledTableCell>NG.InProcess(pcs)</StyledTableCell>
          <StyledTableCell>NG.Set up(pcs.)</StyledTableCell>
          <StyledTableCell>START</StyledTableCell>
          <StyledTableCell>END</StyledTableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {masterPlan?.map((row) => {
          return (
            <>
              {editPlan === row.id ? (
                <>
                  <EditTableRow
                    editFormData={editFormData}
                    handleEditFromData={handleEditFromData}
                    edit={edit}
                  ></EditTableRow>
                </>
              ) : (
                <ReadOnlyRow
                  row={row}
                  handleEditTable={handleEditTable}
                  edit={edit}
                  handleDeleteTable={handleDeleteTable}
                  deleteMachine={deleteMachine}
                ></ReadOnlyRow>
              )}
            </>
          )
        })}

        <StyledTableRow>
          <StyledTableCell
            style={{ fontSize: "16px", fontWeight: "bolder" }}
            colSpan={14}
            align="center"
          >
            Total Plan
          </StyledTableCell>
          <StyledTableCell />
          <StyledTableCell>{totalQuantity}</StyledTableCell>
          {Array.from(Array(4), () => {
            return <StyledTableCell />
          })}
        </StyledTableRow>
        {backupPlan?.map((row) => {
          return (
            <>
              {editPlan === row.id ? (
                <EditTableRow
                  editFormData={editFormData}
                  handleEditFromData={handleEditFromData}
                  edit={edit}
                ></EditTableRow>
              ) : (
                <ReadOnlyRow
                  row={row}
                  handleEditTable={handleEditTable}
                  edit={edit}
                  handleDeleteTable={handleDeleteTable}
                  deleteMachine={deleteMachine}
                ></ReadOnlyRow>
              )}
            </>
          )
        })}
        {backupPlan.length > 0 ? (
          <StyledTableRow>
            <StyledTableCell
              style={{ fontSize: "16px", fontWeight: "bolder" }}
              colSpan={14}
              align="center"
            >
              Total Plan
            </StyledTableCell>
            <StyledTableCell />
            <StyledTableCell>{totalQuantityBackup}</StyledTableCell>
            {Array.from(Array(5), () => {
              return <StyledTableCell />
            })}
            <StyledTableCell />
          </StyledTableRow>
        ) : (
          <></>
        )}
      </TableBody>
    </Table>
  )
}
