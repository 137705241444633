import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  useTheme
} from "@mui/material"
import {
  StyledTableCell,
  StyledTableRow,
  getAllPlant,
  paddingPartDeliveryDates,
  showTableActualF1,
  showTableActualF2,
  showTableFollowUp,
  validateRowSpan
} from "../utilities"
import { logoTable } from "../../../common/utilities"
import { useMPContext } from "../../../context/MPContext"
import { IMasterPlanPartData } from "../MPReport"
import { Dispatch, SetStateAction, useState } from "react"
import { TableRowActualF1 } from "./TableRowActualF1"
import { TableRowActualOSF1 } from "./TableRowActualOSF1"
import { TableRowActualF2 } from "./TableRowActualF2"
import { TableRowFollowUp } from "./TableRowFollowUp"
interface IProp {
  masterPlanPartData: IMasterPlanPartData[]
  setPartDataWip: Dispatch<SetStateAction<IMasterPlanPartData | undefined>>
  setOpenDialogWip: Dispatch<SetStateAction<boolean>>
}
interface Column {
  id:
    | "index"
    | "partNo"
    | "partName"
    | "plant"
    | "plating"
    | "customer"
    | "minStock"
    | "maxStock"
    | "model"
    | "factory"
    | "materialStock"
    | "beginStock"
    | "fgStock"
    | "order"
  label: string
  minWidth?: number
  format?: (value: number) => string
}
export const columns: Column[] = [
  { id: "index", label: "Index", minWidth: 20 },
  { id: "partNo", label: "Part No", minWidth: 100 },
  { id: "partName", label: "Part Name", minWidth: 100 },
  { id: "plant", label: "Plant", minWidth: 100 },
  { id: "plating", label: "Plating", minWidth: 100 },
  { id: "customer", label: "Customer", minWidth: 100 },
  { id: "minStock", label: "Min Stock", minWidth: 100 },
  { id: "maxStock", label: "Max Stock", minWidth: 100 },
  { id: "model", label: "Model", minWidth: 100 },
  { id: "factory", label: "Factory", minWidth: 100 },
  { id: "materialStock", label: "Material Stock", minWidth: 100 },
  { id: "fgStock", label: "FG Stock", minWidth: 100 },
  { id: "beginStock", label: "Begin Stock", minWidth: 100 },
  { id: "order", label: "Order", minWidth: 100 }
]
export const dataRows: string[] = [
  "index",
  "partNo",
  "partName",
  "plant",
  "status",
  "customer",
  "minStock",
  "maxStock",
  "model",
  "factory",
  "matStock"
]
export const TableContainerMP: React.FC<IProp> = (props: IProp) => {
  const { masterPlanPartData, setOpenDialogWip, setPartDataWip } = props
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)

  const {
    maxDueDate,
    factory,
    plating,
    followUp,
    orderCheck,
    negativePartFollowUpF1,
    customer
  } = useMPContext()

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const handleClickDialogWip = (partData: IMasterPlanPartData) => {
    setPartDataWip(partData)
    setOpenDialogWip(true)
  }
  const theme = useTheme()

  return (
    <TableContainer>
      <Table key={"master"}>
        <TableHead>
          <StyledTableRow key={"table-masterplan"}>
            <StyledTableCell
              style={{
                padding: "20px",
                fontSize: "20px"
              }}
              colSpan={7}
            >
              <img src={logoTable} alt="" height={"80vh"} />
            </StyledTableCell>
            <StyledTableCell
              style={{
                padding: "20px",
                fontSize: "35px"
              }}
              colSpan={maxDueDate.length + 7}
            >
              <div> Master Plan</div>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key={"row-masterplan"}>
            {columns.map((column) => (
              <StyledTableCell key={column.id}>{column.label}</StyledTableCell>
            ))}
            {maxDueDate.map((e: string, i: number) => {
              return (
                <StyledTableCell key={i + "max-due-date"}>
                  {e.split("-")[2]} - {e.split("-")[1]}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
        </TableHead>
        <TableBody key={"table-body-masterplan"}>
          {masterPlanPartData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((partData: IMasterPlanPartData, index) => {
              const order = paddingPartDeliveryDates(
                partData.order,
                maxDueDate,
                partData.stockBegin
              )

              return (
                <>
                  <StyledTableRow
                    className={index % 2 === 0 ? "display-border" : undefined}
                    key={"row-body" + index + partData.partNo}
                    onDoubleClick={() => handleClickDialogWip(partData)}
                  >
                    {Array.from(Array(dataRows.length), (num, i) => {
                      return (
                        <StyledTableCell
                          key={i}
                          rowSpan={validateRowSpan(
                            factory,
                            plating,
                            followUp,
                            partData.customer,
                            partData.partNo,
                            orderCheck
                          )}
                        >
                          {dataRows[i] === "plant"
                            ? getAllPlant(partData.plant)
                            : partData[
                                dataRows[i] as keyof IMasterPlanPartData
                              ]}
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                  <StyledTableRow
                    className={index % 2 === 0 ? "display-border" : undefined}
                    key={"row-order" + index + partData.partNo}
                  >
                    <StyledTableCell rowSpan={1}>FG Stock</StyledTableCell>
                    <StyledTableCell rowSpan={1}>Begin Stock</StyledTableCell>
                    <StyledTableCell rowSpan={1}>Order</StyledTableCell>
                    {Array.from(Array(maxDueDate.length), (num, i) => {
                      if (order[i]) {
                        return (
                          <StyledTableCell
                            rowSpan={1}
                            key={"cel-order" + i + partData.partNo}
                          >
                            <span
                              style={
                                order[i].partCounted > 1 ? { color: "red" } : {}
                              }
                            >
                              <>{order[i].quantity}</>
                            </span>
                          </StyledTableCell>
                        )
                      } else {
                        return (
                          <StyledTableCell
                            rowSpan={1}
                            key={"cel-order" + i + partData.partNo}
                          >
                            <>{0}</>
                          </StyledTableCell>
                        )
                      }
                    })}
                  </StyledTableRow>
                  {showTableActualF1(
                    partData.customer,
                    orderCheck,
                    factory,
                    negativePartFollowUpF1,
                    plating,
                    followUp
                  ) ? (
                    <TableRowActualF1
                      index={index}
                      customer={customer}
                      partData={partData}
                      order={order}
                      negativePartFollowUpF1={negativePartFollowUpF1}
                      plating={plating}
                      followUp={followUp}
                    />
                  ) : (
                    <></>
                  )}
                  {showTableActualF2(
                    partData.customer,
                    orderCheck,
                    factory,
                    plating,
                    followUp
                  ) ? (
                    <>
                      <TableRowActualOSF1
                        index={index}
                        customer={customer}
                        partData={partData}
                        order={order}
                      />
                      <TableRowActualF2
                        index={index}
                        customer={customer}
                        partData={partData}
                        order={order}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {showTableFollowUp(
                    partData.customer,
                    orderCheck,
                    factory,
                    plating,
                    negativePartFollowUpF1,
                    followUp
                  ) ? (
                    <TableRowFollowUp
                      index={index}
                      order={order}
                      partNo={partData.partNo}
                      partData={partData}
                      plating={plating}
                      negativePartFollowUpF1={negativePartFollowUpF1}
                      followUp={followUp}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[3, 5, 10, masterPlanPartData.length]}
        component="div"
        count={masterPlanPartData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          backgroundColor: theme.palette.warning.light
        }}
        style={{ width: "100%" }}
      />
    </TableContainer>
  )
}
