import { gql } from "@apollo/client"

export const ADD_PRODUCTION_PLAN = gql`
  mutation SubmitProductionPlan(
    $inputProductionPlan: [InputProductionPlan]!
    $department: String
  ) {
    submitProductionPlan(
      inputProductionPlan: $inputProductionPlan
      department: $department
    )
  }
`

export const DELETE_PRODUCTION_PLAN = gql`
  mutation DeleteProductionPlan($id: Int!) {
    deleteProductionPlan(Id: $id)
  }
`
export const CREATE_PROBLEM = gql`
  mutation ProblemCreate($dataProblem: DataProblem!) {
    problemCreate(dataProblem: $dataProblem) {
      id
      message
      department
      actionDate
      link
      time
      userRead
    }
  }
`

export const UPDATE_STATUS = gql`
  mutation UpdateStatusProductionPlan(
    $line: String
    $revision: String
    $planDate: String
  ) {
    updateStatusProductionPlan(
      line: $line
      revision: $revision
      planDate: $planDate
    )
  }
`

export const DELETE_JOB_ORDER = gql`
  mutation DeleteJobOrder(
    $jobOrder: String!
    $updatedBy: String!
    $fromPage: String!
  ) {
    deleteJobOrder(
      jobOrder: $jobOrder
      updatedBy: $updatedBy
      fromPage: $fromPage
    )
  }
`
