import { gql } from "@apollo/client"

export const ADD_CUSTOMER = gql`
  mutation CreateCustomers($customer: String!) {
    createCustomers(customer: $customer)
  }
`
export const ADD_MATERIAL = gql`
  mutation CreateMaterial(
    $matNo: String!
    $matName: String!
    $sizeL: String!
    $sizeW: String!
    $sizeH: String!
    $ratio: Float!
    $partType: String!
    $spec: String!
    $factory: String
    $wipQtyBox: Float
  ) {
    createMaterial(
      matNo: $matNo
      matName: $matName
      sizeL: $sizeL
      sizeW: $sizeW
      sizeH: $sizeH
      ratio: $ratio
      partType: $partType
      spec: $spec
      factory: $factory
      wipQtyBox: $wipQtyBox
    ) {
      matNo
      matName
      sizeL
      sizeW
      sizeH
      ratio
      partType
      spec
      wipQtyBox
      factory
    }
  }
`

export const ADD_PARTS = gql`
  mutation AddParts(
    $partNo: String!
    $partName: String!
    $customer: String
    $model: String
    $material: String!
    $cost: Float!
    $leadTime: Int!
    $stamp: Int!
    $weld: Int!
    $arcweld: Int!
    $plate: Int!
    $boxStd: Int!
  ) {
    createParts(
      partNo: $partNo
      partName: $partName
      customer: $customer
      model: $model
      material: $material
      cost: $cost
      leadTime: $leadTime
      stamp: $stamp
      weld: $weld
      arcweld: $arcweld
      plate: $plate
      boxStd: $boxStd
    ) {
      weld
      stamp
      plate
      partName
      partNo
      customer
      model
      model
      leadTime
      customer
      cost
      arcweld
      boxStd
    }
  }
`

export const ADD_MACHINE = gql`
  mutation CreateMachine(
    $machine: String!
    $code: String!
    $line: String!
    $machinePower: String
    $remarks: String
    $installationDate: String
    $details: String
    $brand: String
    $model: String
    $company: String
    $priority: String
    $frequency: Int
    $factory: String
  ) {
    createMachine(
      machine: $machine
      code: $code
      line: $line
      machinePower: $machinePower
      remarks: $remarks
      installationDate: $installationDate
      details: $details
      brand: $brand
      model: $model
      company: $company
      priority: $priority
      frequency: $frequency
      factory: $factory
    ) {
      machine
      code
      line
      factory
      frequency
      priority
      company
      model
      brand
      installationDate
      details
      machinePower
      remarks
    }
  }
`

export const ADD_PROCESS = gql`
  mutation AddProcess($process: String!) {
    createProcess(process: $process) {
      process
    }
  }
`

export const ADD_PARTS_PROCESS = gql`
  mutation CreatePartsprocessing(
    $processOrder: String!
    $stepProcess: Int!
    $partNo: String!
    $process: String!
    $machine: String!
    $line: String!
    $jph: Float!
    $minStroke: Int!
    $operator: String
  ) {
    createPartsprocessing(
      processOrder: $processOrder
      stepProcess: $stepProcess
      partNo: $partNo
      process: $process
      machine: $machine
      line: $line
      jph: $jph
      minStroke: $minStroke
      operator: $operator
    ) {
      processOrder
      stepProcess
      partNo
      process
      machine
      line
      jph
      minStroke
      operator
    }
  }
`

export const ADD_WIP_MATERIAL = gql`
  mutation AddWipMaterial(
    $matNo: String!
    $matName: String
    $sizeL: String
    $sizeH: String
    $ratio: Float
    $partType: String
    $factory: String
    $wipQtyBox: Float
    $spec: String
    $sizeW: String
  ) {
    addWipMaterial(
      matNo: $matNo
      matName: $matName
      sizeL: $sizeL
      sizeH: $sizeH
      ratio: $ratio
      partType: $partType
      factory: $factory
      wipQtyBox: $wipQtyBox
      spec: $spec
      sizeW: $sizeW
    )
  }
`
export const EDIT_PART_PROCESS = gql`
  mutation UpdatePartsprocessing(
    $processOrder: String!
    $partNo: String!
    $machine: String!
    $jph: Float!
    $minStroke: Int!
    $line: String!
    $stepProcess: Int!
    $operator: String
  ) {
    updatePartsprocessing(
      processOrder: $processOrder
      partNo: $partNo
      machine: $machine
      jph: $jph
      minStroke: $minStroke
      line: $line
      stepProcess: $stepProcess
      operator: $operator
    )
  }
`

export const UPLOAD_PART_IMAGE = gql`
  mutation UploadImage($file: String!, $partNo: String) {
    uploadImage(file: $file, partNo: $partNo)
  }
`

export const UPLOAD_MACHINE_IMAGE = gql`
  mutation UploadImageMachine($file: Upload!, $name: String) {
    uploadImageMachine(file: $file, name: $name)
  }
`

export const DELETE_MACHINE = gql`
  mutation DeleteMachine($machine: String) {
    deleteMachine(machine: $machine)
  }
`

export const UPDATE_MACHINE = gql`
  mutation UpdateMachine(
    $machine: String!
    $code: String!
    $line: String!
    $factory: String
    $frequency: Int
    $priority: String
    $company: String
    $brand: String
    $model: String
    $details: String
    $machinePower: String
    $installationDate: String
    $remarks: String
  ) {
    updateMachine(
      machine: $machine
      code: $code
      line: $line
      factory: $factory
      frequency: $frequency
      priority: $priority
      company: $company
      brand: $brand
      model: $model
      details: $details
      machinePower: $machinePower
      installationDate: $installationDate
      remarks: $remarks
    )
  }
`

export const ADD_EMPLOYEE = gql`
  mutation CreateEmployee(
    $firstName: String!
    $lastName: String!
    $departmentId: Int!
    $factory: String!
    $position: String!
  ) {
    createEmployee(
      firstName: $firstName
      lastName: $lastName
      departmentId: $departmentId
      factory: $factory
      position: $position
    ) {
      employeeId
      firstName
      lastName
      departmentId
      factory
      position
    }
  }
`

export const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployee(
    $firstName: String
    $lastName: String
    $departmentId: Int
    $factory: String
    $employeeId: Int
    $position: String
  ) {
    updateEmployee(
      firstName: $firstName
      lastName: $lastName
      departmentId: $departmentId
      factory: $factory
      employeeId: $employeeId
      position: $position
    )
  }
`

export const DELETE_EMPLOYEE = gql`
  mutation DeleteEmployee($employeeId: Int) {
    deleteEmployee(employeeId: $employeeId)
  }
`

export const UPDATE_PART = gql`
  mutation UpdateParts(
    $partNo: String!
    $partName: String!
    $cost: Float!
    $leadTime: Int!
    $stamp: Int!
    $weld: Int!
    $arcweld: Int!
    $plate: Int!
    $customer: String
    $model: String
    $boxStd: Int!
  ) {
    updateParts(
      partNo: $partNo
      partName: $partName
      cost: $cost
      leadTime: $leadTime
      stamp: $stamp
      weld: $weld
      arcweld: $arcweld
      plate: $plate
      customer: $customer
      model: $model
      boxStd: $boxStd
    )
  }
`
export const ADD_BOM = gql`
  mutation CreateBom($partNo: String!, $childrenPart: [IPartToChildren!]) {
    createBom(partNo: $partNo, childrenPart: $childrenPart)
  }
`
