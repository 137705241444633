import { useMutation, useQuery } from "@apollo/client"
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material"
import { useState } from "react"
import { GET_EMPLOYEE } from "../../../common/Query/MasterQuery"
import swal from "sweetalert"
import { REPAIR_AUDITOR } from "../../../common/Mutation/MaintenanceMutation"
import moment from "moment"
import { GET_REPAIR_AUDIT } from "../../../common/Query/MaintenanceQuery"
import { miniLogo } from "../../../common/utilities"
import ThemedRadio from "../../../common/Resources/ThemedComponents/ThemedRadio"
import FormTextField from "../../../common/Resources/ThemedComponents/Maintenance/FormTextField"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"

const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  borderBottom: "1px",
  padding: "5px",
  fontSize: "14px",
  border: "solid 1px black",
  backgroundColor: "#FFFFFF",
  color: "#000000"
}))

interface IRequestData {
  actionDate: string
  recordNo: number
  department: string
  machineName: string
  code: string
  workType: string
  jobType: string
  urgency: string
  detail: string
  requestDate: string
  request: string
  completeDate: string
  requestBy: string
  approveBy: string
  factory: string
}

export interface IRepairAuditData {
  recordNo: number
  status: string
  remarks: string
  checker: string
  actionDate: string
  department: string
}
interface IProp {
  repairAuditData?: IRepairAuditData
  repairFrom: IRequestData
}

const RepairAuditor: React.FC<IProp> = (props: IProp) => {
  const { repairAuditData, repairFrom } = props

  const [checker, setChecker] = useState<string>("")
  const [status, setStatus] = useState<string>("")
  const [remarks, setRemarks] = useState<string>("")
  const getEmployee = useQuery(GET_EMPLOYEE)
  const [auditor] = useMutation(REPAIR_AUDITOR, {
    refetchQueries: [GET_REPAIR_AUDIT],
    awaitRefetchQueries: true
  })
  const leaderList =
    getEmployee.data?.employee
      .filter(
        (e: { position: string }) =>
          e.position === "Leader" || e.position === "Manager"
      )
      .map(({ firstName }: { firstName: string }) => firstName)
      .reduce(
        (prev: string[], cur: string) =>
          prev.includes(cur) ? prev : prev.concat(cur),
        []
      ) || []
  const handleCreate = async () => {
    if (status === "ไม่เรียบร้อย" && remarks === "")
      return swal("กรอกข้อมูลไม่ครบ", "", "warning")
    if (status === "" || checker === "")
      return swal("กรอกข้อมูลไม่ครบ", "", "error")

    const inputRepairAuditor = {
      recordNo: Number(repairFrom.recordNo),
      status,
      remarks,
      checker,
      department: repairFrom.department,
      actionDate: repairFrom.actionDate,
      factory: repairFrom.factory
    }
    try {
      await auditor({
        variables: {
          inputRepairAuditor
        }
      })
      swal("OK", "", "success")
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "error")
    }
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ backgroundColor: "white", padding: "5px", color: "black" }}
    >
      <Table sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell rowSpan={2}>
              <img
                src={miniLogo}
                alt="Brother Auto Parts."
                style={{
                  width: "150px",
                  height: "100px"
                }}
              />
            </StyledTableCell>
            <StyledTableCell rowSpan={2}>
              <h1>ใบเเจ้งซ่อม</h1>
            </StyledTableCell>
            <StyledTableCell>
              <h3>Doc No.F-MT-01-04</h3>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>
              <h3>
                Rec.No.
                {moment(repairFrom?.actionDate).format("YYYY-MM") +
                  "-" +
                  repairFrom?.recordNo}
                -1000
              </h3>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell>สำหรับผู้ตรวจรับ</StyledTableCell>
            <StyledTableCell colSpan={2}>
              ผู้ตรวจรับได้นำเนินการตรวจสอบการซ่อมตามรายละเอียดการเเจ้งซ่อมที่กำหนดข้างต้นเเล้ว
              ปรากฎว่า
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell colSpan={3}>
              <FormControl>
                <RadioGroup
                  value={repairAuditData?.status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <FormControlLabel
                    value="เรียบร้อยเเละสามารถใช้งานได้ตามปกติ"
                    control={<ThemedRadio />}
                    label="เรียบร้อยเเละสามารถใช้งานได้ตามปกติ"
                  />
                  <FormControlLabel
                    value="ไม่เรียบร้อย"
                    control={<ThemedRadio />}
                    label="ไม่เรียบร้อย"
                  />
                  <FormTextField
                    type="text"
                    variant="standard"
                    value={repairAuditData?.remarks || remarks}
                    error={status === "ไม่เรียบร้อย" ? true : false}
                    label="สาเหตุเพราะ"
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </RadioGroup>
              </FormControl>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              colSpan={3}
              style={{ textAlign: "right", borderTop: "solid 2px white" }}
            >
              <p style={{ display: "inline-flex" }}>ผู้ตรวจรับ :</p>
              <Autocomplete
                freeSolo
                options={leaderList}
                value={repairAuditData?.checker || checker}
                sx={{
                  width: "150px",
                  display: "inline-flex",
                  marginRight: "150px"
                }}
                onChange={(e, newValue) => setChecker(newValue as string)}
                renderInput={(params) => (
                  <FormTextField
                    {...params}
                    variant="standard"
                    sx={{ input: { textAlign: "center" } }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              colSpan={3}
              style={{ textAlign: "right", borderTop: "solid 2px white" }}
            >
              <p style={{ paddingRight: "30px", marginRight: "120px" }}>
                หัวหน้า/ผู้จัดการฝ่าย
              </p>
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid item xs={2} sm={2}>
        <ThemedLoadingButton
          variant="contained"
          sx={{ mt: 1, width: "150px" }}
          autoFocus
          onClick={handleCreate}
        >
          CREATE
        </ThemedLoadingButton>
      </Grid>
    </Grid>
  )
}

export default RepairAuditor
