import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  tableCellClasses
} from "@mui/material"

interface ITableSignatureProps {
  factory: string
  title: string
  name: string
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2,
    color: "black"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  border: "solid 1px black",
  "&:nth-of-type(odd)": {
    border: "solid 1px black"
  },

  "&:last-child td, &:last-child th": {
    border: "solid 1px black"
  }
}))
export const TableSignature: React.FC<ITableSignatureProps> = (
  props: ITableSignatureProps
) => {
  const { factory, name, title } = props
  return (
    <Table>
      <TableBody>
        <StyledTableRow>
          <StyledTableCell colSpan={1} align="center">
            {title}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{
              height: "30px"
            }}
          >
            {factory === "2" ? name : ""}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell align="center">Approved</StyledTableCell>
        </StyledTableRow>
      </TableBody>
    </Table>
  )
}
