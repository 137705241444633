import { FormControlLabel, Grid } from "@mui/material"
import ThemedCheckbox from "../../../common/Resources/ThemedComponents/ThemedCheckbox"
import { tableCustomerMahle } from "../utilities"
import { useMPContext } from "../../../context/MPContext"
import { ChangeEvent, useEffect } from "react"

export const FilterCheckout: React.FC = () => {
  const {
    factory,
    setNegativePartF1,
    setPlating,
    setSubmitUpdate,
    setFollowUp,
    setNegativePartF2,
    setNegativePartFollowUp,
    setNegativePartFollowUpF1,
    followUp,
    customer,
    plating
  } = useMPContext()
  useEffect(() => {
    setNegativePartF1(false)
    setPlating(false)
    setSubmitUpdate(false)
    setFollowUp(false)
    setNegativePartF2(false)
    setNegativePartFollowUp(false)
    setNegativePartFollowUpF1(false)
    setPlating(false)
  }, [customer, factory])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <label>Filter Part </label>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <ThemedCheckbox
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setPlating(event.target.checked)
              }}
            />
          }
          label="Plating (ชุบ)"
        ></FormControlLabel>
        <FormControlLabel
          control={
            <ThemedCheckbox
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setFollowUp(event.target.checked)
              }
            />
          }
          style={factory === "2" && plating ? {} : { display: "none" }}
          label="Follow Up factory 2"
        ></FormControlLabel>
        <FormControlLabel
          control={
            <ThemedCheckbox
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setNegativePartF1(event.target.checked)
              }
            />
          }
          style={factory === "1" ? {} : { display: "none" }}
          label={
            !tableCustomerMahle(customer)
              ? "Deficit (ยอดติตลบ) F1"
              : "Stock bal. OS (ยอดติตลบ)"
          }
        ></FormControlLabel>
        <FormControlLabel
          control={
            <ThemedCheckbox
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setNegativePartF2(event.target.checked)
              }
            />
          }
          style={
            factory == "2" || customer === "H-ONE" ? {} : { display: "none" }
          }
          label={
            !tableCustomerMahle(customer)
              ? "Deficit (ยอดติตลบ) F2"
              : "Stock bal. F1 (ยอดติตลบ)"
          }
        ></FormControlLabel>
        <FormControlLabel
          control={
            <ThemedCheckbox
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setNegativePartFollowUp(event.target.checked)
              }
            />
          }
          style={!followUp ? { display: "none" } : {}}
          label="Deficit (ยอดติตลบ) Follow Up"
        ></FormControlLabel>
        <FormControlLabel
          control={
            <ThemedCheckbox
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setNegativePartFollowUpF1(event.target.checked)
              }
            />
          }
          style={!followUp ? { display: "none" } : {}}
          label="Deficit (ยอดติตลบ) F1"
        ></FormControlLabel>
      </Grid>
    </Grid>
  )
}
