import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme
} from "@mui/material"
import { ThemeProvider } from "@mui/styles"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { useEffect, useState } from "react"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"
import DateAdapter from "@mui/lab/AdapterMoment"
import moment from "moment"
import { green, grey } from "@mui/material/colors"
import { red } from "@mui/material/colors"
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye"
import CircleIcon from "@mui/icons-material/Circle"
import { ITrialToolingData } from "./Component/TrialToolingSheetPlanningApproval"
import { signatureMap } from "./Component/TrialToolingCreate"
import { useQuery } from "@apollo/client"
import { GET_TRIAL_TOOLING_SHEET } from "../../common/Query/engineerQuery"

interface Column {
  id:
    | "recordNo"
    | "trialDate"
    | "factory"
    | "partNo"
    | "pLPlanDate"
    | "pLTime"
    | "pL"
    | "engLead"
    | "eng"
    | "pd1"
    | "pd2"
    | "pd"
    | "pt"
    | "qa"
    | "mt"
    | "fm"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}
const columns: Column[] = [
  { id: "recordNo", label: "Doc No.", minWidth: 200 },
  { id: "trialDate", label: "Create Date", minWidth: 200 },
  { id: "factory", label: "Factory", minWidth: 200 },
  { id: "partNo", label: "Part No.", minWidth: 200 },
  { id: "pLPlanDate", label: "Trial Date", minWidth: 200 },
  { id: "pLTime", label: "Trial Time", minWidth: 200 },
  { id: "pL", label: "PL Approve", minWidth: 200 },
  { id: "engLead", label: "ENG Approve", minWidth: 200 },
  { id: "eng", label: "ENG", minWidth: 200 },
  { id: "pd1", label: "PD1", minWidth: 200 },
  { id: "pd2", label: "PD2", minWidth: 200 },
  { id: "pd", label: "PD", minWidth: 200 },
  { id: "pt", label: "PT", minWidth: 200 },
  { id: "qa", label: "QA", minWidth: 200 },
  { id: "mt", label: "MT", minWidth: 200 },
  { id: "fm", label: "FM Approve", minWidth: 200 }
]

const pageTitle = "Trial Tooling Log"

const TrialToolingLog: React.FC = () => {
  const theme = useTheme()
  const [factory, setFactory] = useState<string>("")
  const [selectDate, setSelectDate] = useState<Date>(new Date())
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(50)
  const [dbInfo, setDbInfo] = useState<ITrialToolingData[]>([])
  const { data: getTrialToolingData } = useQuery(GET_TRIAL_TOOLING_SHEET)

  useEffect(() => {
    if (!factory) {
      setDbInfo([])
    } else if (getTrialToolingData && factory && selectDate) {
      const filterData = getTrialToolingData?.getTrialToolingSheet.filter(
        (e: ITrialToolingData) => {
          return (
            e.factory === factory &&
            moment(selectDate).format("YYYY-MM") ===
              moment(e.trialDate).format("YYYY-MM")
          )
        }
      )

      setDbInfo(filterData)
    }
  }, [factory, selectDate])

  const Root = styled("div")(
    ({ theme }) => `
        table {
          font-family: IBM Plex Sans, sans-serif;
          font-size: 0.875rem;
          border-collapse: collapse;
          width: 100%;
        }
      
        td,
        th {
          border: 1px solid ${
            theme.palette.mode === "dark" ? red[50] : grey[900]
          };
          padding: 6px;
        }
      
        th {
          background-color: ${
            theme.palette.mode === "dark" ? grey[900] : grey[100]
          };
          font-weight:bold
        }
        `
  )

  const checkDivisionSignOnTable = (
    divisionKey: string,
    index: number
  ): boolean => {
    const changeSelectedDivisionsToObj = JSON.parse(
      dbInfo[index].selectedDivisions || "{}"
    )
    const selectedDivisionsKeyArray: string[] = Object.keys(
      changeSelectedDivisionsToObj
    )
    return selectedDivisionsKeyArray.includes(divisionKey)
  }

  const checkAllRequiredDivisionsSigned = (index: number): boolean => {
    for (const [key] of Object.entries(
      JSON.parse(dbInfo[index]?.selectedDivisions || "{}")
    )) {
      const grabKeyAndConvertItLikeSignature = Object.keys(signatureMap).find(
        (signatureKey) => signatureMap[signatureKey] === key
      )

      if (
        (grabKeyAndConvertItLikeSignature &&
          dbInfo[index] &&
          !dbInfo[index][
            grabKeyAndConvertItLikeSignature as keyof ITrialToolingData
          ]) ||
        !dbInfo[index]?.pJSignature ||
        !dbInfo[index]?.pLSignature ||
        !dbInfo[index]?.engineerHead1Signature ||
        !dbInfo[index]?.engineerHead2Signature
      ) {
        return false
      }
    }
    return true
  }

  const handleTrialNavigation = (index: number): string => {
    let panel: number
    if (!dbInfo[index].pLSignature) {
      panel = 1
    } else if (!dbInfo[index].engineerHead1Signature) {
      panel = 2
    } else if (!checkAllRequiredDivisionsSigned(index)) {
      panel = 3
    } else {
      panel = 4
    }

    return (window.location.href = `?factory=${dbInfo[index].factory}&date=${
      dbInfo[index].trialDate
    }&docNo=${dbInfo[index].controlNo}&panel=${panel}&dataId=${
      dbInfo[index].trialId
    }#${"/TrialToolingSheet"}`)
  }

  const dataList = dbInfo
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((e: ITrialToolingData, i: number) => {
      return (
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={page * rowsPerPage + i}
          sx={{ cursor: "pointer" }}
          onDoubleClick={() => handleTrialNavigation(page * rowsPerPage + i)}
        >
          <TableCell align="left">{e.recordNo}</TableCell>
          <TableCell align="left">{e.trialDate}</TableCell>
          <TableCell align="center">{e.factory}</TableCell>
          <TableCell align="left">{e.partNo}</TableCell>
          <TableCell align="left">{e.pLPlanDate}</TableCell>
          <TableCell align="left">
            {e.pLStartTime} - {e.pLEndTime} น.
          </TableCell>
          <TableCell align="center">
            {e.pLSignature ? (
              <CircleIcon sx={{ color: green[500] }} />
            ) : (
              <PanoramaFishEyeIcon color="disabled" />
            )}
          </TableCell>
          <TableCell align="center">
            {e.engineerHead1Signature ? (
              <CircleIcon sx={{ color: green[500] }} />
            ) : (
              <PanoramaFishEyeIcon color="disabled" />
            )}
          </TableCell>
          <TableCell align="center">
            {checkDivisionSignOnTable("ENG", page * rowsPerPage + i) ? (
              e.eNGSignature ? (
                <CircleIcon sx={{ color: green[500] }} />
              ) : (
                <PanoramaFishEyeIcon color="disabled" />
              )
            ) : (
              ""
            )}
          </TableCell>
          <TableCell align="center">
            {checkDivisionSignOnTable("PD1", page * rowsPerPage + i) ? (
              e.pD1Signature ? (
                <CircleIcon sx={{ color: green[500] }} />
              ) : (
                <PanoramaFishEyeIcon color="disabled" />
              )
            ) : (
              ""
            )}
          </TableCell>
          <TableCell align="center">
            {checkDivisionSignOnTable("PD2", page * rowsPerPage + i) ? (
              e.pD2Signature ? (
                <CircleIcon sx={{ color: green[500] }} />
              ) : (
                <PanoramaFishEyeIcon color="disabled" />
              )
            ) : (
              ""
            )}
          </TableCell>
          <TableCell align="center">
            {checkDivisionSignOnTable("PD", page * rowsPerPage + i) ? (
              e.pDSignature ? (
                <CircleIcon sx={{ color: green[500] }} />
              ) : (
                <PanoramaFishEyeIcon color="disabled" />
              )
            ) : (
              ""
            )}
          </TableCell>
          <TableCell align="center">
            {checkDivisionSignOnTable("PT", page * rowsPerPage + i) ? (
              e.pTSignature ? (
                <CircleIcon sx={{ color: green[500] }} />
              ) : (
                <PanoramaFishEyeIcon color="disabled" />
              )
            ) : (
              ""
            )}
          </TableCell>
          <TableCell align="center">
            {checkDivisionSignOnTable("QA", page * rowsPerPage + i) ? (
              e.qASignature ? (
                <CircleIcon sx={{ color: green[500] }} />
              ) : (
                <PanoramaFishEyeIcon color="disabled" />
              )
            ) : (
              ""
            )}
          </TableCell>
          <TableCell align="center">
            {checkDivisionSignOnTable("MT", page * rowsPerPage + i) ? (
              e.mTSignature ? (
                <CircleIcon sx={{ color: green[500] }} />
              ) : (
                <PanoramaFishEyeIcon color="disabled" />
              )
            ) : (
              ""
            )}
          </TableCell>
          <TableCell align="center">
            {e.fmSignature ? (
              <CircleIcon sx={{ color: green[500] }} />
            ) : (
              <PanoramaFishEyeIcon color="disabled" />
            )}
          </TableCell>
        </TableRow>
      )
    })

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <PageLabel
          menuItem={menuItemList.Engineer}
          menuItemName={Object.keys(menuItemList)[5]}
          menuPageName={pageTitle}
        />
        <ThemedCard>
          <PageLabelCard
            title="Trial Tooling Log"
            subTitle="Trial Tooling Log Report"
          />
          <Grid container spacing={2} width={"98%"} margin={2}>
            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <Autocomplete
                    value={factory}
                    freeSolo
                    onChange={(event, value) => {
                      setFactory(value as string)
                    }}
                    onInputChange={(event, freeHandInputValue) => {
                      setFactory(freeHandInputValue)
                    }}
                    options={["1", "2"]}
                    renderInput={(params) => (
                      <ThemedTextField
                        {...params}
                        label="Factory"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Select Month"
                      views={["year", "month"]}
                      value={selectDate}
                      onChange={(newSelectDate) => {
                        setSelectDate(moment(newSelectDate).toDate() as Date)
                      }}
                      PaperProps={{
                        sx: {
                          "& .MuiCalendarPicker-root": {
                            ".Mui-selected": {
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? theme.palette.warning.main
                                  : theme.palette.primary.main,
                              color: "#000000"
                            }
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <ThemedTextField
                          {...params}
                          fullWidth
                          sx={{
                            "& .Mui-selected": {
                              backgroundColor: theme.palette.warning.main
                            }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Divider
            sx={{ height: 1, bgcolor: theme.palette.secondary.contrastText }}
          />
          <br />
          <Root sx={{ width: "100%", marginTop: "30px" }}>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{dataList}</TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 20, 50]}
                component="div"
                count={dbInfo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Root>
        </ThemedCard>
      </Box>
    </ThemeProvider>
  )
}
export default TrialToolingLog
