import { Typography } from "@mui/material"
import { ITrialToolingData } from "./TrialToolingSheetPlanningApproval"
import { TrialToolingPaper } from "./TrialToolingSheetPlanningApproval"

interface IProp {
  filteredData?: ITrialToolingData
}
const TrialToolingSheetPeApproval: React.FC<IProp> = (props: IProp) => {
  const { filteredData } = props
  return (
    <>
      <Typography variant="h1" sx={{ fontSize: "50px", textAlign: "center", pb: "20px" }}>
        สำหรับแผนก Engineer ตรวจสอบและ Approve
      </Typography>
      <TrialToolingPaper filteredData={filteredData} />
    </>
  )
}

export default TrialToolingSheetPeApproval
