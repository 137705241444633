import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { bankImage, getImagePng, miniLogo } from "../../../common/utilities"
import { useMutation, useQuery } from "@apollo/client"
import { GET_ZINC_BARREL } from "../../../common/Query/PlatingQuery"
import { ReactElement, useEffect, useState } from "react"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"

import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import swal from "sweetalert"
import {
  ADD_ZINC_BARREL,
  DELETE_ZINC_BARREL,
  UPDATE_ZINC_BARREL
} from "../../../common/Mutation/PlatingMutation"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import PrintIcon from "@mui/icons-material/Print"
import { usePartNo } from "../../../common/Resources/customHook/usePartNo"
import { IPart } from "../../Master/Parts"
import { GET_PARTS } from "../../../common/Query/MasterQuery"

interface IReportZincBarrel {
  item: number
  customer: string
  model: string
  partNo: string
  partName: string
  wt: number
  adm: number
  spec: string
  kg: number
  pcs: number
  chromatting: string
  colour: string
  packing: string
  img: string
}

interface IPropsAddZincBarrel {
  setOpenAddZincBarrel: (boolean: boolean) => void
  openAddZincBarrel: boolean
}

interface IUpdateZincBarrel {
  setOpenUpdateZincBarrel: (boolean: boolean) => void
  openUpdateZincBarrel: boolean
}

interface IDeleteZincBarrel {
  setOpenDeleteZincBarrel: (boolean: boolean) => void
  openDeleteZincBarrel: boolean
}

interface Column {
  id:
    | "item"
    | "customer"
    | "model"
    | "partNo"
    | "partName"
    | "wt"
    | "adm"
    | "spec"
    | "kg"
    | "pcs"
    | "chromatting"
    | "colour"
    | "packing"
    | "img"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

const columns: Column[] = [
  { id: "item", label: "item", minWidth: 50 },
  { id: "customer", label: "Customer", minWidth: 50 },
  { id: "model", label: "Model", minWidth: 100 },
  { id: "partNo", label: "part No", minWidth: 50 },
  { id: "partName", label: "Part Name", minWidth: 100 },
  { id: "img", label: "img", minWidth: 100 },
  { id: "wt", label: "น้ำหนัก wt(g)", minWidth: 100 },
  { id: "adm", label: "พื้นที่ผิว A (dm2)", minWidth: 100 },
  { id: "spec", label: "Spec", minWidth: 100 },
  { id: "kg", label: "กก. ต่อโม่", minWidth: 50 },
  { id: "pcs", label: "ชิ้นต่อโม่", minWidth: 50 },
  { id: "chromatting", label: "chromatting", minWidth: 50 },
  { id: "colour", label: "colour", minWidth: 100 },
  { id: "packing", label: "Packing", minWidth: 50 }
]

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 29,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 30,
    fontFamily: "Sarabun",
    textAlign: "center"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },

  "td, th": {
    border: "1px solid black"
  }
}))

const UpdateZincBarrel: React.FC<IUpdateZincBarrel> = (
  props: IUpdateZincBarrel
): ReactElement => {
  const [updateZincBarrel, { loading }] = useMutation(UPDATE_ZINC_BARREL, {
    refetchQueries: [GET_ZINC_BARREL],
    awaitRefetchQueries: true
  })
  const { data } = useQuery(GET_ZINC_BARREL)

  const { openUpdateZincBarrel, setOpenUpdateZincBarrel } = props
  const [partNo, setPartNo] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [model, setModel] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [spec, setSpec] = useState<string>("")
  const [wt, setWt] = useState<number>()
  const [adm, setAdm] = useState<number>()
  const [kg, setKg] = useState<number>()
  const [pcs, setPcs] = useState<number>()
  const [colour, setColour] = useState<string>("รุ้ง (เงิน)")
  const [chromatting, setChromatting] = useState<string>("มิว-80")
  const [packing, setPacking] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  useEffect(() => {
    const zincBarrel = data?.masterBarrel?.find((entry: { partNo: string }) => {
      return entry.partNo === partNo
    })
    if (zincBarrel) {
      setPartName(zincBarrel.partName)
      setPartNo(zincBarrel.partNo)
      setPacking(zincBarrel.packing)
      setSpec(zincBarrel.spec)
      setCustomer(zincBarrel.customer)
      setModel(zincBarrel.model)
      setAdm(zincBarrel.adm)
      setWt(zincBarrel.wt)
      setPcs(zincBarrel.pcs)
      setKg(zincBarrel.kg)
      setChromatting(zincBarrel.chromatting)
      setColour(zincBarrel.colour)
    }
    if (!partNo) handleReset()
  }, [partNo])

  const zincBarrelList = data
    ? data?.masterBarrel?.map(({ partNo }: { partNo: string }) => partNo)
    : []

  const handleReset = () => {
    setPartName("")
    setPartNo("")
    setPacking("")
    setSpec("")
    setCustomer("")
    setModel("")
    setWt(0)
    setAdm(0)
    setPcs(0)
    setKg(0)
  }

  const handleSubmit = async () => {
    try {
      await updateZincBarrel({
        variables: {
          partNo,
          partName,
          model,
          customer,
          spec,
          wt,
          adm,
          kg,
          pcs,
          colour,
          chromatting,
          packing
        }
      })
      swal("Success", "Updater Success", "success")
      setOpenUpdateZincBarrel(false)
    } catch (err) {
      setError(true)
      swal("Warning", `กรุณาใส่ MatNo`, "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openUpdateZincBarrel}
        onClose={() => setOpenUpdateZincBarrel(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Update Zinc Barrel
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item sm={12} xs={12}>
              <Autocomplete
                id="part no"
                value={partNo ? partNo : ""}
                options={zincBarrelList}
                style={{ width: "100%" }}
                sx={{ width: 100 }}
                loading={!!zincBarrelList}
                onChange={(e, newValue) => setPartNo(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select partNo"
                    error={!partNo && error}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setPartName(e.target.value)}
                value={partName ? partName : ""}
                id="part name"
                autoComplete="family-name"
                label="Part Name"
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setCustomer(e.target.value)}
                value={customer ? customer : ""}
                id="customer"
                label="Customer"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                onChange={(e) => setSpec(e.target.value)}
                value={spec ? spec : ""}
                id="spec"
                label="Spec"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <ThemedTextField
                fullWidth
                label="Model"
                onChange={(e) => setModel(e.target.value)}
                value={model ? model : ""}
                id="sizeL"
                autoComplete="family-name"
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <ThemedTextField
                label="น้ำหนัก (กรัม)"
                fullWidth
                value={wt ? wt : ""}
                type="number"
                inputProps={{
                  minLength: 0,
                  step: "0.1"
                }}
                onChange={(e) => setWt(parseFloat(e.target.value))}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                fullWidth
                label="พื้นที่ผิว"
                onChange={(e) => setAdm(parseFloat(e.target.value))}
                value={adm ? adm : ""}
                id="adm"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                label="กก. ต่อโม่"
                onChange={(e) => setKg(parseFloat(e.target.value))}
                value={kg ? kg : ""}
                id="kg"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                fullWidth
                label="ชิ้นต่อโม่"
                onChange={(e) => setPcs(parseFloat(e.target.value))}
                value={pcs ? pcs : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                fullWidth
                label="สีที่ใช้ Chromatting"
                onChange={(e) => setChromatting(e.target.value)}
                value={chromatting ? chromatting : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                fullWidth
                label="colour"
                onChange={(e) => setColour(e.target.value)}
                value={colour ? colour : ""}
                id="colour"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ThemedTextField
                fullWidth
                label="packing"
                onChange={(e) => setPacking(e.target.value)}
                value={packing ? packing : ""}
                id="pcs"
                autoComplete="family-name"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenUpdateZincBarrel(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const AddNewZincBarrel: React.FC<IPropsAddZincBarrel> = (
  props: IPropsAddZincBarrel
): ReactElement => {
  const [addZincBarrel, { loading }] = useMutation(ADD_ZINC_BARREL, {
    refetchQueries: [GET_ZINC_BARREL],
    awaitRefetchQueries: true
  })
  const { setOpenAddZincBarrel, openAddZincBarrel } = props
  const [partNo, setPartNo] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [model, setModel] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [spec, setSpec] = useState<string>("")
  const [wt, setWt] = useState<number>()
  const [adm, setAdm] = useState<number>()
  const [kg, setKg] = useState<number>()
  const [pcs, setPcs] = useState<number>()
  const [colour, setColour] = useState<string>("รุ้ง (เงิน)")
  const [chromatting, setChromatting] = useState<string>("มิว-80")
  const [packing, setPacking] = useState<string>("")
  const [error, setError] = useState<boolean>(false)

  const partNoList = usePartNo()
  const { data: partNoData } = useQuery(GET_PARTS, {
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    if (!partNo) {
      handleReset()
    } else if (partNoData) {
      const part = partNoData.parts.find(
        (part: IPart) => part.partNo === partNo
      )
      setPartName(part.partName)
      setCustomer(part.customer)
      setModel(part.model)
    }
  }, [partNo])

  const isComplete = () => {
    return (
      partNo &&
      partName &&
      customer &&
      spec &&
      wt &&
      adm &&
      kg &&
      pcs &&
      colour &&
      packing &&
      model &&
      chromatting
    )
  }

  const theme = useTheme()

  const handleReset = () => {
    setPartName("")
    setPartNo("")
    setCustomer("")
    setModel("")
    setPacking("")
    setSpec("")
    setWt(0)
    setAdm(0)
    setPcs(0)
    setKg(0)
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    if (
      !partNo &&
      !partName &&
      !customer &&
      !spec &&
      !wt &&
      !adm &&
      !kg &&
      !pcs &&
      !colour &&
      !packing &&
      !model &&
      !chromatting
    ) {
      setError(true)
      return swal("Warning", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
    e.preventDefault()
    if (!isComplete()) {
      setError(true)
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }

    try {
      await addZincBarrel({
        variables: {
          partNo,
          partName,
          model,
          customer,
          spec,
          wt,
          adm,
          kg,
          pcs,
          colour,
          chromatting,
          packing
        }
      })
      swal("Success", "Add Part Successful", "success")
      handleReset()
      setOpenAddZincBarrel(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={openAddZincBarrel}
        onClose={() => setOpenAddZincBarrel(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="text-material">
          Add New List Zinc Barrel
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                id="select-part-no"
                value={partNo ? partNo : ""}
                options={partNoList}
                loading={!!partNoList}
                onChange={(e, newValue) => setPartNo(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Part No"
                    error={!partNo && error}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                value={partName}
                error={!partName && error}
                label={"Part Name"}
                onChange={(event) => setPartName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                value={customer}
                error={!customer && error}
                label={"Customer"}
                onChange={(event) => setCustomer(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                value={spec}
                error={!spec && error}
                label={"Spec."}
                onChange={(event) => setSpec(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemedTextField
                fullWidth
                value={model}
                error={!model && error}
                label={"model"}
                onChange={(event) => setModel(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemedTextField
                fullWidth
                type="number"
                value={wt}
                error={!wt && error}
                label={"น้ำหนัก (กรัม)"}
                onChange={(e) => setWt(parseFloat(e.target.value))}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemedTextField
                fullWidth
                type="number"
                value={adm}
                error={!adm && error}
                label={"พื้นที่ผิว"}
                onChange={(e) => setAdm(parseFloat(e.target.value))}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemedTextField
                fullWidth
                type="number"
                value={kg}
                error={!kg && error}
                label={"กก. ต่อโม่"}
                onChange={(e) => setKg(parseFloat(e.target.value))}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemedTextField
                fullWidth
                type="number"
                value={pcs}
                error={!pcs && error}
                label={"ชิ้นต่อโม่"}
                onChange={(e) => setPcs(parseFloat(e.target.value))}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemedTextField
                fullWidth
                value={chromatting}
                error={!chromatting && error}
                label={"สีที่ใช้ Chromatting"}
                onChange={(e) => setChromatting(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemedTextField
                fullWidth
                value={colour}
                error={!colour && error}
                label={"color"}
                onChange={(e) => setColour(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemedTextField
                fullWidth
                value={packing}
                error={!packing && error}
                label={"Packing"}
                onChange={(e) => setPacking(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginLeft: "16px",
              borderRadius: "5px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            sx={{
              backgroundColor: theme.palette.error.main,
              "&:hover": { backgroundColor: theme.palette.error.dark }
            }}
            variant="text"
            onClick={() => handleReset()}
          >
            RESET
          </ThemedButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenAddZincBarrel(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

const DeleteZincBarrel: React.FC<IDeleteZincBarrel> = (
  props: IDeleteZincBarrel
) => {
  const [deleteZinc, { loading }] = useMutation(DELETE_ZINC_BARREL, {
    refetchQueries: [GET_ZINC_BARREL],
    awaitRefetchQueries: true
  })
  const { data } = useQuery(GET_ZINC_BARREL)

  const { openDeleteZincBarrel, setOpenDeleteZincBarrel } = props
  const [partNo, setPartNo] = useState<string>("")
  const [error, setError] = useState<boolean>(false)

  const zincBarrelList = data
    ? data?.masterBarrel?.map(({ partNo }: { partNo: string }) => partNo)
    : []

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!partNo) {
      setError(true)
    }
    try {
      await deleteZinc({
        variables: { partNo }
      })
      swal("Success", "Delete Successful", "success")
      setOpenDeleteZincBarrel(false)
    } catch (error) {
      swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
  }

  return (
    <>
      <Dialog
        open={openDeleteZincBarrel}
        onClose={() => setOpenDeleteZincBarrel(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Sarabun" }}>
          Delete Zinc Barrel
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} marginTop={2}>
            <Grid item sm={12} xs={12}>
              <Autocomplete
                id="part no"
                value={partNo ? partNo : ""}
                options={zincBarrelList}
                style={{ width: "100%" }}
                sx={{ width: 100 }}
                loading={!!zincBarrelList}
                onChange={(e, newValue) => setPartNo(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Select partNo"
                    error={!partNo && error}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              borderRadius: "5px",
              marginLeft: "16px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton
            contrast
            variant="text"
            onClick={() => setOpenDeleteZincBarrel(false)}
          >
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const ZincBarrel: React.FC = () => {
  const { data } = useQuery(GET_ZINC_BARREL)
  const [search, setSearch] = useState<string>("")
  const [openAddZincBarrel, setOpenAddZincBarrel] = useState<boolean>(false)
  const [openUpdateZincBarrel, setOpenUpdateZincBarrel] =
    useState<boolean>(false)
  const [openDeleteZincBarrel, setOpenDeleteZincBarrel] =
    useState<boolean>(false)

  const partListSearch =
    data?.masterBarrel?.map((part: IReportZincBarrel) => part.partNo) || []

  const theme = useTheme()

  let rows: IReportZincBarrel[] = []

  if (data) {
    rows = data.masterBarrel
      .map((part: IReportZincBarrel, idx: number) => {
        return { ...part, item: idx + 1 }
      })
      .filter((part: IReportZincBarrel) => {
        if (search) return part.partNo === search
        else return part
      })
  }
  return (
    <ThemeProvider theme={theme}>
      <AddNewZincBarrel
        setOpenAddZincBarrel={setOpenAddZincBarrel}
        openAddZincBarrel={openAddZincBarrel}
      />
      <UpdateZincBarrel
        openUpdateZincBarrel={openUpdateZincBarrel}
        setOpenUpdateZincBarrel={setOpenUpdateZincBarrel}
      />
      <DeleteZincBarrel
        openDeleteZincBarrel={openDeleteZincBarrel}
        setOpenDeleteZincBarrel={setOpenDeleteZincBarrel}
      />
      <div className="none-display">
        <Grid container spacing={2} width={"98%"} padding={2}>
          <Grid item xs={3} sm={3}>
            <Autocomplete
              id="search"
              freeSolo
              value={search}
              options={partListSearch}
              style={{ width: "100%", marginLeft: "-15px" }}
              sx={{ width: 100 }}
              onChange={(e, newValue) => setSearch(newValue ? newValue : "")}
              renderInput={(params) => (
                <ThemedTextField
                  style={{ marginLeft: "15px" }}
                  {...params}
                  label="Search"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={9}
            sm={9}
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <ThemedButton
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenAddZincBarrel(true)}
              >
                ADD Zinc Barrel
              </ThemedButton>
            </Grid>
            <Grid item>
              <ThemedButton
                contrast
                variant="contained"
                startIcon={<EditIcon />}
                onClick={() => setOpenUpdateZincBarrel(true)}
              >
                Edit Zinc Barrel
              </ThemedButton>
            </Grid>
            <Grid item>
              <ThemedButton
                contrast
                color="error"
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => setOpenDeleteZincBarrel(true)}
              >
                DELETE Zinc Barrel
              </ThemedButton>
            </Grid>
            <Grid item>
              <ThemedButton
                variant="contained"
                endIcon={<PrintIcon />}
                onClick={() => window.print()}
              >
                Print
              </ThemedButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Table sx={{ zoom: "50%" }} className="bg-plating plating-absolute">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell colSpan={1}>
              <img src={miniLogo} alt="" width={"100px"} />
            </StyledTableCell>
            <StyledTableCell colSpan={22}>Zinc Barrel Plating</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.id} align={column.align}>
                {column.label}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            return (
              <StyledTableRow hover tabIndex={-1} key={i}>
                {columns.map((column) => {
                  const value = row[column.id]
                  return (
                    <StyledTableCell key={column.id} align={column.align}>
                      {column.id === "img" ? (
                        <img
                          src={getImagePng(`images/parts/${row.partNo}`)}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = bankImage
                          }}
                          width={80}
                          height={80}
                          alt=""
                        ></img>
                      ) : column.format && typeof value === "number" ? (
                        column.format(value)
                      ) : (
                        value
                      )}
                    </StyledTableCell>
                  )
                })}
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </ThemeProvider>
  )
}

export default ZincBarrel
