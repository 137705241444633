import { CardHeader, useTheme } from "@mui/material"
import "../../css/Dashboard/Dashboard.css"
interface INewPageLayoutProps {
  title?: string
  subTitle?: string
  staticColor?: boolean
}
export const PageLabelCard: React.FC<INewPageLayoutProps> = (
  props: React.PropsWithChildren<INewPageLayoutProps>
) => {
  const { title, subTitle, staticColor = false } = props
  const theme = useTheme()

  return (
    <div className="display-none-print">
      <CardHeader
        titleTypographyProps={{
          variant: "h4",
          fontFamily: "Sarabun",
          color: staticColor ? "black" : theme.palette.secondary.contrastText
        }}
        title={title}
        subheaderTypographyProps={{
          fontFamily: "Sarabun",
          color: staticColor ? "black" : theme.palette.secondary.contrastText
        }}
        subheader={subTitle}
        style={{ paddingLeft: "20px" }}
      />
    </div>
  )
}
