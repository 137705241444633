import {
  Autocomplete,
  Box,
  CardHeader,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  styled,
  useTheme
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import { useState } from "react"
import { grey } from "@mui/material/colors"
import { useLazyQuery } from "@apollo/client"
import { useQuery } from "@apollo/client"
import { GET_DEPARTMENT } from "../../common/Query/MasterQuery"
import { IRequestData } from "./component/RepairPerson"
import {
  GET_MACHINES_MT,
  GET_REPAIRER,
  GET_REPAIR_AUDIT,
  GET_REPAIR_REQUEST
} from "../../common/Query/MaintenanceQuery"
import moment from "moment"
import { IRepairer } from "./component/Repairer"
import { conditionLinePress, subtractDate } from "../../common/utilities"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"

const pageTitle = "BM Report"
export interface IDataTable {
  no: string
  actionDate: string
  recordNo: number
  machineCode: string
  repairType: string
  detail: string
  completeDate: string
  cause: string
  correction: string
  startDate: string
  actualTime: number
  informer: string
  receiver: string
  remarks: string
  department: string
  factory: string
}
interface Column {
  id:
    | "no"
    | "machineCode"
    | "actionDate"
    | "recordNo"
    | "repairType"
    | "cause"
    | "correction"
    | "startDate"
    | "actualTime"
    | "completeDate"
    | "informer"
    | "receiver"
    | "remarks"
    | "department"
    | "factory"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}
const columns: Column[] = [
  { id: "no", label: "No", minWidth: 200 },
  { id: "actionDate", label: "ActionDate", minWidth: 200 },
  { id: "department", label: "Department", minWidth: 200 },
  { id: "factory", label: "Factory", minWidth: 200 },
  { id: "machineCode", label: "Machine Code", minWidth: 200 },
  { id: "recordNo", label: "RecordNo", minWidth: 200 },
  { id: "repairType", label: "Repair Type", minWidth: 200 },
  { id: "cause", label: "Cause", minWidth: 200 },
  { id: "correction", label: "Correction", minWidth: 200 },
  { id: "startDate", label: "StartDate", minWidth: 200 },
  { id: "completeDate", label: "CompleteDate", minWidth: 200 },
  { id: "actualTime", label: "ActualTime", minWidth: 200 },
  { id: "informer", label: "Informer", minWidth: 200 },
  { id: "receiver", label: "Repairer Name", minWidth: 200 },
  { id: "remarks", label: "Remarks", minWidth: 200 }
]
const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[100]};
    font-weight:bold
  }
  `
)
const RepairRecord: React.FC = () => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [department, setDepartment] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [line, setLine] = useState<string>("")
  const getDepartment = useQuery(GET_DEPARTMENT)
  const { data: getMachine } = useQuery(GET_MACHINES_MT)
  const [page, setPage] = useState<number>(0)
  // const [dataBreakDown, setDataBreakDown] = useState<IDataTable>()
  // const [openDialogWip, setOpenDialogWip] = useState<boolean>(false)
  const departmentList: string[] =
    getDepartment.data?.department.map(
      (e: { departmentName: string }) => e.departmentName
    ) || []
  const [
    getRepairFrom,
    { data: repairRequestData, loading: repairRequestLoading }
  ] = useLazyQuery(GET_REPAIR_REQUEST)
  const { data: repairAuditData } = useQuery(GET_REPAIR_AUDIT)
  const [getRepairer, { data: repairerQuery }] = useLazyQuery(GET_REPAIRER)
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleClickBreakDown = (
    department: string,
    date: string,
    no: string,
    factory: string,
    link: string
  ) => {
    return (window.location.href = `?department=${department}&no=${
      no.split("/")[1]
    }&date=${date}&factory=${factory}#${link}`)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleSubmit = async () => {
    await getRepairFrom({
      variables: {
        dateFrom,
        dateTo,
        department,
        factory
      },
      fetchPolicy: "network-only"
    })
    await getRepairer({
      variables: {
        department,
        factory
      },
      fetchPolicy: "network-only"
    })
  }
  const theme = useTheme()
  const monthList = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12"
  ]
  const rows: IDataTable[] =
    repairRequestData?.repairRequestFrom
      .filter(
        (filterData: { code: string; approveBy: string; factory: string }) => {
          const machine = getMachine.machinesMt.find(
            (e: { code: string }) => e.code === filterData.code
          )
          if (line === "PRESS" && factory)
            return (
              conditionLinePress(machine?.line) &&
              filterData?.factory === factory &&
              filterData.approveBy
            )
          if (line === "SPOT" && factory)
            return (
              machine?.line === "LINE SPOT" &&
              filterData?.factory === factory &&
              filterData.approveBy
            )
          if (line === "ARC WELDING" && factory)
            return (
              machine?.line === "ARC WELDING" &&
              filterData?.factory === factory &&
              filterData.approveBy
            )
          if (factory) return filterData?.factory === factory
          else {
            return filterData.approveBy
          }
        }
      )
      .map((data: IRequestData, i: number) => {
        const repairer: IRepairer = repairerQuery?.getRepairerData.find(
          (repairer: IRepairer) =>
            repairer.docNo === data.recordNo &&
            data.department === repairer.department &&
            repairer.factory === data.factory &&
            repairer.detail === data.detail &&
            moment(repairer.requestDate).format("YYYY-MM-DD") ===
              data.actionDate
        )
        const repairAudit = repairAuditData?.getRepairAuditor.find(
          (e: { recordNo: number; factory: string; actionDate: string }) =>
            e.recordNo === Number(data.recordNo) &&
            e.factory === data.factory &&
            e.actionDate === data.actionDate
        )

        const waitTime = subtractDate(
          new Date(
            repairer?.endWaitRepair.split(" ")[0] +
              " " +
              moment(new Date(repairer?.endWaitRepair).getTime()).format(
                "HH:mm"
              )
          ),
          new Date(
            repairer?.waitRepair.split(" ")[0] +
              " " +
              moment(new Date(repairer?.waitRepair).getTime()).format("HH:mm")
          )
        )
        console.log(repairer?.endWaitRepair)
        console.log(repairer?.waitRepair)
        console.log(repairer?.completeDate)
        console.log(repairer?.startRepair)

        const repairTime = subtractDate(
          new Date(
            repairer?.completeDate.split(" ")[0] +
              " " +
              moment(new Date(repairer?.completeDate).getTime()).format("HH:mm")
          ),
          new Date(
            repairer?.startRepair.split(" ")[0] +
              " " +
              moment(new Date(repairer?.startRepair).getTime()).format("HH:mm")
          )
        )
        const diff = Math.abs(
          Number(new Date(waitTime.date)) - Number(new Date(repairTime.date))
        )

        const actualTime = diff / 1000 / 60
        const month = new Date(data.actionDate).getMonth()
        const correctionList = JSON.parse(repairer?.correctionList || "[{}]")

        return {
          ...data,
          no: i + 1,
          recordNo: monthList[month] + "/" + data.recordNo,
          machineCode: data.code,
          informer: data.requestBy,
          startDate: repairer?.startRepair.split(" ")[0],
          repairType: data?.workType,
          actualTime: Number(actualTime.toFixed(2)) || 0,
          cause: data.detail,
          correction: correctionList
            .map((data: { correction: string }) => data.correction)
            .join(","),
          completeDate: moment(repairer?.completeDate).format("YYYY-MM-DD"),
          receiver: repairer?.repairMan,
          remarks: repairAudit ? "close" : "",
          factory: data.factory
        }
      }) || []
  const totalTime = rows.reduce((prev: number, cur: IDataTable) => {
    return prev + cur.actualTime
  }, 0)
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Maintenance}
        menuItemName={Object.keys(menuItemList)[12]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <CardHeader
          titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
          title="Repair Record Sheet"
          subheaderTypographyProps={{ fontFamily: "Sarabun" }}
        />
        <div style={{ margin: "20px" }}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3}>
                <ThemedTextField
                  fullWidth
                  label="Date From."
                  value={dateFrom}
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => setDateFrom(e.target.value)}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <ThemedTextField
                  fullWidth
                  label="Date To."
                  value={dateTo}
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => setDateTo(e.target.value)}
                ></ThemedTextField>
              </Grid>
              <Grid item sm={3} xs={3}>
                <Autocomplete
                  value={department}
                  fullWidth
                  onChange={(event, selectedDepartment) => {
                    setDepartment(selectedDepartment as string)
                  }}
                  options={departmentList}
                  renderInput={(params) => (
                    <ThemedTextField {...params} label="Department" />
                  )}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <ThemedLoadingButton
                  variant="contained"
                  sx={{
                    mt: 1,
                    mb: 1,
                    backgroundColor: theme.palette.primary.main
                  }}
                  onClick={handleSubmit}
                  loading={repairRequestLoading}
                >
                  Submit
                </ThemedLoadingButton>
              </Grid>
              <Grid item sm={3} xs={3}>
                <ThemedTextField
                  id="factory"
                  fullWidth
                  label="Factory No."
                  value={factory}
                  onChange={(e) => setFactory(e.target.value)}
                  select
                >
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                </ThemedTextField>
              </Grid>
              <Grid item sm={3} xs={3}>
                <Autocomplete
                  value={line}
                  fullWidth
                  onChange={(event, selectedDepartment) => {
                    setLine(selectedDepartment as string)
                  }}
                  options={["PRESS", "SPOT", "ARC WELDING"]}
                  renderInput={(params) => (
                    <ThemedTextField {...params} label="Line" />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Root sx={{ width: "100%", marginTop: "30px" }}>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      ใบบันทึกการเเจ้งซ่อม
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      ประจำเดือน {dateFrom} - {dateTo}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={i}
                          onDoubleClick={() =>
                            handleClickBreakDown(
                              row.department,
                              row.actionDate,
                              row.recordNo.toString(),
                              row.factory,
                              "/RepairDocument"
                            )
                          }
                        >
                          {columns.map((column) => {
                            const value = row[column.id]
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                </TableBody>
                <TableRow>
                  <TableCell colSpan={8} align="right">
                    Total Time
                  </TableCell>
                  <TableCell colSpan={4} align="left">
                    {totalTime}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={8} align="right">
                    Total Repair Quantity
                  </TableCell>
                  <TableCell colSpan={4} align="left">
                    {rows.length}
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Root>
        </div>
      </ThemedCard>
    </ThemeProvider>
  )
}
export default RepairRecord
