import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import {
  Grid,
  Autocomplete,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  useTheme,
  ThemeProvider
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import PrintIcon from "@mui/icons-material/Print"
import { styled } from "@mui/material/styles"
import CircularProgress from "@mui/material/CircularProgress"
import moment from "moment"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import {
  gql,
  useQuery,
  LazyQueryResult,
  QueryLazyOptions,
  useLazyQuery
} from "@apollo/client"
import "../../css/Logistics/LogisticsCSS.css"
import { GET_QC_FINISHED_GOODS } from "../../common/Query/QCQuery"
import "../../css/QC/QC.css"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import { getImagePng, miniLogo } from "../../common/utilities"
import { GET_CUSTOMERS } from "../../common/Query/MasterQuery"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

interface PartNoList {
  parts: PartNo[]
}

interface PartNo {
  partNo: string
}

interface QCFinishedGoodsQueryArguments {
  dateFrom: string
  dateTo: string
  status: string
  factory: string
  partNo: string
}

interface ISearchQCFinishedGoodsProps {
  startDate: Date
  endDate: Date
  factory: string
  partNo: string
  type: string
  customer: string
  ngType: string
  partNoList: string[]
  printMode: boolean
  setStartDate: Dispatch<SetStateAction<Date>>
  setEndDate: Dispatch<SetStateAction<Date>>
  setFactory: Dispatch<SetStateAction<string>>
  setPartNo: Dispatch<SetStateAction<string>>
  setType: Dispatch<SetStateAction<string>>
  setNgType: Dispatch<SetStateAction<string>>
  setCustomer: Dispatch<SetStateAction<string>>
  setPrintMode: Dispatch<SetStateAction<boolean>>
  getQcFinishedGoods: (
    options?: QueryLazyOptions<QCFinishedGoodsQueryArguments> | undefined
  ) => Promise<
    LazyQueryResult<QCFinishedGoodsDataList, QCFinishedGoodsQueryArguments>
  >
}

interface NgTypeInfo {
  name: string
  dataKey: string
  ngTypeInfoHeader: string[]
  ngTypeInfoKey: string[]
}

interface NgType {
  [key: string]: NgTypeInfo
}

interface IQCFinishedGoodsBodyProps {
  qcFinishedGoodsData: QCFinishedGoodsDataList | undefined
  qcFinishedGoodsLoading: boolean
  qcFinishedGoodsCalled: boolean
  ngType: string
  partNo: string
  status: string
  customer: string
}

interface QCFinishedGoodsDataList {
  qcFinishGoodsReport: QCFinishedGoodsData[]
}

interface QCFinishedGoodsData {
  date: Date
  pdLotNo: string
  jobOrder: string
  partNo: string
  partName: string
  customer: string
  stamp: number
  weld: number
  arcweld: number
  plate: number
  quantity: number
  okQuantity: number
  scratched: number
  deformed: number
  torn: number
  dirtyStains: number
  rusted: number
  fin: number
  incorrectStampSize: number
  skipProcessStamp: number
  otherStamp: number
  offset: number
  incorrectWeldPosition: number
  incorrectWeldSize: number
  looseWelding: number
  noWelding: number
  skipProcessWeld: number
  otherWeld: number
  loosePlating: number
  discoloredPlating: number
  otherPlate: number
  oneSidedWeld: number
  twiceWelded: number
  puncturedWeld: number
  messyWeld: number
  incompleteWeld: number
  deformedArcweld: number
  otherArcweld: number
  totalStamp: number
  totalWeld: number
  totalPlate: number
  totalArcWeld: number
  operator: string
  remarks: string
  factory: string
  process: string
  approveStatus: aprStatus
}
interface aprStatus {
  apr: string
  checkout: string
}

const StyledTableCell = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  textAlign: "center",
  borderBottom: "1px",
  border: "solid 1px black"
}))

const StyledTableCellBold = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  textAlign: "center",
  borderBottom: "1px",
  fontWeight: "bold",
  border: "solid 1px black"
}))

const StyledTableCellVertical = styled(TableCell)(() => ({
  backgroundColor: "white",
  color: "black",
  textAlign: "left",
  borderBottom: "1px",
  writingMode: "vertical-lr",
  transform: "rotate(180deg)",
  fontWeight: "bold",
  border: "solid 1px black"
}))

export const StyledTableRow = styled(TableRow)(() => ({
  borderBottom: "1px",
  "&:last-child td": { borderBottom: "1px solid black" }
}))

export const GET_PARTNO = gql`
  query Parts {
    parts {
      partNo
    }
  }
`

const pageTitle = "QC Finished Goods"

//english keys are needed due to Apollo query limitations
const ngTypeInfoList: NgType = {
  Stamping: {
    name: "Stamping",
    dataKey: "stamp",
    ngTypeInfoHeader: [
      "เป็นรอย",
      "เสียรูป",
      "ขาดแหว่ง",
      "สนิม",
      "ครีบ",
      "ขนาดไม้ได้",
      "คราบสกปรก",
      "ข้ามprocessStamp",
      "อื่นStamp",
      "totalStamp"
    ],
    ngTypeInfoKey: [
      "scratched",
      "deformed",
      "torn",
      "rusted",
      "fin",
      "incorrectStampSize",
      "dirtyStains",
      "skipProcessStamp",
      "otherStamp",
      "totalStamp"
    ]
  },
  Welding: {
    name: "Welding",
    dataKey: "weld",
    ngTypeInfoHeader: [
      "offset",
      "ผิดตำแหน่ง",
      "ผิดขนาด",
      "ไม่ติด",
      "noWelding",
      "",
      "",
      "ข้ามprocessWeld",
      "อื่นWeld",
      "totalWeld"
    ],
    ngTypeInfoKey: [
      "offset",
      "incorrectWeldPosition",
      "incorrectWeldSize",
      "looseWelding",
      "noWelding",
      "",
      "",
      "skipProcessWeld",
      "otherWeld",
      "totalWeld"
    ]
  },
  Plating: {
    name: "Plating",
    dataKey: "plate",
    ngTypeInfoHeader: [
      "ชุบไม่ติด",
      "สีพอง",
      "",
      "",
      "",
      "",
      "",
      "",
      "อื่นPlate",
      "totalPlate"
    ],
    ngTypeInfoKey: [
      "loosePlating",
      "discoloredPlating",
      "",
      "",
      "",
      "",
      "",
      "",
      "otherPlate",
      "totalPlate"
    ]
  },
  "Arc Welding": {
    name: "Arc Welding",
    dataKey: "arcweld",
    ngTypeInfoHeader: [
      "เชื่อมไม่ต่อเนื่อง",
      "เชื่อมติดด้านเดียว",
      "เชื่อม2รอบ",
      "เชื่อมทะลุ",
      "เชื่อมเป็นตามด",
      "เชื่อมไม่เต็ม",
      "ลืมปิดCO2",
      "ชิ้นงานเสียรูป",
      "อื่นArcWeld",
      "totalArcWeld"
    ],
    ngTypeInfoKey: [
      "discontinuousWeld",
      "oneSidedWeld",
      "twiceWelded",
      "puncturedWeld",
      "messyWeld",
      "incompleteWeld",
      "forgetToTurnOffCO2",
      "deformedArcweld",
      "otherArcweld",
      "totalArcWeld"
    ]
  }
}

const calculateQcFinishedGoodsTotal = (
  filteredList: QCFinishedGoodsData[],
  ngTypeKey: string
): number | string => {
  if (!filteredList.length) return ""
  const total: number = filteredList.reduce(
    (previousVal: number, currObject: QCFinishedGoodsData) => {
      return (
        previousVal +
        (currObject[ngTypeKey as keyof QCFinishedGoodsData] as number)
      )
    },
    0
  )
  return isNaN(total) ? "" : total
}

export const SearchQCFinishedGoods = (
  props: ISearchQCFinishedGoodsProps
): JSX.Element => {
  const {
    startDate,
    endDate,
    factory,
    partNo,
    type,
    ngType,
    partNoList,
    customer,
    setStartDate,
    setEndDate,
    setFactory,
    setPartNo,
    setType,
    setNgType,
    getQcFinishedGoods,
    setCustomer
  } = props
  const typeList = ["MATERIAL", "PART"]
  const factoryList = ["1", "2"]
  const ngTypeList = ["Stamping", "Welding", "Plating", "Arc Welding"]
  const { data } = useQuery(GET_CUSTOMERS)
  const theme = useTheme()
  const [error, setError] = useState<boolean>(false)

  const handleSearch = (event: React.FormEvent) => {
    if (!factory || !type) {
      setError(true)
      return
    }
    event.preventDefault()
    getQcFinishedGoods({
      variables: {
        dateFrom: moment(startDate).format("YYYY-MM-DD"),
        dateTo: moment(endDate).format("YYYY-MM-DD"),
        status: "Final",
        factory,
        partNo
      }
    })
  }

  const { customers } = data ? data : { customers: [] }

  const customerList = customers.map(
    ({ customer }: { customer: string }) => customer
  )

  return (
    <ThemeProvider theme={theme}>
      <div className="hidden-print">
        <PageLabelCard
          title="QC Finished Goods"
          subTitle="detail about QC Finished Goods"
        />
      </div>
      <form onSubmit={handleSearch}>
        <Grid container spacing={2} width={"98%"} margin={2}>
          <Grid item xs={12} sm={2}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                openTo="day"
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                aria-label="date-from"
                value={startDate}
                onChange={(newStartDate) => {
                  setStartDate(moment(newStartDate).toDate() as Date)
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    fullWidth
                    label="Date From"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={2}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                openTo="day"
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                aria-label="date-to"
                value={endDate}
                onChange={(newEndDate) => {
                  setEndDate(moment(newEndDate).toDate() as Date)
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    fullWidth
                    label="Date To"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              fullWidth
              value={factory}
              disableClearable
              disablePortal
              aria-label="select-factory"
              onChange={(event, selectedFactory) => {
                setFactory(selectedFactory as string)
              }}
              options={factoryList}
              renderInput={(params) => (
                <ThemedTextField
                  required
                  error={!factory && error}
                  {...params}
                  label="Factory"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              fullWidth
              value={type}
              disablePortal
              aria-label="select-type"
              onChange={(event, selectedType) => {
                setType(selectedType as string)
              }}
              options={typeList}
              renderInput={(params) => (
                <ThemedTextField
                  required
                  error={!type && error}
                  {...params}
                  label="Type"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              fullWidth
              value={ngType}
              disablePortal
              aria-label="select-ng-type"
              onChange={(event, selectedNgType) => {
                setNgType(selectedNgType as string)
              }}
              options={ngTypeList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="NG Type"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              loading={partNoList.length === 0}
              value={partNo}
              disablePortal
              aria-label="select-partno"
              onChange={(event, selectedPartNo) => {
                setPartNo(selectedPartNo as string)
              }}
              options={partNoList}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Part No."
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Autocomplete
              id="search"
              freeSolo
              value={customer}
              options={customerList}
              style={{ width: "100%", fontFamily: "Sarabun" }}
              sx={{ width: 100 }}
              onChange={(e, newValue) => setCustomer(newValue ? newValue : "")}
              renderInput={(params) => (
                <ThemedTextField
                  style={{ fontFamily: "Sarabun" }}
                  {...params}
                  label="Customer"
                />
              )}
            />
          </Grid>

          <Grid container item xs={12} spacing={2} className="hidden-print">
            <Grid item>
              <ThemedButton
                type="submit"
                variant="contained"
                aria-label="search-qc-finished-goods"
                endIcon={<SearchIcon />}
              >
                ค้นหา
              </ThemedButton>
            </Grid>
            <Grid item>
              <ThemedButton
                contrast
                variant="outlined"
                endIcon={<PrintIcon />}
                onClick={() => window.print()}
              >
                Print
              </ThemedButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </ThemeProvider>
  )
}

export const QCFinishedGoodsBody = (
  props: IQCFinishedGoodsBodyProps
): JSX.Element => {
  const {
    qcFinishedGoodsData,
    qcFinishedGoodsCalled,
    qcFinishedGoodsLoading,
    ngType,
    partNo,
    status,
    customer
  } = props

  let filteredListFromParts: QCFinishedGoodsData[] = []
  if (qcFinishedGoodsData && qcFinishedGoodsData.qcFinishGoodsReport.length) {
    filteredListFromParts = partNo
      ? qcFinishedGoodsData.qcFinishGoodsReport.filter(
          (demolishReport: QCFinishedGoodsData) =>
            demolishReport.partNo === partNo
        )
      : customer
      ? qcFinishedGoodsData.qcFinishGoodsReport.filter(
          (demolishReport: QCFinishedGoodsData) =>
            demolishReport.customer === customer
        )
      : qcFinishedGoodsData.qcFinishGoodsReport
  }

  const filteredListFromStatus: QCFinishedGoodsData[] = status
    ? filteredListFromParts.filter((demolishReport: QCFinishedGoodsData) => {
        if (status === "In Process" && demolishReport.process === "WIP")
          return true
        if (status === "Final" && demolishReport.process === "QC") return true

        //sNG and tNG doesn't exist? so always return false... for now
        if (status === "ปรับแต่งแม่พิมพ์/Set Up") return false
        return false
      })
    : filteredListFromParts

  const filteredList: QCFinishedGoodsData[] = filteredListFromStatus.filter(
    (demolishReport: QCFinishedGoodsData) =>
      demolishReport[
        ngTypeInfoList[ngType].dataKey as keyof QCFinishedGoodsData
      ]
  )

  //each ngType has 10 columns to it hence index 0 -> 9
  const qcFinishedGoodsReportBody: JSX.Element | JSX.Element[] =
    qcFinishedGoodsCalled && qcFinishedGoodsLoading ? (
      <StyledTableRow>
        <StyledTableCell colSpan={30}>
          <CircularProgress />
        </StyledTableCell>
      </StyledTableRow>
    ) : (
      filteredList.map((demolishReport: QCFinishedGoodsData, index: number) => {
        const totalForType: number = demolishReport[
          ngTypeInfoList[ngType].ngTypeInfoKey[9] as keyof QCFinishedGoodsData
        ] as number
        const demolishQuantity: number = demolishReport.quantity
        return (
          <StyledTableRow
            aria-label="qc-demolish-body"
            key={`qc-demolish-body-row-${index + 1}`}
          >
            <StyledTableCell colSpan={1}>{index + 1}</StyledTableCell>
            <StyledTableCell colSpan={2}>{demolishReport.date}</StyledTableCell>
            <StyledTableCell colSpan={2}>
              {demolishReport.partNo}
            </StyledTableCell>
            <StyledTableCell colSpan={3}>
              {demolishReport.partName}
            </StyledTableCell>
            <StyledTableCell colSpan={2}>
              {demolishReport.customer}
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[0] as keyof QCFinishedGoodsData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[1] as keyof QCFinishedGoodsData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[2] as keyof QCFinishedGoodsData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[3] as keyof QCFinishedGoodsData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[4] as keyof QCFinishedGoodsData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[5] as keyof QCFinishedGoodsData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[6] as keyof QCFinishedGoodsData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[7] as keyof QCFinishedGoodsData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {
                demolishReport[
                  ngTypeInfoList[ngType]
                    .ngTypeInfoKey[8] as keyof QCFinishedGoodsData
                ]
              }
            </StyledTableCell>
            <StyledTableCell colSpan={1}>{totalForType}</StyledTableCell>
            <StyledTableCell colSpan={1}>
              {demolishReport.okQuantity}
            </StyledTableCell>
            <StyledTableCell colSpan={1}>{demolishQuantity}</StyledTableCell>
            <StyledTableCell colSpan={1}>
              {(100 * (totalForType / demolishQuantity)).toFixed(2)}%
            </StyledTableCell>
            <StyledTableCell colSpan={2}>
              {demolishReport.pdLotNo}
            </StyledTableCell>
            <StyledTableCell colSpan={2}>
              {demolishReport.jobOrder}
            </StyledTableCell>
            <StyledTableCell colSpan={2}>
              {demolishReport.remarks}
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              {demolishReport.operator}
            </StyledTableCell>
          </StyledTableRow>
        )
      })
    )

  return (
    <>
      {qcFinishedGoodsReportBody}
      <StyledTableRow style={{ height: "70px" }}>
        <StyledTableCellBold colSpan={10}>ยอดรวม</StyledTableCellBold>
        <StyledTableCell colSpan={1}>
          {calculateQcFinishedGoodsTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[0]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcFinishedGoodsTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[1]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcFinishedGoodsTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[2]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcFinishedGoodsTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[3]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcFinishedGoodsTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[4]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcFinishedGoodsTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[5]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcFinishedGoodsTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[6]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcFinishedGoodsTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[7]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcFinishedGoodsTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[8]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={1}>
          {calculateQcFinishedGoodsTotal(
            filteredList,
            ngTypeInfoList[ngType].ngTypeInfoKey[9]
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={10}></StyledTableCell>
      </StyledTableRow>
    </>
  )
}

interface IPropsSignature {
  qcFinishedGoodsData: QCFinishedGoodsDataList | undefined
  factory: string
}
export const QCFinishedGoodsSignature = (
  props: IPropsSignature
): JSX.Element => {
  const { qcFinishedGoodsData, factory } = props
  const approveStatusApr = qcFinishedGoodsData?.qcFinishGoodsReport.find(
    (data) => !data.approveStatus.apr
  )
  const approveStatusCheck = qcFinishedGoodsData?.qcFinishGoodsReport.find(
    (data) => !data.approveStatus.checkout
  )
  const lerd = getImagePng(
    "images/signature/%E0%B9%80%E0%B8%A5%E0%B8%B4%E0%B8%A8%E0%B8%A7%E0%B8%A3%E0%B8%A3%E0%B8%98%E0%B8%99%E0%B9%8C"
  )
  const chi = getImagePng(
    "images/signature/%E0%B9%84%E0%B8%8A%E0%B8%A2%E0%B8%A8"
  )
  const sne = getImagePng("images/signature/sna")

  let apr = false
  if (!approveStatusApr) {
    apr = qcFinishedGoodsData?.qcFinishGoodsReport.find(
      (data) => data.approveStatus
    )?.approveStatus?.apr
      ? true
      : false
  }

  let checkout = false
  if (!approveStatusCheck) {
    checkout = qcFinishedGoodsData?.qcFinishGoodsReport.find(
      (data) => data.approveStatus
    )?.approveStatus?.checkout
      ? true
      : false
  }

  return (
    <>
      <StyledTableRow style={{ height: "70px" }}>
        <StyledTableCell
          rowSpan={2}
          colSpan={18}
          style={{ border: 0 }}
        ></StyledTableCell>
        <StyledTableCell colSpan={4}>
          {apr ? (
            <>
              <img src={lerd} alt="" width={80} />
            </>
          ) : (
            <></>
          )}
        </StyledTableCell>
        <StyledTableCell colSpan={4}>
          {checkout ? (
            <>
              {factory === "1" ? (
                <>
                  <img src={sne} alt="" width={80} />
                </>
              ) : (
                <>
                  <img src={chi} alt="" width={80} />
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </StyledTableCell>

        <StyledTableCell colSpan={4}>ศรีนวล</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell colSpan={4}>APPROVER</StyledTableCell>
        <StyledTableCell colSpan={4}>CHECKOUT</StyledTableCell>
        <StyledTableCell colSpan={4}>ISSUER</StyledTableCell>
      </StyledTableRow>
    </>
  )
}

const QCFinishedGoods = (): JSX.Element => {
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [factory, setFactory] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [type, setType] = useState<string>("")

  const [ngType, setNgType] = useState<string>("Stamping")

  const [printMode, setPrintMode] = useState<boolean>(false)

  const { data: partNoData, loading: partNoLoading } = useQuery(GET_PARTNO)

  const [
    getQcFinishedGoods,
    {
      data: qcFinishedGoodsData,
      loading: qcFinishedGoodsLoading,
      called: qcFinishedGoodsCalled
    }
  ] = useLazyQuery<QCFinishedGoodsDataList, QCFinishedGoodsQueryArguments>(
    GET_QC_FINISHED_GOODS,
    { fetchPolicy: "network-only" }
  )

  const makePartNoList = (partsList: PartNoList, loading: boolean) => {
    if (loading || !partsList || partsList.parts.length === 0) return []
    return partsList.parts.map((part: PartNo) => part.partNo)
  }

  const partNoList = useMemo(
    () => makePartNoList(partNoData, partNoLoading),
    [partNoData, partNoLoading]
  )

  return (
    <div style={{ position: "relative" }}>
      <div className="hidden-print">
        <PageLabel
          menuItem={menuItemList.Qc}
          menuItemName={Object.keys(menuItemList)[4]}
          menuPageName={pageTitle}
        />
      </div>
      <ThemedCard>
        <div className="hidden-print">
          <SearchQCFinishedGoods
            startDate={startDate}
            customer={customer}
            endDate={endDate}
            factory={factory}
            partNo={partNo}
            type={type}
            ngType={ngType}
            partNoList={partNoList}
            printMode={printMode}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setFactory={setFactory}
            setPartNo={setPartNo}
            setType={setType}
            setNgType={setNgType}
            getQcFinishedGoods={getQcFinishedGoods}
            setPrintMode={setPrintMode}
            setCustomer={setCustomer}
          />
        </div>
        <div className="hidden-print">
          <PageLabelCard
            title="Preview QC Finished Goods"
            subTitle="detail about QC Finished Goods"
          />
        </div>
        <div className="qc-report-to-print">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                  className="print-sm-order"
                >
                  <TableHead>
                    <StyledTableRow id="qc-finished-goods-header">
                      <StyledTableCell
                        style={{ maxWidth: "300px" }}
                        colSpan={5}
                      >
                        <img src={miniLogo} alt="Brother Auto Parts." />
                      </StyledTableCell>
                      <StyledTableCellBold
                        align="center"
                        colSpan={20}
                        style={{ fontSize: "30px" }}
                      >
                        ตารางการตรวจเช็คงาน QC FINISHED GOODS
                      </StyledTableCellBold>
                      <StyledTableCellBold align="center" colSpan={5}>
                        F-QA-03-06
                        <br />
                        Effective Date: 01/08/2024 Rev.08 Issue: 1
                      </StyledTableCellBold>
                    </StyledTableRow>
                    <StyledTableRow id="qc-finished-goods-options">
                      <StyledTableCellBold colSpan={1}>
                        Type
                      </StyledTableCellBold>
                      <StyledTableCell colSpan={4}>{type}</StyledTableCell>
                      <StyledTableCellBold colSpan={2}>
                        Status
                      </StyledTableCellBold>
                      <StyledTableCell colSpan={4}>{status}</StyledTableCell>
                      <StyledTableCellBold colSpan={2}>
                        NG Type
                      </StyledTableCellBold>
                      <StyledTableCell colSpan={4}>{ngType}</StyledTableCell>
                      <StyledTableCellBold colSpan={2}>
                        Factory
                      </StyledTableCellBold>
                      <StyledTableCell colSpan={3}>{factory}</StyledTableCell>
                      <StyledTableCellBold colSpan={2}>
                        Date
                      </StyledTableCellBold>
                      <StyledTableCell colSpan={6}>{`${moment(startDate).format(
                        "DD/MM/YYYY"
                      )} - ${moment(endDate).format(
                        "DD/MM/YYYY"
                      )}`}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow id="qc-finished-goods-body-header">
                      <StyledTableCell rowSpan={4} colSpan={1}>
                        No.
                      </StyledTableCell>
                      <StyledTableCell rowSpan={4} colSpan={2}>
                        Date
                      </StyledTableCell>
                      <StyledTableCell rowSpan={4} colSpan={2}>
                        Part No.
                      </StyledTableCell>
                      <StyledTableCell rowSpan={4} colSpan={3}>
                        Part Name
                      </StyledTableCell>
                      <StyledTableCellVertical rowSpan={4} colSpan={2}>
                        Customer
                      </StyledTableCellVertical>
                      <StyledTableCell rowSpan={1} colSpan={9}>
                        รายระเอียดของเสีย
                      </StyledTableCell>

                      <StyledTableCellVertical rowSpan={4} colSpan={1}>
                        รวมของเสีย
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={4} colSpan={1}>
                        ยอดจำนวน OK
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={4} colSpan={1}>
                        Lot Size
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={4} colSpan={1}>
                        % NG
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={4} colSpan={2}>
                        PRODUCTION LOT
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={4} colSpan={2}>
                        JON/Mat Lot No
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={4} colSpan={2}>
                        หมายเหตุ
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={4} colSpan={1}>
                        ผู้รับผิดชอบ QC/QA
                      </StyledTableCellVertical>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellVertical
                        rowSpan={3}
                        colSpan={1}
                        aria-label="ng-type-col-1"
                      >
                        {ngTypeInfoList[ngType].ngTypeInfoHeader[0]}
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={3} colSpan={1}>
                        {ngTypeInfoList[ngType].ngTypeInfoHeader[1]}
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={3} colSpan={1}>
                        {ngTypeInfoList[ngType].ngTypeInfoHeader[2]}
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={3} colSpan={1}>
                        {ngTypeInfoList[ngType].ngTypeInfoHeader[3]}
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={3} colSpan={1}>
                        {ngTypeInfoList[ngType].ngTypeInfoHeader[4]}
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={3} colSpan={1}>
                        {ngTypeInfoList[ngType].ngTypeInfoHeader[5]}
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={3} colSpan={1}>
                        {ngTypeInfoList[ngType].ngTypeInfoHeader[6]}
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={3} colSpan={1}>
                        {ngTypeInfoList[ngType].ngTypeInfoHeader[7]}
                      </StyledTableCellVertical>
                      <StyledTableCellVertical rowSpan={3} colSpan={1}>
                        อื่น
                      </StyledTableCellVertical>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <QCFinishedGoodsBody
                      qcFinishedGoodsData={qcFinishedGoodsData}
                      qcFinishedGoodsCalled={qcFinishedGoodsCalled}
                      qcFinishedGoodsLoading={qcFinishedGoodsLoading}
                      ngType={ngType}
                      partNo={partNo}
                      status={status}
                      customer={customer}
                    />
                    <QCFinishedGoodsSignature
                      qcFinishedGoodsData={qcFinishedGoodsData}
                      factory={factory}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </ThemedCard>
    </div>
  )
}

export default QCFinishedGoods
