import { useEffect, useState } from "react"
import { DataProductionPlan } from "../ProductionPlanReport"

const sumQuantity = (plans: DataProductionPlan[] = []) => {
  if (plans.length > 0) {
    return plans.map((e) => e.totalQuantity).reduce((prev, cur) => prev + cur)
  } else {
    return 0
  }
}

interface IProduction {
  masterPlan: DataProductionPlan[]
  backupPlan: DataProductionPlan[]
  totalQuantity: number
  totalQuantityBackup: number
}
export const useDataProductionPlan = (
  productionPlanData: DataProductionPlan[][]
): IProduction => {
  const [masterPlan, setMasterPlan] = useState<DataProductionPlan[]>([])
  const [backupPlan, setBackup] = useState<DataProductionPlan[]>([])
  const [totalQuantity, setTotalQuantity] = useState<number>(0)
  const [totalQuantityBackup, setTotalQuantityBackup] = useState<number>(0)
  useEffect(() => {
    if (productionPlanData.length > 1) {
      setMasterPlan(productionPlanData[0])
      setTotalQuantity(sumQuantity(productionPlanData[0]))
      setBackup(productionPlanData[1] ?? [])
      setTotalQuantityBackup(sumQuantity(productionPlanData[1]))
    } else {
      setMasterPlan(productionPlanData[0] ?? [])
      setTotalQuantity(sumQuantity(productionPlanData[0]))
    }
  }, [productionPlanData])

  return { masterPlan, backupPlan, totalQuantity, totalQuantityBackup }
}
