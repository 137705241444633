import PlayArrowIcon from "@mui/icons-material/Edit"
import StopIcon from "@mui/icons-material/Stop"
import {
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  Tooltip
} from "@mui/material"
import { useEffect, useState } from "react"
import { ISummaryMachine } from "../SummaryPlan"
import TimeCurrent from "./Time"
import DialogProblem from "../../Planning/component/DialogProblem"
import ProblemTime, {
  IDictionaryPartName
} from "../../Planning/component/ProblemTime"
import { calculateTime } from "../../Planning/ProductionPlan"
import "../../../css/Production/ProductionResult.css"

import moment from "moment"
import EditProblemMachine from "./EditProblemMachine"
import { bankImage, getImagePng } from "../../../common/utilities"

export interface IDataProductionPlan {
  id: number
  planNo?: string
  revision?: string
  planDate?: string
  pdPlan?: string
  factory?: string
  shift?: string
  partNo: string
  partName: string
  customer: string
  matLotNo?: string
  componentPartLotNo?: string
  pdLotNo?: string
  line: string
  section?: string
  step: string
  process: string
  machine: string
  jph: number
  startTime: string
  endTime: string
  startTimeOT: string
  endTimeOT: string
  totalHour: string
  totalQuantity: number
  totalNGProcess?: number
  totalNGSetUp?: number
  employee?: string
  plApprove?: string
  pdApprove?: string
  engApprove?: string
  qaApprove?: string
  mtApprove?: string
  remarks?: string
  totalActual?: string
  cycleTime?: string
  totalPlan?: string
  totalOK?: string
  totalNG?: string
  totalStart?: string
  totalEnd?: string
  operator?: string
  lostTime?: string
  status?: number
  timeShow?: string
  timeShowOT?: string
  timeCalculate?: IDataTimeCal[]
}

interface IDataTimeCal {
  startTime: string
  endTime: string
  startTimeOT: string
  endTimeOT: string
}
export interface IDataProblem {
  machine: string
  partNo: string
  process: string
  actionDate: string
  startTime: string
  endTime: string
  totalTime: number
  problemName: string
  remarks: string
}
interface IWSIncrementQuantity {
  partNo: string
  process: string
  machine: string
  actionDate: string
  quantity: number
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "15px",
    border: "solid gray",
    color: "white",
    borderWidth: "2px",
    padding: "0px",
    whiteSpace: "nowrap"
  }
}))
interface IProps {
  planData: IDataProductionPlan
  actualProcess?: ISummaryMachine
  incrementQuantity?: IWSIncrementQuantity
  handleSubmit: () => void
  factory: string | null
}

const conditionEndTime = (
  currentTime: string,
  endTime: string,
  date?: string
) => {
  return Number(
    calculateTime(
      currentTime > endTime &&
        date !== moment(new Date()).format("YYYY-MM-DD") &&
        endTime !== "00:00"
        ? endTime
        : currentTime
    )
  )
}

const SummaryMachine = (props: IProps): JSX.Element => {
  const { planData, handleSubmit, actualProcess, factory } = props
  const [problemTime, setProblemTime] = useState<IDictionaryPartName>({})
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [dialogEditMachineOpen, setDialogEditMachineOpen] =
    useState<boolean>(false)

  const [statusRun, setStatusRun] = useState<boolean>(true)
  const [timeCurrent, setTimeCurrent] = useState<number>(0)
  const [timeStatus, setTimeStatus] = useState<boolean>(true)

  const [newActual, setNewActual] = useState<number>(
    Number(props.actualProcess?.quantity) || 0
  )
  const [dateState, setDateState] = useState(new Date())
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 1000)
  }, [])
  const currentTime = dateState.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false
  })
  let totalHour = 0
  const nowDate: string = moment(new Date()).format("yyyy-MM-DD").toString()
  const nowTime: string = moment(new Date()).format("HH:mm").toString()

  if (planData.timeCalculate) {
    for (const plan of planData.timeCalculate) {
      const endTimeNow = conditionEndTime(
        currentTime,
        plan.endTime,
        planData.planDate
      )
      const startTime = Number(calculateTime(plan.startTime))
      const endTime = Number(calculateTime(plan.endTime))
      const endTimeOT = Number(calculateTime(plan.endTimeOT))
      const startTimeOT = Number(calculateTime(plan.startTimeOT))

      if (props.planData.planDate !== nowDate) {
        if (endTime > 12 && startTime < 13) {
          totalHour += endTime - startTime - 1 + endTimeOT - startTimeOT
        } else if (endTime <= 12 && startTime <= 12) {
          totalHour += endTime - startTime + endTimeOT - startTimeOT
        } else if (startTime >= 13) {
          totalHour += endTime - startTime + endTimeOT - startTimeOT
        } else {
          totalHour += 0
        }
      } else {
        if (
          Number(calculateTime(nowTime)) <= 12 &&
          startTime < 13 &&
          startTime !== 0
        ) {
          totalHour += endTimeNow - startTime
        } else if (
          Number(calculateTime(nowTime)) >= 13 &&
          Number(calculateTime(nowTime)) <= 17
        ) {
          if (
            (startTime <= 12 && startTime !== 0 && endTime <= 12) ||
            (startTime >= 13 &&
              startTime !== 0 &&
              Number(calculateTime(nowTime)) > startTime)
          ) {
            totalHour += endTimeNow - startTime
          } else if (startTime <= 12 && startTime !== 0 && endTime >= 13) {
            totalHour += endTimeNow - startTime - 1
          } else {
            totalHour += 0
          }
        } else if (Number(calculateTime(nowTime)) >= 17.5) {
          if (startTimeOT !== 0) {
            if (
              (startTime <= 12 && endTime <= 12) ||
              (startTime >= 13 && Number(calculateTime(nowTime)) > startTimeOT)
            ) {
              totalHour += endTime - startTime + endTimeNow - startTimeOT
            } else if (startTime <= 12 && endTime >= 13) {
              totalHour += endTime - startTime + endTimeNow - startTimeOT - 1
            } else {
              totalHour += 0
            }
          } else {
            if (
              (startTime <= 12 && startTime !== 0 && endTime <= 12) ||
              (startTime >= 13 && startTime !== 0)
            ) {
              totalHour += endTime - startTime
            } else if (startTime <= 12 && startTime !== 0 && endTime >= 13) {
              totalHour += endTime - startTime - 1
            }
          }
        } else if (
          Number(calculateTime(nowTime)) > 12 &&
          Number(calculateTime(nowTime)) < 13
        ) {
          if (endTime !== 0 && endTime <= 12) {
            totalHour += endTime - startTime
          } else if (startTime <= 12 && startTime !== 0 && endTime >= 13) {
            totalHour += endTimeNow - startTime - (endTimeNow - 12)
          } else {
            totalHour += 0
          }
        } else if (
          Number(calculateTime(nowTime)) > 17 &&
          Number(calculateTime(nowTime)) < 17.5
        ) {
          if ((endTime !== 0 && endTime <= 12) || startTime >= 13) {
            totalHour += endTime - startTime
          } else if (startTime <= 12 && startTime !== 0 && endTime >= 13) {
            totalHour += endTime - startTime - 1
          }
        } else {
          totalHour += 0
        }
      }
    }
  }

  const target =
    totalHour * props.planData.jph > props.planData.totalQuantity ||
    props.planData.planDate !== nowDate
      ? props.planData.totalQuantity
      : totalHour * (props.planData.jph || 0)

  const other = Math.round(problemTime["other"]?.totalTime * 60) || 0
  const qc =
    Math.round(problemTime["Wait QC First Set Up"]?.totalTime * 60) || 0
  const die = Math.round(problemTime["DIE Breakdown"]?.totalTime * 60) || 0
  const pm = Math.round(problemTime["PM"]?.totalTime * 60) || 0
  const MCBreakdown =
    Math.round(problemTime["Machine Breakdown"]?.totalTime * 60) || 0

  const lostTime = (other + qc + die + MCBreakdown) / 60
  const problemTooltip = (type: string) => {
    const problemList = problemTime[type]?.problemNameList

    return (
      <>
        {problemList?.map((e: string, i: number) => {
          return (
            <span style={{ fontSize: "15px", color: "orange" }}>
              {i + 1}. {e} <br />
            </span>
          )
        })}
      </>
    )
  }
  const workloadTime = totalHour - pm / 60
  const machineRunTime = workloadTime - lostTime
  const productivity = machineRunTime / workloadTime

  const performance = Number(props.actualProcess?.quantity) / target
  const rateQ =
    (Number(props.actualProcess?.quantity) - Number(props.actualProcess?.sNG)) /
    Number(props.actualProcess?.quantity)

  const oee = productivity * performance * rateQ * 100 || 0

  useEffect(() => {
    setNewActual(Number(props.actualProcess?.quantity) || 0)
  }, [props.actualProcess?.quantity])
  useEffect(() => {
    const increment = Number(props.incrementQuantity?.quantity) || 0
    setNewActual(newActual + increment)
  }, [props.incrementQuantity])

  const imgStatus = () => {
    if (nonActiveQuantity()) {
      return getImagePng(`hololive_yt_054`)
    }
    if (!statusRun || oee < 80) {
      return getImagePng(`pakorabad`)
    } else {
      return getImagePng(`__hoshimachi_suisei_hololive_drawn_by_hinata_kokag`)
    }
  }

  const nonActiveQuantity = (): boolean => {
    const stopTime = Number(problemTime["totalStopMachine"]?.totalTime || 0)
    if (
      !stopTime &&
      timeCurrent > Number(planData.totalHour) * 0.25 &&
      newActual === 0
    ) {
      return true
    }
    if (
      stopTime &&
      newActual === 0 &&
      timeCurrent - stopTime > (Number(planData.totalHour) - stopTime) * 0.25
    ) {
      return true
    }
    return false
  }

  const classBlink = (): string => {
    if (!statusRun) {
      return "blink"
    } else if (nonActiveQuantity()) {
      return "blink-yellow"
    } else {
      return ""
    }
  }

  return (
    <div>
      <Table
        style={{
          width: "100%",
          marginLeft: "15px",
          backgroundColor: "black",
          zoom: "80%"
        }}
      >
        <TableBody
          className={classBlink()}
          style={{ border: "solid 8px black" }}
        >
          <TableRow>
            <StyledTableCell colSpan={2} style={{ color: "yellow" }}>
              <label style={{ color: "yellow", fontSize: "16px" }}>
                Machine:
              </label>
              <label style={{ color: "white", fontSize: "16px" }}>
                {props.planData.machine}
              </label>
              <label style={{ color: "green", fontSize: "16px" }}>
                (
                {planData.remarks === "Try Out"
                  ? "Try Out"
                  : planData.pdPlan
                  ? planData.pdPlan
                  : "ไม่มีในแผน"}
                )
              </label>
              <br />
              <label style={{ color: "yellow", fontSize: "16px" }}>Step:</label>
              <label style={{ color: "white", fontSize: "16px" }}>
                {props.planData.step}
              </label>
              <label style={{ color: "yellow", fontSize: "16px" }}>JPH:</label>
              <label style={{ color: "white", fontSize: "16px" }}>
                {props.planData.jph}
              </label>
              <br />
              <label style={{ color: "yellow", fontSize: "16px" }}>
                Process:
              </label>
              <label style={{ color: "white", fontSize: "16px" }}>
                {props.planData.process}
              </label>
              <br />
              <label style={{ color: "yellow", fontSize: "16px" }}>
                Part No:
              </label>
              <label style={{ color: "white", fontSize: "16px" }}>
                {props.planData.partNo}
              </label>
              <br />
              <label style={{ color: "yellow", fontSize: "16px" }}>
                Part Name:
              </label>
              <label style={{ color: "white", fontSize: "16px" }}>
                {props.planData.partName}
              </label>
            </StyledTableCell>
            <StyledTableCell>
              Date: {props.planData.planDate}
              <br />
              Line: {props.planData.line}
              <br />
              Shift:{" "}
              <span
                style={{
                  color: props.planData.shift === "Day" ? "#ffcc00" : "#00ffff"
                }}
              >
                {props.planData.shift}
              </span>
              <br />
              <img
                src={getImagePng(`images/parts/${props.planData.partNo}`)}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = bankImage
                }}
                alt=""
                width={"100px"}
                height={"60"}
                style={{ paddingLeft: "30px" }}
              />
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell colSpan={2} align="center">
              <label
                style={{ fontSize: "15px", margin: "1px", fontWeight: "bold" }}
              >
                Production Result
              </label>
            </StyledTableCell>
            <StyledTableCell colSpan={1}>
              <label
                style={{ color: "#ff55ff", fontSize: "16px", margin: "3px" }}
              >
                Target Plan:
              </label>
              {props.planData.totalQuantity}
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>
              <label
                style={{ color: "yellow", fontSize: "16px", margin: "3px" }}
              >
                Time:
              </label>
              <label
                style={
                  planData.timeShow && planData.timeShow?.length > 11
                    ? { fontSize: "12px" }
                    : { fontSize: "16px" }
                }
              >
                {planData.timeShow}
              </label>
            </StyledTableCell>
            <StyledTableCell>
              <label
                style={{ color: "yellow", fontSize: "16px", margin: "3px" }}
              >
                OT:
              </label>

              <label
                style={
                  planData.timeShowOT && planData.timeShowOT?.length > 11
                    ? { fontSize: "12px" }
                    : { fontSize: "16px" }
                }
              >
                {planData.timeShowOT}
              </label>
            </StyledTableCell>
            <StyledTableCell>
              <label
                style={{ color: "#ff55ff", fontSize: "16px", margin: "3px" }}
              >
                Current Target :
              </label>
              {Math.ceil(target) < 0 ? 0 : Math.ceil(target) || 0} PCs.
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell>
              {statusRun && planData.planDate === nowDate ? (
                <label style={{ color: "#55ff55", fontSize: "16px" }}>
                  Running
                </label>
              ) : (
                <label style={{ color: "red", fontSize: "16px" }}>
                  Stop Machine
                </label>
              )}
            </StyledTableCell>
            <StyledTableCell>
              <label
                style={{ color: "#ff55ff", fontSize: "16px", margin: "3px" }}
              >
                Actual:
              </label>
              {newActual} Pcs.
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center">Current Time</StyledTableCell>
            <StyledTableCell>
              <label style={{ color: "#55ff55", fontSize: "16px" }}>
                <TimeCurrent
                  planData={planData}
                  setTimeStatus={setTimeStatus}
                  setTimeCurrent={setTimeCurrent}
                />
              </label>
            </StyledTableCell>
            <StyledTableCell>
              <label
                style={{ color: "#ff55ff", fontSize: "16px", margin: "3px" }}
              >
                Diff:
              </label>
              <label
                style={
                  parseFloat(props.actualProcess?.quantity || "0") - target < 0
                    ? { color: "red", fontSize: "16px", margin: "3px" }
                    : { color: "white", fontSize: "16px", margin: "3px" }
                }
              >
                {parseFloat(props.actualProcess?.quantity || "0") -
                  (Math.ceil(target) < 0 ? 0 : Math.ceil(target)) || 0}
                Pcs.
              </label>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell colSpan={2} align="center">
              <Button
                variant="outlined"
                style={{
                  width: "150px",
                  color: "red",
                  borderColor: "red",
                  height: "20px"
                }}
                onClick={() => setDialogOpen(true)}
              >
                <StopIcon /> Stop Machine
              </Button>
              <Button
                variant="outlined"
                style={{
                  width: "150px",
                  color: "green",
                  marginLeft: "15px",
                  borderColor: "green",
                  height: "20px"
                }}
                onClick={() => setDialogEditMachineOpen(true)}
              >
                <PlayArrowIcon /> Edit Machine
              </Button>
            </StyledTableCell>
            <StyledTableCell>
              <label
                style={{ color: "#ff55ff", fontSize: "16px", margin: "3px" }}
              >
                NG:
              </label>
              <label style={{ color: "red", fontSize: "16px", margin: "3px" }}>
                {props.actualProcess?.sNG || 0} Pcs.
              </label>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                borderRight: "solid black",
                borderWidth: "2px",
                color: "yellow"
              }}
            >
              PM :
            </StyledTableCell>
            <StyledTableCell align="right">
              {Math.round(problemTime["PM"]?.totalTime * 60) || 0} min
            </StyledTableCell>
            <StyledTableCell rowSpan={1} align="left">
              Operation:
              <label style={{ color: "yellow", fontSize: "16px" }}>
                {actualProcess?.productionWorker || ""}
              </label>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                borderRight: "solid black",
                borderWidth: "2px"
              }}
            >
              <label style={{ color: "red", fontSize: "16px" }}>
                Machine Breakdown:
              </label>
            </StyledTableCell>
            <StyledTableCell align="right">
              {Math.round(problemTime["Machine Breakdown"]?.totalTime * 60) ||
                0}{" "}
              min
            </StyledTableCell>
            <StyledTableCell rowSpan={4} align="center">
              {timeStatus ? (
                <img src={imgStatus()} alt="" width={"120"} height={"120"} />
              ) : (
                <div style={{ height: "120px" }}></div>
              )}
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                borderRight: "solid black",
                borderWidth: "2px",
                color: "blue"
              }}
            >
              DIE Breakdown :
            </StyledTableCell>
            <StyledTableCell align="right">
              {Math.round(problemTime["DIE Breakdown"]?.totalTime * 60) || 0}{" "}
              min
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                borderRight: "solid black",
                borderWidth: "2px"
              }}
            >
              Wait QC First Set Up :
            </StyledTableCell>
            <StyledTableCell align="right">
              {Math.round(
                problemTime["Wait QC First Set Up"]?.totalTime * 60
              ) || 0}{" "}
              min
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                borderRight: "solid black",
                borderWidth: "2px",
                color: "orange"
              }}
            >
              other :
            </StyledTableCell>
            <StyledTableCell align="right">
              <Tooltip
                title={
                  Math.round(problemTime["other"]?.totalTime * 60) > 0
                    ? problemTooltip("other")
                    : "ไม่มีปัญหา"
                }
                arrow
                sx={{ fontSize: "25px" }}
                followCursor={true}
              >
                <div>
                  {Math.round(problemTime["other"]?.totalTime * 60) || 0} min
                </div>
              </Tooltip>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center" colSpan={2}>
              ประสิทธิภาพเครื่อง OEE
            </StyledTableCell>
            <StyledTableCell align="center">
              <label style={{ color: "#55ff55", fontSize: "20px" }}>
                {oee.toFixed(2)}%
              </label>
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div>
        <DialogProblem
          handleSubmit={handleSubmit}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          planData={planData}
          factory={factory}
        />
        <EditProblemMachine
          handleSubmit={handleSubmit}
          dialogOpen={dialogEditMachineOpen}
          setDialogOpen={setDialogEditMachineOpen}
          planData={planData}
        />
        <ProblemTime
          setProblemTime={setProblemTime}
          setStatusRun={setStatusRun}
          planData={planData}
        />
      </div>
    </div>
  )
}
export default SummaryMachine
