import { useMutation, useQuery } from "@apollo/client"
import { Autocomplete, Grid, useTheme } from "@mui/material"
import moment from "moment"

import swal from "sweetalert"
import AddIcon from "@mui/icons-material/Add"
import {
  GET_MACHINES_MT,
  GET_PM_PLAN_YEAR
} from "../../../common/Query/MaintenanceQuery"
import {
  PM_PLAN_RECORD,
  PM_PLAN_REMOVE
} from "../../../common/Mutation/MaintenanceMutation"

import { useState } from "react"
import { PageLabelCard } from "../../../common/Resources/PageLabelCard"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedCard from "../../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"

const PMPlanRecord: React.FC = () => {
  const [dateFrom, setDateFrom] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [actionDate, setActionDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [machine, setMachine] = useState<string>("")
  const [removeMachine, setRemoveMachine] = useState<string>("")
  const [outsource, setOutsource] = useState<boolean>(false)
  const [removeOutsource, setRemoveOutsource] = useState<boolean>(false)

  const [createPMPlanRecord, { loading }] = useMutation(PM_PLAN_RECORD, {
    refetchQueries: [GET_PM_PLAN_YEAR],
    awaitRefetchQueries: true
  })

  const [removePMPlanRecord, { loading: removeLoading }] = useMutation(
    PM_PLAN_REMOVE,
    {
      refetchQueries: [GET_PM_PLAN_YEAR],
      awaitRefetchQueries: true
    }
  )

  const getMachine = useQuery(GET_MACHINES_MT)
  let machineList: Array<string> = []
  machineList = getMachine.data?.machinesMt
    .map((e: { code: string }) => e.code)
    .reduce(
      (prev: string[], cur: string) =>
        prev.includes(cur) ? prev : prev.concat(cur),
      []
    )

  const handleSubmit = async () => {
    try {
      await createPMPlanRecord({
        variables: {
          machine,
          actionDate,
          outsource
        }
      })
      swal("Success", "Create PM Success", "success")
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  const handleRemove = async () => {
    try {
      await removePMPlanRecord({
        variables: {
          machine: removeMachine,
          dateTo,
          dateFrom,
          outsource: removeOutsource
        }
      })
      swal("Success", "Remove PM Success", "success")
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  const theme = useTheme()
  return (
    <>
      <ThemedCard>
        <PageLabelCard title="Create Plan" />
        <Grid container spacing={2} width={"98%"} padding={2}>
          <Grid item sm={6}>
            <ThemedTextField
              fullWidth
              value={actionDate}
              label="Date"
              id="date"
              type="date"
              autoComplete="family-name"
              onChange={(e) => setActionDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              disablePortal
              id="machine"
              value={machine}
              freeSolo
              options={machineList ?? ["-"]}
              onChange={(e, newValue) => setMachine(newValue ?? "")}
              renderInput={(params) => (
                <ThemedTextField {...params} label={"Machines"} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              id="outsource"
              value={outsource ? "Outsource" : "BAE"}
              freeSolo
              options={["BAE", "OutSource"]}
              onChange={(e, newValue) =>
                setOutsource(newValue === "OutSource" ? true : false)
              }
              renderInput={(params) => (
                <ThemedTextField {...params} label={"Outsource"} />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <ThemedLoadingButton
              variant="contained"
              sx={{ mt: 1, mb: 2, backgroundColor: theme.palette.primary.main }}
              onClick={handleSubmit}
              loading={loading}
              endIcon={<AddIcon />}
            >
              Submit
            </ThemedLoadingButton>
          </Grid>
        </Grid>
      </ThemedCard>
      <ThemedCard>
        <PageLabelCard title="Delete Plan" />
        <Grid container spacing={2} width={"98%"} padding={2}>
          <Grid item xs={3}>
            <ThemedTextField
              fullWidth
              value={dateFrom}
              id="dateFrom"
              type="date"
              label="Date From"
              InputLabelProps={{
                shrink: true
              }}
              onChange={(e) => setDateFrom(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <ThemedTextField
              fullWidth
              value={dateTo}
              id="dateTo"
              type="date"
              label="Date To"
              InputLabelProps={{
                shrink: true
              }}
              onChange={(e) => setDateTo(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              disablePortal
              id="machine"
              value={removeMachine}
              freeSolo
              options={machineList ?? ["-"]}
              onChange={(e, newValue) => setRemoveMachine(newValue ?? "")}
              renderInput={(params) => (
                <ThemedTextField {...params} label={"Machines"} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              id="outsource"
              value={removeOutsource ? "Outsource" : "BAE"}
              freeSolo
              options={["BAE", "OutSource"]}
              onChange={(e, newValue) =>
                setRemoveOutsource(newValue === "OutSource" ? true : false)
              }
              renderInput={(params) => (
                <ThemedTextField {...params} label={"Outsource"} />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <ThemedLoadingButton
              variant="contained"
              sx={{ mt: 1, mb: 2, backgroundColor: theme.palette.primary.main }}
              onClick={handleRemove}
              loading={removeLoading}
              endIcon={<AddIcon />}
            >
              Submit
            </ThemedLoadingButton>
          </Grid>
        </Grid>
      </ThemedCard>
    </>
  )
}
export default PMPlanRecord
