import {
  Autocomplete,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  useTheme
} from "@mui/material"
import { useState } from "react"

import Grid from "@mui/material/Grid"
import { useMutation, useQuery } from "@apollo/client"
import "../../css/Login/CreateUser.css"
import { CREATE_USER } from "../../common/Mutation/CreateUser"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { PageLabel } from "../../common/Resources/PageLabel"

import { LoadingButton } from "@mui/lab"
import { GET_EMPLOYEE } from "../../common/Query/MasterQuery"
import { IEmployee } from "../Master/Employee"
import swal from "sweetalert"

const CreateUsersBae: React.FC = () => {
  const [employeeId, setEmployeeId] = useState<number>(0)
  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [role, setRole] = useState<string[]>([])
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [createUser, { loading }] = useMutation(CREATE_USER)
  const getEmployee = useQuery(GET_EMPLOYEE)
  const theme = useTheme()
  const roleList: string[] = [
    "MASTER",
    "MATERIAL",
    "PLANNING",
    "PRODUCTION",
    "SCANNER",
    "QC",
    "ENGINEER",
    "WAREHOUSE",
    "LOGISTIC",
    "AUTHORIZATION",
    "ADJUST",
    "ADMIN",
    "MAINTENANCE",
    "EMPLOYEE"
  ]
  const roleNumber = () => {
    const roleNumber = role.map((element) => {
      switch (element) {
        case "MASTER":
          return 1
        case "MATERIAL":
          return 2
        case "PLANNING":
          return 3
        case "PRODUCTION":
          return 4
        case "SCANNER":
          return 5
        case "QC":
          return 6
        case "ENGINEER":
          return 7
        case "WAREHOUSE":
          return 8
        case "LOGISTIC":
          return 9
        case "AUTHORIZATION":
          return 10
        case "ADJUST":
          return 11
        case "MAINTENANCE":
          return 12
        case "EMPLOYEE":
          return 17
        case "ADMIN":
          return 99
        default:
          return false
      }
    })
    return roleNumber
  }
  let employeeList: IEmployee[] = []
  employeeList = getEmployee?.data?.employee
  const handleSubmit = async () => {
    try {
      if (!employeeId || !password || role.length === 0 || !username)
        return swal("ERROR", "Please enter your username and password", "error")
      if (password !== confirmPassword) {
        return swal("ERROR", "รหัสผ่านไม่ตรงกัน", "error")
      }
      const result = await createUser({
        variables: {
          username: username,
          password: password,
          role: roleNumber().toString(),
          employeeId: employeeId
        }
      })

      if (result.data.createUser) {
        swal("Success", "Create User Success", "success")
      } else {
        swal("ERROR", "Please enter your username and password", "error")
      }
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "error")
    }
  }

  return (
    <>
      <PageLabel
        menuItem={[]}
        menuItemName={"Sign up"}
        menuPageName={"Sign Up"}
      />
      <Card sx={{ width: 348, margin: "auto", marginTop: "25px" }}>
        <PageLabelCard
          title="Sign Up Account"
          subTitle="detail about Sign Up Account"
        />
        <Grid container spacing={2} margin={2} width={"98%"}>
          <Grid item xs={12} sm={12} marginRight={4}>
            <TextField
              fullWidth
              value={username}
              id="username"
              onChange={(e) =>
                setUsername(e.target.value ? e.target.value : "")
              }
              autoComplete="family-name"
              label="Username"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} marginRight={4}>
            <Autocomplete
              fullWidth
              disablePortal
              onChange={(e, newValue) => {
                setEmployeeId(newValue?.employeeId ? newValue?.employeeId : 0)
              }}
              renderInput={(params) => (
                <TextField {...params} label="Employee" />
              )}
              options={employeeList}
              getOptionLabel={(option) =>
                option.firstName + " " + option.lastName
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} marginRight={4}>
            <TextField
              fullWidth
              value={password}
              id="password"
              onChange={(e) =>
                setPassword(e.target.value ? e.target.value : "")
              }
              autoComplete="family-name"
              label="Password"
              InputLabelProps={{ shrink: true }}
              type={showPassword ? "string" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(
                        event: React.MouseEvent<HTMLButtonElement>
                      ) => event.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} marginRight={4}>
            <TextField
              fullWidth
              value={confirmPassword}
              id="confirm password"
              onChange={(e) =>
                setConfirmPassword(e.target.value ? e.target.value : "")
              }
              autoComplete="family-name"
              label={
                !confirmPassword
                  ? "Confirm Password"
                  : password && confirmPassword !== password
                  ? "รหัสผ่านไม่ตรงกัน"
                  : "รหัสผ่านตรงกัน"
              }
              InputLabelProps={
                !confirmPassword
                  ? { shrink: true }
                  : password && confirmPassword !== password
                  ? { shrink: true, style: { color: "red" } }
                  : { shrink: true, style: { color: "green" } }
              }
              type={showConfirmPassword ? "string" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      onMouseDown={(
                        event: React.MouseEvent<HTMLButtonElement>
                      ) => event.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} marginRight={4}>
            <Autocomplete
              multiple
              fullWidth
              disablePortal
              onChange={(e, newValue) => {
                setRole(newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Role" />
              )}
              options={roleList}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LoadingButton
              variant="contained"
              loadingPosition="end"
              loading={loading}
              onClick={handleSubmit}
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              SIGN UP
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default CreateUsersBae
