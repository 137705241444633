import * as React from "react"
import Box from "@mui/material/Box"
import jwt_decode from "jwt-decode"
import Routes from "../config/routes"
import Footer from "./Footer"
import NavBar, { DrawerHeader } from "./Navbar"
import { Theme, useTheme } from "@mui/material/styles"

let roles: string[] = []
// const token: string = window?.localStorage?.getItem("token") || ""
export let user: {
  role: string
  username: string
  position: string
  employee: ""
  factory: string
} = {
  role: "",
  username: "",
  position: "",
  employee: "",
  factory: ""
}

const token: string = window?.localStorage?.getItem("token") || ""
if (token) user = jwt_decode(token)

const useStyles = (theme: Theme) => ({
  mobileDisplay: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "fit-content",
      left: 0
    }
  },
  mobileAdjust: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: theme.palette.primary.light
    }
  },
  mobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
})

export const checkRole = (role: string[], token: string | null): boolean => {
  if (token) {
    user = jwt_decode(token)
    roles = user?.role.toString().split(",")
  }
  const intersection = roles.filter((element) =>
    role.find((ele2) => ele2 === element)
  )
  return intersection.length > 0 ? true : false
}

interface IProp {
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>
  isDarkMode: boolean
}

const MainApp: React.FC<IProp> = (props: IProp) => {
  const { setIsDarkMode, isDarkMode } = props
  const theme = useTheme()
  const classes = useStyles(theme)
  const [open, setOpen] = React.useState(false)

  const handleDrawerClose = () => {
    setOpen(false)
  }
  const minHeightWindow = () => {
    return window.innerHeight - 64 + "px"
  }

  return (
    <Box sx={{ display: "flex" }}>
      <NavBar
        open={open}
        setOpen={setOpen}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
      />
      <Box
        component="main"
        sx={{ ...classes.mobileAdjust, flexGrow: 1, p: 1 }}
        onClick={handleDrawerClose}
      >
        <DrawerHeader />
        <Box component="label"></Box>
        <Box
          style={{
            minHeight: minHeightWindow()
          }}
          sx={{ ...classes.mobileDisplay }}
        >
          <Routes />
        </Box>
        <div
          style={{
            display: !user ? "none" : "",
            backgroundColor: theme.palette.background.default
          }}
        >
          <Footer />
        </div>
      </Box>
    </Box>
  )
}

export default MainApp
