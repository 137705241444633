import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { IMasterPlanPartData } from "../MPReport"
import { GET_WORK_IN_PROCESS } from "../../../common/Query/PlanningQuery"
import { useQuery } from "@apollo/client"
import moment from "moment"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses
} from "@mui/material"
import { logoTable } from "../../../common/utilities"
import { IAmount, INewProcesses } from "../../Planning/WorkInProcess"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: " solid black 1px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun",
    border: " solid black 1px"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },

  "td, th": {
    border: 0
  }
}))
interface IProps {
  dialogOpen: boolean
  setDialogOpen: (status: boolean) => void
  partData?: IMasterPlanPartData
}

const DialogMasterWip = (props: IProps): JSX.Element => {
  const { dialogOpen, setDialogOpen, partData } = props
  const handleClose = () => {
    setDialogOpen(false)
  }
  const { data } = useQuery(GET_WORK_IN_PROCESS, {
    variables: {
      dateFrom: "1970-12-01",
      dateTo: moment(new Date()).format("YYYY-MM-DD"),
      partNo: partData?.partNo,
      factory: partData?.factory
    }
  })

  const rows: INewProcesses[] = data?.workInProcess
    .filter(
      (data: { amount: IAmount }) =>
        data.amount.total - data.amount.quantity !== 0
    )
    .map((e: { amount: { quantity: number; total: number } }) => {
      return {
        ...e,
        quantity: e.amount.total,
        wip: e.amount.total - e.amount.quantity
      }
    })
  interface Column {
    id:
      | "actionDate"
      | "customer"
      | "partNo"
      | "jobOrder"
      | "factory"
      | "quantity"
      | "wip"
      | "remarks"
      | "process"
    label: string
    minWidth?: number
    align?: "right"
    format?: (value: number) => string
  }
  const columns: Column[] = [
    { id: "actionDate", label: "วันที่เบิกวัตถุดิบ", minWidth: 120 },
    { id: "customer", label: "Customer", minWidth: 50 },
    { id: "partNo", label: "PartNo", minWidth: 50 },
    { id: "jobOrder", label: "JobOrder", minWidth: 50 },
    { id: "factory", label: "Factory", minWidth: 50 },
    { id: "quantity", label: "Quantity/Job", minWidth: 50 },
    { id: "wip", label: "WIP", minWidth: 50 },
    { id: "process", label: "Process", minWidth: 50 },
    { id: "remarks", label: "Remarks", minWidth: 50 }
  ]

  const totalQuantityPerJob = rows?.reduce(
    (prev: number, cur: INewProcesses) => {
      return prev + cur.quantity
    },
    0
  )
  const totalQuantityWip = rows?.reduce((prev: number, cur: INewProcesses) => {
    return prev + cur.wip
  }, 0)
  const wipPercent = (totalQuantityWip / totalQuantityPerJob) * 100

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">
          {"Detail Masterplan Report"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table>
              <TableHead>
                <StyledTableRow style={{ borderBottom: "1px solid gray" }}>
                  <StyledTableCell colSpan={5} style={{ textAlign: "center" }}>
                    <img
                      style={{
                        verticalAlign: "top",
                        float: "left"
                      }}
                      height="80vh"
                      src={logoTable}
                      alt=""
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    colSpan={4}
                    style={{
                      fontSize: "20px",
                      textAlign: "center"
                    }}
                  >
                    Work in process : {partData?.partNo}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      className="setBorder"
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth
                      }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, i) => {
                  return (
                    <StyledTableRow hover tabIndex={-1} key={i}>
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        )
                      })}
                    </StyledTableRow>
                  )
                })}

                <StyledTableRow>
                  <StyledTableCell
                    style={{ fontSize: "16px", fontWeight: "bolder" }}
                    colSpan={5}
                  >
                    Total
                  </StyledTableCell>
                  <StyledTableCell>{totalQuantityPerJob}</StyledTableCell>
                  <StyledTableCell>{totalQuantityWip}</StyledTableCell>
                  <StyledTableCell>WIP %</StyledTableCell>
                  <StyledTableCell>
                    {Math.round(wipPercent) || 0}%
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default DialogMasterWip
