import { useTheme } from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useRef } from "react"
import { monthNames } from "../../Maintenance/PreventiveMaintenancePlan"
import { IPMKPI } from "../hook/useFetchData"

export const PMColumnChart = (props: {
  chart?: HighchartsReact.Props
  pmKPI: IPMKPI[]
  setShowChart?: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
  const { pmKPI } = props
  if (!pmKPI.length) return <>Loading...</>

  const theme = useTheme()
  const columnOptions: Highcharts.Options = {
    chart: {
      type: "column",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "Preventive Maintenance ",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },

    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    },
    xAxis: {
      categories: monthNames,
      labels: {
        x: -10,
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "10px"
        }
      }
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "Total",
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      }
    },
    series: [
      {
        type: "column",
        name: "Plan",
        data: pmKPI.map((e) => e.target || 0)
      },
      {
        type: "column",
        name: "Actual",
        data: pmKPI.map((e) => e.actual || 0)
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={columnOptions}
        ref={chartComponentRef}
        {...props.chart}
      />
    </>
  )
}
