import {
  Box,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  useTheme
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { useState } from "react"
import SupplierIn from "./component/SipplierIn"
import SupplierOut from "./component/SipplierOut"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  }
}

const SupplierRecord = (): JSX.Element => {
  const theme = useTheme()
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <PageLabel
          menuItem={menuItemList.SupplierControl}
          menuItemName={Object.keys(menuItemList)[18]}
          menuPageName={"Supplier Record"}
        />
        <ThemedCard>
          <PageLabelCard
            title="Supplier Record"
            subTitle="detail about Supplier record"
          />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Supplier In" {...a11yProps(0)} />
              <Tab label="Supplier Out" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <SupplierIn />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <SupplierOut />
          </CustomTabPanel>
        </ThemedCard>
      </Box>
    </ThemeProvider>
  )
}
export default SupplierRecord
