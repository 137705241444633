import { AutocompleteRenderGetTagProps, Chip, useTheme } from "@mui/material"

export const autocompleteRenderTags = (
  value: readonly string[],
  getTagProps: AutocompleteRenderGetTagProps
): JSX.Element[] => {
  const theme = useTheme()
  return value.map((option: string, index: number) => (
    <Chip
      label={option}
      {...getTagProps({ index })}
      sx={{
        background: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText
      }}
    />
  ))
}
