import { Box, IconButton, useTheme } from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

interface IHighChartsDialogButtonProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  setOpenChart: React.Dispatch<React.SetStateAction<boolean>>
}

const HighChartsDialogButton = (
  props: IHighChartsDialogButtonProps
): JSX.Element => {
  const { setOpenChart, children } = props

  const theme = useTheme()

  return (
    <Box {...props} component="div" sx={{ position: "relative" }}>
      {children}
      <Box
        id="button-svg-wrapper"
        component="div"
        sx={{
          zIndex: 4,
          position: "absolute",
          right: 0,
          top: 0,
          display: "flex",
          justifyContent: "right",
          alignItems: "start"
        }}
      >
        <IconButton
          aria-label={"highchart-dialog-button-icon"}
          onMouseDown={() => setOpenChart(true)}
          sx={{
            color: theme.palette.secondary.contrastText
          }}
        >
          <OpenInNewIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default HighChartsDialogButton
