import {
  Divider,
  useTheme,
  Autocomplete,
  Button,
  Grid,
  Theme
} from "@mui/material"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { ThemeProvider } from "@mui/styles"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { DataGrid } from "bitsoft-c3"
import {
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp
} from "@mui/x-data-grid"
import { useMemo, useState, useRef } from "react"
import { GET_PARTS } from "../../common/Query/MasterQuery"
import { GET_SUPPLIER_CONTROL } from "../../common/Query/SupplierControl"
import Swal from "sweetalert2"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import { DELETE_SUPPLIER_CONTROL } from "../../common/Mutation/SupplierControl"

interface ISupplierControl {
  id: number
  jobOrder: string
  pdLotNo: string
  partNo: string
  boxNo: number
  quantity: number
  NGQty: number
  deliveryDate: string
  dateScan: string
  inOrOut: number
  operator: string
  branchFrom: string
  branchTo: string
  period: string
  carLicense: string
  remark: string
  isNew: boolean
}

const makeColumns = (
  handleDeleteClick: (id: GridRowId) => () => void,

  theme: Theme
): GridColDef[] => {
  const column: GridColDef[] = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <Button
            startIcon={<DeleteIcon />}
            onClick={handleDeleteClick(id)}
            sx={{
              color: theme.palette.text.primary,
              "&:hover": {
                backgroundColor: "transparent",
                color: theme.palette.error.main
              }
            }}
          />
        ]
      }
    },
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center"
    },
    {
      field: "jobOrder",
      headerName: "Job Order",
      minWidth: 250,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "pdLotNo",
      headerName: "pdLot No",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "partNo",
      headerName: "Part No",
      headerAlign: "center",
      minWidth: 180,
      align: "center"
    },
    {
      field: "boxNo",
      headerName: "Box No",
      minWidth: 100,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "center",
      align: "center"
    },
    {
      field: "NGQty",
      headerName: "NG Qty",
      minWidth: 100,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "inOrOut",
      headerName: "in Or Out",
      minWidth: 100,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "deliveryDate",
      headerName: "Delivery Date",
      minWidth: 160,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "dateScan",
      headerName: "Date Scan",
      minWidth: 300,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "operator",
      headerName: "Operator",
      minWidth: 80,
      headerAlign: "center",
      align: "center"
    },

    {
      field: "branchFrom",
      headerName: "Branch From",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "branchTo",
      headerName: "Branch To",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "period",
      headerName: "Period",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "carLicense",
      headerName: "CarLicense",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "remark",
      headerName: "Remark",
      minWidth: 180,
      headerAlign: "center",
      align: "center"
    }
  ]
  return column
}

const SupplierEdit: React.FC = () => {
  const theme = useTheme()
  const [rows, setRows] = useState<GridRowsProp>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const rowIdRef = useRef<GridRowId>()
  const [partNo, setPartNo] = useState<string>("")
  const [pdLotNo, setPdLotNo] = useState<string>("")

  const { data: getPartNo } = useQuery(GET_PARTS)
  const listPartNo: string[] = useMemo(() => {
    if (!getPartNo || !getPartNo?.parts) return []
    else {
      return getPartNo?.parts.map(({ partNo }: { partNo: string }) => {
        return partNo
      })
    }
  }, [getPartNo])

  const { data: getPdLotNo, refetch: refetchSupplierControl } = useQuery(
    GET_SUPPLIER_CONTROL,
    {
      variables: { partNo: partNo }
    }
  )
  const listPdLotNo: string[] = useMemo(() => {
    if (!getPdLotNo && !getPdLotNo?.getSupplierControl) return []
    else {
      return Array.from(
        new Set(
          getPdLotNo?.getSupplierControl
            .map(({ pdLotNo }: { pdLotNo: string }) => {
              return pdLotNo
            })
            .reverse()
        )
      )
    }
  }, [getPdLotNo])

  const [getSupplierControl, { loading: loadingGetSupplierControl }] =
    useLazyQuery(GET_SUPPLIER_CONTROL, {
      onCompleted: (data) => {
        if (data && data.getSupplierControl.length) {
          const result = data?.getSupplierControl.filter(
            (e: ISupplierControl) => {
              return e.partNo === partNo && e.pdLotNo === pdLotNo
            }
          )
          setRows(result)
        } else Swal.fire({ title: `ไม่พบข้อมูล`, icon: "info" })
      }
    })
  const handleSubmit = async () => {
    setRows([])
    if (partNo && pdLotNo) {
      try {
        await getSupplierControl({
          variables: {
            partNo: partNo
          },
          fetchPolicy: "network-only"
        })
      } catch (error) {
        Swal.fire({ title: `${(error as Error).message}`, icon: "error" })
      }
    } else
      Swal.fire({ title: `กรุณาใส่ค่า part No และ pdLot No`, icon: "info" })
  }

  const partNoOnchangeResetTextField = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null | undefined
  ) => {
    if (!value) {
      setPartNo("")
      setPdLotNo("")
    } else {
      setPartNo(value)
    }
  }

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const [letDeleteSupplierControl] = useMutation(DELETE_SUPPLIER_CONTROL)
  const handleSaveData = async (updateRow: ISupplierControl, type: string) => {
    if (type === "delete") {
      try {
        const deleted = await letDeleteSupplierControl({
          variables: {
            pdLotNo: updateRow.pdLotNo,
            partNo: updateRow.partNo,
            boxNo: updateRow.boxNo,
            inOrOut: updateRow.inOrOut
          }
        })
        await refetchSupplierControl()
        return deleted.data?.deleteSupplierControl
      } catch (error) {
        throw new Error((error as Error).message)
      }
    }
  }

  const whatDataWillBeDeleted = async (id: number): Promise<number[]> => {
    const current = (rows as ISupplierControl[]).find(
      (item: ISupplierControl) => item.id === id
    )
    let result: number[] = []

    if (current?.inOrOut === -1) {
      const getData = (rows as ISupplierControl[]).filter(
        (item: ISupplierControl) => {
          return (
            item.pdLotNo === current?.pdLotNo &&
            item.partNo === current?.partNo &&
            item.boxNo >= current?.boxNo &&
            item.inOrOut === current?.inOrOut
          )
        }
      )
      result = getData.map((item: ISupplierControl) => {
        return item.id
      })
    } else if (current?.inOrOut === 1) {
      const getData = (rows as ISupplierControl[]).filter(
        (item: ISupplierControl) => {
          return (
            item.pdLotNo === current?.pdLotNo &&
            item.partNo === current?.partNo &&
            item.boxNo >= current?.boxNo
          )
        }
      )
      result = getData.map((item: ISupplierControl) => {
        return item.id
      })
    }
    return result
  }

  const handleDeleteClick = (id: GridRowId) => async () => {
    const getIDToShowBeforeDelete: number[] = await whatDataWillBeDeleted(
      id as number
    )
    const displayText =
      getIDToShowBeforeDelete.length > 1
        ? `ลบสินค้าขาเข้า InOrOut = 1, มี ID ${getIDToShowBeforeDelete} ที่จะถูกลบ`
        : `ลบสินค้าขาออก InOrOut = -1, มี ID ${getIDToShowBeforeDelete} ที่จะถูกลบ`

    const result = await Swal.fire({
      title: "ลบข้อมูลใช่หรือไม่ ?",
      text: displayText,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ลบข้อมูล",
      cancelButtonText: "ยกเลิก"
    })
    if (result.isConfirmed) {
      try {
        const clickedRowToDelete = rows.find((row) => row.id === id)
        const showResult = await handleSaveData(
          clickedRowToDelete as ISupplierControl,
          "delete"
        )
        if (showResult) {
          await handleSubmit()
          Swal.fire({
            title: "เสร็จสิ้น",
            text: "ลบข้อมูลเรียบร้อย",
            icon: "success"
          })
        }
      } catch (error) {
        Swal.fire({
          title: "ล้มเหลว",
          text: (error as Error).message,
          icon: "error"
        })
      }
    }
  }

  const handleProcessError = async (error: Error) => {
    const id = rowIdRef.current as GridRowId
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
    return Swal.fire({
      title: "error",
      text: error.message,
      icon: "error"
    })
  }

  const columns = makeColumns(handleDeleteClick, theme)

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.SupplierControl}
        menuItemName={Object.keys(menuItemList)[17]}
        menuPageName={"Supplier Edit"}
      />
      <ThemedCard>
        <PageLabelCard
          title="Edit Supplier Control"
          subTitle="Edit Quantity Supplier Control"
        />
        <Divider
          sx={{ height: 1, bgcolor: theme.palette.secondary.contrastText }}
        />
        <br />
        <Grid container spacing={2} sx={{ p: "20px" }}>
          <Grid item xs={4} md={2}>
            <Autocomplete
              value={partNo}
              onChange={partNoOnchangeResetTextField}
              options={listPartNo}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Part No."
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <Autocomplete
              value={pdLotNo}
              onChange={(event, value) => {
                setPdLotNo(value as string)
              }}
              options={listPdLotNo}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="pdLot No."
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={3} md={2}>
            <ThemedLoadingButton
              contrast
              loading={loadingGetSupplierControl}
              variant="outlined"
              sx={{ mt: 1, width: "70%" }}
              onClick={handleSubmit}
            >
              SUBMIT
            </ThemedLoadingButton>
          </Grid>

          <Grid item xs={12}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              dataGridProps={{
                rowModesModel: rowModesModel,
                onRowEditStop: handleRowEditStop,
                // processRowUpdate: processRowUpdate,
                onProcessRowUpdateError: handleProcessError,
                getRowHeight: () => "auto",
                getRowId: (row) => row.id,
                sx: {
                  bgcolor: theme.palette.secondary.main,
                  "& .MuiGrid-root .MuiButtonBase-root": {
                    color: theme.palette.secondary.contrastText,
                    "&:hover": {
                      bgcolor: "rgba(0, 0, 0, 0.1) !important"
                    }
                  },
                  "& .MuiDataGrid-row:nth-of-type(odd)": {
                    backgroundColor: theme.palette.background.default
                  }
                }
              }}
            />
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}
export default SupplierEdit
