import { gql } from "@apollo/client"

export const GET_COMPONENT_Lot = gql`
  query ComponentLot {
    componentLot {
      componentLot
    }
  }
`

export const GET_WIP_REPORT = gql`
  query WipReport(
    $dateFrom: Date
    $dateTo: Date
    $factory: String
    $partNo: String
    $customer: String
  ) {
    wipReport(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
      partNo: $partNo
      customer: $customer
    ) {
      date
      partNo
      machine
      process
      jobOrder
      pdLotNo
      componentLot
      line
      quantity
      iNG
      sNG
      tNG
      supplierNG
      packingBy
      productionWorker
      totalCost
      stampNG
      weldNG
      plateNG
      remarks
      stopTime
      customer
    }
  }
`
export const OEE_REPORT = gql`
  query OeeReport($date: String, $factory: String, $line: [String]) {
    oeeReport(date: $date, factory: $factory, line: $line) {
      machine
      performance
      rateQ
      productivity
      oee
      actual
      target
      ng
      totalQuantity
      startTime
      endTime
    }
  }
`
export const PROBLEM_ALERT = gql`
  query Query {
    problemAlert
  }
`

export const GET_DATA_SCANNER = gql`
  query GetDataScanner($dataInPushGetScanner: IDataInPushGetScanner) {
    getDataScanner(dataInPushGetScanner: $dataInPushGetScanner) {
      id
      partNo
      jobOrder
      pdLotNo
      inOrOut
      actionDate
      quantity
      factory
      packingBy
      remarks
      tNG
      iNG
      sNG
      supplierNG
      machine
      line
      process
      boxNo
      prodDate
      totalCost
      dateScan
      componentLot
      keyBy
      shift
    }
  }
`

export const OEE_MONTHLY_REPORT = gql`
  query OeeReportMonthly($dateFrom: String, $dateTo: String) {
    oeeReportMonthly(dateFrom: $dateFrom, dateTo: $dateTo) {
      line
      oee
    }
  }
`

export const GET_DAILY_PRODUCTION_LIST = gql`
  query DailyProductionList($factory: String) {
    dailyProductionList(factory: $factory) {
      baseRevision {
        revision
        planDate
        pdPlan
        partNo
        partName
        customer
        line
        step
        process
        machine
        jph
        startTime
        endTime
        startTimeOT
        endTimeOT
        totalHour
        totalQuantity
        employee
        plApprove
        pdApprove
        engApprove
        qaApprove
        mtApprove
        factory
      }
      newRevision {
        revision
        planDate
        partNo
        partName
        pdPlan
        customer
        line
        step
        process
        machine
        jph
        startTime
        endTime
        startTimeOT
        endTimeOT
        totalHour
        totalQuantity
        employee
        plApprove
        pdApprove
        engApprove
        qaApprove
        mtApprove
        factory
      }
    }
  }
`

export const GET_MACHINE_RUN_TIME = gql`
  query RunTimeMachine($factory: String, $dateTo: String, $dateFrom: String) {
    runTimeMachine(factory: $factory, dateTo: $dateTo, dateFrom: $dateFrom) {
      machine
      partNo
      line
      factory
      totalHour
      stopTime
      setupTime
      planDate
      totalTime
    }
  }
`

export const UPDATE_STOP_MACHINE = gql`
  mutation ProblemUpdate($dataProblem: DataProblem!) {
    problemUpdate(dataProblem: $dataProblem)
  }
`
export const DELETE_STOP_MACHINE = gql`
  mutation ProblemDelete($problemDeleteId: Int!) {
    problemDelete(id: $problemDeleteId)
  }
`

export const GET_MACHINE_BREAK_DOWN = gql`
  query MachineBreakdownReport($machine: String!) {
    machineBreakdownReport(machine: $machine) {
      id
      machine
      partNo
      process
      actionDate
      startTime
      endTime
      totalTime
      problemName
      remarks
      detailRepair {
        problemName
        correction
        protection
      }
    }
  }
`

export const GET_MACHINE_PROBLEM = gql`
  query ProblemMachineList($actionDate: String, $line: [String]) {
    problemMachineList(actionDate: $actionDate, line: $line) {
      startTime
      endTime
      problemName
      machine
    }
  }
`

export const GET_BREAKDOWN_LIST = gql`
  query ProblemList {
    problemList {
      id
      problem_name
    }
  }
`
