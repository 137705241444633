import { Checkbox, CheckboxProps, useTheme } from "@mui/material"

const ThemedCheckbox = (props: CheckboxProps): JSX.Element => {
  const theme = useTheme()
  const themedCheckbox = (
    <Checkbox
      {...props}
      sx={{
        ...props.sx,
        color: {
          color:
            theme.palette.mode === "light"
              ? theme.palette.primary.main
              : theme.palette.warning.main,
          "&.Mui-checked": {
            color:
              theme.palette.mode === "light"
                ? theme.palette.primary.main
                : theme.palette.warning.main
          }
        }
      }}
    />
  )
  return <>{themedCheckbox}</>
}

export default ThemedCheckbox
