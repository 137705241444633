import { Radio, RadioProps, useTheme } from "@mui/material"

const ThemedRadio = (props: RadioProps): JSX.Element => {
  const theme = useTheme()
  const themedRadio = (
    <Radio
      {...props}
      sx={{
        color: "#666666",
        "& .Mui-checked": {
          borderColor: theme.palette.warning.main
        }
      }}
    />
  )
  return <>{themedRadio}</>
}

export default ThemedRadio
