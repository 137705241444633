import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  Box,
  Grid,
  Autocomplete,
  FormControlLabel,
  CardHeader,
  useTheme,
  ThemeProvider
} from "@mui/material"
import moment from "moment"
import React, { useMemo, useState } from "react"
import { ADD_MATERIAL_OUT } from "../../common/Mutation/MaterialMutation"
import { GET_MATERIALS, GET_PARTS } from "../../common/Query/MasterQuery"
import { GET_PART_MATSTOCK } from "../../common/Query/MaterialQuery"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { GET_PART_PROCESS } from "../../common/Query/ScannerQuery"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCheckbox from "../../common/Resources/ThemedComponents/ThemedCheckbox"

interface DataInvoiceList {
  invoice: string[]
  totalQuantity: number
}
const pageTitle = "Materials'Out"

const MatOut: React.FC = () => {
  const theme = useTheme()
  const [getPartStock] = useLazyQuery(GET_PART_MATSTOCK)
  const { data } = useQuery(GET_MATERIALS)
  const [jobOrder, setJobOrder] = useState<string>("")
  const [pdLotNo, setPdLotNo] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [actionDate, setActionDate] = useState(moment().format("YYYY-MM-DD"))
  const [quantity, setQuantity] = useState<number>(0)
  const [deliveryDate, setDeliveryDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  )
  const [wipBox, setWipBox] = useState<number>(0)
  const [operator, setOperator] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [invoiceNo, setInvoiceNo] = useState<string>("")
  const [invoiceList, setInvoiceList] = useState<DataInvoiceList>({
    invoice: [],
    totalQuantity: 0
  })
  const [remarks, setRemarks] = useState("")
  const [isNG, setIsNG] = useState<boolean>(false)
  const [lotSize, setLotSize] = useState<number>(0)
  const [prodDate, setProdDate] = useState(moment().format("YYYY-MM-DD"))
  const [totalHours, setTotalHours] = useState<string>("")
  const getPart = useQuery(GET_PARTS)
  const [createMatOut, { loading }] = useMutation(ADD_MATERIAL_OUT)
  const [getPartProcess] = useLazyQuery(GET_PART_PROCESS)

  let partNoList: Array<string> = []

  partNoList = getPart.data
    ? getPart.data.parts.map((e: { partNo: string }) => e.partNo)
    : []

  useMemo(async () => {
    if (partNo !== null) {
      const getInvoice = await getPartStock({
        variables: { partNo: partNo },
        fetchPolicy: "network-only"
      })
      if (getInvoice?.data?.partMatStock) {
        setInvoiceList(getInvoice.data.partMatStock)
        setFactory(getInvoice.data.partMatStock.factory)
      }
      if (data && data.materials) {
        const material = data.materials.find(
          (mat: { matNo: string }) => partNo === mat.matNo
        )
        setWipBox(material?.wipQtyBox)
        if (quantity !== null) {
          const ratio = material?.ratio
          setLotSize(Math.floor(quantity * ratio))
        }
      }
      if (lotSize) {
        const partProcessing = await getPartProcess({
          variables: {
            partNo
          }
        })
        const jph = partProcessing.data
          ? partProcessing.data.partProcesses
              .filter((data: { jph: number }) => data.jph !== 0)
              .reduce(
                (previous: { jph: number }, current: { jph: number }) =>
                  current.jph < previous.jph ? current : previous,
                { jph: Infinity }
              )
          : { jph: 0 }
        const time = lotSize / jph.jph
        const minute = (time % 1) * 60
        const hr = Math.floor(time)

        setTotalHours(`${hr} ชม ${minute} นาที`)
      }
      setJobOrder(
        moment(actionDate).format("DD-MM-YY") + "|" + partNo + "|" + invoiceNo
      )
      setPdLotNo(moment(prodDate).format("DD-MM-YY") + "|" + partNo)
    }
  }, [data, getPartStock, invoiceNo, partNo, prodDate, quantity, lotSize, actionDate])

  const isProductionDate = () => {
    const dateLimit = 15
    const productionDate = new Date(prodDate)
    const targetDate = new Date(deliveryDate)
    targetDate.setDate(targetDate.getDate() - dateLimit)
    return targetDate <= productionDate
  }
  const isComplete = () => {
    return (
      partNo !== "" &&
      actionDate !== "" &&
      quantity !== 0 &&
      operator !== "" &&
      invoiceNo !== ""
    )
  }

  const handleSubmit = async () => {
    if (!isProductionDate()) {
      return swal("Error", "วันที่ผลิตเร็วเกิน", "warning")
    }
    if (!isComplete()) {
      return swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
    if (quantity > invoiceList.totalQuantity) {
      return swal("Error", "จำนวนที่เบิกไม่ถูกต้อง", "warning")
    }
    try {
      const res = await createMatOut({
        variables: {
          matNo: partNo,
          partNo,
          jobOrder,
          pdLotNo,
          inOrOut: -1,
          actionDate,
          quantity,
          lotSize,
          factory,
          packingBy: operator,
          isNG: isNG ? 1 : 0,
          perBox: wipBox,
          remarks,
          invoiceNo,
          deliveryDate,
          prodDate
        }
      })

      if (res) {
        onReset()
        return swal(
          "Success",
          "Removed Outgoing Material From Stock!",
          "success"
        )
      }
    } catch (error) {
      return swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  const onReset = () => {
    setFactory("")
    setPartNo("")
    setInvoiceNo("")
    setQuantity(0)
    setRemarks("")
    setJobOrder("")
    setPdLotNo("")
  }

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Materials}
        menuItemName={Object.keys(menuItemList)[1]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <CardHeader
          titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
          title="Mat'l Out"
          subheaderTypographyProps={{ fontFamily: "Sarabun" }}
          subheader="Remove outgoing material from supply"
        />

        <Box component="form" style={{ padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                id="partNo"
                options={partNoList}
                style={{ width: "100%" }}
                sx={{ width: 300 }}
                value={partNo}
                onChange={(e, newValue) => {
                  setPartNo(newValue ? newValue : "")
                  setInvoiceNo("")
                  setQuantity(0)
                  setRemarks("")
                  setJobOrder("")
                  setPdLotNo("")
                }}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Part No" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                id="invNO"
                value={invoiceNo}
                options={invoiceList.invoice || []}
                style={{ width: "100%" }}
                sx={{ width: 300 }}
                onChange={(e, newValue) =>
                  setInvoiceNo(newValue ? newValue : "")
                }
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Invoice No"
                    value={invoiceNo}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                value={jobOrder}
                id="joborder"
                label="Job Order"
                type="text"
                disabled
                autoComplete="family-name"
                onChange={(e) => setJobOrder(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemedTextField
                fullWidth
                className="disable-back-ground-color"
                value={pdLotNo}
                id="pdLotNo"
                label="Production Lot No."
                type="text"
                disabled
                autoComplete="family-name"
                onChange={(e) => setPdLotNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <ThemedTextField
                className="disable-back-ground-color"
                fullWidth
                value={invoiceNo ? invoiceList.totalQuantity : ""}
                id="current-stock"
                label="Current Stock"
                type="text"
                disabled
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemedTextField
                fullWidth
                value={actionDate}
                id="actiondate"
                label="วันที่เบิก"
                type="date"
                autoComplete="family-name"
                onChange={(e) => setActionDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemedTextField
                fullWidth
                id="quantity"
                label="Quantity Mat'l"
                value={quantity === 0 ? "" : quantity}
                autoComplete="family-name"
                onChange={(e) => setQuantity(parseFloat(e.target.value))}
                type="number"
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemedTextField
                fullWidth
                value={operator}
                id="operator"
                label="Operator"
                type="text"
                autoComplete="family-name"
                onChange={(e) => setOperator(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                fullWidth
                value={deliveryDate}
                id="deliveryDate"
                label="วันที่จัดส่ง"
                type="date"
                autoComplete="family-name"
                onChange={(e) => setDeliveryDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                fullWidth
                value={prodDate}
                id="prodDate"
                label="วันที่ผลิต"
                type="date"
                autoComplete="family-name"
                onChange={(e) => setProdDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                fullWidth
                className="disable-back-ground-color"
                disabled
                id="wipBox"
                label="WIP Qty Box"
                value={`${wipBox}`}
                type="number"
                autoComplete="family-name"
                onChange={(e) => setWipBox(+e.target.value)}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                fullWidth
                disabled
                className="disable-back-ground-color"
                value={lotSize}
                id="lotsize"
                label="Lot Size"
                type="number"
                autoComplete="family-name"
                onChange={(e) => setLotSize(+e.target.value)}
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
              />
            </Grid>
            <Grid item xs={12}>
              Total Hours :{totalHours}
            </Grid>
            <Grid item xs={12}>
              <ThemedTextField
                fullWidth
                value={remarks}
                id="remarks"
                label="Remarks"
                type="text"
                autoComplete="family-name"
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <ThemedCheckbox onChange={(e) => setIsNG(e.target.checked)} />
                }
                label="is NG?"
              />
            </Grid>
          </Grid>
          <ThemedLoadingButton
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
            onClick={handleSubmit}
          >
            Submit
          </ThemedLoadingButton>
        </Box>
      </ThemedCard>
    </ThemeProvider>
  )
}
export default MatOut
