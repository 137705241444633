import { gql } from "@apollo/client"

export const GET_WH_LOCATION = gql`
  query WhLocationReport {
    whLocationReport {
      partNo
      pdLotNo
      boxNo
      jobOrder
      location
      quantity
    }
  }
`
export const GET_WH_TAG_REPORT = gql`
  query WhTagReport {
    whTagReport {
      partNo
      partName
      customer
      model
      quantity
      totalQuantity
      packingBy
      recieveDate
      pdLotNo
      remarks
      boxCount
    }
  }
`

export const GET_WH_FG_REPORT_ALL = gql`
  query WhFGReportAll($partNo: String, $factory: String) {
    whFGReportAll(partNo: $partNo, factory: $factory) {
      id
      partNo
      pdLotNo
      inOrOut
      actionDate
      quantity
      factory
      packingBy
      isNG
      dateScan
      remarks
    }
  }
`

export const GET_WH_FG_REPORT = gql`
  query WhFGReport(
    $partNo: String
    $factory: String
    $dateFrom: String
    $dateTo: String
    $pdLotNo: String
  ) {
    whFGReport(
      partNo: $partNo
      factory: $factory
      dateFrom: $dateFrom
      dateTo: $dateTo
      pdLotNo: $pdLotNo
    ) {
      date
      in {
        date
        actionDate
        dateScan
        factory
        inOrOut
        newInOrOut
        isNG
        packingBy
        partNo
        pdLotNo
        quantity
        remarks
        total
      }
      out {
        date
        actionDate
        dateScan
        factory
        inOrOut
        newInOrOut
        isNG
        packingBy
        partNo
        pdLotNo
        quantity
        remarks
        total
      }
      total
    }
  }
`

export const GET_TEST_SCAN_WH = gql`
  query TestScannerWH($data: DataTestScannerWH) {
    testScannerWH(data: $data) {
      partNo
      pdLotNo
      jobOrder
      quantity
      boxNo
    }
  }
`
export const GET_WH_TRANSPORTS = gql`
  query Query($data: DataTestScannerWH) {
    getWHtransports(data: $data)
  }
`

export const GET_WH_LOCATION_SCAN = gql`
  query GetWHLocationScan {
    getWHLocationScan {
      partNo
      pdLotNo
      jobOrder
      quantity
      boxNo
      location
    }
  }
`

export const GET_WH_TRANSPORTS_BOX_NO = gql`
  query GetWHTransportBoxNo($data: DataIInputWHTransport) {
    getWHTransportBoxNo(data: $data) {
      boxNo
    }
  }
`

export const GET_WH_FIFO = gql`
  query GetFifoWarehouseTransport($data: DataIInputGetFifo) {
    getFifoWarehouseTransport(data: $data) {
      partNo
      jobOrder
      pdLotNo
      boxNo
      prodDate
    }
  }
`
export const GET_INVENTORY_FG_REPORT = gql`
  query FgInventoryMaterialReport(
    $date: String
    $customer: String
    $partNo: String
    $factory: String
    $typeMat: String
  ) {
    fgInventoryMaterialReport(
      date: $date
      customer: $customer
      partNo: $partNo
      factory: $factory
      typeMat: $typeMat
    ) {
      partNo
      balanceMaterial
      cost
      maxStock
      minStock
      saleOrder
      remarks
      factory
      customer
      model
      partName
      typeMat
    }
  }
`

export const GET_ORDER_FG_OUT = gql`
  query FgOutMMTh($mmThFgOut: IMMThFGOut) {
    fgOutMMTh(mmThFGOut: $mmThFgOut) {
      partNo
      actionDate
      factory
      quantity
      remarks
      packingBy
      currentStock
      status
      plating
    }
  }
`
export const GET_PD_LOT_NO_FG = gql`
  query Query($partNo: String, $factory: String) {
    getPdLotNoFG(partNo: $partNo, factory: $factory)
  }
`
