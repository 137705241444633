import { useEffect, useState } from "react"
import { IMasterPlanPartData, IOrder } from "../MPReport"

const compare = (a: { partNo: string }, b: { partNo: string }) => {
  if (a.partNo < b.partNo) {
    return -1
  }
  if (a.partNo > b.partNo) {
    return 1
  }
  return 0
}
interface IMP {
  maxDueDate: string[]
  masterPlanPartData: IMasterPlanPartData[]
}

const sortPartsByNegativeBalActual = (
  response: IMasterPlanPartData[],
  negativePartF1: boolean,
  negativePartF2: boolean,
  negativePartFollowUp: boolean,
  dateFrom: string
) => {
  return response.sort((a, b) => {
    const aFirstDayNegative = a.order.find((val) => {
      if (negativePartF1) {
        return val.balActual < 0
      }
      if (negativePartF2) {
        return val.balActual2 < 0
      }
      if (negativePartFollowUp) {
        return val.balActualFollowUp < 0
      }
    })
    const bFirstDayNegative = b.order.find((val) => {
      if (negativePartF1) {
        return val.balActual < 0
      }
      if (negativePartF2) {
        return val.balActual2 < 0
      }
      if (negativePartFollowUp) {
        return val.balActualFollowUp < 0
      }
    })
    let diffDateA = 0
    let diffDateB = 0

    if (aFirstDayNegative) {
      diffDateA =
        new Date(dateFrom).getTime() -
        new Date(aFirstDayNegative.dueDate).getTime()
    }
    if (bFirstDayNegative) {
      diffDateB =
        new Date(dateFrom).getTime() -
        new Date(bFirstDayNegative.dueDate).getTime()
    }

    if (diffDateA > diffDateB) {
      return -1
    } else if (diffDateA < diffDateB) {
      return 1
    } else {
      if (
        negativePartF1 &&
        aFirstDayNegative &&
        bFirstDayNegative &&
        aFirstDayNegative.balActual < bFirstDayNegative.balActual
      ) {
        return -1
      }
      if (
        negativePartF2 &&
        aFirstDayNegative &&
        bFirstDayNegative &&
        aFirstDayNegative.balActual2 < bFirstDayNegative.balActual2
      ) {
        return -1
      }
      if (
        negativePartFollowUp &&
        aFirstDayNegative &&
        bFirstDayNegative &&
        aFirstDayNegative.balActualFollowUp <
          bFirstDayNegative.balActualFollowUp
      ) {
        return -1
      }
      return 0
    }
  })
}

export const useMasterPlan = (
  data: IMasterPlanPartData[],
  negativePartF1: boolean,
  negativePartF2: boolean,
  negativePartFollowUp: boolean,
  dateFrom: string
): IMP => {
  const [maxDueDate, setMaxDueDate] = useState<string[]>([])
  const [masterPlanPartData, setMasterPlanPartData] = useState<
    IMasterPlanPartData[]
  >([])
  useEffect(() => {
    const allDueDate: Array<string> = []
    if (data) {
      data.forEach((e: { order: Array<IOrder> }) => {
        e.order.forEach((e) => {
          allDueDate.push(e.dueDate)
        })
      })
      const newDueDate = Array.from(new Set(allDueDate))
      setMaxDueDate(newDueDate.sort())
      const dataReport = [...data]
      if (
        dataReport.length > 0 &&
        (negativePartF1 || negativePartF2 || negativePartFollowUp)
      ) {
        sortPartsByNegativeBalActual(
          dataReport,
          negativePartF1,
          negativePartF2,
          negativePartFollowUp,
          dateFrom
        )
      } else {
        dataReport.sort(compare)
      }

      setMasterPlanPartData(
        dataReport.map((e, i: number) => {
          return {
            ...e,
            index: i + 1
          }
        })
      )
    }
  }, [data])
  return { maxDueDate, masterPlanPartData }
}
