import { gql } from "@apollo/client"

export const ADD_MATERIAL_IN = gql`
  mutation CreateMatIn(
    $matNo: String!
    $inOrOut: Int!
    $actionDate: Date!
    $quantity: Float!
    $factory: String!
    $packingBy: String!
    $invoiceNo: String!
    $smQty: Float!
    $supplier: String!
    $jobOrder: String
    $remarks: String
  ) {
    createMatIn(
      matNo: $matNo
      inOrOut: $inOrOut
      actionDate: $actionDate
      quantity: $quantity
      factory: $factory
      packingBy: $packingBy
      invoiceNo: $invoiceNo
      smQty: $smQty
      supplier: $supplier
      jobOrder: $jobOrder
      remarks: $remarks
    ) {
      indexNo
      matNo
      inOrOut
      actionDate
      quantity
      factory
      packingBy
      jobOrder
      remarks
      isNG
      invoiceNo
      deliveryDate
      dateScan
      smQty
      supplier
    }
  }
`
export const ADD_MATERIAL_OUT = gql`
  mutation CreateMatOut(
    $matNo: String!
    $partNo: String!
    $jobOrder: String!
    $pdLotNo: String!
    $actionDate: Date!
    $quantity: Float!
    $lotSize: Float!
    $packingBy: String!
    $isNG: Int!
    $perBox: Int!
    $invoiceNo: String!
    $deliveryDate: Date!
    $remarks: String
    $factory: String
    $prodDate: String
  ) {
    createMatOut(
      matNo: $matNo
      partNo: $partNo
      jobOrder: $jobOrder
      pdLotNo: $pdLotNo
      actionDate: $actionDate
      quantity: $quantity
      lotSize: $lotSize
      packingBy: $packingBy
      isNG: $isNG
      perBox: $perBox
      invoiceNo: $invoiceNo
      deliveryDate: $deliveryDate
      remarks: $remarks
      factory: $factory
      prodDate: $prodDate
    ) {
      indexNo
      matNo
      inOrOut
      actionDate
      quantity
      factory
      packingBy
      jobOrder
      remarks
      isNG
      invoiceNo
      deliveryDate
      dateScan
      smQty
      supplier
    }
  }
`
export const MAT_OUT_SCANNER = gql`
  mutation Mutation($inputMatOut: IInputData) {
    matOutScanner(inputMatOut: $inputMatOut)
  }
`

export const UPDATE_WIP_STOCK = gql`
  mutation UpdateWipStock(
    $indexNo: Int!
    $quantity: Float
    $perBox: Float
    $updatedBy: String
    $updatedDate: String
  ) {
    updateWipStock(
      indexNo: $indexNo
      quantity: $quantity
      perBox: $perBox
      updatedBy: $updatedBy
      updatedDate: $updatedDate
    ) {
      indexNo
      matNo
      partNo
      jobOrder
      pdLotNo
      inOrOut
      actionDate
      quantity
      factory
      packingBy
      remarks
      isNG
      perBox
      dateScan
      updatedBy
      updatedDate
    }
  }
`

export const UPDATE_MAT_STOCK = gql`
  mutation UpdateMatStock(
    $indexNo: Int!
    $remarks: String
    $smQty: Float
    $updatedBy: String
    $updatedDate: String
  ) {
    updateMatStock(
      indexNo: $indexNo
      remarks: $remarks
      smQty: $smQty
      updatedBy: $updatedBy
      updatedDate: $updatedDate
    ) {
      indexNo
      matNo
      inOrOut
      actionDate
      quantity
      factory
      packingBy
      jobOrder
      remarks
      isNG
      invoiceNo
      deliveryDate
      dateScan
      smQty
      supplier
      updatedBy
      updatedDate
    }
  }
`

export const DELETE_MAT_STOCK = gql`
  mutation DeleteMatStock(
    $indexNo: Int!
    $matNo: String!
    $inOrOut: Int!
    $invoiceNo: String!
    $updatedBy: String!
    $fromPage: String!
  ) {
    deleteMatStock(
      indexNo: $indexNo
      matNo: $matNo
      inOrOut: $inOrOut
      invoiceNo: $invoiceNo
      updatedBy: $updatedBy
      fromPage: $fromPage
    )
  }
`
