import { styled, Table, TableBody } from "@mui/material"
import moment from "moment"
import QRCode from "qrcode.react"
import { bankImage, getImagePng, miniLogo } from "../../../common/utilities"
import "../../../css/Planning/jobTable.css"

interface IProcesses {
  process: string
  processOrder: string
  complete: string
  line: string
  machine: string
  jph: number
  minStroke: number
  partNo: string
  stepProcess: number
  amount: {
    complete: string
    ng: number
    quantity: number
    total: number
  }
}

interface Prop {
  lotData?: {
    pdLotNo?: string
    leadTime?: string
    customer?: string
    partName?: string
    matQuantity?: number
    quantity?: number
    spec?: number
    partType?: string
    sizeL?: string
    sizeW?: string
    sizeH?: string
    ratio?: number
    perBox?: number
    packingBy?: string
    deliveryDate?: string
  }
  jobOrder?: string | null
  partNo?: string | null

  processes?: IProcesses[]
}
const Root = styled("div")(
  () => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
    }

    td,
    th {
      white-space: nowrap;
      border: 1px solid black;
      padding: 5px
    }


    `
)
export default function Welcome(props: Prop): JSX.Element {
  const getSize = () => {
    const matData = props.lotData
    if (!matData?.partType) return
    if (matData.partType === "Component") {
      return `${matData.sizeL}×${matData.sizeW}`
    }
    if (matData.partType === "Coil") {
      return `${matData.sizeH}×${matData.sizeW}×${matData.sizeL}`
    }
    return `${matData.sizeH}×${matData.sizeW}×${matData.sizeL}`
  }
  const { processes } = props

  function compare(a: { stepProcess: number }, b: { stepProcess: number }) {
    if (a.stepProcess < b.stepProcess) {
      return -1
    }
    if (a.stepProcess > b.stepProcess) {
      return 1
    }
    return 0
  }

  const PartProcesses = () => {
    if (props?.processes?.length !== 0) props?.processes?.sort(compare)
    return (
      <>
        {Array.from(Array(10), (e, i) => {
          return (
            <td colSpan={2} key={i}>
              <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                {processes && i < processes?.length
                  ? processes && processes[i]?.process
                  : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  const PartProcessOrder = () => {
    if (props?.processes?.length !== 0) props?.processes?.sort(compare)
    return (
      <>
        {Array.from(Array(10), (e, i) => {
          return (
            <td colSpan={2} key={i}>
              <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                {processes && i < processes?.length
                  ? processes &&
                    processes[i]?.processOrder + processes[i].complete
                  : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  const LineProcessOrder = () => {
    if (props?.processes?.length !== 0) props?.processes?.sort(compare)
    return (
      <>
        {Array.from(Array(10), (e, i) => {
          return (
            <td colSpan={2} key={i}>
              <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                {processes && i < processes?.length
                  ? processes && processes[i]?.line
                  : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  const FlowProcess = () => {
    if (props?.processes?.length !== 0) props?.processes?.sort(compare)
    return (
      <>
        {Array.from(Array(10), (e, i) => {
          return (
            <td colSpan={2} key={i}>
              <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                {processes && i < processes?.length ? i + 1 : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  const MachineProcess = () => {
    if (props?.processes?.length !== 0) props?.processes?.sort(compare)
    return (
      <>
        {Array.from(Array(10), (e, i) => {
          return (
            <td colSpan={2} key={i}>
              <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                {processes && i < processes?.length ? processes[i].machine : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  const JPH = () => {
    if (props?.processes?.length !== 0) props?.processes?.sort(compare)
    return (
      <>
        {Array.from(Array(10), (e, i) => {
          return (
            <td colSpan={2} key={i}>
              <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                {processes && i < processes?.length ? processes[i].jph : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  const InAmount = () => {
    if (processes?.length !== 0) processes?.sort(compare)
    return (
      <>
        {Array.from(Array(10), (e, i) => {
          return (
            <td colSpan={2} key={i} style={{ minWidth: "40px" }}>
              <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                {processes && i < processes?.length
                  ? processes[i].amount.total
                  : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  const OutAmount = () => {
    if (processes?.length !== 0) processes?.sort(compare)
    return (
      <>
        {Array.from(Array(10), (e, i) => {
          return (
            <td colSpan={2} key={i} style={{ minWidth: "40px" }}>
              <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                {processes && i < processes?.length
                  ? `${processes[i].amount.quantity} ${processes[i].amount.complete}`
                  : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  const NgAmount = () => {
    if (processes?.length !== 0) processes?.sort(compare)
    return (
      <>
        {Array.from(Array(10), (e, i) => {
          return (
            <td colSpan={2} key={i} style={{ minWidth: "40px" }}>
              <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                {processes && i < processes?.length
                  ? processes[i].amount.ng
                  : ""}
              </div>
            </td>
          )
        })}
      </>
    )
  }

  return (
    <div>
      <Root>
        <Table
          // className="table table-bordered"
          style={{
            border: "1px solid black",
            backgroundColor: "white",
            color: "black"
          }}
        >
          <TableBody>
            <tr>
              <th
                rowSpan={1}
                colSpan={2}
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  height: "30%"
                }}
              >
                <img src={miniLogo} alt="" height={40} />
              </th>
              <th rowSpan={1} colSpan={5} style={{ padding: "0px" }}>
                <h1 style={{ whiteSpace: "nowrap" }}>
                  ใบสั่งผลิต/ใบเบิกวัตถุดิบ
                </h1>
              </th>
              <th rowSpan={1} colSpan={4}>
                ( ☐ ต้นฉบับ ☐ สำเนา )
              </th>
              <th rowSpan={1} colSpan={3}>
                <h3>Job Order No.</h3>
              </th>
              <td rowSpan={1} colSpan={7}>
                <span
                  style={{
                    fontSize: "24px",
                    whiteSpace: "nowrap"
                  }}
                >
                  {props.jobOrder}
                </span>
              </td>
              <th
                rowSpan={1}
                colSpan={5}
                style={{ fontSize: "10px", whiteSpace: "nowrap" }}
              >
                <div>F-PL-03-05</div>
                <div>
                  Effective Date: 11/01/21 <br /> Rev.01 Issue: 1
                </div>
              </th>
            </tr>
            <tr>
              <th rowSpan={1} colSpan={3}>
                วัน/เดือน/ปี ผลิต
              </th>
              <td rowSpan={1} colSpan={6}>
                {props.lotData ? props.lotData.pdLotNo?.split("|")[0] : ""}
              </td>
              <th rowSpan={1} colSpan={2}>
                Lead Time
              </th>
              <td rowSpan={1} colSpan={2}>
                {props.lotData?.leadTime} วัน
              </td>

              <th rowSpan={1} colSpan={2}>
                วันที่เสร็จ
              </th>
              <td rowSpan={1} colSpan={2}>
                {props.lotData
                  ? moment(props.lotData.pdLotNo?.split("|")[0], "DD-MM-YY")
                      .add(props.lotData.leadTime, "days")
                      .format("DD-MM-YY")
                  : ""}
              </td>
              <th rowSpan={1} colSpan={2}>
                Delivery Date
              </th>
              <td rowSpan={1} colSpan={6}>
                {props.lotData?.deliveryDate}
              </td>
            </tr>
            <tr>
              <th rowSpan={1} colSpan={3}>
                ลูกค้า
              </th>
              <td rowSpan={1} colSpan={4}>
                {props.lotData?.customer}
              </td>
              <th rowSpan={1} colSpan={2}>
                Part No.
              </th>
              <td rowSpan={1} colSpan={4}>
                {props.partNo}
              </td>
              <th rowSpan={1} colSpan={2}>
                Part Name
              </th>
              <td rowSpan={1} colSpan={4}>
                {props.lotData?.partName}
              </td>
              <th rowSpan={1} colSpan={2}>
                จำนวน
              </th>
              <td rowSpan={1} colSpan={4}>
                {props.lotData?.quantity} pcs
              </td>
            </tr>
            <tr>
              <th rowSpan={1} colSpan={3}>
                Spec.
              </th>
              <td rowSpan={1} colSpan={5}>
                {props.lotData?.spec}
              </td>
              <th rowSpan={1} colSpan={3}>
                Mat Size
              </th>
              <td rowSpan={1} colSpan={4}>
                {getSize()}
              </td>
              <th rowSpan={1} colSpan={2}>
                PD Lot No.
              </th>
              <td rowSpan={1} colSpan={8}>
                {props.lotData?.pdLotNo}
              </td>
            </tr>
            <tr>
              <th rowSpan={1} colSpan={3}>
                Ratio
              </th>
              <td rowSpan={1} colSpan={2}>
                {props.lotData?.ratio}
              </td>
              <th rowSpan={1} colSpan={8}>
                จำนวนที่เบิก Mat'l Sheet/Coil
              </th>
              <td rowSpan={1} colSpan={4}>
                {props.lotData?.matQuantity}
              </td>
              <th rowSpan={1} colSpan={5}>
                WIP Std. Pack
              </th>
              <td rowSpan={1} colSpan={4}>
                {props.lotData?.perBox}
              </td>
            </tr>

            <tr>
              <th rowSpan={1} colSpan={2} style={{ whiteSpace: "nowrap" }}>
                Flow Process
              </th>
              <FlowProcess />
              <th rowSpan={5} colSpan={2} style={{ padding: "0px" }}>
                <img
                  src={
                    props.partNo
                      ? getImagePng(`images/parts/${props?.partNo}`)
                      : bankImage
                  }
                  style={{ maxHeight: "100px", maxWidth: "100px" }}
                  alt=""
                />
              </th>
            </tr>
            <tr>
              <th rowSpan={1} colSpan={2} style={{ whiteSpace: "nowrap" }}>
                Process Name
              </th>
              <PartProcesses />
            </tr>
            <tr>
              <th rowSpan={1} colSpan={2}>
                step
              </th>
              <PartProcessOrder />
            </tr>

            <tr>
              <th rowSpan={1} colSpan={2}>
                Line
              </th>
              <LineProcessOrder />
            </tr>
            <tr>
              <th rowSpan={1} colSpan={2}>
                M/c No.
              </th>
              <MachineProcess />
            </tr>
            <tr>
              <th rowSpan={1} colSpan={2}>
                JPH
              </th>
              <JPH />
              <th rowSpan={4} colSpan={2}>
                <QRCode
                  value={props?.jobOrder ? props?.jobOrder : ""}
                  size={70}
                />
              </th>
            </tr>
            <tr>
              <th rowSpan={1} colSpan={2}>
                In (ชิ้น)
              </th>
              <InAmount />
            </tr>
            <tr>
              <th rowSpan={1} colSpan={2}>
                Out (ชิ้น)
              </th>
              <OutAmount />
            </tr>
            <tr>
              <th rowSpan={1} colSpan={2}>
                NG (ชิ้น)
              </th>
              <NgAmount />
            </tr>
            <tr style={{ height: "60px" }}>
              <th rowSpan={1} colSpan={2}>
                {props.lotData?.packingBy}
              </th>
              <th rowSpan={1} colSpan={2}>
                <>
                  <img
                    src={getImagePng("images/signature/signature_nut")}
                    width={"55px"}
                  />
                </>
              </th>
              <th rowSpan={2} colSpan={4} />
              <th rowSpan={2} colSpan={3} />
              <th rowSpan={2} colSpan={3} />
              <th rowSpan={2} colSpan={3} />
              <th rowSpan={2} colSpan={3} />
              <th rowSpan={2} colSpan={2} />
              <th rowSpan={2} colSpan={3} />
            </tr>
            <tr />
            <tr>
              <th rowSpan={1} colSpan={2}>
                ผู้ออก Job Order
              </th>
              <th rowSpan={1} colSpan={2}>
                ผู้อนุมัติ Job Order
              </th>
              <th rowSpan={1} colSpan={4}>
                ฝ่ายผลิต
              </th>
              <th rowSpan={1} colSpan={3}>
                สโตร์ Mat'l
              </th>
              <th rowSpan={1} colSpan={3}>
                Plating
              </th>
              <th rowSpan={1} colSpan={3}>
                IPQA/OQA
              </th>
              <th rowSpan={1} colSpan={3}>
                คลังสิ้นค้า
              </th>
              <th rowSpan={1} colSpan={2}>
                จัดส่ง
              </th>
              <th rowSpan={1} colSpan={3}>
                PL
              </th>
            </tr>
          </TableBody>
        </Table>
      </Root>
    </div>
  )
}
