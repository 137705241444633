import { useTheme } from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { ReactElement, useRef } from "react"

interface IData {
  totalFG: number
  totalSaleOrder: number
  date: string
}

const MultiChart = (props: {
  chart?: HighchartsReact.Props
  data: IData
}): ReactElement => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]

  const month = months[new Date(props.data.date).getMonth()]
  const year = new Date(props.data.date).getFullYear()

  const theme = useTheme()

  const options: Highcharts.Options = {
    title: {
      text: "กราฟเเสดงการเปรียบเทียบ Sale Order VS FG Inventory",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    chart: {
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    xAxis: {
      categories: [month + "  " + year],
      crosshair: true,
      className: "xAxis-style"
    },
    yAxis: [
      {
        title: {
          text: "Total"
        }
      },
      {
        opposite: true,
        title: {
          text: ""
        }
      }
    ],
    tooltip: {
      headerFormat: '<span style="font-size:8px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="padding:0">{series.name}: </td>' +
        '<td style="padding:3"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.02,
        borderRadius: 5
      }
    },
    series: [
      {
        name: "Sale Order",
        type: "column",
        data: [props.data.totalSaleOrder],
        dataLabels: [
          {
            enabled: true,
            inside: true,
            style: {
              fontSize: "24px"
            }
          }
        ]
      },
      {
        name: "Inventory FG",
        type: "column",
        data: [props.data.totalFG],
        dataLabels: [
          {
            enabled: true,
            inside: true,
            style: {
              fontSize: "24px"
            }
          }
        ]
      }
    ]
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props.chart}
    />
  )
}
export default MultiChart
