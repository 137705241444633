import { Delete, Edit } from "@mui/icons-material"
import {
  Button,
  styled,
  TableCell,
  tableCellClasses,
  TableRow
} from "@mui/material"

import { DataProductionPlan } from "../ProductionPlanReport"

interface IBaseMaster extends DataProductionPlan {
  fieldEdit?: boolean
}
interface Column {
  id:
    | "machine"
    | "customer"
    | "partNo"
    | "partName"
    | "step"
    | "process"
    | "startTime"
    | "endTime"
    | "startTimeOT"
    | "endTimeOT"
    | "totalHour"
    | "jph"
    | "totalQuantity"
    | "cycleTime"
    | "totalPlan"
    | "totalActual"
    | "quantity"
    | "iNG"
    | "sNG"
    | "totalStart"
    | "totalEnd"
    | "productionWorker"
    | "remarks"
    | "matLotNo"
    | "pdLotNo"
    | "componentPartLotNo"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}
export const columns: Column[] = [
  {
    id: "machine",
    label: "machine",
    minWidth: 100
  },
  {
    id: "customer",
    label: "customer",
    minWidth: 100
  },
  {
    id: "partNo",
    label: "partNo",
    minWidth: 100
  },
  {
    id: "partName",
    label: "partName",
    minWidth: 100
  },
  {
    id: "step",
    label: "step",
    minWidth: 100
  },
  {
    id: "process",
    label: "process",
    minWidth: 100
  },
  {
    id: "pdLotNo",
    label: "pdLotNo",
    minWidth: 100
  },
  {
    id: "matLotNo",
    label: "matLotNo",
    minWidth: 100
  },
  {
    id: "componentPartLotNo",
    label: "componentPartLotNo",
    minWidth: 100
  },
  {
    id: "startTime",
    label: "startTime",
    minWidth: 100
  },
  {
    id: "endTime",
    label: "endTime",
    minWidth: 100
  },
  {
    id: "startTimeOT",
    label: "startTimeOT",
    minWidth: 100
  },
  {
    id: "endTimeOT",
    label: "endTimeOT",
    minWidth: 100
  },
  {
    id: "totalHour",
    label: "totalHour",
    minWidth: 100
  },
  { id: "jph", label: "jph", minWidth: 100 },
  {
    id: "totalQuantity",
    label: "totalQuantity",
    minWidth: 100
  },
  {
    id: "quantity",
    label: "totalOK",
    minWidth: 100
  },
  {
    id: "iNG",
    label: "totalNGProcess",
    minWidth: 100
  },
  {
    id: "sNG",
    label: "totalNGSetUp",
    minWidth: 100
  },
  {
    id: "totalPlan",
    label: "totalPlan",
    minWidth: 100
  },
  {
    id: "totalActual",
    label: "totalActual",
    minWidth: 100
  },

  {
    id: "productionWorker",
    label: "operator",
    minWidth: 100
  },
  {
    id: "remarks",
    label: "remarks",
    minWidth: 100
  }
]

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2,
    color: "black"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  border: "solid 1px black",
  "&:nth-of-type(odd)": {
    border: "solid 1px black"
  },

  "&:last-child td, &:last-child th": {
    border: "solid 1px black"
  }
}))

const ReadOnlyRow = ({
  row,
  handleEditTable,
  edit,
  deleteMachine,
  handleDeleteTable
}: {
  row: IBaseMaster
  handleEditTable: (
    even: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: DataProductionPlan
  ) => void
  edit: boolean
  deleteMachine: boolean
  handleDeleteTable: (
    even: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: DataProductionPlan
  ) => void
}): JSX.Element => {
  return (
    <StyledTableRow>
      {edit ? (
        <StyledTableCell>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Edit />}
            style={{ backgroundColor: "green" }}
            onClick={(event) => handleEditTable(event, row)}
          >
            Edit
          </Button>
        </StyledTableCell>
      ) : (
        <></>
      )}
      {deleteMachine ? (
        <StyledTableCell>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Delete />}
            style={{ backgroundColor: "red" }}
            onClick={(event) => handleDeleteTable(event, row)}
          >
            Delete
          </Button>
        </StyledTableCell>
      ) : (
        <></>
      )}

      {columns.map((column) => {
        const value = row[column.id]
        return (
          <StyledTableCell
            key={column.id}
            align={column.align}
            style={row.fieldEdit ? { color: "red" } : {}}
          >
            {column.format && typeof value === "number"
              ? column.format(value)
              : value}
          </StyledTableCell>
        )
      })}
    </StyledTableRow>
  )
}
export default ReadOnlyRow
