import React, { ReactElement, useState } from "react"
import {
  Grid,
  Autocomplete,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { useQuery } from "@apollo/client"

import { GET_PARTS } from "../../common/Query/MasterQuery"
import { GET_PM_HISTORY } from "../../common/Query/engineerQuery"
import moment from "moment"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

interface Data {
  partNo: string
  process: string
  quantity: string
  minStroke: string
  needMaintenance: string
  factory: string
}

interface Data2 {
  partNo: string
  process: string
  date: Date
}

const pageTitle = "PM History"

const PMhistory: React.FC = (): ReactElement => {
  const [partNo, setPartNo] = useState<string | null>(null)
  const { data } = useQuery(GET_PM_HISTORY, {
    variables: {
      data: {
        partNo: partNo
      }
    }
  })

  const getPart = useQuery(GET_PARTS)

  const rows: Data[] = data ? data.pmHistory.testScanner : []
  const rows2: Data2[] = data
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.pmHistory.maintenance.map((element: any) => {
        return { ...element, date: moment(element.date).format("DD-MM-YYYY") }
      })
    : []
  let partNoList: Array<string> = []

  partNoList = getPart.data
    ? getPart.data.parts.map((e: { partNo: string }) => e.partNo)
    : []

  interface Column1 {
    id: "partNo" | "process" | "quantity"
    label: string
    minWidth?: number
    align?: "right"
    format?: (value: number) => string
  }

  const columns1: Column1[] = [
    { id: "partNo", label: "partNo", minWidth: 100 },
    { id: "process", label: "process", minWidth: 100 },
    { id: "quantity", label: "Quantity", minWidth: 100 }
  ]

  interface Column2 {
    id: "partNo" | "process" | "date"
    label: string
    minWidth?: number
    align?: "right"
    format?: (value: number) => string
  }

  const columns2: Column2[] = [
    { id: "partNo", label: "Part No.", minWidth: 100 },
    { id: "process", label: "Process", minWidth: 100 },
    { id: "date", label: "Date", minWidth: 100 }
  ]

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Engineer}
        menuItemName={Object.keys(menuItemList)[5]}
        menuPageName={pageTitle}
      />

      <ThemedCard>
        <PageLabelCard title="PM History" subTitle="detail about PM History" />
        <Grid container spacing={2} width={"98%"} margin={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              disablePortal
              id="status"
              value={partNo}
              loading={partNoList.length === 0}
              defaultValue={""}
              options={partNoList}
              style={{ width: "100%" }}
              sx={{ width: 100 }}
              onChange={(e, newValue) => setPartNo(newValue)}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Select PartNo"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <PageLabelCard
            title="History"
            subTitle="detail about History ผลรวม Quantity การใช้งานรวมทั้งโรง 1 และ 2"
          />
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns1.map((column1) => (
                  <TableCell
                    key={column1.id}
                    align={column1.align}
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      backgroundColor: theme.palette.warning.light
                    }}
                  >
                    {column1.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice().map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns1.map((column1) => {
                      const value = row[column1.id]
                      return (
                        <TableCell key={column1.id} align={column1.align}>
                          {column1.format && typeof value === "number"
                            ? column1.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer style={{ marginTop: "15px" }}>
          <PageLabelCard
            title="History Process"
            subTitle="detail about History Process"
          />
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns2.map((column2) => (
                  <TableCell
                    key={column2.id}
                    align={column2.align}
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      backgroundColor: theme.palette.warning.light
                    }}
                  >
                    {column2.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows2.slice().map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns2.map((column2) => {
                      const value = row[column2.id]
                      return (
                        <TableCell key={column2.id} align={column2.align}>
                          {column2.format && typeof value === "number"
                            ? column2.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default PMhistory
