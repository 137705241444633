import { useMutation, useQuery } from "@apollo/client"
import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from "@mui/material"
import { useMemo, useState } from "react"
import swal from "sweetalert"
import {
  REMOVE_SPARE_PART,
  UPDATE_SPARE_PART
} from "../../../common/Mutation/MaintenanceMutation"
import { GET_SPARE_PARTS } from "../../../common/Query/MaintenanceQuery"

import AutocompleteSparePartCode from "../../../common/Resources/AutocompleteSparePartCode"
import AutocompleteSparePartPartName from "../../../common/Resources/AutocompleteSparePartPartName"
import Input from "../../../common/Resources/Input"

interface IProps {
  dialogOpen: boolean
  setDialogOpen: (status: boolean) => void
}

const EditSparePart = (props: IProps): JSX.Element => {
  const { dialogOpen, setDialogOpen } = props
  const [code, setCode] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [model, setModel] = useState<string>("")
  const [brand, setBrand] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [min, setMin] = useState<number>(0)
  const [max, setMax] = useState<number>(0)
  const [error, setError] = useState<boolean>(false)
  const [location, setLocation] = useState<string>("")
  const [prevCode, setPrevCode] = useState<string>("")
  const [prevPartName, setPrevPartName] = useState<string>("")
  const [file, setFile] = useState<File | null>(null)
  const { data } = useQuery(GET_SPARE_PARTS)
  const [updateSparePart] = useMutation(UPDATE_SPARE_PART, {
    refetchQueries: [GET_SPARE_PARTS],
    awaitRefetchQueries: true
  })
  const [removeSparePart] = useMutation(REMOVE_SPARE_PART, {
    refetchQueries: [GET_SPARE_PARTS],
    awaitRefetchQueries: true
  })

  const handleUpdate = async () => {
    if (!prevCode || !prevPartName) {
      setError(true)
      return swal("ERROR", "กรุณาใส่ Code และ Part Name", "error")
    }
    try {
      const data = {
        prevCode,
        prevPartName,
        code,
        partName,
        model,
        brand,
        min,
        max,
        location,
        file
      }

      await updateSparePart({
        variables: {
          updateSparePart: data
        }
      })
      swal("Success", "Update Spare Part Successful", "success")
      setDialogOpen(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  const handleDelete = async () => {
    try {
      await removeSparePart({
        variables: {
          code: prevCode,
          partName: prevPartName
        }
      })
      setDialogOpen(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }
  useMemo(() => {
    const findData = data.sparePartsList.find(
      (e: { partName: string; code: string }) =>
        e.partName === prevPartName && e.code === prevCode
    )

    if (findData) {
      setCode(findData.code)
      setPartName(findData.partName)
      setModel(findData.model)
      setFactory(findData.factory)
      setBrand(findData.brand)
      setMin(findData.min)
      setMax(findData.max)
      setModel(findData.brand)
      setLocation(findData.location)
    }
  }, [prevCode, prevPartName, data])

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"xl"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        >
          Select Spare Part
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item sm={6} xs={12}>
              <AutocompleteSparePartCode
                setCode={setPrevCode}
                code={prevCode}
                error={!prevCode && error}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <AutocompleteSparePartPartName
                setPartName={setPrevPartName}
                partName={prevPartName}
                code={prevCode}
                error={!prevPartName && error}
              />
            </Grid>
          </Grid>
          <DialogTitle
            id="alert-dialog-title"
            textAlign={"center"}
            fontSize="25px"
          >
            Update Spare Part
          </DialogTitle>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Input
              fullWidth
              text={"Code"}
              value={code}
              setValue={(event) => {
                setCode(event.target.value)
              }}
              inputType="string"
              sm={6}
            />
            <Input
              fullWidth
              text={"PartName"}
              value={partName}
              setValue={(event) => {
                setPartName(event.target.value)
              }}
              inputType="string"
              sm={6}
            />
            <Input
              fullWidth
              text={"Model"}
              value={model}
              setValue={(event) => {
                setModel(event.target.value)
              }}
              inputType="string"
              sm={4}
            />
            <Grid item sm={4} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                value={factory}
                renderInput={(params) => (
                  <TextField {...params} label="Select Factory" />
                )}
                onChange={(e, newValue) => setFactory(newValue as string)}
                options={["1", "2"]}
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
              <input
                type="file"
                onChange={(e) => {
                  setFile(
                    e.currentTarget.files?.["0"]
                      ? e.currentTarget.files?.["0"]
                      : null
                  )
                }}
              />
            </Grid>
            <Input
              fullWidth
              text={"Brand"}
              value={brand}
              setValue={(event) => {
                setBrand(event.target.value)
              }}
              inputType="string"
              sm={4}
            />
            <Input
              fullWidth
              text={"Min"}
              value={min}
              setValue={(event) => {
                setMin(+event.target.value)
              }}
              inputType="number"
              sm={4}
            />
            <Input
              fullWidth
              text={"Max"}
              value={max}
              setValue={(event) => {
                setMax(+event.target.value)
              }}
              inputType="number"
              sm={4}
            />

            <Input
              fullWidth
              text={"Location"}
              value={location}
              setValue={(event) => {
                setLocation(event.target.value)
              }}
              inputType="string"
              sm={12}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            style={{ backgroundColor: "green" }}
            autoFocus
            onClick={handleUpdate}
          >
            Update
          </LoadingButton>
          <LoadingButton
            variant="contained"
            style={{ backgroundColor: "red" }}
            autoFocus
            onClick={handleDelete}
          >
            Delete
          </LoadingButton>
          <Button
            variant="outlined"
            style={{ color: "red", borderColor: "red" }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditSparePart
