import "./App.css"
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  split
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import MainApp from "./common/MainApp"
import { createUploadLink } from "apollo-upload-client"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { getMainDefinition } from "@apollo/client/utilities"
import { createTheme } from "@mui/material"
import { ThemeProvider } from "@mui/material"
import { HashRouter as Router } from "react-router-dom"
import { AuthContextProvider } from "./context/AuthContext"
import { useState } from "react"

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})
const GATEWAY_API = createUploadLink({
  uri:
    process?.env?.REACT_APP_ERP_API_ENDPOINT ||
    "https://ntif783mti.execute-api.ap-southeast-1.amazonaws.com/Prod/graphQL"
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: "ws://localhost:4000/graphql"
  })
)
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  wsLink,
  GATEWAY_API
)
const client = new ApolloClient({
  link: from([errorLink, splitLink]),
  cache: new InMemoryCache()
})
console.log(`
         !
         !
         ^
        / \\ 
       /___\\
      |=   =|
      |     |
      |  B  |
      |  I  |
      |  T  |
      |  S  |
      |  O  |
      |  F  |
      |  T  |
      |     |
     /|##!##|\\
    / |##!##| \\
   /  |##!##|  \\
  |   | ^|^ |   |
  | /  ( | ) \\  |
  |/   ( | )  \\ | 
      ((   ))   
     ((     ))  
    ((   :   )) 
    ((   :   ))   
     ((     ))    
        ( )     
         .    
         .   
         .
`)

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#81e9f4",
      light: "#A4EBF3",
      dark: "#34a2e7",
      contrastText: "#53ace4"
    },
    secondary: {
      main: "#ffffff",
      light: "#a9dcfc",
      dark: "#34a2e7",
      contrastText: "#000000"
    },
    warning: {
      main: "#ed6c02",
      light: "#A4EBF3"
    },
    background: {
      default: "#CCF2F4",
      paper: "#ffffff"
    },
    common: {
      black: "#CCF2F4",
      white: "#F4F9F9"
    },
    success: {
      main: "#ffffff",
      light: "#dcf5f7",
      dark: "#acf3f9",
      contrastText: "#71e2ed"
    },
    error: {
      main: "#f00006",
      light: "#ff5357",
      dark: "#b50004"
    },
    text: {
      primary: "#4c4c4c",
      secondary: "#000000"
    }
  }
})

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#082032",
      light: "#2C394B",
      dark: "#19283b",
      contrastText: "#18191a"
    },
    secondary: {
      main: "#4f6f86",
      light: "#787A91",
      dark: "#19283b",
      contrastText: "#ffffff"
    },
    warning: {
      main: "#ed6c02",
      light: "#000000"
    },
    background: {
      default: "#2C394B",
      paper: "#2C394B"
    },
    common: {
      black: "#19283b",
      white: "#2C394B"
    },
    success: {
      main: "#082032",
      dark: "#787A91",
      contrastText: "#787A91"
    },
    error: {
      main: "#f00006",
      light: "#ff5357",
      dark: "#b50004"
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff"
    }
  }
})

const isTheme: string = window?.localStorage?.getItem("theme") || ""

const App = (): JSX.Element => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    isTheme === "true" ? true : false
  )
  const theme = isDarkMode ? darkTheme : lightTheme
  return (
    <Router>
      <div className={isDarkMode ? "Main-dark" : "Main"}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <AuthContextProvider>
              <div style={{ width: "99%" }}>
                <MainApp
                  setIsDarkMode={setIsDarkMode}
                  isDarkMode={isDarkMode}
                />
              </div>
            </AuthContextProvider>
          </ThemeProvider>
        </ApolloProvider>
      </div>
    </Router>
  )
}

export default App
