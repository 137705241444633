import { useLazyQuery, useQuery } from "@apollo/client"
import {
  Box,
  Grid,
  Autocomplete,
  DialogContent,
  CircularProgress,
  Dialog,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  Typography,
  Tab,
  ThemeProvider
} from "@mui/material"
import moment from "moment"
import { useMemo, useState, useRef, useEffect } from "react"
import {
  GET_LINE_PRODUCTION_PLAN,
  GET_PRODUCTION_PLAN,
  GET_SUMMARY_MACHINE
} from "../../common/Query/PlanningQuery"
import SummaryMachine, { IDataProductionPlan } from "./component/SummaryMachine"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { clearParam, factoryList, shiftList } from "../../common/utilities"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import DialogProblem from "./component/DialogProblem"
import { makeStyles } from "@mui/styles"
import OEE from "./OEE"
import {
  GET_MACHINE_PROBLEM,
  GET_WIP_REPORT,
  OEE_REPORT
} from "../../common/Query/ProductionQuery"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedTabs from "../../common/Resources/ThemedComponents/ThemedTabs"
import { autocompleteRenderTags } from "../../common/Resources/ThemedComponents/Other/autocompleteRenderTags"
import { Fullscreen } from "@mui/icons-material"

export interface ISummaryMachine {
  machine: string
  partNo: string
  quantity: string
  process: string
  sNG: string
  shift: string
  packingBy: string
  productionWorker: string
}

interface IData {
  data: {
    productionPlanReport: ISummaryMachine[]
  }
}
interface IScannerData {
  actionDate: string
  machine: string
  partNo: string
  process: string
  quantity: number
}
const pageTitle = "Production OEE Result"
interface IPropsTabPanel {
  children?: React.ReactNode
  index: number
  value: number
}
const TabPanel = (props: IPropsTabPanel) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}
const SummaryPlan: React.FC = () => {
  const theme = useTheme()

  const [date, setDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [open, setOpen] = useState<boolean>(false)
  const [statusSubmit, setStatusSubmit] = useState<boolean>(false)
  const [statusRun, setStatusRun] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [line, setLine] = useState<string[]>([])
  const [factory, setFactory] = useState<string | null>("")
  const [shift, setShift] = useState<string | null>("")
  const [productionPlanData, setProductionPlanData] = useState<
    IDataProductionPlan[]
  >([])
  const [summaryMachine, setSummaryMachine] = useState<ISummaryMachine[]>([])
  const [getDataProductionPlan] = useLazyQuery(GET_PRODUCTION_PLAN)
  const [getSummaryMachine] = useLazyQuery(GET_SUMMARY_MACHINE)
  const [getOeeDetail, { data: oeeDetail }] = useLazyQuery(OEE_REPORT, {
    fetchPolicy: "network-only"
  })
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [tapMenu, setTapMenu] = useState<number>(0)
  const [getWipReport, { data: dataWip }] = useLazyQuery(GET_WIP_REPORT, {
    fetchPolicy: "network-only"
  })
  const [message, setMessage] = useState<IScannerData>()

  const defaultMachineProblem = useRef([
    {
      startTime: "08:00",
      endTime: "00:00",
      problemName: "other",
      machine: ""
    }
  ])

  const [getMachineProblem, { data: dataMachineProblem }] =
    useLazyQuery(GET_MACHINE_PROBLEM)
  useMemo(async () => {
    if (productionPlanData?.length > 0) {
      const summaryData = await getSummaryMachine({
        variables: {
          date
        },
        fetchPolicy: "network-only"
      })
      setSummaryMachine(summaryData?.data?.summaryMachineOfPlan || [])
    }
  }, [date, getSummaryMachine, productionPlanData])
  let lineList: string[] = []
  const getLineList = useQuery(GET_LINE_PRODUCTION_PLAN, {
    variables: {
      date: date,
      factory: factory
    },
    fetchPolicy: "network-only"
  })
  if (date && factory) {
    lineList = getLineList?.data?.lineProductionPlan || []
  }

  const setLineAuto = () => {
    const index = lineList.findIndex((lineList: string) => lineList === line[0])
    if (lineList[index + 1]) {
      return lineList[index + 1]
    } else {
      return lineList[0]
    }
  }

  const handleSubmit = async (data?: IData) => {
    await getOeeDetail({
      variables: {
        date,
        factory,
        line
      }
    })
    await getWipReport({
      variables: {
        dateFrom: date,
        dateTo: date,
        factory: factory
      },
      fetchPolicy: "network-only"
    })
    await getMachineProblem({
      variables: {
        actionDate: date,
        line
      }
    })
    let dataProductionPlan
    if (data) {
      dataProductionPlan = data
    } else {
      if (!factory || !line.length) {
        setError(true)
        swal("ERROR", "กรุณาใส่ข้อมูล", "error")
        return
      }
      setOpen(true)

      dataProductionPlan = await getDataProductionPlan({
        variables: {
          date: date,
          line: line,
          factory: factory
        },
        fetchPolicy: "network-only"
      })
    }

    let concatData: IDataProductionPlan[] = []
    if (dataProductionPlan?.data.productionPlanReport.length > 1) {
      concatData = dataProductionPlan.data.productionPlanReport[0].concat(
        dataProductionPlan.data.productionPlanReport[1]
      )
    } else {
      concatData = dataProductionPlan.data.productionPlanReport[0]
    }
    const dataUnique = concatData?.reduce(
      (prev: IDataProductionPlan[], cur: IDataProductionPlan) =>
        prev.find(
          (e: IDataProductionPlan) =>
            e.partNo === cur.partNo &&
            e.machine === cur.machine &&
            e.process === cur.process &&
            e.shift === cur.shift
        )
          ? prev
          : prev.concat(cur),
      []
    )

    const productionPlanNew = dataUnique?.map((e: IDataProductionPlan) => {
      const dataFilter = concatData.filter(
        (entry) =>
          e.partNo === entry.partNo &&
          e.process == entry.process &&
          e.machine === entry.machine &&
          e.shift === entry.shift
      )

      const filterStart = filterTime(dataFilter, "startTime")
      const filterStartOT = filterTime(dataFilter, "startTimeOT")

      const timeShow =
        filterStart.length > 0
          ? filterStart
              .map((e) => e.startTime + "-" + e.endTime)
              .reduce((prev: string, cur: string) => prev + " & " + cur)
          : "-"

      const timeShowOT =
        filterStartOT.length > 0
          ? filterStartOT
              .map((e) => e.startTimeOT + "-" + e.endTimeOT)
              .reduce((prev: string, cur: string) => prev + " & " + cur)
          : "-"
      const timeCal = dataFilter.map((e) => {
        return {
          startTime: e.startTime,
          endTime: e.endTime,
          startTimeOT: e.startTimeOT,
          endTimeOT: e.endTimeOT
        }
      })
      return {
        ...e,
        totalQuantity: dataFilter.reduce((total, object) => {
          return total + object.totalQuantity
        }, 0),
        timeShow: timeShow,
        timeShowOT: timeShowOT,
        timeCalculate: timeCal
      }
    })

    const productionPlanFinal = productionPlanNew.filter(
      (value) => value.shift === shift
    )

    setProductionPlanData(productionPlanFinal)
    setOpen(false)
  }
  const filterTime = (data: IDataProductionPlan[], key: string) =>
    data.filter((e) => e[key as keyof IDataProductionPlan])

  const tapProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    }
  }

  useMemo(() => {
    if (statusSubmit) {
      if (date && factory) {
        const lineAuto = setLineAuto()
        setTimeout(async function () {
          setLine([lineAuto])
          const dataProductionPlan = await getDataProductionPlan({
            variables: {
              date: date,
              line: lineAuto,
              factory: factory
            },
            fetchPolicy: "network-only"
          })

          let concatData: IDataProductionPlan[] = []
          if (dataProductionPlan?.data?.productionPlanReport?.length > 1) {
            concatData = dataProductionPlan.data.productionPlanReport[0].concat(
              dataProductionPlan.data.productionPlanReport[1]
            )
          } else {
            concatData = dataProductionPlan?.data?.productionPlanReport[0]
          }
          const dataUnique = concatData.reduce(
            (prev: IDataProductionPlan[], cur: IDataProductionPlan) =>
              prev.find(
                (e: IDataProductionPlan) =>
                  e.partNo === cur.partNo &&
                  e.machine === cur.machine &&
                  e.process === cur.process
              )
                ? prev
                : prev.concat(cur),
            []
          )

          const productionPlanNew = dataUnique.map((e: IDataProductionPlan) => {
            const dataFilter = concatData.filter(
              (entry) =>
                e.partNo === entry.partNo &&
                e.process == entry.process &&
                e.machine === entry.machine
            )

            const filterStart = filterTime(dataFilter, "startTime")
            const filterStartOT = filterTime(dataFilter, "startTimeOT")

            const timeShow =
              filterStart.length > 0
                ? filterStart
                    .map((e) => e.startTime + "-" + e.endTime)
                    .reduce((prev: string, cur: string) => prev + " & " + cur)
                : "-"

            const timeShowOT =
              filterStartOT.length > 0
                ? filterStartOT
                    .map((e) => e.startTimeOT + "-" + e.endTimeOT)
                    .reduce((prev: string, cur: string) => prev + " & " + cur)
                : "-"
            const timeCal = dataFilter.map((e) => {
              return {
                startTime: e.startTime,
                endTime: e.endTime,
                startTimeOT: e.startTimeOT,
                endTimeOT: e.endTimeOT
              }
            })
            return {
              ...e,
              totalQuantity: dataFilter.reduce((total, object) => {
                return total + object.totalQuantity
              }, 0),
              timeShow: timeShow,
              timeShowOT: timeShowOT,
              timeCalculate: timeCal
            }
          })

          setStatusRun(!statusRun)
          setProductionPlanData(productionPlanNew)
        }, 0.5 * 60 * 1000)
      }
    }
  }, [statusSubmit, statusRun, oeeDetail])
  const elem: HTMLElement | null = document.getElementById("tableMachine")
  const openFullscreen = () => {
    if (elem?.requestFullscreen) {
      elem.requestFullscreen()
    }
  }
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTapMenu(newValue)
  }
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const dateParam = urlParams.get("date")
  const factoryParam = urlParams.get("factory")
  const lineParam = urlParams.get("line")

  const handleChangeFactory = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setFactory(newValue)
    setLine([])
  }

  useMemo(async () => {
    if (dateParam && factoryParam && lineParam) {
      setDate(dateParam)
      setFactory(factoryParam)
      setLine([lineParam])
      const dataProductionPlan = await getDataProductionPlan({
        variables: {
          date: dateParam,
          line: [lineParam],
          factory: factoryParam
        },
        fetchPolicy: "network-only"
      })
      handleSubmit(dataProductionPlan)
      clearParam("ProductionOEEResult")
    }
  }, [dateParam, dateParam, lineParam])
  const useStyles = makeStyles({
    tabs: {
      "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.secondary.contrastText
      },
      "& .MuiTab-root.Mui-selected": {
        color: theme.palette.secondary.contrastText
      }
    }
  })

  useEffect(() => {
    const wsUrl =
      "wss://dht6wg9m3k.execute-api.ap-southeast-1.amazonaws.com/production/"
    const ws = new WebSocket(wsUrl)

    ws.onopen
    ws.onmessage = (event: MessageEvent) => {
      setMessage(JSON.parse(event.data))
    }
  }, [])

  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Production}
        menuItemName={Object.keys(menuItemList)[3]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard title="OEE Result" subTitle="about detail OEE Result" />
        <Box>
          <Grid container spacing={2} margin={2} width={"98%"}>
            <Grid item sm={3}>
              <ThemedTextField
                fullWidth
                value={date}
                error={!date && error}
                label="Date"
                InputLabelProps={{
                  shrink: true
                }}
                type="date"
                autoComplete="family-name"
                onChange={(e) => setDate(e.target.value)}
              />
            </Grid>
            <Grid item sm={1}>
              <Autocomplete
                fullWidth
                disabled={statusSubmit}
                value={factory ? factory : ""}
                options={factoryList}
                disableClearable
                disablePortal
                onChange={(e, value) => handleChangeFactory(e, value)}
                renderInput={(params) => (
                  <ThemedTextField
                    required
                    error={!factory && error}
                    {...params}
                    label="Factory"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4}>
              <Autocomplete
                multiple
                fullWidth
                disabled={statusSubmit || !factory}
                value={line}
                options={lineList.concat("PLATING")}
                loading={lineList.length === 0}
                disablePortal
                onChange={(e, value) => setLine(value)}
                renderTags={autocompleteRenderTags}
                renderInput={(params) => (
                  <ThemedTextField
                    required
                    error={!line && error}
                    {...params}
                    label="line"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={2}>
              <Autocomplete
                fullWidth
                disabled={statusSubmit}
                value={shift ? shift : ""}
                options={shiftList}
                disablePortal
                onChange={(e, value) => setShift(value)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Shift"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            container
            justifyContent="flex-start"
            style={{ marginLeft: "30px" }}
          >
            <ThemedLoadingButton
              disabled={statusSubmit}
              variant="contained"
              style={{
                top: "-13px",
                width: "120px"
              }}
              sx={{ mt: 3, mb: 2 }}
              onClick={() => handleSubmit()}
            >
              Submit
            </ThemedLoadingButton>
            <ThemedButton
              contrast
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
              style={
                factory
                  ? {
                      whiteSpace: "nowrap",
                      marginLeft: "25px",
                      width: "120px",
                      top: "-13px",

                      borderColor: theme.palette.primary.main
                    }
                  : { display: "none" }
              }
              onClick={() => {
                setStatusSubmit(!statusSubmit)
              }}
            >
              {!statusSubmit ? "Auto Run" : "Stop Auto Run"}
            </ThemedButton>
            <ThemedButton
              contrast
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
              style={
                factory
                  ? {
                      whiteSpace: "nowrap",
                      marginLeft: "25px",
                      top: "-13px",

                      borderColor: theme.palette.primary.main
                    }
                  : { display: "none" }
              }
              onClick={() => {
                openFullscreen()
              }}
              endIcon={<Fullscreen />}
            >
              Full Screen
            </ThemedButton>
            <ThemedButton
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
              style={
                factory && line
                  ? {
                      whiteSpace: "nowrap",
                      marginLeft: "25px",
                      width: "120px",
                      top: "-13px",
                      color: theme.palette.secondary.contrastText,
                      backgroundColor: "#FF0000",
                      borderColor: "#FF0000"
                    }
                  : { display: "none" }
              }
              onClick={() => setDialogOpen(true)}
            >
              Stop Machine
            </ThemedButton>
          </Grid>
        </Box>
        <Box className="hidden-print">
          <ThemedTabs
            value={tapMenu}
            onChange={handleChange}
            className={classes.tabs}
          >
            <Tab label="OEE Record" {...tapProps(0)} />
            <Tab label="OEE Dashboard" {...tapProps(1)} />
          </ThemedTabs>
        </Box>
      </ThemedCard>
      <Dialog open={open}>
        <DialogContent style={{ width: "auto" }}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <TabPanel value={tapMenu} index={0}>
        <Typography
          style={
            productionPlanData?.length > 0
              ? {
                  marginTop: "12px",
                  marginLeft: "11px",
                  fontFamily: "Sarabun",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  letterSpacing: "0.15000000596046448px",
                  textAlign: "left"
                }
              : { display: "none" }
          }
        >
          Overview Machine Factory {factory}
          {`${line.map((line) => {
            const findLine = productionPlanData?.find(
              (value) => value.line === line
            )
            return findLine ? `${line} ${findLine.revision}` : line
          })}`.toString()}
        </Typography>
        <div id="tableMachine">
          <Grid container spacing={1} width={"99%"} marginTop={"1px"}>
            {productionPlanData?.map((e, i) => {
              return (
                <Grid
                  item
                  sm={3}
                  style={{ minWidth: "500px" }}
                  key={e.machine + e.partNo + String(i)}
                >
                  <SummaryMachine
                    handleSubmit={handleSubmit}
                    planData={e}
                    actualProcess={summaryMachine.find(
                      (testScan) =>
                        testScan.machine === e.machine &&
                        testScan.process === e.step &&
                        e.partNo === testScan.partNo &&
                        testScan.shift === e.shift
                    )}
                    incrementQuantity={
                      message?.machine === e.machine &&
                      message?.process === e.step &&
                      e.partNo === message?.partNo
                        ? message
                        : undefined
                    }
                    factory={factory}
                  />
                </Grid>
              )
            })}

            {productionPlanData?.length > 0 && statusSubmit ? (
              Array.from(Array(3), () => {
                return (
                  <Grid item sm={4}>
                    <Table
                      style={{
                        width: "100%",
                        marginLeft: "25px",
                        backgroundColor: "white",
                        height: "514px"
                      }}
                    >
                      <TableBody style={{ border: "solid 8px white" }}>
                        <TableRow>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                )
              })
            ) : (
              <></>
            )}
          </Grid>
        </div>
      </TabPanel>
      <TabPanel value={tapMenu} index={1}>
        <OEE
          data={oeeDetail?.oeeReport}
          wipData={dataWip?.wipReport.filter((wipData: { line: string }) =>
            line.includes(wipData.line)
          )}
          machineProblem={
            dataMachineProblem
              ? dataMachineProblem.problemMachineList
              : defaultMachineProblem.current
          }
        />
      </TabPanel>
      <div>
        <DialogProblem
          handleSubmit={handleSubmit}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
        />
      </div>
    </ThemeProvider>
  )
}
export default SummaryPlan
