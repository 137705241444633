import { gql } from "@apollo/client"

export const ADD_TEST_FG = gql`
  mutation Testfg($data: InputData) {
    testfg(data: $data)
  }
`
export const ADD_UPDATE_REWORK = gql`
  mutation Mutation($updateRw: InputRework) {
    updateRework(updateRw: $updateRw) {
      operator
      partNo
      quantity
      pdLotNo
      actionDate
      process
      scanner
      factory
    }
  }
`
