import React, { useMemo, useState, Dispatch, SetStateAction } from "react"
import { useMutation } from "@apollo/client"
import makeStyles from "@mui/styles/makeStyles"
import Grid from "@mui/material/Grid"
import "../../../css/Document/Document.css"
import "../../../css/Masterplan/MasterplanEntry.css"
import AddIcon from "@mui/icons-material/Add"
import { Autocomplete, Box, TextField, Theme } from "@mui/material"
import { ADD_DUE_DATES } from "../../../common/Mutation/MasterPlanMutation"
import moment from "moment"
import DateAdapter from "@mui/lab/AdapterMoment"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"
import swal from "sweetalert"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"

interface DueDateType {
  dueDate: string
  quantity?: number | null
  actualProduction?: number
}

interface OtherPlanInput {
  partNo: string
  factory?: string
  status: string
  order: DueDateType[]
}

interface IDueDateMMThProps {
  quantity: (number | null)[]
  setQuantity: Dispatch<SetStateAction<(number | null)[]>>
  dateListArray: string[]
}

interface IAddDueDatesMMTh {
  partNo: string
}
const useStyles = makeStyles<Theme>({
  root: {
    [`& fieldset`]: {
      borderRadius: 0
    }
  },
  textfield: {
    [`& fieldset`]: {
      borderRadius: 0
    },
    minWidth: 150,
    border: "none",
    borderRadius: "none",
    padding: "none",
    margin: "none",
    outline: "none"
  },
  box: {
    minWidth: 200,
    overflow: "auto"
  }
})

const makeDatesList = (date: Date): string[] => {
  //used to get length of array
  const daysInMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate()

  const makeDateString = (dateListIndex: number): string => {
    const newDate: string =
      dateListIndex < 10 ? "0" + (dateListIndex + 1) : dateListIndex + 1 + ""
    const month: string =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1 + ""
    const year: string = date.getFullYear() + ""
    const dateString = moment(new Date(`${year}-${month}-${newDate}`)).format(
      "YYYY-MM-DD"
    )
    return dateString
  }
  const dateList: string[] = Array.from(Array(daysInMonth), (_, index) =>
    makeDateString(index)
  )
  return dateList
}

const DueDate = (props: IDueDateMMThProps) => {
  const { dateListArray } = props
  const { quantity, setQuantity } = props

  const classes = useStyles()

  const handleChange = (
    key: number,
    value: number,
    list: (number | null)[],
    setState: Dispatch<SetStateAction<(number | null)[]>>
  ) => {
    const newList = [...list]
    newList[key] = isNaN(value) ? null : value
    setState(newList)
  }

  const dateList = useMemo(
    () =>
      dateListArray.map((date: string) => {
        return (
          <ThemedTextField
            key={date}
            value={moment(new Date(date)).format("DD")}
            className={classes.textfield}
            inputProps={{ style: { textAlign: "center", width: "50px" } }}
            disabled
          />
        )
      }),
    [dateListArray, classes.textfield]
  )

  const quantityList = useMemo(
    () =>
      dateListArray.map((date, index) => {
        return (
          <TextField
            key={date}
            value={quantity[index] != null ? quantity[index] : ""}
            className={classes.textfield}
            onChange={(e) =>
              handleChange(
                index,
                parseFloat(e.target.value),
                quantity,
                setQuantity
              )
            }
            label={""}
          />
        )
      }),
    [quantity, classes.textfield, dateListArray, setQuantity]
  )

  return (
    <Box>
      <Grid container>
        <Grid item xs={3}>
          <ThemedTextField
            label="Dates"
            className={classes.textfield}
            disabled
            fullWidth
            InputLabelProps={{ shrink: false }}
          />
          <ThemedTextField
            label="Order"
            className={classes.textfield}
            disabled
            fullWidth
            InputLabelProps={{ shrink: false }}
          />
        </Grid>
        <Grid
          item
          sm={9}
          style={{
            overflowX: "scroll"
            // width: "850px"
          }}
        >
          <div style={{ display: "flex" }}>{dateList}</div>
          <div style={{ display: "flex" }}>{quantityList}</div>
        </Grid>
      </Grid>
      <Grid item sm={2}>
        <p className="duedate-subtext">*ช่องว่างมีค่าเป็น 0</p>
      </Grid>
    </Box>
  )
}

const AddDueDatesMMTh = (props: IAddDueDatesMMTh): JSX.Element => {
  const { partNo } = props
  const [factory, setFactory] = useState<string>("")
  const [status, setStatus] = useState<string>("-")
  const [selectDate, setSelectDate] = useState<Date>(new Date())
  const dateListArray: string[] = useMemo(
    () => makeDatesList(selectDate),
    [selectDate]
  )
  const [quantity, setQuantity] = useState<(number | null)[]>(
    new Array(dateListArray.length).fill(null)
  )
  const factoryList: string[] = ["1", "2"]

  const [addDueDate, { loading }] = useMutation(ADD_DUE_DATES)

  //can do async await to avoid messy looking code
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const order: DueDateType[] = dateListArray.map((date, index) => {
      return {
        dueDate: date,
        quantity: quantity[index] !== null ? quantity[index] : null
      }
    })
    const otherPlan: OtherPlanInput = {
      partNo,
      factory,
      status,
      order
    }
    try {
      await addDueDate({
        variables: {
          otherplan: otherPlan
        }
      })
      swal("Success", "Update Success", "success")
      isReset()
    } catch (err) {
      swal("ERROR", `${(err as Error).message}`, "warning")
    }
  }

  const isReset = () => {
    setQuantity(new Array(dateListArray.length).fill(null))
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ padding: "20px" }}>
      <h3>{"Part NO: " + partNo}</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} container spacing={0}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              disableClearable
              disablePortal
              onChange={(event, selectedFactory) => {
                setFactory(selectedFactory as string)
              }}
              options={factoryList}
              renderInput={(params) => (
                <ThemedTextField {...params} label="Factory" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <p className="duedate-subtext">*อัพเดตโรงงานเเละชุบพร้อมกัน</p>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              label="เลือกเดือน"
              views={["year", "month"]}
              value={selectDate}
              onChange={(newSelectDate) => {
                setSelectDate(moment(newSelectDate).toDate() as Date)
              }}
              renderInput={(params) => (
                <ThemedTextField {...params} fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ThemedTextField
            fullWidth
            label="Plating"
            onChange={(e) => setStatus(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <DueDate
            quantity={quantity}
            setQuantity={setQuantity}
            dateListArray={dateListArray}
          />
        </Grid>
        <Grid item xs={12}>
          <ThemedLoadingButton
            loading={loading}
            disabled={!factory}
            loadingPosition="end"
            type="submit"
            endIcon={<AddIcon />}
          >
            Submit
          </ThemedLoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddDueDatesMMTh
