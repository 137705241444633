import {
  Button,
  DialogContentText,
  IconButton,
  InputAdornment,
  Paper,
  TextField
} from "@mui/material"
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react"
import Grid from "@mui/material/Grid"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material"
import { LOGIN } from "../../common/Query/LoginQuery"
import { useLazyQuery } from "@apollo/client"
import swal from "sweetalert"
import "../../css/Login/Login.css"
import { useNavigate } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { styled } from "@mui/material/styles"
import { getImagePng } from "../../common/utilities"

const LoginTextField = styled(TextField)(() => ({
  "& .MuiInputBase-formControl": {
    borderRadius: "30px"
  }
}))

const CustomDialog = styled(Dialog)(({ theme }) => ({
  textAlign: "center",
  fontFamily: "Roboto", // this doesn't overwrite fonts :S
  "& .MuiDialog-root": {
    fontFamily: "Roboto"
  },

  "& .MuiDialog-paper": {
    maxWidth: "700px",
    verticalAlign: "center",
    horizontalAlign: "center"
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    backgroundColor: "#EFF3FC"
  },
  "& .MuiDialogTitle-root": {
    color: "#3A77DA",
    fontFamily: "Roboto"
  },
  "& .MuiDialogActions-root": {
    alignItems: "center",
    justifyContent: "center"
  }
}))

const errorMessage = (inputType: string, inputValue: string) => {
  if (!inputValue) return "ช่องว่างเปล่า"
  return `${inputType} ผิด`
}

interface IForgotPasswordPrompt {
  forgotPassword: boolean
  setForgotPassword: Dispatch<SetStateAction<boolean>>
}

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const TestPrompt = (props: IForgotPasswordPrompt) => {
  const { forgotPassword, setForgotPassword } = props
  const [inputEmail, setInputEmail] = useState<string>("")

  const handleClose = () => {
    setForgotPassword(false)
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    alert(`success! ${inputEmail}`)
    setInputEmail("")
    setForgotPassword(false)
  }

  return (
    <CustomDialog
      open={forgotPassword}
      onClose={handleClose}
      fullWidth
      data-testid="login-dialog"
    >
      <CustomDialogTitle
        id="forgot-password-dialog-title"
        onClose={handleClose}
      >
        กู้คืนรหัสผ่านใหม่
      </CustomDialogTitle>
      <DialogContent>
        <DialogContentText>
          ป้อนหมายเลขโทรศัพท์หรืออีเมลที่ลงทะเบียนกับ LinkLook
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          value={inputEmail}
          id="name"
          label="Email Address"
          variant="outlined"
          onChange={(e) => setInputEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}

const Login: React.FC = () => {
  const [userName, setUserName] = useState<string>("")
  const [userNameError, setUserNameError] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("")
  const [passwordError, setPasswordError] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [forgotPassword, setForgotPassword] = useState<boolean>(false)
  const navigate = useNavigate()

  const [getLogin] = useLazyQuery(LOGIN)

  const handleSubmit = async () => {
    if (!userName) {
      setUserNameError(true)
      return swal("โปรดใส่ Username ด้วย", ``, "error")
    }
    if (!password) {
      setPasswordError(true)
      return swal("Error!", `โปรดใส่ Password ด้วย`, "error")
    }
    try {
      const { data } = await getLogin({
        variables: { username: userName, password }
      })
      if (data?.signIn?.accessToken) {
        localStorage.setItem("token", data.signIn.accessToken)
        swal("Good job!", "Sucess!", "success")
        setUserNameError(false)
        setPasswordError(false)
        window.location.reload()
        navigate("/Customers", { replace: true })
      } else {
        if (data.signIn.errorMessage === "Invalid Password!")
          setPasswordError(true)
        if (data.signIn.errorMessage === "Invalid User!") setUserNameError(true)
        return swal("Error!", `Incorrect Username or Password`, "error")
      }
    } catch (error) {
      return swal("Error!", `Incorrect Username or Password`, "error")
    }
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
        className="login-page"
      >
        <Paper elevation={12} style={{ borderRadius: "15px" }}>
          <Grid
            item
            xs={12}
            style={{
              padding: "2em"
            }}
            maxWidth="610px"
          >
            <form onSubmit={handleSubmit}>
              <Grid
                item
                container
                xs={12}
                spacing={2}
                direction="column"
                justifyContent="center"
                style={{ textAlign: "center" }}
              >
                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                  <img src={getImagePng("logo")} alt="Brother Auto Parts." />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0, color: "#0000FE" }}>
                  <h2>Brother Auto Parts & Engineering Company</h2>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={10}>
                  <LoginTextField
                    type="string"
                    key="login-username-input"
                    fullWidth
                    style={{ textAlign: "center" }}
                    label="Username"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setUserName(event.target.value)
                    }
                    error={userNameError}
                    helperText={
                      userNameError ? errorMessage("Username", userName) : ""
                    }
                    onKeyDown={(event) => {
                      if (event.code === "Enter") handleSubmit()
                    }}
                  />
                </Grid>
                <Grid item xs={10} className="password-input">
                  <LoginTextField
                    fullWidth
                    key="login-password-input"
                    type={showPassword ? "string" : "password"}
                    style={{ textAlign: "center" }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setPassword(event.target.value)
                    }
                    error={passwordError}
                    helperText={
                      passwordError ? errorMessage("Password", password) : ""
                    }
                    label="Password"
                    onKeyDown={(event) => {
                      if (event.code === "Enter") handleSubmit()
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(
                              event: React.MouseEvent<HTMLButtonElement>
                            ) => event.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={6} style={{ textAlign: "left" }}>
                    <Button
                      variant="text"
                      onClick={() => setForgotPassword(true)}
                    >
                      ลืมรหัสผ่าน
                    </Button>
                    <TestPrompt
                      forgotPassword={forgotPassword}
                      setForgotPassword={setForgotPassword}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Button variant="contained" onClick={handleSubmit}>
                      Sign In
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default Login
