import React, { ReactElement, useState } from "react"
import {
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  ThemeProvider,
  tableCellClasses,
  styled
} from "@mui/material"
import { useQuery } from "@apollo/client"
import { GET_WH_TAG_REPORT } from "../../common/Query/WarehouseQuery"
import moment from "moment"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { menuItemList } from "../../common/Resources/menuItemList"
import { miniLogo } from "../../common/utilities"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"

interface Column {
  id:
    | "partNo"
    | "partName"
    | "customer"
    | "pdLotNo"
    | "model"
    | "quantity"
    | "totalQuantity"
    | "packingBy"
    | "recieveDate"
    | "remarks"
    | "boxCount"
  label: string
  minWidth?: number
  align?: "right"
  // eslint-disable-next-line no-unused-vars
  format?: (value: number) => string
}

interface DataTagReport {
  partNo: string
  partName: string
  customer: string
  model: string
  quantity: number
  totalQuantity: number
  packingBy: string
  recieveDate: string
  pdLotNo: string
  remarks: string
  boxCount: string
}

const pageTitle = "WH Report"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const WhTagReport: React.FC = (): ReactElement => {
  const [partNo, setPartNo] = useState<string>("")
  const [dataTags, setDataTags] = useState<DataTagReport[]>([])
  const [dateFrom, setDateFrom] = useState(
    moment(new Date("2021-01-01")).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const getWhTagReport = useQuery(GET_WH_TAG_REPORT)

  const columns: Column[] = [
    { id: "recieveDate", label: "Received Data", minWidth: 100 },
    { id: "partNo", label: "Part no", minWidth: 100 },
    { id: "partName", label: "Part Name", minWidth: 100 },
    { id: "customer", label: "Customer", minWidth: 100 },
    { id: "model", label: "Model", minWidth: 100 },
    { id: "quantity", label: "Quantity", minWidth: 100 },
    { id: "totalQuantity", label: "Total Quantity", minWidth: 100 },
    { id: "packingBy", label: "Packing By", minWidth: 100 },
    { id: "pdLotNo", label: "PD Lot No", minWidth: 100 },
    { id: "remarks", label: "Remarks", minWidth: 100 },
    { id: "boxCount", label: "Box Count", minWidth: 100 }
  ]

  const onChangeSubmit = () => {
    if (partNo !== "") {
      setDataTags(
        getWhTagReport?.data?.whTagReport
          .filter((e: DataTagReport) => e.partNo === partNo)
          .filter(
            (e: DataTagReport) =>
              e.recieveDate <= dateTo && e.recieveDate >= dateFrom
          )
      )
    }
    if (partNo === "" || !partNo) {
      setDataTags(
        getWhTagReport?.data?.whTagReport.filter(
          (e: DataTagReport) =>
            e.recieveDate <= dateTo && e.recieveDate >= dateFrom
        )
      )
    }
  }
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <Box style={{ marginLeft: "22px" }} component="form">
        <PageLabel
          menuItem={menuItemList.Warehouse}
          menuItemName={Object.keys(menuItemList)[6]}
          menuPageName={pageTitle}
        />
        <ThemedCard style={{ marginTop: "26px" }} className="print-hide">
          <PageLabelCard
            title="Warehouse Tag Report"
            subTitle="detail about Warehouse Tag Report"
          />
          <Grid container spacing={2} width={"98%"} margin={2}>
            <Grid item xs={4}>
              <ThemedTextField
                fullWidth
                value={dateFrom}
                id="dateFrom"
                type="date"
                label="Date From"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <ThemedTextField
                fullWidth
                value={dateTo}
                id="dateTo"
                type="date"
                label="Date To"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AutocompletePartNo setPartNo={setPartNo} partNo={partNo} />
            </Grid>
            <Grid item xs={12} sm={1}>
              <ThemedButton
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main
                }}
                onClick={onChangeSubmit}
              >
                SUBMIT
              </ThemedButton>
            </Grid>
            <Grid item xs={12} sm={1}>
              <ThemedButton
                variant="outlined"
                style={{
                  color: theme.palette.secondary.contrastText,
                  borderColor: theme.palette.primary.main
                }}
                onClick={() => window.print()}
              >
                Print
              </ThemedButton>
            </Grid>
          </Grid>
        </ThemedCard>
        <ThemedCard style={{ marginTop: "26px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ color: "black", backgroundColor: "white" }}
                className="print-sm-order"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={1} style={{ width: "20%" }}>
                      <img
                        src={miniLogo}
                        style={{
                          width: "80px",
                          height: "80px",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto"
                        }}
                        alt=""
                      />
                    </StyledTableCell>
                    <StyledTableCell colSpan={2} style={{ width: "20%" }}>
                      <label style={{ fontSize: "18px", MozAnimation: "none" }}>
                        Part Delivery Summary
                      </label>
                    </StyledTableCell>
                    <StyledTableCell
                      colSpan={6}
                      style={{ textAlign: "center" }}
                    >
                      <label style={{ fontSize: "18px", MozAnimation: "none" }}>
                        Part Delivery Summary
                      </label>
                    </StyledTableCell>
                    <StyledTableCell
                      colSpan={2}
                      style={{ textAlign: "center" }}
                    >
                      <label style={{ fontSize: "18px", height: "60px" }}>
                        F-PL-03-09/1 <br />
                        <label style={{ fontSize: "13px" }}>
                          Effective Date : 1-10-21 Rev.00
                        </label>
                      </label>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow style={{}}>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{}}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTags.map((row, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                        {columns.map((column) => {
                          const value = row[column.id]
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={12} container justifyContent="flex-end">
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{
                  marginTop: "50px",
                  border: "1px solid",
                  width: "500px"
                }}
              >
                <TableRow
                  style={{
                    textAlign: "center",
                    border: "2px solid",
                    height: "50px"
                  }}
                >
                  <TableCell
                    style={{ textAlign: "center", border: "2px solid" }}
                  ></TableCell>
                  <TableCell
                    style={{ textAlign: "center", border: "2px solid" }}
                  ></TableCell>
                  <TableCell
                    style={{ textAlign: "center", border: "2px solid" }}
                  ></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={1}
                    style={{ textAlign: "center", border: "2px solid" }}
                  >
                    <label style={{ fontSize: "18px", MozAnimation: "none" }}>
                      Approved
                    </label>
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{ textAlign: "center", border: "2px solid" }}
                  >
                    <label style={{ fontSize: "18px", MozAnimation: "none" }}>
                      Checked
                    </label>
                  </TableCell>{" "}
                  <TableCell
                    colSpan={1}
                    style={{ textAlign: "center", border: "2px solid" }}
                  >
                    <label style={{ fontSize: "18px", MozAnimation: "none" }}>
                      Received
                    </label>
                  </TableCell>
                </TableRow>
              </Table>
            </Grid>
          </Grid>
        </ThemedCard>
      </Box>
    </ThemeProvider>
  )
}

export default WhTagReport
