import { LoadingButton, LocalizationProvider, TimePicker } from "@mui/lab"
import {
  Autocomplete,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@mui/material"
import DateAdapter from "@mui/lab/AdapterMoment"
import { useEffect, useMemo, useState } from "react"
import swal from "sweetalert"
import moment from "moment"
import { CREATE_PROBLEM } from "../../../common/Mutation/PlanningMutation"
import { calculateTime, diff } from "../ProductionPlan"
import { DataProductionPlan } from "../ProductionPlanReport"
import { useMutation, useQuery } from "@apollo/client"
import { user } from "../../../common/MainApp"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import { GET_BREAKDOWN_LIST } from "../../../common/Query/ProductionQuery"

interface IProps {
  dialogOpen: boolean
  setDialogOpen: (status: boolean) => void
  planData: DataProductionPlan
  handleSubmit: () => void
  factory: string | null
}

export const problemNameList: { name: string; value: string }[] = [
  { name: "รอ QC set up", value: "Wait QC First Set Up" },
  { name: "อื่นๆ", value: "other" },
  { name: "แม่พิมพ์เสีย", value: "DIE Breakdown" },
  { name: "แผนบำรุงรักษา PM ", value: "PM" },
  { name: "เครื่องเสีย", value: "Machine Breakdown" }
]

const DialogProblem = (props: IProps): JSX.Element => {
  const { planData, dialogOpen, setDialogOpen, handleSubmit } = props
  const [startTime, setStartTime] = useState<number>(3600000)
  const [endTime, setEndTime] = useState<number>(36000000)
  const [problemName, setProblemName] = useState<string>("")
  const [remarks, setRemarks] = useState<string>("")
  const [createProblem, { loading }] = useMutation(CREATE_PROBLEM)
  const [workTime, setWorkTime] = useState<number>(0)
  const { data: problemNameOtherList } = useQuery(GET_BREAKDOWN_LIST)
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const problemOtherList = useMemo(() => {
    return problemNameOtherList
      ? problemNameOtherList.problemList.map(
          (item: { problem_name: string }) => item.problem_name
        )
      : []
  }, [problemNameOtherList])
  useMemo(() => {
    if (
      Number(moment(endTime).format("HH")) > 12 &&
      Number(moment(startTime).format("HH")) < 13
    ) {
      const time = diff(startTime, endTime - 60 * 60 * 1000)
      setWorkTime(Number(calculateTime(time)))
    } else {
      const time = diff(startTime, endTime)
      setWorkTime(Number(calculateTime(time)))
    }
    // getProblemStatus()
  }, [startTime, endTime])
  const onSubmit = async () => {
    if (!problemName) return swal("Warning", "กรุณากรอกปัญหาที่พบ", "warning")
    if (problemName === "other" && !remarks)
      return swal("Warning", "กรุณากรอกปัญหาที่พบ", "warning")
    try {
      const data = {
        machine: planData.machine,
        partNo: planData.partNo,
        process: planData.process,
        actionDate: planData.planDate,
        startTime: moment(startTime).format("HH:mm"),
        endTime: moment(endTime).format("HH:mm"),
        totalTime: workTime,
        problemName: problemName,
        remarks: remarks,
        line: planData.line,
        department: user.role
      }
      const { data: response } = await createProblem({
        variables: { dataProblem: data }
      })
      const sendData = {
        message: response.problemCreate.message,
        actionDate: response.problemCreate.actionDate,
        id: response.problemCreate.id,
        link: response.problemCreate.link,
        time: response.problemCreate.time,
        userRead: response.problemCreate.userRead,
        status: false
      }
      sendMessage(JSON.stringify(sendData))
      swal("Success", "บัททึกสำเร็จ", "success")
      setRemarks("")
      setProblemName("")
      handleSubmit()
      setDialogOpen(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  const filter = createFilterOptions<string>()
  useEffect(() => {
    const wsUrl =
      "wss://dht6wg9m3k.execute-api.ap-southeast-1.amazonaws.com/production/"
    const ws = new WebSocket(wsUrl)

    ws.onopen
    setSocket(ws)
  }, [])

  const sendMessage = (msg: string) => {
    try {
      if (socket && socket.readyState === WebSocket.OPEN) {
        const messagePayload = {
          action: "sendMessage",
          message: msg
        }

        socket.send(JSON.stringify(messagePayload))
      }
    } catch (e) {
      swal("Error", `${e}`, "warning")
    }
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        >
          Problem Machine
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            color={"black"}
            fontSize="20px"
            marginBottom={"10px"}
          >
            Machine: {props.planData.machine} <br />
            Part No: {props.planData.partNo} <br />
            Part Name: {props.planData.partName} <br />
            Process: {props.planData.process} Step: {props.planData.step}
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item sm={2}>
              <label style={{ whiteSpace: "nowrap" }}>Start Time</label>
            </Grid>
            <Grid item sm={4}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                  label="Start Time"
                  ampm={false}
                  views={["hours", "minutes"]}
                  inputFormat="HH:mm"
                  value={startTime}
                  onChange={(newSelectTime) => {
                    setStartTime(newSelectTime ? newSelectTime : 0)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField {...params} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={2}>
              <label style={{ whiteSpace: "nowrap" }}>End Time</label>
            </Grid>
            <Grid item sm={4}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                  label="End Time"
                  ampm={false}
                  views={["hours", "minutes"]}
                  inputFormat="HH:mm"
                  value={endTime}
                  onChange={(newSelectTime) => {
                    setEndTime(newSelectTime ? newSelectTime : 0)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField {...params} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={2}>
              <label>Total time: </label>
            </Grid>
            <Grid item sm={10}>
              {workTime >= 1 ? <label>{Math.trunc(workTime)} Hour </label> : ""}
              <label>{Math.round((workTime % 1) * 60)} Minute</label>
            </Grid>
            <Grid item sm={2}>
              <label>Problem Name</label>
            </Grid>
            <Grid item sm={10}>
              <Autocomplete
                fullWidth
                disablePortal
                onChange={(e, newValue) => {
                  setProblemName(newValue?.value ? newValue?.value : "")
                }}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Select Problem Type" />
                )}
                options={problemNameList}
                getOptionLabel={(option) => option.name}
              />
            </Grid>
            <Grid item sm={2}>
              Remarks
            </Grid>
            <Grid item sm={10}>
              <Autocomplete
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const { inputValue } = params
                  if (inputValue !== "") {
                    filtered.push(inputValue)
                  }
                  return filtered
                }}
                freeSolo={problemName !== "other" ? true : false}
                fullWidth
                value={remarks}
                disablePortal
                getOptionLabel={(option) => option.toString()}
                onChange={(event, selectedDrop) => {
                  setRemarks(selectedDrop ? selectedDrop : "")
                }}
                options={problemName !== "other" ? [] : problemOtherList}
                renderInput={(params) => <ThemedTextField {...params} />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            style={{ backgroundColor: "green" }}
            autoFocus
            onClick={onSubmit}
            loading={loading}
          >
            Submit
          </LoadingButton>
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DialogProblem
