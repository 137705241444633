import { useQuery } from "@apollo/client"
import { GET_LINE } from "../../../common/Query/GeneralQuery"
import { useEffect, useState } from "react"

export const useLine = (): string[] => {
  const [line, setLine] = useState<string[]>([""])
  const { data } = useQuery(GET_LINE)
  useEffect(() => {
    if (data) {
      setLine(
        data.getLine.map((element: { lineName: string }) => element.lineName)
      )
    }
  }, [data])
  return line
}
