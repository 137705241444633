import { gql } from "@apollo/client"

export const Authorization = gql`
  mutation Authorization(
    $date: Date
    $factory: String
    $name: String
    $authorizeName: String
  ) {
    authorization(
      date: $date
      factory: $factory
      name: $name
      authorizeName: $authorizeName
    )
  }
`
