import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  styled,
  Grid,
  Autocomplete,
  useTheme,
  ThemeProvider
} from "@mui/material"
import { grey } from "@mui/material/colors"
import { useState } from "react"
import { useLazyQuery } from "@apollo/client"
import AddIcon from "@mui/icons-material/Add"
import { GET_CONFIRM } from "../../common/Query/authorizationProdQuery"
import { MomentInput } from "moment"
import moment from "moment"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"

interface Column {
  id: "date" | "name" | "factory" | "authorized"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}
const columns: Column[] = [
  { id: "date", label: "Date", minWidth: 200 },
  { id: "name", label: "Name", minWidth: 200 },
  { id: "factory", label: "Factory", minWidth: 200 },
  { id: "authorized", label: "Authorized", minWidth: 200 }
]

interface Data {
  date: string
  name: string
  factory: string
  authorized: string
}

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[100]};
    font-weight:bold
  }
  `
)

const pageTitle = "Confirmation"

const AuthorizationConfirmation: React.FC = () => {
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [department, setDepartment] = useState<string>("")
  const [getConfirm, { data, loading }] = useLazyQuery(GET_CONFIRM)
  const [error, setError] = useState<boolean>(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const rows: Data[] = data
    ? data.confirmReport
        .map(
          (datum: {
            actionDate: MomentInput
            name: string
            factory: string
            date: string
          }) => {
            return {
              date: moment(datum.actionDate).format("YYYY-MM-DD"),
              name: datum.name,
              factory: datum.factory,
              authorized: datum.date ? "✔️" : "❌"
            }
          }
        )
        .sort((a: { date: string }, b: { date: string }) =>
          a.date < b.date ? 1 : a.date > b.date ? -1 : 0
        )
    : []

  const handleSubmit = () => {
    if (!department) {
      setError(true)
      return swal("ERROR", "กรุณาใส่ Department", "error")
    }
    getConfirm({
      variables: { department },
      fetchPolicy: "network-only"
    })
    if (data?.confirmReport?.length === 0) alert("ไม่พบข้อมูล")
  }
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Authorization}
        menuItemName={Object.keys(menuItemList)[9]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="Authorization Confirm"
          subTitle="detail about authorization confirm"
        />
        <Grid container spacing={2} width={"98%"} padding={2}>
          <Grid item xs={6}>
            <Autocomplete
              fullWidth
              disablePortal
              value={department}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Department"
                  InputLabelProps={{ shrink: true }}
                  error={!department && error}
                />
              )}
              onChange={(e, newValue) =>
                setDepartment(newValue ? newValue : "")
              }
              options={["QC", "Production"]}
            />
          </Grid>
          <Grid item xs={4}>
            <ThemedLoadingButton
              loading={loading}
              variant="contained"
              sx={{ mt: 1, mb: 1, backgroundColor: theme.palette.primary.main }}
              onClick={handleSubmit}
              endIcon={<AddIcon />}
            >
              Submit
            </ThemedLoadingButton>
          </Grid>
        </Grid>
      </ThemedCard>
      <ThemedCard style={{ marginTop: "25px", padding: "15px" }}>
        <Root sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>Authorization Confirm</TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                        {columns.map((column) => {
                          const value = row[column.id]
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : column.id === "date"
                                ? moment(value).format("DD-MM-YYYY")
                                : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Root>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default AuthorizationConfirmation
