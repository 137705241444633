import { TextField, TextFieldProps } from "@mui/material"
import { styled } from "@mui/material/styles"

const ThemedTextFieldComponent = styled(TextField)(({ theme }) => ({
  "& label": {
    zIndex: 3,
    "&.Mui-focused": {
      color: theme.palette.secondary.contrastText
    }
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.secondary.contrastText
    },
    "&:hover fieldset": {
      borderColor:
        theme.palette.mode === "light"
          ? theme.palette.primary.dark
          : theme.palette.secondary.light
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${theme.palette.secondary.contrastText}`
    }
  },
  // note lack of space
  input: {
    "&.MuiOutlinedInput-input.MuiInputBase-input.Mui-disabled": {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.secondary.main
          : theme.palette.primary.dark
    }
  }
})) as typeof TextField
//important to cast styled components as its original MUI component

const ThemedTextField = (props: TextFieldProps): JSX.Element => {
  const shrink =
    props?.InputLabelProps?.shrink === false
      ? props?.InputLabelProps?.shrink
      : true

  const themedTextField = (
    <ThemedTextFieldComponent
      {...props}
      InputLabelProps={{
        shrink
      }}
    />
  )
  return <>{themedTextField}</>
}

export default ThemedTextField
