import { useLazyQuery } from "@apollo/client"
import { useMemo, useState } from "react"
import { GET_PRODUCTION_PLAN_REVISION } from "../../../common/Query/PlanningQuery"

interface IShiftList {
  revisionList: string[]
  loadRevision: boolean
  lastRevision: string
}
export const useRevisionList = (
  date: string,
  line: string,
  factory: string,
  shift: string
): IShiftList => {
  const [revisionList, setRevisionList] = useState<string[]>([])
  const [lastRevision, setLastRevision] = useState<string>("")

  const [getRevision, { loading: loadRevision }] = useLazyQuery(
    GET_PRODUCTION_PLAN_REVISION
  )

  useMemo(async () => {
    if (date && line && factory && shift) {
      const { data } = await getRevision({
        variables: {
          date: date,
          line: line,
          factory: factory,
          shift: shift
        }
      })
      const rawRevisionList = data.productionPlanRevision
      setLastRevision(rawRevisionList[rawRevisionList.length - 1])
      setRevisionList(rawRevisionList)
    }
  }, [line, date, factory, shift])
  return { revisionList, loadRevision, lastRevision }
}
