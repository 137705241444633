import { useTheme } from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useRef } from "react"
import { IInventorySaleOrder } from "../hook/useFetchData"

export const ChartTotalInventoryMaterialMonthly = (props: {
  chart?: HighchartsReact.Props
  inventorySaleOrder: IInventorySaleOrder | undefined
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const theme = useTheme()
  const { inventorySaleOrder } = props
  if (!inventorySaleOrder) return <>Loading...</>
  const options: Highcharts.Options = {
    title: {
      text: "Total Inventory",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    credits: { enabled: false },
    chart: {
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: ["Total"],
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "8px"
        }
      }
    },
    yAxis: [
      {
        labels: {
          style: {
            color: theme.palette.secondary.contrastText
          }
        },
        title: {
          text: "Total",
          style: {
            color: theme.palette.secondary.contrastText
          }
        }
      },
      {
        opposite: true,
        title: {
          text: ""
        }
      },
      {
        opposite: true,
        title: {
          text: ""
        }
      }
    ],
    tooltip: {
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.02,
        borderRadius: 5,
        stacking: "normal"
      }
    },
    series: [
      {
        name: "Sale Order",
        type: "column",
        stack: "inventory",
        color: "#AACB73",
        data: [
          Number(inventorySaleOrder?.inventoryDashboard?.sumInventory.saleOrder)
        ],
        dataLabels: [
          {
            enabled: true
          }
        ]
      },
      {
        name: "Inventory Mat't Stock",
        type: "column",
        color: "#ffa500",
        stack: "saleOrder",
        data: [
          Math.round(
            Number(
              inventorySaleOrder?.inventoryDashboard?.sumInventory.matStock
            )
          )
        ],
        dataLabels: [
          {
            enabled: true
          }
        ]
      },
      {
        name: "Inventory FG Stock",
        type: "column",
        color: "#03C988",
        stack: "saleOrder",
        data: [
          Number(inventorySaleOrder?.inventoryDashboard?.sumInventory.fgStock)
        ],
        dataLabels: [
          {
            enabled: true
          }
        ]
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props.chart}
      />
    </>
  )
}
export default ChartTotalInventoryMaterialMonthly
