import { useQuery } from "@apollo/client"
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  // tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import { styled } from "@mui/styles"
import moment from "moment"
import { GET_DAILY_PRODUCTION_LIST } from "../../common/Query/ProductionQuery"
import { getImagePng, logoTable } from "../../common/utilities"
import { DataProductionPlan } from "../Planning/ProductionPlanReport"

interface Column {
  id:
    | "revision"
    | "planDate"
    | "partNo"
    | "partName"
    | "customer"
    | "line"
    | "step"
    | "process"
    | "machine"
    | "jph"
    | "startTime"
    | "endTime"
    | "startTimeOT"
    | "endTimeOT"
    | "totalHour"
    | "totalQuantity"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

interface IProps {
  data: DataProductionPlan[]
  baseRevision: DataProductionPlan[]
}

const StyledTableRow = styled(TableRow)(() => ({
  color: "#000000",
  backgroundColor: "#FFFFFF"
}))

const columns: Column[] = [
  { id: "revision", label: "Revision", minWidth: 200 },
  { id: "partNo", label: "PartNo", minWidth: 200 },
  { id: "step", label: "Process", minWidth: 150 },
  { id: "machine", label: "Machine", minWidth: 200 },
  { id: "startTime", label: "StartTime", minWidth: 200 },
  { id: "endTime", label: "EndTime", minWidth: 200 },
  { id: "startTimeOT", label: "StartTimeOT", minWidth: 200 },
  { id: "endTimeOT", label: "EndTimeOT", minWidth: 200 },
  { id: "totalQuantity", label: "TotalQuantity", minWidth: 200 }
]

const ProductionList = (props: IProps) => {
  const newRevision: DataProductionPlan[] = props.data || []
  const baseRevision: DataProductionPlan[] = props.baseRevision || []
  const rows = newRevision.map((e) => {
    const checkEditBaseRevision = baseRevision.find((base) => {
      return (
        base.machine === e.machine &&
        base.partNo === e.partNo &&
        base.startTime === e.startTime &&
        base.endTime === e.endTime &&
        base.startTimeOT === e.startTimeOT &&
        base.endTimeOT === e.endTimeOT
      )
    })

    if (!checkEditBaseRevision)
      return {
        ...e,
        fieldEdit: true
      }
    else
      return {
        ...e,
        fieldEdit: false
      }
  })
  let sumTotal = 0
  return (
    <Box sx={{ backgroundColor: "#FFFFFF" }}>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ border: "1px solid black" }}
        >
          <TableHead>
            <StyledTableRow>
              <TableCell colSpan={3}>
                <img
                  style={{ width: "100%", verticalAlign: "top" }}
                  src={logoTable}
                  alt=""
                />
              </TableCell>
              <TableCell colSpan={3}>
                <h3>PRODUCTION PLAN</h3>
              </TableCell>
              <TableCell colSpan={3} style={{ padding: 3 }}>
                <h3>
                  Production Date : {moment(new Date()).format("DD-MM-YYYY")}
                </h3>
                <br />
                <hr />
                <h3>Line : {props.data[0].line}</h3>
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ padding: 3 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              sumTotal += row.totalQuantity

              return (
                <StyledTableRow hover key={i}>
                  {columns.map((column) => {
                    const value = row[column.id]
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={
                          row.pdPlan !== "แผนสำรอง" && row.fieldEdit
                            ? { color: "red", padding: 3 }
                            : { padding: 3 }
                        }
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    )
                  })}
                </StyledTableRow>
              )
            })}
            <StyledTableRow>
              <TableCell colSpan={8}>Total</TableCell>
              <TableCell colSpan={1} align="left">
                {sumTotal}
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        spacing={0}
        sx={{ border: "1px solid black", borderTop: 0 }}
      >
        <Grid item sm={2} xs={12} margin={"4px"}>
          <Table padding="none">
            <TableBody>
              <StyledTableRow>
                <TableCell
                  colSpan={3}
                  align="center"
                  style={{ fontSize: "10px" }}
                >
                  PLANNING
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell style={{ fontSize: "10px" }} align="center">
                  {props.data[0].employee}
                </TableCell>
                <TableCell style={{ fontSize: "10px" }} align="center">
                  {props.data[0].factory === "2"
                    ? "มนตรี"
                    : props.data[0].plApprove}
                </TableCell>
                <TableCell>
                  <img
                    src={getImagePng("images/signature/signature_nut")}
                    width={"30px"}
                  />
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell style={{ fontSize: "10px" }} align="center">
                  ISSUE
                </TableCell>
                <TableCell style={{ fontSize: "10px" }} align="center">
                  Checked
                </TableCell>
                <TableCell style={{ fontSize: "10px" }} align="center">
                  Approved
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item sm={2} xs={12} margin={"4px"}>
          <Table padding="none">
            <TableBody>
              <StyledTableRow>
                <TableCell align="center" style={{ fontSize: "10px" }}>
                  PRODUCTION
                </TableCell>
              </StyledTableRow>
              <StyledTableRow></StyledTableRow>
              <StyledTableRow>
                <TableCell style={{ fontSize: "10px" }} align="center">
                  {props.data[0].pdApprove}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item sm={2} xs={12} margin={"4px"}>
          <Table padding="none">
            <TableBody>
              <StyledTableRow>
                <TableCell align="center" style={{ fontSize: "10px" }}>
                  ENGINEER
                </TableCell>
              </StyledTableRow>
              <StyledTableRow></StyledTableRow>
              <StyledTableRow>
                <TableCell style={{ fontSize: "10px" }} align="center">
                  {props.data[0].engApprove}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item sm={2} xs={12} margin={"4px"}>
          <Table padding="none">
            <TableBody>
              <StyledTableRow>
                <TableCell align="center" style={{ fontSize: "10px" }}>
                  QUANTITY
                </TableCell>
              </StyledTableRow>
              <StyledTableRow></StyledTableRow>
              <StyledTableRow>
                <TableCell style={{ fontSize: "10px" }} align="center">
                  {props.data[0].qaApprove}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item sm={2} xs={12} margin={"4px"}>
          <Table padding="none">
            <TableBody>
              <StyledTableRow>
                <TableCell align="center" style={{ fontSize: "10px" }}>
                  MAINTENANCE
                </TableCell>
              </StyledTableRow>
              <StyledTableRow></StyledTableRow>
              <StyledTableRow>
                <TableCell style={{ fontSize: "10px" }} align="center">
                  {props.data[0].mtApprove}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Box>
  )
}

const DailyProductionList: React.FC = () => {
  const { data, loading } = useQuery(GET_DAILY_PRODUCTION_LIST, {
    variables: {
      factory: "2"
    }
  })
  const dailyProductionList: DataProductionPlan[] =
    data?.dailyProductionList.newRevision || []
  const baseDailyProductionList: DataProductionPlan[] =
    data?.dailyProductionList.baseRevision || []
  interface IDictionaryLine {
    [id: string]: DataProductionPlan[]
  }
  const newProductionPlan: IDictionaryLine = {}
  for (const pdPlan of dailyProductionList) {
    const curPart = newProductionPlan[pdPlan.line]
    if (!curPart) newProductionPlan[pdPlan.line] = [pdPlan]
    else {
      newProductionPlan[pdPlan.line].push(pdPlan)
    }
  }
  const baseProductionPlan: IDictionaryLine = {}
  for (const pdPlan of baseDailyProductionList) {
    const curPart = baseProductionPlan[pdPlan.line]
    if (!curPart) baseProductionPlan[pdPlan.line] = [pdPlan]
    else {
      baseProductionPlan[pdPlan.line].push(pdPlan)
    }
  }
  const baseDailyProduction = Object.entries(baseProductionPlan)
  const dailyProduction = Object.entries(newProductionPlan).sort(
    (a, b) => b[1].length - a[1].length
  )
  return (
    <Grid container spacing={1} width={"99%"}>
      <Dialog open={!!loading}>
        <DialogContent style={{ width: "auto" }}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      {dailyProduction.map((e, i) => {
        return (
          <Grid item sm={6} key={i}>
            <ProductionList
              data={e[1]}
              baseRevision={
                baseDailyProduction.filter((base) => e[0] === base[0])[0][1]
              }
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
export default DailyProductionList
