import { useMutation, useQuery } from "@apollo/client"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  useTheme,
  ThemeProvider,
  styled,
  tableCellClasses,
  TableContainer,
  Paper,
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  DialogContent,
  Autocomplete
} from "@mui/material"
import { GET_BREAKDOWN_LIST } from "../../common/Query/ProductionQuery"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import { DialogCircularProgress } from "../../common/Resources/DialogCircularProgress"
import { useState } from "react"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { Add, Delete } from "@mui/icons-material"
import {
  CREATE_PROBLEM_NAME,
  DELETE_PROBLEM_NAME
} from "../../common/Mutation/productionMutation"
import swal from "sweetalert"

interface Column {
  id: "id" | "problem_name"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}
interface IData {
  id: number
  problem_name: string
}
interface IProp {
  open: boolean
  title: string
  list: IData[]
  setOpen: (value: boolean) => void
}
const pageTitle = "Master BreakDown"

const columns: Column[] = [
  { id: "problem_name", label: "Problem Name", minWidth: 50 }
]
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    //these gets overwritten when using ThemeProvider
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.secondary.contrastText,
    fontSize: 26,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 22,
    fontFamily: "Sarabun"
  }
}))
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.black
  },

  "td, th": {
    border: 0
  }
}))
const MasterBreakdown: React.FC = () => {
  const { data, loading } = useQuery(GET_BREAKDOWN_LIST)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [open, setOpen] = useState<boolean>(false)
  const [title, setTitle] = useState<string>("")
  const [value, setValue] = useState<string>("")
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  let rows: IData[] = data ? data.problemList : []
  if (value) {
    rows = rows.filter((row) => row.problem_name === value)
  }
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <DialogCircularProgress loading={loading} />
      <PageLabel
        menuItem={menuItemList.Production}
        menuItemName={Object.keys(menuItemList)[3]}
        menuPageName={pageTitle}
      />
      <DialogCreateOrDeleteProblem
        open={open}
        title={title}
        setOpen={setOpen}
        list={rows}
      />
      <ThemedCard>
        <PageLabelCard
          title="Breakdown List"
          subTitle="About detail breakdown"
        />
        <Box
          justifyContent={"flex-end"}
          sx={{
            display: "flex"
          }}
        >
          <ThemedButton
            id="add-customer-dialog-button"
            style={{
              whiteSpace: "nowrap",
              top: "8px",
              width: "155px",
              height: "40px",
              marginRight: "20px"
            }}
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              setTitle("Add Problem")
              setOpen(true)
            }}
          >
            Add Problem
          </ThemedButton>
          <ThemedButton
            id="add-customer-dialog-button"
            style={{
              whiteSpace: "nowrap",
              top: "8px",
              width: "155px",
              height: "40px",
              marginRight: "20px"
            }}
            variant="contained"
            startIcon={<Delete />}
            onClick={() => {
              setTitle("Delete Problem")
              setOpen(true)
            }}
          >
            Delete Problem
          </ThemedButton>
        </Box>
        <Autocomplete
          value={value}
          sx={{ width: "400px" }}
          onChange={(event, selectedCustomer) => {
            setValue(selectedCustomer as string)
          }}
          options={rows.map(({ problem_name }) => problem_name)}
          renderInput={(params) => (
            <ThemedTextField
              {...params}
              sx={{ mt: 2 }}
              label="Problem Name"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
        <TableContainer component={Paper} style={{ marginTop: "10px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                {columns.map((column) => (
                  <StyledTableCell key={column.id} align={column.align}>
                    {column.label}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <StyledTableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        )
                      })}
                    </StyledTableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ThemedCard>
    </ThemeProvider>
  )
}
const DialogCreateOrDeleteProblem: React.FC<IProp> = (props: IProp) => {
  const { open, title, list, setOpen } = props
  const [value, setValue] = useState<string>("")
  const [createProblem] = useMutation(CREATE_PROBLEM_NAME, {
    refetchQueries: [{ query: GET_BREAKDOWN_LIST }]
  })
  const [deleteProblem] = useMutation(DELETE_PROBLEM_NAME, {
    refetchQueries: [{ query: GET_BREAKDOWN_LIST }]
  })
  const handleSubmit = async () => {
    try {
      if (title === "Delete Problem") {
        await deleteProblem({ variables: { problemName: value } })
        swal("Delete Success", "", "success")
      } else {
        await createProblem({ variables: { problemName: value } })
        swal("Create Success", "", "success")
      }
      setValue("")
      setOpen(false)
    } catch (error) {
      swal("Error", (error as Error).message, "error")
    }
  }
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      key="master-customer-dialog"
    >
      <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Sarabun" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        {title === "Add Problem" ? (
          <ThemedTextField
            value={value}
            fullWidth
            sx={{ mt: 2 }}
            InputLabelProps={{ shrink: true }}
            label="Problem Name"
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          <Autocomplete
            value={value}
            fullWidth
            onChange={(event, selectedCustomer) => {
              setValue(selectedCustomer as string)
            }}
            options={list.map(({ problem_name }) => problem_name)}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                sx={{ mt: 2 }}
                label="Problem Name"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions style={{ alignSelf: "start" }}>
        <ThemedLoadingButton
          onClick={handleSubmit}
          variant="contained"
          style={{
            borderRadius: "5px",
            marginLeft: "16px"
          }}
        >
          SUBMIT
        </ThemedLoadingButton>
        <ThemedButton contrast variant="text" onClick={() => setOpen(false)}>
          CANCEL
        </ThemedButton>
      </DialogActions>
    </Dialog>
  )
}
export default MasterBreakdown
