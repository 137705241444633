import { Card, Box, useTheme, Grid, Theme, Typography } from "@mui/material"
import moment from "moment"
import { useContext, useLayoutEffect, useMemo, useState } from "react"
import "../../css/Dashboard/Dashboard.css"
import BreakDownMonthlyMonthly, {
  ChartBreakDownMonthlyMonthly
} from "./chartComponent/BreakDownMonthly"
import BreakDownProblemMonthly, {
  ChartBreakDownProblemMonthly
} from "./chartComponent/BreakDownProblemMonthly"
import Capacity, { ChartCapacity } from "./chartComponent/CapacityMonthly"
import InventoryDefectReport, {
  ChartDefectReport
} from "./chartComponent/DefectMonthly"
import InventoryMaterialMonthly, {
  ChartInventoryMaterialMonthly
} from "./chartComponent/InventoryMonthly"
import OEEMonthly, { ChartOEE } from "./chartComponent/OEEMonthly"
import ShareMarketVolumePlan from "./chartComponent/ShareMarketVolumePlan"
import ChartTotalInventoryMaterialMonthly from "./chartComponent/TotalInventory"
import VolumePlan, { ChartVolumePlan } from "./chartComponent/VolumePlan"
import VolumePlanAutoPart, {
  ChartVolumePlanAutoPart
} from "./chartComponent/VolumePlanAutoPart"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import Highcharts from "highcharts"
import ReactGridLayout, { Responsive, WidthProvider } from "react-grid-layout"
import PremiumFreightDialog, {
  PremiumFreightChart
} from "./chartComponent/PremiumFreightChart"
import { checkRole } from "../../config/routes"
import {
  conditionLinePress,
  getImagePng,
  isMobile
} from "../../common/utilities"
import FilterChartButton from "../../common/Resources/FilterChartButton"
import { ITargetKpi } from "../Maintenance/component/MTTR"

import HighChartsDialogButton from "../../common/Resources/HighChartsDialogButton"
import AuthContext from "../../context/AuthContext"
import { PMColumnChart } from "./chartComponent/PMDashboard"
import { MTTRColumnDashboard } from "./chartComponent/MTTRDashboard"
import { MTBFDashboard } from "./chartComponent/MTBFDashboard"
import { BMDashboard } from "./chartComponent/BMDashboard"
import { IPress, useFetchData } from "./hook/useFetchData"

const ResponsiveGridLayout = WidthProvider(Responsive)
export interface GridLayoutConfig extends ReactGridLayout.Layout {
  i: string
  x: number
  y: number
  w: number
  h: number
}

const useStyles = (theme: Theme) => ({
  dashboardHeader: {
    backgroundColor: "#ffffff",
    height: "70px"
  },
  dashboardTitle: {
    color: theme.palette.mode === "light" ? "#000000" : "#FFFFFF",
    textShadow:
      theme.palette.mode === "light" ? "2px 2px white" : "2px 2px black",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "34px",
    lineHeight: "125%",
    letterSpacing: "0.25px",
    marginLeft: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    }
  },
  dashboardSubTitle: {
    fontSize: "16px"
  },
  regularMode: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  mobileMode: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  filterChartButton: {
    justifyContent: "flex-end"
  }
})

const layoutBase: GridLayoutConfig[] = [
  { i: "inventory-material-monthly", x: 0, y: 0, w: 4, h: 3 },
  { i: "total-inventory-material-monthly", x: 4, y: 0, w: 4, h: 3 },
  { i: "volume-plan", x: 0, y: 3, w: 2, h: 3 },
  { i: "market-volume-plan", x: 2, y: 3, w: 3, h: 3 },
  { i: "volume-plan-auto-part", x: 5, y: 3, w: 3, h: 3 },
  { i: "oee", x: 0, y: 6, w: 4, h: 3 },
  { i: "breakdown-monthly", x: 4, y: 6, w: 2, h: 3 },
  { i: "breakdown-problem-monthly", x: 6, y: 6, w: 2, h: 3 },
  { i: "defect-report", x: 0, y: 9, w: 2, h: 3 },
  { i: "premium-freight", x: 2, y: 9, w: 2, h: 3 },
  { i: "capacity", x: 4, y: 9, w: 4, h: 3 },
  { i: "PMKPI", x: 0, y: 10, w: 4, h: 3 },
  { i: "MTTR", x: 4, y: 10, w: 4, h: 3 },
  { i: "MTBF", x: 0, y: 13, w: 4, h: 3 },
  { i: "BM", x: 4, y: 13, w: 4, h: 3 }
]

const layoutBaseMobile: GridLayoutConfig[] = [
  { i: "inventory-material-monthly", x: 0, y: 0, w: 6, h: 3 },
  { i: "total-inventory-material-monthly", x: 0, y: 3, w: 6, h: 3 },
  { i: "volume-plan", x: 0, y: 6, w: 2, h: 3 },
  { i: "market-volume-plan", x: 2, y: 6, w: 2, h: 3 },
  { i: "volume-plan-auto-part", x: 0, y: 9, w: 2, h: 3 },
  { i: "oee", x: 0, y: 12, w: 4, h: 3 },
  { i: "breakdown-monthly", x: 2, y: 9, w: 2, h: 3 },
  { i: "breakdown-problem-monthly", x: 0, y: 15, w: 2, h: 3 },
  { i: "defect-report", x: 2, y: 15, w: 2, h: 3 },
  { i: "premium-freight", x: 0, y: 18, w: 4, h: 3 },
  { i: "capacity", x: 0, y: 21, w: 4, h: 3 },
  { i: "PMKPI", x: 0, y: 24, w: 4, h: 3 },
  { i: "MTTR", x: 0, y: 27, w: 4, h: 3 },
  { i: "MTBF", x: 0, y: 30, w: 4, h: 3 },
  { i: "BM", x: 0, y: 33, w: 4, h: 3 }
]

const gridCols = { lg: 8, md: 6, sm: 4, xs: 4, xxs: 4 }

//code is deprecated but works for grid layout
//window.dispatchEvent(new Event('resize')) doesn't work apparently
const triggerWindowResize = () => {
  const evt = window.document.createEvent("UIEvents")
  evt.initUIEvent("resize", true, false, window, 0)
  setTimeout(() => window.dispatchEvent(evt), 100)
}
const initQuarter = () => {
  const date = new Date()
  const month = date.getMonth()
  return Math.floor(month / 3) + 1
}
const Dashboard = (): JSX.Element => {
  const date = new Date()
  const newDate = moment(date).format("DD-MM-YYYY").split("-")
  const theme = useTheme()
  const classes = useStyles(theme)
  const [openOee, setOpenOee] = useState<boolean>(false)
  const [openCapacity, setOpenCapacity] = useState<boolean>(false)
  const [openBreakDown, setBreakDown] = useState<boolean>(false)
  const [openProblem, setProblem] = useState<boolean>(false)
  const [openDefect, setDefect] = useState<boolean>(false)
  const [openVolumePlan, setOpenVolumePlan] = useState<boolean>(false)
  const [openQuarterVolumePlan, setQuarterVolumePlan] = useState<boolean>(false)
  const [openPremiumFreight, setOpenPremiumFreight] = useState<boolean>(false)
  const [oeeInventoryDashboard, setInventoryDashboard] =
    useState<boolean>(false)
  const { chartLayout, chartList, setChartLayout, setChartList } =
    useContext(AuthContext)

  const [inventoryType, setInventoryType] = useState<string>("autoPart")
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      "YYYY-MM-DD"
    )
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
      "YYYY-MM-DD"
    )
  )
  const [factory, setFactory] = useState<string>("2")
  const [month, setMonth] = useState<Date>(new Date())
  const [line, setLine] = useState<string>("LINE B")
  const [year, setYear] = useState<string>(moment(new Date()).format("yyyy"))
  const [quarter, setQuarter] = useState<number>(initQuarter)

  const {
    inventorySaleOrder,
    volumePlan,
    autoPart,
    oeeMonthly,
    breakDownMachine,
    breakdownProblem,
    monthlyDefect,
    premiumFreight,
    capacity,
    lineList,
    holiday,
    pmKPI,
    repairRequestData,
    machineMT,
    queryRepairer,
    targetMT,
    runtimeMachine
  } = useFetchData(
    inventoryType,
    dateFrom,
    dateTo,
    factory,
    month,
    quarter,
    year
  )

  useLayoutEffect(() => {
    triggerWindowResize()
  }, [])

  const onResize = () => {
    for (let i = 0; i < Highcharts.charts.length; i += 1) {
      Highcharts?.charts[i]?.setSize(null, null, false)
      Highcharts?.charts[i]?.reflow()
    }
    triggerWindowResize()
  }

  const onResetChartLayout = (): void => {
    window.localStorage.removeItem("chart-layout")
    setChartLayout(layoutBase)
  }

  const onResetChartList = (): void => {
    onResize()
    window.localStorage.removeItem("filter-chart-list")
  }

  const onLayoutChange = () => {
    const screenWith = window.innerWidth
    const layout = screenWith > 1309 ? layoutBase : layoutBaseMobile
    window.localStorage.setItem("chart-layout", JSON.stringify(layout))
    setChartLayout(layout)
  }

  const MTTRTarget = useMemo(() => {
    return targetMT.filter((e) => e.typeName === "MTTR")
  }, [targetMT])
  const MTBFTarget = useMemo(() => {
    return targetMT.filter((e) => e.typeName === "MTBF")
  }, [targetMT])
  const BMTarget = useMemo(() => {
    return targetMT.filter((e) => e.typeName === "BM")
  }, [targetMT])
  const linePress = useMemo(() => {
    const data = repairRequestData?.filter((filterData) => {
      const machine = machineMT.find(
        (e: { code: string }) => e.code === filterData.code
      )
      return (
        conditionLinePress(machine?.line || "") &&
        filterData.factory === "2" &&
        filterData.approveBy &&
        filterData.workType === "ซ่อม"
      )
    })
    const press: IPress[] = []
    data?.forEach((e) => {
      const repairer = queryRepairer.find(
        (repairer) =>
          repairer.docNo.toString() === e.recordNo &&
          e.department === repairer.department &&
          repairer.factory === e.factory &&
          repairer.detail === e.detail
      )
      if (repairer) {
        press.push({
          ...repairer,
          actionDate: e.actionDate,
          docNo: e?.recordNo.toString() || "0"
        })
      }
    })

    return press
  }, [repairRequestData, queryRepairer])

  return (
    <>
      {checkRole(["99"]) ? (
        <div
          style={{
            backgroundColor: theme.palette.primary.light
          }}
        >
          <Grid
            container
            spacing={0}
            direction="row"
            sx={{ ...classes.dashboardHeader }}
            alignItems="center"
            style={{ backgroundColor: theme.palette.primary.light }}
          >
            <Grid item xs={9} sm={6}>
              <Box sx={{ ...classes.regularMode }}>
                <Typography variant="h1" sx={{ ...classes.dashboardTitle }}>
                  {`Dashboard ประจำเดือน ${newDate[1]}-${newDate[2]}`}
                </Typography>
              </Box>
              <Box sx={{ ...classes.mobileMode }}>
                <Grid container spacing={0}>
                  <Grid item>
                    <Typography
                      variant="h1"
                      sx={{ ...classes.dashboardTitle }}
                    >{`Dashboard`}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h4"
                      sx={{
                        ...classes.dashboardTitle,
                        ...classes.dashboardSubTitle
                      }}
                    >{`ประจำเดือน ${newDate[1]}-${newDate[2]}`}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={3} sm={6}>
              <Box display="flex" sx={{ ...classes.filterChartButton }}>
                <FilterChartButton
                  buttonText="Filter"
                  filterChartList={chartList}
                  setFilterChartList={setChartList}
                  onResetChartLayout={onResetChartLayout}
                  onResetChartList={onResetChartList}
                />
              </Box>
            </Grid>
          </Grid>
          <ResponsiveGridLayout
            className="layout dashboard-grid-container"
            layouts={{ lg: chartLayout, sm: layoutBaseMobile }}
            cols={gridCols}
            onResizeStop={onResize}
            onLayoutChange={onLayoutChange}
            isDraggable={isMobile()}
            isResizable={false}
            style={{
              backgroundColor: theme.palette.primary.light
            }}
          >
            <Card
              key="inventory-material-monthly"
              className="dashboard-grid-item"
              style={{
                display: chartList[0].checked ? "" : "none"
              }}
            >
              <HighChartsDialogButton
                onDoubleClick={() => setInventoryDashboard(true)}
                className="dashboard-grid-item"
                setOpenChart={setInventoryDashboard}
              >
                <ChartInventoryMaterialMonthly
                  inventorySaleOrder={inventorySaleOrder}
                />
              </HighChartsDialogButton>
              <InventoryMaterialMonthly
                type={inventoryType}
                setInventoryType={setInventoryType}
                dialogOpen={oeeInventoryDashboard}
                setDialogOpen={setInventoryDashboard}
                inventorySaleOrder={inventorySaleOrder}
              />
            </Card>
            <Card
              key="total-inventory-material-monthly"
              className="dashboard-grid-item"
              style={{ display: chartList[1].checked ? "" : "none" }}
            >
              <ChartTotalInventoryMaterialMonthly
                inventorySaleOrder={inventorySaleOrder}
              />
            </Card>
            <Card
              key="volume-plan"
              className="dashboard-grid-item"
              style={{ display: chartList[2].checked ? "" : "none" }}
            >
              <HighChartsDialogButton
                onDoubleClick={() => setQuarterVolumePlan(true)}
                className="dashboard-grid-item"
                setOpenChart={setQuarterVolumePlan}
              >
                <ChartVolumePlan volumePlan={volumePlan} />
              </HighChartsDialogButton>
              <VolumePlan
                dialogOpen={openQuarterVolumePlan}
                setDialogOpen={setQuarterVolumePlan}
                volumePlan={volumePlan}
                quarter={quarter}
                setQuarter={setQuarter}
                dateRange={{
                  dateFrom: "",
                  dateTo: ""
                }}
              />
            </Card>
            <Card
              key="market-volume-plan"
              className="dashboard-grid-item"
              style={{ display: chartList[3].checked ? "" : "none" }}
            >
              <ShareMarketVolumePlan volumePlan={volumePlan} />
            </Card>
            <Card
              key="volume-plan-auto-part"
              className="dashboard-grid-item"
              style={{ display: chartList[4].checked ? "" : "none" }}
            >
              <HighChartsDialogButton
                onDoubleClick={() => setOpenVolumePlan(true)}
                className="dashboard-grid-item"
                setOpenChart={setOpenVolumePlan}
              >
                <ChartVolumePlanAutoPart autoPart={autoPart} />
              </HighChartsDialogButton>
              <VolumePlanAutoPart
                inventoryType={inventoryType}
                setInventoryType={setInventoryType}
                autoPart={autoPart}
                dialogOpen={openVolumePlan}
                setDialogOpen={setOpenVolumePlan}
                dateRange={{
                  dateFrom: "",
                  dateTo: ""
                }}
              />
            </Card>
            <Card
              key="oee"
              className="dashboard-grid-item"
              style={{ display: chartList[5].checked ? "" : "none" }}
            >
              <HighChartsDialogButton
                onDoubleClick={() => setOpenOee(true)}
                className="dashboard-grid-item"
                setOpenChart={setOpenOee}
              >
                <ChartOEE oeeMonthly={oeeMonthly} />
              </HighChartsDialogButton>
              <OEEMonthly
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
                dialogOpen={openOee}
                setDialogOpen={setOpenOee}
                oeeMonthly={oeeMonthly}
              />
            </Card>
            <Card
              key="breakdown-monthly"
              className="dashboard-grid-item"
              style={{ display: chartList[6].checked ? "" : "none" }}
            >
              <HighChartsDialogButton
                onDoubleClick={() => setBreakDown(true)}
                className="dashboard-grid-item"
                setOpenChart={setBreakDown}
              >
                <ChartBreakDownMonthlyMonthly
                  breakDownMachine={breakDownMachine}
                />
              </HighChartsDialogButton>
              <BreakDownMonthlyMonthly
                breakDownMachine={breakDownMachine}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                dateFrom={dateFrom}
                dateTo={dateTo}
                dialogOpen={openBreakDown}
                setDialogOpen={setBreakDown}
              />
            </Card>
            <Card
              key="breakdown-problem-monthly"
              className="dashboard-grid-item"
              style={{ display: chartList[7].checked ? "" : "none" }}
            >
              <HighChartsDialogButton
                onDoubleClick={() => setProblem(true)}
                className="dashboard-grid-item"
                setOpenChart={setProblem}
              >
                <ChartBreakDownProblemMonthly
                  breakdownProblem={breakdownProblem}
                />
              </HighChartsDialogButton>
              <BreakDownProblemMonthly
                dateFrom={dateFrom}
                dateTo={dateTo}
                breakdownProblem={breakdownProblem}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                dialogOpen={openProblem}
                setDialogOpen={setProblem}
              />
            </Card>
            <Card
              key="defect-report"
              className="dashboard-grid-item"
              style={{
                display: chartList[8].checked ? "" : "none"
              }}
            >
              <HighChartsDialogButton
                onDoubleClick={() => setDefect(true)}
                className="dashboard-grid-item"
                setOpenChart={setDefect}
              >
                <ChartDefectReport monthlyDefect={monthlyDefect} />
              </HighChartsDialogButton>
              <InventoryDefectReport
                dateFrom={dateFrom}
                dateTo={dateTo}
                monthlyDefect={monthlyDefect}
                dialogOpen={openDefect}
                setDialogOpen={setDefect}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
              />
            </Card>
            <Card
              key="premium-freight"
              className="dashboard-grid-item"
              style={{ display: chartList[9].checked ? "" : "none" }}
            >
              <HighChartsDialogButton
                onDoubleClick={() => setOpenPremiumFreight(true)}
                className="dashboard-grid-item"
                setOpenChart={setOpenPremiumFreight}
              >
                <PremiumFreightChart premiumFreight={premiumFreight} />
              </HighChartsDialogButton>
              <PremiumFreightDialog
                dialogOpen={openPremiumFreight}
                setDialogOpen={setOpenPremiumFreight}
                premiumFreight={premiumFreight}
                setFactory={setFactory}
                setYear={setYear}
                factory={factory}
                year={year}
                quarter={quarter}
                setQuarter={setQuarter}
              />
            </Card>
            <Card
              key="capacity"
              className="dashboard-grid-item"
              style={{ display: chartList[10].checked ? "" : "none" }}
            >
              <HighChartsDialogButton
                onDoubleClick={() => setOpenCapacity(true)}
                className="dashboard-grid-item"
                setOpenChart={setOpenCapacity}
              >
                <ChartCapacity
                  line={line}
                  capacity={capacity}
                  date={month}
                  holiday={holiday}
                />
              </HighChartsDialogButton>
              <Capacity
                month={month}
                dialogOpen={openCapacity}
                setDialogOpen={setOpenCapacity}
                line={line}
                setLine={setLine}
                capacity={capacity}
                holiday={holiday}
                setMonth={setMonth}
                lineList={lineList}
              />
            </Card>
            <Card
              key="PMKPI"
              className="dashboard-grid-item"
              style={{ display: chartList[11]?.checked ? "" : "none" }}
            >
              <PMColumnChart pmKPI={pmKPI} />
            </Card>
            <Card
              key="MTTR"
              className="dashboard-grid-item"
              style={{
                display: chartList[11]?.checked ? "" : "none",
                backgroundColor: theme.palette.secondary.main
              }}
            >
              <MTTRColumnDashboard
                lineData={linePress}
                target={MTTRTarget.filter((e) => e.line === "Press")[0]}
              />
            </Card>
            <Card
              key="MTBF"
              className="dashboard-grid-item"
              style={{
                display: chartList[11]?.checked ? "" : "none",
                backgroundColor: theme.palette.secondary.main
              }}
            >
              <MTBFDashboard
                lineData={linePress}
                type={"stamp"}
                rowsMachineRuntime={runtimeMachine}
                target={MTBFTarget.filter((e) => e.line === "Press")[0]}
              />
            </Card>
            <Card
              key="BM"
              className="dashboard-grid-item"
              style={{
                display: chartList[11]?.checked ? "" : "none",
                backgroundColor: theme.palette.secondary.main
              }}
            >
              <BMDashboard
                lineData={linePress}
                type={"Press"}
                rowsMachineRuntime={runtimeMachine}
                target={
                  BMTarget.filter((e: ITargetKpi) => e.line === "Press")[0]
                }
              />
            </Card>
          </ResponsiveGridLayout>
        </div>
      ) : (
        <>
          <img
            src={getImagePng("LongFormTHPage")}
            alt=""
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            width={"80%"}
          />
        </>
      )}
    </>
  )
}

export default Dashboard
