import {
  Box,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  styled,
  useTheme
} from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { grey } from "@mui/material/colors"
import moment from "moment"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import { useQuery } from "@apollo/client"
import { GET_DATA_SLIP } from "../../common/Query/SlipSalaryQuery"
import { user } from "../../common/MainApp"
import PrintIcon from "@mui/icons-material/Print"
import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import "moment/locale/th"
const pageTitle = "Slip Salary"

const setStyleBody = {
  fontSize: "16px",
  fontWeight: 700,
  color: "black"
}

const setStyleHead = { fontSize: "18px", fontWeight: 900 }

const setStyleBodySum = {
  fontSize: "20px",
  fontWeight: 900,
  color: "black"
}

const setStyleButton = {
  mt: 1,
  mb: 1
}

interface Column {
  id: "รายได้" | "จำนวน" | "จำนวนเงิน" | "รายการหัก" | "จำนวนเงินหัก"
  label: string
  minWidth?: number
  align?: "center"
  format?: (value: number) => string
}

interface Column2 {
  id:
    | "เงินได้สะสมต่อปี"
    | "ภาษีสะสมต่อปี"
    | "เงินสะสมกองทุนต่อปี"
    | "เงินประกันสังคมต่อปี"
    | "ค่าลดหย่อนอื่นๆ"
  label: string
  minWidth?: number
  align?: "center"
  format?: (value: number) => string
}
const columns: Column[] = [
  { id: "รายได้", label: "รายได้", minWidth: 200 },
  { id: "จำนวน", label: "จำนวน", minWidth: 200 },
  { id: "จำนวนเงิน", label: "จำนวนเงิน", minWidth: 200 },
  { id: "รายการหัก", label: "รายการหัก", minWidth: 200 },
  { id: "จำนวนเงินหัก", label: "จำนวนเงิน", minWidth: 200 }
]

const columns2: Column2[] = [
  { id: "เงินได้สะสมต่อปี", label: "เงินได้สะสมต่อปี", minWidth: 200 },
  { id: "ภาษีสะสมต่อปี", label: "ภาษีสะสมต่อปี", minWidth: 200 },
  { id: "เงินสะสมกองทุนต่อปี", label: "เงินสะสมกองทุนต่อปี", minWidth: 200 },
  { id: "เงินประกันสังคมต่อปี", label: "เงินประกันสังคมต่อปี", minWidth: 200 },
  { id: "ค่าลดหย่อนอื่นๆ", label: "ค่าลดหย่อนอื่นๆ", minWidth: 200 }
]

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[100]};
    font-weight:bold
  }
  `
)
const SlipSalary: React.FC = () => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM")
  )

  const exportElement = useRef<HTMLElement>()

  const { data } = useQuery(GET_DATA_SLIP, {
    variables: { date: dateFrom, userId: user.username },
    fetchPolicy: "network-only"
  })
  const listSlip = data?.getDataSlipSalary

  useEffect(() => {
    if (listSlip) {
      setDateFrom(moment(listSlip.payRollDate).format("YYYY-MM"))
    }
  }, [data])

  const theme = useTheme()

  const onPrint = () => {
    window.print()
  }

  const generatePDF = () => {
    const input = exportElement.current as HTMLElement
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF("p", "mm", "a4", true)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      const imgX = (pdfWidth - imgWidth * ratio) / 2
      const imgY = 10
      pdf.addImage(
        imgData,
        "PNG",
        imgX + 5,
        imgY,
        imgWidth * ratio - 10,
        imgHeight * ratio
      )
      pdf.save("slip.pdf")
    })
  }

  const convertData = (item: string) => {
    return Number(item).toLocaleString()
  }
  const convertDate = (item: string) => {
    return item.split("-").reverse().join("/")
  }

  return (
    <ThemeProvider theme={theme}>
      <ThemedCard>
        <CardHeader
          titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
          title={pageTitle}
          subheaderTypographyProps={{ fontFamily: "Sarabun" }}
        />
        <div style={{ margin: "20px" }}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    label="เลือกเดือน"
                    views={["year", "month"]}
                    inputFormat="MM-yyyy"
                    value={dateFrom}
                    onChange={(newSelectDate) => {
                      setDateFrom(moment(newSelectDate).format("YYYY-MM"))
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        lang="th"
                        value={moment(dateFrom).format("MM-YYYY")}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={"auto"} sm={"auto"}>
                <ThemedLoadingButton
                  variant="contained"
                  sx={{
                    ...setStyleButton,
                    backgroundColor: theme.palette.primary.main
                  }}
                  endIcon={<PrintIcon />}
                  onClick={() => onPrint()}
                >
                  Print
                </ThemedLoadingButton>
              </Grid>
              <Grid item xs={"auto"} sm={"auto"}>
                <ThemedLoadingButton
                  variant="contained"
                  sx={{
                    ...setStyleButton,
                    backgroundColor: theme.palette.primary.main
                  }}
                  endIcon={<PictureAsPdfIcon />}
                  onClick={() => generatePDF()}
                >
                  Export PDF
                </ThemedLoadingButton>
              </Grid>
            </Grid>
          </Box>
          {listSlip ? (
            <Box
              ref={exportElement}
              sx={{
                "@media print": {
                  position: "absolute",
                  minWidth: "fit-content",
                  backgroundColor: "white",

                  left: 0,
                  right: 0,
                  top: 0,
                  zIndex: 1,
                  zoom: "65%"
                }
              }}
            >
              <Root sx={{ minWidth: "1400px", marginTop: "30px" }}>
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          align="center"
                          sx={setStyleHead}
                        >
                          ใบจ่ายเงินเดือน
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          align="center"
                          sx={setStyleHead}
                        >
                          บริษัท บราเดอร์ ออโต้พาร์ทส์ แอนด์ เอ็นจิเนียริ่ง
                          จำกัด
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          align="center"
                          sx={setStyleHead}
                        >
                          <Grid container>
                            <Grid item xs={3}>
                              รหัส : {listSlip.userId}
                            </Grid>
                            <Grid item xs={3}>
                              ชื่อ - นามสกุล: {listSlip.userName}
                            </Grid>
                            <Grid item xs={3}>
                              แผนก : {listSlip.section}
                            </Grid>
                            <Grid item xs={3}>
                              เลขบัญชี : {listSlip.accountNumber}
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            sx={setStyleHead}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "#ffffff" }}>
                      <TableRow>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          อัตรา
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="right"
                          sx={setStyleBody}
                        ></TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.rate)}
                        </TableCell>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          หักเงินสมทบประกันสังคม
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.socialSecurity)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          เงินเดือน
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="right"
                          sx={setStyleBody}
                        ></TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.salary)}
                        </TableCell>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          หักภาษี
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.tax)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ค่าล่วงเวลา 1 เท่า
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.ot10Hr)}
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.ot10)}
                        </TableCell>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ไม่จ่ายค่าจ้าง
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.notPay)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ค่าล่วงเวลา 1.5 เท่า
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.ot15Hr)}
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.ot15)}
                        </TableCell>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ตรวจสุขภาพ
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.healthCheck)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ค่าล่วงเวลา 2 เท่า
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.ot20Hr)}
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.ot20)}
                        </TableCell>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ค่าชุดฟอร์ม
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.uniform)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ค่าล่วงเวลา 3 เท่า
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.ot30Hr)}
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.ot30)}
                        </TableCell>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ฌาปนกิจศพ
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.cremation)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ค่าสวัสดิการ
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="right"
                          sx={setStyleBody}
                        ></TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.benefit)}
                        </TableCell>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ค่าเครื่องมือ
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.toolFee)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ค่าตำแหน่ง
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="right"
                          sx={setStyleBody}
                        ></TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.positionValue)}
                        </TableCell>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          หักเงินคืน
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.deductionOfRefund)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          เบี้ยขยัน + ค่ารถ + คืนพักร้อน
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="right"
                          sx={setStyleBody}
                        ></TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.diligenceAllowance)}
                        </TableCell>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          เงิน กยศ.
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.studentLoanFund)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ค่ากะ + อาหาร + โทรศัพท์ + โบนัส
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="right"
                          sx={setStyleBody}
                        ></TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.shiftFee)}
                        </TableCell>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          หักกองทุนสำรองเลี้ยงชีพ
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.providerFund)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          สารเคมี + ค่าครองชีพ + ค่าคืนชุดฟอร์ม
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="right"
                          sx={setStyleBody}
                        ></TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.cheepCanalFee)}
                        </TableCell>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          ขาดงาน
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.missingWork)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={1}
                          align="left"
                          sx={setStyleBody}
                        ></TableCell>
                        <TableCell colSpan={1} align="right"></TableCell>
                        <TableCell colSpan={1} align="right"></TableCell>
                        <TableCell colSpan={1} align="left" sx={setStyleBody}>
                          หักลากิจ
                        </TableCell>
                        <TableCell colSpan={1} align="right" sx={setStyleBody}>
                          {convertData(listSlip.leaveOfAbsence)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          align="left"
                          sx={setStyleBodySum}
                        >
                          รวมเงินได้ Total Earning
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="right"
                          sx={setStyleBodySum}
                        >
                          {convertData(listSlip.totalEarnings)}
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="left"
                          sx={setStyleBodySum}
                        >
                          รวมรายการหัก Total Deduction
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          align="right"
                          sx={setStyleBodySum}
                        >
                          {convertData(listSlip.totalDeduction)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Root>
              <Root sx={{ minWidth: "1400px", marginTop: "30px" }}>
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns2.map((column) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            sx={setStyleHead}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "#ffffff" }}>
                      <TableRow>
                        <TableCell colSpan={1} align="center" sx={setStyleBody}>
                          {convertData(listSlip.accumulatedIncomePerYear)}
                        </TableCell>
                        <TableCell colSpan={1} align="center" sx={setStyleBody}>
                          {convertData(listSlip.taxPerYear)}
                        </TableCell>
                        <TableCell colSpan={1} align="center" sx={setStyleBody}>
                          {convertData(listSlip.providerFundPerYear)}
                        </TableCell>
                        <TableCell colSpan={1} align="center" sx={setStyleBody}>
                          {convertData(listSlip.socialSecurityPerYear)}
                        </TableCell>
                        <TableCell colSpan={1} align="center" sx={setStyleBody}>
                          {convertData(listSlip.otherDeductibles)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Root>
              <Root sx={{ width: "700px", marginTop: "30px" }}>
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={1} align="center" sx={setStyleHead}>
                          วันที่จ่าย Payroll Date
                        </TableCell>
                        <TableCell colSpan={1} align="center" sx={setStyleHead}>
                          เงินรับสุทธิ Net To Pay
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: "#ffffff" }}>
                      <TableRow>
                        <TableCell colSpan={1} align="center" sx={setStyleBody}>
                          {convertDate(listSlip.payRollDate)}
                        </TableCell>
                        <TableCell colSpan={1} align="center" sx={setStyleBody}>
                          {convertData(listSlip.netToPay)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Root>
            </Box>
          ) : (
            <></>
          )}
        </div>
      </ThemedCard>
    </ThemeProvider>
  )
}
export default SlipSalary
