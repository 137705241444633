import {
  Autocomplete,
  Box,
  CardContent,
  Grid,
  Typography,
  useTheme
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import AutocompleteFactory from "../../common/Resources/AutocompleteFactory"
import { useEffect, useMemo, useState } from "react"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DateAdapter from "@mui/lab/AdapterMoment"
import DatePicker from "@mui/lab/DatePicker"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import RepairInformant from "./Component/RepairInformant"
import { useLazyQuery } from "@apollo/client"
import { GET_INFORMANT_PM } from "../../common/Query/engineerQuery"
import moment from "moment"
import Repairer from "./Component/Repairer"
import PMQCFrom from "./Component/PMQCFrom"
import AddModeratorIcon from "@mui/icons-material/AddModerator"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined"

export interface IRepairInformant {
  repairRequestDate: string
  repairRequestTime: string
  line: string
  factory: string
  documentNo: number
  partNo: string
  partName: string
  process: string
  customer: string
  worker: string
  leaderProduction: string
  machine: string
  objective: string
  type: string
  production: number
  repairDueDate: string
  problem: string
  repairDate: string
  repairTime: string
  repairer: string
  repairSendBack: string
  checkAndEdit: string
  purchaseNo: string
  purchaseDate: string
  receiveDate: string
  quantity: number
  amount: number
  startRepairDate: string
  startRepairTime: string
  endRepairDate: string
  endRepairTime: string
  recipient: string
  code: string
  cause: string
  howToRepair: string
  summaryRepair: string
  NGQuantity: number
  deliverDate: string
  deliverLeaderProduction: string
  leaderQC: string
  leaderTooling: string
  leaderToolingDate: string
  qcDate: string
  summaryQC: string
  repairDueDateTime: string
  dieType: string
  machineHour: number
  manHour: number
  holiday: string
}

interface IPartNo {
  partNo: string
  deliverLeaderProduction: string
}

const pageTitle = "PM Engineer Document"

const PMDocument: React.FC = () => {
  const [factory, setFactory] = useState<string>("")
  const [date, setDate] = useState<Date>(new Date())
  const [record, setRecord] = useState<number>(0)
  const [partNo, setPartNo] = useState<{
    partNo: string
    deliverLeaderProduction: string
  } | null>(null)
  const [addFrom, setAddFrom] = useState<number[]>([1])
  const [getPMHistory, { data: queryPMEngineer }] = useLazyQuery(
    GET_INFORMANT_PM,
    {
      fetchPolicy: "no-cache"
    }
  )

  const partNoList: IPartNo[] = useMemo(() => {
    return queryPMEngineer?.getPMHistory.reduce(
      (acc: IPartNo[], item: IPartNo) => {
        if (acc.find((partNo: IPartNo) => partNo.partNo === item.partNo)) {
          return acc
        }
        return [
          ...acc,
          {
            partNo: item.partNo,
            deliverLeaderProduction: item.deliverLeaderProduction
          }
        ]
      },
      []
    )
  }, [queryPMEngineer?.getPMHistory, factory, date, record])

  useMemo(async () => {
    await getPMHistory({
      variables: {
        date: moment(date).format("YYYY-MM"),
        factory: factory
      }
    })
  }, [date, factory, record])
  const informantPMFrom: {
    recordList: IRepairInformant[]
    pmFrom: IRepairInformant | undefined
  } = useMemo(() => {
    if (queryPMEngineer?.getPMHistory) {
      return {
        recordList: queryPMEngineer?.getPMHistory
          ?.filter((item: { partNo: string }) =>
            partNo?.partNo ? item.partNo === partNo.partNo : true
          )
          .map((item: IRepairInformant) => {
            return {
              documentNo: item.documentNo.toString().padStart(3, "0"),
              leaderQC: item.leaderQC,
              deliverLeaderProduction: item.deliverLeaderProduction,
              leaderTooling: item.leaderTooling
            }
          })
          .sort(
            (a: IRepairInformant, b: IRepairInformant) =>
              Number(a.documentNo) - Number(b.documentNo)
          ),
        pmFrom: queryPMEngineer.getPMHistory.find(
          (item: IRepairInformant) =>
            item.documentNo === Number(record) && item.factory === factory
        )
      }
    }

    return {
      recordList: [],
      pmFrom: undefined
    }
  }, [queryPMEngineer?.getPMHistory, factory, record, partNo])
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const dateParam = urlParams.get("date")
  const factoryParam = urlParams.get("factory")
  const documentNoParam = urlParams.get("documentNo")

  useEffect(() => {
    if (dateParam && factoryParam && documentNoParam) {
      setDate(moment(dateParam).toDate() as Date)
      setFactory(factoryParam)
      setRecord(Number(documentNoParam))
    }
  }, [dateParam, factoryParam, documentNoParam])
  const handleAdd = () => {
    const max = Math.max(...addFrom)
    const newAddFrom = [...addFrom, max + 1]
    setAddFrom(newAddFrom)
  }

  const theme = useTheme()
  return (
    <Box>
      <PageLabel
        menuItem={menuItemList.Engineer}
        menuItemName={Object.keys(menuItemList)[0]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="BM/PM Document"
          subTitle="About detail PM Document"
        />
        <CardContent className="hide-container">
          <Grid
            spacing={2}
            container
            sx={{
              "@media print": {
                display: "none"
              }
            }}
          >
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="Select Month"
                  views={["year", "month"]}
                  inputFormat="MM/yyyy"
                  value={date}
                  onChange={(newSelectDate) => {
                    setDate(moment(newSelectDate).toDate() as Date)
                  }}
                  PaperProps={{
                    sx: {
                      "& .MuiCalendarPicker-root": {
                        ".Mui-selected": {
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? theme.palette.warning.main
                              : theme.palette.primary.main,
                          color: "#000000"
                        }
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <ThemedTextField
                      {...params}
                      fullWidth
                      sx={{
                        "& .Mui-selected": {
                          backgroundColor: theme.palette.warning.main
                        }
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <AutocompleteFactory setFactory={setFactory} factory={factory} />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                disablePortal
                id="partNo"
                value={partNo}
                options={partNoList}
                loading={partNoList?.length === 0}
                onChange={(e, newValue) => {
                  setPartNo(newValue)
                }}
                getOptionLabel={(option) => (option ? option.partNo : "")}
                renderOption={(props, options) => {
                  return (
                    <Box component="li" {...props}>
                      <Grid container sx={{ padding: "0 5px" }}>
                        <Grid item xs="auto">
                          {options.partNo}
                        </Grid>
                      </Grid>
                    </Box>
                  )
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Part No"
                    value={partNo ? `${partNo.partNo}` : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                fullWidth
                id="next-step-process"
                disablePortal
                renderInput={(params) => (
                  <ThemedTextField {...params} label={"Document No."} />
                )}
                onChange={(e, newValue) => {
                  setRecord(newValue?.documentNo ? newValue.documentNo : 0)
                }}
                getOptionLabel={(option) => option.documentNo.toString()}
                renderOption={(props, options) => {
                  return (
                    <Box component="li" {...props}>
                      <Grid container sx={{ padding: "0 5px" }}>
                        <Grid item xs="auto">
                          {options.documentNo}
                        </Grid>
                        <Grid item container xs justifyContent="flex-end">
                          <AddModeratorIcon
                            sx={{
                              color: options.leaderQC ? "green" : "red"
                            }}
                          />
                          <DescriptionOutlinedIcon
                            sx={{
                              color: options.deliverLeaderProduction
                                ? "green"
                                : "red"
                            }}
                          />
                          <EngineeringOutlinedIcon
                            sx={{
                              color: options.leaderTooling ? "green" : "red"
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )
                }}
                options={informantPMFrom.recordList}
              />
            </Grid>
          </Grid>
          <Box
            mt={2}
            sx={{
              "@media print": {
                zIndex: 999,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%"
              }
            }}
          >
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <RepairInformant
                  informantPMFrom={informantPMFrom.pmFrom}
                  record={record}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={
                    informantPMFrom.pmFrom?.repairRequestDate
                      ? { fontSize: 12 }
                      : { display: "none" }
                  }
                >
                  ผู้แจ้งซ่อม ส่งใบแจ้งซ่อมแม่พิมพ์ แจ้งปัญหา
                  พร้อมแนบใบสั่งหยุดผลิต F-QA-03-08 ในกรณีชิ้นงานไม่ได้คุณภาพ
                  ส่งให้ แผนกซ่อมแม่พิมพ์
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={
                  informantPMFrom.pmFrom?.leaderProduction
                    ? {}
                    : { display: "none" }
                }
              >
                <Repairer
                  record={record}
                  informantPMFrom={informantPMFrom.pmFrom}
                  factory={factory}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={
                    informantPMFrom.pmFrom?.repairDate
                      ? { fontSize: 12 }
                      : { display: "none" }
                  }
                >
                  แก้ไขแมแ่พิมพ์เสร็จเรียบร้อยให้ช่างซ่อม นำไใบสั่งหยุดผลิต
                  F-QA-03-08ให้ QC ตรวจรับการแก้ไข เสร็จ
                  และทำการส่งมอบแม่พิมพ์นำไปผลิต
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={
                  informantPMFrom.pmFrom?.repairDate ? {} : { display: "none" }
                }
              >
                <PMQCFrom
                  record={record}
                  informantPMFrom={informantPMFrom.pmFrom}
                  factory={factory}
                  handleAdd={handleAdd}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </ThemedCard>
    </Box>
  )
}
export default PMDocument
