import Maintenance from "./Component/Maintenance"

const Factory1: React.FC = () => {
  return (
    <>
      <Maintenance factory="1" />
    </>
  )
}

export default Factory1
