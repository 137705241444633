import { useTheme } from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useRef } from "react"
import { getProcessFilterRumTimeMachine } from "../../../common/utilities"
import { IDataMachine } from "../../Maintenance/KPIMaintenance"
import { ITargetKpi } from "../../Maintenance/component/MTTR"
import { monthNames } from "../../Maintenance/PreventiveMaintenancePlan"
import { IPress, IRuntimeMachine } from "../hook/useFetchData"

const machineRunTime = (type = "", filterMachineOfMonth: IRuntimeMachine[]) => {
  const machineRunTime = getProcessFilterRumTimeMachine(
    type,
    filterMachineOfMonth
  )
  const totalTime = Number(
    machineRunTime
      ?.reduce((acc: number, obj: IDataMachine) => {
        return acc + obj.totalTime
      }, 0)
      .toFixed(2)
  )
  const actualTime = Number(
    machineRunTime
      ?.reduce((acc: number, obj: IDataMachine) => {
        return acc + obj.totalHour
      }, 0)
      .toFixed(2)
  )
  return {
    totalTime: totalTime,
    actualTime: actualTime
  }
}
const total = (
  lineData: IPress[],
  i: number,
  rowsMachineRuntime: IRuntimeMachine[],
  type = ""
) => {
  if (lineData === undefined)
    return { dataOfMonth: 0, totalQuantityRepair: 0, actualTime: 0, arc: 0 }
  const dataOfMonth = lineData.filter(
    (e) => new Date(e?.startRepair || new Date()).getMonth() === i
  )
  const machineRunTimeOfMonth = rowsMachineRuntime.filter(
    (e: { planDate: string }) => new Date(e.planDate).getMonth() === i
  )
  const lineMachineRunTime = machineRunTime(
    type,
    machineRunTimeOfMonth
  ).totalTime

  if (dataOfMonth.length) {
    const totalQuantityRepair = dataOfMonth
      .map((data) => {
        const diff = Math.abs(
          Number(new Date(data?.completeDate || new Date())) -
            Number(new Date(data?.endWaitRepair || new Date()))
        )
        const actualTime = diff / 1000 / 60 / 60
        return actualTime
      })
      .reduce((prev: number, cur: number) => {
        return prev + cur
      }, 0)

    const actualTime = lineMachineRunTime - totalQuantityRepair
    const arc = actualTime / dataOfMonth.length
    return {
      dataOfMonth: Math.ceil(totalQuantityRepair * 60),
      lineMachineRunTime: Math.ceil(lineMachineRunTime * 60),
      actualTime: Math.ceil(actualTime * 60),
      totalQuantityRepair: dataOfMonth.length,
      arc: Math.ceil(arc * 60)
    }
  } else {
    return {
      dataOfMonth: 0,
      totalQuantityRepair: 0,
      lineMachineRunTime: Math.ceil(lineMachineRunTime * 60),
      actualTime: Math.ceil(lineMachineRunTime * 60 - 0),
      arc: 0
    }
  }
}
export const MTBFDashboard = (props: {
  chart?: HighchartsReact.Props
  lineData: IPress[]
  type: string
  setShowChart?: React.Dispatch<React.SetStateAction<boolean>>
  rowsMachineRuntime: IRuntimeMachine[]
  target: ITargetKpi
}): JSX.Element => {
  const { lineData, target, type, rowsMachineRuntime } = props
  if (!lineData.length) return <div>Loading...</div>
  const actual = monthNames.map((e: string, i: number) => {
    return total(lineData, i, rowsMachineRuntime, type).arc
  })

  const theme = useTheme()
  const columnOptions: Highcharts.Options = {
    chart: {
      type: "column",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "ระยะเวลาที่เครื่องจักรจะเสีย (MTBF)",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },

    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    },
    xAxis: {
      categories: monthNames,
      labels: {
        x: -10,
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "10px"
        }
      }
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "Total",
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      }
    },
    series: [
      {
        type: "column",
        name: "Target",
        data: monthNames.map(() => Number(target?.target))
      },
      {
        type: "column",
        name: "Actual",
        data: actual
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  return (
    <>
      <div style={{ display: "inline-block" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={columnOptions}
          ref={chartComponentRef}
          {...props.chart}
        />
      </div>
    </>
  )
}
