import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import exporting from "highcharts/modules/exporting.js"
import accessibility from "highcharts/modules/accessibility.js"
import { useRef } from "react"
import { LocalizationProvider, DatePicker } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  useTheme
} from "@mui/material"
import moment from "moment"
import DateAdapter from "@mui/lab/AdapterMoment"
import { IBreakdownProblem } from "../hook/useFetchData"

exporting(Highcharts)
accessibility(Highcharts)

interface IDataProblemBreakDown {
  totalTime: number
  remarks: string
  problemName: string
}

export const ChartBreakDownProblemMonthly = (props: {
  chart?: HighchartsReact.Props
  breakdownProblem: IBreakdownProblem[] | undefined
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const { breakdownProblem } = props
  if (!breakdownProblem) return <>Loading...</>
  const dataBreakDown: IDataProblemBreakDown[] = breakdownProblem || []
  const sumTotalTime = (type?: string) => {
    if (dataBreakDown && type) {
      const filterType = dataBreakDown.filter((e) =>
        type === "other"
          ? e.problemName === type && e.remarks !== "Set up"
          : e.problemName === type
      )
      return filterType.reduce((accumulator, object: IDataProblemBreakDown) => {
        return accumulator + object.totalTime
      }, 0)
    }
  }
  const setCategories = (type?: string) => {
    if (dataBreakDown && type) {
      return dataBreakDown
        .filter((e) =>
          type === "other"
            ? e.problemName === type &&
            e.remarks !== "Set up"
            : e.problemName === type
        )
        .map((e) => (e.remarks ? e.remarks : type))
    }
    return [type]
  }

  const setData = (type?: string) => {
    if (dataBreakDown && type) {
      return dataBreakDown
        .filter((e) =>
          type === "other"
            ? e.problemName === type && e.remarks !== "Set up"
            : e.problemName === type
        )
        .map((e) => e.totalTime)
    }
    return []
  }
  const setupData = dataBreakDown?.find(
    (e) => e.problemName === "other" && e.remarks === "Set up"
  )

  const categories = [
    "other",
    "DIE Breakdown",
    "Machine Breakdown",
    "Wait QC First Set Up",
    "PM",
    "Set up"
  ]
  const data = [
    {
      y: sumTotalTime("other"),
      color: "rgb(255, 251, 0)",
      drilldown: {
        name: "other",
        categories: setCategories("other"),
        data: setData("other")
      }
    },
    {
      y: sumTotalTime("DIE Breakdown"),
      color: "rgb(255, 0, 0)",
      drilldown: {
        name: "DIE Breakdown",
        categories: setCategories("DIE Breakdown"),
        data: setData("DIE Breakdown")
      }
    },
    {
      y: sumTotalTime("Machine Breakdown"),
      color: "rgb(67, 67, 72)",
      drilldown: {
        name: "Machine Breakdown",
        categories: setCategories("Machine Breakdown"),
        data: setData("Machine Breakdown")
      }
    },
    {
      y: sumTotalTime("Wait QC First Set Up"),
      color: "rgb(124, 181, 236)",
      drilldown: {
        name: "Wait QC First Set Up",
        categories: setCategories("Wait QC First Set Up"),
        data: setData("Wait QC First Set Up")
      }
    },
    {
      y: sumTotalTime("PM"),
      color: "rgb(144, 237, 125)",
      drilldown: {
        name: "PM",
        categories: setCategories("PM"),
        data: setData("PM")
      }
    },
    {
      y: setupData?.totalTime,
      color: "rgb(43, 144, 143)",
      drilldown: {
        name: "Set Up",
        categories: ["Set up"],
        data: [setupData?.totalTime]
      }
    }
  ]
  const browserData = []
  const versionsData = []
  const dataLen = data.length
  let drillDataLen
  // let brightness

  for (let i = 0; i < dataLen; i += 1) {
    // Add browser data
    browserData.push({
      name: categories[i],
      y: data[i].y,
      color: data[i].color
    });

    // Add version data
    drillDataLen = data[i].drilldown.data.length;
    for (let j = 0; j < drillDataLen; j += 1) {
      const remark = data[i].drilldown.categories[j];

      // Define custom colors for specific remarks in "plating"
      let categoryColor;
      if (categories[i] === "other") {
        if (remark === "พนักงานไม่พอ") {
          categoryColor = "rgb(0, 1, 255)";
        } else if (remark === "รอต่อ process") {
          categoryColor = "rgb(255, 155, 0)";
        } else if (remark === "รอ Mat'l") {
          categoryColor = "rgb(255, 134, 255)";
        } else if (remark === "รอรถโฟล์คลิฟท์") {
          categoryColor = "rgb(126, 0, 255)";
        } else if (remark === "Set up แม่พิมท์") {
          categoryColor = "rgb(168, 116, 18)";
        } else {
          categoryColor = data[i].color;
        }
      } else {
        categoryColor = data[i].color;
      }

      versionsData.push({
        name: remark,
        y: data[i].drilldown.data[j],
        color: categoryColor
      });
    }
  }

  // for (let i = 0; i < dataLen; i += 1) {
  //   // add browser data
  //   browserData.push({
  //     name: categories[i],
  //     y: data[i].y,
  //     color: data[i].color
  //   })

  //   // add version data
  //   drillDataLen = data[i].drilldown.data.length
  //   for (let j = 0; j < drillDataLen; j += 1) {
  //     // brightness = 0.2 - j / drillDataLen / 5
  //     versionsData.push({
  //       name: data[i].drilldown.categories[j],
  //       y: data[i].drilldown.data[j],
  //       color: data[i].drilldown.data[j]
  //       // color: Highcharts.color(data[i].color).brighten(brightness).get()
  //     })
  //   }
  // }
  const theme = useTheme()
  const options: Highcharts.Options = {
    chart: {
      type: "pie",
      backgroundColor: theme.palette.secondary.main,
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0
      }
    },
    title: {
      text: "Break Down Problem Monthly",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    credits: { enabled: false },
    accessibility: {
      point: {
        valueSuffix: "%"
      }
    },
    tooltip: {
      pointFormat:
        "<b>{series.name}: {point.y:.1f} HOUR</b><br /> คิดเป็น :{point.percentage:.1f}% "
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        depth: 35,
        dataLabels: {
          enabled: true,
          format: "{point.name}"
        }
      }
    },
    series: [
      {
        type: "pie",
        name: "จำนวน",
        data: browserData,
        size: "60%",
        dataLabels: {
          formatter: function () {
            return this.y ? this.point.name : null
          },
          color: "#ffffff",
          distance: -30
        }
      },
      {
        type: "pie",
        name: "จำนวน",
        data: versionsData,
        size: "80%",
        innerSize: "60%",
        dataLabels: {
          formatter: function () {
            return this.y
              ? "<b>" + this.point.name + ":</b> " + this.y + "%"
              : null
          }
        }
      }
    ],
    exporting: {
      enabled: false
    }
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props.chart}
      />
    </>
  )
}

interface IBreakDownProblemMonthly {
  dateFrom: string
  dateTo: string
  breakdownProblem: IBreakdownProblem[] | undefined
  setDateFrom: (date: string) => void
  setDateTo: (date: string) => void
  dialogOpen: boolean
  setDialogOpen: (open: boolean) => void
}

const BreakDownProblemMonthly = (
  props: IBreakDownProblemMonthly
): JSX.Element => {
  const {
    dialogOpen,
    setDialogOpen,
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
    breakdownProblem
  } = props

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        >
          Break Down Problem Monthly
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12} justifyContent={"center"} margin={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="Date From"
                  // views={["year", "month"]}
                  value={dateFrom}
                  onChange={(newSelectDate) => {
                    setDateFrom(moment(newSelectDate).format("YYYY-MM-DD"))
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      value={moment(dateFrom).format("DD-MM-YYYY")}
                      inputProps={{
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={3} xs={12} justifyContent={"center"} margin={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="Date To"
                  // views={["year", "month"]}
                  value={dateTo}
                  onChange={(newSelectDate) => {
                    setDateTo(moment(newSelectDate).format("YYYY-MM-DD"))
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      value={moment(dateTo).format("DD-MM-YYYY")}
                      inputProps={{
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={12} xs={12} justifyContent="center">
              <ChartBreakDownProblemMonthly
                breakdownProblem={breakdownProblem}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => {
              setDialogOpen(false)
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default BreakDownProblemMonthly
