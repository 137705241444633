import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  Box,
  Grid,
  Autocomplete,
  CardHeader,
  useTheme,
  Switch,
  Button
} from "@mui/material"
import React, { useMemo, useState } from "react"
import JobOrderTable from "./component/jobOrderTable"
import { GET_PARTS } from "../../common/Query/MasterQuery"
import { GET_WIP_STOCK } from "../../common/Query/MaterialQuery"
import { GET_JOB_ORDER } from "../../common/Query/PlanningQuery"
import { PageLabel } from "../../common/Resources/PageLabel"
import "../../css/Planning/jobOrder.css"
import QRCode from "qrcode.react"
import moment from "moment"
import { menuItemList } from "../../common/Resources/menuItemList"
import {
  bankImage,
  clearParam,
  factoryList,
  getImagePng
} from "../../common/utilities"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { DELETE_JOB_ORDER } from "../../common/Mutation/PlanningMutation"
import Swal from "sweetalert2"
import { user } from "../../common/MainApp"

interface partprocessing {
  process: string
  processOrder: string
  complete: string
  line: string
  machine: string
  jph: number
  minStroke: number
  partNo: string
  stepProcess: number
  amount: {
    complete: string
    ng: number
    quantity: number
    total: number
  }
}

interface IJobOrderHeaderProps {
  openDetail: boolean
  jobNoOrder: string | null
  partNo: string | null
  children?: JSX.Element | JSX.Element[] | null | undefined
}

interface IWipStocks {
  matNo: string
  partNo: string
  jobOrder: string
  pdLotNo: string
  inOrOut: number
  actionDate: string
  quantity: number
  factory: string
  packingBy: string
  remarks: string
  isNG: number
  perBox: number
  dateScan: string
}
interface INewProcesses {
  processOrder: string
  stepProcess: number
  partNo: string
  process: string
  machine: string
  line: string
  jph: number
  minStroke: number
  amount: IAmount
  complete: string
}
interface IAmount {
  quantity: number
  ng: number
  total: number
  complete: string
}

interface ITotalJobOrder {
  response: IWipStocks
  newProcesses: INewProcesses[]
}

const pageTitle = "Job Order"

const JobOrderHeader = (props: IJobOrderHeaderProps): JSX.Element => {
  const { jobNoOrder, partNo, children, openDetail } = props
  const [isOpen, setIsOpen] = useState(openDetail)
  const [deleteJob] = useMutation(DELETE_JOB_ORDER, {
    refetchQueries: [{ query: GET_JOB_ORDER }],
    awaitRefetchQueries: true
  })
  const theme = useTheme()

  const handleDeleteJob = async () => {
    const data = {
      jobOrder: jobNoOrder,
      updatedBy: user.username,
      fromPage: "Planning > Job Order"
    }

    const bottom = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    })
    if (bottom.isConfirmed) {
      try {
        await deleteJob({ variables: data })
        await Swal.fire("Deleted!", "Your job has been deleted.", "success")
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: (error as Error).message
        })
      }
    }
  }
  return (
    <Grid container spacing={0}>
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{
          fontFamily: "Roboto",
          fontSize: "16px",
          border: "1px solid black",
          borderBottom: isOpen ? "0" : "1px solid black",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.contrastText,
          padding: "20px"
        }}
        xs={12}
      >
        <Grid container item spacing={2} direction="column" xs={8} sm={8}>
          <Grid container item direction="row" alignItems="center">
            <Grid item xs={12} sm={1}>
              <img
                src={partNo ? getImagePng(`images/parts/${partNo}`) : bankImage}
                style={{ maxHeight: "80px", maxWidth: "80px" }}
                alt=""
              />
            </Grid>
            <Grid item xs={12} sm={11} style={{ fontSize: "20px" }}>
              <strong>Job No. Order: {`${jobNoOrder}`}</strong>
            </Grid>
          </Grid>
          <Grid item xs={8} sm={8}>{`Part No. ${partNo}`}</Grid>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Switch
            checked={isOpen}
            onChange={() => setIsOpen(!isOpen)}
            name="gilad"
            sx={{
              "& .Mui-checked": {
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.secondary.main
                    : theme.palette.warning.main
              },
              "& .MuiSwitch-track": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? `${theme.palette.secondary.main} !important`
                    : `${theme.palette.warning.main} !important`
              }
            }}
          />
          <label style={{ fontSize: "14px" }}>Expand</label>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button variant="contained" color="error" onClick={handleDeleteJob}>
            DELETE
          </Button>
        </Grid>
        <Grid item xs={12} sm={1}>
          <QRCode value={jobNoOrder ? jobNoOrder : ""} size={100} />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <div style={{ width: "100%" }}>{isOpen ? children : <></>}</div>
      </Grid>
    </Grid>
  )
}

const compare = (a: { stepProcess: number }, b: { stepProcess: number }) => {
  if (a.stepProcess < b.stepProcess) {
    return -1
  }
  if (a.stepProcess > b.stepProcess) {
    return 1
  }
  return 0
}

const JobOrder: React.FC = () => {
  const theme = useTheme()
  const [partNo, setPartNo] = useState<string | null>("")
  const [jobOrder, setJobOrder] = useState<string | null>("")
  const [jobOrderList, setJobOrderList] = useState<string[]>([])
  const [totalJobOrder, setTotalJobOrder] = useState<ITotalJobOrder[]>([])
  const [print, setPrint] = useState(true)
  const [actionDate, setActionDate] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const getPart = useQuery(GET_PARTS)
  const getWipStocks = useQuery(GET_WIP_STOCK, {
    variables: {
      partNo,
      actionDate: actionDate ? moment(actionDate).format("DD-MM-YY") : ""
    },
    fetchPolicy: "network-only"
  })
  const [getJobOrder, { loading }] = useLazyQuery(GET_JOB_ORDER)

  let partNoList: Array<string> = []
  if (actionDate && getWipStocks?.data) {
    partNoList = Array.from(
      new Set(
        getWipStocks?.data.wipStocks.map((e: { partNo: string }) => e.partNo)
      )
    )
  } else {
    Array.from(
      new Set(
        (partNoList = getPart.data
          ? getPart.data.parts.map((e: { partNo: string }) => e.partNo)
          : [])
      )
    )
  }

  useMemo(() => {
    const jobOrderList: string[] = Array.from(
      new Set(
        getWipStocks.data
          ? getWipStocks.data.wipStocks
              .filter(({ partNo }: { partNo: string }) =>
                partNo ? partNo === partNo : true
              )
              .sort(
                (
                  { actionDate: a }: { actionDate: string },
                  { actionDate: b }: { actionDate: string }
                ) => (a > b ? -1 : a < b ? 1 : 0)
              )
              .map(({ jobOrder }: { jobOrder: string }) => jobOrder)
          : []
      )
    )

    setJobOrderList(jobOrderList)
  }, [getWipStocks.data, partNo])

  const handleSubmit = async () => {
    if (actionDate) {
      const lotdata = await getJobOrder({
        variables: { jobOrderList },
        fetchPolicy: "network-only"
      })
      setTotalJobOrder(
        lotdata?.data?.jobOrder
          .filter((e: ITotalJobOrder) =>
            factory ? e.response.factory === factory : true
          )
          .map((e: ITotalJobOrder) => {
            const temp: partprocessing[] = e.newProcesses || []
            let process: partprocessing[] = []
            if (temp.length !== 0 && temp) {
              const temp1 = temp.slice()
              process = temp1.sort(compare)
            }
            return {
              response: e.response,
              newProcesses: process
            }
          })
      )
    } else {
      const lotdata = await getJobOrder({
        variables: { jobOrderList: [jobOrder] },
        fetchPolicy: "network-only"
      })
      setTotalJobOrder(
        lotdata?.data?.jobOrder
          .filter((e: ITotalJobOrder) =>
            factory ? e.response.factory === factory : true
          )
          .map((e: ITotalJobOrder) => {
            const temp: partprocessing[] = e.newProcesses || []
            let process: partprocessing[] = []
            if (temp.length !== 0 && temp) {
              const temp1 = temp.slice()
              process = temp1.sort(compare)
            }
            return {
              response: e.response,
              newProcesses: process
            }
          })
      )
    }
  }

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const jobParam = urlParams.get("job")
  const partNoParam = urlParams.get("partNo")

  useMemo(async () => {
    if (jobParam && partNoParam) {
      setPartNo(partNoParam)
      setJobOrder(jobParam)
      const lotdata = await getJobOrder({
        variables: { jobOrderList: [jobParam] },
        fetchPolicy: "network-only"
      })
      setTotalJobOrder(
        lotdata?.data?.jobOrder
          .filter((e: ITotalJobOrder) =>
            factory ? e.response.factory === factory : true
          )
          .map((e: ITotalJobOrder) => {
            const temp: partprocessing[] = e.newProcesses || []
            let process: partprocessing[] = []
            if (temp.length !== 0 && temp) {
              const temp1 = temp.slice()
              process = temp1.sort(compare)
            }
            return {
              response: e.response,
              newProcesses: process
            }
          })
      )
      clearParam("JobOrder")
    }
  }, [partNoParam, jobParam])

  const handlePrint = async () => {
    setPrint(false)
  }
  return (
    <div>
      <PageLabel
        menuItem={menuItemList.Planning}
        menuItemName={Object.keys(menuItemList)[2]}
        menuPageName={pageTitle}
      />
      <br />
      {!print ? (
        <div className="label_navbar">
          <ThemedButton
            variant="contained"
            color="error"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => setPrint(true)}
          >
            Return
          </ThemedButton>
        </div>
      ) : (
        <></>
      )}
      {print ? (
        <ThemedCard style={{ padding: "20px" }}>
          <div className="label_navbar">
            <Box
              sx={{
                marginTop: 3,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <CardHeader
                titleTypographyProps={{
                  variant: "h4",
                  fontFamily: "Sarabun"
                }}
                title="Job Order"
                subheaderTypographyProps={{ fontFamily: "Sarabun" }}
                subheader="Retrieve job orders"
              />
              <Box component="form">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2}>
                    <ThemedTextField
                      id="Date Range"
                      value={actionDate}
                      type="date"
                      fullWidth
                      onChange={(e) => setActionDate(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      onChange={(event, selectedFactory) => {
                        setFactory(selectedFactory as string)
                      }}
                      options={factoryList}
                      renderInput={(params) => (
                        <ThemedTextField {...params} label="Factory" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Autocomplete
                      disablePortal
                      id="partNo"
                      value={partNo}
                      options={partNoList}
                      loading={partNoList.length === 0}
                      onChange={(e, newValue) => {
                        setPartNo(newValue)
                        setJobOrder("")
                      }}
                      renderInput={(params) => (
                        <ThemedTextField
                          {...params}
                          label="Part NO"
                          value={partNo}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      disabled={!jobOrderList.length}
                      id="jobOrder"
                      value={jobOrder}
                      options={jobOrderList}
                      onChange={(e, newValue) => setJobOrder(newValue)}
                      renderInput={(params) => (
                        <ThemedTextField {...params} label="Job Order" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <ThemedLoadingButton
                      type="submit"
                      loading={loading}
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 2,
                        mb: 2
                      }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </ThemedLoadingButton>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <ThemedButton
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "white",
                        color: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main
                      }}
                      variant="contained"
                      color="inherit"
                      fullWidth
                      sx={{ mt: 2, mb: 2 }}
                      onClick={handlePrint}
                    >
                      Print
                    </ThemedButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <CardHeader
              titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
              title="Job Order (ใบสั่งผลิต/ใบเบิกวัตถุดิบ)"
              subheaderTypographyProps={{ fontFamily: "Sarabun" }}
            />
            {totalJobOrder?.map((e) => {
              const processes = e.newProcesses
              return (
                <div
                  id="jobordertable"
                  style={{
                    paddingLeft: "-20px",
                    paddingRight: "-20px"
                  }}
                  key={e.response.jobOrder}
                >
                  <JobOrderHeader
                    jobNoOrder={e.response.jobOrder}
                    partNo={e.response.partNo}
                    openDetail={totalJobOrder.length === 1 ? true : false}
                  >
                    <JobOrderTable
                      lotData={e.response}
                      jobOrder={e.response.jobOrder}
                      partNo={e.response.partNo}
                      processes={processes}
                    ></JobOrderTable>
                  </JobOrderHeader>
                </div>
              )
            })}
          </div>
        </ThemedCard>
      ) : (
        <div className="job-order-print-head">
          {totalJobOrder.map((e, index: number) => {
            const processes = e.newProcesses
            return (
              <div
                id="jobordertable"
                style={
                  (index + 1) % 4 === 0
                    ? {
                        paddingLeft: "-20px",
                        paddingRight: "-20px",
                        minHeight: "520px",
                        maxWidth: "1650px"
                      }
                    : {
                        paddingLeft: "-20px",
                        paddingRight: "-20px",
                        minHeight: "630px",
                        maxWidth: "1650px"
                      }
                }
                key={e.response.jobOrder}
              >
                <JobOrderTable
                  lotData={e.response}
                  jobOrder={e.response.jobOrder}
                  partNo={e.response.partNo}
                  processes={processes}
                ></JobOrderTable>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
export default JobOrder
