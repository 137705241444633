import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useEffect, useRef, useState } from "react"
import exporting from "highcharts/modules/exporting.js"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
  DialogActions,
  Button,
  Autocomplete,
  useTheme
} from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import moment from "moment"
import { IPremiumFreight } from "../hook/useFetchData"

exporting(Highcharts)

export const PremiumFreightChart = (props: {
  chart?: HighchartsReact.Props
  premiumFreight: IPremiumFreight[] | undefined
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const { premiumFreight } = props

  const [monthly, setMonthly] = useState<string[]>([])
  const [dataPremiumFreight, setDataPremiumFreight] = useState<
    IPremiumFreight[]
  >([])

  const getMonthName = (monthNumber: number) => {
    const date = new Date()
    date.setMonth(monthNumber - 1)
    return date.toLocaleString("en-US", { month: "long" })
  }

  useEffect(() => {
    if (premiumFreight) {
      const uniqueMonthly: string[] = Array.from(
        new Set(premiumFreight.map((e) => getMonthName(e?.month || 0)))
      )
      setDataPremiumFreight(premiumFreight)
      setMonthly(uniqueMonthly)
    }
  }, [premiumFreight])
  const theme = useTheme()
  if (!premiumFreight) return <>Loading...</>
  const options: Highcharts.Options = {
    chart: {
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "Cost Of Poor Monthly",
      align: "center",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    xAxis: {
      categories: monthly,
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "8px"
        }
      }
    },
    yAxis: {
      title: {
        text: "Baht",
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      }
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true
        }
      }
    },
    legend: {
      itemStyle: {
        fontSize: "10px",
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
    },
    series: [
      {
        type: "column",
        color: "#FF8000",
        name: "Cost Of Poor In Process",
        data: dataPremiumFreight?.map((e) => Number(e.totalProcess?.toFixed(2)))
      },
      {
        type: "column",
        color: "#FFFF00",
        name: "Premium Freight Transport",
        data: dataPremiumFreight?.map((e) =>
          Number(e.premiumFreight?.toFixed(2))
        )
      },
      {
        type: "column",
        color: "#17FF00",
        name: "Premium Freight Man Hour",
        data: dataPremiumFreight?.map((e) =>
          Number(e.premiumFreightMan?.toFixed(2))
        )
      },
      {
        type: "column",
        color: "#001BFF",
        name: "Customer Claim",
        data: dataPremiumFreight?.map((e) =>
          Number(e.customerClaim?.toFixed(2))
        )
      }
    ],
    exporting: {
      enabled: false
    }
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props.chart}
    />
  )
}

interface IPremiumFreightProp {
  premiumFreight: IPremiumFreight[] | undefined
  dialogOpen: boolean
  setDialogOpen: (value: boolean) => void
  quarter: number
  setQuarter: (value: number) => void
  setYear: (value: string) => void
  year: string
  factory: string
  setFactory: (value: string) => void
}
const PremiumFreightDialog = (props: IPremiumFreightProp): JSX.Element => {
  const {
    dialogOpen,
    setDialogOpen,
    quarter,
    setQuarter,
    premiumFreight,
    setYear,
    year,
    factory,
    setFactory
  } = props
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        >
          Cost Of Poor Monthly
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12}>
              <TextField
                id="factory"
                fullWidth
                label="ไตรมาส"
                value={quarter}
                onChange={(e) => setQuarter(+e.target.value)}
                select
              >
                <MenuItem value={1}>ไตรมาส 1</MenuItem>
                <MenuItem value={2}>ไตรมาส 2</MenuItem>
                <MenuItem value={3}>ไตรมาส 3</MenuItem>
                <MenuItem value={4}>ไตรมาส 4</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={4}>
              <Autocomplete
                fullWidth
                value={factory}
                options={["1", "2", "plating"]}
                disablePortal
                onChange={(e, value) => setFactory(value ? value : "")}
                renderInput={(params) => (
                  <TextField {...params} label="Select Factory" />
                )}
              />
            </Grid>
            <Grid item sm={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="เลือกปี"
                  views={["year"]}
                  value={year}
                  onChange={(newSelectDate) => {
                    setYear(moment(newSelectDate).format("yyyy"))
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={12} xs={12} justifyContent="center">
              <PremiumFreightChart premiumFreight={premiumFreight} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default PremiumFreightDialog
