import { Drawer, useTheme, Button } from "@mui/material"
import { useState } from "react"
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined"
import { ChatNamCha, ThemeProvider } from "bitsoft-c3"

const DrawerChat: React.FC<{ width: number }> = (props: { width: number }) => {
  const { width } = props
  const theme = useTheme()
  const [openChat, setOpenChat] = useState(false)

  const handleToggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return
      }
      setOpenChat(open)
    }

  return (
    <>
      <Button onClick={handleToggleDrawer(true)}>
        <MessageOutlinedIcon
          fontSize={width > 600 ? "large" : "small"}
          style={{ color: theme.palette.text.primary }}
        />
      </Button>
      <Drawer
        anchor={"right"}
        open={openChat}
        onClose={handleToggleDrawer(false)}
        sx={{ zIndex: 1 }}
      >
        <ThemeProvider>
          <ChatNamCha wsUrl="wss://ox9iy61fcf.execute-api.ap-southeast-1.amazonaws.com/production/" />
        </ThemeProvider>
      </Drawer>
    </>
  )
}

export default DrawerChat
