import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { GET_MACHINES } from "../../Query/MasterQuery"
import { IMachine } from "../../../page/Master/Machine"

export const useMachine = (): IMachine[] => {
  const [machine, setMachine] = useState<IMachine[]>([])
  const { data } = useQuery(GET_MACHINES)
  useEffect(() => {
    if (data) {
      setMachine(data.machines)
    }
  }, [data])
  return machine
}
