import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  useTheme
} from "@mui/material"
import { getProcessFilterRumTimeMachine } from "../../../common/utilities"
import { IDataMachine } from "../KPIMaintenance"
import { useState } from "react"
import DialogEditTarget from "./DialogEditTarget"
import { ITargetKpi } from "./MTTR"
import { monthNames } from "../PreventiveMaintenancePlan"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 15,
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2,
    width: "150px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center",
    border: "solid 1px black",
    borderCollapse: "collapse",
    padding: 2,
    width: "150px"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  border: "solid 1px black",
  "&:nth-of-type(odd)": {
    border: "solid 1px black"
  },

  "&:last-child td, &:last-child th": {
    border: "solid 1px black"
  }
}))

interface Repairer {
  id: number
  checkReceive: number
  completeDate: number
  department: number
  docNo: number
  repairMan: number
  repairType: number
  receiver: number
  detail: number
  requestDate: number
  startRepair: number
  waitRepair: number
  endWaitRepair: number
  referDate: number
  leaderCheck: number
  managerCheck: number
  actionDate: string
}
interface IProp {
  linePress: Repairer[]
  lineSpot: Repairer[]
  lineArcWelding: Repairer[]
  rowsMachineRuntime: never[] | undefined
  target: ITargetKpi[]
}

const machineRunTime = (type = "", filterMachineOfMonth = []) => {
  const machineRunTime = getProcessFilterRumTimeMachine(
    type,
    filterMachineOfMonth
  )
  const totalTime = Number(
    machineRunTime
      ?.reduce((acc: number, obj: IDataMachine) => {
        return acc + obj.totalTime
      }, 0)
      .toFixed(2)
  )
  const actualTime = Number(
    machineRunTime
      ?.reduce((acc: number, obj: IDataMachine) => {
        return acc + obj.totalHour
      }, 0)
      .toFixed(2)
  )
  return {
    totalTime: totalTime,
    actualTime: actualTime
  }
}

const total = (
  lineData: Repairer[],
  i: number,
  rowsMachineRuntime = [],
  type = ""
) => {
  if (lineData === undefined)
    return { dataOfMonth: 0, totalQuantityRepair: 0, actualTime: 0, arc: 0 }
  const dataOfMonth = lineData.filter(
    (e) => new Date(e.actionDate).getMonth() === i
  )
  const machineRunTimeOfMonth = rowsMachineRuntime.filter(
    (e: { planDate: string }) => new Date(e.planDate).getMonth() === i
  )
  const lineMachineRunTime = machineRunTime(
    type,
    machineRunTimeOfMonth
  ).totalTime

  if (dataOfMonth.length) {
    const totalQuantityRepair = dataOfMonth
      .map((data) => {
        const diff = Math.abs(
          Number(new Date(data.completeDate)) -
            Number(new Date(data.endWaitRepair))
        )
        const actualTime = diff / 1000 / 60 / 60
        return actualTime
      })
      .reduce((prev: number, cur: number) => {
        return prev + cur
      }, 0)

    const actualTime = lineMachineRunTime - totalQuantityRepair
    const arc = actualTime / dataOfMonth.length
    return {
      dataOfMonth: Math.ceil(totalQuantityRepair * 60),
      lineMachineRunTime: Math.ceil(lineMachineRunTime * 60),
      actualTime: Math.ceil(actualTime * 60),
      totalQuantityRepair: dataOfMonth.length,
      arc: Math.ceil(arc * 60)
    }
  } else {
    return {
      dataOfMonth: 0,
      totalQuantityRepair: 0,
      lineMachineRunTime: Math.ceil(lineMachineRunTime * 60),
      actualTime: Math.ceil(lineMachineRunTime * 60 - 0),
      arc: 0
    }
  }
}
const MTBF: React.FC<IProp> = (props: IProp) => {
  const { linePress, lineSpot, lineArcWelding, rowsMachineRuntime, target } =
    props
  const [open, setOpen] = useState<boolean>(false)
  const [updateTarget, setTarget] = useState<string>("")
  const [line, setLine] = useState<string>("")

  const theme = useTheme()
  return (
    <>
      <DialogEditTarget
        setOpen={setOpen}
        setTarget={setTarget}
        open={open}
        line={line}
        type={"MTBF"}
        updateTarget={updateTarget}
      />
      <h1>ระยะเวลาที่เครื่องจักรจะเสีย (MTBF)</h1>

      <h3 style={{ display: "inline-block" }}>LINE PRESS</h3>
      <ThemedLoadingButton
        sx={{ mt: -2, marginLeft: "10px" }}
        variant="contained"
        style={{ backgroundColor: theme.palette.warning.main }}
        onClick={() => {
          setOpen(true)
          setLine("Press")
        }}
      >
        EDIT TARGET
      </ThemedLoadingButton>
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>Month</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  <span>{monthNames[i]}</span>
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Target
            </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell
                  colSpan={1}
                  rowSpan={1}
                  key={i}
                  style={{ backgroundColor: "grey" }}
                >
                  {target.length > 0
                    ? target.find((e: { line: string }) => e.line === "Press")
                        ?.target
                    : "0"}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>จำนวนครั้งที่ซ่อม</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {
                    total(linePress, i, rowsMachineRuntime, "stamp")
                      .totalQuantityRepair
                  }
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>เวลาสูญเสีย </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {total(linePress, i, rowsMachineRuntime, "stamp").dataOfMonth}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>เวลาทำงานรวม </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {
                    total(linePress, i, rowsMachineRuntime, "stamp")
                      .lineMachineRunTime
                  }
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>เวลาทำงานจริง</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {total(linePress, i, rowsMachineRuntime, "stamp").actualTime}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Act.Targe (%)
            </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell
                  colSpan={1}
                  rowSpan={1}
                  key={i}
                  style={{ backgroundColor: "grey" }}
                >
                  {total(linePress, i, rowsMachineRuntime, "stamp").arc}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
        </TableBody>
      </Table>
      <hr />
      <h3 style={{ display: "inline-block" }}>LINE SPOT</h3>
      <ThemedLoadingButton
        sx={{ mt: -2, marginLeft: "10px" }}
        variant="contained"
        style={{ backgroundColor: theme.palette.warning.main }}
        onClick={() => {
          setOpen(true)
          setLine("Spot")
        }}
      >
        EDIT TARGET
      </ThemedLoadingButton>
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>Month</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  <span>{monthNames[i]}</span>
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Target
            </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell
                  colSpan={1}
                  rowSpan={1}
                  key={i}
                  style={{ backgroundColor: "grey" }}
                >
                  {target.length > 0
                    ? target.find((e: { line: string }) => e.line === "Spot")
                        ?.target
                    : "0"}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>จำนวนครั้งที่ซ่อม</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {
                    total(lineSpot, i, rowsMachineRuntime, "weld")
                      .totalQuantityRepair
                  }
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>เวลาสูญเสีย </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {total(lineSpot, i, rowsMachineRuntime, "weld").dataOfMonth}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>เวลาทำงานรวม </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {
                    total(lineSpot, i, rowsMachineRuntime, "weld")
                      .lineMachineRunTime
                  }
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>เวลาทำงานจริง</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {total(lineSpot, i, rowsMachineRuntime, "weld").actualTime}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Act.Targe (%)
            </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell
                  colSpan={1}
                  rowSpan={1}
                  key={i}
                  style={{ backgroundColor: "grey" }}
                >
                  {total(lineSpot, i, rowsMachineRuntime, "weld").arc}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
        </TableBody>
      </Table>
      <hr />
      <h3 style={{ display: "inline-block" }}>LINE ARC WELDING</h3>
      <ThemedLoadingButton
        sx={{ mt: -2, marginLeft: "10px" }}
        variant="contained"
        style={{ backgroundColor: theme.palette.warning.main }}
        onClick={() => {
          setOpen(true)
          setLine("ArcWelding")
        }}
      >
        EDIT TARGET
      </ThemedLoadingButton>
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>Month</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  <span>{monthNames[i]}</span>
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Target
            </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell
                  colSpan={1}
                  rowSpan={1}
                  key={i}
                  style={{ backgroundColor: "grey" }}
                >
                  {target.length > 0
                    ? target.find(
                        (e: { line: string }) => e.line === "ArcWelding"
                      )?.target
                    : "0"}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>จำนวนครั้งที่ซ่อม</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {
                    total(lineArcWelding, i, rowsMachineRuntime, "arcWeld")
                      .totalQuantityRepair
                  }
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>เวลาสูญเสีย </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {
                    total(lineArcWelding, i, rowsMachineRuntime, "arcWeld")
                      .dataOfMonth
                  }
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>เวลาทำงานรวม </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {
                    total(lineArcWelding, i, rowsMachineRuntime, "arcWeld")
                      .lineMachineRunTime
                  }
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>เวลาทำงานจริง</StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell colSpan={1} rowSpan={1} key={i}>
                  {
                    total(lineArcWelding, i, rowsMachineRuntime, "arcWeld")
                      .actualTime
                  }
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: "grey" }}>
              Act.Targe (%)
            </StyledTableCell>
            {Array.from(Array(monthNames.length), (num, i) => {
              return (
                <StyledTableCell
                  colSpan={1}
                  rowSpan={1}
                  key={i}
                  style={{ backgroundColor: "grey" }}
                >
                  {total(lineArcWelding, i, rowsMachineRuntime, "arcWeld").arc}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
        </TableBody>
      </Table>
    </>
  )
}
export default MTBF
