import { createContext, useEffect, useState } from "react"
import { GridLayoutConfig } from "../page/Dashboard/Dashboard"
import { FilterChart } from "../common/Resources/FilterChartButton"

export interface AuthContextInterface {
  token: string
  chartLayout: ReactGridLayout.Layout[]
  chartList: FilterChart[]
  setChartLayout: (layout: ReactGridLayout.Layout[]) => void
  setChartList: (chartList: FilterChart[]) => void
}

const getToken = () => {
  const savedToken = window?.localStorage?.getItem("token")
  const layout = window.localStorage.getItem("chart-layout")
  const filterChartList = window.localStorage.getItem("filter-chart-list")

  return { savedToken, layout, filterChartList }
}

const baseFilterChartList: FilterChart[] = [
  { name: "กราฟเเสดงการเปรียบเทียบ Sale Order VS Inventory", checked: true },
  { name: "Total Inventory", checked: true },
  { name: "Volume Plan", checked: true },
  { name: "Market Share Volume Plan", checked: true },
  { name: "Auto Part Volume Plan", checked: true },
  { name: "OEE Monthly", checked: true },
  { name: "Breakdown Monthly", checked: true },
  { name: "Breakdown Problem Monthly", checked: true },
  { name: "Defect Report", checked: true },
  { name: "Premium Freight", checked: true },
  { name: "Capacity", checked: true },
  { name: "PMKPI", checked: true },
  { name: "MTTR", checked: true },
  { name: "MTBF", checked: true },
  { name: "BM", checked: true }
]
const layoutBase: GridLayoutConfig[] = [
  { i: "inventory-material-monthly", x: 0, y: 0, w: 4, h: 3 },
  { i: "total-inventory-material-monthly", x: 4, y: 0, w: 4, h: 3 },
  { i: "volume-plan", x: 0, y: 3, w: 2, h: 3 },
  { i: "market-volume-plan", x: 2, y: 3, w: 3, h: 3 },
  { i: "volume-plan-auto-part", x: 5, y: 3, w: 3, h: 3 },
  { i: "oee", x: 0, y: 6, w: 4, h: 3 },
  { i: "breakdown-monthly", x: 4, y: 6, w: 2, h: 3 },
  { i: "breakdown-problem-monthly", x: 6, y: 6, w: 2, h: 3 },
  { i: "defect-report", x: 0, y: 9, w: 2, h: 3 },
  { i: "premium-freight", x: 2, y: 9, w: 2, h: 3 },
  { i: "capacity", x: 4, y: 9, w: 4, h: 3 },
  { i: "PMKPI", x: 0, y: 10, w: 4, h: 3 },
  { i: "MTTR", x: 4, y: 10, w: 4, h: 3 },
  { i: "MTBF", x: 0, y: 13, w: 4, h: 3 },
  { i: "BM", x: 4, y: 13, w: 4, h: 3 }
]
const AuthContext = createContext<AuthContextInterface>({
  token: "",
  chartLayout: [],
  chartList: [],
  setChartLayout: () => ({}),
  setChartList: () => ({})
})

export const AuthContextProvider = ({
  children
}: {
  children: JSX.Element
}): JSX.Element => {
  const [token, setToken] = useState<string>("")
  const [chartLayout, setChartLayout] =
    useState<ReactGridLayout.Layout[]>(layoutBase)
  const [chartList, setChartList] = useState<FilterChart[]>(baseFilterChartList)
  useEffect(() => {
    const { savedToken, layout, filterChartList } = getToken()
    setToken(savedToken ? savedToken : "")
    setChartLayout(layout ? JSON.parse(layout) : layoutBase)
    setChartList(filterChartList ? JSON.parse(filterChartList) : chartList)
  }, [setChartLayout, setChartList])

  return (
    <AuthContext.Provider
      value={{ token, chartLayout, chartList, setChartLayout, setChartList }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
