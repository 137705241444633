import { Box, Grid, Popover, Typography } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"
import moment from "moment"
import { useState } from "react"
import { IMachineProblem } from "../../page/Production/OEE"

interface PopoverOpenProps {
  event: React.MouseEvent<HTMLElement>
  problemName: string
  startTime: string
  endTime: string
}

interface ProblemNameInfo {
  [key: string]: string
}

interface ProblemNameType {
  [key: string]: ProblemNameInfo
}

interface IChartPopoverProps {
  problemName: string
  startTime: string
  endTime: string
  open: boolean
  anchorEl: HTMLElement | null
  handlePopoverClose: () => void
}

interface ITimeProps {
  startTime: string
  endTime: string
}

const useStyles = makeStyles(() =>
  createStyles({
    chartProperty: {
      backgroundColor: "#245953"
    }
  })
)

const sharedBoxStyle = {
  position: "absolute",
  height: 20
}

// alternative to Info Key in QCDemolish/FG
const problemNameColorList: ProblemNameType = {
  pm: {
    pm: "yellow"
  },
  "Machine Breakdown": {
    "machine-breakdown": "red"
  },
  "DIE Breakdown": {
    "die-breakdown": "blue"
  },
  "wait qc first set up": {
    "wait-qc-first-set-up": "white"
  },
  other: {
    other: "orange"
  }
}

const handleMouseOver = (problemName: string) => {
  const hoveredProblem = document.querySelectorAll<HTMLElement>(
    `.problemName-${problemName}`
  )
  for (let i = 0; i < hoveredProblem.length; i++) {
    hoveredProblem[i].style.opacity = "0.8"
  }
}

const handleMouseOut = (problemName: string) => {
  const hoveredProblem = document.querySelectorAll<HTMLElement>(
    `.problemName-${problemName}`
  )
  for (let i = 0; i < hoveredProblem.length; i++) {
    hoveredProblem[i].style.opacity = "1"
  }
}

const ChartPopover = (props: IChartPopoverProps): JSX.Element => {
  const {
    problemName,
    startTime,
    endTime,
    open,
    anchorEl,
    handlePopoverClose
  } = props

  const classes = useStyles()

  return (
    <Popover
      id={`problemName-${problemName}-${startTime}`}
      sx={{
        pointerEvents: "none"
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Box className={classes.chartProperty}>
        <Grid container spacing={1} direction="column" padding={2}>
          <Grid item xs={12}>
            <Typography fontWeight={"bold"} fontSize={20}>
              {problemName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Start Time: {startTime}</Typography>
          </Grid>
          <Grid item>
            <Typography>End Time: {endTime}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  )
}

const BarChartLegend = (props: ITimeProps): JSX.Element => {
  const { startTime, endTime } = props
  const startDayTime = new Date(`2023-01-31 ${props.startTime}`).getTime()
  const endDayTime = new Date(`2023-01-31 ${props.endTime}`).getTime()
  const diffTimeOne = moment(
    (endDayTime - startDayTime) / 3 + startDayTime
  ).format("HH:mm")
  const diffTimeTwo = moment(
    ((endDayTime - startDayTime) / 3) * 2 + startDayTime
  ).format("HH:mm")

  const legendList = []
  for (const problemName in problemNameColorList) {
    // only 1 key:value pair each so index = 0
    const problemClassName = Object.keys(
      problemNameColorList[problemName as keyof ProblemNameType]
    )[0]
    const problemColor = Object.values(
      problemNameColorList[problemName as keyof ProblemNameType]
    )[0]

    legendList.push(
      <Grid container item xs="auto" spacing={2}>
        <Grid item>
          <Box
            className={`problemName-${problemClassName}`}
            sx={{
              height: 20,
              width: 20,
              backgroundColor: problemColor,
              marginTop: 2
            }}
            onMouseOver={() => handleMouseOver(problemClassName)}
            onMouseOut={() => handleMouseOut(problemClassName)}
          ></Box>
        </Grid>
        <Grid item>
          <Typography
            className={`problemName-${problemClassName}`}
            sx={{ color: problemColor }}
            onMouseOver={() => handleMouseOver(problemClassName)}
            onMouseOut={() => handleMouseOut(problemClassName)}
          >
            {`${problemName}`}
          </Typography>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid>
      <Grid
        container
        justifyContent="space-between"
        style={{ fontSize: "20px" }}
      >
        <Grid>{startTime}</Grid>
        <Grid>{diffTimeOne}</Grid>
        <Grid>{diffTimeTwo}</Grid>
        <Grid>{endTime}</Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        spacing={2}
        xs={12}
        className="the-legend"
        padding={2}
      >
        {legendList}
      </Grid>
    </Grid>
  )
}

interface IProp {
  machineProblem: IMachineProblem[]
  startDayTime: string
  endDayTime: string
}

const BarChart = (props: IProp): JSX.Element => {
  const { machineProblem } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [popoverProblemName, setPopoverProblemName] = useState<string>("")
  const [popoverStartTime, setPopoverStartTime] = useState<string>("")
  const [popoverEndTime, setPopoverEndTime] = useState<string>("")
  const classes = useStyles()
  const handlePopoverOpen = (props: PopoverOpenProps) => {
    const { event, problemName, startTime, endTime } = props
    setAnchorEl(event.currentTarget)
    setPopoverProblemName(problemName)
    setPopoverStartTime(startTime)
    setPopoverEndTime(endTime)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const startDayTime = new Date(`2023-01-31 ${props.startDayTime}`).getTime()
  const endDayTime = new Date(`2023-01-31 ${props.endDayTime}`).getTime()
  const totalHour = endDayTime - startDayTime
  const chart = machineProblem.map(({ problemName, startTime, endTime }) => {
    const startTimeNumber =
      new Date(`2023-01-31 ${startTime}`).getTime() - startDayTime
    const endTimeNumber =
      new Date(`2023-01-31 ${endTime}`).getTime() - startDayTime

    const startPoint = Number(((100 * startTimeNumber) / totalHour).toFixed(2))
    const boxWidth = Number(
      ((100 * (endTimeNumber - startTimeNumber)) / totalHour).toFixed(2)
    )

    const problemClassName = Object.keys(
      problemNameColorList[problemName as keyof ProblemNameType]
    )[0]
    const problemColor = Object.values(
      problemNameColorList[problemName as keyof ProblemNameType]
    )[0]

    return (
      <>
        <Box
          id={open ? `problemName-${problemClassName}-${startTime}` : undefined}
          aria-haspopup="true"
          className={`problemName-${problemClassName}`}
          sx={{
            ...sharedBoxStyle,
            width: `${boxWidth}%`,
            left: `${startPoint}%`,
            backgroundColor: problemColor
          }}
          onMouseOver={() => handleMouseOver(problemClassName)}
          onMouseOut={() => handleMouseOut(problemClassName)}
          onMouseEnter={(event: React.MouseEvent<HTMLElement>) =>
            handlePopoverOpen({ event, problemName, startTime, endTime })
          }
          onMouseLeave={handlePopoverClose}
        ></Box>
      </>
    )
  })

  return (
    <Grid container width={"100%"} padding={1}>
      <Grid container item xs={12} className="the-chart">
        <Box
          className={classes.chartProperty}
          sx={{
            position: "relative",
            width: "100%",
            height: 20
          }}
        >
          {chart}
        </Box>
      </Grid>
      <BarChartLegend
        startTime={props.startDayTime}
        endTime={props.endDayTime}
      />
      <ChartPopover
        startTime={popoverStartTime}
        endTime={popoverEndTime}
        problemName={popoverProblemName}
        open={open}
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
      />
    </Grid>
  )
}

export default BarChart
