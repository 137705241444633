import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Grid,
  Autocomplete,
  Checkbox,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  TableFooter,
  useTheme,
  TextField
} from "@mui/material"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import DeleteIcon from "@mui/icons-material/Delete"
import FilterListIcon from "@mui/icons-material/FilterList"
import { alpha } from "@mui/material/styles"
import { useMutation } from "@apollo/client"
import { CREATE_MULTIPLE_FG_OUT } from "../../../common/Mutation/WarehouseMutation"
import swal from "sweetalert"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"

interface Column {
  id:
    | "actionDate"
    | "partNo"
    | "factory"
    | "plating"
    | "quantity"
    | "currentStock"
    | "factory"
    | "packingBy"
    | "status"
    | "remarks"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

interface IRow {
  [key: string]: number | string
  partNo: string
  actionDate: string
  quantity: number
  factory: string
  remarks: string
  packingBy: string
  currentStock: number
  status: string
  plating: string
}
const columns: Column[] = [
  { id: "actionDate", label: "ActionDate", minWidth: 100 },
  { id: "partNo", label: "Part No", minWidth: 50 },
  { id: "factory", label: "Factory", minWidth: 50 },
  { id: "plating", label: "Plating", minWidth: 50 },
  { id: "quantity", label: "Quantity Order", minWidth: 20 },
  { id: "currentStock", label: "Current Stock", minWidth: 20 },
  { id: "packingBy", label: "Employee", minWidth: 20 },
  { id: "status", label: "Status", minWidth: 20 },
  { id: "remarks", label: "Remarks", minWidth: 20 }
]
interface IProps {
  data: IRow[]
  userFactory: string
  handleSubmit: () => void
}

const TableFGOut: React.FC<IProps> = (props: IProps) => {
  const { data, userFactory, handleSubmit } = props
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [partNo, setPartNo] = useState<string | null>("")
  const [selected, setSelected] = useState<IRow[]>([])
  const [createFgOut, { loading }] = useMutation(CREATE_MULTIPLE_FG_OUT)
  const [rows, setRows] = useState<IRow[]>([])

  let partNoList: Array<string> = []

  useEffect(()=>{
    if (data){
      setRows(data)
      if (partNo){
        setRows(rows?.filter((e) => e.partNo === partNo))
      }
    } else {setRows([])}
  },[data, partNo])
  
  if (data?.length > 0) {
    partNoList = data.map((e: { partNo: string }) => e.partNo)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const EnhancedTableHead = (props: {
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
    numSelected: number
    rowCount: number
  }) => {
    const { numSelected, rowCount, onSelectAllClick } = props

    return (
      <TableHead>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell key={headCell.id}>{headCell.label}</TableCell>
          ))}
          <TableCell padding="checkbox">
            <Checkbox
              onChange={onSelectAllClick}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              inputProps={{
                "aria-label": "select all desserts"
              }}
            />
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired
  }

  const EnhancedTableToolbar = (props: { numSelected: number }) => {
    const { numSelected } = props

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              )
          })
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Nutrition
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    )
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
  }

  const handelOutFg = async () => {
    try {
      const data = selected.map((e) => {
        // userFactory คือ user ใส่ textFIeld factory 1 เข้ามา, แต่เนื่องจาก Data ที่ดึงมี part โรง 2(ที่ต้อง Plating) มาแสดงด้วย, แล้ว function นี้คือการตัด Stock ใน finishgood(stockCard), ดังนั้น Part โรง2 จึงต้องตัด stock ที่โรง 1 เพราะว่า เจ้าหน้าที่เค้า บรรจุ finished good(stockCard) ของ partNo โรง2แบบชุบไว้ที่ factory 1 ใน database
        return {
          partNo: e.partNo,
          actionDate: e.actionDate,
          factory: userFactory,
          quantity: e.quantity,
          remarks: e.remarks,
          isNG: false,
          packingBy: e.packingBy
        }
      })
      if (data.length === 0) {
        return swal("ERROR", "กรุณาเลือกอย่างน้อย 1 row", "error")
      }

      await createFgOut({
        variables: {
          dataFgOutMultiple: data
        }
      })
      swal("Success", "บัททึกสำเร็จ", "success")
      setSelected([])
      handleSubmit()
    } catch (err) {
      swal("error", `${(err as Error).message}`, "error")
    }
  }

  const handleClick = (
    // event: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>,
    row: IRow
  ) => {
    const selectedIndex = selected.indexOf(row)
    let newSelected: IRow[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleEditChange = (index: number, field: keyof IRow, value: string) => {
    const updatedData: IRow[] = [...rows];
    updatedData[index][field] = value;
    
    setRows(updatedData)
  };

  const isSelected = (row: IRow) => selected.indexOf(row) !== -1

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(rows)
      return
    }
    setSelected([])
  }

  const theme = useTheme()
  return (
    <div>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            disablePortal
            id="partNo"
            value={partNo}
            options={partNoList}
            style={{ width: "100%" }}
            sx={{ width: 300 }}
            onChange={(e, newValue: string | null) => {
              setPartNo(newValue)
            }}
            renderInput={(params) => (
              <ThemedTextField {...params} label={"Part No."} value={partNo} />
            )}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            onSelectAllClick={handleSelectAllClick}
            numSelected={selected.length}
            rowCount={data?.length || 0}
          />
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                const isItemSelected = isSelected(row)
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    // onClick={(event) => handleClick(event, row)}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                  >
                    {columns.map((column) => {
                      const value = row[column.id]
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={
                            column.id === "status" && value === "Stock ติดลบ"
                              ? { color: "red" }
                              : {}
                          }
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : column.id === "status"
                            ? `${value}  ${
                                row["currentStock"] - row["quantity"]
                              }`
                            : column.id === "remarks" ?  <TextField label="Remark" size="small" value={value}
                            onChange={(e) =>handleEditChange((page * rowsPerPage + i), column.id, e.target.value)} 
                            /> : value}
                        </TableCell>
                      )
                    })}

                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={isItemSelected} onClick={() => handleClick(row)}/>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4}>
                <ThemedLoadingButton
                  loading={loading}
                  variant="contained"
                  sx={{ backgroundColor: theme.palette.primary.main }}
                  onClick={handelOutFg}
                >
                  Submit Out Fg
                </ThemedLoadingButton>
              </TableCell>
              <TableCell colSpan={4}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}
export default TableFGOut
