import { gql } from "@apollo/client"

export const GET_OtherPdlotNO = gql`
  query OtherPdlotNO($partNo: String, $prodDate: String) {
    otherPdlotNO(partNo: $partNo, prodDate: $prodDate) {
      partNo
      pdLotNo
      process
      boxNo
      prodDate
    }
  }
`

export const GET_WIPReportSum = gql`
  query WIPReportSum($boxNo: Int, $jobOrder: String, $pdLotNo: String) {
    WIPReportSum(boxNo: $boxNo, jobOrder: $jobOrder, pdLotNo: $pdLotNo) {
      partNo
      pdLotNo
      process
      boxNo
      prodDate
      jobOrder
      quantity
      totalNG
    }
  }
`

export const GET_Wip_VOLUME = gql`
  query Wipvolume($jobOrder: String!) {
    wipvolume(jobOrder: $jobOrder) {
      quantity
      perBox
    }
  }
`

export const GET_TEST_SCAN = gql`
  query CheckOrder($pdLotNo: String!, $jobOrder: String!) {
    checkOrder(pdLotNo: $pdLotNo, jobOrder: $jobOrder) {
      partNo
      jobOrder
      pdLotNo
      inOrOut
      actionDate
      quantity
      factory
      packingBy
      remarks
      tNG
      iNG
      sNG
      supplierNG
      machine
      line
      boxNo
      process
      prodDate
      totalCost
      dateScan
      componentLot
      keyBy
    }
  }
`

export const GET_PART_PROCESS = gql`
  query PartProcesses($partNo: String!) {
    partProcesses(partNo: $partNo) {
      processOrder
      stepProcess
      partNo
      process
      machine
      line
      jph
      minStroke
    }
  }
`

export const GET_MAT_COMPONENT = gql`
  query Query {
    materialComponentList
  }
`

export const GET_COMPONENT_LOT = gql`
  query Query($partComponent: String!) {
    componentLotList(partComponent: $partComponent)
  }
`

export const GET_TODAY_PLATING_THICKNESS = gql`
  query GetTodayPlatingThickness($jobOrderInput: String) {
    getTodayPlatingThickness(jobOrderInput: $jobOrderInput) {
      jobOrder
      partNo
      actionDate
      firstPieceThickness
      secondPieceThickness
      thirdPieceThickness
    }
  }
`
