import React, {
  ChangeEvent,
  ReactElement,
  SetStateAction,
  useState
} from "react"
import {
  Box,
  Grid,
  Autocomplete,
  FormControlLabel,
  RadioGroup,
  Radio,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Switch,
  useTheme
} from "@mui/material"
import QrReader from "react-qr-reader"
import swal from "sweetalert"
import { GET_WH_TRANSPORTS_BOX_NO } from "../../common/Query/WarehouseQuery"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  CREATE_WAREHOUSE_TRANSPORT,
  DELETE_WH_LOCATION
} from "../../common/Mutation/WarehouseMutation"
import moment from "moment"
import { GET_LOGISTICS_REPORT_CAR_LICENSE } from "../../common/Query/LogisticsQuery"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import { menuItemList } from "../../common/Resources/menuItemList"
import { GET_WIP_CARD } from "../../common/Query/MaterialQuery"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"

const pageTitle = "WH Scanner"

const WhScanner: React.FC = (): ReactElement => {
  const [isStream, setIsStream] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [dataScan, setDataScan] = useState<string>("")
  const [pdLotNo, setPdLotNo] = useState<string>("")
  const [jobOrder, setJobOrder] = useState<string>("")
  const [partNo, setPartNo] = useState<string>("")
  const [remarks, setRemarks] = useState<string>("")
  const [carLicense, setCarLicense] = useState<string | null>("")
  const [period, setPeriod] = useState<string | null>("")
  const [personnel, setPersonnel] = useState<string | null>("")
  const [boxNo, setBoxNo] = useState("")
  const [endBox, setEndBox] = useState<number>(1)
  const [array, setBoxCount] = useState<undefined[]>([])
  const [productionDate, setProductionDate] = useState("YYYY-MM-DD")
  const [transportDate, setTransportDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [quantity, setQuantity] = useState<number>(0)
  const [fromLocation, setFromLocation] = useState("BAE1")
  const [toLocation, setToLocation] = useState("BAE1")
  const { data: carLicenseData, loading: carLicenseLoading } = useQuery(
    GET_LOGISTICS_REPORT_CAR_LICENSE
  )
  const [getWipCard] = useLazyQuery(GET_WIP_CARD)
  // const [getWIPReportSum] = useLazyQuery(GET_WIPReportSum)
  const [getWarehouseTransportBoxNo] = useLazyQuery(GET_WH_TRANSPORTS_BOX_NO)
  // const [getWarehouseFifo] = useLazyQuery(GET_WH_FIFO)
  const [deleteWHLocation] = useMutation(DELETE_WH_LOCATION)
  const [createWhTransport] = useMutation(CREATE_WAREHOUSE_TRANSPORT)
  // const [processData, setProcessData] = useState([])
  const periodList: string[] = [
    "เช้า: 09.00 น.",
    "เช้า: 11.00 น.",
    "บ่าย: 14.00 น.",
    "บ่าย: 16.00 น.",
    "เย็น: 18.00 น.",
    "เย็น: 19.00 น."
  ]
  const personnelList: Array<string> = ["รุ่งฤดี", "วันเฉลิม", "วรากร", "ณัฏฐ์"]

  const carLicenseList =
    !carLicenseLoading && carLicenseData
      ? carLicenseData.carLicense.map(
          ({ carLicense }: { carLicense: string }) => carLicense
        )
      : [""]

  const handleScan = (data: SetStateAction<string> | null): void => {
    if (data) {
      setDataScan(data)
      if (dataScan) {
        check()
      }
    }
  }

  const handleError = (err: string) => {
    console.error(err)
  }

  const isComplete = () => {
    return (
      partNo !== "" &&
      pdLotNo !== "" &&
      boxNo !== "" &&
      transportDate !== "" &&
      productionDate !== "" &&
      quantity !== 0 &&
      fromLocation !== "" &&
      toLocation !== "" &&
      period !== "" &&
      carLicense !== "" &&
      personnel !== ""
    )
  }

  const check = async () => {
    const newSplit = dataScan?.split("|")
    if (!newSplit || newSplit.length !== 6) {
      swal("warning!", "มีปัญหากับ format ของ QR Code!", "warning")
    } else {
      setProductionDate(moment(newSplit[5], "DD-MM-YY").format("YYYY-MM-DD"))
      setPdLotNo(`${newSplit[5]}|${newSplit[1]}`)
      setJobOrder(`${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`)
      setPartNo(newSplit[1])
      setBoxNo(newSplit[3])
      if (newSplit[1] && `${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`) {
        const wipcard = await getWipCard({
          variables: {
            partNo: newSplit[1],
            jobOrder: `${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`
          }
        })
        if (wipcard?.data?.wipcard[0]) {
          const box = Math.ceil(
            wipcard.data.wipcard[0].quantity / wipcard.data.wipcard[0].perBox -
              Number(newSplit[3]) +
              1
          )
          setEndBox(Number(newSplit[3]))
          setBoxCount([...new Array(box)])
        }
      }
      alert(dataScan)
    }
  }

  const deleteLocation = async () => {
    const newSplit = dataScan.split("|")
    const data = {
      partNo: newSplit[1],
      jobOrder: `${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`,
      boxNo: parseInt(newSplit[3])
    }
    try {
      await deleteWHLocation({
        variables: {
          data
        }
      })
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }
  const onReset = () => {
    setPartNo("")
    setPdLotNo("")
    setBoxNo("")
    setJobOrder("")
    setDataScan("")
    setRemarks("")
  }

  const onSave = async () => {
    if (!isComplete()) {
      swal("warning", `กรุณากรอกข้อมูลให้ครบถ้วน`, "warning")
      return
    }
    if (await checkOrder()) {
      return
    }
    setLoading(true)
    deleteLocation()
    const data = {
      partNo: partNo,
      pdLotNo: pdLotNo,
      jobOrder: jobOrder,
      boxNo: parseInt(boxNo),
      prodDate: productionDate,
      quantity: quantity,
      endBox: endBox,
      branchFrom: fromLocation,
      branchTo: toLocation,
      period: period,
      transportDate: transportDate,
      remarks: remarks,
      operatorWH: personnel,
      carLicense: carLicense
    }
    try {
      await createWhTransport({
        variables: { data }
      })

      swal("Success", "Scan Production tag Successful", "success")
      onReset()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      swal("ERROR", `${(error as Error).message}`, "warning")
    }
  }

  const breakQRCode = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    check()
  }

  const checkOrder = async () => {
    const newSplit = dataScan.split("|")
    const parseIntBoxNo = parseInt(boxNo)
    const getBoxNo = await getWarehouseTransportBoxNo({
      variables: {
        data: {
          jobOrder: `${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`,
          pdLotNo: `${newSplit[5]}|${newSplit[4]}`
        }
      },
      fetchPolicy: "network-only"
    })
    let max = 0
    const boxData = getBoxNo?.data?.getWHTransportBoxNo
    if (boxData.length > 0) {
      max = boxData.reduce(
        (prev: { boxNo: number }, current: { boxNo: number }) =>
          prev.boxNo > current.boxNo ? prev : current
      ).boxNo
    }
    if (max + 1 > parseIntBoxNo)
      alert(`Box ${parseIntBoxNo} ได้ถูกยิงเข้าไปแล้ว`)
    if (max + 1 < parseIntBoxNo)
      alert(`มีการยิงข้าม Box ไม่สามารถ Submit ได้ \nBox ที่หายไป: ${max + 1}`)
    return max + 1 === parseIntBoxNo ? false : true
  }

  const handleChangeFromLocation = (event: ChangeEvent<HTMLInputElement>) => {
    setFromLocation(event.target.value)
  }

  const handleChangeToLocation = (event: ChangeEvent<HTMLInputElement>) => {
    setToLocation(event.target.value)
  }
  const theme = useTheme()

  return (
    <Box sx={{ marginLeft: "22px" }}>
      <PageLabel
        menuItem={menuItemList.Warehouse}
        menuItemName={Object.keys(menuItemList)[6]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <Box onSubmit={breakQRCode} component="form">
          <Accordion
            expanded={isStream}
            onChange={() => setIsStream(!isStream)}
            style={{
              marginBottom: "5px",
              marginTop: "10px"
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#ffffff", display: "none" }}
            />

            <AccordionDetails>
              {isStream ? (
                <>
                  <Grid container justifyContent={"center"}>
                    <div style={{ width: "500px" }}>
                      <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: "70%" }}
                      />
                    </div>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </AccordionDetails>
          </Accordion>
          <PageLabelCard
            title="Warehouse Scanner"
            subTitle="detail about Warehouse Scanner"
          />
          <Grid container spacing={2} width={"98%"} margin={2}>
            <Grid item xs={12} sm={12}>
              <Switch
                checked={isStream}
                onChange={(event) =>
                  setIsStream(event.target.value ? !isStream : isStream)
                }
                name="gilad"
              />
              <label style={{ fontSize: "14px" }}>
                Active Camera{" "}
                <CameraAltIcon style={{ verticalAlign: "middle" }} />
              </label>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                id="scanner"
                value={dataScan}
                onChange={(e) => setDataScan(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                value={partNo}
                disabled
                className="disable-field"
                label="Part No"
                InputProps={{
                  style: { WebkitTextFillColor: "black" }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                id="pdLotNo"
                label="PdLotNo"
                value={pdLotNo}
                disabled
                InputProps={{
                  style: { WebkitTextFillColor: "black" }
                }}
                className="disable-field"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                id="jobOrder"
                value={jobOrder}
                disabled
                label="JobOrder"
                InputProps={{
                  style: { WebkitTextFillColor: "black" }
                }}
                className="disable-field"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                id="productionDate"
                value={productionDate}
                disabled
                label="Production Date"
                InputProps={{
                  style: { WebkitTextFillColor: "black" }
                }}
                className="disable-field"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <ThemedTextField
                fullWidth
                id="boxNo"
                value={boxNo}
                disabled
                label="Box No"
                InputProps={{
                  style: { WebkitTextFillColor: "black" }
                }}
                className="disable-field"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Autocomplete
                disablePortal
                id="boxNo"
                value={endBox}
                options={array.map((each, index) => index + Number(boxNo))}
                style={{ width: "100%" }}
                sx={{ width: 300 }}
                onChange={(e, newValue) => setEndBox(newValue ? newValue : 0)}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Select end box " />
                )}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <ThemedTextField
                id="Date Range"
                type="date"
                value={transportDate}
                error={!transportDate}
                fullWidth
                label="Delivery Date"
                onChange={(event: {
                  target: { value: React.SetStateAction<string> }
                }) => setTransportDate(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <ThemedTextField
                fullWidth
                id="quantity"
                error={!quantity}
                value={quantity ? quantity : ""}
                type="number"
                label="Quantity"
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
                autoComplete="family-name"
                onChange={(e) => setQuantity(+e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disablePortal
                id="period"
                value={period}
                options={periodList}
                onChange={(e, newValue) => setPeriod(newValue)}
                renderInput={(params) => (
                  <ThemedTextField {...params} error={!period} label="Period" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  width: "auto",
                  height: "50px",
                  border: " 1px solid"
                }}
                paddingLeft={2}
              >
                <legend
                  id="labels"
                  style={{
                    marginTop: "-13px",
                    backgroundColor: "white",
                    width: "100px",
                    marginLeft: "5px"
                  }}
                >
                  Delivery From
                </legend>
                <RadioGroup
                  row
                  value={fromLocation}
                  onChange={handleChangeFromLocation}
                >
                  <FormControlLabel
                    value="BAE1"
                    control={<Radio />}
                    label="BAE1"
                  />
                  <FormControlLabel
                    value="BAE2"
                    control={<Radio />}
                    label="BAE2"
                  />
                </RadioGroup>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  width: "auto",
                  height: "50px",
                  border: " 1px solid"
                }}
                paddingLeft={2}
              >
                <legend
                  id="labels"
                  style={{
                    marginTop: "-13px",
                    backgroundColor: "white",
                    width: "100px",
                    marginLeft: "5px"
                  }}
                >
                  Delivery To
                </legend>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={toLocation}
                  onChange={handleChangeToLocation}
                >
                  <FormControlLabel
                    value="BAE1"
                    control={<Radio />}
                    label="BAE1"
                  />
                  <FormControlLabel
                    value="BAE2"
                    control={<Radio />}
                    label="BAE2"
                  />
                  <FormControlLabel
                    value="SUPPLIER"
                    control={<Radio />}
                    label="SUPPLIER"
                  />
                </RadioGroup>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Autocomplete
                disablePortal
                id="carLicense"
                value={carLicense}
                options={carLicenseList}
                onChange={(e, newValue) => setCarLicense(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    error={!carLicense}
                    label="CarLicense"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disablePortal
                id="personnel"
                value={personnel}
                options={personnelList}
                onChange={(e, newValue) => setPersonnel(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    error={!personnel}
                    label="Personnel"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <ThemedTextField
                fullWidth
                id="remarks"
                label="Remarks"
                autoComplete="family-name"
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedLoadingButton
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main
                }}
                onClick={onSave}
                loading={loading}
              >
                SUBMIT
              </ThemedLoadingButton>
            </Grid>
          </Grid>
          <ThemedButton type="submit"></ThemedButton>
        </Box>
      </ThemedCard>
    </Box>
  )
}
export default WhScanner
