import React, { Dispatch, SetStateAction, useEffect } from "react"
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  CircularProgress
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { gql, useQuery } from "@apollo/client"
import QRCode from "qrcode.react"
import "../../../css/Warehouse/GetWarehouseTag.css"

import { bankImage, getImageJPG, getImagePng } from "../../../common/utilities"

interface IDeliveryTagLabelsProps {
  customer: string
  partNo: string
  period: string
  warehouseQuantity: number
  totalQuantity: number
  packingBy: string
  pdLotNo: string
  deliveryDate: string
  setPartName: Dispatch<SetStateAction<string>>
  setModel: Dispatch<SetStateAction<string>>
}

const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  border: "solid 1px black",
  fontFamily: "Avenir, Arial, Helvetica, sans-serif",
  fontSize: "25px",
  fontStyle: "normal",
  padding: "0px"
}))

interface PartType {
  partNo: string
  partName: string
  model: string
}

interface PartsListType {
  parts: PartType[]
}

export const GET_PARTS_INFO = gql`
  query getParts {
    parts {
      partNo
      partName
      model
    }
  }
`

const DeliveryTagLabels = (props: IDeliveryTagLabelsProps): JSX.Element => {
  const {
    customer,
    partNo,
    period,
    warehouseQuantity,
    totalQuantity,
    packingBy,
    pdLotNo,
    deliveryDate,
    setPartName,
    setModel
  } = props
  const { data: partsData, loading } = useQuery(GET_PARTS_INFO)
  const filterName = (partsList: PartsListType): string => {
    if (!partsList) return ""
    const partName: string[] = partsList.parts
      .filter((part: PartType) => partNo === part.partNo)
      .map((filteredPart: PartType) => filteredPart.partName)
    // setPartName(partName[0])
    return partName[0]
  }
  const filterModel = (partsList: PartsListType): string => {
    if (!partsList) return ""
    const partModel: string[] = partsList.parts
      .filter((part: PartType) => partNo === part.partNo)
      .map((filteredPart: PartType) => filteredPart.model)
    // setModel(partModel[0])
    return partModel[0]
  }
  useEffect(() => {
    setPartName(filterName(partsData))
    setModel(filterModel(partsData))
  })

  if (loading)
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ padding: "10px" }}
      >
        <CircularProgress />
      </Grid>
    )

  const amountOfTags = new Array(
    Math.ceil(totalQuantity / warehouseQuantity)
  ).fill("")

  const periodColor = (period: string): string => {
    if (period === "รอบเสริม") return getImageJPG("images/color/periodyellow")
    if (period === "รอบพิเศษ") return getImageJPG("images/color/periodred")
    return getImageJPG("images/color/periodgreen")
  }
  const getImage = (partNo: string): string => {
    try {
      return getImagePng(`images/parts/${partNo}`)
    } catch (err) {
      return getImageJPG(`images/parts/blank`)
    }
  }

  const getQR = (
    partNo: string,
    receiveDate: string,
    pdLotNo: string,
    warehouseQuantity: number,
    index: number
  ) => {
    return `${partNo}|${receiveDate}|${pdLotNo}|${warehouseQuantity}|${
      index + 1
    }`
  }

  const filterQuantity = () => {
    if (totalQuantity % warehouseQuantity === 0) return warehouseQuantity
    else return totalQuantity % warehouseQuantity
  }

  return (
    <>
      {amountOfTags.length && partsData ? (
        amountOfTags.map((unused: unknown, index: number) => {
          return (
            <Grid
              item
              xs={6}
              sm={6}
              container
              justifyContent="center"
              padding={3}
            >
              <TableContainer>
                <Table
                  className="print-delivery-tag"
                  style={{ height: "430px" }}
                >
                  <TableHead
                    className="bordered-section"
                    style={{ textAlign: "center" }}
                  >
                    <StyledTableCell colSpan={4}>
                      <Grid container spacing={1} mb={2} mt={2}>
                        <Grid item xs={12}>
                          <label className="delivery-tag-bolded">
                            BROTHER AUTO PARTS ENGINEERING CO.,LTD ●
                          </label>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={customer === "HITACHI" ? 5 : 6}
                          justifyContent="space-evenly"
                          alignItems="center"
                        >
                          <label
                            style={{
                              display: "inline-block",
                              margin: "1px"
                            }}
                            className="delivery-tag-small-font"
                          >
                            DL-TAG
                          </label>
                          <img
                            src={periodColor(period)}
                            alt=""
                            style={{
                              width: "70px  ",
                              height: "auto",
                              display: "inline-block"
                            }}
                          />
                        </Grid>
                        {customer === "HITACHI" ? (
                          <Grid item xs={2}>
                            <label style={{ color: "red", fontWeight: "bold" }}>
                              RoHS
                            </label>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <Grid item xs={customer === "HITACHI" ? 5 : 6}>
                          <label className="delivery-tag-small-font">
                            F-WH-03
                          </label>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableHead>
                  <TableBody style={{ textAlign: "center" }}>
                    <TableRow>
                      <StyledTableCell colSpan={2}>
                        Part No(รหัสชิ้นส่วน)
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={2}
                        style={{ fontSize: "20px", whiteSpace: "nowrap" }}
                      >
                        {partNo}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell colSpan={2}>
                        Part Name(ชื่อชิ้นส่วน)
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={2}
                        style={{ fontSize: "20px", whiteSpace: "nowrap" }}
                      >
                        {filterName(partsData)}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell colSpan={2}>
                        {`MODEL(รุ่น): ${filterModel(partsData)}`}
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        {`ลูกค้า: ${customer}`}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow className="bordered-section delivery-tag-4-column-bold">
                      <StyledTableCell colSpan={1}>
                        Q'TY
                        <br />
                        (จำนวน)
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        className="delivery-tag-wh-quantity"
                      >
                        {index + 1 ===
                        Math.ceil(totalQuantity / warehouseQuantity)
                          ? filterQuantity()
                          : warehouseQuantity}
                      </StyledTableCell>
                      <StyledTableCell colSpan={1}>
                        Packing by
                        <br />
                        (ผู้แพ็คงาน)
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        style={
                          customer === "HITACHI" || customer === "PROSPIRA"
                            ? { color: "red" }
                            : { color: "black" }
                        }
                      >
                        {packingBy}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow className="delivery-tag-bolded">
                      <StyledTableCell colSpan={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            PD.Lot No.
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              color:
                                customer === "HITACHI" ||
                                customer === "PROSPIRA"
                                  ? "red"
                                  : "black"
                            }}
                          >
                            {pdLotNo}
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            Delivery date
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              color:
                                customer === "HITACHI" ||
                                customer === "PROSPIRA"
                                  ? "red"
                                  : "black"
                            }}
                          >
                            {deliveryDate}
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={2}
                        style={{ padding: "5px 0px" }}
                      >
                        <img
                          src={getImage(partNo)}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = bankImage
                          }}
                          alt=""
                          style={{
                            width: "100px",
                            height: "100px"
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        <QRCode
                          value={getQR(
                            partNo,
                            deliveryDate,
                            pdLotNo,
                            warehouseQuantity,
                            index
                          )}
                          size={100}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow className="bordered-section delivery-tag-4-column-bold">
                      <StyledTableCell colSpan={1}>Box No.</StyledTableCell>
                      <StyledTableCell colSpan={1}>{index + 1}</StyledTableCell>
                      <StyledTableCell colSpan={1}>/</StyledTableCell>
                      <StyledTableCell colSpan={1}>
                        {amountOfTags.length}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )
        })
      ) : (
        <></>
      )}
    </>
  )
}

export default DeliveryTagLabels
