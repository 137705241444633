import {
  Autocomplete,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { useMemo, useState } from "react"
import AutocompleteFactory from "../../common/Resources/AutocompleteFactory"
import { miniLogo } from "../../common/utilities"
import { useQuery } from "@apollo/client"
import {
  GET_MASTER_PM_SPARE_PART,
  GET_PM_SPARE_PART_STOCK_CARD
} from "../../common/Query/engineerQuery"

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: "#FFFFFF",
  [`&[class*=display-border]`]: {
    backgroundColor: "#BBBBBB"
  },
  "&:last-child td, &:last-child th": {
    border: "solid",
    borderWidth: "1px"
  }
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "bold",
    border: "solid",
    borderWidth: "1px",
    borderStyle: "solid",
    whiteSpace: "nowrap",
    padding: "7px",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary.main
        : theme.palette.secondary.contrastText,
    color: "#000000"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "bold",
    border: "solid",
    borderWidth: "1px",
    padding: "3px",
    borderStyle: "solid",
    whiteSpace: "nowrap"
  }
}))

const pageTitle = "PM Engineer Stock Card"

const PMSparePartStockCard: React.FC = () => {
  const [code, setCode] = useState<string>("")
  const [dateFrom, setDateFrom] = useState<string>("")
  const [dateTo, setDateTo] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const { data: masterSparePart } = useQuery(GET_MASTER_PM_SPARE_PART)
  const { data: stockCard } = useQuery(GET_PM_SPARE_PART_STOCK_CARD, {
    variables: {
      dateFrom,
      dateTo,
      factory
    }
  })

  const dateLength = useMemo(() => {
    const startDate = new Date(dateFrom)
    const endDate = new Date(dateTo)
    const dateArray = []
    while (startDate <= endDate) {
      dateArray.push(startDate.toISOString().split("T")[0])
      startDate.setDate(startDate.getDate() + 1)
    }
    return dateArray
  }, [dateFrom, dateTo])
  const codeList: string[] = useMemo(() => {
    const getCode = stockCard?.getSparePartStockCardByDate
      .map((e: { code: string }) => e.code)
      .filter((item: string) => {
        if (code) return item === code
        return true
      })

    return Array.from(new Set(getCode))
  }, [stockCard?.getSparePartStockCardByDate, code])

  const codeListShow = Array.from(
    new Set(
      masterSparePart?.masterPMSparePart
        .filter((val: { code: string }) => val.code)
        .map((item: { code: string }) => item.code) || []
    )
  )

  return (
    <Box>
      <PageLabel
        menuItem={menuItemList.Engineer}
        menuItemName={Object.keys(menuItemList)[0]}
        menuPageName={pageTitle}
      />
      <ThemedCard sx={{ marginBottom: "10px" }}>
        <div style={{ padding: "8px" }}>
          <PageLabelCard
            title="PM Engineer Spare Part Stock Card"
            subTitle="About detail PM Engineer Spare Part Stock Card"
          />
          <Grid spacing={2} container>
            <Grid item xs={2}>
              <ThemedTextField
                fullWidth
                value={dateFrom}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date From"
                autoComplete="family-name"
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <ThemedTextField
                fullWidth
                value={dateTo}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date To"
                autoComplete="family-name"
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <AutocompleteFactory setFactory={setFactory} factory={factory} />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                fullWidth
                value={code}
                options={codeListShow}
                popupIcon={null}
                onChange={(e, value) => setCode(value as string)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    variant="outlined"
                    label="Code"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                sx={{
                  "@media print": {
                    zoom: "60%"
                  },
                  width: "93vw",
                  height: "70vh"
                }}
              >
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell colSpan={8}>
                        <Box
                          justifyContent={"center"}
                          display={"flex"}
                          justifyItems={"center"}
                          alignItems={"center"}
                        >
                          <img src={miniLogo} alt="" style={{ width: "20%" }} />
                          <h2>Brother Auto Parts & Engineering Company</h2>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={Math.round(dateLength.length / 2)}
                      >
                        <h3>SPARE PART STOCK CARD</h3>
                      </StyledTableCell>
                      <StyledTableCell colSpan={dateLength.length}>
                        <h4>F-ENG-02-15</h4>
                        <br />
                        <h4>Effective date 25/08/20 Rev.01 </h4>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow sx={{ display: dateTo ? "" : "none" }}>
                      <StyledTableCell>No</StyledTableCell>
                      <StyledTableCell>PART NAME</StyledTableCell>
                      <StyledTableCell>CODE</StyledTableCell>
                      <StyledTableCell>MIN</StyledTableCell>
                      <StyledTableCell>RE-ORDER</StyledTableCell>
                      <StyledTableCell>MAX</StyledTableCell>
                      <StyledTableCell>Stock ยกมา</StyledTableCell>
                      <StyledTableCell rowSpan={5}>Operator</StyledTableCell>

                      {dateLength.map((date, index) => (
                        <StyledTableCell key={index}>
                          {date.split("-")[2]}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {codeList.map((item) => {
                      const sparePart = masterSparePart?.masterPMSparePart.find(
                        (part: { code: string }) => part.code === item
                      )
                      return (
                        <>
                          <StyledTableRow>
                            <StyledTableCell rowSpan={5}>
                              {codeList.indexOf(item) + 1}
                            </StyledTableCell>
                            <StyledTableCell rowSpan={5}>
                              {sparePart?.partName || ""}
                            </StyledTableCell>
                            <StyledTableCell rowSpan={5}>
                              {item}
                            </StyledTableCell>
                            <StyledTableCell rowSpan={5}>
                              {sparePart?.min || ""}
                            </StyledTableCell>
                            <StyledTableCell rowSpan={5}>
                              {sparePart?.reOrder || ""}
                            </StyledTableCell>
                            <StyledTableCell rowSpan={5}>
                              {sparePart?.max || ""}
                            </StyledTableCell>
                            <StyledTableCell rowSpan={5}>
                              {
                                stockCard?.getSparePartStockCardByDate[0]
                                  ?.beginStock
                              }
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>{"In"}</StyledTableCell>
                            {stockCard?.getSparePartStockCardByDate
                              .filter((e: { code: string }) => e.code === item)
                              .map((e: { in: number }) => (
                                <StyledTableCell>{e.in}</StyledTableCell>
                              ))}
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>{"Out"}</StyledTableCell>
                            {stockCard?.getSparePartStockCardByDate
                              .filter((e: { code: string }) => e.code === item)
                              .map((e: { out: number }) => (
                                <StyledTableCell>{e.out}</StyledTableCell>
                              ))}
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>{"Balance"}</StyledTableCell>
                            {stockCard?.getSparePartStockCardByDate
                              .filter((e: { code: string }) => e.code === item)
                              .map((e: { balance: number }) => (
                                <StyledTableCell>{e.balance}</StyledTableCell>
                              ))}
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell>{"Remark"}</StyledTableCell>
                            {stockCard?.getSparePartStockCardByDate
                              .filter((e: { code: string }) => e.code === item)
                              .map((e: { balance: number }) => (
                                <StyledTableCell
                                  sx={
                                    e.balance < sparePart?.min
                                      ? { color: "red" }
                                      : e.balance > sparePart?.max
                                      ? { color: "green" }
                                      : {}
                                  }
                                >
                                  {e.balance < sparePart?.min
                                    ? "น้อยกว่า"
                                    : e.balance > sparePart?.max
                                    ? "มากกว่า"
                                    : ""}
                                </StyledTableCell>
                              ))}
                          </StyledTableRow>
                        </>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </ThemedCard>
    </Box>
  )
}
export default PMSparePartStockCard
