import { gql } from "@apollo/client"

export const GET_MASTERPLAN = gql`
  query GetMasterPlan($partNo: String) {
    MasterPlan(partNo: $partNo) {
      partNo
      quantity
      date
      plant
      cycle
    }
  }
`

export const GET_MASTERPLAN_REPORT = gql`
  query MasterPlan(
    $dateFrom: String!
    $dateTo: String!
    $factory: String
    $customer: String
    $partNo: String
    $negativePartF1: Boolean
    $negativePartF2: Boolean
    $negativePartBalActualOOs: Boolean
    $negativePartBalActualPlanOs: Boolean
    $negativePartFollowUp: Boolean
    $plating: Boolean
  ) {
    MasterPlan(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
      customer: $customer
      partNo: $partNo
      negativePartF1: $negativePartF1
      negativePartF2: $negativePartF2
      negativePartBalActualOOs: $negativePartBalActualOOs
      negativePartBalActualPlanOs: $negativePartBalActualPlanOs
      negativePartFollowUp: $negativePartFollowUp
      plating: $plating
    ) {
      partNo
      plant
      customer
      minStock
      maxStock

      model
      partName
      factory
      status
      matStock

      order {
        quantity
        dueDate
        balActual
        actualProduction
        balActual2
        actualProductionOSF1
        balActualProductionOSF1
        balActualPlanOs
        balActualOos
        planOs
        actualProductionFactory2
        balActualFollowUp
        actualProductionFollowUp
        partCounted
      }
      stockBegin {
        partNo
        balActual
        balanceOs
        balanceOOs
        balancePlanOs
        balanceF1
        balanceF2
        quantity
        balanceFollowUp
        balActualProductionOSF1
        date
      }
      fgStock {
        partNo
        quantity
        factory
      }
    }
  }
`

export const GET_QUANTITY_DUE_DATE = gql`
  query Query($partNo: String, $dueDate: String) {
    getDueDate(partNo: $partNo, dueDate: $dueDate)
  }
`
