import { useLazyQuery, useMutation } from "@apollo/client"
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  useTheme
} from "@mui/material"
import swal from "sweetalert"
import { useCallback, useEffect, useState } from "react"
import moment from "moment"
import { GET_MASTERPLAN_REPORT } from "../../common/Query/MasterPlanQuery"
import MPReportToPrint from "./MPReportToPrint"
import { tableCustomerMahle } from "./utilities"
import { UPDATE_FG_STOCK } from "../../common/Mutation/MasterPlanMutation"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import DialogMasterWip from "./mpcomponents/DialogMasterWip"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedCheckbox from "../../common/Resources/ThemedComponents/ThemedCheckbox"
import { useMasterPlan } from "./customHook/useMasterPlan"
import { useMPContext } from "../../context/MPContext"
import { FilterCheckout } from "./mpcomponents/FilterCheckBox"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import AutocompleteCustomer from "../../common/Resources/AutocompleteCustomer"
import { TableContainerMP } from "./mpcomponents/TableContainerMP"
import { DialogCircularProgress } from "../../common/Resources/DialogCircularProgress"
export interface IOrder {
  actualProduction: number
  actualProductionOSF1: number
  balActualProductionOSF1: number
  balActual: number
  dueDate: string
  quantity: number
  planOs: number
  balActualPlanOs: number
  balActualOos: number
  actualProductionFactory2: number
  balActual2: number
  balActualFollowUp: number
  balanceOs?: number
  actualProductionFollowUp: number
  partCounted: number
}

export interface IFGReport {
  partNo: string
  quantity: number
  factory: string
}

export interface IMasterPlanPartData {
  index?: number
  customer: string
  maxStock: number
  minStock: number
  model: string
  factory: string
  order: Array<IOrder>
  status: string
  partNo: string
  partName: string
  plant: Array<string>
  stockBegin: IPartOrder
  matStock: number
  fgStock: IFGReport[]
}

export interface IPartOrder {
  partNo?: string
  balActual?: number
  balanceOs?: number
  balanceOOs?: number
  balancePlanOs?: number
  balanceF1?: number
  balanceF2?: number
  balanceFollowUp?: number
  balActualProductionOSF1?: number
  date?: string
}

const pageTitle = "Masterplan Report"

const MPReport: React.FC = () => {
  const {
    setMaxDueDate,
    orderCheck,
    setOrderCheck,
    factory,
    setFactory,
    setCustomer,
    setSubmitUpdate,
    customer,
    submitUpdate,
    plating,
    followUp,
    negativePartFollowUpF1,
    negativePartF1,
    negativePartF2,
    negativePartFollowUp
  } = useMPContext()
  const [getReport, { data, loading }] = useLazyQuery(GET_MASTERPLAN_REPORT, {
    fetchPolicy: "no-cache"
  })

  const [updateFg] = useMutation(UPDATE_FG_STOCK)
  const [partNo, setPartNo] = useState<string>("")
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000)).format(
      "YYYY-MM-DD"
    )
  )
  const [print, setPrint] = useState(false)
  const [openDialogWip, setOpenDialogWip] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [partDataWip, setPartDataWip] = useState<IMasterPlanPartData>()
  const { maxDueDate, masterPlanPartData } = useMasterPlan(
    data?.MasterPlan,
    negativePartFollowUpF1 ? negativePartFollowUpF1 : negativePartF1,
    negativePartF2,
    negativePartFollowUp,
    dateFrom
  )

  useEffect(() => {
    if (customer === "H-ONE") {
      setFactory("2")
    }
    if (customer === "MAHLE") {
      setFactory("")
    }
  }, [customer])

  useEffect(() => {
    setMaxDueDate(maxDueDate)
  }, [maxDueDate, masterPlanPartData])

  const factoryList: string[] = ["1", "2"]

  const updateFgStock = useCallback(() => {
    setOpenDialog(false)
    const valueOrder: Array<IPartOrder> = []

    if (data) {
      data.MasterPlan.forEach((element: IMasterPlanPartData) => {
        const order: IPartOrder = {
          partNo: element?.partNo,
          balActual:
            factory === "2"
              ? element?.order[element.order.length - 1]?.balActual2
              : element?.order[element.order.length - 1]?.balActual,
          balanceOs: element?.order[element.order.length - 1]?.balanceOs,
          balanceOOs: element?.order[element.order.length - 1]?.balActualOos,
          balancePlanOs:
            element?.order[element.order.length - 1]?.balActualPlanOs,
          balanceF1: element?.order[element.order.length - 1]?.balActual,
          balanceF2: element?.order[element.order.length - 1]?.balActual2,
          balActualProductionOSF1:
            element?.order[element.order.length - 1]?.balActualProductionOSF1,
          balanceFollowUp:
            element?.order[element.order.length - 1]?.balActualFollowUp,
          date: element?.order[element.order.length - 1]?.dueDate
        }
        valueOrder.push(order)
      })
      updateFgApi(valueOrder)
    }
  }, [data, factory])

  const updateFgApi = async (valueOrder: IPartOrder[]) => {
    try {
      await updateFg({
        variables: {
          data: valueOrder
        }
      })
      swal("success", "Update Success!", "success")
    } catch (error) {
      swal("error", "Update Error!", "error")
    }
  }

  const handleSubmit = useCallback(async () => {
    setSubmitUpdate(true)
    await getReport({
      variables: {
        partNo,
        dateFrom,
        dateTo,
        customer,
        negativePartF1: negativePartFollowUpF1
          ? negativePartFollowUpF1
          : negativePartF1,
        negativePartF2,
        negativePartFollowUp,
        plating,
        factory
      },
      fetchPolicy: "network-only"
    })
  }, [
    partNo,
    dateFrom,
    dateTo,
    customer,
    negativePartFollowUpF1,
    negativePartF1,
    negativePartF2,
    negativePartFollowUp,
    plating,
    factory
  ])

  const handlePrint = useCallback(() => {
    setPrint(!print)
  }, [print])

  const theme = useTheme()

  return (
    <div>
      {!print ? (
        <div>
          <Box>
            <PageLabel
              menuItem={menuItemList.MasterPlan}
              menuItemName={Object.keys(menuItemList)[7]}
              menuPageName={pageTitle}
            />
            <ThemedCard>
              <PageLabelCard
                title="Masterplan Report"
                subTitle="view Masterplan report"
              />
              <Grid container width={"98%"} margin={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2}>
                    <AutocompleteCustomer
                      setCustomer={setCustomer}
                      customer={customer}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Autocomplete
                      disabled={
                        tableCustomerMahle(customer) || customer === "H-ONE"
                      }
                      fullWidth
                      disablePortal
                      value={factory}
                      onChange={(event, selectedFactory) => {
                        setFactory(selectedFactory ? selectedFactory : "")
                        setSubmitUpdate(false)
                      }}
                      options={factoryList}
                      renderInput={(params) => (
                        <ThemedTextField {...params} label="Factory" />
                      )}
                    />
                  </Grid>
                  {customer ? (
                    <Grid item xs={12} sm={2}>
                      <AutocompletePartNo
                        setPartNo={setPartNo}
                        partNo={partNo}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={2} sm={2}>
                    <ThemedTextField
                      fullWidth
                      value={dateFrom}
                      id="dateFrom"
                      type="date"
                      autoComplete="family-name"
                      onChange={(e) => {
                        setDateFrom(e.target.value)
                        setSubmitUpdate(false)
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <ThemedTextField
                      fullWidth
                      value={dateTo}
                      id="dateTo"
                      type="date"
                      autoComplete="family-name"
                      onChange={(e) => {
                        setDateTo(e.target.value)
                        setSubmitUpdate(false)
                      }}
                    />
                  </Grid>
                </Grid>
                <FilterCheckout />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ThemedButton
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </ThemedButton>
                    &nbsp; &nbsp;
                    <ThemedButton
                      contrast
                      variant="outlined"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handlePrint}
                      style={{
                        border: "solid 1px"
                      }}
                    >
                      Show Balance
                    </ThemedButton>
                    &nbsp; &nbsp;
                    <ThemedButton
                      contrast
                      disabled={!submitUpdate}
                      variant="outlined"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => setOpenDialog(true)}
                      style={{
                        border: "solid 1px"
                      }}
                    >
                      Update Begin Stock
                    </ThemedButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <ThemedCheckbox
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => setOrderCheck(event.target.checked)}
                        />
                      }
                      label="Order"
                    ></FormControlLabel>
                  </Grid>
                </Grid>
                <TableContainerMP
                  masterPlanPartData={masterPlanPartData}
                  setPartDataWip={setPartDataWip}
                  setOpenDialogWip={setOpenDialogWip}
                />
              </Grid>
            </ThemedCard>
          </Box>
          {openDialogWip ? (
            <DialogMasterWip
              dialogOpen={openDialogWip}
              setDialogOpen={setOpenDialogWip}
              partData={partDataWip}
            />
          ) : (
            <></>
          )}
          <div>
            <DialogCircularProgress loading={loading} />
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
              <DialogTitle>{"Confirm Update Begin Stock?"}</DialogTitle>
              <DialogActions
                style={{ justifyContent: "flex-start", marginLeft: "24px" }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: theme.palette.primary.main }}
                  onClick={() => updateFgStock()}
                >
                  Agree
                </Button>
                <Button
                  variant="text"
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => setOpenDialog(false)}
                >
                  Disagree
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      ) : (
        <div>
          <ThemedButton onClick={handlePrint} sx={{ backgroundColor: "red" }}>
            Return
          </ThemedButton>
          <MPReportToPrint
            customer={customer}
            maxDueDate={maxDueDate}
            masterPlan={masterPlanPartData}
            factory={factory}
            plating={plating}
            followUp={followUp}
            showOrder={orderCheck}
            showDeficitFollowup={negativePartFollowUp}
            showFollowUp={followUp}
          ></MPReportToPrint>
        </div>
      )}
    </div>
  )
}
export default MPReport
