import React, { Dispatch, SetStateAction, useState } from "react"
import {
  Grid,
  Autocomplete,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  useTheme,
  FormControlLabel,
  Checkbox,
  ThemeProvider
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import PrintIcon from "@mui/icons-material/Print"
import { styled } from "@mui/material/styles"
import CircularProgress from "@mui/material/CircularProgress"
import moment from "moment"
import { DatePicker, LocalizationProvider } from "@mui/lab"
import {
  GET_LOGISTICS_REPORT,
  GET_LOGISTICS_REPORT_CUSTOMERS,
  GET_LOGISTICS_REPORT_CAR_LICENSE
} from "../../common/Query/LogisticsQuery"
import {
  LazyQueryResult,
  QueryLazyOptions,
  useLazyQuery,
  useQuery
} from "@apollo/client"
import DateAdapter from "@mui/lab/AdapterMoment"
import "../../css/Logistics/LogisticsCSS.css"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import { getImagePng, logoTable } from "../../common/utilities"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"

export interface LogisticsCostReportQueryArgument {
  dateFrom: string
  dateTo: string
  carRegistration: string
  customer: string
}

export interface ISearchLogisticsCostReport {
  startDate: Date
  endDate: Date
  carLicense: string
  deliveryCustomer: string
  premiumFreight: boolean
  setStartDate: Dispatch<SetStateAction<Date>>
  setEndDate: Dispatch<SetStateAction<Date>>
  setCarLicense: Dispatch<SetStateAction<string>>
  setDeliveryCustomer: Dispatch<SetStateAction<string>>
  setPremiumFreight: Dispatch<SetStateAction<boolean>>
  getLogisticsReport: (
    options?:
      | QueryLazyOptions<{
          dateFrom: string
          dateTo: string
          carRegistration: string
          customer: string
          premiumFreight: boolean
        }>
      | undefined
  ) => Promise<
    LazyQueryResult<
      unknown,
      {
        dateFrom: string
        dateTo: string
        carRegistration: string
        customer: string
        premiumFreight: boolean
      }
    >
  >
}

interface CompanyCustomerObjectType {
  companyName: string
}

export interface LogisticsReportCustomer {
  data: {
    companycustom: CompanyCustomerObjectType[]
  }
}

interface CarLicenseObjectType {
  carLicense: string
}

export interface LogisticsReportCarLicense {
  data: {
    carLicense: CarLicenseObjectType[]
  }
}

export interface ICustomerCostSummaryProps {
  sumCustomer: CustomerSummary[]
}

export interface CustomerSummary {
  customer: string
  incomeCost: number
  transportCost: number
  percentTransportCost: number
  target: string
}

export interface EntryReport {
  deliveryDate: Date
  fromOrigin: string
  companyCustom: string
  poCustomer: string
  invoiceNo: string
  amountSales: number
  carPerWay: number
  cost: number
  drop: number
  advance: number
  premiumFreight: number
  transportCost: number
  userRecord: string
  carLicense: string
  remarks: string
}

interface EntryQueryResultType {
  deliveryDate: string
  fromOrigin: string
  companyCustom: string
  poCustomer: string
  invoiceNo: string
  amountSales: number
  carPerWay: number
  cost: number
  drop: number
  advance: number
  premiumFreight: number
  transportCost: number
  userRecord: string
  carLicense: string
  remarks: string
}

export interface ILogisticsCostReportBodyProps {
  reportEntry: EntryReport[]
}

export interface LogisticsCostReportQueryResult {
  data: {
    logisticReport: {
      total: {
        sales: number
        transport: number
      }
      entry: EntryQueryResultType[]
      sumCustomer: CustomerSummary[]
    }
  }
}

const StyledTableCell = styled(TableCell)(() => ({
  color: "black",
  backgroundColor: "white",
  textAlign: "center",
  borderBottom: "1px",
  padding: 0,
  fontSize: "22px",
  border: "solid 1px black"
}))

const StyledTableRow = styled(TableRow)(() => ({
  borderBottom: "1px",
  "&:last-child td": { borderBottom: "1px solid black" }
}))

const pageTitle = "Logistics Cost Report"

export const SearchCostReport: React.FC<ISearchLogisticsCostReport> = (
  props: ISearchLogisticsCostReport
) => {
  const {
    startDate,
    endDate,
    carLicense,
    deliveryCustomer,
    setStartDate,
    setEndDate,
    setCarLicense,
    setDeliveryCustomer,
    getLogisticsReport,
    setPremiumFreight,
    premiumFreight
  } = props
  const { data: customerData, loading: customerLoading } = useQuery(
    GET_LOGISTICS_REPORT_CUSTOMERS
  )
  const { data: carLicenseData, loading: carLicenseLoading } = useQuery(
    GET_LOGISTICS_REPORT_CAR_LICENSE
  )

  const deliveryCustomerList =
    !customerLoading && customerData
      ? customerData.companycustom.map(
          ({ companyName }: { companyName: string }) => companyName
        )
      : [""]
  const carLicenseList =
    !carLicenseLoading && carLicenseData
      ? carLicenseData.carLicense.map(
          ({ carLicense }: { carLicense: string }) => carLicense
        )
      : [""]

  const handleSearch = () => {
    getLogisticsReport()
  }
  const onPrint = () => {
    window.print()
  }

  const theme = useTheme()

  return (
    <Grid container spacing={2} width="98%" margin={2} paddingRight={2}>
      <Grid item xs={12} sm={2}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DatePicker
            openTo="day"
            views={["year", "month", "day"]}
            inputFormat="DD/MM/YYYY"
            value={startDate}
            onChange={(newStartDate) => {
              setStartDate(moment(newStartDate).toDate() as Date)
            }}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                fullWidth
                label="Date From"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={2}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DatePicker
            openTo="day"
            views={["year", "month", "day"]}
            inputFormat="DD/MM/YYYY"
            value={endDate}
            onChange={(newEndDate) => {
              setEndDate(moment(newEndDate).toDate() as Date)
            }}
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                fullWidth
                label="Date To"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Autocomplete
          fullWidth
          value={carLicense}
          disablePortal
          onChange={(event, selectedCarLicense) => {
            setCarLicense(selectedCarLicense as string)
          }}
          options={carLicenseList}
          renderInput={(params) => (
            <ThemedTextField
              {...params}
              label="ทะเบียนรถบรรทุก"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Autocomplete
          fullWidth
          value={deliveryCustomer}
          disablePortal
          onChange={(event, selectedDeliveryCustomer) => {
            setDeliveryCustomer(selectedDeliveryCustomer as string)
          }}
          options={deliveryCustomerList}
          renderInput={(params) => (
            <ThemedTextField
              {...params}
              label="Customer"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPremiumFreight(event.target.checked)
              }
            />
          }
          value={premiumFreight}
          label="	Premium Freight"
        />
      </Grid>
      <Grid item xs={12} sm={2} />

      <Grid item xs={4} sm={4} container>
        <ThemedLoadingButton
          loading={carLicenseLoading}
          style={{ backgroundColor: theme.palette.primary.main }}
          variant="contained"
          onClick={handleSearch}
          endIcon={<SearchIcon />}
        >
          ค้นหา
        </ThemedLoadingButton>
        <ThemedButton
          variant="outlined"
          endIcon={<PrintIcon />}
          style={{
            color: theme.palette.secondary.contrastText,
            marginLeft: "15px",
            borderColor: theme.palette.primary.main
          }}
          onClick={() => onPrint()}
        >
          Print
        </ThemedButton>
      </Grid>
    </Grid>
  )
}

export const LogisticsCostReportBody: React.FC<
  ILogisticsCostReportBodyProps
> = (props: ILogisticsCostReportBodyProps) => {
  const { reportEntry } = props
  return (
    <>
      {reportEntry.map((entry, index) => {
        return (
          <StyledTableRow key={index} className="logistics-cost-report-table">
            <StyledTableCell>{entry.deliveryDate}</StyledTableCell>
            <StyledTableCell>{entry.fromOrigin}</StyledTableCell>
            <StyledTableCell>{entry.companyCustom}</StyledTableCell>
            <StyledTableCell>
              <p style={{ wordBreak: "break-all", width: "140px" }}>
                {entry.poCustomer}
              </p>
            </StyledTableCell>
            <StyledTableCell>
              <p style={{ wordBreak: "break-all", width: "140px" }}>
                {entry.invoiceNo}
              </p>
            </StyledTableCell>
            <StyledTableCell>{entry.amountSales}</StyledTableCell>
            <StyledTableCell>{entry.carPerWay}</StyledTableCell>
            <StyledTableCell>{entry.cost}</StyledTableCell>
            <StyledTableCell>{entry.drop}</StyledTableCell>
            <StyledTableCell>{entry.advance}</StyledTableCell>
            <StyledTableCell>{entry.premiumFreight}</StyledTableCell>
            <StyledTableCell>{entry.transportCost}</StyledTableCell>
            <StyledTableCell>{entry.carLicense}</StyledTableCell>
            <StyledTableCell>{entry.userRecord}</StyledTableCell>
            <StyledTableCell>
              <p style={{ whiteSpace: "nowrap" }}>{entry.remarks}</p>
            </StyledTableCell>
          </StyledTableRow>
        )
      })}
    </>
  )
}

export const CustomerCostSummary: React.FC<ICustomerCostSummaryProps> = (
  props: ICustomerCostSummaryProps
) => {
  const { sumCustomer } = props
  const customerCostSummaryBody = sumCustomer.map((customerSummary) => {
    return (
      <StyledTableRow
        key={customerSummary.customer}
        className="logistics-cost-report-customer-summary-table"
      >
        <StyledTableCell>{customerSummary.customer}</StyledTableCell>
        <StyledTableCell>{customerSummary.incomeCost}</StyledTableCell>
        <StyledTableCell>{customerSummary.transportCost}</StyledTableCell>
        <StyledTableCell>
          {customerSummary.percentTransportCost}
        </StyledTableCell>
        <StyledTableCell>{customerSummary.target}</StyledTableCell>
      </StyledTableRow>
    )
  })

  return (
    <div
      style={{
        marginBottom: "20px",
        overflow: "auto"
      }}
    >
      <Table
        style={{
          border: "1px solid black"
        }}
      >
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Customer</StyledTableCell>
            <StyledTableCell>Income Cost</StyledTableCell>
            <StyledTableCell>Transport Cost</StyledTableCell>
            <StyledTableCell>% Transport Cost</StyledTableCell>
            <StyledTableCell>Target 3%</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>{customerCostSummaryBody}</TableBody>
      </Table>
    </div>
  )
}

export const LogisticsCostReportSignature: React.FC = () => {
  //change 50vw style later
  return (
    <div style={{ maxWidth: "50vw" }}>
      <Table>
        <TableHead>
          <StyledTableRow style={{ height: "70px" }}>
            <StyledTableCell>วรารัตน์</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>ISSUER</StyledTableCell>
            <StyledTableCell>CHECKED</StyledTableCell>
            <StyledTableCell>APPROVED</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </div>
  )
}

export const LogisticsCostReport = (): JSX.Element => {
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [carLicense, setCarLicense] = useState<string>("")
  const [deliveryCustomer, setDeliveryCustomer] = useState<string>("")
  const [premiumFreight, setPremiumFreight] = useState<boolean>(false)
  const [getLogisticsReport, { data, loading, called }] = useLazyQuery(
    GET_LOGISTICS_REPORT,
    {
      variables: {
        dateFrom: moment(startDate).format("YYYY-MM-DD"),
        dateTo: moment(endDate).format("YYYY-MM-DD"),
        carRegistration: carLicense || "",
        customer: deliveryCustomer,
        premiumFreight: premiumFreight
      }
    }
  )
  const logoCar = getImagePng("images/truck_icon")

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Logistics}
        menuItemName={Object.keys(menuItemList)[8]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="Logistics Cost Report"
          subTitle="detail about Logistics Cost Report"
        />
        <div className="label_navbar">
          <SearchCostReport
            startDate={startDate}
            endDate={endDate}
            carLicense={carLicense}
            deliveryCustomer={deliveryCustomer}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setCarLicense={setCarLicense}
            setDeliveryCustomer={setDeliveryCustomer}
            getLogisticsReport={getLogisticsReport}
            setPremiumFreight={setPremiumFreight}
            premiumFreight={premiumFreight}
          />
        </div>
      </ThemedCard>
      <ThemedCard style={{ marginTop: "25px", padding: "15px" }}>
        <div className="transport-report-to-print">
          <TableContainer style={{ marginTop: "20px" }}>
            <Table aria-label="customized table">
              <TableHead style={{ minWidth: "10px" }}>
                <StyledTableRow>
                  <StyledTableCell colSpan={3} width={"400px"}>
                    <img src={logoTable} alt="" style={{ width: "400px" }} />
                  </StyledTableCell>
                  <StyledTableCell colSpan={11}>
                    <span
                      style={{
                        fontSize: "35px",
                        marginRight: "15px",
                        fontWeight: "bold",
                        fontFamily: "emoji"
                      }}
                    >
                      Logistics Cost Report
                    </span>
                    <img
                      src={logoCar}
                      alt=""
                      width="100vh"
                      style={{ verticalAlign: "bottom" }}
                    />
                  </StyledTableCell>
                  <StyledTableCell colSpan={2}>
                    ทะเบียนรถ
                    <br />
                    {carLicense}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>วัน/เดือน/ปี</StyledTableCell>
                  <StyledTableCell>ต้นทาง</StyledTableCell>
                  <StyledTableCell>ปลายทาง</StyledTableCell>
                  <StyledTableCell>PO.Customer</StyledTableCell>
                  <StyledTableCell>Invoice No.</StyledTableCell>
                  <StyledTableCell>ยอดขาย(บาท)</StyledTableCell>
                  <StyledTableCell>จำนวนเที่ยว</StyledTableCell>
                  <StyledTableCell>เที่ยวปกติ</StyledTableCell>
                  <StyledTableCell>ราคา Drop</StyledTableCell>
                  <StyledTableCell>Advance</StyledTableCell>
                  <StyledTableCell>Premium Freight</StyledTableCell>
                  <StyledTableCell>Transport Cost</StyledTableCell>
                  <StyledTableCell>ทะเบียนรถ</StyledTableCell>
                  <StyledTableCell>ผู้บันทึก</StyledTableCell>
                  <StyledTableCell>หมายเหตุ</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {called && loading ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={15}>
                      <CircularProgress />
                    </StyledTableCell>
                  </StyledTableRow>
                ) : called && !loading && data ? (
                  <LogisticsCostReportBody
                    reportEntry={
                      called && !loading && data
                        ? data.logisticReport.entry
                        : []
                    }
                  />
                ) : (
                  <></>
                )}
                <StyledTableRow>
                  <StyledTableCell colSpan={5} style={{ textAlign: "right" }}>
                    Total Sales
                  </StyledTableCell>
                  <StyledTableCell colSpan={1} style={{ textAlign: "left" }}>
                    {`฿ ${
                      called && !loading && data
                        ? Math.round(data.logisticReport.total.sales * 100) /
                          100
                        : 0
                    }`}
                  </StyledTableCell>
                  <StyledTableCell colSpan={5} style={{ textAlign: "right" }}>
                    Total Transport Cost
                  </StyledTableCell>
                  <StyledTableCell colSpan={1} style={{ textAlign: "left" }}>
                    {`฿ ${
                      called && !loading && data
                        ? Math.round(
                            data.logisticReport.total.transport * 100
                          ) / 100
                        : 0
                    }`}
                  </StyledTableCell>
                  <StyledTableCell colSpan={3} />
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ margin: "20px 0px" }} />
          <CustomerCostSummary
            sumCustomer={
              called && !loading && data ? data.logisticReport.sumCustomer : []
            }
          />
          <LogisticsCostReportSignature />
        </div>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default LogisticsCostReport
