import { DatePicker, LocalizationProvider } from "@mui/lab"
import {
  Autocomplete,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow
} from "@mui/material"
import DateAdapter from "@mui/lab/AdapterMoment"
import { useState } from "react"
import moment from "moment"
import { printDiv } from "../PMReport"
import { monthNames } from "../PreventiveMaintenancePlan"
import { factoryList, miniLogo } from "../../../common/utilities"
import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined"
import Brightness1Icon from "@mui/icons-material/Brightness1"
import { useQuery } from "@apollo/client"
import { GET_PM_PLAN_YEAR } from "../../../common/Query/MaintenanceQuery"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"

interface IDatePm {
  actionDate: string
  status: boolean
  percent: string
}

interface IReportPMYear {
  machine: string
  code: string
  machineRemark: string
  frequency: string
  line: string
  actionDate: [IDatePm]
}

interface IProps {
  status: boolean
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 25,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
    fontFamily: "Sarabun"
  }
}))

const StyledTableCellApprove = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    textAlign: "center",
    fontSize: 20,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "td, th": {
    border: "1px solid black"
  }
}))

const PmPlanYear = (props: IProps): JSX.Element => {
  const [year, setYear] = useState<string>(moment(new Date()).format("yyyy"))
  const [factory, setFactory] = useState<string>("1")

  const getPmPlanYear = useQuery(GET_PM_PLAN_YEAR, {
    variables: {
      year,
      factory,
      outsource: props.status
    },
    fetchPolicy: "network-only"
  })

  const getPmPlanYearList: IReportPMYear[] =
    getPmPlanYear?.data?.pmYearReport || []

  const checkMonth = (index: number, dateArr: IDatePm[]) => {
    const getMonth = dateArr.map((date) => {
      return {
        date: new Date(date.actionDate).getMonth(),
        status: date.status
      }
    })

    const month = getMonth.find((value) => value.date === index)
    const monthStatus = getMonth.find(
      (value) => value.date === index && value.status
    )

    if (month && monthStatus) return 1
    if (month && !month.status) return 2
    return 3
  }

  return (
    <>
      <Grid container spacing={2} width={"98%"} padding={2}>
        <Grid item sm={2}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              label="เลือกปี"
              views={["year"]}
              value={year}
              onChange={(newSelectDate) => {
                setYear(moment(newSelectDate).format("yyyy"))
              }}
              renderInput={(params) => (
                <ThemedTextField {...params} fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Autocomplete
            fullWidth
            disablePortal
            value={factory}
            renderInput={(params) => (
              <ThemedTextField {...params} label="Select Factory" />
            )}
            onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
            options={factoryList}
          />
        </Grid>
        <Grid item sm={2}>
          <ThemedButton
            variant="contained"
            sx={{ ml: 2, mt: 1, mb: 2, backgroundColor: "yellowgreen" }}
            onClick={() => printDiv("pmYearOs")}
          >
            Print
          </ThemedButton>
        </Grid>
      </Grid>
      <div id="pmYearOs">
        <Table style={{ zoom: "80%" }} className="print-sm-order">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell colSpan={5}>
                <img src={miniLogo} alt="" width={"100px"} />
              </StyledTableCell>
              <StyledTableCell colSpan={20}>
                Preventive Maintenance Plan ประจำปี {year}
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }} colSpan={4}>
                Doc. No. <br />
                F-MT-01-01 <br />
                <span style={{ fontSize: "14px" }}>
                  Effective Date : 30/06/22 Isue: R00
                </span>
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell rowSpan={1} colSpan={9}>
                Plan
                <Brightness1OutlinedIcon sx={{ mt: 2, ml: 2 }} />
                <span style={{ marginLeft: "10px" }}>
                  Actual <Brightness1Icon sx={{ mt: 2, ml: 1 }} />
                </span>
              </StyledTableCell>
              <StyledTableCell rowSpan={2} colSpan={2}>
                Priority
              </StyledTableCell>
              <StyledTableCell rowSpan={2} colSpan={2}>
                Section
              </StyledTableCell>
              <StyledTableCell colSpan={12}>Month</StyledTableCell>
              <StyledTableCell rowSpan={2}>Frequency</StyledTableCell>
              <StyledTableCell rowSpan={2}>Remarks</StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell rowSpan={1} colSpan={1}>
                No.
              </StyledTableCell>
              <StyledTableCell rowSpan={1} colSpan={4}>
                Machine Name
              </StyledTableCell>
              <StyledTableCell rowSpan={1} colSpan={4}>
                Machine Code
              </StyledTableCell>
              {Array.from(Array(12), (num, i) => {
                return (
                  <StyledTableCell key={monthNames[i]}>
                    {monthNames[i]}
                  </StyledTableCell>
                )
              })}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {getPmPlanYearList.map((value, index: number) => {
              return (
                <StyledTableRow key={index + value.machine}>
                  <StyledTableCell colSpan={1}>{index + 1}</StyledTableCell>
                  <StyledTableCell colSpan={4}>{value.machine}</StyledTableCell>
                  <StyledTableCell colSpan={4}>{value?.code}</StyledTableCell>
                  <StyledTableCell colSpan={2}>
                    {value?.machineRemark}
                  </StyledTableCell>
                  <StyledTableCell colSpan={2}>{value?.line}</StyledTableCell>
                  {Array.from(Array(12), (num, i) => {
                    return (
                      <StyledTableCell
                        key={i + "1" + i}
                        sx={{ textAlign: "center" }}
                      >
                        {checkMonth(i, value.actionDate) === 1 ? (
                          <Brightness1Icon />
                        ) : checkMonth(i, value.actionDate) === 2 ? (
                          <Brightness1OutlinedIcon />
                        ) : (
                          <></>
                        )}
                      </StyledTableCell>
                    )
                  })}
                  <StyledTableCell>{value?.frequency}</StyledTableCell>
                  <StyledTableCell />
                </StyledTableRow>
              )
            })}
            <StyledTableRow>
              <StyledTableCellApprove
                rowSpan={3}
                colSpan={14}
              ></StyledTableCellApprove>
              <StyledTableCellApprove colSpan={5}>
                Reported
              </StyledTableCellApprove>
              <StyledTableCellApprove colSpan={5}>
                Checked
              </StyledTableCellApprove>
              <StyledTableCellApprove colSpan={8}>
                Approved
              </StyledTableCellApprove>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCellApprove colSpan={5} sx={{ height: "50px" }} />
              <StyledTableCellApprove colSpan={5} sx={{ height: "50px" }} />
              <StyledTableCellApprove colSpan={8} sx={{ height: "50px" }} />
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCellApprove colSpan={5}>
                หัวหน้าฝ่ายซ่อมบำรุง
              </StyledTableCellApprove>
              <StyledTableCellApprove colSpan={5}>
                ผู้จัดการฝ่ายซ่อมบำรุง
              </StyledTableCellApprove>
              <StyledTableCellApprove colSpan={8}>
                ผู้จัดการโรงงาน
              </StyledTableCellApprove>
            </StyledTableRow>
          </TableBody>
        </Table>
      </div>
    </>
  )
}

export default PmPlanYear
