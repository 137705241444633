import { useLazyQuery, useMutation } from "@apollo/client"
import {
  Autocomplete,
  Box,
  CardHeader,
  Grid,
  ThemeProvider,
  useTheme
} from "@mui/material"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { GET_PRODUCTION_PLAN_BY_REVISION } from "../../common/Query/PlanningQuery"
import "../../css/Planning/ProductionPlan.css"
import { Print, Edit, Add, ArrowBack } from "@mui/icons-material"
import swal from "sweetalert"
import { ADD_PRODUCTION_PLAN } from "../../common/Mutation/PlanningMutation"
import AddTableRow from "./component/AddTableRow"
import { calculateTime } from "./ProductionPlan"
import { PageLabel } from "../../common/Resources/PageLabel"
import { user } from "../../common/MainApp"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import { menuItemList } from "../../common/Resources/menuItemList"
import { clearParam } from "../../common/utilities"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import AutocompleteLine from "../../common/Resources/AutocompleteLine"
import CustomTextFieldTypeDate from "../../common/Resources/CustomTextFieldTypeDate"
import AutocompleteFactory from "../../common/Resources/AutocompleteFactory"
import { useRevisionList } from "./customHook/useRevisionList"
import { DialogCircularProgress } from "../../common/Resources/DialogCircularProgress"
import { DeleteConfirmDialog } from "../../common/Resources/DialogConfirmDelete"
import { ApproveProductionPlan } from "./component/TableApproveProductionPlan/ApproveProductionPlan"
import { useDataProductionPlan } from "./customHook/useDataProductionPlan"
import { useDiffHours } from "./customHook/useDifHours"
import { TableProductionPlan } from "./TableProductionPlan"
import { TChangeEvent } from "./component/EditTableRow"

export interface DataProductionPlan {
  id: number
  planNo?: string
  revision?: string
  planDate?: string
  pdPlan?: string
  factory?: string
  shift?: string
  partNo: string
  partName: string
  customer: string
  matLotNo?: string
  componentPartLotNo?: string
  pdLotNo?: string
  line: string
  section?: string
  step: string
  process: string
  machine: string
  jph: number
  startTime: string | null
  endTime: string | null
  startTimeOT: string | null
  endTimeOT: string | null
  totalHour: string
  totalQuantity: number
  totalNGProcess?: number
  totalNGSetUp?: number
  employee?: string
  plApprove?: string
  pdApprove?: string
  engApprove?: string
  qaApprove?: string
  mtApprove?: string
  remarks?: string
  totalActual?: string
  cycleTime?: string
  totalPlan?: string
  totalOK?: string
  totalNG?: string
  totalStart?: string
  totalEnd?: string
  operator?: string
  lostTime?: string
  status?: number
  quantity?: number
  iNG?: number
  sNG?: number
  productionWorker?: string
}

export interface IDataEvent {
  name: string
  value: string
  partName: string
  process: string
  totalQuantity: number
  totalHour: number
}

type TData = {
  [key: string]: string | number | null
  id: number
  partNo: string
  partName: string
  customer: string
  step: string
  process: string
  machine: string
  jph: number
  startTime: string | null
  endTime: string | null
  startTimeOT: string | null
  endTimeOT: string | null
  totalHour: string
  totalQuantity: number
  line: string
}

const pageTitle = "Production Plan Report"

const calculateTotalRealTime = (
  startWorkingTime: string,
  endWorkingTime: string,
  startWorkingTimeOT: string,
  endWorkingTimeOT: string
) => {
  const startTime = Number(calculateTime(startWorkingTime))
  const endTime = Number(calculateTime(endWorkingTime))
  const startTimeOT = Number(calculateTime(startWorkingTimeOT))
  const endTimeOT = Number(calculateTime(endWorkingTimeOT))
  const totalTimeOT = endTimeOT - startTimeOT

  if (endTime > 12 && startTime < 13) {
    const totalTime =
      endTime - startTime < 0
        ? endTime - startTime + 23
        : endTime - startTime - 1
    if (totalTimeOT) return totalTime + totalTimeOT
    else return totalTime
  } else {
    const totalTime =
      endTime - startTime < 0 ? endTime - startTime + 24 : endTime - startTime
    if (totalTimeOT) return totalTime + totalTimeOT
    else return totalTime
  }
}

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const dateParam = urlParams.get("date")
const lineParam = urlParams.get("line")
const factoryParam = urlParams.get("factory")
const revisionParam = urlParams.get("revision")
const shiftParam = urlParams.get("shift")

const ProductionReport: React.FC = () => {
  const theme = useTheme()
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [line, setLine] = useState<string>("")
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(false)
  const [addMachine, setAddMachine] = useState<boolean>(false)
  const [deleteMachine, setDeleteMachine] = useState<boolean>(false)
  const [addPlans, setAddPlans] = useState<boolean>(false)

  const [editPlan, setEditPlan] = useState<number>(0)
  const [deletePlanId, setDeletePlanId] = useState<DataProductionPlan>()
  const [factory, setFactory] = useState<string>("")
  const [revision, setRevision] = useState<string>("")
  const [shift, setShift] = useState<string>("")
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [productionPlanData, setProductionPlanData] = useState<
    DataProductionPlan[][]
  >([])
  const [AddProductionPlan] = useMutation(ADD_PRODUCTION_PLAN)
  const [error, setError] = useState<boolean>(false)
  const [submitUpdate, setSubmitUpdate] = useState<boolean>(false)
  const [status, setStatus] = useState<boolean>(false)
  const [editFormData, setEditFormData] = useState<DataProductionPlan>({
    id: 0,
    machine: "",
    customer: "",
    partNo: "",
    partName: "",
    step: "",
    process: "",
    startTime: "",
    endTime: "",
    startTimeOT: "",
    endTimeOT: "",
    totalHour: "",
    jph: 0,
    line: "",
    totalQuantity: 0
  })

  const [getDataProductionPlan, { data, loading }] = useLazyQuery(
    GET_PRODUCTION_PLAN_BY_REVISION
  )

  const { revisionList, loadRevision, lastRevision } = useRevisionList(
    date,
    line,
    factory,
    shift
  )
  const { masterPlan, backupPlan, totalQuantity, totalQuantityBackup } =
    useDataProductionPlan(productionPlanData)

  const onSubmit = useCallback(async () => {
    if (!date || !line || !factory || !shift || !revision) {
      setError(true)
      swal("ERROR", "กรุณากรอกข้แมูลให้ครบถ้วน", "error")
      return
    }
    setSubmitUpdate(true)
    const { data: productionPlan } = await getDataProductionPlan({
      variables: {
        date: date,
        line: line,
        factory: factory,
        shift: shift,
        revision: revision
      },
      fetchPolicy: "network-only"
    })
    if (revision !== "Rev00") {
      const curRevision = revision
      const data = (Number(curRevision?.split("Rev")[1]) - 1).toString()
      const preRevision =
        curRevision?.substring(0, curRevision?.length - data.length) + data
      const prevDataProduction = await getDataProductionPlan({
        variables: {
          date: date,
          line: line,
          factory: factory,
          shift: shift,
          revision: preRevision
        },
        fetchPolicy: "network-only"
      })

      productionPlan.productionPlanReportByRevision[0] =
        productionPlan.productionPlanReportByRevision[0].map(
          (e: DataProductionPlan) => {
            const checkEditBaseRevision =
              prevDataProduction.data.productionPlanReportByRevision[0].find(
                (base: DataProductionPlan) => {
                  return (
                    base.machine === e.machine &&
                    base.partNo === e.partNo &&
                    base.startTime === e.startTime &&
                    base.endTime === e.endTime &&
                    base.startTimeOT === e.startTimeOT &&
                    base.endTimeOT === e.endTimeOT &&
                    base.jph === e.jph
                  )
                }
              )
            if (!checkEditBaseRevision) {
              return {
                ...e,
                totalHour: Number(e.totalHour).toFixed(2),
                fieldEdit: true
              }
            } else
              return {
                ...e,
                totalHour: Number(e.totalHour).toFixed(2),
                fieldEdit: false
              }
          }
        )
      setProductionPlanData(
        productionPlan?.productionPlanReportByRevision ?? []
      )
    } else {
      setProductionPlanData(
        productionPlan?.productionPlanReportByRevision ?? []
      )
    }

    const approved = productionPlan?.productionPlanReportByRevision[0]?.filter(
      (e: { status: boolean }) => !e?.status
    )

    if (approved?.length > 0) {
      setStatus(true)
    }
  }, [date, factory, getDataProductionPlan, line, revision, shift])

  useEffect(() => {
    if (addPlans) {
      onSubmit()
    }
  }, [addPlans, onSubmit])
  const handleEditTable = (
    even: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: DataProductionPlan
  ) => {
    even.preventDefault()

    setEditPlan(row.id)
    const formValues = {
      ...row
    }
    setEditFormData(formValues)
  }
  const handleDeleteTable = (
    even: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: DataProductionPlan
  ) => {
    even.preventDefault()
    setOpenDelete(true)
    setDeletePlanId(row)
  }
  const submitDeletePlan = () => {
    setOpenDelete(false)

    if (deletePlanId?.pdPlan === "แผนหลัก") {
      const newPdData = productionPlanData[0].filter(
        (e) => e.id !== deletePlanId.id
      )
      setProductionPlanData(
        productionPlanData[1] ? [newPdData, productionPlanData[1]] : [newPdData]
      )
    } else {
      const newPdData = productionPlanData[1].filter(
        (e) => e.id !== deletePlanId?.id
      )
      setProductionPlanData(
        newPdData ? [productionPlanData[0], newPdData] : [productionPlanData[0]]
      )
    }
  }

  const handleEditFromData = (event: TChangeEvent) => {
    const newFormData: TData = { ...editFormData }
    const fieldName = event.target.name
    const fieldValue = event.target.value
    newFormData[fieldName] = fieldValue

    newFormData.partName = event.target.partName || editFormData.partName
    newFormData.process = event.target.process || editFormData.process
    newFormData.totalQuantity =
      event.target.totalQuantity || editFormData.totalQuantity
    setEditFormData(newFormData)
  }

  const handleEditFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const newTotalHour = calculateTotalRealTime(
      editFormData.startTime ?? "00:00",
      editFormData.endTime ?? "00:00",
      editFormData.startTimeOT ?? "00:00",
      editFormData.endTimeOT ?? "00:00"
    )

    const editedContact = {
      id: editPlan,
      employee: editFormData.employee,
      machine: editFormData.machine,
      customer: editFormData.customer,
      partNo: editFormData.partNo,
      partName: editFormData.partName,
      step: editFormData.step,
      process: editFormData.process,
      startTime: editFormData.startTime,
      endTime: editFormData.endTime,
      startTimeOT: editFormData.startTimeOT,
      endTimeOT: editFormData.endTimeOT,
      totalHour: newTotalHour.toString(),
      jph: editFormData.jph,
      planNo: editFormData.planNo,
      revision: editFormData.revision,
      planDate: editFormData.planDate,
      pdPlan: editFormData.pdPlan,
      factory: editFormData.factory,
      shift: editFormData.shift,
      line: editFormData.line,
      section: editFormData.section,
      totalQuantity: Math.ceil(newTotalHour * editFormData.jph),
      plApprove: editFormData.plApprove,
      pdApprove: editFormData.pdApprove,
      engApprove: editFormData.engApprove,
      qaApprove: editFormData.qaApprove,
      mtApprove: editFormData.mtApprove,
      remarks: editFormData.remarks,
      status: 0
    }
    const newContacts = [...productionPlanData]
    let index1 = -1
    let index2 = -1
    index1 = productionPlanData[0].findIndex(
      (plan: { id: number }) => plan.id === editPlan
    )
    if (productionPlanData.length > 1 && productionPlanData[1]?.length > 0) {
      index2 = productionPlanData[1].findIndex(
        (plan: { id: number }) => plan.id === editPlan
      )
    }
    if (index1 >= 0) {
      newContacts[0][index1] = editedContact
    }
    if (index2 >= 0) {
      newContacts[1][index2] = editedContact
    }

    setProductionPlanData(newContacts)
    setEditPlan(0)
  }

  useMemo(async () => {
    if (factoryParam && dateParam && lineParam && revisionParam && shiftParam) {
      setDate(moment(new Date(dateParam)).format("YYYY-MM-DD"))
      setFactory(factoryParam)
      setLine(lineParam)
      setRevision(revisionParam)
      setShift(shiftParam)
      const dataProductionPlan = await getDataProductionPlan({
        variables: {
          date: moment(new Date(dateParam)).format("YYYY-MM-DD"),
          line: lineParam,
          factory: factoryParam,
          revision: revisionParam,
          shift: shiftParam
        },
        fetchPolicy: "network-only"
      })
      const status =
        dataProductionPlan.data.productionPlanReportByRevision[0]?.filter(
          (e: { status: boolean }) => !e.status
        )
      if (status?.length > 0) {
        setStatus(true)
      }

      if (revisionParam !== "Rev00") {
        const curRevision = revisionParam
        const rev = (Number(curRevision?.split("Rev")[1]) - 1).toString()
        const preRevision =
          curRevision?.substring(0, curRevision?.length - rev.length) + rev
        const rawPrevDataProduction = await getDataProductionPlan({
          variables: {
            date: moment(new Date(dateParam)).format("YYYY-MM-DD"),
            line: lineParam,
            factory: factoryParam,
            revision: preRevision,
            shift: shiftParam
          },
          fetchPolicy: "network-only"
        })
        const prevDataProduction =
          rawPrevDataProduction.data.productionPlanReportByRevision[0]
        dataProductionPlan.data.productionPlanReportByRevision[0] =
          dataProductionPlan.data.productionPlanReportByRevision[0].map(
            (e: DataProductionPlan) => {
              const checkEditBaseRevision = prevDataProduction.find(
                (base: DataProductionPlan) => {
                  return (
                    base.machine === e.machine &&
                    base.partNo === e.partNo &&
                    base.startTime === e.startTime &&
                    base.endTime === e.endTime &&
                    base.startTimeOT === e.startTimeOT &&
                    base.endTimeOT === e.endTimeOT &&
                    base.jph === e.jph
                  )
                }
              )
              if (!checkEditBaseRevision) {
                return {
                  ...e,
                  totalHour: Number(e.totalHour).toFixed(2),
                  fieldEdit: true
                }
              } else
                return {
                  ...e,
                  totalHour: Number(e.totalHour).toFixed(2),
                  fieldEdit: false
                }
            }
          )

        setProductionPlanData(
          dataProductionPlan?.data?.productionPlanReportByRevision ?? []
        )
      } else {
        setProductionPlanData(
          dataProductionPlan.data.productionPlanReportByRevision ?? []
        )
      }
    }
    clearParam("ProductionPlanReport")
  }, [factoryParam, dateParam, lineParam, revisionParam, shiftParam])

  useEffect(() => {
    if (factoryParam && dateParam && lineParam && revisionParam && shiftParam) {
      onSubmit()
    }
  }, [factoryParam, dateParam, lineParam, revisionParam, shiftParam])

  const updateRev = async () => {
    setOpenDialog(false)
    const concatPlan =
      productionPlanData.length > 1 && productionPlanData[1]?.length > 0
        ? productionPlanData[0].concat(productionPlanData[1])
        : productionPlanData[0]
    const newRevision = concatPlan.map((e: DataProductionPlan) => {
      const prevData = e.revision
      const data = (Number(prevData?.split("Rev")[1]) + 1).toString()
      const newRev =
        prevData?.substring(0, prevData?.length - data.length) + data
      return {
        customer: e.customer,
        employee: e.employee,
        endTime: e.endTime,
        endTimeOT: e.endTimeOT,
        engApprove: e.engApprove,
        factory: e.factory,
        jph: Number(e.jph),
        line: e.line,
        machine: e.machine,
        mtApprove: e.mtApprove,
        partName: e.partName,
        partNo: e.partNo,
        pdApprove: e.pdApprove,
        pdPlan: e.pdPlan,
        plApprove: e.plApprove,
        planDate: e.planDate,
        planNo: e.planNo,
        process: e.process,
        qaApprove: e.qaApprove,
        remarks: e.remarks,
        section: e.section,
        shift: e.shift,
        startTime: e.startTime,
        startTimeOT: e.startTimeOT,
        step: e.step,
        totalHour: e.totalHour.toString(),
        totalQuantity: Math.ceil(e.totalQuantity),
        revision: newRev,
        status: false
      }
    })
    try {
      await AddProductionPlan({
        variables: {
          inputProductionPlan: newRevision,
          department: user.role
        }
      })
      swal("Success", "update success", "success")
      onSubmit()
    } catch (error) {
      swal("Error", `${error}`, "error")
    }
  }

  const handlePrint = () => {
    if (status) {
      swal("Warning", "Wait for Approve", "warning")
    } else {
      window.print()
    }
  }

  const diffHours = useDiffHours(date)

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Planning}
        menuItemName={Object.keys(menuItemList)[2]}
        menuPageName={pageTitle}
      />
      <ThemedCard style={{ padding: "20px" }}>
        <CardHeader
          titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
          title="Production Plan Report"
          subheaderTypographyProps={{ fontFamily: "Sarabun" }}
          subheader="Add and/or print production plan report"
          style={{ marginBottom: "20px" }}
        />
        <div className="print-hide" style={{ marginBottom: "60px" }}>
          <Grid container spacing={2}>
            <Grid item sm={2.4}>
              <CustomTextFieldTypeDate
                setDate={setDate}
                date={date}
                error={error}
              />
            </Grid>
            <Grid item sm={2.4}>
              <AutocompleteLine line={line} setLine={setLine} error={error} />
            </Grid>
            <Grid item sm={2.4}>
              <AutocompleteFactory
                setFactory={setFactory}
                factory={factory}
                error={error}
              />
            </Grid>
            <Grid item sm={2.4}>
              <Autocomplete
                fullWidth
                id="Shift"
                value={shift}
                options={["Day", "Night"]}
                disablePortal
                onChange={(e, value) => {
                  setShift(value as string)
                  setRevision("")
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Shift"
                    error={!shift && error}
                  />
                )}
              />
            </Grid>
            <Grid item sm={2.4}>
              <Autocomplete
                fullWidth
                id="revision"
                value={revision}
                options={revisionList}
                loading={loadRevision}
                noOptionsText="No Revision"
                disablePortal
                onChange={(e, value) => {
                  setSubmitUpdate(false)
                  setRevision(value as string)
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    value={revision}
                    label="Revision"
                    error={!revision && error}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              sm={12}
              style={{ textAlign: "left" }}
              container
              spacing={2}
              direction="row"
            >
              <Grid item>
                <ThemedButton
                  type="submit"
                  variant="contained"
                  onClick={onSubmit}
                >
                  Submit
                </ThemedButton>
              </Grid>
              <Grid item>
                <ThemedButton
                  contrast
                  variant="outlined"
                  color="primary"
                  endIcon={<Print />}
                  onClick={handlePrint}
                >
                  Print
                </ThemedButton>
              </Grid>

              <Grid item>
                <ThemedButton
                  variant="contained"
                  color={addMachine ? "warning" : "primary"}
                  startIcon={addMachine ? <ArrowBack /> : <Add />}
                  disabled={!submitUpdate || lastRevision !== revision}
                  style={
                    masterPlan.length > 0
                      ? {
                          marginLeft: "10px",
                          width: "150px",
                          whiteSpace: "nowrap"
                        }
                      : { display: "none" }
                  }
                  onClick={() => setAddMachine(!addMachine)}
                >
                  {addMachine ? "black" : "Add"}
                </ThemedButton>
              </Grid>
              <Grid item sm={0.7} xs={4} minWidth={"110px"}>
                <ThemedButton
                  variant="outlined"
                  color="primary"
                  startIcon={edit ? <ArrowBack /> : <Edit />}
                  disabled={!submitUpdate || lastRevision !== revision}
                  style={
                    masterPlan.length > 0
                      ? {
                          marginLeft: "10px"
                        }
                      : { display: "none" }
                  }
                  onClick={() => setEdit(!edit)}
                >
                  {edit ? "back" : "edit"}
                </ThemedButton>
              </Grid>
              <Grid item>
                <ThemedButton
                  variant="contained"
                  color={deleteMachine ? "warning" : "error"}
                  disabled={!submitUpdate || lastRevision !== revision}
                  startIcon={deleteMachine ? <ArrowBack /> : <DeleteIcon />}
                  style={
                    masterPlan.length > 0
                      ? {
                          marginLeft: "10px",
                          width: "160px",
                          whiteSpace: "nowrap"
                        }
                      : { display: "none" }
                  }
                  onClick={() => setDeleteMachine(!deleteMachine)}
                >
                  {deleteMachine ? "Back" : "Delete Machine"}
                </ThemedButton>
              </Grid>
              {addMachine || deleteMachine || edit ? (
                <>
                  <Grid item sm={1} xs={6} minWidth={"80px"}>
                    <ThemedButton
                      variant="contained"
                      style={{ marginLeft: "15px" }}
                      color="success"
                      endIcon={<SaveIcon />}
                      onClick={() => {
                        setAddMachine(false)
                        setDeleteMachine(false)
                        setEdit(false)
                        setOpenDialog(true)
                      }}
                    >
                      save
                    </ThemedButton>
                  </Grid>
                  <Grid item sm={1} xs={6} minWidth={"120px"}>
                    <>
                      <ThemedButton
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          setAddMachine(false)
                          setDeleteMachine(false)
                          setEdit(false)
                          onSubmit()
                        }}
                      >
                        Cancel
                      </ThemedButton>
                    </>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <DeleteConfirmDialog
            open={openDialog}
            setOpen={setOpenDialog}
            title={"Confirm Update Revision"}
            agreeFunction={updateRev}
          />
        </div>
        <Box
          component="form"
          onSubmit={handleEditFormSubmit}
          className="print-production-plan-report"
          sx={{ backgroundColor: "white", padding: 2 }}
        >
          <TableProductionPlan
            masterPlan={masterPlan}
            edit={edit}
            deleteMachine={deleteMachine}
            editPlan={editPlan}
            editFormData={editFormData}
            totalQuantity={totalQuantity}
            totalQuantityBackup={totalQuantityBackup}
            backupPlan={backupPlan}
            handleEditTable={handleEditTable}
            handleDeleteTable={handleDeleteTable}
            handleEditFromData={handleEditFromData}
          />
          {addMachine ? (
            <AddTableRow
              plan={productionPlanData}
              setAddPlans={setAddPlans}
              setProductionPlanData={setProductionPlanData}
            />
          ) : (
            <ApproveProductionPlan
              status={status}
              setStatus={setStatus}
              line={line}
              revision={revision}
              date={date}
              factory={factory}
              masterPlan={masterPlan}
              diffHours={diffHours}
              productionPlanReportByRevision={
                data?.productionPlanReportByRevision
              }
            />
          )}
        </Box>
      </ThemedCard>
      <DialogCircularProgress loading={loading} />
      <DeleteConfirmDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Confirm Delete Machine"}
        agreeFunction={submitDeletePlan}
      />
    </ThemeProvider>
  )
}

export default ProductionReport
