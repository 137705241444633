import { useLazyQuery, useQuery } from "@apollo/client"
import {
  Autocomplete,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  ThemeProvider,
  useTheme
} from "@mui/material"
import moment from "moment"
import { useState } from "react"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import swal from "sweetalert"
import AddIcon from "@mui/icons-material/Add"
import {
  GET_MACHINES_MT,
  GET_PM_MACHINE_REPORT
} from "../../common/Query/MaintenanceQuery"
import { IPMReport } from "./component/MachineDialogReportMt"
import { factoryList } from "../../common/utilities"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

const pageTitle = "PM Report"

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: "solid black 1px",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun",

    borderBottom: "solid black 1px"
  }
}))

export const StyledTableCell2 = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: "solid black 1px",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun",
    border: "solid black 1px"
  }
}))

const StyledTableRow = styled(TableRow)(() => ({}))

export const printDiv = (id: string): void => {
  const printContents = document.getElementById(id)?.innerHTML
  const originalContents = document.body.innerHTML
  document.body.innerHTML =
    "<html><head><title></title></head><body>" + printContents + "</body>"
  window.print()
  document.body.innerHTML = originalContents
  location.reload()
}

const PMReport: React.FC = () => {
  const [dateFrom, setDateFrom] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState(
    moment(new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000)).format(
      "YYYY-MM-DD"
    )
  )
  const [machine, setMachine] = useState<string>("")
  const [dataPMRecord, setDataPMRecord] = useState<IPMReport[]>([])
  const [factory, setFactory] = useState<string>("")
  const oneHour = 60
  const updateTotalTime = dataPMRecord.map((item) => ({
    ...item,
    totalTime: Math.round(item?.totalTime * oneHour)
  }))

  const getMachine = useQuery(GET_MACHINES_MT)
  const [getPmMachineReport, { loading }] = useLazyQuery(
    GET_PM_MACHINE_REPORT,
    { fetchPolicy: "network-only" }
  )

  let machineList: Array<string> = []

  machineList = getMachine.data?.machinesMt
    .map((e: { code: string }) => e.code)
    .reduce(
      (prev: string[], cur: string) =>
        prev.includes(cur) ? prev : prev.concat(cur),
      []
    )

  const handleSubmit = async () => {
    try {
      const dataPMMachineReport = await getPmMachineReport({
        variables: {
          dateFrom,
          dateTo,
          factory,
          machine
        }
      })
      setDataPMRecord(dataPMMachineReport?.data?.pmMachineReport ?? [])
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Maintenance}
        menuItemName={Object.keys(menuItemList)[12]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard title="PM Report" subTitle="About detail PM Report" />
        <Grid container spacing={2} width={"98%"} padding={2}>
          <Grid item sm={2}>
            <ThemedTextField
              fullWidth
              value={dateFrom}
              error={!dateFrom}
              id="dateFrom"
              label={"Date From"}
              InputLabelProps={{
                shrink: true
              }}
              type="date"
              autoComplete="family-name"
              onChange={(e) => setDateFrom(e.target.value)}
            />
          </Grid>

          <Grid item sm={2}>
            <ThemedTextField
              fullWidth
              value={dateTo}
              error={!dateTo}
              label={"Date To"}
              InputLabelProps={{
                shrink: true
              }}
              id="dateTo"
              type="date"
              autoComplete="family-name"
              onChange={(e) => setDateTo(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              id="machine"
              value={machine}
              freeSolo
              options={machineList ?? ["-"]}
              onChange={(e, newValue) => setMachine(newValue ?? "")}
              renderInput={(params) => (
                <ThemedTextField {...params} label={"Machines"} />
              )}
            />
          </Grid>
          <Grid item sm={2} xs={12}>
            <Autocomplete
              fullWidth
              disablePortal
              value={factory}
              renderInput={(params) => (
                <ThemedTextField {...params} label="Select Factory" />
              )}
              onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
              options={factoryList}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ThemedLoadingButton
              variant="contained"
              sx={{ mt: 1, mb: 2, backgroundColor: theme.palette.primary.main }}
              onClick={handleSubmit}
              loading={loading}
              endIcon={<AddIcon />}
            >
              Submit
            </ThemedLoadingButton>
            <ThemedButton
              sx={{ ml: 2, mt: 1, mb: 2, backgroundColor: "yellowgreen" }}
              variant="contained"
              onClick={() => printDiv("PMReport")}
            >
              Print
            </ThemedButton>
          </Grid>
        </Grid>
        <div id="PMReport">
          <Table
            style={{
              border: "solid black 1px",
              borderCollapse: "collapse"
            }}
            className="print-sm-order"
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell2
                  style={{
                    textAlign: "center",
                    fontSize: "30px",
                    whiteSpace: "nowrap"
                  }}
                  colSpan={8}
                >
                  PREVENTIVE MAINTENANCE REPORT
                </StyledTableCell2>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell2>No.</StyledTableCell2>
                <StyledTableCell2>Machine</StyledTableCell2>
                <StyledTableCell2>Date</StyledTableCell2>
                <StyledTableCell2>Start</StyledTableCell2>
                <StyledTableCell2>Finish</StyledTableCell2>
                <StyledTableCell2>Total Time</StyledTableCell2>
                <StyledTableCell2>Maintenance</StyledTableCell2>
                <StyledTableCell2>Remark</StyledTableCell2>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {updateTotalTime.map((value, index: number) => {
                return (
                  <StyledTableRow key={value.id}>
                    <StyledTableCell2>{index + 1}</StyledTableCell2>
                    <StyledTableCell2>{value.machineCode}</StyledTableCell2>
                    <StyledTableCell2>
                      {moment(value.actionDate).format("DD-MM-YYYY")}
                    </StyledTableCell2>
                    <StyledTableCell2>{value.startTime}</StyledTableCell2>
                    <StyledTableCell2>{value.endTime}</StyledTableCell2>
                    <StyledTableCell2>{value.totalTime} Min</StyledTableCell2>
                    <StyledTableCell2>{value.maintenance}</StyledTableCell2>
                    <StyledTableCell2>{value.remarks}</StyledTableCell2>
                  </StyledTableRow>
                )
              })}
              <StyledTableRow>
                <StyledTableCell2 />
                <StyledTableCell2 />
                <StyledTableCell2 />
                <StyledTableCell2 />
                <StyledTableCell2 />
                <StyledTableCell2 />
                <StyledTableCell2 />
              </StyledTableRow>
            </TableBody>
          </Table>
        </div>
      </ThemedCard>
    </ThemeProvider>
  )
}
export default PMReport
