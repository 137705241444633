import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useEffect, useRef, useState } from "react"
import pareto from "highcharts/modules/pareto"
import { LocalizationProvider, DatePicker } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  useTheme
} from "@mui/material"
import moment from "moment"
import DateAdapter from "@mui/lab/AdapterMoment"
import { IMonthlyDefect } from "../hook/useFetchData"

pareto(Highcharts)

export const ChartDefectReport = (props: {
  chart?: HighchartsReact.Props
  monthlyDefect: IMonthlyDefect | undefined
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const { monthlyDefect } = props
  const theme = useTheme()

  if (!monthlyDefect) return <>Loading...</>
  const dataMonthly = monthlyDefect

  const newData = [
    ["ปรับแต่งแม่พิมพ์", dataMonthly.totalTNG],
    ["ของเสียในกระบวนการ", dataMonthly.totalING],
    ["ของเสีย Setup", dataMonthly.totalSNG],
    ["ของเสีย Supplier", dataMonthly.totalSupplierNG]
  ]
  let result = newData
  if (dataMonthly) {
    result = newData.sort((a, b) => Number(b[1]) - Number(a[1]))
  }

  const options: Highcharts.Options = {
    chart: {
      renderTo: "container",
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "Defect Pareto Monthly",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      shared: true
    },
    xAxis: {
      categories: result.map((e) => e[0].toString()),
      crosshair: true,
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "10px"
        }
      }
    },
    legend: {
      enabled: false
    },
    yAxis: [
      {
        labels: {
          style: {
            color: theme.palette.secondary.contrastText
          }
        },
        title: {
          text: "",
          style: {
            color: theme.palette.secondary.contrastText
          }
        }
      },
      {
        title: {
          text: "",
          style: {
            color: theme.palette.secondary.contrastText
          }
        },
        minPadding: 0,
        maxPadding: 0,
        max: 100,
        min: 0,
        opposite: true,
        labels: {
          format: "{value}%",
          style: {
            color: theme.palette.secondary.contrastText
          }
        }
      }
    ],
    series: [
      {
        type: "pareto",
        name: "Percentage",
        yAxis: 1,
        zIndex: 10,
        baseSeries: 1,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: "%"
        }
      },
      {
        name: "จำนวน (ชิ้น)",
        type: "column",
        colorByPoint: true,
        zIndex: 2,
        data: [result[0][1], result[1][1], result[2][1], result[3][1]]
      }
    ],
    exporting: {
      enabled: false
    }
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props.chart}
      />
    </>
  )
}
interface IDefectReportProps {
  setDateFrom: (dateFrom: string) => void
  setDateTo: (dateTo: string) => void
  dateFrom: string
  dateTo: string
  dialogOpen: boolean
  setDialogOpen: (dialogOpen: boolean) => void
  monthlyDefect: IMonthlyDefect | undefined
}

const DefectReport = (props: IDefectReportProps): JSX.Element => {
  const { dialogOpen, setDialogOpen, setDateFrom, setDateTo, monthlyDefect } =
    props
  const [date, setDate] = useState(new Date())
  useEffect(() => {
    const newDate = moment(date).format("DD-MM-YYYY").split("-")
    const daysInMonth = new Date(
      Number(newDate[2]),
      Number(newDate[1]),
      0
    ).getDate()
    setDateFrom(`${newDate[2]}-${newDate[1]}-01`)
    setDateTo(`${newDate[2]}-${newDate[1]}-${daysInMonth}`)
  }, [date, setDateFrom, setDateTo])
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          fontSize="25px"
        >
          Defect Pareto Monthly
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12} justifyContent={"center"} margin={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="เลือกเดือน"
                  views={["year", "month"]}
                  value={date}
                  onChange={(newSelectDate) => {
                    setDate(moment(newSelectDate).toDate() as Date)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      value={moment(date).format("MM-YYYY")}
                      inputProps={{
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={12} xs={12} justifyContent="center">
              <ChartDefectReport monthlyDefect={monthlyDefect} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => {
              setDialogOpen(false)
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default DefectReport
