import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  Box,
  Grid,
  Button,
  Autocomplete,
  Switch,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CardHeader,
  useTheme,
  FormControlLabel,
  Checkbox,
  ThemeProvider
} from "@mui/material"
import { useMemo, useState } from "react"
import { ADD_PRODUCTION_PLAN } from "../../common/Mutation/PlanningMutation"
import { GET_MACHINES, GET_PARTS } from "../../common/Query/MasterQuery"
import { GET_PART_PROCESS } from "../../common/Query/ScannerQuery"
import swal from "sweetalert"
import "../../css/Planning/ProductionPlan.css"
import DateAdapter from "@mui/lab/AdapterMoment"
import { LocalizationProvider, TimePicker } from "@mui/lab"
import AddIcon from "@mui/icons-material/Add"
import moment from "moment"
import { PageLabel } from "../../common/Resources/PageLabel"
import { user } from "../../common/MainApp"
import { menuItemList } from "../../common/Resources/menuItemList"
import { filter } from "../../common/utilities"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import AutocompleteLine from "../../common/Resources/AutocompleteLine"

export interface IDataPlanTable {
  planNo: string
  revision: string
  planDate: string
  pdPlan: string
  factory: string
  shift: string
  partNo: string
  partName: string
  customer: string
  line: string
  section: string
  step: string
  process: string
  machine: string
  jph: number
  startTime: string
  endTime: string
  hour: number
  startTimeOT: string
  endTimeOT: string
  hourOT: number
  totalHour: string
  totalQuantity: number
  employee: string
  plApprove: string
  pdApprove: string
  engApprove: string
  qaApprove: string
  mtApprove: string
  remarks: string
}

export const diff = (startTime: number, endTime: number): string => {
  let diff = endTime - startTime
  let hours = Math.floor(diff / 1000 / 60 / 60)
  diff -= hours * 1000 * 60 * 60
  const minutes = Math.floor(diff / 1000 / 60)

  // If using time pickers with 24 hours format, add the below line get exact hours
  if (hours < 0) hours = hours + 24

  return (
    (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes
  )
}

export const calculateTime = (time: string): string => {
  if (!time) return "0"
  const timeSplit = time.split(":")
  return (
    Number(timeSplit[0]) +
    (Number(timeSplit[1]) * 100) / 60 / 100
  ).toFixed(2)
}

const pageTitle = "Production Plan"

const ProductionPlan: React.FC = () => {
  const theme = useTheme()
  const [productionPlanJobNo, setProductionPlanJobNo] = useState<string>("")
  const [revision, setRevision] = useState<string>("Rev00")
  const [planDate, setPlanDate] = useState<string>("")
  const [pdPlan, setPdPlan] = useState<string | null>("")
  const [factory, setFactory] = useState<string | null>("")
  const [shift, setShift] = useState<string | null>("")
  const [partNo, setPartNo] = useState<string | null>("")
  const [partName, setPartName] = useState<string>("")
  const [select, setSelect] = useState<boolean>(false)
  const [selectTime, setSelectTime] = useState<boolean>(false)
  const [customer, setCustomer] = useState<string>("")
  const [line, setLine] = useState<string>("")
  const [machine, setMachine] = useState<string | null>("")
  const [step, setStep] = useState<string | null>("")
  const [process, setProcess] = useState<string | null>("")
  const [section, setSection] = useState<string | null>("")
  const [jobPerHour, setJobPerHour] = useState<number>(0)
  const [startTime, setStartTime] = useState<number>(3600000)
  const [endTime, setEndTime] = useState<number>(36000000)
  const [startTimeOT, setStartTimeOT] = useState<number>(36000000)
  const [endTimeOT, setEndTimeOT] = useState<number>(46800000)
  const [hour, setWorkTime] = useState<number>(0)
  const [hourOT, setWorkTimeOT] = useState<number>(0)
  const [totalHour, setTotalHour] = useState<number>(0)
  const [totalQuantity, setTotalPlan] = useState<number>(0)
  const [employee, setEmployee] = useState<string | null>()
  const [plApprove, setPlanningApprove] = useState<string>()
  const [pdApprove, setProductApprove] = useState<string>()
  const [engApprove, setEngineerApprove] = useState<string>()
  const [qaApprove, setQaApprove] = useState<string>()
  const [mtApprove, setMaintenanceApprove] = useState<string>()
  const [remarks, setRemarks] = useState<string>("")
  const [partProcessing, setPartProcessing] = useState([])
  const [machineData, setMachineData] = useState([])
  const getPart = useQuery(GET_PARTS)
  const getMachine = useQuery(GET_MACHINES)
  const [getPartProcess] = useLazyQuery(GET_PART_PROCESS)
  const [AddProductionPlan, { loading }] = useMutation(ADD_PRODUCTION_PLAN)
  const [dataPlans, setDataPlans] = useState<IDataPlanTable[]>([])
  const [tryOut, setTryOut] = useState<boolean>(false)
  const partNoList: string[] = getPart.data
    ? getPart.data.parts.map((part: { partNo: string }) => part.partNo)
    : []

  const machineList = machineData
    .filter((machine: { priority: string }) => machine.priority === "D")
    .map((machines: { machine: string }) => machines.machine)
  const stepList = partProcessing
    .filter((process: { line: string }) => process.line !== "QC")
    .map((process: { processOrder: string }) => process.processOrder)
  const processList = partProcessing.map(
    (process: { process: string }) => process.process
  )
  partProcessing.find(
    (process: { line: string; processOrder: string }) =>
      process.line !== "QC" && process.processOrder === step
  )
  const recorder1 = ["วันเฉลิม", "สุรศักดิ์"]
  const recorder2 = ["มนตรี", "อมราวดี"]

  useMemo(async () => {
    if (partNo) {
      const partProcessing = await getPartProcess({
        variables: {
          partNo
        }
      })

      setMachineData(
        getMachine.data && factory && line
          ? getMachine.data.machines.filter(
              (machine: { line: string; factory: string }) =>
                machine.line === line && machine.factory === factory
            )
          : []
      )
      setPartName(
        getPart.data && partNo
          ? getPart.data.parts.find(
              (part: { partNo: string }) => part.partNo === partNo
            )?.partName || "-"
          : ""
      )
      setCustomer(
        getPart.data && partNo
          ? getPart.data.parts.find(
              (part: { partNo: string }) => part.partNo === partNo
            )?.customer || "-"
          : ""
      )

      if (step && line && partProcessing.data) {
        const jph = partProcessing.data.partProcesses.find(
          (process: { line: string; processOrder: string }) =>
            process.line === line && process.processOrder === step
        )
        if (jph) {
          setJobPerHour(jph.jph)
        }
      }

      setPartProcessing(partProcessing.data.partProcesses)
      setProductionPlanJobNo("BAE" + factory + " - " + line)
      if (!selectTime) {
        setWorkTime(0)
      } else {
        if (
          (Number(moment(endTime).format("HH")) > 12 &&
            Number(moment(startTime).format("HH")) < 13) ||
          (Number(moment(endTime).format("HH")) >= 1 &&
            Number(moment(endTime).format("HH")) <= 5 &&
            ((Number(moment(startTime).format("HH")) < 24 &&
              Number(moment(startTime).format("HH")) > 19) ||
              Number(moment(startTime).format("HH")) === 0))
        ) {
          const time = diff(startTime, endTime - 60 * 60 * 1000)
          setWorkTime(Number(calculateTime(time)))
        } else {
          const time = diff(startTime, endTime)
          setWorkTime(Number(calculateTime(time)))
        }
      }
      if (!select) {
        setWorkTimeOT(0)
      } else {
        const time = diff(startTimeOT, endTimeOT)
        setWorkTimeOT(Number(calculateTime(time)))
      }

      setTotalHour(hour + hourOT)
      setTotalPlan(Math.round(jobPerHour * (hour + hourOT)))

      if (factory === "2") {
        setPlanningApprove("ณัฏฐ์")
        setProductApprove("อนุชา")
        setEngineerApprove("ฐานพงศ์")
        setQaApprove("ไชยศ")
        setMaintenanceApprove("วิรัช")
      } else if (factory === "1") {
        setPlanningApprove("ณัฏฐ์")
        setProductApprove("ธวัฒชัย")
        setEngineerApprove("ปิยะพงษ์")
        setQaApprove("เสน่ห์")
        setMaintenanceApprove("มานิต")
      }
    }
  }, [
    endTime,
    endTimeOT,
    factory,
    getMachine.data,
    getPart.data,
    getPartProcess,
    hour,
    hourOT,
    jobPerHour,
    line,
    partNo,
    select,
    startTime,
    startTimeOT,
    step,
    selectTime
  ])

  const isComplete = () => {
    if (tryOut) {
      return (
        factory !== "" &&
        partNo !== "" &&
        employee !== "" &&
        machine !== "" &&
        section !== "" &&
        line !== "" &&
        step !== "" &&
        process !== ""
      )
    }
    if (pdPlan === "แผนสำรอง") {
      return (
        productionPlanJobNo !== "" &&
        planDate !== "" &&
        factory !== "" &&
        shift !== "" &&
        partNo !== "" &&
        partName !== "" &&
        customer !== "" &&
        line !== "" &&
        section !== "" &&
        step !== "" &&
        process !== "" &&
        machine !== "" &&
        jobPerHour !== 0 &&
        employee !== "" &&
        plApprove !== "" &&
        pdApprove !== "" &&
        engApprove !== "" &&
        qaApprove !== "" &&
        mtApprove !== ""
      )
    } else {
      return (
        productionPlanJobNo !== "" &&
        // revision !== "" &&
        planDate !== "" &&
        pdPlan !== "" &&
        factory !== "" &&
        shift !== "" &&
        partNo !== "" &&
        partName !== "" &&
        customer !== "" &&
        line !== "" &&
        section !== "" &&
        step !== "" &&
        process !== "" &&
        machine !== "" &&
        jobPerHour !== 0 &&
        employee !== "" &&
        plApprove !== "" &&
        pdApprove !== "" &&
        engApprove !== "" &&
        qaApprove !== "" &&
        mtApprove !== "" &&
        totalHour !== 0
      )
    }
  }

  const addProductionPlan = () => {
    if (!isComplete()) return swal("กรุณาใส่ข้อมูลให้ครบถ้วน", "", "warning")
    const data = {
      planNo: productionPlanJobNo,
      revision,
      planDate,
      pdPlan,
      factory,
      shift,
      partNo,
      partName,
      customer,
      line,
      section,
      step,
      process,
      machine,
      jph: jobPerHour,
      startTime: selectTime ? moment(startTime).format("HH:mm") : null,
      endTime: selectTime ? moment(endTime).format("HH:mm") : null,
      totalHour: totalHour.toString(),
      totalQuantity: Math.ceil(totalQuantity),
      startTimeOT: select ? moment(startTimeOT).format("HH:mm") : null,
      endTimeOT: select ? moment(endTimeOT).format("HH:mm") : null,
      employee,
      plApprove,
      pdApprove,
      engApprove,
      qaApprove,
      mtApprove,
      remarks,
      status: false
    }
    let plan = []
    const dataPlan: string = window?.localStorage?.getItem("dataPlan") || ""
    if (!dataPlan) {
      plan.push(JSON.stringify(data))
      window.localStorage.setItem("dataPlan", plan.toString())
    } else {
      plan = [dataPlan]
      plan.push("data")
      plan.push(JSON.stringify(data))
      window.localStorage.setItem("dataPlan", plan.toString())
    }
    dataTable()
    swal("Add Success", "", "success")
  }

  const dataTable = () => {
    const dataPlanTable: IDataPlanTable[] = []
    const dataPlan: string = window?.localStorage?.getItem("dataPlan") || ""
    const rows = dataPlan.split(",data,")
    if (rows.length > 0 && dataPlan) {
      rows.forEach((element: string) => {
        dataPlanTable.push(JSON.parse(element))
      })
      setDataPlans(dataPlanTable)
    } else {
      if (dataPlans.length !== 0) {
        setDataPlans([])
      }
    }
  }

  if (dataPlans.length === 0) {
    dataTable()
  }

  const onReset = () => {
    setStep("")
    setProcess("")
    setJobPerHour(0)
    setCustomer("")
  }

  const deleteLocal = (index: number) => {
    const dataPlanTable: string[] = []
    const dataPlan: string = window?.localStorage?.getItem("dataPlan") || ""
    const rows = dataPlan.split(",data,")
    if (rows.length > 0 && dataPlan) {
      rows.forEach((element: string, i: number) => {
        if (i === 0) {
          if (i !== index) {
            dataPlanTable.push(element)
          } else {
            setDataPlans([])
            window.localStorage.setItem("dataPlan", "")
          }
        } else {
          if (dataPlanTable.length === 0) {
            dataPlanTable.push(element)
          } else {
            if (i !== index) {
              dataPlanTable.push("data")
              dataPlanTable.push(element)
            }
          }
        }
      })
      if (dataPlanTable.length > 0) {
        window.localStorage.setItem("dataPlan", dataPlanTable.toString())
      }
    }
    dataTable()
  }

  const submitProductionPlan = async () => {
    try {
      if (dataPlans.length === 0)
        return swal("ไม่พบข้อมูลที่จะ บันทึก", "", "warning")
      await AddProductionPlan({
        variables: {
          inputProductionPlan: dataPlans,
          department: user.role
        }
      })

      // setDataPlans([])
      window.localStorage.setItem("dataPlan", "")
      dataTable()
      swal("บันทึกสำเร็จ", "Success!", "success")
    } catch (err) {
      swal("Unsuccess", `${(err as Error).message}`, "error")
    }
  }

  const ReadOnlyRow = (props: { plan: IDataPlanTable; index: number }) => {
    const { plan, index } = props
    return (
      <TableRow key={index}>
        <TableCell>{plan.machine}</TableCell>
        <TableCell>{plan.customer}</TableCell>
        <TableCell>{plan.partNo}</TableCell>
        <TableCell>{plan.partName}</TableCell>
        <TableCell>{plan.step}</TableCell>
        <TableCell>{plan.process}</TableCell>
        <TableCell>{plan.startTime}</TableCell>
        <TableCell>{plan.endTime}</TableCell>
        <TableCell>{plan.startTimeOT}</TableCell>
        <TableCell>{plan.endTimeOT}</TableCell>
        <TableCell>{plan.totalHour}</TableCell>
        <TableCell>{plan.totalQuantity}</TableCell>
        <TableCell>{plan.employee}</TableCell>
        <TableCell>{plan.remarks}</TableCell>
        <TableCell>
          <Button
            color="error"
            variant="contained"
            onClick={() => deleteLocal(index)}
          >
            delete
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Planning}
        menuItemName={Object.keys(menuItemList)[2]}
        menuPageName={pageTitle}
      />

      <ThemedCard style={{ padding: "20px" }}>
        <CardHeader
          titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
          title="Production Plan"
          subheaderTypographyProps={{ fontFamily: "Sarabun" }}
          subheader="Add or search for production plan"
          style={{ marginBottom: "20px" }}
        />
        <Box width={"98%"}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                id="pfJobNo"
                type="text"
                label="Production Plan Job No"
                fullWidth
                disabled
                className="disable-back-ground-color"
                value={productionPlanJobNo}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                disabled
                className="disable-back-ground-color"
                fullWidth
                label="Revision"
                value={revision}
                id="revision"
                type="text"
                onChange={(e) => setRevision(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                id="date"
                label="Date"
                type="date"
                value={planDate}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => setPlanDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={8} sm={3}>
              <Autocomplete
                fullWidth
                id="plan"
                disablePortal
                onChange={(e, newValue) => {
                  setPdPlan(newValue)
                }}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Plan" />
                )}
                options={["แผนหลัก", "แผนสำรอง"]}
              />
            </Grid>
            <Grid item xs={4} sm={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      setTryOut(e.target.checked)
                      e.target.checked ? setRemarks("Try Out") : setRemarks("")
                    }}
                  />
                }
                label="Try Out"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                id="factory"
                onChange={(e, newValue) => {
                  setFactory(newValue)
                }}
                disablePortal
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Factory" />
                )}
                options={["1", "2"]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                id="shift"
                disablePortal
                onChange={(e, newValue) => {
                  setShift(newValue)
                }}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Shift" />
                )}
                options={["Day", "Night"]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const { inputValue } = params
                  if (inputValue !== "") {
                    filtered.push(inputValue)
                  }
                  return filtered
                }}
                freeSolo
                fullWidth
                disablePortal
                id="part-no"
                onChange={(e, newValue) => {
                  setPartNo(newValue)
                  onReset()
                }}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Part No" />
                )}
                options={partNoList}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const { inputValue } = params
                  if (inputValue !== "") {
                    filtered.push(inputValue)
                  }
                  return filtered
                }}
                freeSolo
                fullWidth
                disabled={!tryOut}
                disablePortal
                id="part-no"
                value={partName}
                onChange={(e, newValue) => {
                  setPartName(newValue as string)
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    value={partName}
                    label="Part Name"
                  />
                )}
                options={["-"]}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const { inputValue } = params
                  if (inputValue !== "") {
                    filtered.push(inputValue)
                  }
                  return filtered
                }}
                freeSolo
                fullWidth
                disabled={!tryOut}
                disablePortal
                id="part-no"
                value={customer}
                onChange={(e, newValue) => {
                  setCustomer(newValue as string)
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    value={customer}
                    label="Customer"
                  />
                )}
                options={["-"]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutocompleteLine setLine={setLine} line={line} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const { inputValue } = params
                  if (inputValue !== "") {
                    filtered.push(inputValue)
                  }
                  return filtered
                }}
                freeSolo
                fullWidth
                id="select-machine"
                value={machine}
                disablePortal
                disabled={!line || !factory}
                onChange={(e, newValue) => setMachine(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Machine"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                options={machineList}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const { inputValue } = params
                  if (inputValue !== "") {
                    filtered.push(inputValue)
                  }
                  return filtered
                }}
                freeSolo
                fullWidth
                id="select-step"
                value={step}
                disablePortal
                disabled={!partNo}
                onChange={(e, newValue) => setStep(newValue)}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Step" />
                )}
                options={stepList.length > 0 ? stepList : ["-"]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const { inputValue } = params
                  if (inputValue !== "") {
                    filtered.push(inputValue)
                  }
                  return filtered
                }}
                freeSolo
                fullWidth
                value={process}
                id="select-process"
                onChange={(e, newValue) => setProcess(newValue)}
                disablePortal
                disabled={!partNo}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Process" />
                )}
                options={processList.length > 0 ? processList : ["-"]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const { inputValue } = params
                  if (inputValue !== "") {
                    filtered.push(inputValue)
                  }
                  return filtered
                }}
                freeSolo
                fullWidth
                id="select-section"
                disablePortal
                onChange={(e, newValue) => setSection(newValue)}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Section" />
                )}
                options={["ARC WELDING", "PRESS", "SPOT", "PLATING"]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                // disabled
                id="jobs-per-hour"
                label="Jobs Per Hour"
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
                onChange={(e) => setJobPerHour(+e.target.value)}
                value={jobPerHour}
                fullWidth
                type={"number"}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <Switch
                checked={selectTime}
                onChange={(event) =>
                  setSelectTime(event.target.value ? !selectTime : selectTime)
                }
                name="gilad"
              />
            </Grid>
            <Grid item xs={5.5} sm={2.5}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                  label="Start Time"
                  ampm={false}
                  disabled={!selectTime}
                  views={["hours", "minutes"]}
                  inputFormat="HH:mm"
                  value={startTime}
                  onChange={(newSelectTime) => {
                    setStartTime(newSelectTime ? newSelectTime : 0)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField {...params} id="start-time" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={5.5} sm={2.5}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                  label="End Time"
                  ampm={false}
                  disabled={!selectTime}
                  views={["hours", "minutes"]}
                  inputFormat="HH:mm"
                  value={endTime}
                  onChange={(newSelectTime) => {
                    setEndTime(newSelectTime ? newSelectTime : 0)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField {...params} id="end-time" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              sx={{ display: { xs: "flex", sm: "none" } }}
            >
              <Switch
                checked={selectTime}
                onChange={(event) =>
                  setSelectTime(event.target.value ? !selectTime : selectTime)
                }
                name="gilad"
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <Switch
                checked={select}
                onChange={(event) =>
                  setSelect(event.target.value ? !select : select)
                }
                name="gilad"
              />
            </Grid>
            <Grid item xs={5.5} sm={2.5}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                  label="Start Time OT"
                  ampm={false}
                  disabled={!select}
                  views={["hours", "minutes"]}
                  inputFormat="HH:mm"
                  value={startTimeOT}
                  onChange={(newSelectTime) => {
                    setStartTimeOT(newSelectTime ? newSelectTime : 0)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField {...params} id="start-time-ot" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={5.5} sm={2.5}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                  label="End Time OT"
                  ampm={false}
                  disabled={!select}
                  views={["hours", "minutes"]}
                  inputFormat="HH:mm"
                  value={endTimeOT}
                  onChange={(newSelectTime) => {
                    setEndTimeOT(newSelectTime ? newSelectTime : 0)
                  }}
                  renderInput={(params) => (
                    <ThemedTextField {...params} id="end-time-ot" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              sx={{ display: { xs: "flex", sm: "none" } }}
            >
              <Switch
                checked={select}
                onChange={(event) =>
                  setSelect(event.target.value ? !select : select)
                }
                name="gilad"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                label="Work Time (Hrs.)"
                id="workTime"
                type="number"
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
                disabled
                className="disable-back-ground-color"
                value={hour}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                label="Work Time OT (Hrs.)"
                id="workTimeOT"
                type="number"
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
                disabled
                className="disable-back-ground-color"
                value={hourOT}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                label="Total Time (Hrs.)"
                id="totalTime"
                type="number"
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
                disabled
                className="disable-back-ground-color"
                value={totalHour}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                label="Total Plan (Quantity)"
                id="totalQuantity"
                type="number"
                onWheel={(event) =>
                  event.currentTarget.querySelector("input")?.blur()
                }
                onChange={(e) => setTotalPlan(+e.target.value)}
                value={totalQuantity}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                id="select-employee"
                onChange={(e, newValue) => setEmployee(newValue)}
                disablePortal
                disabled={!factory}
                renderInput={(params) => (
                  <ThemedTextField {...params} label={"Employee"} />
                )}
                options={
                  factory === "1" ? recorder1 : factory === "2" ? recorder2 : []
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                label="Planning Approved By"
                id="planning-approve"
                type="text"
                disabled
                InputLabelProps={{ shrink: true }}
                className="disable-back-ground-color"
                value={plApprove}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                label="Product Approved By"
                id="product-approve"
                type="text"
                disabled
                InputLabelProps={{ shrink: true }}
                className="disable-back-ground-color"
                value={pdApprove}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                label="Engineer Approved By"
                id="engineer-approve"
                type="text"
                disabled
                InputLabelProps={{ shrink: true }}
                className="disable-back-ground-color"
                value={engApprove}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                label="Quantity Approved By"
                id="quantity-approve"
                type="text"
                disabled
                InputLabelProps={{ shrink: true }}
                className="disable-back-ground-color"
                value={qaApprove}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                label="Maintenance Approved By"
                id="maintenance"
                type="text"
                disabled
                InputLabelProps={{ shrink: true }}
                className="disable-back-ground-color"
                value={mtApprove}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                value={remarks}
                label="Remarks"
                id="remarks"
                type="text"
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Grid>
            <Grid container item xs={12} sm={12} spacing={2}>
              <Grid item>
                <ThemedLoadingButton
                  variant="contained"
                  onClick={submitProductionPlan}
                  loading={loading}
                >
                  Submit
                </ThemedLoadingButton>
              </Grid>
              <Grid item>
                <ThemedButton
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={addProductionPlan}
                >
                  Add
                </ThemedButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Machine</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Part NO</TableCell>
                <TableCell>Part Name</TableCell>
                <TableCell>Step</TableCell>
                <TableCell>Process</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Start Time OT</TableCell>
                <TableCell>End Time OT</TableCell>
                <TableCell>Total Time</TableCell>
                <TableCell>Total Plan Quantity</TableCell>
                <TableCell>Employee</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataPlans.map((plan, index) => {
                return <ReadOnlyRow plan={plan} index={index} />
              })}
            </TableBody>
          </Table>
        </div>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default ProductionPlan
