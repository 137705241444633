import moment from "moment"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { calculateTime } from "../../Planning/ProductionPlan"
import { IDataProductionPlan } from "./SummaryMachine"
interface IProps {
  planData: IDataProductionPlan
  setTimeStatus: Dispatch<SetStateAction<boolean>>
  setTimeCurrent: Dispatch<SetStateAction<number>>
}
const TimeCurrent = (props: IProps): JSX.Element => {
  const [dateState, setDateState] = useState(new Date())
  const [totalHour, setTotalHour] = useState<number>(0)

  const nowDate: string = moment(new Date()).format("yyyy-MM-DD").toString()
  const nowTime: string = moment(new Date()).format("HH:mm").toString()
  const currentTime = dateState.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false
  })

  const conditionEndTime = (
    currentTime: string,
    endTime: string,
    date?: string
  ) => {
    return Number(
      calculateTime(
        (currentTime > endTime && endTime !== "00:00") ||
          date !== moment(new Date()).format("YYYY-MM-DD")
          ? endTime
          : currentTime
      )
    )
  }
  useEffect(() => {
    let totalHour = 0
    if (props.planData.timeCalculate) {
      for (const plan of props.planData.timeCalculate) {
        const endTimeNow = conditionEndTime(
          currentTime,
          plan.endTime,
          props.planData.planDate
        )
        const startTime = Number(calculateTime(plan.startTime))
        const endTime = Number(calculateTime(plan.endTime))
        const startTimeOT = Number(calculateTime(plan.startTimeOT))

        if (props.planData.shift === "Day") {
          if (props.planData.planDate !== nowDate) {
            totalHour = Number(props.planData.totalHour || 0)
          } else {
            if (
              Number(calculateTime(nowTime)) <= 12 &&
              startTime < 13 &&
              startTime !== 0
            ) {
              totalHour += endTimeNow - startTime
            } else if (
              Number(calculateTime(nowTime)) >= 13 &&
              Number(calculateTime(nowTime)) <= 17
            ) {
              if (
                (startTime <= 12 && startTime !== 0 && endTime <= 12) ||
                (startTime >= 13 &&
                  startTime !== 0 &&
                  Number(calculateTime(nowTime)) > startTime)
              ) {
                totalHour += endTimeNow - startTime
              } else if (startTime <= 12 && startTime !== 0 && endTime >= 13) {
                totalHour += endTimeNow - startTime - 1
              } else {
                totalHour += 0
              }
            } else if (Number(calculateTime(nowTime)) >= 17.5) {
              if (startTimeOT !== 0) {
                if (
                  (startTime <= 12 && endTime <= 12) ||
                  (startTime >= 13 &&
                    Number(calculateTime(nowTime)) > startTimeOT)
                ) {
                  totalHour += endTime - startTime + endTimeNow - startTimeOT
                } else if (startTime <= 12 && endTime >= 13) {
                  totalHour +=
                    endTime - startTime + endTimeNow - startTimeOT - 1
                } else {
                  totalHour += 0
                }
              } else {
                if (
                  (startTime <= 12 && startTime !== 0 && endTime <= 12) ||
                  (startTime >= 13 && startTime !== 0)
                ) {
                  totalHour += endTime - startTime
                } else if (
                  startTime <= 12 &&
                  startTime !== 0 &&
                  endTime >= 13
                ) {
                  totalHour += endTime - startTime - 1
                }
              }
            } else if (
              Number(calculateTime(nowTime)) > 12 &&
              Number(calculateTime(nowTime)) < 13
            ) {
              if (endTime !== 0 && endTime <= 12) {
                totalHour += endTime - startTime
              } else if (startTime <= 12 && startTime !== 0 && endTime >= 13) {
                totalHour += endTimeNow - startTime - (endTimeNow - 12)
              } else {
                totalHour += 0
              }
            } else if (
              Number(calculateTime(nowTime)) > 17 &&
              Number(calculateTime(nowTime)) < 17.5
            ) {
              if ((endTime !== 0 && endTime <= 12) || startTime >= 13) {
                totalHour += endTime - startTime
              } else if (startTime <= 12 && startTime !== 0 && endTime >= 13) {
                totalHour += endTime - startTime - 1
              }
            } else {
              totalHour += 0
            }
          }
        } else {
          const currentTime = new Date()
          const dateObj = new Date(props?.planData?.planDate || "")
          dateObj.setDate(dateObj.getDate() + 1)
          const newDateText = dateObj.toISOString().slice(0, 10)

          const start = new Date(`${props.planData.planDate}T${plan.startTime}`)
          const end = new Date(`${newDateText}T${plan.endTime}`)
          const startTimeOT = new Date(`${newDateText}T${plan.startTimeOT}`)
          const endTimeOT = new Date(`${newDateText}T${plan.endTimeOT}`)

          if (
            (currentTime >= end && !plan.startTimeOT) ||
            currentTime >= endTimeOT
          ) {
            totalHour = Number(props.planData.totalHour || 0)
          } else if (currentTime <= start) {
            totalHour = 0
          } else {
            let workTime =
              (currentTime.getTime() - start.getTime()) / (1000 * 60 * 60)
            if (
              (currentTime >= end && !startTimeOT) ||
              (currentTime <= startTimeOT && currentTime >= end)
            ) {
              workTime = (end.getTime() - start.getTime()) / (1000 * 60 * 60)
            } else {
              const timeWork =
                (end.getTime() - start.getTime()) / (1000 * 60 * 60)
              const timeOT =
                (currentTime.getTime() - startTimeOT.getTime()) /
                (1000 * 60 * 60)
              if (timeOT > 0) {
                workTime = timeWork + timeOT
              }
            }
            const nonBreakTimeStart = new Date(`${newDateText}T00:00:00`)
            const nonBreakTimeEnd = new Date(`${newDateText}T01:00:00`)
            if (
              currentTime >= nonBreakTimeStart &&
              currentTime <= nonBreakTimeEnd
            ) {
              const overlapTime =
                (currentTime.getTime() - nonBreakTimeStart.getTime()) /
                (1000 * 60 * 60)
              workTime -= overlapTime
            }
            if (currentTime >= nonBreakTimeEnd) {
              workTime -= 1
            }
            totalHour = workTime
          }
        }
      }
    }
    if (totalHour > 0) {
      setTotalHour(totalHour)
      props.setTimeStatus(true)
      props.setTimeCurrent(totalHour)
    } else {
      props.setTimeCurrent(0)
      props.setTimeStatus(false)
    }
  }, [props.planData, props.setTimeStatus, props.setTimeCurrent])

  useEffect(() => {
    const planDate = props.planData.planDate
    const nowDate = new Date().toLocaleString("fr-CA", {
      timeZone: "Asia/Bangkok",
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    })

    if (planDate === nowDate) {
      setInterval(() => setDateState(new Date()), 60 * 1000)
    }
  }, [])

  return (
    <>
      <span>{`${Math.floor(totalHour)} ชม.  ${Math.round(
        (totalHour % 1) * 60
      )} นาที`}</span>
    </>
  )
}

export default TimeCurrent
