import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState
} from "react"
import MPReport from "../page/MasterPlan/MPReport"

interface MPContextInterface {
  maxDueDate: string[]
  orderCheck: boolean
  factory: string
  negativePartF1: boolean
  plating: boolean
  submitUpdate: boolean
  followUp: boolean
  customer: string
  negativePartF2: boolean
  negativePartFollowUp: boolean
  negativePartFollowUpF1: boolean
  setNegativePartFollowUpF1: (data: boolean) => void
  setNegativePartFollowUp: (data: boolean) => void
  setNegativePartF2: (data: boolean) => void
  setCustomer: Dispatch<SetStateAction<string>>
  setFollowUp: (data: boolean) => void
  setSubmitUpdate: (data: boolean) => void
  setPlating: (data: boolean) => void
  setNegativePartF1: (data: boolean) => void
  setFactory: (data: string) => void
  setMaxDueDate: (data: string[]) => void
  setOrderCheck: (data: boolean) => void
}
const MPContext = createContext<MPContextInterface>({
  maxDueDate: [],
  orderCheck: false,
  factory: "",
  negativePartF1: false,
  plating: false,
  submitUpdate: false,
  followUp: false,
  customer: "",
  negativePartF2: false,
  negativePartFollowUp: false,
  negativePartFollowUpF1: false,
  setNegativePartFollowUpF1: () => ({}),
  setNegativePartFollowUp: () => ({}),
  setNegativePartF2: () => ({}),
  setCustomer: () => ({}),
  setFollowUp: () => ({}),
  setSubmitUpdate: () => ({}),
  setPlating: () => ({}),
  setNegativePartF1: () => ({}),
  setMaxDueDate: () => ({}),
  setOrderCheck: () => ({}),
  setFactory: () => ({})
})

const MPContextProvider = ({
  children
}: {
  children: JSX.Element
}): JSX.Element => {
  const [maxDueDate, setMaxDueDate] = useState<string[]>([])
  const [orderCheck, setOrderCheck] = useState<boolean>(false)
  const [factory, setFactory] = useState<string>("")
  const [negativePartF1, setNegativePartF1] = useState<boolean>(false)
  const [plating, setPlating] = useState<boolean>(false)
  const [submitUpdate, setSubmitUpdate] = useState<boolean>(false)
  const [followUp, setFollowUp] = useState<boolean>(false)
  const [customer, setCustomer] = useState<string>("")
  const [negativePartF2, setNegativePartF2] = useState<boolean>(false)
  const [negativePartFollowUp, setNegativePartFollowUp] =
    useState<boolean>(false)
  const [negativePartFollowUpF1, setNegativePartFollowUpF1] =
    useState<boolean>(false)
  const contextValue = useMemo(
    () => ({
      maxDueDate,
      orderCheck,
      factory,
      negativePartF1,
      plating,
      submitUpdate,
      followUp,
      customer,
      negativePartF2,
      negativePartFollowUp,
      negativePartFollowUpF1,
      setNegativePartFollowUpF1,
      setNegativePartFollowUp,
      setNegativePartF2,
      setCustomer,
      setFollowUp,
      setSubmitUpdate,
      setPlating,
      setNegativePartF1,
      setFactory,
      setMaxDueDate,
      setOrderCheck
    }),
    [
      maxDueDate,
      orderCheck,
      factory,
      negativePartF1,
      plating,
      submitUpdate,
      followUp,
      customer,
      negativePartF2,
      negativePartFollowUp,
      negativePartFollowUpF1,
      setNegativePartFollowUpF1,
      setNegativePartFollowUp,
      setNegativePartF2,
      setCustomer,
      setFollowUp,
      setSubmitUpdate,
      setPlating,
      setNegativePartF1,
      setFactory,
      setMaxDueDate,
      setOrderCheck
    ]
  )
  return (
    <MPContext.Provider value={contextValue}>{children}</MPContext.Provider>
  )
}
export const useMPContext = (): MPContextInterface => useContext(MPContext)

const MPReportWithProvider = (): JSX.Element => {
  return (
    <MPContextProvider>
      <MPReport />
    </MPContextProvider>
  )
}
export default MPReportWithProvider
