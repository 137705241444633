import Toolbar from "@mui/material/Toolbar"
import Tooltip from "@mui/material/Tooltip"
import { useEffect, useState } from "react"
import Link from "@mui/material/Link"
import {
  AppBar,
  Box,
  Button,
  Collapse,
  CSSObject,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  styled,
  Theme,
  useTheme
} from "@mui/material"
import {
  Brightness4,
  Brightness7,
  ExpandLess,
  ExpandMore
} from "@mui/icons-material"
import DvrIcon from "@mui/icons-material/Dvr"
import Avatar from "@mui/material/Avatar"
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined"
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined"
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined"
import WarehouseIcon from "@mui/icons-material/Warehouse"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined"
import PrecisionManufacturingTwoToneIcon from "@mui/icons-material/PrecisionManufacturingTwoTone"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import MuiDrawer from "@mui/material/Drawer"
import AddModeratorIcon from "@mui/icons-material/AddModerator"
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined"
import CustomNavbarMenu from "./Resources/CustomNavbarMenu"
import React from "react"
import { checkRole, user } from "./MainApp"
import { useLocation } from "react-router-dom"
import Notification from "./Notification"
import AuthContext from "../context/AuthContext"
import { menuItemList } from "./Resources/menuItemList"
import "../css/Dashboard/Navbar.css"
import { clearParam, getImagePng } from "./utilities"
import AccountBoxIcon from "@mui/icons-material/AccountBox"
import WaterIcon from "@mui/icons-material/Water"
import EventNoteIcon from "@mui/icons-material/EventNote"
import CloudIcon from "@mui/icons-material/Cloud"
import CompareArrowsIcon from "@mui/icons-material/CompareArrows"
import DrawerChat from "./component/DrawerChat"
export interface IMenuItem {
  key: string
  url: string
}

interface INavListItemProps {
  permissionKey: string
  menuName: string
  menuItems: IMenuItem[]
  onClick: () => void
  icon: JSX.Element
}

const useStyles = (theme: Theme) => ({
  desktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  mobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  midScreenHide: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  mobileDisplay: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "fit-content",
      width: "100vw"
    }
  },
  mobileAdjust: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: 0,
      left: 0
    }
  },
  midScreen: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  fullTitle: {
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      fontSize: "24px",
      fontFamily: "Sarabun",
      color: theme.palette.text.primary
    }
  },
  midTitle: {
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      fontSize: "24px",
      fontFamily: "Sarabun",
      color: theme.palette.text.primary
    }
  }
})

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}))

const midSizePageTitle = "SUISEI-MES"
const pageTitle = "SUISEI-MES"

interface IPropsNavbar {
  open: boolean
  setOpen: (data: boolean) => void
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>
  isDarkMode: boolean
}

const NavBar: React.FC<IPropsNavbar> = (props: IPropsNavbar) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { open, setOpen, isDarkMode, setIsDarkMode } = props
  const authContext = React.useContext(AuthContext)
  const token = authContext.token
  const drawerWidth = token ? 250 : 0
  const drawerWidth2 = token ? 8 : 0

  const UserMenu = ["Sign Up", "Log Out"]
  const handleToggle = () => {
    localStorage.setItem("theme", String(!isDarkMode))
    setIsDarkMode(!isDarkMode)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: "hidden"
  })

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(8)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(drawerWidth2)} + 1px)`
    }
  })
  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open"
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme)
    })
  }))

  const stringToColor = (string: string) => {
    let hash = 0
    let i

    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = "#"

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }

    return color
  }

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name)
      },
      children: `${name?.split(" ")[0][0]}`.toUpperCase()
    }
  }
  const navItemProps = [
    {
      permissionKey: "1",
      menuItems: menuItemList.Master,
      menuName: "Master",
      icon: <DvrIcon style={{ color: theme.palette.text.primary }} />
    },
    {
      permissionKey: "2",
      menuItems: menuItemList.Materials,
      menuName: "Materials",
      icon: (
        <ConstructionOutlinedIcon
          style={{ color: theme.palette.text.primary }}
        />
      )
    },
    {
      permissionKey: "3",
      menuItems: menuItemList.Planning,
      menuName: "Planning",
      icon: (
        <ArticleOutlinedIcon style={{ color: theme.palette.text.primary }} />
      )
    },
    {
      permissionKey: "3",
      menuItems: menuItemList.MasterPlan,
      menuName: "Masterplan",
      icon: (
        <NoteAltOutlinedIcon style={{ color: theme.palette.text.primary }} />
      )
    },
    {
      permissionKey: "4",
      menuItems: menuItemList.Production,
      menuName: "Production",
      icon: (
        <DescriptionOutlinedIcon
          style={{ color: theme.palette.text.primary }}
        />
      )
    },
    {
      permissionKey: "15",
      menuItems: menuItemList.Plate,
      menuName: "Plating Department",
      icon: <WaterIcon style={{ color: theme.palette.text.primary }} />
    },
    {
      permissionKey: "5",
      menuItems: menuItemList.Scanner,
      menuName: "Scanner",
      icon: (
        <QrCodeScannerOutlinedIcon
          style={{ color: theme.palette.text.primary }}
        />
      )
    },
    {
      permissionKey: "6",
      menuItems: menuItemList.Qc,
      menuName: "Quality Control",
      icon: <AddModeratorIcon style={{ color: theme.palette.text.primary }} />
    },
    {
      permissionKey: "7",
      menuItems: menuItemList.Engineer,
      menuName: "Engineer",
      icon: (
        <EngineeringOutlinedIcon
          style={{ color: theme.palette.text.primary }}
        />
      )
    },
    {
      permissionKey: "12",
      menuItems: menuItemList.Maintenance,
      menuName: "Maintenance",
      icon: (
        <PrecisionManufacturingTwoToneIcon
          style={{ color: theme.palette.text.primary }}
        />
      )
    },
    {
      permissionKey: "8",
      menuItems: menuItemList.Warehouse,
      menuName: "Warehouse",
      icon: <WarehouseIcon style={{ color: theme.palette.text.primary }} />
    },
    {
      permissionKey: "9",
      menuItems: menuItemList.Logistics,
      menuName: "Logistics",
      icon: <LocalShippingIcon style={{ color: theme.palette.text.primary }} />
    },
    {
      permissionKey: "10",
      menuItems: menuItemList.Authorization,
      menuName: "Authorization",
      icon: (
        <AssignmentTurnedInOutlinedIcon
          style={{ color: theme.palette.text.primary }}
        />
      )
    },
    {
      permissionKey: "14",
      menuItems: menuItemList.Account,
      menuName: "Account",
      icon: <AccountBoxIcon style={{ color: theme.palette.text.primary }} />
    },
    {
      permissionKey: "16",
      menuItems: menuItemList.HumanResources,
      menuName: "Human Resources",
      icon: <PeopleAltIcon style={{ color: theme.palette.text.primary }} />
    },
    {
      permissionKey: "17",
      menuItems: menuItemList.Iot,
      menuName: "Iot",
      icon: <CloudIcon style={{ color: theme.palette.text.primary }} />
    },
    {
      permissionKey: "18",
      menuItems: menuItemList.SupplierControl,
      menuName: "Supplier Control",
      icon: <CompareArrowsIcon style={{ color: theme.palette.text.primary }} />
    }
  ]
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openIcon = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const removeWhiteSpaces = (menuItemName: string): string => {
    return menuItemName.split(" ").join("")
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [width, setWidth] = useState(window.innerWidth)
  const updateDimensions = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, [])

  return (
    <div
      className={`label_navbar ${classes.mobileDisplay}`}
      style={!token ? { display: "none" } : {}}
    >
      <AppBar
        style={
          !token
            ? { display: "none" }
            : open
            ? {
                marginTop: "0px",

                marginLeft: drawerWidth,

                width: `calc(100% - ${drawerWidth}px)`,

                position: "fixed"
              }
            : {
                marginTop: "0px",

                position: "fixed",

                zIndex: theme.zIndex.drawer + 1
              }
        }
        className={`${classes.mobile}`}
      >
        <Toolbar
          style={{
            backgroundColor: theme.palette.primary.main,
            placeContent: "end",
            alignItems: "center"
          }}
        >
          <Grid container spacing={1}>
            <Grid item sm={5} xs={4} textAlign={"start"} height="70px">
              <Link
                href={"#/Dashboard"}
                underline="none"
                key={"dashboard"}
                sx={{
                  marginRight: 0,
                  ...(open && { display: "none" })
                }}
              >
                <img
                  src={
                    isDarkMode
                      ? getImagePng("Title SPCS-White")
                      : getImagePng("logo-mini")
                  }
                  style={{
                    marginTop: "4px",
                    marginLeft: "14px",
                    width: "50px",
                    height: "50px"
                  }}
                  alt=""
                />
              </Link>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                style={{
                  position: "absolute",
                  top: "15px",
                  color: "white",
                  marginRight: `calc(100% - ${152}px)`
                }}
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" })
                }}
              >
                <ChevronRightIcon
                  style={{ color: theme.palette.text.primary }}
                />
              </IconButton>
              <Box
                component="span"
                className={open ? "margin-open-navbar" : "margin-close-navbar"}
                sx={{
                  ...classes.fullTitle,
                  ...classes.midScreenHide
                }}
              >
                {pageTitle}
              </Box>
              <Box
                component="span"
                className={open ? "margin-open-navbar" : "margin-close-navbar"}
                sx={{
                  ...classes.midTitle,
                  ...classes.midScreen
                }}
              >
                {midSizePageTitle}
              </Box>
            </Grid>
            <Grid item sm={7} xs={8} textAlign={"end"} mt="-5px" height="70px">
              <Notification width={width} />
              {width > 400 ? (
                <>
                  <DrawerChat width={width} />
                </>
              ) : (
                <></>
              )}
              <Tooltip title="Mode">
                <IconButton
                  onClick={handleToggle}
                  size="small"
                  sx={width > 600 ? { ml: 2 } : {}}
                  aria-controls={openIcon ? "notifications-list" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openIcon ? "true" : undefined}
                >
                  {isDarkMode ? (
                    <Brightness4
                      fontSize={width > 600 ? "large" : "small"}
                      style={{ color: theme.palette.text.primary }}
                    />
                  ) : (
                    <Brightness7
                      fontSize={width > 600 ? "large" : "small"}
                      style={{ color: theme.palette.text.primary }}
                    />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title={user?.username}>
                <Button
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 1 }}
                  aria-controls={openIcon ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openIcon ? "true" : undefined}
                >
                  <Avatar
                    style={
                      width > 600
                        ? { width: "35px", height: "35px" }
                        : { width: "15px", height: "15px" }
                    }
                    {...stringAvatar(user?.username ? user.username : "G")}
                  />
                </Button>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openIcon}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0
                    }
                  }
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <CustomNavbarMenu
                  anchorElement={anchorEl}
                  handleMenuClose={handleClose}
                  menuList={UserMenu}
                  removeWhiteSpaces={removeWhiteSpaces}
                ></CustomNavbarMenu>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          ...(!open && {
            ...classes.mobileAdjust,
            ...classes.mobile
          })
        }}
        PaperProps={{ style: { backgroundColor: theme.palette.primary.main } }}
      >
        <DrawerHeader
          style={{
            backgroundColor: theme.palette.primary.main
          }}
        >
          <Link href={"#/Dashboard"} underline="none" key={"dashboard"}>
            <img
              src={
                isDarkMode
                  ? getImagePng("Title SPCS-White")
                  : getImagePng("logo-mini")
              }
              style={{ width: "50px", height: "50px" }}
              alt=""
            />
          </Link>
          <IconButton
            style={{
              marginRight: `calc(100% - ${120}px)`,
              color: "white"
            }}
            color="inherit"
            onClick={handleDrawerClose}
          >
            <ChevronLeftIcon style={{ color: theme.palette.text.primary }} />
          </IconButton>
        </DrawerHeader>
        <Divider
          style={{ backgroundColor: theme.palette.primary.contrastText }}
          sx={{
            ...(!open && {
              ...classes.mobileDisplay,
              ...classes.mobile
            })
          }}
        />
        {navItemProps.map((navItem) => {
          return (
            checkRole(["99", navItem.permissionKey], token) && (
              <NavListItem
                {...navItem}
                onClick={() => setOpen(true)}
                key={navItem.menuName}
              />
            )
          )
        })}
        <List
          style={
            checkRole(["17"], token)
              ? { display: "none" }
              : !checkRole(["99 ,3 ,4"], token)
              ? { backgroundColor: theme.palette.primary.main }
              : { display: "none" }
          }
        >
          {getLinkItem(
            "Document",
            "Document",
            <DocumentScannerOutlinedIcon style={{ color: "white" }} />
          )}
        </List>
        <List
          style={
            !checkRole(["99, 17"], token)
              ? { backgroundColor: theme.palette.primary.main }
              : { display: "none" }
          }
        >
          {getLinkItem(
            "Slip Salary",
            "SlipSalary",
            <EventNoteIcon style={{ color: "white" }} />
          )}
        </List>
      </Drawer>
      <Toolbar />
    </div>
  )
}

const NavListItem: React.FC<INavListItemProps> = (props: INavListItemProps) => {
  const { menuName, menuItems, onClick, icon } = props
  const [expandMenu, setExpandMenu] = useState<boolean>(false)
  const onClickListItem = () => {
    onClick()
    setExpandMenu(!expandMenu)
  }
  const theme = useTheme()
  return (
    <List style={{ backgroundColor: theme.palette.primary.main }}>
      {getListItem(menuName, icon, onClickListItem, expandMenu, theme)}
      {getCollapseMenuElement(menuItems, expandMenu, theme)}
    </List>
  )
}

function getLinkItem(key: string, url: string, icon: JSX.Element) {
  return (
    <ListItemButton>
      <Link
        href={`#/${url}`}
        underline="none"
        style={{ color: "black" }}
        key={key}
      >
        <ListItemIcon>{icon}</ListItemIcon>
      </Link>
      <Link
        href={`#/${url}`}
        underline="none"
        style={{ color: "white", textShadow: "1px 1px black" }}
        key={key + "1"}
      >
        <ListItemText primary={key} />
      </Link>
    </ListItemButton>
  )
}

function getListItem(
  menuName: string,
  icon: JSX.Element,
  onClick: () => void,
  expandMenu: boolean,
  theme: Theme
) {
  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        ":hover": {
          backgroundColor: theme.palette.success.dark
        }
      }}
    >
      <ListItemIcon style={{ color: theme.palette.text.primary }}>
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={menuName}
        style={{
          color: theme.palette.text.primary
        }}
      />
      {expandMenu ? (
        <ExpandLess style={{ color: theme.palette.text.primary }} />
      ) : (
        <ExpandMore style={{ color: theme.palette.text.primary }} />
      )}
    </ListItemButton>
  )
}

function getCollapseMenuElement(
  menuItems: IMenuItem[],
  isCollapse: boolean,
  theme: Theme
): JSX.Element {
  const location = useLocation()
  const pathname = location.pathname.split("/")[1]

  return (
    <Collapse in={isCollapse} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {menuItems.map((item) => {
          return (
            <Link
              href={`#/${item.url}`}
              underline="none"
              style={
                pathname === item.url ? { color: "white" } : { color: "black" }
              }
              key={item.key}
            >
              <ListItemButton
                onClick={() => clearParam(item.url)}
                style={{ color: "white" }}
                sx={{
                  pl: 4,
                  backgroundColor: theme.palette.primary.light,
                  ":hover": {
                    backgroundColor: theme.palette.success.contrastText
                  }
                }}
              >
                <ListItemText
                  primary={item.key}
                  style={{
                    color: theme.palette.secondary.contrastText
                  }}
                />
              </ListItemButton>
            </Link>
          )
        })}
      </List>
    </Collapse>
  )
}

export default NavBar
