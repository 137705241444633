import "../../css/Master/Customer.css"
import { styled } from "@mui/material/styles"
import { PersonRemove, Edit, Add } from "@mui/icons-material"
import CircularProgress from "@mui/material/CircularProgress"
import Table from "@mui/material/Table"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import TablePagination from "@mui/material/TablePagination"
import { GET_DEPARTMENT, GET_EMPLOYEE } from "../../common/Query/MasterQuery"
import {
  ADD_EMPLOYEE,
  DELETE_EMPLOYEE,
  UPDATE_EMPLOYEE
} from "../../common/Mutation/MasterMutation"
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  ThemeProvider,
  useTheme
} from "@mui/material"
import { useQuery, useMutation } from "@apollo/client"
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useMemo,
  useState
} from "react"
import "../../css/Dashboard/Customer.css"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

export interface IEmployee {
  firstName: string
  lastName: string
  factory: string
  departmentId: number
  employeeId?: number
  position: string
}

const positionList: string[] = [
  "Leader",
  "Supervisor",
  "Asst.Mgr",
  "Manager",
  "FM",
  "GM",
  "User"
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.secondary.contrastText,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.black
  },

  "td, th": {
    border: 0
  }
}))

const departmentMap = {
  0: "",
  3: "PLANNING",
  4: "PRODUCTION",
  6: "QUALITY",
  7: "ENGINEERING",
  12: "MAINTENANCE",
  1: "PURCHASE",
  8: "WAREHOUSE",
  15: "PLATING",
  13: "HR",
  14: "ACCOUNTING",
  16: "IT",
  17: "SYSTEM INTEGRATION",
  19: "PROJECT ENGINEER"
}
const reverseDepartmentMap = Object.fromEntries(
  Object.entries(departmentMap).map(([key, value]) => [value, parseInt(key)])
)

export const getEmployeeListTableCells = (
  employees: IEmployee[],
  page: number,
  rowsPerPage: number
): JSX.Element[] => {
  return employees
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((employee: IEmployee, index: number) => {
      const { departmentId } = employee
      const department =
        departmentMap[departmentId as keyof typeof departmentMap]
      return (
        <StyledTableRow key={index}>
          <StyledTableCell align="left" component="th">
            {employee.firstName}
          </StyledTableCell>
          <StyledTableCell align="left">{employee.lastName}</StyledTableCell>
          <StyledTableCell align="left">{employee.factory}</StyledTableCell>
          <StyledTableCell align="left">{department}</StyledTableCell>
        </StyledTableRow>
      )
    })
}
interface IEmployeeProps {
  setOpen: Dispatch<SetStateAction<boolean>>
  open: boolean
  refetch: () => void
  employee?: IEmployee[]
}

const pageTitle = "Employee"

export const AddEmployee: React.FC<IEmployeeProps> = (
  props: IEmployeeProps
) => {
  const { open, setOpen, refetch } = props
  const [addEmployee, { loading }] = useMutation(ADD_EMPLOYEE)
  const { data } = useQuery(GET_DEPARTMENT)
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [factory, setFactory] = useState<string>("")
  const [department, setDepartment] = useState<string | null>("")
  const [position, setPosition] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  let departmentList: string[] = []
  if (data) {
    departmentList = data.department.map(
      (e: { departmentName: string }) => e.departmentName
    )
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!firstName || !lastName || !factory || !department || !position) {
      setError(true)
      return swal("Error", `กรุณากรอกข้อมูลให้ครบถ้วน`, "warning")
    }
    try {
      const departmentId = data.department.find(
        (e: { departmentName: string }) => e.departmentName === department
      ).departmentId
      await addEmployee({
        variables: { firstName, lastName, factory, departmentId, position }
      })
      refetch()
      swal("Success", "Add Employee Successful", "success")
      setFirstName("")
      setOpen(false)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Sarabun" }}>
          Add Employee
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} marginTop={2}>
            <Grid item sm={6} xs={12}>
              <ThemedTextField
                fullWidth
                value={firstName}
                label="First Name"
                onChange={(e) => setFirstName(e.target.value)}
                error={!firstName && error}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ThemedTextField
                fullWidth
                value={lastName}
                error={!lastName && error}
                label="Last Name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                disablePortal
                options={["1", "2"]}
                onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Factory"
                    value={factory}
                    error={!factory && error}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                disablePortal
                options={departmentList}
                onChange={(e, newValue) => setDepartment(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Department"
                    value={department}
                    error={!department && error}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                disablePortal
                value={position}
                options={positionList}
                onChange={(e, newValue) => setPosition(newValue as string)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Position"
                    value={position}
                    error={!position && error}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              borderRadius: "5px",
              marginLeft: "16px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton contrast variant="text" onClick={() => setOpen(false)}>
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
const UpdateEmployee: React.FC<IEmployeeProps> = (props: IEmployeeProps) => {
  const { open, setOpen, refetch } = props
  const [updateEmployee, { loading }] = useMutation(UPDATE_EMPLOYEE)
  const { data } = useQuery(GET_DEPARTMENT)
  const { data: dataEmployee } = useQuery(GET_EMPLOYEE)
  const [error, setError] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string | null>("")
  const [lastName, setLastName] = useState<string | null>("")
  const [updateFirstName, setUpdateFirstName] = useState<string | null>("")
  const [updateLastName, setUpdateLastName] = useState<string | null>("")
  const [factory, setFactory] = useState<string>("")
  const [position, setPosition] = useState<string>("")
  const [department, setDepartment] = useState<string | null>("")
  const [employeeId, setEmployeeId] = useState<number | undefined>(undefined)
  let departmentList: string[] = []

  if (data) {
    departmentList = data.department.map(
      (e: { departmentName: string }) => e.departmentName
    )
  }

  const reset = () => {
    setEmployeeId(undefined)
  }

  useMemo(() => {
    if (firstName && lastName) {
      const findEmployee = dataEmployee?.employee?.find((entry: IEmployee) => {
        return (
          entry.firstName === firstName &&
          entry.lastName === lastName &&
          entry.employeeId === employeeId
        )
      })
      if (findEmployee) {
        const department = data.department.find(
          (val: { departmentId: number }) =>
            val.departmentId === findEmployee.departmentId
        )
        setUpdateFirstName(findEmployee.firstName)
        setUpdateLastName(findEmployee.lastName)
        setDepartment(department.departmentName)
        setFactory(findEmployee.factory)
        setPosition(findEmployee.position)
      }
    }
  }, [firstName, lastName, employeeId])

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (
      !updateFirstName ||
      !updateLastName ||
      !factory ||
      !department ||
      !employeeId
    ) {
      setError(true)
      if (!employeeId) swal("Error", `ไม่พบ Employee Id`, "error")
      return
    }
    try {
      const findDepartment = data.department.find(
        (val: { departmentName: string }) => val.departmentName === department
      )

      await updateEmployee({
        variables: {
          firstName: updateFirstName,
          lastName: updateLastName,
          factory,
          departmentId: findDepartment.departmentId,
          employeeId
        }
      })
      swal("Success", "Update Employee Successful", "success")
      reset()
      refetch()
      setOpen(false)
    } catch (error) {
      swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
          reset()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Sarabun" }}>
          Update Employee
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} marginTop={2}>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                disablePortal
                options={
                  Array.from(
                    new Set(props.employee?.map((e) => e.firstName))
                  ) || [""]
                }
                onChange={(e, newValue) => setFirstName(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="First Name"
                    value={firstName}
                    error={!firstName && error}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                disablePortal
                disabled={!firstName}
                options={
                  props.employee?.filter((e) => e.firstName === firstName) || []
                }
                getOptionLabel={(option) => option.lastName}
                onChange={(e, newValue) => {
                  setLastName(newValue?.lastName || "")
                  setEmployeeId(newValue?.employeeId)
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Last Name"
                    value={lastName}
                    error={!lastName && error}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContent>
          <Grid container spacing={2} marginTop={2}>
            <Grid item sm={6} xs={12}>
              <ThemedTextField
                fullWidth
                label="First Name"
                value={updateFirstName}
                error={!updateFirstName && error}
                onChange={(e) => setUpdateFirstName(e.target.value)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ThemedTextField
                fullWidth
                label="Last Name"
                value={updateLastName}
                error={!updateLastName && error}
                onChange={(e) => setUpdateLastName(e.target.value)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                disablePortal
                options={["1", "2"]}
                value={factory}
                onChange={(e, newValue) => setFactory(newValue ? newValue : "")}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Factory"
                    value={factory}
                    error={!factory && error}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                disablePortal
                options={departmentList}
                value={department}
                onChange={(e, newValue) => setDepartment(newValue)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Department"
                    value={department}
                    error={!department && error}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                disablePortal
                value={position}
                options={positionList}
                onChange={(e, newValue) => setPosition(newValue as string)}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Position"
                    value={position}
                    error={!position && error}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              borderRadius: "5px",
              marginLeft: "16px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton contrast variant="text" onClick={() => setOpen(false)}>
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const DeleteEmployee: React.FC<IEmployeeProps> = (props: IEmployeeProps) => {
  const { open, setOpen, refetch } = props
  const [updateEmployee, { loading }] = useMutation(DELETE_EMPLOYEE)
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [employeeId, setEmployeeId] = useState<number | undefined>(undefined)

  const [error, setError] = useState<boolean>(false)
  const [selectedDepartment, setSelectedDepartment] = useState<string | null>(
    null
  )

  const { data } = useQuery(GET_EMPLOYEE)
  const employee = data ? data.employee : []
  const departmentList: string[] = Array.from(
    new Set(
      employee.map((employee: { departmentId: number }) => {
        return departmentMap[
          employee.departmentId as keyof typeof departmentMap
        ]
      })
    )
  )

  const reset = () => {
    setEmployeeId(undefined)
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!firstName || !lastName || !employeeId) {
      setError(true)
      if (!employeeId) swal("Error", `ไม่พบ Employee Id`, "error")
      return
    }
    if (firstName && lastName && employeeId) {
      try {
        await updateEmployee({
          variables: { employeeId }
        })
        swal("Success", "Delete Employee Successful", "success")
        reset()
        setOpen(false)
        refetch()
      } catch (error) {
        setError(true)
        swal("Error", `${(error as Error).message}`, "warning")
      }
    } else {
      swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "warning")
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
          reset()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Sarabun" }}>
          Delete Employee
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} marginTop={2}>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                disablePortal
                options={departmentList}
                value={selectedDepartment}
                onChange={(e, newValue) => {
                  setSelectedDepartment(newValue)
                  setFirstName("")
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Department"
                    value={selectedDepartment}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                disablePortal
                options={
                  Array.from(
                    new Set(
                      props.employee
                        ?.filter(
                          (e) =>
                            e.departmentId ===
                            reverseDepartmentMap[selectedDepartment as string]
                        )
                        .map((e) => e.firstName)
                    )
                  ) || [""]
                }
                onChange={(e, newValue) =>
                  setFirstName(newValue ? newValue : "")
                }
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="First Name"
                    value={firstName}
                    error={!firstName && error}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                disabled={!firstName}
                disablePortal
                options={
                  props.employee?.filter(
                    (e) =>
                      e.firstName === firstName &&
                      e.departmentId ===
                      reverseDepartmentMap[selectedDepartment as string]
                  ) || []
                }
                getOptionLabel={(option) => {
                  return option.lastName
                }}
                onChange={(e, newValue) => {
                  setLastName(newValue?.lastName || "")
                  setEmployeeId(newValue?.employeeId)
                }}
                renderInput={(params) => (
                  <ThemedTextField
                    {...params}
                    label="Last Name"
                    value={lastName}
                    error={!lastName && error}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignSelf: "start" }}>
          <ThemedLoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            style={{
              borderRadius: "5px",
              marginLeft: "16px"
            }}
          >
            SUBMIT
          </ThemedLoadingButton>
          <ThemedButton contrast variant="text" onClick={() => setOpen(false)}>
            CANCEL
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const GetEmployeeList: React.FC = (): ReactElement => {
  const theme = useTheme()
  const { loading, data, refetch } = useQuery(GET_EMPLOYEE)
  const [page, setPage] = useState<number>(0)
  const [openAddEmployee, setOpenAddEmployee] = useState<boolean>(false)
  const [openUpdateEmployee, setOpenUpdateEmployee] = useState<boolean>(false)
  const [openDeleteEmployee, setOpenDeleteEmployee] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [selectedDepartment, setSelectedDepartment] = useState<string>("")

  if (loading)
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ padding: "10px" }}
      >
        <CircularProgress />
      </Grid>
    )

  let { employee } = data ? data : { employee: [] }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  if (search) {
    employee = employee.filter(
      (emp: { firstName: string }) => emp.firstName === search
    )
  }

  if (selectedDepartment) {
    employee = employee.filter(
      (employee: { departmentId: number }) =>
        reverseDepartmentMap[selectedDepartment as string] ===
        employee.departmentId
    )
  }

  const emptyRows: number =
    rowsPerPage - Math.min(rowsPerPage, employee.length - page * rowsPerPage)

  const employeeList = getEmployeeListTableCells(employee, page, rowsPerPage)

  const searchList: string[] = Array.from(
    new Set(
      employee.map((employee: { firstName: string }) => employee.firstName)
    )
  )
  const departmentList: string[] = Array.from(
    new Set(
      employee.map((employee: { departmentId: number }) => {
        return departmentMap[
          employee.departmentId as keyof typeof departmentMap
        ]
      })
    )
  )

  return (
    <>
      <Grid container spacing={2} width={"98%"} padding={2} alignItems="center">
        <Grid
          container
          item
          spacing={2}
          width={"98%"}
          xs={6}
          sm={6}
          alignItems="center"
        >
          <Grid item xs={12} sm={8}>
            <Autocomplete
              id="search"
              freeSolo
              value={search}
              options={searchList}
              onChange={(e, newValue) => {
                setPage(0)
                setSearch(newValue ? newValue : "")
              }}
              renderInput={(params) => (
                <ThemedTextField {...params} label="Employee" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Autocomplete
              id="search-department"
              freeSolo
              value={selectedDepartment}
              options={departmentList}
              onChange={(e, newValue) => {
                setPage(0)
                setSelectedDepartment(newValue ? newValue : "")
              }}
              renderInput={(params) => (
                <ThemedTextField {...params} label="Department" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ThemedButton
              variant="contained"
              startIcon={<Add />}
              onClick={() => setOpenAddEmployee(true)}
            >
              ADD EMPLOYEE
            </ThemedButton>
            <AddEmployee
              open={openAddEmployee}
              setOpen={setOpenAddEmployee}
              refetch={refetch}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          width={"98%"}
          xs={6}
          sm={6}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item xs={6} sm="auto">
            <ThemedButton
              contrast
              variant="outlined"
              startIcon={<Edit />}
              onClick={() => setOpenUpdateEmployee(true)}
            >
              UPDATE EMPLOYEE
            </ThemedButton>
            <UpdateEmployee
              open={openUpdateEmployee}
              setOpen={setOpenUpdateEmployee}
              employee={employee}
              refetch={refetch}
            />
          </Grid>
          <Grid item xs={6} sm="auto">
            <ThemedButton
              color="primary"
              variant="contained"
              startIcon={<PersonRemove />}
              onClick={() => setOpenDeleteEmployee(true)}
            >
              DELETE EMPLOYEE
            </ThemedButton>
            <DeleteEmployee
              open={openDeleteEmployee}
              setOpen={setOpenDeleteEmployee}
              employee={employee}
              refetch={refetch}
            />
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Factory</StyledTableCell>
              <StyledTableCell>Department</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeList}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, employee.length]}
          component="div"
          count={employee.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ backgroundColor: theme.palette.warning.light }}
        />
      </TableContainer>
    </>
  )
}

const Employee: React.FC = () => {
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Master}
        menuItemName={Object.keys(menuItemList)[0]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard title="Employee" subTitle="About detail employee" />
        <GetEmployeeList />
      </ThemedCard>
    </ThemeProvider>
  )
}

export default Employee
