import { gql } from "@apollo/client"

export const DownloadFile = gql`
  query DownloadFile(
    $department: String!
    $docNo: String!
    $docName: String!
    $revision: String!
  ) {
    downloadFile(
      department: $department
      docNo: $docNo
      docName: $docName
      revision: $revision
    ) {
      department
      docName
      docNo
      revision
      url
    }
  }
`

export const Get_Document = gql`
  query Pmwis {
    pmwis {
      department
      docNo
      docName
      revision
      remarks
      record
      date
      id
    }
  }
`
