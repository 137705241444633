import { Autocomplete } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import ThemedTextField from "./ThemedComponents/ThemedTextField"
import { useLine } from "./customHook/useLine"

interface IProps {
  setLine: Dispatch<SetStateAction<string>>
  line: string
  error?: boolean
}
const AutocompleteLine = (props: IProps): JSX.Element => {
  const { line, setLine, error } = props
  const lineList = useLine()
  return (
    <Autocomplete
      fullWidth
      id="line"
      value={line}
      options={lineList}
      disablePortal
      onChange={(e, value) => setLine(value as string)}
      renderInput={(params) => (
        <ThemedTextField {...params} label="Line" error={!line && error} />
      )}
    />
  )
}
export default AutocompleteLine
