import { useTheme } from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useRef } from "react"
import { useQuery } from "@apollo/client"
import { GET_NUMBER_REPAIR_YEAR } from "../../../../common/Query/MaintenanceQuery"
import moment from "moment"

interface IInvoices {
  complete: number
  unComplete: number
}

interface IRawData {
  month: string
  complete: string
  unComplete: string
}
export const RepairNotiDashboard = (props: {
  lineData: string
  factory: string
  year: string
}): JSX.Element => {
  const { lineData, factory, year } = props

  const { data: repairData } = useQuery(GET_NUMBER_REPAIR_YEAR, {
    variables: { year: year, line: lineData || "", factory: factory }
  })

  const rawData = repairData?.getNumberOfRepairInvoicesYear || []

  const totalComplete = rawData.reduce(
    (prev: number, curr: IInvoices) => prev + curr.complete,
    0
  )

  const totalUnComplete = rawData.reduce(
    (prev: number, curr: IInvoices) => prev + curr.unComplete,
    0
  )

  const theme = useTheme()
  const pieOptions: Highcharts.Options = {
    chart: {
      type: "pie",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main,
      height: 400
    },
    title: {
      text: `Monthly ${moment(new Date()).format("YYYY-MM")}`,
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
    },
    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    series: [
      {
        name: "Brands",
        type: "pie",
        data: [
          {
            name: "Complete",
            y: totalComplete || 0
          },
          {
            name: "Un Complete",
            y: totalUnComplete || 0
          }
        ]
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }

  const columnOptions: Highcharts.Options = {
    chart: {
      type: "column",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "กราฟเปรียบเทียบใบแจ้งซ่อม",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },

    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    },
    xAxis: {
      categories: rawData.map((val: IRawData) => val.month),
      labels: {
        x: -10,
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "10px"
        }
      }
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "Total",
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      }
    },
    series: [
      {
        type: "column",
        name: "Complete",
        data: rawData.map((val: IRawData) => val.complete)
      },
      {
        type: "column",
        name: "Un complete",
        data: rawData.map((val: IRawData) => val.unComplete)
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  return (
    <>
      <div style={{ display: "inline-block" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={columnOptions}
          ref={chartComponentRef}
        />
      </div>
      <div style={{ display: "inline-block" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={pieOptions}
          ref={chartComponentRef}
        />
      </div>
    </>
  )
}
