import { Grid, Table } from "@mui/material"
import { TableSignature } from "./TableSignature"
import { DataProductionPlan } from "../../ProductionPlanReport"
import { ApproverProductionPlan } from "./ApproverProductionPlan"
import { ApprovePlanning } from "./ApprovePlanning"
import { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { GET_EMPLOYEE } from "../../../../common/Query/MasterQuery"
import { IEmployee } from "../../../Master/Employee"

interface IApproveSignatureProps {
  status: boolean
  setStatus: (status: boolean) => void
  line: string
  revision: string
  date: string
  factory: string
  masterPlan: DataProductionPlan[]
  diffHours: number
  productionPlanReportByRevision: DataProductionPlan[]
}

export const ApproveProductionPlan: React.FC<IApproveSignatureProps> = (
  props: IApproveSignatureProps
) => {
  const {
    status,
    setStatus,
    line,
    revision,
    date,
    factory,
    masterPlan,
    productionPlanReportByRevision = [],
    diffHours
  } = props
  const [mpApproveName, setMpApproveName] = useState<string>("")
  const { data: employees } = useQuery(GET_EMPLOYEE)
  useEffect(() => {
    if (employees?.employee) {
      const employee: IEmployee = employees.employee.find(
        (emp: IEmployee) =>
          emp.position === "Supervisor" &&
          emp.factory === factory &&
          emp.departmentId === 12
      )
      setMpApproveName(employee?.firstName)
    }
  }, [employees, factory])
  return (
    <Grid container spacing={2} marginTop={3}>
      <Grid item xs={2}>
        <ApprovePlanning
          status={status}
          setStatus={setStatus}
          line={line}
          revision={revision}
          date={date}
          factory={factory}
          masterPlan={masterPlan}
          productionPlanReportByRevision={productionPlanReportByRevision}
        />
      </Grid>
      <Grid item xs={2}>
        <TableSignature factory={factory} title={"PRODUCTION"} name={"อนุชา"} />
      </Grid>
      <Grid item xs={2}>
        <Table>
          <TableSignature
            factory={factory}
            title={"ENGINEER"}
            name={"นรินทร"}
          />
        </Table>
      </Grid>
      <Grid item xs={2}>
        <TableSignature factory={factory} title={"QUANTITY"} name={"ไชยยศ"} />
      </Grid>
      <Grid item xs={2}>
        <TableSignature
          factory={factory}
          title={"MAINTENANCE"}
          name={mpApproveName}
        />
      </Grid>
      <Grid item xs={2}>
        <ApproverProductionPlan diffHours={diffHours} />
      </Grid>
    </Grid>
  )
}
