import { ThemeProvider, useTheme } from "@mui/styles"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { CardHeader, Grid } from "@mui/material"
import AllSheetDashBoard from "./Component/DashBoardKPI/AllSheetDashBoard"
import GraphDefectParato from "./Component/DashBoardKPI/Graph/GraphDefectParato"
import GraphDefectProblem from "./Component/DashBoardKPI/Graph/GraphDefectProblem"
import Status from "./Component/DashBoardKPI/Graph/GraphStatus"
import ParetoMachine from "./Component/DashBoardKPI/Graph/GraphDefectParetoMachine"
const pageTitle = "PM DashBoard KPI"
const PMDashBoard: React.FC = () => {
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.engineer}
        menuItemName={Object.keys(menuItemList)[5]}
        menuPageName={pageTitle}
      />
      <ThemedCard sx={{ padding: "10px" }}>
        <CardHeader
          titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
          title="PM DashBoard KPI"
          subheaderTypographyProps={{ fontFamily: "Sarabun" }}
          subheader="Check PM Engineer KPI here"
        />
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <AllSheetDashBoard />
          </Grid>
          <Grid item sm={6}>
            <Status />
          </Grid>
          <Grid item sm={6}>
            <GraphDefectParato />
          </Grid>
          <Grid item sm={12}>
            <GraphDefectProblem />
          </Grid>
          <Grid item sm={12}>
            <ParetoMachine />
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default PMDashBoard
