import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses
} from "@mui/material"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { useEffect, useState } from "react"
import moment from "moment"
import { useQuery } from "@apollo/client"
import { GET_PRODUCTION_IOT } from "../../common/Query/Iot"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center",
    border: "1px solid black"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Sarabun",
    textAlign: "center",
    border: "1px solid black"
  }
}))
interface IProductionReportIot {
  machine: string
  quantity: number
  jobOrder: string
  partNo: string
  process: string
  planStartTime: string
  planEndTime: string
  createdDate: string
  updatedDate: string
}

const iotProductionReport = (): JSX.Element => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment().format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState<string>(moment().format("YYYY-MM-DD"))
  const [dataRow, setDataRow] = useState<IProductionReportIot[]>([])
  const { data } = useQuery(GET_PRODUCTION_IOT, {
    variables: {
      dateFrom,
      dateTo
    }
  })

  useEffect(() => {
    if (data?.getProductionReportIot) {
      setDataRow(data?.getProductionReportIot)
    }
  }, [data])

  return (
    <div>
      <ThemedCard>
        <Grid container spacing={2} width={"98%"} margin={2}>
          <Grid item xs={12} sm={12}>
            <h1>IOT Production Report</h1>
          </Grid>
          <Grid item xs={12} sm={12} container spacing={2}>
            <Grid item sm={3}>
              <ThemedTextField
                error={!dateFrom}
                InputLabelProps={{
                  shrink: true
                }}
                id="Date Range"
                label="From"
                type="date"
                value={dateFrom}
                fullWidth
                onChange={(event: {
                  target: { value: React.SetStateAction<string> }
                }) => {
                  setDateFrom(event.target.value)
                }}
              />
            </Grid>
            <Grid item sm={3}>
              <ThemedTextField
                error={!dateTo}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                value={dateTo}
                label="To"
                id="Date To"
                type="date"
                onChange={(event: {
                  target: { value: React.SetStateAction<string> }
                }) => {
                  setDateTo(event.target.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={7}>
                    Production Report IOT
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Machine</StyledTableCell>

                  <StyledTableCell>Job Order</StyledTableCell>
                  <StyledTableCell>Part No</StyledTableCell>
                  <StyledTableCell>Process</StyledTableCell>
                  <StyledTableCell>Quantity</StyledTableCell>
                  <StyledTableCell>Start Time</StyledTableCell>
                  <StyledTableCell>End Time</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataRow.map((row, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>{row.machine}</StyledTableCell>
                    <StyledTableCell>{row.jobOrder}</StyledTableCell>
                    <StyledTableCell>{row.partNo}</StyledTableCell>
                    <StyledTableCell>{row.process}</StyledTableCell>
                    <StyledTableCell>{row.quantity}</StyledTableCell>
                    <StyledTableCell>{row.planStartTime}</StyledTableCell>
                    <StyledTableCell>{row.planEndTime}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </ThemedCard>
    </div>
  )
}
export default iotProductionReport
