import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"

interface IPdfViewerProps {
  url: string
  showPdf: boolean
  setShowPdf: (showPdf: boolean) => void
}
const DialogShowPdf = (props: IPdfViewerProps): JSX.Element => {
  const { url, setShowPdf, showPdf } = props

  return (
    <>
      <Dialog open={showPdf} maxWidth="lg" onClose={() => setShowPdf(false)}>
        <DialogContent>
          <DialogTitle>PDF</DialogTitle>
          <iframe src={url} width={"1080px"} height={"700px"} />
        </DialogContent>
        <DialogActions>
          <ThemedButton
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => {
              setShowPdf(false)
            }}
          >
            Close
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default DialogShowPdf
