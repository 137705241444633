import React, {
  ChangeEvent,
  MouseEvent,
  ReactElement,
  SetStateAction,
  useState
} from "react"
import {
  Box,
  Grid,
  Autocomplete,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Paper,
  TableContainer,
  TablePagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  useTheme
} from "@mui/material"
import QrReader from "react-qr-reader"
import swal from "sweetalert"
import {
  GET_TEST_SCAN_WH,
  GET_WH_LOCATION_SCAN,
  GET_WH_TRANSPORTS
} from "../../common/Query/WarehouseQuery"
import { useLazyQuery, useMutation } from "@apollo/client"
import { alpha } from "@mui/material/styles"
import DeleteIcon from "@mui/icons-material/Delete"
import FilterListIcon from "@mui/icons-material/FilterList"
import PropTypes from "prop-types"
import { CREATE_LOCATION_SCAN } from "../../common/Mutation/WarehouseMutation"
import { PageLabel } from "../../common/Resources/PageLabel"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import ThemedButton from "../../common/Resources/ThemedComponents/ThemedButton"
interface DataTestScannerWH {
  partNo: string
  pdLotNo: string
  jobOrder: string
  quantity: number
  boxNo: number
}

interface DataWHLocationScan {
  partNo: string
  pdLotNo: string
  jobOrder: string
  quantity: number
  boxNo: number
  location: string
}

const headCells = [
  {
    id: "partNo",
    numeric: false,
    disablePadding: true,
    label: "Part No"
  },
  {
    id: "jobOrder",
    numeric: true,
    disablePadding: false,
    label: "Job Order"
  },
  {
    id: "pdLotNo",
    numeric: true,
    disablePadding: false,
    label: "Pd Lot No"
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity"
  },
  {
    id: "boxNo",
    numeric: true,
    disablePadding: false,
    label: "Box No"
  }
]

const pageTitle = "WH Location Scan"

const WhLocationScan: React.FC = (): ReactElement => {
  const [isStream, setIsStream] = useState(false)
  const [dataScan, setDataScan] = useState("")
  const [pdLotNo, setPdLotNo] = useState("")
  const [jobOrder, setJobOrder] = useState("")
  const [partNo, setPartNo] = useState("")
  const [location, setLocation] = useState<string | null>("")
  const [error, setError] = useState<boolean>(false)
  const [selected, setSelected] = React.useState<
    Array<DataTestScannerWH> | DataTestScannerWH[]
  >([])
  const [dataSelect, setDataSelect] = useState<Array<DataTestScannerWH>>([])
  const [page, setPage] = React.useState(0)
  // const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [getTestScanWh] = useLazyQuery(GET_TEST_SCAN_WH)
  const [getWhTransports] = useLazyQuery(GET_WH_TRANSPORTS)
  const [getWhLocationScan] = useLazyQuery(GET_WH_LOCATION_SCAN)
  const [createLocationScan, { loading }] = useMutation(CREATE_LOCATION_SCAN)

  const locationList: Array<string> = [
    "A1",
    "A2",
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "B1",
    "B2",
    "B3",
    "B4",
    "B5",
    "B6"
  ]
  let newCheck: Array<DataWHLocationScan> = []

  const handleScan = (data: SetStateAction<string> | null): void => {
    if (data) {
      setDataScan(data)
      check()
    }
  }
  const handleError = (err: string) => {
    console.error(err)
  }

  const check = async () => {
    const newSplit = dataScan.split("|")
    if (newSplit.length !== 6) {
      swal("warning!", "มีปัญหากับ format ของ QR Code!", "warning")
    } else {
      setPdLotNo(`${newSplit[5]}|${newSplit[1]}`)
      setJobOrder(`${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`)
      setPartNo(newSplit[1])
      let dataTestScan: DataTestScannerWH[] = []
      dataTestScan = await (
        await getTestScanWh({
          variables: {
            data: {
              partNo: newSplit[1],
              jobOrder: `${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`
            }
          }
        })
      ).data.testScannerWH
      if (dataTestScan.length !== 0) {
        checkScanOut(dataTestScan)
      } else {
        swal("warning", "partNO ยิงแล้ว", "warning")
      }
    }
  }

  const checkScanOut = async (dataTestScan: Array<DataTestScannerWH>) => {
    const newSplit = dataScan.split("|")
    const scanOut = await (
      await getWhTransports({
        variables: {
          data: {
            partNo: newSplit[1],
            jobOrder: `${newSplit[0]}|${newSplit[1]}|${newSplit[2]}`
          }
        }
      })
    ).data.getWHtransports
    const newDataTest = dataTestScan.slice()
    if (scanOut.length !== 0) {
      const newDataScan = dataTestScan.map((e) => e.boxNo)
      const value = dataTestScan.map((e) => e.boxNo)
      value.forEach((e, index) => {
        if (scanOut[index]) {
          const dataUnit = newDataScan.indexOf(scanOut[index])
          newDataTest.splice(dataUnit, 1)
          newDataScan.splice(dataUnit, 1)
        }
      })
    }

    checkWareHouse(newDataTest)
  }

  const checkWareHouse = async (newDataTest: Array<DataTestScannerWH>) => {
    const newSplit = dataScan.split("|")
    newCheck = await (await getWhLocationScan()).data.getWHLocationScan
    const filterDataNewCheck = newCheck.filter(
      (e) =>
        e.partNo === newSplit[1] &&
        e.jobOrder === `${newSplit[0]}|${newSplit[1]}|${newSplit[2]}` &&
        e.pdLotNo === `${newSplit[5]}|${newSplit[1]}`
    )
    const newDataLocation = newDataTest.filter(
      (e) =>
        e.partNo === newSplit[1] &&
        e.jobOrder === `${newSplit[0]}|${newSplit[1]}|${newSplit[2]}` &&
        e.pdLotNo === `${newSplit[5]}|${newSplit[1]}`
    )
    if (filterDataNewCheck.length !== 0) {
      const data = filterDataNewCheck.map((e) => e.boxNo)
      const newDataScan = newDataLocation.map((e) => e.boxNo)
      const value = newDataLocation.map((e) => e.boxNo)
      for await (const index of value) {
        if (data[index - 1]) {
          const dataUnit = newDataScan.indexOf(data[index - 1])
          newDataLocation.splice(dataUnit, 1)
          newDataScan.splice(dataUnit, 1)
        }
      }
    }
    setDataSelect(newDataLocation)
  }

  const EnhancedTableHead = (props: {
    numSelected: number
    rowCount: number
  }) => {
    const { numSelected, rowCount } = props

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              inputProps={{
                "aria-label": "select all desserts"
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired
  }

  const EnhancedTableToolbar = (props: { numSelected: number }) => {
    const { numSelected } = props

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              )
          })
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Nutrition
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    )
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
  }

  const handleClick = (
    event: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>,
    row: DataTestScannerWH
  ) => {
    const selectedIndex = selected.indexOf(row)
    let newSelected: Array<DataTestScannerWH> = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (row: DataTestScannerWH) => selected.indexOf(row) !== -1
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataSelect.length) : 0

  const onSave = async () => {
    if (!dataScan) {
      setError(true)
      return swal("ERROR", "กรุณาใส่ Scanner", "error")
    }
    newCheck = await (await getWhLocationScan()).data.getWHLocationScan
    const checkOrder = newCheck
      .filter((e) => e.location === location)
      .map((e) => e.partNo)
      .reduce(
        (prev: Array<string>, cur: string) =>
          prev.includes(cur) ? prev : prev.concat(cur),
        []
      ).length
    if (
      location === "A1" ||
      location === "A2" ||
      location === "A3" ||
      location === "A4" ||
      location === "A5" ||
      location === "A6" ||
      location === "A7"
    ) {
      if (checkOrder >= 3) {
        swal("warning", `location ${location} เต็มแล้ว`, "warning")
        return
      }
    }
    if (
      location === "B1" ||
      location === "B2" ||
      location === "B3" ||
      location === "B4" ||
      location === "B5" ||
      location === "B6"
    ) {
      if (checkOrder >= 4) {
        swal("Warning", `location ${location} เต็มแล้ว`, "warning")
        return
      }
    }
    if (!location) {
      swal("Warning", "กรุณาใส่ Location", "warning")
      return
    }
    if (selected.length === 0) {
      swal("Warning", "กรุณาใส่ เลือกข้อมูล", "warning")
      return
    }
    selected.forEach(async (element) => {
      const dataLocation = {
        partNo: element.partNo,
        pdLotNo: element.pdLotNo,
        jobOrder: element.jobOrder,
        quantity: element.quantity,
        boxNo: element.boxNo,
        location: location
      }
      const res = await createLocationScan({
        variables: { dataLocation }
      })
      const status = res.data.creatLocationScan.status
      if (status === "success")
        swal("Success", "คุณบันทึกค่า สำเร็จ", "success")
      if (status === "error") swal("ERROR", "คุณบันทึกค่า ไม่สำเร็จ", "warning")
    })
    const value = dataSelect.map((e) => e.boxNo)
    value.forEach((value, index) => {
      if (selected[index]) {
        const dataUnit = dataSelect.indexOf(selected[index])
        dataSelect.splice(dataUnit, 1)
      }
    })
    setSelected([])
  }

  const breakQRCode = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    check()
  }
  const theme = useTheme()
  return (
    <Box style={{ marginLeft: "22px" }}>
      <PageLabel
        menuItem={menuItemList.Warehouse}
        menuItemName={Object.keys(menuItemList)[6]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <Box onSubmit={breakQRCode} component="form">
          <Accordion
            expanded={isStream}
            onChange={() => setIsStream(!isStream)}
            style={{
              marginBottom: "5px",
              marginTop: "10px"
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#ffffff", display: "none" }}
            />

            <AccordionDetails>
              {isStream ? (
                <>
                  <Grid container justifyContent={"center"}>
                    <div style={{ width: "500px" }}>
                      <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: "70%" }}
                      />
                    </div>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </AccordionDetails>
          </Accordion>
          <PageLabelCard
            title="Warehouse Location Scan"
            subTitle="detail about Warehouse Location Scan"
          />
          <Grid container spacing={2} width={"98%"} margin={2}>
            <Grid item xs={12} sm={12}>
              <Switch
                checked={isStream}
                onChange={(event) =>
                  setIsStream(event.target.value ? !isStream : isStream)
                }
                name="gilad"
              />
              <label style={{ fontSize: "14px" }}>
                Active Camera{" "}
                <CameraAltIcon style={{ verticalAlign: "middle" }} />
              </label>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                id="scanner"
                label="Scanner"
                value={dataScan}
                error={!dataScan && error}
                onChange={(e) => setDataScan(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                fullWidth
                id="partNo"
                label="Part No."
                value={partNo}
                disabled
                style={{ backgroundColor: "gainsboro" }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ThemedTextField
                fullWidth
                id="pdLotNo"
                value={pdLotNo}
                label="Pd Lot No."
                disabled
                style={{ backgroundColor: "gainsboro" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemedTextField
                fullWidth
                id="jobOrder"
                value={jobOrder}
                label="JobOrder"
                disabled
                style={{ backgroundColor: "gainsboro" }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                disablePortal
                id="status"
                value={location}
                options={locationList}
                onChange={(e, newValue) => setLocation(newValue)}
                renderInput={(params) => (
                  <ThemedTextField {...params} label="Location" />
                )}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <Paper sx={{ width: "100%", mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="medium"
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      rowCount={dataSelect.length}
                    />
                    <TableBody>
                      {dataSelect
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row: DataTestScannerWH, index: number) => {
                          const isItemSelected = isSelected(row)
                          const labelId = `enhanced-table-checkbox-${index}`

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.partNo}
                              </TableCell>
                              <TableCell align="right">
                                {row.jobOrder}
                              </TableCell>
                              <TableCell align="right">{row.pdLotNo}</TableCell>
                              <TableCell align="right">
                                {row.quantity}
                              </TableCell>
                              <TableCell align="right">{row.boxNo}</TableCell>
                            </TableRow>
                          )
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dataSelect.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={1}>
              <ThemedLoadingButton
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main
                }}
                loading={loading}
                onClick={onSave}
              >
                SUBMIT
              </ThemedLoadingButton>
            </Grid>
          </Grid>
          <ThemedButton type="submit"></ThemedButton>
        </Box>
      </ThemedCard>
    </Box>
  )
}
export default WhLocationScan
