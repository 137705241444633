import {
  CardHeader,
  Box,
  Grid,
  MenuItem,
  useTheme,
  Autocomplete
} from "@mui/material"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import AddIcon from "@mui/icons-material/Add"
import AutocompleteSparePartCode from "../../common/Resources/AutocompleteSparePartCode"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { CREATE_SPARE_PART } from "../../common/Mutation/MaintenanceMutation"
import swal from "sweetalert"
import AutocompleteSparePartPartName from "../../common/Resources/AutocompleteSparePartPartName"
import {
  GET_SPARE_PARTS,
  GET_SPARE_PARTS_STOCK_CARD
} from "../../common/Query/MaintenanceQuery"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { GET_EMPLOYEE } from "../../common/Query/MasterQuery"

const SparePartInOrOut: React.FC = () => {
  const [code, setCode] = useState<string>("")
  const [quantity, setQuantity] = useState<number>(0)
  const [actionDate, setActionDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [factory, setFactory] = useState<string>("")
  const [operation, setOperation] = useState<string>("")
  const [operator, setOperator] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  const [currentStock, setCurrentStock] = useState<number>(0)
  const [maxStock, setMaxStock] = useState<number>(0)
  const [createSparePart, { loading }] = useMutation(CREATE_SPARE_PART, {
    refetchQueries: [GET_SPARE_PARTS_STOCK_CARD],
    awaitRefetchQueries: true
  })
  const [sparePartsStockCard, { data: sparePartStockCard }] = useLazyQuery(
    GET_SPARE_PARTS_STOCK_CARD
  )
  const { data: sparePart } = useQuery(GET_SPARE_PARTS)
  const { data: dataEmploy } = useQuery(GET_EMPLOYEE)
  useEffect(() => {
    if (code && partName && factory) {
      const findSparePart = sparePart.sparePartsList.find(
        (e: { code: string; partName: string }) =>
          e.code === code && e.partName === partName
      )
      setMaxStock(findSparePart?.max)
    }
  }, [code, partName, sparePart.sparePartsList, factory, sparePartStockCard])

  useMemo(async () => {
    if (code && partName && factory) {
      const stockCard = await sparePartsStockCard({
        variables: {
          partName,
          factory,
          code
        }
      })
      const data = stockCard.data.sparePartStockCard

      const totalQuantity = data.reduce(
        (prev: number, cur: { quantity: number; inOrOut: number }) => {
          return prev + cur.quantity * cur.inOrOut
        },
        0
      )
      setCurrentStock(totalQuantity)
    }
  }, [code, partName, factory, sparePartStockCard])
  const isComplete = () => {
    return (
      code !== "" &&
      partName !== "" &&
      actionDate !== "" &&
      quantity !== 0 &&
      operator !== "" &&
      factory !== ""
    )
  }

  const employeeLeader = dataEmploy
    ? dataEmploy.employee
        .filter((e: { departmentId: number }) => e.departmentId === 12)
        .map((value: { firstName: string }) => value.firstName)
        .reduce(
          (prev: string, cur: string) =>
            prev.includes(cur) ? prev : prev.concat(cur),
          []
        )
    : ["-"]

  const handleSubmit = async () => {
    setError(true)
    const inStock = currentStock + quantity
    if (operation === "In" && inStock > maxStock) {
      return swal(
        `ค่า Max Stock ห้ามมากกว่า ${maxStock}`,
        "มียอดคงเหลือมากกว่าหรือเท่ากับค่า Max Stock",
        "error"
      )
    }
    if (!isComplete())
      return swal("จำนวนไม่ถูกต้อง", "กรุณากรอกข้อมูลให้ครบ", "error")

    if (operation == "Out" && currentStock < quantity)
      return swal("จำนวนไม่ถูกต้อง", "ยอดคงเหลือไม่พอ", "error")
    const data = {
      code,
      partName,
      actionDate,
      quantity,
      factory,
      inOrOut: operation === "In" ? 1 : -1,
      operator
    }
    try {
      await createSparePart({
        variables: {
          spareParts: data
        }
      })
      swal("Success", "Added Incoming Material Successfully", "success")
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }
  const theme = useTheme()

  return (
    <div className="material-in-body">
      <CardHeader
        titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
        title="Spare Part In Or Out"
        subheaderTypographyProps={{ fontFamily: "Sarabun" }}
        subheader="Add incoming Spare Part  to supply"
      />
      <div style={{ margin: "20px" }}>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <ThemedTextField
                fullWidth
                label="Select Operation"
                value={operation}
                onChange={(e) => setOperation(e.target.value)}
                select
                error={!operation && error}
              >
                <MenuItem value={"In"}>In</MenuItem>
                <MenuItem value={"Out"}>Out</MenuItem>
              </ThemedTextField>
            </Grid>
            <Grid item xs={6}>
              <AutocompleteSparePartCode
                setCode={setCode}
                code={code}
                error={!code && error}
              />
            </Grid>
            <Grid item xs={6}>
              <AutocompleteSparePartPartName
                setPartName={setPartName}
                partName={partName}
                code={code}
                error={!partName && error}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemedTextField
                id="factory"
                fullWidth
                label="Factory No."
                value={factory}
                error={!factory && error}
                onChange={(e) => setFactory(e.target.value)}
                select
              >
                <MenuItem value={"1"}>1</MenuItem>
                <MenuItem value={"2"}>2</MenuItem>
              </ThemedTextField>
            </Grid>
            <Grid item xs={6} sx={{ mt: 2 }}>
              Current Stock {currentStock}
            </Grid>
            <Grid item xs={4}>
              <ThemedTextField
                fullWidth
                value={actionDate}
                variant="outlined"
                type="date"
                label="Date Received"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => setActionDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <ThemedTextField
                fullWidth
                value={quantity === 0 ? "" : quantity}
                error={!quantity && error}
                label="Quantity"
                variant="outlined"
                type="number"
                onChange={(e) => setQuantity(+e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Autocomplete
                freeSolo
                options={employeeLeader}
                value={operator}
                fullWidth
                onChange={(e, newValue) => setOperator(newValue as string)}
                renderInput={(params) => (
                  <ThemedTextField
                    label="ชื่อผู้บันทึก"
                    error={!operator && error}
                    {...params}
                    sx={{ input: { textAlign: "center" } }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <ThemedLoadingButton
          variant="contained"
          sx={{ mt: 3, mb: 2, backgroundColor: theme.palette.primary.main }}
          onClick={handleSubmit}
          loading={loading}
          endIcon={<AddIcon />}
        >
          Submit
        </ThemedLoadingButton>
      </div>
    </div>
  )
}
export default SparePartInOrOut
