import { useTheme } from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import moment from "moment"
import { useRef } from "react"
import { getProcessFilterRumTimeMachine } from "../../../../common/utilities"
import { IDataMachine } from "../../KPIMaintenance"
import { monthNames } from "../../PreventiveMaintenancePlan"
import { ITargetKpi } from "../MTTR"

interface Repairer {
  id: number
  checkReceive: number
  completeDate: number
  department: number
  docNo: number
  repairMan: number
  repairType: number
  receiver: number
  detail: number
  requestDate: number
  startRepair: number
  waitRepair: number
  endWaitRepair: number
  referDate: number
  leaderCheck: number
  managerCheck: number
}
const machineRunTime = (type = "", filterMachineOfMonth = []) => {
  const machineRunTime = getProcessFilterRumTimeMachine(
    type,
    filterMachineOfMonth
  )
  const totalTime = Number(
    machineRunTime
      ?.reduce((acc: number, obj: IDataMachine) => {
        return acc + obj.totalTime
      }, 0)
      .toFixed(2)
  )
  const actualTime = Number(
    machineRunTime
      ?.reduce((acc: number, obj: IDataMachine) => {
        return acc + obj.totalHour
      }, 0)
      .toFixed(2)
  )
  return {
    totalTime: totalTime,
    actualTime: actualTime
  }
}
const total = (
  lineData: Repairer[],
  i: number,
  rowsMachineRuntime = [],
  type = ""
) => {
  if (lineData === undefined)
    return { dataOfMonth: 0, totalQuantityRepair: 0, actualTime: 0, arc: 0 }
  const dataOfMonth = lineData.filter(
    (e) => new Date(e.startRepair).getMonth() === i
  )
  const machineRunTimeOfMonth = rowsMachineRuntime.filter(
    (e: { planDate: string }) => new Date(e.planDate).getMonth() === i
  )
  const lineMachineRunTime = machineRunTime(
    type,
    machineRunTimeOfMonth
  ).totalTime

  if (dataOfMonth.length) {
    const totalQuantityRepair = dataOfMonth
      .map((data) => {
        const diff = Math.abs(
          Number(new Date(data.completeDate)) -
            Number(new Date(data.endWaitRepair))
        )
        const actualTime = diff / 1000 / 60 / 60
        return actualTime
      })
      .reduce((prev: number, cur: number) => {
        return prev + cur
      }, 0)

    const actualTime = lineMachineRunTime - totalQuantityRepair
    const arc = actualTime / dataOfMonth.length
    return {
      dataOfMonth: Math.ceil(totalQuantityRepair * 60),
      lineMachineRunTime: Math.ceil(lineMachineRunTime * 60),
      actualTime: Math.ceil(actualTime * 60),
      totalQuantityRepair: dataOfMonth.length,
      arc: Math.ceil(arc * 60)
    }
  } else {
    return {
      dataOfMonth: 0,
      totalQuantityRepair: 0,
      lineMachineRunTime: Math.ceil(lineMachineRunTime * 60),
      actualTime: Math.ceil(lineMachineRunTime * 60 - 0),
      arc: 0
    }
  }
}
export const MTBFDashboard = (props: {
  chart?: HighchartsReact.Props
  lineData: Repairer[]
  type: string
  setShowChart?: React.Dispatch<React.SetStateAction<boolean>>
  rowsMachineRuntime: never[] | undefined
  target: ITargetKpi
}): JSX.Element => {
  const { lineData, target, type, rowsMachineRuntime } = props
  const actual = monthNames.map((e: string, i: number) => {
    return total(lineData, i, rowsMachineRuntime, type).arc
  })

  const actualMonthly = total(
    lineData,
    new Date().getMonth(),
    rowsMachineRuntime,
    type
  ).arc

  const theme = useTheme()
  const columnOptions: Highcharts.Options = {
    chart: {
      type: "column",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "ระยะเวลาที่เครื่องจักรจะเสีย (MTBF)",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },

    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    },
    xAxis: {
      categories: monthNames,
      labels: {
        x: -10,
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "10px"
        }
      }
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "Total",
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      }
    },
    series: [
      {
        type: "column",
        name: "Target",
        data: monthNames.map(() => Number(target?.target))
      },
      {
        type: "column",
        name: "Actual",
        data: actual
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }
  const pieOptions: Highcharts.Options = {
    chart: {
      type: "pie",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main
    },
    title: {
      text: `MTBF Monthly ${moment(new Date()).format("YYYY-MM")}`,
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
    },
    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    series: [
      {
        name: "Brands",
        type: "pie",
        colorByPoint: true,
        data: [
          {
            name: "Target",
            y: Number(target?.target) || 0
          },
          {
            name: "Actual",
            y: actualMonthly
          }
        ]
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  return (
    <>
      <div style={{ display: "inline-block" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={columnOptions}
          ref={chartComponentRef}
          {...props.chart}
        />
      </div>
      <div style={{ display: "inline-block" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={pieOptions}
          ref={chartComponentRef}
          {...props.chart}
        />
      </div>
    </>
  )
}
