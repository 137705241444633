import { gql } from "@apollo/client"

export const CREATE_SPARE_PART = gql`
  mutation SparePartsInOrOut($spareParts: SparePartsInput) {
    sparePartsInOrOut(spareParts: $spareParts)
  }
`

export const ADD_SPARE_PART = gql`
  mutation AddSparePart($addSparePart: InputSpareParts) {
    addSparePart(addSparePart: $addSparePart)
  }
`

export const UPDATE_SPARE_PART = gql`
  mutation UpdateSparePart($updateSparePart: UpDateSparePart) {
    updateSparePart(updateSparePart: $updateSparePart)
  }
`

export const REMOVE_SPARE_PART = gql`
  mutation DeleteSparePart($code: String, $partName: String) {
    deleteSparePart(code: $code, partName: $partName)
  }
`
export const CREATE_PM_RECORD = gql`
  mutation PmRecord($inputPmRecord: IInputPMRecord) {
    pmRecord(inputPMRecord: $inputPmRecord)
  }
`

export const ADD_MACHINE_MT = gql`
  mutation CreateMachineMt(
    $machine: String!
    $code: String!
    $line: String!
    $factory: String
    $frequency: Int
    $priority: String
    $company: String
    $model: String
    $brand: String
    $installationDate: String
    $details: String
    $remarks: String
    $machinePower: String
  ) {
    createMachineMt(
      machine: $machine
      code: $code
      line: $line
      factory: $factory
      frequency: $frequency
      priority: $priority
      company: $company
      model: $model
      brand: $brand
      installationDate: $installationDate
      details: $details
      remarks: $remarks
      machinePower: $machinePower
    ) {
      machine
      code
      line
      factory
      frequency
      priority
      company
      model
      brand
      installationDate
      details
      machinePower
      remarks
    }
  }
`

export const UPLOAD_MACHINE_IMAGE_MT = gql`
  mutation UploadImageMachineMt($file: Upload!, $name: String) {
    uploadImageMachineMt(file: $file, name: $name)
  }
`

export const DELETE_MACHINE_MT = gql`
  mutation DeleteMachineMt($machine: String) {
    deleteMachineMt(machine: $machine)
  }
`

export const UPDATE_MACHINE_MT = gql`
  mutation UpdateMachineMt(
    $machine: String!
    $code: String!
    $line: String!
    $factory: String
    $frequency: Int
    $priority: String
    $company: String
    $model: String
    $brand: String
    $installationDate: String
    $details: String
    $machinePower: String
    $remarks: String
  ) {
    updateMachineMt(
      machine: $machine
      code: $code
      line: $line
      factory: $factory
      frequency: $frequency
      priority: $priority
      company: $company
      model: $model
      brand: $brand
      installationDate: $installationDate
      details: $details
      machinePower: $machinePower
      remarks: $remarks
    )
  }
`

export const PM_PLAN_RECORD = gql`
  mutation PmPlanRecord(
    $machine: String
    $actionDate: String
    $outsource: Boolean
  ) {
    pmPlanRecord(
      machine: $machine
      actionDate: $actionDate
      outsource: $outsource
    )
  }
`

export const PM_CHECK_LIST_RECORD = gql`
  mutation PmRecordList($inputPmCheckList: [IPMListUpdate]) {
    pmRecordList(inputPMCheckList: $inputPmCheckList)
  }
`

export const CANCEL_PM_CHECK_LIST_RECORD = gql`
  mutation PmCancelRecordList($machineCode: String, $actionDate: String) {
    pmCancelRecordList(machineCode: $machineCode, actionDate: $actionDate)
  }
`

export const CREATE_PM_CHECK_LIST = gql`
  mutation PmRecordCheckList($inputPmCheckList: IPMCheckList) {
    pmRecordCheckList(inputPMCheckList: $inputPmCheckList)
  }
`

export const UPDATE_PM_CHECK_LIST = gql`
  mutation PmUpdateCheckList($inputPmCheckList: IPMCheckList) {
    pmUpdateCheckList(inputPMCheckList: $inputPmCheckList)
  }
`

export const REPAIR_REQUEST_FROM = gql`
  mutation RepairRequestFrom($inputRepairRequestFrom: InputRepairRequestFrom) {
    repairRequestFrom(inputRepairRequestFrom: $inputRepairRequestFrom)
  }
`

export const UPDATE_APPROVE_BY = gql`
  mutation UpdateApproveBy(
    $approveBy: String
    $department: String
    $actionDate: String
    $recordNo: Int
    $factory: String
  ) {
    updateApproveBy(
      approveBy: $approveBy
      department: $department
      recordNo: $recordNo
      actionDate: $actionDate
      factory: $factory
    )
  }
`

export const CREATE_REPAIRER = gql`
  mutation CreateRepairer($inputRepairer: InputRepairer) {
    createRepairer(inputRepairer: $inputRepairer)
  }
`

export const UPDATE_REPAIRER = gql`
  mutation UpdateRepairer($inputRepairer: InputRepairer) {
    updateRepairer(inputRepairer: $inputRepairer)
  }
`

export const APPROVE_REPAIRER = gql`
  mutation ApproveRepairer($position: String, $name: String, $repairerId: Int) {
    approveRepairer(position: $position, name: $name, repairerId: $repairerId)
  }
`

export const REPAIR_AUDITOR = gql`
  mutation RepairAuditor($inputRepairAuditor: RepairAuditor) {
    repairAuditor(inputRepairAuditor: $inputRepairAuditor)
  }
`

export const PM_PLAN_REMOVE = gql`
  mutation PmPlanReMove(
    $machine: String
    $dateFrom: String
    $dateTo: String
    $outsource: Boolean
  ) {
    pmPlanReMove(
      machine: $machine
      dateFrom: $dateFrom
      dateTo: $dateTo
      outsource: $outsource
    )
  }
`

export const UPDATE_MAINTENANCE_KPI = gql`
  mutation UpdateMaintenanceTargetKPI(
    $typeName: String
    $target: String
    $line: String
  ) {
    updateMaintenanceTargetKPI(
      typeName: $typeName
      target: $target
      line: $line
    )
  }
`

export const UPDATE_RECORD_APPROVE = gql`
  mutation PmRecordApprove($inputPmRecordApprove: IPmRecordApprove) {
    pmRecordApprove(inputPmRecordApprove: $inputPmRecordApprove)
  }
`

export const UPDATE_REPAIR_FROM = gql`
  mutation UpdateRepairFrom($inUpdateRepairFrom: InputRepairRequestFrom) {
    updateRepairFrom(inUpdateRepairFrom: $inUpdateRepairFrom)
  }
`
export const DELETE_PM_CHECK_LIST = gql`
  mutation PmDeleteCheckList($inputPmCheckList: IPMCheckList) {
    pmDeleteCheckList(inputPMCheckList: $inputPmCheckList)
  }
`
export const DELETE_REPAIR_FROM = gql`
  mutation DeleteRepairFrom($inDeleteRepairFrom: DeleteRepairFrom) {
    deleteRepairFrom(inDeleteRepairFrom: $inDeleteRepairFrom)
  }
`
