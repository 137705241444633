import React from "react"
import { WIPCardStamp } from "."
import { Grid } from "@mui/material"

interface curMatLot {
  actionDate: string
  arcweld: number
  cost: number
  customer: string
  dateScan: string
  factory: string
  inOrOut: number
  indexNo: number
  isNG: number
  jobOrder: string
  leadTime: number
  matNo: string
  model: string
  packingBy: string
  partNo: string
  partName: string
  pdLotNo: string
  perBox: number
  plate: number
  quantity: number
  remarks: string
  stamp: number
  weld: number
}
interface curMat {
  matNo: string
  matName: string
  partType: string
  ratio: number
  sizeH: string
  sizeL: string
  sizeW: string
  spec: string
  wipQtyBox: number
}
interface Prop {
  curMatLot?: curMatLot
  curMat: curMat[]
  partprocessing?: partprocessing[]
  getBoxCount: number
}

interface partprocessing {
  jph: number
  line: string
  machine: string
  minStroke: number
  partNo: string
  process: string
  processOrder: string
  stepProcess: number
  operator: string
}

export default function WIPCardStamps(props: Prop): JSX.Element {
  const getMatQuantity = (index: number, quantity: number, perBox: number) => {
    const boxCount = props.getBoxCount
    if (index !== boxCount) return perBox
    else {
      const mod = quantity % perBox
      if (mod !== 0) return mod
      else return perBox
    }
  }

  const Card = () => {
    return (
      <Grid container>
        {Array.from(Array(props.getBoxCount), (e, i) => {
          return (
            <WIPCardStamp
              key={i}
              data={props.curMatLot}
              curMat={props.curMat}
              quantity={getMatQuantity(
                i + 1,
                props.curMatLot?.quantity ? props.curMatLot?.quantity : 0,
                props.curMatLot?.perBox ? props.curMatLot?.perBox : 0
              )}
              boxCount={props.getBoxCount}
              boxNum={i + 1}
              partProcesses={props.partprocessing ? props.partprocessing : []}
            ></WIPCardStamp>
          )
        })}
      </Grid>
    )
  }
  return <Card></Card>
}
