import { gql } from "@apollo/client"

export const UploadFile = gql`
  mutation UploadFile($fileName: String, $contentType: String) {
    uploadFile(fileName: $fileName, contentType: $contentType)
  }
`
export const CreatePmwi = gql`
  mutation CreatePmwi(
    $department: String!
    $docNo: String!
    $docName: String!
    $revision: String!
    $fileName: String!
    $record: String!
    $date: String!
    $remarks: String
  ) {
    createPmwi(
      department: $department
      docNo: $docNo
      docName: $docName
      revision: $revision
      fileName: $fileName
      record: $record
      date: $date
      remarks: $remarks
    ) {
      department
      docNo
      docName
      revision
      fileName
      remarks
      record
      date
    }
  }
`
