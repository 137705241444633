import { gql } from "@apollo/client"

export const GET_QC_DEMOLISH = gql`
  query QcDemolishReport(
    $dateFrom: String
    $dateTo: String
    $factory: String
    $partNo: String
    $status: String
  ) {
    qcDemolishReport(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
      partNo: $partNo
      status: $status
    ) {
      date
      pdLotNo
      jobOrder
      partNo
      partName
      customer
      stamp
      weld
      arcweld
      plate
      quantity
      okQuantity
      scratched
      deformed
      torn
      dirtyStains
      rusted
      fin
      incorrectStampSize
      skipProcessStamp
      otherStamp
      offset
      incorrectWeldPosition
      incorrectWeldSize
      looseWelding
      noWelding
      skipProcessWeld
      otherWeld
      loosePlating
      discoloredPlating
      otherPlate
      discontinuousWeld
      oneSidedWeld
      twiceWelded
      puncturedWeld
      messyWeld
      incompleteWeld
      forgetToTurnOffCO2
      deformedArcweld
      otherArcweld
      totalStamp
      totalWeld
      totalPlate
      totalArcWeld
      operator
      remarks
      factory
      process
      sNG
      supplierNG
      tNG
      iNG
    }
  }
`

export const GET_QC_FINISHED_GOODS = gql`
  query QcFinishGoodsReport(
    $dateFrom: String
    $factory: String
    $dateTo: String
    $status: String
    $partNo: String
  ) {
    qcFinishGoodsReport(
      dateFrom: $dateFrom
      factory: $factory
      dateTo: $dateTo
      status: $status
      partNo: $partNo
    ) {
      date
      pdLotNo
      jobOrder
      partNo
      partName
      customer
      stamp
      weld
      arcweld
      plate
      quantity
      okQuantity
      scratched
      deformed
      torn
      dirtyStains
      rusted
      fin
      incorrectStampSize
      skipProcessStamp
      otherStamp
      offset
      incorrectWeldPosition
      incorrectWeldSize
      looseWelding
      noWelding
      skipProcessWeld
      otherWeld
      loosePlating
      discoloredPlating
      otherPlate
      oneSidedWeld
      twiceWelded
      puncturedWeld
      messyWeld
      incompleteWeld
      deformedArcweld
      otherArcweld
      totalStamp
      totalWeld
      totalPlate
      totalArcWeld
      operator
      remarks
      factory
      process
      approveStatus {
        apr
        checkout
      }
    }
  }
`

export const GET_PREMIUM_FREIGHT = gql`
  query PremiumFreightReport(
    $dateFrom: String
    $dateTo: String
    $factory: String
  ) {
    premiumFreightReport(
      dateFrom: $dateFrom
      dateTo: $dateTo
      factory: $factory
    ) {
      id
      actionDate
      customer
      numberOfPeople
      totalHour
      customerClaim
      cost
      department
      allowance
      recordBy
      remarks
      factory
    }
  }
`

export const GET_PREMIUM_FREIGHT_CHART = gql`
  query PremiumFreightChart($quarter: Int, $factory: String, $year: String) {
    premiumFreightChart(quarter: $quarter, factory: $factory, year: $year) {
      month
      customerClaim
      premiumFreightMan
      premiumFreight
      totalProcess
    }
  }
`

export const GET_MASTERPLAN_FOR_CREATE_OR_REPORT_CHECK_SHEET = gql`
  query getMasterPlanForCreateOrReportCheckSheet(
    $date: String
    $dateTo: String
    $customer: String
    $factory: String
    $partNo: String
  ) {
    getMasterPlanForCreateOrReportCheckSheet(
      date: $date
      dateTo: $dateTo
      customer: $customer
      factory: $factory
      partNo: $partNo
    ) {
      Id
      dueDate
      factory
      customer
      partNo
      plant
      pdLotNo
      quantity
      quantityActual
      wipQtyBox
      wipQtyBoxActual
      tag
      rusty
      deformed
      plating
      cleanliness
      upSideDownLetter
      randomInspection
      officer
      verifyOrderBy
      inspector
      approver
      action
    }
  }
`
