import { useLazyQuery } from "@apollo/client"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Switch
} from "@mui/material"
import React, { SetStateAction, useState } from "react"
import QrReader from "react-qr-reader"
import { GET_MATSTOCK } from "../../common/Query/MaterialQuery"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import ActualPlan from "./planningcomponents/ActualPlan"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import { menuItemList } from "../../common/Resources/menuItemList"
import swal from "sweetalert"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"

const pageTitle = "Material Out Scanner"

const MatOutScanner: React.FC = () => {
  const [isStream, setIsStream] = useState(false)
  const [partNo, setPartNO] = useState<string>("")
  const [materialPlan, setMaterialPlan] = useState<number>(0)
  const [actionDate, setActionDate] = useState<string>("")
  const [dataScan, setDataScan] = useState<string>("")
  const [invoiceNo, setInvoiceNO] = useState<string>("")

  // const classes = useStyles()

  const [getMatStock] = useLazyQuery(GET_MATSTOCK)

  const handleScan = (data: SetStateAction<string> | null): void => {
    if (data) {
      setDataScan(data)
      if (dataScan) {
        breakQRCode()
      }
    }
  }

  const handleError = (err: string) => {
    console.error(err)
  }
  const breakQRCode = async (e?: { preventDefault: () => void }) => {
    e?.preventDefault()
    const newSplit = dataScan.split("|")
    if (newSplit.length !== 3) {
      return swal("Error", "มีปัญหากับ format ของ QR Code!", "warning")
    }
    swal("Success", "Scan Job Order Successful", "success")
    setPartNO(newSplit[1])
    setInvoiceNO(newSplit[2])
    const quantity = await getMatStock({
      variables: { matNo: newSplit[1] }
    })
    const actualPlan = quantity.data.matStocks.find(
      (matStock: { invoiceNo: string; inOrOut: number; actionDate: string }) =>
        matStock.invoiceNo === newSplit[2] &&
        matStock.inOrOut === -1 &&
        matStock.actionDate.split("-")[2] +
          matStock.actionDate.split("-")[1] ===
          newSplit[0].split("-")[0] + newSplit[0].split("-")[1]
    )
    setMaterialPlan(actualPlan.quantity)
    setActionDate(newSplit[0])
  }
  const handleReset = () => {
    setPartNO("")
    setInvoiceNO("")
    setDataScan("")
    setMaterialPlan(0)
    setActionDate("")
  }

  return (
    <div>
      <PageLabel
        menuItem={menuItemList.Materials}
        menuItemName={Object.keys(menuItemList)[1]}
        menuPageName={pageTitle}
      />
      <Box component="form" onSubmit={breakQRCode}>
        <Accordion
          expanded={isStream}
          onChange={() => setIsStream(!isStream)}
          style={{
            marginBottom: "5px",
            marginTop: "10px"
          }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ backgroundColor: "#ffffff", display: "none" }}
          />

          <AccordionDetails>
            {isStream ? (
              <>
                <Grid container justifyContent={"center"}>
                  <div style={{ width: "500px" }}>
                    <QrReader
                      delay={300}
                      onError={handleError}
                      onScan={handleScan}
                      style={{ width: "70%" }}
                    />
                  </div>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </AccordionDetails>
        </Accordion>
        <ThemedCard>
          <PageLabelCard title="Job Order" subTitle="About detail Job Order" />
          <>
            <Grid container spacing={2} paddingLeft={2} width={"98%"}>
              <Grid item xs={6} sm={12}>
                <Switch
                  checked={isStream}
                  onChange={(event) =>
                    setIsStream(event.target.value ? !isStream : isStream)
                  }
                  name="gilad"
                />
                <label style={{ fontSize: "14px" }}>
                  Active Camera{" "}
                  <CameraAltIcon style={{ verticalAlign: "middle" }} />
                </label>
              </Grid>
              <Grid
                item
                xs={6}
                justifyContent="flex-end"
                sx={{ display: { xs: "flex", sm: "none" } }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleReset}
                  style={{ width: "120px", height: "36px", top: "9px" }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <ThemedTextField
                  fullWidth
                  id="scanner"
                  value={dataScan}
                  label="scanner"
                  onChange={(e) => setDataScan(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                container
                justifyContent="flex-end"
                sx={{ display: { xs: "none", sm: "flex" } }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleReset}
                  style={{ width: "120px", height: "36px", top: "9px" }}
                >
                  Reset
                </Button>
              </Grid>
              {/* </Grid> */}
              <Grid item xs={12} sm={12}>
                <ThemedTextField
                  className="disable-back-ground-color"
                  fullWidth
                  disabled
                  label="Part No."
                  value={partNo}
                  id="partNo"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <ThemedTextField
                  label="Invoice No."
                  className="disable-back-ground-color"
                  fullWidth
                  disabled
                  value={invoiceNo}
                  id="invoiceNo"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <ThemedTextField
                  label="Material Plan"
                  fullWidth
                  className="disable-back-ground-color"
                  disabled
                  value={materialPlan}
                  id="materialPlan"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <ThemedTextField
                  label="Material Oue Date"
                  fullWidth
                  className="disable-back-ground-color"
                  disabled
                  value={actionDate}
                  id="actionDate"
                  autoComplete="family-name"
                />
              </Grid>
            </Grid>
            <Button type="submit"></Button>
          </>
        </ThemedCard>
      </Box>

      <ActualPlan
        invoiceNo={invoiceNo}
        materialPlan={materialPlan}
        jobOrder={dataScan}
      ></ActualPlan>
    </div>
  )
}

export default MatOutScanner
