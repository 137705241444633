import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { LoadingButton, LocalizationProvider, TimePicker } from "@mui/lab"
import {
  Autocomplete,
  Card,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField
} from "@mui/material"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import swal from "sweetalert"
import DateAdapter from "@mui/lab/AdapterMoment"
import {
  GET_DATE_PM,
  GET_MACHINES_MT,
  GET_PM_CHECK_LIST,
  GET_PM_LIST_REPORT,
  GET_PM_RECORD
} from "../../common/Query/MaintenanceQuery"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import { filter, miniLogo } from "../../common/utilities"
import { IMachine } from "./MachineMt"
import { GET_EMPLOYEE } from "../../common/Query/MasterQuery"
import {
  CANCEL_PM_CHECK_LIST_RECORD,
  CREATE_PM_RECORD,
  PM_CHECK_LIST_RECORD
} from "../../common/Mutation/MaintenanceMutation"
import { calculateTime, diff } from "../Planning/ProductionPlan"
import { IPMReport } from "./component/MachineDialogReportMt"

interface IReportPMCheckList {
  id: number
  type: string
  details: string
  method: string
  tool: string
  specificationReference: string
  remarks: string
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 25,
    fontWeight: "bold",
    fontFamily: "Sarabun",
    textAlign: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
    fontFamily: "Sarabun"
  }
}))

const StyledTableCellApprove = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    textAlign: "center",
    fontSize: 20,
    fontFamily: "Sarabun"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },

  "td, th": {
    border: "1px solid black"
  }
}))

interface IPMListUpdate {
  pmCheckListId: number
  machineCode: string
  actionDate: string
  ok: string
  ng: string
  modify: string
  remark: string
}

const PmQrRecord: React.FC = () => {
  const [machine, setMachine] = useState<IMachine>()
  const [actionDate, setActionDate] = useState<string>("")
  const [startTime, setStartTime] = useState<number>(3600000)
  const [endTime, setEndTime] = useState<number>(36000000)
  const [details, setDetails] = useState<string>("")
  const [maintenance, setMaintenance] = useState<string>("")
  const [operator, setOperator] = useState<string>("")
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const codeParam = urlParams.get("code")
  const [totalTime, setTotalTime] = useState<number>(0)
  const [updatePMList, setUpdatePMList] = useState<IPMListUpdate[]>([])
  const [getMachine] = useLazyQuery(GET_MACHINES_MT)
  const [getDatePM] = useLazyQuery(GET_DATE_PM)
  const [createPMRecord] = useMutation(CREATE_PM_RECORD)
  const { data: dataEmploy } = useQuery(GET_EMPLOYEE)
  const [getPmCheckList, { data: dataPmCheckList }] = useLazyQuery(
    GET_PM_CHECK_LIST,
    {
      variables: {
        machine: machine?.machine
      }
    }
  )
  const [cancelRecord, { loading: loadingCancelRecordPM }] = useMutation(
    CANCEL_PM_CHECK_LIST_RECORD
  )
  const { data: rawDataPMRecord } = useQuery(GET_PM_RECORD, {
    variables: {
      machine: codeParam
    }
  })

  const [pmCheckListRecord, { loading }] = useMutation(PM_CHECK_LIST_RECORD)

  const { data: dataPMListReport } = useQuery(GET_PM_LIST_REPORT, {
    variables: { machineCode: codeParam, actionDate: actionDate },
    fetchPolicy: "network-only"
  })

  const employee = dataEmploy
    ? dataEmploy.employee
        .map((value: { firstName: string }) => value.firstName)
        .reduce(
          (prev: string, cur: string) =>
            prev.includes(cur) ? prev : prev.concat(cur),
          []
        )
    : ["-"]

  const pmCheckList: IReportPMCheckList[] =
    dataPmCheckList?.pmCheckListReport || []

  const getActionDate = useCallback(async () => {
    const rawMachine = await getMachine()

    if (rawMachine) {
      const findMachine = rawMachine?.data?.machinesMt.find(
        ({ code }: { code: string }) => code === codeParam
      )
      setMachine(findMachine)
      await getPmCheckList({
        variables: {
          machine: findMachine?.machine
        }
      })
    }

    const result = await getDatePM({
      variables: {
        machine: codeParam
      }
    })

    if (result?.data?.queryDatePM) {
      setActionDate(result?.data?.queryDatePM)
    } else {
      swal("warning", "ไม่มีแผน PM ในเดือนนี้", "warning")
    }
  }, [])
  useEffect(() => {
    getActionDate()
  }, [])

  useMemo(() => {
    const newUpdatePMList =
      dataPMListReport?.pmListReport.map((value: IPMListUpdate) => {
        return {
          pmCheckListId: value.pmCheckListId,
          machineCode: value.machineCode,
          actionDate: value.actionDate,
          ok: value.ok,
          ng: value.ng,
          modify: value.modify,
          remark: value.remark
        }
      }) || []
    setUpdatePMList(newUpdatePMList)
  }, [dataPMListReport])

  const handleSubmit = async () => {
    try {
      await pmCheckListRecord({
        variables: {
          inputPmCheckList: updatePMList
        }
      })
      await updatePmMachine()
      swal("Success", `Update Success`, "success")
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  const updatePmMachine = async () => {
    await createPMRecord({
      variables: {
        inputPmRecord: {
          machineCode: machine?.code,
          actionDate: actionDate,
          startTime: moment(startTime).format("HH:mm"),
          endTime: moment(endTime).format("HH:mm"),
          remarks: details,
          totalTime,
          maintenance,
          operator
        }
      }
    })
  }

  const updateStatus = (
    pmCheck: IReportPMCheckList,
    status: string,
    type: string
  ) => {
    const checkIdPMList = updatePMList.find(
      (value) => value.pmCheckListId === pmCheck.id
    )
    if (checkIdPMList) {
      const newUpdatePMList = updatePMList.map((value) => {
        if (value.pmCheckListId === pmCheck.id) {
          return {
            pmCheckListId: value.pmCheckListId,
            machineCode: value.machineCode,
            actionDate: value.actionDate,
            ok: type === "ok" ? status : value.ok,
            ng: type === "ng" ? status : value.ng,
            modify: type === "modify" ? status : value.modify,
            remark: value.remark
          }
        }
        return {
          pmCheckListId: value.pmCheckListId,
          machineCode: value.machineCode,
          actionDate: value.actionDate,
          ok: value.ok,
          ng: value.ng,
          modify: value.modify,
          remark: value.remark
        }
      })
      setUpdatePMList(newUpdatePMList)
    } else {
      const newUpdatePMList = updatePMList.concat([
        {
          pmCheckListId: pmCheck.id,
          machineCode: machine?.code || "",
          actionDate: actionDate || "",
          ok: type === "ok" ? status : "",
          ng: type === "ng" ? status : "",
          modify: type === "modify" ? status : "",
          remark: ""
        }
      ])
      setUpdatePMList(newUpdatePMList)
    }
  }

  const addMonth = (date: string, frequency: number) => {
    return getDateLocal(
      new Date(new Date(date).setMonth(new Date(date).getMonth() + frequency))
    )
  }

  const getDateLocal = (date: Date) =>
    date.toLocaleDateString("fr-CA", {
      timeZone: "Asia/Bangkok",
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    })

  const checkDay = (date: string): string => {
    const getDay = new Date(date).toLocaleString("en-us", { weekday: "long" })

    if (getDay === "Sunday" || getDay === "Saturday") {
      return checkDay(
        getDateLocal(
          new Date(new Date(date).setDate(new Date(date).getDate() + 1))
        )
      )
    } else {
      return date
    }
  }

  const cancelRecordPM = async () => {
    try {
      await cancelRecord({
        variables: {
          machineCode: machine?.code,
          actionDate: actionDate
        }
      })
      swal("Success", `Cancel Success`, "success")
      window.location.reload()
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  useEffect(() => {
    if (
      Number(moment(endTime).format("HH")) > 12 &&
      Number(moment(startTime).format("HH")) < 13
    ) {
      const time = diff(startTime, endTime - 60 * 60 * 1000)
      setTotalTime(Number(calculateTime(time)))
    } else {
      const time = diff(startTime, endTime)
      setTotalTime(Number(calculateTime(time)))
    }
  }, [startTime, endTime])

  useEffect(() => {
    const dataPMRecord = rawDataPMRecord?.pmReport ?? []
    const findData: IPMReport = dataPMRecord.find(
      (e: IPMReport) => e.actionDate === actionDate
    )
    setDetails(findData?.remarks || "")
    setMaintenance(findData?.maintenance || "")
    setOperator(findData?.operator || "")
    setStartTime(
      new Date(`2022-01-01 ${findData?.startTime}`).getTime() || 3600000
    )
    setEndTime(
      new Date(`2022-01-01 ${findData?.endTime}`).getTime() || 36000000
    )
  }, [rawDataPMRecord, actionDate])

  return (
    <>
      <Card>
        <PageLabelCard title="PM Check Sheet" />
        {actionDate ? (
          <div id="table">
            <Table style={{ zoom: "80%" }}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell colSpan={5}>
                    <img src={miniLogo} alt="" width={"100px"} />
                  </StyledTableCell>
                  <StyledTableCell colSpan={10}>
                    ใบแจ้งการบำรุงรักษาเชิงป้องกัน
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }} colSpan={5}>
                    Doc. No. <br />
                    F-MT-01-02 <br />
                    <span style={{ fontSize: "14px" }}>
                      Effective Date : 30/05/19 Isue: R03
                    </span>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={3}>M/C Name</StyledTableCell>
                  <StyledTableCell colSpan={7}>
                    {machine?.machine}
                  </StyledTableCell>
                  <StyledTableCell colSpan={3}>Line</StyledTableCell>
                  <StyledTableCell colSpan={7}>{machine?.line}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={3}>M/C Code</StyledTableCell>
                  <StyledTableCell colSpan={7}>{machine?.code}</StyledTableCell>
                  <StyledTableCell colSpan={3}>PM Date</StyledTableCell>
                  <StyledTableCell colSpan={7}>{actionDate}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={3} rowSpan={2}>
                    Item
                  </StyledTableCell>
                  <StyledTableCell colSpan={3} rowSpan={2}>
                    Maintenance Checking
                  </StyledTableCell>
                  <StyledTableCell colSpan={2} rowSpan={2}>
                    Method
                  </StyledTableCell>
                  <StyledTableCell colSpan={2}>Equipment</StyledTableCell>

                  <StyledTableCell colSpan={3} rowSpan={2}>
                    Std.
                  </StyledTableCell>
                  <StyledTableCell colSpan={3}>Actual</StyledTableCell>
                  <StyledTableCell colSpan={2} rowSpan={2}>
                    Actual Plan
                  </StyledTableCell>
                  <StyledTableCell colSpan={2} rowSpan={2}>
                    Comment
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={2}>"(Tool)"</StyledTableCell>
                  <StyledTableCell colSpan={1}>OK</StyledTableCell>
                  <StyledTableCell colSpan={1}>NG</StyledTableCell>
                  <StyledTableCell colSpan={1}>Modify</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {pmCheckList.map((val, index) => {
                  return (
                    <StyledTableRow key={val.details}>
                      <StyledTableCell colSpan={3}>{index + 1}</StyledTableCell>
                      <StyledTableCell colSpan={3}>
                        {val.details}
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        {val.method}
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}>{val.tool}</StyledTableCell>
                      <StyledTableCell colSpan={3}>
                        {val.specificationReference}
                      </StyledTableCell>
                      <StyledTableCellApprove>
                        <TextField
                          id="Ok"
                          value={
                            updatePMList.find(
                              (value) => value.pmCheckListId === val.id
                            )?.ok || ""
                          }
                          sx={{ input: { textAlign: "center" } }}
                          type="text"
                          variant="standard"
                          style={{ width: "50px" }}
                          onChange={(e) =>
                            updateStatus(val, e.target.value, "ok")
                          }
                        />
                      </StyledTableCellApprove>
                      <StyledTableCellApprove>
                        <TextField
                          id="NG"
                          type="text"
                          value={
                            updatePMList.find(
                              (value) => value.pmCheckListId === val.id
                            )?.ng || ""
                          }
                          sx={{ input: { textAlign: "center" } }}
                          variant="standard"
                          style={{ width: "50px" }}
                          onChange={(e) =>
                            updateStatus(val, e.target.value, "ng")
                          }
                        />
                      </StyledTableCellApprove>
                      <StyledTableCellApprove>
                        <TextField
                          id="Modify"
                          type="text"
                          value={
                            updatePMList.find(
                              (value) => value.pmCheckListId === val.id
                            )?.modify || ""
                          }
                          sx={{ input: { textAlign: "center" } }}
                          variant="standard"
                          style={{ width: "50px" }}
                          onChange={(e) =>
                            updateStatus(val, e.target.value, "modify")
                          }
                        />
                      </StyledTableCellApprove>
                      <StyledTableCell colSpan={2} />
                      <StyledTableCell colSpan={2}>
                        {val.remarks}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
                <StyledTableRow key={"tag pm"}>
                  <StyledTableCell colSpan={3} rowSpan={2}>
                    {pmCheckList.length + 1}
                  </StyledTableCell>
                  <StyledTableCell colSpan={3} rowSpan={2}>
                    {"บันทึก Tag PM"}
                  </StyledTableCell>
                  <StyledTableCell colSpan={2} rowSpan={2}>
                    {"บันทึก"}
                  </StyledTableCell>
                  <StyledTableCell colSpan={2} rowSpan={2}>
                    {"ปากกา"}
                  </StyledTableCell>
                  <StyledTableCell colSpan={3} rowSpan={2}>
                    {"ลงบันทึกวันที่บน Tag PM"}
                  </StyledTableCell>
                  <StyledTableCellApprove colSpan={3}>
                    {"ครั้งล่าสุด"}
                  </StyledTableCellApprove>
                  <StyledTableCell colSpan={2}>{"ครั้งถัดไป"}</StyledTableCell>
                  <StyledTableCell colSpan={2} rowSpan={2}>
                    {/* {val.remarks} */}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCellApprove colSpan={3}>
                    {actionDate}
                  </StyledTableCellApprove>
                  <StyledTableCell colSpan={2}>
                    {checkDay(
                      addMonth(
                        actionDate || moment(new Date()).format("yyyy-MM-DD"),
                        Number(machine?.frequency) || 1
                      )
                    )}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={20} style={{ height: "100px" }}>
                    <Grid container spacing={2} margin={2}>
                      <Grid item sm={3} xs={12}>
                        Start Time
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <TimePicker
                            ampm={false}
                            views={["hours", "minutes"]}
                            inputFormat="HH:mm"
                            value={startTime}
                            onChange={(newSelectTime) => {
                              setStartTime(newSelectTime ? newSelectTime : 0)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ input: { textAlign: "center" } }}
                                id="start-time-ot"
                                variant="standard"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item sm={3} xs={12}>
                        End Time
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <TimePicker
                            ampm={false}
                            views={["hours", "minutes"]}
                            inputFormat="HH:mm"
                            value={endTime}
                            onChange={(newSelectTime) => {
                              setEndTime(newSelectTime ? newSelectTime : 0)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ input: { textAlign: "center" }, ml: 2 }}
                                id="start-time-ot"
                                variant="standard"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <TextareaAutosize
                          aria-label="empty textarea"
                          placeholder="รายละเอียด"
                          value={details}
                          onChange={(e) => setDetails(e.target.value)}
                          minRows={3}
                          style={{
                            width: "95%",
                            fontSize: "25px",
                            marginTop: "10px"
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Autocomplete
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params)
                            const { inputValue } = params
                            if (inputValue !== "") {
                              filtered.push(inputValue)
                            }
                            return filtered
                          }}
                          freeSolo
                          fullWidth
                          disablePortal
                          id="maintenance"
                          value={maintenance || ""}
                          onChange={(e, newValue) => {
                            setMaintenance(newValue as string)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Maintenance"
                              value={maintenance || ""}
                            />
                          )}
                          options={employee}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Autocomplete
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params)
                            const { inputValue } = params
                            if (inputValue !== "") {
                              filtered.push(inputValue)
                            }
                            return filtered
                          }}
                          freeSolo
                          fullWidth
                          disablePortal
                          id="operator"
                          value={operator || ""}
                          onChange={(e, newValue) => {
                            setOperator(newValue as string)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Operator"
                              value={operator || ""}
                            />
                          )}
                          options={employee}
                        />
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCellApprove rowSpan={3} colSpan={10} />
                  <StyledTableCellApprove colSpan={3}>
                    Reported
                  </StyledTableCellApprove>
                  <StyledTableCellApprove colSpan={3}>
                    Checked
                  </StyledTableCellApprove>
                  <StyledTableCellApprove colSpan={4}>
                    Approved
                  </StyledTableCellApprove>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCellApprove colSpan={3} sx={{ height: "50px" }} />
                  <StyledTableCellApprove colSpan={3} sx={{ height: "50px" }} />
                  <StyledTableCellApprove colSpan={4} sx={{ height: "50px" }} />
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCellApprove colSpan={3}>
                    หัวหน้าฝ่ายซ่อมบำรุง
                  </StyledTableCellApprove>
                  <StyledTableCellApprove colSpan={3}>
                    ผู้จัดการฝ่ายซ่อมบำรุง
                  </StyledTableCellApprove>
                  <StyledTableCellApprove colSpan={4}>
                    เจ้าหน้าที่เทคนิค
                  </StyledTableCellApprove>
                </StyledTableRow>
              </TableBody>
            </Table>

            <Grid container spacing={2} sx={{ padding: 2 }}>
              <Grid item sm={12} container justifyContent="flex-end">
                <LoadingButton
                  variant="contained"
                  style={{ backgroundColor: "green" }}
                  autoFocus
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Update
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  style={{ backgroundColor: "red", marginLeft: 5 }}
                  autoFocus
                  onDoubleClick={cancelRecordPM}
                  loading={loadingCancelRecordPM}
                >
                  Cancel
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
        ) : (
          <>ไม่มีแผน PM ในเดือนนี้</>
        )}
      </Card>
    </>
  )
}

export default PmQrRecord
