import { useMutation, useQuery } from "@apollo/client"
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  styled,
  TableCell,
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Autocomplete,
  useTheme,
  TextField,
  ThemeProvider
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import moment from "moment"
import { useEffect, useState } from "react"
import { GET_EMPLOYEE } from "../../../common/Query/MasterQuery"
import { GET_REPAIRER } from "../../../common/Query/MaintenanceQuery"
import { LoadingButton, LocalizationProvider, TimePicker } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import swal from "sweetalert"
import { miniLogo, subtractDate } from "../../../common/utilities"
import { user } from "../../../common/MainApp"
import {
  APPROVE_REPAIRER,
  CREATE_REPAIRER,
  UPDATE_REPAIRER
} from "../../../common/Mutation/MaintenanceMutation"
import FormTextField from "../../../common/Resources/ThemedComponents/Maintenance/FormTextField"
import ThemedRadio from "../../../common/Resources/ThemedComponents/ThemedRadio"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { autocompleteRenderTags } from "../../../common/Resources/ThemedComponents/Other/autocompleteRenderTags"
const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  borderBottom: "1px",
  padding: "5px",
  fontSize: "22px",
  border: "solid 1px black",
  backgroundColor: "#FFFFFF",
  color: "#000000"
}))

export interface IRepairer {
  id: number
  checkReceive: string
  completeDate: string
  department: string
  docNo: number
  repairMan: string
  repairType: string
  receiver: string
  detail: string
  requestDate: string
  startRepair: string
  waitRepair: string
  endWaitRepair: string
  referDate: string
  leaderCheck: string
  managerCheck: string
  correctionList: string
  factory: string
}
interface IRequestData {
  actionDate: string
  recordNo: number
  department: string
  machineName: string
  code: string
  workType: string
  jobType: string
  urgency: string
  detail: string
  requestDate: string
  request: string
  completeDate: string
  requestBy: string
  approveBy: string
  factory: string
}
interface IProp {
  data: IRepairer
  repairFrom: IRequestData
}
interface IRepairProblem {
  problemName?: string
  correction: string
  protection: string
}
const Repairer: React.FC<IProp> = (props: IProp) => {
  const { data, repairFrom } = props

  const [leaderCheckStatus, setLeaderCheckStatus] = useState<boolean>(true)
  const [managerCheckStatus, setManagerCheckStatus] = useState<boolean>(true)

  const [referDate, setReferDate] = useState<string>("")
  const [repairType, setRepairType] = useState<string>("")
  const [receiver, setReceiver] = useState<string>("")
  const [checkReceive, setCheckReceive] = useState<string>("")
  const [newCorrection, setNewCorrection] = useState<string>("")
  const [newProtection, setNewProtection] = useState<string>("")
  const [repairMan, setRepairMan] = useState<string[]>([])
  const [waitRepair, setWaitRepair] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [endWaitRepairTime, setEndWaitRepairTime] = useState<number>(3600000)
  const [endWaitRepair, setEndWaitRepair] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [waitRepairTime, setWaitRepairTime] = useState<number>(3600000)
  const [startRepair, setStartRepair] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [completeDate, setCompleteDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [checkValue, setCheckValue] = useState<boolean>(false)
  const [completeTime, setCompleteTime] = useState<number>(36000000)
  const [startTime, setStartTime] = useState<number>(3600000)
  const [newOrderProblem, setNewOrderProblem] = useState<IRepairProblem[]>([])
  const getEmployee = useQuery(GET_EMPLOYEE)
  const [createRepairer] = useMutation(CREATE_REPAIRER, {
    refetchQueries: [GET_REPAIRER],
    awaitRefetchQueries: true
  })
  const [updateRepairer] = useMutation(UPDATE_REPAIRER, {
    refetchQueries: [GET_REPAIRER],
    awaitRefetchQueries: true
  })
  const [approveRepairer] = useMutation(APPROVE_REPAIRER, {
    refetchQueries: [GET_REPAIRER],
    awaitRefetchQueries: true
  })
  const leaderList =
    getEmployee.data?.employee
      .filter((e: { position: string }) => e.position === "Leader")
      .map(({ firstName }: { firstName: string }) => firstName) || []
  const managerList =
    getEmployee.data?.employee
      .filter((e: { position: string }) => e.position === "Manager")
      .map(({ firstName }: { firstName: string }) => firstName) || []
  const repairManList: string[] =
    Array.from(
      new Set(
        getEmployee.data?.employee.map(
          ({ firstName }: { firstName: string }) => firstName
        )
      )
    ) || []

  useEffect(() => {
    if (data) {
      setRepairType(data.repairType)
      setReferDate(data.referDate)
      setReceiver(data.receiver)
      setCheckReceive(data.checkReceive)
      setStartRepair(data.startRepair.split(" ")[0])
      setStartTime(new Date(data.startRepair).getTime())
      setCompleteDate(data.completeDate.split(" ")[0])
      setCompleteTime(new Date(data.completeDate).getTime())
      setWaitRepair(data.waitRepair.split(" ")[0])
      setWaitRepairTime(new Date(data.waitRepair).getTime())
      setRepairMan(data.repairMan.split(","))
      setEndWaitRepair(data.endWaitRepair.split(" ")[0])
      setEndWaitRepairTime(new Date(data.endWaitRepair).getTime())
      setNewOrderProblem(JSON.parse(data.correctionList))
    }
  }, [data])
  const addOrderProblem = () => {
    if (!newCorrection) return swal("Warning", "กรอกข้อมูลไม่ครบ", "warning")
    const newOrder = [
      {
        correction: newCorrection,
        protection: newProtection
      }
    ]
    setNewOrderProblem(newOrderProblem.concat(newOrder))
  }
  const waitTime = subtractDate(
    new Date(endWaitRepair + " " + moment(endWaitRepairTime).format("HH:mm")),
    new Date(waitRepair + " " + moment(waitRepairTime).format("HH:mm"))
  )
  const repairTime = subtractDate(
    new Date(completeDate + " " + moment(completeTime).format("HH:mm")),
    new Date(startRepair + " " + moment(startTime).format("HH:mm"))
  )
  const realTime = subtractDate(repairTime.date, waitTime.date)
  const handleApproveLeader = async () => {
    if (
      user.position === "Admin" ||
      user.position === "Manager" ||
      user.position === "GM" ||
      user.position === "FM" ||
      user.position === "Leader" ||
      user.position === "Supervisor"
    ) {
      setLeaderCheckStatus(false)
      await approveRepairer({
        variables: {
          position: "leader",
          repairerId: data.id,
          name: user.employee
        }
      })
    }
  }
  const handleApproveManager = async () => {
    if (
      user.position === "Admin" ||
      user.position === "Manager" ||
      user.position === "GM" ||
      user.position === "FM"
    ) {
      setManagerCheckStatus(false)
      await approveRepairer({
        variables: {
          position: "manager",
          repairerId: data.id,
          name: user.employee
        }
      })
    }
  }
  const isComplete = () => {
    return (
      repairType !== "" &&
      receiver !== "" &&
      checkReceive !== "" &&
      newOrderProblem.length !== 0 &&
      repairMan.length !== 0
    )
  }
  const handleSubmit = async () => {
    if (!isComplete()) {
      setCheckValue(true)
      return swal("Error", "กรอกข้อมูลไม่ครบ", "error")
    }
    const inputData = {
      actionDate: repairFrom?.actionDate,
      detail: repairFrom?.detail || "",
      department: repairFrom?.department || "",
      docNo: repairFrom?.recordNo || "",
      repairType,
      referDate,
      receiver,
      checkReceive,
      correctionList: JSON.stringify(newOrderProblem),
      newOrderProblem: newOrderProblem.map((e) => {
        return {
          problemName: repairFrom.detail,
          correction: e.correction,
          protection: e.protection
        }
      }),
      requestDate: repairFrom?.requestDate || "0000-00-00 00:00",
      startRepair: startRepair + " " + moment(startTime).format("HH:mm"),
      completeDate: completeDate + " " + moment(completeTime).format("HH:mm"),
      waitRepair: waitRepair + " " + moment(waitRepairTime).format("HH:mm"),
      endWaitRepair:
        endWaitRepair + " " + moment(endWaitRepairTime).format("HH:mm"),
      repairMan: repairMan.join(","),
      factory: repairFrom?.factory
    }
    try {
      await createRepairer({
        variables: {
          inputRepairer: { ...inputData }
        }
      })
      swal("OK", "", "success")
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "error")
    }
  }
  const handleUpdate = async () => {
    const inputData = {
      docNo: repairFrom.recordNo,
      repairType,
      referDate,
      receiver,
      checkReceive,
      correctionList: JSON.stringify(newOrderProblem),
      newOrderProblem: newOrderProblem.map((e) => {
        return {
          problemName: repairFrom.detail,
          correction: e.correction,
          protection: e.protection
        }
      }),
      requestDate: repairFrom?.requestDate || "0000-00-00 00:00",
      startRepair: startRepair + " " + moment(startTime).format("HH:mm"),
      completeDate: completeDate + " " + moment(completeTime).format("HH:mm"),
      waitRepair: waitRepair + " " + moment(waitRepairTime).format("HH:mm"),
      endWaitRepair:
        endWaitRepair + " " + moment(endWaitRepairTime).format("HH:mm"),
      repairMan: repairMan.join(","),
      actionDate: repairFrom.actionDate,
      factory: repairFrom.factory
    }
    try {
      await updateRepairer({
        variables: {
          inputRepairer: { ...inputData }
        }
      })
      swal("OK", "Update Success", "success")
    } catch (error) {
      swal("ERROR", `${(error as Error).message}`, "error")
    }
  }
  const theme = useTheme()
  const checkPermission = ["leader", "supervisor", "manager"].includes(
    user.position.toLowerCase()
  )
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} sx={{ padding: "5px" }}>
        <Grid item sm={12} xs={12} sx={{ marginLeft: "-5px" }}>
          <Table sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
            <TableHead>
              <TableRow>
                <StyledTableCell rowSpan={2}>
                  <img
                    src={miniLogo}
                    alt="Brother Auto Parts."
                    style={{
                      width: "150px",
                      height: "100px"
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  <h1>ใบเเจ้งซ่อม</h1>
                </StyledTableCell>
                <StyledTableCell>
                  <h3>Doc No.F-MT-01-04</h3>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <h3>
                    Rec.No.
                    {moment(repairFrom?.actionDate).format("YYYY-MM") +
                      "-" +
                      repairFrom?.recordNo}
                    -1000
                  </h3>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell>สำหรับผู้ซ่อม</StyledTableCell>
                <StyledTableCell colSpan={2}>
                  ผลจากการตรวจสอบอาการเครื่องจักรเบื้องต้น
                  แผนกซ่อมบำรุงพิจารณาเเล้วเห็นว่าสมควร
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={3}>
                  <FormControl>
                    <RadioGroup
                      row
                      onChange={(e) => setRepairType(e.target.value)}
                      value={repairType}
                    >
                      <FormControlLabel
                        value="ซ่อมเองภายใน"
                        control={<ThemedRadio />}
                        label="ซ่อมเองภายใน"
                      />
                      <FormControlLabel
                        value="จัดหาผู้ว่าจ้างภายนอก"
                        control={<ThemedRadio />}
                        label="จัดหาผู้ว่าจ้างภายนอก"
                      />
                      แจ้งซ่อมเมื่อ
                    </RadioGroup>
                  </FormControl>
                  <FormTextField
                    type="date"
                    variant="standard"
                    value={referDate}
                    sx={{ marginLeft: "20px" }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) => setReferDate(e.target.value)}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={3}>
                  <p style={{ display: "inline-flex" }}>ผู้รับเรื่อง</p>
                  <Autocomplete
                    freeSolo
                    options={leaderList}
                    value={receiver}
                    sx={{
                      width: "150px",
                      display: "inline-flex"
                    }}
                    onChange={(e, newValue) => setReceiver(newValue as string)}
                    renderInput={(params) => (
                      <FormTextField
                        {...params}
                        error={checkValue}
                        variant="standard"
                        sx={{ input: { textAlign: "center" } }}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  />
                  <p style={{ display: "inline-flex" }}>
                    หัวหน้าแผนกซ่อมบำรุง{" "}
                  </p>
                  &nbsp;&nbsp;&nbsp;
                  <p style={{ display: "inline-flex" }}> ผู้ตรวจสอบ </p>
                  <Autocomplete
                    freeSolo
                    options={managerList}
                    value={checkReceive}
                    sx={{
                      width: "150px",
                      display: "inline-flex"
                    }}
                    onChange={(e, newValue) =>
                      setCheckReceive(newValue as string)
                    }
                    renderInput={(params) => (
                      <FormTextField
                        {...params}
                        error={checkValue}
                        variant="standard"
                        sx={{ input: { textAlign: "center" } }}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  />
                  <p style={{ display: "inline-flex" }}>
                    ผู้จัดการฝ่ายซ่อมบำรุง
                  </p>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={4}>รายงานผลการซ่อม</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={1}>ลำดับ</StyledTableCell>
                <StyledTableCell colSpan={1}>
                  รายละเอียดการเเก้ไข
                </StyledTableCell>
                <StyledTableCell colSpan={1}>เเนวทางป้องกัน</StyledTableCell>
              </TableRow>
              {newOrderProblem.map((e: IRepairProblem, i: number) => {
                return (
                  <TableRow key={i}>
                    <StyledTableCell colSpan={1}>{i + 1}</StyledTableCell>
                    <StyledTableCell colSpan={1}>
                      {e.correction}
                    </StyledTableCell>
                    <StyledTableCell colSpan={1}>
                      {e.protection}
                      <ThemedLoadingButton
                        variant="contained"
                        style={{ backgroundColor: "red" }}
                        autoFocus
                        onClick={() =>
                          setNewOrderProblem(
                            newOrderProblem.filter(
                              (value: { correction: string }) =>
                                value.correction !== e.correction
                            )
                          )
                        }
                      >
                        Delete
                      </ThemedLoadingButton>
                    </StyledTableCell>
                  </TableRow>
                )
              })}
              <TableRow>
                <StyledTableCell colSpan={3}>
                  <Grid container spacing={2}>
                    <Grid item sm={2} xs={2}>
                      รายละเอียดการเเก้ไข
                    </Grid>
                    <Grid item sm={2} xs={2}>
                      <FormTextField
                        fullWidth
                        error={checkValue}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#BBBBBB"
                            },
                            "&:hover fieldset": {
                              borderColor: "#000000"
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#000000"
                            }
                          }
                        }}
                        onChange={(e) => setNewCorrection(e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={2} xs={2}>
                      เเนวทางป้องกัน
                    </Grid>
                    <Grid item sm={2} xs={2}>
                      <FormTextField
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#BBBBBB"
                            },
                            "&:hover fieldset": {
                              borderColor: "#000000"
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#000000"
                            }
                          }
                        }}
                        onChange={(e) => setNewProtection(e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={2} xs={2}>
                      <ThemedButton
                        sx={{ mt: 1 }}
                        style={{
                          backgroundColor: "white",
                          width: "150px",
                          color: theme.palette.primary.contrastText,
                          borderColor: theme.palette.primary.main
                        }}
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={addOrderProblem}
                      >
                        Add
                      </ThemedButton>
                    </Grid>
                  </Grid>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={3} style={{ textAlign: "left" }}>
                  <Grid container spacing={2}>
                    <Grid item sm={3} xs={3}>
                      แจ้งซ่อมเมื่อ:&nbsp;
                      {moment(
                        repairFrom?.requestDate || "0000-00-00 00:00"
                      ).format("DD/MM/YYYY")}
                      :
                      {moment(
                        repairFrom?.requestDate || "0000-00-00 00:00"
                      ).format("HH:mm")}
                    </Grid>
                    <Grid item sm={4} xs={4}>
                      เริ่มซ่อมเมื่อ
                      <FormTextField
                        type="date"
                        variant="standard"
                        value={moment(startRepair).format("YYYY-MM-DD")}
                        onChange={(e) => setStartRepair(e.target.value)}
                      />
                      เวลา
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <TimePicker
                          ampm={false}
                          views={["hours", "minutes"]}
                          inputFormat="HH:mm"
                          value={startTime}
                          onChange={(newSelectTime) => {
                            setStartTime(newSelectTime ? newSelectTime : 0)
                          }}
                          renderInput={(params) => (
                            <FormTextField
                              sx={{
                                input: { textAlign: "center" },
                                width: "20%",
                                "& svg": {
                                  color: "#888888"
                                }
                              }}
                              {...params}
                              id="start-time-ot"
                              variant="standard"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item sm={4} xs={4}>
                      ซ่อมเสร็จเมื่อ:
                      <FormTextField
                        type="date"
                        variant="standard"
                        value={moment(completeDate).format("YYYY-MM-DD")}
                        onChange={(e) => setCompleteDate(e.target.value)}
                      />
                      เวลา
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <TimePicker
                          ampm={false}
                          views={["hours", "minutes"]}
                          inputFormat="HH:mm"
                          value={completeTime}
                          onChange={(newSelectTime) => {
                            setCompleteTime(newSelectTime ? newSelectTime : 0)
                          }}
                          renderInput={(params) => (
                            <FormTextField
                              {...params}
                              sx={{
                                input: { textAlign: "center" },
                                width: "20%",
                                "& svg": {
                                  color: "#888888"
                                }
                              }}
                              variant="standard"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  colSpan={1}
                  style={{
                    textAlign: "left",
                    borderTop: "solid 2px white",
                    borderBottom: "solid 2px white",
                    borderRight: "solid 2px white"
                  }}
                >
                  <p style={{ display: "inline-flex" }}>รวมเวลารอคอย : </p>
                  <FormTextField
                    type="date"
                    variant="standard"
                    value={moment(waitRepair).format("YYYY-MM-DD")}
                    sx={{ mt: 2, width: "20%" }}
                    onChange={(e) => setWaitRepair(e.target.value)}
                  />
                  :
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <TimePicker
                      ampm={false}
                      views={["hours", "minutes"]}
                      inputFormat="HH:mm"
                      value={waitRepairTime}
                      onChange={(newSelectTime) => {
                        setWaitRepairTime(newSelectTime ? newSelectTime : 0)
                      }}
                      renderInput={(params) => (
                        <FormTextField
                          sx={{
                            mt: 2,
                            input: { textAlign: "center" },
                            width: "15%",
                            "& svg": {
                              color: "#888888"
                            }
                          }}
                          {...params}
                          id="start-time-ot"
                          variant="standard"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <p
                    style={{
                      display: "inline-flex",
                      marginLeft: "5px",
                      marginRight: "5px"
                    }}
                  >
                    ถึง
                  </p>
                  <FormTextField
                    type="date"
                    variant="standard"
                    value={moment(endWaitRepair).format("YYYY-MM-DD")}
                    sx={{ mt: 2 }}
                    onChange={(e) => setEndWaitRepair(e.target.value)}
                  />
                  :
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <TimePicker
                      ampm={false}
                      views={["hours", "minutes"]}
                      inputFormat="HH:mm"
                      value={endWaitRepairTime}
                      onChange={(newSelectTime) => {
                        setEndWaitRepairTime(newSelectTime ? newSelectTime : 0)
                      }}
                      renderInput={(params) => (
                        <FormTextField
                          sx={{
                            mt: 2,
                            input: { textAlign: "center" },
                            width: "15%",
                            "& svg": {
                              color: "#888888"
                            }
                          }}
                          {...params}
                          id="start-time-ot"
                          variant="standard"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </StyledTableCell>
                <StyledTableCell
                  colSpan={2}
                  style={{
                    borderTop: "solid 2px white",
                    borderBottom: "solid 2px white"
                  }}
                >
                  <p style={{ display: "inline-flex" }}>
                    รวมเวลารอคอย(Mean waiting time) :
                  </p>
                  {waitTime.month +
                    " เดือน " +
                    waitTime.day +
                    " วัน " +
                    waitTime.hour +
                    " ชม " +
                    waitTime.minute +
                    " นาที "}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  colSpan={1}
                  style={{
                    textAlign: "left",
                    borderBottom: "solid 2px white",
                    borderRight: "solid 2px white"
                  }}
                >
                  <p style={{ display: "inline-flex" }}>
                    เวลาปฎิบัติงานจริง :
                    {realTime.month +
                      " เดือน " +
                      realTime.day +
                      " วัน " +
                      realTime.hour +
                      " ชม " +
                      realTime.minute +
                      " นาที "}
                  </p>
                </StyledTableCell>
                <StyledTableCell
                  colSpan={2}
                  style={{
                    textAlign: "left",
                    borderBottom: "solid 2px white",
                    borderLeft: "solid 2px white"
                  }}
                >
                  <p style={{ display: "inline-flex" }}>
                    รวมเวลาซ่อม :
                    {repairTime.month +
                      " เดือน " +
                      repairTime.day +
                      " วัน " +
                      repairTime.hour +
                      " ชม " +
                      repairTime.minute +
                      " นาที "}
                  </p>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  colSpan={1}
                  style={{ borderRight: "solid 2px white" }}
                >
                  <p style={{ display: "inline-flex" }}>ลงชื่อ :</p>
                  <Autocomplete
                    multiple
                    freeSolo
                    options={repairManList}
                    value={repairMan}
                    sx={{
                      width: "320px",
                      display: "inline-flex"
                    }}
                    onChange={(e, newValue) => setRepairMan(newValue)}
                    renderTags={autocompleteRenderTags}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={checkValue}
                        variant="standard"
                        InputLabelProps={{
                          shrink: true
                        }}
                        sx={{
                          input: {
                            color: "black"
                          }
                        }}
                      />
                    )}
                  />
                  <p style={{ display: "inline-flex" }}>ผู้ซ่อม</p>
                </StyledTableCell>
                <StyledTableCell
                  colSpan={2}
                  style={{
                    borderTop: "solid 2px white",
                    borderLeft: "solid 2px white"
                  }}
                >
                  <p style={{ display: "inline-flex" }}>ผู้ตรวจสอบ :</p>

                  {!data ? (
                    <>_____________</>
                  ) : data?.leaderCheck ? (
                    data.leaderCheck
                  ) : leaderCheckStatus ? (
                    <ThemedButton
                      style={checkPermission ? {} : { display: "none" }}
                      onClick={handleApproveLeader}
                    >
                      Approve
                    </ThemedButton>
                  ) : (
                    <>{user.employee}</>
                  )}
                  <p
                    style={{
                      display: "inline-flex",
                      marginLeft: "20px"
                    }}
                  >
                    ผู้ตรวจสอบ :
                    {!data ? (
                      <>_____________</>
                    ) : data?.managerCheck ? (
                      data.managerCheck
                    ) : managerCheckStatus ? (
                      <ThemedButton
                        style={checkPermission ? {} : { display: "none" }}
                        onClick={handleApproveManager}
                      >
                        Approve
                      </ThemedButton>
                    ) : (
                      <>{user.employee}</>
                    )}
                  </p>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  colSpan={3}
                  style={{
                    textAlign: "right",
                    borderTop: "solid 2px white"
                  }}
                >
                  <p
                    style={{
                      display: "inline-flex",
                      marginRight: "30px"
                    }}
                  >
                    หัวหน้าแผนกซ่อมบำรุง
                  </p>
                  <p style={{ display: "inline-flex", marginRight: "115px" }}>
                    ผู้จัดการฝ่ายซ่อมบำรุง
                  </p>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={2} sm={2}>
          {data?.docNo ? (
            <ThemedLoadingButton
              variant="contained"
              sx={{ mt: 1, width: "150px" }}
              style={{ backgroundColor: theme.palette.primary.light }}
              autoFocus
              onClick={handleUpdate}
            >
              UPDATE
            </ThemedLoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              sx={{ mt: 1, width: "150px" }}
              style={{ backgroundColor: theme.palette.grey[900] }}
              autoFocus
              onClick={handleSubmit}
            >
              CONFIRM
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}
export default Repairer
