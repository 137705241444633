interface IBreakTime {
  start: string
  end: string
}

const breakTime = [
  { start: "10:01", end: "10:10" },
  { start: "12:01", end: "13:00" },
  { start: "15:01", end: "15:10" },
  { start: "17:01", end: "17:30" },
  { start: "20:01", end: "08:00" }
]

function parseTime(timeString: string) {
  const [hours, minutes] = timeString.split(":").map(Number)
  return { hours, minutes }
}

function isTimeInRange(time: Date, rangeStart: Date, rangeEnd: Date) {
  return time >= rangeStart && time <= rangeEnd
}

function isWeekend(date: Date, holiday: string): boolean {
  const day: number = date.getDay()
  if (holiday === "เสาร์,อาทิตย์") {
    return false
  }
  if (holiday === "อาทิตย์") {
    return day === 0
  }
  if (holiday === "เสาร์") {
    return day === 6
  }
  return day === 0 || day === 6
}

function calculateBreakTime(
  startTime: Date,
  endTime: Date,
  breakTime: IBreakTime[],
  holiday: string
) {
  const result = []

  for (const breakInterval of breakTime) {
    const breakStart = parseTime(breakInterval.start)
    const breakEnd = parseTime(breakInterval.end)

    const breakStartTime = new Date(startTime)
    breakStartTime.setHours(breakStart.hours, breakStart.minutes, 0, 0)

    const breakEndTime = new Date(startTime)
    breakEndTime.setHours(breakEnd.hours, breakEnd.minutes, 0, 0)

    while (breakEndTime < breakStartTime) {
      breakEndTime.setDate(breakEndTime.getDate() + 1)
    }

    let count = 0

    while (breakStartTime <= endTime) {
      if (isTimeInRange(breakStartTime, startTime, endTime)) {
        if (!isWeekend(breakStartTime, holiday)) {
          // Check if the current day is not Saturday (6) or Sunday (0)
          count++
        }
      }

      breakStartTime.setDate(breakStartTime.getDate() + 1)
      breakEndTime.setDate(breakEndTime.getDate() + 1)
    }

    result.push({ start: breakInterval.start, end: breakInterval.end, count })
  }

  return result
}

function calculateTimeDifference(
  startDate: Date,
  endDate: Date,
  holiday: string
): number {
  let millisecondsDiff = 0
  const currentDate: Date = new Date(startDate)

  while (currentDate < endDate) {
    if (!isWeekend(currentDate, holiday)) {
      const nextDay: Date = new Date(currentDate)
      nextDay.setDate(nextDay.getDate() + 1)
      const endTime: Date = new Date(
        Math.min(nextDay.getTime(), endDate.getTime())
      ) // Ensure we don't exceed endDate
      millisecondsDiff += endTime.getTime() - currentDate.getTime()
    }
    currentDate.setDate(currentDate.getDate() + 1) // Move to the next day
  }

  return millisecondsDiff
}

// Convert the time difference to hours
export const calculateTotalHours = (
  startTime: Date,
  endTime: Date,
  holiday: string
): number => {
  const timeDifference = calculateTimeDifference(startTime, endTime, holiday)

  const totalHours = timeDifference / (1000 * 60)

  const result = calculateBreakTime(startTime, endTime, breakTime, holiday)

  const morningBreak = 10 * result[0].count
  const lunchBreak = 60 * result[1].count
  const afternoonBreak = 10 * result[2].count
  const shiftBreak = 30 * result[3].count
  const overNight = 720 * result[4].count
  return (
    totalHours -
    morningBreak -
    lunchBreak -
    afternoonBreak -
    shiftBreak -
    overNight
  )
}
