import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { LocalizationProvider, TimePicker } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import moment from "moment"
import ThemedRadio from "../../../common/Resources/ThemedComponents/ThemedRadio"
import { IRepairInformant } from "../PMDocument"
import { useMutation, useQuery } from "@apollo/client"
import {
  GET_INFORMANT_PM,
  GET_MASTER_PM_SPARE_PART,
  GET_PM_SPARE_PART
} from "../../../common/Query/engineerQuery"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import {
  CREATE_PM_SPARE_PART,
  DELETE_PM_SPARE_PART_STOCK,
  UPDATE_PM_FROM
} from "../../../common/Mutation/engineerMutation"
import swal from "sweetalert"
import { PMDocumentGrid } from "./RepairInformant"
import { calculateTotalHours } from "./utilities"

interface IProp {
  record: number
  informantPMFrom: IRepairInformant | undefined
  factory: string
}
const timeAsia = new Date().toLocaleString("en-US", {
  timeZone: "Asia/Bangkok",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false
})
const wsUrl =
  "wss://dht6wg9m3k.execute-api.ap-southeast-1.amazonaws.com/production/"
const ws = new WebSocket(wsUrl)

const Repairer: React.FC<IProp> = (props: IProp) => {
  const { record, informantPMFrom, factory } = props
  const [repairDate, setRepairDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [repairTime, setRepairTime] = useState<number>(new Date().getTime())
  const [repairer, setRepairer] = useState<string>("")
  const [repairSendBack, setRepairSendBack] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [checkAndEdit, setCheckAndEdit] = useState<string>("")
  const [purchaseNo, setPurchaseNo] = useState<string>("")
  const [purchaseDate, setPurchaseDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [receiveDate, setReceiveDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [quantity, setQuantity] = useState<number>(0)
  const [amount, setAmount] = useState<number>(0)
  const [startRepairDate, setStartRepairDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [startRepairTime, setStartRepairTime] = useState<number>(3600000)
  const [endRepairDate, setEndRepairDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [endRepairTime, setEndRepairTime] = useState<number>(3600000)
  const recipientList = ["กานดา", "นรินทร"]
  const [recipient, setRecipient] = useState<string>(recipientList[0])
  const [code, setCode] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [cause, setCause] = useState<string>("")
  const [howToRepair, setHowToRepair] = useState<string>("")
  const [summaryRepair, setSummaryRepair] = useState<string>("")
  const [holiday, setHoliday] = useState<string>("")
  const [dieType, setDieType] = useState<string>("")
  const [machineHour, setMachineHour] = useState<number>(0)
  const [machineMinute, setMachineMinute] = useState<number>(0)
  const [manHour, setManHour] = useState<number>(0)
  const [manMinute, setManMinute] = useState<number>(0)

  const { data: masterPMSparePart } = useQuery(GET_MASTER_PM_SPARE_PART)
  const [updateFrom] = useMutation(UPDATE_PM_FROM, {
    refetchQueries: [GET_INFORMANT_PM, GET_PM_SPARE_PART],
    awaitRefetchQueries: true
  })
  const [createPMSparePart] = useMutation(CREATE_PM_SPARE_PART)
  const partNameList = Array.from(
    new Set(
      masterPMSparePart?.masterPMSparePart.map(
        (data: { partName: string }) => data.partName
      )
    )
  )
  const { data: sparePartStockCard } = useQuery(GET_PM_SPARE_PART, {
    variables: {
      code: code,
      factory: factory
    }
  })

  const [deleteSparePartStockCard] = useMutation(DELETE_PM_SPARE_PART_STOCK)
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const repairerList = [
    "ไพโรจน์ หยกสุวรรณกุล",
    "ธงชัย ทัพเสลา",
    "ทัตพร บัวขจร",
    "กฤษกร กระต่ายนาค",
    "ศราวุฒิ โสภา"
  ]

  useEffect(() => {
    ws.onopen
    setSocket(ws)
  }, [])
  const sendMessage = (msg: string) => {
    try {
      if (socket && socket.readyState === WebSocket.OPEN) {
        const messagePayload = {
          action: "sendMessage",
          message: msg
        }

        socket.send(JSON.stringify(messagePayload))
      }
    } catch (e) {
      swal("Error", `${e}`, "warning")
    }
  }
  useEffect(() => {
    setRepairDate(
      informantPMFrom?.repairDate || moment(new Date()).format("YYYY-MM-DD")
    )
    setRepairTime(
      informantPMFrom && informantPMFrom?.repairTime
        ? new Date("2023-01-01 " + informantPMFrom.repairTime).getTime()
        : new Date("2023-01-01 " + moment().format("HH:mm")).getTime()
    )
    setRecipient(informantPMFrom?.recipient || "")
    setCause(informantPMFrom?.cause || "")
    setRepairSendBack(informantPMFrom?.repairSendBack || "")
    setCheckAndEdit(informantPMFrom?.checkAndEdit || "")
    setPurchaseNo(informantPMFrom?.purchaseNo || "")
    setCode(informantPMFrom?.code || "")
    setQuantity(informantPMFrom?.quantity || 0)
    setAmount(informantPMFrom?.amount || 0)
    setStartRepairDate(informantPMFrom?.startRepairDate || "")
    setStartRepairTime(
      new Date("2023-01-01 " + informantPMFrom?.startRepairTime).getTime()
    )
    setEndRepairDate(informantPMFrom?.endRepairDate || "")
    setEndRepairTime(
      new Date("2023-01-01 " + informantPMFrom?.endRepairTime).getTime()
    )
    setRepairer(informantPMFrom?.repairer || "")
    setSummaryRepair(informantPMFrom?.summaryRepair || "")
    setHoliday(informantPMFrom?.holiday || "")
    setHowToRepair(informantPMFrom?.howToRepair || "")
    setDieType(informantPMFrom?.dieType || "")
    const machineTotalTime = informantPMFrom?.machineHour || 0
    const manTotalTime = informantPMFrom?.manHour || 0
    setMachineHour(Math.floor(machineTotalTime))
    setMachineMinute(
      Math.round((machineTotalTime - Math.floor(machineTotalTime)) * 60)
    )
    setManHour(Math.floor(manTotalTime))
    setManMinute(Math.round((manTotalTime - Math.floor(manTotalTime)) * 60))
  }, [informantPMFrom])

  const isComplete = () => {
    if (
      !repairDate ||
      !repairTime ||
      !recipient ||
      !cause ||
      !repairSendBack ||
      !dieType
    ) {
      return false
    }
    return true
  }

  const handleRepairerPMFrom = async () => {
    if (!isComplete()) {
      swal("Error", "กรุณากรอกข้อมูลให้ครบถ้วน", "error")
      return
    }
    try {
      if (
        ((machineHour + manHour) * 60 + (machineMinute + manMinute)) / 60 !==
        totalTime
      ) {
        swal("error", "รวมเวลาไม่เท่ากับเวลาที่ซ่อม", "error")
        return
      }
      if (checkAndEdit === "ซ่อม/เบิกอะไหล่") {
        if (
          !sparePartStockCard?.getSparePartStockCard[0]?.quantity ||
          sparePartStockCard?.getSparePartStockCard[0]?.quantity < quantity
        ) {
          swal("Error", "จำนวนสินค้าไม่พอ", "error")
          return
        } else {
          const inputSparePart = {
            code: code,
            actionDate: repairDate,
            factory: factory,
            quantity: Number(quantity),
            inOrOut: -1,
            employee: recipient,
            repairRequestDate: informantPMFrom?.repairRequestDate,
            documentNo: Number(record)
          }
          await createPMSparePart({
            variables: {
              inputSparePart: inputSparePart
            }
          })
        }
      }
      const machineTotalTime = (machineHour * 60 + machineMinute) / 60
      const manTotalTime = (manHour * 60 + manMinute) / 60
      const inputRepairerPMFrom = {
        documentNo: Number(record),
        repairRequestDate: informantPMFrom?.repairRequestDate,
        factory: informantPMFrom?.factory,
        repairDate: repairDate,
        repairTime: moment(repairTime).format("HH:mm"),
        repairer: repairer,
        repairSendBack: repairSendBack,
        checkAndEdit: checkAndEdit,
        purchaseNo: purchaseNo,
        purchaseDate: purchaseDate,
        receiveDate: receiveDate,
        quantity: quantity,
        amount: amount,
        startRepairDate: startRepairDate,
        startRepairTime: moment(startRepairTime).format("HH:mm"),
        endRepairDate: endRepairDate,
        endRepairTime: moment(endRepairTime).format("HH:mm"),
        recipient: recipient,
        code: code,
        cause: cause,
        howToRepair: howToRepair,
        summaryRepair: summaryRepair,
        dieType,
        machineHour: machineTotalTime,
        manHour: manTotalTime,
        holiday: holiday
      }
      const { data: repairForm } = await updateFrom({
        variables: {
          inputCreatePmFrom: inputRepairerPMFrom
        }
      })
      const sendData = {
        message: repairForm.updatePMFrom?.message,
        วันที่: repairForm.updatePMFrom?.actionDate,
        เลขที่เอกสาร: repairForm.updatePMFrom?.id,
        factory: repairForm.updatePMFrom?.link,
        userRead: repairForm.updatePMFrom?.userRead,
        time: timeAsia,
        status: false
      }
      sendMessage(JSON.stringify(sendData))

      swal("Success", "Update PM From Success", "success")
    } catch (error) {
      swal("Error", (error as Error).message, "error")
    }
  }

  const handleDeleteRepairer = async () => {
    try {
      if (checkAndEdit === "ซ่อม/เบิกอะไหล่") {
        await deleteSparePartStockCard({
          variables: {
            factory: factory,
            documentNo: Number(record),
            repairDate: repairDate,
            repairRequestDate: informantPMFrom?.repairRequestDate
          }
        })
      }
      const inputRepairerPMFrom = {
        documentNo: Number(record),
        repairRequestDate: informantPMFrom?.repairRequestDate,
        factory: informantPMFrom?.factory,
        repairDate: "",
        repairTime: "",
        repairer: "",
        repairSendBack: "",
        checkAndEdit: "",
        purchaseNo: "",
        purchaseDate: "",
        receiveDate: "",
        quantity: 0,
        amount: 0,
        startRepairDate: "",
        startRepairTime: "",
        endRepairDate: "",
        endRepairTime: "",
        recipient: "",
        code: "",
        cause: "",
        howToRepair: "",
        summaryRepair: "",
        dieType: ""
      }
      await updateFrom({
        variables: {
          inputCreatePmFrom: inputRepairerPMFrom
        }
      })

      swal("Success", "Delete PM Repairer From Success", "success")
    } catch (error) {
      swal("Error", (error as Error).message, "error")
    }
  }

  const calculatePaymentMachine = () => {
    if (machineHour === 0 && machineMinute === 0) {
      return 0
    }
    const totalHour = machineHour + machineMinute / 60
    const totalPayment = totalHour * 850
    return totalPayment.toFixed(2)
  }
  const calculatePaymentMan = () => {
    if (manHour === 0 && manMinute === 0) {
      return 0
    }
    const totalHour = manHour + manMinute / 60
    const totalPayment = totalHour * 130
    return totalPayment.toFixed(2)
  }
  const totalTime =
    calculateTotalHours(
      new Date(`${startRepairDate} ` + moment(startRepairTime).format("HH:mm")),
      new Date(`${endRepairDate} ` + moment(endRepairTime).format("HH:mm")),
      holiday
    ) / 60
  const hour = Math.floor(totalTime)
  const minute = Math.round((totalTime - hour) * 60)

  return (
    <Box>
      <Grid
        container
        item
        border={"2px solid black"}
        bgcolor={"white"}
        sx={{
          "@media print": {
            zoom: "60%"
          }
        }}
      >
        <Grid item container xs={12}>
          <Grid item container xs={12}>
            <Grid item xs={3}>
              <Typography pl={1}>
                2.ผู้รับซ่อม (หัวหน้าแผนกซ่อม Tooling)
              </Typography>
            </Grid>
            <PMDocumentGrid item xs={1}>
              <Typography>วันที่</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2}>
              <TextField
                type="date"
                variant="standard"
                disabled
                onChange={(e) => setRepairDate(e.target.value)}
                value={repairDate}
                sx={{
                  input: {
                    color: "black",
                    textAlign: "center"
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#666666"
                  }
                }}
              />
            </PMDocumentGrid>
            <PMDocumentGrid item xs={1}>
              <Typography>เวลา</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                  ampm={false}
                  value={repairTime}
                  disabled
                  onChange={(value) => {
                    setRepairTime(value ? value : 0)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      sx={{
                        input: {
                          color: "black",
                          textAlign: "center"
                        },

                        svg: {
                          color: "black"
                        }
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </PMDocumentGrid>
            <PMDocumentGrid
              item
              xs={1}
              display={"flex"}
              justifyItems={"center"}
            >
              <Typography>โรงงาน</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={1}>
              <Typography>{factory}</Typography>
            </PMDocumentGrid>
          </Grid>

          <Grid item container xs={12} borderBottom="2px solid black">
            <PMDocumentGrid item xs={2}>
              <Typography>ลงชื่อ</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2} pb={1}>
              <Autocomplete
                fullWidth
                value={recipient}
                options={recipientList || []}
                disablePortal
                disableClearable
                popupIcon={null}
                onChange={(e, value) => setRecipient(value as string)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      input: {
                        color: "black",
                        textAlign: "center"
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#666666"
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#666666"
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#666666"
                      }
                    }}
                  />
                )}
              />
            </PMDocumentGrid>
            <PMDocumentGrid
              item
              xs={2}
              display={"flex"}
              justifyItems={"center"}
            >
              <Typography>สาเหตุ</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid
              item
              xs={2}
              display={"flex"}
              justifyItems={"center"}
            >
              <TextField
                multiline
                maxRows={4}
                variant="standard"
                value={cause}
                fullWidth
                onChange={(e) => setCause(e.target.value)}
                sx={{
                  pb: 1,
                  input: {
                    color: "black",
                    textAlign: "center"
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#666666"
                  }
                }}
              />
            </PMDocumentGrid>
            <PMDocumentGrid
              item
              xs={1}
              display={"flex"}
              justifyItems={"center"}
            >
              <Typography>วันที่ ส่งกลับ</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid
              item
              xs={1}
              display={"flex"}
              justifyItems={"center"}
            >
              <TextField
                type="date"
                variant="standard"
                onChange={(e) => setRepairSendBack(e.target.value)}
                value={repairSendBack}
                sx={{
                  input: {
                    color: "black",
                    textAlign: "center"
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#666666"
                  }
                }}
              />
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2}>
              <FormControl>
                <RadioGroup
                  row
                  onChange={(e) => {
                    setDieType(e.target.value)
                  }}
                  value={dieType}
                >
                  <FormControlLabel
                    value="Big"
                    control={<ThemedRadio />}
                    label={"Big"}
                  />
                  <FormControlLabel
                    value="Small"
                    control={<ThemedRadio />}
                    label={"Small"}
                  />
                </RadioGroup>
              </FormControl>
            </PMDocumentGrid>
          </Grid>

          <Grid item container xs={12} borderBottom="2px solid black">
            <Grid
              item
              xs={3}
              sx={{
                justifyContent: "flex-start",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Typography pl={1}>
                3. ตรวจสอบและแก้ปัญหา (หัวหน้างานซ่อม)
              </Typography>
            </Grid>
            <PMDocumentGrid item xs={9} pt={1} pb={1}>
              <FormControl>
                <RadioGroup
                  row
                  onChange={(e) => {
                    setPurchaseNo("")
                    setPurchaseDate("")
                    setReceiveDate("")
                    setCode("")
                    setQuantity(0)
                    setAmount(0)
                    setStartRepairDate("")
                    setStartRepairTime(0)
                    setEndRepairDate("")
                    setEndRepairTime(0)
                    setRepairer("")
                    setSummaryRepair("")
                    setHoliday("")
                    setHowToRepair("")
                    setMachineHour(0)
                    setManHour(0)
                    setManMinute(0)
                    setMachineHour(0)
                    setMachineMinute(0)
                    setManHour(0)
                    setCheckAndEdit(e.target.value)
                  }}
                  value={checkAndEdit}
                >
                  <FormControlLabel
                    value="สั่งซื้อใหม่"
                    control={<ThemedRadio />}
                    label={
                      <>
                        สั่งซื้อใหม่
                        <br />
                        (ไปขั้นตอนที่ 4)16-30 Day
                      </>
                    }
                  />
                  <FormControlLabel
                    value="ซ่อม/เบิกอะไหล่"
                    style={{ paddingLeft: "103px" }}
                    control={<ThemedRadio />}
                    label={
                      <>
                        ซ่อม/เบิกอะไหล่
                        <br />
                        (ไปขั้นตอนที่ 5)1-7 Day
                      </>
                    }
                  />
                  <FormControlLabel
                    value="ซ่อม/ไม่เบิกอะไหล่"
                    style={{
                      paddingLeft: "100px",
                      paddingRight: "100px"
                    }}
                    control={<ThemedRadio />}
                    label={
                      <>
                        ซ่อม/ไม่เบิกอะไหล่
                        <br />
                        (ไปขั้นตอนที่ 6)1 Day
                      </>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </PMDocumentGrid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            borderBottom="2px solid black"
            justifyContent={"center"}
          >
            <Typography textAlign={"center"}>
              ทำการวิเคราะห์หาสาเหตุ และตนวจสอบ Tooling เสร็จ สรุป การแก่ไข
              หัวหน้างานซ่อมส่งใบแจ้งซ่อมกลับไปทางผลิต เพื่อจัดทำแผนงาน ภายใน 2
              วันทำการ
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            borderBottom="2px solid black"
            sx={{
              display: checkAndEdit === "สั่งซื้อใหม่" ? {} : "none"
            }}
          >
            <Grid item xs={2}>
              <Typography pl={1}>
                4. กรณีสั่งซื้อชิ้นส่วนซ่อม Tooling
              </Typography>
            </Grid>
            <PMDocumentGrid item xs={1}>
              <Typography>เลขที่ใบสั่งซื้อ</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2}>
              <TextField
                variant="standard"
                fullWidth
                onChange={(e) => setPurchaseNo(e.target.value)}
                value={purchaseNo}
                sx={{
                  pb: 1,
                  input: {
                    color: "black",
                    textAlign: "center"
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#666666"
                  }
                }}
              />
            </PMDocumentGrid>
            <PMDocumentGrid item xs={1}>
              <Typography>วันที่สั่ง</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2}>
              <TextField
                type="date"
                variant="standard"
                onChange={(e) => setPurchaseDate(e.target.value)}
                value={purchaseDate}
                sx={{
                  pb: 1,
                  input: {
                    color: "black",
                    textAlign: "center"
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#666666"
                  }
                }}
              />
            </PMDocumentGrid>
            <PMDocumentGrid item xs={1}>
              <Typography>วันที่รับ</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2}>
              <TextField
                variant="standard"
                type="date"
                onChange={(e) => setReceiveDate(e.target.value)}
                value={receiveDate}
                sx={{
                  pb: 1,
                  input: {
                    color: "black",
                    textAlign: "center"
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#666666"
                  }
                }}
              />
            </PMDocumentGrid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            borderBottom="2px solid black"
            sx={{
              display:
                checkAndEdit === "ซ่อม/เบิกอะไหล่" ||
                checkAndEdit === "สั่งซื้อใหม่"
                  ? {}
                  : "none"
            }}
          >
            <Grid item xs={2}>
              <Typography pl={1}>5. เบิกชิ้นส่วน Tooling</Typography>
            </Grid>
            <PMDocumentGrid item xs={1}>
              <Typography>Part Name</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2}>
              <Autocomplete
                fullWidth
                value={partName}
                options={partNameList || []}
                disablePortal
                disableClearable
                popupIcon={null}
                onChange={(e, value) => setPartName(value as string)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      input: {
                        color: "black",
                        textAlign: "center"
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#666666"
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#666666"
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#666666"
                      }
                    }}
                  />
                )}
              />
            </PMDocumentGrid>
            <PMDocumentGrid item xs={1}>
              <Typography>ชื่อ/รหัส/</Typography>
            </PMDocumentGrid>

            <PMDocumentGrid item xs={2}>
              <Autocomplete
                fullWidth
                value={code}
                options={
                  masterPMSparePart?.masterPMSparePart
                    .filter(
                      (val: { partName: string; code: string }) =>
                        val.partName === partName && val.code
                    )
                    .map((item: { code: string }) => item.code) || []
                }
                disablePortal
                disableClearable
                popupIcon={null}
                onChange={(e, value) => setCode(value as string)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      input: {
                        color: "black",
                        textAlign: "center"
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#666666"
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#666666"
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#666666"
                      }
                    }}
                  />
                )}
              />
            </PMDocumentGrid>
            <PMDocumentGrid item xs={1}>
              <Typography>Current Stock</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2}>
              <Typography>
                {sparePartStockCard?.getSparePartStockCard[0]?.quantity ||
                  "ไม่มี Stock"}
              </Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2}>
              <Typography>จำนวน</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2}>
              <TextField
                variant="standard"
                type="number"
                value={quantity ? quantity : ""}
                inputProps={{ step: "0.1", lang: "en-US" }}
                onChange={(e) => setQuantity(Number(e.target.value))}
                sx={{
                  pb: 1,
                  input: {
                    color: "black",
                    textAlign: "center"
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#666666"
                  }
                }}
              />
              ชิ้น
            </PMDocumentGrid>
            <PMDocumentGrid item xs={1}>
              <Typography>ราคา</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={2}>
              <TextField
                variant="standard"
                inputProps={{ step: "0.1", lang: "en-US" }}
                onChange={(e) => setAmount(Number(e.target.value))}
                value={amount ? amount : ""}
                sx={{
                  pb: 1,
                  input: {
                    color: "black",
                    textAlign: "center"
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#666666"
                  }
                }}
              />
              บาท
            </PMDocumentGrid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            sx={{
              display:
                checkAndEdit === "ซ่อม/ไม่เบิกอะไหล่" ||
                checkAndEdit === "ซ่อม/เบิกอะไหล่" ||
                checkAndEdit === "สั่งซื้อใหม่"
                  ? {}
                  : "none"
            }}
          >
            <Grid item container xs={12}>
              <Grid item xs={2}>
                <Typography pl={1} mt={1}>
                  6.ซ่อม (ช่างซ่อม)
                </Typography>
              </Grid>
              <PMDocumentGrid item xs={1}>
                <Typography textAlign={"center"}>ซ่อมวันที่</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={1}>
                <TextField
                  type="date"
                  variant="standard"
                  onChange={(e) => setStartRepairDate(e.target.value)}
                  value={startRepairDate}
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
              </PMDocumentGrid>
              <PMDocumentGrid item xs={1}>
                <Typography textAlign={"center"}>เวลา</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={1}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    ampm={false}
                    value={startRepairTime}
                    onChange={(value) => {
                      setStartRepairTime(value ? value : 0)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        sx={{
                          input: {
                            color: "black",
                            textAlign: "center"
                          },

                          svg: {
                            color: "black"
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </PMDocumentGrid>

              <PMDocumentGrid item xs={1}>
                <Typography textAlign={"center"}>ซ่อมเสร็จเมื่อ</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={1}>
                <TextField
                  type="date"
                  variant="standard"
                  onChange={(e) => setEndRepairDate(e.target.value)}
                  value={endRepairDate}
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
              </PMDocumentGrid>
              <PMDocumentGrid item xs={1}>
                <Typography textAlign={"center"}>เวลา</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={1}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    ampm={false}
                    value={endRepairTime}
                    onChange={(value) => {
                      setEndRepairTime(value ? value : 0)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        sx={{
                          input: {
                            color: "black",
                            textAlign: "center"
                          },

                          svg: {
                            color: "black"
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={2}>
                <MultiSelectComponent
                  setSummaryRepair={setHoliday}
                  summaryRepair={holiday}
                  options={["เสาร์", "อาทิตย์"]}
                />
              </PMDocumentGrid>
            </Grid>
            <Grid item container xs={12}>
              <PMDocumentGrid item xs={2}>
                <Typography pl={1}>เวลารวม</Typography>
                <Typography sx={{ pl: 1 }}>
                  {hour} ชม. {minute} นาที
                </Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={1}>
                <Typography>สรุปการซ่อม</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={9}>
                <MultiSelectComponent
                  setSummaryRepair={setSummaryRepair}
                  summaryRepair={summaryRepair}
                  options={[
                    "Machine Basic",
                    "2D",
                    "3D",
                    "Lower",
                    "Upper",
                    "Stipper Pad",
                    "เสริมแผ่นซิม",
                    "เจียรคมตัด",
                    "เจียรปรับแต่งแม่พิมพ์"
                  ]}
                />
              </PMDocumentGrid>
            </Grid>
            <Grid item container xs={12}>
              <PMDocumentGrid item xs={1}>
                <Typography>{"Machine"}</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={2}>
                <TextField
                  variant="standard"
                  type="number"
                  disabled={!summaryRepair.includes("Machine Basic")}
                  inputProps={{ step: "0.1", lang: "en-US" }}
                  onChange={(e) => setMachineHour(Number(e.target.value))}
                  value={machineHour ? machineHour : ""}
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center",
                      width: "100px"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
                ชม.
                <TextField
                  variant="standard"
                  type="number"
                  disabled={!summaryRepair.includes("Machine Basic")}
                  inputProps={{ step: "0.1", lang: "en-US" }}
                  onChange={(e) => setMachineMinute(Number(e.target.value))}
                  value={machineMinute ? machineMinute : ""}
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center",
                      width: "100px"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
                นาที
                <Typography>x850</Typography>
              </PMDocumentGrid>

              <PMDocumentGrid item xs={"auto"}>
                <Typography pl={1}>ค่าใช้จ่าย</Typography>
                <Typography pl={1}>{calculatePaymentMachine()} บาท</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={"auto"}>
                <Typography pl={1}>{"Man"}</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={"auto"}>
                <TextField
                  variant="standard"
                  type="number"
                  inputProps={{ step: "0.1", lang: "en-US" }}
                  onChange={(e) => setManHour(Number(e.target.value))}
                  value={manHour ? manHour : ""}
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center",
                      width: "100px"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
                ชม.
                <TextField
                  variant="standard"
                  type="number"
                  disabled={summaryRepair.length > 0 ? false : true}
                  inputProps={{ step: "0.1", lang: "en-US" }}
                  onChange={(e) => setManMinute(Number(e.target.value))}
                  value={manMinute ? manMinute : ""}
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center",
                      width: "100px"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
                นาที
                <Typography>x130</Typography>
              </PMDocumentGrid>

              <PMDocumentGrid item xs={"auto"}>
                <Typography pl={1}>ค่าใช้จ่าย </Typography>
                <Typography pl={1}>{calculatePaymentMan()} บาท</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={"auto"}>
                <Typography>ลงชื่อ ช่างซ่อม</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={2}>
                <Autocomplete
                  fullWidth
                  value={repairer}
                  options={repairerList}
                  disablePortal
                  disableClearable
                  popupIcon={null}
                  onChange={(e, value) => setRepairer(value as string)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        input: {
                          color: "black",
                          textAlign: "center"
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#666666"
                        }
                      }}
                    />
                  )}
                />
              </PMDocumentGrid>
            </Grid>

            <Grid item container xs={12}>
              <PMDocumentGrid item xs={1}>
                <Typography>วิธีการซ่อม</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={3}>
                <TextField
                  multiline
                  value={howToRepair}
                  onChange={(e) => setHowToRepair(e.target.value)}
                  maxRows={4}
                  variant="standard"
                  sx={{
                    width: "500px",
                    pb: 1,
                    input: {
                      color: "black",
                      textAlign: "center"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
              </PMDocumentGrid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          "@media print": {
            display: "none"
          },
          display: informantPMFrom?.qcDate ? "none" : "flex"
        }}
      >
        <Grid item xs={2}>
          <ThemedButton fullWidth sx={{ mt: 1 }} onClick={handleRepairerPMFrom}>
            {informantPMFrom?.repairDate ? "Update" : "Create From"}
          </ThemedButton>
        </Grid>
        <Grid item xs={2}>
          <ThemedButton
            fullWidth
            sx={{ mt: 1, display: informantPMFrom?.repairDate ? "" : "none" }}
            onClick={handleDeleteRepairer}
          >
            Delete
          </ThemedButton>
        </Grid>
      </Grid>
    </Box>
  )
}
interface MultiSelectComponentProps {
  options: string[]
  setSummaryRepair: (value: string) => void
  summaryRepair: string
}
const MultiSelectComponent: React.FC<MultiSelectComponentProps> = ({
  options,
  setSummaryRepair,
  summaryRepair
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  useEffect(() => {
    setSelectedOptions(summaryRepair.split(","))
  }, [summaryRepair])
  const handleCheckboxChange = (value: string) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== value))
    } else {
      setSelectedOptions([...selectedOptions, value])
    }
  }
  useEffect(() => {
    setSummaryRepair(selectedOptions.filter((e) => e).join(","))
  }, [selectedOptions])
  return (
    <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
      {options.map((option) => (
        <FormControlLabel
          key={option}
          control={
            <Checkbox
              checked={selectedOptions.includes(option)}
              onChange={() => handleCheckboxChange(option)}
              value={option}
            />
          }
          label={option}
        />
      ))}
    </FormGroup>
  )
}
export default Repairer
