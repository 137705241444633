import { Box, Grid, Tab, Tabs, ThemeProvider, useTheme } from "@mui/material"
import { useState } from "react"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import CreateDockAuditCheckSheet from "./Component/CreateDockAuditCheckSheet"
import ReportDockAuditCheckSheet from "./Component/ReportDockAuditCheckSheet"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"

interface IPropsTabPanel {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: IPropsTabPanel) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

const MasterDockAuditCheckSheet: React.FC = () => {
  const [tapMenu, setTapMenu] = useState<number>(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTapMenu(newValue)
  }

  const tapProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    }
  }
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Qc}
        menuItemName={Object.keys(menuItemList)[4]}
        menuPageName={"Dock Audit Check Sheet"}
      />
      <ThemedCard>
        <PageLabelCard
          title="Dock Audit Check Sheet"
          subTitle="About detail Dock Audit Check Sheet"
        />
        <Grid sx={{ mt: "20px" }} item xs={12} sm={12}>
          <Box className="hidden-print">
            <Tabs value={tapMenu} onChange={handleChange}>
              <Tab label="Create Audit Check Sheet" {...tapProps(0)} />
              <Tab label="Report Audit Check Sheet" {...tapProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tapMenu} index={0}>
            <CreateDockAuditCheckSheet />
          </TabPanel>
          <TabPanel value={tapMenu} index={1}>
            <ReportDockAuditCheckSheet />
          </TabPanel>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

export default MasterDockAuditCheckSheet
