import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { GET_PARTS } from "../../Query/MasterQuery"

export const usePartNo = (): string[] => {
  const [partNo, setPartNo] = useState<string[]>([""])
  const { data } = useQuery(GET_PARTS)
  useEffect(() => {
    if (data) {
      setPartNo(data.parts.map((part: { partNo: string }) => part.partNo))
    }
  }, [data])
  return partNo
}
