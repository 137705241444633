import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ThemeProvider,
  styled,
  tableCellClasses,
  useTheme
} from "@mui/material"
import { PageLabel } from "../../common/Resources/PageLabel"
import { menuItemList } from "../../common/Resources/menuItemList"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import AutocompletePartNo from "../../common/Resources/AutocompletePartNo"
import { useState, useEffect } from "react"
import { logoTable } from "../../common/utilities"
import { GET_SUPPLIER_STOCK_CARD } from "../../common/Query/SupplierControl"
import { useQuery } from "@apollo/client"
import { GET_PARTS } from "../../common/Query/MasterQuery"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import ThemedLoadingButton from "../../common/Resources/ThemedComponents/ThemedLoadingButton"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "20px",
    fontFamily: "Sarabun",
    textAlign: "center",
    border: "1px solid black",
    padding: "5px"
  }
}))
interface IRows {
  date: string
  remarkIn: string
  remarkOut: string
  quantityIn: number
  quantityOut: number
  lotNoIn: string
  lotNoOut: string
  total: number
  supplier: string
}
const SupplierStockCard = (): JSX.Element => {
  const [partNo, setPartNo] = useState<string>("")
  const [partName, setPartName] = useState<string>("")
  const [customer, setCustomer] = useState<string>("")
  const [dateFrom, setDateFrom] = useState<string>("")
  const [dateTo, setDateTo] = useState<string>("")
  const { data: getPart } = useQuery(GET_PARTS)
  const { data, refetch } = useQuery(GET_SUPPLIER_STOCK_CARD, {
    variables: { partNo: partNo },
    fetchPolicy: "no-cache"
  })
  const [rowsPrintTable, setRowsPrintTable] = useState<IRows[]>([])
  const [rowsTable, setRowsTable] = useState<IRows[]>([])

  useEffect(() => {
    if (data) {
      setRowsPrintTable(data?.getSupplierStockCard || [])
      setRowsTable(data?.getSupplierStockCard || [])
      const findPart = getPart?.parts.find(
        (part: { partNo: string }) => part.partNo === partNo
      )
      setPartName(findPart?.partName)
      setCustomer(findPart?.customer)
    }
  }, [data])
  useEffect(() => {
    if (dateFrom && dateTo) {
      const filterDate = rowsTable.filter((row) => {
        return row.date >= dateFrom && row.date <= dateTo
      })
      setRowsPrintTable(filterDate)
    }
  }, [dateFrom, dateTo, rowsTable])

  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <PageLabel
          menuItem={menuItemList.SupplierControl}
          menuItemName={Object.keys(menuItemList)[18]}
          menuPageName={"Supplier Stock Card"}
        />
        <ThemedCard>
          <PageLabelCard
            title="Supplier Record"
            subTitle="detail about Supplier record"
          />
          <Grid
            container
            spacing={2}
            width={"98%"}
            margin={2}
            sx={{
              "@media print": {
                display: "none"
              }
            }}
          >
            <Grid item xs={12} sm={4}>
              <AutocompletePartNo setPartNo={setPartNo} partNo={partNo} />
            </Grid>
            <Grid item xs={2}>
              <ThemedTextField
                fullWidth
                value={dateFrom}
                label="Date From"
                id="dateFrom"
                type="date"
                autoComplete="family-name"
                onChange={(e) => setDateFrom(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={2}>
              <ThemedTextField
                fullWidth
                value={dateTo}
                id="dateTo"
                type="date"
                label="Date To"
                autoComplete="family-name"
                onChange={(e) => setDateTo(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={2}>
              <ThemedLoadingButton
                variant="contained"
                style={{
                  width: "120px",
                  height: "36px",
                  backgroundColor: theme.palette.primary.main
                }}
                sx={{ mt: 1, mb: 1 }}
                onClick={() => refetch()}
              >
                Submit
              </ThemedLoadingButton>
            </Grid>
          </Grid>
          <Card style={{ marginTop: "26px" }}>
            <PageLabelCard
              title="View Part History"
              subTitle="detail about View Part History"
            />
            <Grid
              container
              spacing={2}
              width={"98%"}
              margin={2}
              justifyContent={"center"}
            >
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell colSpan={3} rowSpan={2}>
                        <img
                          src={logoTable}
                          alt=""
                          style={{ width: "400px", backgroundColor: "white" }}
                        />
                      </StyledTableCell>
                      <StyledTableCell colSpan={5} rowSpan={2}>
                        <label className="label_auto_center_font">
                          SUPPLIER STOCK CARD
                        </label>
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}>
                        <label className="label_auto_center_font">
                          F-PL-03-03
                        </label>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={2}
                        style={{ textAlign: "left" }}
                      >
                        <label className="label_auto_center_font">
                          Effective Date: 14/01/08 <br></br>
                          R02 Issue: 1
                        </label>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell colSpan={5}>
                        <div>
                          <b>ชื่อลูกค้า:{customer ? customer : ""}</b>
                        </div>
                        <div>
                          <b>รหัสสินค้า ( Part No.): {partNo}</b>
                        </div>
                        <div>
                          <b>
                            ชื่อสินค้า (Part Name): {partName ? partName : ""}
                          </b>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell colSpan={5}>
                        <div>
                          <b>Supplier: </b>
                          {/* {rowsPrintTable.length &&
                            rowsPrintTable.map((row) => row.supplier).join(",")} */}
                        </div>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell rowSpan={2} colSpan={1}>
                        วัน/เดือน/ปี
                      </StyledTableCell>
                      <StyledTableCell colSpan={3}>
                        บันทึกรับเข้า (RECEIVING RECORD)
                      </StyledTableCell>
                      <StyledTableCell colSpan={4}>
                        บันทึกจ่ายออก (DISTRIBUTE RECORD)
                      </StyledTableCell>
                      <StyledTableCell rowSpan={2} colSpan={1}>
                        ยอดคงเหลือ
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell colSpan={1}>หมายเหตุ</StyledTableCell>
                      <StyledTableCell colSpan={1}>Lot No.</StyledTableCell>
                      <StyledTableCell colSpan={1}>
                        รับเข้า (pcs.)
                      </StyledTableCell>
                      <StyledTableCell colSpan={1}>หมายเหตุ</StyledTableCell>
                      <StyledTableCell colSpan={1}>Supplier</StyledTableCell>
                      <StyledTableCell colSpan={1}>Lot No.</StyledTableCell>
                      <StyledTableCell colSpan={1}>
                        จ่ายออก (pcs.)
                      </StyledTableCell>
                    </TableRow>
                    {rowsPrintTable.length > 0 ? (
                      rowsPrintTable.map((row, i) => {
                        return (
                          <TableRow key={i}>
                            <StyledTableCell colSpan={1}>
                              {row.date}
                            </StyledTableCell>
                            <StyledTableCell colSpan={1}>
                              {row.remarkIn}
                            </StyledTableCell>
                            <StyledTableCell colSpan={1}>
                              {row.lotNoIn}
                            </StyledTableCell>
                            <StyledTableCell colSpan={1}>
                              {row.quantityIn}
                            </StyledTableCell>
                            <StyledTableCell colSpan={1}>
                              {row.remarkOut}
                            </StyledTableCell>
                            <StyledTableCell colSpan={1}>
                              {row.supplier}
                            </StyledTableCell>
                            <StyledTableCell colSpan={1}>
                              {row.lotNoOut}
                            </StyledTableCell>
                            <StyledTableCell colSpan={1}>
                              {row.quantityOut}
                            </StyledTableCell>
                            <StyledTableCell colSpan={1}>
                              {row.total}
                            </StyledTableCell>
                          </TableRow>
                        )
                      })
                    ) : (
                      <TableRow>
                        <StyledTableCell colSpan={1}></StyledTableCell>
                        <StyledTableCell colSpan={1}></StyledTableCell>
                        <StyledTableCell colSpan={1}></StyledTableCell>
                        <StyledTableCell colSpan={1}></StyledTableCell>
                        <StyledTableCell colSpan={1}></StyledTableCell>
                        <StyledTableCell colSpan={1}></StyledTableCell>
                        <StyledTableCell colSpan={1}></StyledTableCell>
                        <StyledTableCell colSpan={1}></StyledTableCell>
                        <StyledTableCell colSpan={1}></StyledTableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <StyledTableCell rowSpan={3} colSpan={4}>
                        <b>Remarks:</b>
                      </StyledTableCell>
                      <StyledTableCell rowSpan={1} colSpan={2}>
                        <b>ผู้จัดทำ</b>
                      </StyledTableCell>
                      <StyledTableCell rowSpan={1} colSpan={2}>
                        <b>ผู้ตรวจสอบ</b>
                      </StyledTableCell>
                      <StyledTableCell rowSpan={1} colSpan={2}>
                        <b>ผู้อนุมัติ</b>
                      </StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell
                        sx={{ height: "80px" }}
                        rowSpan={1}
                        colSpan={2}
                      ></StyledTableCell>
                      <StyledTableCell
                        sx={{ height: "80px" }}
                        rowSpan={1}
                        colSpan={2}
                      ></StyledTableCell>
                      <StyledTableCell
                        sx={{ height: "80px" }}
                        rowSpan={1}
                        colSpan={2}
                      ></StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell rowSpan={1} colSpan={2}>
                        <b>พนักงานแผนกสโตร์</b>
                      </StyledTableCell>
                      <StyledTableCell rowSpan={1} colSpan={2}>
                        <b>หัวหน้าแผนกสโตร์</b>
                      </StyledTableCell>
                      <StyledTableCell rowSpan={1} colSpan={2}>
                        <b>ผู้จัดการฝ่ายวางแผน</b>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Card>
        </ThemedCard>
      </Box>
    </ThemeProvider>
  )
}
export default SupplierStockCard
