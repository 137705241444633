import { Divider, Grid, Typography, useTheme } from "@mui/material"
import moment from "moment"
import React, { useRef, useState } from "react"
import AutocompleteFactory from "../../../../../common/Resources/AutocompleteFactory"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useQuery } from "@apollo/client"
import { GET_MACHINE_PARETO } from "../../../../../common/Query/engineerQuery"
import ThemedCard from "../../../../../common/Resources/ThemedComponents/ThemedCard"
import { ThemeProvider } from "@mui/styles"
import ThemedTextField from "../../../../../common/Resources/ThemedComponents/ThemedTextField"

interface IParetoMachine {
  machine: string
  machineCount: number
}
const ParetoMachine: React.FC = () => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment().format("YYYY-MM-01")
  )
  const [dateTo, setDateTo] = useState<string>(moment().format("YYYY-MM-DD"))
  const [factory, setFactory] = useState<string>("2")
  const { data: queryData } = useQuery(GET_MACHINE_PARETO, {
    variables: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      factory: factory
    }
  })

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <ThemedCard sx={{ background: theme.palette.secondary.main }}>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", padding: "10px" }}
            >
              Defect Pareto Machine
            </Typography>
          </Grid>
          <Grid item container spacing={1} padding={1} sm={9}>
            <Grid item sm={4}>
              <ThemedTextField
                fullWidth
                value={dateFrom}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date From"
                autoComplete="family-name"
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Grid>
            <Grid item sm={4}>
              <ThemedTextField
                fullWidth
                value={dateTo}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date To"
                autoComplete="family-name"
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Grid>
            <Grid item sm={4}>
              <AutocompleteFactory setFactory={setFactory} factory={factory} />
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={12} justifyContent="center">
            <ChartDefectReport
              data={queryData?.getListProblemMachineList ?? []}
            />
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}

const ChartDefectReport = (props: {
  chart?: HighchartsReact.Props
  data: IParetoMachine[]
}): JSX.Element => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const { data } = props
  const theme = useTheme()

  const options: Highcharts.Options = {
    chart: {
      renderTo: "container",
      type: "column",
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      shared: true
    },
    xAxis: {
      categories: data.map((item) => item.machine),
      crosshair: true,
      labels: {
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "14px"
        }
      }
    },
    legend: {
      enabled: false
    },
    yAxis: [
      {
        labels: {
          style: {
            color: theme.palette.secondary.contrastText
          }
        },
        title: {
          text: "",
          style: {
            color: theme.palette.secondary.contrastText
          }
        }
      },
      {
        title: {
          text: "",
          style: {
            color: theme.palette.secondary.contrastText
          }
        },
        minPadding: 0,
        maxPadding: 0,
        max: 100,
        min: 0,
        opposite: true,
        labels: {
          format: "{value}%",
          style: {
            color: theme.palette.secondary.contrastText
          }
        }
      }
    ],
    series: [
      {
        type: "pareto",
        name: "Percentage",
        yAxis: 1,
        zIndex: 10,
        baseSeries: 1,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: "%"
        },
        color: "#4595F7"
      },
      {
        name: "จำนวน",
        type: "column",
        zIndex: 2,
        color: "#FF924C",
        data: data.map((item) => item.machineCount)
      }
    ],
    exporting: {
      enabled: false
    }
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props.chart}
      />
    </>
  )
}
export default ParetoMachine
