import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  TextField,
  Typography,
  useTheme
} from "@mui/material"
import { PMDocumentGrid } from "./RepairInformant"
import { useEffect, useState } from "react"
import ThemedRadio from "../../../common/Resources/ThemedComponents/ThemedRadio"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import { IRepairInformant } from "../PMDocument"
import { useMutation } from "@apollo/client"
import {
  CREATE_PM_FROM,
  DELETE_PM_FROM,
  DELETE_PM_SPARE_PART_STOCK,
  UPDATE_PM_FROM
} from "../../../common/Mutation/engineerMutation"
import { GET_INFORMANT_PM } from "../../../common/Query/engineerQuery"
import swal from "sweetalert"
import { Print, Delete, Add } from "@mui/icons-material"
import { user } from "../../../common/MainApp"
import moment from "moment"

interface IProp {
  record: number
  informantPMFrom: IRepairInformant | undefined
  factory: string
  handleAdd: () => void
}
const leaderQCList = [
  "ผดุงศักดิ์ พ่อสียา",
  "กฤษณา มังเดชะ",
  "สนิสา กำปงซัน",
  "วิไลวรรณ ยงยืน",
  "ไชยศ",
  "เลิศวรรธน์",
  "เสน่ห์"
]

const wsUrl =
  "wss://dht6wg9m3k.execute-api.ap-southeast-1.amazonaws.com/production/"
const ws = new WebSocket(wsUrl)

const PMQCFrom: React.FC<IProp> = (props: IProp) => {
  const { informantPMFrom, record, factory } = props
  const [NGQuantity, setNGQuantity] = useState<number>(0)
  const [summaryQC, setSummaryQC] = useState<string>("")
  const [leaderQC, setLeaderQC] = useState<string>("")
  const [qcDate, setQCDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [leaderTooling, setLeaderTooling] = useState<string>("")
  const [leaderToolingDate, setLeaderToolingDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [deliverLeaderProduction, setDeliverLeaderProduction] =
    useState<string>("")
  const [deliverDate, setDeliverDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [updateFrom] = useMutation(UPDATE_PM_FROM, {
    refetchQueries: [GET_INFORMANT_PM],
    awaitRefetchQueries: true
  })
  const [deleteFrom] = useMutation(DELETE_PM_FROM, {
    refetchQueries: [],
    awaitRefetchQueries: true
  })
  const [createPMEngineerFrom] = useMutation(CREATE_PM_FROM, {
    refetchQueries: [GET_INFORMANT_PM],
    awaitRefetchQueries: true
  })
  const [deleteSparePartStockCard] = useMutation(DELETE_PM_SPARE_PART_STOCK)
  const [socket, setSocket] = useState<WebSocket | null>(null)

  useEffect(() => {
    setNGQuantity(informantPMFrom?.NGQuantity || 0)
    setSummaryQC(informantPMFrom?.summaryQC || "")
    setLeaderQC(informantPMFrom?.leaderQC || "")
    setQCDate(
      informantPMFrom?.qcDate || moment(new Date()).format("YYYY-MM-DD")
    )
    setLeaderTooling(informantPMFrom?.leaderTooling || "")
    setLeaderToolingDate(
      informantPMFrom?.leaderToolingDate ||
        moment(new Date()).format("YYYY-MM-DD")
    )
    setDeliverLeaderProduction(informantPMFrom?.deliverLeaderProduction || "")
    setDeliverDate(
      informantPMFrom?.deliverDate || moment(new Date()).format("YYYY-MM-DD")
    )
  }, [informantPMFrom])
  useEffect(() => {
    ws.onopen
    setSocket(ws)
  }, [])
  const sendMessage = (msg: string) => {
    try {
      if (socket && socket.readyState === WebSocket.OPEN) {
        const messagePayload = {
          action: "sendMessage",
          message: msg
        }

        socket.send(JSON.stringify(messagePayload))
      }
    } catch (e) {
      swal("Error", `${e}`, "warning")
    }
  }
  const handleCreateSummaryPM = async () => {
    if (!summaryQC || !qcDate) {
      swal("Error", "กรุณาเลือกสรุปผลการซ่อม / กรุณาเลือกวัน", "error")
      return
    }
    if (summaryQC === "Trial tooling ไม่ผ่านต้องนำกลับไปซ่อม") {
      const inputCreatePmFrom = {
        repairDate: "",
        repairTime: "",
        repairer: "",
        repairSendBack: "",
        checkAndEdit: "",
        purchaseNo: "",
        purchaseDate: "",
        receiveDate: "",
        quantity: 0,
        amount: 0,
        startRepairDate: "",
        startRepairTime: "",
        endRepairDate: "",
        endRepairTime: "",
        recipient: "",
        code: "",
        cause: "",
        howToRepair: "",
        summaryRepair: "",
        dieType: "",
        repairRequestDate: informantPMFrom?.repairRequestDate,
        repairRequestTime: informantPMFrom?.repairRequestTime,
        factory: informantPMFrom?.factory,
        line: informantPMFrom?.line,
        partNo: informantPMFrom?.partNo,
        partName: informantPMFrom?.partName,
        process: informantPMFrom?.process,
        customer: informantPMFrom?.customer,
        worker: informantPMFrom?.worker,
        leaderProduction: informantPMFrom?.leaderProduction,
        machine: informantPMFrom?.machine,
        objective: informantPMFrom?.objective,
        type: informantPMFrom?.type,
        production: informantPMFrom?.production,
        repairDueDate: informantPMFrom?.repairDueDate,
        problem: informantPMFrom?.problem,
        repairDueDateTime: informantPMFrom?.repairDueDateTime
      }
      const { data: formData } = await createPMEngineerFrom({
        variables: {
          inputCreatePmFrom
        }
      })
      const sendData = {
        message: summaryQC,
        วันที่: formData?.createPMFrom?.repairRequestDate,
        เลขที่เอกสาร: formData?.createPMFrom?.documentNo,
        factory: factory,
        status: false
      }
      sendMessage(JSON.stringify(sendData))

      swal("Warning", "นำกลับไปซ่อมใหม่", "warning")
    }

    const inputRepairerPMFrom = {
      documentNo: Number(record),
      repairRequestDate: informantPMFrom?.repairRequestDate,
      factory: informantPMFrom?.factory,
      NGQuantity: NGQuantity,
      summaryQC: summaryQC,
      leaderQC: leaderQC,
      qcDate: qcDate
    }

    try {
      await updateFrom({
        variables: {
          inputCreatePmFrom: inputRepairerPMFrom
        }
      })

      swal("Success", "Update PM From Success", "success")
    } catch (error) {
      swal("Error", (error as Error).message, "error")
    }
  }

  const handleDeleteSummaryPM = async () => {
    try {
      if (informantPMFrom?.checkAndEdit === "ซ่อม/เบิกอะไหล่") {
        await deleteSparePartStockCard({
          variables: {
            factory: factory,
            documentNo: Number(record),
            repairDate: informantPMFrom?.repairDate,
            repairRequestDate: informantPMFrom?.repairRequestDate
          }
        })
      }
      await deleteFrom({
        variables: {
          documentNo: Number(record),
          factory: factory,
          repairRequestDate: informantPMFrom?.repairRequestDate
        }
      })
      swal("Success", "Delete PM From Success", "success")
    } catch (error) {
      swal("Error", (error as Error).message, "error")
    }
  }

  const handleApproveTooling = async () => {
    if (!leaderToolingDate) {
      swal("Error", "กรุณาเลือกสรุปผลการซ่อม", "error")
      return
    }
    const inputRepairerPMFrom = {
      documentNo: Number(record),
      repairRequestDate: informantPMFrom?.repairRequestDate,
      factory: informantPMFrom?.factory,
      leaderTooling: user.employee,
      leaderToolingDate: leaderToolingDate
    }
    try {
      await updateFrom({
        variables: {
          inputCreatePmFrom: inputRepairerPMFrom
        }
      })
      setLeaderTooling(user.employee)
      swal("Success", "Update PM From Success", "success")
    } catch (error) {
      swal("Error", (error as Error).message, "error")
    }
  }
  const handleApproveDeliver = async () => {
    if (!deliverDate) {
      swal("Error", "กรุณาเลือกสรุปผลการซ่อม", "error")
      return
    }
    const inputRepairerPMFrom = {
      documentNo: Number(record),
      repairRequestDate: informantPMFrom?.repairRequestDate,
      factory: informantPMFrom?.factory,
      deliverLeaderProduction: user.employee,
      deliverDate: deliverDate
    }
    try {
      await updateFrom({
        variables: {
          inputCreatePmFrom: inputRepairerPMFrom
        }
      })
      setLeaderTooling(user.employee)
      swal("Success", "Update PM From Success", "success")
    } catch (error) {
      swal("Error", (error as Error).message, "error")
    }
  }

  const theme = useTheme()
  return (
    <Box>
      <Grid
        container
        item
        border={"2px solid black"}
        bgcolor={"white"}
        sx={{
          "@media print": {
            zoom: "60%"
          }
        }}
      >
        <Grid item container xs={12}>
          <PMDocumentGrid item container xs={6} needBorderRight pb={1}>
            <PMDocumentGrid item container xs={12}>
              <PMDocumentGrid item xs={5} justifyContent={"flex-start"}>
                <Typography pl={1}>7.สรุปผลการซ่อม</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={7}>
                <Typography sx={{ display: "inline-block", pt: 1 }}>
                  ชิ้นงานเสียจากการปรับแต่ง
                </Typography>
                <TextField
                  variant="standard"
                  inputProps={{ step: "0.1", lang: "en-US" }}
                  onChange={(e) => setNGQuantity(Number(e.target.value))}
                  value={NGQuantity ? NGQuantity : 0}
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
                <Typography sx={{ pt: 1 }}>ชิ้น</Typography>
              </PMDocumentGrid>
              <PMDocumentGrid
                item
                xs={12}
                justifyContent={"flex-start"}
                sx={{ pl: 3 }}
              >
                <FormControl>
                  <RadioGroup
                    onChange={(e) => setSummaryQC(e.target.value)}
                    value={summaryQC}
                  >
                    <FormControlLabel
                      value="แก้ไขปัญหาได้แล้ว"
                      control={<ThemedRadio />}
                      label="แก้ไขปัญหาได้แล้ว"
                    />
                    <FormControlLabel
                      value="Trial tooling ไม่ผ่านต้องนำกลับไปซ่อม"
                      control={<ThemedRadio />}
                      label={"Trial tooling ไม่ผ่านต้องนำกลับไปซ่อม"}
                    />
                    <FormControlLabel
                      value="แก้ไขปัญหาชั่วคราว เนื่องจากค้องรีบผลิต จะต้องนำกลับไปซ่อม"
                      control={<ThemedRadio />}
                      label="แก้ไขปัญหาชั่วคราว เนื่องจากค้องรีบผลิต จะต้องนำกลับไปซ่อม"
                    />
                    <FormControlLabel
                      value="ไม่สามารถแก้ไข ได้ต้อวติดต่อลูกค้าเพื่อขอเป็นกรณีพิเศษ"
                      control={<ThemedRadio />}
                      label="ไม่สามารถแก้ไข ได้ต้อวติดต่อลูกค้าเพื่อขอเป็นกรณีพิเศษ"
                    />
                    <FormControlLabel
                      value="ไม่สามารถแก้ไขปัญหาได้"
                      control={<ThemedRadio />}
                      label="ไม่สามารถแก้ไขปัญหาได้"
                    />
                  </RadioGroup>
                </FormControl>
              </PMDocumentGrid>
              <PMDocumentGrid item xs={12}>
                <Typography sx={{ display: "inline-block", pt: 1 }}>
                  ลงชื่อ
                </Typography>
                <Autocomplete
                  fullWidth
                  value={leaderQC}
                  options={leaderQCList}
                  sx={{ width: "200px" }}
                  onChange={(e, value) => setLeaderQC(value as string)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        input: {
                          color: "black",
                          textAlign: "center"
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#666666"
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#666666"
                        }
                      }}
                    />
                  )}
                />
                <TextField
                  type="date"
                  variant="standard"
                  onChange={(e) => setQCDate(e.target.value)}
                  value={qcDate}
                />
              </PMDocumentGrid>
              <PMDocumentGrid item xs={12}>
                <Typography>
                  อนุมัติคุณภาพชิ้นงาน (หัวหน้าฝ่ายประกันคุณภาพ)
                </Typography>
              </PMDocumentGrid>
            </PMDocumentGrid>
          </PMDocumentGrid>

          <PMDocumentGrid
            item
            container
            xs={6}
            style={{
              display:
                leaderQC &&
                summaryQC !== "Trial tooling ไม่ผ่านต้องนำกลับไปซ่อม"
                  ? "flex"
                  : "none"
            }}
          >
            <PMDocumentGrid
              item
              xs={12}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
            >
              <Typography pl={1}>8.ส่งมอบคืน Tooling</Typography>
            </PMDocumentGrid>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{ padding: "10px", textAlign: "center" }}
            >
              <Typography sx={{ display: "inline-block" }}>ลงชื่อ</Typography>
              {!leaderTooling ? (
                <ThemedButton
                  style={
                    user.position === "Leader" ||
                    user.position === "GM" ||
                    user.position === "Manager"
                      ? {
                          backgroundColor: theme.palette.primary.main
                        }
                      : { display: "none" }
                  }
                  onClick={handleApproveTooling}
                >
                  Approve
                </ThemedButton>
              ) : (
                <Typography sx={{ display: "inline-block", pl: 1, pr: 1 }}>
                  {leaderTooling}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={2}
              sx={{ padding: "5px", textAlign: "center" }}
            >
              <TextField
                type="date"
                variant="standard"
                onChange={(e) => setLeaderToolingDate(e.target.value)}
                value={leaderToolingDate}
                sx={{
                  input: {
                    color: "black",
                    textAlign: "center"
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#666666"
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#666666"
                  }
                }}
              />
            </Grid>
            <PMDocumentGrid item xs={12}>
              <Typography>ผู้รับซ่อม (หัวหน้างานซ่อม Tooling)</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid container xs={12}>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ padding: "10px", textAlign: "center" }}
              >
                <Typography sx={{ display: "inline-block" }}>ลงชื่อ</Typography>
                {!deliverLeaderProduction ? (
                  <ThemedButton
                    style={
                      user.position === "Leader" ||
                      user.position === "GM" ||
                      user.position === "Manager"
                        ? {
                            backgroundColor: theme.palette.primary.main
                          }
                        : { display: "none" }
                    }
                    onClick={handleApproveDeliver}
                  >
                    Approve
                  </ThemedButton>
                ) : (
                  <Typography sx={{ display: "inline-block", pl: 2, pr: 2 }}>
                    {deliverLeaderProduction}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ padding: "10px", textAlign: "center" }}
              >
                <TextField
                  type="date"
                  variant="standard"
                  onChange={(e) => setDeliverDate(e.target.value)}
                  value={deliverDate}
                  sx={{
                    input: {
                      color: "black",
                      textAlign: "center"
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#666666"
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#666666"
                    }
                  }}
                />
              </Grid>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={12}>
              <Typography>ผู้แจ้งซ่อม (หัวหน้าฝ่ายผลิต)</Typography>
            </PMDocumentGrid>
          </PMDocumentGrid>
          <PMDocumentGrid
            item
            container
            xs={6}
            style={{
              display:
                summaryQC === "Trial tooling ไม่ผ่านต้องนำกลับไปซ่อม"
                  ? "flex"
                  : "none"
            }}
          >
            <PMDocumentGrid
              item
              xs={12}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
            >
              <Typography pl={1}>8.ส่งมอบคืน Tooling</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={12}>
              <Typography sx={{ display: "inline-block" }}>ลงชื่อ</Typography>
              <Typography sx={{ display: "inline-block", pl: 2, pr: 2 }}>
                ______________
              </Typography>
              <TextField
                type="date"
                variant="standard"
                onChange={(e) => setLeaderToolingDate(e.target.value)}
                value={leaderToolingDate}
              />
            </PMDocumentGrid>
            <PMDocumentGrid item xs={12}>
              <Typography>ผู้รับซ่อม (หัวหน้างานซ่อม Tooling)</Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={12}>
              <Typography sx={{ display: "inline-block" }}>ลงชื่อ</Typography>

              <Typography sx={{ display: "inline-block", pl: 2, pr: 2 }}>
                ______________
              </Typography>
              <TextField
                type="date"
                variant="standard"
                onChange={(e) => setDeliverDate(e.target.value)}
                value={deliverDate}
              />
            </PMDocumentGrid>
            <PMDocumentGrid item xs={12} md={3}>
              <Typography sx={{ padding: "10px", textAlign: "center" }}>
                ผู้แจ้งซ่อม
              </Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={12} md={3}>
              <Typography sx={{ padding: "10px", textAlign: "center" }}>
                (หัวหน้างานซ่อม หัวหน้าฝ่ายผลิต)
              </Typography>
            </PMDocumentGrid>
            <PMDocumentGrid item xs={12}>
              <Typography color={"red"} fontSize={24}>
                แม่พิมพ์ซ่อมไม่สำเร็จ นำกลับไปซ่อมอีกครั้ง
              </Typography>
            </PMDocumentGrid>
          </PMDocumentGrid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          "@media print": {
            display: "none"
          }
        }}
      >
        <Grid item xs={2}>
          <ThemedButton
            fullWidth
            sx={{ mt: 1 }}
            endIcon={<Add />}
            onClick={handleCreateSummaryPM}
          >
            {informantPMFrom?.qcDate ? "Update" : "Create From"}
          </ThemedButton>
        </Grid>
        <Grid item xs={2}>
          <ThemedButton
            fullWidth
            sx={{ mt: 1, display: informantPMFrom?.qcDate ? "" : "none" }}
            endIcon={<Delete />}
            onClick={handleDeleteSummaryPM}
          >
            Delete
          </ThemedButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: leaderQC ? "flex" : "none"
          }}
        >
          <ThemedButton
            fullWidth
            sx={{
              mt: 1,
              width: "220px",
              borderColor: theme.palette.primary.main,
              color: theme.palette.secondary.contrastText
            }}
            onClick={() => window.print()}
            aria-label="print"
            endIcon={<Print />}
          >
            Print
          </ThemedButton>
        </Grid>
      </Grid>
    </Box>
  )
}
export default PMQCFrom
