import { useQuery } from "@apollo/client"
import { useTheme } from "@mui/material"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import moment from "moment"
import { useRef } from "react"
import { GET_PM_MACHINE_KPI } from "../../../../common/Query/MaintenanceQuery"
import { monthNames } from "../../PreventiveMaintenancePlan"

interface IPMKpi {
  target: number
  month: number
  actual: number
}
export const PMColumnChart = (props: {
  dashboard?: boolean
  chart?: HighchartsReact.Props
  pmData: IPMKpi[]
  setShowChart?: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
  const { pmData: dataPmPlan, dashboard } = props
  let pmData: IPMKpi[] = []
  if (dashboard) {
    const { data: dataGetPMKpi } = useQuery(GET_PM_MACHINE_KPI, {
      variables: { year: new Date().getFullYear().toString() }
    })
    pmData = dataGetPMKpi?.pmMachineKPI || []
  } else {
    pmData = dataPmPlan
  }
  const theme = useTheme()
  const month = new Date().getMonth() + 1
  const columnOptions: Highcharts.Options = {
    chart: {
      type: "column",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main
    },
    credits: { enabled: false },
    title: {
      text: "Preventive Maintenance ",
      style: {
        color: theme.palette.secondary.contrastText
      }
    },

    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
    },
    xAxis: {
      categories: monthNames,
      labels: {
        x: -10,
        style: {
          color: theme.palette.secondary.contrastText,
          fontSize: "10px"
        }
      }
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "Total",
        style: {
          color: theme.palette.secondary.contrastText
        }
      },
      labels: {
        style: {
          color: theme.palette.secondary.contrastText
        }
      }
    },
    series: [
      {
        type: "column",
        name: "Plan",
        data: pmData.map((e) => e.target || 0)
      },
      {
        type: "column",
        name: "Actual",
        data: pmData.map((e) => e.actual || 0)
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }
  const pieOptions: Highcharts.Options = {
    chart: {
      type: "pie",
      plotShadow: false,
      backgroundColor: theme.palette.secondary.main
    },
    title: {
      text: `PM.Machine Monthly ${moment(new Date()).format("YYYY-MM")}`,
      style: {
        color: theme.palette.secondary.contrastText
      }
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
    },
    plotOptions: {
      pie: {
        innerSize: 100,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true
        },
        showInLegend: false
      }
    },
    series: [
      {
        name: "Brands",
        type: "pie",
        colorByPoint: true,
        data: [
          {
            name: "Target",
            y:
              100 -
              pmData
                .filter((e) => e.month === month)
                .map((pm) => (pm.actual / pm.target) * 100 || 0)[0]
          },
          {
            name: "Actual",
            y: pmData
              .filter((e) => e.month === month)
              .map((pm) => (pm.actual / pm.target) * 100 || 0)[0]
          }
        ]
      }
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  }
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  return (
    <>
      {dashboard ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={columnOptions}
          ref={chartComponentRef}
          {...props.chart}
        />
      ) : (
        <>
          <div style={{ display: "inline-block" }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={columnOptions}
              ref={chartComponentRef}
              {...props.chart}
            />
          </div>
          <div style={{ display: "inline-block" }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={pieOptions}
              ref={chartComponentRef}
              {...props.chart}
            />
          </div>
        </>
      )}
    </>
  )
}
