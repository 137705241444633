import { useQuery } from "@apollo/client"
import { Autocomplete } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { GET_SPARE_PARTS } from "../Query/MaintenanceQuery"
import ThemedTextField from "./ThemedComponents/ThemedTextField"

interface IProps {
  setPartName: Dispatch<SetStateAction<string>>
  partName: string
  required?: boolean
  error?: boolean
  code: string
}
const AutocompleteSparePartPartName = (props: IProps): JSX.Element => {
  const { partName, setPartName, code, required, error } = props
  const { loading, data } = useQuery(GET_SPARE_PARTS)
  let partNameList = []
  if (data) {
    if (code)
      partNameList = data.sparePartsList
        .filter((sparePart: { code: string }) => sparePart.code === code)
        .map(({ partName }: { partName: string }) => partName)
    else
      partNameList = data.sparePartsList.map(
        ({ partName }: { partName: string }) => partName
      )
  }
  partNameList = Array.from(new Set(partNameList))
  return (
    <Autocomplete
      value={partName}
      fullWidth
      onChange={(event, selectedPartName) => {
        setPartName(selectedPartName as string)
      }}
      options={partNameList}
      loading={loading}
      renderInput={(params) => (
        <ThemedTextField
          {...params}
          label="Part Name"
          required={required}
          error={error ? error : false}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  )
}
export default AutocompleteSparePartPartName
