import { Button, ButtonProps, ThemeProvider, useTheme } from "@mui/material"
import { styled } from "@mui/material/styles"

interface IThemedButtonProps extends ButtonProps {
  contrast?: boolean
}

const ThemedButtonComponent = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.secondary.contrastText
})) as typeof Button

const ContrastButtonComponent = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText
})) as typeof Button

const ThemedButton = (props: IThemedButtonProps): JSX.Element => {
  const { contrast = false } = props
  const theme = useTheme()

  const themedButton = contrast ? (
    <ContrastButtonComponent {...props} />
  ) : (
    <ThemedButtonComponent {...props} />
  )
  return <ThemeProvider theme={theme}>{themedButton}</ThemeProvider>
}

export default ThemedButton
