import { gql } from "@apollo/client"

export const GET_SHIFT = gql`
  query GetShift($planDate: String, $line: String, $factory: String) {
    getShift(planDate: $planDate, line: $line, factory: $factory) {
      shift
    }
  }
`

export const GET_LINE = gql`
  query GetLine {
    getLine {
      lineId
      lineName
    }
  }
`

export const GET_NOTIFICATION = gql`
  query GetNotification($department: String, $limit: Int, $userId: String) {
    getNotification(department: $department, limit: $limit, userId: $userId) {
      notification {
        actionDate
        message
        id
        time
        link
        status
        userRead
      }
      unRead
    }
  }
`

export const GET_HOLIDAY = gql`
  query GetHoliday {
    getHoliday {
      holiday
      detail
    }
  }
`
