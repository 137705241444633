import { ThemeProvider, useTheme } from "@mui/styles"
import { PageLabel } from "../../../common/Resources/PageLabel"
import { menuItemList } from "../../../common/Resources/menuItemList"
import { Box, CardHeader, Grid } from "@mui/material"
import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import { useState } from "react"
import ThemedCard from "../../../common/Resources/ThemedComponents/ThemedCard"
import CommonDataGrid from "../../../common/Resources/CommonDatagrid"
import { GridColDef, GridColumnGroupingModel } from "@mui/x-data-grid"
import { GET_LOG_INFORMANT_PM } from "../../../common/Query/engineerQuery"
import { useQuery } from "@apollo/client"
import moment from "moment"
import AutocompleteFactory from "../../../common/Resources/AutocompleteFactory"
import { calculateTotalHours } from "./utilities"

const pageTitle = "PM Log Sheet"

interface IGridData {
  index: number
  repairNotificationDate: string
  timeToReportRepairs: string
  line: string
  factory: string
  documentNumber: string
  informer: string
  headOfProduction: string
  machineNo: string
  objective: string
  type: string
  customer: string
  partNo: string
  partName: string
  process: string
  problem: string
  dateReceiptRepair: string
  timeToReceiveRepair: string
  repairRecipient: string
  cause: string
  returnDate: string
  dieSize: string
  checkAndEdit: string
  diePurchaseOrderNumber: string
  dieOrderDate: string
  dieReceivedDate: string
  partNameDie: string
  partNoDie: string
  quantity: number
  repairStartDate: string
  repairStartTime: string
  dateRepairCompletion: string
  timeRepairCompletion: string
  totalTimeRepair: string
  summaryOfRepairMethods: string
  howToRepair: string
  repairer: string
  piecesDamaged: number
  repairResults: string
  QA: string
  dateQC: string
  leadRepair: string
  deliveryWorkRecipient: string
  receivingWorkFromRepair: string
}

interface IPMData {
  repairRequestDate: string
  repairRequestTime: string
  documentNo: number
  line: string
  factory: string
  partNo: string
  partName: string
  process: string
  customer: string
  worker: string
  leaderProduction: string
  machine: string
  objective: string
  type: string
  production: number
  repairDueDate: string
  problem: string
  repairDate: string
  repairTime: string
  repairer: string
  repairSendBack: string
  checkAndEdit: string
  purchaseNo: string
  purchaseDate: string
  receiveDate: string
  quantity: number
  amount: number
  startRepairDate: string
  startRepairTime: string
  endRepairDate: string
  endRepairTime: string
  recipient: string
  code: string
  cause: string
  howToRepair: string
  summaryRepair: string
  NGQuantity: number
  deliverDate: string
  deliverLeaderProduction: string
  leaderQC: string
  leaderTooling: string
  leaderToolingDate: string
  qcDate: string
  summaryQC: string
  repairDueDateTime: string
  dieType: string
  manHour: number
  machineHour: number
}

const columnNames: GridColDef[] = [
  {
    field: "index",
    headerName: "ลำดับ",
    minWidth: 10,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "repairNotificationDate",
    headerName: "วันที่แจ้งซ่อม",
    minWidth: 130,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "timeToReportRepairs",
    headerName: "เวลาแจ้งซ่อม",
    minWidth: 110,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "line",
    headerName: "ไลน์ผลิต",
    minWidth: 120,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "factory",
    headerName: "โรงงาน",
    headerAlign: "center",
    align: "center"
  },
  {
    field: "documentNumber",
    headerName: "เลขที่เอกสาร",
    minWidth: 120,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "informer",
    headerName: "ผู้แจ้งซ่อม",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "headOfProduction",
    headerName: "หัวหน้าฝ่ายผลิต",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "machineNo",
    headerName: "Machine No.",
    minWidth: 160,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "objective",
    headerName: "วัตถุประสงค์",
    minWidth: 120,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "type",
    headerName: "ประเภทเครื่องจักร",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "customer",
    headerName: "ลูกค้า",
    headerAlign: "center",
    align: "center"
  },
  {
    field: "partNo",
    headerName: "Part No.",
    minWidth: 200,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "partName",
    headerName: "Part Name",
    minWidth: 250,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "process",
    headerName: "Process",
    headerAlign: "center",
    align: "center"
  },
  {
    field: "problem",
    headerName: "ปัญหา",
    minWidth: 250,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "dateReceiptRepair",
    headerName: "วันที่รับซ่อม",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "timeToReceiveRepair",
    headerName: "เวลาที่รับซ่อม",
    headerAlign: "center",
    align: "center"
  },
  {
    field: "repairRecipient",
    headerName: "ผู้รับซ่อม",
    headerAlign: "center",
    align: "center"
  },
  {
    field: "cause",
    headerName: "สาเหตุแจ้งซ่อม",
    minWidth: 250,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "returnDate",
    headerName: "วันที่ส่งกลับ",
    minWidth: 120,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "dieSize",
    headerName: "ขนาดแม่พิมพ์",
    minWidth: 120,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "checkAndEdit",
    headerName: "การแก้ไข",
    minWidth: 200,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "diePurchaseOrderNumber",
    headerName: "เลขที่ใบสั่งซื้อ",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "dieOrderDate",
    headerName: "วันที่สั่ง",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "dieReceivedDate",
    headerName: "วันที่รับของ",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "partNameDie",
    headerName: "Part Name",
    minWidth: 200,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "partNoDie",
    headerName: "รหัส",
    minWidth: 200,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "quantity",
    headerName: "จำนวน",
    headerAlign: "center",
    align: "center"
  },
  {
    field: "repairStartDate",
    headerName: "วันที่ซ่อม",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "repairStartTime",
    headerName: "เวลาเริ่มซ่อม",
    headerAlign: "center",
    align: "center"
  },
  {
    field: "dateRepairCompletion",
    headerName: "วันที่ซ่อมเสร็จ",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "timeRepairCompletion",
    headerName: "เวลาที่ซ่อมเสร็จ",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "totalTimeRepair",
    headerName: "เวลาที่ใช้ไปทั้งหมด",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "summaryOfRepairMethods",
    headerName: "สรุปวิธีการซ่อม",
    minWidth: 300,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "howToRepair",
    headerName: "วิธีการซ่อม",
    minWidth: 250,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "repairer",
    headerName: "ผู้ซ่อม",
    minWidth: 200,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "piecesDamaged",
    headerName: "ชิ้นงานเสียจากการปรับแต่ง",
    headerAlign: "center",
    align: "center"
  },
  {
    field: "repairResults",
    headerName: "สรุปผลการซ่อม",
    minWidth: 200,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "QA",
    headerName: "QA",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "dateQC",
    headerName: "วันที่อนุมัติ",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "leadRepair",
    headerName: "หัวหน้างานซ่อม",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "deliveryWorkRecipient",
    headerName: "วันที่ส่งกลับผู้แจ้งซ่อม",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "receivingWorkFromRepair",
    headerName: "วันที่ผู้รับ",
    minWidth: 150,
    headerAlign: "center",
    align: "center"
  }
]

const PMLogSheetReport: React.FC = () => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment(new Date()).format("YYYY-MM-01")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [factory, setFactory] = useState<string>("2")
  const { data: PMhistory } = useQuery(GET_LOG_INFORMANT_PM, {
    variables: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      factory: factory
    }
  })

  const theme = useTheme()

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "detailReportFix",
      description: "",
      headerName: "รายล่ะเอียดการแจ้งซ่อม",
      headerAlign: "center",
      children: [
        { field: "repairNotificationDate" },
        { field: "timeToReportRepairs" },
        { field: "line" },
        { field: "factory" },
        { field: "documentNumber" },
        { field: "informer" },
        { field: "headOfProduction" },
        { field: "machineNo" },
        { field: "objective" },
        { field: "type" },
        { field: "customer" },
        { field: "partNo" },
        { field: "partName" },
        { field: "process" },
        { field: "problem" }
      ]
    },
    {
      groupId: "receivedRepair",
      description: "",
      headerName: "รับซ่อม",
      headerAlign: "center",
      children: [
        { field: "dateReceiptRepair" },
        { field: "timeToReceiveRepair" },
        { field: "repairRecipient" },
        { field: "cause" },
        { field: "returnDate" },
        { field: "dieSize" }
      ]
    },
    {
      groupId: "checkAndEdit",
      description: "",
      headerName: "การแก้ไข/เบิกอะไหล่",
      headerAlign: "center",
      children: [{ field: "checkAndEdit" }]
    },
    {
      groupId: "reciptDie",
      description: "",
      headerName: "สั่งซื้อชิ้นส่วนซ่อมแม่พิมพ์",
      headerAlign: "center",
      children: [
        { field: "diePurchaseOrderNumber" },
        { field: "dieOrderDate" },
        { field: "dieReceivedDate" }
      ]
    },
    {
      groupId: "reciptDie1",
      description: "",
      headerName: "เบิกชิ้นส่วนแม่พิมพ์",
      headerAlign: "center",
      children: [
        { field: "partNameDie" },
        { field: "partNoDie" },
        { field: "quantity" }
      ]
    },
    {
      groupId: "repairDetail",
      description: "",
      headerName: "การซ่อม",
      headerAlign: "center",
      children: [
        { field: "repairStartDate" },
        { field: "repairStartTime" },
        { field: "dateRepairCompletion" },
        { field: "timeRepairCompletion" },
        { field: "totalTimeRepair" },
        { field: "summaryOfRepairMethods" },
        { field: "howToRepair" },
        { field: "repairer" },
        { field: "piecesDamaged" },
        { field: "repairResults" }
      ]
    },
    {
      groupId: "returnDie",
      description: "",
      headerName: "ส่งมอบคืนแม่พิพม์",
      headerAlign: "center",
      children: [
        { field: "QA" },
        { field: "dateQC" },
        { field: "leadRepair" },
        { field: "deliveryWorkRecipient" },
        { field: "receivingWorkFromRepair" }
      ]
    }
  ]

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.engineer}
        menuItemName={Object.keys(menuItemList)[5]}
        menuPageName={pageTitle}
      />
      <ThemedCard sx={{ marginBottom: "10px" }}>
        <CardHeader
          titleTypographyProps={{ variant: "h4", fontFamily: "Sarabun" }}
          title="PM Log Sheet Report"
          subheaderTypographyProps={{ fontFamily: "Sarabun" }}
          subheader="Check Log Sheet PM Engineer Report here"
        />
        <div style={{ padding: "8px" }}>
          <Grid sx={{ display: "flex" }}>
            <Grid item xs={4} sx={{ width: "200px", padding: "5px" }}>
              <ThemedTextField
                fullWidth
                value={dateFrom}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date From"
                autoComplete="family-name"
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Grid>
            <Grid item xs={4} sx={{ width: "200px", padding: "5px" }}>
              <ThemedTextField
                fullWidth
                value={dateTo}
                variant="outlined"
                id="action-date"
                type="date"
                label="Date To"
                autoComplete="family-name"
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Grid>
            <Grid item xs={4} sx={{ width: "200px", padding: "5px" }}>
              <AutocompleteFactory setFactory={setFactory} factory={factory} />
            </Grid>
          </Grid>
        </div>
        <Box sx={{ overflow: "auto" }}>
          <h3>PM LOG SHEET</h3>
          <Box
            sx={{
              width: "100%",
              display: "table",
              tableLayout: "fixed",
              backgroundColor: "#FFFFFF"
            }}
          >
            <CommonDataGrid
              data={processRawData(PMhistory?.getLogPMHistory)}
              columnNames={columnNames}
              pageSize={5}
              fileName="RawDataTable"
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={columnGroupingModel}
            />
          </Box>
        </Box>
      </ThemedCard>
    </ThemeProvider>
  )
}

function processRawData(PMhistory: IPMData[]): IGridData[] {
  const rawData = PMhistory ?? []

  const processedData = rawData.map((row, index) => {
    const totalTime = isNaN(
      calculateTotalHours(
        new Date(`${row.startRepairDate} ` + row.startRepairTime),
        new Date(`${row.endRepairDate} ` + row.endRepairTime),
        ""
      )
    )
      ? 0
      : calculateTotalHours(
          new Date(`${row.startRepairDate} ` + row.startRepairTime),
          new Date(`${row.endRepairDate} ` + row.endRepairTime),
          ""
        ) / 60
    const hour = Math.floor(totalTime)
    const minute = Math.round((totalTime - hour) * 60)

    const convertDate = (item: string) => {
      return item.split("-").reverse().join("/")
    }
    return {
      id: index,
      index: index + 1,
      repairNotificationDate: convertDate(row.repairRequestDate),
      timeToReportRepairs: row.repairRequestTime,
      line: row.line,
      factory: row.factory,
      documentNumber: row.documentNo.toString().padStart(3, "0"),
      informer: row.worker,
      headOfProduction: row.leaderProduction,
      machineNo: row.machine,
      objective: row.objective,
      type: row.type,
      customer: row.customer,
      partNo: row.partNo,
      partName: row.partName,
      process: row.process,
      problem: row.problem.split(".")[1],
      dateReceiptRepair: row.repairDate,
      timeToReceiveRepair: row.repairTime,
      repairRecipient: row.recipient,
      cause: row.problem.split(".")[1],
      returnDate: row.repairSendBack,
      dieSize: row.dieType,
      checkAndEdit: row.checkAndEdit,
      diePurchaseOrderNumber: row.purchaseNo,
      dieOrderDate: row.purchaseDate,
      dieReceivedDate: row.receiveDate,
      partNameDie: row.partName,
      partNoDie: row.code,
      quantity: row.quantity,
      repairStartDate: row.startRepairDate,
      repairStartTime: row.startRepairTime,
      dateRepairCompletion: row.endRepairDate,
      timeRepairCompletion: row.endRepairTime,
      totalTimeRepair: `${hour}` + ` ชม.` + ` ${minute}` + ` นาที`,
      summaryOfRepairMethods: row.summaryRepair
        ? row.summaryRepair.split(",")[1]
        : "",
      howToRepair: row.howToRepair,
      repairer: row.repairer,
      piecesDamaged: row.NGQuantity,
      repairResults: row.cause,
      QA: row.leaderQC,
      dateQC: row.qcDate,
      leadRepair: row.leaderTooling,
      deliveryWorkRecipient: row.deliverLeaderProduction,
      receivingWorkFromRepair: row.deliverDate
    }
  })

  return processedData
}

export default PMLogSheetReport
