import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  ThemeProvider,
  useTheme
} from "@mui/material"
import moment from "moment"
import { useMemo, useState } from "react"
import swal from "sweetalert"
import { user } from "../../common/MainApp"
import { APPROVE_EDIT_SCANNER } from "../../common/Mutation/ApproveEditMutation"
import { GET_APPROVE_EDIT_SCANNER } from "../../common/Query/ApproveEditQuery"
import { GET_EMPLOYEE } from "../../common/Query/MasterQuery"
import { menuItemList } from "../../common/Resources/menuItemList"
import { PageLabel } from "../../common/Resources/PageLabel"
import { PageLabelCard } from "../../common/Resources/PageLabelCard"
import ThemedCard from "../../common/Resources/ThemedComponents/ThemedCard"
import ThemedTextField from "../../common/Resources/ThemedComponents/ThemedTextField"
import { clearParam } from "../../common/utilities"
import { checkRole } from "../../config/routes"

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary.contrastText,
    border: 0,
    whiteSpace: "nowrap"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.light
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.primary.main
  },

  "td,th": {
    border: 0
  }
}))

const pageTitle = "Approve"

interface IDataGetScanner {
  id: number
  tableName: string
  statusApprove: string
  approver: string
  approvalDate: string
  requestDate: string
  editJson: string
  editBy: string
}

export interface IDataEmployee {
  employeeId: number
  firstName: string
  lastName: string
  departmentId: number
  factory: string
  position: string
}

const countStatusApprove = (
  data: IDataGetScanner[]
): Record<string, number> => {
  return data.reduce((acc, currentItem) => {
    acc[currentItem.statusApprove] = (acc[currentItem.statusApprove] || 0) + 1
    return acc
  }, {} as Record<string, number>)
}

const ApproveEditScanner: React.FC = () => {
  const [approveBy, setApproveBy] = useState<string>(user.employee)
  const [date, setDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [dateTo, setDateTo] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [status, setStatus] = useState<string>("")
  const [listFactory, setListFactory] = useState<number[]>([])
  const [factory, setFactory] = useState<number | null>(null)
  const [result, setResult] = useState<boolean>(false)
  const [getDataScanner] = useLazyQuery(GET_APPROVE_EDIT_SCANNER)
  const [updateDataScanner, { loading }] = useMutation(APPROVE_EDIT_SCANNER)
  const [listEditTable, setListEditTable] = useState<IDataGetScanner[]>([])
  let employeeList: IDataEmployee[] = []
  const getEmployee = useQuery(GET_EMPLOYEE)
  if (getEmployee.data) {
    employeeList = getEmployee.data.employee.filter(
      ({ position }: { position: string }) =>
        position === "Leader" || position === "Manager"
    )
  }
  useMemo(async () => {
    const dataEdit = await getDataScanner({
      variables: {
        tableName: "testScanner",
        actionDate: date,
        actionDateTo: dateTo
      },
      fetchPolicy: "network-only"
    })

    let dataQuery = dataEdit.data?.getApproveEdit

    if (dataQuery?.length > 0) {
      const getFactory: number[] = Array.from(
        new Set(
          dataQuery
            .map((item: IDataGetScanner) => {
              return JSON.parse(item.editJson).factory
            })
            .sort(function (a: number, b: number) {
              return a - b
            })
        )
      )
      setListFactory(getFactory)
    }

    if (dataQuery?.length > 0) {
      setListEditTable(dataQuery)
      if (status) {
        dataQuery = dataQuery?.filter(
          (entry: IDataGetScanner) => entry.statusApprove === status
        )
      }

      if (approveBy) {
        const employee = employeeList.find(
          (ele: IDataEmployee) => ele.firstName === approveBy
        )
        if (employee?.departmentId === 3) {
          dataQuery = dataQuery?.filter(
            (entry: IDataGetScanner) =>
              JSON.parse(entry.editJson).process === "QC"
          )
        }
        if (employee?.departmentId === 2) {
          dataQuery = dataQuery?.filter(
            (entry: IDataGetScanner) =>
              JSON.parse(entry.editJson).process !== "QC"
          )
        }
      }

      if (factory !== undefined && factory !== null) {
        dataQuery = dataQuery?.filter(
          (entry: IDataGetScanner) =>
            JSON.parse(entry.editJson).factory === factory
        )
      }

      setListEditTable(
        dataQuery.sort((a: { id: number }, b: { id: number }) =>
          a.id > b.id ? -1 : a.id < b.id ? 1 : 0
        )
      )
    } else setListEditTable([])
  }, [getDataScanner, result, status, date, dateTo, approveBy, factory])

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const dateParam = urlParams.get("date")

  useMemo(async () => {
    if (dateParam) {
      setDate(dateParam)
      const dataEdit = await getDataScanner({
        variables: {
          tableName: "testScanner",
          actionDate: dateParam,
          actionDateTo: dateParam
        },
        fetchPolicy: "network-only"
      })
      let dataQuery = dataEdit.data?.getApproveEdit

      if (dataQuery?.length > 0) {
        const getFactory: number[] = Array.from(
          new Set(
            dataQuery
              .map((item: IDataGetScanner) => {
                return JSON.parse(item.editJson).factory
              })
              .sort(function (a: number, b: number) {
                return a - b
              })
          )
        )
        setListFactory(getFactory)
      }

      if (dataQuery?.length > 0) {
        setListEditTable(dataQuery)
        if (status) {
          dataQuery = dataQuery?.filter(
            (entry: IDataGetScanner) => entry.statusApprove === status
          )
        }

        if (approveBy) {
          const employee = employeeList.find(
            (ele: IDataEmployee) => ele.firstName === approveBy
          )
          if (employee?.departmentId === 3) {
            dataQuery = dataQuery?.filter(
              (entry: IDataGetScanner) =>
                JSON.parse(entry.editJson).process === "QC"
            )
          }
          if (employee?.departmentId === 2) {
            dataQuery = dataQuery?.filter(
              (entry: IDataGetScanner) =>
                JSON.parse(entry.editJson).process !== "QC"
            )
          }
        }

        if (factory !== undefined && factory !== null) {
          dataQuery = dataQuery?.filter(
            (entry: IDataGetScanner) =>
              JSON.parse(entry.editJson).factory === factory
          )
        }

        setListEditTable(
          dataQuery.sort((a: { id: number }, b: { id: number }) =>
            a.id > b.id ? -1 : a.id < b.id ? 1 : 0
          )
        )
      }
      clearParam("ApproveEditScanner")
    }
  }, [dateParam])

  const handleSubmit = async (data: IDataGetScanner, type: string) => {
    if (!approveBy) return swal("", "กรุณาใส่ชื่อผู้อนุมัติ", "warning")
    try {
      await updateDataScanner({
        variables: {
          dataUpdateApproveEdit: {
            id: data.id,
            tableName: data.tableName,
            statusApprove: type,
            approver: approveBy,
            requestDate: data.requestDate,
            editJson: data.editJson,
            editBy: data.editBy
          }
        }
      })
      setResult(!result)
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "error")
    }
  }

  const listStatusApprove = useMemo(
    () => countStatusApprove(listEditTable),
    [listEditTable]
  )

  const displayStatusApprove = (status: string): number => {
    if (status === "รออนุมัติ") {
      return listStatusApprove?.รออนุมัติ ?? 0
    } else if (status === "อนุมัติแล้ว") {
      return listStatusApprove?.อนุมัติแล้ว ?? 0
    } else if (status === "ไม่อนุมัติ") {
      return listStatusApprove?.ไม่อนุมัติ ?? 0
    } else {
      return 0
    }
  }

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <PageLabel
        menuItem={menuItemList.Scanner}
        menuItemName={Object.keys(menuItemList)[11]}
        menuPageName={pageTitle}
      />
      <ThemedCard>
        <PageLabelCard
          title="Approve Scanner"
          subTitle="detail about Approve Scanner"
        />
        <Grid container spacing={2} width={"98%"} margin={2}>
          <Grid item xs={12} sm={3}>
            <ThemedTextField
              fullWidth
              type="date"
              value={date}
              label="Date From"
              InputLabelProps={{
                shrink: true
              }}
              id="date"
              autoComplete="family-name"
              onChange={(e) => setDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ThemedTextField
              fullWidth
              type="date"
              value={dateTo}
              label="Date To"
              InputLabelProps={{
                shrink: true
              }}
              id="date"
              autoComplete="family-name"
              onChange={(e) => setDateTo(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              disablePortal
              value={factory}
              onChange={(e, newValue) => {
                setFactory(newValue as number | null)
              }}
              options={listFactory}
              getOptionLabel={(option) => option?.toString()}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Factory"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              disablePortal
              value={status}
              onChange={(e, newValue) => setStatus(newValue ? newValue : "")}
              options={["รออนุมัติ", "อนุมัติแล้ว", "ไม่อนุมัติ"]}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Status"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Autocomplete
              disabled
              fullWidth
              disablePortal
              value={approveBy}
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label="Approver By"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(e, newValue) => setApproveBy(newValue ? newValue : "")}
              options={employeeList.map(
                ({ firstName }: { firstName: string }) => firstName
              )}
            />
          </Grid>
          <Grid container sx={{ mt: "20px", textAlign: "center" }}>
            <Grid item xs={2} sx={{ color: "orange" }}>
              จำนวนรออนุมัติ = {displayStatusApprove("รออนุมัติ")}
            </Grid>
            <Grid item xs={2} sx={{ color: "green" }}>
              จำนวนอนุมัติแล้ว = {displayStatusApprove("อนุมัติแล้ว")}
            </Grid>
            <Grid item xs={2} sx={{ color: "red" }}>
              จำนวนไม่อนุมัติ = {displayStatusApprove("ไม่อนุมัติ")}
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Table
              style={{
                margin: "5px",
                width: "95%"
              }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ borderRadius: "20px 0px 0px 0px" }}>
                    request Date
                  </StyledTableCell>
                  <StyledTableCell>Part No</StyledTableCell>
                  <StyledTableCell>jobOrder</StyledTableCell>
                  <StyledTableCell>Factory</StyledTableCell>
                  <StyledTableCell>process</StyledTableCell>
                  <StyledTableCell>Box No</StyledTableCell>
                  <StyledTableCell>Old Machine</StyledTableCell>
                  <StyledTableCell>New Machine</StyledTableCell>
                  <StyledTableCell>Old Quantity</StyledTableCell>
                  <StyledTableCell>New Quantity</StyledTableCell>
                  <StyledTableCell>Old Action Date</StyledTableCell>
                  <StyledTableCell>New Action Date</StyledTableCell>
                  <StyledTableCell>แก้ไข / ยกเลิก</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell style={{ borderRadius: "0px 20px 0px 0px" }}>
                    Approve
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(Array(listEditTable.length), (e, i) => {
                  const {
                    partNo,
                    jobOrder,
                    factory,
                    process,
                    boxNo,
                    oldMachine,
                    machine,
                    oldQuantity,
                    quantity,
                    actionDate,
                    oldDate,
                    type
                  } = JSON.parse(listEditTable[i]?.editJson)

                  return (
                    <StyledTableRow key={i}>
                      <StyledTableCell>
                        {moment(listEditTable[i].requestDate).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{partNo}</StyledTableCell>
                      <StyledTableCell>{jobOrder}</StyledTableCell>
                      <StyledTableCell>{factory}</StyledTableCell>
                      <StyledTableCell>{process}</StyledTableCell>
                      <StyledTableCell>{boxNo}</StyledTableCell>
                      <StyledTableCell>{oldMachine}</StyledTableCell>
                      <StyledTableCell
                        style={machine !== oldMachine ? { color: "red" } : {}}
                      >
                        {machine}
                      </StyledTableCell>
                      <StyledTableCell>{oldQuantity}</StyledTableCell>
                      <StyledTableCell
                        style={quantity !== oldQuantity ? { color: "red" } : {}}
                      >
                        {quantity}
                      </StyledTableCell>
                      <StyledTableCell>{oldDate}</StyledTableCell>
                      <StyledTableCell
                        style={actionDate !== oldDate ? { color: "red" } : {}}
                      >
                        {actionDate}
                      </StyledTableCell>
                      <StyledTableCell>{type}</StyledTableCell>
                      <StyledTableCell
                        style={
                          listEditTable[i].statusApprove === "อนุมัติแล้ว"
                            ? { color: "green" }
                            : listEditTable[i].statusApprove === "รออนุมัติ"
                            ? { color: "orange" }
                            : { color: "red" }
                        }
                      >
                        {listEditTable[i].statusApprove}
                      </StyledTableCell>
                      <StyledTableCell>
                        {listEditTable[i].statusApprove !== "รออนุมัติ" ? (
                          <>
                            {moment(listEditTable[i].approvalDate).format(
                              "DD-MM-YYYY HH:mm"
                            ) +
                              " " +
                              listEditTable[i].approver}
                          </>
                        ) : (
                          <>
                            <LoadingButton
                              style={
                                checkRole(["99"])
                                  ? { marginLeft: "10px" }
                                  : { display: "none" }
                              }
                              variant="contained"
                              disabled={
                                listEditTable[i].statusApprove !==
                                  "รออนุมัติ" || !checkRole(["99"])
                              }
                              color="success"
                              sx={{ mt: 3, mb: 2 }}
                              onClick={() =>
                                handleSubmit(listEditTable[i], "อนุมัติแล้ว")
                              }
                              loading={loading}
                            >
                              Yes
                            </LoadingButton>
                            <LoadingButton
                              style={
                                checkRole(["99"])
                                  ? { marginLeft: "10px" }
                                  : { display: "none" }
                              }
                              variant="contained"
                              disabled={
                                listEditTable[i].statusApprove !==
                                  "รออนุมัติ" || !checkRole(["99"])
                              }
                              color="error"
                              sx={{ mt: 3, mb: 2 }}
                              onClick={() =>
                                handleSubmit(listEditTable[i], "ไม่อนุมัติ")
                              }
                              loading={loading}
                            >
                              No
                            </LoadingButton>
                          </>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </ThemedCard>
    </ThemeProvider>
  )
}
export default ApproveEditScanner
